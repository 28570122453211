import { realm } from '../helpers/realm'

export const getWFMData = async (filter) => {
	// console.log("filter", filter)
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')

	const aggregationPipeline = []

	aggregationPipeline.push({
		$match: {
			$and: [{ wholefoods: true }, filter],
		},
	})

	aggregationPipeline.push({
		$sort: {
			timestamp: 1,
		},
	})

	aggregationPipeline.push({
		$group: {
			_id: '$timestamp',
			boxesOutLg: {
				$sum: {
					$cond: [
						{ $eq: ['$type', 'boxesOut'] },
						'$boxCountByContainer.OG-large',
						0,
					],
				},
			},
			boxesOutMd: {
				$sum: {
					$cond: [
						{ $eq: ['$type', 'boxesOut'] },
						'$boxCountByContainer.OG-medium',
						0,
					],
				},
			},
			boxesInLg: {
				$sum: {
					$cond: [
						{ $eq: ['$type', 'boxesIn'] },
						'$boxCountByContainer.OG-large',
						0,
					],
				},
			},
			boxesInMd: {
				$sum: {
					$cond: [
						{ $eq: ['$type', 'boxesIn'] },
						'$boxCountByContainer.OG-medium',
						0,
					],
				},
			},
			barTrans: {
				// $first: '$barTrans'
				$sum: {
					$cond: [{ $eq: ['$type', 'boxesOut'] }, '$barTrans', 0],
				},
			},
			wkPercent: {
				$sum: {
					$cond: [
						{ $eq: ['$type', 'boxesOut'] },
						{
							$divide: [
								{
									$add: [
										'$boxCountByContainer.OG-large',
										'$boxCountByContainer.OG-medium',
									],
								},
								'$barTrans',
							],
						},
						0,
					],
				},
			},
		},
	})

	const result = await transactions.aggregate(aggregationPipeline)

	for (const doc of result) {
		const docDate = new Date(doc._id)
		const fiveWeeksAgo = new Date(docDate)
		fiveWeeksAgo.setDate(fiveWeeksAgo.getDate() - 34)
		fiveWeeksAgo.setHours(0, 0, 0, 0)
		const dayAfterFiveWeeksAgo = new Date(
			fiveWeeksAgo.getTime() + 24 * 60 * 60 * 1000
		)

		const fiveWeeksAgoPipeline = [
			{
				$match: {
					wholefoods: true,
					fromWeeklyReport: true,
					timestamp: {
						$lt: dayAfterFiveWeeksAgo,
					},
					type: 'boxesOut',
				},
			},
			{
				$group: {
					_id: null,
					returnRateDenominator: {
						$sum: {
							$add: [
								'$boxCountByContainer.OG-large',
								'$boxCountByContainer.OG-medium',
							],
						},
					},
				},
			},
		]

		const docDatePipeline = [
			{
				$match: {
					$and: [
						{
							wholefoods: true,
							fromWeeklyReport: true,
						},
						{
							timestamp: {
								$lt: docDate,
							},
							type: 'boxesIn',
						},
					],
				},
			},
			{
				$group: {
					_id: null,
					returnRateNumerator: {
						$sum: {
							$add: [
								'$boxCountByContainer.OG-large',
								'$boxCountByContainer.OG-medium',
							],
						},
					},
				},
			},
		]

		const boxesOutData = await transactions.aggregate(fiveWeeksAgoPipeline)

		const boxesInData = await transactions.aggregate(docDatePipeline)

		const returnRateDenominator = boxesOutData[0]
			? boxesOutData[0].returnRateDenominator
			: 0

		const returnRateNumerator = boxesInData[0]
			? boxesInData[0].returnRateNumerator
			: 0

		doc.returnRate =
			returnRateDenominator && returnRateDenominator > 0
				? returnRateNumerator / returnRateDenominator
				: '-'

		// console.log(docDate, returnRateNumerator, returnRateDenominator, doc.returnRate)
	}

	return result
}

export const getWFMDataBySlug = async (slug) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const allData = client.db('caas').collection('whole_foods')
	const data = await allData.findOne({ slug: slug })
	return data
}

export const getWFMLocations = async () => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const locations = client.db('caas').collection('whole_foods')

	const locationData = []
	const resultArray = await locations.find({}, { _id: 0, name: 1, slug: 1 })
	for (const result of resultArray) {
		locationData.push({ name: result.name, slug: result.slug })
	}

	return locationData
}

export const uploadWFMData = async (payload) => {
	const uploadResponse = await realm.currentUser.callFunction(
		'wholefoods/inputData',
		payload
	)

	return uploadResponse
}

export const getWFMEnvImpact = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')

	const boxesOutMatch = {
		...payload.filter,
		type: 'boxesOut',
		wholefoods: true,
		timestamp: { $gte: payload.startDate, $lte: payload.endDate },
	}

	const boxesOutAgg = await transactions.aggregate([
		{ $match: boxesOutMatch },
		{
			$group: {
				_id: '',
				boxCount: { $sum: '$boxCount' },
			},
		},
		{
			$project: {
				_id: 0,
				totalBoxCount: '$boxCount',
			},
		},
	])

	const result = {
		totalBoxesOut: boxesOutAgg[0].totalBoxCount,
	}

	let boxesOutReturnEndDate = new Date(payload.endDate)
	boxesOutReturnEndDate.setDate(boxesOutReturnEndDate.getDate() - 35) // 21 for general admin enviro calc

	const boxesOutReturnMatch = {
		...payload.filter,
		timestamp: { $gte: payload.startDate, $lte: boxesOutReturnEndDate },
		type: 'boxesOut',
		wholefoods: true,
	}

	const boxesOutReturnAgg = await transactions.aggregate([
		{ $match: boxesOutReturnMatch },
		{
			$group: {
				_id: '',
				boxCount: { $sum: '$boxCount' },
			},
		},
		{
			$project: {
				_id: 0,
				totalBoxCount: '$boxCount',
			},
		},
	])

	const boxesInMatch = {
		...payload.filter,
		type: 'boxesIn',
		timestamp: { $gte: payload.startDate, $lte: payload.endDate },
		$or: [
			{ raasReturn: { $exists: false } },
			{ raasReturn: { $ne: true } },
		],
		wholefoods: true,
	}

	const boxesInAgg = await transactions.aggregate([
		{ $match: boxesInMatch },
		{
			$group: {
				_id: '',
				boxCount: { $sum: '$boxCount' },
			},
		},
		{
			$project: {
				_id: 0,
				totalBoxCount: '$boxCount',
			},
		},
	])

	result['totalBoxesIn'] = boxesInAgg[0].totalBoxCount
	result['effectiveReturnRate'] =
		boxesInAgg[0].totalBoxCount / boxesOutReturnAgg[0].totalBoxCount

	return result
}
