import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getOrders,
	getPaymentMethods,
	getNodeByAdminCodeOrSlug,
	updateNode,
	updateUser,
	getLead,
	alertSlack,
	resetNode,
	inviteMerchantUser,
	resetMerchant,
	addChildrenToUser,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { realm } from '../../../helpers/realm'
import { Button, SelectGroup, Input } from '../../../components/Elements'
import { MdClear } from 'react-icons/md'

const MerchantNewLocations = (state) => {
	const {
		user,
		onGetUser,
		onGetNodeBySlugAdmin,
		node,
		onUpdateUser,
		updateUserLoading,
		onGetLead,
		lead,
		onAlertSlack,
		inviteSent,
		onResetMerchant,
		onAddChildLocs,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [loadingAddNode, setLoadingAddNode] = useState(false)
	const [showAddLocation, setShowAddLocation] = useState(false)
	const [showAdminPin, setShowAdminPin] = useState(false)
	const [adminPinCode, setAdminPinCode] = useState('')
	const [showNewLoc, setShowNewLoc] = useState(false)
	const [showSignUp, setShowSignUp] = useState(false)
	const [signUpUrl, setSignUpURL] = useState('')
	const [contacts, setContacts] = useState([])
	const [newLocName, setNewLocName] = useState(null)
	const [newLocAddress, setNewLocAddress] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [showNewLocMessage, setShowNewLocMessage] = useState(false)
	const { nodeSlug } = useParams()

	useEffect(() => {
		onGetUser()
		onResetMerchant()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	useEffect(() => {
		if (node && node.fromSignUpFlow) {
			onGetLead({ slug: nodeSlug })
		}
		if (node && node.contacts) {
			setContacts(node.contacts)
		}
	}, [node])

	useEffect(() => {
		if (lead && !lead.signUpComplete) {
			setShowSignUp(true)
			const pageNumber = lead.lastPage + 1
			setSignUpURL(`/restaurantpartners/sign-up/page-${pageNumber}`)
		}
	}, [lead])

	const changeTheMerchant = (selectedNodeSlug) => {
		onUpdateUser({
			restaurantAdmin: selectedNodeSlug,
		})
		navigate(`/restaurant-admin/account/${selectedNodeSlug}`)
	}

	const addTheMerchant = async () => {
		setLoadingAddNode(true)
		const client = realm.currentUser.mongoClient('RealmService')
		const nodes = client.db('caas').collection('nodes')
		const newNode = await nodes.findOne({
			adminPinCode: adminPinCode.toLowerCase(),
		})

		if (newNode && newNode.parent) {
			onAddChildLocs(newNode.slug)
		} else {
			let currentOtherLocations = []
			if (user.restaurantAdminOtherLocations) {
				currentOtherLocations = user.restaurantAdminOtherLocations
			} else {
				currentOtherLocations.push({
					name: node.name,
					nodeSlug: node.slug,
				})
			}

			if (
				currentOtherLocations.filter((e) => e.nodeSlug === newNode.slug)
					.length > 0
			) {
			} else {
				currentOtherLocations.push({
					name: newNode.name,
					nodeSlug: newNode.slug,
				})
			}
			onUpdateUser({
				restaurantAdminOtherLocations: currentOtherLocations,
			})
		}

		setLoadingAddNode(false)
		setShowAddLocation(false)
	}

	useEffect(() => {
		if (inviteSent) {
			onResetMerchant()
			onGetNodeBySlugAdmin(nodeSlug)
		}
	}, [inviteSent])

	const handleAddressChange = async (newAddress) => {
		if (newAddress.target) {
			setNewLocAddress(newAddress.target.value)
			geocodeAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setNewLocAddress(newAddress.formatted_address)
			geocodeAddress(newAddress.formatted_address)
		}
	}

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	const handleSubmitNewLoc = () => {
		onAlertSlack({
			// channel: "#eng-testing",
			// text:
			//     "<@U05CHR1JENT>, New Location Inquiry: " + "\n" +
			//     newLocName + "\n" + geoCodedAddress + "\n" + ' from ' + (user.firstName || "") + ' ' + (user.lastName || "") + ' (' + (user.phone || "") + ', ' + (user.email || "") + ")"
			channel: '#sales',
			text:
				'<@U02PUDJCWPM> New Location Inquiry: ' +
				'\n' +
				newLocName +
				'\n' +
				geoCodedAddress +
				'\n' +
				' from ' +
				(user.firstName || '') +
				' ' +
				(user.lastName || '') +
				' (' +
				(user.phone || '') +
				', ' +
				(user.email || '') +
				')',
		})
		setShowNewLocMessage(true)
		setTimeout(() => {
			setShowAddLocation(false)
			setShowNewLocMessage(false)
		}, 5000)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		if (node && node.slug === user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/restaurant-admin/home')
						}}
					>
						&#8592; Go to Dashboard Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='min-h-screen w-full'>
						{node ? (
							<div>
								{user.restaurantAdminOtherLocations &&
								user.restaurantAdminOtherLocations.length >
									0 ? (
									<div className='flex flex-row justify-center'>
										<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
											{node.name}'s Account
										</h1>
										{showNodeChoices ? (
											<BsChevronUp
												onClick={() => {
													setShowNodeChoices(false)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										) : (
											<BsChevronDown
												onClick={() => {
													setShowNodeChoices(true)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										)}
									</div>
								) : (
									<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
										{node.name}'s Account
									</h1>
								)}
							</div>
						) : (
							<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
								DeliverZero Dashboard
							</h1>
						)}
						<div className='flex-col flex items-center'>
							{showNodeChoices && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									{user.restaurantAdminOtherLocations &&
									user.restaurantAdminOtherLocations.length >
										1 ? (
										user.restaurantAdminOtherLocations.map(
											(location) => (
												<div
													className='text-sm cursor-pointer my-1 hover:bg-gray-100'
													key={location.nodeSlug}
													onClick={() => {
														changeTheMerchant(
															location.nodeSlug
														)
														setShowNodeChoices(
															false
														)
													}}
												>
													{location.name}
												</div>
											)
										)
									) : user.restaurantAdminOtherLocations &&
									  user.restaurantAdminOtherLocations[0] ? (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											onClick={() => {
												changeTheMerchant(
													location.nodeSlug
												)
												setShowNodeChoices(false)
											}}
										>
											{
												user
													.restaurantAdminOtherLocations[0]
													.name
											}
										</div>
									) : (
										<div></div>
									)}
								</div>
							)}
						</div>

						{/* Add locations */}
						<div className='flex flex-col items-center'>
							<Button
								size='sm'
								className='mb-4 w-[180px] px-10'
								onClick={() => {
									setShowAddLocation(!showAddLocation)
									setShowNewLoc(false)
									setShowAdminPin(false)
								}}
								text={
									!showAddLocation
										? 'Add Another Location'
										: 'Hide Location Form'
								}
							/>
						</div>
						{showAddLocation && (
							<div className='w-[90%] min-w-fit sm:w-fit mx-auto border-2 border-green-600 p-3 sm:p-6 shadow-xl rounded-xl bg-white mb-6'>
								{!showNewLoc && !showAdminPin && (
									<div className='flex flex-row justify-center mb-3'>
										<div className='my-1 w-[300px] mx-auto'>
											<h3>
												Do you already have an Admin Pin
												Code for this location?
											</h3>
											<SelectGroup
												placeholder='Select...'
												options={[
													{
														value: 'yes',
														label: 'Yes',
													},
													{
														value: 'no',
														label: 'No',
													},
												]}
												onChange={(e) => {
													if (
														e.target.value === 'yes'
													) {
														setShowAdminPin(true)
													} else {
														setShowAdminPin(false)
														setShowNewLoc(true)
													}
												}}
											/>
										</div>
									</div>
								)}
								{showAdminPin && (
									<div className='flex flex-row justify-center mb-3'>
										<Input
											type='text'
											label=''
											name='item-search'
											placeholder='Enter Your Admin Pin Code'
											value={adminPinCode}
											className='bg-white border-2 border-gray-300 w-[260px] sm:w-[270px] mr-2'
											onChange={(e) => {
												setAdminPinCode(
													e.target.value.toLowerCase()
												)
											}}
										/>
										{loadingAddNode && updateUserLoading ? (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											/>
										) : (
											<Button
												text='Verify'
												size='sm'
												className='px-4 sm:px-8 py-2'
												onClick={async () => {
													setLoadingAddNode(true)
													await addTheMerchant()
												}}
											/>
										)}
									</div>
								)}
								{showNewLoc && (
									<div className='flex flex-col justify-center mb-3 mx-auto'>
										<div className='my-1 w-full sm:w-[500px] mx-auto'>
											<h3 className='font-header'>
												New Location Intake Form:
											</h3>
										</div>
										<Input
											type='text'
											label=''
											name='item-search'
											placeholder='Enter location name'
											value={newLocName}
											className='bg-white border-2 border-gray-300 w-full sm:w-[500px] mb-3'
											onChange={(e) => {
												setNewLocName(e.target.value)
											}}
										/>
										<Input
											type='text'
											label=''
											name='item-search'
											placeholder='Enter location address'
											autoCompleteAddress={true}
											value={newLocAddress}
											error={addressError}
											onChange={handleAddressChange}
											className='bg-white border-2 border-gray-300 w-full sm:w-[500px] mb-3'
										/>
										{newLocAddress !== '' && (
											<MdClear
												className={`font-semibold absolute left-2 ml-4 top-[83px] transform -translate-y-12 text-green-600 cursor-pointer`}
												onClick={() =>
													setNewLocAddress('')
												}
											/>
										)}
										{loadingAddNode && updateUserLoading ? (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											/>
										) : newLocAddress && newLocName ? (
											<>
												<Button
													text='Submit'
													size='sm'
													className='sm:w-[150px] px-8 py-2 mb-3'
													onClick={() => {
														handleSubmitNewLoc()
													}}
												/>
												{setShowNewLocMessage && (
													<div className=' w-full sm:w-[500px]'>
														Someone from the
														DeliverZero team will
														reach out shortly to get
														your new location set
														up!
													</div>
												)}
											</>
										) : (
											<Button
												text='Submit'
												size='sm'
												color='disabled'
												className='w-[150px] px-8 py-2'
											/>
										)}
									</div>
								)}
							</div>
						)}
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Transactions,
	User,
	AppInfo,
	Orders,
	Nodes,
	Leads,
	Merchant,
}) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	lead: Leads.lead,
	updatingNode: Nodes.updatingNode,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	updateNodeError: Nodes.updateNodeError,
	inviteSent: Merchant.invite,
	loadingMerchant: Merchant.loading,
	merchantError: Merchant.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetPaymentMethods: (payload) => dispatch(getPaymentMethods(payload)),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onUpdateUser: (payload) => dispatch(updateUser(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
	onAlertSlack: (payload) => dispatch(alertSlack(payload)),
	onUpdateNode: (payload) => dispatch(updateNode(payload)),
	onResetNode: () => dispatch(resetNode()),
	onInviteMerchantUser: (payload) => dispatch(inviteMerchantUser(payload)),
	onResetMerchant: () => dispatch(resetMerchant()),
	onAddChildLocs: (payload) => dispatch(addChildrenToUser(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MerchantNewLocations)
