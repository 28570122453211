import { realm } from '../helpers/realm'
import * as Realm from 'realm-web'

export const register = async (form) => {
	const phone = form.phone
	const email = form.email.toLowerCase()
	const password = form.password
	const firstName = form.firstName
	const lastName = form.lastName
	const response = await realm.emailPasswordAuth.registerUser({
		email,
		password,
	})
	const credentials = Realm.Credentials.emailPassword(email, password)
	const user = await realm.logIn(credentials)
	const userDoc = {
		email: email,
		phone: phone,
		firstName: firstName,
		lastName: lastName,
		userId: realm.currentUser.id,
		registrationType: 'email-pw',
		signUpNewsletter: form.signUpNewsletter,
		form: form,
	}
	if (form.restaurantAdmin) {
		userDoc.restaurantAdmin = form.restaurantAdmin
	}
	if (form.officeSlug) {
		userDoc.officeSlug = form.officeSlug
	}
	const authCreateOrUpdateUserDoc =
		await realm.currentUser.functions.authCreateOrUpdateUserDoc(userDoc)
	return response
}

export const replace = async (form) => {
	const email = form.email.toLowerCase()
	const password = form.password
	const phone = form.phone
	const firstName = form.firstName
	const lastName = form.lastName
	const response = await realm.emailPasswordAuth.registerUser({
		email,
		password,
	})
	const credentials = Realm.Credentials.emailPassword(email, password)
	const user = await realm.logIn(credentials)
	const authCreateOrUpdateUserDoc =
		await realm.currentUser.functions.authCreateOrUpdateUserDoc({
			email: email,
			phone: phone,
			firstName: firstName,
			lastName: lastName,
			userId: realm.currentUser.id,
			registrationType: 'email-pw',
			signUpNewsletter: form.signUpNewsletter,
			form: form,
		})
	return response
}

export const login = async (form) => {
	const credentials = Realm.Credentials.emailPassword(
		form.email.toLowerCase(),
		form.password
	)
	const user = await realm.logIn(credentials)
	return user
}

export const logout = async () => {
	try {
		// console.log('in logout func', res)
		const res = await realm.currentUser.logOut()
		return res
	} catch (err) {
		console.error('Failed to log out, ya dingus', err)
	}
}

export const googleLogin = async (res) => {
	const credentials = Realm.Credentials.google(res.tokenId)
	try {
		const user = await realm.logIn(credentials)
		console.assert(user.id === realm.currentUser.id)
		return user
	} catch (err) {
		// console.log(err)
	}
}

export const googleRegister = async (obj) => {
	const googleProfile = obj.googleRes.profileObj
	const credentials = Realm.Credentials.google(obj.googleRes.tokenId)

	try {
		const user = await realm.logIn(credentials)
		// console.log(user)
		const authCreateOrUpdateUserDoc =
			await realm.currentUser.functions.authCreateOrUpdateUserDoc({
				email: googleProfile.email.toLowerCase(),
				phone: obj.phone,
				firstName: googleProfile.givenName,
				lastName: googleProfile.familyName,
				userId: realm.currentUser.id,
				registrationType: 'google',
				signUpNewsletter: obj.signUpNewsletter,
			})
		console.assert(user.id === realm.currentUser.id)
		return user
	} catch (err) {
		// console.log(err)
	}
}

export const signUpNewsletter = async (obj) => {}

export const smsCheckSend = async (phone) => {
	const response = await realm.currentUser.callFunction(
		'twilio/smsCheckSend',
		phone
	)
	return response
}

export const smsCheckVerify = async ({
	smsUserPhone: phone,
	smsUserResponse: code,
}) => {
	const response = await realm.currentUser.callFunction(
		'twilio/smsCheckVerify',
		{ phone: phone, code: code }
	)
	return response
}

export const deleteUser = async (userId) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const users = client.db('caas').collection('users')
	await users.deleteOne({ userId: userId })
	realm.currentUser.logOut().then(() => {})
}

export const connectToClient = async (payload) => {
	// console.log("entered model connectToClient")
	const response = await realm.currentUser.callFunction(
		'oauth/ConnectionHandler',
		payload
	)
	console.log('response from connectToClient', response)
	return response
}

export const sendResetPassword = async (email) => {
	const response = await realm.emailPasswordAuth.sendResetPasswordEmail({
		email,
	})
	// console.log(response)

	return response
}

export const authorizeChownow = async () => {
	const response = await realm.currentUser.callFunction(
		'chownow/authorizationLink'
	)
	// console.log(response)
	return response
}

export const getSquareLink = async (nodeSlug) => {
	const url = await realm.currentUser.callFunction(
		'square/authorizeLocationUrl',nodeSlug
	)
	// console.log(response)
	return url
}
