export const GET_WFM_DATA = 'GET_WFM_DATA'
export const GET_WFM_DATA_FAIL = 'GET_WFM_DATA_FAIL'
export const GET_WFM_DATA_SUCCESS = 'GET_WFM_DATA_SUCCESS'
export const UPLOAD_WFM_DATA = 'UPLOAD_WFM_DATA'
export const UPLOAD_WFM_DATA_FAIL = 'UPLOAD_WFM_DATA_FAIL'
export const UPLOAD_WFM_DATA_SUCCESS = 'UPLOAD_WFM_DATA_SUCCESS'
export const GET_WFM_BY_SLUG = 'GET_WFM_BY_SLUG'
export const GET_WFM_BY_SLUG_FAIL = 'GET_WFM_BY_SLUG_FAIL'
export const GET_WFM_BY_SLUG_SUCCESS = 'GET_WFM_BY_SLUG_SUCCESS'
export const GET_WFM_LOCATIONS = 'GET_WFM_LOCATIONS'
export const GET_WFM_LOCATIONS_FAIL = 'GET_WFM_LOCATIONS_FAIL'
export const GET_WFM_LOCATIONS_SUCCESS = 'GET_WFM_LOCATIONS_SUCCESS'
export const GET_WFM_ENV_IMPACT = 'GET_WFM_ENV_IMPACT'
export const GET_WFM_ENV_IMPACT_SUCCESS = 'GET_WFM_ENV_IMPACT_SUCCESS'
