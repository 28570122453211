import { call, put, takeEvery } from 'redux-saga/effects'
import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	LOGIN_USER_FAIL,
	SOCIAL_LOGIN_GOOGLE,
	SOCIAL_REGISTER_GOOGLE,
} from './actionTypes'
import {
	loginUserSuccess,
	loginUserFail,
	logoutUserFail,
	logoutUserSuccess,
	socialLoginGoogleFail,
	socialLoginGoogleSuccess,
} from './actions'
import { login, logout, googleLogin, googleRegister } from '../../../model/auth'
import { getUser, getUserSuccess } from '../user/actions'
import { realm } from '../../../helpers/realm'

function* loginUser({ payload: { form } }) {
	try {
		const user = yield call(login, form)
		yield put(loginUserSuccess(user))
		yield put(getUserSuccess(Object.assign(realm.currentUser, user)))
	} catch (error) {
		yield put(loginUserFail(error))
	}
}

function* logoutUser({ payload: history }) {
	try {
		const res = yield call(logout, history)
		yield put(logoutUserSuccess(res))
	} catch (error) {
		yield put(logoutUserFail(error))
	}
}

function* watchLoginFail({ payload: error }) {
	return error
}

function* watchLoginSuccess({ payload: user }) {
	yield call(getUser)
	// window.Intercom('boot', {
	//   app_id: process.env.REACT_APP_INTERCOM_ID,
	//   email: user.email,
	// })
}

function* watchLogoutSuccess() {
	// window.Intercom('shutdown')
}

function* loginGoogle({ payload: res }) {
	try {
		const user = yield call(googleLogin, res)
		yield put(socialLoginGoogleSuccess(user))
		yield put(getUserSuccess(Object.assign(realm.currentUser, user)))
	} catch (error) {
		yield put(socialLoginGoogleFail(error))
	}
}

function* registerGoogle({ payload: res }) {
	try {
		const user = yield call(googleRegister, res)
		yield put(socialLoginGoogleSuccess(user))
		yield put(getUserSuccess(Object.assign(realm.currentUser, user)))
	} catch (error) {
		yield put(socialLoginGoogleFail(error))
	}
}

function* authSaga() {
	yield takeEvery(LOGOUT_USER_SUCCESS, watchLogoutSuccess)
	yield takeEvery(LOGIN_USER_SUCCESS, watchLoginSuccess)
	yield takeEvery(LOGIN_USER_FAIL, watchLoginFail)
	yield takeEvery(LOGIN_USER, loginUser)
	yield takeEvery(LOGOUT_USER, logoutUser)
	yield takeEvery(SOCIAL_LOGIN_GOOGLE, loginGoogle)
	yield takeEvery(SOCIAL_REGISTER_GOOGLE, registerGoogle)
}

export default authSaga
