import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	createUberDirectRequest,
	getContainers,
	getUberDirectQuoteDropoff,
	getUberDirectQuoteDropoffNoNode,
	resetSuccess,
} from '../../store/actions'
import { Footer, Header } from '../'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	SelectGroup,
	TextArea,
} from '../Elements'
import { FormGroup } from '../Elements/index'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../model/nodes'
import { MdClear } from 'react-icons/md'

const DropoffWidgetUberdirect = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		error,
		loading,
		uberQuote,
		uberRequest,
		onGetUberDirectQuoteDropoff,
		onGetUberDirectQuoteDropoffNoNode,
		onCreateUberDirectRequest,
		containers,
		onGetContainers,
		onResetSuccess,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [pickupNode, setPickupNode] = useState({})
	const [pickupInstructions, setPickupInstructions] = useState('')
	const [quantity, setQuantity] = useState({})
	const [dropoffDate, setDropoffDate] = useState('')
	const [pickupStartTime, setPickupStartTime] = useState('')
	const [dropoffEndTime, setDropoffEndTime] = useState('')
	const [dropoffNodes, setDropoffNodes] = useState([])
	const [dropoffInstructions, setDropoffInstructions] = useState('')
	const [signoffRequired, setSignoffRequired] = useState(false)
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [showNodeChoicesDropOff, setShowNodeChoicesDropOff] = useState(false)
	const [formError, setFormError] = useState('')
	const [dropType, setDropType] = useState('')
	const [pickupType, setPickupType] = useState('')
	const [pickupNoNode, setPickupNoNode] = useState()
	const [dropoffPhone, setDropoffPhone] = useState(null)
	const [enabledFilter, setEnabledFilter] = useState({
		type: {
			$nin: [NodeTypes.none],
		},
	})
	const [products, setProducts] = useState([])

	const [searchedAddress, setSearchedAddress] = useState('')
	const [searchedPickupAddress, setSearchedPickupAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [geoCodedPickupAddress, setGeoCodedPickupAddress] = useState(null)
	const [geoCodedPickupLat, setGeoCodedPickupLat] = useState(null)
	const [geoCodedPickupLng, setGeoCodedPickupLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [locationName, setLocationName] = useState('')
	const [locationNamesObj, setLocationNamesObj] = useState({})

	useEffect(() => {
		onResetSuccess()
	}, [])

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}
	const geocodePickupAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedPickupAddress(results[0].formatted_address)
					setGeoCodedPickupLat(results[0].geometry.location.lat())
					setGeoCodedPickupLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	const handleAddressChange = async (newAddress) => {
		if (newAddress.target) {
			setSearchedAddress(newAddress.target.value)
			geocodeAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
			geocodeAddress(newAddress.formatted_address)
		}
	}

	const handlePickupAddressChange = async (newAddress) => {
		if (newAddress.target) {
			setSearchedPickupAddress(newAddress.target.value)
			geocodePickupAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedPickupAddress(newAddress.formatted_address)
			geocodePickupAddress(newAddress.formatted_address)
		}
	}

	const handleAddressSubmit = () => {
		setFormError('')
		setAddressError(false)
		if (locationName === '') {
			setFormError('Specify the dropoff location name')
		} else {
			setDropoffNodes(() => {
				let tempArray = []
				if (geoCodedAddress) {
					tempArray.push(geoCodedAddress)
				}
				return tempArray
			})
			setLocationNamesObj(() => {
				let tempObj = {}
				if (geoCodedAddress) {
					tempObj[geoCodedAddress] = locationName
				}
				return tempObj
			})
		}
	}

	const handlePickupAddressSubmit = () => {
		setFormError('')
		setAddressError(false)
		setPickupNoNode(() => {
			let tempArray = []
			if (geoCodedPickupAddress) {
				tempArray.push(geoCodedPickupAddress)
			}
			return tempArray
		})
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		onGetContainers([
			{
				enabled: true,
			},
		])
	}, [onGetContainers])

	useEffect(() => {
		// console.log(filter)
		onGetNodes([filter])
	}, [filter])

	const handleGetQuote = () => {
		if (!pickupNoNode && Object.keys(pickupNode).length === 0) {
			setFormError('Assign a pickup location')
		} else if (dropoffNodes.length === 0) {
			setFormError('Assign at least one dropoff location')
		} else if (
			pickupStartTime === '' ||
			dropoffEndTime === '' ||
			!handleTimeChange({ target: { value: pickupStartTime } }) ||
			!handleTimeChange({ target: { value: dropoffEndTime } })
		) {
			setFormError(
				'Assign time windows in the correct format for pickup and dropoff'
			)
		} else if (Object.keys(quantity).length < dropoffNodes.length) {
			// console.log(Object.keys(quantity).length, dropoffNodes.length)
			setFormError('Specify a quantity of cases per stop')
		} else if (
			pickupInstructions.length > 280 ||
			dropoffInstructions.length > 280
		) {
			setFormError(
				'Instructions are too long, must be less than 280 characters'
			)
		} else {
			setFormError('')
			const userObj = {
				phone: user.phone,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
			}
			const dropoffPayload = {
				dropoffNodes: dropoffNodes,
				dropoffInstructions: dropoffInstructions,
				pickupInstructions: pickupInstructions,
				quantity: quantity,
				pickupStartTime: pickupStartTime,
				dropoffEndTime: dropoffEndTime,
				signoffRequired: signoffRequired,
				dropoffDate: new Date(dropoffDate),
				userObj: userObj,
				products: products,
				addressCoords: {
					adddress: geoCodedAddress,
					lat: geoCodedLat,
					lng: geoCodedLng,
				},
			}

			if (pickupType === 'address') {
				dropoffPayload.pickupNode = pickupNoNode
				dropoffPayload.pickupAddressCoords = {
					adddress: geoCodedPickupAddress,
					lat: geoCodedPickupLat,
					lng: geoCodedPickupLng,
				}
				if (dropoffPhone) {
					dropoffPayload.dropoffPhone = `+1${dropoffPhone}`
				}
			} else {
				dropoffPayload.pickupNode = pickupNode.slug
			}

			// console.log("dropoffPayload", dropoffPayload)

			if (dropType === 'address') {
				onGetUberDirectQuoteDropoffNoNode(dropoffPayload)
			} else {
				onGetUberDirectQuoteDropoff(dropoffPayload)
			}
		}
	}

	// useEffect(() => {
	//     console.log("uberQuote", uberQuote)
	// }, [uberQuote])

	const handleAcceptQuote = () => {
		onCreateUberDirectRequest(uberQuote)
	}

	const changeQuantity = (e, nodeSlug) => {
		if (nodeSlug) {
			setQuantity({
				[nodeSlug]: e.target.value,
			})
		} else {
			setQuantity({
				[locationName]: e.target.value,
			})
		}
	}

	const removeFromDropoff = (nodeSlug) => {
		const tempObj = quantity
		delete tempObj[nodeSlug]
		setQuantity(tempObj)

		var tempArray = dropoffNodes
		tempArray = tempArray.filter((obj) => obj.slug !== nodeSlug)
		setDropoffNodes(tempArray)
	}

	const removeAddressFromDropoff = (address) => {
		const tempObj = products
		const newObj = {}
		for (const index in tempObj) {
			if (
				tempObj.hasOwnProperty(index) &&
				tempObj[index].node !== address
			) {
				newObj[index] = tempObj[index]
			}
		}
		setProducts(newObj)

		let tempArray = dropoffNodes
		tempArray = tempArray.filter((obj) => obj !== address)
		setDropoffNodes(tempArray)

		const tempNamesObj = locationNamesObj
		const newNamesObj = {}
		for (const index in tempNamesObj) {
			if (tempNamesObj.hasOwnProperty(index) && index !== address) {
				newNamesObj[index] = tempNamesObj[index]
			}
		}
		setLocationNamesObj(newNamesObj)
	}

	const updateProduct = (e, containerId, description, nodeSlug) => {
		const updatedProducts = [...products]
		const existingProductIndex = updatedProducts.findIndex(
			(product) => product.id === containerId && product.node === nodeSlug
		)
		let inputValue = parseInt(e.target.value, 10)
		if (inputValue < 0) {
			inputValue = '0'
		}
		if (existingProductIndex !== -1) {
			updatedProducts[existingProductIndex] = {
				...updatedProducts[existingProductIndex],
				name: description,
				quantity: inputValue,
				node: nodeSlug,
			}
		} else {
			updatedProducts.push({
				id: containerId,
				name: description,
				quantity: inputValue,
				node: nodeSlug,
			})
		}

		setProducts(updatedProducts)
	}

	const handleTimeChange = (e) => {
		setFormError('')
		const inputTime = e.target.value
		const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/

		if (!timeRegex.test(inputTime)) {
			setFormError('Invalid time format. Please use HH:MM AM/PM')
			return false
		}
		return true
	}

	const handlePhoneChange = (e) => {
		setFormError('')
		let inputValue = e.target.value.replace(/\D/g, '')
		if (inputValue.length === 10) {
			setDropoffPhone(inputValue)
		} else if (inputValue.length < 10) {
			setFormError('Please enter a 10-digit phone number')
		}
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Request a Dropoff
				</h1>
				<div>
					<div>
						<div className='w-full flex justify-center items-center mb-6'>
							<SelectGroup
								label=''
								placeholder='Select Pickup Type...'
								className='h-12 mt-3 min-w-[300px]'
								onChange={(e) => {
									setPickupType(e.target.value)
									setProducts([])
									setPickupNode()
									setFormError('')
								}}
								options={[
									{ label: 'Node', value: 'node' },
									{ label: 'Address', value: 'address' },
								]}
							/>
						</div>

						{pickupType === 'address' ? (
							<>
								<div
									className={`relative flex w-full items-center md:min-w-[500px] max-w-[720px] ${className} px-4`}
								>
									<FormGroup
										className='h-auto w-full items-center justify-around my-5'
										header
									>
										{loadingUser ? (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										) : (
											<>
												<Input
													placeholder='Enter pickup address'
													className={`relative pl-8 !-mb-4`}
													error={addressError}
													autoCompleteAddress={true}
													value={
														searchedPickupAddress
													}
													onChange={
														handlePickupAddressChange
													}
												/>
											</>
										)}
										{searchedPickupAddress !== '' && (
											<MdClear
												className={`font-semibold absolute left-2 ml-4 top-[83px] transform -translate-y-12 text-green-600 cursor-pointer`}
												onClick={() =>
													setSearchedPickupAddress('')
												}
											/>
										)}
										<Button
											className='mb-6'
											text='Select Address'
											size='sm'
											onClick={handlePickupAddressSubmit}
										/>
									</FormGroup>
								</div>
								{pickupNoNode && (
									<div className='flex flex-row'>
										<div className='mb-3'>
											<span className='font-bold '>
												Selected Pickup Location:
											</span>
											<span className='text-lg font-bold text-green-600'>
												{' '}
												{pickupNoNode[0]}
											</span>
										</div>
										{pickupNoNode && (
											<div
												className='ml-4 font-bold text-lg cursor-pointer text-green-600'
												onClick={() => {
													setPickupNode({})
												}}
											>
												X
											</div>
										)}
									</div>
								)}
							</>
						) : pickupType === 'node' ? (
							<>
								<div className='w-4/5 max-w-[600px] mb-4'>
									<Input
										placeholder='Search and select pickup location'
										className=''
										name='searchInput'
										id='searchInput'
										onChange={(e) => {
											setShowNodeChoices(true)
											setFilter({
												$or: [
													{
														name: {
															$regex: e.target
																.value,
															$options: 'i',
														},
													},
													{
														slug: {
															$regex: e.target
																.value,
															$options: 'i',
														},
													},
												],
											})
										}}
									/>
								</div>
								<div className='flex-col flex items-center'>
									{showNodeChoices && (
										<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
											{nodes.map((node) => (
												<div
													className='text-sm cursor-pointer my-1 hover:bg-gray-100'
													key={node.slug}
													onClick={() => {
														setPickupNode(node)
														setShowNodeChoices(
															false
														)
													}}
												>
													{node.name}
												</div>
											))}
										</div>
									)}
								</div>
								{pickupNode && (
									<div className='flex flex-row'>
										<div className='mb-3'>
											<span className='font-bold '>
												Selected Pickup Location:
											</span>
											<span className='text-lg font-bold text-green-600'>
												{' '}
												{pickupNode.name}
											</span>
										</div>
										{Object.keys(pickupNode).length > 0 && (
											<div
												className='ml-4 font-bold text-lg cursor-pointer text-green-600'
												onClick={() => {
													setPickupNode({})
												}}
											>
												X
											</div>
										)}
									</div>
								)}
							</>
						) : (
							<div></div>
						)}

						<TextArea
							name='Pickup Instructions'
							label='Pickup Instructions'
							placeholder='Include any special pickup instructions here'
							className='!h-20 !mt-1'
							onChange={(e) => {
								setPickupInstructions(e)
							}}
						/>
						<span className='text-sm'>
							Character count:{' '}
							{pickupInstructions.length > 280 ? (
								<span className='text-red-600'>
									{pickupInstructions.length}
								</span>
							) : (
								<span className='text-green-600'>
									{pickupInstructions.length}
								</span>
							)}
						</span>
						{pickupInstructions.length > 280 && (
							<div className='text-red-600 text-lg'>
								!! Pickup instructions cannot exceed 280
								characters !!
							</div>
						)}

						<div className='w-full flex justify-center items-center'>
							<SelectGroup
								label=''
								placeholder='Select Dropoff Type...'
								className='h-12 mt-3 min-w-[300px]'
								onChange={(e) => {
									setDropType(e.target.value)
									setProducts([])
									setDropoffNodes([])
									setFormError('')
								}}
								options={[
									{ label: 'Node', value: 'node' },
									{ label: 'Address', value: 'address' },
								]}
							/>
						</div>

						{dropType === 'address' ? (
							<>
								<div
									className={`relative flex w-full items-center md:min-w-[500px] max-w-[720px] ${className} px-4`}
								>
									<FormGroup
										className='h-auto w-full items-center justify-around my-5'
										header
									>
										{loadingUser ? (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										) : (
											<>
												<Input
													placeholder='Enter dropoff restaurant/company name'
													className={`relative bg-${inputBackgroundColor} pl-8 !-mb-4`}
													value={locationName}
													onChange={(e) => {
														setLocationName(
															e.target.value
														)
													}}
												/>
												<Input
													placeholder='Enter dropoff address'
													className={`relative bg-${inputBackgroundColor} pl-8 !-mb-4`}
													error={addressError}
													autoCompleteAddress={true}
													value={searchedAddress}
													onChange={
														handleAddressChange
													}
												/>
											</>
										)}
										{searchedAddress !== '' && (
											<MdClear
												className={`font-semibold absolute left-2 ml-4 top-[83px] transform -translate-y-12 text-green-600 cursor-pointer`}
												onClick={() =>
													setSearchedAddress('')
												}
											/>
										)}
										<Button
											className='mb-6'
											text='Select Address'
											size='sm'
											onClick={handleAddressSubmit}
										/>
									</FormGroup>
								</div>
								<div className='bg-gray-100 w-full pb-6 mb-3 px-3'>
									<div className='mb-3'>
										<span className='font-bold text-sm '>
											Selected Dropoff Location (limit 1):
										</span>
									</div>
									{dropoffNodes &&
										dropoffNodes.map((address, index) => {
											const addressParts =
												address.split(', ')
											const stateAndZip =
												addressParts[
													addressParts.length - 2
												].split(' ')
											const state = stateAndZip[0]
											const filteredContainers =
												containers.filter(
													(container) => {
														return container.markets.includes(
															state
														)
													}
												)
											return (
												<div
													className='flex flex-col mb-3'
													key={index}
												>
													<div className='flex flex-row'>
														<div className='mt-1 font-bold text-green-600'>
															{address}:
														</div>
														<div
															className='ml-4 font-bold text-sm mt-1 cursor-pointer underline text-red-600'
															onClick={() => {
																removeAddressFromDropoff(
																	address
																)
															}}
														>
															Remove location
														</div>
													</div>
													{/* <div className="flex flex-col ml-[20px]">
                                                {filteredContainers.map((container) => (
                                                    <div className="flex flex-row" key={container._id}>
                                                        <div className="mt-1 mr-2">{container.description}:</div>
                                                        <Input
                                                            className="w-full"
                                                            type="number"
                                                            step={1}
                                                            min={0}
                                                            placeholder="# of containers"
                                                            onChange={(e) => {
                                                                updateProduct(e, container.containerId, container.description, address)
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div> */}
												</div>
											)
										})}
								</div>
							</>
						) : dropType === 'node' ? (
							<>
								<div className='text-sm font-bold mt-4 ml-3'>
									Search by Node:
								</div>
								<div className='w-4/5 max-w-[600px] mb-4'>
									<Input
										placeholder='Search and select dropoff locations'
										className=''
										name='searchInput'
										id='searchInput'
										onChange={(e) => {
											setShowNodeChoicesDropOff(true)
											setFilter({
												$or: [
													{
														name: {
															$regex: e.target
																.value,
															$options: 'i',
														},
													},
													{
														slug: {
															$regex: e.target
																.value,
															$options: 'i',
														},
													},
												],
											})
										}}
									/>
								</div>
								<div className='flex-col flex items-center'>
									{showNodeChoicesDropOff && (
										<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
											<div
												className='cursor-pointer text-green-600'
												onClick={() => {
													setShowNodeChoicesDropOff(
														false
													)
												}}
											>
												{' '}
												Close X
											</div>
											{nodes.map((node) => (
												<div
													className='text-sm cursor-pointer my-1 hover:bg-gray-100'
													key={node.slug}
													onClick={() => {
														var tempArray = []
														const found =
															tempArray.some(
																(obj) =>
																	obj.slug ===
																	node.slug
															)
														if (found) {
														} else {
															tempArray.push(node)
														}
														setDropoffNodes(
															tempArray
														)
														setShowNodeChoicesDropOff(
															false
														)
													}}
												>
													{node.name}
												</div>
											))}
										</div>
									)}
								</div>
								<div className='bg-gray-100 w-full pb-6 mb-3 px-3'>
									<div className='mb-3'>
										<span className='font-bold text-sm'>
											Selected Dropoff Location (limit 1):
										</span>
									</div>
									{dropoffNodes.map((node) => {
										const state = node.state
										const filteredContainers =
											containers.filter((container) => {
												return container.markets.includes(
													state
												)
											})
										return (
											<div
												className='flex flex-col'
												key={node.slug}
											>
												<div className='flex flex-row'>
													<div className='mt-1 font-bold text-green-600'>
														{node.name}:
													</div>
													<div
														className='ml-4 font-bold text-sm mt-1 cursor-pointer underline text-red-600'
														onClick={() => {
															removeFromDropoff(
																node.slug
															)
														}}
													>
														Remove location
													</div>
												</div>
												{/* <div className="flex flex-col ml-[20px]">
                                                        {filteredContainers.map((container) => (
                                                            <div className="flex flex-row" key={container._id}>
                                                                <div className="mt-1 mr-2">{container.description}:</div>
                                                                <Input
                                                                    className="w-full"
                                                                    type="number"
                                                                    step={1}
                                                                    min={0}
                                                                    placeholder="# of containers"
                                                                    onChange={(e) => {
                                                                        updateProduct(e, container.containerId, container.description, node.slug)
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div> */}
											</div>
										)
									})}
								</div>
							</>
						) : (
							<div></div>
						)}

						{dropoffNodes.map((node) => (
							<>
								<div className='mt-3 mb-1'>
									<span className='font-bold '>
										Enter case QTY for driver:
										{/* (only the case QTY below will be visible to the driver, container QTYs are for internal reference only): */}
									</span>
								</div>
								<div
									className='flex flex-row items-center mb-3'
									key={node.slug}
								>
									<div className='mt-1 text-lg font-bold text-green-600'>
										{node.name ? node.name : node}:
									</div>
									<Input
										className='ml-2 w-[100px]'
										type='number'
										step={1}
										placeholder='Qty'
										onChange={(e) => {
											changeQuantity(e, node.slug)
										}}
									/>
									<div className='mt-1 ml-2 text-sm'>
										cases
									</div>
									<div
										className='ml-4 font-bold text-lg mt-1 cursor-pointer text-green-600'
										onClick={() => {
											removeFromDropoff(node.slug)
										}}
									>
										X
									</div>
								</div>
							</>
						))}
					</div>

					<TextArea
						name='Instructions'
						label='Drop Off Instructions'
						placeholder='Include any special delivery instructions here'
						className='!h-20 !mt-1'
						onChange={(e) => {
							setDropoffInstructions(e)
						}}
					/>
					<span className='text-sm'>
						Character count:{' '}
						{dropoffInstructions.length > 280 ? (
							<span className='text-red-600'>
								{dropoffInstructions.length}
							</span>
						) : (
							<span className='text-green-600'>
								{dropoffInstructions.length}
							</span>
						)}
					</span>
					{dropoffInstructions.length > 280 && (
						<div className='text-red-600 text-lg'>
							!! Dropoff instructions cannot exceed 280 characters
							!!
						</div>
					)}

					<div className='my-6'>
						<div className='flex flex-row mb-2'>
							<Input
								type='date'
								label='Date of Pickup:'
								name='returnDate'
								onChange={(e) => {
									setDropoffDate(
										new Date(e.target.value + 'T00:00:00')
									)
								}}
								error=''
								className='bg-white border-2 border-gray-300 text-base min-w-[200px] w-2/3 h-11 mb-3'
								// defaultValue={startDate}
							/>
							<div className='ml-4 flex flex-col'>
								<label className='font-bold'>
									Pickup Start Window:
								</label>
								<input
									type='text'
									value={pickupStartTime}
									onChange={(e) => {
										setPickupStartTime(e.target.value)
										handleTimeChange(e)
									}}
									placeholder='HH:MM AM'
								/>
							</div>
							<div className='ml-4 flex flex-col'>
								<label className='font-bold'>
									Dropoff End Window:
								</label>
								<input
									type='text'
									value={dropoffEndTime}
									onChange={(e) => {
										setDropoffEndTime(e.target.value)
										handleTimeChange(e)
									}}
									placeholder='HH:MM AM'
								/>
							</div>
						</div>
						<div className='font-bold'>
							HEADS UP! Specify the time in Eastern Standard Time.
						</div>
					</div>

					{dropType === 'address' && (
						<div className='flex flex-row mb-2'>
							<Input
								type='tel'
								label='Phone number for dropoff contact (will receive text updates from UberDirect):'
								name='dropoffPhone'
								onChange={handlePhoneChange}
								error=''
								className='bg-white border-2 border-gray-300 text-base min-w-[200px] w-2/3 h-11 mb-3'
							/>
						</div>
					)}

					<div className='mt-2 mb-1'>
						<CheckboxGroup
							label='Is a signature required from the restaurant?'
							name='default-address'
							className=''
							defaultChecked={false}
							onChange={() => {
								setSignoffRequired(!signoffRequired)
							}}
						/>
					</div>
					{formError !== '' && (
						<div className='font-bold text-red-400 my-1'>
							{formError}
						</div>
					)}
					{error && (
						<div className='font-bold text-red-400 my-1'>
							{error.message}
						</div>
					)}
					{loading ? (
						<BounceLoaderCentered container='div' />
					) : (
						<Button
							className='mt-6'
							onClick={handleGetQuote}
							text='Get Dropoff Quote'
						/>
					)}
					{uberQuote && (
						<div>
							<div className='mt-2 text-xl'>
								<span className='font-bold'>
									Dropoff Quote:
								</span>
								$
								{(
									JSON.parse(uberQuote.quote).fee / 100
								).toFixed(2)}
							</div>
							<div className='mt-2 text-xl'>
								<span className='font-bold'>
									Dropoff Quote Id:
								</span>
								{JSON.parse(uberQuote.quote).id}
							</div>
							{loading ? (
								<BounceLoaderCentered container='div' />
							) : (
								<Button
									className='mt-6'
									onClick={() => {
										handleAcceptQuote()
									}}
									text='Request Dropoff'
								/>
							)}
						</div>
					)}
					{uberRequest && (
						<div>
							<div className='mt-2 text-xl mb-6'>
								<span className='font-bold'>Status: </span>
								{uberRequest.status}
							</div>
							{/*    {dropoffRequested.dasher && (<div className="mt-2 text-xl"><span className="font-bold">Dasher Info:</span>{dropoffRequested.dasher.name}, {dropoffRequested.dasher.phone}</div>)}*/}
							{/*<div className="mt-2 text-xl"><span className="font-bold">Tracking URL:</span>{dropoffQuote.trackingUrl}</div>*/}
							<Button
								className='mb-6'
								link='/admin/dropoff'
								text='View Dropoff Statuses'
							/>
						</div>
					)}
				</div>
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, Logistics, Containers }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loading: Logistics.loading,
	uberRequest: Logistics.uberRequest,
	uberQuote: Logistics.uberQuote,
	error: Logistics.error,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetUberDirectQuoteDropoffNoNode: (payload) =>
		dispatch(getUberDirectQuoteDropoffNoNode(payload)),
	onGetUberDirectQuoteDropoff: (payload) =>
		dispatch(getUberDirectQuoteDropoff(payload)),
	onCreateUberDirectRequest: (payload) =>
		dispatch(createUberDirectRequest(payload)),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onResetSuccess: () => dispatch(resetSuccess()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DropoffWidgetUberdirect)
