export const GET_RESTAURANT_PAYMENTS = 'GET_RESTAURANT_PAYMENTS'
export const GET_RESTAURANT_PAYMENTS_SUCCESS = 'GET_RESTAURANT_PAYMENTS_SUCCESS'
export const GET_RESTAURANT_PAYMENTS_FAIL = 'GET_RESTAURANT_PAYMENTS_FAIL'
export const SEND_RESTAURANT_PAYMENTS = 'SEND_RESTAURANT_PAYMENTS'
export const SEND_RESTAURANT_PAYMENTS_SUCCESS =
	'SEND_RESTAURANT_PAYMENTS_SUCCESS'
export const SEND_RESTAURANT_PAYMENTS_FAIL = 'SEND_RESTAURANT_PAYMENTS_FAIL'
export const GET_RESTAURANT_CHARGES = 'GET_RESTAURANT_CHARGES'
export const GET_RESTAURANT_CHARGES_FAIL = 'GET_RESTAURANT_CHARGES_FAIL'
export const GET_RESTAURANT_CHARGES_SUCCESS = 'GET_RESTAURANT_CHARGES_SUCCESS'
export const CHARGE_RESTAURANTS = 'CHARGE_RESTAURANTS'
export const CHARGE_RESTAURANTS_FAIL = 'CHARGE_RESTAURANTS_FAIL'
export const CHARGE_RESTAURANTS_SUCCESS = 'CHARGE_RESTAURANTS_SUCCESS'
