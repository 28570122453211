import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	updateNode,
	getTransactions,
	createTransaction,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import WFMData from '../../../components/Admin/AdminWFM'
import WFMEnvironmental from './AdminWFMEnviro'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import {
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
} from '../../../components/Elements'

const AdminWFM = (state) => {
	const {
		user,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		onCreateTransaction,
		transactionResult,
		onUpdateNode,
		updateNodeCompleted,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	const [loadedNodes, setLoadedNodes] = useState([])
	const [showUpdateBalance, setShowUpdateBalance] = useState('')
	const [newBalance, setNewBalance] = useState(0)
	const [noteForBalance, setNoteForBalance] = useState('')
	const [balanceError, setBalanceError] = useState(false)
	const [filter, setFilter] = useState({
		type: 'whole-foods',
	})

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetNodes([
			{
				type: 'whole-foods',
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		onGetNodes([filter])
	}, [filter, updateNodeCompleted, transactionResult])

	useEffect(() => {
		nodes.sort((a, b) => (a.balance > b.balance ? 1 : -1))
		setLoadedNodes(nodes)
	}, [nodes])

	useEffect(() => {
		const validNegativeIntegerRegex = /^-?\d+$/
		if (newBalance === '' || !validNegativeIntegerRegex.test(newBalance)) {
			setBalanceError(true)
			setNewBalance(0)
		} else {
			const numericBalance = parseInt(newBalance, 10)
			if (numericBalance === 0) {
				setBalanceError(true)
				setNewBalance(0)
			} else {
				setBalanceError(false)
			}
		}
	}, [newBalance])

	const handleUpdateBalance = (nodeSlug, update, note) => {
		onCreateTransaction({
			node: nodeSlug,
			type: 'adminBalanceUpdate',
			user: {
				phone: user.phone,
				id: user.id,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
			},
			boxCount: update.balance,
			timestamp: new Date(),
			note: note,
		})
		setShowUpdateBalance('')
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div className='px-2'>
					<div className='flex container flex-col justify-center items-center text-center w-full mb-4 mx-auto'>
						<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
							Whole Foods Market Dashboard
						</h1>
						{/* <div className='w-4/5 max-w-[600px] mb-4'>
							<Input
								placeholder='Search WFM locations ...'
								className=''
								name='searchInput'
								id='searchInput'
								onChange={(e) => {
									setFilter({
										$or: [
											{
												name: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
											{
												slug: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
										],
									})
								}}
							/>
						</div> */}
					</div>

					<div className='max-w-[80%] max-h-[600px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col mx-[2px] items-center sm:mx-auto rounded-xl pb-2 mt-6 mb-8 scrollbar bg-white bg-opacity-100 w-[80%]'>
						<div className='w-full mx-auto'>
							<div className='flex flex-row sticky top-0 bg-white g-opacity-100 z-10'>
								<div className='mb-1 pt-1 font-header text-center w-1/2 z-50 sticky bg-white left-0 text-xs sm:text-base'>
									Location
								</div>
								<div className='mb-1 pt-1 font-header text-center w-1/4 bg-white text-xs sm:text-base '>
									Inventory Balance
								</div>
								<div className='mb-1 pt-1 font-header text-center w-1/4 text-xs sm:text-base'>
									Store Status
								</div>
							</div>
							{nodes.length > 0 ? (
								<div className='h-full'>
									{loadedNodes.map((node, index) => {
										return (
											<div
												className='w-full h-full even:bg-gray-100 my-0'
												key={index}
											>
												<div className='text-sm flex flex-row items-center my-0 justify-start min-h-[50px] my-0 py-0'>
													<div
														className='font-bold underline text-center cursor-pointer sticky w-1/2 text-xs sm:text-base'
														onClick={() => {
															const url = `/admin/restaurants/${node.slug}`
															navigate(url)
														}}
													>
														<div className='h-full'>
															{node.name}
														</div>
													</div>
													<div className='my-1 text-center w-1/4 flex flex-col items-center'>
														{node.balance}
														{showUpdateBalance ===
															node.slug && (
															<div className='flex flex-col items-center w-full'>
																<div className=' flex mt-6 w-3/5 justify-around'>
																	<div>
																		<Input
																			type='number'
																			label='Add/Subtract inventory'
																			name='balance'
																			className='bg-white border-2 border-gray-300 w-full'
																			onChange={(
																				e
																			) => {
																				setNewBalance(
																					e
																						.target
																						.value
																				)
																			}}
																		/>
																		<div className='text-xs'>
																			enter
																			a
																			negative
																			to
																			subtract
																		</div>
																	</div>
																	<Input
																		type='text'
																		label='note: (optional)'
																		name='note'
																		className='bg-white border-2 border-gray-300 w-full'
																		onChange={(
																			e
																		) =>
																			setNoteForBalance(
																				e
																					.target
																					.value
																			)
																		}
																	/>
																</div>
																{updatingNode ||
																loadingNodes ? (
																	<div className='ml-4'>
																		Loading...
																	</div>
																) : balanceError ||
																  newBalance ===
																		0 ? (
																	<>
																		<Button
																			className='ml-4 mb-4'
																			size='xs'
																			color='disabled'
																			disabled
																			text='SUBMIT NEW BALANCE'
																		/>
																		{balanceError && (
																			<div className='ml-4 text-xs text-red-400'>
																				Please
																				enter
																				a
																				valid
																				number
																			</div>
																		)}
																	</>
																) : (
																	<Button
																		className='ml-4 mb-4'
																		size='xs'
																		text='SUBMIT NEW BALANCE'
																		onClick={() => {
																			// e.preventDefault()
																			handleUpdateBalance(
																				node.slug,
																				{
																					balance:
																						parseInt(
																							newBalance
																						),
																				},
																				noteForBalance
																			)
																		}}
																	/>
																)}
															</div>
														)}
														{showUpdateBalance ===
														node.slug ? (
															<Button
																className='w-min'
																size='xs'
																color='red'
																text='CLOSE'
																onClick={() => {
																	setShowUpdateBalance(
																		''
																	)
																}}
															/>
														) : (
															<Button
																className='w-min'
																size='xs'
																text='UPDATE'
																onClick={() => {
																	setShowUpdateBalance(
																		node.slug
																	)
																}}
															/>
														)}
													</div>
													<div className='my-1 text-center w-1/4 flex flex-col items-center'>
														{node.enabled ? (
															<div>
																<div className='font-bold text-green-400'>
																	<div>
																		Enabled
																	</div>
																</div>
															</div>
														) : (
															<div>
																<div className='font-bold text-red-400'>
																	<div>
																		Disabled
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										)
									})}
								</div>
							) : (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							)}
						</div>
					</div>
					<WFMData />
					<div className='flex justify-center items-center my-6 mx-auto'>
						<Button
							text='View Environmental Impact'
							color='green'
							className='px-2 flex items-center justify-center'
							link='/admin/wholefoods/environmental'
						/>
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, TransactionsCreate }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	updatingNode: Nodes.updatingNode,
	loggedIn: User.loggedIn,
	updateNodeError: Nodes.updateNodeError,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	transactionResult: TransactionsCreate.transactionResult,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onUpdateNode: (updatePayload) => dispatch(updateNode(updatePayload)),
	onCreateTransaction: (transactionPayload) =>
		dispatch(createTransaction(transactionPayload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminWFM)
