import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	getUberDirectQuote,
	getUberDirectRequestById,
	getAllUberDirect,
	updateUberDirect,
	cancelUberDirect,
	createUberDirectRequest,
	getAllPendingPickups,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	TextArea,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'

const PickupHome = (state) => {
	const {
		user,
		onGetUser,
		loading,
		uberRequest,
		uberRequests,
		uberQuote,
		error,
		onGetUberDirectQuote,
		onCreateUberDirectRequest,
		onCancelUberDirect,
		onUpdateUberDirect,
		onGetAllUberDirect,
		onGetUberDirectRequestById,
		updatedRequest,
		cancelledRequest,
		pendingPickup,
		pickupSuccess,
		pickupLoading,
		pickupError,
		pendingPickups,
		onGetAllPendingPickups,
	} = state

	const paginationLimit = 20
	const bounceLoaderColor = '#507f74'
	const [filter, setFilter] = useState({
		type: { $ne: 'adminBalanceUpdate' },
	})
	const [page, setPage] = useState(1)
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	const getPickups = (page) => {
		if (user) {
			onGetAllPendingPickups({
				filter: filter,
				options: {
					sort: {
						timestamp: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const pickupsPaginate = () => {
		getPickups(page)
	}

	useEffect(() => {
		if (user && user.admin && !pickupLoading && !pickupError) {
			// console.log('here')
			pickupsPaginate()
		}
	}, [user, page])

	// const handleRefresh = async () => {
	//     await onGetAllPendingPickups({
	//         filter: filter,
	//         options: {
	//             sort: {
	//                 timestamp: -1,
	//             },
	//             limit: paginationLimit,
	//             skip: (page - 1) * paginationLimit,
	//         },
	//     })
	// }

	const handleCancel = (id) => {
		// console.log(id)
		onCancelUberDirect(id)
	}

	function convertToLocalTime(isoDate) {
		const date = new Date(isoDate)
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			timeZoneName: 'short',
		}
		const localTime = date.toLocaleString(undefined, options)
		return localTime
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='px-12'>
					<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
						Customer Pickups
					</h1>
					<div className='flex flex-col items-center w-full'>
						{loading ? (
							<BounceLoaderCentered container='div' />
						) : (
							<>
								{/* <Button
                                onClick={handleRefresh}
                                text='Refresh Pickup Statuses'
                                size='sm'
                                color="green-outlined"
                                className='w-[200px] mt-4 my-2 sm:ml-2'
                            /> */}
							</>
						)}
					</div>
					{error && (
						<div className='font-bold text-red-400 my-1'>
							{error.message}
						</div>
					)}
					<div className='max-w-[1200px] max-h-[900px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col m-auto rounded-xl px-8 py-2 mt-6 mb-8 scrollbar justify-between items-center'>
						<div className='flex flex-row justify-start items-start'>
							<div className='font-bold w-[160px] font-header px-1'>
								<div>Order ID</div>
							</div>
							<div className='font-bold w-[130px] font-header px-1'>
								<div>Pickup Date</div>
							</div>
							<div className='font-bold w-[360px] font-header overflow-ellipsis px-1'>
								<div>Pickup Location</div>
							</div>
							<div className='font-bold w-[130px] font-header px-1'>
								<div>Status</div>
							</div>
							<div className='font-bold w-[130px] font-header px-1'>
								<div>RLP</div>
							</div>
						</div>
						{pendingPickups &&
						pendingPickups[0] &&
						pendingPickups[0].data &&
						pendingPickups[0].data.length > 0 ? (
							<div className=''>
								{pendingPickups[0].data.map(
									(request, index) => {
										return (
											<div
												className='w-full h-auto py-2 mb-2 whitespace-nowrap border-b border-gray-300'
												key={index}
											>
												<div className='text-sm flex flex-row items-center my-1 justify-start'>
													<div
														className='font-bold underline w-[160px]  whitespace-normal overflow-hidden cursor-pointer'
														onClick={() => {
															const url = `/admin/logistics/pickups/${
																request.orderId ||
																request.user
																	.phone
															}`
															navigate(url)
														}}
													>
														<div className='px-1 overflow-hidden'>
															{request.orderId ||
																request.user
																	.firstName +
																	' ' +
																	request.user
																		.lastName}
														</div>
													</div>
													{/* <div className='w-[25px]'></div> */}
													<div className='w-[130px] overflow-hidden whitespace-nowrap ml-2 px-1'>
														{request.requestTimeEstimated
															? request.requestTimeEstimated
															: JSON.stringify(
																	request.timestamp
															  ).slice(1, 11)}
													</div>
													<div className='w-[360px] overflow-hidden px-1'>
														{request.requestNodeName ||
															request.user
																.pickupAddress}
													</div>
													<div className='w-[130px] font-bold px-1'>
														{request.completed ? (
															<div className='text-green-600'>
																COMPLETED
															</div>
														) : [
																'deliverzero',
																'flot',
																'returnmates',
																'gently',
														  ].includes(
																request.reverseLogisticsPartner
														  ) ? (
															request.transactionAdded ? (
																<div className='text-green-600'>
																	COMPLETED
																</div>
															) : (
																<div className='text-red-400'>
																	PENDING
																</div>
															)
														) : request.status ? (
															request.status ===
															'delivered' ? (
																<div className='text-green-600'>
																	{request.status.toUpperCase()}
																</div>
															) : (
																<div className='text-red-400'>
																	{request.status.toUpperCase()}
																</div>
															)
														) : (
															<div className='text-red-400'>
																PENDING
															</div>
														)}
													</div>
													<div className='w-[130px] px-1'>
														<span>
															{
																request.reverseLogisticsPartner
															}
														</span>
													</div>
												</div>
											</div>
										)
									}
								)}
							</div>
						) : (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
					</div>
					<div className='flex flex-row mb-3'>
						<div
							className='mx-auto underline font-bold mr-6 cursor-pointer'
							onClick={() => {
								if (page > 1) {
									setPage(page - 1)
								}
							}}
						>
							Previous {paginationLimit} pickups
						</div>
						<div
							className='mx-auto underline font-bold cursor-pointer'
							onClick={() => {
								setPage(page + 1)
							}}
						>
							Next {paginationLimit} pickups
						</div>
					</div>
				</div>

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Logistics, Pickups }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loading: Logistics.loading,
	uberRequest: Logistics.uberRequest,
	uberRequests: Logistics.uberRequests,
	uberQuote: Logistics.uberQuote,
	error: Logistics.error,
	updatedRequest: Logistics.updatedRequest,
	cancelledRequest: Logistics.cancelledRequest,
	pendingPickup: Pickups.pickup,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pickupLoading: Pickups.pickupLoading,
	pendingPickups: Pickups.pendingPickups,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetUberDirectQuote: (payload) => dispatch(getUberDirectQuote(payload)),
	onGetUberDirectRequestById: (payload) =>
		dispatch(getUberDirectRequestById(payload)),
	onGetAllUberDirect: (payload) => dispatch(getAllUberDirect(payload)),
	onUpdateUberDirect: (payload) => dispatch(updateUberDirect(payload)),
	onCancelUberDirect: (payload) => dispatch(cancelUberDirect(payload)),
	onCreateUberDirectRequest: (payload) =>
		dispatch(createUberDirectRequest(payload)),
	onGetAllPendingPickups: (payload) =>
		dispatch(getAllPendingPickups(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PickupHome)
