import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAppInfo,
	getOffice,
	runAggregation,
	getOfficeTransactions,
	getOfficeAdmins,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import EnvironmentalImpactCard from '../../components/UserProfile/EnvironmentalImpactCard'
import { BounceLoaderCentered, Button } from '../../components/Elements'
// import { Header } from '../../components'

const OfficeAdminHome = (state) => {
	const {
		onGetUser,
		onGetOffice,
		office,
		user,
		loadingUser,
		onRunAggregation,
		aggregationResult,
		loadingAggregation,
		aggregationError,
		appInfo,
		onGetAppInfo,
		onGetOfficeTransactions,
		transactions,
		loadingTransactions,
		onGetOfficeAdmins,
		loadingOfficeAdmins,
		officeAdmins,
		officeAdminsError,
	} = state

	const paginationLimit = 5
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)

	let navigate = useNavigate()

	useEffect(() => {
		onGetAppInfo()
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.officeSlug) {
			onGetOffice(user.officeSlug)
			onRunAggregation({
				officeSlug: user.officeSlug,
				functionName: 'getOfficeEnvImpact',
			})
			onGetOfficeTransactions({
				filter: {
					type: 'boxesOut',
					'user.officeSlug': { $regex: user.officeSlug },
				},

				options: {
					sort: {
						timestamp: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
			onGetOfficeAdmins(user.officeSlug)
		}
	}, [user])

	// console.log(transactions)

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	// if (aggregationResult && aggregationResult['getOfficeEnvImpact']) {
	//     console.log(aggregationResult['getOfficeEnvImpact'].totalOfficePackagingPrevented)
	// }

	if (user.officeSlug) {
		if (office) {
			return (
				<>
					<Header />
					<div>
						<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
							{office.name}'s Dashboard
						</h1>
						<div className='flex flex-col min-mx-6 mx-auto max-w-[1200px] justify-center'>
							<div className='flex flex-col md:flex-row justify-evenly md:mb-10'>
								<div className='md:w-[45%]'>
									{appInfo &&
										aggregationResult &&
										aggregationResult[
											'getOfficeEnvImpact'
										] && (
											<EnvironmentalImpactCard
												packagingPrevented={
													aggregationResult[
														'getOfficeEnvImpact'
													]
														.totalOfficePackagingPrevented
												}
												environmentalImpact={
													appInfo.environmentalImpact
												}
											/>
										)}
								</div>
								<div className='md:w-[10%]'></div>
								<div className='md:w-[45%] card'>
									<h3
										className={`mt-2 block font-header text-green-400 text-3.5xl`}
									>
										Account Info
									</h3>
									<Button
										text='View and Edit Account'
										className='w-[200px] text-[16px] mb-5'
										size='sm'
										onClick={() => {
											navigate('/office-ordering/account')
										}}
									/>
									{office.locations &&
									Object.keys(office.locations).length > 0 ? (
										<div>
											<div className='flex flex-row font-bold text-green-600'>
												<div className='min-w-[200px] max-w-[200px]'>
													Office
												</div>
												<div className='min-w-[150px] max-w-[150px] '>
													Admin
												</div>
											</div>
											{Object.keys(office.locations).map(
												(location) => {
													return (
														<div className='flex flex-row'>
															<div className='min-w-[200px] max-w-[200px]'>
																{
																	office
																		.locations[
																		location
																	].name
																}
															</div>
															{office.locations[
																location
															].admin && (
																<div className='min-w-[150px] max-w-[150px]'>
																	{
																		office
																			.locations[
																			location
																		].admin
																			.name
																	}
																</div>
															)}
														</div>
													)
												}
											)}
										</div>
									) : (
										<div>
											Add office locations to your
											company's account.
										</div>
									)}
									{/*    Table of location and phone number */}
									{/*    Add addresses */}
									{/*    Other admin users */}
									{/*    Pickup schedule */}
									{/*    Edit building pick up info */}
									{/*    Payment info */}
								</div>
							</div>
							{transactions && (
								<div className='card mb-10'>
									<h3
										className={`mt-2 block font-header text-green-400 text-3.5xl`}
									>
										Orders at a glance
									</h3>
									<Button
										text='View Orders Dashboard'
										className='w-[200px] text-[16px] mb-5'
										size='sm'
										onClick={() => {
											navigate('/office-ordering/orders')
										}}
									/>
									{loadingTransactions ? (
										<BounceLoaderCentered container='div' />
									) : (
										<div>
											<div className='w-[900px] flex flex-row mb-3 text-green-600 text-left font-bold px-10'>
												<div className='font-bold text-lg w-[150px] mr-4'>
													{office.aggregatedOrdering
														? 'Office'
														: 'Employee'}
												</div>
												<div className='font-bold text-lg w-[100px] mr-4'>
													Date
												</div>
												<div className='font-bold text-lg w-[200px] mr-4'>
													Restaurant
												</div>
												<div className='font-bold text-lg w-[100px] mr-4 text-center'>
													Boxes
												</div>
											</div>
											{transactions.map((transaction) => {
												const transactionDate =
													new Date(
														transaction.timestamp
													)
												const transactionDateString =
													transactionDate.toLocaleString(
														'default',
														{
															month: 'short',
															day: 'numeric',
															year: 'numeric',
														}
													)

												return (
													<div
														className='flex flex-row w-full my-2 even:bg-gray-100 px-10 py-1 my-0'
														key={transaction._id}
													>
														<div className='w-[150px] mr-4 pt-2'>
															{
																transaction.user
																	.firstName
															}{' '}
															{
																transaction.user
																	.lastName
															}
														</div>
														<div className='w-[100px] mr-4 pt-2'>
															{
																transactionDateString
															}
														</div>
														<div className='w-[200px] mr-4 pt-2'>
															{transaction.nodeObject ? (
																<div className='w-[200px]'>
																	{
																		transaction
																			.nodeObject[0]
																			.name
																	}
																</div>
															) : (
																<div className='w-[200px]'>
																	{
																		transaction.node
																	}
																</div>
															)}
														</div>
														<div className='w-[100px] mr-4 text-center pt-2'>
															{
																transaction.boxCount
															}
														</div>
													</div>
												)
											})}
										</div>
									)}
								</div>
							)}

							<div className='card mb-10'>
								<h3
									className={`mt-2 block font-header text-green-400 text-3.5xl`}
								>
									Employee activity at a glance
								</h3>
								<Button
									text='View Employee Dashboard'
									className='w-[200px] text-[16px] mb-5'
									size='sm'
									onClick={() => {
										navigate('/office-ordering/employees')
									}}
								/>
							</div>
						</div>
					</div>
					<Footer />
				</>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, OfficeOrdering, AppInfo, Aggregation }) => ({
	office: OfficeOrdering.office,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	appInfo: AppInfo.appInfo,
	aggregationResult: Aggregation.aggregationResult,
	loadingAggregation: Aggregation.runningAggregation,
	aggregationError: Aggregation.aggregationError,
	transactions: OfficeOrdering.transactions,
	loadingTransactions: OfficeOrdering.loadingTransactions,
	loadingOfficeAdmins: OfficeOrdering.loadingOfficeAdmins,
	officeAdmins: OfficeOrdering.officeAdmins,
	officeAdminsError: OfficeOrdering.officeAdminsError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOffice: (officeSlug) => dispatch(getOffice(officeSlug)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onRunAggregation: (payload) => dispatch(runAggregation(payload)),
	onGetOfficeTransactions: (payload) =>
		dispatch(getOfficeTransactions(payload)),
	onGetOfficeAdmins: (payload) => dispatch(getOfficeAdmins(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(OfficeAdminHome)
