import React, { useEffect, useState } from 'react'
import { Header, Footer } from '../../components'
import { Link, useParams } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'
import { connect } from 'react-redux'
import { getNodeBySlug } from '../../store/nodes/actions'
import { BounceLoader } from 'react-spinners'
import { Button } from '../../components/Elements'
import { thirdPartyDict } from '../../helpers/clientInfo'
import { runPentest } from '../../store/pentest/actions'

const Pentest = (props) => {
	const {
		runningPentest,
		pentestResult,
		runPentestError,
		onRunPentest,
		user,
	} = props
	const bounceLoaderColor = '#507f74'
	const { nodeSlug } = useParams()

	useEffect(() => {
		onRunPentest()
	}, [])

	useEffect(() => {
		console.log(user)
	}, [user])

	useEffect(() => {
		console.log(pentestResult)
	}, [pentestResult])

	return (
		<>
			<div>HONK</div>
		</>
	)
}
const mapStateToProps = ({ Pentest, User }) => ({
	runningPentest: Pentest.runningPentest,
	pentestResult: Pentest.pentestResult,
	runPentestError: Pentest.runPentestError,
	user: User.user,
})

const mapDispatchToProps = (dispatch) => ({
	onRunPentest: () => dispatch(runPentest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pentest)
