export const INVITE_MERCHANT_USER = 'INVITE_MERCHANT_USER'
export const INVITE_MERCHANT_USER_FAIL = 'INVITE_MERCHANT_USER_FAIL'
export const INVITE_MERCHANT_USER_SUCCESS = 'INVITE_MERCHANT_USER_SUCCESS'

export const GET_ALL_CHILDREN = 'GET_ALL_CHILDREN'
export const GET_ALL_CHILDREN_FAIL = 'GET_ALL_CHILDREN_FAIL'
export const GET_ALL_CHILDREN_SUCCESS = 'GET_ALL_CHILDREN_SUCCESS'

export const ADD_CHILDREN_TO_USER = 'ADD_CHILDREN_TO_USER'
export const ADD_CHILDREN_TO_USER_FAIL = 'ADD_CHILDREN_TO_USER_FAIL'
export const ADD_CHILDREN_TO_USER_SUCCESS = 'ADD_CHILDREN_TO_USER_SUCCESS'

export const TRACK_USAGE = 'TRACK_USAGE'
export const TRACK_USAGE_FAIL = 'TRACK_USAGE_FAIL'
export const TRACK_USAGE_SUCCESS = 'TRACK_USAGE_SUCCESS'

export const RESET_MERCHANT = 'RESET_MERCHANT'
