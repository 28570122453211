import { realm } from '../helpers/realm'

export const executePentestApi = async () => {
	if (realm.currentUser) {
		const client = realm.currentUser.mongoClient('RealmService')
		console.log('1')
		const testCol = client.db('caas').collection('test_collection')
		console.log('2')
		const test = await testCol.findOne({ name: 'Roshni' })
		console.log('TEST', test)
		return test
	}
	return {}
}
