import { realm } from '../helpers/realm'

export const totalCart = (cartItems) => {
	// console.log('MODEL PAYLOAD TOTAL CART', cartItems)
	let subtotal = 0
	cartItems.forEach((cartItem) => {
		cartItem.modifiers.forEach((mod) => {
			subtotal =
				subtotal + cartItem.quantity * mod.quantity * mod.price.amount
		})
		subtotal = subtotal + cartItem.quantity * cartItem.product.price.amount
	})
	// console.log('subtotal', subtotal)
	return subtotal
}

function arraysEqual(a, b) {
	if (a === b) return true
	if (a == null || b == null) return false
	if (a.length !== b.length) return false

	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false
	}
	return true
}

export const existingCartItem = ({ prevCartItems, nextCartItem }) => {
	// console.log('prev', prevCartItems)
	// console.log('next', nextCartItem)
	if (prevCartItems)
		return prevCartItems.find(
			(cartItem) =>
				cartItem.id === nextCartItem.id &&
				arraysEqual(cartItem.modifiers, nextCartItem.modifiers) &&
				cartItem.note === nextCartItem.note
		)
}

export const handleAddToCart = ({ prevCartItems, nextCartItem }) => {
	const quantityIncrement = 1
	const cartItemExists = existingCartItem({ prevCartItems, nextCartItem })

	if (cartItemExists) {
		return prevCartItems.map((cartItem) =>
			cartItem.id === nextCartItem.id &&
			arraysEqual(cartItem.modifiers, nextCartItem.modifiers) &&
			cartItem.note === nextCartItem.note
				? {
						...cartItem,
						quantity: cartItem.quantity + quantityIncrement,
				  }
				: cartItem
		)
	}
	if (prevCartItems) {
		const newCartItems = [
			...prevCartItems,
			{
				...nextCartItem,
				quantity: quantityIncrement,
			},
		]
		return newCartItems
	} else {
		const newCartItems = [
			{
				...nextCartItem,
				quantity: quantityIncrement,
			},
		]
		return newCartItems
	}
}

export const handleRemoveCartItem = ({ prevCartItems, cartItemToRemove }) => {
	return prevCartItems.filter(
		(item) =>
			!(
				item.id === cartItemToRemove.id &&
				arraysEqual(item.modifiers, cartItemToRemove.modifiers) &&
				item.note === cartItemToRemove.note
			)
	)
}

export const handleReduceCartItem = ({ prevCartItems, cartItemToReduce }) => {
	const existingCartItem = prevCartItems.find(
		(cartItem) =>
			cartItem.id === cartItemToReduce.id &&
			arraysEqual(cartItem.modifiers, cartItemToReduce.modifiers) &&
			cartItem.note === cartItemToReduce.note
	)

	if (existingCartItem.quantity === 1) {
		return prevCartItems.filter(
			(cartItem) =>
				!(
					cartItem.id === existingCartItem.id &&
					arraysEqual(
						cartItem.modifiers,
						existingCartItem.modifiers
					) &&
					cartItem.note === existingCartItem.note
				)
		)
	}

	return prevCartItems.map((cartItem) =>
		cartItem.id === existingCartItem.id &&
		arraysEqual(cartItem.modifiers, existingCartItem.modifiers) &&
		cartItem.note === existingCartItem.note
			? {
					...cartItem,
					quantity: cartItem.quantity - 1,
			  }
			: cartItem
	)
}

export const addCartToUser = async (payload) => {
	const activeCart = payload.activeCart
	const user = payload.user
	const client = realm.currentUser.mongoClient('RealmService')
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const users = client.db('caas').collection('users')
	await users.updateOne(
		{ userId: realm.currentUser.id },
		{ $set: { activeCart: activeCart } }
	)
}

export const getCartNodeBySlug = async (slug) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')
	return nodes.findOne({ slug })
}

export const clearCart = async () => {
	const client = realm.currentUser.mongoClient('RealmService')
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const users = client.db('caas').collection('users')
	await users.updateOne(
		{ userId: realm.currentUser.id },
		{
			$set: {
				'activeCart.nodeSlug': '',
				'activeCart.cartItems': [],
			},
		}
	)
}

export const getCartFromUser = async () => {
	const client = realm.currentUser.mongoClient('RealmService')
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const users = client.db('caas').collection('users')
	const user = await users.findOne({ userId: realm.currentUser.id })

	return user.activeCart
}
