import {
	GET_ORDERS,
	GET_ONE_ORDER,
	GET_ORDERS_SUCCESS,
	GET_ONE_ORDER_SUCCESS,
	GET_ORDERS_FAIL,
	GET_ONE_ORDER_FAIL,
	SUBMIT_ORDER,
	SUBMIT_ORDER_SUCCESS,
	SUBMIT_ORDER_FAIL,
	ADMIN_GET_ONE_ORDER,
	RESET_SUBMIT_ORDER,
} from './actionTypes'

const initialState = {
	orders: [],
	oneOrder: null,
	loading: true,
	getOrdersError: null,
	ordersCount: 0,
	oneOrderError: null,
	submittingOrder: false,
	submittedOrder: null,
	submitOrderError: null,
}

const orders = (state = initialState, action) => {
	switch (action.type) {
		case GET_ORDERS:
			return {
				...state,
				loading: true,
			}
		case GET_ORDERS_SUCCESS:
			return {
				...state,
				loading: false,
				orders:
					action.payload[0].data.length === 0
						? []
						: action.payload[0].data,
				ordersCount: action.payload[0].pagination[0]
					? action.payload[0].pagination[0].total
					: 0,
			}
		case GET_ORDERS_FAIL:
			return {
				...state,
				loading: false,
				getOrdersError: action.payload,
			}
		case GET_ONE_ORDER:
			return {
				...state,
				loading: true,
			}
		case GET_ONE_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				oneOrder: action.payload,
			}
		case GET_ONE_ORDER_FAIL:
			return {
				...state,
				loading: false,
				oneOrderError: action.payload,
			}
		case SUBMIT_ORDER:
			return {
				...state,
				submittingOrder: true,
				submitOrderError: null,
			}
		case SUBMIT_ORDER_SUCCESS:
			return {
				...state,
				submittingOrder: false,
				submittedOrder: action.payload,
			}
		case SUBMIT_ORDER_FAIL:
			return {
				...state,
				submittingOrder: false,
				submitOrderError: action.payload,
			}
		case ADMIN_GET_ONE_ORDER:
			return {
				...state,
				loading: true,
			}
		case RESET_SUBMIT_ORDER:
			return {
				...state,
				submittingOrder: false,
				submittedOrder: null,
				submitOrderError: null,
			}
		default:
			return state
	}
}

export default orders
