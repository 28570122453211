import React from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { AiFillDollarCircle } from 'react-icons/ai'
import { BsShop } from 'react-icons/bs'
import { ImEarth } from 'react-icons/im'
import { WhyDeliverZero, Testimonials } from '../../components'
import { Button } from '../../components/Elements'
import RestaurantForm from './RestaurantForm'
import PlatformPartnersDark from '../../components/Homepage/PlatformPartnersDark'

function RestaurantPartners() {
	return (
		<>
			<Header />
			<div className='bg-yellow-400'>
				<div className='text-center py-5 md:py-8 font-header text-green-600 text-3.5xl sm:text-5xl sm:mb-4 xl:mb-8'>
					For Restaurant Partners
				</div>
			</div>
			<div className='flex flex-col items-center justify-center mt-10 mb-16 w-full'>
				<h3 className='w-[90%] font-header text-center text-green-600 text-3xl sm:text-4xl sm:mb-4'>
					Join the largest returnable reusable packaging system in
					North America
				</h3>
				<h3 className='w-[90%] text-center text-2xl sm:text-2.5xl sm:mb-4 xl:mb-8 lg:mb-6 xl:mb-8'>
					We make reuse easy and accessible to both restaurants and
					their customers
				</h3>
				<div className='flex flex-col sm:flex-row justify-between items-center lg:mt-6 xl:mt-8'>
					<div className='flex justify-center text-center w-4/5 mt-6 lg:mb-12 lg:mt-0 mx-6'>
						<Button
							text='Join DeliverZero'
							color='green'
							size='sm'
							className='flex lg:w-[400px] w-[250px] !text-2xl sm:py-4'
							link='/restaurantpartners/sign-up/page-1'
						/>
					</div>
					<div className='flex justify-center text-center w-4/5 mt-6 lg:mb-12 lg:mt-0 mx-6'>
						<Button
							text='Get more info'
							color='green-outlined'
							size='sm'
							className='flex lg:w-[400px] w-[250px] !text-2xl  !text-2xl px-2 sm:py-4'
							link='/restaurantpartners/getInfo'
						/>
					</div>
				</div>
				<div className='text-green-600 sm:mb-6 mb-3 lg:my-[50px]'>
					<PlatformPartnersDark />
				</div>
				<div className='mb-3 lg:my-[50px]'>
					<h1 className='font-header text-center text-green-600 text-3.5xl sm:text-5xl mb-4 xl:mb-8 lg:mb-6 mt-3 sm:mt-8'>
						How It Works
					</h1>
					<ol>
						<li className='mx-auto w-[70%] sm:w-[90%] max-w-[1200px] text-left text-xl sm:text-[25px] lg:text-[28px] mb-4 xl:mb-8 lg:mb-6'>
							<span className='text-green-600 font-header mr-1'>
								1.
							</span>{' '}
							DeliverZero provides your restaurant with
							returnable, reusable packaging at a cost lower than
							single-use alternatives.
						</li>
						<li className='mx-auto w-[70%] sm:w-[90%] max-w-[1200px] text-left text-xl sm:text-[25px] lg:text-[28px] mb-4 xl:mb-8 lg:mb-6'>
							<span className='text-green-600 font-header mr-1'>
								2.
							</span>{' '}
							Using easy to follow instructions, add the
							DeliverZero menu item to your applicable menus the
							same way you would add any other menu item.
						</li>
						<li className='mx-auto w-[70%] sm:w-[90%] max-w-[1200px] text-left text-xl sm:text-[25px] lg:text-[28px] sm:mb-6 lg:mb-10'>
							<span className='text-green-600 font-header mr-1'>
								3.
							</span>{' '}
							Begin receiving orders and making a positive impact!
						</li>
					</ol>
					<div className='w-full flex flex-col items-center justify-center mt-8 mx-auto'>
						<div className='flex flex-col lg:flex-row justify-center items-center mx-auto'>
							<div className='w-[350px] h-[320px] sm:w-[560px] sm:h-[230px] p-4 rounded-xl shadow-light-grey text-center flex flex-col items-center mb-4 pt-6 mx-4 px-4 flex-shrink-0 bg-white z-10'>
								<h1 className='font-header text-green-600 text-3.5xl'>
									No Extra Effort
								</h1>
								<p className='px-6 text-center text-[20px] leading-7 mb-8'>
									When DeliverZero is on the order ticket,
									your kitchen will pack the order in
									DeliverZero containers and send it out like
									any other order. The entire process is
									seamless for you, your staff, and customers.
								</p>
							</div>
							<div className='w-[350px] h-[320px] sm:w-[560px] sm:h-[230px] p-4 rounded-xl shadow-light-grey text-center flex flex-col items-center mb-4 pt-6 mx-4 px-4 flex-shrink-0 bg-white z-10'>
								<h1 className='font-header text-green-600 text-3.5xl'>
									Cutting Edge Technology
								</h1>
								<p className='px-3 text-center text-[20px] leading-7'>
									Our technology captures all the data we need
									to track container usage, allowing us to
									track your inventory and replenish you as
									needed, and manage customer returns.
								</p>
							</div>
						</div>
					</div>
				</div>
				<h1 className='font-header text-center text-green-600 text-3.5xl sm:text-5xl my-3 sm:my-4 sm:my-8 lg:mt-[100px]'>
					Why DeliverZero?
				</h1>
				<div className='block lg:hidden'>
					<WhyDeliverZero />
				</div>
				<div className='w-full lg:flex flex-col items-center justify-center hidden lg:mb-[100px]'>
					<div className='flex flex-col lg:flex-row'>
						{/* Save Money  */}
						<div className='w-[380px] h-[420px] p-4 rounded-xl shadow-light-grey text-center flex flex-col items-center mb-4 pt-6 mx-4 px-4 flex-shrink-0 bg-white z-10'>
							<h1 className='font-header text-green-600 text-3.5xl'>
								Save Money
							</h1>
							<AiFillDollarCircle className='h-[100px] w-[100px] lg:flex-shrink-0 lg:object-cover lg:rounded-xl fill-green-400 my-3' />
							<p className='px-6 text-center text-[20px] leading-7 mb-8'>
								Restaurants that use DeliverZero save over 40%
								on packaging costs for every order that’s
								fulfilled in our reusable containers.
							</p>
						</div>
						{/* Attract Customers  */}
						<div className='w-[380px] h-[420px] p-4 rounded-xl shadow-light-grey text-center flex flex-col items-center mb-4 pt-6 mx-4 px-4 flex-shrink-0 bg-white z-10'>
							<h1 className='font-header text-green-600 text-3.5xl'>
								Attract Customers
							</h1>
							<BsShop className='h-[100px] w-[100px] lg:flex-shrink-0 lg:object-cover lg:rounded-xl fill-green-400 my-3' />
							<p className='px-3 text-center text-[20px] leading-7'>
								Customers want reusable options. In a recent
								survey, 85.9% of respondents told us they
								ordered from a restaurant they had never tried
								before because of DeliverZero.
							</p>
						</div>
						{/* Fight Waste */}
						<div className='w-[380px] h-[420px] p-4 rounded-xl shadow-light-grey text-center flex flex-col items-center mb-4 pt-6 mx-4 px-4 flex-shrink-0 bg-white z-10'>
							<h1 className='font-header text-green-600 text-3.5xl'>
								Fight Waste
							</h1>
							<ImEarth className='h-[100px] w-[100px] lg:flex-shrink-0 lg:object-cover lg:rounded-xl fill-green-400 my-3' />
							<p className='px-3 text-center text-[20px] leading-7'>
								Single-use packaging litters our planet and
								impacts our climate. Give your diners the only
								truly eco-friendly packaging option that's more
								sustainable and trustworthy than composting and
								recycling.
							</p>
						</div>
					</div>
				</div>
				<div className='block lg:hidden mb-2 lg:my-[50px]'>
					<h1 className='font-header text-center text-green-600 text-3.5xl sm:text-5xl mt-16'>
						Testimonials
					</h1>
					<Testimonials />
				</div>
				<div className='w-full lg:flex flex-col items-center justify-center hidden mt-3 sm:mt-8 lg:my-[50px]'>
					<h1 className='font-header text-center text-green-600 text-3.5xl sm:text-5xl mt-8'>
						Testimonials
					</h1>
					{/* Spiro  */}
					<div className='w-[750px] h-[225px] p-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center items-center mt-12 mb-4 mx-8 flex-shrink-0 z-10 bg-white text-[20px]'>
						<div className='h-full w-full flex flex-col lg:flex-row justify-around p-4'>
							{/* <div className='hidden lg:inline-block text-center m-auto w-[215px] h-full overflow-hidden rounded-xl'>
								<img src='https://i.imgur.com/8MdW9DE.jpg' />
							</div> */}
							<div className='h-full flex flex-col justify-center lg:items-center lg:w-full lg:px-8'>
								<p className=' text-center mb-2 lg:text-start  leading-7'>
									<span className='text-green-600 font-header font-semibold'>
										"
									</span>
									Since day one, DeliverZero has shown they
									care about my business. They’re helping the
									planet, and they’re also helping restaurant
									owners like me save money.
									<span className='text-green-600 font-header font-semibold'>
										"
									</span>
								</p>
								<p className='mt-2 text-green-600 font-header font-semibold italic'>
									Spiro Hiotis, Owner of Athena Mediterranean,
									Brooklyn, NY
								</p>
							</div>
						</div>
					</div>
					{/* Liad  */}
					<div className='w-[750px] h-[225px] p-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center items-center mt-12 mb-4 mx-8 flex-shrink-0 z-10 bg-white text-[20px]'>
						<div className='h-full w-full flex flex-col lg:flex-row-reverse justify-around p-4'>
							{/* <div className='hidden lg:inline-block text-center m-auto w-[215px] h-full overflow-hidden rounded-xl'>
							<img
								src='https://i.imgur.com/8MdW9DE.jpg'
								className=''
							/>
						</div> */}
							<div className='h-full flex flex-col justify-center lg:items-center lg:w-full lg:px-8'>
								<p className=' text-center mb-2 lg:text-start  leading-7'>
									<span className='text-green-600 font-header font-semibold'>
										"
									</span>
									It’s a win-win. We save money because we can
									reuse the same containers, it’s a win for
									the customers, and it’s a win for the
									planet, and it doesn’t take us any extra
									work.
									<span className='text-green-600 font-header font-semibold'>
										"
									</span>
								</p>
								<p className='mt-2 text-green-600 font-header font-semibold italic'>
									Liad Matatyahu, General Manager Cafe
									Mogador, Williamsburg, NY
								</p>
							</div>
						</div>
					</div>
					{/* Christine  */}
					<div className='w-[750px] h-[225px] p-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center items-center mt-12 mb-4 mx-8 flex-shrink-0 z-10 bg-white text-[20px]'>
						<div className='h-full w-full flex flex-col lg:flex-row justify-around p-4'>
							{/* <div className='hidden lg:inline-block text-center m-auto w-[215px] h-full overflow-hidden rounded-xl'>
								<img
									src='https://i.imgur.com/zfSCvO5.jpg'
									className=''
								/>
							</div> */}
							<div className='h-full flex flex-col justify-center lg:items-center lg:w-full lg:px-8'>
								<p className='text-center mb-2 lg:text-start leading-7'>
									<span className='text-green-600 font-header font-semibold'>
										"
									</span>
									{/* I am super passionate about what
								DeliverZero is doing, especially in light of the pandemic and how much restaurant waste has accumulated with the
								increase in to-go [orders].{" "}*/}
									We so enjoy working with DeliverZero and
									having it at our restaurant. We save money
									each time a customer chooses DeliverZero and
									DeliverZero makes it so easy to implement.
									<span className='text-green-600 font-header font-semibold'>
										"
									</span>
								</p>
								<p className='mt-2 text-green-600 font-header font-semibold italic'>
									Christine Ruch, Owner of Fresh Thymes
									Eatery, Boulder, CO
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-col sm:flex-row justify-between mt-3 sm:mt-8'>
					<div className='flex justify-center text-center w-4/5 mt-6 lg:mb-12 lg:mt-0 mx-6'>
						<Button
							text='Join DeliverZero'
							color='green'
							size='sm'
							className='flex lg:w-[400px] w-[250px] !text-2xl sm:py-4'
							link='/restaurantpartners/sign-up/page-1'
						/>
					</div>
					<div className='flex justify-center text-center w-4/5 mt-6 lg:mb-12 lg:mt-0 mx-6'>
						<Button
							text='Get more info'
							color='green-outlined'
							size='sm'
							className='flex lg:w-[400px] w-[250px] !text-2xl sm:py-4'
							link='/restaurantpartners/getInfo'
						/>
					</div>
				</div>
			</div>
			{/* <div className="w-full mt-10">
                <img src="https://i.imgur.com/PlxHdLz.jpg"/>
            </div> */}

			<Footer />
		</>
	)
}

export default RestaurantPartners
