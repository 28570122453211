import React, { useState, useEffect } from 'react'
import { Header, Footer } from '../../components'
import { Button } from '../../components/Elements'

function EnterprisePartners() {
	const [calc, setCalc] = useState(140)
	const [displayedCalc, setDisplayedCalc] = useState(25)
	const [employeeBarValue, setEmployeeBarValue] = useState(25)
	const defaultEmployeeBarValue = 25

	const animateCount = async () => {
		// console.log("animateCount")
		if (displayedCalc < calc) {
			setTimeout(() => {
				setDisplayedCalc(displayedCalc + 1)
			}, 1)
		}
		if (displayedCalc > calc) {
			setTimeout(() => {
				setDisplayedCalc(displayedCalc - 1)
			}, 1)
		}
	}

	function handleEmployeeCount(e) {
		setCalc((e.target.value * 1.1).toFixed(0) * 5)
		setEmployeeBarValue(e.target.value)
	}

	useEffect(() => {
		// console.log("useEffect")
		animateCount()
	}, [displayedCalc, calc])

	// useEffect(() => { console.log(calc) }, [calc])

	return (
		<>
			<Header />
			<div className='bg-yellow-400'>
				<div className='text-center py-5 md:py-10 font-header text-green-600 text-3.5xl sm:text-5xl sm:mb-4 xl:mb-8'>
					DeliverZero at Work
				</div>
			</div>
			<div className='relative'>
				<div
					className='bg-cover h-[8rem] sm:h-[36rem] md:h-[24rem] l:h-[36rem] xl:h-[48rem] bg-center'
					style={{
						backgroundImage:
							'url(https://i.imgur.com/lR9rIRK.jpeg)',
					}}
				></div>
			</div>
			<div className='flex flex-col items-center justify-center w-full'>
				<div className='w-full lg:w-4/5 flex flex-col items-center justify-center'>
					<h1 className='font-header text-green-600 text-3.5xl sm:text-5xl sm:mb-4 xl:mb-8 w-full'>
						Reduce waste at work
					</h1>
					<div className='flex justify-center text-center w-4/5 mb-6 lg:mb-12 lg:mt-0'>
						<a href='#learn-more'>
							<Button
								text='Learn More'
								color='green'
								size='sm'
								className='flex w-[210px]'
							/>
						</a>
					</div>
					<h2 className='text-lg lg:text-xl text-center w-full lg:w-4/5 px-4 mb-4'>
						We make it easy to order delivery in returnable reusable
						containers with serviced return bins and custom programs
						designed to meet your needs.
					</h2>
					<p className='w-4/5 text-center font-light '>
						<span className='text-base font-black'>
							One office with 65 employees saves an average of 350
							containers from the trash every week.{' '}
						</span>
						Every container used reduces the upstream and downstream
						emissions associated with single-use packaging.{` `}
						<a
							target='_blank'
							className='font-semibold underline text-green-600'
							href='https://upstreamsolutions.org/reuse-wins-report'
							rel='noreferrer'
						>
							Learn more about the benefits of reuse.
						</a>
					</p>
				</div>

				<div className='w-full lg:w-4/5 px-4 flex flex-col justify-center items-center mt-8'>
					<div className=' lg:w-[900px] flex flex-col items-center justify-center'>
						<h1 className='text-2xl lg:text-3xl font-header text-green-600 text-center w-full lg:w-4/5 mt-8 mb-6'>
							How much waste can your office reduce?
						</h1>
						<div className='text-sm lg:text-base w-5/6 max-w-[400px] mx-4'>
							<form action='#'>
								<label
									htmlFor='employees'
									className=' text-gray-700 w-full'
								>
									# of Employees
								</label>
								<input
									type='range'
									min='1'
									name='employees'
									id='employees'
									max='500'
									defaultValue={defaultEmployeeBarValue}
									className='w-full h-2 bg-green-400 rounded-full appearance-none'
									onChange={(event) =>
										handleEmployeeCount(event)
									}
								/>
							</form>
							<div>{employeeBarValue} Employees</div>
						</div>
						{/* {calc && */}

						<div className='text-2xl font-header text-green-600 text-center w-4/5 mt-8 mb-6 flex flex-row'>
							<div className='w-full'>
								<p>
									You could save... <span> </span>{' '}
								</p>
								<span className='text-5xl mx-6'>
									{displayedCalc}
								</span>
								<p>Containers per week!</p>
							</div>
						</div>
						{/* }	 */}
					</div>
				</div>

				<h1
					className='text-5xl font-header text-green-600 text-center lg:text-center lg:w-4/5 w-full -mb-20 z-20 h-16 bg-white mt-12'
					id='learn-more'
				>
					Learn more
				</h1>
				<script src='https://static.airtable.com/js/embed/embed_snippet_v1.js'></script>
				<iframe
					className='airtable-embed airtable-dynamic-height'
					src='https://airtable.com/embed/shrns9QTXAEp2m6L2?backgroundColor=gray'
					frameBorder='0'
					onmousewheel=''
					width='100%'
					height='1231'
				></iframe>
			</div>
			<div className='w-full mt-10'>
				<img src='https://i.imgur.com/eG1rwhH.jpg' />
			</div>

			<Footer />
		</>
	)
}

export default EnterprisePartners
