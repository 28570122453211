import {
	GET_FRANCHISEE_CHARGES,
	GET_FRANCHISEE_CHARGES_FAIL,
	GET_FRANCHISEE_CHARGES_SUCCESS,
	GET_FRANCHISEE,
	GET_FRANCHISEE_SUCCESS,
	GET_FRANCHISEE_ERROR,
} from './actionTypes'

const initialState = {
	error: null,
	franchiseeCharges: [],
	loadingCharges: false,
	totalCharges: null,
	fetchingFranchisee: false,
	fetchedFranchisee: false,
	fetchFranchiseeError: null,
}

const franchisee = (state = initialState, action) => {
	switch (action.type) {
		case GET_FRANCHISEE_CHARGES:
			return {
				...state,
				loadingCharges: true,
				totalCharges: null,
			}
		case GET_FRANCHISEE_CHARGES_SUCCESS:
			const franchiseeCharges = action.payload[0]
			return {
				...state,
				loadingCharges: false,
				franchiseeCharges: franchiseeCharges.data,
				error: null,
				totalCharges: action.payload[0].pagination[0]
					? action.payload[0].pagination[0].total
					: 0,
			}
		case GET_FRANCHISEE_CHARGES_FAIL:
			return {
				...state,
				loadingCharges: false,
				error: action.payload,
				totalCharges: null,
			}
		case GET_FRANCHISEE:
			return {
				...state,
				fetchingFranchisee: true,
				fetchedFranchisee: false,
				fetchFranchiseeError: null,
			}
		case GET_FRANCHISEE_SUCCESS:
			return {
				...state,
				fetchingFranchisee: false,
				fetchedFranchisee: action.payload,
				fetchFranchiseeError: null,
			}
		case GET_FRANCHISEE_ERROR:
			return {
				...state,
				fetchingFranchisee: false,
				fetchedFranchisee: false,
				fetchFranchiseeError: action.payload,
			}
		default:
			return state
	}
}

export default franchisee
