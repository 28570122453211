import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getOrders,
	getNodes,
	updateUser,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { Button } from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'
// import { Header } from '../../components'

const AdminPromos = (state) => {
	const { user, loadingUser, onGetUser, onGetNodes, nodes } = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	const [loadedNodes, setLoadedNodes] = useState([])
	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})

	const transactionPaginate = (page) => {
		getTransactions(page * paginationLimit)
	}

	const orderPaginate = (page) => {
		getOrders(page * paginationLimit)
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		// console.log(filter)
		onGetNodes([filter])
	}, [filter])

	useEffect(() => {
		// console.log(nodes.length)
		nodes.sort((a, b) => (a.balance > b.balance ? 1 : -1))
		setLoadedNodes(nodes)
	}, [nodes])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Promos
				</h1>
				<div className='flex flex-col mx-4 sm:mx-0 sm:flex-row m-auto justify-center mb-8'>
					<Button
						link='/admin/promo-create'
						text='Create a Promo Code'
						size='sm'
						className=''
					/>

					{/* need to build */}
					{/* <Button
						link='/admin/returns/create'
						text='View Promos Dashboard'
						size='sm'
						className='my-2 sm:my-0 sm:ml-2'
					/> */}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPromos)
