import {
	GENERATE_GIFT_CARD,
	GENERATE_GIFT_CARD_ERROR,
	GENERATE_GIFT_CARD_SUCCESS,
	GENERATE_PROMO,
	GENERATE_PROMO_ERROR,
	GENERATE_PROMO_SUCCESS,
	VALIDATE_GIFT_CARD,
	VALIDATE_GIFT_CARD_ERROR,
	VALIDATE_GIFT_CARD_SUCCESS,
	VALIDATE_PROMO,
	VALIDATE_PROMO_ERROR,
	VALIDATE_PROMO_SUCCESS,
	UPDATE_GIFT_CARD,
	UPDATE_GIFT_CARD_ERROR,
	UPDATE_GIFT_CARD_SUCCESS,
	UPDATE_PROMO,
	UPDATE_PROMO_ERROR,
	UPDATE_PROMO_SUCCESS,
	GET_GIFT_CARD_CUSTOMER,
	GET_GIFT_CARD_CUSTOMER_ERROR,
	GET_GIFT_CARD_CUSTOMER_SUCCESS,
	GET_PROMO_IDS,
	GET_PROMO_IDS_SUCCESS,
	GET_PROMO_IDS_ERROR,
	RESET_PROMO,
} from './actionTypes'

export const generatePromo = (payload) => ({
	type: GENERATE_PROMO,
	payload: payload,
})

export const generatePromoSuccess = (payload) => ({
	type: GENERATE_PROMO_SUCCESS,
	payload: payload,
})

export const generatePromoError = (payload) => ({
	type: GENERATE_PROMO_ERROR,
	payload: payload,
})

export const validatePromo = (payload) => ({
	type: VALIDATE_PROMO,
	payload: payload,
})

export const validatePromoSuccess = (payload) => ({
	type: VALIDATE_PROMO_SUCCESS,
	payload: payload,
})

export const validatePromoError = (payload) => ({
	type: VALIDATE_PROMO_ERROR,
	payload: payload,
})

export const generateGiftCard = (payload) => ({
	type: GENERATE_GIFT_CARD,
	payload: payload,
})

export const generateGiftCardSuccess = (payload) => ({
	type: GENERATE_GIFT_CARD_SUCCESS,
	payload: payload,
})

export const generateGiftCardError = (payload) => ({
	type: GENERATE_GIFT_CARD_ERROR,
	payload: payload,
})

export const validateGiftCard = (payload) => ({
	type: VALIDATE_GIFT_CARD,
	payload: payload,
})

export const validateGiftCardSuccess = (payload) => ({
	type: VALIDATE_GIFT_CARD_SUCCESS,
	payload: payload,
})

export const validateGiftCardError = (payload) => ({
	type: VALIDATE_GIFT_CARD_ERROR,
	payload: payload,
})

export const updateGiftCard = (payload) => ({
	type: UPDATE_GIFT_CARD,
	payload: payload,
})

export const updateGiftCardSuccess = (payload) => ({
	type: UPDATE_GIFT_CARD_SUCCESS,
	payload: payload,
})

export const updateGiftCardError = (payload) => ({
	type: UPDATE_GIFT_CARD_ERROR,
	payload: payload,
})

export const updatePromo = (payload) => ({
	type: UPDATE_PROMO,
	payload: payload,
})

export const updatePromoSuccess = (payload) => ({
	type: UPDATE_PROMO_SUCCESS,
	payload: payload,
})

export const updatePromoError = (payload) => ({
	type: UPDATE_PROMO_ERROR,
	payload: payload,
})

export const chargeStripeForGiftCard = (payload) => ({
	type: CHARGE_STRIPE_FOR_GIFT_CARD,
	payload: payload,
})

export const chargeStripeForGiftCardSuccess = (payload) => ({
	type: CHARGE_STRIPE_FOR_GIFT_CARD_SUCCESS,
	payload: payload,
})

export const chargeStripeForGiftCardError = (payload) => ({
	type: CHARGE_STRIPE_FOR_GIFT_CARD_ERROR,
	payload: payload,
})

export const getGiftCardCustomer = (payload) => ({
	type: GET_GIFT_CARD_CUSTOMER,
	payload: payload,
})

export const getGiftCardCustomerSuccess = (payload) => ({
	type: GET_GIFT_CARD_CUSTOMER_SUCCESS,
	payload: payload,
})

export const getGiftCardCustomerError = (payload) => ({
	type: GET_GIFT_CARD_CUSTOMER_ERROR,
	payload: payload,
})

export const getPromoIds = () => ({
	type: GET_PROMO_IDS,
})

export const getPromoIdsSuccess = (payload) => ({
	type: GET_PROMO_IDS_SUCCESS,
	payload: payload,
})

export const getPromoIdsError = (payload) => ({
	type: GET_PROMO_IDS_ERROR,
	payload: payload,
})

export const resetPromo = () => {
	return { type: RESET_PROMO }
}
