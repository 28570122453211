import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import {
	Input,
	SelectGroup,
	Badge,
	CheckboxGroup,
	RadioGroup,
	Button,
} from '../Elements'
import { FaSearch, FaAngleDown, FaMapMarkerAlt } from 'react-icons/fa'
import { BsSliders } from 'react-icons/bs'
import { MdClear } from 'react-icons/md'
import useOutsideClick from '../Elements/DetectOutsideClick'
import { thirdPartyDict } from '../../helpers/clientInfo'

const LocationsSearchInputs = (props) => {
	const {
		nameValue,
		onNameQuery,
		handleSortBy,
		activeNodeCuisines,
		alphaCuisines,
		activeClients,
		onSelectQuery,
		onClientFilter,
		onCourierFilter,
		onOpenNowFilter,
		courierFilterChecked,
		openNowFilterChecked,
		selectedClient,
		deliveryOnlyFilterChecked,
		setDeliveryOnlyFilterChecked,
	} = props

	const [currentlyTypedNameValue, setCurrentlyTypedNameValue] = useState('')
	const [sortBy, setSortBy] = useState('')
	const [showFilters, setShowFilters] = useState(false)
	const [showSortBy, setShowSortBy] = useState(false)
	const [userSavedAddress, setUserSavedAddress] = useState()

	const handleSubmitFilter = (e) => {
		onSelectQuery(e)
	}

	const handleClientFilter = (e) => {
		onClientFilter(e)
	}

	const handleCourierFilter = (e) => {
		onCourierFilter(e)
	}

	const handleOpenNowFilter = (e) => {
		onOpenNowFilter(e)
	}

	const handleSortSelect = () => {
		handleSortBy(sortBy)
	}

	const impactRef = useRef(null)

	useOutsideClick(impactRef, () => {
		setShowFilters(false)
	})

	const activeClientsRadios = []
	activeClients.map((client) => {
		activeClientsRadios.push({
			value: client,
			label: thirdPartyDict[client]
				? thirdPartyDict[client].name
				: client.charAt(0).toUpperCase() + client.slice(1),
			checked: client === selectedClient,
		})
	})
	activeClientsRadios.push({
		value: 'none',
		label: 'No Filter',
	})

	return (
		<div className='flex flex-col md:flex-row items-center w-screen h-full px-4 '>
			<div className='w-full h-16 relative items-center justify-between flex'>
				<div className='w-full'>
					<Input
						placeholder='Search by Restaurant or Cuisine'
						className='pl-12 py-2 md:py-4'
						name='nodeName'
						error={false}
						onChange={(e) => {
							setCurrentlyTypedNameValue(e.target.value)
							if (e.target.value.length > 2) {
								handleSubmitFilter(e)
							} else {
								handleSubmitFilter({ target: { value: '' } })
							}
						}}
						onKeyDown={(e) => {
							e.code === 'Enter' ? handleSubmitFilter(e) : null
						}}
						value={nameValue || currentlyTypedNameValue}
					/>
					<FaSearch className='absolute left-0 ml-4 top-1/2 transform -translate-y-1/2 text-green-400 text-2xl' />
				</div>
				<div className='inline-block justify-self-end'>
					<MdClear
						className='text-green-600 text-4xl mx-4 cursor-pointer '
						onClick={() => {
							setCurrentlyTypedNameValue('')
							handleSubmitFilter({ target: { value: '' } })
						}}
					/>
				</div>
			</div>

			<div className='relative w-full md:w-1/4 min-w-[294px]'>
				<div className='flex flex-grow-0 max-w-1/2'>
					<div
						className='flex items-center border-2 rounded-full flex-grow-0 max-w-12 py-2 md:py-4 px-6 cursor-pointer justify-between w-full mr-2'
						onMouseOver={() => setShowFilters(true)}
					>
						<div className='flex justify-between items-center w-full'>
							<div className='flex items-center'>
								<BsSliders className='mr-6' />
								<p className=' mr-16 font-semibold'>Filters </p>
							</div>

							<FaAngleDown className='mr-2 justify-self-end' />
						</div>
					</div>
					{/*<div className="relative flex items-center justify-between ">*/}
					{/*  <div*/}
					{/*    className="flex items-center cursor-pointer md:hidden"*/}
					{/*    onMouseOver={() => setShowSortBy(true)}*/}
					{/*  >*/}
					{/*    Sort*/}
					{/*    <FaAngleDown className="mx-2" />*/}
					{/*  </div>*/}
					{/*  {showSortBy && (*/}
					{/*    <div*/}
					{/*      className="rounded-xl bg-white absolute top-16 right-0 cursor-pointer z-10 min-w-32 shadow-xl p-4"*/}
					{/*      onMouseLeave={() => setShowSortBy(false)}*/}
					{/*    >*/}
					{/*      <div className="flex ">*/}
					{/*        <RadioGroup*/}
					{/*          className="mt-1 sm:mt-0"*/}
					{/*          name="sortBy"*/}
					{/*          horizontal*/}
					{/*          radios={[*/}
					{/*            { value: "distance", label: "Distance", checked: true },*/}
					{/*            { value: "name", label: "Name" }*/}
					{/*          ]}*/}
					{/*          onChange={(sortBy) => {*/}
					{/*            setSortBy(sortBy)*/}
					{/*            handleSortSelect()*/}
					{/*          }}*/}
					{/*        />*/}
					{/*      </div>*/}
					{/*    </div>*/}
					{/*  )}*/}
					{/*</div>*/}
				</div>
				{showFilters && (
					<div
						className='rounded-xl bg-white absolute top-16 cursor-pointer z-40 min-w-32 max-w-[540px] shadow-xl'
						onMouseLeave={() => setShowFilters(false)}
						ref={impactRef}
					>
						<div className='w-full flex justify-end mt-2'>
							<p
								className='text-green-600 text-sm mx-4 cursor-pointer '
								onClick={() => {
									setCurrentlyTypedNameValue('')
									handleSubmitFilter({
										target: { value: '' },
									})
								}}
							>
								Clear Filters
							</p>
						</div>
						<div className='text-sm lg:text-base w-5/6  mx-4 my-4'>
							<form action='#'>
								<SelectGroup
									label='Search by Cuisine'
									placeholder='Select...'
									name='color'
									options={alphaCuisines.map((cuisine) => {
										return {
											label: cuisine,
											value: cuisine,
										}
									})}
									onChange={(e) => {
										handleSubmitFilter(e)
										// console.log(e)
									}}
								/>
								<div className='my-4'>
									<p className='mb-2'>Popular Categories</p>
									{activeNodeCuisines.map((cuisine) => (
										<Badge
											text={`${cuisine}`}
											key={`${cuisine}`}
											className='mx-1 my-1 inline-block'
											onClick={(e) => {
												// console.log(e)
												handleSubmitFilter({
													target: {
														value: e.target
															.textContent,
													},
												})
											}}
										/>
									))}
								</div>

								<div className='mt-4 mb-2'>
									<p>Order Through:</p>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-6'
										name='enabled'
										horizontal
										radios={activeClientsRadios}
										onChange={(client) => {
											handleClientFilter(client)
										}}
									/>
								</div>
								<p>Other Filters:</p>
								<div className='mt-2 mb-4'>
									<CheckboxGroup
										label='Only show restaurants who deliver'
										name='delivery-only'
										defaultChecked={
											deliveryOnlyFilterChecked
										}
										onChange={(check) => {
											setDeliveryOnlyFilterChecked(
												check.target.checked
											)
										}}
									/>
								</div>
								<div className='my-4'>
									<CheckboxGroup
										label='Show restaurants where courier accepts returns'
										name='courier-accepts-returns'
										defaultChecked={courierFilterChecked}
										onChange={(check) => {
											handleCourierFilter(
												check.target.checked
											)
										}}
									/>
								</div>
								<div className='my-4'>
									<CheckboxGroup
										label='Open now'
										name='open-now'
										defaultChecked={openNowFilterChecked}
										onChange={(check) => {
											handleOpenNowFilter(
												check.target.checked
											)
										}}
									/>
								</div>

								{/* <div className="my-4">
                      <label htmlFor="miles" className=" text-gray-700 w-full">
                        Distance
                      </label>
                      <input
                        type="range"
                        min="1"
                        name="miles"
                        id="miles"
                        max="25"
                        defaultValue={defaultDistanceBarValue}
                        className="w-full h-2 bg-green-400 rounded-full appearance-none"
                        onChange={(event) =>
                          handleSelectDistance(event.target.value)
                        }
                      />

                      <div>{distanceBarValue} miles</div>
                    </div> */}
							</form>
						</div>
					</div>
				)}
			</div>
			{/*<div*/}
			{/*	className={`hidden md:mt-0 sm:mt-2 sm:flex sm:items-center sm:justify-center sm:w-4/5 h-10 sm:max-w-[100px] sm:rounded-full sm:bg-green-600`}*/}
			{/*	onClick={handleGPSRequest}*/}
			{/*>*/}
			{/*	<FaMapMarkerAlt*/}
			{/*		className='text-white text-lg'*/}
			{/*		onClick={handleGPSRequest}*/}
			{/*	/>*/}
			{/*</div>*/}
		</div>
	)
}

LocationsSearchInputs.propTypes = {
	addressValue: PropTypes.any,
	changeMapBoolToFalse: PropTypes.func,
	defaultDistanceBarValue: PropTypes.any,
	distanceBarValue: PropTypes.any,
	nameValue: PropTypes.any,
	onAddressSelect: PropTypes.func,
	onNameQuery: PropTypes.func,
	setDistanceBarValue: PropTypes.func,
	setMapBool: PropTypes.func,
}

export default LocationsSearchInputs
