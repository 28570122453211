import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAppInfo,
	updateUser,
	getOfficeByAdminCode,
	resetAdminOffice,
	updateOffice,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '../../components/Elements'

const OfficeAdminVerification = (state) => {
	const {
		onGetOfficeByAdminCode,
		loadingNode,
		office,
		user,
		loadingUser,
		onResetAdminOffice,
		onGetUser,
		updateUserLoading,
		onUpdateUser,
		onUpdateOffice,
		updatingOffice,
		updateOfficeError,
		loadingOffice,
	} = state

	const [adminPinCode, setAdminPinCode] = useState('')
	const [isVerified, setIsVerified] = useState(false)
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
		onResetAdminOffice()
	}, [onGetUser])

	const onVerify = () => {
		onGetOfficeByAdminCode(adminPinCode)
	}

	useEffect(() => {
		if (office && office.adminPinCode === adminPinCode) {
			setIsVerified(true)
		}
	}, [office])

	useEffect(() => {
		if (isVerified) {
			onUpdateUser({
				officeSlug: office.slug,
			})

			let officeAdmins = office.officeAdmins
				? [...office.officeAdmins, user.email]
				: [user.email]
			officeAdmins = [...new Set(officeAdmins)]

			const updateObj = {
				officeAdmins: officeAdmins,
			}

			onUpdateOffice({
				slug: office.slug,
				update: updateObj,
			})
		}
	}, [isVerified])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.officeSlug) {
		navigate('/office-ordering/home')
	} else {
		return (
			<div>
				<Header />
				<h1 className='font-header text-green-600 text-4xl mb-11 mt-14 text-center'>
					Office Admin Verification
				</h1>
				<div className='h-screen-3/4 flex flex-col items-center'>
					<div className='text-center max-w-[500px] mx-auto mb-2'>
						To connect to your DeliverZero office account, enter in
						your office's admin pin code below
					</div>

					<Input
						type='text'
						label=''
						name='item-search'
						placeholder='Enter Your Admin Pin Code'
						value={adminPinCode}
						className='bg-white border-2 border-gray-300 w-[270px]'
						onChange={(e) => {
							setAdminPinCode(e.target.value.toLowerCase())
						}}
					/>
					{loadingOffice && updateUserLoading ? (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					) : (
						<Button
							text='Verify'
							className='px-8 py-2 mt-2'
							onClick={() => {
								onVerify()
							}}
						/>
					)}
				</div>

				<Footer />
			</div>
		)
	}
}

const mapStateToProps = ({ OfficeOrdering, User }) => ({
	loadingOffice: OfficeOrdering.loadingOffice,
	office: OfficeOrdering.office,
	updatingOffice: OfficeOrdering.updatingOffice,
	updateOfficeError: OfficeOrdering.updateOfficeError,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetOfficeByAdminCode: (payload) =>
		dispatch(getOfficeByAdminCode(payload)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onUpdateOffice: (payload) => dispatch(updateOffice(payload)),
	onResetAdminOffice: () => dispatch(resetAdminOffice()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OfficeAdminVerification)
