export const GET_RL_PARTNERS = 'GET_RL_PARTNERS'
export const GET_RL_PARTNERS_SUCCESS = 'GET_RL_PARTNERS_SUCCESS'
export const GET_RL_PARTNERS_FAILURE = 'GET_RL_PARTNERS_FAILURE'
export const GET_RL_PARTNERS_BY_ZIP = 'GET_RL_PARTNERS_BY_ZIP'
export const GET_RL_PARTNERS_BY_ZIP_SUCCESS = 'GET_RL_PARTNERS_BY_ZIP_SUCCESS'
export const GET_RL_PARTNERS_BY_ZIP_FAILURE = 'GET_RL_PARTNERS_BY_ZIP_FAILURE'
export const GET_RL_PARTNERS_BY_POLYGON = 'GET_RL_PARTNERS_BY_POLYGON'
export const GET_RL_PARTNERS_BY_POLYGON_SUCCESS =
	'GET_RL_PARTNERS_BY_POLYGON_SUCCESS'
export const GET_RL_PARTNERS_BY_POLYGON_FAILURE =
	'GET_RL_PARTNERS_BY_POLYGON_FAILURE'
export const GET_RL_PARTNER = 'GET_RL_PARTNER'
export const GET_RL_PARTNER_SUCCESS = 'GET_RL_PARTNER_SUCCESS'
export const GET_RL_PARTNER_FAILURE = 'GET_RL_PARTNER_FAILURE'
export const CANCEL_FIRST_DELIVERY = 'CANCEL_FIRST_DELIVERY'
export const CANCEL_FIRST_DELIVERY_SUCCESS = 'CANCEL_FIRST_DELIVERY_SUCCESS'
export const CANCEL_FIRST_DELIVERY_FAILURE = 'CANCEL_FIRST_DELIVERY_FAILURE'
export const GET_THIRD_PARTY_PARTNERS = 'GET_THIRD_PARTY_PARTNERS'
export const GET_THIRD_PARTY_PARTNERS_SUCCESS =
	'GET_THIRD_PARTY_PARTNERS_SUCCESS'
export const GET_THIRD_PARTY_PARTNERS_FAILURE =
	'GET_THIRD_PARTY_PARTNERS_FAILURE'
