import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	getPickupById,
	cancelUberDirect,
	updateUberDirect,
	getUberDirectRequestById,
	getUberDirectRequestDocById,
	cancelFlotDropoff,
	getProofOfDelivery,
	resetPickups,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	BounceLoaderCentered,
	Button,
	TextArea,
	Input,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'

const ViewOnePickup = (state) => {
	const {
		user,
		onGetUser,
		loading,
		error,
		uberQuote,
		uberRequest,
		onGetUberDirectRequestById,
		onCancelUberDirect,
		onUpdateUberDirect,
		uberRequestDoc,
		onGetUberDirectRequestDocById,
		updatedRequest,
		cancelledRequest,
		proofOfDelivery,
		onGetProofOfDelivery,
		onResetPickups,
		onGetPickupById,
		pendingPickup,
		pendingPickups,
		pickupError,
		pickupLoading,
	} = state

	const { orderId } = useParams()
	// const [tipAmount, setTipAmount] = useState(null)
	const [editPickupNote, setEditPickupNote] = useState(false)
	const [pickupNote, setPickupNote] = useState(null)
	const [editDropoffNote, setEditDropoffNote] = useState(false)
	const [dropoffNote, setDropoffNote] = useState(null)

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	useEffect(() => {
		onResetPickups()
	}, [])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetPickupById(orderId)
	}, [orderId])

	useEffect(() => {
		if (pendingPickup && pendingPickup.uberdirectId) {
			onGetUberDirectRequestDocById(pendingPickup.uberdirectId)
		}
	}, [pendingPickup])

	useEffect(() => {
		if (uberRequestDoc && uberRequestDoc.id) {
			onGetUberDirectRequestById(uberRequestDoc.id)
		}
		if (
			uberRequestDoc &&
			uberRequestDoc.pickup &&
			uberRequestDoc.pickup.notes
		) {
			setPickupNote(uberRequestDoc.pickup.notes)
		}
		if (
			uberRequestDoc &&
			uberRequestDoc.dropoff &&
			uberRequestDoc.dropoff.notes
		) {
			setDropoffNote(uberRequestDoc.dropoff.notes)
		}
	}, [uberRequestDoc, updatedRequest, cancelledRequest])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='w-full h-full'>
					<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
						Pickup Details: {orderId}
					</h1>
					{pickupError && (
						<div className='font-bold text-red-400 my-1'>
							{JSON.stringify(pickupError)}
						</div>
					)}
					{pendingPickup && pendingPickup.length > 1 ? (
						<div className=''>
							{pendingPickup.map((request, index) => {
								return (
									<div
										className='max-w-[90%] shadow-light-grey flex flex-col justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'
										key={index}
									>
										<div className='flex flex-col justify-start items-start p-10'>
											<div className='flex flex-col sm:flex-row'>
												<span className='font-bold font-header mr-3'>
													Order ID:
												</span>
												<span className='font-normal mb-3'>
													{orderId}
												</span>
											</div>
											<div className='flex flex-col sm:flex-row'>
												<span className='font-bold font-header mr-3'>
													Pickup Date:
												</span>
												<span className='font-normal mb-3'>
													{request.dropoffTimeEstimated ||
														JSON.stringify(
															request.timestamp
														).slice(1, 11)}
												</span>
											</div>
											<div className='flex flex-col sm:flex-row'>
												<span className='font-bold font-header mr-3'>
													Pickup Location:
												</span>
												<span className='font-normal mb-3'>
													{request.dropoffNodeName}
												</span>
											</div>
											{uberRequestDoc ? (
												<div></div>
											) : request.flotResponse ? (
												<div className='flex flex-col sm:flex-row'>
													<span className='font-bold font-header mr-3'>
														Status:
													</span>
													{[
														'returned',
														'attempted',
														'cancelled',
													].includes(
														request.status
													) ? (
														<span className='text-red-400 font-normal mb-3'>
															{(
																request.status ||
																''
															).toUpperCase()}
														</span>
													) : (
														<span className='text-green-600 font-normal mb-3'>
															{(
																request.status ||
																''
															).toUpperCase()}
														</span>
													)}
												</div>
											) : (
												<div className='flex flex-col sm:flex-row'>
													<span className='font-bold font-header mr-3'>
														Status:
													</span>
													{request.completed ? (
														<div className='text-green-600'>
															COMPLETED
														</div>
													) : [
															'deliverzero',
															'flot',
															'returnmates',
															'gently',
													  ].includes(
															request.reverseLogisticsPartner
													  ) ? (
														request.transactionAdded ? (
															<div className='text-green-600'>
																COMPLETED
															</div>
														) : (
															<div className='text-red-400'>
																PENDING
															</div>
														)
													) : request.status ? (
														request.status ===
														'delivered' ? (
															<div className='text-green-600'>
																{request.status.toUpperCase()}
															</div>
														) : (
															<div className='text-red-400'>
																{request.status.toUpperCase()}
															</div>
														)
													) : (
														<div className='text-red-400'>
															PENDING
														</div>
													)}
												</div>
											)}
											<div className='flex flex-col sm:flex-row'>
												<span className='font-bold font-header mr-3'>
													Last Updated:
												</span>
												{typeof request.updateTimestamp ===
												'string' ? (
													<span className='font-normal mb-3'>
														{
															request.updateTimestamp
														}
													</span>
												) : (
													<span className='font-normal mb-3'>
														{request.updateTimestamp
															? JSON.stringify(
																	request.updateTimestamp
															  ).slice(1, 11)
															: ''}
													</span>
												)}
											</div>
											<div className='flex flex-col sm:flex-row'>
												<span className='font-bold font-header mr-3'>
													Reverse Logistics Partner:
												</span>
												<span className='font-normal mb-3'>
													{
														request.reverseLogisticsPartner
													}
												</span>
											</div>
											<div className='flex flex-col sm:flex-row'>
												<span className='font-bold font-header mr-3'>
													Containers:
												</span>
												<span className='font-normal mb-3 mr-3'>
													<table className='w-full border border-collapse'>
														<thead>
															<tr className='bg-gray-100'>
																{request &&
																request.items &&
																request
																	.items[0] &&
																request.items[0]
																	.sku ? (
																	<th className='border p-2'>
																		SKU
																	</th>
																) : (
																	<>
																		<th className='border p-2'>
																			ID
																		</th>
																		<th className='border p-2'>
																			Name
																		</th>
																	</>
																)}
																<th className='border p-2'>
																	Quantity
																</th>
															</tr>
														</thead>
														<tbody>
															{request.items ? (
																request.items.map(
																	(
																		item,
																		index
																	) =>
																		item.sku ? (
																			<tr
																				key={
																					index
																				}
																				className={
																					index %
																						2 ===
																					0
																						? 'bg-gray-50'
																						: ''
																				}
																			>
																				<td className='border p-2'>
																					{
																						item.sku
																					}
																				</td>
																				<td className='border p-2'>
																					{
																						item.quantity
																					}
																				</td>
																			</tr>
																		) : (
																			<tr
																				key={
																					index
																				}
																				className={
																					index %
																						2 ===
																					0
																						? 'bg-gray-50'
																						: ''
																				}
																			>
																				<td className='border p-2'>
																					{
																						item.id
																					}
																				</td>
																				<td className='border p-2'>
																					{
																						item.name
																					}
																				</td>
																				<td className='border p-2'>
																					{
																						item.quantity
																					}
																				</td>
																			</tr>
																		)
																)
															) : (
																<div>
																	QTYs by
																	container
																	not
																	specified
																	<br /> Box
																	count:{' '}
																	{
																		request.boxCount
																	}
																</div>
															)}
														</tbody>
													</table>
												</span>
											</div>
											{request.flotResponse && (
												<>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Shipping label url:
														</span>
														<span className='font-normal mb-3'>
															<a
																href={`https://account.flot.delivery/</span>/print_label/${request.tracking_number}?format=thermal`}
																target='_blank'
																className='underline text-green-600'
															>
																link
															</a>
														</span>
													</div>
												</>
											)}
										</div>
										{uberRequestDoc && (
											<div className='max-w-[90%] border-2 border-green-600 flex justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'>
												<div className='flex flex-col justify-start items-start p-10'>
													<div className='font-header mb-6 text-green-600'>
														Uber Direct Info
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Uber Request ID:
														</span>
														<span className='font-normal mb-3'>
															{uberRequestDoc.id}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Status:
														</span>
														{cancelledRequest ? (
															<span className='text-red-400 font-normal mb-3'>
																CANCELLED
															</span>
														) : uberRequestDoc.status !==
														  'completed' ? (
															<span className='text-red-400 font-normal mb-3'>
																{
																	uberRequestDoc.status
																}
															</span>
														) : (
															<span className='text-green-600 font-normal mb-3'>
																COMPLETED
															</span>
														)}
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Created:
														</span>
														<span className='font-normal mb-3'>
															{
																uberRequestDoc.created
															}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Pickup Location:
														</span>
														<span className='font-normal mb-3'>
															{
																uberRequestDoc
																	.pickup.name
															}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Pickup Ready Time:
														</span>
														<span className='font-normal mb-3'>
															{
																uberRequestDoc.pickup_ready
															}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Pickup Deadline:
														</span>
														<span className='font-normal mb-3'>
															{
																uberRequestDoc.pickup_deadline
															}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Pickup Note:
														</span>
														{editPickupNote ? (
															<div>
																<TextArea
																	type='text'
																	value={
																		pickupNote
																	}
																	onChange={(
																		e
																	) => {
																		setPickupNote(
																			e
																		)
																	}}
																/>
																<span className='text-sm'>
																	Character
																	count:{' '}
																	{pickupNote.length >
																	280 ? (
																		<span className='text-red-600'>
																			{
																				pickupNote.length
																			}
																		</span>
																	) : (
																		<span className='text-green-600'>
																			{
																				pickupNote.length
																			}
																		</span>
																	)}
																</span>
																{pickupNote.length >
																	280 && (
																	<div className='text-red-600 text-lg'>
																		!!
																		Pickup
																		instructions
																		cannot
																		exceed
																		280
																		characters
																		!!
																	</div>
																)}
															</div>
														) : (
															<span className='font-normal mb-3'>
																{
																	uberRequestDoc
																		.pickup
																		.notes
																}{' '}
																<span
																	className='text-red-400 underline'
																	onClick={() => {
																		setEditPickupNote(
																			true
																		)
																	}}
																>
																	edit
																</span>
															</span>
														)}
													</div>
													<div className='flex flex-row sm:flex-col'>
														<span className='font-bold font-header mr-3'>
															Pickup Image:
														</span>
														{proofOfDelivery &&
															proofOfDelivery.pickupImageData && (
																<img
																	src={
																		proofOfDelivery.pickupImageData
																	}
																	alt='Pickup'
																	className='w-full h-auto'
																/>
															)}
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Dropoff Location:
														</span>
														<span className='font-normal mb-3'>
															{
																uberRequestDoc
																	.dropoff
																	.name
															}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Dropoff Ready Time:
														</span>
														<span className='font-normal mb-3'>
															{
																uberRequestDoc.dropoff_ready
															}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Dropoff Deadline:
														</span>
														<span className='font-normal mb-3'>
															{
																uberRequestDoc.dropoff_deadline
															}
														</span>
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Dropoff Note:
														</span>
														{editDropoffNote ? (
															<div>
																<TextArea
																	type='text'
																	className='w-full'
																	value={
																		dropoffNote
																	}
																	onChange={(
																		e
																	) => {
																		setDropoffNote(
																			e
																		)
																	}}
																/>
																<span className='text-sm'>
																	Character
																	count:{' '}
																	{dropoffNote.length >
																	280 ? (
																		<span className='text-red-600'>
																			{
																				dropoffNote.length
																			}
																		</span>
																	) : (
																		<span className='text-green-600'>
																			{
																				dropoffNote.length
																			}
																		</span>
																	)}
																</span>
																{dropoffNote.length >
																	280 && (
																	<div className='text-red-600 text-lg'>
																		!!
																		Dropoff
																		instructions
																		cannot
																		exceed
																		280
																		characters
																		!!
																	</div>
																)}
															</div>
														) : (
															<span className='font-normal mb-3'>
																{
																	uberRequestDoc
																		.dropoff
																		.notes
																}{' '}
																<span
																	className='text-red-400 underline'
																	onClick={() => {
																		setEditDropoffNote(
																			true
																		)
																	}}
																>
																	edit
																</span>
															</span>
														)}
													</div>
													<div className='flex flex-row sm:flex-col'>
														<span className='font-bold font-header mr-3'>
															Dropoff Image:
														</span>
														{proofOfDelivery &&
															proofOfDelivery.dropoffImageData && (
																<img
																	src={
																		proofOfDelivery.dropoffImageData
																	}
																	alt='Pickup'
																	className='w-full h-auto'
																/>
															)}
													</div>
													<div className='flex flex-col sm:flex-row'>
														<span className='font-bold font-header mr-3'>
															Tracking:
														</span>
														<span className='font-normal mb-3'>
															<a
																href={
																	uberRequestDoc.tracking_url
																}
																target='_blank'
																className='underline text-green-600'
															>
																link
															</a>
														</span>
													</div>
													{/* <div className='flex flex-col sm:flex-row mb-3 items-center'>
                                                    <span className='font-bold font-header mr-3'>
                                                        Driver tip:
                                                    </span>
                                                    <Input
                                                        className='w-full mr-3'
                                                        type='number'
                                                        value={tipAmount}
                                                        placeholder={
                                                            uberRequestDoc.tip_by_customer
                                                                ? uberRequestDoc.tip_by_customer
                                                                : '$0.00'
                                                        }
                                                        min={0}
                                                        onChange={(e) => {
                                                            setTipAmount(e.target.value)
                                                        }}
                                                    />
                                                </div> */}
													<div className='flex flex-row justify-between items-center'>
														<Button
															text='Cancel'
															size='sm'
															color='red-outlined'
															className='mr-4 block font-semibold relative'
															onClick={() => {
																onCancelUberDirect(
																	uberRequestDoc.id
																)
															}}
														/>
														<Button
															text='Save Changes'
															size='sm'
															color='green'
															className='mr-6 font-semibold relative'
															onClick={() => {
																// console.log("Updating Uber Direct with the following values:");
																// console.log("orderId:", dropoff.uberdirectId);
																// console.log("tipByCustomer:", parseFloat(tipAmount));
																// console.log("pickupNote:", pickupNote);
																// console.log("dropoffNote:", dropoffNote);

																onUpdateUberDirect(
																	{
																		orderId:
																			dropoff.uberdirectId,
																		// tipByCustomer:
																		// 	parseFloat(
																		// 		tipAmount
																		// 	),
																		pickupNote:
																			pickupNote,
																		dropoffNote:
																			dropoffNote,
																	}
																)
															}}
														/>
													</div>

													{!proofOfDelivery && (
														<Button
															text='Fetch Images'
															size='sm'
															color='green-outlined'
															className='my-3 block font-semibold relative'
															onClick={() => {
																onGetProofOfDelivery(
																	uberRequestDoc.id
																)
															}}
														/>
													)}
													{loading && (
														<BounceLoaderCentered container='div' />
													)}
												</div>
											</div>
										)}
									</div>
								)
							})}
						</div>
					) : pendingPickup && pendingPickup[0] ? (
						<div className='max-w-[90%] shadow-light-grey flex flex-col justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'>
							<div className='flex flex-col justify-start items-start p-10'>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Order ID:
									</span>
									<span className='font-normal mb-3'>
										{orderId}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Pickup Date:
									</span>
									<span className='font-normal mb-3'>
										{pendingPickup[0].dropoffTimeEstimated}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Pickup Location:
									</span>
									<span className='font-normal mb-3'>
										{pendingPickup[0].dropoffNodeName}
									</span>
								</div>
								{uberRequestDoc ? (
									<div></div>
								) : pendingPickup[0].flotResponse ? (
									<div className='flex flex-col sm:flex-row'>
										<span className='font-bold font-header mr-3'>
											Status:
										</span>
										{[
											'returned',
											'attempted',
											'cancelled',
										].includes(pendingPickup[0].status) ? (
											<span className='text-red-400 font-normal mb-3'>
												{(
													pendingPickup[0].status ||
													''
												).toUpperCase()}
											</span>
										) : (
											<span className='text-green-600 font-normal mb-3'>
												{(
													pendingPickup[0].status ||
													''
												).toUpperCase()}
											</span>
										)}
									</div>
								) : (
									<div className='flex flex-col sm:flex-row'>
										<span className='font-bold font-header mr-3'>
											Status:
										</span>
										{pendingPickup[0].completed ||
										pendingPickup[0].transactionAdded ? (
											<span className='text-green-600 font-normal mb-3'>
												COMPLETED
											</span>
										) : pendingPickup[0].status ? (
											pendingPickup[0].status ===
											'delivered' ? (
												<div className='text-green-600'>
													{pendingPickup[0].status.toUpperCase()}
												</div>
											) : (
												<div className='text-red-400'>
													{pendingPickup[0].status.toUpperCase()}
												</div>
											)
										) : (
											<div className='text-red-400'>
												PENDING
											</div>
										)}
									</div>
								)}
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Last Updated:
									</span>
									{typeof pendingPickup[0].updateTimestamp ===
									'string' ? (
										<span className='font-normal mb-3'>
											{pendingPickup[0].updateTimestamp}
										</span>
									) : (
										<span className='font-normal mb-3'>
											{pendingPickup[0].updateTimestamp
												? JSON.stringify(
														pendingPickup[0]
															.updateTimestamp
												  ).slice(1, 11)
												: ''}
										</span>
									)}
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Reverse Logistics Partner:
									</span>
									<span className='font-normal mb-3'>
										{
											pendingPickup[0]
												.reverseLogisticsPartner
										}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Containers:
									</span>
									<span className='font-normal mb-3 mr-3'>
										<table className='w-full border border-collapse'>
											<thead>
												<tr className='bg-gray-100'>
													{pendingPickup[0] &&
													pendingPickup[0].items &&
													pendingPickup[0].items[0] &&
													pendingPickup[0].items[0]
														.sku ? (
														<th className='border p-2'>
															SKU
														</th>
													) : (
														<>
															<th className='border p-2'>
																ID
															</th>
															<th className='border p-2'>
																Name
															</th>
														</>
													)}
													<th className='border p-2'>
														Quantity
													</th>
												</tr>
											</thead>
											<tbody>
												{pendingPickup[0].items ? (
													pendingPickup[0].items.map(
														(item, index) =>
															item.sku ? (
																<tr
																	key={index}
																	className={
																		index %
																			2 ===
																		0
																			? 'bg-gray-50'
																			: ''
																	}
																>
																	<td className='border p-2'>
																		{
																			item.sku
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.quantity
																		}
																	</td>
																</tr>
															) : (
																<tr
																	key={index}
																	className={
																		index %
																			2 ===
																		0
																			? 'bg-gray-50'
																			: ''
																	}
																>
																	<td className='border p-2'>
																		{
																			item.id
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.name
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.quantity
																		}
																	</td>
																</tr>
															)
													)
												) : (
													<div>
														QTYs by container not
														specified
														<br /> Box count:{' '}
														{
															pendingPickup[0]
																.boxCount
														}
													</div>
												)}
											</tbody>
										</table>
									</span>
								</div>
								{pendingPickup[0].flotResponse && (
									<>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Shipping label url:
											</span>
											<span className='font-normal mb-3'>
												<a
													href={`https://account.flot.delivery/</span>/print_label/${pendingPickup[0].tracking_number}?format=thermal`}
													target='_blank'
													className='underline text-green-600'
												>
													link
												</a>
											</span>
										</div>
									</>
								)}
							</div>
							{uberRequestDoc && (
								<div className='max-w-[90%] border-2 border-green-600 flex justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'>
									<div className='flex flex-col justify-start items-start p-10'>
										<div className='font-header mb-6 text-green-600'>
											Uber Direct Info
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Uber Request ID:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.id}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Status:
											</span>
											{cancelledRequest ? (
												<span className='text-red-400 font-normal mb-3'>
													CANCELLED
												</span>
											) : uberRequestDoc.status !==
											  'completed' ? (
												<span className='text-red-400 font-normal mb-3'>
													{uberRequestDoc.status}
												</span>
											) : (
												<span className='text-green-600 font-normal mb-3'>
													COMPLETED
												</span>
											)}
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Created:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.created}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Location:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.pickup.name}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Ready Time:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.pickup_ready}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Deadline:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.pickup_deadline}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Note:
											</span>
											{editPickupNote ? (
												<div>
													<TextArea
														type='text'
														value={pickupNote}
														onChange={(e) => {
															setPickupNote(e)
														}}
													/>
													<span className='text-sm'>
														Character count:{' '}
														{pickupNote.length >
														280 ? (
															<span className='text-red-600'>
																{
																	pickupNote.length
																}
															</span>
														) : (
															<span className='text-green-600'>
																{
																	pickupNote.length
																}
															</span>
														)}
													</span>
													{pickupNote.length >
														280 && (
														<div className='text-red-600 text-lg'>
															!! Pickup
															instructions cannot
															exceed 280
															characters !!
														</div>
													)}
												</div>
											) : (
												<span className='font-normal mb-3'>
													{
														uberRequestDoc.pickup
															.notes
													}{' '}
													<span
														className='text-red-400 underline'
														onClick={() => {
															setEditPickupNote(
																true
															)
														}}
													>
														edit
													</span>
												</span>
											)}
										</div>
										<div className='flex flex-row sm:flex-col'>
											<span className='font-bold font-header mr-3'>
												Pickup Image:
											</span>
											{proofOfDelivery &&
												proofOfDelivery.pickupImageData && (
													<img
														src={
															proofOfDelivery.pickupImageData
														}
														alt='Pickup'
														className='w-full h-auto'
													/>
												)}
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Location:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.dropoff.name}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Ready Time:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.dropoff_ready}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Deadline:
											</span>
											<span className='font-normal mb-3'>
												{
													uberRequestDoc.dropoff_deadline
												}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Note:
											</span>
											{editDropoffNote ? (
												<div>
													<TextArea
														type='text'
														className='w-full'
														value={dropoffNote}
														onChange={(e) => {
															setDropoffNote(e)
														}}
													/>
													<span className='text-sm'>
														Character count:{' '}
														{dropoffNote.length >
														280 ? (
															<span className='text-red-600'>
																{
																	dropoffNote.length
																}
															</span>
														) : (
															<span className='text-green-600'>
																{
																	dropoffNote.length
																}
															</span>
														)}
													</span>
													{dropoffNote.length >
														280 && (
														<div className='text-red-600 text-lg'>
															!! Dropoff
															instructions cannot
															exceed 280
															characters !!
														</div>
													)}
												</div>
											) : (
												<span className='font-normal mb-3'>
													{
														uberRequestDoc.dropoff
															.notes
													}{' '}
													<span
														className='text-red-400 underline'
														onClick={() => {
															setEditDropoffNote(
																true
															)
														}}
													>
														edit
													</span>
												</span>
											)}
										</div>
										<div className='flex flex-row sm:flex-col'>
											<span className='font-bold font-header mr-3'>
												Dropoff Image:
											</span>
											{proofOfDelivery &&
												proofOfDelivery.dropoffImageData && (
													<img
														src={
															proofOfDelivery.dropoffImageData
														}
														alt='Pickup'
														className='w-full h-auto'
													/>
												)}
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Tracking:
											</span>
											<span className='font-normal mb-3'>
												<a
													href={
														uberRequestDoc.tracking_url
													}
													target='_blank'
													className='underline text-green-600'
												>
													link
												</a>
											</span>
										</div>
										{/* <div className='flex flex-col sm:flex-row mb-3 items-center'>
                                                <span className='font-bold font-header mr-3'>
                                                    Driver tip:
                                                </span>
                                                <Input
                                                    className='w-full mr-3'
                                                    type='number'
                                                    value={tipAmount}
                                                    placeholder={
                                                        uberRequestDoc.tip_by_customer
                                                            ? uberRequestDoc.tip_by_customer
                                                            : '$0.00'
                                                    }
                                                    min={0}
                                                    onChange={(e) => {
                                                        setTipAmount(e.target.value)
                                                    }}
                                                />
                                            </div> */}
										<div className='flex flex-row justify-between items-center'>
											<Button
												text='Cancel'
												size='sm'
												color='red-outlined'
												className='mr-4 block font-semibold relative'
												onClick={() => {
													onCancelUberDirect(
														uberRequestDoc.id
													)
												}}
											/>
											<Button
												text='Save Changes'
												size='sm'
												color='green'
												className='mr-6 font-semibold relative'
												onClick={() => {
													// console.log("Updating Uber Direct with the following values:");
													// console.log("orderId:", dropoff.uberdirectId);
													// console.log("tipByCustomer:", parseFloat(tipAmount));
													// console.log("pickupNote:", pickupNote);
													// console.log("dropoffNote:", dropoffNote);

													onUpdateUberDirect({
														orderId:
															dropoff.uberdirectId,
														// tipByCustomer:
														// 	parseFloat(
														// 		tipAmount
														// 	),
														pickupNote: pickupNote,
														dropoffNote:
															dropoffNote,
													})
												}}
											/>
										</div>

										{!proofOfDelivery && (
											<Button
												text='Fetch Images'
												size='sm'
												color='green-outlined'
												className='my-3 block font-semibold relative'
												onClick={() => {
													onGetProofOfDelivery(
														uberRequestDoc.id
													)
												}}
											/>
										)}
										{loading && (
											<BounceLoaderCentered container='div' />
										)}
									</div>
								</div>
							)}
						</div>
					) : pendingPickup ? (
						<div className='max-w-[90%] shadow-light-grey flex flex-col justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'>
							<div className='flex flex-col justify-start items-start p-10'>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Order ID:
									</span>
									<span className='font-normal mb-3'>
										{orderId}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Pickup Date:
									</span>
									<span className='font-normal mb-3'>
										{pendingPickup.timestamp
											? JSON.stringify(
													pendingPickup.timestamp
											  ).slice(1, 11)
											: ''}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Pickup Location:
									</span>
									<span className='font-normal mb-3'>
										{pendingPickup.user.pickupAddress}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Status:
									</span>
									{pendingPickup.completed ||
									pendingPickup.transactionAdded ? (
										<span className='text-green-600 font-normal mb-3'>
											COMPLETED
										</span>
									) : pendingPickup.status ? (
										pendingPickup.status === 'delivered' ? (
											<div className='text-green-600'>
												{pendingPickup.status.toUpperCase()}
											</div>
										) : (
											<div className='text-red-400'>
												{pendingPickup.status.toUpperCase()}
											</div>
										)
									) : (
										<div className='text-red-400'>
											PENDING
										</div>
									)}
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Last Updated:
									</span>
									{typeof pendingPickup.updateTimestamp ===
									'string' ? (
										<span className='font-normal mb-3'>
											{pendingPickup.updateTimestamp}
										</span>
									) : (
										<span className='font-normal mb-3'>
											{pendingPickup.updateTimestamp
												? JSON.stringify(
														pendingPickup.updateTimestamp
												  ).slice(1, 11)
												: ''}
										</span>
									)}
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Reverse Logistics Partner:
									</span>
									<span className='font-normal mb-3'>
										{pendingPickup.reverseLogisticsPartner}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Containers:
									</span>
									<span className='font-normal mb-3 mr-3'>
										<table className='w-full border border-collapse'>
											<thead>
												<tr className='bg-gray-100'>
													{pendingPickup &&
													pendingPickup.items &&
													pendingPickup.items[0] &&
													pendingPickup.items[0]
														.sku ? (
														<th className='border p-2'>
															SKU
														</th>
													) : (
														<>
															<th className='border p-2'>
																ID
															</th>
															<th className='border p-2'>
																Name
															</th>
														</>
													)}
													<th className='border p-2'>
														Quantity
													</th>
												</tr>
											</thead>
											<tbody>
												{pendingPickup.items ? (
													pendingPickup.items.map(
														(item, index) =>
															item.sku ? (
																<tr
																	key={index}
																	className={
																		index %
																			2 ===
																		0
																			? 'bg-gray-50'
																			: ''
																	}
																>
																	<td className='border p-2'>
																		{
																			item.sku
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.quantity
																		}
																	</td>
																</tr>
															) : (
																<tr
																	key={index}
																	className={
																		index %
																			2 ===
																		0
																			? 'bg-gray-50'
																			: ''
																	}
																>
																	<td className='border p-2'>
																		{
																			item.id
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.name
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.quantity
																		}
																	</td>
																</tr>
															)
													)
												) : (
													<div>
														QTYs by container not
														specified
														<br /> Box count:{' '}
														{pendingPickup.boxCount}
													</div>
												)}
											</tbody>
										</table>
									</span>
								</div>
							</div>
						</div>
					) : (
						<BounceLoaderCentered container='div' />
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Pickups, Logistics }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loading: Logistics.loading,
	uberRequest: Logistics.uberRequest,
	uberRequestDoc: Logistics.uberRequestDoc,
	uberQuote: Logistics.uberQuote,
	error: Logistics.error,
	updatedRequest: Logistics.updatedRequest,
	cancelledRequest: Logistics.cancelled,
	pendingPickup: Pickups.pickup,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pickupLoading: Pickups.pickupLoading,
	pendingPickups: Pickups.pendingPickups,
	proofOfDelivery: Logistics.proofOfDelivery,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetDropoff: (payload) => dispatch(getDropoffById(payload)),
	onCancelUberDirect: (payload) => dispatch(cancelUberDirect(payload)),
	onUpdateUberDirect: (payload) => dispatch(updateUberDirect(payload)),
	onGetUberDirectRequestById: (payload) =>
		dispatch(getUberDirectRequestById(payload)),
	onGetUberDirectRequestDocById: (payload) =>
		dispatch(getUberDirectRequestDocById(payload)),
	onCancelFlotDropoff: (payload) => dispatch(cancelFlotDropoff(payload)),
	onGetProofOfDelivery: (payload) => dispatch(getProofOfDelivery(payload)),
	onResetPickups: () => dispatch(resetPickups()),
	onGetPickupById: (payload) => dispatch(getPickupById(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewOnePickup)
