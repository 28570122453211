import { connect } from 'react-redux'
import { BSON } from 'realm-web'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import { Button } from '../../components/Elements'
import {
	getOffice,
	getOneTransactionOffice,
	getUser,
} from '../../store/actions'

const OfficeViewOneOrder = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetOneTransaction,
		loadingTransaction,
		transaction,
		office,
		onGetOffice,
	} = state

	const { transactionId } = useParams()
	const bounceLoaderColor = '#507f74'
	const [loading, setLoading] = useState(true)
	const paginationLimit = 50
	const [page, setPage] = useState(1)
	const [date, setDate] = useState('')

	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
		onGetOneTransaction({
			filter: {
				_id: BSON.ObjectId(String(transactionId)),
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}, [onGetUser])

	useEffect(() => {
		if (user && user.officeSlug) {
			onGetOffice(user.officeSlug)
		}
	}, [user])

	const getTransaction = () => {
		onGetOneTransaction({
			filter: {
				_id: BSON.ObjectId(String(transactionId)),
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const transactionPaginate = () => {
		getTransaction(page)
	}

	useEffect(() => {
		if (transactionId) {
			transactionPaginate()
		}
	}, [transactionId])

	useEffect(() => {
		if (transactionId) {
			transactionPaginate()
		}
	}, [page])

	useEffect(() => {
		setLoading(false)
	}, [transaction])

	const openSupport = () => {
		window.Intercom('show')
	}

	useEffect(() => {
		if (transaction) {
			const transactionDate = new Date(transaction.timestamp)
			const transactionDateString = transactionDate.toLocaleString(
				'default',
				{
					month: 'short',
					day: 'numeric',
					year: 'numeric',
				}
			)
			setDate(transactionDateString)
		}
	}, [transaction])

	if (!user)
		return (
			<div>
				<Header />
				<div className='flex items-center h-screen-no-header'>
					<BounceLoader
						className='m-auto w-full'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</div>
		)

	if (user.officeSlug) {
		if (transaction) {
			return (
				<>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/office-ordering/orders')
						}}
					>
						&#8592; Back to Orders
					</div>
					<div className='flex flex-col w-full justify-center mx-auto px-6'>
						<h1 className='font-header text-green-600 text-xl sm:text-2xl md:text-4xl mb-3 sm:mb-9 mt-5 sm:mt-14 text-center'>
							Order Details
						</h1>
						<div className='flex flex-col sm:mx-[30%]'>
							<div className='flex flex-row'>
								<div className='text-green-600'>
									{office && office.aggregatedOrdering
										? 'Office:'
										: 'Employee:'}
								</div>
								<div className='ml-2'>
									{transaction.user.firstName}{' '}
									{transaction.user.lastName}
								</div>
							</div>
							<div className='flex flex-row'>
								<div className='text-green-600'>Date:</div>
								<div className='ml-2'>{date}</div>
							</div>
							<div className='flex flex-row'>
								<div className='text-green-600'>
									Restaurant:
								</div>
								<div className='ml-2'>
									<div className='w-[200px] overflow-hidden'>
										{transaction.nodeObject[0].name}
									</div>
								</div>
							</div>
							<div className='flex flex-row'>
								<div className='text-green-600 mr-2'>
									Number of Boxes:
								</div>
								<div className='ml-2'>
									{transaction.boxCount}
								</div>
							</div>
						</div>
					</div>
					<div className='w-full'>
						<div className='flex flex-col max-w-[1200px] max-h-[900px] justify-center mb-10 overflow-x-auto mx-auto overflow-y-auto scrollbar'>
							<div className='w-full'>
								<div>
									<div className='min-w-[340px] w-[340px] sm:w-[940px] px-2 sm:px-5 py-5 my-6 shadow-light-grey mx-auto'>
										<div className='flex flex-row min-w-[320px] w-[320px] sm:w-[900px] mb-3 px-2 sm:px-5 text-green-600 text-left font-bold'>
											<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
												Item Name
											</div>
											<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4 text-center'>
												Quantity
											</div>
											<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4 text-center'>
												Boxes
											</div>
										</div>
										{loadingTransaction && (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										)}
										{transaction.items.map((item) => {
											if (item.boxCount > 0) {
												return (
													<div
														className='min-w-[320px] w-[320px] sm:w-[900px] flex flex-row my-2 even:bg-gray-100 py-1 px-2 sm:px-5'
														key={item.id}
													>
														<div></div>
														<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
															{item.product.name}
														</div>
														<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 mr-2 sm:mr-4 text-center'>
															{item.quantity}
														</div>
														<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 text-center'>
															{item.boxCount}
														</div>
													</div>
												)
											}
										})}
									</div>
									{loadingTransaction && (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									)}
								</div>
							</div>
						</div>
					</div>
					{transaction.items.length > paginationLimit ? (
						<div className='flex flex-row'>
							<div
								className='mx-auto underline font-bold mr-6 cursor-pointer'
								onClick={() => {
									setPage(page - 1)
								}}
							>
								Previous {paginationLimit} transactions
							</div>
							<div
								className='mx-auto underline font-bold cursor-pointer'
								onClick={() => {
									setPage(page + 1)
									setLoading(true)
								}}
							>
								Next {paginationLimit} transactions
							</div>
						</div>
					) : (
						<div></div>
					)}
					<div className='flex w-full flex-col justify-center mb-10 mx-auto'>
						<div className='font-bold text-center mb-2'>
							See an error in the number of boxes?
						</div>
						<div>
							<Button
								className='w-[200px] text-base flex justify-center m-auto'
								size='sm'
								text='Contact our office support team'
								onClick={openSupport}
							/>
						</div>
					</div>
					<Footer />
				</>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, OfficeOrdering }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingTransaction: OfficeOrdering.loadingTransaction,
	transaction: OfficeOrdering.oneTransactionOffice,
	transactionError: OfficeOrdering.error,
	office: OfficeOrdering.office,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOffice: (officeSlug) => dispatch(getOffice(officeSlug)),
	onGetOneTransaction: (payload) =>
		dispatch(getOneTransactionOffice(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeViewOneOrder)
