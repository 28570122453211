import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getAppInfo, updateUser } from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { Button } from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import Input from '../../../components/Elements/Input'
import { useNavigate } from 'react-router-dom'
// import { Header } from '../../components'

const AdminLogin = (state) => {
	const {
		user,
		onGetUser,
		appInfo,
		onGetAppInfo,
		onUpdateUser,
		updateUserLoading,
	} = state
	let navigate = useNavigate()

	const [enteredCode, setEnteredCode] = useState('')
	const [adminError, setAdminError] = useState(false)

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetAppInfo()
	}, [onGetAppInfo])

	const bounceLoaderColor = '#507f74'

	const checkAdminCode = () => {
		setAdminError(false)
		if (enteredCode === appInfo.adminCode) {
			onUpdateUser({ admin: true })
		} else {
			setAdminError(true)
		}
	}
	useEffect(() => {
		// console.log('in user')
		if (user && user.admin) {
			// console.log('in admin')
			navigate('/admin/home')
		}
	}, [user, user?.admin])

	const handleChange = (e) => {
		setEnteredCode(e.target.value)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	return (
		<div>
			<Header />
			<div className='min-h-min'>
				<h1 className='font-header text-[30px] md:text-[60px] text-center my-10'>
					Admin Login
				</h1>
				<div className='mb-20 mx-auto px-5 max-w-[500px] flex flex-col justify-center'>
					<Input
						type='adminCode'
						label='ENTER ADMIN CODE'
						name='adminCode'
						className='bg-white border-2 border-gray-300 mb-5'
						onChange={handleChange}
					/>
					{adminError && (
						<div className='text-red-400 text-base'>
							That code is not correct! You sure you work here?
						</div>
					)}
					{updateUserLoading ? (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					) : (
						<Button
							text='Submit'
							className='px-6'
							color='green'
							onClick={checkAdminCode}
						/>
					)}
				</div>
			</div>
			<Footer />
		</div>
	)
}

const mapStateToProps = ({ User, AppInfo }) => ({
	appInfo: AppInfo.appInfo,
	user: User.user,
	// hasValidCard: User.hasValidCard,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onUpdateUser: (update) => dispatch(updateUser(update)),
	onGetAppInfo: () => dispatch(getAppInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin)
