import { realm, client } from '../helpers/realm'

export const getPayments = async (payload) => {
	const { filter, options } = payload
	const { sort, limit, skip } = options

	const payments = client.db('caas').collection('payments')
	const stages = []

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

	return payments.aggregate(stages)
}

export const getPaymentMethods = async (stripeUserId) => {
	const paymentMethods = await realm.currentUser.callFunction(
		'stripe/getUserPaymentMethods',
		stripeUserId
	)

	return paymentMethods
}

export const deletePaymentMethod = async (deletionPayload) => {
	const paymentMethods = await realm.currentUser.callFunction(
		'stripe/deletePaymentMethod',
		deletionPayload
	)

	return paymentMethods
}

export const addPaymentMethod = async (addPayload) => {
	const paymentMethods = await realm.currentUser.callFunction(
		'stripe/addPaymentMethod',
		addPayload
	)

	return paymentMethods
}

export const chargePaymentMethod = async (chargePayload) => {
	const chargeCustomerResponse = await realm.currentUser.callFunction(
		'stripe/chargeCustomerForOrder',
		chargePayload
	)

	return chargeCustomerResponse
}

export const chargeGuestPayment = async (chargePayload) => {
	// console.log("chargePayload", chargePayload)
	const chargeCustomerResponse = await realm.currentUser.callFunction(
		'stripe/chargeGuestLatePayments',
		chargePayload
	)
	return chargeCustomerResponse
}

export const storeGuestPayment = async (chargePayload) => {
	// console.log("chargePayload", chargePayload)
	const chargeCustomerResponse = await realm.currentUser.callFunction(
		'stripe/addGuestPayment',
		chargePayload
	)
	// console.log("chargeCustomerResponse", chargeCustomerResponse)
	return chargeCustomerResponse
}
