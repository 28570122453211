import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

import { Footer, Header } from '../../components'
import RestaurantCreditCardForm from './RestaurantCreditCardForm'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import { BounceLoaderCentered } from '../../components/Elements'

const RestaurantCreditCardPage = (state) => {
	const { user, loadingUser, onGetUser } = state
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	if (user) {
		if (user.restaurantAdmin) {
			return (
				<>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/restaurant-admin/home')
						}}
					>
						&#8592; Go to Dashboard Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='flex flex-col items-center text-center h-screen-no-header w-screen px-4 py-12'>
						<h1 className='font-header text-3xl md:text-5xl my-8 text-green-600'>
							Credit/Debit Card Authorization Form
						</h1>
						<p className='w-1/3 text-center min-w-[300px] mb-8 md:text-base'>
							Enter in your credit or debit card info below to set
							up and consent to automatic billing for monthly
							container usage.
						</p>
						<div className='w-full flex flex-col justify-center items-center mb-12'>
							<Elements stripe={stripePromise}>
								<RestaurantCreditCardForm />
							</Elements>
						</div>
					</div>
					<Footer />
				</>
			)
		} else {
			navigate('/')
		}
	} else {
		return (
			<>
				<Header />
				<BounceLoaderCentered container='page' />
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ User, Payments, Nodes }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantCreditCardPage)
