import React, { useEffect, useState } from 'react'
import { Header, Footer, LatePaymentsForm } from '../../components'
import {
	getUserByPhoneOnly,
	getAppInfo,
	getOverdueBoxCount,
	getUser,
} from '../../store/actions'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useParams, useNavigate } from 'react-router-dom'

const LatePaymentsPhone = (props) => {
	const {
		onGetUser,
		onGetUserByPhone,
		user,
		loadingUser,
		onGetAppInfo,
		appInfo,
		onGetOverdueBoxCount,
		overdueBoxCount,
		loadingBoxCount,
		boxCountError,
	} = props

	const bounceLoaderColor = '#507f74'
	const { phone } = useParams()

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	let navigate = useNavigate()

	useEffect(() => {
		onGetAppInfo()
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			if (!user && phone) {
				await onGetUserByPhone({
					phone: phone,
				})
				onGetOverdueBoxCount(phone)
			} else if (user && user.phone === phone) {
				onGetOverdueBoxCount(user.phone)
			} else if (phone) {
				onGetOverdueBoxCount(phone)
			} else {
				navigate('/late-payments')
			}
		}

		fetchData()
	}, [user, user?.phone, phone])

	if (!appInfo || !appInfo.containerLateFee) {
		return (
			<div>
				<Header />
				<div className='flex items-center h-screen-no-header'>
					<BounceLoader
						className='m-auto w-full'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</div>
		)
	} else {
		return (
			<>
				<Header />

				<div className='flex flex-col justify-center items-center text-center h-full w-screen px-4 py-12'>
					<h1 className='font-header text-3xl md:text-5xl mb-12 text-green-600'>
						Late or Lost Containers
					</h1>
					<p className='w-1/2 text-center min-w-[300px] mb-12 text-sm md:text-base'>
						For any overdue or misplaced containers, the fee of $
						{appInfo.containerLateFee} per container can be paid
						below.
					</p>
					<div className='w-full flex flex-col justify-center items-center mb-12'>
						<Elements stripe={stripePromise}>
							<LatePaymentsForm
								overdueBoxCount={overdueBoxCount}
								userPhone={phone}
							/>
						</Elements>
					</div>
				</div>
				<Footer />
			</>
		)
	}
}
const mapStateToProps = ({ User, AppInfo }) => ({
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	overdueBoxCount: User.overdueBoxCount,
	loadingBoxCount: User.loadingBoxCount,
	boxCountError: User.boxCountError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUserByPhone: (payload) => dispatch(getUserByPhoneOnly(payload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetOverdueBoxCount: (payload) => dispatch(getOverdueBoxCount(payload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LatePaymentsPhone)
