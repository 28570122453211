import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
	TextArea,
} from '../Elements'
import { BounceLoader } from 'react-spinners'
import {
	getRlPartner,
	getRlPartnersByPolygon,
	createPendingPickup,
	createTransaction,
	resetTransaction,
	getUser,
	getUserByPhone,
	getAppInfo,
	chargePaymentMethod,
	resetCardResponse,
	captureIP,
	captureGPS,
	resetSuccess,
	getUberDirectQuote,
	createUberDirectRequest,
	validatePromo,
	updatePromo,
	resetPromo,
	getPickupsByPhone,
	resetCardAdded,
	storeGuestPayment,
	storePaymentMethod,
	alertSlack,
} from '../../store/actions'
import {
	dayNames,
	getNextTwoDatesOfDay,
	monthNames,
	sortDateArray,
} from '../../helpers/dates'
import ReactConfetti from 'react-confetti'
import { MdOutlineCelebration } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PendingPickupsPopUp from './PendingPickupsPopUp'

const ReturnAtDoorPhone = (props) => {
	const {
		onGetUserByPhone,
		user,
		loadingUser,
		partners,
		loadingPartners,
		onGetRlPartnersByPolygon,
		onCreateTransaction,
		transaction,
		creatingTransaction,
		onResetTransaction,
		onChargeCustomer,
		chargingCard,
		chargingCardResponse,
		chargingCardError,
		paymentMethods,
		onGetAppInfo,
		appInfo,
		onResetCardResponse,
		onCaptureIP,
		onCreatePendingPickup,
		pendingPickup,
		pendingPickups,
		pickupSuccess,
		pickupLoading,
		pickupError,
		onGetPendingPickupsByPhone,
		onCaptureGPS,
		onResetSuccess,
		onGetUberDirectQuote,
		onCreateUberDirectRequest,
		loading,
		uberRequest,
		uberQuote,
		error,
		onValidatePromo,
		promoMessageType,
		promoIsActive,
		promo,
		onUpdatePromo,
		onResetPromo,
		updatedPromo,
		loadingAddition,
		onStorePaymentMethod,
		loadingPaymentMethods,
		loggedIn,
		onGetUser,
		onStorePaymentGuest,
		cardAdded,
		paymentError,
		onResetCardAdded,
		onAlertSlack,
		requestError,
	} = props
	const google = window.google
	const [streetNumber, setStreetNumber] = useState('')
	const [streetName, setStreetName] = useState('')
	const [zipCode, setZipCode] = useState('')
	const [city, setCity] = useState('')
	const [stateName, setStateName] = useState('')
	const [address, setAddress] = useState('')
	const [coords, setCoords] = useState([])
	const [address2, setAddress2] = useState('')
	const [useDefaultAddress, setUseDefaultAddress] = useState(false)
	const [addressError, setAddressError] = useState(false)
	const bounceLoaderColor = '#507f74'
	const [geoCodedAddress, setGeoCodedAddress] = useState(false)
	const [availableDates, setAvailableDates] = useState([])
	const [availableTimesPartners, setAvailableTimesPartners] = useState({})
	const [availableOndemand, setAvailableOndemand] = useState([])
	const [ondemandPickupSelected, setOndemandPickupSelected] = useState(false)
	const [availableDatesObj, setAvailableDatesObj] = useState({})
	const [showSuccess, setShowSuccess] = useState(false)
	const [qty, setQty] = useState(0)
	const [errorMessage, setErrorMessage] = useState('')
	const [selectedReturnDate, setSelectedReturnDate] = useState(null)
	const [selectedReturnPartner, setSelectedReturnPartner] = useState({})
	const [showSearchBar, setShowSearchBar] = useState(false)
	const [pickupNote, setPickupNote] = useState('')
	const [cardRadios, setCardRadios] = useState([])
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
	const { returnByTextId, phone } = useParams()
	const [emailAddress, setEmailAddress] = useState('')
	const [timeoutExpired, setTimeoutExpired] = useState(false)
	const [tipAmount, setTipAmount] = useState(0)
	const [uberdirectTotal, setUberdirectTotal] = useState(0)
	const [providedCode, setProvidedCode] = useState('')
	const [promoMessage, setPromoMessage] = useState('')
	const [invalidAddress, setInvalidAddress] = useState(false)
	const [openPopUp, setOpenPopUp] = useState(false)
	const [filteredPickups, setFilteredPickups] = useState([])
	const [pendingDates, setPendingDates] = useState([])
	const [addCardError, setAddCardError] = useState(false)
	const [stripeLoading, setStripeLoading] = useState(false)
	const [stripeCustomer, setStripeCustomer] = useState(null)
	const [showCardForm, setShowCardForm] = useState(false)
	const [isMember, setIsMember] = useState(false)
	let navigate = useNavigate()

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const stripe = useStripe()
	const elements = useElements()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	useEffect(() => {
		onGetAppInfo()
		onResetTransaction()
		onResetSuccess()
		onResetCardResponse()
		onGetUser()
		onResetPromo()
		onResetCardAdded()
	}, [])

	useEffect(() => {
		if (phone && returnByTextId) {
			onGetUserByPhone({
				phone: phone,
				returnByTextId: returnByTextId,
			})
		}
	}, [phone, returnByTextId, JSON.stringify(paymentMethods)])

	useEffect(() => {
		if (!user || !user.phone) {
			onGetUserByPhone({
				phone: phone,
				returnByTextId: returnByTextId,
			})
		}
	}, [loadingUser])

	useEffect(() => {
		if (user && user.phone) {
			onCaptureIP({ phone: phone })
			onCaptureGPS({ phone: phone })
			onGetPendingPickupsByPhone({ phone: phone })
		}
		if (
			user?.dzMemberSubscription?.enabled &&
			user.dzMemberSubscription?.tierSlug === 'tierTwo'
		) {
			setIsMember(true)
		}
	}, [user])

	useEffect(() => {
		if (pendingPickups) {
			const currentDate = new Date()
			const filteredPickups = pendingPickups.filter(
				(pickup) => new Date(pickup.timestamp) > currentDate
			)
			setFilteredPickups(filteredPickups)
			const dates = []
			for (const pickup of filteredPickups) {
				dates.push(pickup.timestamp.toString().slice(0, 10))
			}
			setPendingDates(dates)
		}
	}, [pendingPickups])

	const handleOpenPopUp = () => {
		setOpenPopUp(!openPopUp)
	}

	useEffect(() => {
		if (window.google && window.google.maps) {
			setShowSearchBar(true)
		}
	}, [window.google?.maps])

	useEffect(() => {
		if (user) {
			if (user.address != '') {
				setAddress(user.address)
				// handleAddressSubmit(null)
			}
			setEmailAddress(user.email)
		}
	}, [user])

	useEffect(() => {
		setSelectedReturnPartner({})
		// setSelectedPaymentMethod('')
		if (!geoCodedAddress) {
			geocodeAddress()
		}
	}, [address])

	useEffect(() => {
		setSelectedReturnPartner({})
		// setSelectedPaymentMethod('')
		if (google && google.maps && !geoCodedAddress) {
			geocodeAddress()
		}
	}, [google, google?.maps])

	useEffect(() => {
		if (geoCodedAddress) {
			validateAddress()
		}
	}, [address, geoCodedAddress, addressError])

	const validateAddress = () => {
		setErrorMessage('')
		if (
			!streetName ||
			streetName === '' ||
			!streetNumber ||
			streetNumber === ''
		) {
			setInvalidAddress(true)
			setErrorMessage('Please enter a valid address')
		} else {
			setInvalidAddress(false)
			setErrorMessage('')
			if (address && !geoCodedAddress) {
				geocodeAddress()
			}
		}
	}

	const reverseGeocodeAddress = async (coords) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{ location: coords },
			function (results, status) {
				// console.log("results[0].formatted_address", results[0].formatted_address)
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(true)
					setAddress(results[0].formatted_address)
				} else {
					setAddressError(true)
					alert('Invalid address')
				}
			}
		)
	}

	useEffect(() => {
		const lastSearchedAddress = JSON.parse(
			localStorage.getItem('lastSearchedAddress')
		)
		navigator.geolocation.getCurrentPosition(
			(position) => {
				// console.log("position", position)
				const crd = position.coords
				reverseGeocodeAddress({
					lat: parseFloat(crd.latitude),
					lng: parseFloat(crd.longitude),
				})
				setTimeoutExpired(true)
			},
			(e) => {
				// console.log("error", e)
				if (lastSearchedAddress && lastSearchedAddress.address) {
					setAddress(lastSearchedAddress.address)
					setGeoCodedAddress(false)
					// geocodeAddress(lastSearchedAddress.address);
				} else if (user && user.lastSearchedAddress) {
					setAddress(user.lastSearchedAddress)
					setGeoCodedAddress(false)
					// geocodeAddress(user.lastSearchedAddress);
				} else if (user && user.address) {
					setAddress(user.address)
					setGeoCodedAddress(false)
					// geocodeAddress(user.address);
				}
				const timeoutId = setTimeout(() => {
					// update state to indicate that timeout has expired
					setTimeoutExpired(true)
				}, 5000)
			},
			{ enableHighAccuracy: true, timeout: 5000 }
		)
	}, [])

	const geocodeAddress = async () => {
		// Geocode the address
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: address,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(true)
					setAddress(results[0].formatted_address)
					setStreetName('')
					setStreetNumber('')
					results[0].address_components.forEach((element) => {
						if (element.types.includes('postal_code')) {
							setZipCode(element.short_name)
						} else if (element.types.includes('route')) {
							setStreetName(element.short_name)
						} else if (element.types.includes('street_number')) {
							setStreetNumber(element.short_name)
						} else if (
							element.types.includes('sublocality') ||
							element.types.includes('locality')
						) {
							setCity(element.short_name)
						} else if (
							element.types.includes(
								'administrative_area_level_1'
							)
						) {
							setStateName(element.short_name)
						}
					})
					setCoords([
						results[0].geometry.location.lat(),
						results[0].geometry.location.lng(),
					])
				} else {
					setAddressError(true)
					// // show an error if it's not
					// alert("Invalid address");
				}
			}
		)
	}

	const handleAddressSubmit = async (e) => {
		e.preventDefault()
		// console.log(address)
		// Geocode the address
		geocodeAddress()
	}

	const handleAddressChange = (newAddress) => {
		setStreetName('')
		setStreetNumber('')
		if (newAddress.target) {
			setAddress(newAddress.target.value)
			setPromoMessage('')
			onResetPromo()
			onResetSuccess()
		} else if (newAddress.formatted_address) {
			newAddress.address_components.forEach((element) => {
				if (element.types.includes('postal_code')) {
					setZipCode(element.short_name)
				} else if (element.types.includes('route')) {
					setStreetName(element.short_name)
				} else if (element.types.includes('street_number')) {
					setStreetNumber(element.short_name)
				} else if (
					element.types.includes('sublocality') ||
					element.types.includes('locality')
				) {
					setCity(element.short_name)
				} else if (
					element.types.includes('administrative_area_level_1')
				) {
					setStateName(element.short_name)
				}
			})
			setAddress(newAddress.formatted_address)
			setCoords([
				newAddress.geometry.location.lat(),
				newAddress.geometry.location.lng(),
			])
			setPromoMessage('')
			onResetPromo()
			onResetSuccess()
		}
	}

	const handleAddress2Change = (apt) => {
		if (apt.target) {
			setAddress2(apt.target.value)
		}
	}

	useEffect(() => {
		if (useDefaultAddress) {
			geocodeAddress()
		}
	}, [useDefaultAddress])

	useEffect(() => {
		if (coords && coords.length != 0) {
			setAvailableDates([])
			setAvailableDatesObj({})
			setAvailableTimesPartners({})
			setOndemandPickupSelected(false)
			onGetRlPartnersByPolygon(coords)
		}
	}, [coords])

	useEffect(() => {
		let availDobj = {}
		let availD = []
		let availOndemandPartners = []
		let availTimesPartners = {}
		if (partners && partners.length > 0) {
			partners.forEach((partner) => {
				if (partner.availability === 'onDemand') {
					availOndemandPartners.push(partner)
				} else {
					partner.availability.forEach((day) => {
						let nextTwoDates = getNextTwoDatesOfDay(
							day,
							partner.bufferDays
						)
						// console.log(nextTwoDates)
						availD = availD.concat(nextTwoDates)
						nextTwoDates.forEach((date) => {
							availDobj[date] = partner
						})
					})
					if (partner.availableTimes) {
						availTimesPartners[partner.partner] =
							partner.availableTimes // maps from partner name to obj of available times
					}
				}
			})
		}
		setAvailableDatesObj(availDobj)
		setAvailableDates([...new Set(sortDateArray(availD))])
		setAvailableOndemand(availOndemandPartners)
		setAvailableTimesPartners(availTimesPartners)
	}, [partners])

	const handleOnDemandClicked = () => {
		setOndemandPickupSelected(true)
		const quotePayload = {
			timestamp: new Date(),
			type: 'boxesIn',
			boxCount: qty,
			user: {
				phone: user.phone,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				pickupAddress:
					address2 != ''
						? 'Unit: ' + address2 + '; ' + address
						: address,
				street: streetNumber + ' ' + streetName,
				unit: address2,
				city: city,
				state: stateName,
				postal: zipCode,
				coords: coords,
				paymentMethodId: selectedPaymentMethod,
			},
			returnAtDoor: true,
			reverseLogisticsPartner: availableOndemand[0],
			pickupNote: pickupNote,
		}
		onGetUberDirectQuote(quotePayload)
	}

	const createUberdirectPickup = () => {
		let payload = {
			...uberQuote,
			boxCount: qty,
			pickupNote: pickupNote,
			tip: tipAmount,
		}
		if (promoMessage === 'Promo code applied!' || isMember) {
			payload = {
				...uberQuote,
				boxCount: qty,
				pickupNote: pickupNote,
				tip: 1,
			}
		}
		onCreateUberDirectRequest(payload)
	}

	const submitTransaction = () => {
		const transactionPayload = {
			timestamp: selectedReturnDate,
			type: 'boxesIn',
			boxCount: qty,
			user: {
				phone: user.phone,
				email: user.email ? user.email : emailAddress,
				firstName: user.firstName,
				lastName: user.lastName,
				pickupAddress:
					address2 != ''
						? 'Unit: ' + address2 + '; ' + address
						: address,
				street: streetNumber + ' ' + streetName,
				unit: address2,
				city: city,
				state: stateName,
				postal: zipCode,
				coords: coords,
				paymentMethodId: selectedPaymentMethod,
			},
			returnAtDoor: true,
			reverseLogisticsPartner: selectedReturnPartner.partner,
			pickupNote: pickupNote,
			returnedByText: true,
		}
		if (
			selectedReturnPartner.partner === 'deliverzero' ||
			selectedReturnPartner.partner === 'flot' ||
			selectedReturnPartner.partner === 'gently' ||
			selectedReturnPartner.partner === 'returnmates'
		) {
			onCreatePendingPickup(transactionPayload)
		} else {
			onCreateTransaction(transactionPayload)
		}
		// console.log(transactionPayload)
	}

	useEffect(() => {
		if (uberQuote && uberQuote.quote) {
			const fee = parseFloat(JSON.parse(uberQuote.quote).fee) / 100
			setUberdirectTotal(fee)
			setSelectedReturnDate(new Date())
			setSelectedReturnPartner(availableOndemand[0]) // since uberdirect is the only onDemand partner
		}
	}, [uberQuote])

	useEffect(() => {
		if (requestError && requestError !== null) {
			setErrorMessage('Could not create a request for on demand pickup')
			onAlertSlack({
				channel: '#api-errors',
				text:
					'<@U05CHR1JENT>, Could not create a request for on demand pickup for ' +
					user.phone +
					' ' +
					user.email +
					' from: ' +
					address +
					' Error: ' +
					JSON.stringify(requestError) +
					(chargingCardError
						? ', Card error: ' + JSON.stringify(chargingCardError)
						: ', no charging error'),
			})
		}
	}, [requestError])

	// for testing without charging
	// useEffect(() => {
	// 	if (uberRequest) {
	// 		if (promoIsActive) {
	// 			onUpdatePromo({ promo: promo, promoCode: providedCode, userPhone: user.phone })
	// 		}
	// 	}
	// }, [uberRequest])

	useEffect(() => {
		if ((transaction || pickupSuccess) && selectedReturnDate) {
			setShowSuccess(true)
		}
		onResetTransaction()
		onResetSuccess()
		onResetCardResponse()
	}, [transaction, pickupSuccess])

	const submitHandler = (e) => {
		e.preventDefault()
		setErrorMessage('')
		if (qty > 0) {
			if (selectedReturnDate) {
				if (selectedReturnPartner.partner == 'uberdirect') {
					if (
						(promoMessage === 'Promo code applied!' &&
							pickupNote.length <= 280) ||
						isMember
					) {
						createUberdirectPickup()
					} else if (selectedPaymentMethod == '') {
						setErrorMessage('Please select a card')
					} else if (pickupNote.length > 280) {
						// do nothing, error is already shown
					} else {
						onChargeCustomer({
							userId: user.phone,
							paymentMethodId: selectedPaymentMethod,
							totalChargeAmount: uberdirectTotal,
							type: 'return_pickup',
							uberdirect: true,
						})
					}
				} else if (
					selectedReturnPartner.pickupFee &&
					selectedReturnPartner.pickupFee > 0
				) {
					if (selectedPaymentMethod !== '') {
						onChargeCustomer({
							userId: user.phone,
							paymentMethodId: selectedPaymentMethod,
							totalChargeAmount: selectedReturnPartner.pickupFee,
							type: 'return_pickup',
						})
					} else {
						setErrorMessage('Please select a card')
					}
				} else if (
					selectedReturnPartner.partner !== 'gently' &&
					selectedReturnPartner.partner !== 'uberdirect'
				) {
					if (selectedPaymentMethod !== '') {
						onChargeCustomer({
							userId: user.phone,
							paymentMethodId: selectedPaymentMethod,
							totalChargeAmount: appInfo.pickupAtDoorFee,
							type: 'return_pickup',
						})
					} else {
						setErrorMessage('Please select a card')
					}
				} else {
					if (streetName === '' || streetNumber === '') {
						setErrorMessage(
							'Please select a valid address with street name and number'
						)
					} else {
						validateAddress()
						submitTransaction()
					}
				}
			} else {
				setErrorMessage('Please select a return date')
			}
		} else {
			setErrorMessage('Please enter a quantity greater than 0')
		}
	}

	useEffect(() => {
		setErrorMessage('')
		if (
			(uberRequest &&
				selectedReturnPartner.partner === 'uberdirect' &&
				promoMessage === 'Promo code applied!') ||
			isMember
		) {
			if (promoMessage === 'Promo code applied!') {
				onUpdatePromo({
					promo: promo,
					promoCode: providedCode,
					userPhone: user.phone,
				})
			}
			setShowSuccess(true)
			return function cleanup() {
				onResetTransaction()
				onResetSuccess()
				onResetCardResponse()
				onResetPromo()
			}
		} else if (chargingCardResponse === 'success') {
			if (selectedReturnPartner.partner != 'uberdirect') {
				submitTransaction()
				onResetCardResponse()
			} else if (uberRequest) {
				setShowSuccess(true)
				return function cleanup() {
					onResetTransaction()
					onResetSuccess()
					onResetCardResponse()
					onResetPromo()
				}
			} else {
				createUberdirectPickup()
			}
		} else if (chargingCardResponse === 'error') {
			setErrorMessage('Error while charging your card!')
		}
	}, [chargingCardResponse, uberRequest])

	useEffect(() => {
		if (chargingCardError) {
			setErrorMessage('Error while charging your card!')
		}
	}, [chargingCardError])

	useEffect(() => {
		// console.log('user payment useeffect triggered')
		if (user && user.paymentMethods) {
			makeCardRadios(user.paymentMethods)
		}
	}, [user?.paymentMethods, JSON.stringify(user?.paymentMethods)])

	useEffect(() => {
		if (paymentMethods.length > 0) {
			onGetUser()
			makeCardRadios(paymentMethods)
			setStripeCustomer(paymentMethods[0].customer)
		}
	}, [paymentMethods])

	const makeCardRadios = (paymentMethods) => {
		// console.log(paymentMethods)
		let radios = []
		paymentMethods.forEach(function (paymentMethod, index) {
			radios.push({
				value: paymentMethod.id,
				label: `${paymentMethod.card.brand.toUpperCase()} ....${
					paymentMethod.card.last4
				} - Exp. ${paymentMethod.card.exp_month}/${
					paymentMethod.card.exp_year
				}`,
				checked: index === 0,
			})
			if (index === 0) {
				setSelectedPaymentMethod(paymentMethod.id)
			}
		})
		setCardRadios(radios)
	}

	const handleAddCardSubmit = async (event) => {
		setStripeLoading(true)
		event.preventDefault()
		// setAddCardError(false)
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})
		if (result.error) {
			setStripeLoading(false)
			// setAddCardError(true)
		} else {
			setStripeLoading(false)
			onStorePaymentMethod({
				stripeCustomerId: user.stripeId,
				paymentMethodId: result.paymentMethod.id,
			})

			setShowCardForm(false)
		}
	}

	const handleAddCardGuest = async (e) => {
		e.preventDefault()
		setStripeLoading(true)
		e.preventDefault()
		setAddCardError(false)
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})

		if (result.error || !result.paymentMethod) {
			setStripeLoading(false)
			setAddCardError(true)
		} else {
			setStripeLoading(false)
			// console.log("result.paymentMethod.id", result.paymentMethod.id);
			onStorePaymentGuest({
				email: user.email || emailAddress,
				phone: user.phone || '',
				paymentMethodId: result.paymentMethod.id,
			})
			setSelectedPaymentMethod(result.paymentMethod.id)
		}
	}

	// ! VALIDATE PROMO
	const handleCodeValidation = (e) => {
		e.preventDefault()
		if (selectedReturnPartner.partner === 'uberdirect') {
			onValidatePromo({
				promoCode: providedCode,
				phone: user.phone,
				node: null,
				uberDirect: true,
			})
		} else {
			onValidatePromo({
				promoCode: providedCode,
				phone: user.phone,
				node: null,
			})
		}
	}

	const promoMessages = {
		promoValid: 'Promo code applied!',
		promoInvalid: 'Promo code is invalid',
		promoExpired: 'Promo code has expired',
		promoAlreadyUsed: 'Promo code is no longer valid',
		promoNotYetActive: 'Promo code is not yet active',
		promoNotValidForUser: 'Promo code is not valid',
		promoNotValidForNode: 'Promo code is not valid for this restaurant',
		giftCardValid: 'Gift card applied!',
		giftCardInvalid: 'Gift card is invalid',
		giftCardBalanceZero: 'Gift card balance is $0.00',
		promoNotValidForUberDirect:
			'Promo code not valid for on demand pickups',
		promoOnlyValidForUberDirect:
			'Promo code is only valid for on demand pickups',
	}

	useEffect(() => {
		if (promoMessageType) {
			setPromoMessage(promoMessages[promoMessageType])
		}
	}, [promoMessageType])

	if (user && user.phone) {
		return (
			<div className=' h-full w-full flex flex-col justify-between '>
				{showSuccess && selectedReturnDate ? (
					<div className='h-screen-no-header my-5 flex flex-col justify-center items-center overflow-hidden'>
						<ReactConfetti colors={['#f38b74']} />
						<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
							You're all set!
						</h1>
						{ondemandPickupSelected ? (
							<div className='mx-3 text-base text-center'>
								A courier is being dispatched shortly to pick up
								your containers. Keep an eye out for a text with
								updates!
							</div>
						) : (
							<div className='mx-3 text-base text-center'>
								Remember to leave your containers out for pick
								up on {dayNames[selectedReturnDate.getDay()]},
								{` `}
								{monthNames[selectedReturnDate.getMonth()]}{' '}
								{selectedReturnDate.getDate()}
							</div>
						)}

						<MdOutlineCelebration className='text-orange-600 text-[250px]' />
						<Button
							link='/user/account'
							className='px-10 w-[260px] mt-5 shadow-light-grey'
							text='View My Account'
						/>
					</div>
				) : (
					<div>
						<div
							className='text-green-600 underline cursor-pointer bg-yellow-400 font-bold pl-4 pt-2'
							onClick={() => {
								navigate(`/returns/${returnByTextId}/${phone}`)
							}}
						>
							&#129064; Back
						</div>

						<div className='flex flex-col items-center min-h-min w-full text-center  bg-yellow-400'>
							<h1 className='p-8 text-6xl text-green-600 font-header sm:text-6xl '>
								Schedule a Pickup
							</h1>
							<div className='flex flex-col mx-[10%] w-[80%] min-h-min py-5 px-5 mb-12 shadow-lighter-grey bg-white rounded-[10px]'>
								<div className='text-left sm:text-center text-base md:text-[20px] font-semibold'>
									Return containers at your door. Leave your
									containers in a safe spot for our couriers
									to pick up.
								</div>
								<div className='mt-2 text-left text-xs sm:text-center font-light'>
									Our pickups take advantage of existing empty
									courier legs and use optimized route
									technology, so that that there's minimal
									added emissions.
								</div>
								{filteredPickups &&
									filteredPickups.length > 0 && (
										<div className='flex flex-col w-full mx-auto justify-center items-center'>
											<Button
												text='View Upcoming Pickups'
												size='xs'
												color='green'
												onClick={() => {
													handleOpenPopUp()
												}}
												className='px-3 py-2 mt-2 w-[175px]'
											/>
											{openPopUp && (
												<PendingPickupsPopUp
													handleOpenPopUp={
														handleOpenPopUp
													}
													pendingPickups={
														filteredPickups
													}
													openPopUp={openPopUp}
												/>
											)}{' '}
										</div>
									)}
								<div className='mt-5 text-left sm:text-center text-base font-light'>
									Select your pickup address
								</div>
								{useDefaultAddress ? (
									<div className='text-left'>
										<div>{user.address}</div>
										{user.address2 &&
											user.address2 != '' && (
												<div>Unit: {user.address2}</div>
											)}
									</div>
								) : (
									<div className='text-left'>
										<div className='pt-1'>
											<label
												className={`text-sm text-gray-600 font-poppins ${
													addressError &&
													'text-red-600'
												} sm:w-24 -translate-y-5 pb-1`}
												htmlFor='address'
											>
												ADDRESS
											</label>
											{showSearchBar ? (
												<Input
													type=''
													label=''
													name='address'
													onChange={
														handleAddressChange
													}
													autoCompleteAddress={true}
													className='bg-white border-2 border-gray-300 mb-3 w-full sm:w-5/6'
													value={address}
													onKeyDown={(e) => {
														handleAddressChange(e)
														e.key === 'Enter' &&
															handleAddressSubmit(
																e
															)
													}}
												/>
											) : (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
										</div>
										<div className='flex flex-row items-center'>
											<div className='pt-1'>
												<label
													className={`text-sm text-gray-600 font-poppins ${
														addressError &&
														'text-red-600'
													} sm:w-24 -translate-y-5 pb-1`}
													htmlFor='address2'
												>
													UNIT
												</label>
												<Input
													type='address2'
													name='unit'
													onChange={
														handleAddress2Change
													}
													onKeyDown={(e) => {
														e.key === 'Enter' &&
															e.preventDefault()
													}}
													defaultValue={
														user.address2
															? user.address2
															: ''
													}
													className='bg-white border-2 border-gray-300 mb-3'
												/>
											</div>
											{/*<Button*/}
											{/*    size='sm'*/}
											{/*    className='text-xs px-1 mt-3 sm:ml-2'*/}
											{/*    text='Find Pickup Date'*/}
											{/*    onClick = {handlePickupDateSearch}*/}
											{/*/>*/}
										</div>
									</div>
								)}
								<label
									className={`text-sm text-gray-600 font-poppins sm:w-24 pb-1 text-left`}
									htmlFor=''
								>
									EMAIL
								</label>
								<div className='text-left'>
									<Input
										type='email'
										name='email'
										onChange={(e) => {
											e.preventDefault()
											setEmailAddress(e.target.value)
										}}
										defaultValue={
											user.email ? user.email : ''
										}
										className='bg-white border-2 border-gray-300 mb-3 w-full sm:w-1/2 text-left'
									/>
								</div>

								<div className='flex flex-col md:flex-row my-3'>
									<div className='w-full md:mr-3 md:w-2/5'>
										<div className='text-left text-base font-light'>
											Available Pickup Times
										</div>
										{loadingPartners ? (
											<div className='mt-3 text-center'>
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											</div>
										) : (
											<div className='sm:w-[240px]'>
												{availableOndemand.length > 0 &&
													(loading ? (
														<BounceLoader
															className='m-auto'
															color={
																bounceLoaderColor
															}
														></BounceLoader>
													) : ondemandPickupSelected &&
													  !uberQuote ? (
														<div className='font-bold text-xs justify-start text-left text-red-400'>
															Sorry, no on demand
															pickups available at
															this time. Please
															try again later or{' '}
															<a
																className='underline cursor-pointer'
																onClick={() => {
																	navigate(
																		`/returns/locations/${returnByTextId}/${phone}`
																	)
																}}
															>
																return your
																containers to
																the nearest
																return location
															</a>
														</div>
													) : ondemandPickupSelected &&
													  uberQuote ? (
														uberQuote.code ===
														'address_undeliverable' ? (
															<div className='font-bold text-xs justify-start text-left text-red-400'>
																Sorry, no on
																demand pickups
																are available in
																your area at
																this time.
																Please try again
																later or{' '}
																<a
																	className='underline cursor-pointer'
																	onClick={() => {
																		navigate(
																			`/returns/locations/${returnByTextId}/${phone}`
																		)
																	}}
																>
																	return your
																	containers
																	to the
																	nearest
																	return
																	location
																</a>
															</div>
														) : (
															<div className='font-bold justify-start text-left'>
																Please fill out
																remaining
																details to
																request your
																pickup!
															</div>
														)
													) : (
														<div>
															{availableOndemand.map(
																(
																	onDemandPartner,
																	index
																) => {
																	return (
																		<Button
																			size='sm'
																			className='my-1'
																			key={
																				index
																			}
																			color={
																				'green'
																			}
																			onClick={
																				handleOnDemandClicked
																			}
																			text={
																				'On Demand'
																			}
																		/>
																	)
																}
															)}
															<div className='font-bold text-xs justify-start text-left mb-3'>
																For on demand,
																we'll dispatch a
																courier right
																away to pick up
																your returns
															</div>
														</div>
													))}
												{partners &&
												partners.length > 0 ? (
													<div>
														{availableDates.map(
															(date, index) => {
																if (index < 5) {
																	if (
																		pendingDates &&
																		pendingDates.includes(
																			date
																				.toString()
																				.slice(
																					0,
																					10
																				)
																		)
																	) {
																		return (
																			<Button
																				size='sm'
																				key={
																					index
																				}
																				className='my-1'
																				color='disabled'
																				text={
																					<div>
																						<span className='text-xs'>
																							PENDING
																							PICKUP:
																						</span>{' '}
																						<span className='text-base'>
																							{date
																								.toString()
																								.slice(
																									0,
																									10
																								)}
																						</span>
																					</div>
																				}
																				onClick={() => {
																					handleOpenPopUp()
																				}}
																			/>
																		)
																	} else {
																		return (
																			<Button
																				size='sm'
																				key={
																					index
																				}
																				className='my-1'
																				color={
																					selectedReturnDate ===
																					date
																						? 'green'
																						: 'green-outlined'
																				}
																				onClick={() => {
																					setSelectedReturnDate(
																						date
																					)
																					setSelectedReturnPartner(
																						availableDatesObj[
																							date
																						]
																					)
																					setOndemandPickupSelected(
																						false
																					)
																					setPromoMessage(
																						''
																					)
																					onResetPromo()
																					onResetSuccess()
																				}}
																				text={
																					availableDatesObj[
																						date
																					]
																						.pickupFee &&
																					availableDatesObj[
																						date
																					]
																						.pickupFee >
																						0
																						? `${dayNames[
																								date.getDay()
																						  ].slice(
																								0,
																								3
																						  )}, ${monthNames[
																								date.getMonth()
																						  ].slice(
																								0,
																								3
																						  )} ${date.getDate()} $${availableDatesObj[
																								date
																						  ].pickupFee.toFixed(
																								2
																						  )} ${
																								availableTimesPartners[
																									availableDatesObj[
																										date
																									]
																										.partner
																								] &&
																								availableTimesPartners[
																									availableDatesObj[
																										date
																									]
																										.partner
																								][
																									dayNames[
																										date.getDay()
																									]
																								]
																									? availableTimesPartners[
																											availableDatesObj[
																												date
																											]
																												.partner
																									  ][
																											dayNames[
																												date.getDay()
																											]
																									  ]
																									: ''
																						  }`
																						: `${dayNames[
																								date.getDay()
																						  ].slice(
																								0,
																								3
																						  )}, ${monthNames[
																								date.getMonth()
																						  ].slice(
																								0,
																								3
																						  )} ${date.getDate()}
                                                                                    ${
																						availableTimesPartners[
																							availableDatesObj[
																								date
																							]
																								.partner
																						] &&
																						availableTimesPartners[
																							availableDatesObj[
																								date
																							]
																								.partner
																						][
																							dayNames[
																								date.getDay()
																							]
																						]
																							? availableTimesPartners[
																									availableDatesObj[
																										date
																									]
																										.partner
																							  ][
																									dayNames[
																										date.getDay()
																									]
																							  ]
																							: ''
																					}`
																				}
																			/>
																		)
																	}
																}
															}
														)}
													</div>
												) : availableOndemand.length >
												  0 ? (
													<div></div>
												) : (
													<div>
														No pickup available yet
														for that address!
													</div>
												)}
											</div>
										)}
									</div>

									<div className='flex flex-col'>
										{/* PROMO */}
										{selectedReturnPartner &&
											selectedReturnPartner.partner ===
												'uberdirect' && (
												<div className='flex flex-col mt-3 md:mt-0 md:ml-6'>
													<div className='w-full h-full'>
														<div className='text-left text-base font-light'>
															Promo Code
														</div>
														<Input
															label=''
															className='text-left'
															onChange={(e) =>
																setProvidedCode(
																	e.target
																		.value
																)
															}
														/>
														<Button
															text='Apply'
															size='xs'
															className='flex justify-center items-center my-2 px-2 h-fit max-w-[100px]'
															onClick={(e) =>
																handleCodeValidation(
																	e
																)
															}
														/>
													</div>
													{promoMessage &&
														promoMessage !==
															'Promo code applied!' && (
															<div className='my-4 text-red-400'>
																{promoMessage}
															</div>
														)}
												</div>
											)}
										<div className='mt-3 md:mt-0 md:ml-6'>
											<div className='text-left text-base font-light'>
												Special Instructions
											</div>
											<TextArea
												name='Special Instructions'
												placeholder='Include special instructions for our drivers here'
												// description='Maximum of 200 characters'
												className='!h-20 !mt-1'
												onChange={(e) => {
													setPickupNote(e)
												}}
											/>
											{(selectedReturnPartner &&
												selectedReturnPartner.partner ===
													'uberdirect') ||
												(ondemandPickupSelected && (
													<>
														<span className='text-xs flex flex-row my-1'>
															Character count:{' '}
															{pickupNote.length >
															280 ? (
																<span className='text-red-600 ml-1'>
																	{' '}
																	{
																		pickupNote.length
																	}
																</span>
															) : (
																<span className='text-green-600 ml-1'>
																	{' '}
																	{
																		pickupNote.length
																	}
																</span>
															)}
														</span>
														{pickupNote.length >
															280 && (
															<div className='text-red-600 text-sm mb-3'>
																Special
																Instructions
																cannot exceed
																280 characters
															</div>
														)}
													</>
												))}
										</div>
										<div className='w-full md:ml-6 md:w-3/5 mt-3 md:mt-0'>
											<div className='text-left text-base font-light'>
												Tell us how many containers
												you're returning
											</div>
											<div className='mt-3 border-2 rounded-full mb-3 sm:mb-0 sm:w-[175px] w-full text-center flex flex-row justify-between px-3 items-center'>
												<span
													color='default'
													className='text-4xl text-gray-300 w-1/3 cursor-pointer'
													onClick={() => {
														if (qty >= 2) {
															setQty(qty - 1)
														}
													}}
												>
													-
												</span>
												<div className='text-2xl'>
													{qty}
												</div>
												<span
													color='default'
													className='text-2xl text-gray-300 w-1/3 cursor-pointer'
													onClick={() => {
														// if (qty < user.boxCount)
														setQty(qty + 1)
													}}
												>
													+
												</span>
											</div>
											{selectedReturnPartner &&
												selectedReturnPartner.partner &&
												(selectedReturnPartner.partner !=
													'gently' ||
													selectedReturnPartner.partner ===
														'uberdirect') && (
													<div>
														{promoMessage ===
														'Promo code applied!' ? (
															<div className='my-4 text-green-600'>
																{promoMessage}
															</div>
														) : isMember ? (
															<div className='my-4 text-green-600'>
																Free pickup!
																Thanks for being
																a DeliverZero+
																member.
															</div>
														) : !user.paymentMethods ||
														  (user.paymentMethods &&
																user
																	.paymentMethods
																	.length ===
																	0) ? (
															<div className='text-left mt-3 mb-4'>
																<div className='mb-1'>
																	{selectedReturnPartner &&
																	selectedReturnPartner.partner ===
																		'uberdirect' ? (
																		uberQuote &&
																		uberQuote.quote ? (
																			<div className='mb-2'>
																				<b>
																					Pickup
																					fee:{' '}
																					{`$${(
																						JSON.parse(
																							uberQuote.quote
																						)
																							.fee /
																						100
																					).toFixed(
																						2
																					)}`}
																				</b>
																				<div className='text-left mt-2 mb-2'>
																					Tip
																					amount:
																					$
																					{
																						tipAmount
																					}
																				</div>
																				<RadioGroup
																					horizontal
																					name='tips'
																					radios={[
																						{
																							label: '$1',
																							value: 1,
																						},
																						{
																							label: '$3',
																							value: 3,
																						},
																						{
																							label: '$5',
																							value: 5,
																						},
																						{
																							label: 'None',
																							value: 0,
																						},
																					]}
																					onChange={(
																						tip
																					) => {
																						setTipAmount(
																							tip
																						)
																						const fee =
																							parseFloat(
																								JSON.parse(
																									uberQuote.quote
																								)
																									.fee
																							) /
																							100
																						const totalAmount =
																							fee +
																							parseFloat(
																								tip
																							)
																						const roundedTotal =
																							totalAmount.toFixed(
																								2
																							)
																						setUberdirectTotal(
																							roundedTotal
																						)
																					}}
																				/>
																			</div>
																		) : (
																			<BounceLoader
																				className='m-auto'
																				color={
																					bounceLoaderColor
																				}
																			></BounceLoader>
																		)
																	) : (
																		<b>
																			Pickup
																			fee:{' '}
																			{`$${appInfo.pickupAtDoorFee.toFixed(
																				2
																			)}`}
																		</b>
																	)}
																</div>
																{loggedIn ? (
																	<>
																		<div className='mt-1 mb-2 text-lg font-semibold'>
																			Select
																			a
																			card
																			to
																			continue.
																		</div>
																		{!loadingAddition &&
																			!stripeLoading &&
																			!loadingUser &&
																			!loadingPaymentMethods && (
																				<div>
																					{user.paymentMethods &&
																					cardRadios.length >
																						0 ? (
																						<div>
																							<RadioGroup
																								className='mt-1 sm:mt-0'
																								name='sortBy'
																								radios={
																									cardRadios
																								}
																								onChange={(
																									paymentMethodId
																								) => {
																									setSelectedPaymentMethod(
																										paymentMethodId
																									)
																								}}
																							/>
																						</div>
																					) : (
																						<div>
																							<div className='font-semibold'>
																								No
																								cards
																								on
																								file
																							</div>
																						</div>
																					)}
																				</div>
																			)}
																		{loadingPaymentMethods && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		{loadingAddition && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		{loadingUser && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		{stripeLoading && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		<div className='w-[90%] max-w-[800px] mb-3'>
																			<div>
																				{!showCardForm && (
																					<div>
																						<button
																							className='my-3 text-lg underline text-green-600 font-semibold'
																							onClick={() => {
																								setShowCardForm(
																									true
																								)

																								onResetCardAdded()
																							}}
																						>
																							Add
																							a
																							card
																						</button>
																					</div>
																				)}
																				{showCardForm && (
																					<div>
																						<div className='mt-4'>
																							<div className='text-sm'>
																								Enter
																								card
																								info
																								below
																							</div>

																							<form
																								onSubmit={
																									handleAddCardSubmit
																								}
																							>
																								<CardElement
																									options={
																										cardElementOptions
																									}
																								/>
																								{!loadingAddition &&
																									!stripeLoading &&
																									!loadingUser && (
																										<div>
																											<Button
																												className='mt-2'
																												text='Add Card'
																												size='sm'
																												submit={
																													true
																												}
																											/>
																										</div>
																									)}
																							</form>
																						</div>
																						<div>
																							{!loadingAddition &&
																								!stripeLoading &&
																								!loadingUser && (
																									<button
																										className='mt-6 text-[16px] underline text-green-600 font-semibold'
																										onClick={() => {
																											setShowCardForm(
																												false
																											)
																										}}
																									>
																										Cancel
																									</button>
																								)}
																						</div>
																					</div>
																				)}
																			</div>
																		</div>
																	</>
																) : (
																	<>
																		{cardRadios &&
																		cardRadios.length >
																			0 ? (
																			<>
																				<div>
																					<RadioGroup
																						className='mt-1 sm:mt-0'
																						name='sortBy'
																						radios={
																							cardRadios
																						}
																						onChange={(
																							paymentMethodId
																						) => {
																							setSelectedPaymentMethod(
																								paymentMethodId
																							)
																						}}
																					/>
																				</div>
																				<div className='w-[90%] max-w-[800px] mb-3'>
																					<div>
																						{!showCardForm && (
																							<div>
																								<button
																									className='my-3 text-lg underline text-green-600 font-semibold'
																									onClick={() => {
																										setShowCardForm(
																											true
																										)
																										onResetCardAdded()
																									}}
																								>
																									Add
																									a
																									card
																								</button>
																							</div>
																						)}
																						{showCardForm && (
																							<div>
																								<div className='mt-4'>
																									<div className='text-sm'>
																										Enter
																										card
																										info
																										below
																									</div>

																									<form
																										onSubmit={
																											handleAddCardSubmit
																										}
																									>
																										<CardElement
																											options={
																												cardElementOptions
																											}
																										/>
																										{!loadingAddition &&
																											!stripeLoading &&
																											!loadingUser && (
																												<div>
																													<Button
																														className='mt-2'
																														text='Add Card'
																														size='sm'
																														submit={
																															true
																														}
																													/>
																												</div>
																											)}
																									</form>
																								</div>
																								<div>
																									{!loadingAddition &&
																										!stripeLoading &&
																										!loadingUser && (
																											<button
																												className='mt-6 text-[16px] underline text-green-600 font-semibold'
																												onClick={() => {
																													setShowCardForm(
																														false
																													)
																												}}
																											>
																												Cancel
																											</button>
																										)}
																								</div>
																							</div>
																						)}
																					</div>
																				</div>
																			</>
																		) : (
																			<form
																				onSubmit={
																					handleAddCardGuest
																				}
																				className='w-full sm:w-[500px]'
																			>
																				<CardElement
																					options={
																						cardElementOptions
																					}
																				/>
																				{cardAdded ? (
																					<div className='text-green-600 font-bold'>
																						Card
																						successfully
																						added!
																					</div>
																				) : (
																					<div></div>
																				)}
																				{paymentError ? (
																					<div className='text-red-600 font-bold'>
																						There
																						was
																						an
																						error
																						adding
																						your
																						card.
																						Please
																						try
																						again.
																					</div>
																				) : (
																					<div></div>
																				)}
																				{!loadingAddition &&
																					!stripeLoading &&
																					!loadingUser && (
																						<div>
																							<Button
																								className={`mt-2 px-2`}
																								text='Add Card'
																								size='sm'
																								submit={
																									true
																								}
																							/>
																						</div>
																					)}
																				{loadingAddition && (
																					<div>
																						<BounceLoader
																							className='m-auto'
																							color={
																								bounceLoaderColor
																							}
																						></BounceLoader>
																					</div>
																				)}
																				{stripeLoading && (
																					<div>
																						<BounceLoader
																							className='m-auto'
																							color={
																								bounceLoaderColor
																							}
																						></BounceLoader>
																					</div>
																				)}
																			</form>
																		)}
																	</>
																)}
															</div>
														) : (
															<div className='text-left mt-3 mb-4'>
																<div className='mb-1'>
																	{selectedReturnPartner &&
																	selectedReturnPartner.partner ===
																		'uberdirect' ? (
																		uberQuote &&
																		uberQuote.quote ? (
																			<div className='mb-5'>
																				<b>
																					Pickup
																					fee:{' '}
																					{`$${(
																						JSON.parse(
																							uberQuote.quote
																						)
																							.fee /
																						100
																					).toFixed(
																						2
																					)}`}
																				</b>
																				<div className='text-left mt-2 mb-2'>
																					Tip
																					amount:
																					$
																					{
																						tipAmount
																					}
																				</div>
																				<RadioGroup
																					horizontal
																					name='tips'
																					radios={[
																						{
																							label: '$1',
																							value: 1,
																						},
																						{
																							label: '$3',
																							value: 3,
																						},
																						{
																							label: '$5',
																							value: 5,
																						},
																						{
																							label: 'None',
																							value: 0,
																						},
																					]}
																					onChange={(
																						tip
																					) => {
																						setTipAmount(
																							tip
																						)
																						const fee =
																							parseFloat(
																								JSON.parse(
																									uberQuote.quote
																								)
																									.fee
																							) /
																							100
																						const totalAmount =
																							fee +
																							parseFloat(
																								tip
																							)
																						const roundedTotal =
																							totalAmount.toFixed(
																								2
																							)
																						setUberdirectTotal(
																							roundedTotal
																						)
																					}}
																				/>
																			</div>
																		) : (
																			<BounceLoader
																				className='m-auto'
																				color={
																					bounceLoaderColor
																				}
																			></BounceLoader>
																		)
																	) : (
																		<b>
																			Pickup
																			fee:{' '}
																			{`$${appInfo.pickupAtDoorFee.toFixed(
																				2
																			)}`}
																		</b>
																	)}
																</div>
																{loggedIn ? (
																	<>
																		<div className='mt-1 mb-2 text-lg font-semibold'>
																			Select
																			a
																			card
																			to
																			continue.
																		</div>
																		{!loadingAddition &&
																			!stripeLoading &&
																			!loadingUser &&
																			!loadingPaymentMethods && (
																				<div>
																					{user.paymentMethods &&
																					cardRadios.length >
																						0 ? (
																						<div>
																							<RadioGroup
																								className='mt-1 sm:mt-0'
																								name='sortBy'
																								radios={
																									cardRadios
																								}
																								onChange={(
																									paymentMethodId
																								) => {
																									setSelectedPaymentMethod(
																										paymentMethodId
																									)
																								}}
																							/>
																						</div>
																					) : (
																						<div>
																							<div className='font-semibold'>
																								No
																								cards
																								on
																								file
																							</div>
																						</div>
																					)}
																				</div>
																			)}
																		{loadingPaymentMethods && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		{loadingAddition && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		{loadingUser && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		{stripeLoading && (
																			<div className='mt-3 text-center'>
																				<BounceLoaderCentered container='div' />
																			</div>
																		)}
																		<div className='w-[90%] max-w-[800px] mb-3'>
																			<div>
																				{!showCardForm && (
																					<div>
																						<button
																							className='my-3 text-lg underline text-green-600 font-semibold'
																							onClick={() => {
																								setShowCardForm(
																									true
																								)

																								onResetCardAdded()
																							}}
																						>
																							Add
																							a
																							card
																						</button>
																					</div>
																				)}
																				{showCardForm && (
																					<div>
																						<div className='mt-4'>
																							<div className='text-sm'>
																								Enter
																								card
																								info
																								below
																							</div>

																							<form
																								onSubmit={
																									handleAddCardSubmit
																								}
																							>
																								<CardElement
																									options={
																										cardElementOptions
																									}
																								/>
																								{!loadingAddition &&
																									!stripeLoading &&
																									!loadingUser && (
																										<div>
																											<Button
																												className='mt-2'
																												text='Add Card'
																												size='sm'
																												submit={
																													true
																												}
																											/>
																										</div>
																									)}
																							</form>
																						</div>
																						<div>
																							{!loadingAddition &&
																								!stripeLoading &&
																								!loadingUser && (
																									<button
																										className='mt-6 text-[16px] underline text-green-600 font-semibold'
																										onClick={() => {
																											setShowCardForm(
																												false
																											)
																										}}
																									>
																										Cancel
																									</button>
																								)}
																						</div>
																					</div>
																				)}
																			</div>
																		</div>
																	</>
																) : (
																	<>
																		{cardRadios &&
																		cardRadios.length >
																			0 ? (
																			<>
																				<div>
																					<RadioGroup
																						className='mt-1 sm:mt-0'
																						name='sortBy'
																						radios={
																							cardRadios
																						}
																						onChange={(
																							paymentMethodId
																						) => {
																							setSelectedPaymentMethod(
																								paymentMethodId
																							)
																						}}
																					/>
																				</div>
																				<div className='w-[90%] max-w-[800px] mb-3'>
																					<div>
																						{!showCardForm && (
																							<div>
																								<button
																									className='my-3 text-lg underline text-green-600 font-semibold'
																									onClick={() => {
																										setShowCardForm(
																											true
																										)
																										onResetCardAdded()
																									}}
																								>
																									Add
																									a
																									card
																								</button>
																							</div>
																						)}
																						{showCardForm && (
																							<div>
																								<div className='mt-4'>
																									<div className='text-sm'>
																										Enter
																										card
																										info
																										below
																									</div>

																									<form
																										onSubmit={
																											handleAddCardSubmit
																										}
																									>
																										<CardElement
																											options={
																												cardElementOptions
																											}
																										/>
																										{!loadingAddition &&
																											!stripeLoading &&
																											!loadingUser && (
																												<div>
																													<Button
																														className='mt-2'
																														text='Add Card'
																														size='sm'
																														submit={
																															true
																														}
																													/>
																												</div>
																											)}
																									</form>
																								</div>
																								<div>
																									{!loadingAddition &&
																										!stripeLoading &&
																										!loadingUser && (
																											<button
																												className='mt-6 text-[16px] underline text-green-600 font-semibold'
																												onClick={() => {
																													setShowCardForm(
																														false
																													)
																												}}
																											>
																												Cancel
																											</button>
																										)}
																								</div>
																							</div>
																						)}
																					</div>
																				</div>
																			</>
																		) : (
																			<form
																				onSubmit={
																					handleAddCardGuest
																				}
																				className='w-full sm:w-[500px]'
																			>
																				<CardElement
																					options={
																						cardElementOptions
																					}
																				/>
																				{cardAdded ? (
																					<div className='text-green-600 font-bold'>
																						Card
																						successfully
																						added!
																					</div>
																				) : (
																					<div></div>
																				)}
																				{paymentError ? (
																					<div className='text-red-600 font-bold'>
																						There
																						was
																						an
																						error
																						adding
																						your
																						card.
																						Please
																						try
																						again.
																					</div>
																				) : (
																					<div></div>
																				)}
																				{!loadingAddition &&
																					!stripeLoading &&
																					!loadingUser && (
																						<div>
																							<Button
																								className={`mt-2 px-2`}
																								text='Add Card'
																								size='sm'
																								submit={
																									true
																								}
																							/>
																						</div>
																					)}
																				{loadingAddition && (
																					<div>
																						<BounceLoader
																							className='m-auto'
																							color={
																								bounceLoaderColor
																							}
																						></BounceLoader>
																					</div>
																				)}
																				{stripeLoading && (
																					<div>
																						<BounceLoader
																							className='m-auto'
																							color={
																								bounceLoaderColor
																							}
																						></BounceLoader>
																					</div>
																				)}
																			</form>
																		)}
																	</>
																)}
															</div>
														)}
													</div>
												)}
											{creatingTransaction ||
											chargingCard ? (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											) : (
												<div>
													{invalidAddress ||
													qty < 1 ||
													(partners &&
														selectedReturnPartner &&
														Object.keys(
															selectedReturnPartner
														).length !== 0 &&
														selectedReturnPartner.pickupFee &&
														selectedReturnPartner.pickupFee >
														0 &&
														selectedPaymentMethod ===
														'' &&
														promoMessage !==
														'Promo code applied!' &&
														!isMember) ? (
														<Button
															text='Submit'
															size='sm'
															color='gray'
															onClick={() => {}}
															className='px-3 py-2 mt-2 w-[175px]'
														/>
													) : (
														<div>
															{!selectedReturnPartner ||
															(selectedReturnPartner &&
																Object.keys(
																	selectedReturnPartner
																).length === 0) ||
															(selectedPaymentMethod ===
																'' &&
																![
																	'uberdirect',
																	'gently',
																].includes(
																	selectedReturnPartner.partner
																)) ||
															qty < 1 ||
															(selectedReturnPartner &&
																selectedReturnPartner.partner ===
																'uberdirect' &&
																(!uberQuote ||
																	(selectedPaymentMethod ===
																		"" &&
																		promoMessage !==
																		"Promo code applied!" && !isMember) ||
																	qty < 1)) ? (
																<Button
																	text='Submit'
																	size='sm'
																	color='gray'
																	onClick={() => {}}
																	className='px-3 py-2 mt-2 w-[175px]'
																/>
															) : (
																<Button
																	text='Submit'
																	size='sm'
																	color='green'
																	onClick={(
																		e
																	) =>
																		submitHandler(
																			e
																		)
																	}
																	className='px-3 py-2 mt-2 w-[175px]'
																/>
															)}
														</div>
													)}
												</div>
											)}
											{errorMessage !== '' && (
												<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
													{errorMessage}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							<div
								className='h-32 bg-red-400 w-full'
								style={{
									clipPath:
										'polygon( 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%)',
								}}
							></div>
						</div>
					</div>
				)}
			</div>
		)
	} else {
		// return (
		//     <div className=' h-full w-full flex flex-col justify-between '>
		//         <Header />
		//         <div className='flex flex-col items-center h-screen-no-header w-full text-center  bg-yellow-400 lg:h-screen-no-header'>
		//             <h1 className='p-8 text-6xl text-green-600 font-header sm:text-6xl '>
		//                 Schedule a Pickup
		//             </h1>
		//             <div className='flex flex-col mx-[10%] w-[80%] h-[750px] py-3 px-5 mb-12 shadow-lighter-grey bg-white rounded-[10px]'>
		//                 <div className='text-left sm:text-center text-base md:text-[20px] font-semibold'>
		//                     Return containers at your door. Leave your containers in a safe spot for our couriers to pick up.
		//                 </div>
		//                 <div className='mt-3 text-left sm:text-center text-base font-light'>
		//                     Select your pickup address
		//                 </div>
		//                 <div className='mt-3 text-center'>
		//                     <BounceLoader
		//                         className='m-auto'
		//                         color={bounceLoaderColor}
		//                     ></BounceLoader>
		//                 </div>
		//             </div>
		//             <div
		//                 className='h-32 bg-red-400 w-full'
		//                 style={{
		//                     clipPath:
		//                         'polygon( 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%)',
		//                 }}
		//             ></div>
		//         </div>
		//         <Footer />
		//     </div>
		// )
		return (
			<div className='flex w-full h-screen-no-header justify-center align-center'>
				<BounceLoaderCentered container='div' />
			</div>
		)
	}
}
const mapStateToProps = ({
	User,
	AppInfo,
	ReverseLogistics,
	TransactionsCreate,
	Payments,
	Logistics,
	Promos,
	Pickups,
}) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	appInfo: AppInfo.appInfo,
	partners: ReverseLogistics.partners,
	loadingPartners: ReverseLogistics.loadingPartners,
	partnersError: ReverseLogistics.partnersError,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
	partner: ReverseLogistics.partner,
	loadingPartner: ReverseLogistics.loadingPartner,
	partnerError: ReverseLogistics.partnerError,
	chargingCard: Payments.chargingCard,
	chargingCardError: Payments.chargingCardError,
	chargingCardResponse: Payments.chargingCardResponse,
	paymentMethods: Payments.paymentMethods,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
	paymentError: Payments.error,
	pendingPickup: Pickups.pickup,
	pendingPickups: Pickups.pendingPickups,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pickupLoading: Pickups.pickupLoading,
	loading: Logistics.loading,
	uberRequest: Logistics.uberRequest,
	uberQuote: Logistics.uberQuote,
	error: Logistics.error,
	requestError: Logistics.requestError,
	promoError: Promos.error,
	promoIsActive: Promos.promoIsActive,
	promoLoading: Promos.loading,
	promoMessageType: Promos.promoMessageType,
	promo: Promos.promo,
	updatedPromo: Promos.updatedPromo,
	giftCardIsActive: Promos.giftCardIsActive,
	giftCard: Promos.giftCard,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetUserByPhone: (payload) => dispatch(getUserByPhone(payload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetRlPartnersByPolygon: (coords) =>
		dispatch(getRlPartnersByPolygon(coords)),
	onGetRlPartner: (query) => dispatch(getRlPartner(query)),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onChargeCustomer: (payload) => dispatch(chargePaymentMethod(payload)),
	onResetCardResponse: () => dispatch(resetCardResponse()),
	onStorePaymentGuest: (payload) => dispatch(storeGuestPayment(payload)),
	onResetCardAdded: () => dispatch(resetCardResponse()),
	onCaptureIP: (payload) => dispatch(captureIP(payload)),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
	onCreatePendingPickup: (payload) => dispatch(createPendingPickup(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
	onGetUberDirectQuote: (payload) => dispatch(getUberDirectQuote(payload)),
	onCreateUberDirectRequest: (payload) =>
		dispatch(createUberDirectRequest(payload)),
	onValidatePromo: (promoCode) => dispatch(validatePromo(promoCode)),
	onUpdatePromo: (payload) => dispatch(updatePromo(payload)),
	onResetPromo: () => dispatch(resetPromo()),
	onGetPendingPickupsByPhone: (payload) =>
		dispatch(getPickupsByPhone(payload)),
	onAlertSlack: (payload) => dispatch(alertSlack(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnAtDoorPhone)

{
	/* <svg class="absolute t-0 z-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#000b29" fill-opacity="1" d="M0,32L48,80C96,128,192,224,288,224C384,224,480,128,576,112C672,96,768,160,864,154.7C960,149,1056,75,1152,90.7C1248,107,1344,213,1392,266.7L1440,320L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> */
}
