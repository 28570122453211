import { realm } from '../helpers/realm'

export const getRestaurantPayments = (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const orders = client.db('caas').collection('restaurant_payments')

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const stages = [
		{
			$lookup: {
				from: 'nodes',
				localField: 'nodeSlug',
				foreignField: 'slug',
				as: 'nodeInfo',
			},
		},
		{
			$unwind: {
				path: '$nodeInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}
	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

	return orders.aggregate(stages)
}

export const sendRestaurantPayments = async (restaurantPaymentsIdList) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const updatedRestaurantPayments = await realm.currentUser.callFunction(
		'sendRestaurantPayments',
		restaurantPaymentsIdList
	)

	return updatedRestaurantPayments
}

export const getRestaurantCharges = (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const charges = client.db('caas').collection('restaurant_charges')

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const stages = [
		{
			$lookup: {
				from: 'nodes',
				localField: 'nodeSlug',
				foreignField: 'slug',
				as: 'nodeInfo',
			},
		},
		{
			$lookup: {
				from: 'meal_delivery_services',
				localField: 'nodeSlug',
				foreignField: 'slug',
				as: 'mealDeliveryInfo',
			},
		},
		{
			$unwind: {
				path: '$nodeInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
		{
			$unwind: {
				path: '$mealDeliveryInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

	return charges.aggregate(stages)
}

export const chargeRestaurants = async (restaurantChargesIdList) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const updatedRestaurantPayments = await realm.currentUser.callFunction(
		'chargeRestaurants',
		restaurantChargesIdList
	)

	return updatedRestaurantPayments
}
