import {
	GET_CONTAINERS,
	GET_CONTAINERS_FAIL,
	GET_CONTAINERS_SUCCESS,
	GET_CONTAINER_BY_ID,
	GET_CONTAINER_BY_ID_FAIL,
	GET_CONTAINER_BY_ID_SUCCESS,
} from './actionTypes'

const initialState = {
	containers: null,
	loadingContainers: false,
	containersError: null,
	container: null,
	loadingContainer: false,
	containerError: null,
}

const containers = (state = initialState, action) => {
	switch (action.type) {
		case GET_CONTAINERS:
			return {
				...state,
				loadingContainers: true,
			}
		case GET_CONTAINERS_SUCCESS:
			return {
				...state,
				loadingContainers: false,
				containers: action.payload,
			}

		case GET_CONTAINERS_FAIL:
			return {
				...state,
				loadingContainers: false,
				containersError: action.payload,
			}
		case GET_CONTAINER_BY_ID:
			return {
				...state,
				loadingContainer: true,
			}
		case GET_CONTAINER_BY_ID_SUCCESS:
			return {
				...state,
				loadingContainer: false,
				container: action.payload,
			}
		case GET_CONTAINER_BY_ID_FAIL:
			return {
				...state,
				loadingContainer: false,
				containerError: action.payload,
			}
		default:
			return state
	}
}
export default containers
