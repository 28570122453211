import {
	CONNECT_TO_CLIENT_FAILED,
	CONNECT_TO_CLIENT,
	CONNECT_TO_CLIENT_SUCCESSFUL,
	AUTHORIZE_CHOWNOW,
	AUTHORIZE_CHOWNOW_SUCCESSFUL,
	AUTHORIZE_CHOWNOW_FAILED,
	GET_SQUARE_AUTH_LINK,
	GET_SQUARE_AUTH_LINK_SUCCESS,
	GET_SQUARE_AUTH_LINK_ERROR,
} from './actionTypes'

const initialState = {
	connectionError: false,
	errorMessage: null,
	connecting: false,
	connected: false,
	authorizing: false,
	authorizeLink: '',
	authorized: false,
	authorizeError: false,
	authorizeErrorMessage: null,
	loadingSquareAuthLink: false,
	squareAuthLink: null,
	squareAuthLinkError: null,
}

const oauth = (state = initialState, action) => {
	switch (action.type) {
		case CONNECT_TO_CLIENT:
			state = {
				...state,
				connecting: true,
				connectionError: false,
				errorMessage: null,
			}
			break
		case CONNECT_TO_CLIENT_SUCCESSFUL:
			state = {
				...state,
				connecting: false,
				connected: true,
				connectionError: false,
				errorMessage: null,
			}
			break
		case CONNECT_TO_CLIENT_FAILED:
			state = {
				...state,
				connecting: false,
				connected: false,
				connectionError: true,
				errorMessage: action.payload,
			}
			break
		case AUTHORIZE_CHOWNOW:
			state = {
				...state,
				authorizing: true,
				authorizeError: false,
				authorizeErrorMessage: null,
			}
			break
		case AUTHORIZE_CHOWNOW_SUCCESSFUL:
			state = {
				...state,
				authorizing: false,
				authorized: true,
				authorizeError: false,
				authorizeErrorMessage: null,
				authorizeLink: action.payload,
			}
			break
		case AUTHORIZE_CHOWNOW_FAILED:
			state = {
				...state,
				authorizing: false,
				authorized: false,
				authorizeError: true,
				authorizeErrorMessage: action.payload,
			}
			break
		case GET_SQUARE_AUTH_LINK:
			return {
				...state,
				loadingSquareAuthLink: true,
				squareAuthLink: null,
				squareAuthLinkError: null,
			};
		case GET_SQUARE_AUTH_LINK_SUCCESS:
			return {
				...state,
				loadingSquareAuthLink: false,
				squareAuthLink: action.payload,
				squareAuthLinkError: null,
			};
		case GET_SQUARE_AUTH_LINK_ERROR:
			return {
				...state,
				loadingSquareAuthLink: false,
				squareAuthLink: null,
				squareAuthLinkError: action.payload,
			};
		default:
			state = { ...state }
			break
	}
	return state
}

export default oauth
