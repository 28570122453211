import { call, put, takeEvery } from 'redux-saga/effects'
import { createSubscriptionSuccess, createSubscriptionFail } from './actions'
import { CREATE_SUBSCRIPTION } from './actionTypes'
import { realm } from '../../helpers/realm'

function* createSubscription(action) {
	try {
		const stripeCreateSubscription =
			realm.currentUser.functions.stripeCreateSubscription
		const subscription = yield call(
			stripeCreateSubscription,
			action.payload
		)
		yield put(createSubscriptionSuccess(subscription))
	} catch (error) {
		yield put(createSubscriptionFail(error))
	}
}

function* subscriptionsSaga() {
	yield takeEvery(CREATE_SUBSCRIPTION, createSubscription)
}

export default subscriptionsSaga
