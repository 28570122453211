import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import { Footer } from '../../components'
import {
	connectToClient,
	authorizeChownow,
} from '../../store/auth/oauth/actions'
import { Button } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'

const ChowNowLanding = (props) => {
	const {
		connectionError,
		connecting,
		connected,
		errorMessage,
		onConnectToClient,
		authorizeError,
		authorizing,
		authorized,
		authorizeErrorMessage,
		onAuthorizeChownow,
		authorizeLink,
	} = props
	const bounceLoaderColor = '#507f74'

	useEffect(() => {
		if (authorized && authorizeLink) {
			window.location.href = authorizeLink
		}
	}, [authorized, authorizeLink])

	const handleClickAuthorize = () => {
		onAuthorizeChownow({})
	}

	const openSupport = () => {
		window.Intercom('show')
	}

	return (
		<div>
			<Header />
			{authorizing && (
				<div className='mt-3 text-center'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)}
			<div className='h-screen-3/4'>
				<h1 className='font-vollkorn text-green-600 lg:text-4xl text-3xl mt-14 text-center'>
					Add DeliverZero to your ChowNow!
				</h1>
				<div className='mt-6 text-center lg:mx-auto mx-5 px-4 max-w-[800px] lg:text-base text-sm'>
					Together we can clean up the takeout industry and our
					planet.
				</div>
				<div className='my-6 text-center lg:mx-auto mx-5 px-4 max-w-[800px] lg:text-base text-sm'>
					<span className='font-bold lg:text-base text-sm'>
						Here's how!{` `}
					</span>
					Click the button below to authorize ChowNow to connect to
					DeliverZero. You'll get redirected to ChowNow to login, then
					will get redirected back to DeliverZero after successful
					authorization.
				</div>
				<div className='max-w-[800px] mx-auto px-4 flex justify-center'>
					<Button
						text={'Authorize ChowNow'}
						size='sm'
						className='lg:w-[350px] w-[250px]'
						onClick={handleClickAuthorize}
					></Button>
				</div>
				<div className='font-bold mt-10 text-center lg:mx-auto mx-8 max-w-[800px] lg:text-base text-sm'>
					Need assistance? Click{' '}
					<span
						onClick={openSupport}
						className='cursor-pointer text-green-600'
					>
						here{` `}
					</span>
					to contact support or email restaurants@deliverzero.com
				</div>
				<div className='lg:my-6 mb-15 text-center lg:mx-auto mx-5 px-4 max-w-[800px] lg:text-base text-sm'>
					We're thrilled to work with you!
				</div>
			</div>
			<Footer />
		</div>
	)
}
const mapStateToProps = ({ OAuth }) => ({
	connectionError: OAuth.connectionError,
	connecting: OAuth.connecting,
	connected: OAuth.connected,
	errorMessage: OAuth.errorMessage,
	authorizeError: OAuth.authorizeError,
	authorizing: OAuth.authorizing,
	authorized: OAuth.authorized,
	authorizeErrorMessage: OAuth.authorizeErrorMessage,
	authorizeLink: OAuth.authorizeLink,
})

const mapDispatchToProps = (dispatch) => ({
	onConnectToClient: (payload) => dispatch(connectToClient(payload)),
	onAuthorizeChownow: (payload) => dispatch(authorizeChownow(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChowNowLanding)
