import React from 'react'
import { BounceLoaderCentered } from '../Elements'
import { BsFillTreeFill } from 'react-icons/bs'
import { IoIosWater } from 'react-icons/io'

const EnvironmentalImpactCard = ({
	packagingPrevented = 0,
	environmentalImpact = null,
}) => {
	if (environmentalImpact != null) {
		const ghgReduction =
			packagingPrevented *
			environmentalImpact.gramsEmissionsReductionPerPackage
		const waterReduction =
			packagingPrevented *
			environmentalImpact.gallonsWaterReductionPerPackage

		return (
			// <div className='mb-10 mx-auto md:ml-10 min-w-[300px] md:min-w-[360px] lg:min-w-[720px] h-[350px] shadow-lighter-grey w-full max-w-xs flex sm:flex-row justify-between rounded-xl bg-white p-6'>
			<div className=' card'>
				<h3
					className={`mt-2 block font-header text-2xl sm:text-3xl text-green-400`}
				>
					Environmental Impact
				</h3>
				<div className='mt-3 mb-2 text-md lg:text-[18px]'>
					So far you've prevented
				</div>
				<div className='flex flex-row'>
					<div className='text-5xl font-header w-min lg:pb-1 mr-3 text-green-600'>
						{packagingPrevented}
					</div>
					<div className='text-md lg:text-[18px] my-auto w-full'>
						single-use boxes from harming our planet
					</div>
				</div>
				{environmentalImpact && (
					<div>
						<div className='text-[13px] lg:text-md'>
							That's equivalent to avoiding an estimated
						</div>
						{ghgReduction.toString().length > 3 && (
							<div className=''>
								<BsFillTreeFill className='mr-1 inline-block text-green-600 text-[24px] lg:text-[32px] ' />
								<span className='text-[18px] lg:text-[24px] font-header w-min pb-3 mr-1 text-green-600'>
									{(ghgReduction / 1000).toFixed(2)}
								</span>
								<span className='text-[13px] lg:text-md'>
									{' '}
									kg of greenhouse gas emissions
								</span>
							</div>
						)}
						{ghgReduction.toString().length <= 3 && (
							<div className=''>
								<BsFillTreeFill className='mr-2 inline-block text-green-600 text-[24px] lg:text-[32px] ' />
								<span className='text-[18px] lg:text-3xl font-header w-min mr-1 text-green-600'>
									{ghgReduction.toFixed(2)}
								</span>
								<span className='text-[13px] lg:text-md'>
									g of greenhouse gas emissions
								</span>
							</div>
						)}
						<div className=''>
							<IoIosWater className='mr-2 inline-block text-blue-600 text-[24px] lg:text-[32px] ' />
							<span className='text-[18px] lg:text-3xl font-header w-min mr-1 text-blue-600'>
								{waterReduction.toFixed(2)}
							</span>
							<span className='text-[13px] lg:text-md'>
								{' '}
								gallons of water{' '}
							</span>
						</div>
						<div className='mt-1 text-[9px]'>
							The above values are estimates based on best
							available research
						</div>
					</div>
				)}
				{!environmentalImpact && (
					<div className='mt-3 text-center'>
						<BounceLoaderCentered container='div' />
					</div>
				)}

				{/*<div className="hidden mt-5 lg:inline-block">*/}
				{/*    <Button link="/why-reuse" text="Why Reuse?" className="mt-6"/></div>*/}
				{/*<Link to="/why-reuse" className="inline-block lg:hidden my-3 text-[16px] underline text-green-600 font-semibold">Find out more about how reuse benefits our climate and planet.</Link>*/}
			</div>
		)
	}
}

export default EnvironmentalImpactCard
