import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Input } from './index'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { updateUser } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import { BsPinFill } from 'react-icons/bs'
import { getGPSLocation, captureGPS } from '../../store/actions'

const LocationSearchBar = (props) => {
	const google = window.google
	const {
		className = '',
		buttonText = 'Order Now',
		size = 'base',
		buttonColor = 'green',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		onGetGPSLocation,
		path,
		hideButton = false,
		userGPSLocation,
		userGPSLocationError,
		gpsLocationFound,
		onLocationWarningChange,
		onCaptureGPS,
		...newProps
	} = props
	const bounceLoaderColor = '#507f74'
	let inputSize = `h-`
	let iconSize = `h-`

	if (size === 'sm') {
		inputSize += '11'
		iconSize += '11'
	} else if (size === 'xs') {
		inputSize += '10 py-1'
		iconSize += '11'
	} else if (size === 'base') {
		inputSize += '14'
		iconSize += '14'
	}

	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [inputHasValue, setInputHasValue] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const [searchSavedAddress, setSearchSavedAddress] = useState(false)
	const [searchByGPS, setSearchByGPS] = useState(false) // when user clicks 'use current loc'
	const [userLoc, setUserLoc] = useState(null)
	const [locationWarning, setLocationWarning] = useState(false)
	const [locationPermission, setLocationPermission] = useState(null)

	let navigate = useNavigate()

	const handlePermission = () => {
		navigator.permissions
			.query({ name: 'geolocation' })
			.then((permissionStatus) => {
				setLocationPermission(permissionStatus.state)
			})
			.catch((error) => {
				console.error('Error getting permission status:', error)
			})
	}

	useEffect(() => {
		// callback function whenever locationWarning changes
		onLocationWarningChange(locationWarning)
		handlePermission()
	}, [locationWarning, onLocationWarningChange])

	useEffect(() => {
		const lastSearchedAddress = JSON.parse(
			localStorage.getItem('lastSearchedAddress')
		)
		if (lastSearchedAddress && lastSearchedAddress.address) {
			setSearchedAddress(lastSearchedAddress.address)
		} else if (user && user.lastSearchedAddress) {
			setSearchedAddress(user.lastSearchedAddress)
		} else {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const crd = position.coords
					reverseGeocodeAddress({
						lat: parseFloat(crd.latitude),
						lng: parseFloat(crd.longitude),
					})
					setUserLoc(searchedAddress)
					setLocationWarning(false)
				},
				(e) => {
					if (user && user.address) {
						setSearchedAddress(user.address)
					} else {
						// console.log("error", e)
						setSearchedAddress('43 Park Row, New York, NY 10038')
					}
					setLocationWarning(true)
				},
				{ enableHighAccuracy: true, timeout: 5000 }
			)
		}
	}, [
		user,
		user?.lastSearchedAddress,
		navigator,
		JSON.stringify(navigator),
		locationPermission,
	])

	useEffect(() => {
		if (userLoc) {
			setSearchedAddress(userLoc)
			setLocationWarning(false)
		}
	}, [userLoc])

	const reverseGeocodeAddress = async (coords) => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{ location: coords },
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setSearchedAddress(results[0].formatted_address)
				} else {
					setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const geocodeAddress = async () => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: searchedAddress,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// console.log(results[0])
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const handleGPSRequest = () => {
		if (userGPSLocation) {
			reverseGeocodeAddress(userGPSLocation)
			setLocationWarning(false)
		}
		setSearchByGPS(true)
		onGetGPSLocation()
	}

	useEffect(() => {
		if (userGPSLocation && searchByGPS) {
			reverseGeocodeAddress({
				lat: userGPSLocation.lat,
				lng: userGPSLocation.lng,
			})
		}
	}, [userGPSLocation, gpsLocationFound])

	useEffect(() => {
		if (searchedAddress && searchedAddress != '' && searchByGPS) {
			geocodeAddress()
			setSearchByGPS(false)
		}
	}, [searchByGPS, searchedAddress])

	useEffect(() => {
		if (userGPSLocationError) {
			setLocationWarning(true)
		}
	}, [userGPSLocationError])

	const handleAddressChange = (newAddress) => {
		// if (newAddress.target.value) {
		// 	setInputHasValue(true)
		// }
		setAddressError(false)
		setGeoCodedAddress(null)
		if (newAddress.target) {
			// console.log(newAddress.target.value)
			setSearchedAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
		}
	}

	const handleSavedAddressSelect = (newAddress) => {
		setAddressError(false)
		setGeoCodedAddress(null)
		setSearchedAddress(newAddress)
		setSearchSavedAddress(true)
	}

	useEffect(() => {
		if (
			searchedAddress &&
			searchedAddress != '' &&
			searchSavedAddress === true
		) {
			// Geocode the address
			geocodeAddress()
			setSearchSavedAddress(false)
		}
	}, [searchSavedAddress])

	const handleAddressSubmit = async (e) => {
		if (e) e.preventDefault()
		if (searchedAddress && searchedAddress != '') {
			// Geocode the address
			await geocodeAddress()
		}
	}

	useEffect(() => {
		if (geoCodedAddress) {
			const lastSearchedAddress = {
				address: geoCodedAddress,
				lat: geoCodedLat,
				lng: geoCodedLng,
				address2: '',
			}
			localStorage.setItem(
				'lastSearchedAddress',
				JSON.stringify(lastSearchedAddress)
			)
			if (user && user.userId) {
				if (
					user &&
					!user?.savedAddresses?.find(
						(address) => address.savedAddress === geoCodedAddress
					)
				) {
					// new address so add to front of array
					if (user.savedAddresses) {
						onUpdateUser({
							savedAddresses: [
								{
									savedAddress: geoCodedAddress,
									savedAddress2: '',
								},
								...user.savedAddresses,
							],
							lastSearchedAddress: geoCodedAddress,
						})
					} else {
						onUpdateUser({
							savedAddresses: [
								{
									savedAddress: geoCodedAddress,
									savedAddress2: '',
								},
							],
							lastSearchedAddress: geoCodedAddress,
						})
					}
				} else {
					// exisiting address so need to shift it to front to update ordering
					const existingAddressIdx = user.savedAddresses.findIndex(
						(address) => address.savedAddress === geoCodedAddress
					)
					const savedAddress2 =
						user.savedAddresses[existingAddressIdx].savedAddress2
					const updatedSavedAddresses = [...user.savedAddresses]
					updatedSavedAddresses.splice(existingAddressIdx, 1)
					updatedSavedAddresses.unshift({
						savedAddress: geoCodedAddress,
						savedAddress2: savedAddress2,
					})
					onUpdateUser({
						savedAddresses: updatedSavedAddresses,
						lastSearchedAddress: geoCodedAddress,
						lastSearchedAddress2: savedAddress2,
					})
				}
			}

			if (user && user.userId) {
				if (path != '/marketplace/locations') {
					navigate('/marketplace/locations')
				}
			} else {
				if (path == '/marketplace/locations') {
					window.location.reload()
				} else if (
					path.includes('marketplace') &&
					path.includes('locations')
				) {
					navigate('/marketplace/locations')
					window.location.reload()
				} else {
					navigate('/marketplace/locations')
				}
			}
		}
	}, [geoCodedAddress])

	return (
		<div
			className={`relative flex sm:w-3/5 items-center md:min-w-[500px] max-w-[720px] ${className}`}
		>
			<FormGroup
				className='h-full w-full items-center justify-between'
				header
			>
				{loadingUser ? (
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				) : (
					<div>
						<Input
							placeholder='Enter your address'
							className={`relative bg-${inputBackgroundColor} pl-10 ${inputSize}`}
							error={addressError}
							autoCompleteAddress={true}
							value={searchedAddress}
							onChange={handleAddressChange}
							onFocus={() => {
								setIsFocused(true)
							}}
							// onBlur={() => { setIsFocused(false); console.log('onBlur') }}
							// onKeyDown={(e) => {
							// 	// console.log('in key down', e.target.value)
							// 	handleAddressChange(e)
							// 	e.key === 'Enter' && handleAddressSubmit(e)
							// }}
						/>
					</div>
				)}
				{searchedAddress == '' ? (
					<FaMapMarkerAlt
						className={`absolute left-0 ml-4 top-1/2 transform -translate-y-1/2 text-green-400 ${iconSize}`}
					/>
				) : (
					<AiOutlineClose
						className={`font-semibold absolute left-0 ml-4 top-1/2 transform -translate-y-1/2 text-green-600 cursor-pointer ${iconSize}`}
						onClick={() => setSearchedAddress('')}
					/>
				)}
				{searchedAddress == '' && isFocused && (
					<div
						className='absolute left-0 top-11 sm:left-4 sm:top-14 z-50 max-w-[400px] w-full'
						onBlur={() => {
							// console.log('blah')
						}}
					>
						{user?.savedAddresses?.length > 0 && (
							<div className='flex flex-col p-2 bg-white rounded-xl shadow-light-grey border max-h-[300px] max-w-screen overflow-scroll'>
								<div className='flex justify-between items-center text-green-600 font-semibold pl-4 pt-2'>
									Searched Addresses:
									<AiOutlineClose
										className={`font-semibold text-green-600 cursor-pointer align-self-end`}
										onClick={() => setIsFocused(false)}
									/>
								</div>
								<div className='flex flex-col x-50 bg-white items-start justify-start text-start p-2 w-full'>
									<div
										onClick={handleGPSRequest}
										className='text-sm font-bold flex items-center border-b border-t border-gray-200 p-2 m-2 w-full cursor-pointer'
									>
										<BsPinFill
											className={`text-green-400 text-sm mr-2`}
										/>
										Use current location
									</div>
									{user?.savedAddresses?.map((address) => (
										<div
											key={address.savedAddress}
											className='text-sm text-gray-500 cursor-pointer max-h-10 w-full border-b overflow-hidden p-2 m-2 whitespace-nowrap '
											onClick={() => {
												handleSavedAddressSelect(
													address.savedAddress
												)
											}}
										>
											{address.savedAddress}
										</div>
									))}
								</div>
							</div>
						)}

						{!user.userId && (
							<div className='flex flex-col p-2 bg-white rounded-xl shadow-light-grey border max-h-[300px] max-w-screen overflow-scroll'>
								<div className='flex justify-between items-center text-green-600 font-semibold pl-4 pt-2'>
									Suggestions:
									<AiOutlineClose
										className={`font-semibold text-green-600 cursor-pointer`}
										onClick={() => setIsFocused(false)}
									/>
								</div>
								<div className='flex flex-col x-50 bg-white items-start justify-start text-start p-2 w-full'>
									<div
										onClick={handleGPSRequest}
										className='text-sm font-bold flex items-center border-b border-t border-gray-200 p-2 m-2 w-full cursor-pointer'
									>
										<BsPinFill
											className={`text-green-400 text-sm mr-2`}
										/>
										Use current location
									</div>
								</div>
							</div>
						)}
					</div>
				)}
				{!hideButton && (
					<Button
						text={buttonText}
						size={size}
						color={buttonColor}
						className={
							size === 'xs'
								? 'absolute right-0 px-4 !py-3'
								: 'absolute right-0 px-4'
						}
						onClick={handleAddressSubmit}
					/>
				)}
			</FormGroup>
		</div>
	)
}

const mapStateToProps = ({ User, Path, Location }) => ({
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	path: Path.path,
	userGPSLocation: Location.userLocation,
	gpsLocationFound: Location.gpsLocationFound,
	userGPSLocationError: Location.userLocationError,
})

const mapDispatchToProps = (dispatch) => ({
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetGPSLocation: () => dispatch(getGPSLocation()),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchBar)
