import {
	GET_APP_INFO,
	GET_APP_INFO_SUCCESS,
	GET_APP_INFO_FAILURE,
	GET_BANNER,
	GET_BANNER_SUCCESS,
	GET_BANNER_FAILURE,
	HIDE_BANNER,
	RUN_MONGO_FUNCTION,
	RUN_MONGO_FUNCTION_SUCCESS,
	RUN_MONGO_FUNCTION_ERROR,
} from './actionTypes'

const initialState = {
	appInfo: {},
	loading: false,
	bannerInfo: null,
	error: null,
	hideBanner: false,
	runningMongoFunction: false,
	mongoFunctionResult: null,
	mongoFunctionError: null,
}

const appInfo = (state = initialState, action) => {
	switch (action.type) {
		case GET_APP_INFO:
			return {
				...state,
				loading: true,
			}
		case GET_APP_INFO_SUCCESS:
			return {
				...state,
				appInfo: action.payload,
				loading: false,
			}
		case GET_APP_INFO_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			}
		case GET_BANNER:
			return {
				...state,
			}
		case GET_BANNER_SUCCESS:
			return {
				...state,
				bannerInfo: action.payload,
			}
		case GET_BANNER_FAILURE:
			return {
				...state,
				error: action.payload,
			}
		case HIDE_BANNER:
			return {
				...state,
				hideBanner: true,
			}
		case RUN_MONGO_FUNCTION:
			return {
				...state,
				runningMongoFunction: true,
				mongoFunctionResult: null,
				mongoFunctionError: null,
			};
		case RUN_MONGO_FUNCTION_SUCCESS:
			return {
				...state,
				runningMongoFunction: false,
				mongoFunctionResult: action.payload,
				mongoFunctionError: null,
			};
		case RUN_MONGO_FUNCTION_ERROR:
			return {
				...state,
				runningMongoFunction: false,
				mongoFunctionResult: null,
				mongoFunctionError: action.payload,
			};
		default:
			return state
	}
}

export default appInfo
