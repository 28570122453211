import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const PlatformPartners = () => {
	return (
		<>
			<div className='h-auto flex flex-col justify-center items-center mt-20 sm:mt-28 xl:mt-36'>
				<h1 className='text-3.5xl sm:text-5xl font-header text-white text-center w-full mb-6 sm:mb-12 z-20'>
					We Integrate With
				</h1>
				<div className='grid grid-cols-2 place-content-center justify-items-center gap-5 mx-2 sm:hidden z-20'>
					<img
						src='images/PlatformPartners/ubereats.png'
						alt='ubereats logo'
						className='w-[140px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/wholefoods.png'
						alt='wholefoods logo'
						className='w-[80px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/amazon.png'
						alt='amazon logo'
						className='w-[120px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/deliverect.png'
						alt='deliverect logo'
						className='w-[120px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/chownowInverted.svg'
						alt='chownow logo'
						className='w-[120px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/olo.png'
						alt='olo logo'
						className='w-[80px] h-auto my-auto sm:mb-5'
					/>
					<img
						src='images/PlatformPartners/otter.png'
						alt='otter logo'
						className='w-[100px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/caviar.png'
						alt='caviar logo'
						className='w-[120px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/toast.png'
						alt='toast logo'
						className='w-[120px] h-auto my-auto brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/itsacheckmate-large.png'
						alt='itsacheckmate logo'
						className='w-[100px] h-auto my-auto brightness-0 invert sm:mb-5'
					/>
					<img
						src='images/PlatformPartners/doordash.png'
						alt='doordash logo'
						className='w-[220px] col-span-2 h-auto my-auto brightness-0 invert mb-5'
					/>
				</div>
				<div className='sm:grid gap-5 grid-cols-3 max-w-[80%] flex flex-wrap justify-center items-center z-20 hidden'>
					<img
						src='images/PlatformPartners/ubereats.png'
						alt='ubereats logo'
						className='w-[140px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/wholefoods.png'
						alt='wholefoods logo'
						className='w-[80px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/otter.png'
						alt='otter logo'
						className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/amazon.png'
						alt='amazon logo'
						className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/toast.png'
						alt='toast logo'
						className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/caviar.png'
						alt='caviar logo'
						className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/itsacheckmate-large.png'
						alt='itsacheckmate logo'
						className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/doordash.png'
						alt='doordash logo'
						className='w-[220px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
					<img
						src='images/PlatformPartners/olo.png'
						alt='olo logo'
						className='w-[80px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
				</div>
				<div className='sm:grid gap-5 grid-cols-2 max-w-[80%] flex flex-wrap justify-center items-center z-20 hidden mb-6'>
					<img
						src='images/PlatformPartners/chownowInverted.svg'
						alt='chownow logo'
						className='w-[180px] h-auto my-auto mx-auto flex justify-center items-center'
					/>
					<img
						src='images/PlatformPartners/deliverect.png'
						alt='deliverect logo'
						className='w-[200px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert'
					/>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PlatformPartners)
