import { call, put, takeEvery } from 'redux-saga/effects'
import {
	CANCEL_DROPOFF,
	CREATE_GENTLY_DROPOFF,
	CREATE_GENTLY_DROPOFF_NO_NODE,
	CREATE_DROPOFF,
	GET_DROPOFF_QUOTE,
	GET_DROPOFF_STATUS,
	GET_DROPOFFS,
	GET_DROPOFF_BY_ID,
	CREATE_FLOT_DROPOFF,
	CREATE_FLOT_DROPOFF_NO_NODE,
	CANCEL_FLOT_DROPOFF,
	GET_ALL_FLOT_DROPOFFS,
} from './actionTypes'
import {
	getDropoffQuoteSuccess,
	getDropoffQuoteError,
	createDropoffSuccess,
	createDropoffError,
	getDropoffsSuccess,
	getDropoffsError,
	getDropoffStatusSuccess,
	getDropoffStatusError,
	cancelDropoffError,
	createGentlyDropoffSuccess,
	createGentlyDropoffError,
	createGentlyDropoffNoNodeSuccess,
	createGentlyDropoffNoNodeError,
	getDropoffByIdSuccess,
	getDropoffByIdError,
	createFlotDropoffSuccess,
	createFlotDropoffError,
	createFlotDropoffNoNodeError,
	createFlotDropoffNoNodeSuccess,
	cancelFlotDropoffSuccess,
	cancelFlotDropoffError,
	getAllFlotDropoffsSuccess,
	getAllFlotDropoffsError,
} from './actions'
import {
	cancelDropoff,
	createDropoff,
	getDropoffQuote,
	getDropoffs,
	getDropoffStatus,
	createGentlyDropoff,
	createGentlyDropoffNoNode,
	getDropoffById,
	createFlotDropoff,
	createFlotDropoffNoNode,
	cancelFlotDropoff,
	getAllFlotDropoffs,
} from '../../model/dropoffs'

function* quoteDropoff(action) {
	try {
		const quoteResponse = yield call(getDropoffQuote, action.payload)
		yield put(getDropoffQuoteSuccess(quoteResponse))
	} catch (error) {
		yield put(getDropoffQuoteError(error))
	}
}

function* dispatchDropoff(action) {
	try {
		const quoteResponse = yield call(createDropoff, action.payload)
		yield put(createDropoffSuccess(quoteResponse))
	} catch (error) {
		yield put(createDropoffError(error))
	}
}

function* getDropoff(action) {
	try {
		const quoteResponse = yield call(getDropoffStatus, action.payload)
		yield put(getDropoffStatusSuccess(quoteResponse))
	} catch (error) {
		yield put(getDropoffStatusError(error))
	}
}

function* retrieveDropoffs(action) {
	try {
		const quoteResponse = yield call(getDropoffs, action.payload)
		yield put(getDropoffsSuccess(quoteResponse))
	} catch (error) {
		yield put(getDropoffsError(error))
	}
}

function* nixDropoff(action) {
	try {
		const quoteResponse = yield call(cancelDropoff, action.payload)
		yield put(getDropoffStatusSuccess(quoteResponse))
	} catch (error) {
		yield put(cancelDropoffError(error))
	}
}

function* dispatchGentlyDropoff(action) {
	try {
		const quoteResponse = yield call(createGentlyDropoff, action.payload)
		yield put(createGentlyDropoffSuccess(quoteResponse))
	} catch (error) {
		yield put(createGentlyDropoffError(error))
	}
}

function* dispatchGentlyDropoffNoNode(action) {
	try {
		const quoteResponse = yield call(
			createGentlyDropoffNoNode,
			action.payload
		)
		yield put(createGentlyDropoffNoNodeSuccess(quoteResponse))
	} catch (error) {
		yield put(createGentlyDropoffNoNodeError(error))
	}
}

function* fetchDropoffById(action) {
	try {
		const dropoff = yield call(getDropoffById, action.payload)
		yield put(getDropoffByIdSuccess(dropoff))
	} catch (error) {
		yield put(getDropoffByIdError(error))
	}
}

function* dispatchFlotDropoff(action) {
	try {
		const response = yield call(createFlotDropoff, action.payload)
		yield put(createFlotDropoffSuccess(response))
	} catch (error) {
		yield put(createFlotDropoffError(error))
	}
}

function* dispatchFlotDropoffNoNode(action) {
	try {
		const response = yield call(createFlotDropoffNoNode, action.payload)
		yield put(createFlotDropoffNoNodeSuccess(response))
	} catch (error) {
		yield put(createFlotDropoffNoNodeError(error))
	}
}

function* dispatchCancelFlotDropoff(action) {
	try {
		const response = yield call(cancelFlotDropoff, action.payload)
		yield put(cancelFlotDropoffSuccess(response))
	} catch (error) {
		yield put(cancelFlotDropoffError(error))
	}
}

function* fetchAllFlotDropoffs(action) {
	try {
		const response = yield call(getAllFlotDropoffs, action.payload)
		yield put(getAllFlotDropoffsSuccess(response))
	} catch (error) {
		yield put(getAllFlotDropoffsError(error))
	}
}

function* adminSaga() {
	yield takeEvery(GET_DROPOFF_QUOTE, quoteDropoff)
	yield takeEvery(CREATE_DROPOFF, dispatchDropoff)
	yield takeEvery(CREATE_GENTLY_DROPOFF, dispatchGentlyDropoff)
	yield takeEvery(CREATE_GENTLY_DROPOFF_NO_NODE, dispatchGentlyDropoffNoNode)
	yield takeEvery(GET_DROPOFF_STATUS, getDropoff)
	yield takeEvery(GET_DROPOFFS, retrieveDropoffs)
	yield takeEvery(CANCEL_DROPOFF, nixDropoff)
	yield takeEvery(GET_DROPOFF_BY_ID, fetchDropoffById)
	yield takeEvery(CREATE_FLOT_DROPOFF, dispatchFlotDropoff)
	yield takeEvery(CREATE_FLOT_DROPOFF_NO_NODE, dispatchFlotDropoffNoNode)
	yield takeEvery(CANCEL_FLOT_DROPOFF, dispatchCancelFlotDropoff)
	yield takeEvery(GET_ALL_FLOT_DROPOFFS, fetchAllFlotDropoffs)
}

export default adminSaga
