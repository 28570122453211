import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getOrders,
	getPaymentMethods,
	getNodeByAdminCodeOrSlug,
	updateNode,
	updateUser,
	getLead,
	alertSlack,
	resetNode,
	resetMerchant,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import MerchantAccountForm from '../../../components/RestaurantAdmin/MerchantAccountForm'
import MerchantAccount from '../../../components/RestaurantAdmin/MerchantAccount'

const MerchantRestaurantInfo = (state) => {
	const {
		user,
		onGetUser,
		onGetNodeBySlugAdmin,
		node,
		onUpdateUser,
		userError,
		onGetLead,
		lead,
		onUpdateNode,
		updatingNode,
		updateNodeCompleted,
		updateNodeError,
		onResetNode,
		onResetMerchant,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [showSignUp, setShowSignUp] = useState(false)
	const [signUpUrl, setSignUpURL] = useState('')
	const [contacts, setContacts] = useState([])
	const [showEditMerchant, setShowEditMerchant] = useState(false)
	const { nodeSlug } = useParams()

	useEffect(() => {
		onGetUser()
		onResetMerchant()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	useEffect(() => {
		if (node && node.fromSignUpFlow) {
			onGetLead({ slug: nodeSlug })
		}
		if (node && node.contacts) {
			setContacts(node.contacts)
		}
	}, [node])

	useEffect(() => {
		if (lead && !lead.signUpComplete) {
			setShowSignUp(true)
			const pageNumber = lead.lastPage + 1
			setSignUpURL(`/restaurantpartners/sign-up/page-${pageNumber}`)
		}
	}, [lead])

	const changeTheMerchant = (selectedNodeSlug) => {
		onUpdateUser({
			restaurantAdmin: selectedNodeSlug,
		})
		navigate(`/restaurant-admin/account/${selectedNodeSlug}`)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		if (node && node.slug === user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/restaurant-admin/home')
						}}
					>
						&#8592; Go to Dashboard Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='min-h-screen w-full'>
						{node ? (
							<div>
								{user.restaurantAdminOtherLocations &&
								user.restaurantAdminOtherLocations.length >
									0 ? (
									<div className='flex flex-row justify-center'>
										<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
											{node.name}'s Account
										</h1>
										{showNodeChoices ? (
											<BsChevronUp
												onClick={() => {
													setShowNodeChoices(false)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										) : (
											<BsChevronDown
												onClick={() => {
													setShowNodeChoices(true)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										)}
									</div>
								) : (
									<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
										{node.name}'s Account
									</h1>
								)}
							</div>
						) : (
							<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
								DeliverZero Dashboard
							</h1>
						)}
						<div className='flex-col flex items-center'>
							{showNodeChoices && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									{user.restaurantAdminOtherLocations &&
									user.restaurantAdminOtherLocations.length >
										1 ? (
										user.restaurantAdminOtherLocations.map(
											(location) => (
												<div
													className='text-sm cursor-pointer my-1 hover:bg-gray-100'
													key={location.nodeSlug}
													onClick={() => {
														changeTheMerchant(
															location.nodeSlug
														)
														setShowNodeChoices(
															false
														)
													}}
												>
													{location.name}
												</div>
											)
										)
									) : user.restaurantAdminOtherLocations &&
									  user.restaurantAdminOtherLocations[0] ? (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											onClick={() => {
												changeTheMerchant(
													location.nodeSlug
												)
												setShowNodeChoices(false)
											}}
										>
											{
												user
													.restaurantAdminOtherLocations[0]
													.name
											}
										</div>
									) : (
										<div></div>
									)}
								</div>
							)}
						</div>

						{/* Location information */}
						<div className='w-full flex flex-col justify-center items-center mb-10'>
							{showEditMerchant ? (
								<MerchantAccountForm
									onUpdateNode={onUpdateNode}
									nodeSlug={nodeSlug}
									name={node.name}
									phone={
										node.phone
											? node.phone
											: node.contactPhone
									}
									email={node.email}
									address={node.address}
									city={node.city}
									state={node.state}
									postal={node.postal}
									updatingNode={updatingNode}
									updateNodeCompleted={updateNodeCompleted}
									updateNodeError={updateNodeError}
									onResetNode={onResetNode}
									setShowEditMerchant={setShowEditMerchant}
									onGetNodeBySlugAdmin={onGetNodeBySlugAdmin}
								/>
							) : (
								<MerchantAccount
									onUpdateNode={onUpdateNode}
									nodeSlug={nodeSlug}
									name={node.name}
									phone={
										node.phone
											? node.phone
											: node.contactPhone
									}
									email={node.email}
									address={node.address}
									city={node.city}
									state={node.state}
									postal={node.postal}
									showEditMerchant={showEditMerchant}
									setShowEditMerchant={setShowEditMerchant}
								/>
							)}
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Transactions,
	User,
	AppInfo,
	Orders,
	Nodes,
	Leads,
	Merchant,
}) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	lead: Leads.lead,
	updatingNode: Nodes.updatingNode,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	updateNodeError: Nodes.updateNodeError,
	inviteSent: Merchant.invite,
	loadingMerchant: Merchant.loading,
	merchantError: Merchant.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetPaymentMethods: (payload) => dispatch(getPaymentMethods(payload)),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onUpdateUser: (payload) => dispatch(updateUser(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
	onAlertSlack: (payload) => dispatch(alertSlack(payload)),
	onUpdateNode: (payload) => dispatch(updateNode(payload)),
	onResetNode: () => dispatch(resetNode()),
	onInviteMerchantUser: (payload) => dispatch(inviteMerchantUser(payload)),
	onResetMerchant: () => dispatch(resetMerchant()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MerchantRestaurantInfo)
