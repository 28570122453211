import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Header, Footer } from '../../components'
import { Link } from 'react-router-dom'
import { BiHome } from 'react-icons/bi'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { GiPaperBagOpen } from 'react-icons/gi'
import { getUser } from '../../store/actions'
import { BounceLoader } from 'react-spinners'

const ReturnLandingPage = (state) => {
	const { user, onGetUser } = state

	const bounceLoaderColor = '#507f74'

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	return (
		<div className=' h-full w-full flex flex-col justify-between '>
			<Header />
			<div className='flex flex-col justify-between items-center min-h-min w-full text-center  bg-yellow-400 lg:h-screen-no-header'>
				<h1 className='text-3xl md:text-5xl font-header text-green-600 text-center w-full mb-4 mt-12'>
					Return Containers
				</h1>
				<div className='flex flex-col items-center w-5/6 xl:w-2/3 h-full py-2 mb-12 md:flex-row '>
					{!user.franchiseeSlug && (
						<>
							<Link
								to='/returns/pickup'
								className='flex w-full px-4 py-8 m-4 bg-white rounded-xl shadow-lg md:min-h-[300px] lg:w-full hover:shadow-2xl'
							>
								<div className='flex flex-col h-full w-full justify-between'>
									<h1 className='text-3xl text-green-600 font-header'>
										Return at your door
									</h1>
									<div className=' mx-auto bg-white'>
										<BiHome className='text-[100px] text-green-400 mt-2 mb-4' />
									</div>
									<p className=''>
										Schedule a pickup by a courier
									</p>
								</div>
							</Link>
							<Link
								to='/returns/third-party'
								className='flex w-full px-4 py-8 m-4 bg-white rounded-xl shadow-lg md:min-h-[300px] lg:w-full hover:shadow-2xl'
							>
								<div className='flex flex-col h-full w-full justify-between'>
									<h1 className='text-3xl text-green-600 font-header'>
										Return with Partner
									</h1>
									<div className='mx-auto bg-white'>
										<GiPaperBagOpen className='text-[100px] text-green-400 mt-2 mb-4' />
									</div>
									<p className=''>
										Hand your containers back to the courier
										from one of our sustainability partners
									</p>
								</div>
							</Link>
						</>
					)}
					<Link
						to='/returns/locations'
						className='flex w-full px-4 py-8 m-4 bg-white rounded-xl shadow-lg md:min-h-[300px] lg:w-full hover:shadow-2xl'
					>
						<div className='flex flex-col h-full w-full justify-between'>
							<h1 className='text-3xl text-green-600 font-header'>
								Return in person
							</h1>
							<div className='mx-auto bg-white'>
								<HiOutlineOfficeBuilding className='text-[100px] text-green-400 mt-2 mb-4' />
							</div>
							<p className=''>
								Select a dropoff location and confirm your
								return
							</p>
						</div>
					</Link>
				</div>
				<div
					className='h-32 bg-red-400 w-full'
					style={{
						clipPath:
							'polygon( 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%)',
					}}
				></div>
			</div>
			<Footer />
		</div>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnLandingPage)

{
	/* <svg class="absolute t-0 z-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#000b29" fill-opacity="1" d="M0,32L48,80C96,128,192,224,288,224C384,224,480,128,576,112C672,96,768,160,864,154.7C960,149,1056,75,1152,90.7C1248,107,1344,213,1392,266.7L1440,320L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> */
}
