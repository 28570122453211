import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from '../Elements'

const MerchantReusableProductTile = (props) => {
	const {
		name,
		description,
		imgSrc,
		price,
		sku,
		bulkQty,
		handleAddProduct,
		weight25,
	} = props

	const [itemCount, setItemCount] = useState(1)
	const [bulkDescription, setBulkDescription] = useState(1)

	const descrMaxLen = 260

	const handleAddToCart = () => {
		if (itemCount < 1) {
			return
		}

		handleAddProduct({
			id: sku,
			name: name,
			description: description,
			price: price,
			imgSrc: imgSrc,
			quantity: itemCount,
			bulk: true,
			bulkQty: bulkQty,
			weight25: weight25,
		})
	}

	useEffect(() => {
		if (description) {
			setBulkDescription('CASE OF QTY ' + bulkQty + ': ' + description)
		}
	}, [description])

	return (
		<div className='w-[275px] sm:w-[315px] h-[375px] py-7 px-7 flex flex-col flex-shrink-0 border border-gray-200 rounded-xl'>
			<div className='w-full mb-6'>
				<img
					src={imgSrc}
					className='w-[125px] h-[125px]'
				/>
			</div>
			<div className='h-full flex flex-col'>
				<div className='flex flex-col h-full'>
					<h1 className='font-header text-black text-lg'>{name}</h1>
					<p className='whitespace-wrap overflow-hidden text-sm mt-2'>
						{bulkDescription.length > descrMaxLen
							? bulkDescription.slice(0, descrMaxLen - 3) + '...'
							: bulkDescription}
					</p>
				</div>
				<div className='flex flex-col'>
					<p className='text-sm font-bold pb-2'>
						{'Price: $' + price.toString()}
					</p>
					<div className='flex flex-row justify-between'>
						<div
							className={`border-2 w-full rounded-full text-center flex justify-between px-3 items-center`}
						>
							<span
								color='default'
								className={`text-xl text-gray-300 cursor-pointer`}
								onClick={() => {
									const updatedCount =
										itemCount > 1
											? itemCount - 1
											: itemCount
									setItemCount(updatedCount)
								}}
							>
								-
							</span>
							<input
								value={itemCount}
								onChange={(e) => {
									if (isNaN(parseInt(e.target.value))) {
										setItemCount(0)
									} else {
										const updatedCount = parseInt(
											e.target.value
										)
										if (updatedCount < 101) {
											setItemCount(updatedCount)
										}
									}
								}}
								className='max-w-[40px] text-center'
							/>
							<span
								color='default'
								className={`text-xl text-gray-300 cursor-pointer`}
								onClick={() => {
									const updatedCount = itemCount + 1
									if (updatedCount < 101) {
										setItemCount(updatedCount)
									}
								}}
							>
								+
							</span>
						</div>
						<Button
							text={'Add to Cart'}
							size='sm'
							color={itemCount > 0 ? 'green' : 'gray'}
							className='min-w-[100px] ml-2'
							onClick={handleAddToCart}
						></Button>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MerchantReusableProductTile)
