export const GET_USER_ADDRESS_LOCATION = 'GET_USER_ADDRESS_LOCATION'
export const GET_USER_ADDRESS_LOCATION_SUCCESS =
	'GET_USER_ADDRESS_LOCATION_SUCCESS'
export const GET_USER_ADDRESS_LOCATION_FAIL = 'GET_USER_ADDRESS_LOCATION_FAIL'
export const GET_GPS_LOCATION = 'GET_GPS_LOCATION'
export const GET_GPS_LOCATION_SUCCESS = 'GET_GPS_LOCATION_SUCCESS'
export const GET_GPS_LOCATION_FAIL = 'GET_GPS_LOCATION_FAIL'
export const CAPTURE_GPS = 'CAPTURE_GPS'
export const CAPTURE_GPS_SUCCESS = 'CAPTURE_GPS_SUCCESS'
export const CAPTURE_GPS_FAIL = 'CAPTURE_GPS_FAIL'
