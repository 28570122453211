import React, { useRef, useState, useEffect } from 'react'
import useOutsideClick from '../Elements/DetectOutsideClick'
import { MdClear } from 'react-icons/md'
import { connect } from 'react-redux'
import { Button, TextArea } from '../Elements'
import { BounceLoader } from 'react-spinners'

const PendingPickupsPopUp = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		openPopUp,
		handleOpenPopUp,
		pendingPickups,
		...newProps
	} = props

	const bounceLoaderColor = '#507f74'
	const impactRef = useRef(null)

	const handleClosePopUp = () => {
		handleOpenPopUp(false)
	}

	useOutsideClick(impactRef, () => {
		handleOpenPopUp(false)
	})

	return (
		<>
			{openPopUp && (
				<>
					<div className='justify-center flex fixed inset-0 z-50 shadow-xl overflow-x-hidden bg-gray-800 bg-opacity-25 backdrop-filter backdrop-blur-sm py-10'>
						<div
							ref={impactRef}
							className='h-fit h-max-[500px] overflow-y-auto scrollbar my-auto border-0 shadow-xl relative w-full overflow-y-auto sm:bg-white sm:w-[500px] sm:rounded-xl z-50'
						>
							<div className='relative flex flex-col w-full bg-white outline-none focus:outline-none'>
								<div className='relative flex flex-row w-full'>
									<div className='flex flex-col items-start justify-between px-4 py-4 border-b border-solid border-slate-200 rounded-t'>
										<h3 className='text-xl font-semibold my-3'>
											Upcoming Pickups:
										</h3>
										{pendingPickups &&
											pendingPickups.length > 0 &&
											pendingPickups.map(
												(pendingPickup, index) => (
													<div
														key={index}
														className='text-left mb-3'
													>
														<p className='text-slate-500 text-md leading-relaxed'>
															<span className='font-semibold font-header'>
																Address:
															</span>{' '}
															<span className='text-green-600'>
																{
																	pendingPickup
																		.user
																		.pickupAddress
																}
															</span>
														</p>
														<p className='text-slate-500 text-md leading-relaxed'>
															<span className='font-semibold font-header'>
																Date:
															</span>{' '}
															<span className='text-green-600'>
																{pendingPickup.timestamp
																	.toString()
																	.slice(
																		0,
																		10
																	)}
															</span>
														</p>
														<p className='text-slate-500 text-md leading-relaxed'>
															<span className='font-semibold font-header'>
																Number of
																Containers:
															</span>{' '}
															<span className='text-green-600'>
																{
																	pendingPickup.boxCount
																}
															</span>
														</p>
														<p className='text-slate-500 text-md leading-relaxed'>
															{pendingPickup.pickupNote ? (
																<>
																	<span className='font-semibold font-header'>
																		Special
																		Instructions:
																	</span>{' '}
																	<span className='text-green-600'>
																		{
																			pendingPickup.pickupNote
																		}
																	</span>
																</>
															) : (
																''
															)}
														</p>
													</div>
												)
											)}
										<div className='absolute right-3 top-3'>
											<MdClear
												className='text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl'
												onClick={handleClosePopUp}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
				</>
			)}
		</>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PendingPickupsPopUp)
