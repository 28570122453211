import {
	SMS_VERIFY_SEND_PHONE,
	SMS_VERIFY_USER_RESPONSE,
	SMS_VERIFY_SUCCESSFUL,
	SMS_VERIFY_FAILED,
	SMS_VERIFY_SEND_PHONE_SUCCESS,
} from './actionTypes'

const initialState = {
	userPhone: null,
	smsVerifyUserResponse: null,
	smsVerifyUserPhone: null,
	smsVerifySuccessResponse: null,
	smsVerifySendPhoneResponse: null,
	smsVerifyError: null,
	smsVerifyLoading: false,
}

const smsVerify = (state = initialState, action) => {
	switch (action.type) {
		case SMS_VERIFY_SEND_PHONE:
			state = {
				...state,
				smsVerifyLoading: true,
				smsVerifyError: null,
				userPhone: action.payload,
			}
			break
		case SMS_VERIFY_SEND_PHONE_SUCCESS:
			{
				state = {
					...state,
					smsVerifyLoading: false,
					smsVerifyError: null,
					smsVerifySendPhoneSuccessResponse: action.payload,
				}
			}
			break
		case SMS_VERIFY_USER_RESPONSE:
			state = {
				...state,
				smsVerifyLoading: true,
				smsVerifyError: null,
				smsVerifyUserResponse: action.payload.smsUserResponse,
				smsVerifyUserPhone: action.payload.smsUserPhone,
			}
			break
		case SMS_VERIFY_SUCCESSFUL:
			state = {
				...state,
				smsVerifyLoading: false,
				smsVerifySuccessResponse: action.payload,
				smsVerifyError: null,
			}
			break
		case SMS_VERIFY_FAILED:
			state = {
				...state,
				smsVerifyLoading: false,
				smsVerifyError: action.payload,
			}
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default smsVerify
