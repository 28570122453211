import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	addNode,
	getAppInfo,
	getFranchisee,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import {
	Button,
	Input,
	RadioGroup,
	TextArea,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const AddRestaurant = (state) => {
	const {
		user,
		onGetUser,
		onGetNodes,
		nodes,
		loadingAddNode,
		addNodeSuccess,
		addNodeError,
		onAddNode,
		appInfo,
		onGetAppInfo,
		franchisee,
		onGetFranchisee,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [form, setForm] = useState({})
	const [thirdPartyClientForm, setThirdPartyClientForm] = useState({})
	const [thirdPartyClient, setThirdPartyClient] = useState('')
	const [incomplete, setIncomplete] = useState(false)
	const [showDirectOrdering, setShowDirectOrdering] = useState(false)
	const [showSpecialCommission, setShowSpecialCommission] = useState(false)
	const [showThirdParty, setShowThirdParty] = useState(false)
	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			onGetFranchisee(user.franchiseeSlug)
		}
	}, [user])

	useEffect(() => {
		onGetAppInfo()
	}, [onGetAppInfo])

	function generateRandomNumberString() {
		let randomNumberString = ''

		for (let i = 0; i < 4; i++) {
			randomNumberString += Math.floor(Math.random() * 10)
		}

		return randomNumberString
	}

	const handleSubmitNode = (e) => {
		e.preventDefault()
		setIncomplete(false)
		const requiredFields = [
			'name',
			'streetAddress',
			'city',
			'state',
			'postalCode',
			'thumbnail',
			'country',
			'phone',
			'enabled',
			'balance',
			'coordinates',
			'maxBalance',
			'returnBin',
			'inHouseCouriers',
			'openHoursDescription',
			'courierAcceptsReturns',
			'directOrdering',
			'cuisine',
			'instoreOnly',
			'pickupOnly',
			'deliveryPolygon',
		]

		const notValidated = requiredFields.forEach((key) => {
			if (
				!(key in form) ||
				form[key] === null ||
				form[key] === '' ||
				(key === 'deliveryPolygon' && form[key].length < 3)
			) {
				// console.log(key)
				setIncomplete(true)
				return true
			}
		})

		if (!notValidated) {
			const nodeDoc = {
				name: form.name,
				address: form.streetAddress,
				city: form.city,
				state: form.state,
				postal: form.postalCode,
				unit: form.unit ? form.unit : '',
				thumbnail: form.thumbnail,
				country: form.country,
				slug: form.name.toLowerCase().replaceAll(' ', '-'),
				phone: '+1' + form.phone,
				email: form.email,
				type: 'restaurant',
				enabled: form.enabled,
				balance: parseInt(form.balance),
				balanceByContainerType: {},
				pricePerUseByContainerType: {},
				// pricePerUse: user.franchiseeSlug ? (form.restaurantWashingContainers ? franchisee.washingInHouseContainerFee : franchisee.washingOutsourcedContainerFee)
				// 	: (form.restaurantWashingContainers ? appInfo.washingInHouseContainerFee : appInfo.washingOutsourcedContainerFee),
				pricePerUse: user.franchiseeSlug
					? form.pricePerUse
						? parseFloat(form.pricePerUse)
						: franchisee.washingOutsourcedContainerFee
					: form.restaurantWashingContainers
					? appInfo.washingInHouseContainerFee
					: appInfo.washingOutsourcedContainerFee,
				// pricePerUse: form.restaurantWashingContainers ? 0.07 : 0.25,
				adminPinCode:
					form.name.toLowerCase().slice(0, 3) +
					generateRandomNumberString(),
				coordinates: [
					parseFloat(
						form.coordinates
							.replaceAll(' ', '')
							.replaceAll('"', '')
							.split(',')[1]
					),
					parseFloat(
						form.coordinates
							.replaceAll(' ', '')
							.replaceAll('"', '')
							.split(',')[0]
					),
				],
				maxBalance: parseInt(form.maxBalance),
				returnBinEnabled: form.returnBin,
				inHouseCouriers: form.inHouseCouriers,
				description: form.description ? form.description : '',
				openHoursDescription: form.openHoursDescription,
				openHours: {},
				courierAcceptsReturns: form.courierAcceptsReturns,
				qr: ['XXXXXX'],
				cuisine: form.cuisine,
				instoreOnly: form.instoreOnly,
				pickupOnly: form.pickupOnly,
				commissionFee: form.specialCommission
					? parseFloat(form.specialCommissionFee)
					: 0.15,
				serviceFeeToUser: 0.1,
				deliveryFeeToUser: form.deliveryFeeToUser
					? parseFloat(form.deliveryFeeToUser)
					: 0.0,
				taxToUser: form.taxToUser ? parseFloat(form.taxToUser) : 0.0,
				deliveryPolygon: form.deliveryPolygon,
				directOrdering: form.directOrdering,
				checkoutModule: form.checkoutModule,
			}
			if (Object.keys(thirdPartyClientForm).length > 0) {
				nodeDoc.thirdPartyClient = thirdPartyClientForm
				nodeDoc.chargePerOrder = user.franchiseeSlug
					? form.chargePerOrder
						? parseFloat(form.chargePerOrder)
						: franchisee.customerServiceFee
					: 0.99
			}
			if (form.directOrdering) {
				nodeDoc.directOrderingClient = form.aggregator
					? form.aggregator
					: null
			}
			if (user.franchiseeSlug) {
				nodeDoc.franchiseeSlug = user.franchiseeSlug
			}
			// console.log('nodeDoc', nodeDoc)
			onAddNode(nodeDoc)
		}
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Add a Restaurant
				</h1>

				<div className='flex min-w-min w-3/5 mx-auto items-center mb-4'>
					Fill out all fields in the form below! Then click the
					"Submit Restaurant" button.
				</div>
				<form
					className='form-horizontal'
					onSubmit={handleSubmitNode}
				>
					<div className='w-4/5 min-w-[500px] shadow-light-grey flex flex-col m-auto pt-10 px-6 mb-10'>
						<Input
							type='name'
							label='NAME'
							name='name'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='text'
							label='RESTAURANT DESCRIPTION'
							name='description'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='text'
							label='CUISINE'
							name='cuisine'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='address'
							label='ADDRESS'
							name='streetAddress'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='unit'
							label='UNIT'
							name='unit'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='city'
							label='CITY'
							name='city'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='state'
							label='STATE INITIAL (e.g. NY, CO, TX)'
							name='state'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='number'
							label='ZIP OR POSTAL CODE'
							name='postalCode'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='country'
							label='COUNTRY'
							name='country'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='text'
							label='LATITUDE, LONGITUDE (e.g "39.6706, -105.097946")'
							name='coordinates'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='phone'
							label='PHONE (ONLY DIGITS NO SPACES)'
							name='phone'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='email'
							label='EMAIL TO SEND CHARGE AND PAYMENT INFO'
							name='email'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							TURN THE RESTAURANT ON IMMEDIATELY?
						</div>
						<RadioGroup
							className='mt-1 sm:mt-0 mb-10'
							name='enabled'
							horizontal
							radios={[
								{ value: true, label: 'Yes' },
								{ value: false, label: 'No' },
							]}
							onChange={(status) => {
								setForm({
									...form,
									['enabled']: status === 'true',
								})
							}}
						/>

						<Input
							type='url'
							label='URL for Thumbnail (square image, upload to imgur, then open image in new tab and copy url)'
							name='thumbnail'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='number'
							label='STARTING INVENTORY'
							name='balance'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='number'
							label='MAXIMUM INVENTORY BEFORE REBALANCING IS NEEDED'
							name='maxBalance'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							WILL THIS RESTAURANT ACCEPT RETURNS?
						</div>
						<div>
							<RadioGroup
								className='mt-1 sm:mt-0 mb-10'
								name='returnBin'
								horizontal
								radios={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
								onChange={(bin) => {
									setForm({
										...form,
										['returnBin']: bin === 'true',
									})
								}}
							/>
						</div>
						{!user.franchiseeSlug && (
							<>
								<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
									IS THE RESTAURANT WASHING CONTAINERS?
								</div>
								<div>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-10'
										name='washing'
										horizontal
										radios={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' },
										]}
										onChange={(washing) => {
											setForm({
												...form,
												['restaurantWashingContainers']:
													washing === 'true',
											})
										}}
									/>
								</div>
							</>
						)}

						<Input
							type='text'
							label='WHAT ARE THE RESTAURANTS HOURS?'
							name='openHoursDescription'
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>

						{!user.franchiseeSlug && (
							<>
								<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
									WILL THIS RESTAURANT HAVE DIRECT ORDERING?
								</div>
								<div>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-10'
										name='directOrdering'
										horizontal
										radios={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' },
										]}
										onChange={(directOrdering) => {
											setForm({
												...form,
												['directOrdering']:
													directOrdering === 'true',
											})
											setShowDirectOrdering(
												directOrdering === 'true'
											)
										}}
									/>
								</div>
								<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
									IS THIS RESTAURANT USING OUR IN-PERSON
									CHECKOUT MODULE?
								</div>
								<div>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-10'
										name='checkoutModule'
										horizontal
										radios={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' },
										]}
										onChange={(checkoutModule) => {
											setForm({
												...form,
												['checkoutModule']:
													checkoutModule === 'true',
											})
										}}
									/>
								</div>
								{showDirectOrdering ? (
									<div>
										<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
											WHO IS THE AGGREGATOR?
										</div>
										<div>
											<RadioGroup
												className='mt-1 sm:mt-0 mb-10'
												name='aggregator'
												horizontal
												radios={[
													{
														value: 'otter',
														label: 'Otter',
													},
													{
														value: 'checkmate',
														label: 'Itsacheckmate',
													},
													{
														value: 'cuboh',
														label: 'Cuboh',
													},
												]}
												onChange={(aggregator) => {
													setForm({
														...form,
														['aggregator']:
															aggregator,
													})
												}}
											/>
										</div>
										<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
											IS THERE A SPECIAL COMMISSION RATE
											FOR THE RESTAURANT?
										</div>
										<div>
											<RadioGroup
												className='mt-1 sm:mt-0 mb-10'
												name='commission'
												horizontal
												radios={[
													{
														value: true,
														label: 'Yes',
													},
													{
														value: false,
														label: 'No',
													},
												]}
												onChange={(
													specialCommission
												) => {
													setForm({
														...form,
														['specialCommission']:
															specialCommission ===
															'true',
													})
													setShowSpecialCommission(
														specialCommission ===
															'true'
													)
												}}
											/>
										</div>
										{showSpecialCommission && (
											<Input
												type='number'
												step='0.01'
												label='WHAT IS THE COMMISSION RATE? DECIMALS ONLY, NO PERCENTAGES'
												name='specialCommissionFee'
												className='bg-white border-2 border-gray-300 mb-6'
												onChange={handleChange}
											/>
										)}
										<Input
											type='number'
											step='0.01'
											label='WHAT IS THE DELIVERY FEE TO USERS? (Do not enter $ sign. Leave blank if N/a)'
											name='deliveryFeeToUser'
											className='bg-white border-2 border-gray-300 mb-6'
											onChange={handleChange}
										/>
										<Input
											type='number'
											step='0.00001'
											label='WHAT IS THE TAX RATE? DECIMALS ONLY, NO PERCENTAGES'
											name='taxToUser'
											className='bg-white border-2 border-gray-300 mb-6'
											onChange={handleChange}
										/>
									</div>
								) : null}
								<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
									IS THIS RESTAURANT USING ITS OWN COURIERS?
								</div>
								<div>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-10'
										name='couriers'
										horizontal
										radios={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' },
										]}
										onChange={(couriers) => {
											setForm({
												...form,
												['inHouseCouriers']:
													couriers === 'true',
											})
										}}
									/>
								</div>
								<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
									CAN COURIERS ACCEPT RETURNS?
								</div>
								<div>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-10'
										name='courierReturns'
										horizontal
										radios={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' },
										]}
										onChange={(returns) => {
											setForm({
												...form,
												['courierAcceptsReturns']:
													returns === 'true',
											})
										}}
									/>
								</div>
							</>
						)}
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							IS THIS RESTAURANT IN PERSON ONLY?
						</div>
						<div>
							<RadioGroup
								className='mt-1 sm:mt-0 mb-10'
								name='inPerson'
								horizontal
								radios={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
								onChange={(inPerson) => {
									setForm({
										...form,
										['instoreOnly']: inPerson === 'true',
									})
								}}
							/>
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							IS THIS RESTAURANT PICKUP ONLY?
						</div>
						<div>
							<RadioGroup
								className='mt-1 sm:mt-0 mb-10'
								name='pickup'
								horizontal
								radios={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
								onChange={(pickup) => {
									setForm({
										...form,
										['pickupOnly']: pickup === 'true',
									})
								}}
							/>
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px] font-bold'>
							PASTE THE DELIVERY POLYGON COORDINATES HERE
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							<a
								href='https://youtu.be/l2Bgb-l2fWo'
								target='_blank'
								className='underline cursor-pointer font-bold text-green-600'
								rel='noreferrer'
							>
								VIEW THE HOW TO VIDEO
							</a>
							<span> Or follow instructions below.</span>
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							1. Visit
							<a
								href='https://mymaps.google.com/'
								target='_blank'
								className='underline cursor-pointer'
								rel='noreferrer'
							>
								{' '}
								Google My Maps
							</a>
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							2. Add a layer, then draw the delivery polygon
							around the restaurant (if pickup only, draw a 10
							mile radius polygon)
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							3. Click the three vertical dots for that layer,
							then "Export data" and "KML/KMZ". In the pop up,
							CHECK "Export as KML instead of KMZ".
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							4. It will create a file, open in a text reader.
							Find the portion that
							<a
								href='https://imgur.com/BZ9CReo'
								target='_blank'
								className='underline cursor-pointer'
								rel='noreferrer'
							>
								{' '}
								looks like this.
							</a>
						</div>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							5. Copy just the rows of numbers between the
							coordinates tag. Paste in box below. Don't need to
							erase white space.
						</div>
						<TextArea
							name='Delivery Note'
							// label='Delivery Note'
							placeholder='PASTE THE DELIVERY POLYGON COORDINATES HERE'
							className='!h-32'
							onChange={(e) => {
								const textArray = e.toString().split('\n')
								textArray.forEach(function (item, index) {
									item = item.replaceAll(' ', '')
									textArray[index] = [
										parseFloat(item.split(',')[1]),
										parseFloat(item.split(',')[0]),
									]
								})
								// console.log(textArray)
								setForm({
									...form,
									['deliveryPolygon']: textArray,
								})
							}}
						/>
						<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
							ARE WE WORKING WITH THIS RESTAURANT THRU A THIRD
							PARTY PLATFORM?
						</div>
						<div>
							<RadioGroup
								className='mt-1 sm:mt-0 mb-10'
								name='platform'
								horizontal
								radios={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
								onChange={(platform) => {
									if (platform) {
										setShowThirdParty(true)
									} else {
										setShowThirdParty(false)
										setThirdPartyClientForm({})
										setThirdPartyClient('')
									}
									setShowThirdParty(platform === 'true')
								}}
							/>
						</div>
						{showThirdParty && (
							<div>
								<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
									WHAT PLATFORM?
								</div>
								<div>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-10'
										name='platformName'
										horizontal
										radios={[
											{ value: 'toast', label: 'Toast' },
											{
												value: 'doordash',
												label: 'DoorDash',
											},
											{
												value: 'caviar',
												label: 'Caviar',
											},
											{
												value: 'ubereats',
												label: 'Uber Eats',
											},
										]}
										onChange={(platform) => {
											setThirdPartyClientForm({
												...thirdPartyClientForm,
												[platform]: '',
											})
											setThirdPartyClient(platform)
										}}
									/>
								</div>
								<Input
									type='text'
									label='LINK TO RESTAURANTS STOREFRONT'
									name='deliveryFeeToUser'
									className='bg-white border-2 border-gray-300 mb-6'
									onChange={(url) => {
										setThirdPartyClientForm({
											...thirdPartyClientForm,
											[thirdPartyClient]:
												url.target.value,
										})
									}}
								/>
							</div>
						)}

						{user.franchiseeSlug && (
							<>
								<Input
									type='number'
									step='0.01'
									label='WHAT WILL YOU CHARGE RESTAURANTS PER CONTAINER?'
									placeholder='0.00'
									name='pricePerUse'
									className='bg-white border-2 border-gray-300 mb-6'
									onChange={handleChange}
								/>
								<Input
									type='number'
									step='0.01'
									placeholder='0.00'
									label='WHAT SERVICE FEE WILL YOU CHARGE CUSTOMERS PER ORDER?'
									name='chargePerOrder'
									className='bg-white border-2 border-gray-300 mb-6'
									onChange={handleChange}
								/>
							</>
						)}

						{loadingAddNode ? (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						) : (
							<Button
								className='mb-10 w-[200px]'
								size='sm'
								submit={handleSubmitNode}
								text='Submit Restaurant'
							/>
						)}
						{incomplete && (
							<p className='text-red-400'>
								Error above. Missing or incorrect fields.
							</p>
						)}
						{addNodeError && (
							<p className='text-red-400'>
								There was an error submitting the node.
								{addNodeError}. Try again.
							</p>
						)}
						{addNodeSuccess && (
							<p className='text-green-600'>
								SUCCESS! You may navigate away from this page or
								submit another restaurant.
							</p>
						)}
					</div>
				</form>

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, AppInfo, Franchisee }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingAddNode: Nodes.loadingAddNode,
	addNodeError: Nodes.addNodeError,
	addNodeSuccess: Nodes.addNodeSuccess,
	appInfo: AppInfo.appInfo,
	franchisee: Franchisee.fetchedFranchisee,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onAddNode: (nodeDoc) => dispatch(addNode(nodeDoc)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetFranchisee: (payload) => dispatch(getFranchisee(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddRestaurant)
