// import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Header from '../../components/Header'
import LoginCard from '../../components/Authentication/LoginCard'
import { Footer } from '../../components'

const Login = (props) => {
	return (
		<>
			<Header />
			<div className='h-screen-no-header w-screen flex'>
				<div className='w-full h-full min-h-[600px] flex flex-col items-center justify-center bg-red-400 lg:w-1/2 '>
					<div>
						<LoginCard />
					</div>
				</div>
				<div className='h-full w-full hidden lg:overflow-hidden lg:w-1/2 lg:flex'>
					<img
						className='overflow-hidden object-cover h-full w-full'
						src='https://i.imgur.com/31JfaBe.jpg'
						alt='Tasty Treats in Deliver Zero reusable containers'
					/>
				</div>
			</div>
			<Footer />
		</>
	)
}

const mapStateToProps = ({ User, Login, Path }) => ({
	// user: User.user,
	// loggedIn: User.loggedIn,
	// loginLoading: Login.loading,
	// loginError: Login.error,
	// loginAttemptStatus: Login.loginAttemptStatus,
	// userLoading: User.loading,
	// path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({
	// onLoginSuccess: user => dispatch(loginUserSuccess(user)),
	// onLoginUser: credentials => dispatch(loginUser(credentials)),
	// onGoogleLogin: res => dispatch(socialLoginGoogle(res)),
	// onGoogleRegister: payload => dispatch(socialRegisterGoogle(payload)),
	// onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
