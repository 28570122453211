import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getOrders,
	getNodes,
	updateUser,
	getNodeBySlug,
	getAdminCustomersBoxCount,
	adminDisputeTransaction,
	resetUpdateTransaction,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input } from '../../../components/Elements'

const ViewOneCustomer = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		transactions,
		transactionsCount,
		loadingTransactions,
		onGetTransactions,
		onGetOrders,
		loadingOrders,
		orders,
		ordersCount,
		onGetCustomers,
		loadingCustomers,
		customers,
		onSubmitDisputeTransaction,
		successfulDispute,
		oneTransaction,
		transactionError,
		onResetTransaction,
	} = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const { userId } = useParams()
	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	const [orderPage, setOrderPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [customer, setCustomer] = useState(null)
	const [editingIndex, setEditingIndex] = useState(-1)
	const [showEdit, setShowEdit] = useState(0)
	const [actualBoxCount, setActualBoxCount] = useState(null)
	let navigate = useNavigate()

	const getTransactions = (page) => {
		onGetTransactions({
			filter: {
				'user.phone': customer.phone,
				franchiseeSlug: customer.franchiseeSlug,
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const transactionPaginate = () => {
		getTransactions(page)
	}

	// console.log(transactions)

	const getOrders = (page) => {
		onGetOrders({
			filter: {
				userId: userId,
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const orderPaginate = () => {
		getOrders(orderPage)
	}

	const handleSaveBoxCount = (payload) => {
		onSubmitDisputeTransaction(payload)
	}

	useEffect(() => {
		if (userId) {
			if (userId.length > 12) {
				onGetCustomers({
					filter: { userId: userId },
					options: {
						sort: {
							lastUse: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			} else {
				onGetCustomers({
					filter: { phone: userId },
					options: {
						sort: {
							lastUse: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			}
			orderPaginate()
		}
	}, [userId])

	useEffect(() => {
		if (customers.length > 0) {
			setCustomer(customers[0])
		}
	}, [customers])

	useEffect(() => {
		if (customer) {
			transactionPaginate()
		}
	}, [customer])

	useEffect(() => {
		if (customer) {
			transactionPaginate()
		}
	}, [page, successfulDispute])

	useEffect(() => {
		if (userId) {
			orderPaginate()
		}
	}, [orderPage])

	useEffect(() => {
		setLoading(false)
	}, [orders, transactions])

	useEffect(() => {
		if (successfulDispute) {
			setTimeout(() => {
				onResetTransaction()
				setEditingIndex(-1)
				setShowEdit(0)
				setActualBoxCount(null)
			}, [2000])
		}
	}, [successfulDispute])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		if (customer) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='flex flex-col max-w-[800px] justify-center my-10'>
						<div className='mx-auto'>
							<div className='font-header text-2xl text-green-600'>
								{customer.firstName} {customer.lastName}
							</div>
							{!user.franchiseeSlug && (
								<>
									<div className=''>
										<span className='font-bold'>
											Phone:
										</span>{' '}
										{customer.phone}
									</div>
									<div className=''>
										<span className='font-bold'>
											Email:
										</span>{' '}
										{customer.email}
									</div>
								</>
							)}
							{customer.getTotalPackagingPrevented ? (
								<>
									<div className=''>
										<span className='font-bold'>
											Box Count:
										</span>{' '}
										{
											customer.getTotalPackagingPrevented
												.currentPackagingOut
										}{' '}
										boxes
									</div>
									<div className=''>
										<span className='font-bold'>
											Packaging Prevented:
										</span>{' '}
										{
											customer.getTotalPackagingPrevented
												.totalPackagingPrevented
										}{' '}
										boxes
									</div>
								</>
							) : (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							)}
							{transactionsCount > 0 && (
								<div className=''>
									<span className='font-bold'>
										Number of total transactions:
									</span>{' '}
									{transactionsCount}
								</div>
							)}
							{ordersCount > 0 && (
								<div className=''>
									<span className='font-bold'>
										Number of total orders:
									</span>{' '}
									{ordersCount}
								</div>
							)}

							{/* {loadingTransactions && (
								<div className="my-3 w-full items-center mx-auto justify-center">
									<BounceLoader
										className="m-auto"
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{successfulDispute && (
								<div className="my-3 w-full text-center mx-auto justify-center text-green-600">
									Box count adjustment saved!
								</div>
							)}
							{transactionError && (
								<div className="my-3 w-full text-center mx-auto justify-center text-red-400">
									Error saving box count adjustment: {JSON.stringify(transactionError)}
								</div>
							)} */}

							{transactions.length > 0 && (
								<div className='px-10 my-6 shadow-light-grey'>
									<div className='my-2 text-lg text-green-600'>
										All Box Transactions
									</div>
									<div className='flex flex-row mb-2'>
										<div className='font-bold w-[200px]'>
											Date
										</div>
										<div className='font-bold w-[150px]'>
											Restaurant
										</div>
										<div className='font-bold w-[200px]'>
											Box Count
										</div>
										<div className='font-bold w-[180px]'></div>
									</div>
									{loadingCustomers && (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									)}
									{transactions.map((transaction, index) => {
										const transactionDate = new Date(
											transaction.timestamp
										)
										const transactionDateString =
											transactionDate.toLocaleString(
												'default',
												{
													month: 'short',
													day: 'numeric',
													year: 'numeric',
													hour: '2-digit',
													minute: '2-digit',
												}
											)

										const isEditing = editingIndex === index

										return (
											<div
												className='flex flex-row my-2 divide-y-2 divide-solid'
												key={index}
											>
												<div></div>
												<div className='w-[200px]'>
													{transactionDateString}
												</div>
												{transaction.reverseLogisticsPartner ? (
													<div className='w-[150px] overflow-hidden'>
														{
															transaction.reverseLogisticsPartner
														}
													</div>
												) : transaction.latePayment ? (
													<div className='w-[150px] overflow-hidden'>
														LATE PAYMENT
													</div>
												) : (
													<div className='w-[150px] overflow-hidden'>
														{transaction.node}
													</div>
												)}
												{transaction.type ===
												'boxesIn' ? (
													transaction.latePayment ? (
														<div className='w-[200px] text-green-600'>
															{
																transaction.boxCount
															}{' '}
															boxes paid for by
															user
														</div>
													) : (
														<>
															<div className='w-[200px] text-green-600'>
																{
																	transaction.boxCount
																}{' '}
																boxes returned
																by user
															</div>
															{isEditing ? (
																<div className='font-bold w-[180px] flex flex-row'>
																	<Input
																		className='w-[120px]'
																		placeholder='# boxes'
																		type='number'
																		step={1}
																		min={0}
																		value={
																			actualBoxCount &&
																			actualBoxCount.index ===
																				index
																				? actualBoxCount.actual
																				: ''
																		}
																		onChange={(
																			e
																		) => {
																			const newValue =
																				parseInt(
																					e
																						.target
																						.value
																				)
																			if (
																				!isNaN(
																					newValue
																				)
																			) {
																				setActualBoxCount(
																					{
																						actual: newValue,
																						index,
																					}
																				)
																			}
																		}}
																	/>
																	<Button
																		className='ml-2 px-2 py-1 my-auto'
																		text='Save'
																		size='xs'
																		color={
																			!actualBoxCount ||
																			actualBoxCount.index !==
																				index
																				? 'disabled'
																				: 'green'
																		}
																		onClick={() => {
																			handleSaveBoxCount(
																				{
																					transaction:
																						transaction,
																					adjustedBoxCount:
																						actualBoxCount.actual,
																				}
																			)
																			setShowEdit(
																				false
																			)
																			setEditingIndex(
																				-1
																			)
																		}}
																	/>
																</div>
															) : (
																<div
																	className='ml-3 w-[50px] text-green-600 underline'
																	onClick={() => {
																		setShowEdit(
																			true
																		)
																		setEditingIndex(
																			index
																		)
																	}}
																>
																	EDIT
																</div>
															)}
														</>
													)
												) : transaction.type ===
												  'giftCard' ? (
													<div className='w-[200px] text-green-600'>
														Gift card purchased by
														user
													</div>
												) : (
													<>
														<div className='w-[200px] text-orange-600'>
															{
																transaction.boxCount
															}{' '}
															boxes given to user
														</div>
														{isEditing ? (
															<div className='font-bold w-[180px] flex flex-row'>
																<Input
																	className='w-[120px]'
																	placeholder='# boxes'
																	type='number'
																	step={1}
																	min={0}
																	value={
																		actualBoxCount &&
																		actualBoxCount.index ===
																			index
																			? actualBoxCount.actual
																			: ''
																	}
																	onChange={(
																		e
																	) => {
																		const newValue =
																			parseInt(
																				e
																					.target
																					.value
																			)
																		if (
																			!isNaN(
																				newValue
																			)
																		) {
																			setActualBoxCount(
																				{
																					actual: newValue,
																					index,
																				}
																			)
																		}
																	}}
																/>
																<Button
																	className='ml-2 px-2 py-1 my-auto'
																	text='Save'
																	size='xs'
																	color={
																		!actualBoxCount ||
																		actualBoxCount.index !==
																			index
																			? 'disabled'
																			: 'green'
																	}
																	onClick={() => {
																		handleSaveBoxCount(
																			{
																				transaction:
																					transaction,
																				adjustedBoxCount:
																					actualBoxCount.actual,
																			}
																		)
																		setShowEdit(
																			false
																		)
																		setEditingIndex(
																			-1
																		)
																	}}
																/>
															</div>
														) : (
															<div
																className='ml-3 w-[50px] text-green-600 underline'
																onClick={() => {
																	setShowEdit(
																		true
																	)
																	setEditingIndex(
																		index
																	)
																}}
															>
																EDIT
															</div>
														)}
													</>
												)}
											</div>
										)
									})}
									<div className='flex flex-row'>
										{page > 1 && (
											<div
												className='mx-auto underline font-bold mr-6 cursor-pointer'
												onClick={() => {
													setPage(page - 1)
												}}
											>
												Previous {paginationLimit}{' '}
												transactions
											</div>
										)}
										{transactions.length >
											paginationLimit && (
											<div
												className='mx-auto underline font-bold cursor-pointer'
												onClick={() => {
													setPage(page + 1)
													setLoading(true)
												}}
											>
												Next {paginationLimit}{' '}
												transactions
											</div>
										)}
									</div>
								</div>
							)}

							{orders.length > 0 && !user.franchiseeSlug && (
								<div className='px-10 my-6 shadow-light-grey'>
									<div className='my-2 text-lg text-green-600'>
										Marketplace Orders
									</div>
									<div className='flex flex-row mb-2'>
										<div className='font-bold w-[200px]'>
											Date
										</div>
										<div className='font-bold w-[150px]'>
											User
										</div>
										<div className='font-bold w-[100px]'>
											Subtotal
										</div>
										<div className='font-bold w-[100px]'>
											Total
										</div>
									</div>
									{loading && (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									)}
									{orders.map((order) => {
										const orderDate = new Date(
											order.timestamp
										)
										const orderDateString =
											orderDate.toLocaleString(
												'default',
												{
													month: 'short',
													day: 'numeric',
													year: 'numeric',
													hour: '2-digit',
													minute: '2-digit',
												}
											)

										return (
											<div className='flex flex-row my-2 divide-y-2 divide-solid'>
												<div></div>
												<div className='w-[200px]'>
													{orderDateString}
												</div>
												<div className='w-[150px] overflow-hidden'>
													{order.userInfo.firstName}{' '}
													{order.userInfo.lastName}
												</div>
												<div className='w-[100px] overflow-hidden'>
													${order.subtotal.toFixed(2)}
												</div>
												<div className='w-[100px] overflow-hidden'>
													${order.dzTotal.toFixed(2)}
												</div>
											</div>
										)
									})}
									<div className='flex flex-row'>
										<div
											className='mx-auto underline font-bold mr-6 cursor-pointer'
											onClick={() => {
												setOrderPage(orderPage - 1)
												setLoading(true)
											}}
										>
											Previous {paginationLimit} orders
										</div>
										<div
											className='mx-auto underline font-bold cursor-pointer'
											onClick={() => {
												setOrderPage(orderPage + 1)
												setLoading(true)
											}}
										>
											Next {paginationLimit} orders
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div>
					<Header />
					<div className='flex items-center h-screen'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
						<Footer />
					</div>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Transactions, Nodes, User, Orders, Admin }) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	nodes: Nodes.nodes,
	node: Nodes.node,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	customers: Admin.customers,
	customersCount: Admin.customersCount,
	loadingCustomers: Admin.loadingCustomers,
	transactionError: Transactions.error,
	successfulDispute: Transactions.successfulDispute,
	oneTransaction: Transactions.oneTransaction,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onGetCustomers: (payload) => dispatch(getAdminCustomersBoxCount(payload)),
	onSubmitDisputeTransaction: (payload) =>
		dispatch(adminDisputeTransaction(payload)),
	onResetTransaction: () => dispatch(resetUpdateTransaction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewOneCustomer)
