import React, { useEffect } from 'react'
import { connect } from 'react-redux'

const ReusablesCartItem = (props) => {
	const { cartItems, handleUpdateCartItem } = props

	const handleUpdateProduct = (product, operation) => {
		const updated = { ...product }
		if (operation == 'decrement') {
			updated.quantity -= 1
		} else {
			updated.quantity += 1
		}
		handleUpdateCartItem(updated)
	}

	return (
		<div className='w-full flex flex-col justify-center items-center px-5'>
			{Object.entries(cartItems).map(([key, item]) => (
				<div
					className='w-full max-w-[300px] flex flex-col border-2 my-2 p-4 rounded-lg'
					key={key}
				>
					<div className='flex flex-col'>
						<p className='text-xs font-header inline'>
							{item && item.name && item.name.length > 33
								? item.name.slice(0, 33) + '...'
								: item.name}
						</p>
						<div className='flex flex-row items-center'>
							<img
								className='h-[75px] w-[75px] rounded-md'
								src={item.imgSrc}
								alt=''
							/>
							<div className='border-2 rounded-full text-center flex justify-between items-center h-[25px] ml-3'>
								<span
									color='default'
									className='text-gray-300 cursor-pointer text-center justify-center pr-2 pl-4 text-xs'
									onClick={() =>
										handleUpdateProduct(item, 'decrement')
									}
								>
									-
								</span>
								<div className='text-center text-xs'>
									{item.quantity}
								</div>
								<span
									color='default'
									className='pl-2 pr-4 text-gray-300 cursor-pointer text-center justify-center text-xs'
									onClick={() =>
										handleUpdateProduct(item, 'increment')
									}
								>
									+
								</span>
							</div>
							<p className='text-xs font-semibold ml-3'>
								{new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'USD',
								}).format(item.price)}
							</p>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ReusablesCartItem)
