import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getOrders,
	getPaymentMethods,
	updateUser,
	getNodeByAdminCodeOrSlug,
	resetAdminNode,
	addChildrenToUser,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '../../components/Elements'
// import { Header } from '../../components'

const RestaurantAdminVerification = (state) => {
	const {
		onGetNodeByAdminCode,
		loadingNode,
		node,
		user,
		loadingUser,
		onResetAdminNode,
		onGetUser,
		updateUserLoading,
		onUpdateUser,
		onAddChildLocs,
	} = state

	const [adminPinCode, setAdminPinCode] = useState('')
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
		onResetAdminNode()
	}, [onGetUser])

	const onVerify = () => {
		onGetNodeByAdminCode(adminPinCode)
	}

	useEffect(() => {
		if (node && node.adminPinCode === adminPinCode) {
			onUpdateUser({
				restaurantAdmin: node.slug,
			})
			if (node && node.parent) {
				onAddChildLocs(node.slug)
			}
		}
	}, [node, node?.parent])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		navigate('/restaurant-admin/home/')
	} else {
		return (
			<div>
				<Header />
				<h1 className='font-header text-green-600 text-4xl mb-11 mt-14 text-center'>
					Merchant Admin Verification
				</h1>
				<div className='h-screen-3/4 flex flex-col items-center'>
					<div className='text-center max-w-[500px] mx-auto mb-2'>
						To connect to your DeliverZero merchant dashboard, enter
						in your merchant's admin pin code below
					</div>

					<Input
						type='text'
						label=''
						name='item-search'
						placeholder='Enter Your Admin Pin Code'
						value={adminPinCode}
						className='bg-white border-2 border-gray-300 w-[270px]'
						onChange={(e) => {
							setAdminPinCode(e.target.value.toLowerCase())
						}}
					/>
					{loadingNode && updateUserLoading ? (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					) : (
						<Button
							text='Verify'
							className='px-8 py-2 mt-2'
							onClick={() => {
								onVerify()
							}}
						/>
					)}
				</div>

				<Footer />
			</div>
		)
	}
}

const mapStateToProps = ({ Nodes, User }) => ({
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetPaymentMethods: (payload) => dispatch(getPaymentMethods(payload)),
	onGetNodeByAdminCode: (payload) =>
		dispatch(getNodeByAdminCodeOrSlug(payload)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onResetAdminNode: () => dispatch(resetAdminNode()),
	onAddChildLocs: (payload) => dispatch(addChildrenToUser(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantAdminVerification)
