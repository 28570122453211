import React, { useEffect } from 'react'
import { Header, Footer } from '../../components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BiHome } from 'react-icons/bi'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { connect } from 'react-redux'
import { BounceLoaderCentered } from '../../components/Elements'
import { getUserByPhone, captureIP, captureGPS } from '../../store/actions'

const ReturnLandingPhone = (props) => {
	const { loadingUser, user, onGetUserByPhone, onCaptureIP, onCaptureGPS } =
		props

	const { returnByTextId, phone } = useParams()
	let navigate = useNavigate()

	useEffect(() => {
		if (phone && returnByTextId) {
			onGetUserByPhone({
				phone: phone,
				returnByTextId: returnByTextId,
			})
		}
	}, [phone, returnByTextId])

	useEffect(() => {
		if (user && user.phone) {
			onCaptureIP({ phone: phone })
			onCaptureGPS({ phone: phone })
		}
	}, [user])

	useEffect(() => {
		if (!user || !user.phone) {
			onGetUserByPhone({
				phone: phone,
				returnByTextId: returnByTextId,
			})
		}
	}, [loadingUser])

	if (user && user.phone) {
		return (
			<div className=' h-full w-full flex flex-col justify-between '>
				<Header />
				<div className='flex flex-col justify-between items-center min-h-min w-full text-center  bg-yellow-400 lg:h-screen-no-header'>
					<h1 className='text-3xl md:text-5xl font-header text-center text-green-600 w-full mb-4 mt-12 md:mb-8'>
						Return Containers
					</h1>
					{user.providerType === 'anon-user' && (
						<div>
							<span
								onClick={() => {
									navigate('/sign-up')
								}}
								className='text-green-600 cursor-pointer underline'
							>
								Create or log into your DeliverZero account
							</span>
							{` `}to track your usage and measure your impact!
						</div>
					)}
					<div className='flex flex-col items-center w-5/6 xl:w-2/3 h-full py-2 mb-12 md:flex-row '>
						<Link
							to={`/returns/pickup/${returnByTextId}/${phone}`}
							className='flex w-full px-4 py-8 m-4 bg-white rounded-xl shadow-lg md:min-h-[300px] lg:w-full hover:shadow-2xl'
						>
							<div className='flex flex-col h-full w-full justify-between'>
								<h1 className='text-3xl text-green-600 font-header'>
									Return at your door
								</h1>
								<div className=' mx-auto bg-white'>
									<BiHome className='text-[100px] text-green-400 mt-2 mb-4' />
								</div>
								<p className=''>
									Schedule a pickup by a courier
								</p>
							</div>
						</Link>
						{/*<Link to='/marketplace/locations' className='flex w-full px-4 py-8 m-4 bg-white rounded-xl shadow-lg md:min-h-[300px] lg:w-full hover:shadow-2xl'>*/}
						{/*  <div className='flex flex-col h-full w-full justify-between'>*/}
						{/*    <h1 className='text-3xl text-green-600 font-header'>Return with next order</h1>*/}
						{/*    <div className='mx-auto bg-white'><MdOutlineFastfood className="text-[100px] text-green-400 mt-2 mb-4"/></div>*/}
						{/*    <p className=''>Order from a restaurant whose courier will pick up your containers</p>*/}
						{/*  </div>*/}
						{/*</Link>*/}
						<Link
							to={`/returns/locations/${returnByTextId}/${phone}`}
							className='flex w-full px-4 py-8 m-4 bg-white rounded-xl shadow-lg md:min-h-[300px] lg:w-full hover:shadow-2xl'
						>
							<div className='flex flex-col h-full w-full justify-between'>
								<h1 className='text-3xl text-green-600 font-header'>
									Return in person
								</h1>
								<div className='mx-auto bg-white'>
									<HiOutlineOfficeBuilding className='text-[100px] text-green-400 mt-2 mb-4' />
								</div>
								<p className=''>
									Select a dropoff location and confirm your
									return
								</p>
							</div>
						</Link>
					</div>
					<div
						className='h-32 bg-red-400 w-full'
						style={{
							clipPath:
								'polygon( 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%)',
						}}
					></div>
				</div>
				<Footer />
			</div>
		)
	} else {
		return (
			<div>
				<Header />
				<BounceLoaderCentered container='page' />
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUserByPhone: (payload) => dispatch(getUserByPhone(payload)),
	onCaptureIP: (payload) => dispatch(captureIP(payload)),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnLandingPhone)
