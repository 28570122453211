import {connect} from 'react-redux'
import React, {useEffect, useRef, useState} from 'react'
import {
    getAppInfo,
    getNodeByAdminCodeOrSlug,
    getTransactions,
    getUser,
    runMongoFunction,
    triggerOtter,
    updateUser,
} from '../../store/actions'
import {Footer} from '../../components'
import Header from '../../components/Header'
import {BounceLoader} from 'react-spinners'
import {useNavigate, useParams} from 'react-router-dom'
import {Button} from '../../components/Elements'
import PlatformVideo from '../../components/RestaurantAdmin/PlatformIntegration/PlatformVideo'
import PDFViewer from '../../components/RestaurantAdmin/PlatformIntegration/PDFVIewer'

const PlatformIntructions = (state) => {
    const {
        user,
        onGetUser,
        onGetNodeBySlugAdmin,
        node,
        onTriggerOtter,
        otterError,
        otterSuccess,
        loadingOtter,
        onRunMongoFunction,
        runningMongoFunction,
        mongoFunctionResult,
        mongoFunctionError
    } = state

    const {platform} = useParams()

    const bounceLoaderColor = '#507f74'
    let navigate = useNavigate()
    const inputRef = useRef(null)
    const [otterTriggered, setOtterTriggered] = useState(false)
    const [copyStatus, setCopyStatus] = useState({
        deliverectId: false,
        deliverectUrl: false,
        ddCategoryDescription: false,
        ddCategoryName: false,
        ddItemDescription: false,
        ddItemName: false,
        ddModifierName: false,
        ddModifierOption: false,
        ueCategoryName: false,
        ueInstructionsName: false,
        ueItem: false,
        ghCategoryName: false,
        ghInstructionsName: false,
        ghItem: false,
        ghItemDescription: false,
        ghModifier:false,
        chownowCategoryName: false,
        chownowItemName: false,
        chownowItemDescription: false,
        chownowModifierGroupName: false,
        chownowModifierName: false,
    })

    useEffect(() => {
        onGetUser()
    }, [onGetUser])

    useEffect(() => {
        if (user) {
            onGetNodeBySlugAdmin(user.restaurantAdmin)
        }
    }, [user, user?.restaurantAdmin])

    useEffect(() => {
        if (node && node.otterTriggered) {
            setOtterTriggered(true)
        }
    }, [node, node?.otterTriggered])

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text)
        setCopyStatus({...copyStatus, [type]: true})

        setTimeout(() => {
            setCopyStatus({...copyStatus, [type]: false})
        }, 2000)
    }

    const disconnectSquare = () => {
        onRunMongoFunction({
            functionName: "square/revokeMerchantAuthorizationFrontend",
            payload: {
                nodeSlug: node.slug
            }
        })
    }


    if (!user)
        return (
            <div className='flex items-center h-screen'>
                <BounceLoader
                    className='m-auto'
                    color={bounceLoaderColor}
                ></BounceLoader>
            </div>
        )

    if (user.restaurantAdmin) {
        if (node && node.slug === user.restaurantAdmin) {
            return (
                <div>
                    <Header/>
                    <div
                        className='underline font-bold my-4 cursor-pointer ml-6'
                        onClick={() => {
                            navigate('/restaurant-admin/home')
                        }}
                    >
                        &#8592; Go to Dashboard Home
                    </div>
                    <div
                        className='underline font-bold my-4 cursor-pointer ml-6'
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        &#8592; Go Back
                    </div>
                    <div className='flex flex-col w-full justify-center my-10'>
                        <h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 text-center'>
                            {platform === 'manual'
                                ? 'MANUAL ENTRY'
                                : platform.toUpperCase()}
                            {/* <img
                                src={`/images/PlatformPartners/${platform}.png`}
                                alt={`${platform} logo`}
                                className='w-[140px] h-auto my-auto mx-auto flex justify-center items-center'
                            /> */}
                        </h1>
                        <div className='w-full h-fit mb-6'>
                            <PlatformVideo platform={platform}/>
                        </div>
                        {platform && platform === 'toast' && (
                            <div className=''>
                                <PDFViewer pdfUrl={'/toast.pdf'}/>
                            </div>
                        )}
                        {platform && platform === 'deliverect' && (
                            <div className='flex flex-col justify-center w-full'>
                                <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                    How to integrate with DeliverZero from the
                                    Deliverect Portal:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        Follow{' '}
                                        <a
                                            href='https://www.deliverect.com/en-us/integrations'
                                            target='_blank'
                                            className='font-bold text-green-600 underline'
                                        >
                                            this link
                                        </a>{' '}
                                        to add DeliverZero as an ordering
                                        channel via your Deliverect Portal
                                    </li>
                                    <li className='my-5 text-lg'>
                                        In your Deliverect Portal, click on the "Locations" tab in the left-hand menu.
                                        For the appropriate location, open the dropdown and click "Edit" next to
                                        DeliverZero
                                    </li>
                                    <img
                                        src={`https://i.imgur.com/I5TtpIs.jpeg`}
                                        alt='deliverect screenshot 1'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <img
                                        src={`https://i.imgur.com/2FdnnsZ.jpeg`}
                                        alt='deliverect screenshot 1'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-5 text-lg'>
                                        In the next page, click "Show more" at the top to show more settings options
                                    </li>
                                    <img
                                        src={`https://i.imgur.com/gOA6ikb.png`}
                                        alt='deliverect screenshot 1'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='mt-5 mb-3 text-lg'>
                                        In the "Generic Channel" settings, copy and paste the following in the Add your "External location ID" field: (The value in the image is just an example, use your ID below)
                                    </li>
                                    <div className='flex flex-col w-full'>
                                        <label className='ml-3 text-green-600'>
                                            External location id:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 mb-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={node.slug}
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        node.slug,
                                                        'deliverectId'
                                                    )
                                                }
                                                text={
                                                    copyStatus.deliverectId
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                        <li className='my-3 text-lg'>
                                            Copy and paste the following in the "Channel registration webhook URL" field:
                                        </li>
                                        <label className='ml-3 text-green-600'>
                                            Channel registration webhook URL:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 mb-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={`https://data.mongodb-api.com/app/caas-ateyl/endpoint/deliverectRegisterEventHandler`}
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        "https://data.mongodb-api.com/app/caas-ateyl/endpoint/deliverectRegisterEventHandler",
                                                        'registrationEndpoint'
                                                    )
                                                }
                                                text={
                                                    copyStatus.registrationEndpoint
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/r5ro4WZ.jpeg`}
                                        alt='deliverect screenshot 1'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-5 text-lg'>
                                        Then click "Register"
                                        <img
                                            src={`https://i.imgur.com/q5yDX9x.jpeg`}
                                            alt='deliverect screenshot 2'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-5 text-lg'>
                                        Next, publish your menus to DeliverZero
                                        <img
                                            src={`https://i.imgur.com/DF5pN1s.png`}
                                            alt='deliverect screenshot 3'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-5 text-lg'>
                                        Lastly, return to the "Show more" settings section for the appropriate
                                        Location's DeliverZero tab and click "Activate!"
                                        <img
                                            src={`https://i.imgur.com/s5zwARr.jpeg`}
                                            alt='deliverect screenshot 4'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                </ul>
                            </div>
                        )}
                        {platform && platform === 'manual' && (
                            <div className='flex flex-col justify-center w-full'>
                                <div className='flex flex-col justify-center w-full mx-auto'>
                                    <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                        <a
                                            href='https://www.deliverzero.com/restaurant-admin/checkout-containers/'
                                            target='_blank'
                                            className='flex flex-col'
                                        >
                                            Bookmark the Checkout Module for
                                            easy access:
                                        </a>
                                    </h3>
                                    <a
                                        href='https://www.deliverzero.com/restaurant-admin/checkout-containers/'
                                        target='_blank'
                                        className='font-bold  underline flex justify-center mx-auto'
                                    >
                                        https://www.deliverzero.com/restaurant-admin/checkout-containers/
                                    </a>
                                </div>
                                <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                    For each order you receive do the following:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        From the DeliverZero{' '}
                                        <span className='font-bold text-green-600'>
											<a
                                                href='https://www.deliverzero.com/restaurant-admin/home/'
                                                target='_blank'
                                            >
												Merchant Dashboard
											</a>
										</span>{' '}
                                        select{' '}
                                        <span className='font-bold text-green-600'>
											<a
                                                href='https://www.deliverzero.com/restaurant-admin/home/'
                                                target='_blank'
                                            >
												Checkout Containers to Customers
											</a>
										</span>
                                        <img
                                            src={`https://i.imgur.com/KGtecD2.png`}
                                            alt='manual screenshot 1'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Enter the phone number and the number of
                                        containers given to the customer, then{' '}
                                        <span className='font-bold text-green-600'>
											Submit
										</span>
                                        <img
                                            src={`https://i.imgur.com/pM0zEJq.png`}
                                            alt='manual screenshot 2'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Done! Select{' '}
                                        <span className='font-bold text-green-600'>
											<a
                                                href='https://www.deliverzero.com/restaurant-admin/home/'
                                                target='_blank'
                                            >
												Checkout More Containers
											</a>
										</span>{' '}
                                        to reload the module
                                        <img
                                            src={`https://i.imgur.com/O0aTWyC.png`}
                                            alt='manual screenshot 3'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                </ul>
                            </div>
                        )}
                        {platform && platform === 'otter' && (
                            <div className='flex flex-col justify-center w-full mb-10'>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        Click below to start the process:
                                        {otterTriggered ? (
                                            <Button
                                                size='sm'
                                                className='ml-2 my-3 h-full w-[80px] px-10'
                                                disabled
                                                color='disabled'
                                                text='Processing'
                                            />
                                        ) : (
                                            <Button
                                                size='sm'
                                                className='ml-2 my-3 h-full w-[80px] px-10'
                                                onClick={() => {
                                                    setOtterTriggered(true)
                                                    onTriggerOtter(node.slug)
                                                }}
                                                text='Get Started'
                                            />
                                        )}
                                        {otterSuccess && (
                                            <div className='italic text-green-600'>
                                                DeliverZero will inform you once
                                                your Otter account is connected
                                            </div>
                                        )}
                                        {!loadingOtter && otterError && (
                                            <div className='text-red-400'>
                                                {JSON.stringify(otterError)}
                                            </div>
                                        )}
                                        {loadingOtter && (
                                            <div className='flex items-center h-screen'>
                                                <BounceLoader
                                                    className='m-auto'
                                                    color={bounceLoaderColor}
                                                ></BounceLoader>
                                            </div>
                                        )}
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Once connected, publish your menu to
                                        DeliverZero
                                    </li>
                                </ul>
                            </div>
                        )}
                        {platform && platform === 'doordash' && (
                            <div className='flex flex-col justify-center w-full'>
                                <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                    How to integrate with DeliverZero from the
                                    DoorDash Portal:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        Log in to Doordash Merchant and select{' '}
                                        <span className='font-bold text-green-600'>
											{' '}
                                            Menu Manager
										</span>
                                        <img
                                            src={`https://i.imgur.com/wB25ld4.jpg`}
                                            alt='doordash screenshot 1'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											{' '}
                                            Add New Category
										</span>
                                        <img
                                            src={`https://i.imgur.com/PYWPa5B.jpg`}
                                            alt='doordash screenshot 2'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='mt-3 text-lg'>
                                        Enter{' '}
                                        <span className='italic'>
											Eco-Friendly Packaging
										</span>{' '}
                                        then{' '}
                                        <span className='font-bold text-green-600'>
											Save Changes
										</span>
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Category Name:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    '♻️ Eco-Friendly Packaging'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        '♻️ Eco-Friendly Packaging',
                                                        'ddCategoryName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ddCategoryName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Category Description:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'Reduce waste, get your order in reusable containers.'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Reduce waste, get your order in reusable containers.',
                                                        'ddCategoryDescription'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ddCategoryDescription
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/1ExTcyg.jpg`}
                                        alt='doordash screenshot 3'
                                        className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Add Item
										</span>{' '}
                                        and enter the provided item information:
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Item Name:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'DeliverZero Reusable Containers'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'DeliverZero Reusable Containers',
                                                        'ddItemName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ddItemName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Item Description:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'Receive your order in durable, returnable, reusable containers. Return these containers to any return point in the DeliverZero network or schedule a pick-up at your door within 3 weeks.'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Receive your order in durable, returnable, reusable containers. Return these containers to any return point in the DeliverZero network or schedule a pick-up at your door within 3 weeks.'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ddItemDescription
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/ZDP6w0z.jpg`}
                                        alt='doordash screenshot 4'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        <div className='flex flex-col w-full'>
											<span>
												Upload the{' '}
                                                <a
                                                    href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view'
                                                    target='_blank'
                                                    className='font-bold text-green-600 underline'
                                                >
													provided photo
												</a>{' '}
                                                then{' '}
                                                <span className='font-bold text-green-600'>
													Submit for Review
												</span>
                                                {/* <span className='italic'> (Link to photo: <a href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view' className='text-green-600 underline' target='_blank'>https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view</a>)</span> */}
											</span>
                                        </div>
                                        <img
                                            src={`https://i.imgur.com/1fCjH6G.jpg`}
                                            alt='doordash screenshot 5'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											{' '}
                                            + New Modifier
										</span>
                                        <img
                                            src={`https://i.imgur.com/cWegKDZ.jpg`}
                                            alt='doordash screenshot 6'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Enter the provided modifier information:
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Modifier Name:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'DeliverZero Customer Consent'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'DeliverZero Customer Consent',
                                                        'ddModifierName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ddModifierName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Modifier Option:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'By selecting this option, I agree to share my name, phone number, and email address with DeliverZero to facilitate returning the reusable container(s). See the DeliverZero Privacy Notice here: www.deliverzero.com/privacy.'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'By selecting this option, I agree to share my name, phone number, and email address with DeliverZero to facilitate returning the reusable container(s). See the DeliverZero Privacy Notice here: www.deliverzero.com/privacy.',
                                                        'ddModifierOption'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ddModifierOption
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/egXKZAi.jpg`}
                                        alt='doordash screenshot 7'
                                        className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        In{' '}
                                        <span className='font-bold text-green-600'>
											Rules
										</span>{' '}
                                        turn on{' '}
                                        <span className='font-bold text-green-600'>
											Require a selection
										</span>
                                        <img
                                            src={`https://i.imgur.com/EKL7mLI.jpg`}
                                            alt='doordash screenshot 8'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        That's it! The item should now appear on
                                        your storefront and look like below:
                                        <img
                                            src={`https://i.imgur.com/gtVMkxh.jpg`}
                                            alt='doordash screenshot 9'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600 mb-3'
                                        />
                                        <img
                                            src={`https://i.imgur.com/pp45aXN.jpg`}
                                            alt='doordash screenshot 10'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                </ul>
                            </div>
                        )}
                        {platform && platform === 'itsacheckmate' && (
                            <div className='flex flex-col justify-center w-full'>
                                <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                    How to integrate with DeliverZero from the
                                    ItsaCheckmate Portal:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        From the{' '}
                                        <span className='font-bold text-green-600'>
											Customer Portal
										</span>
                                        , select{' '}
                                        <span className='font-bold text-green-600'>
											Marketplace
										</span>
                                        <img
                                            src={`https://i.imgur.com/GpNqhlT.png`}
                                            alt='checkmate screenshot 1'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Search for{' '}
                                        <span className='font-bold text-green-600'>
											DeliverZero
										</span>{' '}
                                        then select{' '}
                                        <span className='font-bold text-green-600'>
											Add
										</span>
                                        <img
                                            src={`https://i.imgur.com/L1xOvQC.png`}
                                            alt='checkmate screenshot 2'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Continue
										</span>
                                        <img
                                            src={`https://i.imgur.com/R5D6lvp.png`}
                                            alt='checkmate screenshot 3'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Provide the necessary credentials and
                                        payment options
                                        <img
                                            src={`https://i.imgur.com/Fy9oelQ.png`}
                                            alt='checkmate screenshot 4'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Add
										</span>
                                        <img
                                            src={`https://i.imgur.com/MNe5yAK.png`}
                                            alt='checkmate screenshot 5'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Check that the DeliverZero is now marked
                                        as{' '}
                                        <span className='font-bold text-green-600'>
											Added
										</span>
                                        <img
                                            src={`https://i.imgur.com/0aM5wS6.png`}
                                            alt='checkmate screenshot 6'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
										<span className='font-bold text-green-600'>
											Push
										</span>{' '}
                                        your menu to{' '}
                                        <span className='font-bold text-green-600'>
											DeliverZero
										</span>
                                        <img
                                            src={`https://i.imgur.com/6ddUa1c.png`}
                                            alt='checkmate screenshot 7'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                </ul>
                            </div>
                        )}
                        {platform && platform === 'grubhub' && (
                            <div className='flex flex-col justify-center w-full'>
                                <h3 className='text-center text-green-600 font-header text-2xl'>
                                    How to integrate DeliverZero with
                                    Grubhub:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        Log in to your Grubhub dashboard and go to edit your menus{' '}
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Create a new category called
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    '♻️ Eco-friendly Packaging'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        '♻️ Eco-friendly Packaging',
                                                        'ghCategoryName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ghCategoryName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>

                                    </li>
                                    <li className='mt-5 text-lg'>Within this category, create a menu item called</li>
                                    <div className='flex flex-row w-full'>
                                        <input
                                            type='text'
                                            className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                            value={
                                                'Use DeliverZero containers, please'}
                                            disabled
                                            readOnly
                                        />
                                        <Button
                                            size='sm'
                                            className='ml-2 h-full w-[80px] px-10'
                                            onClick={() =>
                                                copyToClipboard(
                                                    'Use DeliverZero containers, please', 'ghItem'
                                                )
                                            }
                                            text={
                                                copyStatus.ghItem
                                                    ? 'Copied!'
                                                    : 'Copy'
                                            }
                                        />
                                    </div>
                                    <li className="mt-5 text-lg">Add the below to the description of the menu item:</li>
                                    <div className='flex flex-row w-full'>
                                        <input
                                            type='text'
                                            className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                            value={
                                                'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point within 3 weeks. To receive DeliverZero containers, please select the checkbox below to opt-in to sharing specific order and contact details.'
                                            }
                                            disabled
                                            readOnly
                                        />
                                        <Button
                                            size='sm'
                                            className='ml-2 h-full w-[80px] px-10'
                                            onClick={() =>
                                                copyToClipboard(
                                                    'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point within 3 weeks. To receive DeliverZero containers, please select the checkbox below to opt-in to sharing specific order and contact details.',
                                                    'ghItemDescription'
                                                )
                                            }
                                            text={
                                                copyStatus.ghItemDescription
                                                    ? 'Copied!'
                                                    : 'Copy'
                                            }
                                        />
                                    </div>

                                    <li className='mt-5 text-lg'>
                                        Set the price of the item
                                        to <span className='font-bold text-green-600'>
											$0.99
										</span>
                                    </li>

                                    <li className='mt-5 text-lg'>
										<span>
											Upload the{' '}
                                            <a
                                                href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view'
                                                target='_blank'
                                                className='font-bold text-green-600 underline'
                                            >
												provided photo in this link as the image for the menu item
											</a>
                                            {/* <span className='italic'> (Link to photo: <a href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view' className='text-green-600 underline' target='_blank'>https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view</a>)</span> */}
										</span>
                                    </li>

                                    <li className='mt-5 text-lg'>
                                        Next, add a modifier group to this item. Name the modifier group{' '}
                                        <span className='font-bold text-green-600'>
											Terms of Use
										</span>{' '}
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={'Terms of Use'}
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Terms of Use',
                                                        'ghInstructionsName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ghInstructionsName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>

                                    <li className='mt-5 text-lg'>
                                        Make this modifier group{` `}
                                        <span className='font-bold text-green-600'>
											required
										</span>.
                                    </li>
                                    <li className='mt-5 text-lg'>
                                        Add a modifier option to this modifier group. The name of the modifier should be
                                        the following{' '}
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    ' I have read and agreed to DeliverZero’s terms of use and privacy policy.' }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'I have read and agreed to DeliverZero’s terms of use and privacy policy.',
                                                        'ghModifier'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ghModifier
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <li className="mt-5 text-lg">Set the price of the modifier to{` `}<span className='font-bold text-green-600'>
											$0.00
										</span>.</li>
                                    <li className="mt-5 mb-20 text-lg">The category and menu item are now complete! Add the category to all applicable menus at the top of your menu.</li>


                                </ul>
                            </div>
                        )}
                        {platform && platform === 'ubereats' && (
                            <div className='flex flex-col justify-center w-full'>
                                <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                    How to integrate with DeliverZero from the
                                    UberEats Portal:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        Log in to UberEats Manager and select{' '}
                                        <span className='font-bold text-green-600'>
											{' '}
                                            Menu
										</span>
                                        <img
                                            src={`https://i.imgur.com/Enh9yqH.png`}
                                            alt='ubereats screenshot 1'
                                            className='ml-3 w-[30%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Categories
										</span>{' '}
                                        then{' '}
                                        <span className='font-bold text-green-600'>
											+ New category
										</span>
                                        <img
                                            src={`https://i.imgur.com/UxuNQvW.png`}
                                            alt='ubereats screenshot 2'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Add{' '}
                                        <span className='font-bold text-green-600'>
											Returnable Reusable Packaging
										</span>{' '}
                                        and check the boxes for both{' '}
                                        <span className='font-bold text-green-600'>
											All Day
										</span>{' '}
                                        and{' '}
                                        <span className='font-bold text-green-600'>
											Late Night
										</span>
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    '♻️ Returnable Reusable Packaging'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        '♻️ Returnable Reusable Packaging',
                                                        'ueCategoryName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ueCategoryName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/kiVoYmv.png`}
                                        alt='ubereats screenshot 3'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Customizations
										</span>{' '}
                                        then{' '}
                                        <span className='font-bold text-green-600'>
											+ Create customization
										</span>
                                        <img
                                            src={`https://i.imgur.com/y04QElu.png`}
                                            alt='ubereats screenshot 4'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Enter{' '}
                                        <span className='font-bold text-green-600'>
											Terms of Use
										</span>{' '}
                                        then{' '}
                                        <span className='font-bold text-green-600'>
											Save
										</span>
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={'Terms of Use'}
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Terms of Use',
                                                        'ueInstructionsName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ueInstructionsName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/IrnLb4V.png`}
                                        alt='ubereats screenshot 5'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        In{' '}
                                        <span className='font-bold text-green-600'>
											Customizations
										</span>{' '}
                                        select{' '}
                                        <span className='font-bold text-green-600'>
											Edit options
										</span>
                                        <img
                                            src={`https://i.imgur.com/jRwRc9E.png`}
                                            alt='ubereats screenshot 6'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											+ Create a new option
										</span>
                                        <img
                                            src={`https://i.imgur.com/YK1qBk0.png`}
                                            alt='ubereats screenshot 7'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Add the provided{' '}
                                        <span className='font-bold text-green-600'>
											Name
										</span>{' '}
                                        and set the{' '}
                                        <span className='font-bold text-green-600'>
											Price
										</span>{' '}
                                        to $0.00
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'To add DeliverZero packaging, Uber Eats will share your contact information and order details with DeliverZero. By checking the box, I agree that DeliverZero will use my information in accordance with its Terms of Use (available at www.deliverzero.com/terms) and Privacy Policy (available at www.deliverzero.com/privacy) to facilitate my container returns.'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'To add DeliverZero packaging, Uber Eats will share your contact information and order details with DeliverZero. By checking the box, I agree that DeliverZero will use my information in accordance with its Terms of Use (available at www.deliverzero.com/terms) and Privacy Policy (available at www.deliverzero.com/privacy) to facilitate my container returns.',
                                                        'ueModifier'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ueModifier
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/92r3Sod.png`}
                                        alt='ubereats screenshot 8'
                                        className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Customization rules
										</span>
                                        <img
                                            src={`https://i.imgur.com/bZZlHZm.png`}
                                            alt='ubereats screenshot 9'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Enter{' '}
                                        <span className='font-bold text-green-600'>
											1
										</span>{' '}
                                        for{' '}
                                        <span className='italic'>
											What's the minimum number of options
											a customer must select?
										</span>{' '}
                                        then{' '}
                                        <span className='font-bold text-green-600'>
											Save
										</span>
                                        <img
                                            src={`https://i.imgur.com/M6pBmzi.png`}
                                            alt='ubereats screenshot 10'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Items
										</span>{' '}
                                        then{' '}
                                        <span className='font-bold text-green-600'>
											+ New item
										</span>
                                        <img
                                            src={`https://i.imgur.com/5XIUuMe.png`}
                                            alt='ubereats screenshot 11'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
										<span>
											Upload the{' '}
                                            <a
                                                href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view'
                                                target='_blank'
                                                className='font-bold text-green-600 underline'
                                            >
												provided photo
											</a>
                                            {/* <span className='italic'> (Link to photo: <a href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view' className='text-green-600 underline' target='_blank'>https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view</a>)</span> */}
										</span>
                                        <img
                                            src={`https://i.imgur.com/rwknnXw.png`}
                                            alt='ubereats screenshot 12'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Enter the provided{' '}
                                        <span className='font-bold text-green-600'>
											Description
										</span>
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point within 3 weeks. To receive DeliverZero containers, please select the checkbox below to opt-in to sharing specific order and contact details.'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point within 3 weeks. To receive DeliverZero containers, please select the checkbox below to opt-in to sharing specific order and contact details.',
                                                        'ueItem'
                                                    )
                                                }
                                                text={
                                                    copyStatus.ueItem
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/ZtimIdh.png`}
                                        alt='ubereats screenshot 13'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='italic'>
											Returnable Reusable Packaging
										</span>{' '}
                                        from{' '}
                                        <span className='font-bold text-green-600'>
											Categories
										</span>
                                        <img
                                            src={`https://i.imgur.com/3UqPPWc.png`}
                                            alt='ubereats screenshot 14'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Set the{' '}
                                        <span className='font-bold text-green-600'>
											Default Price
										</span>{' '}
                                        to <span className='italic'>$0.99</span>
                                        <img
                                            src={`https://i.imgur.com/5xCwnMO.png`}
                                            alt='ubereats screenshot 15'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Add Modifier Group
										</span>
                                        <img
                                            src={`https://i.imgur.com/vaBzs5T.png`}
                                            alt='ubereats screenshot 16'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Check the box for{' '}
                                        <span className='italic'>
											Terms of Use
										</span>{' '}
                                        then{' '}
                                        <span className='font-bold text-green-600'>
											Save
										</span>
                                        <img
                                            src={`https://i.imgur.com/RfYnUqP.png`}
                                            alt='ubereats screenshot 17'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
										<span className='font-bold text-green-600'>
											Save
										</span>{' '}
                                        the new item
                                        <img
                                            src={`https://i.imgur.com/7p40Ooy.png`}
                                            alt='ubereats screenshot 18'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											Overview
										</span>{' '}
                                        then drag the new item to the top of the
                                        list
                                        <img
                                            src={`https://i.imgur.com/LnHX8up.png`}
                                            alt='ubereats screenshot 19'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
										<span className='font-bold'>
											That's it! The item should now
											appear on your storefront and look
											like below:
										</span>
                                        <img
                                            src={`https://i.imgur.com/1q7WMyk.png`}
                                            alt='ubereats screenshot 20'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600 mb-3'
                                        />
                                        <img
                                            src={`https://i.imgur.com/V4wHiP0.png`}
                                            alt='ubereats screenshot 21'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                </ul>
                            </div>
                        )}
                        {platform && platform === 'chownow' && (
                            <div className='flex flex-col justify-center w-full'>
                                <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                    How to integrate ChowNow with DeliverZero:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        Use{' '}
                                        <a
                                            href='https://www.deliverzero.com/chownow'
                                            target='_blank'
                                            className='font-bold text-green-600 underline'
                                        >
                                            this link
                                        </a>{' '}
                                        to connect DeliverZero to your ChowNow:{' '}
                                        <a
                                            href='https://www.deliverzero.com/chownow'
                                            target='_blank'
                                            className='font-bold text-green-600 underline'
                                        >
                                            https://www.deliverzero.com/chownow
                                        </a>
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Click{' '}
                                        <span className='font-bold text-green-600'>
											{' '}
                                            Authorize ChowNow
										</span>
                                        <img
                                            src={`https://i.imgur.com/RUvvCD0.png`}
                                            alt='chownow dz screenshot 1'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Once redirected, log in to ChowNow
                                        <img
                                            src={`https://i.imgur.com/V9wvMjj.png`}
                                            alt='chownow dz screenshot 2'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											{' '}
                                            Authorize
										</span>
                                        <img
                                            src={`https://i.imgur.com/KnOnpPK.png`}
                                            alt='chownow dz screenshot 3'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Connection successful! Now return to
                                        ChowNow to add the menu item
                                        <img
                                            src={`https://i.imgur.com/Ah1H9q0.png`}
                                            alt='chownow dz screenshot 4'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Log in to ChowNow
                                        <img
                                            src={`https://i.imgur.com/V9wvMjj.png`}
                                            alt='chownow dz screenshot 2'
                                            className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Click on{' '}
                                        <span className='font-bold text-green-600'>
											Headquarters
										</span>{' '}
                                        then the name of your location
                                        <img
                                            src={`https://i.imgur.com/W1Oxdt9.png`}
                                            alt='chownow screenshot 1'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Click on{' '}
                                        <span className='font-bold text-green-600'>
											Menu
										</span>{' '}
                                        section then select{' '}
                                        <span className='font-bold text-green-600'>
											Edit Items
										</span>
                                        <img
                                            src={`https://i.imgur.com/VDjri6G.png`}
                                            alt='chownow screenshot 2'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        Select{' '}
                                        <span className='font-bold text-green-600'>
											+ New Item
										</span>{' '}
                                        and enter the provided item information:
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Category Name:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    '♻️ Eco-Friendly Packaging'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        '♻️ Eco-Friendly Packaging',
                                                        'chownowCategoryName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.chownowCategoryName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Item Name:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'Use DeliverZero containers, please'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Use DeliverZero containers, please',
                                                        'chownowItemName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.chownowItemName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Item Description:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point or at your door within 3 weeks, and the containers will be thoroughly sanitized and reused. We will need to share certain info such as phone, email, and names with DeliverZero. DeliverZero provides its service in accordance with the terms available at  www.deliverzero.com/terms and will process your personal information in accordance with its privacy policy available at www.deliverzero.com/privacy.'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point or at your door within 3 weeks, and the containers will be thoroughly sanitized and reused. We will need to share certain info such as phone, email, and names with DeliverZero. DeliverZero provides its service in accordance with the terms available at  www.deliverzero.com/terms and will process your personal information in accordance with its privacy policy available at www.deliverzero.com/privacy.'
                                                    )
                                                }
                                                text={
                                                    copyStatus.chownowItemDescription
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 my-2 text-green-600'>
                                            Photo:{' '}
                                            <a
                                                href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view'
                                                target='_blank'
                                                className='font-bold text-green-600 underline'
                                            >
                                                download link
                                            </a>{' '}
                                        </label>
                                    </div>
                                    <img
                                        src={`https://i.imgur.com/KYFTZdC.png`}
                                        alt='chownow screenshot 3'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    />
                                    <li className='my-3 text-lg'>
                                        Enter the provided modifier information
                                        and ensure the modifier group is set to{' '}
                                        <span className='font-bold text-green-600'>
											Required
										</span>{' '}
                                        in{' '}
                                        <span className='font-bold text-green-600'>
											Modifier group settings
										</span>
                                        :
                                    </li>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Modifier Group Name:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={'Terms of Use'}
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'Terms of Use',
                                                        'chownowModifierGroupName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.chownowModifierGroupName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full mb-2'>
                                        <label className='ml-3 text-green-600'>
                                            Modifier Name:
                                        </label>
                                        <div className='flex flex-row w-full'>
                                            <input
                                                type='text'
                                                className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'
                                                value={
                                                    'I have read and agreed to DeliverZero’s terms of use and privacy policy and consent to the transfer of my contact information to DeliverZero for the purposes of tracking my container returns.'
                                                }
                                                disabled
                                                readOnly
                                            />
                                            <Button
                                                size='sm'
                                                className='ml-2 h-full w-[80px] px-10'
                                                onClick={() =>
                                                    copyToClipboard(
                                                        'I have read and agreed to DeliverZero’s terms of use and privacy policy and consent to the transfer of my contact information to DeliverZero for the purposes of tracking my container returns.',
                                                        'chownowModifierName'
                                                    )
                                                }
                                                text={
                                                    copyStatus.chownowModifierName
                                                        ? 'Copied!'
                                                        : 'Copy'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <li className='my-3 text-lg'>
                                        That's it! The item should now appear on
                                        your storefront and look like below:
                                        <img
                                            src={`https://i.imgur.com/gRindiR.png`}
                                            alt='chownow screenshot 4'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                        {/* <img
                                        src={`/images/integration/chownow/chownow5.png`}
                                        alt='chownow screenshot 5'
                                        className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                    /> */}
                                    </li>
                                </ul>
                            </div>
                        )}
                        {platform && platform === 'square' && (

                            <div className='flex flex-col justify-center w-full'>
                                <h3 className='mt-10 text-center text-green-600 font-header text-2xl'>
                                    How to integrate Square with DeliverZero:
                                </h3>
                                <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] mx-auto'>
                                    <li className='my-3 text-lg'>
                                        First,{' '}<a
                                        href='https://app.squareup.com/login'
                                        target='_blank'
                                        className='font-bold text-green-600 underline'
                                    >
                                        log in to the appropriate Square account
                                    </a>
                                    </li>

                                    <li className='my-3 text-lg'>
                                        Then, use{' '}
                                        <a
                                            href='https://www.deliverzero.com/square-landing'
                                            target='_blank'
                                            className='font-bold text-green-600 underline'
                                        >
                                            this link
                                        </a>{' '}
                                        to connect DeliverZero to your Square and click "Authorize Square":{' '}
                                        <a
                                            href='https://www.deliverzero.com/square-landing'
                                            target='_blank'
                                            className='font-bold text-green-600 underline'
                                        >
                                            https://www.deliverzero.com/square-landing
                                        </a>
                                    </li>
                                    <li className='my-3 text-lg'>
                                        When prompted, click{' '}<span
                                        className='font-bold text-green-600'>Allow</span>{' '}to grant DeliverZero
                                        access to your Square. NOTE: We will not edit or change anything in your
                                        account. This connection is to read order and menu data so that we can track
                                        packaging{' '}

                                        <img
                                            src={`https://i.imgur.com/ASIXuog.jpeg`}
                                            alt='square dz screenshot 1'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>
                                    <li className='my-3 text-lg'>
                                        You should get redirected to the screen below after a successful connection. Now
                                        DeliverZero will reach out to you about bringing your reusable containers.
                                        <img
                                            src={`https://i.imgur.com/AcufkW3.jpeg`}
                                            alt='chownow dz screenshot 4'
                                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                                        />
                                    </li>

                                    <li className='my-3 text-lg'>
                                        Lastly, add the "Use DeliverZero Reusable Containers" menu item so that your customers can opt-in to reusable packaging!
                                    </li>
                                    <Button
                                        link='/menu-item-instructions'
                                        size="sm"
                                        className='px-10 w-[260px] mt-5'
                                        text='Add the DeliverZero Menu Item'
                                    />


                                    {/*UNINSTALL FROM SQUARE*/}
                                    <li className='my-10 text-lg'>
                                        To disconnect DeliverZero from Square, click the link below.<br/>
                                        {runningMongoFunction ? (<BounceLoader/>) : (
                                            <span className="underline cursor-pointer" onClick={disconnectSquare}>Disconnect from Square</span>
                                        )
                                        }
                                        <br/>
                                        {/*{mongoFunctionResult && mongoFunctionResult=== "successfully disconnected from square" && (*/}
                                        <div>
                                            <div>Successfully disconnected from Square. To reconnect, follow the
                                                steps above or click{` `}
                                                <a
                                                    href='https://www.deliverzero.com/square-landing'
                                                    target='_blank'
                                                    className='font-bold text-green-600 underline'
                                                >
                                                    this link
                                                </a>{' '}
                                                and then click the "Authorize Square" button to begin connecting
                                                DeliverZero to your Square.
                                            </div>

                                        </div>
                                        {/*)}*/}
                                        {mongoFunctionError && (<div>Error disconnecting from Square. Please contact
                                            restaurants@deliverzero.com for support.</div>)}
                                    </li>
                                    {/*<li className='my-3 text-lg'>*/}
                                    {/*	Log in to ChowNow*/}
                                    {/*	<img*/}
                                    {/*		src={`https://i.imgur.com/V9wvMjj.png`}*/}
                                    {/*		alt='chownow dz screenshot 2'*/}
                                    {/*		className='ml-3 w-[50%] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'*/}
                                    {/*	/>*/}
                                    {/*</li>*/}
                                    {/*<li className='my-3 text-lg'>*/}
                                    {/*	Click on{' '}*/}
                                    {/*	<span className='font-bold text-green-600'>*/}
                                    {/*		Headquarters*/}
                                    {/*	</span>{' '}*/}
                                    {/*	then the name of your location*/}
                                    {/*	<img*/}
                                    {/*		src={`https://i.imgur.com/W1Oxdt9.png`}*/}
                                    {/*		alt='chownow screenshot 1'*/}
                                    {/*		className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'*/}
                                    {/*	/>*/}
                                    {/*</li>*/}
                                    {/*<li className='my-3 text-lg'>*/}
                                    {/*	Click on{' '}*/}
                                    {/*	<span className='font-bold text-green-600'>*/}
                                    {/*		Menu*/}
                                    {/*	</span>{' '}*/}
                                    {/*	section then select{' '}*/}
                                    {/*	<span className='font-bold text-green-600'>*/}
                                    {/*		Edit Items*/}
                                    {/*	</span>*/}
                                    {/*	<img*/}
                                    {/*		src={`https://i.imgur.com/VDjri6G.png`}*/}
                                    {/*		alt='chownow screenshot 2'*/}
                                    {/*		className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'*/}
                                    {/*	/>*/}
                                    {/*</li>*/}
                                    {/*<li className='my-3 text-lg'>*/}
                                    {/*	Select{' '}*/}
                                    {/*	<span className='font-bold text-green-600'>*/}
                                    {/*		+ New Item*/}
                                    {/*	</span>{' '}*/}
                                    {/*	and enter the provided item information:*/}
                                    {/*</li>*/}
                                    {/*<div className='flex flex-col w-full mb-2'>*/}
                                    {/*	<label className='ml-3 text-green-600'>*/}
                                    {/*		Category Name:*/}
                                    {/*	</label>*/}
                                    {/*	<div className='flex flex-row w-full'>*/}
                                    {/*		<input*/}
                                    {/*			type='text'*/}
                                    {/*			className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'*/}
                                    {/*			value={*/}
                                    {/*				'♻️ Eco-Friendly Packaging'*/}
                                    {/*			}*/}
                                    {/*			disabled*/}
                                    {/*			readOnly*/}
                                    {/*		/>*/}
                                    {/*		<Button*/}
                                    {/*			size='sm'*/}
                                    {/*			className='ml-2 h-full w-[80px] px-10'*/}
                                    {/*			onClick={() =>*/}
                                    {/*				copyToClipboard(*/}
                                    {/*					'♻️ Eco-Friendly Packaging',*/}
                                    {/*					'chownowCategoryName'*/}
                                    {/*				)*/}
                                    {/*			}*/}
                                    {/*			text={*/}
                                    {/*				copyStatus.chownowCategoryName*/}
                                    {/*					? 'Copied!'*/}
                                    {/*					: 'Copy'*/}
                                    {/*			}*/}
                                    {/*		/>*/}
                                    {/*	</div>*/}
                                    {/*</div>*/}
                                    {/*<div className='flex flex-col w-full mb-2'>*/}
                                    {/*	<label className='ml-3 text-green-600'>*/}
                                    {/*		Item Name:*/}
                                    {/*	</label>*/}
                                    {/*	<div className='flex flex-row w-full'>*/}
                                    {/*		<input*/}
                                    {/*			type='text'*/}
                                    {/*			className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'*/}
                                    {/*			value={*/}
                                    {/*				'Use DeliverZero containers, please'*/}
                                    {/*			}*/}
                                    {/*			disabled*/}
                                    {/*			readOnly*/}
                                    {/*		/>*/}
                                    {/*		<Button*/}
                                    {/*			size='sm'*/}
                                    {/*			className='ml-2 h-full w-[80px] px-10'*/}
                                    {/*			onClick={() =>*/}
                                    {/*				copyToClipboard(*/}
                                    {/*					'Use DeliverZero containers, please',*/}
                                    {/*					'chownowItemName'*/}
                                    {/*				)*/}
                                    {/*			}*/}
                                    {/*			text={*/}
                                    {/*				copyStatus.chownowItemName*/}
                                    {/*					? 'Copied!'*/}
                                    {/*					: 'Copy'*/}
                                    {/*			}*/}
                                    {/*		/>*/}
                                    {/*	</div>*/}
                                    {/*</div>*/}
                                    {/*<div className='flex flex-col w-full mb-2'>*/}
                                    {/*	<label className='ml-3 text-green-600'>*/}
                                    {/*		Item Description:*/}
                                    {/*	</label>*/}
                                    {/*	<div className='flex flex-row w-full'>*/}
                                    {/*		<input*/}
                                    {/*			type='text'*/}
                                    {/*			className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'*/}
                                    {/*			value={*/}
                                    {/*				'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point or at your door within 3 weeks, and the containers will be thoroughly sanitized and reused. We will need to share certain info such as phone, email, and names with DeliverZero. DeliverZero provides its service in accordance with the terms available at  www.deliverzero.com/terms and will process your personal information in accordance with its privacy policy available at www.deliverzero.com/privacy.'*/}
                                    {/*			}*/}
                                    {/*			disabled*/}
                                    {/*			readOnly*/}
                                    {/*		/>*/}
                                    {/*		<Button*/}
                                    {/*			size='sm'*/}
                                    {/*			className='ml-2 h-full w-[80px] px-10'*/}
                                    {/*			onClick={() =>*/}
                                    {/*				copyToClipboard(*/}
                                    {/*					'Fight climate change and reduce waste; get your order in returnable, reusable containers. Return the containers to any DeliverZero drop point or at your door within 3 weeks, and the containers will be thoroughly sanitized and reused. We will need to share certain info such as phone, email, and names with DeliverZero. DeliverZero provides its service in accordance with the terms available at  www.deliverzero.com/terms and will process your personal information in accordance with its privacy policy available at www.deliverzero.com/privacy.'*/}
                                    {/*				)*/}
                                    {/*			}*/}
                                    {/*			text={*/}
                                    {/*				copyStatus.chownowItemDescription*/}
                                    {/*					? 'Copied!'*/}
                                    {/*					: 'Copy'*/}
                                    {/*			}*/}
                                    {/*		/>*/}
                                    {/*	</div>*/}
                                    {/*</div>*/}
                                    {/*<div className='flex flex-col w-full mb-2'>*/}
                                    {/*	<label className='ml-3 my-2 text-green-600'>*/}
                                    {/*		Photo:{' '}*/}
                                    {/*		<a*/}
                                    {/*			href='https://drive.google.com/file/d/1s4KxWUZymoCppgyyx9fQWRJH2_PB-q24/view'*/}
                                    {/*			target='_blank'*/}
                                    {/*			className='font-bold text-green-600 underline'*/}
                                    {/*		>*/}
                                    {/*			download link*/}
                                    {/*		</a>{' '}*/}
                                    {/*	</label>*/}
                                    {/*</div>*/}
                                    {/*<img*/}
                                    {/*	src={`https://i.imgur.com/KYFTZdC.png`}*/}
                                    {/*	alt='chownow screenshot 3'*/}
                                    {/*	className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'*/}
                                    {/*/>*/}
                                    {/*<li className='my-3 text-lg'>*/}
                                    {/*	Enter the provided modifier information*/}
                                    {/*	and ensure the modifier group is set to{' '}*/}
                                    {/*	<span className='font-bold text-green-600'>*/}
                                    {/*		Required*/}
                                    {/*	</span>{' '}*/}
                                    {/*	in{' '}*/}
                                    {/*	<span className='font-bold text-green-600'>*/}
                                    {/*		Modifier group settings*/}
                                    {/*	</span>*/}
                                    {/*	:*/}
                                    {/*</li>*/}
                                    {/*<div className='flex flex-col w-full mb-2'>*/}
                                    {/*	<label className='ml-3 text-green-600'>*/}
                                    {/*		Modifier Group Name:*/}
                                    {/*	</label>*/}
                                    {/*	<div className='flex flex-row w-full'>*/}
                                    {/*		<input*/}
                                    {/*			type='text'*/}
                                    {/*			className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'*/}
                                    {/*			value={'Terms of Use'}*/}
                                    {/*			disabled*/}
                                    {/*			readOnly*/}
                                    {/*		/>*/}
                                    {/*		<Button*/}
                                    {/*			size='sm'*/}
                                    {/*			className='ml-2 h-full w-[80px] px-10'*/}
                                    {/*			onClick={() =>*/}
                                    {/*				copyToClipboard(*/}
                                    {/*					'Terms of Use',*/}
                                    {/*					'chownowModifierGroupName'*/}
                                    {/*				)*/}
                                    {/*			}*/}
                                    {/*			text={*/}
                                    {/*				copyStatus.chownowModifierGroupName*/}
                                    {/*					? 'Copied!'*/}
                                    {/*					: 'Copy'*/}
                                    {/*			}*/}
                                    {/*		/>*/}
                                    {/*	</div>*/}
                                    {/*</div>*/}
                                    {/*<div className='flex flex-col w-full mb-2'>*/}
                                    {/*	<label className='ml-3 text-green-600'>*/}
                                    {/*		Modifier Name:*/}
                                    {/*	</label>*/}
                                    {/*	<div className='flex flex-row w-full'>*/}
                                    {/*		<input*/}
                                    {/*			type='text'*/}
                                    {/*			className='ml-3 w-[300px] bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-green-600 block w-full p-2.5'*/}
                                    {/*			value={*/}
                                    {/*				'I have read and agreed to DeliverZero’s terms of use and privacy policy and consent to the transfer of my contact information to DeliverZero for the purposes of tracking my container returns.'*/}
                                    {/*			}*/}
                                    {/*			disabled*/}
                                    {/*			readOnly*/}
                                    {/*		/>*/}
                                    {/*		<Button*/}
                                    {/*			size='sm'*/}
                                    {/*			className='ml-2 h-full w-[80px] px-10'*/}
                                    {/*			onClick={() =>*/}
                                    {/*				copyToClipboard(*/}
                                    {/*					'I have read and agreed to DeliverZero’s terms of use and privacy policy and consent to the transfer of my contact information to DeliverZero for the purposes of tracking my container returns.',*/}
                                    {/*					'chownowModifierName'*/}
                                    {/*				)*/}
                                    {/*			}*/}
                                    {/*			text={*/}
                                    {/*				copyStatus.chownowModifierName*/}
                                    {/*					? 'Copied!'*/}
                                    {/*					: 'Copy'*/}
                                    {/*			}*/}
                                    {/*		/>*/}
                                    {/*	</div>*/}
                                    {/*</div>*/}
                                    {/*<li className='my-3 text-lg'>*/}
                                    {/*	That's it! The item should now appear on*/}
                                    {/*	your storefront and look like below:*/}
                                    {/*	<img*/}
                                    {/*		src={`https://i.imgur.com/gRindiR.png`}*/}
                                    {/*		alt='chownow screenshot 4'*/}
                                    {/*		className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'*/}
                                    {/*	/>*/}
                                    {/*	/!* <img*/}
                                    {/*    src={`/images/integration/chownow/chownow5.png`}*/}
                                    {/*    alt='chownow screenshot 5'*/}
                                    {/*    className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'*/}
                                    {/*/> *!/*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        )}
                    </div>
                    <Footer/>
                </div>
            )
        } else {
            return (
                <div className='flex items-center h-screen'>
                    <BounceLoader
                        className='m-auto'
                        color={bounceLoaderColor}
                    ></BounceLoader>
                </div>
            )
        }
    } else {
        navigate('/')
    }
}

const mapStateToProps = ({User, AppInfo, Nodes, Platforms}) => ({
    user: User.user,
    loadingUser: User.loading,
    appInfo: AppInfo.appInfo,
    node: Nodes.restaurantAdminNode,
    loadingNode: Nodes.loadingNode,
    updateUserLoading: User.updateUserLoading,
    userUpdateCompleted: User.userUpdateCompleted,
    userError: User.error,
    otterSuccess: Platforms.platform,
    loadingOtter: Platforms.loadingPlatforms,
    otterError: Platforms.error,
    runningMongoFunction: AppInfo.runningMongoFunction,
    mongoFunctionResult: AppInfo.mongoFunctionResult,
    mongoFunctionError: AppInfo.mongoFunctionError,
})

const mapDispatchToProps = (dispatch) => ({
    onGetTransactions: (payload) => dispatch(getTransactions(payload)),
    onGetUser: () => dispatch(getUser()),
    onGetAppInfo: () => dispatch(getAppInfo()),
    onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
    onUpdateUser: (payload) => dispatch(updateUser(payload)),
    onTriggerOtter: (payload) => dispatch(triggerOtter(payload)),
    onRunMongoFunction: (payload) => dispatch(runMongoFunction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlatformIntructions)
