import React from 'react'
import { Button } from '../Elements'
import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
	DotGroup,
	Dot,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { BsTrash } from 'react-icons/bs'
import { MdOutlineWaterDrop } from 'react-icons/md'
import { FiWind } from 'react-icons/fi'

/* Install pure-react-carousel using -> npm i pure-react-carousel */

const WhyReuseCarousel = () => {
	return (
		<div className='2xl:mx-auto 2xl:container flex justify-center w-screen '>
			<div className='2xl:px-20 my-2 w-full xl:w-4/5 '>
				{/* Carousel for Small-Sized Screen */}
				<CarouselProvider
					className='relative block lg:hidden'
					naturalSlideWidth={5}
					isIntrinsicHeight={true}
					totalSlides={3}
					visibleSlides={1}
					step={1}
					infinite={true}
				>
					<div className='js-flickity flex justify-center items-center '>
						<ButtonBack
							role='button'
							aria-label='slide backward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center  absolute z-30 left-0 bottom-50 ml-8 focus:outline-none  focus:ring-2  focus:ring-gray-200 cursor-pointer'
							id='prev'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7 1L1 7L7 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonBack>
						<Slider>
							<Slide index={0}>
								{/* Waste  */}
								<div className='w-[300px] h-[300px] p-4 rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center mt-3 sm:mt-12 mb-4 mx-auto flex-shrink-0 z-10 bg-white'>
									<div className='h-full w-full flex flex-col justify-around p-4'>
										<div className='w-full flex justify-around items-center  rounded-xl  '>
											<BsTrash className='w-[100px] h-[100px] fill-green-400' />
										</div>
										<div className=' h-full flex flex-col justify-center text-md'>
											<p className=' text-center mb-2 leading-7'>
												Over{' '}
												<span className='font-semibold'>
													25 tons of single-use
													packaging
												</span>{' '}
												is diverted from{' '}
												<span className='font-semibold'>
													landfills
												</span>{' '}
												when compared to single-use
												plastics and compostables.
											</p>
										</div>
									</div>
								</div>
							</Slide>
							<Slide index={1}>
								{/* Water  */}
								<div className='w-[300px] h-[300px] lg:w-4/5 lg:max-w-[1200px] lg:h-[215px] p-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center items-center mt-3 sm:mt-12 mb-4 mx-auto flex-shrink-0 z-10 bg-white'>
									<div className='h-full w-full flex flex-col lg:flex-row justify-around p-4'>
										<div className='w-full lg:w-[175px]  lg:h-full flex justify-around items-center  rounded-xl lg:flex-shrink-0 '>
											<MdOutlineWaterDrop className='w-[100px] h-[100px] lg:w-full lg:h-[175px] lg:flex-shrink-0 lg:object-cover lg:rounded-xl fill-green-400' />
										</div>
										<div className=' h-full flex flex-col justify-center lg:items-center lg:w-full lg:px-8 text-md'>
											<p className=' text-center mb-2 lg:text-xl lg:text-start  leading-7'>
												Over{' '}
												<span className='font-semibold'>
													440,000 gallons
												</span>{' '}
												of{' '}
												<span className='font-semibold'>
													water
												</span>{' '}
												is saved when compared to
												compostables.
											</p>
										</div>
									</div>
								</div>
							</Slide>
							<Slide index={2}>
								{/* Emissions */}
								<div className='w-[300px] h-[300px] lg:w-4/5 lg:max-w-[1200px] lg:h-[215px] p-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center items-center mt-3 sm:mt-12 mb-4 mx-auto flex-shrink-0 z-10 bg-white'>
									<div className='h-full w-full flex flex-col lg:flex-row justify-around p-4'>
										<div className='w-full lg:w-[175px]  lg:h-full flex justify-around items-center  rounded-xl lg:flex-shrink-0 '>
											<FiWind className='w-[100px] h-[100px] lg:w-full lg:h-[175px] lg:flex-shrink-0 lg:object-cover lg:rounded-xl stroke-green-400' />
										</div>
										<div className=' h-full flex flex-col justify-center lg:items-center lg:w-full lg:px-8'>
											<p className=' text-center mb-2 lg:text-xl lg:text-start text-md leading-7'>
												Over{' '}
												<span className='font-semibold'>
													110 tons
												</span>{' '}
												of{' '}
												<span className='font-semibold'>
													Greenhouse Gas (GHG)
													emissions
												</span>{' '}
												are avoided compared to
												single-use plastic and
												compostable packaging.
											</p>
										</div>
									</div>
								</div>
							</Slide>
							<DotGroup className='z-90 w-full bg-black reset-element absolute bottom-0 shadow-xl' />
						</Slider>
						<ButtonNext
							role='button'
							aria-label='slide forward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center absolute z-30 right-0 bottom-50 mr-8 focus:outline-none  focus:ring-2  focus:ring-gray-200'
							id='next'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M1 1L7 7L1 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonNext>
					</div>
					<div className='w-full flex justify-center mt-2'>
						<Dot
							slide={0}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 1</span>
						</Dot>
						<Dot
							slide={1}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 2</span>
						</Dot>
						<Dot
							slide={2}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 3</span>
						</Dot>
					</div>
				</CarouselProvider>

				{/* Carousel for Medium and Large-Sized Screen */}
				<CarouselProvider
					className='relative hidden lg:block'
					naturalSlideWidth={100}
					isIntrinsicHeight={true}
					totalSlides={3}
					visibleSlides={1}
					step={1}
					infinite={true}
					currentSlide={1}
				>
					<div className='js-flickity flex justify-center items-center '>
						<ButtonBack
							role='button'
							aria-label='slide backward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center  absolute z-30 left-0 bottom-50 ml-8 focus:outline-none  focus:ring-2  focus:ring-gray-200 cursor-pointer'
							id='prev'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7 1L1 7L7 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonBack>
						<Slider>
							<Slide index={0}>
								{/* Waste  */}
								<div className='w-[300px] h-[300px] p-4 rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center mt-3 sm:mt-12 mb-4 mx-auto flex-shrink-0 z-10 bg-white'>
									<div className='h-full w-full flex flex-col justify-around p-4'>
										<div className='w-full flex justify-around items-center  rounded-xl  '>
											<BsTrash className='w-[100px] h-[100px] fill-green-400' />
										</div>
										<div className=' h-full flex flex-col justify-center text-md'>
											<p className=' text-center mb-2 leading-7'>
												Over{' '}
												<span className='font-semibold'>
													25 tons of single-use
													packaging
												</span>{' '}
												is diverted from{' '}
												<span className='font-semibold'>
													landfills
												</span>{' '}
												when compared to single-use
												plastics and compostables.
											</p>
										</div>
									</div>
								</div>
							</Slide>
							<Slide index={1}>
								{/* Water  */}
								<div className='w-[300px] h-[300px] lg:w-4/5 lg:max-w-[1200px] lg:h-[215px] p-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center items-center mt-3 sm:mt-12 mb-4 mx-auto flex-shrink-0 z-10 bg-white'>
									<div className='h-full w-full flex flex-col lg:flex-row justify-around p-4'>
										<div className='w-full lg:w-[175px]  lg:h-full flex justify-around items-center  rounded-xl lg:flex-shrink-0 '>
											<MdOutlineWaterDrop className='w-[100px] h-[100px] lg:w-full lg:h-[175px] lg:flex-shrink-0 lg:object-cover lg:rounded-xl fill-green-400' />
										</div>
										<div className=' h-full flex flex-col justify-center lg:items-center lg:w-full lg:px-8'>
											<p className=' text-center mb-2 lg:text-xl lg:text-start text-md leading-7'>
												Over{' '}
												<span className='font-semibold'>
													440,000 gallons
												</span>{' '}
												of{' '}
												<span className='font-semibold'>
													water
												</span>{' '}
												is saved when compared to
												compostables.
											</p>
										</div>
									</div>
								</div>
							</Slide>
							<Slide index={2}>
								{/* Emissions */}
								<div className='w-[300px] h-[300px] lg:w-4/5 lg:max-w-[1200px] lg:h-[215px] p-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center items-center mt-3 sm:mt-12 mb-4 mx-auto flex-shrink-0 z-10 bg-white'>
									<div className='h-full w-full flex flex-col lg:flex-row justify-around p-4'>
										<div className='w-full lg:w-[175px]  lg:h-full flex justify-around items-center  rounded-xl lg:flex-shrink-0 '>
											<FiWind className='w-[100px] h-[100px] lg:w-full lg:h-[175px] lg:flex-shrink-0 lg:object-cover lg:rounded-xl stroke-green-400' />
										</div>
										<div className=' h-full flex flex-col justify-center lg:items-center lg:w-full lg:px-8'>
											<p className=' text-center mb-2 lg:text-xl lg:text-start text-md leading-7'>
												Over{' '}
												<span className='font-semibold'>
													110 tons
												</span>{' '}
												of{' '}
												<span className='font-semibold'>
													Greenhouse Gas (GHG)
													emissions
												</span>{' '}
												are avoided compared to
												single-use plastic and
												compostable packaging.
											</p>
										</div>
									</div>
								</div>
							</Slide>
							<DotGroup className='z-90 w-full bg-black reset-element absolute bottom-0 shadow-xl' />
						</Slider>
						<ButtonNext
							role='button'
							aria-label='slide forward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full flex justify-center items-center absolute z-30 right-0 bottom-50 mr-8 focus:outline-none  focus:ring-2  focus:ring-gray-200'
							id='next'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M1 1L7 7L1 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonNext>
					</div>
					<div className='w-full flex justify-center mt-2'>
						<Dot
							slide={0}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 1</span>
						</Dot>
						<Dot
							slide={1}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 2</span>
						</Dot>
						<Dot
							slide={2}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 3</span>
						</Dot>
					</div>
				</CarouselProvider>
			</div>

			<style>
				{`
                    .gallery-cell {
                        height: 386px;
                        padding-right:15px;
                    }
                    @media (min-width: 300px) and (max-width: 420px) {
                        .gallery-cell {
                            height: 286px !important;
                            
                        }
                    }
                    
                    @media (max-width: 640px) {
                        .gallery-cell {
                            padding-right:0;
                        }
                    }

                    .carousel__sliderLarge {
                        padding-left: 20%;
                        padding-right: 20%;
                    }

                    /* gives us the illusion of spaces between the slides */
                    .carousel__inner-slideLarge {
                        width: calc(100% - 20px);
                        height: calc(100% - 20px);
                        left: 10px;

                        
                    }
					.reset-element: { all: revert; }

					.carousel__dot--selected {
						color: #ffd378 !important;
					}
                `}
			</style>
		</div>
	)
}

export default WhyReuseCarousel
