import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getOffice, registerUser } from '../../store/actions'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../../components'
import Header from '../../components/Header'
import {
	Alert,
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
	TextArea,
} from '../../components/Elements'
import PhoneInput from 'react-phone-number-input/input'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import OfficeCreditCardForm from './OfficeCreditCardForm'
import { realm } from '../../helpers/realm'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const OfficeRegistration = (state) => {
	const {
		onGetUser,
		onGetOffice,
		office,
		user,
		path,
		loadingUser,
		loggedIn,
		registrationLoading,
		registrationAttemptStatus,
		cardAdded,
		loadingAddition,
		cardAddedError,
		paymentMethods,
		loadingPaymentMethods,
		onRegisterUser,
	} = state

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	const [formErrors, setFormErrors] = useState({})
	const [existingUserError, setExistingUserError] = useState('')
	const [disableTextNotifications, setDisableTextNotifications] =
		useState(false)
	const [stripePaymentMethod, setStripePaymentMethod] = useState(null)
	const [submitForm, setSubmitForm] = useState(false)
	const [submitLoading, setSubmitLoading] = useState(false)
	const otherRequiredFields = [
		'firstName',
		'lastName',
		'officeName',
		'password',
		'aggregatedOrdering',
		'pickupCadence',
		'returnBin',
		'paymentEmail',
	]
	let navigate = useNavigate()

	const [calc, setCalc] = useState(140)
	const [displayedCalc, setDisplayedCalc] = useState(25)
	const [employeeBarValue, setEmployeeBarValue] = useState(25)
	const defaultEmployeeBarValue = 25

	const animateCount = async () => {
		// console.log("animateCount")
		if (displayedCalc < calc) {
			setTimeout(() => {
				setDisplayedCalc(displayedCalc + 1)
			}, 1)
		}
		if (displayedCalc > calc) {
			setTimeout(() => {
				setDisplayedCalc(displayedCalc - 1)
			}, 1)
		}
	}

	function handleEmployeeCount(e) {
		setCalc((e.target.value * 1.1).toFixed(0) * 5)
		setEmployeeBarValue(e.target.value)
	}

	useEffect(() => {
		animateCount()
	}, [displayedCalc, calc])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		// console.log('in useffect')
		// console.log(path)
		if (loggedIn && user && user.officeSlug) {
			// console.log('here')
			navigate('/office-ordering/home')
		}
	}, [loggedIn])

	const errorMessageLookUp = {
		'name already in use': 'This email is already in use.',
		'password must be between 6 and 128 characters':
			'Oops, looks like the password you used was less than 6 characters. Refresh the page to start sign up over.',
		'invalid username': 'That email is invalid. Try again or sign up below',
		'invalid username/password':
			'That email or password is invalid. Try again, reset your password, or sign up below',
		'invalid password':
			'That password is invalid. Try again or reset your password below',
		'invalid-email': 'Email is missing or invalid',
		'invalid-phone': 'Phone number is missing or invalid',
	}

	const [form, setForm] = useState({
		email: '',
		phone: '',
		password: '',
		signUpNewsletter: false,
		officeAdmin: true,
	})

	const handleChange = (e) => {
		if (e.target.name == 'email') {
			setForm({ ...form, [e.target.name]: e.target.value.toLowerCase() })
		} else {
			setForm({ ...form, [e.target.name]: e.target.value })
		}
	}

	const handlePhone = (e) => {
		setForm({ ...form, phone: e })
	}

	const handleSubmit = async (e) => {
		setSubmitLoading(true)
		//Clear the alert
		setSubmitForm(false)
		e.preventDefault()
		//validate the form
		await checkExistingDzUser()
		await validateFormFields()
		setSubmitForm(true)
		setSubmitLoading(false)
	}

	useEffect(() => {
		if (submitForm) {
			if (
				existingUserError === '' &&
				Object.keys(formErrors).length === 0
			) {
				onRegisterUser(form)
			}
			setSubmitForm(false)
		}
	}, [submitForm])

	const checkExistingDzUser = async () => {
		const phone = form.phone
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ phone: phone })
		if (userDoc) {
			if (userDoc.userId) {
				// If Existing DZ User, send user email instructions to sign in with existing
				// email and show alert to give them option to replace account with new account
				const sendUserSignInInfoEmail =
					await realm.currentUser.functions.sendUserSignInInfoEmail({
						phone: phone,
					})
				setExistingUserError(
					'An account already exists with this phone number! ' +
						"We've sent an email to the address associated with that phone number with sign-in instructions"
				)
			} else {
				// If Client User, submit form, we have all the info we need
				setExistingUserError('')
			}
		} else {
			setExistingUserError('')
		}
	}

	const validateFormFields = () => {
		if (!form.email || form.email === '' || !form.email.includes('@')) {
			setFormErrors((prevState) => ({
				...prevState,
				email: 'invalid-email',
			}))
		} else {
			setFormErrors((prevState) => {
				let newState = { ...prevState }
				delete newState.email
				return newState
			})
		}
		if (form.phone === '' || form.phone.length !== 12) {
			setFormErrors((prevState) => ({
				...prevState,
				phone: 'invalid-phone',
			}))
		} else {
			setFormErrors((prevState) => {
				let newState = { ...prevState }
				delete newState.phone
				return newState
			})
		}
		if (form.officePaymentMethod === '' || !form.officePaymentMethod) {
			console.log('ummm')
			setFormErrors((prevState) => ({
				...prevState,
				officePaymentMethod: 'missing-officePaymentMethod',
			}))
		} else {
			setFormErrors((prevState) => {
				let newState = { ...prevState }
				delete newState.officePaymentMethod
				return newState
			})
		}

		for (const field of otherRequiredFields) {
			if (typeof form[field] === 'undefined' || form[field] === '') {
				setFormErrors((prevState) => ({
					...prevState,
					[field]: 'missing-' + field,
				}))
			} else {
				setFormErrors((prevState) => {
					let newState = { ...prevState }
					delete newState[field]
					return newState
				})
			}
		}
	}

	const handleDisableTextNotifications = () => {
		setDisableTextNotifications(!disableTextNotifications)
	}

	useEffect(() => {
		setForm({
			...form,
			disableTextNotifications: disableTextNotifications,
		})
	}, [disableTextNotifications])

	useEffect(() => {
		setForm({
			...form,
			officePaymentMethod: stripePaymentMethod,
		})
	}, [stripePaymentMethod])

	useEffect(() => {
		if (registrationAttemptStatus == 'completed') {
			onGetUser()
		}
	}, [registrationAttemptStatus])

	return (
		<>
			<Header />
			<div className='bg-yellow-400'>
				<div className='ml-10 md:ml-28 py-5 md:py-10 text-green-600 text-2xl sm:text-4xl md:text-[50px] lg:text-[66px] font-header'>
					DeliverZero at Work
				</div>
			</div>
			<div className='relative'>
				<div
					className='bg-cover h-[8rem] sm:h-[36rem] md:h-[24rem] l:h-[36rem] xl:h-[24rem] bg-center'
					style={{
						backgroundImage:
							'url(https://i.imgur.com/lR9rIRK.jpeg)',
					}}
				></div>
			</div>
			<div className='flex flex-col items-center justify-center w-full'>
				<div className='w-full lg:w-4/5 flex flex-col items-center justify-center'>
					<h1 className='text-3xl lg:text-5xl font-header text-green-600 text-center w-full lg:w-4/5 mt-8 mb-6'>
						Reduce waste at work
					</h1>
					<div className='flex justify-center text-center w-4/5 mb-6 lg:mb-12 lg:mt-0'>
						<a href='#signup'>
							<Button
								text='Sign Up'
								color='green'
								size='sm'
								className='flex w-[210px]'
							/>
						</a>
					</div>
					<h2 className='text-lg lg:text-xl text-center w-full lg:w-4/5 px-4 mb-4'>
						We make it easy to order delivery in returnable reusable
						containers with serviced return bins and custom programs
						designed to meet your needs.
					</h2>
					<p className='w-4/5 text-center font-light '>
						<span className='text-base font-black'>
							One office with 65 employees saves an average of 350
							containers from the trash every week.{' '}
						</span>
						Every container used reduces the upstream and downstream
						emissions associated with single-use packaging.{` `}
						<a
							target='_blank'
							className='font-semibold underline text-green-600'
							href='https://upstreamsolutions.org/reuse-wins-report'
							rel='noreferrer'
						>
							Learn more about the benefits of reuse.
						</a>
					</p>
				</div>

				<div className='w-full lg:w-4/5 px-4 flex flex-col justify-center items-center mt-8'>
					<div className=' lg:w-[900px] flex flex-col items-center justify-center'>
						<h1 className='text-2xl lg:text-3xl font-header text-green-600 text-center w-full lg:w-4/5 mt-8 mb-6'>
							How much waste can your office reduce?
						</h1>
						<div className='text-sm lg:text-base w-5/6 max-w-[400px] mx-4'>
							<form action='#'>
								<label
									htmlFor='employees'
									className=' text-gray-700 w-full'
								>
									# of Employees
								</label>
								<input
									type='range'
									min='1'
									name='employees'
									id='employees'
									max='500'
									defaultValue={defaultEmployeeBarValue}
									className='w-full h-2 bg-green-400 rounded-full appearance-none'
									onChange={(event) =>
										handleEmployeeCount(event)
									}
								/>
							</form>
							<div>{employeeBarValue} Employees</div>
						</div>
						<div className='text-2xl font-header text-green-600 text-center w-4/5 mt-8 mb-6 flex flex-row'>
							<div className='w-full'>
								<p>
									You could save... <span> </span>{' '}
								</p>
								<span className='text-5xl mx-6'>
									{displayedCalc}
								</span>
								<p>
									containers from the trash and our planet per
									week!
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div
					id='signup'
					className='pt-10 md:pt-20 pb-[42rem] md:pb-0'
					style={{
						backgroundImage: `url("https://i.imgur.com/N8Tqgz8.jpg")`,
						height: '100vh', // this makes the div fill the viewport
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}}
				>
					<div className='z-20 flex flex-col p-5 mx-auto max-w-[500px] bg-green-600 shadow-light-grey rounded-sm h-[600px]'>
						<div className='bg-white shadow-light-grey rounded-xl w-full min-h-full overflow-y-auto'>
							{existingUserError !== '' && (
								<Alert
									type='warning'
									content={existingUserError}
									className='text-sm mt-2 mb-4'
								/>
							)}
							{Object.keys(formErrors).length > 0 && (
								<Alert
									type='warning'
									content={
										errorMessageLookUp[
											formErrors[
												Object.keys(formErrors)[0]
											]
										]
											? errorMessageLookUp[
													formErrors[
														Object.keys(
															formErrors
														)[0]
													]
											  ]
											: 'Missing fields, see red fields below'
									}
									className='text-sm mb-4'
								/>
							)}
							<h1 className='font-header text-green-600 text-4xl my-6 text-center'>
								Sign up
							</h1>
							<div className='mx-4'>
								<form
									className='form-horizontal'
									onSubmit={(e) => handleSubmit(e)}
								>
									<h1 className='text-xl mt-4 mb-4 text-center'>
										After you sign up, you'll receive access
										to your admin dashboard to schedule
										pickups and track your impact
									</h1>
									<Input
										type='name'
										label='NAME OF YOUR COMPANY'
										name='officeName'
										onChange={handleChange}
										error={formErrors['officeName']}
										className='bg-white border-2 border-gray-300 mb-4'
									/>
									<Input
										type='name'
										label='YOUR FIRST NAME'
										name='firstName'
										onChange={handleChange}
										error={formErrors['officeName']}
										className='bg-white border-2 border-gray-300 mb-4'
									/>
									<Input
										type='name'
										label='YOUR LAST NAME'
										name='lastName'
										onChange={handleChange}
										error={formErrors['officeName']}
										className='bg-white border-2 border-gray-300 mb-4'
									/>
									<div className={'pb-1'}>
										<label
											className={`text-sm text-gray-600 font-poppins ${
												formErrors['phone'] &&
												'text-red-600'
											} -translate-y-5`}
											htmlFor={'phone'}
										>
											PHONE
										</label>
									</div>
									<PhoneInput
										onChange={handlePhone}
										country='US'
										value={form.phone}
										id='phone'
										name='phone'
										label='PHONE'
										className={` form-control bg-white border-2 border-gray-300 w-full  rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow ${
											formErrors['phone'] &&
											`border-red-600 mb-6`
										}`}
										type='phone'
									/>
									<div className='my-3'>
										<CheckboxGroup
											label='Do not send this number text notifications for order activity or return reminders'
											name='terms'
											className=''
											labelClassName='!text-xs'
											defaultChecked={
												disableTextNotifications
											}
											onChange={
												handleDisableTextNotifications
											}
										/>
									</div>
									{/*<p className='text-xs mb-8'>*/}
									{/*    If you've ordered in DeliverZero boxes*/}
									{/*    from a third-party, please use the phone*/}
									{/*    number from those orders*/}
									{/*</p>*/}
									<Input
										type='email'
										label='LOGIN EMAIL'
										name='email'
										onChange={handleChange}
										error={formErrors['email']}
										className='bg-white border-2 border-gray-300 '
									/>
									<p className='text-xs mb-4 font-bold'>
										Please use your company email address!
									</p>
									<Input
										type='password'
										label='PASSWORD'
										name='password'
										onChange={handleChange}
										error={formErrors['password']}
										className='bg-white border-2 border-gray-300 mb-6'
									/>

									<h1 className='font-header text-green-600 text-2xl my-6 text-center'>
										Ordering Information
									</h1>
									<p
										className={`${
											formErrors['aggregatedOrdering'] &&
											'text-red-600'
										}`}
									>
										How are orders placed?
									</p>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-6'
										name='aggregatedOrdering'
										horizontal
										radios={[
											{
												value: 'true',
												label: 'I or another admin submits orders on behalf of all our employees (including group ordering)',
												labelClassName: 'w-[95%] mt-2',
											},
											{
												value: 'false',
												label: 'Employees submit their orders individually',
												labelClassName: 'w-[95%] mt-2',
											},
										]}
										onChange={(aggregatedOrdering) => {
											setForm({
												...form,
												['aggregatedOrdering']:
													aggregatedOrdering ===
													'true',
											})
										}}
									/>

									<p>
										Which ordering platform does your office
										use?{' '}
										<span className='italic text-sm'>
											(Optional)
										</span>
									</p>
									<TextArea
										name='Pickup Note'
										label=''
										placeholder='e.g. DoorDash for Work (List all that apply)'
										description=''
										className='!h-12 !mt-1 !text-sm mb-2'
										onChange={(e) => {
											setForm({
												...form,
												['orderingPlatforms']: e,
											})
										}}
									/>

									<h1 className='font-header text-green-600 text-2xl my-6 text-center'>
										Pickup Information
									</h1>
									<p
										className={`${
											formErrors['pickupCadence'] &&
											'text-red-600'
										}`}
									>
										How often will you want DeliverZero to
										pick up your used containers?
									</p>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-6'
										name='pickupCadence'
										horizontal
										radios={[
											{
												value: 'weekly',
												label: 'Weekly',
												labelClassName: 'mt-2',
											},
											{
												value: 'daily',
												label: 'Daily',
												labelClassName: 'mt-2',
											},
											{
												value: 'ondemand',
												labelClassName: 'w-[95%] mt-2',
												label: "By Request (you'll be able to request pickups 24 hours in advance through your dashboard)",
											},
											{
												value: 'by-order',
												label: "Same day as order (when you order a day ahead, we'll automatically schedule a pickup for you)",
												labelClassName: 'mt-2',
											},
										]}
										onChange={(pickupCadence) => {
											setForm({
												...form,
												['pickupCadence']:
													pickupCadence,
											})
										}}
									/>

									<p>
										Pick up Note{' '}
										<span className='italic text-sm'>
											(Optional)
										</span>
									</p>
									<TextArea
										name='Delivery Note'
										label=''
										placeholder='Include any special instructions for how to enter your building here'
										description='Maximum of 250 characters'
										className='!h-24 !mt-1'
										onChange={(e) => {
											setForm({
												...form,
												['pickupNotes']: e,
											})
										}}
									/>

									<p
										className={`${
											formErrors['returnBin'] &&
											'text-red-600'
										} mt-6`}
									>
										Do you want a bin in your office where
										your employees can drop off their used
										containers?
									</p>
									<RadioGroup
										className='mt-1 sm:mt-0 mb-6'
										name='returnBin'
										horizontal
										radios={[
											{
												value: 'true',
												label: 'Yes',
												labelClassName: 'mt-2',
											},
											{
												value: 'false',
												label: 'No',
												labelClassName: 'mt-2',
											},
										]}
										onChange={(returnBin) => {
											setForm({
												...form,
												['returnBin']:
													returnBin === 'true',
											})
										}}
									/>

									<h1 className='font-header text-green-600 text-2xl my-6 text-center'>
										Payment Information
									</h1>
									<Elements stripe={stripePromise}>
										<OfficeCreditCardForm
											form={form}
											setStripePaymentMethod={
												setStripePaymentMethod
											}
											setForm={setForm}
											formErrors={formErrors}
										/>
									</Elements>

									{submitLoading ||
									registrationLoading ||
									loadingUser ? (
										<div className='mt-3 mb-10 text-center'>
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										</div>
									) : (
										<div className='text-center'>
											<Button
												color='green'
												full
												text='Sign Up'
												className='mb-10 w-5/6 md:w-2/3 mx-auto'
												submit={true}
											/>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
					<div className='z-20 flex flex-col p-5 mx-auto max-w-[500px] bg-green-600 shadow-light-grey rounded-sm h-fit mt-12'>
						<div className='bg-white shadow-light-grey rounded-xl w-full min-h-full overflow-y-auto'>
							<div className='w-full mx-auto my-4 flex flex-col text-center'>
								Already have a pin for an office account?
								<span
									className='underline  text-green-600 cursor-pointer'
									onClick={() => {
										navigate(
											'/office-ordering/verification'
										)
									}}
								>
									Click here to connect
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

const mapStateToProps = ({
	User,
	OfficeOrdering,
	Account,
	Login,
	Path,
	Payments,
}) => ({
	office: OfficeOrdering.office,
	user: User.user,
	loggedIn: User.loggedIn,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,

	registrationLoading: Account.loading,
	registrationAttemptStatus: Account.registrationAttemptStatus,
	registrationError: Account.registrationError,
	path: Path.path,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOffice: (officeSlug) => dispatch(getOffice(officeSlug)),
	onRegisterUser: (form) => dispatch(registerUser(form)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeRegistration)
