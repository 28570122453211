import { call, put, takeEvery } from 'redux-saga/effects'
import {
	insertTransaction,
	createOrUpdateTransaction,
} from '../../../model/transactions'
import {
	createTransactionSuccess,
	createTransactionFail,
	createOrUpdateTransactionError,
	createOrUpdateTransactionSuccess,
} from './actions'
import { CREATE_TRANSACTION, CREATE_OR_UPDATE_TRANSACTION } from './actionTypes'

function* createTransaction(action) {
	try {
		// console.log(action.payload)
		const transaction = yield call(insertTransaction, action.payload)
		// console.log(transaction)
		yield put(createTransactionSuccess(transaction))
	} catch (error) {
		yield put(createTransactionFail(error))
	}
}

function* createOrUpdateTransactionSaga(action) {
	try {
		const transaction = yield call(
			createOrUpdateTransaction,
			action.payload
		)
		yield put(createOrUpdateTransactionSuccess(transaction))
	} catch (error) {
		yield put(createOrUpdateTransactionError(error))
	}
}

function* createTransactionSaga() {
	yield takeEvery(CREATE_TRANSACTION, createTransaction)
	yield takeEvery(CREATE_OR_UPDATE_TRANSACTION, createOrUpdateTransactionSaga)
}

export default createTransactionSaga
