import {
	CONNECT_TO_CLIENT,
	CONNECT_TO_CLIENT_FAILED,
	CONNECT_TO_CLIENT_SUCCESSFUL,
	AUTHORIZE_CHOWNOW,
	AUTHORIZE_CHOWNOW_SUCCESSFUL,
	AUTHORIZE_CHOWNOW_FAILED,
	GET_SQUARE_AUTH_LINK,
	GET_SQUARE_AUTH_LINK_SUCCESS,
	GET_SQUARE_AUTH_LINK_ERROR,
} from './actionTypes'

export const connectToClient = (payload) => {
	return {
		type: CONNECT_TO_CLIENT,
		payload: payload,
	}
}

export const connectToClientSuccessful = (payload) => {
	return {
		type: CONNECT_TO_CLIENT_SUCCESSFUL,
		payload: payload,
	}
}

export const connectToClientFailed = (payload) => {
	return {
		type: CONNECT_TO_CLIENT_FAILED,
		payload: payload,
	}
}

export const authorizeChownow = (payload) => {
	return {
		type: AUTHORIZE_CHOWNOW,
		payload: payload,
	}
}

export const authorizeChownowSuccessful = (payload) => {
	return {
		type: AUTHORIZE_CHOWNOW_SUCCESSFUL,
		payload: payload,
	}
}

export const authorizeChownowFailed = (payload) => {
	return {
		type: AUTHORIZE_CHOWNOW_FAILED,
		payload: payload,
	}
}

export const getSquareAuthLink = (payload) => ({
	type: GET_SQUARE_AUTH_LINK,
	payload: payload,
});

export const getSquareAuthLinkSuccess = (payload) => ({
	type: GET_SQUARE_AUTH_LINK_SUCCESS,
	payload: payload,
});

export const getSquareAuthLinkError = (payload) => ({
	type: GET_SQUARE_AUTH_LINK_ERROR,
	payload: payload,
});