import { realm } from '../helpers/realm'

// need to add pagination potentially
export const getAllReusableProducts = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const products = client.db('caas').collection('products')

	const allProducts = await products.find(payload)

	return allProducts
}

export const handleAddToCartReusablesShop = ({
	currCartItems,
	nextCartItem,
	quantity, // quantity to increment by
}) => {
	if (currCartItems && currCartItems[nextCartItem.id]) {
		// item already exists so just increment qty
		const item = currCartItems[nextCartItem.id]
		item.quantity += quantity
		currCartItems[nextCartItem.id] = item
	} else {
		currCartItems[nextCartItem.id] = nextCartItem
	}
	return currCartItems
}

export const handleUpdateCartItemReusablesShop = ({
	currCartItems,
	cartItemToUpdate,
	quantity, // quantity to update to
}) => {
	if (currCartItems && currCartItems[cartItemToUpdate.id]) {
		const item = currCartItems[cartItemToUpdate.id]
		if (quantity > 0) {
			item.quantity = quantity
			currCartItems[cartItemToUpdate.id] = item
		} else {
			// need to remove cart item
			delete currCartItems[cartItemToUpdate.id]
		}
	}

	return currCartItems
}

export const handleClearCartReusablesShop = () => {
	const clearedCart = {}
	return clearedCart // reducer cartItems var will be set to {} which will trigger user doc update
}

// to update local copy with user doc cart or local storage cart if user not logged in
export const updateCartFromUser = (reusablesShopCart) => {
	if (reusablesShopCart.cartItems) {
		return reusablesShopCart.cartItems
	}
	return {}
}

export const addCartToUserReusablesShop = async (payload) => {
	const cartObj = payload
	const client = realm.currentUser.mongoClient('RealmService')
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const users = client.db('caas').collection('users')
	await users.updateOne(
		{ userId: realm.currentUser.id },
		{ $set: { reusablesShopCart: cartObj } }
	)
}

// export const getProductBySku = async (payload) => {
// 	const client = realm.currentUser.mongoClient('RealmService')
// 	const products = client.db('caas').collection('products')

// 	const product = await products.findOne({sku: payload})

// 	return product
// }

export const submitReusablesOrder = async (submitOrderPayload) => {
	const submittedOrder =
		await realm.currentUser.functions.dzReusablesOrderHandler(
			submitOrderPayload
		)

	return submittedOrder
}

export const totalReusablesCart = (cartItems) => {
	let subtotal = 0

	for (const sku in cartItems) {
		const item = cartItems[sku]
		subtotal += item.quantity * item.price
	}

	return subtotal
}

export const calculateShipping = async (payload) => {
	// console.log("payload", payload)
	const response = await realm.currentUser.callFunction(
		// 'reusables/shippingCalculator',
		'reusables/shippoEstimate',
		payload
	)

	return response
}

export const getResuablesOrders = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const reusablesOrders = client.db('caas').collection('reusables_shop')

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const stages = []

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

	return reusablesOrders.aggregate(stages)
}

export const resetReusables = () => {
	return true
}
