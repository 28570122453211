import {
	GET_MENU_MAP,
	GET_MENU_MAP_SUCCESS,
	GET_MENU_MAP_FAILURE,
	UPDATE_MENU_MAP,
	UPDATE_MENU_MAP_SUCCESS,
	UPDATE_MENU_MAP_FAILURE,
	GET_MENU_MAP_FROM_CLIENT,
} from './actionTypes'

export const getMenuMap = (payload) => ({
	type: GET_MENU_MAP,
	payload: payload,
})

export const getMenuMapSuccess = (payload) => ({
	type: GET_MENU_MAP_SUCCESS,
	payload: payload,
})

export const getMenuMapFailure = (payload) => ({
	type: GET_MENU_MAP_FAILURE,
	payload: payload,
})

export const updateMenuMap = (payload) => ({
	type: UPDATE_MENU_MAP,
	payload: payload,
})

export const updateMenuMapSuccess = (payload) => ({
	type: UPDATE_MENU_MAP_SUCCESS,
	payload: payload,
})

export const updateMenuMapFailure = (payload) => ({
	type: UPDATE_MENU_MAP_FAILURE,
	payload: payload,
})

export const getMenuMapFromClient = (payload) => ({
	type: GET_MENU_MAP_FROM_CLIENT,
	payload: payload,
})
