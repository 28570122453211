import { UPDATE_PATH } from './actionTypes'

const initialState = {
	path: '/',
}

const path = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PATH:
			return {
				...state,
				path: action.payload,
			}
		default:
			state = { ...state }
			return state
	}
}

export default path
