import React, { useState } from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { Button } from '../../components/Elements'

import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

function HowToAddMenuItem() {
    const FAQs = {
        'How do I order food?':
            'Search your address on our to see which restaurants deliver to you. ' +
            'You can order from select restaurants directly through our site, or you will be redirected to another platform. ' +
            'If you order through another platform, you will see an option to opt-in to receive your order in our containers on ' +
            'the restaurant’s menu or on the checkout page.',
        'How do I order in DeliverZero containers on other platforms?':
            'When you use our order page to see which restaurants deliver to you, you can filter by your preferred platform. When you select a restaurant, you’ll be redirected to the restaurant’s menu on another platform. On the menu, you’ll see an option to opt-in to receive your order in reusable containers on the restaurant’s menu, as an item-level modifier (similar to choosing a dressing or adding avocado), or on the checkout page.',
        'Does it cost extra to order in DeliverZero containers?':
            'When you order pickup or delivery through our site or app, there is a small service fee that allows us to manage everything in the background to retrieve, sanitize, and reuse our containers, so that you can continue dining without the waste. If you order through another platform, there may be a small fee associated with ordering in our containers.',
        'How do I return my containers?':
            'You can return your boxes to any return point on the DeliverZero network, schedule a pickup, or order from a restaurant whose courier accepts returns. Find a return point near you or schedule a return by clicking "Return Containers" in our menu',
        'How do I return containers at my door?':
            'You can schedule a container pickup by clicking "Return Containers" in our menu and the "Return at your door". Then enter the address where you are returning from, select an available pickup date, and tell us how many container you are returning. We take advantage of existing empty courier legs and use route optimization so that pick ups are as efficient and minimally impactful as possible.',
        'Do I have to clean my containers before returning them?':
            'Please make sure your containers are rinsed clean and free of food debris before returning them. They will be sanitized in a commercial dishwasher before they are reused.',
        'What happens after I return the containers? How often can they be reused?':
            'The containers you return will be sanitized and reused. They are designed to be used up to 1,000 times and withstand the high heat of commercial dishwashers. They are BPA free and NSF certified.',
        'What are the environmental benefits of ordering in DeliverZero containers?':
            'Each time one of our containers is used, it saves a single-use container from ending up in the trash. According to Upstream’s Reuse Wins report, reusable food service containers beat single-use alternatives through every environmental measure, including climate pollution, energy use, water use, resource extraction, waste, and plastic pollution.',
        'How do I get in touch?':
            'For media or partnership inquiries, please email us at hello@deliverzero.com. For customer support inquiries, please contact support@deliverzero.com.',
    }

    return (
        <>
            <Header/>
            <div
                className='underline font-bold my-4 cursor-pointer ml-6'
                onClick={() => {
                    navigate('/restaurant-admin/home')
                }}
            >
                &#8592; Go to Dashboard Home
            </div>
            <div
                className='underline font-bold my-4 cursor-pointer ml-6'
                onClick={() => {
                    navigate(-1)
                }}
            >
                &#8592; Go Back
            </div>
            <div className='flex flex-col justify-center'>

                <div className='w-[90%] md:w-4/5 max-w-[950px] mx-auto'>
                    <h1 className='text-2xl md:text-5xl font-header text-green-600 w-full mb-4 mt-12'>
                        How to add the DeliverZero Menu Item
                    </h1>
                    <div className="my-4">After successfully connecting your ordering platforms to DeliverZero, and
                        after receiving reusable containers, the last step is adding the DeliverZero
                        menu item to your menu. Customers add this item to their order to indicate that they want their
                        meal packed in our containers.
                    </div>
                    <ul className='list-disc pl-5 flex flex-col justify-center max-w-[60%] min-w-[350px] mx-auto'>
                        <li className='my-3 text-lg'>
                            Go to the Menu section of your platform's dashboard
                        </li>
                        <img
                            src={`https://i.imgur.com/HIjhAg9.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <li className='my-3 text-lg'>
                            Create a new Category called "Eco-friendly Packaging ♻️"
                        </li>
                        <img
                            src={`https://i.imgur.com/jWo2lgd.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <img
                            src={`https://i.imgur.com/8LyCZF2.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full mt-1 max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <li className='my-3 text-lg'>
                            Create an item called "Use DeliverZero Reusable Containers, please!" and put it inside the
                            "Eco-friendly Packaging ♻️" category
                        </li>
                        <img
                            src={`https://i.imgur.com/7JSiZy3.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <img
                            src={`https://i.imgur.com/B697Kns.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full mt-1 max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <img
                            src={`https://i.imgur.com/c4z7QO6.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full mt-1 max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <li className='my-3 text-lg'>
                            Add this category and menu item to all applicable menus
                        </li>
                        <li className='my-3 text-lg'>
                            Sort your categories to put the "Eco-friendly Packaging" category on top
                        </li>
                        <img
                            src={`https://i.imgur.com/hbKtcrz.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <img
                            src={`https://i.imgur.com/ASR4YTG.jpeg`}
                            alt='add the deliverzero menu item'
                            className='w-full mt-1 max-w-[1000px] h-auto my-auto mx-auto flex justify-center items-center border-4 border-green-600'
                        />
                        <li className='my-3 text-lg mb-20'>
                            Save and publish your menu. You're all set now!
                        </li>
                    </ul>
                </div>

            </div>
            <Footer/>
        </>
    )
}

export default HowToAddMenuItem
