export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL'
export const GET_ONE_TRANSACTION = 'GET_ONE_TRANSACTION'
export const GET_ONE_TRANSACTION_SUCCESS = 'GET_ONE_TRANSACTION_SUCCESS'
export const DISPUTE_TRANSACTION = 'DISPUTE_TRANSACTION'
export const DISPUTE_TRANSACTION_SUCCESS = 'DISPUTE_TRANSACTION_SUCCESS'
export const GET_TRANSACTIONS_BY_CLIENT = 'GET_TRANSACTIONS_BY_CLIENT'
export const GET_NODE_TRANSACTIONS = 'GET_NODE_TRANSACTIONS'
export const GET_NODE_TRANSACTIONS_SUCCESS = 'GET_NODE_TRANSACTIONS_SUCCESS'
export const GET_NODE_TRANSACTIONS_FAIL = 'GET_NODE_TRANSACTIONS_FAIL'
export const ADMIN_UPLOAD_ORDERS = 'ADMIN_UPLOAD_ORDERS'
export const ADMIN_UPLOAD_ORDERS_SUCCESS = 'ADMIN_UPLOAD_ORDERS_SUCCESS'
export const ADMIN_UPLOAD_ORDERS_ERROR = 'ADMIN_UPLOAD_ORDERS_ERROR'
export const UPDATE_ONE_TRANSACTION = 'UPDATE_ONE_TRANSACTION'
export const UPDATE_ONE_TRANSACTION_SUCCESS = 'UPDATE_ONE_TRANSACTION_SUCCESS'
export const UPDATE_ONE_TRANSACTION_ERROR = 'UPDATE_ONE_TRANSACTION_ERROR'
export const GET_ANALYTICS_TRANSACTIONS = 'GET_ANALYTICS_TRANSACTIONS'
export const GET_ANALYTICS_TRANSACTIONS_SUCCESS =
	'GET_ANALYTICS_TRANSACTIONS_SUCCESS'
export const GET_ANALYTICS_TRANSACTIONS_FAIL = 'GET_ANALYTICS_TRANSACTIONS_FAIL'
export const RESET_UPDATE_TRANSACTION = 'RESET_UPDATE_TRANSACTION'

export const ADMIN_DISPUTE_TRANSACTION = 'ADMIN_DISPUTE_TRANSACTION'
export const ADMIN_DISPUTE_TRANSACTION_SUCCESS =
	'ADMIN_DISPUTE_TRANSACTION_SUCCESS'
export const ADMIN_DISPUTE_TRANSACTION_FAIL = 'ADMIN_DISPUTE_TRANSACTION_FAIL'
