import { realm } from '../helpers/realm'

export const createPendingPickup = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const pendingPickups = client.db('caas').collection('pending_pickup')

	const existingDoc = await pendingPickups.findOne({
		timestamp: payload.timestamp,
		'user.phone': payload.user.phone,
	})

	if (existingDoc) {
		const updateResult = await pendingPickups.updateOne(
			{ _id: existingDoc._id },
			{ $set: payload }
		)

		if (updateResult.modifiedCount === 1) {
			const updateResponse = await realm.currentUser.callFunction(
				'returnmates/updatePickupRequest',
				{ payload: payload, existingDoc: existingDoc }
			)

			return updateResponse
		} else {
			throw new Error('Error updating existing pickup request.')
		}
	} else {
		const insertResult = await pendingPickups.insertOne(payload)
		return insertResult
	}
}

export const getAllPendingPickups = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const pendingPickups = client.db('caas').collection('pending_pickup')

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const stages = []

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

	return pendingPickups.aggregate(stages)
}

export const getPendingPickups = async (filter) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const pendingPickups = client.db('caas').collection('pending_pickup')
	const transactions = client.db('caas').collection('transactions')

	const transactionFilter = {
		timestamp: {
			$gte: filter[0].timestamp.$gte,
			$lt: filter[0].timestamp.$lt,
		},
		returnAtDoor: true,
		reverseLogisticsPartner: { $in: ['flot', 'deliverzero'] },
	}

	const [pendingPickupsResult, transactionsResult] = await Promise.all([
		pendingPickups.find(transactionFilter, { $limit: 100 }),
		transactions.find(transactionFilter, { $limit: 100 }),
	])

	let marketResult = []
	if (filter && filter[0].market) {
		marketResult = await checkMarketPolygonLocal({
			transactionArray: [...pendingPickupsResult, ...transactionsResult],
			market: filter[0].market,
		})
	}

	return marketResult
}

export const getPickupsByPhone = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const pendingPickups = client.db('caas').collection('pending_pickup')

	const pickups = await pendingPickups.find({
		'user.phone': payload.phone,
	})

	return pickups
}

export const getPickupById = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const pendingPickups = client.db('caas').collection('pending_pickup')
	const pickupByOrderId = await pendingPickups.findOne({ orderId: payload })
	const pickupsByPhone = await pendingPickups.find({ 'user.phone': payload })

	// console.log("pickupByOrderId", pickupByOrderId);
	// console.log("pickupsByPhone", pickupsByPhone);

	return pickupByOrderId || pickupsByPhone
}

export const createGentlyPickup = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const pickupResponse = await realm.currentUser.callFunction(
		'gently/pickupRequest',
		payload
	)

	return pickupResponse
}

export const createGentlyPickupNoNode = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const pickupResponse = await realm.currentUser.callFunction(
		'gently/pickupRequestNoNode',
		payload
	)

	return pickupResponse
}
