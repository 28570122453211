import React, { useEffect, useState } from 'react'
import {
	getUser,
	updateMailchimpMergeFields,
	updateUser,
	deletePaymentMethod,
	storePaymentMethod,
} from '../../store/actions'
import { connect } from 'react-redux'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Button, BounceLoaderCentered } from '../Elements'
import AddressEditForm from './AddressEditForm'
import { useNavigate } from 'react-router-dom'

const ProfileInfoCard = (state) => {
	const {
		user,
		onGetUser,
		loadingUser,
		loadingDeletion,
		onDeletePaymentMethod,
		cardDeletionError,
		cardDeleted,
		onStorePaymentMethod,
		cardAdded,
		cardAddedError,
		loadingAddition,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateMailchimpMergeFields,
	} = state

	const [showCardForm, setShowCardForm] = useState(false)
	const [showAddressForm, setShowAddressForm] = useState(false)

	let navigate = useNavigate()

	// console.log(deletePayload)
	const deleteCard = (deletePayload) => {
		onDeletePaymentMethod(deletePayload)
		onGetUser()
	}

	const stripe = useStripe()
	const elements = useElements()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}
	const [addCardError, setAddCardError] = useState(false)
	const [stripeLoading, setStripeLoading] = useState(false)

	useEffect(() => {
		if (user && user.address) {
			onUpdateMailchimpMergeFields(user)
		}
	}, [user?.address])

	const handleAddCardSubmit = async (event) => {
		setStripeLoading(true)
		event.preventDefault()
		setAddCardError(false)
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})
		if (result.error) {
			setStripeLoading(false)
			setAddCardError(true)
		} else {
			setStripeLoading(false)
			onStorePaymentMethod({
				stripeCustomerId: user.stripeId,
				paymentMethodId: result.paymentMethod.id,
			})
			setShowCardForm(false)
		}
	}

	const handleEditSelect = () => {
		setShowAddressForm(!showAddressForm)
	}

	const handleHideAddressForm = () => {
		setShowAddressForm(false)
	}

	useEffect(() => {
		if (cardDeleted) {
			onGetUser()
		}
	}, [cardDeleted])

	useEffect(() => {
		if (cardAdded) {
			onGetUser()
		}
	}, [cardAdded])

	useEffect(() => {
		if (userUpdateCompleted) {
			// console.log('userUpdateCompleted')
			onGetUser()
		}
	}, [userUpdateCompleted])

	return (
		<div className='card sm:flex sm:flex-row'>
			<div className='w-full sm:w-[350px] mr-7'>
				<h3
					className={`mt-2 block font-header text-green-400 text-3.5xl`}
				>
					My Info
				</h3>
				{!user && <BounceLoaderCentered container='div' />}
				{user && (
					<div className='mt-3 overflow-visible'>
						<div className='my-3 text-md lg:text-[18px] font-bold'>
							{user.firstName + ' ' + user.lastName}
						</div>
						<div className='my-3 text-md lg:text-[18px]'>
							{user.phone}
						</div>
						<div className='my-3 text-md lg:text-[18px] overflow-hidden'>
							{user.email}
						</div>
						<div>
							{user.address != '' && (
								<div>
									<div className='flex flex-col mt-0'>
										<div className='mt-3 text-base lg:text-[18px] w-48'>
											{user.address}
										</div>
										{user.address2 &&
											user.address2 != '' && (
												<div className='mb-3 text-base lg:text-[18px] w-48'>
													Unit: {user.address2}
												</div>
											)}
									</div>
									{!updateUserLoading && !showAddressForm && (
										<div
											className='my-3 link-sm'
											onClick={(e) => {
												handleEditSelect(e)
											}}
										>
											Change default address
										</div>
									)}
									{!updateUserLoading && showAddressForm && (
										<div
											className='my-3 link-sm'
											onClick={(e) => {
												handleEditSelect(e)
											}}
										>
											Close list
										</div>
									)}
								</div>
							)}
							{user.address == '' && (
								<button
									className='my-3 text-[16px] underline text-green-600 font-semibold'
									onClick={() => {
										setShowAddressForm(true)
									}}
								>
									Add new address
								</button>
							)}
						</div>
						{showAddressForm && (
							<AddressEditForm
								user={user}
								onGetUser={onGetUser}
								showAddressForm={showAddressForm}
							/>
						)}
						{!updateUserLoading && showAddressForm && (
							<div
								className=' link-sm'
								onClick={(e) => {
									handleEditSelect(e)
								}}
							>
								Close list
							</div>
						)}
					</div>
				)}
				<div
					className='mb-3 mt-6 link-sm'
					onClick={() => {
						navigate('/user/manageaccount')
					}}
				>
					Manage Account
				</div>
			</div>
			<div className='w-full sm:w-[350px]'>
				{user && (
					<>
						<h3
							className={`mt-2 block font-header text-green-400 text-3.5xl`}
						>
							My Payment Info
						</h3>
						{cardDeletionError && (
							<div className='mt-3 text-center text-sm text-red-400'>
								Error while deleting card. Contact customer
								service.
							</div>
						)}
						{cardAddedError ||
							(addCardError && (
								<div className='mt-3 text-center text-sm text-red-400'>
									Error while adding card. Contact customer
									service.
								</div>
							))}
						{loadingDeletion ||
							loadingAddition ||
							loadingUser ||
							(stripeLoading && (
								<BounceLoaderCentered container='div' />
							))}
						{!loadingDeletion &&
							!loadingUser &&
							!loadingAddition &&
							!stripeLoading && (
								<div>
									{!user.paymentMethods ||
										(user.paymentMethods.length == 0 && (
											<div className='text-md lg:text-[18px] mt-3'>
												No cards on file
											</div>
										))}
									{user.paymentMethods &&
										user.paymentMethods.length > 0 && (
											<div>
												{user.paymentMethods.map(
													(paymentMethod, i) => {
														return (
															<div
																key={i}
																className='flex flex-row'
															>
																<div>
																	<div className='mt-3 text-md lg:text-[18px]'>
																		{paymentMethod.card.brand.toUpperCase()}
																		....
																		{
																			paymentMethod
																				.card
																				.last4
																		}
																	</div>
																	<div className='mb-2 text-[13px] lg:text-md'>
																		Exp.{' '}
																		{
																			paymentMethod
																				.card
																				.exp_month
																		}
																		/
																		{
																			paymentMethod
																				.card
																				.exp_year
																		}
																	</div>
																</div>
																{user
																	.paymentMethods
																	.length >
																	1 && (
																	<div className='items-center'>
																		<button
																			onClick={() => {
																				deleteCard(
																					{
																						stripeCustomerId:
																							user.stripeId,
																						paymentMethodId:
																							paymentMethod.id,
																					}
																				)
																			}}
																			className='ml-10 mt-6 text-xs underline text-green-600 font-semibold'
																		>
																			Delete
																		</button>
																	</div>
																)}
															</div>
														)
													}
												)}
											</div>
										)}
								</div>
							)}
						<div>
							{!showCardForm && (
								<div>
									<button
										className='my-3 link-sm underline text-green-600 font-semibold'
										onClick={() => {
											setShowCardForm(true)
										}}
									>
										Add a card
									</button>
								</div>
							)}
							{showCardForm && (
								<div>
									<div className='mt-4'>
										<div className='text-sm'>
											Enter card info below
										</div>

										<form onSubmit={handleAddCardSubmit}>
											<CardElement
												options={cardElementOptions}
											/>
											{!loadingAddition &&
												!stripeLoading &&
												!loadingUser && (
													<Button
														className='mt-2 px-2'
														text='Add Card'
														size='sm'
														submit={true}
													/>
												)}
											{loadingAddition ||
												(stripeLoading && (
													<BounceLoaderCentered container='div' />
												))}
										</form>
									</div>
									<div>
										<button
											className='mt-6 text-[16px] underline text-green-600 font-semibold'
											onClick={() => {
												setShowCardForm(false)
											}}
										>
											Cancel
										</button>
									</div>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Payments }) => ({
	loadingDeletion: Payments.loadingDeletion,
	user: User.user,
	loadingUser: User.loading,
	cardDeletionError: Payments.deletionError,
	cardDeleted: Payments.deleted,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onDeletePaymentMethod: (paymentMethodId) =>
		dispatch(deletePaymentMethod(paymentMethodId)),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onUpdateMailchimpMergeFields: (user) =>
		dispatch(updateMailchimpMergeFields(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfoCard)
