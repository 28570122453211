import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getResuablesOrders } from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Elements'

const ReusablesShopDashboard = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		loadingOrders,
		error,
		orders,
		ordersCount,
		onGetOrders,
	} = state

	useEffect(() => {
		onGetUser()
		orderPaginate()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	const paginationLimit = 20
	const bounceLoaderColor = '#507f74'
	const [ordersPage, setOrdersPage] = useState(1)
	const [loading, setLoading] = useState(true)
	let navigate = useNavigate()

	const getOrders = (page) => {
		onGetOrders({
			filter: {},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const orderPaginate = () => {
		getOrders(ordersPage)
	}

	useEffect(() => {
		orderPaginate()
	}, [ordersPage])

	useEffect(() => {
		setLoading(false)
	}, [orders])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Reusables Shop Orders
				</h1>
				{ordersCount > 0 && (
					<div className='text-center'>
						<span className='font-bold'>
							Number of total orders:
						</span>{' '}
						{ordersCount}
					</div>
				)}
				<div className='flex flex-col max-w-[1200px] justify-center my-6 overflow-auto mx-auto scrollbar'>
					<div className='mx-auto'>
						{orders && orders.length > 0 && (
							<div className='px-10 my-6 pt-3 shadow-light-grey'>
								<div className='flex flex-row mb-2 mt-6'>
									<div className='font-bold w-[200px]'>
										Order Date
									</div>
									<div className='font-bold w-[250px]'>
										Customer
									</div>
									<div className='font-bold w-[250px]'>
										Items
									</div>
									<div className='font-bold w-[250px]'>
										Order Total
									</div>
								</div>
								{loading && (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								{orders.map((order) => {
									const orderDate = new Date(order.timestamp)
									const orderDateString =
										orderDate.toLocaleString('default', {
											month: 'short',
											day: 'numeric',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
										})

									return (
										<div className='flex flex-row my-2 divide-y-2 divide-solid'>
											<div></div>
											<div className='w-[200px]'>
												{orderDateString}
											</div>
											<div className='w-[250px] overflow-hidden flex flex-col'>
												<div className=''>
													{order.user.firstName}{' '}
													{order.user.lastName}
												</div>
												{order.companyName && (
													<div className=''>
														{order.companyName}
													</div>
												)}
												<div className=''>
													{order.user.email}
												</div>
												<div className=''>
													{order.user.phone}
												</div>
											</div>
											<div className='w-[250px] overflow-auto flex flex-col'>
												{Object.values(
													order.cartItems
												).map((item) => (
													<div
														key={item.id}
														className='flex items-center w-[200px] overflow-hidden mb-6'
													>
														<div className='w-24 h-24'>
															<img
																src={
																	item.imgSrc
																}
																alt={item.name}
																className='w-full h-full object-contain'
															/>
														</div>
														<div className='ml-2'>
															<p>{item.name}</p>
															<p>
																SKU: {item.id}
															</p>
															<p>
																Quantity:{' '}
																{item.quantity}
															</p>
														</div>
													</div>
												))}
											</div>
											<div className='w-[250px] overflow-hidden flex flex-col'>
												<div className='font-header'>
													$
													{order.payment.orderTotal.toFixed(
														2
													)}
												</div>
												<div className=''>
													Subtotal: $
													{order.payment.subtotal.toFixed(
														2
													)}
												</div>
												<div className='w'>
													Service Fee: $
													{order.payment.serviceFee.toFixed(
														2
													)}
												</div>
												<div className=''>
													Taxes: $
													{order.payment.taxes.toFixed(
														2
													)}
												</div>
												<div className=''>
													Shipping: $
													{order.payment.shippingFee.toFixed(
														2
													)}
												</div>
												<div className=''>
													zeroFoodPrint: $
													{order.payment.zeroFoodPrint.toFixed(
														2
													)}
												</div>
											</div>
										</div>
									)
								})}

								<div className='flex flex-row justify-between w-full'>
									<div
										className='underline font-bold mr-6 cursor-pointer'
										onClick={() => {
											setOrdersPage(ordersPage - 1)
											setLoading(true)
										}}
									>
										Previous {paginationLimit} orders
									</div>
									<div
										className='underline font-bold cursor-pointer'
										onClick={() => {
											setOrdersPage(ordersPage + 1)
											setLoading(true)
										}}
									>
										Next {paginationLimit} orders
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, ReusablesShop }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingOrders: ReusablesShop.loading,
	orders: ReusablesShop.reusablesOrders,
	ordersCount: ReusablesShop.ordersCount,
	error: ReusablesShop.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOrders: (payload) => dispatch(getResuablesOrders(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReusablesShopDashboard)
