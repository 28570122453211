import {
	GET_ALL_REUSABLE_PRODUCTS,
	GET_ALL_REUSABLE_PRODUCTS_SUCCESS,
	GET_ALL_REUSABLE_PRODUCTS_FAIL,
	ADD_TO_CART_REUSABLES_SHOP,
	ADD_TO_CART_REUSABLES_SHOP_SUCCESS,
	ADD_TO_CART_REUSABLES_SHOP_FAIL,
	UPDATE_CART_ITEM_REUSABLES_SHOP,
	UPDATE_CART_ITEM_REUSABLES_SHOP_SUCCESS,
	UPDATE_CART_ITEM_REUSABLES_SHOP_FAIL,
	UPDATE_CART_FROM_USER,
	UPDATE_CART_FROM_USER_SUCCESS,
	UPDATE_CART_FROM_USER_FAIL,
	ADD_CART_TO_USER_REUSABLES_SHOP,
	ADD_CART_TO_USER_REUSABLES_SHOP_SUCCESS,
	ADD_CART_TO_USER_REUSABLES_SHOP_FAIL,
	CLEAR_CART_REUSABLES_SHOP,
	CLEAR_CART_REUSABLES_SHOP_SUCCESS,
	CLEAR_CART_REUSABLES_SHOP_FAIL,
	SUBMIT_REUSABLES_ORDER,
	SUBMIT_REUSABLES_ORDER_FAIL,
	SUBMIT_REUSABLES_ORDER_SUCCESS,
	TOTAL_REUSABLES_CART,
	CALCULATE_SHIPPING,
	CALCULATE_SHIPPING_FAIL,
	CALCULATE_SHIPPING_SUCCESS,
	GET_REUSABLES_ORDERS,
	GET_REUSABLES_ORDERS_FAIL,
	GET_REUSABLES_ORDERS_SUCCESS,
	RESET_REUSABLES,
} from './actionTypes'

export const getAllReusableProducts = (payload) => {
	return {
		type: GET_ALL_REUSABLE_PRODUCTS,
		payload: payload,
	}
}

export const getAllReusableProductsSuccess = (allProducts) => {
	return {
		type: GET_ALL_REUSABLE_PRODUCTS_SUCCESS,
		payload: allProducts,
	}
}

export const getAllReusableProductsFail = (error) => {
	return {
		type: GET_ALL_REUSABLE_PRODUCTS_FAIL,
		payload: error,
	}
}

export const addProductReusablesShop = (nextCartItem) => {
	return {
		type: ADD_TO_CART_REUSABLES_SHOP,
		payload: nextCartItem,
	}
}

export const addProductReusablesShopSuccess = (updatedCart) => {
	return {
		type: ADD_TO_CART_REUSABLES_SHOP_SUCCESS,
		payload: updatedCart,
	}
}

export const addProductReusablesShopFail = (error) => {
	return {
		type: ADD_TO_CART_REUSABLES_SHOP_FAIL,
		payload: error,
	}
}

export const updateCartItemReusablesShop = (item) => {
	return {
		type: UPDATE_CART_ITEM_REUSABLES_SHOP,
		payload: item,
	}
}

export const updateCartItemReusablesShopSuccess = (updatedCart) => {
	return {
		type: UPDATE_CART_ITEM_REUSABLES_SHOP_SUCCESS,
		payload: updatedCart,
	}
}

export const updateCartItemReusablesShopFail = (error) => {
	return {
		type: UPDATE_CART_ITEM_REUSABLES_SHOP_FAIL,
		payload: error,
	}
}

export const updateCartFromUser = (cart) => {
	return {
		type: UPDATE_CART_FROM_USER,
		payload: cart,
	}
}

export const updateCartFromUserSuccess = (payload) => {
	return {
		type: UPDATE_CART_FROM_USER_SUCCESS,
		payload: payload,
	}
}

export const updateCartFromUserFail = (error) => {
	return {
		type: UPDATE_CART_FROM_USER_FAIL,
		payload: error,
	}
}

export const addCartToUserReusablesShop = (payload) => {
	return {
		type: ADD_CART_TO_USER_REUSABLES_SHOP,
		payload: payload,
	}
}

export const addCartToUserReusablesShopSuccess = (payload) => {
	return {
		type: ADD_CART_TO_USER_REUSABLES_SHOP_SUCCESS,
		payload: payload,
	}
}

export const addCartToUserReusablesShopFail = (error) => {
	return {
		type: ADD_CART_TO_USER_REUSABLES_SHOP_FAIL,
		payload: error,
	}
}

export const clearCartReusablesShop = () => {
	return {
		type: CLEAR_CART_REUSABLES_SHOP,
	}
}

export const clearCartReusablesShopSuccess = (clearedCart) => {
	return {
		type: CLEAR_CART_REUSABLES_SHOP_SUCCESS,
		payload: clearedCart,
	}
}

export const clearCartReusablesShopFail = (error) => {
	return {
		type: CLEAR_CART_REUSABLES_SHOP_FAIL,
		payload: error,
	}
}

export const submitReusablesOrder = (payload) => {
	return {
		type: SUBMIT_REUSABLES_ORDER,
		payload: payload,
	}
}

export const submitReusablesOrderSuccess = (payload) => {
	return {
		type: SUBMIT_REUSABLES_ORDER_SUCCESS,
		payload: payload,
	}
}

export const submitReusablesOrderFail = (payload) => {
	return {
		type: SUBMIT_REUSABLES_ORDER_FAIL,
		payload: payload,
	}
}

export const totalReusablesCart = (cart) => {
	return {
		type: TOTAL_REUSABLES_CART,
		payload: cart,
	}
}

export const calculateShipping = (payload) => {
	return {
		type: CALCULATE_SHIPPING,
		payload: payload,
	}
}

export const calculateShippingSuccess = (payload) => {
	return {
		type: CALCULATE_SHIPPING_SUCCESS,
		payload: payload,
	}
}

export const calculateShippingFail = (payload) => {
	return {
		type: CALCULATE_SHIPPING_FAIL,
		payload: payload,
	}
}

export const getResuablesOrders = (payload) => {
	return {
		type: GET_REUSABLES_ORDERS,
		payload: payload,
	}
}

export const getResuablesOrdersSuccess = (payload) => {
	return {
		type: GET_REUSABLES_ORDERS_SUCCESS,
		payload: payload,
	}
}

export const getResuablesOrdersFail = (payload) => {
	return {
		type: GET_REUSABLES_ORDERS_FAIL,
		payload: payload,
	}
}

export const resetReusables = () => {
	return {
		type: RESET_REUSABLES,
	}
}
