import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
	getNodeBySlug,
	getMenuBySlug,
	addProduct,
	getCart,
	totalCart,
	clearCart,
	addCartToUser,
	getAddressLocation,
	updateUser,
	updateDeliveryOption,
	getUser,
	removeCartItem,
	getDispatchAvailability,
} from '../../store/actions'
import { Link, useParams } from 'react-router-dom'
import { parseMenu } from '../../helpers/menuHelpers'
import { isAddressInsideDeliveryPolygon } from '../../helpers/googleMapsAPI'
import {
	Header,
	Footer,
	NodeMenuHeader,
	NodeMenuItem,
	CartItem,
	Cart,
	ItemPopUp,
} from '../../components'
import {
	SlidingTabs,
	Button,
	Input,
	useOutsideClick,
	DeliveryPickupToggle,
	BounceLoaderCentered,
} from '../../components/Elements'
import { BiArrowBack } from 'react-icons/bi'

function NodeMenu(props) {
	const {
		onGetNodeBySlug,
		onGetMenuBySlug,
		node,
		menu,
		cartNodeSlug,
		onAddCartToUser,
		lastActive,
		onAddProduct,
		onGetCart,
		onTotalCart,
		cartItems,
		cartTotal,
		onClearCart,
		clearingCart,
		clearedCart,
		user,
		onUpdateUser,
		updateUserLoading,
		onUpdateDeliveryOption,
		deliveryOption,
		onRemoveCartItem,
		onGetDispatchAvailability,
		deliverectDispatch,
	} = props

	// NODE SLUG
	const { nodeSlug } = useParams()

	// MENU
	const [menuValues, setMenuValues] = useState({})
	// const [hoursByMenuObject, setHoursByMenuObject] = useState({})
	const [currentCategories, setCurrentCategories] = useState([])
	const [openPopUp, setOpenPopUp] = useState(false)
	const [popUpProduct, setPopUpProduct] = useState({})
	const [cartDropUp, setCartDropUp] = useState(false)

	// ADDRESS
	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [addressLat, setAddressLat] = useState(null)
	const [addressLng, setAddressLng] = useState(null)
	const [addressChange, setAddressChange] = useState('')
	const [address2, setAddress2] = useState('')
	const [showAddress2Form, setShowAddress2Form] = useState(false)
	// const [addressError, setAddressError] = useState(false)
	const [showChangeAddressInput, setShowChangeAddressInput] = useState(false)
	const [pickupOnly, setPickupOnly] = useState(false)
	const [cartItemsNodeSlug, setCartItemsNodeSlug] = useState(null)
	const [deliveryToggle, setDeliveryToggle] = useState(null)
	// mainly for deliverect
	const [zipCode, setZipCode] = useState('')
	const [streetName, setStreetName] = useState('')

	const google = window.google

	// figure out if customer is inside delivery radius
	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
			onGetMenuBySlug(nodeSlug)
		}
	}, [nodeSlug])

	// set deliveryToggle to value from user doc only once user doc has been fetched
	useEffect(() => {
		if (deliveryOption) {
			setDeliveryToggle(deliveryOption)
		} else {
			if (user) {
				onUpdateDeliveryOption('delivery')
			}
		}
	}, [deliveryOption, user])

	useEffect(() => {
		if (google && google.maps) {
			const lastSearchedAddress = JSON.parse(
				localStorage.getItem('lastSearchedAddress')
			)
			if (
				user &&
				user.activeCart &&
				user.activeCart.lastSearchedAddress
			) {
				setSearchedAddress(user.activeCart.lastSearchedAddress)
			} else if (lastSearchedAddress && lastSearchedAddress.address) {
				setSearchedAddress(lastSearchedAddress.address)
			} else if (user && user.lastSearchedAddress) {
				setSearchedAddress(user.lastSearchedAddress)
			} else if (user && user.address) {
				setSearchedAddress(user.address)
			}
		}
	}, [user, user?.address, user?.lastSearchedAddress, google, google?.maps])

	const geocodeAddress = async () => {
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: searchedAddress,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)

					results[0].address_components.forEach((element) => {
						// console.log(element)
						if (element.types.includes('postal_code')) {
							setZipCode(element.short_name)
						} else if (element.types.includes('route')) {
							setStreetName(element.short_name)
						}
					})

					setAddressLat(results[0].geometry.location.lat())
					setAddressLng(results[0].geometry.location.lng())

					const lastSearchedAddress = {
						address: results[0].formatted_address,
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng(),
					}
					localStorage.setItem(
						'lastSearchedAddress',
						JSON.stringify(lastSearchedAddress)
					)
				} else {
					// setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const handleDeliveryToggle = (val) => {
		setDeliveryToggle(val)
	}

	useEffect(() => {
		if (searchedAddress != '') {
			geocodeAddress()
		}
	}, [searchedAddress])

	const handleAddressChange = (newAddress) => {
		if (newAddress.target) {
			setAddressChange(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setAddressChange(newAddress.formatted_address)
		}
	}

	const handleAddress2Change = (apt) => {
		if (apt.target) {
			setAddress2(apt.target.value)
		}
	}

	const saveAddress2 = () => {
		if (address2 != '' && address2 !== user.lastSearchedAddress2) {
			const updatedSavedAddresses = user.savedAddresses.map((item) => {
				if (item.savedAddress === geoCodedAddress) {
					return {
						...item,
						savedAddress2: address2,
					}
				}
				return item
			})
			onUpdateUser({
				savedAddresses: updatedSavedAddresses,
				address2: address2,
			})
		}
		setShowAddress2Form(false)
	}

	const handleAddressSubmit = async (e) => {
		e.preventDefault()
		setShowChangeAddressInput(false)
		let geocoder = new google.maps.Geocoder()

		// Geocode the address
		await geocoder.geocode(
			{
				address: addressChange,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)

					results[0].address_components.forEach((element) => {
						// console.log(element)
						if (element.types.includes('postal_code')) {
							setZipCode(element.short_name)
						} else if (element.types.includes('route')) {
							setStreetName(element.short_name)
						}
					})

					if (
						user &&
						!user?.savedAddresses.find(
							(address) =>
								address.savedAddress ===
								results[0].formatted_address
						)
					) {
						// new address so add to front of array
						let activeCart = user.activeCart ? user.activeCart : {}
						activeCart.lastSearchedAddress =
							results[0].formatted_address
						onUpdateUser({
							savedAddresses: [
								{
									savedAddress: results[0].formatted_address,
									savedAddress2: '',
								},
								...user.savedAddresses,
							],
							lastSearchedAddress: results[0].formatted_address,
							lastSearchedAddress2: address2,
							activeCart: activeCart,
						})
					} else {
						// exisiting address so need to shift it to front to update ordering
						const existingAddressIdx =
							user.savedAddresses.findIndex(
								(address) =>
									address.savedAddress ===
									results[0].formatted_address
							)
						const savedAddress2 =
							user.savedAddresses[existingAddressIdx]
								.savedAddress2
						const updatedSavedAddresses = [...user.savedAddresses]
						updatedSavedAddresses.splice(existingAddressIdx, 1)
						updatedSavedAddresses.unshift({
							savedAddress: results[0].formatted_address,
							savedAddress2: savedAddress2,
						})
						let activeCart = user.activeCart ? user.activeCart : {}
						activeCart.lastSearchedAddress =
							results[0].formatted_address
						onUpdateUser({
							savedAddresses: updatedSavedAddresses,
							lastSearchedAddress: results[0].formatted_address,
							lastSearchedAddress2: savedAddress2,
							activeCart: activeCart,
						})
					}
					setAddressLat(results[0].geometry.location.lat())
					setAddressLng(results[0].geometry.location.lng())

					const lastSearchedAddress = {
						address: results[0].formatted_address,
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng(),
					}

					localStorage.setItem(
						'lastSearchedAddress',
						JSON.stringify(lastSearchedAddress)
					)
				} else {
					// setAddressError(true)
					// show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	useEffect(() => {
		if (node && node.deliveryPolygon && addressLng) {
			if (
				isAddressInsideDeliveryPolygon(
					[addressLat, addressLng],
					node.deliveryPolygon
				)
			) {
				if (
					node.pickupOnly ||
					(deliverectDispatch &&
						deliverectDispatch == 'NO AVAILABILITY')
				) {
					setPickupOnly(true)
					if (cartItems) {
						if (cartItems[0]) {
							if (cartItems[0].nodeSlug !== node.slug) {
							} else {
								if (deliveryToggle) {
									onUpdateDeliveryOption('pickup')
								}
							}
						} else {
							if (deliveryToggle) {
								onUpdateDeliveryOption('pickup')
							}
						}
					} else {
						if (deliveryToggle) {
							onUpdateDeliveryOption('pickup')
						}
					}
				} else {
					setPickupOnly(false)
					if (cartItems) {
						if (cartItems[0]) {
							if (cartItems[0].nodeSlug !== node.slug) {
							} else {
								if (deliveryToggle) {
									onUpdateDeliveryOption(deliveryToggle)
								}
							}
						} else {
							if (deliveryToggle) {
								onUpdateDeliveryOption(deliveryToggle)
							}
						}
					} else {
						if (deliveryToggle) {
							onUpdateDeliveryOption(deliveryToggle)
						}
					}
				}
			} else {
				setPickupOnly(true)
				if (cartItems) {
					if (cartItems[0]) {
						if (cartItems[0].nodeSlug !== node.slug) {
						} else {
							if (deliveryToggle) {
								onUpdateDeliveryOption('pickup')
							}
						}
					} else {
						if (deliveryToggle) {
							onUpdateDeliveryOption('pickup')
						}
					}
				} else {
					if (deliveryToggle) {
						onUpdateDeliveryOption('pickup')
					}
				}
			}
		}
	}, [addressLng, node, deliveryToggle, cartItems, deliverectDispatch])

	useEffect(() => {
		if (node && node.deliveryPolygon && addressLng) {
			if (
				isAddressInsideDeliveryPolygon(
					[addressLat, addressLng],
					node.deliveryPolygon
				)
			) {
				if (node.pickupOnly) {
					setPickupOnly(true)
				} else {
					setPickupOnly(false)
				}
			} else {
				setPickupOnly(true)
				setDeliveryToggle('pickup')
			}
		}
	}, [addressLng, node])

	useEffect(() => {
		// console.log('cartItems changed',cartItems)
		if (
			geoCodedAddress &&
			cartItems &&
			cartItems.length &&
			deliveryOption
		) {
			const lastCartItem = cartItems[cartItems.length - 1]
			const activeCart = {
				cartItems: cartItems,
				nodeSlug: lastCartItem.nodeSlug,
				lastActive: new Date(),
				deliveryOption: deliveryToggle,
				lastSearchedAddress: geoCodedAddress,
			}

			if (user) {
				onAddCartToUser({
					activeCart: activeCart,
					user: user,
				})
			}
			if (cartItems[0]?.nodeSlug) {
				setCartItemsNodeSlug(cartItems[0].nodeSlug)
			}
		}
	}, [cartItems, geoCodedAddress])

	useEffect(() => {
		if (cartItems && node && cartItems[0]?.nodeSlug === node.slug) {
			if (
				geoCodedAddress &&
				cartItems &&
				cartItems.length &&
				deliveryOption
			) {
				const lastCartItem = cartItems[cartItems.length - 1]
				const activeCart = {
					cartItems: cartItems,
					nodeSlug: lastCartItem.nodeSlug,
					lastActive: new Date(),
					deliveryOption: deliveryOption,
					lastSearchedAddress: geoCodedAddress,
				}

				if (user) {
					onAddCartToUser({
						activeCart: activeCart,
						user: user,
					})
				}
				if (cartItems[0]?.nodeSlug) {
					setCartItemsNodeSlug(cartItems[0].nodeSlug)
				}
			}
		}
	}, [deliveryOption])

	useEffect(() => {
		if (geoCodedAddress && deliveryOption === 'delivery') {
			if (menu && menu.deliverectStoreId && user) {
				// deliverect resto so check for dispatch availability
				const dispatchPayload = {
					channelLinkId: menu.deliverectStoreId,
					customerName: user.firstName + ' ' + user.lastName,
					address: geoCodedAddress,
					street: streetName,
					postalCode: zipCode,
					phone: user.phone,
					addressLng: addressLng,
					addressLat: addressLat,
				}

				onGetDispatchAvailability(dispatchPayload)
			}
		}
	}, [geoCodedAddress, deliveryOption])

	useEffect(() => {
		if (menu) {
			setMenuValues(parseMenu(menu))
			// setHoursByMenuObject(createHoursDescription(menu))
		}
	}, [menu])

	useEffect(() => {
		if (node && node.pickupOnly) {
			setPickupOnly(true)
		}
	}, [node])

	useEffect(() => {
		let tabCategories = []
		if (menuValues.menuItems) {
			menuValues.menuItems.map((category) => {
				if (
					category.items &&
					category.items.length > 0 &&
					!(
						category.categoryName
							.toLowerCase()
							.includes('packaging') ||
						category.categoryName
							.toLowerCase()
							.includes('deliverzero')
					)
				) {
					tabCategories.push({
						label: category.categoryName,
						value: category.categoryName,
					})
				}
			})
			setCurrentCategories(tabCategories)
		}
	}, [menuValues.menuItems, menu])

	const handleAddProduct = (product) => {
		if (nodeSlug !== cartItemsNodeSlug) {
			if (cartItems) {
				for (const cartItem of cartItems) {
					if (cartItem.nodeSlug !== nodeSlug) {
						onRemoveCartItem(cartItem)
					}
				}
			}
		}
		onAddProduct({
			product: product,
			id: product.id,
			quantity: 1,
			modifiers: product.modifiers,
			note: product.notes,
			lastActive: Date.now(),
			nodeSlug: nodeSlug,
		})
	}

	const handleOpenPopUp = (product) => {
		setOpenPopUp(!openPopUp)
		setPopUpProduct(product)
		// console.log(product)
	}

	const handleCloseViewCart = () => {
		setCartDropUp(!cartDropUp)
	}

	useEffect(() => {
		if (cartItems) {
			onTotalCart(cartItems)
			if (cartItems[0]) {
				setCartItemsNodeSlug(cartItems[0].nodeSlug)
			}
		}
	}, [cartItems, JSON.stringify(cartItems)])

	useEffect(() => {
		if (clearedCart) {
			// console.log('getting new user after clear')
			onGetCart()
		}
	}, [clearedCart])

	const scrollToSelection = (selection) => {
		var element = document.getElementById(selection)
		var headerOffset = 75
		var elementPosition = element.getBoundingClientRect().top
		var offsetPosition = elementPosition + window.pageYOffset - headerOffset
		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth',
		})
	}

	const impactRef = useRef(null)

	useOutsideClick(impactRef, () => {
		setCartDropUp(false)
	})

	if (!menu)
		return (
			<div className='scrollbar'>
				<div className='flex flex-row'>
					<div className={`w-5/6 lg:w-3/4 lg:px-10`}>
						<Link
							className='w-1/3 hidden md:flex text-sm text-green-600 items-center p-4'
							to='/marketplace/locations'
						>
							<BiArrowBack className='inline mr-2' /> Back to All
							Restaurants
						</Link>
						<Link to='/marketplace/locations'>
							<span className='md:hidden rounded-full h-12 w-12 bg-white text-3xl absolute text center flex justify-center items-center m-4'>
								<BiArrowBack className='text-green-400' />
							</span>
						</Link>
						<div className='mt-3 text-center'>
							<BounceLoaderCentered container='screen' />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)

	return (
		<div className='scrollbar'>
			<Header />
			<div className='flex flex-row justify-center pb-5'>
				<div
					className={`w-full px-2 lg:w-3/4 lg:px-10 sm:w-5/6 sm:px-0`}
				>
					<Link
						className='w-1/3 hidden md:flex text-sm text-green-600 items-center px-4 pt-4 whitespace-nowrap'
						to='/marketplace/locations'
					>
						<BiArrowBack className='inline mr-2' /> Back to All
						Restaurants
					</Link>
					<Link to='/marketplace/locations'>
						<span className='md:hidden rounded-full h-12 w-12 bg-white text-3xl absolute text center flex justify-center items-center m-4'>
							<BiArrowBack className='text-green-400' />
						</span>
					</Link>
					{node && <NodeMenuHeader node={node} />}

					{addressLng ? (
						<div>
							{pickupOnly ? (
								<div>
									<DeliveryPickupToggle
										pickupOnly={pickupOnly}
										deliveryOption={deliveryOption}
										onSendToParent={handleDeliveryToggle}
									/>
									<div className='w-full justify-self-end text-red-400 text-xs mx-3 mt-1'>
										Only pickup available for this address
									</div>
								</div>
							) : (
								<DeliveryPickupToggle
									deliveryOption={deliveryOption}
									onSendToParent={handleDeliveryToggle}
								/>
							)}
							<div className='text-xs md:text-sm mx-3 mt-3'>{`${searchedAddress}`}</div>
							{user.address2 && (
								<div className='mx-3 text-xs md:text-sm'>
									Unit: {`${user.address2}`}
								</div>
							)}
							{!showChangeAddressInput && (
								<div
									className='text-green-600 underline my-1 mx-3 cursor-pointer text-xs md:text-sm'
									onClick={() => {
										setShowAddress2Form(!showAddress2Form)
									}}
								>
									Add Apt/Unit/Suite
								</div>
							)}

							{showAddress2Form && (
								<div className='mx-4'>
									<Input
										type=''
										name='unit'
										placeholder='APT, SUITE, ETC (OPTIONAL)'
										onChange={handleAddress2Change}
										className='bg-white mt-2 w-[300px] !text-xs'
										onKeyDown={(e) => {
											e.key === 'Enter' &&
												e.preventDefault()
										}}
									/>
									{!updateUserLoading && (
										<Button
											className='mt-2 w-[150px] py-1'
											text='Save'
											full
											size='sm'
											onClick={saveAddress2}
										/>
									)}
									{updateUserLoading && (
										<div className='mt-3 text-center'>
											<BounceLoaderCentered container='div' />
										</div>
									)}
								</div>
							)}

							{/* <div className='text-xs md:text-sm mx-3'>{`${user.address2}`}</div> */}
							{!showAddress2Form && (
								<div
									className='text-green-600 underline mx-3 cursor-pointer text-xs md:text-sm'
									onClick={() => {
										setShowChangeAddressInput(
											!showChangeAddressInput
										)
									}}
								>
									Change Address
								</div>
							)}
							{showChangeAddressInput && (
								<div className='mx-3'>
									<form onSubmit={handleAddressSubmit}>
										<Input
											placeholder='Enter your address'
											className={`bg-white mt-2 max-w-[400px] !text-xs`}
											name={`address`}
											error={false}
											autoCompleteAddress={true}
											onChange={handleAddressChange}
											onKeyDown={(e) => {
												handleAddressChange(e)
												e.key === 'Enter' &&
													e.preventDefault()
											}}
										/>
										{user && (
											<Input
												type=''
												name='unit'
												placeholder='APT, SUITE, ETC (OPTIONAL)'
												onChange={handleAddress2Change}
												className={`bg-white mt-2 max-w-[400px] !text-xs`}
												onKeyDown={(e) => {
													e.key === 'Enter' &&
														e.preventDefault()
												}}
											/>
										)}
										{!updateUserLoading && (
											<Button
												className='mt-2 w-[150px]'
												text='Save'
												full
												size='xs'
												submit={true}
											/>
										)}
										{updateUserLoading && (
											<div className='mt-3 text-center'>
												<BounceLoaderCentered container='div' />
											</div>
										)}
									</form>
								</div>
							)}
							{node && menuValues.menuIds && (
								<div className='sticky top-[145px] sm:top-0 bg-white pt-2 z-20'>
									<SlidingTabs
										tabs={currentCategories}
										scrollToSelection={scrollToSelection}
									/>
								</div>
							)}
							{(Object.keys(menuValues).length === 0 ||
								menu.tempClosed) && (
								<div className='flex justify-center py-4'>
									Restaurant has no active menus at this time.
								</div>
							)}

							{/*{Object.keys(hoursByMenuObject).length>0 && (*/}
							{/*    <div className="m-2 mx-8 lg:mx-12">*/}
							{/*        Hours by Today's Menus:*/}
							{/*        {Object.keys(hoursByMenuObject).map((menuId,index) => {*/}
							{/*                return(*/}
							{/*                    <div className="m-2 mx-8 lg:mx-12 text-sm"><span className="font-bold">{menu.menus[menuId].name}</span>: {hoursByMenuObject[menuId]}</div>*/}
							{/*                )*/}
							{/*            }*/}
							{/*        )}*/}
							{/*    </div>*/}
							{/*)}*/}

							{node &&
								menuValues.menuIds &&
								menuValues.menuItems.map((category, index) => {
									if (
										category.categoryName
											.toLowerCase()
											.includes('packaging') ||
										category.categoryName
											.toLowerCase()
											.includes('deliverzero')
									) {
									} else {
										return (
											<NodeMenuItem
												category={category}
												handleAddProduct={
													handleAddProduct
												}
												handleOpenPopUp={
													handleOpenPopUp
												}
												key={`${index * 50}`}
											/>
										)
									}
								})}
						</div>
					) : (
						<div className='m-5'>
							<div>
								Enter your address below to see if this
								restaurant delivers. Pickup may be available.
							</div>
							<form onSubmit={handleAddressSubmit}>
								<Input
									placeholder='Enter your address'
									className={`relative bg-white pl-5 h-11`}
									name={`address`}
									error={false}
									autoCompleteAddress={true}
									onChange={handleAddressChange}
									onKeyDown={(e) => {
										handleAddressChange(e)
										e.key === 'Enter' && e.preventDefault()
									}}
								/>
								{user && (
									<Input
										type=''
										name='unit'
										placeholder='APT, SUITE, ETC (OPTIONAL)'
										onChange={handleAddress2Change}
										className='relative bg-white pl-5 h-11 w-[180px]'
										onKeyDown={(e) => {
											e.key === 'Enter' &&
												e.preventDefault()
										}}
										defaultValue={
											user.address2 ? user.address2 : ''
										}
									/>
								)}
								{!updateUserLoading && (
									<Button
										className='mt-2'
										text='Save Address'
										full
										size='sm'
										submit={true}
									/>
								)}
								{updateUserLoading && (
									<div className='mt-3 text-center'>
										<BounceLoaderCentered container='div' />
									</div>
								)}
							</form>
						</div>
					)}
					<div className='px-3 py-2 flex my-2 place-content-end sticky bottom-14 md:bottom-10 items-center justify-center md:bg-gray-100 md:shadow-xl md:rounded-full lg:hidden z-50'>
						<Link
							to={'/checkout'}
							className={
								'items-center border border-2 rounded-full min-w-fit text-center justify-center bg-green-600 border-green-600 text-white px-10 py-1 mr-2 lg:hidden'
							}
						>
							<div className='flex flex-col'>
								<div className='text-[8px] text-right mb-[-5px]'>
									Subtotal
								</div>
								<div className='flex flex-row'>
									<div>Checkout{` `}</div>
									<div className='ml-2'>
										{` ${new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency: 'USD',
										}).format(cartTotal)}`}{' '}
									</div>
								</div>
							</div>
						</Link>
						<Button
							onClick={() => setCartDropUp(true)}
							color='green-light'
							size='sm'
							className='px-4 h-[44px] lg:hidden'
							text='View Cart'
						/>
					</div>
				</div>
				<Cart
					cartDropUp={cartDropUp}
					handleCloseViewCart={handleCloseViewCart}
					cartItems={cartItems}
					deliveryOption={deliveryOption}
				/>
			</div>
			{openPopUp && (
				<ItemPopUp
					handleAddProduct={handleAddProduct}
					handleOpenPopUp={handleOpenPopUp}
					popUpProduct={popUpProduct}
					openPopUp={openPopUp}
				/>
			)}{' '}
			<Footer />
		</div>
	)
}

const mapStateToProps = ({ Nodes, Menu, Cart, User, Location }) => ({
	node: Nodes.node,
	loadingNode: Nodes.loadingNode,
	menu: Menu.menu,
	cartItems: Cart.cartItems,
	cartTotal: Cart.cartTotal,
	clearedCart: Cart.clearedCart,
	deliveryOption: Cart.deliveryOption,
	lastActive: Cart.lastActive,
	cartNodeSlug: Cart.nodeSlug,
	user: User.user,
	updateUserLoading: User.updateUserLoading,
	userLocation: Location.userLocation,
	deliverectDispatch: Menu.dispatch,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onGetMenuBySlug: (slug) => dispatch(getMenuBySlug(slug)),
	onAddProduct: (productId) => dispatch(addProduct(productId)),
	onGetCart: (cart) => dispatch(getCart(cart)),
	onTotalCart: (cart) => dispatch(totalCart(cart)),
	onAddCartToUser: (cart) => dispatch(addCartToUser(cart)),
	onClearCart: () => dispatch(clearCart()),
	onRemoveCartItem: (item) => dispatch(removeCartItem(item)),
	onGetUserAddressLocation: () => dispatch(getAddressLocation()),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onUpdateDeliveryOption: (deliveryOption) =>
		dispatch(updateDeliveryOption(deliveryOption)),
	onGetDispatchAvailability: (payload) =>
		dispatch(getDispatchAvailability(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeMenu)
