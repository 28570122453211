import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getNodes,
	updateUser,
	getNodeBySlug,
	getAdminClients,
	getAdminAggregators,
	getAdminReturns,
	getEnvImpact,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { SelectGroup } from '../../../components/Elements'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import EnvironmentalImpactCard from '../../../components/UserProfile/EnvironmentalImpactCard'

const AdminAnalyticsEnvironmental = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetNodes,
		nodes,
		onGetClients,
		loadingClients,
		clients,
		onGetAggregators,
		loadingAggregators,
		aggregators,
		onGetReturns,
		returns,
		onGetAppInfo,
		appInfo,
		onGetEnvImpact,
		loadingEnvImpact,
		totalBoxesOut,
		totalBoxesIn,
		effectiveReturnRate,
	} = state

	useEffect(() => {
		onGetUser()
		onGetNodes([{}])
		onGetClients()
		onGetAggregators()
		onGetReturns()
		onGetAppInfo()
	}, [onGetUser])

	const bounceLoaderColor = '#507f74'
	const [platform, setPlatform] = useState('')
	const [clientSelections, setClientSelections] = useState([])
	const [aggPlatform, setAggPlatform] = useState('')
	const [aggregatorSelections, setAggregatorSelections] = useState([])
	const [nodeSelections, setNodeSelections] = useState([])
	const [selectedNode, setSelectedNode] = useState('')
	const [startDate, setStartDate] = useState(new Date(2022, 9, 1))
	const [endDate, setEndDate] = useState(new Date())
	const [filter, setFilter] = useState({})

	let navigate = useNavigate()

	useEffect(() => {
		if (user) {
			const localFilter = filter
			if (localFilter.client === '') {
				delete localFilter.client
			}
			if (localFilter.aggregator === '') {
				delete localFilter.aggregator
			}
			if (localFilter.node === '') {
				delete localFilter.node
			}
			if (user.franchiseeSlug) {
				onGetEnvImpact({
					filter: {
						franchiseeSlug: user.franchiseeSlug,
						...localFilter,
					},
					startDate: startDate,
					endDate: endDate,
				})
			} else {
				onGetEnvImpact({
					filter: {
						...localFilter,
					},
					startDate: startDate,
					endDate: endDate,
				})
			}
		} else {
			onGetUser()
		}
	}, [onGetEnvImpact, filter, startDate, endDate, user])

	// console.log(filter)

	useEffect(() => {
		// if (platform !== '') {
		setFilter({
			...filter,
			client: platform,
			aggregator: aggPlatform,
			node: selectedNode,
		})
	}, [platform, aggPlatform, selectedNode])

	useEffect(() => {
		const cliSelections = [
			{
				label: 'All',
				value: '',
				selected: true,
			},
		]
		clients.map((client) => {
			cliSelections.push({
				label: client.clientId,
				value: client.clientId,
			})
		})
		setClientSelections(cliSelections)
	}, [clients])

	useEffect(() => {
		const nodesArray = [
			{
				label: 'All',
				value: '',
				selected: true,
			},
		]
		nodes.map((node) => {
			if (user && user.franchiseeSlug) {
				if (node.franchiseeSlug) {
					nodesArray.push({
						label: node.name,
						value: node.slug,
					})
				}
			} else {
				nodesArray.push({
					label: node.name,
					value: node.slug,
				})
			}
		})
		setNodeSelections(nodesArray)
	}, [nodes])

	useEffect(() => {
		const aggSelections = [
			{
				label: 'All',
				value: '',
				selected: true,
			},
		]
		aggregators.map((aggregator) => {
			aggSelections.push({
				label: aggregator.clientId,
				value: aggregator.clientId,
			})
		})
		setAggregatorSelections(aggSelections)
	}, [aggregators])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div className='h-screen'>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='flex flex-col max-w-[1200px] justify-center my-10 overflow-x-auto mx-auto'>
					<div className='mx-auto my-auto'>
						<div className='font-header text-2xl text-green-600'>
							Environmental Analytics
						</div>
						{loadingEnvImpact && (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
						{!user.franchiseeSlug && (
							<SelectGroup
								label='Filter by Client'
								placeholder='Select...'
								name='client'
								options={
									aggPlatform === ''
										? clientSelections
										: [
												{
													label: 'deliverzero',
													value: 'deliverzero',
												},
										  ]
								}
								onChange={(e) => {
									setPlatform(e.target.value)
								}}
							/>
						)}
						{!user.franchiseeSlug && (
							<SelectGroup
								label='Filter by Aggregator'
								placeholder='Select...'
								name='aggregator'
								options={aggregatorSelections}
								onChange={(e) => {
									setAggPlatform(e.target.value)
								}}
							/>
						)}
						<SelectGroup
							label='Filter by Restaurant'
							placeholder='Select...'
							name='node'
							options={nodeSelections}
							onChange={(e) => {
								setSelectedNode(e.target.value)
							}}
						/>
						<div className='mt-4'>Select Start Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
							/>
						</div>
						<div className='mt-4'>Select End Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={endDate}
								onChange={(date) => setEndDate(date)}
							/>
						</div>

						{totalBoxesOut && (
							<div className='text-2xl mb-10'>
								<div className='text-green-600 font-bold font-header'>
									Total boxes given out:
								</div>
								<div>{totalBoxesOut} boxes</div>
							</div>
						)}

						{!loadingEnvImpact && (
							<EnvironmentalImpactCard
								packagingPrevented={totalBoxesOut}
								environmentalImpact={
									appInfo.environmentalImpact
								}
							/>
						)}

						{effectiveReturnRate > 0.0 &&
							aggPlatform === '' &&
							platform === '' &&
							selectedNode === '' && (
								<div className='text-2xl mb-10'>
									<div className='text-green-600 font-bold font-header'>
										Effective return rate:
									</div>
									<div>
										{effectiveReturnRate.toFixed(3) * 100}%
									</div>
								</div>
							)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Transactions, Nodes, User, Admin, AppInfo }) => ({
	nodes: Nodes.nodes,
	node: Nodes.node,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingClients: Admin.loadingClients,
	clients: Admin.clients,
	loadingAggregators: Admin.loadingAggregators,
	aggregators: Admin.aggregators,
	returns: Admin.returns,
	appInfo: AppInfo.appInfo,
	loadingEnvImpact: Admin.loadingEnvImpact,
	totalBoxesOut: Admin.totalBoxesOut,
	totalBoxesIn: Admin.totalBoxesIn,
	effectiveReturnRate: Admin.effectiveReturnRate,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onGetClients: (payload) => dispatch(getAdminClients(payload)),
	onGetAggregators: (payload) => dispatch(getAdminAggregators(payload)),
	onGetReturns: (payload) => dispatch(getAdminReturns(payload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetEnvImpact: (payload) => dispatch(getEnvImpact(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdminAnalyticsEnvironmental)
