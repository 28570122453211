import { connect } from 'react-redux'
import React, { useDebugValue, useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	getDropoffById,
	cancelUberDirect,
	updateUberDirect,
	getUberDirectRequestById,
	getUberDirectRequestDocById,
	resetSuccess,
	cancelFlotDropoff,
	getProofOfDelivery,
} from '../../../../store/actions'
import { Footer, Header } from '../../../../components'
import {
	BounceLoaderCentered,
	Button,
	TextArea,
	Input,
} from '../../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'

const ViewOneDropoff = (state) => {
	const {
		user,
		onGetUser,
		dropoff,
		getDropoffError,
		gettingDropoff,
		onGetDropoff,
		loading,
		error,
		uberQuote,
		uberRequest,
		onGetUberDirectRequestById,
		onCancelUberDirect,
		onUpdateUberDirect,
		uberRequestDoc,
		onGetUberDirectRequestDocById,
		updatedRequest,
		cancelledRequest,
		cancellingDropoff,
		dropoffError,
		dropoffCancelled,
		onCancelFlotDropoff,
		proofOfDelivery,
		onGetProofOfDelivery,
		onResetSuccess,
	} = state

	const { orderId } = useParams()
	// const [tipAmount, setTipAmount] = useState(null)
	const [editPickupNote, setEditPickupNote] = useState(false)
	const [pickupNote, setPickupNote] = useState(null)
	const [editDropoffNote, setEditDropoffNote] = useState(false)
	const [dropoffNote, setDropoffNote] = useState(null)

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	useEffect(() => {
		onResetSuccess()
	}, [])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetDropoff(orderId)
	}, [orderId, dropoffCancelled])

	useEffect(() => {
		if (dropoff && dropoff.uberdirectId) {
			onGetUberDirectRequestDocById(dropoff.uberdirectId)
		}
	}, [dropoff])

	useEffect(() => {
		if (uberRequestDoc && uberRequestDoc.id) {
			onGetUberDirectRequestById(uberRequestDoc.id)
		}
		if (
			uberRequestDoc &&
			uberRequestDoc.pickup &&
			uberRequestDoc.pickup.notes
		) {
			setPickupNote(uberRequestDoc.pickup.notes)
		}
		if (
			uberRequestDoc &&
			uberRequestDoc.dropoff &&
			uberRequestDoc.dropoff.notes
		) {
			setDropoffNote(uberRequestDoc.dropoff.notes)
		}
	}, [uberRequestDoc, updatedRequest, cancelledRequest])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='w-full h-full'>
					<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
						Dropoff Details: {orderId}
					</h1>
					{getDropoffError && (
						<div className='font-bold text-red-400 my-1'>
							{JSON.stringify(getDropoffError)}
						</div>
					)}
					{dropoff ? (
						<div className='max-w-[90%] shadow-light-grey flex flex-col justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'>
							<div className='flex flex-col justify-start items-start p-10'>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Order ID:
									</span>
									<span className='font-normal mb-3'>
										{orderId}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Dropoff Date:
									</span>
									<span className='font-normal mb-3'>
										{dropoff.dropoffTimeEstimated}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Dropoff Location:
									</span>
									<span className='font-normal mb-3'>
										{dropoff.dropoffNodeName}
									</span>
								</div>
								{uberRequestDoc ? (
									<div></div>
								) : dropoff.flotResponse ? (
									<div className='flex flex-col sm:flex-row'>
										<span className='font-bold font-header mr-3'>
											Status:
										</span>
										{[
											'returned',
											'attempted',
											'cancelled',
										].includes(dropoff.status) ? (
											<span className='text-red-400 font-normal mb-3'>
												{(
													dropoff.status || ''
												).toUpperCase()}
											</span>
										) : (
											<span className='text-green-600 font-normal mb-3'>
												{(
													dropoff.status || ''
												).toUpperCase()}
											</span>
										)}
									</div>
								) : (
									<div className='flex flex-col sm:flex-row'>
										<span className='font-bold font-header mr-3'>
											Status:
										</span>
										{dropoff.completed ? (
											<span className='text-green-600 font-normal mb-3'>
												COMPLETED
											</span>
										) : (
											<span className='text-red-400 font-normal mb-3'>
												PENDING
											</span>
										)}
									</div>
								)}
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Last Updated:
									</span>
									{typeof dropoff.updateTimestamp ===
									'string' ? (
										<span className='font-normal mb-3'>
											{dropoff.updateTimestamp}
										</span>
									) : (
										<span className='font-normal mb-3'>
											{dropoff.updateTimestamp
												? JSON.stringify(
														dropoff.updateTimestamp
												  ).slice(1, 11)
												: ''}
										</span>
									)}
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Reverse Logistics Partner:
									</span>
									<span className='font-normal mb-3'>
										{dropoff.uberdirectId ? (
											<span>Uber Direct</span>
										) : dropoff.flotResponse ? (
											<span>Flot</span>
										) : (
											<span>Gently</span>
										)}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row'>
									<span className='font-bold font-header mr-3'>
										Containers:
									</span>
									<span className='font-normal mb-3 mr-3'>
										<table className='w-full border border-collapse'>
											<thead>
												<tr className='bg-gray-100'>
													{dropoff &&
													dropoff.items &&
													dropoff.items[0] &&
													dropoff.items[0].sku ? (
														<th className='border p-2'>
															SKU
														</th>
													) : (
														<>
															<th className='border p-2'>
																ID
															</th>
															<th className='border p-2'>
																Name
															</th>
														</>
													)}
													<th className='border p-2'>
														Quantity
													</th>
												</tr>
											</thead>
											<tbody>
												{dropoff.items ? (
													dropoff.items.map(
														(item, index) =>
															item.sku ? (
																<tr
																	key={index}
																	className={
																		index %
																			2 ===
																		0
																			? 'bg-gray-50'
																			: ''
																	}
																>
																	<td className='border p-2'>
																		{
																			item.sku
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.quantity
																		}
																	</td>
																</tr>
															) : (
																<tr
																	key={index}
																	className={
																		index %
																			2 ===
																		0
																			? 'bg-gray-50'
																			: ''
																	}
																>
																	<td className='border p-2'>
																		{
																			item.id
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.name
																		}
																	</td>
																	<td className='border p-2'>
																		{
																			item.quantity
																		}
																	</td>
																</tr>
															)
													)
												) : (
													<BounceLoaderCentered container='div' />
												)}
											</tbody>
										</table>
									</span>
								</div>
								{dropoff.flotResponse && (
									<>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Shipping label url:
											</span>
											<span className='font-normal mb-3'>
												<a
													href={`https://account.flot.delivery/</span>/print_label/${dropoff.tracking_number}?format=thermal`}
													target='_blank'
													className='underline text-green-600'
												>
													link
												</a>
											</span>
										</div>
										{dropoff.status === 'new' ? (
											<>
												{dropoffCancelled && (
													<div className='flex flex-col justify-center items-center text-green-600'>
														<div>
															Request successfully
															cancelled.
														</div>
													</div>
												)}
												<div className='flex flex-row justify-between items-center'>
													<Button
														text='Cancel'
														size='sm'
														color='red-outlined'
														className='mr-4 block font-semibold relative'
														onClick={() => {
															onCancelFlotDropoff(
																dropoff.orderId
															)
														}}
													/>
												</div>
											</>
										) : dropoff.status !== 'cancelled' ? (
											<div className='flex flex-col justify-center items-center text-red-400'>
												<div>
													Request is outside of
													cancellation window.
												</div>
											</div>
										) : (
											<></>
										)}
									</>
								)}
							</div>
							{uberRequestDoc && (
								<div className='max-w-[90%] border-2 border-green-600 flex justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'>
									<div className='flex flex-col justify-start items-start p-10'>
										<div className='font-header mb-6 text-green-600'>
											Uber Direct Info
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Uber Request ID:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.id}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Status:
											</span>
											{cancelledRequest ? (
												<span className='text-red-400 font-normal mb-3'>
													CANCELLED
												</span>
											) : uberRequestDoc.status !==
											  'completed' ? (
												<span className='text-red-400 font-normal mb-3'>
													{uberRequestDoc.status}
												</span>
											) : (
												<span className='text-green-600 font-normal mb-3'>
													COMPLETED
												</span>
											)}
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Created:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.created}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Location:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.pickup.name}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Ready Time:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.pickup_ready}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Deadline:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.pickup_deadline}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Pickup Note:
											</span>
											{editPickupNote ? (
												<div>
													<TextArea
														type='text'
														value={pickupNote}
														onChange={(e) => {
															setPickupNote(e)
														}}
													/>
													<span className='text-sm'>
														Character count:{' '}
														{pickupNote.length >
														280 ? (
															<span className='text-red-600'>
																{
																	pickupNote.length
																}
															</span>
														) : (
															<span className='text-green-600'>
																{
																	pickupNote.length
																}
															</span>
														)}
													</span>
													{pickupNote.length >
														280 && (
														<div className='text-red-600 text-lg'>
															!! Pickup
															instructions cannot
															exceed 280
															characters !!
														</div>
													)}
												</div>
											) : (
												<span className='font-normal mb-3'>
													{
														uberRequestDoc.pickup
															.notes
													}{' '}
													<span
														className='text-red-400 underline'
														onClick={() => {
															setEditPickupNote(
																true
															)
														}}
													>
														edit
													</span>
												</span>
											)}
										</div>
										<div className='flex flex-row sm:flex-col'>
											<span className='font-bold font-header mr-3'>
												Pickup Image:
											</span>
											{proofOfDelivery &&
												proofOfDelivery.pickupImageData && (
													<img
														src={
															proofOfDelivery.pickupImageData
														}
														alt='Pickup'
														className='w-full h-auto'
													/>
												)}
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Location:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.dropoff.name}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Ready Time:
											</span>
											<span className='font-normal mb-3'>
												{uberRequestDoc.dropoff_ready}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Deadline:
											</span>
											<span className='font-normal mb-3'>
												{
													uberRequestDoc.dropoff_deadline
												}
											</span>
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Dropoff Note:
											</span>
											{editDropoffNote ? (
												<div>
													<TextArea
														type='text'
														className='w-full'
														value={dropoffNote}
														onChange={(e) => {
															setDropoffNote(e)
														}}
													/>
													<span className='text-sm'>
														Character count:{' '}
														{dropoffNote.length >
														280 ? (
															<span className='text-red-600'>
																{
																	dropoffNote.length
																}
															</span>
														) : (
															<span className='text-green-600'>
																{
																	dropoffNote.length
																}
															</span>
														)}
													</span>
													{dropoffNote.length >
														280 && (
														<div className='text-red-600 text-lg'>
															!! Dropoff
															instructions cannot
															exceed 280
															characters !!
														</div>
													)}
												</div>
											) : (
												<span className='font-normal mb-3'>
													{
														uberRequestDoc.dropoff
															.notes
													}{' '}
													<span
														className='text-red-400 underline'
														onClick={() => {
															setEditDropoffNote(
																true
															)
														}}
													>
														edit
													</span>
												</span>
											)}
										</div>
										<div className='flex flex-row sm:flex-col'>
											<span className='font-bold font-header mr-3'>
												Dropoff Image:
											</span>
											{proofOfDelivery &&
												proofOfDelivery.dropoffImageData && (
													<img
														src={
															proofOfDelivery.dropoffImageData
														}
														alt='Pickup'
														className='w-full h-auto'
													/>
												)}
										</div>
										<div className='flex flex-col sm:flex-row'>
											<span className='font-bold font-header mr-3'>
												Tracking:
											</span>
											<span className='font-normal mb-3'>
												<a
													href={
														uberRequestDoc.tracking_url
													}
													target='_blank'
													className='underline text-green-600'
												>
													link
												</a>
											</span>
										</div>
										{/* <div className='flex flex-col sm:flex-row mb-3 items-center'>
											<span className='font-bold font-header mr-3'>
												Driver tip:
											</span>
											<Input
												className='w-full mr-3'
												type='number'
												value={tipAmount}
												placeholder={
													uberRequestDoc.tip_by_customer
														? uberRequestDoc.tip_by_customer
														: '$0.00'
												}
												min={0}
												onChange={(e) => {
													setTipAmount(e.target.value)
												}}
											/>
										</div> */}
										<div className='flex flex-row justify-between items-center'>
											<Button
												text='Cancel'
												size='sm'
												color='red-outlined'
												className='mr-4 block font-semibold relative'
												onClick={() => {
													onCancelUberDirect(
														uberRequestDoc.id
													)
												}}
											/>
											<Button
												text='Save Changes'
												size='sm'
												color='green'
												className='mr-6 font-semibold relative'
												onClick={() => {
													// console.log("Updating Uber Direct with the following values:");
													// console.log("orderId:", dropoff.uberdirectId);
													// console.log("tipByCustomer:", parseFloat(tipAmount));
													// console.log("pickupNote:", pickupNote);
													// console.log("dropoffNote:", dropoffNote);

													onUpdateUberDirect({
														orderId:
															dropoff.uberdirectId,
														// tipByCustomer:
														// 	parseFloat(
														// 		tipAmount
														// 	),
														pickupNote: pickupNote,
														dropoffNote:
															dropoffNote,
													})
												}}
											/>
										</div>

										{!proofOfDelivery && (
											<Button
												text='Fetch Images'
												size='sm'
												color='green-outlined'
												className='my-3 block font-semibold relative'
												onClick={() => {
													onGetProofOfDelivery(
														uberRequestDoc.id
													)
												}}
											/>
										)}
										{loading && (
											<BounceLoaderCentered container='div' />
										)}
									</div>
								</div>
							)}
						</div>
					) : (
						<BounceLoaderCentered container='div' />
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Dropoffs, Logistics }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	gettingDropoff: Dropoffs.gettingDropoff,
	dropoff: Dropoffs.dropoff,
	getDropoffError: Dropoffs.getDropoffError,
	loading: Logistics.loading,
	uberRequest: Logistics.uberRequest,
	uberRequestDoc: Logistics.uberRequestDoc,
	uberQuote: Logistics.uberQuote,
	error: Logistics.error,
	updatedRequest: Logistics.updatedRequest,
	cancelledRequest: Logistics.cancelled,
	cancellingDropoff: Dropoffs.cancellingDropoff,
	dropoffError: Dropoffs.dropoffError,
	dropoffCancelled: Dropoffs.dropoffCancelled,
	proofOfDelivery: Logistics.proofOfDelivery,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetDropoff: (payload) => dispatch(getDropoffById(payload)),
	onCancelUberDirect: (payload) => dispatch(cancelUberDirect(payload)),
	onUpdateUberDirect: (payload) => dispatch(updateUberDirect(payload)),
	onGetUberDirectRequestById: (payload) =>
		dispatch(getUberDirectRequestById(payload)),
	onGetUberDirectRequestDocById: (payload) =>
		dispatch(getUberDirectRequestDocById(payload)),
	onCancelFlotDropoff: (payload) => dispatch(cancelFlotDropoff(payload)),
	onGetProofOfDelivery: (payload) => dispatch(getProofOfDelivery(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewOneDropoff)
