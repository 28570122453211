import React from 'react'
import { Button, BounceLoaderCentered } from '../Elements'
import UserProfileOrders from './UserProfileOrders'

const OrderHistoryCard = ({
	loadingOrders,
	orders,
	orderPage,
	setOrderPage,
	paginationLimit,
	ordersCount,
}) => {
	return (
		// <div className='mb-10 overflow-y-auto scrollbar mx-auto md:ml-10 min-w-[300px] md:min-w-[360px] lg:min-w-[720px] h-[350px] shadow-lighter-grey max-w-xs w-full flex sm:flex-row justify-between rounded-xl bg-white p-6'>
		<div className='cardLessMargin max-h-[500px] overflow-y-auto scrollbar'>
			<h3 className='mt-2 block font-header text-2xl sm:text-3xl text-green-400'>
				My Order History
			</h3>
			<div className='w-full'>
				{loadingOrders && <BounceLoaderCentered container='div' />}
				{!loadingOrders && (!orders || orders.length == 0) && (
					<div className='mt-3 text-md lg:text-[18px]'>
						<div>Looks like you haven't ordered food yet!</div>
						<div className='mt-2 mb-6 text-md lg:text-[18px]'>
							Let's change that.
						</div>
						<Button
							className='px-3 m-auto mt-3'
							text='Order in Reusables'
							size='sm'
							link='/marketplace/locations'
						></Button>
					</div>
				)}
				{!loadingOrders && orders && orders.length > 0 && (
					<div className='mt-3 w-full'>
						<UserProfileOrders orders={orders} />
					</div>
				)}
				{ordersCount > orderPage * paginationLimit && (
					<div className='flex justify-center'>
						<button
							onClick={() => {
								setOrderPage(orderPage + 1)
							}}
							className='mt-2 my-3 link-sm underline text-green-600 font-semibold'
						>
							Load more
						</button>
					</div>
				)}
			</div>
		</div>
	)
}

export default OrderHistoryCard
