import { getUser } from '../../store/auth/user/actions'
import {
	chargeRestaurants,
	getRestaurantCharges,
} from '../../store/restaurantPayments/actions'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import Header from '../../components/Header'
import { Button, CheckboxGroup } from '../../components/Elements'
import { Footer } from '../../components'
import {
	getNodeByAdminCodeOrSlug,
	getNodeBySlug,
} from '../../store/nodes/actions'

const RestaurantViewCharges = (state) => {
	const {
		user,
		onGetUser,
		restaurantCharges,
		restaurantChargesError,
		chargingRestaurants,
		restaurantChargesCompleted,
		onGetRestaurantCharges,
		node,
		onGetNodeBySlug,
	} = state

	useEffect(() => {
		onGetUser()
		orderPaginate()
	}, [onGetUser])

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [restaurantChargesPage, setRestaurantChargesPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [restaurantsToPay, setRestaurantsToPay] = useState([])
	const [selectedNode, setSelectedNode] = useState('')
	let navigate = useNavigate()

	// console.log(restaurantsToPay)

	const getRestaurantCharges = (page) => {
		if (selectedNode !== '') {
			onGetRestaurantCharges({
				filter: {
					$or: [
						{ nodeSlug: selectedNode },
						{ parentSlug: selectedNode },
					],
				},
				options: {
					sort: {
						endDate: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const orderPaginate = () => {
		getRestaurantCharges(restaurantChargesPage)
	}

	useEffect(() => {
		orderPaginate()
	}, [])

	useEffect(() => {
		orderPaginate()
	}, [restaurantChargesPage, restaurantChargesCompleted, selectedNode])

	useEffect(() => {
		setLoading(false)
	}, [restaurantCharges])

	useEffect(() => {
		if (user) {
			setSelectedNode(user.restaurantAdmin)
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [user])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/restaurant-admin/home')
					}}
				>
					&#8592; Go to Dashboard Home
				</div>
				{node && (
					<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
						What {node.name} owes DeliverZero
					</h1>
				)}
				<div className='text-center'>
					<Button
						className='my-5 mx-auto'
						link='/restaurant-admin/charges/credit-card-form'
						text='Setup Payments to DeliverZero'
					/>
				</div>
				<div className='flex flex-col max-w-[1200px] justify-center mt-5 mb-10 overflow-x-auto mx-auto'>
					<div className='mx-auto'>
						{restaurantCharges.length === 0 && (
							<div className='px-10 my-6 shadow-light-grey w-[300px] h-[100px]'>
								<div className='flex flex-row w-full h-full'>
									<div className='w-full text-green-600 mx-auto my-auto flex justify-center align-center'>
										No charges found
									</div>
								</div>
							</div>
						)}
						{restaurantCharges.length > 0 && (
							<div className='px-10 my-6 shadow-light-grey'>
								<div className='flex flex-row mb-2'>
									<div className='font-bold w-[130px]'>
										Orders From
									</div>
									<div className='font-bold w-[130px]'>
										To
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Number of Orders
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Number of Boxes
									</div>
									<div className='font-bold w-[130px] text-sm'>
										Total Charges
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Charge Status
									</div>
								</div>
								{loading && (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								{restaurantCharges.map((restaurantCharge) => {
									// console.log(restaurantCharge)
									const startDate = new Date(
										restaurantCharge.startDate
									)
									const endDate = new Date(
										restaurantCharge.endDate
									)
									const startDateString =
										startDate.toLocaleString('default', {
											month: 'short',
											day: 'numeric',
											year: 'numeric',
										})
									const endDateString =
										endDate.toLocaleString('default', {
											month: 'short',
											day: 'numeric',
											year: 'numeric',
										})

									const startMonth = startDate.getMonth() + 1
									const startDay = startDate.getDate()
									const startYear = startDate.getFullYear()
									const endMonth = endDate.getMonth() + 1
									const endDay = endDate.getDate()
									const endYear = endDate.getFullYear()

									return (
										<div className='flex flex-row my-2 divide-y-2 divide-solid'>
											<div className='w-[130px]'>
												{startDateString}
											</div>
											<div className='w-[130px]'>
												{endDateString}
											</div>
											<div className='w-[100px] overflow-hidden'>
												{
													restaurantCharge.numberOfOrders
												}
											</div>
											<div className='w-[100px] overflow-hidden'>
												{restaurantCharge.boxCount}
											</div>
											<div className='w-[130px] overflow-hidden'>
												$
												{restaurantCharge.amountToCharge.toFixed(
													2
												)}
											</div>
											{restaurantCharge.status &&
											restaurantCharge.status ===
												'paid' ? (
												<div className='w-[100px] overflow-hidden text-green-600'>
													{restaurantCharge.status.toUpperCase()}
												</div>
											) : (
												<div className='w-[100px] overflow-hidden'>
													{restaurantCharge.status.toUpperCase()}
												</div>
											)}
											<Button
												text='VIEW ORDERS IN THIS PERIOD'
												size='sm'
												link={`/restaurant-admin/orders/date/${
													startMonth +
													'-' +
													startDay +
													'-' +
													startYear
												}/${
													endMonth +
													'-' +
													endDay +
													'-' +
													endYear
												}`}
											/>
										</div>
									)
								})}
								<div className='flex flex-row'>
									<div
										className='mx-auto underline font-bold mr-6 cursor-pointer'
										onClick={() => {
											setRestaurantChargesPage(
												restaurantChargesPage - 1
											)
											setLoading(true)
										}}
									>
										Previous {paginationLimit} payments
									</div>
									<div
										className='mx-auto underline font-bold cursor-pointer'
										onClick={() => {
											setRestaurantChargesPage(
												restaurantChargesPage + 1
											)
											setLoading(true)
										}}
									>
										Next {paginationLimit} payments
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, RestaurantPayments, Nodes }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	restaurantCharges: RestaurantPayments.restaurantCharges,
	restaurantChargesError: RestaurantPayments.error,
	loadingRestaurantCharges: RestaurantPayments.loadingRestaurantCharges,
	chargingRestaurants: RestaurantPayments.chargingRestaurants,
	restaurantChargesCompleted: RestaurantPayments.restaurantChargesCompleted,
	node: Nodes.restaurantAdminNode,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetRestaurantCharges: (payload) =>
		dispatch(getRestaurantCharges(payload)),
	onGetNodeBySlug: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantViewCharges)
