import {
	GET_OFFICE,
	GET_OFFICE_SUCCESS,
	GET_OFFICE_ERROR,
	GET_OFFICE_TRANSACTIONS,
	GET_OFFICE_TRANSACTIONS_SUCCESS,
	GET_OFFICE_TRANSACTIONS_FAIL,
	GET_ONE_TRANSACTION_OFFICE,
	GET_ONE_TRANSACTION_OFFICE_SUCCESS,
	GET_ONE_TRANSACTION_OFFICE_FAIL,
	GET_OFFICE_CUSTOMERS,
	GET_OFFICE_CUSTOMERS_SUCCESS,
	GET_OFFICE_CUSTOMERS_FAIL,
	GET_ONE_CUSTOMER_OFFICE,
	GET_ONE_CUSTOMER_OFFICE_SUCCESS,
	GET_ONE_CUSTOMER_OFFICE_FAIL,
	GET_OFFICE_BY_ADMIN_CODE,
	GET_OFFICE_BY_ADMIN_CODE_SUCCESS,
	GET_OFFICE_BY_ADMIN_CODE_FAIL,
	RESET_ADMIN_OFFICE,
	INVITE_OFFICE_ADMIN,
	INVITE_OFFICE_ADMIN_FAIL,
	INVITE_OFFICE_ADMIN_SUCCESS,
	RESET_OFFICE_INVITE,
} from './actionTypes'
import {
	UPDATE_OFFICE,
	UPDATE_OFFICE_SUCCESS,
	UPDATE_OFFICE_ERROR,
} from './actionTypes'
import {
	UPDATE_OFFICE_EMAIL,
	UPDATE_OFFICE_EMAIL_SUCCESS,
	UPDATE_OFFICE_EMAIL_ERROR,
} from './actionTypes'
import {
	STORE_PAYMENT_METHOD_OFFICE,
	STORE_PAYMENT_METHOD_OFFICE_SUCCESS,
	STORE_PAYMENT_METHOD_OFFICE_FAIL,
} from './actionTypes'
import {
	GET_OFFICE_ADMINS,
	GET_OFFICE_ADMINS_SUCCESS,
	GET_OFFICE_ADMINS_ERROR,
} from './actionTypes'
import {
	SCHEDULE_OFFICE_PICKUP,
	SCHEDULE_OFFICE_PICKUP_SUCCESS,
	SCHEDULE_OFFICE_PICKUP_ERROR,
} from './actionTypes'
import {
	ASSIGN_OFFICE_ADMIN,
	ASSIGN_OFFICE_ADMIN_SUCCESS,
	ASSIGN_OFFICE_ADMIN_ERROR,
} from './actionTypes'

export const getOffice = (payload) => ({
	type: GET_OFFICE,
	payload: payload,
})

export const getOfficeSuccess = (payload) => ({
	type: GET_OFFICE_SUCCESS,
	payload: payload,
})

export const getOfficeError = (payload) => ({
	type: GET_OFFICE_ERROR,
	payload: payload,
})

export const getOfficeTransactions = (payload) => ({
	type: GET_OFFICE_TRANSACTIONS,
	payload: payload,
})

export const getOfficeTransactionsSuccess = (payload) => ({
	type: GET_OFFICE_TRANSACTIONS_SUCCESS,
	payload: payload,
})

export const getOfficeTransactionsFail = (error) => ({
	type: GET_OFFICE_TRANSACTIONS_FAIL,
	payload: error,
})

export const getOneTransactionOffice = (payload) => ({
	type: GET_ONE_TRANSACTION_OFFICE,
	payload: payload,
})

export const getOneTransactionOfficeSuccess = (payload) => ({
	type: GET_ONE_TRANSACTION_OFFICE_SUCCESS,
	payload: payload,
})

export const getOneTransactionOfficeFail = (error) => ({
	type: GET_ONE_TRANSACTION_OFFICE_FAIL,
	payload: error,
})

export const updateOffice = (payload) => ({
	type: UPDATE_OFFICE,
	payload: payload,
})

export const updateOfficeSuccess = (payload) => ({
	type: UPDATE_OFFICE_SUCCESS,
	payload: payload,
})

export const updateOfficeError = (payload) => ({
	type: UPDATE_OFFICE_ERROR,
	payload: payload,
})

export const getOfficeAdmins = (payload) => ({
	type: GET_OFFICE_ADMINS,
	payload: payload,
})

export const getOfficeAdminsSuccess = (payload) => ({
	type: GET_OFFICE_ADMINS_SUCCESS,
	payload: payload,
})

export const getOfficeAdminsError = (payload) => ({
	type: GET_OFFICE_ADMINS_ERROR,
	payload: payload,
})

export const assignOfficeAdmin = (payload) => ({
	type: ASSIGN_OFFICE_ADMIN,
	payload: payload,
})

export const assignOfficeAdminSuccess = (payload) => ({
	type: ASSIGN_OFFICE_ADMIN_SUCCESS,
	payload: payload,
})

export const assignOfficeAdminError = (payload) => ({
	type: ASSIGN_OFFICE_ADMIN_ERROR,
	payload: payload,
})

export const scheduleOfficePickup = (payload) => ({
	type: SCHEDULE_OFFICE_PICKUP,
	payload: payload,
})

export const scheduleOfficePickupSuccess = (payload) => ({
	type: SCHEDULE_OFFICE_PICKUP_SUCCESS,
	payload: payload,
})

export const scheduleOfficePickupError = (payload) => ({
	type: SCHEDULE_OFFICE_PICKUP_ERROR,
	payload: payload,
})

export const getOfficeCustomers = (payload) => ({
	type: GET_OFFICE_CUSTOMERS,
	payload: payload,
})

export const getOfficeCustomersSuccess = (payload) => ({
	type: GET_OFFICE_CUSTOMERS_SUCCESS,
	payload: payload,
})

export const getOfficeCustomersFail = (error) => ({
	type: GET_OFFICE_CUSTOMERS_FAIL,
	payload: error,
})

export const getOneCustomerOffice = (payload) => ({
	type: GET_ONE_CUSTOMER_OFFICE,
	payload: payload,
})

export const getOneCustomerOfficeSuccess = (payload) => ({
	type: GET_ONE_CUSTOMER_OFFICE_SUCCESS,
	payload: payload,
})

export const getOneCustomerOfficeFail = (error) => ({
	type: GET_ONE_CUSTOMER_OFFICE_FAIL,
	payload: error,
})

export const storePaymentMethodOffice = (payload) => ({
	type: STORE_PAYMENT_METHOD_OFFICE,
	payload: payload,
})

export const storePaymentMethodOfficeSuccess = (payload) => ({
	type: STORE_PAYMENT_METHOD_OFFICE_SUCCESS,
	payload: payload,
})

export const storePaymentMethodOfficeFail = (error) => ({
	type: STORE_PAYMENT_METHOD_OFFICE_FAIL,
	payload: error,
})

export const updateOfficeEmail = (payload) => ({
	type: UPDATE_OFFICE_EMAIL,
	payload: payload,
})

export const updateOfficeEmailSuccess = (payload) => ({
	type: UPDATE_OFFICE_EMAIL_SUCCESS,
	payload: payload,
})

export const updateOfficeEmailError = (payload) => ({
	type: UPDATE_OFFICE_EMAIL_ERROR,
	payload: payload,
})

export const getOfficeByAdminCode = (payload) => ({
	type: GET_OFFICE_BY_ADMIN_CODE,
	payload: payload,
})

export const getOfficeByAdminCodeSuccess = (payload) => ({
	type: GET_OFFICE_BY_ADMIN_CODE_SUCCESS,
	payload: payload,
})

export const getOfficeByAdminCodeFail = (payload) => ({
	type: GET_OFFICE_BY_ADMIN_CODE_FAIL,
	payload: payload,
})

export const resetAdminOffice = () => ({
	type: RESET_ADMIN_OFFICE,
})

export const inviteOfficeAdmin = (payload) => ({
	type: INVITE_OFFICE_ADMIN,
	payload: payload,
})

export const inviteOfficeAdminSuccess = (payload) => ({
	type: INVITE_OFFICE_ADMIN_SUCCESS,
	payload: payload,
})

export const inviteOfficeAdminError = (payload) => ({
	type: INVITE_OFFICE_ADMIN_FAIL,
	payload: payload,
})

export const resetOfficeInvite = () => ({
	type: RESET_OFFICE_INVITE,
})
