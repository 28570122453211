import React, { useState, useEffect, Suspense } from 'react'
import { Header, Footer } from '../../../components'
import { Pagination } from '../../../components/Elements'
import {
	getAdminReturns,
	getUser,
	getReturnsCount,
	getRlPartners,
} from '../../../store/actions'
import { connect } from 'react-redux'
import { FaPlus, FaMinus } from 'react-icons/fa'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import LZString from 'lz-string'
import { SelectGroup, CheckboxGroup } from '../../../components/Elements'
import DatePicker from 'react-datepicker'

const ReturnFeed = (props) => {
	const {
		onGetReturns,
		returns,
		user,
		onGetUser,
		onGetReturnsCount,
		returnsCount,
		returnsFeedCount,
		rLPartners,
		partnersError,
		loadingPartners,
		onGetRlPartners,
		onGetPartners,
		partners,
		loadingReturns,
	} = props
	const [paginationLimit, setPaginationLimit] = useState(30)
	const [page, setPage] = useState(1)
	const [enlargedPicture, setEnlargedPicture] = useState(false)
	const [reverseLogisticsPartner, setReverseLogisticsPartner] = useState('')
	// const [returnedByText, setReturnedByText] = useState(false)
	// const [returnAtDoor, setReturnAtDoor] = useState(false)
	const [rlpSelections, setRlpSelections] = useState([])
	const [initialLoad, setInitialLoad] = useState(true)
	const [startDate, setStartDate] = useState(new Date(2022, 9, 1))
	const [endDate, setEndDate] = useState(new Date())
	const [filter, setFilter] = useState({})
	const [rlpFilter, setRlpFilter] = useState([])
	let navigate = useNavigate()
	const bounceLoaderColor = '#507f74'
	const LazyImage = React.lazy(() => import('../../../components/LazyLoad'))

	useEffect(() => {
		onGetUser()
		onGetPartners()
	}, [onGetUser])

	// useEffect(() => {
	// 	onGetReturnsCount()
	// }, [returns])

	useEffect(() => {
		onGetRlPartners({
			filter: {},
			options: {
				sort: {},
				limit: 1000000,
				skip: 0,
			},
		})
	}, [onGetRlPartners])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			setFilter({
				franchiseeSlug: user.franchiseeSlug,
			})
			setInitialLoad(false)
		} else {
			setFilter({})
			setInitialLoad(false)
		}
	}, [])

	useEffect(() => {
		if (user) {
			if (user.franchiseeSlug) {
				if (rlpFilter === 'All') {
					setFilter({
						franchiseeSlug: user.franchiseeSlug,
					})
				} else {
					setFilter({
						franchiseeSlug: user.franchiseeSlug,
						reverseLogisticsPartner: rlpFilter,
					})
				}
			} else {
				if (rlpFilter === 'All') {
					setFilter({})
				} else {
					setFilter({
						reverseLogisticsPartner: rlpFilter,
					})
				}
			}
		} else {
			onGetUser()
		}
	}, [rlpFilter])

	useEffect(() => {
		const rlpSelections = [
			{
				label: 'All',
				value: '',
			},
		]
		rLPartners.map((partner) => {
			rlpSelections.push({
				label: partner.partner,
				value: partner.partner,
			})
		})
		setRlpSelections(rlpSelections)
	}, [rLPartners])

	useEffect(() => {
		const partnerSelections = [
			{
				label: 'All',
				value: 'All',
				selected: true,
			},
		]
		if (partners && partners.length > 0) {
			for (const partner of partners) {
				partnerSelections.push({
					label: partner.partner,
					value: partner.partner,
				})
			}
			setRlpSelections(partnerSelections)
		} else {
			onGetPartners()
		}
	}, [partners])

	useEffect(() => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetReturns({
					page,
					paginationLimit,
					skip: (page - 1) * paginationLimit,
					filter: {
						franchiseeSlug: user.franchiseeSlug,
					},
					startDate,
					endDate,
				})
			} else {
				onGetReturns({
					page,
					paginationLimit,
					skip: (page - 1) * paginationLimit,
					startDate,
					endDate,
					filter: filter,
				})
			}
		} else {
			onGetUser()
		}
	}, [page, paginationLimit, startDate, endDate, filter, user])

	const returnsPaginate = () => {
		getReturns(page)
	}

	useEffect(() => {
		returnsPaginate()
	}, [
		page,
		reverseLogisticsPartner,
		// returnAtDoor,
		// returnedByText,
		startDate,
		endDate,
	])

	const handleChangePage = (newPage) => {
		if (newPage < 1) {
			newPage = 1
		} else if (newPage > Math.ceil(returnsFeedCount / paginationLimit)) {
			newPage = Math.ceil(returnsFeedCount / paginationLimit)
		}

		setPage(newPage)
	}

	const togglePicture = () => {
		setEnlargedPicture(!enlargedPicture)
	}

	const decompressImage = (item) => {
		if (item.returnImageSource.startsWith('data:image/jpeg;base64,/9j/')) {
			return item.returnImageSource
		} else if (item.returnImageSource.startsWith('image data archived')) {
			return '/images/imageArchived.png'
		} else {
			const compressedData = item.returnImageSource.split(
				'data:image/jpeg;base64,'
			)[1]
			const uncompressedData =
				LZString.decompressFromBase64(compressedData)
			return `data:image/jpeg;base64,${uncompressedData}`
		}
	}

	const getReturns = (page) => {
		let filter = {
			timestamp: { $gte: startDate, $lte: endDate },
		}
		if (user && user.franchiseeSlug) {
			filter = {
				timestamp: { $gte: startDate, $lte: endDate },
				franchiseeSlug: user.franchiseeSlug,
			}
		}
		if (reverseLogisticsPartner !== '') {
			filter.reverseLogisticsPartner = reverseLogisticsPartner
		}
		// if (returnedByText) {
		// 	filter.returnedByText = returnedByText
		// }
		// if (returnAtDoor) {
		// 	filter.returnAtDoor = returnAtDoor
		// }
		onGetReturns({
			page,
			paginationLimit,
			skip: (page - 1) * paginationLimit,
			filter: filter,
		})
	}

	if (!user || initialLoad)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='flex flex-col justify-between w-screen'>
					<div className='w-screen h-full px-auto flex flex-col justify-between items-center container'>
						{/* <div className='flex flex-col items-center justify-center mb-16 w-screen container'> */}
						<h1 className='font-header text-green-600 text-4xl md:text-[66px] mb-6 md:mb-11 mt-8'>
							Returns
						</h1>
						<div className='flex flex-col justify-center items-center'>
							<div className='flex flex-col sm:flex-row'>
								<div className='flex flex-col sm:mr-4'>
									<div className='mt-0 sm:m-0'>
										Select Start Date
									</div>
									<div className='mb-2 sm:mb-6 border border-gray-400'>
										<DatePicker
											selected={startDate}
											onChange={(date) =>
												setStartDate(date)
											}
										/>
									</div>
								</div>
								<div className='flex flex-col'>
									<div className='mt-4 sm:m-0'>
										Select End Date
									</div>
									<div className='mb-6 sm:mb-6 border border-gray-400'>
										<DatePicker
											selected={endDate}
											onChange={(date) =>
												setEndDate(date)
											}
										/>
									</div>
								</div>
							</div>
							{!user.franchiseeSlug && (
								<div className='mb-8 flex justify-center items-center'>
									<SelectGroup
										label='Filter by RLP'
										placeholder='Select...'
										name='rlp'
										options={rlpSelections}
										onChange={(e) => {
											setRlpFilter(e.target.value)
										}}
									/>
								</div>
							)}
						</div>
						{loadingReturns && (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
						{!returns.length && (
							<div className='w-full text-center mx-auto flex justify-center align-center'>
								<div className='w-fit font-bold text-red-400 w-[70px] whitespace-nowrap'>
									No returns found
								</div>
							</div>
						)}
						{returns.length > 0 && (
							<>
								<div className='mb-8'>
									{`page ${page} of ${Math.ceil(
										returnsFeedCount / paginationLimit
									)}`}
								</div>
								<div className='w-full'>
									<Pagination
										totalCount={returnsFeedCount}
										paginationLimit={paginationLimit}
										page={page}
										handleChangePage={handleChangePage}
										numDisplayPages={2}
									/>
								</div>
							</>
						)}
						<Suspense
							fallback={
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							}
						>
							{returns.length > 0 &&
								returns.map((item, index) => {
									return (
										<div
											key={index}
											className='border-b-2 flex flex-col sm:flex-row mb-4 pb-2 w-5/6 justify-center items-center h-fit'
										>
											<p className='font-semibold p-2 mr-12'>
												{item?.timestamp?.toLocaleDateString()}
											</p>
											<div className='flex flex-col sm:flex-row w-full items-center'>
												<div className='flexs flex-col w-full'>
													<p className='font-header text-2xl'>
														{
															item.reverseLogisticsPartner
														}
													</p>
													<p className='font-header text-2xl'>
														{item.node}
													</p>
													{item.latePayment && (
														<p className='font-header text-2xl'>
															LATE PAYMENT
														</p>
													)}
													<p>
														Count: {item.boxCount}
													</p>
													<div>
														{!user.franchiseeSlug && (
															<>
																<p>
																	Phone:{' '}
																	{
																		item
																			.user
																			?.phone
																	}
																</p>
																<p>
																	Email:{' '}
																	{
																		item
																			.user
																			?.email
																	}
																</p>
															</>
														)}
														<p>
															Name:{' '}
															{
																item.user
																	?.firstName
															}{' '}
															{
																item.user
																	?.lastName
															}
														</p>
													</div>
												</div>
											</div>
											{item.returnImageSource != null && (
												<div>
													{enlargedPicture ? (
														<div className='flex w-full justify-center items-center text-center  my-2'>
															<FaMinus
																className='m-4 text-4xl text-green-600 cursor-pointer'
																onClick={
																	togglePicture
																}
															/>
															<div className='w-[300px]'>
																<LazyImage
																	src={decompressImage(
																		item
																	)}
																	alt='return image'
																	className='object-fit'
																/>
															</div>
														</div>
													) : (
														<div className='flex w-full justify-center items-center text-center text-green-600 my-2'>
															<FaPlus
																className='m-4 text-4xl text-green-600 cursor-pointer'
																onClick={
																	togglePicture
																}
															/>
															<div className='w-[100px]'>
																<LazyImage
																	src={decompressImage(
																		item
																	)}
																	alt='return image'
																	className='object-fit'
																/>
															</div>
														</div>
													)}
												</div>
											)}
										</div>
									)
								})}
						</Suspense>
						<div className='w-full'>
							<Pagination
								totalCount={returnsFeedCount}
								paginationLimit={paginationLimit}
								page={page}
								handleChangePage={handleChangePage}
								numDisplayPages={2}
							/>
						</div>
					</div>
					<Footer />
				</div>
				{/* </div> */}
			</>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Admin, User, ReverseLogistics }) => ({
	returns: Admin.returns,
	returnsCount: Admin.returnsCount,
	returnsFeedCount: Admin.returnsFeedCount,
	loadingReturns: Admin.loadingReturns,
	user: User.user,
	rLPartners: ReverseLogistics.partners,
	loadingPartners: ReverseLogistics.loadingPartners,
	partnersError: ReverseLogistics.partnersError,
	partners: ReverseLogistics.partners,
})

const mapDispatchToProps = (dispatch) => ({
	onGetReturns: (payload) => dispatch(getAdminReturns(payload)),
	onGetReturnsCount: (payload) => dispatch(getReturnsCount(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetRlPartners: () => dispatch(getRlPartners()),
	onGetPartners: () => dispatch(getRlPartners()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnFeed)
