import React from 'react'
import { Footer, Header } from '../../components'
import { Badge } from '../../components/Elements'

const pressLinks = [
	{
		key: 'cnn',
		publication: 'CNN',
		link: 'https://www.cnn.com/2021/03/22/business/deliverzero-takeout-food-waste-startup/index.html',
		logo: 'https://cdn.deliverzero.com/images/press/cnn-logo-bw.png',
		title: 'This startup aims to take the trash out of takeout',
		synopsis:
			'The company provides plastic, reusable containers to participating restaurants.',
		video: false,
		display: true,
	},
	{
		key: 'forbes',
		publication: 'Forbes',
		link: 'https://www.forbes.com/sites/jeffkart/2021/07/29/the-future-of-takeout-is-plastic-free/?sh=666b2895e40c',
		logo: 'https://i.imgur.com/1wyWWpK.png',
		title: 'The Future Of Takeout Is Plastic-Free',
		synopsis:
			'Solutions to the problem include companies like DeliverZero, a New York City service that packages food from more than 100 participating restaurants in reusable containers.',
		video: false,
		display: true,
	},
	{
		key: 'goop',
		publication: 'goop',
		link: 'https://goop.com/wellness/environmental-health-civics/how-to-reduce-takeout-plastic/',
		logo: 'https://cdn.deliverzero.com/images/press/goop-logo-bw.png',
		title: 'Ordering Takeout the Sustainable Way',
		synopsis:
			'After a recent move to New York City, I discovered DeliverZero, an online platform that allows customers to have food delivered from restaurants in reusable containers.',
		video: false,
		display: true,
	},
	{
		key: 'wsj',
		publication: 'Wall Street Journal',
		link: 'https://www.wsj.com/articles/take-the-guilt-out-of-takeout-11610641855?mod=hp_listc_pos1',
		logo: 'https://cdn.deliverzero.com/images/press/wall-street-journal.png',
		title: 'Take the Guilt Out of Takeout',
		synopsis:
			'Startups around the country are looking to lighten the burden on landfills by offering takeout in reusable containers. Will consumers get on board?',
		video: false,
		display: true,
	},
	{
		key: 'fast-company',
		publication: 'Fast Company',
		link: 'https://www.fastcompany.com/90591190/this-new-delivery-service-cuts-down-on-takeout-waste-by-sending-your-food-in-reusable-packaging',
		logo: 'https://cdn.deliverzero.com/images/press/fast-company.png',
		title: 'This new delivery service cuts down on takeout waste by sending your food in reusable packaging',
		synopsis:
			'Just give your old ones to the driver when your next order arrives.',
		video: false,
		display: true,
	},
	{
		key: 'thrillist',
		publication: 'Thrillist',
		link: 'https://www.thrillist.com/eat/nation/deliverzero-sustainable-takeout-mission',
		logo: 'https://cdn.deliverzero.com/images/press/thrillist.png',
		title: 'Meet The Delivery Service That’s Trying to Make Takeout More Sustainable',
		synopsis:
			'Takeout is trashy. DeliverZero is on a mission to change that.',
		video: false,
		display: true,
	},
	{
		key: 'now-this',
		publication: 'Now This',
		link: 'https://www.youtube.com/watch?v=bDS-JSsoG6Q',
		logo: 'https://cdn.deliverzero.com/images/press/nowthis-logo-bw.png',
		title: 'I Tried Zero Waste Take-Out (DeliverZero)',
		synopsis:
			"We've all felt the guilt: You get takeout for dinner and your order comes with a ton of single-use packaging. There's got to be a better way, so we set out to discover if takeout could go zero-waste",
		video: true,
		display: true,
	},
	{
		key: 'abc',
		publication: ' KMIZ ABC 17',
		link: 'https://abc17news.com/money/2021/03/22/this-startup-aims-to-take-the-trash-out-of-takeout/',
		logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/American_Broadcasting_Company_Logo.svg/240px-American_Broadcasting_Company_Logo.svg.png',
		title: 'This startup aims to take the trash out of takeout',
		synopsis:
			'The company provides plastic, reusable containers to participating restaurants.',
		video: false,
		display: false,
	},
	{
		key: 'world-economic-forum',
		publication: 'World Economic Forum',
		link: 'https://www.weforum.org/agenda/2020/01/how-this-new-company-is-taking-the-waste-out-of-restaurant-delivery/',
		logo: 'https://cdn.deliverzero.com/images/press/world-economic-forum-logo-bw.png',
		title: 'This US startup is making take-out food delivery more sustainable',
		synopsis:
			'Ordering restaurant delivery in New York City with zero packaging waste is virtually impossible — but three young Brooklyn parents are working to change that.',
		video: false,
		display: true,
	},
	{
		key: 'nbc-new-york',
		publication: 'WNBC New York',
		link: 'https://www.nbcnewyork.com/news/ny-business-aims-to-eliminate-takeout-waste/2293765/',
		logo: 'https://cdn.deliverzero.com/images/press/wnbc-logo-bw.png',
		title: 'NY business aims to eliminate takeout waste',
		synopsis:
			'After seeing delivery containers leading to serious waste, a group of Brooklyn parents decided to take action to fight all the unnecessary waste. NBC New York’s Gus Rosendale reports.',
		video: true,
		display: true,
	},
	{
		key: 'fox-5-new-york',
		publication: 'Fox 5 New York',
		link: 'https://www.fox5ny.com/news/restaurants-reduce-waste-by-delivering-food-in-reusable-containers',
		logo: 'https://cdn.deliverzero.com/images/press/fox-5-logo-bw.png',
		title: 'Restaurants reduce waste by delivering food in reusable containers',
		synopsis:
			"New Yorkers love their food delivery but along with all that takeout can come a lot of trash. One start-up thinks there's a better way: DeliverZero. It promises delivery with zero-waste.",
		video: true,
		display: true,
	},
	{
		key: 'mashable',
		publication: 'Mashable',
		link: 'https://mashable.com/video/deliverzero-zero-waste-take-out',
		logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Mashable_Logo_%282021%29.svg/2560px-Mashable_Logo_%282021%29.svg.png',
		title: 'A NYC delivery service is taking the trash out of take-out',
		synopsis:
			'DeliverZero is a New York-based zero waste delivery service that finally brings reusable containers to take-out food.',
		video: false,
		display: true,
	},
	{
		key: 'quartz',
		publication: 'Quartz',
		link: 'https://qz.com/1802406/startups-are-trying-to-solve-takeouts-plastic-problem/',
		logo: '',
		title: 'The growing push to solve the plastic nightmare of takeout food',
		synopsis:
			'Customers who sign up for the DeliverZero service can order their food from those select restaurants and have it delivered in those containers.',
		video: false,
		display: true,
	},
	{
		key: 'greenmatters',
		publication: 'Greenmatters',
		link: 'https://www.greenmatters.com/p/deliver-zero-low-waste-packaging-seamless',
		logo: '',
		title: 'How This New Company Is Taking the Waste Out of Restaurant Delivery',
		synopsis:
			'Ordering restaurant delivery in New York City with zero packaging waste is virtually impossible— but three young Brooklyn parents are working to change that.',
		video: false,
		display: true,
	},
	{
		key: 'bklyner',
		publication: 'BKLYNER',
		link: '',
		logo: '',
		title: 'Beyond the Plastic Bag Ban: Where to Shop Zero-Waste in Brooklyn',
		synopsis:
			'Let’s be honest, New Yorkers love their take-out. We love it so much we throw away almost a billion takeout containers every year, according to Lauren Sweeney, the Co-founder & CMO of DeliverZero, a new Brooklyn-based company offering a sustainable take-out delivery service.',
		video: false,
		display: true,
	},
	{
		key: 'brooklyn-based',
		publication: 'Brooklyn Based',
		link: 'https://qz.com/1802406/startups-are-trying-to-solve-takeouts-plastic-problem/',
		logo: '',
		title: 'DeliverZero wants to make takeout less trashy',
		synopsis:
			'Ordering restaurant delivery in New York City with zero packaging waste is virtually impossible— but three young Brooklyn parents are working to change that.',
		video: false,
		display: true,
	},
	{
		key: 'wanderlust',
		publication: 'Wanderlust',
		link: 'https://wanderlust.com/journal/tackling-takeout-waste-one-order-at-a-time-deliverzero/',
		logo: '',
		title: 'Tackling Takeout Waste One Order at a Time: DeliverZero',
		synopsis:
			'For #PlasticFreeJuly, we interviewed co-founder, Lauren Sweeney, to find out more about the impact one decision can make – even a decision as simple as where to dial up for Thai tonight.',
		video: false,
		display: true,
	},
	{
		key: 'news-12',
		publication: 'News 12',
		link: 'https://brooklyn.news12.com/brooklyn-business-helping-area-restaurants-go-green-41848953',
		logo: '',
		title: 'Brooklyn business helping area restaurants go green',
		synopsis:
			"New Yorkers love their food delivery but along with all that takeout can come a lot of trash. One start-up thinks there's a better way: DeliverZero. It promises delivery with zero-waste.",
		video: true,
		display: true,
	},
]
const Press = () => {
	return (
		<>
			<Header />
			<div className=' flex flex-col justify-center items-center '>
				<h1 className='text-3xl md:text-5xl font-header text-green-600 text-center w-full mb-4 mt-12'>
					Press
				</h1>
				<div className='flex w-full justify-between items-center container px-6'>
					<iframe
						src='https://www.youtube.com/embed/bDS-JSsoG6Q'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
						title='Embedded youtube'
						className='aspect-video w-full'
					/>
					<img
						src='images/MediaSVGs/Now_This.svg'
						alt='now this logo'
						className='w-1/4 ml-12 hidden md:inline-block'
					/>
				</div>
				<div className=' h-auto  flex flex-col justify-center items-center mt-12 border-b-2'>
					<div className='hidden gap-8 lg:flex lg:flex-wrap lg:justify-center lg:items-center lg:max-w-[1200px]'>
						<a
							href='https://abc17news.com/money/2021/03/22/this-startup-aims-to-take-the-trash-out-of-takeout/'
							target='_blank'
							className='h-18 w-24 block'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/abc.svg'
								alt='abc logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.cnn.com/2021/03/22/business/deliverzero-takeout-food-waste-startup/index.html'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/cnn.svg'
								alt='cnn logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.fastcompany.com/90591190/this-new-delivery-service-cuts-down-on-takeout-waste-by-sending-your-food-in-reusable-packaging'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/fastcompany.svg'
								alt='fast company logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.forbes.com/sites/jeffkart/2021/07/29/the-future-of-takeout-is-plastic-free/?sh=666b2895e40c'
							target='_blank'
							className='block h-18 w-24'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/Forbes.svg'
								alt='forbes logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.fox5ny.com/news/restaurants-reduce-waste-by-delivering-food-in-reusable-containers'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/Fox5.svg'
								alt='fox5 logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://mashable.com/video/deliverzero-zero-waste-take-out'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/Mashable.svg'
								alt='mashable logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:h-[30px] xl:w-auto xl:max-w-[600px]'
							/>
						</a>
						<a
							href='https://goop.com/wellness/environmental-health-civics/how-to-reduce-takeout-plastic/'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/Goop.svg'
								alt='goop logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.youtube.com/watch?v=bDS-JSsoG6Q'
							target='_blank'
							className='h-24 w-24 block'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/Now_This.svg'
								alt='now this logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.nbcnewyork.com/news/ny-business-aims-to-eliminate-takeout-waste/2293765/'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/WNBC_4_NY.svg'
								alt='wnbc 4 new york logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.weforum.org/agenda/2020/01/how-this-new-company-is-taking-the-waste-out-of-restaurant-delivery/'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/World_Economic_Forum_logo.svg'
								alt='World Economic Forum logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.thrillist.com/eat/nation/deliverzero-sustainable-takeout-mission'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/thrillist.svg'
								alt='thrillist logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
						<a
							href='https://www.wsj.com/articles/take-the-guilt-out-of-takeout-11610641855?mod=hp_listc_pos1'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src='images/MediaSVGs/wall-street-journal.svg'
								alt='wall street journal logo'
								className='h-auto max-w-[100px] my-10 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
							/>
						</a>
					</div>
				</div>
				<div className='grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6 mx-12 my-12 max-w-[1200px] '>
					{pressLinks.map(
						(press) =>
							press.display && (
								<a
									href={press.link}
									target='_blank'
									rel='noreferrer'
								>
									<div className='mx-8 my-2  pb-4 border-b-2 lg:border-b-0'>
										<div className='flex justify-between mb-2'>
											<h1 className='font-semibold text-sm '>
												{press.publication.toUpperCase()}
											</h1>{' '}
											{press.video && (
												<Badge
													text='Video'
													className='whitespace-nowrap'
												/>
											)}
										</div>
										<h1 className='font-semibold mb-2'>
											{press.title}
										</h1>
										<p className=''>{press.synopsis}</p>
									</div>
								</a>
							)
					)}
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Press
