import React, { useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'

function CheckboxSelections({
	className = '',
	label = '',
	name = '',
	error = false,
	options = [],
	onChange = () => {},
	labelClassName = '',
	...newProps
}) {
	const finalClass = `${className} appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400`

	return (
		<div className='w-full'>
			<div
				className={`mb-2 text-sm font-semibold ${
					error ? 'text-red-600' : ''
				}`}
			>
				{label}
			</div>
			<div
				className={`${
					options.length > 5 ? 'sm:grid sm:grid-cols-2' : ''
				}`}
			>
				{options.map((option) => {
					const [isHovered, setIsHovered] = useState(false)
					return (
						<div
							key={option.value}
							className='flex items-center pt-3 text-sm'
						>
							<input
								type='checkbox'
								name={name}
								value={option.value}
								onChange={(e) => onChange(e)}
								className={finalClass}
								defaultChecked={option.defaultChecked || false}
								{...newProps}
							/>
							<label
								className={`ml-2 ${labelClassName}`}
								htmlFor={option.value}
							>
								{option.label}
								{option.hoverInfo && (
									<div className='relative inline-block text-center'>
										{' '}
										<FaInfoCircle
											className='invert-0 hover:invert inline ml-1 cursor-pointer'
											onMouseEnter={() =>
												setIsHovered(true)
											}
											onMouseLeave={() =>
												setIsHovered(false)
											}
											onClick={() =>
												setIsHovered(!isHovered)
											}
										/>
										{isHovered && (
											<div className='absolute bg-white border-solid border-2 border-gray-400 p-2 rounded-[5px] !min-w-[180px] !max-w-[300px] z-50 -translate-y-full top-0 transform -translate-x-1/3 mt-[-10px] flex flex-col items-center justify-center'>
												{option.hoverImg && (
													<img
														src={option.hoverImg}
														className='w-[120px] h-[120px] object-scale-down mr-2'
														alt={'img'}
													/>
												)}
												{option.hoverInfo}
											</div>
										)}
									</div>
								)}
							</label>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default CheckboxSelections
