import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getOrders,
	getNodes,
	updateUser,
	getAdminCustomers,
	getAdminClients,
	getAdminAggregators,
	getNodeByAdminCodeOrSlug,
	getTransactions,
	getNodeTransactions,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, SelectGroup } from '../../components/Elements'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { thirdPartyDict } from '../../helpers/clientInfo'

const RestaurantAdminOrders = (state) => {
	const {
		user,
		onGetUser,
		transactions,
		transactionsCount,
		loadingTransactions,
		onGetTransactions,
		loadingOrders,
		// onGetCustomers,
		// loadingCustomers,
		// customers,
		onGetClients,
		onGetAggregators,
		onGetNodeBySlug,
		node,
	} = state

	useEffect(() => {
		onGetUser()
		onGetClients()
		onGetAggregators()
		// onGetCustomers({
		// 	filter: {},
		// 	options: {
		// 		sort: {
		// 			lastUse: -1,
		// 		},
		// 		limit: 1000000,
		// 		skip: 0,
		// 	},
		// })
	}, [onGetUser])

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [nodeCustomers, setNodeCustomers] = useState([])
	// const [clientSelections, setClientSelections] = useState([])
	const [selectedNode, setSelectedNode] = useState('')
	const [selectedCustomer, setSelectedCustomer] = useState({})
	const [typedCustomerSearch, setTypedCustomerSearch] = useState('')
	const [customerSearchResults, setCustomerSearchResults] = useState([])
	const [startDate, setStartDate] = useState(new Date(2022, 9, 1))
	const [endDate, setEndDate] = useState(new Date(2322, 9, 1))
	let navigate = useNavigate()
	const { startDateParam, endDateParam } = useParams()
	const [timeframe, setTimeframe] = useState('')
	const [clientSelections, setClientSelections] = useState([])
	const [selectedClient, setSelectedClient] = useState('')
	const [clientsLoaded, setClientsLoaded] = useState(false)

	useEffect(() => {
		if (startDateParam) {
			const startDateDate = new Date(startDateParam)
			const endDateDate = new Date(endDateParam)
			setStartDate(new Date(startDateDate.setUTCHours(23, 59, 59, 999)))
			setEndDate(new Date(endDateDate.setUTCHours(23, 59, 59, 999)))
		}
	}, [startDateParam])
	console.log(endDate)

	const timePeriods = [
		{ label: 'View All', value: 'all' },
		{ label: 'View last 7 days', value: 'seven' },
		{ label: 'View last 30 days', value: 'thirty' },
		{ label: 'View month to date', value: 'month' },
		{ label: 'View year to date', value: 'year' },
		{ label: 'Custom', value: 'custom' },
	]

	useEffect(() => {
		if (transactions && transactions.length > 0 && !clientsLoaded) {
			const uniqueClients = new Set()
			const thirdPartyArray = [
				{
					label: 'All',
					value: '',
				},
			]
			for (const transaction of transactions) {
				const clientValue = transaction.client
				if (!uniqueClients.has(clientValue)) {
					uniqueClients.add(clientValue)
					thirdPartyArray.push({
						label: thirdPartyDict[clientValue]?.name,
						value: clientValue,
					})
				}
			}
			setClientSelections(thirdPartyArray)
			setClientsLoaded(true)
		}
	}, [transactions])

	const getTransactions = (page) => {
		if (selectedNode !== '') {
			let filter = {
				type: 'boxesOut',
				timestamp: { $gte: startDate, $lte: endDate },
				$or: [{ node: selectedNode }, { parentSlug: selectedNode }],
			}
			if (selectedClient !== '') {
				filter = {
					...filter,
					client: selectedClient,
				}
			}
			if (Object.keys(selectedCustomer).length > 0) {
				filter = {
					...filter,
					'user.phone': selectedCustomer.phone,
				}
			}

			onGetTransactions({
				filter: filter,
				options: {
					sort: {
						timestamp: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const transactionPaginate = () => {
		getTransactions(page)
	}

	useEffect(() => {
		transactionPaginate()
	}, [
		page,
		selectedNode,
		startDate,
		endDate,
		selectedCustomer,
		selectedClient,
	])

	useEffect(() => {
		if (user) {
			setSelectedNode(user.restaurantAdmin)
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [user])

	useEffect(() => {
		if (timeframe && timeframe !== 'custom') {
			const today = new Date()
			if (timeframe === 'all') {
				setStartDate(new Date(2022, 9, 1))
				setEndDate(today)
			} else if (timeframe === 'seven') {
				const sevenDaysAgo = new Date(today)
				sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
				setStartDate(sevenDaysAgo)
				setEndDate(today)
			} else if (timeframe === 'thirty') {
				const thirtyDaysAgo = new Date(today)
				thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
				setStartDate(thirtyDaysAgo)
				setEndDate(today)
			} else if (timeframe === 'month') {
				const monthToDate = new Date(today)
				monthToDate.setMonth(monthToDate.getMonth() - 1)
				setStartDate(monthToDate)
				setEndDate(today)
			} else if (timeframe === 'year') {
				const yearToDate = new Date(today)
				yearToDate.setFullYear(yearToDate.getFullYear() - 1)
				setStartDate(yearToDate)
				setEndDate(today)
			}
		}
	}, [timeframe])

	useEffect(() => {
		let usersArray = []
		for (const transaction of transactions) {
			if (transaction.node === node.slug) {
				usersArray.push({
					firstName: transaction.user
						? transaction.user.firstName
						: 'No customer provided',
					lastName: transaction.user
						? transaction.user.lastName
						: 'No customer provided',
				})
			}
		}
		setNodeCustomers(usersArray)
	}, [transactions])

	// useEffect(() => {
	//     const cliSelections = [
	//         {
	//             label: 'All',
	//             value: '',
	//         },
	//     ]
	//     clients.map((client) => {
	//         cliSelections.push({
	//             label: client.clientId,
	//             value: client.clientId,
	//         })
	//     })
	//     setClientSelections(cliSelections)
	// }, [clients])

	useEffect(() => {
		if (typedCustomerSearch !== '') {
			const closestMatches = nodeCustomers.filter(
				(txt) =>
					(txt.firstName + txt.lastName)
						.toLowerCase()
						.indexOf(typedCustomerSearch) !== -1
			)
			setCustomerSearchResults(closestMatches.slice(0, 5))
		} else {
			setCustomerSearchResults([])
		}
	}, [typedCustomerSearch])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/restaurant-admin/home')
					}}
				>
					&#8592; Go to Dashboard Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='flex flex-col max-w-[1200px] justify-center my-10 overflow-x-auto mx-auto'>
					<div className='mx-auto'>
						{node && (
							<h1 className='font-header text-4xl text-green-600 text-center'>
								{node.name}'s Orders
							</h1>
						)}

						{(loadingTransactions || loadingOrders) && (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
						{/*<SelectGroup*/}
						{/*    label='Filter by Client'*/}
						{/*    placeholder='Select...'*/}
						{/*    name='client'*/}
						{/*    options={*/}
						{/*        aggPlatform === ''*/}
						{/*            ? clientSelections*/}
						{/*            : [*/}
						{/*                {*/}
						{/*                    label: 'deliverzero',*/}
						{/*                    value: 'deliverzero',*/}
						{/*                },*/}
						{/*            ]*/}
						{/*    }*/}
						{/*    onChange={(e) => {*/}
						{/*        setPlatform(e.target.value)*/}
						{/*    }}*/}
						{/*/>*/}

						<div className='mt-6 font-bold'>
							Search orders by customer
						</div>
						<Input
							type='text'
							label=''
							name='item-search'
							placeholder='e.g. Bart Simpson'
							value={typedCustomerSearch}
							className='bg-white border-2 border-gray-300'
							onChange={(e) => {
								setTypedCustomerSearch(
									e.target.value.toLowerCase()
								)
							}}
						/>
						<div>
							{customerSearchResults.map((customer, i) => {
								return (
									<div
										key={i}
										className='flex flex-row my-1'
									>
										<div className='w-[350px] overflow-x-hidden'>
											{customer.firstName}
											{` `}
											{customer.lastName}
										</div>
										<Button
											className='items-center'
											size='xs'
											text='Select'
											onClick={() => {
												setSelectedCustomer(customer)
											}}
										/>
									</div>
								)
							})}
						</div>
						<Button
							text='Clear customer'
							size='xs'
							className='my-2'
							onClick={() => {
								setSelectedCustomer({})
								setTypedCustomerSearch('')
							}}
						/>
						<div className='mt-6 font-bold'>Traffic source</div>
						<SelectGroup
							placeholder='Filter by traffic source...'
							name='location'
							options={clientSelections}
							className='mb-2'
							onChange={(e) => {
								const selectedValue = e.target.value
								setSelectedClient(
									selectedValue ===
										'Filter by traffic source...'
										? ''
										: selectedValue
								)
							}}
						/>
						<div className='mt-6 font-bold'>Time period</div>
						<SelectGroup
							placeholder='Select time period...'
							name='location'
							options={timePeriods}
							className='mb-2'
							onChange={(e) => {
								const selectedValue = e.target.value
								setTimeframe(
									selectedValue === 'Select time period...'
										? ''
										: selectedValue
								)
							}}
						/>
						{timeframe && timeframe === 'custom' && (
							<>
								<div className='mt-4'>Select Start Date</div>
								<div className='mb-2 border border-gray-400'>
									<DatePicker
										selected={startDate}
										onChange={(date) => setStartDate(date)}
									/>
								</div>
								<div className='mt-4'>Select End Date</div>
								<div className='mb-2 border border-gray-400'>
									<DatePicker
										selected={endDate}
										onChange={(date) => {
											date.setHours(23, 59, 0)
											setEndDate(date)
										}}
									/>
								</div>
							</>
						)}

						{transactionsCount > 0 ? (
							<div className='mt-3 mb-1'>
								<span className='font-bold'>
									Number of total orders:
								</span>{' '}
								{transactionsCount}
							</div>
						) : (
							<div className='mx-auto px-10 my-6 shadow-light-grey w-[300px] h-[100px]'>
								<div className='flex flex-row w-full h-full'>
									<div className='w-ful`l text-green-600 mx-auto my-auto flex justify-center align-center'>
										No orders found
									</div>
								</div>
							</div>
						)}

						{transactions.length > 0 && (
							<div className='px-10 max-w-[1000px] mb-6 shadow-light-grey'>
								<div className='flex flex-row mb-2'>
									<div className='font-bold w-[200px]'>
										Date
									</div>
									<div className='font-bold w-[200px]'>
										Box Count
									</div>
									<div className='font-bold w-[200px]'>
										Customer
									</div>
									<div className='font-bold w-[200px]'>
										Traffic Source
									</div>
									{node && node.directOrdering && (
										<div className='font-bold w-[200px]'>
											Total to You
										</div>
									)}
									{node && node.parent && (
										<div className='font-bold w-[200px]'>
											Location
										</div>
									)}
									<div className='w-[200px]'></div>
								</div>
								{/* {loadingCustomers && (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)} */}
								{transactions.map((transaction, i) => {
									const transactionDate = new Date(
										transaction.timestamp
									)
									const transactionDateString =
										transactionDate.toLocaleString(
											'default',
											{
												month: 'short',
												day: 'numeric',
												year: 'numeric',
												hour: '2-digit',
												minute: '2-digit',
											}
										)
									const orderInfo = transaction.orderInfo
									let totalToRestaurant = 0
									if (orderInfo) {
										totalToRestaurant =
											orderInfo.subtotal *
												(1 -
													transaction.nodeInfo
														.commissionFee) +
											orderInfo.taxes +
											orderInfo.deliveryFee +
											orderInfo.tip
									}
									return (
										<div
											key={i}
											className='flex flex-row my-2 divide-y-2 divide-solid'
										>
											<div></div>
											<div className='w-[200px]'>
												{transactionDateString}
											</div>
											<div className='w-[200px] text-orange-600'>
												{transaction.boxCount} boxes
												given to user
											</div>
											<div className='w-[200px]'>
												{transaction.user
													? `${transaction.user.firstName} ${transaction.user.lastName}`
													: 'No customer provided'}
											</div>
											<div className='w-[200px]'>
												{
													thirdPartyDict[
														transaction.client
													]?.name
												}
											</div>
											{totalToRestaurant > 0 && (
												<div className='font-bold w-[100px]'>
													$
													{totalToRestaurant.toFixed(
														2
													)}
												</div>
											)}
											{node &&
												node.parent &&
												transaction.nodeInfo &&
												transaction.nodeInfo.name && (
													<div className='font-bold w-[200px]'>
														{
															transaction.nodeInfo
																.name
														}
													</div>
												)}
											<Button
												text='View Order'
												size='sm'
												className='w-[200px]'
												link={`/restaurant-admin/orders/${transaction._id}`}
											/>
										</div>
									)
								})}
								<div className='flex flex-row w-full'>
									{page > 1 && (
										<div
											className='flex justify-start underline font-bold mx-6 cursor-pointer w-full'
											onClick={() => {
												setPage(page - 1)
											}}
										>
											Previous {paginationLimit}{' '}
											transactions
										</div>
									)}
									{transactionsCount >
										page * paginationLimit && (
										<div
											className='flex justify-end underline mr-6 font-bold cursor-pointer w-full'
											onClick={() => {
												setPage(page + 1)
												setLoading(true)
											}}
										>
											Next {paginationLimit} transactions
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Transactions, Nodes, User, Orders, Admin }) => ({
	transactions: Transactions.nodeTransactions,
	transactionsCount: Transactions.nodeTransactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	node: Nodes.restaurantAdminNode,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	// customers: Admin.customers,
	// customersCount: Admin.customersCount,
	// loadingCustomers: Admin.loadingCustomers,
	loadingClients: Admin.loadingClients,
	clients: Admin.clients,
	loadingAggregators: Admin.loadingAggregators,
	aggregators: Admin.aggregators,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getNodeTransactions(payload)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	// onGetCustomers: (payload) => dispatch(getAdminCustomers(payload)),
	onGetClients: (payload) => dispatch(getAdminClients(payload)),
	onGetAggregators: (payload) => dispatch(getAdminAggregators(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantAdminOrders)
