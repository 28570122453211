import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { userRoutes, authRoutes } from './routes/allRoutes'
import PrivateRoute from './routes/middleware/PrivateRoute'
import PageNotFound from './pages/Utility/404'
import { MobileBottomNav } from './components'
import { getUser } from './store/actions'

function App(props) {
	const { onGetUser, user, loading, error } = props

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (!user) {
			onGetUser()
		}
	}, [user])

	return (
		<>
			<Routes className=''>
				{userRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={
							<PrivateRoute
								component={route.component}
								path={route.path}
							/>
						}
						key={idx}
					/>
				))}
				{authRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={route.component}
						key={idx}
					/>
				))}
				<Route
					path='*'
					element={<PageNotFound />}
				/>
			</Routes>
			<MobileBottomNav />
		</>
	)
}

const mapStateToProps = ({ User }) => ({
	User: User.user,
	loading: User.loading,
	error: User.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
