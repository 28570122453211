import {
	CREATE_STOP,
	CREATE_STOP_SUCCESS,
	CREATE_STOP_ERROR,
	UPDATE_STOP,
	UPDATE_STOP_SUCCESS,
	UPDATE_STOP_ERROR,
	DELETE_STOP,
	DELETE_STOP_SUCCESS,
	DELETE_STOP_ERROR,
	GET_ALL_LOGISTICS,
	GET_ALL_LOGISTICS_SUCCESS,
	GET_ALL_LOGISTICS_ERROR,
	RESET_SUCCESS,
	LOGISTICS_ALERT,
	LOGISTICS_ALERT_SUCCESS,
	LOGISTICS_ALERT_ERROR,
	GET_INCOMPLETE_STOPS,
	GET_INCOMPLETE_STOPS_ERROR,
	GET_INCOMPLETE_STOPS_SUCCESS,
	GET_INVENTORY,
	GET_INVENTORY_ERROR,
	GET_INVENTORY_SUCCESS,
	GET_NEW_RESTAURANT_STOPS,
	GET_NEW_RESTAURANT_STOPS_ERROR,
	GET_NEW_RESTAURANT_STOPS_SUCCESS,
	GET_LOGISTICS_DASHBOARD,
	GET_LOGISTICS_DASHBOARD_SUCCESS,
	GET_LOGISTICS_DASHBOARD_ERROR,
	GET_RETURN_BIN_BOX_COUNT,
	GET_RETURN_BIN_BOX_COUNT_ERROR,
	GET_RETURN_BIN_BOX_COUNT_SUCCESS,
	GET_UBER_DIRECT_QUOTE,
	GET_UBER_DIRECT_QUOTE_SUCCESS,
	GET_UBER_DIRECT_QUOTE_ERROR,
	CREATE_UBER_DIRECT_REQUEST,
	CREATE_UBER_DIRECT_REQUEST_SUCCESS,
	CREATE_UBER_DIRECT_REQUEST_ERROR,
	UPDATE_UBER_DIRECT,
	UPDATE_UBER_DIRECT_SUCCESS,
	UPDATE_UBER_DIRECT_ERROR,
	GET_ALL_UBER_DIRECT,
	GET_ALL_UBER_DIRECT_SUCCESS,
	GET_ALL_UBER_DIRECT_ERROR,
	CANCEL_UBER_DIRECT,
	CANCEL_UBER_DIRECT_SUCCESS,
	CANCEL_UBER_DIRECT_ERROR,
	GET_UBER_DIRECT_REQUEST_BY_ID,
	GET_UBER_DIRECT_REQUEST_BY_ID_SUCCESS,
	GET_UBER_DIRECT_REQUEST_BY_ID_ERROR,
	GET_UBER_DIRECT_QUOTE_DROPOFF,
	GET_UBER_DIRECT_QUOTE_DROPOFF_ERROR,
	GET_UBER_DIRECT_QUOTE_DROPOFF_SUCCESS,
	GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE,
	GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_ERROR,
	GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_SUCCESS,
	GET_UBER_DIRECT_REQUEST_DOC_BY_ID,
	GET_UBER_DIRECT_REQUEST_DOC_BY_ID_ERROR,
	GET_UBER_DIRECT_REQUEST_DOC_BY_ID_SUCCESS,
	GET_MARKET,
	GET_MARKET_SUCCESS,
	GET_MARKET_ERROR,
	ADJUST_INVENTORY,
	ADJUST_INVENTORY_SUCCESS,
	ADJUST_INVENTORY_ERROR,
	GET_MARKET_OBJ,
	GET_MARKET_OBJ_ERROR,
	GET_MARKET_OBJ_SUCCESS,
	CREATE_PACKING_LIST_TRANSACTIONS,
	CREATE_PACKING_LIST_TRANSACTIONS_ERROR,
	CREATE_PACKING_LIST_TRANSACTIONS_SUCCESS,
	GET_PROOF_OF_DELIVERY,
	GET_PROOF_OF_DELIVERY_ERROR,
	GET_PROOF_OF_DELIVERY_SUCCESS,
} from './actionTypes'

const initialState = {
	error: null,
	requestError: null,
	stops: [],
	loadingStops: false,
	stopsCount: null,
	success: false,
	loading: false,
	incompleteStops: [],
	inventory: {},
	logisticsDashboard: [],
	logisticsMonthly: [],
	logisticsVariables: [],
	newRestaurantStops: [],
	boxCount: null,
	uberRequests: [],
	uberRequest: null,
	uberRequestDoc: null,
	uberQuote: null,
	updatedRequest: null,
	cancelled: false,
	market: null,
	loadingMarket: false,
	marketError: null,
	availPartners: [],
	adjustedInventory: {},
	marketObj: {},
	transactions: null,
	proofOfDelivery: null,
}

const logistics = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_STOP:
		case UPDATE_STOP:
		case DELETE_STOP:
		case GET_ALL_LOGISTICS:
		case LOGISTICS_ALERT:
		case GET_INCOMPLETE_STOPS:
		case GET_NEW_RESTAURANT_STOPS:
			return {
				...state,
				loadingStops: true,
				success: false,
			}

		case CREATE_STOP_SUCCESS:
			if (state.stops) {
				return {
					...state,
					stops: [...state.stops, action.payload],
					loadingStops: false,
					success: true,
				}
			} else {
				return {
					...state,
					stops: [action.payload],
					loadingStops: false,
					success: true,
				}
			}

		case UPDATE_STOP_SUCCESS:
			const updatedStops = state.stops.map((stop) =>
				stop.stopId === action.payload.stopId ? action.payload : stop
			)
			return {
				...state,
				stops: updatedStops,
				loadingStops: false,
				success: true,
			}

		case DELETE_STOP_SUCCESS:
			const remainingStops = state.stops.filter(
				(stop) => stop.stopId !== action.payload.stopId
			)
			return {
				...state,
				stops: remainingStops,
				loadingStops: false,
				success: true,
			}

		case GET_ALL_LOGISTICS_SUCCESS:
			const allStops = action.payload
			return {
				...state,
				stops: allStops.logistics,
				stopsCount: allStops.totalCount,
				loadingStops: false,
			}

		case GET_INCOMPLETE_STOPS_SUCCESS:
			const incompleteStops = action.payload
			return {
				...state,
				incompleteStops: incompleteStops,
				loadingStops: false,
			}
		case GET_NEW_RESTAURANT_STOPS_SUCCESS:
			const newRestaurants = action.payload
			return {
				...state,
				newRestaurantStops: newRestaurants,
				loadingStops: false,
			}

		case CREATE_STOP_ERROR:
		case UPDATE_STOP_ERROR:
		case DELETE_STOP_ERROR:
		case GET_ALL_LOGISTICS_ERROR:
		case LOGISTICS_ALERT_ERROR:
		case GET_INCOMPLETE_STOPS_ERROR:
		case GET_NEW_RESTAURANT_STOPS_ERROR:
			return {
				...state,
				error: action.payload,
				newRestaurantStops: [],
				stops: [],
				loadingStops: false,
				success: false,
				stopsCount: null,
				incompleteStops: [],
			}

		case LOGISTICS_ALERT_SUCCESS:
			return {
				...state,
				loadingStops: false,
				success: false,
				pickupSuccess: false,
				pickupLoading: false,
				loading: false,
				error: false,
				pickupError: false,
				boxCount: null,
			}

		case GET_INVENTORY:
		case GET_LOGISTICS_DASHBOARD:
		case GET_RETURN_BIN_BOX_COUNT:
			return {
				...state,
				loading: true,
				error: null,
				boxCount: null,
			}

		case GET_INVENTORY_SUCCESS:
			const inventory = action.payload
			return {
				...state,
				loading: false,
				inventory: inventory,
				error: null,
			}

		case GET_LOGISTICS_DASHBOARD_SUCCESS:
			const logisticsDashboardData = action.payload
			return {
				...state,
				loading: false,
				logisticsDashboard: logisticsDashboardData.sheetValues,
				logisticsMonthly: logisticsDashboardData.groupedData,
				logisticsVariables: logisticsDashboardData.uniqueVariables,
				error: null,
			}

		case GET_RETURN_BIN_BOX_COUNT_SUCCESS:
			const boxCount = action.payload
			return {
				...state,
				loading: false,
				boxCount: boxCount,
				error: null,
			}

		case GET_INVENTORY_ERROR:
		case GET_LOGISTICS_DASHBOARD_ERROR:
		case GET_RETURN_BIN_BOX_COUNT_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
				inventory: {},
				logisticsDashboard: [],
				logisticsMonthly: [],
				boxCount: null,
			}

		case GET_INVENTORY_ERROR:
		case GET_LOGISTICS_DASHBOARD_ERROR:
		case GET_RETURN_BIN_BOX_COUNT_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
				inventory: {},
				logisticsDashboard: [],
				logisticsMonthly: [],
				boxCount: null,
			}

		case GET_UBER_DIRECT_QUOTE:
		case GET_UBER_DIRECT_QUOTE_DROPOFF:
		case GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE:
		case CREATE_UBER_DIRECT_REQUEST:
		case GET_ALL_UBER_DIRECT:
		case GET_UBER_DIRECT_REQUEST_BY_ID:
			return {
				...state,
				loading: true,
				uberRequest: null,
				uberRequests: [],
				uberQuote: null,
				error: null,
				requestError: null,
			}

		case GET_UBER_DIRECT_QUOTE_SUCCESS:
		case GET_UBER_DIRECT_QUOTE_DROPOFF_SUCCESS:
		case GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_SUCCESS:
			let uberQuote = action.payload
			if (typeof uberQuote === 'string' && uberQuote.includes('error')) {
				uberQuote = JSON.parse(action.payload)
			}
			return {
				...state,
				uberQuote: uberQuote,
				loading: false,
			}

		case CREATE_UBER_DIRECT_REQUEST_SUCCESS:
		case GET_UBER_DIRECT_REQUEST_BY_ID_SUCCESS:
			return {
				...state,
				uberRequest: action.payload,
				loading: false,
			}

		case GET_ALL_UBER_DIRECT_SUCCESS:
			return {
				...state,
				uberRequests: action.payload,
				loading: false,
			}

		case GET_UBER_DIRECT_QUOTE_ERROR:
		case GET_UBER_DIRECT_QUOTE_DROPOFF_ERROR:
		case GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_ERROR:
			return {
				...state,
				loading: false,
				uberRequest: null,
				uberRequests: [],
				uberQuote: null,
				error: action.payload,
			}
		case CREATE_UBER_DIRECT_REQUEST_ERROR:
		case GET_ALL_UBER_DIRECT_ERROR:
		case GET_UBER_DIRECT_REQUEST_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				uberRequest: null,
				uberRequests: [],
				uberQuote: null,
				requestError: action.payload,
			}

		case UPDATE_UBER_DIRECT:
			return {
				...state,
				loading: true,
				updatedRequest: null,
				error: null,
			}

		case UPDATE_UBER_DIRECT_SUCCESS:
			return {
				...state,
				updatedRequest: action.payload,
				loading: false,
			}

		case UPDATE_UBER_DIRECT_ERROR:
			return {
				...state,
				loading: false,
				updatedRequest: null,
				error: action.payload,
			}

		case CANCEL_UBER_DIRECT:
			return {
				...state,
				loading: true,
				updatedRequest: null,
				cancelled: false,
				error: null,
			}

		case CANCEL_UBER_DIRECT_SUCCESS:
			return {
				...state,
				updatedRequest: action.payload,
				cancelled: true,
				loading: false,
			}

		case CANCEL_UBER_DIRECT_ERROR:
			return {
				...state,
				loading: false,
				updatedRequest: null,
				cancelled: false,
				error: action.payload,
			}

		case GET_UBER_DIRECT_REQUEST_DOC_BY_ID:
			return {
				...state,
				loading: true,
				uberRequestDoc: null,
				error: null,
			}

		case GET_UBER_DIRECT_REQUEST_DOC_BY_ID_SUCCESS:
			return {
				...state,
				uberRequestDoc: action.payload,
				loading: false,
			}

		case GET_UBER_DIRECT_REQUEST_DOC_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				uberRequestDoc: null,
				error: action.payload,
			}

		case RESET_SUCCESS:
			return {
				adjustedInventory: {},
				boxCount: null,
				cancelled: false,
				error: null,
				loading: false,
				loadingStops: false,
				marketObj: {},
				success: false,
				transactions: null,
				uberQuote: null,
				uberRequest: null,
				uberRequests: [],
				updatedRequest: null,
				cancelled: false,
				proofOfDelivery: null,
				requestError: null,
			}

		case GET_MARKET:
			return {
				...state,
				loadingMarket: true,
				market: null,
				marketZips: [],
				availPartners: [],
			}

		case GET_MARKET_SUCCESS:
			const result = action.payload
			return {
				...state,
				loadingMarket: false,
				market: result.market,
				marketError: null,
				availPartners: result.availPartners,
			}

		case GET_MARKET_ERROR:
			return {
				...state,
				loadingMarket: false,
				marketError: action.payload,
				market: null,
				marketZips: [],
				availPartners: [],
			}

		case ADJUST_INVENTORY:
			return {
				...state,
				loading: true,
				error: false,
				adjustedInventory: {},
			}

		case ADJUST_INVENTORY_SUCCESS:
			const values = action.payload
			return {
				...state,
				loading: false,
				adjustedInventory: values,
				error: null,
			}

		case ADJUST_INVENTORY_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
				adjustedInventory: {},
			}

		case GET_MARKET_OBJ:
			return {
				...state,
				loading: true,
				error: false,
				marketObj: {},
			}

		case GET_MARKET_OBJ_SUCCESS:
			const marketObj = action.payload
			return {
				...state,
				loading: false,
				marketObj: marketObj,
				error: null,
			}

		case GET_MARKET_OBJ_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
				marketObj: {},
			}

		case CREATE_PACKING_LIST_TRANSACTIONS:
			return {
				...state,
				loading: true,
				error: false,
				transactions: null,
			}

		case CREATE_PACKING_LIST_TRANSACTIONS_SUCCESS:
			const transactions = action.payload
			return {
				...state,
				loading: false,
				transactions: transactions,
				error: null,
			}

		case CREATE_PACKING_LIST_TRANSACTIONS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
				transactions: null,
			}

		case GET_PROOF_OF_DELIVERY:
			return {
				...state,
				loading: false,
				proofOfDelivery: null,
				error: null,
			}

		case GET_PROOF_OF_DELIVERY_SUCCESS:
			const proof = action.payload
			return {
				...state,
				loading: false,
				proofOfDelivery: proof,
				error: null,
			}

		case GET_PROOF_OF_DELIVERY_ERROR:
			return {
				...state,
				loading: false,
				proofOfDelivery: action.payload,
				error: null,
			}

		default:
			return state
	}
}

export default logistics
