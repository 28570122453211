import React from 'react'
import { Header, LoginCard, RegisterCard } from '../../components'

const ReturnBoxesLogin = () => {
	return (
		<div className='h-screen w-full overflow-hidden'>
			<Header />
			<div className='h-full w-full flex flex-col'>
				<div className=' h-full w-full flex flex-col items-center bg-yellow-600 pt-6 '>
					<h1 className='text-3xl md:text-5xl font-header text-center text-green-600 w-full mb-4 mt-12 md:mb-8'>
						Return Containers
					</h1>
					<div className='w-full flex items-center justify-around'>
						<RegisterCard className='' />
						<LoginCard className='' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default ReturnBoxesLogin
