export const CREATE_PENDING_PICKUP = 'CREATE_PENDING_PICKUP'
export const CREATE_PENDING_PICKUP_SUCCESS = 'CREATE_PENDING_PICKUP_SUCCESS'
export const CREATE_PENDING_PICKUP_ERROR = 'CREATE_PENDING_PICKUP_ERROR'

export const GET_PENDING_PICKUPS = 'GET_PENDING_PICKUPS'
export const GET_PENDING_PICKUPS_SUCCESS = 'GET_PENDING_PICKUPS_SUCCESS'
export const GET_PENDING_PICKUPS_ERROR = 'GET_PENDING_PICKUPS_ERROR'

export const GET_ALL_PENDING_PICKUPS = 'GET_ALL_PENDING_PICKUPS'
export const GET_ALL_PENDING_PICKUPS_SUCCESS = 'GET_ALL_PENDING_PICKUPS_SUCCESS'
export const GET_ALL_PENDING_PICKUPS_ERROR = 'GET_ALL_PENDING_PICKUPS_ERROR'

export const GET_PICKUPS_BY_PHONE = 'GET_PICKUPS_BY_PHONE'
export const GET_PICKUPS_BY_PHONE_SUCCESS = 'GET_PICKUPS_BY_PHONE_SUCCESS'
export const GET_PICKUPS_BY_PHONE_ERROR = 'GET_PICKUPS_BY_PHONE_ERROR'

export const GET_PICKUP_BY_ID = 'GET_PICKUP_BY_ID'
export const GET_PICKUP_BY_ID_SUCCESS = 'GET_PICKUP_BY_ID_SUCCESS'
export const GET_PICKUP_BY_ID_ERROR = 'GET_PICKUP_BY_ID_ERROR'

export const RESET_PICKUPS = 'RESET_PICKUPS'

export const CREATE_GENTLY_PICKUP = 'CREATE_GENTLY_PICKUP'
export const CREATE_GENTLY_PICKUP_SUCCESS = 'CREATE_GENTLY_PICKUP_SUCCESS'
export const CREATE_GENTLY_PICKUP_ERROR = 'CREATE_GENTLY_PICKUP_ERROR'

export const CREATE_GENTLY_PICKUP_NO_NODE = 'CREATE_GENTLY_PICKUP_NO_NODE'
export const CREATE_GENTLY_PICKUP_NO_NODE_SUCCESS =
	'CREATE_GENTLY_PICKUP_NO_NODE_SUCCESS'
export const CREATE_GENTLY_PICKUP_NO_NODE_ERROR =
	'CREATE_GENTLY_PICKUP_NO_NODE_ERROR'
