import {
	GET_ORDERS,
	GET_ONE_ORDER,
	GET_ORDERS_SUCCESS,
	GET_ONE_ORDER_SUCCESS,
	GET_ORDERS_FAIL,
	GET_ONE_ORDER_FAIL,
	SUBMIT_ORDER_FAIL,
	SUBMIT_ORDER_SUCCESS,
	SUBMIT_ORDER,
	ADMIN_GET_ONE_ORDER,
	RESET_SUBMIT_ORDER,
} from './actionTypes'

export const getOrders = (payload) => ({
	type: GET_ORDERS,
	payload: payload,
})

export const getOneOrder = (payload) => ({
	type: GET_ONE_ORDER,
	payload: payload,
})

export const getOrdersSuccess = (payload) => ({
	type: GET_ORDERS_SUCCESS,
	payload: payload,
})

export const getOneOrderSuccess = (payload) => ({
	type: GET_ONE_ORDER_SUCCESS,
	payload: payload,
})

export const getOneOrderFail = (payload) => ({
	type: GET_ONE_ORDER_FAIL,
	payload: payload,
})

export const getOrdersFail = (payload) => ({
	type: GET_ORDERS_FAIL,
	payload: payload,
})

export const submitOrder = (payload) => ({
	type: SUBMIT_ORDER,
	payload: payload,
})

export const submitOrderSuccess = (payload) => ({
	type: SUBMIT_ORDER_SUCCESS,
	payload: payload,
})

export const submitOrderFail = (payload) => ({
	type: SUBMIT_ORDER_FAIL,
	payload: payload,
})

export const adminGetOneOrder = (payload) => ({
	type: ADMIN_GET_ONE_ORDER,
	payload: payload,
})

export const resetSubmitOrder = () => ({
	type: RESET_SUBMIT_ORDER,
})
