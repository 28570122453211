import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	updateNode,
	createTransaction,
	getAdminPlatforms,
	getAdminNodes,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'

const AdminRestaurants = (state) => {
	const {
		user,
		onGetUser,
		onGetNodes,
		nodes,
		nodeCount,
		updatingNode,
		loadingNodes,
		onCreateTransaction,
		transactionResult,
		onUpdateNode,
		updateNodeCompleted,
		platforms,
		onGetPlatforms,
		onGetAdminNodes,
	} = state

	const [loadedNodes, setLoadedNodes] = useState([])
	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [showUpdateBalance, setShowUpdateBalance] = useState('')
	const [newBalance, setNewBalance] = useState(0)
	const [noteForBalance, setNoteForBalance] = useState('')
	const [balanceError, setBalanceError] = useState(false)
	const [enabledFilter, setEnabledFilter] = useState({})
	const [franchiseeFilter, setFranchiseeFilter] = useState({
		franchiseeSlug: {
			$exists: false,
		},
	})
	const [returnBinFilter, setReturnBinFilter] = useState({
		type: {
			$nin: [NodeTypes.none],
		},
		returnBinEnabled: {
			$nin: [NodeTypes.none],
		},
	})
	const [platformFilter, setPlatformFilter] = useState([])
	const [paymentFilter, setPaymentFilter] = useState({})
	const [locationFilter, setLocationFilter] = useState({})
	const [dropdownDisplay, setDropdownDisplay] = useState(false)
	const [buttonText, setButtonText] = useState('Show Filters')
	const [filtersClassName, setFiltersClassName] = useState(
		'flex flex-col md:flex-row hidden'
	)
	const [initialLoad, setInitialLoad] = useState(true)
	const [page, setPage] = useState(1)

	useEffect(() => {
		onGetUser()
		onGetPlatforms()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			const savedFilters = JSON.parse(
				sessionStorage.getItem('adminRestaurants')
			)
			if (savedFilters) {
				handleFilterDisplay()
				setFilter(savedFilters.filter || {})
				setEnabledFilter(savedFilters.enabledFilter || {})
				setFranchiseeFilter(savedFilters.franchiseeFilter || {})
				setReturnBinFilter(savedFilters.returnBinFilter || {})
				setLocationFilter(savedFilters.locationFilter || {})
				setPlatformFilter(savedFilters.platformFilter || {})
				setPaymentFilter(savedFilters.paymentFilter || {})
				const allFilters = [
					savedFilters.filter ? savedFilters.filter : {},
					savedFilters.enabledFilter
						? savedFilters.enabledFilter
						: {},
					savedFilters.franchiseeFilter
						? savedFilters.franchiseeFilter
						: {},
					savedFilters.returnBinFilter
						? savedFilters.returnBinFilter
						: {},
					savedFilters.locationFilter
						? savedFilters.locationFilter
						: {},
					savedFilters.paymentFilter
						? savedFilters.paymentFilter
						: {},
				]
				let nonEmptyFilters = allFilters.filter(
					(filter) => Object.keys(filter).length > 0
				)
				if (user.franchiseeSlug) {
					setFranchiseeFilter({})
					nonEmptyFilters.push({
						franchiseeSlug: user.franchiseeSlug,
					})
				}
				const filterObj = {
					filters: nonEmptyFilters,
					page: page,
					limit: paginationLimit,
				}
				if (
					savedFilters.platformFilter &&
					savedFilters.platformFilter.length > 0
				) {
					filterObj.platformFilter = platformFilter
				}
				onGetAdminNodes(filterObj)
				setInitialLoad(false)
			} else {
				if (user.franchiseeSlug) {
					setFranchiseeFilter({})
					onGetAdminNodes({
						filters: [
							{
								franchiseeSlug: user.franchiseeSlug,
							},
						],
						page: page,
						limit: paginationLimit,
					})
				} else {
					onGetAdminNodes({
						page: page,
						limit: paginationLimit,
					})
				}
				setInitialLoad(false)
			}
		} else {
			onGetUser()
		}
	}, [onGetAdminNodes, user])

	useEffect(() => {
		const validNegativeIntegerRegex = /^-?\d+$/
		if (newBalance === '' || !validNegativeIntegerRegex.test(newBalance)) {
			setBalanceError(true)
			setNewBalance(0)
		} else {
			const numericBalance = parseInt(newBalance, 10)
			if (numericBalance === 0) {
				setBalanceError(true)
				setNewBalance(0)
			} else {
				setBalanceError(false)
			}
		}
	}, [newBalance])

	useEffect(() => {
		if (initialLoad) {
			return
		}
		if (user) {
			let allFilters = [
				filter,
				enabledFilter,
				franchiseeFilter,
				returnBinFilter,
				locationFilter,
				paymentFilter,
			]
			if (user.franchiseeSlug) {
				allFilters.push({
					franchiseeSlug: user.franchiseeSlug,
				})
			}
			const nonEmptyFilters = allFilters.filter(
				(filter) => Object.keys(filter).length > 0
			)
			const filterObj = {
				filters: nonEmptyFilters,
				page: page,
				limit: paginationLimit,
			}
			if (platformFilter && platformFilter.length > 0) {
				filterObj.platformFilter = platformFilter
			}
			onGetAdminNodes(filterObj)
			const savedFilters = {
				filter,
				enabledFilter,
				franchiseeFilter,
				returnBinFilter,
				locationFilter,
				platformFilter,
				paymentFilter,
			}
			sessionStorage.setItem(
				'adminRestaurants',
				JSON.stringify(savedFilters)
			)
		} else {
			onGetUser()
		}
	}, [
		filter,
		enabledFilter,
		franchiseeFilter,
		returnBinFilter,
		locationFilter,
		platformFilter,
		paymentFilter,
		updateNodeCompleted,
		transactionResult,
		page,
	])

	useEffect(() => {
		if (nodes && nodes.length < 1) {
			setPage(1)
		} else if (nodes) {
			nodes.sort((a, b) => (a.balance > b.balance ? 1 : -1))
			setLoadedNodes(nodes)
		}
	}, [nodes])

	const handleNodeUpdate = (nodeSlug, update) => {
		onUpdateNode({
			nodeSlug: nodeSlug,
			update: update,
		})
	}

	const handleUpdateBalance = (nodeSlug, update, note) => {
		onCreateTransaction({
			node: nodeSlug,
			type: 'adminBalanceUpdate',
			user: {
				phone: user.phone,
				id: user.id,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
			},
			boxCount: update.balance,
			timestamp: new Date(),
			note: note,
		})
		setShowUpdateBalance('')
	}

	const handleCheckboxChange = (event, platform) => {
		const isChecked = event.target.checked
		setPlatformFilter((prevPlatformFilter) => {
			if (isChecked) {
				if (prevPlatformFilter && prevPlatformFilter.length > 0) {
					if (!prevPlatformFilter.includes(platform)) {
						return [...prevPlatformFilter, platform]
					} else {
						return [...prevPlatformFilter]
					}
				} else {
					return [platform]
				}
			} else {
				let updatedFilter = [...prevPlatformFilter]
				const filteredArray = updatedFilter.filter((prevPlatform) => {
					const shouldInclude = prevPlatform !== platform
					return shouldInclude
				})

				return filteredArray
			}
		})
	}

	const handleFilterDisplay = () => {
		const currentDisplay = dropdownDisplay
		setDropdownDisplay(!currentDisplay)
		setButtonText(currentDisplay === true ? 'Show filters' : 'Hide filters')
		setFiltersClassName(
			currentDisplay === true
				? 'flex flex-col md:flex-row hidden'
				: 'flex flex-col md:flex-row'
		)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div className='px-2'>
					{transactionResult && (
						<p className='text-xs text-center'>{`Update transaction _id: ${transactionResult.insertedId} inserted!`}</p>
					)}
					<div className='flex container flex-col justify-center items-center text-center w-full mb-4 mx-auto'>
						<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
							Restaurant Dashboard
						</h1>
						<div className='flex flex-col mx-4 md:mx-0 md:flex-row m-auto justify-center mb-8'>
							<Button
								link='/admin/restaurants/add'
								text='Add New Restaurant'
								size='sm'
								className=''
							/>
							{!user.franchiseeSlug && (
								<Button
									link='/admin/dropoff'
									text='Restaurant Dropoffs'
									size='sm'
									className='my-2 md:my-0 md:ml-2'
								/>
							)}
							<Button
								link='/admin/restaurants/charges'
								text='Charge Restaurants'
								size='sm'
								className='my-2 md:my-0 md:ml-2'
							/>
							<Button
								link='/admin/restaurants/payments'
								text='Pay Restaurants'
								size='sm'
								className='my-2 md:my-0 md:ml-2'
							/>
							{!user.franchiseeSlug && (
								<Button
									link='/admin/restaurants/manual'
									text='Manual Entry'
									size='sm'
									className='my-2 md:my-0 md:ml-2'
								/>
							)}
						</div>

						<div className='w-4/5 max-w-[600px] mb-4'>
							<Input
								placeholder='Search restaurants ...'
								className=''
								name='searchInput'
								id='searchInput'
								value={filter?.['$or']?.[0]?.name?.['$regex']}
								onChange={(e) => {
									setFilter({
										$or: [
											{
												name: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
											{
												slug: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
										],
									})
								}}
							/>
						</div>
						<div className=''>
							<Button
								color='green'
								size='sm'
								text={buttonText}
								className='mb-3 w-[180px]'
								submit={true}
								onClick={handleFilterDisplay}
							/>
							<div className={filtersClassName}>
								<div className='flex flex-col min-w-min sm:w-[300px] md:w-auto mx-5 items-center mb-2'>
									<CheckboxGroup
										label='Show only enabled restaurants'
										className='mb-1'
										checked={enabledFilter.enabled}
										onChange={(check) => {
											if (check.target.checked) {
												setEnabledFilter({
													enabled: {
														$nin: [false],
													},
												})
											} else {
												setEnabledFilter({})
											}
										}}
									/>
									{!user.franchiseeSlug && (
										<CheckboxGroup
											label='Show franchisee restaurants'
											className='mb-1'
											checked={
												!franchiseeFilter.franchiseeSlug
											}
											onChange={(check) => {
												if (check.target.checked) {
													setFranchiseeFilter({})
												} else {
													setFranchiseeFilter({
														franchiseeSlug: {
															$exists: false,
														},
													})
												}
											}}
										/>
									)}
									<RadioGroup
										horizontal={false}
										labelClassName='font-semibold mt-5 mb-0 underline'
										name='bin-filter'
										value={returnBinFilter}
										label='Filter by type:'
										radios={[
											{
												value: 'ALL',
												label: 'All',
												checked:
													!returnBinFilter.type ||
													(!returnBinFilter.type
														.$in &&
														!returnBinFilter.type.$nin.includes(
															'bin'
														)),
											},
											{
												value: 'BIN',
												label: 'Return bins only',
												checked:
													returnBinFilter.type &&
													returnBinFilter.type.$in &&
													returnBinFilter.type.$in.includes(
														'bin'
													),
											},
											{
												value: 'REST',
												label: 'Restaurants only',
												checked:
													returnBinFilter.type &&
													returnBinFilter.type.$nin &&
													returnBinFilter.type.$nin.includes(
														'bin'
													),
											},
										]}
										onChange={(selected) => {
											if (selected === 'BIN') {
												setReturnBinFilter({
													type: {
														$in: ['bin'],
													},
													// returnBinEnabled: {
													// 	$in: [false, true],
													// },
												})
											} else if (selected === 'REST') {
												setReturnBinFilter({
													type: {
														$nin: ['bin'],
													},
													// returnBinEnabled: {
													// 	$nin: [NodeTypes.none],
													// },
												})
											} else {
												setReturnBinFilter({
													type: {
														$nin: [NodeTypes.none],
													},
													// returnBinEnabled: {
													// 	$nin: [NodeTypes.none],
													// },
												})
											}
										}}
									/>
								</div>
								{!user.franchiseeSlug && (
									<div className='flex min-w-min sm:w-[300px] md:w-auto mx-5 items-center mb-2'>
										<RadioGroup
											horizontal={false}
											labelClassName='font-semibold mb-1 underline'
											name='restaurant-filter'
											value={locationFilter}
											label='Filter by location:'
											radios={[
												{
													value: 'ALL',
													label: 'All',
													checked:
														!locationFilter.state,
												},
												{
													value: 'CO',
													label: 'Colorado',
													checked:
														locationFilter.state &&
														locationFilter.state.$in.includes(
															'CO'
														),
												},
												{
													value: 'NY',
													label: 'New York',
													checked:
														(locationFilter.state &&
															locationFilter.state.$in.includes(
																'NY'
															)) ||
														(locationFilter.state &&
															locationFilter.state.$in.includes(
																'NJ'
															)),
												},
												{
													value: 'CA',
													label: 'California',
													checked:
														locationFilter.state &&
														locationFilter.state.$in.includes(
															'CA'
														),
												},
												{
													value: 'NC',
													label: 'North Carolina',
													checked:
														locationFilter.state &&
														locationFilter.state.$in.includes(
															'NC'
														),
												},
											]}
											onChange={(state) => {
												if (state !== 'ALL') {
													setLocationFilter({
														state: {
															$in: [state],
														},
													})
												} else {
													setLocationFilter({})
												}
											}}
										/>
									</div>
								)}
								<div className='flex min-w-min sm:w-[300px] md:w-auto mx-5 items-center mb-2'>
									<RadioGroup
										horizontal={false}
										labelClassName='font-semibold mb-1 underline'
										name='payment-filter'
										value={paymentFilter}
										label='Filter by payment on file:'
										radios={[
											{
												value: 'ALL',
												label: 'All',
												checked:
													!paymentFilter.paymentMethods,
											},
											{
												value: 'NO',
												label: 'No',
												checked:
													paymentFilter.paymentMethods &&
													paymentFilter.paymentMethods
														.$exists === false,
											},
											{
												value: 'YES',
												label: 'Yes',
												checked: paymentFilter.$or,
											},
										]}
										onChange={(selected) => {
											if (selected === 'YES') {
												setPaymentFilter({
													$or: [
														{
															paymentMethods: {
																$nin: [''],
																$exists: true,
															},
														},
														{
															defaultPaymentMethod:
																{
																	$nin: [''],
																	$exists: true,
																},
														},
													],
												})
											} else {
												if (selected === 'ALL') {
													setPaymentFilter({})
												} else {
													setPaymentFilter({
														paymentMethods: {
															$exists: false,
														},
														defaultPaymentMethod: {
															$exists: false,
														},
													})
												}
											}
										}}
									/>
								</div>
								<div className='flex min-w-min sm:w-[300px] md:w-auto mx-5 items-center mb-2'>
									<div className='ml-2 text-sm'>
										<div className='font-semibold mb-1 underline'>
											Filter by platform:
										</div>
										{platforms.map((platform) => {
											let isChecked = false
											if (
												platformFilter &&
												platformFilter.length > 0
											) {
												isChecked =
													platformFilter.includes(
														platform
													)
											}
											return (
												<CheckboxGroup
													key={platform}
													label={platform}
													className='mb-1'
													checked={isChecked}
													onChange={(event) =>
														handleCheckboxChange(
															event,
															platform
														)
													}
												/>
											)
										})}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='max-w-[1384px] max-h-[600px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col mx-[2px] items-center sm:mx-auto rounded-xl pb-2 mt-6 mb-8 scrollbar bg-white bg-opacity-100'>
						<div className='flex flex-row sticky top-0 bg-white g-opacity-100 min-w-[750px] z-10'>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[84px] w-[84px] sm:w-[128px] lg:w-[192px] z-50 sticky bg-white left-0 text-xs sm:text-base'>
								Restaurant Name
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[128px] w-[280px] sm:w-[128px] md:w-[128px] lg:w-[192px] sticky bg-white text-xs sm:text-base ml-[180px] pl-[180px] sm:ml-4 sm:pl-0'>
								Inventory Balance
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[128px] w-[128px] lg:w-[192px] sticky bg-white text-xs sm:text-base'>
								Store Status
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px] sticky bg-white text-xs sm:text-base'>
								Store Admin Code
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px] sticky bg-white text-xs sm:text-base'>
								Payment on File?
							</div>
							<div className='mb-1 pt-1 font-header text-center min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px] sticky bg-white text-xs sm:text-base'>
								Platforms
							</div>
						</div>
						{nodes.length > 0 ? (
							<div className=''>
								{loadedNodes.map((node, index) => {
									return (
										<div
											className='min-w-[650px] py-0 whitespace-nowrap bg-white bg-opacity-100 h-full even:bg-gray-100 my-0'
											key={index}
										>
											<div className='text-sm flex flex-row items-center my-0 justify-start min-h-[50px] my-0 py-0'>
												<div
													className='font-bold underline text-center cursor-pointer sticky left-0 overflow-hidden whitespace-normal min-h-[80px] my-0 p-0 bg-green-100 mr-4 min-w-[84px] w-[84px] sm:w-[128px] lg:w-[192px] text-xs sm:text-base'
													onClick={() => {
														const url = `/admin/restaurants/${node.slug}`
														navigate(url)
													}}
												>
													<div className='h-full p-0 m-0 bg-green-100'>
														{node.name}
														{node.type === 'bin' ? (
															<div className='text-xs text-green-600'>
																RETURN BIN ONLY
															</div>
														) : (
															<div></div>
														)}
													</div>
												</div>
												<div className='my-1 text-center mr-4 min-w-[128px] w-[280px] sm:w-[128px] md:w-[128px] lg:w-[192px] flex flex-col items-center ml-[180px] pl-[180px] sm:ml-4 sm:pl-0'>
													{node.balance < 50 && (
														<div className='font-bold text-red-400 mr-2'>
															<div>
																{node.balance}{' '}
																LOW!
															</div>
														</div>
													)}
													{node.balance >= 50 &&
														node.balance <
															node.maxBalance -
																30 && (
															<div className='font-body mr-2'>
																<div>
																	{
																		node.balance
																	}
																</div>
															</div>
														)}
													{node.balance >
														node.maxBalance -
															30 && (
														<div className='font-bold text-red-400 mr-2'>
															<div>
																{node.balance}{' '}
																HIGH!
															</div>
														</div>
													)}
													{showUpdateBalance ===
													node.slug ? (
														<Button
															className='w-min'
															size='xs'
															color='red'
															text='CLOSE'
															onClick={() => {
																setShowUpdateBalance(
																	''
																)
															}}
														/>
													) : (
														<Button
															className='w-min'
															size='xs'
															text='UPDATE'
															onClick={() => {
																setShowUpdateBalance(
																	node.slug
																)
															}}
														/>
													)}
												</div>
												<div className='my-1 text-center mr-4 min-w-[128px] w-[128px] lg:w-[192px] flex flex-col items-center'>
													{node.enabled ? (
														<div>
															<div className='font-bold text-green-400'>
																<div>
																	Enabled
																</div>
															</div>
															{updatingNode ||
															loadingNodes ? (
																<div className='ml-4'>
																	Loading...
																</div>
															) : (
																<Button
																	className='w-min'
																	color='red'
																	size='xs'
																	text='DISABLE'
																	onClick={() => {
																		handleNodeUpdate(
																			node.slug,
																			{
																				enabled: false,
																			}
																		)
																	}}
																/>
															)}
														</div>
													) : (
														<div>
															<div className='font-bold text-red-400'>
																<div>
																	Disabled
																</div>
															</div>
															{updatingNode ||
															loadingNodes ? (
																<div className='ml-4'>
																	Loading...
																</div>
															) : (
																<Button
																	className='w-min'
																	size='xs'
																	text='ENABLE'
																	onClick={() => {
																		handleNodeUpdate(
																			node.slug,
																			{
																				enabled: true,
																			}
																		)
																	}}
																/>
															)}
														</div>
													)}
												</div>
												<div className='my-1 text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px]'>
													{node.adminPinCode}
												</div>
												<div className='my-1 text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px]'>
													{(node.paymentMethods &&
														node.paymentMethods
															.length > 0) ||
													(node.defaultPaymentMethod &&
														node.defaultPaymentMethod !==
															'') ? (
														<div className='text-green-600'>
															YES
														</div>
													) : (
														<div className='text-red-400'>
															NO
														</div>
													)}
												</div>
												<div className='my-1 text-center min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px]'>
													{node.platforms &&
													node.platforms.length >
														0 ? (
														<div>
															{node.platforms.map(
																(
																	platform,
																	index
																) => (
																	<div
																		key={
																			index
																		}
																	>
																		{
																			platform
																		}
																	</div>
																)
															)}
														</div>
													) : (
														<div>-</div>
													)}
												</div>
												{showUpdateBalance ===
													node.slug && (
													<div className='flex flex-col items-center w-full'>
														<div className=' flex mt-6 w-3/5 justify-around'>
															<div>
																<Input
																	type='number'
																	label='Add/Subtract inventory'
																	name='balance'
																	className='bg-white border-2 border-gray-300 w-full'
																	onChange={(
																		e
																	) => {
																		setNewBalance(
																			e
																				.target
																				.value
																		)
																	}}
																/>
																<div className='text-xs'>
																	enter a
																	negative to
																	subtract
																</div>
															</div>
															<Input
																type='text'
																label='note: (optional)'
																name='note'
																className='bg-white border-2 border-gray-300 w-full'
																onChange={(e) =>
																	setNoteForBalance(
																		e.target
																			.value
																	)
																}
															/>
														</div>
														{updatingNode ||
														loadingNodes ? (
															<div className='ml-4'>
																Loading...
															</div>
														) : balanceError ||
														  newBalance === 0 ? (
															<>
																<Button
																	className='ml-4'
																	size='xs'
																	color='disabled'
																	disabled
																	text='SUBMIT NEW BALANCE'
																/>
																{balanceError && (
																	<div className='ml-4 text-xs text-red-400'>
																		Please
																		enter a
																		valid
																		number
																	</div>
																)}
															</>
														) : (
															<Button
																className='ml-4'
																size='xs'
																text='SUBMIT NEW BALANCE'
																onClick={() => {
																	// e.preventDefault()
																	handleUpdateBalance(
																		node.slug,
																		{
																			balance:
																				parseInt(
																					newBalance
																				),
																		},
																		noteForBalance
																	)
																}}
															/>
														)}
													</div>
												)}
											</div>
										</div>
									)
								})}
							</div>
						) : (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
					</div>
					<div className='w-full flex flex-row justify-center items-center mb-8 justify-between'>
						{page > 1 ? (
							<div
								className='mx-auto underline font-bold cursor-pointer w-fit'
								onClick={() => {
									setPage(page - 1)
								}}
							>
								Previous {paginationLimit}
							</div>
						) : (
							<div className='mx-auto text-gray-200 font-bold cursor-pointer w-fit'>
								Previous {paginationLimit}
							</div>
						)}
						<div className='text-center text-xs'>
							Page {page} of{' '}
							{Math.ceil(nodeCount / paginationLimit)}
						</div>
						{nodeCount && page * paginationLimit < nodeCount ? (
							<div
								className='mx-auto w-fit underline font-bold cursor-pointer'
								onClick={() => {
									setPage(page + 1)
								}}
							>
								Next {paginationLimit}
							</div>
						) : (
							<div className='mx-auto text-gray-200 font-bold cursor-pointer w-fit'>
								Next {paginationLimit}
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, TransactionsCreate, Admin }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	updatingNode: Nodes.updatingNode,
	loggedIn: User.loggedIn,
	updateNodeError: Nodes.updateNodeError,
	transactionResult: TransactionsCreate.transactionResult,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	platforms: Admin.platforms,
	nodeCount: Nodes.nodeCount,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetAdminNodes: (filter) => dispatch(getAdminNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onUpdateNode: (updatePayload) => dispatch(updateNode(updatePayload)),
	onCreateTransaction: (transactionPayload) =>
		dispatch(createTransaction(transactionPayload)),
	onGetPlatforms: (payload) => dispatch(getAdminPlatforms(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRestaurants)
