import {
	INVITE_MERCHANT_USER,
	INVITE_MERCHANT_USER_FAIL,
	INVITE_MERCHANT_USER_SUCCESS,
	RESET_MERCHANT,
	GET_ALL_CHILDREN,
	GET_ALL_CHILDREN_FAIL,
	GET_ALL_CHILDREN_SUCCESS,
	ADD_CHILDREN_TO_USER,
	ADD_CHILDREN_TO_USER_FAIL,
	ADD_CHILDREN_TO_USER_SUCCESS,
	TRACK_USAGE,
	TRACK_USAGE_SUCCESS,
	TRACK_USAGE_FAIL,
} from './actionTypes'

const initialState = {
	invite: null,
	loading: false,
	error: null,
	children: [],
	user: null,
	analytics: null,
}

const merchant = (state = initialState, action) => {
	switch (action.type) {
		case INVITE_MERCHANT_USER:
			return {
				...state,
				loading: true,
			}
		case INVITE_MERCHANT_USER_SUCCESS:
			return {
				...state,
				loading: false,
				invite: action.payload,
			}
		case INVITE_MERCHANT_USER_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case GET_ALL_CHILDREN:
			return {
				...state,
				loading: true,
			}
		case GET_ALL_CHILDREN_SUCCESS:
			return {
				...state,
				loading: false,
				children: action.payload,
			}
		case GET_ALL_CHILDREN_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case ADD_CHILDREN_TO_USER:
			return {
				...state,
				loading: true,
			}
		case ADD_CHILDREN_TO_USER_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload,
			}
		case ADD_CHILDREN_TO_USER_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case TRACK_USAGE:
			return {
				...state,
				analytics: null,
			}

		case TRACK_USAGE_SUCCESS:
			return {
				...state,
				analytics: action.payload,
			}

		case TRACK_USAGE_FAIL:
			return {
				...state,
				error: action.payload,
			}

		case RESET_MERCHANT:
			return {
				invite: null,
				loading: false,
				error: null,
				children: null,
				user: null,
				analytics: null,
			}

		default:
			return state
	}
}
export default merchant
