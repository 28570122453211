import React, { useEffect, useState } from 'react'
import { getBanner, hideBanner } from '../store/actions'
import { connect } from 'react-redux'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Banner = (props) => {
	const { onGetBanner, banner, hideBanner, onHideBanner } = props

	useEffect(() => {
		onGetBanner()
	}, [])

	if (!banner || !banner.active) return null

	let bannerClass = ` pl-4 pr-6 py-4 h-12 w-screen overflow-hidden origin-center relative flex cursor-pointer justify-center items-center text-center text-white font-bold text-xs sm:text-sm md:text-lg z-40 transition duration-300 ease-in-out transform hover:shadow-light-gray hover:text-scale-110`

	const bannerBgColor = {
		primary: ` bg-green-600 hover:bg-green-400 `,
		warning: ' bg-yellow-600 hover:bg-yellow-400 ',
	}
	bannerClass += bannerBgColor[banner.colorHex]

	const handleHideBanner = () => {
		onHideBanner(true)
	}

	if (banner && !hideBanner) {
		return (
			<div className={bannerClass}>
				<a
					href={banner.url}
					target='_blank'
					className='w-full cursor-pointer'
				>
					<div>{banner.text}</div>
				</a>
				<div
					className='z-50 h-12 w-12 flex justify-end items-center '
					onClick={handleHideBanner}
				>
					<FaTimes />
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ AppInfo }) => ({
	banner: AppInfo.bannerInfo,
	hideBanner: AppInfo.hideBanner,
})

const mapDispatchToProps = (dispatch) => ({
	onGetBanner: () => dispatch(getBanner()),
	onHideBanner: () => dispatch(hideBanner()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
