import { connect } from 'react-redux'
import React, { useRef, useEffect, useState } from 'react'
import useOutsideClick from '../../../components/Elements/DetectOutsideClick'
import { v4 as uuidv4 } from 'uuid'
import {
	getUser,
	getNodes,
	updateUser,
	createStop,
	updateStop,
	deleteStop,
	getContainers,
	getAllLogistics,
	resetSuccess,
	createRoute,
	getReturnBinBoxCount,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	SelectGroup,
	TextArea,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'
import PackingListPopUp from '../../../components/Admin/PackingListPopUp'

const DZInternalFulfillment = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		onGetUser,
		updatingNode,
		loadingNodes,
		containers,
		onGetContainers,
		onCreateStop,
		onUpdateStop,
		onDeleteStop,
		stopsError,
		loadingStops,
		stopsSuccess,
		stops,
		onGetAllStops,
		stopsCount,
		onGetNodes,
		nodes,
		creatingRoute,
		createdRoute,
		createRouteError,
		onCreateRoute,
		returnBinBoxCount,
		onGetReturnBinBoxCount,
		onResetSuccess,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [date, setDate] = useState(new Date())
	const [page, setPage] = useState(1)
	const [market, setMarket] = useState(null)
	const [openPopUp, setOpenPopUp] = useState(false)
	const [selectedStop, setSelectedStop] = useState(null)

	// route creator
	const [filter, setFilter] = useState({})
	const [pickupNode, setPickupNode] = useState({})
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [startingLocation, setStartingLocation] = useState({})
	const [destinations, setDestinations] = useState([])
	const [userEnteredStart, setUserEnteredStart] = useState('')
	const [currentlyTypedAddressValue, setCurrentlyTypedAddressValue] =
		useState('')
	const [showRouteCreator, setShowRouteCreator] = useState(false)
	const [sortedStops, setSortedStops] = useState(null)
	const impactRef = useRef(null)
	const [binBoxCount, setBinBoxCount] = useState(null)

	useEffect(() => {
		onGetUser()
		onResetSuccess()
	}, [onGetUser])

	useEffect(() => {
		if (!openPopUp) {
			setBinBoxCount(null)
			// onResetSuccess()
		}
	}, [openPopUp])

	useEffect(() => {
		if (returnBinBoxCount) {
			setBinBoxCount(returnBinBoxCount)
		}
	}, [returnBinBoxCount])

	useEffect(() => {
		const filter = {}

		if (market) {
			filter.market = { $in: [market] }
		}
		if (date) {
			filter.date = { $in: [date] }
		}

		onGetAllStops({
			filter: filter,
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}, [date, market, stopsSuccess, openPopUp])

	useEffect(() => {
		const filter = {
			enabled: true,
		}
		if (market) {
			filter.markets = { $in: [market] }
		}
		onGetContainers([filter])
	}, [onGetContainers, market])

	useEffect(() => {
		const today = new Date()

		const yyyy = today.getFullYear()
		const mm = String(today.getMonth() + 1).padStart(2, '0')
		const dd = String(today.getDate()).padStart(2, '0')

		const formattedDate = `${yyyy}-${mm}-${dd}`

		setDate(formattedDate)
	}, [])

	const handleDateInputChange = (e) => {
		const selectedDate = e.target.value

		if (selectedDate) {
			setDate(selectedDate)
		} else {
			setDate(null)
		}
	}

	const handleOpenPopUp = (stop) => {
		setBinBoxCount(null)
		onResetSuccess()
		if (stop.locationType === 'bin') {
			onGetReturnBinBoxCount(stop.slug)
		}
		setOpenPopUp(!openPopUp)
		setSelectedStop(stop)
	}

	// route creator
	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		onGetNodes([filter])
	}, [filter])

	useEffect(() => {
		if (stops && stops[0]) {
			setDestinations(stops[0].stops)
		}
	}, [stops])

	useEffect(() => {
		if (createdRoute && createdRoute.routeOrder) {
			handleSortRoute(createdRoute.routeOrder)
		}
	}, [createdRoute])

	const handleSortRoute = (routeOrder) => {
		// skip starting location index 0
		for (let index = 1; index < routeOrder.length; index++) {
			const stop = routeOrder[index]
			const updatePayload = {
				date: stop.date,
				market: stop.market,
				routeOrder: index,
				stopId: stop.stopId,
			}
			onUpdateStop(updatePayload)
		}
	}

	const onAddressSelectStart = (address) => {
		setStartingLocation({
			name: 'User Entered Start',
			address: address,
		})
	}

	const handleCreateRoute = () => {
		onCreateRoute({
			startingLocation: startingLocation,
			destinations: destinations,
		})
	}

	const autoCompleteQueryStart = (address) => {
		onAddressSelectStart(address)
	}

	const handleSubmitAddressStart = (address) => {
		let latLng = window.google.maps.Geocoder(address)
		onAddressSelectStart(address.target.value)
	}

	useOutsideClick(impactRef, () => {
		setShowNodeChoices(false)
	})

	const handleShowRouteCreator = () => {
		setShowRouteCreator(!showRouteCreator)
	}

	const handleNavigateTo = (address) => {
		let urlAddress = address
		if (address.includes(';')) {
			const addressArr = address.split(';')
			urlAddress = addressArr[1]
		}
		const baseUrl = 'https://www.google.com/maps/dir/'
		const destinationAddress = encodeURIComponent(urlAddress)
		const mapsUrl = `${baseUrl}/${destinationAddress}`
		window.open(mapsUrl, '_blank')
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div className='h-full w-full'>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>

					<div className='px-12'>
						<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
							<h1 className='font-header text-green-600 text-4xl mb-7 mt-5 text-center'>
								DZ Internal Fulfillment
							</h1>
							<div>
								<div className='w-full flex justify-center items-center'>
									<div className='flex flex-row'>
										<label className='font-bold text-md mr-6'>
											Date:
										</label>
										<input
											type='date'
											value={date || ''}
											onChange={handleDateInputChange}
										/>
									</div>
								</div>
								<div className='w-full flex justify-center items-center'>
									<SelectGroup
										label=''
										value={market}
										placeholder='Select Market...'
										className='h-12 mt-3 min-w-[300px]'
										onChange={(e) => {
											setMarket(e.target.value)
											onResetSuccess()
										}}
										options={[
											{
												label: 'Denver/Boulder',
												value: 'CO',
											},
											{ label: 'NYC', value: 'NY' },
											{ label: 'LA', value: 'LA' },
											{ label: 'Bay Area', value: 'SF' },
										]}
									/>
								</div>
								{loadingStops ? (
									<BounceLoaderCentered container='div' />
								) : (
									<></>
								)}
							</div>
						</div>
					</div>

					{/* route creator */}
					{showRouteCreator && (
						<div className='flex flex-col justify-center mx-auto w-full'>
							<div className='bg-gray-200 p-3 border-solid border-2 border-gray-300 rounded-[5px] flex flex-col justify-center mx-auto w-[90%] sm:min-w-[300px] sm:max-w-[700px]'>
								<div className='ml-3 text-base font-bold mt-4'>
									Starting Location
								</div>
								<div className='ml-3 w-4/5 max-w-[600px] mb-4'>
									<Input
										placeholder='Search and select pickup location'
										className=''
										name='searchInput'
										id='searchInput'
										onChange={(e) => {
											setShowNodeChoices(true)
											setFilter({
												$or: [
													{
														name: {
															$regex: e.target
																.value,
															$options: 'i',
														},
													},
													{
														slug: {
															$regex: e.target
																.value,
															$options: 'i',
														},
													},
												],
											})
										}}
									/>
									<div className='text-base font-bold mt-4'>
										Or search an address
									</div>
									<Input
										placeholder={
											userEnteredStart
												? userEnteredStart
												: 'Search by address'
										}
										className=''
										name='nodeAddress'
										error={false}
										autoCompleteAddress={true}
										onKeyDown={(e) => {
											e.code === 'Enter'
												? handleSubmitAddressStart(e)
												: null
										}}
										onChange={(e) => {
											{
												e.target
													? setUserEnteredStart(
															e.target.value
													  )
													: setUserEnteredStart(
															e.formatted_address
													  )
											}
											{
												e.target
													? setCurrentlyTypedAddressValue(
															e.target.value
													  )
													: setCurrentlyTypedAddressValue(
															e.formatted_address
													  )
											}
										}}
										autoCompleteQuery={
											autoCompleteQueryStart
										}
										onAddressSelect={onAddressSelectStart}
										value={
											userEnteredStart
												? userEnteredStart
												: currentlyTypedAddressValue
										}
									/>
								</div>
								<div className='ml-3 flex-col flex'>
									{showNodeChoices && (
										<div
											ref={impactRef}
											className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[90%] sm:w-[300px] whitespace-nowrap'
										>
											{nodes.map((node) => (
												<div
													className='text-sm cursor-pointer my-1 hover:bg-gray-100'
													key={node._id}
													onClick={() => {
														setStartingLocation({
															name: node.name,
															address: [
																node.address,
																node.city,
																node.state,
																node.postal,
															].join(', '),
														})
														setShowNodeChoices(
															false
														)
													}}
												>
													{node.name}
												</div>
											))}
										</div>
									)}
								</div>
								<div className='ml-3 flex flex-row'>
									<div className='mb-3'>
										<span className='font-bold underline'>
											Selected Start Location:
										</span>{' '}
										{startingLocation.name}
									</div>

									{Object.keys(pickupNode).length > 0 && (
										<div
											className='ml-4 font-bold text-lg cursor-pointer text-green-600'
											onClick={() => {
												setPickupNode({})
											}}
										>
											X
										</div>
									)}
								</div>
								<div className='ml-3'>
									<span className='font-bold underline'>
										Address:
									</span>{' '}
									{startingLocation.address}
								</div>

								{createRouteError && (
									<div className='font-bold text-red-400 my-1 ml-3'>
										{createRouteError.message}
									</div>
								)}
								{creatingRoute ? (
									<BounceLoaderCentered container='div' />
								) : (
									<Button
										className='my-6 w-[300px] ml-3 px-2'
										size='sm'
										onClick={handleCreateRoute}
										text='Create Optimized Route'
									/>
								)}

								{createdRoute && (
									<div className='max-w-[500px] ml-3 mb-3'>
										<Button
											href={createdRoute.fullRoute}
											target='_blank'
											className='px-2'
											size='sm'
											color='green-outlined'
											text='Full Optimized Route'
										/>
										{/* <div className="mt-4 font-bold">Steps:</div>
										{createdRoute.routeOrder.map((stop,index)=>(
											<div>{index+1}: {stop.name}, {stop.address}, {stop.note}</div>
										))} */}
									</div>
								)}
							</div>
						</div>
					)}

					{/* show/hide route creator */}
					<div className='w-full my-3'>
						<Button
							className='w-[100px] px-3 flex flex-center mx-auto jsutify-center items-center'
							text={
								showRouteCreator
									? 'Hide Route Creator'
									: 'Show Route Creator'
							}
							size='sm'
							onClick={handleShowRouteCreator}
						/>
					</div>

					{/* mobile tiles */}
					{containers && stops && (
						<div className='flex flex-col justify-center align-center my-3 mx-3 sm:hidden'>
							<div className='overflow-x-auto'>
								{stops && stops.length > 0 ? (
									<>
										{stops
											.filter(
												(packingList) =>
													packingList &&
													packingList.stops &&
													packingList.stops.length > 0
											)
											.map((packingList) =>
												packingList.stops
													.sort(
														(a, b) =>
															a.routeOrder -
															b.routeOrder
													)
													.map((stop, index) => (
														<div
															key={stop.stopId}
															className={`${
																stop.status ===
																'complete'
																	? 'bg-green-100'
																	: stop.status ===
																	  'incomplete'
																	? 'bg-red-100'
																	: 'bg-white'
															} overflow-hidden mb-3 w-80% max-w-[500px] shadow-md flex flex-col m-auto rounded-[10px] cursor-pointer`}
														>
															<div
																onClick={() => {
																	handleOpenPopUp(
																		stop
																	)
																}}
															>
																<h1 className='font-header text-lg mt-2 ml-3'>
																	{stop.name}{' '}
																	(
																	{stop.status ===
																	'complete'
																		? 'Completed'
																		: stop.status ===
																		  'incomplete'
																		? 'Incomplete'
																		: 'Pending'}
																	){' '}
																	{index + 1}{' '}
																	of{' '}
																	{stopsCount}
																</h1>
																<p className='text-base ml-3'>
																	{
																		stop.address
																	}
																</p>
																<p className='font-header text-base mb-1 ml-3'>
																	{stop.stopType ===
																	'pick'
																		? 'Collection'
																		: 'Drop Off'}
																</p>
																<p className='text-base ml-3'>
																	{stop.note
																		? `Note: ${stop.note}`
																		: ''}
																</p>
															</div>
															<Button
																text='Navigate'
																size='xs'
																color='green'
																className='px-2 flex items-start justify-center w-[60%] ml-3 mb-3'
																onClick={() =>
																	handleNavigateTo(
																		stop.address
																	)
																}
															/>
														</div>
													))
											)}
									</>
								) : (
									<div className='text-center text-red-400 my-[50px]'>
										No results found
									</div>
								)}
							</div>
						</div>
					)}

					{/* table */}
					<div className='px-2 hidden sm:block'>
						{containers && stops && (
							<div className='my-10 max-w-[1600px] overflow-auto scrollbar flex flex-col justify-center align-center mx-auto'>
								<div className='overflow-x-auto'>
									{stops && stops.length > 0 ? (
										<table className='w-full border-collapse border border-gray-300'>
											<thead>
												<tr className='bg-gray-100'>
													{/* <th className="border border-gray-300 py-2 px-4">Date</th>
										<th className="border border-gray-300 py-2 px-4">Market</th> */}
													<th className='border border-gray-300 py-2 px-4'>
														Status
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Type
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Name
													</th>
													<th
														className='border border-gray-300 py-2 px-4 w-[200px] h-[100px] overflow-ellipsis'
														style={{
															width: '500px',
														}}
													>
														Address
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Note
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Driver Note
													</th>
													<th
														className='border border-gray-300 py-2 px-4 transform rotate-180'
														style={{
															writingMode:
																'vertical-rl',
														}}
													>
														<div className='flex flex-col'>
															<div>Total Qty</div>
															<div className='text-red-400'>
																(Actuals)
															</div>
														</div>
													</th>
													{containers.map(
														(container) => (
															<th
																key={
																	container.containerId
																}
																className='border border-gray-300 py-2 px-4 transform rotate-180'
																style={{
																	writingMode:
																		'vertical-rl',
																}}
															>
																{
																	container.containerId
																}
															</th>
														)
													)}
												</tr>
											</thead>
											<tbody>
												{stops
													.filter(
														(packingList) =>
															packingList &&
															packingList.stops &&
															packingList.stops
																.length > 0
													)
													.map((packingList) =>
														packingList.stops
															.sort(
																(a, b) =>
																	a.routeOrder -
																	b.routeOrder
															)
															.map((stop) => (
																<tr
																	key={
																		stop.stopId
																	}
																	onClick={() => {
																		handleOpenPopUp(
																			stop
																		)
																	}}
																	className={`${
																		stop.status ===
																		'complete'
																			? 'bg-green-100'
																			: stop.status ===
																			  'incomplete'
																			? 'bg-red-100'
																			: 'bg-white'
																	} h-12 overflow-hidden`}
																>
																	{/* <td className="border border-gray-300 py-2 px-4">{stop.date}</td>
												<td className="border border-gray-300 py-2 px-4">{stop.market}</td> */}
																	<td className='border border-gray-300 py-2 px-4'>
																		{stop.status ===
																		'complete'
																			? 'Completed'
																			: stop.status ===
																			  'incomplete'
																			? 'Incomplete'
																			: 'Pending'}
																	</td>
																	<td className='border border-gray-300 py-2 px-4'>
																		{stop.stopType ===
																		'pick'
																			? 'Collection'
																			: 'Drop Off'}
																	</td>
																	<td className='border border-gray-300 py-2 px-4'>
																		{
																			stop.name
																		}
																	</td>
																	<td
																		className='border border-gray-300 py-2 px-4 w-[200px] overflow-ellipsis'
																		style={{
																			width: '500px',
																		}}
																	>
																		{
																			stop.address
																		}
																	</td>
																	<td className='border border-gray-300 py-2 px-4'>
																		{
																			stop.note
																		}
																	</td>
																	<td className='border border-gray-300 py-2 px-4'>
																		{
																			stop.driverNote
																		}
																	</td>
																	<td className='border border-gray-300 py-2 px-4'>
																		{stop.actuals &&
																		stop
																			.actuals[
																			'boxCount'
																		] ? (
																			<div className='flex flex-col'>
																				<div>
																					{
																						stop.boxCount
																					}
																				</div>
																				<div className='text-red-400'>
																					(
																					{
																						stop
																							.actuals[
																							'boxCount'
																						]
																					}

																					)
																				</div>
																			</div>
																		) : (
																			stop.boxCount
																		)}
																	</td>
																	{containers.map(
																		(
																			container
																		) => (
																			<td
																				key={
																					container.containerId
																				}
																				className='border border-gray-300 py-2 px-4'
																			>
																				{stop?.products &&
																				stop
																					.products[
																					container
																						.containerId
																				] !==
																					undefined ? (
																					stop.actuals &&
																					stop
																						.actuals[
																						container
																							.containerId
																					] ? (
																						<div className='flex flex-col'>
																							<div>
																								{
																									stop
																										.products[
																										container
																											.containerId
																									]
																								}
																							</div>
																							<div className='text-red-400'>
																								(
																								{
																									stop
																										.actuals[
																										container
																											.containerId
																									]
																								}

																								)
																							</div>
																						</div>
																					) : (
																						stop
																							.products[
																							container
																								.containerId
																						]
																					)
																				) : (
																					0
																				)}
																			</td>
																		)
																	)}
																</tr>
															))
													)}
											</tbody>
										</table>
									) : (
										<div className='text-center text-red-400 my-[50px]'>
											No results found
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				{openPopUp && (
					<PackingListPopUp
						handleOpenPopUp={handleOpenPopUp}
						selectedStop={selectedStop}
						openPopUp={openPopUp}
						stopsCount={stopsCount}
						binBoxCount={binBoxCount}
						setBinBoxCount={setBinBoxCount}
					/>
				)}{' '}
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	Admin,
	Dropoffs,
	Containers,
	Logistics,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	creatingRoute: Admin.creatingRoute,
	createdRoute: Admin.createdRoute,
	createRouteError: Admin.createRouteError,
	creatingDropoff: Dropoffs.creatingDropoff,
	dropoffRequested: Dropoffs.dropoffRequested,
	dropoffError: Dropoffs.dropoffError,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	stops: Logistics.stops,
	loadingStops: Logistics.loadingStops,
	stopsError: Logistics.error,
	stopsSuccess: Logistics.success,
	stopsCount: Logistics.stopsCount,
	returnBinBoxCount: Logistics.boxCount,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onCreateGentlyDropoff: (dropoffPayload) =>
		dispatch(createGentlyDropoff(dropoffPayload)),
	onCreateGentlyDropoffNoNode: (dropoffPayload) =>
		dispatch(createGentlyDropoffNoNode(dropoffPayload)),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onCreateStop: (payload) => dispatch(createStop(payload)),
	onUpdateStop: (payload) => dispatch(updateStop(payload)),
	onDeleteStop: (payload) => dispatch(deleteStop(payload)),
	onGetAllStops: (payload) => dispatch(getAllLogistics(payload)),
	onCreateRoute: (routePayload) => dispatch(createRoute(routePayload)),
	onGetReturnBinBoxCount: (payload) =>
		dispatch(getReturnBinBoxCount(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DZInternalFulfillment)
