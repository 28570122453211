import React from 'react'
import { Button } from '../Elements'

const CreateAccount = () => {
	return (
		<div className='w-full h-auto p-4 flex flex-col justify-center items-center  bg-red-400'>
			<div className='w-[350px] h-full flex flex-col justify-center items-center transform -translate md:w-3/5 md:max-w-[1200px] lg:px-8 lg:transform lg:-translate-y-24 z-10'>
				<div className='flex flex-col justify-around  h-full text-center mb-8'>
					<h1 className='font-header text-white mb-8 text-3.5xl sm:text-5xl'>
						Create an Account
					</h1>
					<p className='text-center text-md mb-2 text-white'>
						Check out faster, track your impact, earn rewards for
						returns and more.
					</p>
				</div>
				<div className='w-full lg:h-12 flex flex-col lg:flex-row lg:relative justify-center items-center mb-8'>
					{/* <input type="email" name="email" id="email" className='rounded-full w-full bg-[#E9EFFD] py-3 pl-4 mb-4 lg:w-full lg:h-full lg:mb-0' placeholder='Enter your email'/> */}
					<Button
						color='green'
						size='sm'
						className='mb-4 lg:h-full lg:mb-0 w-full lg:w-3/5 lg:px-12'
						text='Create an Account'
						link='/sign-up'
					/>
				</div>
			</div>
		</div>
	)
}

export default CreateAccount
