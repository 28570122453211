import { call, put, takeEvery } from 'redux-saga/effects'
import { getContainers, getContainerById } from '../../model/containers'
import {
	getContainersFail,
	getContainersSuccess,
	getContainerByIdFail,
	getContainerByIdSuccess,
} from './actions'

import { GET_CONTAINERS, GET_CONTAINER_BY_ID } from './actionTypes'

function* fetchContainers(action) {
	try {
		const containers = yield call(getContainers, action.payload)
		yield put(getContainersSuccess(containers))
	} catch (error) {
		yield put(getContainersFail(error))
	}
}

function* fetchContainerById(action) {
	try {
		const container = yield call(getContainerById, action.payload)
		yield put(getContainerByIdSuccess(container))
	} catch (error) {
		yield put(getContainerByIdFail(error))
	}
}

function* containersSaga() {
	yield takeEvery(GET_CONTAINERS, fetchContainers)
	yield takeEvery(GET_CONTAINER_BY_ID, fetchContainerById)
}

export default containersSaga
