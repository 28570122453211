export const GET_MENU_BY_SLUG = 'GET_MENU_BY_SLUG'
export const GET_MENU_FAIL = 'GET_MENU_FAIL'
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_DELIVERECT_DISPATCH_AVAILABILITY =
	'GET_DELIVERECT_DISPATCH_AVAILABILITY'
export const GET_DELIVERECT_DISPATCH_AVAILABILITY_FAIL =
	'GET_DELIVERECT_DISPATCH_AVAILABILITY_FAIL'
export const GET_DELIVERECT_DISPATCH_AVAILABILITY_SUCCESS =
	'GET_DELIVERECT_DISPATCH_AVAILABILITY_SUCCESS'
