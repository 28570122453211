import {
	GET_CONTAINERS,
	GET_CONTAINERS_FAIL,
	GET_CONTAINERS_SUCCESS,
	GET_CONTAINER_BY_ID,
	GET_CONTAINER_BY_ID_FAIL,
	GET_CONTAINER_BY_ID_SUCCESS,
} from './actionTypes'

export const getContainers = (payload) => ({
	type: GET_CONTAINERS,
	payload: payload,
})

export const getContainersSuccess = (containers) => ({
	type: GET_CONTAINERS_SUCCESS,
	payload: containers,
})

export const getContainersFail = (error) => ({
	type: GET_CONTAINERS_FAIL,
	payload: error,
})

export const getContainerById = (payload) => ({
	type: GET_CONTAINER_BY_ID,
	payload: payload,
})

export const getContainerByIdSuccess = (product) => ({
	type: GET_CONTAINER_BY_ID_SUCCESS,
	payload: product,
})

export const getContainerByIdFail = (error) => ({
	type: GET_CONTAINER_BY_ID_FAIL,
	payload: error,
})
