import React, { useEffect } from 'react'

const RestaurantForm = () => {
	useEffect(() => {
		// Create script element and set attributes
		const script = document.createElement('script')
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.async = true
		script.onload = () => {
			if (
				window.hbspt &&
				!document.getElementById(
					'hsForm_47d96647-904a-42c8-a81a-c72d08df39d6'
				)
			) {
				window.hbspt.forms.create({
					region: 'na1',
					portalId: '39811365',
					formId: '47d96647-904a-42c8-a81a-c72d08df39d6',
					target: '#hubspot-form',
				})
			}
		}

		// Append the script to the document body
		document.body.appendChild(script)

		// Cleanup function to remove the script when component unmounts
		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<div
			id='hubspot-form'
			className='mt-20 min-h-min font-sans text-md text-green-600 font-header font-semibold'
		>
			{/* The HubSpot form will be inserted here */}
		</div>
	)
}

export default RestaurantForm
