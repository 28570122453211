import { realm } from '../helpers/realm'
import * as Realm from 'realm-web'

// paginate for every 15 rewards
export const getAllRewards = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const rewards = client.db('caas').collection('rewards_marketplace')

	const { filter, options } = payload
	// const { skip, limit } = options
	// const page = skip / limit

	const findFilter = {
		expirationDate: { $gt: new Date() },
		availableCodes: { $ne: [] },
	}

	const allRewards = await rewards.find(findFilter)

	return allRewards
}

export const removeDiscountCode = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const reward = await realm.currentUser.callFunction(
		'rewards/removeDiscountCode',
		payload
	)

	return reward
}

export const deductUserRewardPts = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const user = await realm.currentUser.callFunction(
		'rewards/updateUserRewardPts',
		payload
	)
	return user
}

export const updateNonUniqueDiscountCodeUsers = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const reward = await realm.currentUser.callFunction(
		'rewards/updateNonUniqueDiscountCodeUsers',
		payload
	)

	return reward
}

export const sendRedeemRewardEmail = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const res = await realm.currentUser.callFunction(
		'rewards/sendDiscountCodeEmail',
		payload
	)

	return res
}
