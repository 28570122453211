import { combineReducers } from 'redux'

import AppInfo from './appInfo/reducer'
import Cart from './cart/reducer'
import FAQ from './faq/reducer'
import ForgetPassword from './auth/forgetpwd/reducer'
import Franchisee from './franchisee/reducer'
import HowItWorks from './howItWorks/reducer'
import Location from './location/reducer'
import Login from './auth/login/reducer'
import Menu from './menu/reducer'
import Nodes from './nodes/reducer'
import OAuth from './auth/oauth/reducer'
import Orders from './orders/reducer'
import Path from './path/reducer'
import Payments from './payments/reducer'
import Platforms from './platforms/reducer'
import Account from './auth/register/reducer'
import RestaurantPayments from './restaurantPayments/reducer'
import ReverseLogistics from './reverseLogistics/reducer'
import Subscriptions from './subscriptions/reducer'
import Transactions from './transactions/reducer'
import TransactionsCreate from './transactions/create/reducer'
import User from './auth/user/reducer'
import SmsVerify from './auth/smsVerify/reducer'
import Admin from './admin/reducer'
import BoxCountLookUp from './boxCountLookUp/reducer'
import Promos from './promos/reducer'
import Notifications from './notifications/reducer'
import RAAS from './raas/reducer'
import AllRewards from './rewards/reducer'
import OfficeOrdering from './officeOrdering/reducer'
import Aggregation from './aggregation/reducer'
import Containers from './containers/reducer'
import WholeFoods from './wholefoods/reducer'
import Logistics from './logistics/reducer'
import Leads from './leads/reducer'
import Pentest from './pentest/reducer'
import Dropoffs from './dropoffs/reducer'
import Pickups from './pickups/reducer'
import ReusablesShop from './reusablesShop/reducer'
import Merchant from './merchant/reducer'
import Membership from './membership/reducer'

import { LOGOUT_USER_SUCCESS } from './auth/login/actionTypes'

const appReducer = combineReducers({
	AppInfo,
	Admin,
	BoxCountLookUp,
	Cart,
	FAQ,
	Franchisee,
	ForgetPassword,
	HowItWorks,
	Location,
	Login,
	Menu,
	OAuth,
	Nodes,
	Orders,
	Path,
	Pentest,
	Promos,
	Payments,
	Platforms,
	Account,
	RestaurantPayments,
	ReverseLogistics,
	Subscriptions,
	Transactions,
	TransactionsCreate,
	User,
	SmsVerify,
	Notifications,
	RAAS,
	AllRewards,
	OfficeOrdering,
	Aggregation,
	Containers,
	WholeFoods,
	Logistics,
	Leads,
	Dropoffs,
	Pickups,
	ReusablesShop,
	Merchant,
	Membership,
})

export const rootReducer = (state, action) => {
	if (action.type === LOGOUT_USER_SUCCESS) {
		state = undefined
	}

	return appReducer(state, action)
}
