import React from 'react'
import Header from '../../components/Header'
import { Footer } from '../../components'

function ToastPressRelease() {
	return (
		<>
			<Header />
			<div className=''>
				<img
					alt=''
					className='mx-auto max-w-[200px] mt-6'
					src='/images/Small primary.svg'
				/>
			</div>

			<div className='flex flex-col justify-center'>
				<div className='w-[90%] max-w-[950px] px-5 mx-auto pt-10 pb-10 overflow-hidden'>
					<div>
						<p className='mb-10'>
							For Immediate Release: April 5, 2023
							<br />
							Contact: Erika Tannor, etannor@tuskstrategies.com
							(917) 626-2487
						</p>
						<h2 className='mb-3 text-xl font-bold text-center'>
							**PRESS RELEASE**
						</h2>
						<h2 className='mb-3 text-xl font-bold text-center'>
							Reuse Startup DeliverZero Announces Integration with
							Toast Platform Expanding Access to Sustainable
							Takeout Packaging
						</h2>
						<p className='mb-3 italic text-center'>
							Collaboration expands restaurant access to
							sustainable takeout packaging and marks first-ever
							company integration with a POS system.
						</p>
						<p className='mb-3'>
							New York, NY – Today, DeliverZero is excited to
							announce that it has joined the Toast Partner
							Ecosystem. This marks the first time restaurant
							guests can choose to order takeout or delivery in
							reusable packaging without taking any additional
							steps. This offering is now available to any Toast
							restaurant where DeliverZero currently operates,
							which includes the New York City, Chicago, and
							Denver-Boulder metro areas.
						</p>
						<p className='mb-3'>
							<a
								href='https://pos.toasttab.com/'
								className='underline'
							>
								Toast
							</a>
							{` `}is a cloud-based, all-in-one digital technology
							platform purpose-built{` `}
							for the entire restaurant community. It works with
							integration partners in its{` `}
							<a
								href='https://pos.toasttab.com/integrations'
								className='underline'
							>
								Toast Partner Ecosystem
							</a>
							, a curated portfolio of more than 200 partners who
							deliver{` `}
							specialized technology and services to help
							restaurant operators increase sales, engage guests,
							and keep employees happy.
						</p>
						<p className='mb-3'>
							“Reducing waste from takeout helps guests easily
							make a more sustainable choice and fight climate
							change,{` `}
							and we have a huge opportunity to engage the
							restaurant community as we work toward addressing
							these{` `}
							challenges,” said{' '}
							<b>
								Jonathan Grimm, Toast co-founder and Chief
								Technology Officer of Toast.org.
							</b>
							{` `} “We are{` `}
							thrilled to be working with DeliverZero to help make
							reusable packaging easily accessible for both
							restaurants and their guests.”
						</p>
						<p className='mb-3'>
							With this integration, Toast restaurant customers
							can enable their guests to order food in reusable
							containers online via the Toast Takeout app, Toast
							Online Ordering, third-party apps that use Toast
							Orders API integrations, or on-premise, making reuse
							available at all points of sale and easier to select
							than ever. After guests opt-in to receive an order
							in DeliverZero containers, they receive SMS updates
							on the number of containers they received and how
							they can return them. Restaurant guests can return
							DeliverZero containers at any participating
							restaurant or at their door, making the offering
							extremely simple and accessible for restaurants and
							guests alike.
						</p>
						<p className='mb-3'>
							“With this integration we are bringing reuse to even
							more customers and in even more ways,” said{' '}
							<b>
								Lauren Sweeney, CEO and Co-Founder of
								DeliverZero
							</b>
							. “Now customers can order online or at the counter
							through Toast’s trusted platform, making reuse an
							easy and accessible choice. It’s been a pleasure to
							pilot this initiative with Toast and we are so
							excited about the opportunity to achieve more
							together.”
						</p>
						<p className='mb-3'>
							DeliverZero’s new technology makes it easier than
							ever for restaurants to make reusable packaging
							available to their customers. When customers opt-in,
							restaurants pack the order in reusable packaging and
							DeliverZero takes care of the challenges that make
							it difficult for restaurants to offer reusable
							packaging on their own. DeliverZero handles
							container tracking, ensures returns, and handles
							dishwashing for restaurants that do not have
							dishwashing capacity in-house.
						</p>
						<p className='mb-3'>
							Single-use plastics are littering streets, clogging{' '}
							<a
								className='underline'
								href='https://nylcv.org/news/rethinking-single-use-plastics-in-new-york-city/'
							>
								water supply
							</a>
							,
							<a
								className='underline'
								href='https://www.bloomberg.com/news/articles/2022-03-25/microplastics-found-in-blood-for-the-first-time-study-says'
							>
								entering human blood streams
							</a>
							,{` `}
							<a
								className='underline'
								href='https://nylcv.org/news/tackling-single-use-plastics-in-new-york/'
							>
								piling up in landfills
							</a>
							{` `}and crucially,{` `}
							<a
								className='underline'
								href="https://www.weforum.org/agenda/2022/01/plastic-pollution-climate-change-solution/#:~:text=In%202019%2C%20the%20CIEL%20estimated,or%20615%20coal%20plants'%20worth."
							>
								contributing to climate-warming greenhouse gas
								emissions
							</a>
							. In the U.S.{' '}
							<a
								className='underline'
								href='https://upstreamsolutions.org/skip-the-stuff'
							>
								561 billion
							</a>{' '}
							disposable food service items are used every year,
							resulting in 4.9 million tons of waste.
						</p>
						<p className='mb-6'>
							Accessible reusable solutions are an answer to the
							problems created by single-use packaging. Over their
							life-cycle, reusables have lower greenhouse gas
							emissions compared to disposable alternatives,
							producing up to{' '}
							<a
								href='https://upstreamsolutions.org/reuse-vs-single-use-environment'
								className='underline'
							>
								85% less carbon
							</a>
							{` `}than single-use foodware.
						</p>
						<h2 className='mb-3 font-bold'>About DeliverZero:</h2>
						<p className='mb-6'>
							<a
								href='https://www.deliverzero.com'
								className='underline'
							>
								DeliverZero
							</a>
							{` `}is a reusable packaging solution that
							eliminates packaging waste from food delivery and
							takeout. As a neutral network of returnable,
							reusable food containers, we make it easy for
							merchants, delivery platforms, and POS systems to
							offer customers takeout and delivery in reusable
							containers that can be returned to any point on
							their network. Learn more at{' '}
							<a
								href='https://www.deliverzero.com'
								className='underline'
							>
								https://www.deliverzero.com/
							</a>
							.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default ToastPressRelease
