import {
	CardElement,
	Elements,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { Footer, Header } from '../../../components'
import { getUser, updateLead, getLead } from '../../../store/actions'
import {
	getNodeByAdminCodeOrSlug,
	updateNode,
} from '../../../store/nodes/actions'
import { connect } from 'react-redux'
import {
	getPaymentMethods,
	resetCardAdded,
	storePaymentMethod,
} from '../../../store/payments/actions'
import { Button, Input, RadioGroup } from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'

const SignUpCreditCardForm = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		loadingAddition,
		onStorePaymentMethod,
		updatingNode,
		updateNodeCompleted,
		updateNodeError,
		onUpdateNode,
		cardAdded,
		cardAddedError,
		onGetPaymentMethods,
		paymentMethods,
		loadingPaymentMethods,
		onGetNodeBySlug,
		node,
		onGetLead,
		loadingNodes,
		loggedIn,
		loadingLead,
		leadError,
		updatedLead,
		updatingLead,
		updateLeadError,
		nodes,
		onUpdateLead,
		lead,
		defaultPaymentMethod,
		setDefaultPaymentMethod,
		billingEmail,
	} = state

	const bounceLoaderColor = '#507f74'
	const [stripeLoading, setStripeLoading] = useState(false)
	const [addCardError, setAddCardError] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [cardRadios, setCardRadios] = useState([])

	const stripe = useStripe()
	const elements = useElements()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (billingEmail) {
			setEmailAddress(billingEmail)
		}
		// if (node && (!node.email || node.email==="") && (lead && lead.email)){
		//     setEmailAddress(lead.email)
		// }
	}, [node, lead, billingEmail])

	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const handleAddCardSubmit = async (e) => {
		e.preventDefault()

		if (emailAddress === '') {
			setEmailError('Please enter an email address')
			return
		}

		setStripeLoading(true)
		setAddCardError(false)

		try {
			if (elements == null) {
				throw new Error('Elements not available')
			}

			const result = await stripe.createPaymentMethod({
				type: 'card',
				card: elements.getElement(CardElement),
			})

			if (result.error) {
				setStripeLoading(false)
				setAddCardError(true)
			} else {
				onStorePaymentMethod({
					restaurantAdmin: true,
					nodeSlug: user.restaurantAdmin,
					stripeCustomerId: node.stripeId,
					paymentMethodId: result.paymentMethod.id,
					desiredEmail: emailAddress,
				})
				setStripeLoading(false)
			}
		} catch (error) {
			console.error('Error submitting form:', error)
			setStripeLoading(false)
			setAddCardError(true)
		}
	}

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [user])

	useEffect(() => {
		if (node && node.stripeId) {
			if (node.paymentMethods && node.paymentMethods.length > 0) {
				if (node.paymentMethods.length === 1) {
					setDefaultPaymentMethod(node.paymentMethods[0].id)
				}
				makeCardRadios(node.paymentMethods)
			}
		}
		if (node && node.email) {
			setEmailAddress(node.email)
		}
	}, [JSON.stringify(node), JSON.stringify(node?.paymentMethods)])

	const makeCardRadios = (paymentMethods) => {
		let radios = []
		paymentMethods.forEach(function (paymentMethod, index) {
			radios.push({
				value: paymentMethod.id,
				label:
					paymentMethod.id === node.defaultPaymentMethod
						? `${paymentMethod.card.brand.toUpperCase()} ....${
								paymentMethod.card.last4
						  } - Exp. ${paymentMethod.card.exp_month}/${
								paymentMethod.card.exp_year
						  } - DEFAULT`
						: `${paymentMethod.card.brand.toUpperCase()} ....${
								paymentMethod.card.last4
						  } - Exp. ${paymentMethod.card.exp_month}/${
								paymentMethod.card.exp_year
						  }`,
				checked: node.defaultPaymentMethod ? true : false,
			})
		})
		setCardRadios(radios)
	}

	useEffect(() => {
		if (defaultPaymentMethod !== '') {
			onUpdateNode({
				nodeSlug: user.restaurantAdmin,
				update: {
					defaultPaymentMethod: defaultPaymentMethod,
					email: emailAddress,
				},
			})
			onUpdateLead({
				slug: user.restaurantAdmin,
				lastPage: 3,
				defaultPaymentMethod: defaultPaymentMethod,
				billingEmail: emailAddress,
				payWithACH: false,
				lastUpdated: new Date(),
			})
		}
	}, [defaultPaymentMethod])

	useEffect(() => {
		onGetNodeBySlug(user.restaurantAdmin)
	}, [JSON.stringify(paymentMethods)])

	if (node) {
		return (
			<div>
				{cardAddedError && (
					<div className='text-red-400 font-bold'>
						An error occurred while adding your card! Contact
						DeliverZero for help.
					</div>
				)}
				<form
					className='w-full sm:min-w-[330px]'
					onSubmit={handleAddCardSubmit}
				>
					<CardElement options={cardElementOptions} />
					{!loadingAddition &&
						!stripeLoading &&
						!updatingNode &&
						!loadingUser && (
							<div>
								<Button
									className='mt-4 px-2'
									text='Add Card'
									size='sm'
									submit={true}
								/>
							</div>
						)}
				</form>
				{loadingAddition ||
				loadingPaymentMethods ||
				stripeLoading ||
				updatingNode ||
				loadingUser ? (
					<div className='mt-3 text-center'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						/>
					</div>
				) : (
					<div className='mt-3'>
						{cardRadios.length > 0 ? (
							<div>
								<div className='mt-8 font-bold text-base'>
									Select your default payment
								</div>
								<RadioGroup
									className='mt-0'
									name='sortBy'
									radios={cardRadios}
									onChange={(paymentMethodId) => {
										setDefaultPaymentMethod(paymentMethodId)
									}}
								/>
							</div>
						) : (
							<div>
								<div className='font-semibold text-sm'>
									No cards on file
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		)
	} else {
		return (
			<div className='flex items-center h-screen-no-header'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				/>
			</div>
		)
	}
}

const mapStateToProps = ({ User, Payments, Nodes, Leads }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
	node: Nodes.restaurantAdminNode,
	updatingNode: Nodes.updatingNode,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	updateNodeError: Nodes.updateNodeError,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	updatedLead: Leads.updatedLead,
	updatingLead: Leads.updatingLead,
	updateLeadError: Leads.updateLeadError,
	lead: Leads.lead,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onGetNodeBySlug: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onResetCardAdded: () => dispatch(resetCardAdded()),
	onGetPaymentMethods: (userStripeId) =>
		dispatch(getPaymentMethods(userStripeId)),
	onUpdateNode: (updatePayload) => dispatch(updateNode(updatePayload)),
	onUpdateLead: (payload) => dispatch(updateLead(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignUpCreditCardForm)
