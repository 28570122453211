import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getOrders } from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Elements'
// import { Header } from '../../components'

const AdminOrders = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		loadingOrders,
		orders,
		ordersCount,
		onGetOrders,
	} = state

	useEffect(() => {
		onGetUser()
		orderPaginate()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [ordersPage, setOrdersPage] = useState(1)
	const [loading, setLoading] = useState(true)
	let navigate = useNavigate()

	const getOrders = (page) => {
		onGetOrders({
			filter: {},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const orderPaginate = () => {
		getOrders(ordersPage)
	}

	useEffect(() => {
		orderPaginate()
	}, [ordersPage])

	useEffect(() => {
		setLoading(false)
	}, [orders])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Marketplace Orders
				</h1>
				{ordersCount > 0 && (
					<div className='text-center'>
						<span className='font-bold'>
							Number of total orders:
						</span>{' '}
						{ordersCount}
					</div>
				)}
				<div className='flex flex-col max-w-[1200px] justify-center my-10 overflow-x-auto mx-auto'>
					<div className='mx-auto'>
						{orders.length > 0 && (
							<div className='px-10 my-6 pt-3 shadow-light-grey'>
								<div className='flex flex-row mb-2 mt-6'>
									<div className='font-bold w-[200px]'>
										Date
									</div>
									<div className='font-bold w-[150px]'>
										Restaurant
									</div>
									<div className='font-bold w-[150px]'>
										Customer
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Order Subtotal
									</div>
									<div className='font-bold w-[100px] text-sm'>
										DZ Commission Fee
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Service Fee
									</div>
									<div className='font-bold w-[100px] text-sm'>
										DZ Revenue
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Delivery Fee
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Taxes
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Tip
									</div>
									<div className='font-bold w-[100px] text-sm'>
										ZFP
									</div>
									<div className='font-bold w-[100px]'>
										Order Total
									</div>
								</div>
								{loading && (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								{orders.map((order) => {
									const orderDate = new Date(order.timestamp)
									const orderDateString =
										orderDate.toLocaleString('default', {
											month: 'short',
											day: 'numeric',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
										})

									const dzCommission =
										order.nodeInfo.commissionFee *
										order.subtotal

									return (
										<div className='flex flex-row my-2 divide-y-2 divide-solid'>
											<div></div>
											<div className='w-[200px]'>
												{orderDateString}
											</div>
											<div
												onClick={() => {
													const url = `/admin/restaurants/${order.nodeSlug}`
													navigate(url)
												}}
												className='w-[150px] overflow-hidden cursor-pointer underline font-bold'
											>
												{order.nodeInfo.name}
											</div>
											{user.adminPermissions.includes(
												'customer'
											) ? (
												<div
													onClick={() => {
														const url = `/admin/customers/${order.userId}`
														navigate(url)
													}}
													className='w-[150px] overflow-hidden cursor-pointer underline font-bold'
												>
													{order.userInfo.firstName}{' '}
													{order.userInfo.lastName}
												</div>
											) : (
												<div className='w-[150px] overflow-hidden'>
													{order.userInfo.firstName}{' '}
													{order.userInfo.lastName}
												</div>
											)}

											<div className='w-[100px] overflow-hidden'>
												${order.subtotal.toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												${dzCommission.toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												${order.serviceFee.toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												$
												{(
													dzCommission +
													order.serviceFee
												).toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												${order.deliveryFee.toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												${order.taxes.toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												${order.tip.toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												$
												{order.zeroFoodPrint.toFixed(2)}
											</div>
											<div className='w-[100px] overflow-hidden'>
												${order.dzTotal.toFixed(2)}
											</div>
											<Button
												text='View Order'
												size='sm'
												link={`/admin/orders/${order._id}`}
											/>
										</div>
									)
								})}
								<div className='flex flex-row justify-start'>
									<div
										className='underline font-bold mr-6 cursor-pointer'
										onClick={() => {
											setOrdersPage(ordersPage - 1)
											setLoading(true)
										}}
									>
										Previous {paginationLimit} orders
									</div>
									<div
										className='underline font-bold cursor-pointer'
										onClick={() => {
											setOrdersPage(ordersPage + 1)
											setLoading(true)
										}}
									>
										Next {paginationLimit} orders
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, RestaurantPayments, Orders }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	nodes: Nodes.nodes,
	node: Nodes.node,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrders)
