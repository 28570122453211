export const MEMBERSHIP_SIGNUP = 'MEMBERSHIP_SIGNUP'
export const MEMBERSHIP_SIGNUP_SUCCESS = 'MEMBERSHIP_SIGNUP_SUCCESS'
export const MEMBERSHIP_SIGNUP_FAIL = 'MEMBERSHIP_SIGNUP_FAIL'

export const GET_MEMBERSHIP_SAVINGS = 'GET_MEMBERSHIP_SAVINGS'
export const GET_MEMBERSHIP_SAVINGS_SUCCESS = 'GET_MEMBERSHIP_SAVINGS_SUCCESS'
export const GET_MEMBERSHIP_SAVINGS_FAIL = 'GET_MEMBERSHIP_SAVINGS_FAIL'

export const GET_MEMBERS = 'GET_MEMBERS'
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS'
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL'

export const RESET_MEMBERSHIP = 'RESET_MEMBERSHIP'
