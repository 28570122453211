import {
	GET_NODE_FAIL,
	GET_NODE_SUCCESS,
	GET_NODE_BY_SLUG,
	GET_NODE_BY_SLUG_FAIL,
	GET_NODE_BY_SLUG_SUCCESS,
	GET_NODES_FAIL,
	GET_NODES_SUCCESS,
	GET_ADMIN_NODES,
	GET_ADMIN_NODES_FAIL,
	GET_ADMIN_NODES_SUCCESS,
	RESET_NODE,
	GET_NODES,
	GET_NODE,
	GET_NODE_BY_QR,
	GET_NODE_BY_QR_FAIL,
	GET_NODE_BY_QR_SUCCESS,
	GET_NODE_BY_QR_NOT_FOUND,
	UPDATE_SEARCH_ADDRESS,
	ADD_NODE,
	ADD_NODE_SUCCESS,
	ADD_NODE_FAIL,
	UPDATE_NODE,
	UPDATE_NODE_SUCCESS,
	UPDATE_NODE_FAIL,
	GET_NODE_BY_ADMIN_CODE,
	GET_NODE_BY_ADMIN_CODE_SUCCESS,
	GET_NODE_BY_ADMIN_CODE_FAIL,
	RESET_ADMIN_NODE,
	RESTAURANT_ADMIN_SUBMIT_REQUEST,
	RESTAURANT_ADMIN_SUBMIT_REQUEST_SUCCESS,
	RESTAURANT_ADMIN_SUBMIT_REQUEST_FAIL,
} from './actionTypes'

const initialState = {
	node: null,
	nodeError: null,
	nodeNotFound: false,
	nodes: [],
	nodeCount: null,
	nodesError: null,
	finishedNodesPull: null,
	loadingNodes: false,
	loadingNode: false,
	searchedAddress: null,
	searchedAddressLatLng: null,
	loadingAddNode: false,
	addNodeError: false,
	updatingNode: false,
	updateNodeCompleted: null,
	updateNodeError: null,
	restaurantAdminNode: null,
	restaurantAdminNodeError: null,
	submittingRestaurantAdminRequest: false,
	submittedRestaurantAdminRequest: false,
	submitRestaurantAdminRequestError: null,
}

const nodes = (state = initialState, action) => {
	switch (action.type) {
		case GET_NODES:
			return {
				...state,
				loadingNodes: true,
			}

		case GET_NODES_SUCCESS:
			return {
				...state,
				loadingNodes: false,
				nodes: action.payload,
				finishedNodesPull: true,
			}

		case GET_NODES_FAIL:
			return {
				...state,
				loadingNodes: false,
				nodesError: action.payload,
			}

		case GET_ADMIN_NODES:
			return {
				...state,
				loadingNodes: true,
			}

		case GET_ADMIN_NODES_SUCCESS:
			const result = action.payload
			return {
				...state,
				loadingNodes: false,
				nodes: result.nodes,
				nodeCount: result.totalCount,
				finishedNodesPull: true,
			}

		case GET_ADMIN_NODES_FAIL:
			return {
				...state,
				loadingNodes: false,
				nodesError: action.payload,
			}

		case GET_NODE:
			return {
				...state,
				loadingNode: true,
			}

		case GET_NODE_SUCCESS:
			return {
				...state,
				loadingNode: false,
				node: action.payload,
			}

		case GET_NODE_FAIL:
			return {
				...state,
				loadingNode: false,
				nodeError: action.payload,
			}

		case GET_NODE_BY_SLUG:
			return {
				...state,
				loadingNode: true,
			}

		case GET_NODE_BY_SLUG_SUCCESS:
			return {
				...state,
				loadingNode: false,
				node: action.payload,
			}

		case GET_NODE_BY_SLUG_FAIL:
			return {
				...state,
				loadingNode: false,
				nodeError: action.payload,
			}

		case GET_NODE_BY_QR:
			return {
				...state,
				loadingNode: true,
			}

		case GET_NODE_BY_QR_SUCCESS:
			return {
				...state,
				loadingNode: false,
				node: action.payload,
			}

		case GET_NODE_BY_QR_NOT_FOUND:
			return {
				...state,
				loadingNode: false,
				nodeNotFound: true,
			}

		case GET_NODE_BY_QR_FAIL:
			return {
				...state,
				loadingNode: false,
				nodeError: action.payload,
			}

		case UPDATE_SEARCH_ADDRESS:
			return {
				...state,
				searchedAddress: action.payload[0],
				searchedAddressLatLng: action.payload[1],
			}

		case RESET_NODE:
			return {
				...state,
				loadingNode: false,
				node: null,
				error: {},
				updatingNode: false,
				updateNodeCompleted: null,
				updateNodeError: null,
			}

		case ADD_NODE:
			return {
				...state,
				loadingAddNode: true,
			}

		case ADD_NODE_SUCCESS:
			return {
				...state,
				loadingAddNode: false,
				addNodeSuccess: true,
				addNodeError: false,
			}
		case ADD_NODE_FAIL:
			return {
				...state,
				loadingAddNode: false,
				addNodeError: true,
				addNodeSuccess: false,
			}
		case UPDATE_NODE:
			return {
				...state,
				updatingNode: true,
				updateNodeCompleted: false,
			}

		case UPDATE_NODE_SUCCESS:
			return {
				...state,
				updatingNode: false,
				updateNodeCompleted: true,
				updateNodeError: null,
			}
		case UPDATE_NODE_FAIL:
			return {
				...state,
				updatingNode: false,
				updateNodeCompleted: false,
				updateNodeError: action.payload,
			}
		case GET_NODE_BY_ADMIN_CODE:
			return {
				...state,
				loadingNode: true,
			}
		case GET_NODE_BY_ADMIN_CODE_SUCCESS:
			return {
				...state,
				loadingNode: false,
				restaurantAdminNode: action.payload,
			}
		case GET_NODE_BY_ADMIN_CODE_FAIL:
			return {
				...state,
				loadingNode: false,
				restaurantAdminNodeError: action.payload,
			}
		case RESET_ADMIN_NODE:
			return {
				...state,
				restaurantAdminNode: null,
			}
		case RESTAURANT_ADMIN_SUBMIT_REQUEST:
			return {
				...state,
				submittingRestaurantAdminRequest: true,
				submittedRestaurantAdminRequest: false,
				submitRestaurantAdminRequestError: null,
			}
		case RESTAURANT_ADMIN_SUBMIT_REQUEST_SUCCESS:
			return {
				...state,
				submittingRestaurantAdminRequest: false,
				submittedRestaurantAdminRequest: action.payload,
			}
		case RESTAURANT_ADMIN_SUBMIT_REQUEST_FAIL:
			return {
				...state,
				submittingRestaurantAdminRequest: false,
				submitRestaurantAdminRequestError: action.payload,
			}
		default:
			return state
	}
}

export default nodes
