import {
	GET_RL_PARTNERS,
	GET_RL_PARTNERS_SUCCESS,
	GET_RL_PARTNERS_FAILURE,
	GET_RL_PARTNERS_BY_ZIP,
	GET_RL_PARTNERS_BY_ZIP_SUCCESS,
	GET_RL_PARTNERS_BY_ZIP_FAILURE,
	GET_RL_PARTNERS_BY_POLYGON,
	GET_RL_PARTNERS_BY_POLYGON_SUCCESS,
	GET_RL_PARTNERS_BY_POLYGON_FAILURE,
	GET_RL_PARTNER_FAILURE,
	GET_RL_PARTNER_SUCCESS,
	GET_RL_PARTNER,
	CANCEL_FIRST_DELIVERY,
	CANCEL_FIRST_DELIVERY_FAILURE,
	CANCEL_FIRST_DELIVERY_SUCCESS,
	GET_THIRD_PARTY_PARTNERS,
	GET_THIRD_PARTY_PARTNERS_SUCCESS,
	GET_THIRD_PARTY_PARTNERS_FAILURE,
} from './actionTypes'

export const getRlPartners = (payload) => ({
	type: GET_RL_PARTNERS,
	payload: payload,
})

export const getRlPartnersSuccess = (payload) => ({
	type: GET_RL_PARTNERS_SUCCESS,
	payload: payload,
})

export const getRlPartnersFailure = (payload) => ({
	type: GET_RL_PARTNERS_FAILURE,
	payload: payload,
})

export const getRlPartnersByZip = (payload) => ({
	type: GET_RL_PARTNERS_BY_ZIP,
	payload: payload,
})

export const getRlPartnersByZipSuccess = (payload) => ({
	type: GET_RL_PARTNERS_BY_ZIP_SUCCESS,
	payload: payload,
})

export const getRlPartnersByZipFailure = (payload) => ({
	type: GET_RL_PARTNERS_BY_ZIP_FAILURE,
	payload: payload,
})

export const getRlPartnersByPolygon = (payload) => ({
	type: GET_RL_PARTNERS_BY_POLYGON,
	payload: payload,
})

export const getRlPartnersByPolygonSuccess = (payload) => ({
	type: GET_RL_PARTNERS_BY_POLYGON_SUCCESS,
	payload: payload,
})

export const getRlPartnersByPolygonFailure = (payload) => ({
	type: GET_RL_PARTNERS_BY_POLYGON_FAILURE,
	payload: payload,
})

export const getRlPartner = (payload) => ({
	type: GET_RL_PARTNER,
	payload: payload,
})

export const getRlPartnerSuccess = (payload) => ({
	type: GET_RL_PARTNER_SUCCESS,
	payload: payload,
})

export const getRlPartnerFailure = (payload) => ({
	type: GET_RL_PARTNER_FAILURE,
	payload: payload,
})

export const cancelFirstDeliveryPickup = (payload) => ({
	type: CANCEL_FIRST_DELIVERY,
	payload: payload,
})

export const cancelFirstDeliveryPickupSuccess = (payload) => ({
	type: CANCEL_FIRST_DELIVERY_SUCCESS,
	payload: payload,
})

export const cancelFirstDeliveryPickupFailure = (payload) => ({
	type: CANCEL_FIRST_DELIVERY_FAILURE,
	payload: payload,
})
export const getThirdPartyPartners = (payload) => ({
	type: GET_THIRD_PARTY_PARTNERS,
	payload: payload,
})

export const getThirdPartyPartnersSuccess = (payload) => ({
	type: GET_THIRD_PARTY_PARTNERS_SUCCESS,
	payload: payload,
})

export const getThirdPartyPartnersFailure = (payload) => ({
	type: GET_THIRD_PARTY_PARTNERS_FAILURE,
	payload: payload,
})
