import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getUser, getAppInfo } from '../../../store/actions'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import YouTube from 'react-youtube'
import { realm } from '../../../helpers/realm'

const PlatformVideo = ({ user, platform, onGetUser }) => {
	const bounceLoaderColor = '#507f74'
	const navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const videoIds = {
		doordash: 'n_Eb-h2TVrE',
		ubereats: 'btDIyrY4Wk8',
		manual: 'VE-ppGl0HUM',
		chownow: 'ba_Iyjr1mn8',
		deliverect: 'Yqy9yQPiYT8',
	}

	const videoId = videoIds[platform] || ''

	if (!user) {
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				/>
			</div>
		)
	}

	return (
		<div className='w-full flex justify-center items-center'>
			{videoId && (
				<div className='flex w-full justify-center items-center container px-6'>
					<YouTube videoId={videoId} />
				</div>
			)}
		</div>
	)
}

const mapStateToProps = ({ User, AppInfo }) => ({
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlatformVideo)
