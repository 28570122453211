import React, { useState, useEffect } from 'react'
import {
	Button,
	CheckboxSelections,
	BounceLoaderCentered,
} from '../../components/Elements'
import {
	getUserByPhoneOnly,
	updateOptOutPreferences,
	resetPreferencesUpdated,
	storeGuestPayment,
	storePaymentMethod,
} from '../../store/actions'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

const TextEmailOptOut = (props) => {
	const {
		user,
		loadingUser,
		userError,
		onGetUserByPhoneOnly,
		preferences,
		loadingUpdate,
		updateError,
		onUpdatePreferences,
		onResetPreferencesUpdated,
		paymentMethods,
		cardAdded,
		onStorePaymentGuest,
		loadingAddition,
		paymentError,
		loggedIn,
		onStorePaymentMethod,
	} = props
	const { phone } = useParams()
	const [textOptedOut, setTextOptedOut] = useState(false)
	const [emailOptedOut, setEmailOptedOut] = useState(false)
	const [successfulUpdate, setSuccessfulUpdate] = useState(false)
	const [cardWarning, setCardWarning] = useState(false)
	const [showAddCard, setShowAddCard] = useState(false)
	const [addCardError, setAddCardError] = useState(false)
	const [stripeLoading, setStripeLoading] = useState(false)

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const stripe = useStripe()
	const elements = useElements()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	useEffect(() => {
		if (phone) {
			onGetUserByPhoneOnly({ phone: phone })
		}
	}, [phone, JSON.stringify(paymentMethods)])

	useEffect(() => {
		if (!user || !user.phone) {
			onGetUserByPhoneOnly({
				phone: phone,
			})
		}
	}, [loadingUser])

	useEffect(() => {
		if (user) {
			if (user.textOptOut) {
				setTextOptedOut(user.textOptOut)
			}
			if (user.emailOptOut) {
				setEmailOptedOut(user.emailOptOut)
			}
		}
	}, [user, user?.textOptOut, user?.emailOptOut])

	useEffect(() => {
		if (!user || !user.phone) {
			onGetUserByPhoneOnly({
				phone: phone,
			})
		}
	}, [loadingUser])

	useEffect(() => {
		if (preferences) {
			setSuccessfulUpdate(true)
			onResetPreferencesUpdated()
		}
	}, [preferences])

	useEffect(() => {
		if (textOptedOut && emailOptedOut) {
			if (user.paymentMethods && user.paymentMethods.length > 0) {
				setCardWarning(true)
			} else {
				setShowAddCard(true)
			}
		} else {
			setCardWarning(false)
			setShowAddCard(false)
		}
	}, [textOptedOut, emailOptedOut])

	const handleChange = (value, isChecked) => {
		if (value === 'textOptOut') {
			if (isChecked) {
				setTextOptedOut(true)
			} else {
				setTextOptedOut(false)
			}
		}
		if (value === 'emailOptOut') {
			if (isChecked) {
				setEmailOptedOut(true)
			} else {
				setEmailOptedOut(false)
			}
		}
	}

	const handleSubmitPreferences = async (e) => {
		e.preventDefault()
		await onUpdatePreferences({
			phone: user.phone,
			textOptOut: textOptedOut,
			emailOptOut: emailOptedOut,
		})
	}

	const handleAddCardGuest = async (e) => {
		e.preventDefault()
		setStripeLoading(true)
		e.preventDefault()
		setAddCardError(false)
		if (elements == null || !user.email || !user.phone) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})

		if (result.error || !result.paymentMethod) {
			setStripeLoading(false)
			setAddCardError(true)
		} else {
			setStripeLoading(false)
			onStorePaymentGuest({
				email: user.email,
				phone: user.phone,
				paymentMethodId: result.paymentMethod.id,
			})
		}
	}

	const handleAddCardSubmit = async (event) => {
		setStripeLoading(true)
		event.preventDefault()
		// setAddCardError(false)
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})
		if (result.error) {
			setStripeLoading(false)
			// setAddCardError(true)
		} else {
			setStripeLoading(false)
			onStorePaymentMethod({
				stripeCustomerId: user.stripeId,
				paymentMethodId: result.paymentMethod.id,
			})

			setShowCardForm(false)
		}
	}

	if (user && user.phone) {
		return (
			<div className='h-full w-full flex flex-col'>
				<div className='flex flex-col items-center text-center h-screen-no-header'>
					<>
						<h1 className='font-header text-green-600 text-3xl mt-10'>
							Update your preferences
						</h1>
						{loadingUpdate ? (
							<BounceLoaderCentered container='div' />
						) : successfulUpdate ? (
							<div className='mt-5 text-sm'>
								Your preferences were successfully updated
							</div>
						) : (
							<>
								<div className='flex flex-col items-center mt-2'>
									<CheckboxSelections
										name='optOutOptions'
										options={[
											{
												label: 'Opt out of text reminders about containers',
												value: 'textOptOut',
												defaultChecked: user.textOptOut
													? user.textOptOut
													: false,
											},
											{
												label: 'Opt out of email reminders about containers',
												value: 'emailOptOut',
												defaultChecked: user.emailOptOut
													? user.emailOptOut
													: false,
											},
										]}
										onChange={(e) => {
											handleChange(
												e.target.value,
												e.target.checked
											)
										}}
									/>
								</div>
								{cardWarning && (
									<div className='text-sm mt-5'>
										Choosing to opt out of both text and
										email reminders may result in your card
										being charged without warning
									</div>
								)}
								{showAddCard && (
									<div>
										<div className='mt-6'>
											<div className='text-sm mb-4'>
												To proceed, please add a card so
												you may be charged for late
												containers
											</div>
											{loggedIn ? (
												<form
													onSubmit={
														handleAddCardSubmit
													}
												>
													<CardElement
														options={
															cardElementOptions
														}
													/>
													{cardAdded ? (
														<div className='text-green-600 font-bold'>
															Card successfully
															added!
														</div>
													) : (
														<div></div>
													)}
													{paymentError ? (
														<div className='text-red-600 font-bold'>
															There was an error
															adding your card.
															Please try again.
														</div>
													) : (
														<div></div>
													)}
													{(loadingAddition ||
														stripeLoading) && (
														<BounceLoaderCentered container='div' />
													)}
													{!loadingAddition &&
														!stripeLoading &&
														!loadingUser && (
															<div>
																<Button
																	className='mt-2'
																	text='Add Card'
																	size='sm'
																	submit={
																		true
																	}
																/>
															</div>
														)}
												</form>
											) : (
												<form
													onSubmit={
														handleAddCardGuest
													}
												>
													<CardElement
														options={
															cardElementOptions
														}
													/>
													{cardAdded ? (
														<div className='text-green-600 font-bold'>
															Card successfully
															added!
														</div>
													) : (
														<div></div>
													)}
													{paymentError ? (
														<div className='text-red-600 font-bold'>
															There was an error
															adding your card.
															Please try again.
														</div>
													) : (
														<div></div>
													)}
													{(loadingAddition ||
														stripeLoading) && (
														<BounceLoaderCentered container='div' />
													)}
													{!loadingAddition &&
														!stripeLoading &&
														!loadingUser && (
															<div>
																<Button
																	className='mt-2'
																	text='Add Card'
																	size='sm'
																	submit={
																		true
																	}
																/>
															</div>
														)}
												</form>
											)}
										</div>
									</div>
								)}
								{loadingUpdate ? (
									<BounceLoaderCentered container='div' />
								) : showAddCard && !cardAdded ? (
									<Button
										className='mt-5 w-[200px]'
										size='sm'
										onClick={() => {}}
										text='Confirm'
										color='gray'
									/>
								) : (
									<Button
										className='mt-5 w-[200px]'
										size='sm'
										onClick={handleSubmitPreferences}
										text='Confirm'
									/>
								)}
								{updateError && (
									<div className='mt-4 text-red-600 text-sm'>
										An error occurred when updating your
										preferences. Please contact DeliverZero
										for assistance.
									</div>
								)}
							</>
						)}
					</>
				</div>
			</div>
		)
	} else {
		return (
			<div className='flex w-full h-screen-no-header justify-center align-center'>
				<BounceLoaderCentered container='div' />
			</div>
		)
	}
}

const mapStateToProps = ({ User, Payments }) => ({
	user: User.user,
	loadingUser: User.loading,
	userError: User.error,
	preferences: User.preferencesUpdated,
	loadingUpdate: User.preferencesUpdating,
	updateError: User.preferencesUpdatedError,
	paymentMethods: Payments.paymentMethods,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	paymentError: Payments.error,
	loggedIn: User.loggedIn,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUserByPhoneOnly: (payload) => dispatch(getUserByPhoneOnly(payload)),
	onUpdatePreferences: (payload) =>
		dispatch(updateOptOutPreferences(payload)),
	onResetPreferencesUpdated: () => dispatch(resetPreferencesUpdated()),
	onStorePaymentGuest: (payload) => dispatch(storeGuestPayment(payload)),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TextEmailOptOut)
