import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAnalyticsTransactions,
	getOrders,
	getNodes,
	updateUser,
	getNodeBySlug,
	getAdminCustomers,
	getAdminClients,
	getAdminAggregators,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Button, Input, SelectGroup } from '../../../components/Elements'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const AdminAnalyticsOrders = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		transactions,
		transactionsCount,
		loadingTransactions,
		totalBoxCount,
		onGetTransactions,
		onGetOrders,
		loadingOrders,
		orders,
		ordersCount,
		onGetNodes,
		nodes,
		onGetCustomers,
		loadingCustomers,
		customers,
		onGetClients,
		loadingClients,
		clients,
		onGetAggregators,
		loadingAggregators,
		aggregators,
		markets,
	} = state

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	const [orderPage, setOrderPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [platform, setPlatform] = useState('')
	const [clientSelections, setClientSelections] = useState([])
	const [aggPlatform, setAggPlatform] = useState('')
	const [market, setMarket] = useState('')
	const [marketSelections, setMarketSelections] = useState([])
	const [aggregatorSelections, setAggregatorSelections] = useState([])
	const [nodeSelections, setNodeSelections] = useState([])
	const [selectedNode, setSelectedNode] = useState('')
	const [selectedCustomer, setSelectedCustomer] = useState({})
	const [typedCustomerSearch, setTypedCustomerSearch] = useState('')
	const [customerSearchResults, setCustomerSearchResults] = useState([])
	const [startDate, setStartDate] = useState(new Date(2022, 9, 1))
	const [endDate, setEndDate] = useState(new Date())
	const [pageBoxCount, setPageBoxCount] = useState(0)
	const [restaurantFilter, setRestaurantFilter] = useState([])
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [filter, setFilter] = useState({})
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
		onGetClients()
		onGetAggregators()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetCustomers({
					filter: {
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							lastUse: -1,
						},
						limit: 1000000,
						skip: 0,
					},
				})
				onGetNodes([
					filter,
					{
						franchiseeSlug: user.franchiseeSlug,
					},
				])
			} else {
				onGetCustomers({
					filter: {},
					options: {
						sort: {
							lastUse: -1,
						},
						limit: 1000000,
						skip: 0,
					},
				})
				onGetNodes([filter])
			}
		} else {
			onGetUser()
		}
	}, [user, filter])

	const getTransactions = (page) => {
		let filter = {
			type: 'boxesOut',
			timestamp: { $gte: startDate, $lte: endDate },
		}
		if (platform !== '') {
			filter.client = platform
		}
		if (aggPlatform !== '') {
			filter.aggregator = aggPlatform
		}
		if (market !== '') {
			filter.market = market
		}
		if (selectedNode !== '') {
			filter.node = selectedNode
		}
		if (Object.keys(selectedCustomer).length > 0) {
			filter = {
				...filter,
				'user.phone': selectedCustomer.phone,
			}
		}

		if (user) {
			if (user.franchiseeSlug) {
				onGetTransactions({
					filter: {
						...filter,
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							timestamp: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			} else {
				console.log('filter', filter)
				console.log('page', page)
				onGetTransactions({
					filter: filter,
					options: {
						sort: {
							timestamp: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			}
		} else {
			setLoading(true)
		}
	}

	const transactionPaginate = () => {
		getTransactions(page)
	}

	const getOrders = (page) => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetOrders({
					filter: {
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							timestamp: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			} else {
				onGetOrders({
					filter: {},
					options: {
						sort: {
							timestamp: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			}
		} else {
			onGetUser()
		}
	}

	const orderPaginate = () => {
		getOrders(orderPage)
	}

	useEffect(() => {
		transactionPaginate()
	}, [
		page,
		platform,
		aggPlatform,
		selectedNode,
		market,
		startDate,
		endDate,
		selectedCustomer,
		user,
	])

	useEffect(() => {
		orderPaginate()
	}, [orderPage]) // user

	useEffect(() => {
		const marketsArray = [
			{
				label: 'All',
				value: '',
			},
		]
		for (const market of markets) {
			marketsArray.push({
				label: market,
				value: market,
			})
		}
		setMarketSelections(marketsArray)
	}, [markets])

	useEffect(() => {
		const marketsArray = [
			{
				label: 'All',
				value: '',
			},
		]
		for (const market of markets) {
			marketsArray.push({
				label: market,
				value: market,
			})
		}
		setMarketSelections(marketsArray)
	}, [markets])

	useEffect(() => {
		setLoading(false)
		const nodesArray = [
			{
				label: 'All',
				value: '',
			},
		]
		const aggSelections = [
			{
				label: 'All',
				value: '',
			},
		]
		let sumBoxCount = 0
		transactions.map((trans) => {
			if (
				nodesArray.filter((txt) => txt.value === trans.node).length > 0
			) {
			} else {
				if (user && user.franchiseeSlug) {
					if (trans.franchiseeSlug) {
						nodesArray.push({
							label: trans.nodeInfo.name,
							value: trans.node,
						})
					}
				} else {
					nodesArray.push({
						label:
							trans.nodeInfo && trans.nodeInfo.name
								? trans.nodeInfo.name
								: 'Node not found',
						value: trans.node,
					})
				}
			}
			if (!trans.aggregator) {
			} else {
				if (
					aggSelections.filter(
						(txt) => txt.value === trans.aggregator
					).length > 0
				) {
				} else {
					aggSelections.push({
						label: trans.aggregator,
						value: trans.aggregator,
					})
				}
			}
			sumBoxCount = sumBoxCount + trans.boxCount
		})
		setPageBoxCount(sumBoxCount)
		setAggregatorSelections(aggSelections)
		setNodeSelections(nodesArray)
	}, [orders, transactions])

	useEffect(() => {
		const cliSelections = [
			{
				label: 'All',
				value: '',
			},
		]
		clients.map((client) => {
			cliSelections.push({
				label: client.clientId,
				value: client.clientId,
			})
		})
		setClientSelections(cliSelections)
	}, [clients])

	useEffect(() => {
		if (typedCustomerSearch !== '') {
			const closestMatches = customers.filter(
				(txt) =>
					txt.email.toLowerCase().indexOf(typedCustomerSearch) !== -1
			)
			setCustomerSearchResults(closestMatches.slice(0, 5))
		} else {
			setCustomerSearchResults([])
		}
	}, [typedCustomerSearch])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	// console.log(transactions)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='flex flex-col max-w-[1200px] justify-center my-10 overflow-x-auto mx-auto'>
					<div className='mx-auto'>
						<div className='font-header text-2xl text-green-600'>
							Order Analytics
						</div>
						{!user.franchiseeSlug && (
							<>
								<SelectGroup
									label='Filter by Client'
									placeholder='Select...'
									name='client'
									options={
										aggPlatform === ''
											? clientSelections
											: [
													{
														label: 'deliverzero',
														value: 'deliverzero',
													},
											  ]
									}
									onChange={(e) => {
										setPlatform(e.target.value)
									}}
								/>
								<SelectGroup
									label='Filter by Aggregator'
									placeholder='Select...'
									name='aggregator'
									options={aggregatorSelections}
									onChange={(e) => {
										if (e.target.value != '') {
											setPlatform('deliverzero')
											setAggPlatform(e.target.value)
										}
									}}
								/>
								<SelectGroup
									label='Filter by Market'
									placeholder='Select...'
									name='market'
									options={marketSelections}
									onChange={(e) => {
										setMarket(e.target.value)
									}}
								/>
							</>
						)}
						{/* <SelectGroup
							label='Filter by Restaurant'
							placeholder='Select...'
							name='node'
							options={nodeSelections}
							onChange={(e) => {
								setSelectedNode(e.target.value)
							}}
						/> */}
						<div className='mt-6 font-bold'>Search Restaurant:</div>
						<Input
							placeholder='Search and select locations'
							className='bg-white border-2 border-gray-300'
							name='searchInput'
							id='searchInput'
							onChange={(e) => {
								setShowNodeChoices(true)
								setFilter({
									$or: [
										{
											name: {
												$regex: e.target.value,
												$options: 'i',
											},
										},
										{
											slug: {
												$regex: e.target.value,
												$options: 'i',
											},
										},
									],
								})
							}}
						/>
						<div className='flex-col flex items-center'>
							{showNodeChoices && (
								<div className='absolute z-20 h-1/3 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[80%] max-w-[600px] whitespace-nowrap'>
									<div
										className='cursor-pointer text-green-600'
										onClick={() => {
											setShowNodeChoices(false)
										}}
									>
										{' '}
										Close X
									</div>
									{nodes.map((node) => (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											key={node.slug}
											onClick={() => {
												if (node && node.slug) {
													setSelectedNode(node.slug)
													setShowNodeChoices(false)
												}
											}}
										>
											{node.name}
										</div>
									))}
								</div>
							)}
						</div>
						{!user.franchiseeSlug && (
							<>
								<div className='mt-6 font-bold'>
									Search a customer email
								</div>
								<Input
									type='text'
									label=''
									name='item-search'
									placeholder='e.g. "bob@gmail.com"'
									value={typedCustomerSearch}
									className='bg-white border-2 border-gray-300'
									onChange={(e) => {
										setTypedCustomerSearch(
											e.target.value.toLowerCase()
										)
									}}
								/>
								{customerSearchResults.map(
									(customer, index) => {
										return (
											<div
												className='flex flex-row my-1'
												key={index}
											>
												<div className='w-[350px] overflow-x-hidden'>
													{customer.firstName}
													{` `}
													{customer.email}
												</div>
												<Button
													className='items-center'
													size='xs'
													text='Select'
													onClick={() => {
														setSelectedCustomer(
															customer
														)
													}}
												/>
											</div>
										)
									}
								)}
								<Button
									text='Clear customer'
									size='xs'
									className='my-2'
									onClick={() => {
										setSelectedCustomer({})
										setTypedCustomerSearch('')
									}}
								/>
							</>
						)}
						<div className='mt-4'>Select Start Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
							/>
						</div>
						<div className='mt-4'>Select End Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={endDate}
								onChange={(date) => {
									date.setHours(23, 59, 0)
									setEndDate(date)
								}}
							/>
						</div>

						{transactionsCount > 0 && (
							<div className='flex flex-row justify-between'>
								<div className=''>
									Number of total orders: {transactionsCount}
								</div>
								<div className=''>
									Total number of boxes: {totalBoxCount}
								</div>
							</div>
						)}
						{/* {pageBoxCount > 0 && (
							<div className=''>
								<span className='font-bold'>
									Total boxes given out for these {paginationLimit} orders:
								</span>{' '}
								{pageBoxCount} boxes
							</div>
						)} */}

						{transactions.length > 0 && (
							<div className='px-10 max-w-[1000px] my-6 shadow-light-grey'>
								<div className='my-2 text-lg text-green-600'>
									Orders
								</div>
								<div className='flex flex-row mb-2'>
									<div className='font-bold w-[200px]'>
										Date
									</div>
									<div className='font-bold w-[200px]'>
										Restaurant
									</div>
									<div className='font-bold w-[200px]'>
										Box Count
									</div>
									<div className='font-bold w-[200px]'>
										Customer
									</div>
									<div className='font-bold w-[100px]'>
										Client
									</div>
									<div className='font-bold w-[100px]'>
										Aggregator
									</div>
								</div>
								{loadingCustomers && (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								{transactions.map((transaction, index) => {
									const transactionDate = new Date(
										transaction.timestamp
									)
									const transactionDateString =
										transactionDate.toLocaleString(
											'default',
											{
												month: 'short',
												day: 'numeric',
												year: 'numeric',
												hour: '2-digit',
												minute: '2-digit',
											}
										)
									return (
										<div
											className='flex flex-row my-2 divide-y-2 divide-solid'
											key={index}
										>
											<div></div>
											<div className='w-[200px]'>
												{transactionDateString}
											</div>
											{transaction.nodeInfo ? (
												<div className='w-[200px] overflow-hidden'>
													{transaction.nodeInfo.name}
												</div>
											) : (
												<div className='w-[200px] overflow-hidden'>
													{transaction.node}
												</div>
											)}

											<div className='w-[200px] text-orange-600'>
												{transaction.boxCount} boxes
												given to user
											</div>
											{user.adminPermissions.includes(
												'customer'
											) ? (
												<div
													onClick={() => {
														const url = `/admin/customers/${transaction.user.phone}`
														navigate(url)
													}}
													className='w-[200px] cursor-pointer underline'
												>
													{transaction.user
														? `${
																transaction.user
																	.firstName ||
																''
														  } ${
																transaction.user
																	.lastName ||
																''
														  }`
														: 'no user'}
												</div>
											) : (
												<div className='w-[200px]'>
													{transaction.user
														? `${
																transaction.user
																	.firstName ||
																''
														  } ${
																transaction.user
																	.lastName ||
																''
														  }`
														: 'no user'}
												</div>
											)}

											<div className='w-[100px]'>
												{transaction.client}
											</div>
											<div className='w-[100px]'>
												{transaction.aggregator
													? transaction.aggregator
													: ''}
											</div>
										</div>
									)
								})}
								<div className='flex flex-row'>
									<div
										className='mx-auto underline font-bold mr-6 cursor-pointer'
										onClick={() => {
											setPage(page - 1)
										}}
									>
										Previous {paginationLimit} transactions
									</div>
									<div
										className='mx-auto underline font-bold cursor-pointer'
										onClick={() => {
											setPage(page + 1)
											setLoading(true)
										}}
									>
										Next {paginationLimit} transactions
									</div>
								</div>
							</div>
						)}
					</div>
					{loadingTransactions && (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Transactions, Nodes, User, Orders, Admin }) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	nodes: Nodes.nodes,
	node: Nodes.node,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	customers: Admin.customers,
	customersCount: Admin.customersCount,
	loadingCustomers: Admin.loadingCustomers,
	loadingClients: Admin.loadingClients,
	clients: Admin.clients,
	loadingAggregators: Admin.loadingAggregators,
	aggregators: Admin.aggregators,
	totalBoxCount: Transactions.totalBoxCount,
	markets: Transactions.markets,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getAnalyticsTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onGetCustomers: (payload) => dispatch(getAdminCustomers(payload)),
	onGetClients: (payload) => dispatch(getAdminClients(payload)),
	onGetAggregators: (payload) => dispatch(getAdminAggregators(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdminAnalyticsOrders)
