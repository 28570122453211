import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
	getUser,
	getOffice,
	updateOffice,
	getPaymentMethods,
	resetCardAdded,
	storePaymentMethod,
	storePaymentMethodOfficeSuccess,
	updateOfficeEmail,
} from '../../store/actions'
import { connect } from 'react-redux'
import { Button, Input, RadioGroup } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'
import ReactConfetti from 'react-confetti'
import { GiPartyPopper } from 'react-icons/gi'

const OfficeAccountCreditCardForm = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		loadingAddition,
		onStorePaymentMethod,
		office,
		loadingOffice,
		onGetOffice,
		onUpdateOffice,
		updatingOffice,
		updateOfficeError,
		onUpdateOfficeEmail,
		updatingOfficeEmail,
		updateOfficeEmailCompleted,
		updateOfficeEmailError,
		cardAdded,
		cardAddedError,
		onGetPaymentMethods,
		paymentMethods,
		loadingPaymentMethods,
	} = state

	const bounceLoaderColor = '#507f74'
	const [stripeLoading, setStripeLoading] = useState(false)
	const [addCardError, setAddCardError] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [submitError, setSubmitError] = useState('')
	const [cardRadios, setCardRadios] = useState([])
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('')

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const stripe = useStripe()
	const elements = useElements()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const handleAddCardSubmit = async (event) => {
		event.preventDefault()
		if (emailAddress === '') {
			setEmailError('Please enter in an email address')
		} else {
			setStripeLoading(true)
			setAddCardError(false)
			if (elements == null) {
				return
			}
			const result = await stripe.createPaymentMethod({
				type: 'card',
				card: elements.getElement(CardElement),
			})
			if (result.error) {
				setStripeLoading(false)
				setAddCardError(true)
			} else {
				setStripeLoading(false)
				onStorePaymentMethod({
					officeAdmin: true,
					officeSlug: office.slug,
					stripeCustomerId: office.stripeId,
					paymentMethodId: result.paymentMethod.id,
					paymentEmail: emailAddress,
				})
			}
		}
	}

	useEffect(() => {
		if (user && user.officeSlug) {
			onGetOffice(user.officeSlug)
		}
	}, [user])

	useEffect(() => {
		if (office && office.stripeId) {
			if (office.paymentMethods && office.paymentMethods.length > 0) {
				if (office.paymentMethods.length === 1) {
					setDefaultPaymentMethod(office.paymentMethods[0].id)
				}
				makeCardRadios(office.paymentMethods)
			}
		}
		if (office && office.paymentEmail) {
			setEmailAddress(office.paymentEmail)
		}
	}, [JSON.stringify(office?.paymentMethods)])

	useEffect(() => {
		if (office && office.stripeId) {
			if (office.paymentMethods && office.paymentMethods.length > 0) {
				if (office.paymentMethods.length === 1) {
					setDefaultPaymentMethod(office.paymentMethods[0].id)
				}
				makeCardRadios(office.paymentMethods)
			}
		}
		if (office && office.paymentEmail) {
			setEmailAddress(office.paymentEmail)
		}
	}, [
		storePaymentMethodOfficeSuccess,
		defaultPaymentMethod,
		office,
		JSON.stringify(office),
	])

	const makeCardRadios = (paymentMethods) => {
		let radios = []
		paymentMethods.map(function (paymentMethod, index) {
			radios.push({
				value: paymentMethod.id,
				label:
					paymentMethod.id === office.defaultPaymentMethod
						? `${paymentMethod.card.brand.toUpperCase()} ....${
								paymentMethod.card.last4
						  } - Exp. ${paymentMethod.card.exp_month}/${
								paymentMethod.card.exp_year
						  } - DEFAULT`
						: `${paymentMethod.card.brand.toUpperCase()} ....${
								paymentMethod.card.last4
						  } - Exp. ${paymentMethod.card.exp_month}/${
								paymentMethod.card.exp_year
						  }`,
				checked: paymentMethod.id === office.defaultPaymentMethod,
			})
		})
		setCardRadios(radios)
	}

	useEffect(() => {
		if (office && defaultPaymentMethod !== '') {
			if (emailAddress === '') {
				setEmailError('Please enter in an email address')
			} else {
				onUpdateOffice({
					slug: user.officeSlug,
					update: {
						...office,
						defaultPaymentMethod: defaultPaymentMethod,
						paymentEmail: emailAddress,
					},
				})
			}
		}
	}, [defaultPaymentMethod])

	useEffect(() => {
		if (office) {
			getOffice(user.officeSlug)
		}
	}, [office])

	useEffect(() => {
		onGetOffice(user.officeSlug)
	}, [JSON.stringify(paymentMethods)])

	const submitHandler = async (e) => {
		e.preventDefault()
		setSubmitError('')
		if (defaultPaymentMethod !== '') {
			onUpdateOffice({
				slug: user.officeSlug,
				update: {
					defaultPaymentMethod: defaultPaymentMethod,
					paymentEmail: emailAddress,
				},
			})
			onUpdateOfficeEmail({
				officeAdmin: true,
				officeSlug: office.slug,
				stripeId: office.stripeId,
				paymentEmail: emailAddress,
			})
			if (office) {
				// window.location.reload();
				makeCardRadios(office.paymentMethods)
			}
		} else {
			setSubmitError('Please select a default payment method')
		}
	}

	if (office) {
		return (
			<div className='w-full flex flex-col items-center justify-center'>
				<h1 className='font-header text-3xl md:text-5xl mb-8 mt-2 text-green-600'>
					{office.name}'s Payment Methods
				</h1>
				{!office.defaultPaymentMethod ? (
					<p className='w-1/3 text-center min-w-[300px] mb-8 md:text-base'>
						Add a default payment method for your office.
					</p>
				) : (
					<p className='w-1/3 text-center min-w-[300px] mb-8 md:text-base'>
						Update the default payment method for your office.
					</p>
				)}
				{cardAddedError && (
					<div className='text-red-400 font-bold w-[330px] sm:w-[380px]'>
						An error occurred while adding your card! Contact
						DeliverZero for help. {JSON.stringify(cardAddedError)}
					</div>
				)}
				{submitError && (
					<div className='text-red-400 font-bold w-[330px]'>
						{submitError}
					</div>
				)}
				<form
					className='w-[330px] sm:w-[380px]'
					onSubmit={handleAddCardSubmit}
				>
					<Input
						type=''
						label='EMAIL'
						name='email-address'
						defaultValue={office.paymentEmail || ''}
						onChange={(e) => {
							setEmailAddress(e.target.value)
						}}
						className='bg-white border-2 border-gray-300 mb-3 md:w-full lg:w-5/6'
					/>
					<CardElement options={cardElementOptions} />
					{!loadingAddition &&
						!stripeLoading &&
						!updatingOffice &&
						!loadingUser && (
							<div>
								<Button
									className='mt-2 px-2'
									text='Add Card'
									size='sm'
									submit={true}
								/>
							</div>
						)}
				</form>
				{(loadingPaymentMethods ||
					loadingAddition ||
					stripeLoading ||
					loadingUser) && (
					<div className='mt-3 text-center'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						/>
					</div>
				)}

				{!loadingAddition &&
					!stripeLoading &&
					!loadingUser &&
					!loadingPaymentMethods && (
						<div className='mt-6'>
							<div className='mt-8 font-bold text-base'>
								Select your default payment
							</div>
							{cardRadios.length > 0 ? (
								<div>
									<RadioGroup
										className='mt-8 sm:mt-0'
										name='sortBy'
										radios={cardRadios}
										onChange={(paymentMethodId) => {
											setDefaultPaymentMethod(
												paymentMethodId
											)
										}}
									/>
								</div>
							) : (
								<div>
									<div className='font-semibold'>
										No cards on file
									</div>
								</div>
							)}
						</div>
					)}

				{loadingPaymentMethods ||
				loadingAddition ||
				stripeLoading ||
				loadingUser ? (
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				) : (
					<div>
						{defaultPaymentMethod === '' || emailAddress === '' ? (
							<Button
								text='Save Updates'
								size='sm'
								color='gray'
								onClick={() => {}}
								className='mx-auto my-5'
							/>
						) : (
							<Button
								text='Save Updates'
								size='sm'
								color='green'
								onClick={(e) => submitHandler(e)}
								className='mx-auto my-5'
							/>
						)}
					</div>
				)}
			</div>
		)
	} else {
		return (
			<div className='flex items-center h-screen-no-header'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				/>
			</div>
		)
	}
}

const mapStateToProps = ({ User, OfficeOrdering }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	cardAdded: OfficeOrdering.cardAdded,
	loadingAddition: OfficeOrdering.loadingAddition,
	cardAddedError: OfficeOrdering.paymentError,
	paymentMethods: OfficeOrdering.paymentMethods,
	loadingPaymentMethods: OfficeOrdering.loadingPaymentMethods,
	office: OfficeOrdering.office,
	loadingOffice: OfficeOrdering.loadingOffice,
	updatingOffice: OfficeOrdering.updatingOffice,
	updateOfficeError: OfficeOrdering.updateOfficeError,
	updatingOfficeEmail: OfficeOrdering.updatingOfficeEmail,
	updateOfficeEmailCompleted: OfficeOrdering.updateOfficeEmailCompleted,
	updateOfficeEmailError: OfficeOrdering.updateOfficeEmailrror,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onGetOffice: (slug) => dispatch(getOffice(slug)),
	onResetCardAdded: () => dispatch(resetCardAdded()),
	onGetPaymentMethods: (userStripeId) =>
		dispatch(getPaymentMethods(userStripeId)),
	onUpdateOffice: (updatePayload) => dispatch(updateOffice(updatePayload)),
	onUpdateOfficeEmail: (updatePayload) =>
		dispatch(updateOfficeEmail(updatePayload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OfficeAccountCreditCardForm)
