import React, { useState, useEffect } from 'react'
import { BiLeftArrowCircle, BiRightArrowCircle } from 'react-icons/bi'
import { Button } from '../Elements'
import { ReusablesCartItem } from '..'
import { useNavigate } from 'react-router-dom'

const ReusablesCart = (props) => {
	const { cartItems, handleUpdateCartItem, handleClearCart } = props
	const [isOpen, setIsOpen] = useState(true)
	const [cartHeight, setCartHeight] = useState('h-screen')
	const navigate = useNavigate()

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.scrollY
			if (scrollTop > 0) {
				setCartHeight('h-screen top-[h-header] sm:h-full sm:top-0')
			} else {
				setCartHeight('h-screen top-[h-header]')
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<nav
			className={`shadow-light-grey fixed right-0 w-fit bg-white z-10 transition-transform ease-out transform ${
				isOpen ? `${cartHeight}` : 'h-fit'
			}`}
		>
			<div>
				<div className='w-full flex flex-row justify-start m-4'>
					{isOpen ? (
						<BiRightArrowCircle
							className='text-4xl cursor-pointer text-green-600'
							onClick={() => setIsOpen(!isOpen)}
						/>
					) : (
						<BiLeftArrowCircle
							className='text-4xl cursor-pointer text-green-600'
							onClick={() => setIsOpen(!isOpen)}
						/>
					)}
				</div>
				{isOpen ? (
					<div className='flex flex-col pl-5'>
						<div className='flex flex-row items-center justify-between pr-5'>
							<div className='font-header text-2xl cursor-pointer text-green-600'>
								Your Cart
							</div>
							<div
								className='cursor-pointer text-xs underline'
								onClick={() => {
									handleClearCart()
								}}
							>
								Clear Cart
							</div>
						</div>
						<ReusablesCartItem
							cartItems={cartItems}
							handleUpdateCartItem={handleUpdateCartItem}
						/>
						<div className='py-5 border-solid border-t flex justify-center'>
							<Button
								text={'Checkout'}
								className={'w-full mx-8'}
								onClick={() => {
									navigate('/reusables-shop/checkout')
								}}
								size='sm'
							></Button>
						</div>
					</div>
				) : (
					<div className=''></div>
				)}
			</div>
		</nav>
	)
}

export default ReusablesCart
