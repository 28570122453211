import React from 'react'

const PDFViewer = ({ pdfUrl }) => {
	return (
		<div className='pdf-container'>
			<embed
				src={pdfUrl}
				type='application/pdf'
				width='100%'
				height='1200px'
			/>
		</div>
	)
}

export default PDFViewer
