import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, FormGroup, Input, RadioGroup } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'
import ReactConfetti from 'react-confetti'
import { GiPartyPopper } from 'react-icons/gi'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CreditCardForm from '../../components/Stripe/CreditCardForm'
import {
	getUser,
	getAppInfo,
	createTransaction,
	resetTransaction,
	chargePaymentMethod,
	resetCardResponse,
	chargeGuestPayment,
	storeGuestPayment,
	getPaymentMethods,
	captureIP,
	captureGPS,
	getUserByPhoneOnly,
} from '../../store/actions'
import { realm } from '../../helpers/realm'
import PhoneInput from 'react-phone-number-input/input'

const LatePaymentsForm = (props) => {
	const {
		user,
		loadingUser,
		loggedIn,
		onCreateTransaction,
		transaction,
		creatingTransaction,
		onResetTransaction,
		onChargeCustomer,
		onChargeGuest,
		onStorePaymentGuest,
		chargingCard,
		chargingCardResponse,
		chargingCardError,
		cardAdded,
		loadingAddition,
		paymentMethods,
		paymentError,
		onGetUser,
		onResetCardResponse,
		onGetAppInfo,
		appInfo,
		onGetPaymentMethods,
		overdueBoxCount,
		userPhone,
		onCaptureIP,
		onCaptureGPS,
		onGetUserByPhone,
	} = props
	const bounceLoaderColor = '#507f74'
	const [showSuccess, setShowSuccess] = useState(false)
	const [showSubmitWarning, setShowSubmitWarning] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const [cardRadios, setCardRadios] = useState([])
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
	const [form, setForm] = useState({
		amount: 5,
		quantity: overdueBoxCount || 1,
		phone: userPhone || '',
		email: user?.email || '',
		paymentMethodId: '',
		stripeCustomerId: '',
	})
	const [addCardError, setAddCardError] = useState(false)
	const [stripeLoading, setStripeLoading] = useState(false)
	const [lateFee, setLateFee] = useState(null)
	const elements = useElements()
	const stripe = useStripe()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const currentDate = new Date()

	useEffect(() => {
		onGetAppInfo()
	}, [onGetAppInfo])

	useEffect(() => {
		if (user && appInfo) {
			if (
				user?.dzMemberSubscription?.enabled &&
				user?.dzMemberSubscription?.tierSlug === 'tierTwo'
			) {
				setLateFee(
					appInfo.memberLateFee || appInfo.containerLateFee || 5
				)
			} else {
				setLateFee(appInfo.containerLateFee || 5)
			}
		} else {
			setLateFee(appInfo.containerLateFee || 5)
		}
	}, [appInfo, user])

	useEffect(() => {
		setForm({
			...form,
			quantity: overdueBoxCount,
			amount: overdueBoxCount ? overdueBoxCount * lateFee : lateFee,
			quantity: overdueBoxCount || 1,
		})
	}, [overdueBoxCount, lateFee])

	useEffect(() => {
		if (userPhone) {
			onGetUserByPhone({
				phone: userPhone,
			})
		} else {
			onGetUser()
		}
	}, [userPhone, onGetUser, onGetUserByPhone])

	useEffect(() => {
		const fetchUserData = async () => {
			if (userPhone) {
				await onGetUserByPhone({
					phone: userPhone,
				})
			}
			const updatedForm = {
				...form,
				email: user?.email || '',
			}

			setForm(updatedForm)
		}

		fetchUserData()
	}, [user, user?.email])

	const submitTransaction = () => {
		let transactionPayload
		if (!loggedIn) {
			transactionPayload = {
				timestamp: currentDate,
				type: 'boxesIn',
				boxCount: form.quantity ? form.quantity : 1,
				user: {
					phone: form.phone,
					email: form.email,
				},
				latePayment: true,
			}
		} else {
			transactionPayload = {
				timestamp: currentDate,
				type: 'boxesIn',
				boxCount: form.quantity,
				user: {
					phone: user.phone,
					email: user.email,
					firstName: user.firstName,
					lastName: user.lastName,
				},
				latePayment: true,
			}
		}

		onCreateTransaction(transactionPayload)
	}

	useEffect(() => {
		if (transaction) {
			setShowSuccess(true)
		}
		return function cleanup() {
			onResetTransaction()
			onResetCardResponse()
		}
	}, [transaction])

	const submitHandler = (e) => {
		e.preventDefault()
		setErrorMessage('')
		if (
			selectedPaymentMethod !== '' &&
			typeof form.amount === 'number' &&
			!isNaN(form.amount) &&
			form.amount > 1
		) {
			onChargeCustomer({
				userId: user.phone,
				paymentMethodId: selectedPaymentMethod,
				totalChargeAmount: form.amount,
				type: 'late_payment',
			})
		} else if (selectedPaymentMethod === '') {
			setErrorMessage('Please select a card')
		} else {
			validateFormFields()
		}
	}

	const submitHandlerGuest = async (e) => {
		e.preventDefault()
		setErrorMessage('')
		await handleAddCardGuest(e)
		if (
			selectedPaymentMethod !== '' &&
			typeof form.amount === 'number' &&
			!isNaN(form.amount) &&
			form.amount > 1
		) {
			onChargeGuest({
				email: form.email,
				phone: form.phone,
				paymentMethodId: selectedPaymentMethod,
				totalChargeAmount: form.amount,
				type: 'late_payment_guest',
			})
		} else if (selectedPaymentMethod === '') {
			setErrorMessage('Please select a card')
		} else {
			validateFormFields()
		}
	}

	const handleAddCardGuest = async (e) => {
		e.preventDefault()
		setErrorMessage('')
		if (checkExistingDzUser()) {
			if (validateFormFields()) {
				setStripeLoading(true)
				e.preventDefault()
				setAddCardError(false)
				if (elements == null) {
					return
				}

				const result = await stripe.createPaymentMethod({
					type: 'card',
					card: elements.getElement(CardElement),
				})

				if (result.error) {
					setStripeLoading(false)
					setAddCardError(true)
				} else {
					setStripeLoading(false)
					// console.log("result.paymentMethod.id", result.paymentMethod.id)
					onStorePaymentGuest({
						email: form.email,
						phone: form.phone,
						paymentMethodId: result.paymentMethod.id,
					})
					setSelectedPaymentMethod(result.paymentMethod.id)
				}
			}
		}
	}

	const checkExistingDzUser = async () => {
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ phone: form.phone })
		if (userDoc) {
			// console.log("userDoc", userDoc)
			return true
		} else {
			setErrorMessage(
				'Please enter a valid phone number associated with an order'
			)
			return false
		}
	}

	const validateFormFields = () => {
		if (form.email === '' || !form.email.includes('@')) {
			setErrorMessage('Please enter a valid email address')
		} else {
			setErrorMessage('')
		}
		if (form.phone === '' || (form.phone && form.phone.length !== 12)) {
			setErrorMessage('Please enter a 10 digit phone number')
		} else {
			setErrorMessage('')
		}
		if (
			form.quantity < 1 ||
			form.amount < 1 ||
			isNaN(form.amount) ||
			typeof form.amount !== 'number'
		) {
			setErrorMessage('Please enter a quantity greater than 1')
		} else {
			setErrorMessage('')
		}
		if (
			form.email !== '' &&
			form.email.includes('@') &&
			form.phone.length === 12
		) {
			return true
		} else {
			return false
		}
	}

	useEffect(() => {
		validateFormFields()
	}, [form.email, form.phone, form.quantity, form.amount])

	useEffect(() => {
		setErrorMessage('')
		if (chargingCardResponse === 'success') {
			submitTransaction()
			onResetCardResponse()
		} else if (chargingCardResponse === 'error') {
			setErrorMessage('Error while charging your card!')
		}
	}, [chargingCardResponse])

	useEffect(() => {
		if (chargingCardError) {
			setErrorMessage('Error while charging your card!')
		}
	}, [chargingCardError])

	useEffect(() => {
		if (user && user.paymentMethods) {
			makeCardRadios(user.paymentMethods)
		}
	}, [user?.paymentMethods])

	useEffect(() => {
		if (user && user.stripeId) {
			onGetPaymentMethods(user.stripeId)
		}
	}, [cardAdded, paymentError, user?.stripeId])

	const makeCardRadios = (paymentMethods) => {
		let radios = []
		paymentMethods.forEach(function (paymentMethod, index) {
			radios.push({
				value: paymentMethod.id,
				label: `${paymentMethod.card.brand.toUpperCase()} ....${
					paymentMethod.card.last4
				} - Exp. ${paymentMethod.card.exp_month}/${
					paymentMethod.card.exp_year
				}`,
				checked: index === 0,
			})
			if (index === 0) {
				setSelectedPaymentMethod(paymentMethod.id)
			}
		})
		setCardRadios(radios)
	}

	const handlePhone = (e) => {
		setForm({ ...form, phone: e })
	}

	if (showSuccess) {
		return (
			<div className='w-full h-full lg:h-screen-3/4 flex flex-col justify-start items-center overflow-hidden'>
				<ReactConfetti
					className='w-full'
					colors={['#f38b74']}
				/>
				<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
					Payment Submitted!
				</h1>
				<GiPartyPopper className='text-orange-600 text-[250px]' />
				<Button
					link='/user/account'
					className='px-10 w-[260px] mt-5 shadow-light-grey'
					text='View My Account'
				/>
			</div>
		)
	} else {
		return (
			<div className='w-full flex flex-col items-center justify-start min-h-screen-no-header-mobile'>
				<FormGroup className='w-full sm:w-[400px] flex flex-col'>
					{!loggedIn && (
						<>
							<div className='w-full px-2 mb-2'>
								<div className='font-header text-xl font-bold text-start ml-5'>
									<label htmlFor='phone'>Phone</label>
								</div>
								<p className='text-xs text-start ml-5'>
									If you've ordered in DeliverZero boxes from
									a third-party, please use the phone number
									from those orders
								</p>
								<PhoneInput
									onChange={handlePhone}
									country='US'
									value={form.phone}
									id='phone'
									name='phone'
									className={` form-control bg-white border-2 border-gray-300 w-full  rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow`}
									type='phone'
								/>
							</div>
							<div className='w-full px-2 mb-2'>
								<div className='font-header text-xl font-bold text-start ml-5'>
									<label htmlFor='email'>Email</label>
								</div>
								<Input
									type='string'
									name='email'
									value={form.email}
									defaultValue={form.email}
									className='h-12 text-md'
									onChange={(e) =>
										setForm({
											...form,
											email: e.target.value,
										})
									}
								/>
							</div>
						</>
					)}
					<div className='px-2 mb-2'>
						<div className='font-header text-xl font-bold text-start ml-5'>
							<label htmlFor='quantity'>Quantity</label>
						</div>
						<Input
							type='number'
							name='quantity'
							defaultValue='1'
							value={form.quantity}
							min='1'
							className='h-12 text-xl font-bold pl-8'
							onChange={(e) => {
								const inputValue = e.target.value
								if (
									!isNaN(inputValue) &&
									parseInt(inputValue) >= 1
								) {
									setForm({
										...form,
										quantity: parseInt(inputValue),
										amount: parseInt(inputValue) * lateFee,
									})
								} else {
									setForm({
										...form,
										quantity: 1,
										amount: lateFee || 5,
									})
								}
							}}
						/>
					</div>
					<div className='font-header text-xl font-bold text-start ml-5'>
						<label htmlFor='quantity'>Total Due</label>
					</div>
					<div className='mt-2 mb-4 text-green-600 font-bold ml-8 text-xl text-start'>
						$
						{form.amount
							? form.amount
							: overdueBoxCount && lateFee
							? overdueBoxCount * lateFee
							: lateFee}
					</div>
					<div className='w-full'>
						<div className='px-2 mb-2 mx-auto'>
							<div className='font-header text-xl font-bold text-start ml-5'>
								Payment
							</div>
						</div>
						{!loggedIn ? (
							<form
								onSubmit={handleAddCardGuest}
								className='w-full mx-auto'
							>
								<CardElement options={cardElementOptions} />
								{cardAdded ? (
									<div className='text-green-600 font-bold'>
										Card successfully added!
									</div>
								) : (
									<div></div>
								)}
								{paymentError ? (
									<div className='text-red-600 font-bold'>
										There was an error adding your card.
										Please try again.
									</div>
								) : (
									<div></div>
								)}
								{!loadingAddition &&
									!stripeLoading &&
									!loadingUser && (
										<div>
											<Button
												className={`mt-2 px-2`}
												text='Add Card'
												size='sm'
												submit={true}
											/>
										</div>
									)}
								{loadingAddition && (
									<div>
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									</div>
								)}
								{stripeLoading && (
									<div>
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									</div>
								)}
							</form>
						) : user &&
						  user.paymentMethods &&
						  user.paymentMethods.length === 0 ? (
							<div className='w-full my-2 mx-auto'>
								<Elements stripe={stripePromise}>
									<CreditCardForm />
								</Elements>
							</div>
						) : (
							<>
								<div className='w-full my-2 mx-auto'>
									<Elements stripe={stripePromise}>
										<CreditCardForm />
									</Elements>
								</div>
								<div className='flex justify-center items-center text-left mt-3 mb-4 mx-auto'>
									{cardRadios.length > 0 && (
										<div>
											<RadioGroup
												className='mt-1 sm:mt-0'
												name='sortBy'
												radios={cardRadios}
												onChange={(paymentMethodId) => {
													setSelectedPaymentMethod(
														paymentMethodId
													)
												}}
											/>
										</div>
									)}
								</div>
							</>
						)}
					</div>
					{creatingTransaction || chargingCard ? (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					) : loggedIn ? (
						<div>
							{selectedPaymentMethod === '' ||
							errorMessage !== '' ? (
								<Button
									text='Submit Payment'
									size='sm'
									color='disabled'
									onClick={() => {
										setShowSubmitWarning(true)
									}}
									className='mx-auto px-2 my-5'
								/>
							) : (
								<Button
									text='Submit Payment'
									size='sm'
									color='green'
									onClick={(e) => submitHandler(e)}
									className='mx-auto px-2 my-5'
								/>
							)}
						</div>
					) : (
						<div>
							{form.email === '' ||
							isNaN(form.amount) ||
							typeof form.amount !== 'number' ||
							form.quantity === '' ||
							form.phone === '' ||
							selectedPaymentMethod === '' ? (
								<Button
									text='Submit Payment'
									size='sm'
									color='disabled'
									onClick={() => {
										setShowSubmitWarning(true)
									}}
									className='mx-auto px-2 my-5'
								/>
							) : (
								<Button
									text='Submit Payment'
									size='sm'
									color='green'
									onClick={(e) => submitHandlerGuest(e)}
									className='mx-auto px-2 my-5'
								/>
							)}
						</div>
					)}
					{errorMessage !== '' && (
						<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
							{errorMessage}
						</div>
					)}
					{showSubmitWarning &&
						errorMessage === '' &&
						(!loggedIn ||
						(user &&
							user.paymentMethods &&
							user.paymentMethods.length === 0) ? (
							<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
								Please fill out all form fields and "Add Card"
								to verify your payment method before submitting
							</div>
						) : (
							<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
								Please fill out all form fields and add or
								select a payment method before submitting
							</div>
						))}
				</FormGroup>
			</div>
		)
	}
}

const mapStateToProps = ({ User, TransactionsCreate, Payments, AppInfo }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	appInfo: AppInfo.appInfo,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
	chargingCard: Payments.chargingCard,
	chargingCardError: Payments.chargingCardError,
	chargingCardResponse: Payments.chargingCardResponse,
	paymentMethods: Payments.paymentMethods,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	paymentError: Payments.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onChargeCustomer: (payload) => dispatch(chargePaymentMethod(payload)),
	onStorePaymentGuest: (payload) => dispatch(storeGuestPayment(payload)),
	onChargeGuest: (payload) => dispatch(chargeGuestPayment(payload)),
	onResetCardResponse: () => dispatch(resetCardResponse()),
	onGetPaymentMethods: (userStripeId) =>
		dispatch(getPaymentMethods(userStripeId)),
	onCaptureIP: (payload) => dispatch(captureIP(payload)),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
	onGetUserByPhone: (payload) => dispatch(getUserByPhoneOnly(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LatePaymentsForm)
