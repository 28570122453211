export const GET_OFFICE = 'GET_OFFICE'
export const GET_OFFICE_SUCCESS = 'GET_OFFICE_SUCCESS'
export const GET_OFFICE_ERROR = 'GET_OFFICE_ERROR'
export const GET_OFFICE_TRANSACTIONS = 'GET_OFFICE_TRANSACTIONS'
export const GET_OFFICE_TRANSACTIONS_SUCCESS = 'GET_OFFICE_TRANSACTIONS_SUCCESS'
export const GET_OFFICE_TRANSACTIONS_FAIL = 'GET_OFFICE_TRANSACTIONS_FAIL'
export const GET_ONE_TRANSACTION_OFFICE = 'GET_ONE_TRANSACTION_OFFICE'
export const GET_ONE_TRANSACTION_OFFICE_SUCCESS =
	'GET_ONE_TRANSACTION_OFFICE_SUCCESS'
export const GET_ONE_TRANSACTION_OFFICE_FAIL = 'GET_ONE_TRANSACTION_OFFICE_FAIL'
export const UPDATE_OFFICE = 'UPDATE_OFFICE'
export const UPDATE_OFFICE_SUCCESS = 'UPDATE_OFFICE_SUCCESS'
export const UPDATE_OFFICE_ERROR = 'UPDATE_OFFICE_ERROR'
export const GET_OFFICE_ADMINS = 'GET_OFFICE_ADMINS'
export const GET_OFFICE_ADMINS_SUCCESS = 'GET_OFFICE_ADMINS_SUCCESS'
export const GET_OFFICE_ADMINS_ERROR = 'GET_OFFICE_ADMINS_ERROR'
export const ASSIGN_OFFICE_ADMIN = 'ASSIGN_OFFICE_ADMIN'
export const ASSIGN_OFFICE_ADMIN_SUCCESS = 'ASSIGN_OFFICE_ADMIN_SUCCESS'
export const ASSIGN_OFFICE_ADMIN_ERROR = 'ASSIGN_OFFICE_ADMIN_ERROR'
export const SCHEDULE_OFFICE_PICKUP = 'SCHEDULE_OFFICE_PICKUP'
export const SCHEDULE_OFFICE_PICKUP_SUCCESS = 'SCHEDULE_OFFICE_PICKUP_SUCCESS'
export const SCHEDULE_OFFICE_PICKUP_ERROR = 'SCHEDULE_OFFICE_PICKUP_ERROR'
export const GET_OFFICE_CUSTOMERS = 'GET_OFFICE_CUSTOMERS'
export const GET_OFFICE_CUSTOMERS_SUCCESS = 'GET_OFFICE_CUSTOMERS_SUCCESS'
export const GET_OFFICE_CUSTOMERS_FAIL = 'GET_OFFICE_CUSTOMERS_FAIL'
export const GET_ONE_CUSTOMER_OFFICE = 'GET_ONE_CUSTOMER_OFFICE'
export const GET_ONE_CUSTOMER_OFFICE_SUCCESS = 'GET_ONE_CUSTOMER_OFFICE_SUCCESS'
export const GET_ONE_CUSTOMER_OFFICE_FAIL = 'GET_ONE_CUSTOMER_OFFICE_FAIL'
export const STORE_PAYMENT_METHOD_OFFICE = 'STORE_PAYMENT_METHOD'
export const STORE_PAYMENT_METHOD_OFFICE_SUCCESS =
	'STORE_PAYMENT_METHOD_OFFICE_SUCCESS'
export const STORE_PAYMENT_METHOD_OFFICE_FAIL =
	'STORE_PAYMENT_METHOD_OFFICE_FAIL'
export const UPDATE_OFFICE_EMAIL = 'UPDATE_OFFICE_EMAIL'
export const UPDATE_OFFICE_EMAIL_SUCCESS = 'UPDATE_OFFICE_EMAIL_SUCCESS'
export const UPDATE_OFFICE_EMAIL_ERROR = 'UPDATE_OFFICE_EMAIL_ERROR'

export const GET_OFFICE_BY_ADMIN_CODE = 'GET_OFFICE_BY_ADMIN_CODE'
export const GET_OFFICE_BY_ADMIN_CODE_SUCCESS =
	'GET_OFFICE_BY_ADMIN_CODE_SUCCESS'
export const GET_OFFICE_BY_ADMIN_CODE_FAIL = 'GET_OFFICE_BY_ADMIN_CODE_FAIL'

export const RESET_ADMIN_OFFICE = 'RESET_ADMIN_OFFICE'
export const RESET_OFFICE_INVITE = 'RESET_OFFICE_INVITE'

export const INVITE_OFFICE_ADMIN = 'INVITE_OFFICE_ADMIN'
export const INVITE_OFFICE_ADMIN_FAIL = 'INVITE_OFFICE_ADMIN_FAIL'
export const INVITE_OFFICE_ADMIN_SUCCESS = 'INVITE_OFFICE_ADMIN_SUCCESS'
