import {
	GET_MENU_BY_SLUG,
	GET_MENU_SUCCESS,
	GET_MENU_FAIL,
	GET_PRODUCT,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	GET_DELIVERECT_DISPATCH_AVAILABILITY,
	GET_DELIVERECT_DISPATCH_AVAILABILITY_SUCCESS,
	GET_DELIVERECT_DISPATCH_AVAILABILITY_FAIL,
} from './actionTypes'

export const getMenuBySlug = (payload) => ({
	type: GET_MENU_BY_SLUG,
	payload: payload,
})

export const getMenuSuccess = (menu) => ({
	type: GET_MENU_SUCCESS,
	payload: menu,
})

export const getMenuFail = (error) => ({
	type: GET_MENU_FAIL,
	payload: error,
})
export const getProduct = (payload) => ({
	type: GET_PRODUCT,
	payload: payload,
})

export const getProductSuccess = (product) => ({
	type: GET_PRODUCT_SUCCESS,
	payload: product,
})

export const getProductFail = (error) => ({
	type: GET_PRODUCT_FAIL,
	payload: error,
})
export const getDispatchAvailability = (payload) => ({
	type: GET_DELIVERECT_DISPATCH_AVAILABILITY,
	payload: payload,
})

export const getDispatchAvailabilitySuccess = (dispatch) => ({
	type: GET_DELIVERECT_DISPATCH_AVAILABILITY_SUCCESS,
	payload: dispatch,
})

export const getDispatchAvailabilityFail = (error) => ({
	type: GET_DELIVERECT_DISPATCH_AVAILABILITY_FAIL,
	payload: error,
})
