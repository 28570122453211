import React, { useEffect, useState } from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Testimonials } from '../../components'
import { Button, Input } from '../../components/Elements'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { alertSlack } from '../../store/admin/actions'

function RaasLanding(props) {
	const { alertingSlack, alertedSlack, alertSlackError, onAlertSlack } = props
	const [technology, setTechnology] = useState(false)
	const [logistics, setLogistics] = useState(false)
	const [sanitization, setSanitization] = useState(false)
	const [deliverZeroPackaging, setDeliverZeroPackaging] = useState(false)
	const [showEmail, setShowEmail] = useState(false)
	const [typedEmail, setTypedEmail] = useState('')

	const submitInquiry = () => {
		const theyWant = `${technology ? 'technology, ' : ''} ${
			logistics ? 'logistics, ' : ''
		} ${sanitization ? 'sanitization, ' : ''} ${
			deliverZeroPackaging ? 'deliverZeroPackaging, ' : ''
		}`
		onAlertSlack({
			channel: '#sales',
			text:
				'<@U02AE0964S2>, RAAS inquiry ' +
				typedEmail +
				' and they want ' +
				theyWant,
		})
	}
	console.log(alertSlackError)

	const containerStyle = {
		backgroundImage: 'url("https://i.imgur.com/jRdVJzy.png")',
		backgroundSize: 'cover',
		backgroundPosition: 'center', // Adjust as needed
		position: 'relative', // Text color
		textAlign: 'center',
		paddingTop: '50px', // Adjust as needed
	}

	const textStyle1 = {
		// Adjust as needed
		// textShadow:'0.01em 0 white, 0 0.01em white, -0.01em 0 white,0 -0.01em white',
		textShadow: '#cac1bc 0px 0px 10px',
	}
	const textStyle = {
		// Adjust as needed
		// textShadow:'0.01em 0 white, 0 0.01em white, -0.01em 0 white,0 -0.01em white',
		textShadow: '#d1cfd0 0px 0px 10px',
	}

	return (
		<div>
			<div className='inline-block minix:hidden'>
				<Header />
				<img
					src='https://i.imgur.com/jRdVJzy.png'
					className='w-[100vw] text-3.5xl lg:text-5xl drop-shadow-xl'
				></img>
				<div className='text-center mx-auto text-2xl mt-6'>
					<div className='text-green-600 font-bold outline-white text-border-2 text-border-white'>
						Make your packaging reusable
					</div>
					<div className='italic font-bold text-black-pure mt-1 '>
						It's easy with DeliverZero
					</div>
				</div>

				<div className='w-full'>
					<div className='mt-6 text-xl ml-4'>
						DeliverZero is a{' '}
						<span className='font-bold text-green-600 text-xl'>
							full-service reusable packaging solution
						</span>
						{` `}that meets your exact needs.
					</div>

					<div className='text-lg mb-2 ml-4 mt-6'>
						What do you need to run a reuse program?
					</div>
					<div className='mx-4'>
						<p className='ml-10 text-xs'>Select all that apply</p>
						<div className='text-base'>
							<div className='flex flex-row items-start mt-3'>
								<div
									style={{
										width: '1.5rem',
										height: '1.5rem',
									}}
								>
									<input
										type='checkbox'
										defaultChecked={false}
										onChange={(check) => {
											setTechnology(check.target.checked)
										}}
										className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
									/>
								</div>
								<p>
									<span className='font-bold text-green-600 ml-4'>
										Technology
									</span>
									{` `} to track your reusables &#8212;
									whether you use ours{` `}
									<span className='font-bold italic'>
										or your own
									</span>
								</p>
							</div>

							<div className='flex flex-row items-start mt-3'>
								<div
									style={{
										width: '1.5rem',
										height: '1.5rem',
									}}
								>
									<input
										type='checkbox'
										defaultChecked={false}
										onChange={(check) => {
											setLogistics(check.target.checked)
										}}
										className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
									/>
								</div>
								<p>
									<span className='font-bold text-green-600 ml-4'>
										Logistics
									</span>
									{` `}that make reuse seamless for you and
									your customers
								</p>
							</div>

							<div className='flex flex-row items-start mt-3'>
								<div
									style={{
										width: '1.5rem',
										height: '1.5rem',
									}}
								>
									<input
										type='checkbox'
										defaultChecked={false}
										onChange={(check) => {
											setSanitization(
												check.target.checked
											)
										}}
										className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
									/>
								</div>
								<p>
									<span className='font-bold text-green-600 ml-4'>
										Sanitization
									</span>
									{` `}solutions that go beyond compliance
								</p>
							</div>

							<div className='flex flex-row items-start mt-3'>
								<div
									style={{
										width: '1.5rem',
										height: '1.5rem',
									}}
								>
									<input
										type='checkbox'
										defaultChecked={false}
										onChange={(check) => {
											setDeliverZeroPackaging(
												check.target.checked
											)
										}}
										className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
									/>
								</div>
								<p>
									<span className='font-bold text-green-600 ml-4'>
										Production
									</span>
									{` `}capabilities to create custom packaging
									&#8212; or choose from our full catalog of
									reusables
								</p>
							</div>
						</div>
						<div className='mt-6 ml-4 w-[300px] mb-10'>
							<Input
								type='email'
								label='EMAIL'
								name='email'
								onChange={(e) => {
									setTypedEmail(e.target.value)
								}}
								className='bg-white border-2 border-gray-300 mb-3'
							/>
							{typedEmail.length > 0 ? (
								<div>
									{alertingSlack ? (
										<BounceLoader />
									) : (
										<Button
											text='Submit Inquiry'
											color='green'
											size='xs'
											onClick={submitInquiry}
											className='flex !text-xl mt-1'
										/>
									)}
									{alertedSlack && (
										<div className='text-green-600'>
											Inquiry successfully received! We'll
											reach out shortly
										</div>
									)}
								</div>
							) : (
								<Button
									text='Submit Inquiry'
									color='gray'
									size='xs'
									onClick={() => {}}
									className='flex !text-xl mt-1'
								/>
							)}
						</div>
					</div>
				</div>

				<img
					className='h-full w-full'
					src='https://i.imgur.com/4tPZ4kM.png'
					alt='DeliverZero Reusable Containers'
				/>
				<div className='w-full pb-10 '>
					<h1 className='ml-4 text-green-600 text-2xl mt-20 font-bold'>
						Why choose to reuse with DeliverZero?
					</h1>
					<div className='mt-5 ml-4 text-base'>
						<div className='flex flex-row items-start'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								<span className='font-bold text-green-600 '>
									Save money
								</span>
								{` `}on packaging costs
							</p>
						</div>
						<div className='flex flex-row items-start'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								Achieve best-in-class{` `}
								<span className='font-bold  text-green-600 '>
									return rates
								</span>
							</p>
						</div>
						<div className='flex flex-row items-start'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								<span className='font-bold  text-green-600 '>
									Track, meet, and exceed
								</span>
								{` `}your ESG goals
							</p>
						</div>
						<div className='flex flex-row items-start'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								<span className='font-bold  text-green-600 '>
									Easily add reuse
								</span>
								{` `}to your existing operations
							</p>
						</div>
					</div>
					<h1 className='ml-4 text-green-600 text-2xl mt-20 font-bold'>
						Who should use DeliverZero?
					</h1>
					<div className='mt-5 ml-4 text-base'>
						<div>
							DeliverZero was designed to track and recover
							packaging for all types of merchants. Whether
							you're...
						</div>
						<div className='flex flex-row items-start mt-2'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								An e-commerce brand shipping beauty products
								across the country
							</p>
						</div>
						<div className='flex flex-row items-start'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								A meal kit company delivering boxes of groceries
							</p>
						</div>
						<div className='flex flex-row items-start'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								A retailer offering bulk stations for food or
								household goods
							</p>
						</div>
						<div className='flex flex-row items-start'>
							<img
								className='w-[10px] mt-2'
								src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
							/>
							<p className='ml-2'>
								<span className='italic'>Or really</span>,{' '}
								<span className='text-green-600 font-bold'>
									any merchant selling any good in packaging
								</span>
							</p>
						</div>
					</div>
					<div className='mt-6 ml-4 w-[300px]'>
						<Input
							type='email'
							label='EMAIL'
							name='email'
							onChange={(e) => {
								setTypedEmail(e.target.value)
							}}
							className='bg-white border-2 border-gray-300 mb-3 w-[300px]'
						/>
						{typedEmail.length > 0 ? (
							<div>
								{alertingSlack ? (
									<BounceLoader />
								) : (
									<Button
										text='Join the circular economy'
										color='green'
										size='xs'
										onClick={submitInquiry}
										className='flex mt-1 !text-xl'
									/>
								)}
								{alertedSlack && (
									<div className='text-green-600'>
										Inquiry successfully received! We'll
										reach out shortly
									</div>
								)}
							</div>
						) : (
							<Button
								text='Join the circular economy'
								color='gray'
								size='xs'
								onClick={() => {}}
								className='flex !text-xl mt-1'
							/>
						)}
					</div>
				</div>
				<div className='h-auto flex flex-col justify-center items-center mt-3'>
					<h1 className='font-bold text-center text-green-600 text-2xl my-5'>
						We work with...
					</h1>
					<div className='grid grid-cols-2 place-content-center justify-items-center gap-5 mx-2 sm:hidden z-20'>
						<img
							src='images/PlatformPartners/ubereats.png'
							alt='ubereats logo'
							className='w-[140px] h-auto my-auto brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/wholefoods.png'
							alt='wholefoods logo'
							className='w-[80px] h-auto my-auto brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/otter.png'
							alt='otter logo'
							className='w-[100px] h-auto my-auto brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/amazon.png'
							alt='amazon logo'
							className='w-[120px] h-auto my-auto brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/toast.png'
							alt='toast logo'
							className='w-[120px] h-auto my-auto brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/caviar.png'
							alt='caviar logo'
							className='w-[120px] h-auto my-auto brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/itsacheckmate-large.png'
							alt='itsacheckmate logo'
							className='w-[100px] h-auto my-auto brightness-0 invert-1 sm:mb-5'
						/>
						<img
							src='images/PlatformPartners/olo.png'
							alt='olo logo'
							className='w-[80px] h-auto my-auto sm:mb-5'
						/>
						<div className='col-span-1'></div>
						<img
							src='images/PlatformPartners/doordash.png'
							alt='doordash logo'
							className='w-[220px] col-span-2 h-auto my-auto brightness-0 invert-1 mb-5'
						/>
					</div>
					<div className='sm:grid gap-5 grid-cols-3 max-w-[80%] flex flex-wrap justify-center items-center z-20 hidden mb-6'>
						<img
							src='images/PlatformPartners/ubereats.png'
							alt='ubereats logo'
							className='w-[140px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/wholefoods.png'
							alt='wholefoods logo'
							className='w-[80px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/otter.png'
							alt='otter logo'
							className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/amazon.png'
							alt='amazon logo'
							className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/toast.png'
							alt='toast logo'
							className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/caviar.png'
							alt='caviar logo'
							className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/itsacheckmate-large.png'
							alt='itsacheckmate logo'
							className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/doordash.png'
							alt='doordash logo'
							className='w-[220px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
						<img
							src='images/PlatformPartners/olo.png'
							alt='olo logo'
							className='w-[80px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
						/>
					</div>
					<div className='mb-10'></div>
					<img
						src='https://i.imgur.com/zE8CxtB.jpg'
						className='pb-10'
					/>
					<div className='mb-10'></div>
				</div>
			</div>
			<div className='hidden minix:inline-block'>
				<Header />
				<div
					style={containerStyle}
					className='h-[46vw] text-3.5xl lg:text-5xl drop-shadow-xl'
				>
					<div
						style={textStyle1}
						className='text-left w-[800px] drop-shadow-xl ml-10 mt-[24vw] md:mt-[28vw]'
					>
						<div className='text-green-600 font-bold outline-white text-border-2 text-border-white'>
							Make your packaging reusable
						</div>
						<div className='italic font-bold text-black-pure mt-1 '>
							It's easy with DeliverZero
						</div>
					</div>
				</div>

				<div className='flex md:flex-row drop-shadow-xl h-min'>
					<div className='w-full md:w-1/2'>
						<div className='mt-20 text-4xl mb-5 ml-10'>
							DeliverZero is a{' '}
							<span className='font-bold text-green-600 text-2.5xl'>
								full-service reusable packaging solution
							</span>
							{` `}that meets your exact needs.
						</div>

						<div className='text-2xl mb-2 ml-10 mt-6'>
							What do you need to run a reuse program?
						</div>
						<div className='mx-10'>
							<p className='ml-10 text-xs'>
								Select all that apply
							</p>
							<div className='text-lg'>
								<div className='flex flex-row items-start mt-3'>
									<div
										style={{
											width: '1.5rem',
											height: '1.5rem',
										}}
									>
										<input
											type='checkbox'
											defaultChecked={false}
											onChange={(check) => {
												setTechnology(
													check.target.checked
												)
											}}
											className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
										/>
									</div>
									<p>
										<span className='font-bold text-green-600 ml-4'>
											Technology
										</span>
										{` `} to track your reusables &#8212;
										whether you use ours{` `}
										<span className='font-bold italic'>
											or your own
										</span>
									</p>
								</div>

								<div className='flex flex-row items-start mt-3'>
									<div
										style={{
											width: '1.5rem',
											height: '1.5rem',
										}}
									>
										<input
											type='checkbox'
											defaultChecked={false}
											onChange={(check) => {
												setLogistics(
													check.target.checked
												)
											}}
											className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
										/>
									</div>
									<p>
										<span className='font-bold text-green-600 ml-4'>
											Logistics
										</span>
										{` `}that make reuse seamless for you
										and your customers
									</p>
								</div>

								<div className='flex flex-row items-start mt-3'>
									<div
										style={{
											width: '1.5rem',
											height: '1.5rem',
										}}
									>
										<input
											type='checkbox'
											defaultChecked={false}
											onChange={(check) => {
												setSanitization(
													check.target.checked
												)
											}}
											className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
										/>
									</div>
									<p>
										<span className='font-bold text-green-600 ml-4'>
											Sanitization
										</span>
										{` `}solutions that go beyond compliance
									</p>
								</div>

								<div className='flex flex-row items-start mt-3'>
									<div
										style={{
											width: '1.5rem',
											height: '1.5rem',
										}}
									>
										<input
											type='checkbox'
											defaultChecked={false}
											onChange={(check) => {
												setDeliverZeroPackaging(
													check.target.checked
												)
											}}
											className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
										/>
									</div>
									<p>
										<span className='font-bold text-green-600 ml-4'>
											Production
										</span>
										{` `}capabilities to create custom
										packaging &#8212; or choose from our
										full catalog of reusables
									</p>
								</div>
							</div>
							<div className='mt-6 ml-10 w-[300px] mb-10'>
								<Input
									type='email'
									label='EMAIL'
									name='email'
									onChange={(e) => {
										setTypedEmail(e.target.value)
									}}
									className='bg-white border-2 border-gray-300 mb-3'
								/>
								{typedEmail.length > 0 ? (
									<div>
										{alertingSlack ? (
											<BounceLoader />
										) : (
											<Button
												text='Submit Inquiry'
												color='green'
												size='sm'
												onClick={submitInquiry}
												className='flex !text-2xl mt-1'
											/>
										)}
										{alertedSlack && (
											<div className='text-green-600'>
												Inquiry successfully received!
												We'll reach out shortly
											</div>
										)}
									</div>
								) : (
									<Button
										text='Submit Inquiry'
										color='gray'
										size='sm'
										onClick={() => {}}
										className='flex !text-2xl mt-1'
									/>
								)}
							</div>
						</div>
					</div>
					<div className='hidden md:inline-block w-1/2'>
						<img
							className='overflow-hidden object-cover h-full w-full'
							src='https://i.imgur.com/4tPZ4kM.png'
							alt='DeliverZero Reusable Containers'
						/>
					</div>
				</div>
				<div className='inline-block md:hidden'>
					<img
						className='h-full w-full'
						src='https://i.imgur.com/4tPZ4kM.png'
						alt='DeliverZero Reusable Containers'
					/>
				</div>

				<div className='flex flex-row drop-shadow-xl h-min'>
					<div className='hidden md:inline-block w-1/2'>
						<img
							className='overflow-hidden object-cover h-full w-full'
							src='https://i.imgur.com/OGp3eyk.png'
							alt='DeliverZero Reusable Containers'
						/>
					</div>
					<div className='w-full md:w-1/2 pb-10 '>
						<h1 className='ml-10 text-green-600 text-2xl sm:text-4xl mt-20 font-bold'>
							Why choose to reuse with DeliverZero?
						</h1>
						<div className='mt-5 ml-10 text-lg'>
							<div className='flex flex-row items-start'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									<span className='font-bold text-green-600 '>
										Save money
									</span>
									{` `}on packaging costs
								</p>
							</div>
							<div className='flex flex-row items-start'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									Achieve best-in-class{` `}
									<span className='font-bold  text-green-600 '>
										return rates
									</span>
								</p>
							</div>
							<div className='flex flex-row items-start'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									<span className='font-bold  text-green-600 '>
										Track, meet, and exceed
									</span>
									{` `}your ESG goals
								</p>
							</div>
							<div className='flex flex-row items-start'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									<span className='font-bold  text-green-600 '>
										Easily add reuse
									</span>
									{` `}to your existing operations
								</p>
							</div>
						</div>
						<h1 className='ml-10 text-green-600 text-2xl sm:text-4xl mt-20 font-bold'>
							Who should use DeliverZero?
						</h1>
						<div className='mt-5 ml-10 text-lg'>
							<div>
								DeliverZero was designed to track and recover
								packaging for all types of merchants. Whether
								you're...
							</div>
							<div className='flex flex-row items-start mt-2'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									An e-commerce brand shipping beauty products
									across the country
								</p>
							</div>
							<div className='flex flex-row items-start'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									A meal kit company delivering boxes of
									groceries
								</p>
							</div>
							<div className='flex flex-row items-start'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									A retailer offering bulk stations for food
									or household goods
								</p>
							</div>
							<div className='flex flex-row items-start'>
								<img
									className='w-[10px] mt-2'
									src='https://cdn2.iconfinder.com/data/icons/pinpoint-interface/48/dot-2-1024.png'
								/>
								<p className='ml-2'>
									<span className='italic'>Or really</span>,{' '}
									<span className='text-green-600 font-bold'>
										any merchant selling any good in
										packaging
									</span>
								</p>
							</div>
						</div>
						<div className='mt-6 ml-10 w-[300px]'>
							<Input
								type='email'
								label='EMAIL'
								name='email'
								onChange={(e) => {
									setTypedEmail(e.target.value)
								}}
								className='bg-white border-2 border-gray-300 mb-3 w-[300px]'
							/>
							{typedEmail.length > 0 ? (
								<div>
									{alertingSlack ? (
										<BounceLoader />
									) : (
										<Button
											text='Join the circular economy'
											color='green'
											size='sm'
											onClick={submitInquiry}
											className='flex !text-2xl mt-1'
										/>
									)}
									{alertedSlack && (
										<div className='text-green-600'>
											Inquiry successfully received! We'll
											reach out shortly
										</div>
									)}
								</div>
							) : (
								<Button
									text='Join the circular economy'
									color='gray'
									size='sm'
									onClick={() => {}}
									className='flex !text-2xl mt-1'
								/>
							)}
						</div>
					</div>
				</div>

				<div className=''>
					<div className='text-green-600 xl:mb-2 mt-20 mb-20'>
						<div className='h-auto flex flex-col justify-center items-center mt-3'>
							<h1 className='font-bold text-center text-green-600 text-2xl sm:text-4xl my-10'>
								We work with...
							</h1>
							<div className='grid grid-cols-2 place-content-center justify-items-center gap-5 mx-2 sm:hidden z-20'>
								<img
									src='images/PlatformPartners/ubereats.png'
									alt='ubereats logo'
									className='w-[140px] h-auto my-auto brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/wholefoods.png'
									alt='wholefoods logo'
									className='w-[80px] h-auto my-auto brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/otter.png'
									alt='otter logo'
									className='w-[100px] h-auto my-auto brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/amazon.png'
									alt='amazon logo'
									className='w-[120px] h-auto my-auto brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/toast.png'
									alt='toast logo'
									className='w-[120px] h-auto my-auto brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/caviar.png'
									alt='caviar logo'
									className='w-[120px] h-auto my-auto brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/itsacheckmate-large.png'
									alt='itsacheckmate logo'
									className='w-[100px] h-auto my-auto brightness-0 invert-1 sm:mb-5'
								/>
								<img
									src='images/PlatformPartners/olo.png'
									alt='olo logo'
									className='w-[80px] h-auto my-auto sm:mb-5'
								/>
								<div className='col-span-1'></div>
								<img
									src='images/PlatformPartners/doordash.png'
									alt='doordash logo'
									className='w-[220px] col-span-2 h-auto my-auto brightness-0 invert-1 mb-5'
								/>
							</div>
							<div className='sm:grid gap-5 grid-cols-3 max-w-[80%] flex flex-wrap justify-center items-center z-20 hidden mb-6'>
								<img
									src='images/PlatformPartners/ubereats.png'
									alt='ubereats logo'
									className='w-[140px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/wholefoods.png'
									alt='wholefoods logo'
									className='w-[80px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/otter.png'
									alt='otter logo'
									className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/amazon.png'
									alt='amazon logo'
									className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/toast.png'
									alt='toast logo'
									className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/caviar.png'
									alt='caviar logo'
									className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/itsacheckmate-large.png'
									alt='itsacheckmate logo'
									className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/doordash.png'
									alt='doordash logo'
									className='w-[220px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
								<img
									src='images/PlatformPartners/olo.png'
									alt='olo logo'
									className='w-[80px] h-auto my-auto mx-auto flex justify-center items-center brightness-0 invert-1'
								/>
							</div>
							<div className='mb-20'></div>
						</div>
					</div>
				</div>
				<div
					className='h-screen-3/4 drop-shadow-xl '
					style={{
						backgroundImage: 'url("https://i.imgur.com/zE8CxtB.jpg',
						backgroundSize: 'cover',
						backgroundPosition: 'center', // Adjust as needed
						position: 'relative', // Text color
						paddingTop: '50px',
					}}
				>
					<div
						style={textStyle}
						className='flex flex-col text-4xl hidden md:inline-block mt-[1.25vw] drop-shadow-xl text-black-pure ml-[45vw]'
					>
						<div>
							<span className='font-bold text-green-600'>
								Deliver
							</span>
							{` `} in reusable packaging
						</div>
						<div>
							<span className='font-bold text-green-600'>
								Deliver
							</span>
							{` `} on customers' climate expectations
						</div>
						<div>
							<span className='font-bold text-green-600'>
								Deliver
							</span>
							{` `} seamless logistics
						</div>
						<div className='font-bold'>
							<span className='text-green-600'>Deliver</span>Zero
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</div>
	)
}

const mapStateToProps = ({ Admin }) => ({
	alertingSlack: Admin.alertingSlack,
	alertedSlack: Admin.alertedSlack,
	alertSlackError: Admin.alertSlackError,
})

const mapDispatchToProps = (dispatch) => ({
	onAlertSlack: (payload) => dispatch(alertSlack(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RaasLanding)
