import {
	GET_USER,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	GET_USER_BOX_COUNT,
	GET_USER_BOX_COUNT_SUCCESS,
	GET_USER_BOX_COUNT_FAIL,
	UPDATE_USER,
	UPDATE_USER_FAIL,
	UPDATE_USER_SUCCESS,
	VALIDATE_PHONE_SUCCESS,
	VALIDATE_PHONE,
	VALIDATE_PHONE_FAIL,
	UPDATE_MAILCHIMP_MERGE_FIELDS,
	UPDATE_MAILCHIMP_MERGE_FIELDS_SUCCESS,
	UPDATE_MAILCHIMP_MERGE_FIELDS_FAIL,
	GET_USER_BY_PHONE,
	GET_USER_BY_PHONE_ONLY,
	GET_USER_BY_USERID_PHONE,
	UPDATE_USER_BY_PHONE,
	CAPTURE_USER,
	CAPTURE_USER_SUCCESS,
	CAPTURE_USER_FAIL,
	CAPTURE_IP,
	CAPTURE_IP_FAIL,
	CAPTURE_IP_SUCCESS,
	GET_OVERDUE_BOX_COUNT,
	GET_OVERDUE_BOX_COUNT_FAIL,
	GET_OVERDUE_BOX_COUNT_SUCCESS,
	UPDATE_OPT_OUT_PREFERENCES,
	UPDATE_OPT_OUT_PREFERENCES_SUCCESS,
	UPDATE_OPT_OUT_PREFERENCES_FAIL,
	RESET_PREFERENCES_UPDATED,
} from './actionTypes'

export const getUser = () => {
	return {
		type: GET_USER,
	}
}

export const getUserSuccess = (user) => {
	return {
		type: GET_USER_SUCCESS,
		payload: user,
	}
}

export const getUserFail = (error) => {
	return {
		type: GET_USER_FAIL,
		payload: error,
	}
}

export const getUserBoxCount = (payload) => {
	return {
		type: GET_USER_BOX_COUNT,
		payload: payload,
	}
}

export const getUserBoxCountSuccess = (user) => {
	return {
		type: GET_USER_BOX_COUNT_SUCCESS,
		payload: user,
	}
}

export const getUserBoxCountFail = (error) => {
	return {
		type: GET_USER_BOX_COUNT_FAIL,
		payload: error,
	}
}

export const updateUser = (payload) => {
	return {
		type: UPDATE_USER,
		payload: payload,
	}
}

export const updateUserSuccess = (user) => {
	return {
		type: UPDATE_USER_SUCCESS,
		payload: user,
	}
}

export const updateUserFail = (error) => {
	return {
		type: UPDATE_USER_FAIL,
		payload: error,
	}
}

export const validatePhone = (phone) => {
	return {
		type: VALIDATE_PHONE,
		payload: phone,
	}
}

export const validatePhoneSuccess = (validatedPhoneBool) => {
	return {
		type: VALIDATE_PHONE_SUCCESS,
		payload: validatedPhoneBool,
	}
}

export const validatePhoneFail = (error) => {
	return {
		type: VALIDATE_PHONE_FAIL,
		payload: error,
	}
}

export const updateMailchimpMergeFields = (payload) => {
	return {
		type: UPDATE_MAILCHIMP_MERGE_FIELDS,
		payload: payload,
	}
}

export const updateMailchimpMergeFieldsSuccess = (user) => {
	return {
		type: UPDATE_MAILCHIMP_MERGE_FIELDS_SUCCESS,
		payload: user,
	}
}

export const updateMailchimpMergeFieldsFail = (error) => {
	return {
		type: UPDATE_MAILCHIMP_MERGE_FIELDS_FAIL,
		payload: error,
	}
}

export const getUserByPhone = (phone) => {
	return {
		type: GET_USER_BY_PHONE,
		payload: phone,
	}
}

export const getUserByPhoneOnly = (phone) => {
	return {
		type: GET_USER_BY_PHONE_ONLY,
		payload: phone,
	}
}

export const getUserByUserIdPhone = (phone) => {
	return {
		type: GET_USER_BY_USERID_PHONE,
		payload: phone,
	}
}

export const updateUserByPhone = (payload) => {
	return {
		type: UPDATE_USER_BY_PHONE,
		payload: payload,
	}
}

export const captureUser = (payload) => {
	return {
		type: CAPTURE_USER,
		payload: payload,
	}
}

export const captureUserSuccess = (user) => {
	return {
		type: CAPTURE_USER_SUCCESS,
		payload: user,
	}
}

export const captureUserFail = (error) => {
	return {
		type: CAPTURE_USER_FAIL,
		payload: error,
	}
}

export const captureIP = (payload) => {
	return {
		type: CAPTURE_IP,
		payload: payload,
	}
}

export const captureIPSuccess = (user) => {
	return {
		type: CAPTURE_IP_SUCCESS,
		payload: user,
	}
}

export const captureIPFail = (error) => {
	return {
		type: CAPTURE_IP_FAIL,
		payload: error,
	}
}

export const getOverdueBoxCount = (payload) => {
	return {
		type: GET_OVERDUE_BOX_COUNT,
		payload: payload,
	}
}

export const getOverdueBoxCountSuccess = (payload) => {
	return {
		type: GET_OVERDUE_BOX_COUNT_SUCCESS,
		payload: payload,
	}
}

export const getOverdueBoxCountFail = (error) => {
	return {
		type: GET_OVERDUE_BOX_COUNT_FAIL,
		payload: error,
	}
}

export const updateOptOutPreferences = (payload) => {
	return {
		type: UPDATE_OPT_OUT_PREFERENCES,
		payload: payload,
	}
}

export const updateOptOutPreferencesSuccess = (payload) => {
	return {
		type: UPDATE_OPT_OUT_PREFERENCES_SUCCESS,
		payload: payload,
	}
}

export const updateOptOutPreferencesFail = (error) => {
	return {
		type: UPDATE_OPT_OUT_PREFERENCES_FAIL,
		payload: error,
	}
}

export const resetPreferencesUpdated = () => ({
	type: RESET_PREFERENCES_UPDATED,
})
