import { GET_HIW, GET_HIW_SUCCESS, GET_HIW_FAIL } from './actionTypes'

const initialState = {
	howItWorks: [],
	error: null,
	loadingHIW: false,
}

const howItWorks = (state = initialState, action) => {
	switch (action.type) {
		case GET_HIW:
			return {
				...state,
				loadingHIW: true,
			}

		case GET_HIW_SUCCESS:
			return {
				...state,
				loadingHIW: false,
				howItWorks: action.payload,
			}

		case GET_HIW_FAIL:
			return {
				...state,
				loadingHIW: false,
				error: action.payload,
			}

		default:
			return state
	}
}

export default howItWorks
