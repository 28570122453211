import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	createTransaction,
	resetTransaction,
	getAdminCustomers,
	getRlPartners,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { Button, Input } from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import {} from '../../../model/reverseLogistics'
import DatePicker from 'react-datepicker'
// import { Header } from '../../components'

const AdminCreateReturn = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetNodes,
		nodes,
		rLPartners,
		partnersError,
		loadingPartners,
		onGetRlPartners,
		onGetCustomers,
		loadingCustomers,
		customers,
		onCreateTransaction,
		transaction,
		createTransactionError,
		creatingTransaction,
		onResetTransaction,
	} = state

	const [loadedNodes, setLoadedNodes] = useState([])
	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [selectedCustomer, setSelectedCustomer] = useState({})
	const [typedCustomerSearch, setTypedCustomerSearch] = useState('')
	const [customerSearchResults, setCustomerSearchResults] = useState([])
	const [selectedNode, setSelectedNode] = useState({})
	const [typedNodeSearch, setTypedNodeSearch] = useState('')
	const [nodeSearchResults, setNodeSearchResults] = useState([])
	const [selectedRLPartner, setSelectedRLPartner] = useState({})
	const [typedRLPartnerSearch, setTypedRLPartnerSearch] = useState('')
	const [filteredNodes, setFilteredNodes] = useState([])
	const [rLPartnerSearchResults, setRLPartnerSearchResults] = useState([])
	const [qty, setQty] = useState(0)
	const [errorMessage, setErrorMessage] = useState('')
	const [returnDate, setReturnDate] = useState(new Date())
	const [initialLoad, setInitialLoad] = useState(true)

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetNodes([
					{
						franchiseeSlug: user.franchiseeSlug,
					},
				])
				onGetCustomers({
					filter: {
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							lastUse: -1,
						},
						limit: 1000000,
						skip: 0,
					},
				})
				setInitialLoad(false)
			} else {
				onGetNodes({
					filter: {},
					options: {
						sort: {},
						limit: 1000000,
						skip: 0,
					},
				})
				onGetCustomers({
					filter: {},
					options: {
						sort: {
							lastUse: -1,
						},
						limit: 1000000,
						skip: 0,
					},
				})
				setInitialLoad(false)
			}
		} else {
			onGetUser()
		}
	}, [user])

	useEffect(() => {
		onGetRlPartners({
			filter: {},
			options: {
				sort: {},
				limit: 1000000,
				skip: 0,
			},
		})
	}, [onGetRlPartners])

	// useEffect(() => {
	// 	// console.log(filter)
	// 	onGetNodes([filter])
	// }, [filter])

	useEffect(() => {
		if (!initialLoad) {
			nodes.sort((a, b) => (a.balance > b.balance ? 1 : -1))
			setLoadedNodes(nodes)
		}
	}, [nodes])

	useEffect(() => {
		if (typedCustomerSearch !== '') {
			const closestMatches = customers.filter(
				(customer) =>
					customer.email
						.toLowerCase()
						.indexOf(typedCustomerSearch) !== -1 ||
					customer.phone
						.toLowerCase()
						.indexOf(typedCustomerSearch) !== -1
			)
			setCustomerSearchResults(closestMatches.slice(0, 5))
		} else {
			setCustomerSearchResults([])
		}
	}, [typedCustomerSearch])

	useEffect(() => {
		if (typedNodeSearch !== '') {
			const closestMatches = nodes.filter(
				(txt) => txt.name.toLowerCase().indexOf(typedNodeSearch) !== -1
			)
			setNodeSearchResults(closestMatches.slice(0, 5))
		} else {
			setNodeSearchResults([])
		}
	}, [typedNodeSearch])

	useEffect(() => {
		if (typedRLPartnerSearch !== '') {
			const closestMatches = rLPartners.filter(
				(txt) =>
					txt.partner.toLowerCase().indexOf(typedRLPartnerSearch) !==
					-1
			)
			setRLPartnerSearchResults(closestMatches.slice(0, 5))
		} else {
			setRLPartnerSearchResults([])
		}
	}, [typedRLPartnerSearch])

	// useEffect(() => {
	// 	const nodesArray = []
	// 	nodes.map((node) => {
	// 		if(user && user.franchiseeSlug){
	// 			if(node.franchiseeSlug){
	// 				nodesArray.push(node)
	// 			}
	// 		} else {
	// 			nodesArray.push(node)
	// 		}
	// 	})
	// 	setFilteredNodes(nodesArray)
	// 	setInitialLoad(false)
	// }, [nodes])

	if (!user || initialLoad)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	const submitTransaction = (e) => {
		e.preventDefault()
		setErrorMessage('')
		if (returnDate) {
			const transactionPayload = {
				timestamp: returnDate,
				type: 'boxesIn',
				boxCount: qty,
				items: {},
				user: {
					phone: selectedCustomer.phone,
					email: selectedCustomer.email,
					firstName: selectedCustomer.firstName,
					lastName: selectedCustomer.lastName,
				},
				node: selectedNode.slug,
				returnByAdmin: true,
			}

			if (qty > 0) {
				onCreateTransaction(transactionPayload)
				// console.log(transactionPayload)
			} else if (qty === 0) {
				setErrorMessage('Please enter a quantity greater than 0')
				// console.log(errorMessage)
			}
		} else {
			setErrorMessage('Please select a return date')
		}
	}

	if (user.admin) {
		// if(nodes.length > 0){
		return (
			<div>
				<Header />
				<div
					className='underline font-bold m-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Create a Return
				</h1>
				<div className='flex flex-col mx-4 m-auto justify-center mb-8'>
					<div className='mt-6 font-bold'>
						Search a customer email or phone
					</div>
					{customers && customers.length > 0 && (
						<Input
							type='text'
							label=''
							name='item-search'
							placeholder='e.g. "bob@gmail.com or phone"'
							value={typedCustomerSearch}
							className='bg-white border-2 border-gray-300'
							onChange={(e) => {
								setTypedCustomerSearch(
									e.target.value.toLowerCase()
								)
							}}
						/>
					)}
					{user.franchiseeSlug ? (
						<div>
							{customerSearchResults.map((customer, index) => {
								return (
									<div
										className='flex flex-row my-1'
										key={index}
									>
										<div className='w-[350px] overflow-x-hidden'>
											{customer.firstName}
											{` `}
											{customer.lastName}
										</div>
										<Button
											className='items-center'
											size='xs'
											text='Select'
											onClick={() => {
												setSelectedCustomer(customer)
											}}
										/>
									</div>
								)
							})}
						</div>
					) : (
						<div>
							{customerSearchResults.map((customer, index) => {
								return (
									<div
										className='flex flex-row my-1'
										key={index}
									>
										<div className='w-[350px] overflow-x-hidden'>
											{customer.firstName}
											{` `}
											{customer.email}
											{` `}
											{customer.phone}
										</div>
										<Button
											className='items-center'
											size='xs'
											text='Select'
											onClick={() => {
												setSelectedCustomer(customer)
											}}
										/>
									</div>
								)
							})}
						</div>
					)}
					<Button
						text='Clear customer'
						size='xs'
						className='my-2'
						onClick={() => {
							setSelectedCustomer({})
							setTypedCustomerSearch('')
						}}
					/>
					<div className='mt-6 font-bold'>Search a restaurant</div>
					{nodes && nodes.length > 0 && (
						<Input
							type='text'
							label=''
							name='item-search'
							placeholder='e.g. "Bombay Kabab"'
							value={typedNodeSearch}
							className='bg-white border-2 border-gray-300'
							onChange={(e) => {
								setTypedNodeSearch(e.target.value.toLowerCase())
							}}
						/>
					)}
					<div>
						{nodeSearchResults.map((node, index) => {
							return (
								<div
									className='flex flex-row my-1'
									key={index}
								>
									<div className='w-[350px] overflow-x-hidden'>
										{node.name}
									</div>
									<Button
										className='items-center'
										size='xs'
										text='Select'
										onClick={() => {
											setSelectedNode(node)
											setSelectedRLPartner({})
										}}
									/>
								</div>
							)
						})}
					</div>
					<Button
						text='Clear restaurant'
						size='xs'
						className='my-2'
						onClick={() => {
							setSelectedNode({})
							setTypedNodeSearch('')
						}}
					/>

					<div className='mt-4'>Select a Return Date</div>
					<div className='mb-2 border border-gray-400'>
						<DatePicker
							selected={returnDate}
							onChange={(date) => setReturnDate(date)}
						/>
					</div>

					<div className='text-lg my-4'>
						<span className='font-bold'>Customer: </span>
						{selectedCustomer.name}
						{` `}
						{selectedCustomer.email}
					</div>
					{Object.keys(selectedNode).length > 0 && (
						<div className='text-lg my-4'>
							<span className='font-bold'>Restaurant: </span>
							{selectedNode.name}
						</div>
					)}

					{Object.keys(selectedRLPartner).length > 0 && (
						<div className='text-lg my-4'>
							<span className='font-bold'>
								Reverse Logistics Partner:{' '}
							</span>
							{selectedRLPartner.partner}
						</div>
					)}
					<div className='mt-6 font-bold '>Quantity:</div>
					<div className='flex flex-row w-[180px] justify-between'>
						<span
							color='default'
							className='text-4xl text-gray-300 w-1/3 cursor-pointer mx-auto'
							onClick={() => {
								if (qty >= 2) {
									setQty(qty - 1)
								}
							}}
						>
							-
						</span>
						<div className='text-2xl  mx-auto'>{qty}</div>
						<span
							color='default'
							className='text-2xl text-gray-300 w-1/3 cursor-pointer mx-auto'
							onClick={() => {
								// if (qty < user.boxCount)
								setQty(qty + 1)
							}}
						>
							+
						</span>
					</div>
					{Object.keys(selectedNode).length > 0 &&
					Object.keys(selectedNode).length > 0 ? (
						<div>
							{creatingTransaction ? (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							) : (
								<Button
									className='my-4'
									text='Submit Return'
									size='sm'
									onClick={(e) => submitTransaction(e)}
								/>
							)}
						</div>
					) : (
						<Button
							className='my-4'
							color='gray'
							text='Submit Return'
							size='sm'
							onClick={() => {}}
						/>
					)}
					{createTransactionError && (
						<div className='text-red-400 font-bold my-3'>
							{createTransactionError.error}
						</div>
					)}
					{errorMessage !== '' && (
						<div className='text-red-400 font-bold my-3'>
							{errorMessage}
						</div>
					)}
					{transaction && (
						<div className='text-green-600 font-bold'>
							Success! You may now submit another
						</div>
					)}
				</div>
				<Footer />
			</div>
		)
		// } else {
		// 	return (
		// 		<div className='flex items-center h-screen'>
		// 			<BounceLoader
		// 				className='m-auto'
		// 				color={bounceLoaderColor}
		// 			></BounceLoader>
		// 		</div>
		// 	)
		// }
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	ReverseLogistics,
	TransactionsCreate,
	Admin,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	rLPartners: ReverseLogistics.partners,
	loadingPartners: ReverseLogistics.loadingPartners,
	partnersError: ReverseLogistics.partnersError,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
	customers: Admin.customers,
	customersCount: Admin.customersCount,
	loadingCustomers: Admin.loadingCustomers,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onGetRlPartners: () => dispatch(getRlPartners()),
	onGetCustomers: (payload) => dispatch(getAdminCustomers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminCreateReturn)
