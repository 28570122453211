import React from 'react'
import { Button, BounceLoaderCentered } from '../Elements'

const ContainerCountCard = ({ boxCount = null }) => {
	return (
		// <div className='max-w-xs mb-10 m-auto  md:mx-0 min-w-[300px] md:min-w-[360px] lg:min-w-[360px] h-[350px] shadow-lighter-grey w-full flex sm:flex-row justify-between rounded-xl bg-white p-6'>
		<div className='card '>
			<div className='text-center justify-center items-center'>
				<h3 className='mt-2 mb-3 block font-header text-center text-2xl sm:text-3xl text-green-400'>
					Containers currently out
				</h3>
				<div className='mt-5 flex flex-col text-center items-center justify-center'>
					<div className='mb-3 text-5xl rounded-[64px] font-header px-9 w-min bg-gray-100 text-center m-auto py-3 text-orange-600'>
						{boxCount}
					</div>
					<Button
						link='/returns'
						className='px-10 mt-5 shadow-light-grey'
						text='Return Boxes'
						size='sm'
					/>
				</div>
			</div>
		</div>
	)
}

export default ContainerCountCard
