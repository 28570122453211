import React from 'react'
import { Button } from '../Elements'

const ReusablesModal = ({ isOpen, onClose, children }) => {
	if (!isOpen) return null

	return (
		<div className='fixed inset-0 border-8 border-green-600 bg-white z-20 flex justify-center items-center'>
			<div className='bg-white w-full h-full p-6 rounded-lg shadow-lg'>
				<div className='flex justify-end'>
					<Button
						color='red'
						text='Close'
						size='sm'
						onClick={onClose}
					/>
				</div>
				<div className='overflow-auto h-full'>{children}</div>
			</div>
		</div>
	)
}

export default ReusablesModal
