import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Button } from '../../components/Elements'
import { useNavigate, useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import {
	getOneCustomerOffice,
	getUser,
	getOffice,
	getTransactions,
	getAdminCustomers,
} from '../../store/actions'

const OfficeViewOneOrder = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		office,
		onGetOffice,
		onGetOneCustomer,
		loadingCustomer,
		customer,
		transactions,
		transactionsCount,
		loadingTransactions,
		onGetTransactions,
	} = state

	const { phone } = useParams()
	const bounceLoaderColor = '#507f74'
	const paginationLimit = 50
	const [page, setPage] = useState(1)
	const [date, setDate] = useState('')
	const [loading, setLoading] = useState(true)

	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
		onGetOneCustomer({
			filter: {
				phone: phone,
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}, [onGetUser])

	useEffect(() => {
		if (user && user.officeSlug) {
			onGetOffice(user.officeSlug)
		}
	}, [user])

	const getTransactions = (page) => {
		onGetTransactions({
			filter: {
				'user.phone': phone,
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const transactionPaginate = () => {
		getTransactions(page)
	}

	useEffect(() => {
		if (phone) {
			transactionPaginate()
		}
	}, [phone])

	useEffect(() => {
		if (phone) {
			transactionPaginate()
		}
	}, [page])

	useEffect(() => {
		if (customer) {
			transactionPaginate()
		}
	}, [page])

	useEffect(() => {
		setLoading(false)
	}, [customer])

	const openSupport = () => {
		window.Intercom('show')
	}

	if (!user)
		return (
			<div>
				<Header />
				<div className='flex items-center h-screen-no-header'>
					<BounceLoader
						className='m-auto w-full'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</div>
		)

	if (user.officeSlug) {
		if (customer && office) {
			const isAggregatedOrdering = office.aggregatedOrdering === true
			return (
				<>
					<Header />
					{isAggregatedOrdering ? (
						<div
							className='underline font-bold my-4 cursor-pointer ml-6'
							onClick={() => {
								navigate('/office-ordering/employees')
							}}
						>
							&#8592; Back to Office Locations Dashboard
						</div>
					) : (
						<div
							className='underline font-bold my-4 cursor-pointer ml-6'
							onClick={() => {
								navigate('/office-ordering/employees')
							}}
						>
							&#8592; Back to Employee Dashboard
						</div>
					)}
					<div className='flex flex-col w-full justify-center mx-auto px-6'>
						{isAggregatedOrdering ? (
							<h1 className='font-header text-green-600 text-xl sm:text-2xl md:text-4xl mb-3 sm:mb-9 mt-5 sm:mt-14 text-center'>
								Office Location Activity
							</h1>
						) : (
							<h1 className='font-header text-green-600 text-xl sm:text-2xl md:text-4xl mb-3 sm:mb-9 mt-5 sm:mt-14 text-center'>
								Employee Activity
							</h1>
						)}
						<div className='flex flex-col sm:mx-[30%]'>
							<div className='flex flex-row'>
								<div className='text-green-600'>Name:</div>
								<div className='ml-2'>
									{customer.firstName} {customer.lastName}
								</div>
							</div>
							<div className='flex flex-row'>
								<div className='text-green-600'>Phone:</div>
								<div className='ml-2'>{customer.phone}</div>
							</div>
							<div className='flex flex-row'>
								<div className='text-green-600'>Email:</div>
								<div className='ml-2'>
									<div className='w-[200px] overflow-hidden'>
										{customer.email}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='w-full'>
						<div className='flex flex-col max-w-[1200px] max-h-[900px] justify-center mb-10 overflow-x-auto mx-auto overflow-y-auto scrollbar'>
							<div className='w-full'>
								<div>
									<div className='min-w-[345px] w-[345px] sm:w-[640px] px-2 sm:px-5 py-5 my-6 shadow-light-grey mx-auto'>
										<div className='flex flex-row min-w-[325px] w-[325px] sm:w-[600px] mb-3 px-2 sm:px-5 text-green-600 text-left font-bold'>
											<div className='font-bold text-sm sm:text-base min-w-[60px] w-[60px] sm:w-[100px] mr-2 sm:mr-4'>
												Date
											</div>
											<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
												Restaurant
											</div>
											<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px]'>
												Transactions
											</div>
										</div>
										{loadingCustomer && (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										)}
										{transactions.map((transaction) => {
											const transactionDate = new Date(
												transaction.timestamp
											)
											const transactionDateString =
												transactionDate.toLocaleString(
													'default',
													{
														month: 'short',
														day: 'numeric',
														year: 'numeric',
													}
												)
											return (
												<div
													className='min-w-[325px] w-[325px] sm:w-[600px] flex flex-row my-2 even:bg-gray-100 py-1 px-2 sm:px-5'
													key={transaction._id}
												>
													<div className='min-w-[60px] w-[60px] sm:w-[100px] text-xs sm:text-base mr-2 sm:mr-4 pt-2'>
														{transactionDateString}
													</div>
													<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base mr-2 sm:mr-4 pt-2'>
														{
															transaction.nodeInfo
																.name
														}
													</div>
													<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2'>
														{transaction.type ===
														'boxesIn' ? (
															transaction.latePayment ? (
																<div className='text-green-600'>
																	{
																		transaction.boxCount
																	}{' '}
																	paid for by
																	user
																</div>
															) : (
																<div className='text-green-600'>
																	{
																		transaction.boxCount
																	}{' '}
																	boxes
																	returned by
																	user
																</div>
															)
														) : (
															<div
																className='text-orange-600 underline'
																onClick={() => {
																	const url = `/office-ordering/orders/${transaction._id}`
																	navigate(
																		url
																	)
																}}
															>
																{
																	transaction.boxCount
																}{' '}
																boxes given to
																user
															</div>
														)}
													</div>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
					{transactions.length > paginationLimit ? (
						<div className='flex flex-row'>
							<div
								className='mx-auto underline font-bold mr-6 cursor-pointer'
								onClick={() => {
									setPage(page - 1)
								}}
							>
								Previous {paginationLimit} transactions
							</div>
							<div
								className='mx-auto underline font-bold cursor-pointer'
								onClick={() => {
									setPage(page + 1)
									setLoading(true)
								}}
							>
								Next {paginationLimit} transactions
							</div>
						</div>
					) : (
						<div></div>
					)}
					<div className='flex w-full flex-col justify-center mb-10 mx-auto'>
						<div className='font-bold text-center mb-2'>
							See an error in any of the transactions?
						</div>
						<div>
							<Button
								className='w-[200px] text-base flex justify-center m-auto'
								size='sm'
								text='Contact our office support team'
								onClick={openSupport}
							/>
						</div>
					</div>
					<Footer />
				</>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, OfficeOrdering, Transactions }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	office: OfficeOrdering.office,
	loadingCustomer: OfficeOrdering.loadingCustomer,
	customer: OfficeOrdering.oneCustomerOffice,
	customerError: OfficeOrdering.error,
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOffice: (officeSlug) => dispatch(getOffice(officeSlug)),
	onGetOneCustomer: (payload) => dispatch(getOneCustomerOffice(payload)),
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetCustomers: (payload) => dispatch(getAdminCustomers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeViewOneOrder)
