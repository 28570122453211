export const GET_APP_INFO = 'GET_APP_INFO'
export const GET_APP_INFO_SUCCESS = 'GET_APP_INFO_SUCCESS'
export const GET_APP_INFO_FAILURE = 'GET_APP_INFO_FAILURE'

export const GET_BANNER = 'GET_BANNER'
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS'
export const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE'

export const HIDE_BANNER = 'HIDE_BANNER'

export const RUN_MONGO_FUNCTION = 'RUN_MONGO_FUNCTION';
export const RUN_MONGO_FUNCTION_SUCCESS = 'RUN_MONGO_FUNCTION_SUCCESS';
export const RUN_MONGO_FUNCTION_ERROR = 'RUN_MONGO_FUNCTION_ERROR';
