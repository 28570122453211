import React, { useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BounceLoader } from 'react-spinners'
import { Footer, Header, LatePaymentsForm } from '../../components'
import { connect } from 'react-redux'
import { getAppInfo } from '../../store/actions'

const LatePayments = (props) => {
	const { onGetAppInfo, appInfo } = props
	const bounceLoaderColor = '#507f74'

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

	useEffect(() => {
		onGetAppInfo()
	}, [])

	if (!appInfo || !appInfo.containerLateFee) {
		return (
			<div>
				<Header />
				<div className='flex items-center h-screen-no-header'>
					<BounceLoader
						className='m-auto w-full'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</div>
		)
	} else {
		return (
			<>
				<Header />

				<div className='flex flex-col justify-center items-center text-center h-full w-screen px-4 py-12'>
					<h1 className='font-header text-3xl md:text-5xl mb-12 text-green-600'>
						Late or Lost Containers
					</h1>
					<p className='w-1/2 text-center min-w-[300px] mb-12 text-sm md:text-base'>
						For any overdue or misplaced containers, the fee of $
						{appInfo.containerLateFee} per container can be paid
						below.
					</p>
					<div className='w-full flex flex-col justify-center items-center mb-12'>
						<Elements stripe={stripePromise}>
							<LatePaymentsForm />
						</Elements>
					</div>
				</div>
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ AppInfo }) => ({
	appInfo: AppInfo.appInfo,
})

const mapDispatchToProps = (dispatch) => ({
	onGetAppInfo: () => dispatch(getAppInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LatePayments)
