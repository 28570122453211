import React from 'react'
import { Button } from '../Elements'
import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
	DotGroup,
	Dot,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

/* Install pure-react-carousel using -> npm i pure-react-carousel */

const Carousel = () => {
	return (
		<div className='2xl:mx-auto 2xl:container flex justify-center w-screen '>
			<div className='2xl:px-20 my-2 w-full xl:w-4/5 '>
				{/* Carousel for Small-Sized Screen */}
				<CarouselProvider
					className='relative block lg:hidden'
					naturalSlideWidth={5}
					isIntrinsicHeight={true}
					totalSlides={3}
					visibleSlides={1}
					step={1}
					infinite={true}
				>
					<div className='js-flickity flex justify-center items-center '>
						<ButtonBack
							role='button'
							aria-label='slide backward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full bg-white/75 flex justify-center bottom-45 items-center absolute z-30 left-0 ml-8 focus:outline-none  focus:ring-2  focus:ring-gray-200 cursor-pointer'
							id='prev'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7 1L1 7L7 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonBack>
						<Slider>
							<Slide index={0}>
								{/* ORDER NOW */}
								<div className='w-[350px] lg:w-[275px] xl:w-[350px] max-w-[350px] h-[425px] rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center p-4 mt-3 sm:mt-10 mx-auto xl:mx-auto flex-shrink-0 bg-white z-99'>
									<div className='w-full flex justify-center items-center mb-6'>
										<img
											src='images/HowItWorks/ForkKnife.svg'
											alt='an icon of a fork and knife'
											className='w-[100px] mt-8'
										/>
									</div>
									<div className=' h-full flex flex-col justify-between '>
										<div className='flex flex-col justify-around  h-full'>
											<h1 className='font-header text-green-600 text-xl mb-2 sm:mb-6'>
												Find a restaurant and order
												pickup or delivery
											</h1>
											<p className='text-md mb-8'>
												Search your address to see which
												restaurants near you have joined
												our mission to reduce packaging
												waste.
											</p>
										</div>
										<Button
											color='green-outlined'
											size='sm'
											className='mx-12 mb-3'
											text='Order Now'
											link='/marketplace/locations'
										/>
									</div>
								</div>
							</Slide>
							<Slide index={1}>
								{/* LEARN MORE */}
								<div className='w-[350px] lg:w-[275px] xl:w-[350px] max-w-[350px] h-[425px] rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center p-4 mt-3 sm:mt-10 mx-auto xl:mx-auto flex-shrink-0 bg-white z-10 '>
									<div className='w-full flex justify-center mb-6'>
										<img
											src='images/HowItWorks/Not apples.png'
											alt='an icon of a person in front of a house'
											className='w-[100px] mt-8'
										/>
									</div>
									<div className=' h-full flex flex-col justify-center '>
										<div className=' h-full flex flex-col justify-between '>
											<div className='flex flex-col justify-around  h-full'>
												<h1 className='font-header text-green-600 text-xl mb-2 sm:mb-6'>
													Receive your order in the
													most climate-friendly
													packaging
												</h1>
												<p className='text-md mb-8'>
													Reusable containers are more
													environmentally-friendly
													than single-use options.
													We're out to make reusable
													options more convenient,
													too.
												</p>
											</div>
											<Button
												color='green-outlined'
												size='sm'
												className='mx-12 mb-8'
												text='Learn More'
												link='/howitworks'
											/>
										</div>
									</div>
								</div>
							</Slide>
							<Slide index={2}>
								{/* RETURN BOXES */}
								<div className='w-[350px] lg:w-[275px] xl:w-[350px] max-w-[350px] h-[425px] rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center p-4 mt-3 sm:mt-10 mx-auto xl:mx-auto flex-shrink-0 bg-white z-10 '>
									<div className='w-full flex justify-center mb-6'>
										<img
											src='images/HowItWorks/House.svg'
											alt='an icon of a person in front of a house'
											className='w-[100px] mt-8'
										/>
									</div>
									<div className=' h-full flex flex-col justify-center '>
										<div className=' h-full flex flex-col justify-between '>
											<div className='flex flex-col justify-around  h-full'>
												<h1 className='font-header text-green-600 text-xl mb-6'>
													Return your containers
													within 3 weeks
												</h1>
												<p className='text-md mb-8'>
													Schedule a free pickup, hand
													them back to the courier, or
													drop them off in person at
													any return point.
												</p>
											</div>
											<Button
												color='green-outlined'
												size='sm'
												className='mx-12 mb-3'
												text='Return Boxes'
												link='/returns'
											/>
										</div>
									</div>
								</div>
							</Slide>
							<DotGroup className='z-90 w-full bg-black reset-element absolute bottom-0 shadow-xl' />
						</Slider>
						<ButtonNext
							role='button'
							aria-label='slide forward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full bg-white/75 flex justify-center items-center absolute z-30 bottom-45 right-0 mr-8 focus:outline-none  focus:ring-2  focus:ring-gray-200'
							id='next'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M1 1L7 7L1 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonNext>
					</div>
					<div className='w-full flex justify-center mt-2'>
						<Dot
							slide={0}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 1</span>
						</Dot>
						<Dot
							slide={1}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 2</span>
						</Dot>
						<Dot
							slide={2}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 3</span>
						</Dot>
					</div>
				</CarouselProvider>

				{/* Carousel for Medium and Large-Sized Screen */}
				<CarouselProvider
					className='relative hidden lg:block'
					naturalSlideWidth={100}
					isIntrinsicHeight={true}
					totalSlides={3}
					visibleSlides={1}
					step={1}
					infinite={true}
					currentSlide={1}
				>
					<div className='js-flickity flex justify-center items-center '>
						<ButtonBack
							role='button'
							aria-label='slide backward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full bg-white/75 flex justify-center bottom-45 items-center absolute z-30 left-0 ml-8 focus:outline-none  focus:ring-2  focus:ring-gray-200 cursor-pointer'
							id='prev'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7 1L1 7L7 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonBack>
						<Slider>
							<Slide index={0}>
								{/* ORDER NOW */}
								<div className='w-[350px] lg:w-[275px] xl:w-[350px] max-w-[350px] h-[425px] rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center p-4 mt-3 sm:mt-10 mx-auto xl:mx-auto flex-shrink-0 bg-white z-99'>
									<div className='w-full flex justify-center items-center mb-6'>
										<img
											src='images/HowItWorks/ForkKnife.svg'
											alt='an icon of a fork and knife'
											className='w-[100px] mt-8'
										/>
									</div>
									<div className=' h-full flex flex-col justify-between '>
										<div className='flex flex-col justify-around  h-full'>
											<h1 className='font-header text-green-600 text-xl mb-2 sm:mb-6'>
												Find a restaurant and order
												pickup or delivery
											</h1>
											<p className='text-md mb-8'>
												Search your address to see which
												restaurants near you have joined
												our mission to reduce packaging
												waste.
											</p>
										</div>
										<Button
											color='green-outlined'
											size='sm'
											className='mx-12 mb-3'
											text='Order Now'
											link='/marketplace/locations'
										/>
									</div>
								</div>
							</Slide>
							<Slide index={1}>
								{/* LEARN MORE */}
								<div className='w-[350px] lg:w-[275px] xl:w-[350px] max-w-[350px] h-[425px] rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center p-4 mt-3 sm:mt-10 mx-auto xl:mx-auto flex-shrink-0 bg-white z-10 '>
									<div className='w-full flex justify-center mb-6'>
										<img
											src='images/HowItWorks/Not apples.png'
											alt='an icon of a person in front of a house'
											className='w-[100px] mt-8'
										/>
									</div>
									<div className=' h-full flex flex-col justify-center '>
										<div className=' h-full flex flex-col justify-between '>
											<div className='flex flex-col justify-around  h-full'>
												<h1 className='font-header text-green-600 text-xl mb-2 sm:mb-6'>
													Receive your order in the
													most climate-friendly
													packaging
												</h1>
												<p className='text-md mb-8'>
													Reusable containers are more
													environmentally-friendly
													than single-use options.
													We're out to make reusable
													options more convenient,
													too.
												</p>
											</div>
											<Button
												color='green-outlined'
												size='sm'
												className='mx-12 mb-8'
												text='Learn More'
												link='/howitworks'
											/>
										</div>
									</div>
								</div>
							</Slide>
							<Slide index={2}>
								{/* RETURN BOXES */}
								<div className='w-[350px] lg:w-[275px] xl:w-[350px] max-w-[350px] h-[425px] rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center p-4 mt-3 sm:mt-10 mx-auto xl:mx-auto flex-shrink-0 bg-white z-10 '>
									<div className='w-full flex justify-center mb-6'>
										<img
											src='images/HowItWorks/House.svg'
											alt='an icon of a person in front of a house'
											className='w-[100px] mt-8'
										/>
									</div>
									<div className=' h-full flex flex-col justify-center '>
										<div className=' h-full flex flex-col justify-between '>
											<div className='flex flex-col justify-around  h-full'>
												<h1 className='font-header text-green-600 text-xl mb-6'>
													Return your containers
													within 3 weeks
												</h1>
												<p className='text-md mb-8'>
													Schedule a free pickup, hand
													them back to the courier, or
													drop them off in person at
													any return point.
												</p>
											</div>
											<Button
												color='green-outlined'
												size='sm'
												className='mx-12 mb-3'
												text='Return Boxes'
												link='/returns'
											/>
										</div>
									</div>
								</div>
							</Slide>
							<DotGroup className='z-90 w-full bg-black reset-element absolute bottom-0 shadow-xl' />
						</Slider>
						<ButtonNext
							role='button'
							aria-label='slide forward'
							className='w-12 h-12 md:w-14 md:h-14 rounded-full bg-white/75 flex justify-center items-center absolute z-30 bottom-45 right-0 mr-8 focus:outline-none  focus:ring-2  focus:ring-gray-200'
							id='next'
						>
							<svg
								width={14}
								height={24}
								viewBox='0 0 8 14'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M1 1L7 7L1 13'
									stroke='#80beaf'
									strokeWidth={4}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</ButtonNext>
					</div>
					<div className='w-full flex justify-center mt-2'>
						<Dot
							slide={0}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 1</span>
						</Dot>
						<Dot
							slide={1}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 2</span>
						</Dot>
						<Dot
							slide={2}
							className={`font-bold text-xl text-green-600 hover:text-yellow-400 m-2 `}
						>
							<span aria-hidden='true'>o</span>
							<span className='sr-only'>Carousel Slide 3</span>
						</Dot>
					</div>
				</CarouselProvider>
			</div>

			<style>
				{`
                    .gallery-cell {
                        height: 386px;
                        padding-right:15px;
                    }
                    @media (min-width: 300px) and (max-width: 420px) {
                        .gallery-cell {
                            height: 286px !important;
                            
                        }
                    }
                    
                    @media (max-width: 640px) {
                        .gallery-cell {
                            padding-right:0;
                        }
                    }

                    .carousel__sliderLarge {
                        padding-left: 20%;
                        padding-right: 20%;
                    }

                    /* gives us the illusion of spaces between the slides */
                    .carousel__inner-slideLarge {
                        width: calc(100% - 20px);
                        height: calc(100% - 20px);
                        left: 10px;

                        
                    }
					.reset-element: { all: revert; }

					.carousel__dot--selected {
						color: #ffd378 !important;
					}
                `}
			</style>
		</div>
	)
}

export default Carousel
