import React, { useEffect, useState } from 'react'

const containerStyle = {
	width: '100%',
	height: '400px',
}

function LocationsMap() {
	// Load the Google Maps API
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
	})
	// Map States
	const [userLocation, setUserLocation] = useState({
		lat: 40.7128,
		lng: -74.006,
	})
	const [zoomLevel, setZoomLevel] = useState(18)
	const [map, setMap] = useState(null)

	//Get user Position
	useEffect(() => {
		navigator.geolocation.getCurrentPosition((position) => {
			const { latitude, longitude } = position.coords
			setUserLocation({ lat: latitude, lng: longitude })
		})
	}, [])

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			mapContainerClassName='h-full w-full'
			center={userLocation}
			zoom={zoomLevel}
			onLoad={onLoad}
			onUnmount={onUnmount}
		>
			<Marker position={userLocation} /> <></>
		</GoogleMap>
	) : (
		<></>
	)
}

export default React.memo(LocationsMap)
