import { connect } from 'react-redux'
import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
	createTransaction,
	getNodeBySlug,
	getUserByPhone,
	loginUserSuccess,
	resetTransaction,
	captureIP,
	captureGPS,
} from '../../store/actions'

import Webcam from 'react-webcam'

import { Header, ReturnSteps, Footer } from '../../components'
import { Alert, Button } from '../../components/Elements'
import { FaCameraRetro } from 'react-icons/fa'
import { BounceLoader } from 'react-spinners'
import ReactConfetti from 'react-confetti'
import { GiPartyPopper } from 'react-icons/gi'
import LZString from 'lz-string'

const RegisterReturn = (props) => {
	const {
		onGetNodeBySlug,
		loadingNode,
		nodeNotFound,
		node,
		user,
		loggedIn,
		onCreateTransaction,
		transaction,
		createTransactionError,
		creatingTransaction,
		onResetTransaction,
		onGetUserByPhone,
		onCaptureIP,
		onCaptureGPS,
	} = props

	// ! Testing values
	// const node = { name: 'nodename' }
	// const loggedIn = true
	// const showSuccess = true
	const navigate = useNavigate()
	const webcamRef = useRef(null)
	const [showSuccess, setShowSuccess] = useState(false)
	const [qty, setQty] = useState(0)
	const [returnImage, setReturnImage] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const [submitLoading, setSubmitLoading] = useState(false)
	const [submitDisabled, setSubmitDisabled] = useState(true)
	const bounceLoaderColor = '#507f74'
	const { nodeSlug, returnByTextId, phone } = useParams()

	useEffect(() => {
		if (phone && returnByTextId) {
			onGetUserByPhone({
				phone: phone,
				returnByTextId: returnByTextId,
			})
		}
	}, [phone, returnByTextId])

	useEffect(() => {
		if (user && user.phone) {
			onCaptureIP({ phone: phone })
			onCaptureGPS({ phone: phone })
		}
	}, [user])

	const videoConstraints = {
		facingMode: 'environment',
	}

	const fileInputRef = useRef(null)

	useEffect(() => {
		if (creatingTransaction) {
			setSubmitLoading(true)
		} else {
			setSubmitLoading(false)
		}
	}, [creatingTransaction])

	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
		}
	}, [nodeSlug])

	// const handleSubmit = () => {
	//   if (returnImage === '') {
	//     setErrorMessage('Please take a photo of your return')
	//   } else {
	//     submitTransaction()
	//   }
	// }
	const camera = useRef(null)
	// console.log(camera)
	// console.log(webcamRef)

	// console.log(user?.firstName)

	const submitTransaction = (e) => {
		e.preventDefault()
		const transactionPayload = {
			timestamp: new Date(),
			type: 'boxesIn',
			boxCount: qty,
			items: {},
			client: 'deliverzero',
			node: node.slug,
			user: {
				phone: user.phone,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
			},
			returnImageSource: returnImage,
			returnedByText: true,
		}
		if (qty > 0) {
			onCreateTransaction(transactionPayload)
			// console.log(transactionPayload)
			// } else if (!returnImage) {
			//     setErrorMessage('Please take a photo of your return')
			//     // console.log(errorMessage)
		} else if (qty === 0) {
			setErrorMessage('Please enter a quantity greater than 0')
			// console.log(errorMessage)
		}
	}

	// const capture = () => {
	//     const imageSrc = webcamRef.current.getScreenshot()
	//     const base64String = imageSrc.split(',')[1]
	//     const compressedData = LZString.compressToBase64(base64String)
	//     setReturnImage(`data:image/jpeg;base64,${compressedData}`)
	// }

	const decompressImage = () => {
		const compressedData = returnImage.split('data:image/jpeg;base64,')[1]
		const umcompressedData = LZString.decompressFromBase64(compressedData)
		return `data:image/jpeg;base64,${umcompressedData}`
	}

	const handleFileUpload = (files) => {
		if (files.length > 0) {
			const file = files[0]
			const reader = new FileReader()

			reader.onloadend = () => {
				const base64String = reader.result.split(',')[1]
				const compressedData = LZString.compressToBase64(base64String)
				setReturnImage(`data:image/jpeg;base64,${compressedData}`)
			}

			reader.readAsDataURL(file)
		}
	}

	useEffect(() => {
		if (transaction) {
			setShowSuccess(true)
		}
		return function cleanup() {
			onResetTransaction()
		}
	}, [transaction])

	if (node) {
		return (
			<div className=' h-full w-full flex flex-col justify-between '>
				<Header />
				{!showSuccess && (
					<div className='h-full flex flex-col justify-between items-center overflow-hidden'>
						{errorMessage && (
							<Alert
								content={errorMessage}
								type='danger'
							/>
						)}
						{loadingNode && <BounceLoader />}
						{!loadingNode && (
							<>
								<div className='flex flex-col w-2/3 items-center justify-center container mb-2 pt-6'>
									<div className='flex items-center justify-center container mb-2'>
										<ReturnSteps step={2} />
									</div>
									<div className='text-center'>
										<h1 className='text-4xl font-bold font-header text-green-600 mb-4'>
											Creating return to: {node?.name}
										</h1>
										{node.returnInstructions && (
											<div className='text-lg text-center mb-4'>
												<span className='font-header '>
													Return Note:{' '}
												</span>
												{node?.returnInstructions}
											</div>
										)}
									</div>
								</div>
								<div className='shadow-2xl flex flex-col lg:flex-row w-2/3 min-h-[20em] my-6 items-center container mb-2 rounded-xl overflow-hidden relative'>
									{/*<div className='flex h-1/3 justify-center items-center bg-green-400 lg:h-full w-full lg:w-1/3 z-0 absolute'>*/}
									{/*    <FaCameraRetro className='text-green-600 text-7xl' />*/}
									{/*</div>*/}
									{/*<div className='lg:w-1/3 w-full h-full relative'>*/}
									{/*    {!returnImage && (*/}
									{/*        <Webcam*/}
									{/*            audio={false}*/}
									{/*            screenshotFormat='image/jpeg'*/}
									{/*            videoConstraints={*/}
									{/*                videoConstraints*/}
									{/*            }*/}
									{/*            ref={webcamRef}*/}
									{/*            style={{*/}
									{/*                height: '100%',*/}
									{/*                width: '100%',*/}
									{/*                objectFit: 'fill',*/}
									{/*            }}*/}
									{/*            forceScreenshotSourceSize={true}*/}
									{/*        />*/}
									{/*    )}*/}

									{/*    {returnImage && (*/}
									{/*        <img*/}
									{/*            className='w-full h-full object-cover'*/}
									{/*            src={decompressImage(returnImage)}*/}
									{/*        />*/}
									{/*    )}*/}
									{/*</div>*/}
									{/*<div className="w-full h-full inline-block sm:hidden">*/}
									{/*  <Camera*/}
									{/*      style={{*/}
									{/*        height: '100%',*/}
									{/*        width: '100%',*/}
									{/*        objectFit: 'fill',*/}
									{/*      }}*/}
									{/*      ref={camera}*/}
									{/*      facingMode='environment'*/}

									{/*  />*/}
									{/*</div>*/}

									<div className='flex flex-col justify-between h-full w-full p-6 sm:w-2/3 sm:px-8 sm:py-12 z-10 bg-white text-center lg:text-left'>
										{/*<div className=' my-3 lg:mb-3'>*/}
										{/*    Make sure all of the containers are*/}
										{/*    visible to properly verify your*/}
										{/*    return!*/}
										{/*</div>*/}
										{/*<div className='flex flex-col lg:flex-row w-full items-center justify-around'>*/}
										{/*    {returnImage && (*/}
										{/*        <div className='w-full lg:h-full my-3 mx-3'>*/}
										{/*            <Button*/}
										{/*                text='Retake photo?'*/}
										{/*                size='sm lg:base'*/}
										{/*                horizontal*/}
										{/*                onClick={() =>*/}
										{/*                    setReturnImage(null)*/}
										{/*                }*/}
										{/*                className='px-3 py-2'*/}
										{/*            />{' '}*/}
										{/*        </div>*/}
										{/*    )}*/}

										{/*    {!returnImage && (*/}
										{/*        <div className='w-full lg:h-full my-3 mx-3'>*/}
										{/*            <Button*/}
										{/*                text='Take a Photo'*/}
										{/*                size='sm lg:base'*/}
										{/*                onClick={() => {*/}
										{/*                    fileInputRef.current.click()*/}
										{/*                }}*/}
										{/*                className='px-3 py-2'*/}
										{/*            />*/}
										{/*            <input*/}
										{/*                type="file"*/}
										{/*                accept="image/*"*/}
										{/*                onChange={(e) => handleFileUpload(e.target.files)}*/}
										{/*                style={{ display: 'none' }}*/}
										{/*                ref={fileInputRef}*/}
										{/*            />*/}
										{/*        </div>*/}
										{/*    )}*/}
										{/*</div>*/}
										<div className='flex flex-col lg:flex-row items-center justify-center'>
											<div className='my-4 lg:mr-4'>
												Tell us how many containers
												you're returning
											</div>
											<div className='border-2 rounded-full mb-3 sm:mb-0 lg:w-1/3 w-full text-center flex justify-between px-3 items-center'>
												<span
													color='default'
													className='text-4xl text-gray-300 w-1/3 cursor-pointer'
													onClick={() => {
														if (qty >= 2) {
															setQty(qty - 1)
														}
													}}
												>
													-
												</span>
												<div className='text-2xl'>
													{qty}
												</div>
												<span
													color='default'
													className='text-2xl text-gray-300 w-1/3 cursor-pointer'
													onClick={() => {
														// if (qty < user.boxCount)
														setQty(qty + 1)
													}}
												>
													+
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className='flex justify-between w-2/3 items-center my-4'>
									<Button
										text='Back'
										size='sm lg:base'
										link={`/returns/locations/${returnByTextId}/${phone}`}
										color='green-outlined'
										className='px-3 py-3'
									/>
									<div className='mx-2 text-center hidden sm:inline-block'>
										Need Help?{' '}
										<a
											className='text-green-600 font-bold hover:text-green-400'
											href=''
										>
											Chat with us
										</a>
									</div>
									<div className='lg:w-1/6 min-w-32'>
										{submitLoading && (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										)}
										{!submitLoading && (
											<Button
												text='Submit'
												size='sm lg:base'
												color='red'
												onClick={(e) =>
													submitTransaction(e)
												}
												className='px-3 py-3'
											/>
										)}
									</div>
								</div>
								<div className='mx-2 text-center inline-block sm:hidden'>
									Need Help?{' '}
									<a
										className='text-green-600 font-bold hover:text-green-400'
										href=''
									>
										Chat with us
									</a>
								</div>
							</>
						)}
					</div>
				)}
				{showSuccess && (
					<div className='h-full lg:h-screen-3/4 my-5 flex flex-col justify-center items-center overflow-hidden'>
						<ReactConfetti colors={['#f38b74']} />
						<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
							You're all set!
						</h1>
						<GiPartyPopper className='text-orange-600 text-[250px]' />
						<Button
							link='/user/account'
							className='px-10 w-[260px] mt-5 shadow-light-grey'
							text='View My Account'
						/>
					</div>
				)}

				<Footer />
			</div>
		)
	} else {
		return (
			<>
				<Header />
				<div className='h-full flex flex-col items-center justify-center my-10'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
					<div>
						Oops it appears there's nothing here. Let's take you
						back to the beginning of the returns process
					</div>
					<Button
						size='base'
						className='text-sm px-6 static z-40 relative'
						text='Return Boxes'
						link='/returns'
					/>
				</div>
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ Nodes, User, TransactionsCreate }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	node: Nodes.node,
	loadingNode: Nodes.loadingNode,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUserByPhone: (payload) => dispatch(getUserByPhone(payload)),
	onLoginSuccess: (user) => dispatch(loginUserSuccess(user)),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onCaptureIP: (payload) => dispatch(captureIP(payload)),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterReturn)
