import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodeBySlug,
	getAdminClients,
	getAdminPlatforms,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import {
	BounceLoaderCentered,
	Button,
	Input,
} from '../../../components/Elements'
import {
	getMenuMap,
	getMenuMapFromClient,
	updateMenuMap,
} from '../../../store/boxCountLookUp/actions'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const RestaurantMenuMap = (state) => {
	const {
		itemBoxCountLookUp,
		loadingItemBoxCountLookUp,
		updatingItemBoxCountLookUp,
		itemBoxCountLookUpError,
		user,
		loadingUser,
		loggedIn,
		node,
		nodeError,
		loadingNode,
		onGetUser,
		onGetNodeBySlug,
		onGetMenuMap,
		onUpdateMenuMap,
		updatedBoxCountLookUp,
		onGetMenuMapFromClient,
		onGetPlatforms,
		platforms,
	} = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const { nodeSlug } = useParams()
	const bounceLoaderColor = '#507f74'
	const [loading, setLoading] = useState(true)
	const [newBoxCountLookUp, setNewBoxCountLookUp] = useState({})
	const [typedItemSearch, setTypedItemSearch] = useState('')
	const [itemSearchResults, setItemSearchResults] = useState([])
	const [itemName, setItemName] = useState('')
	const [itemBoxCountKeys, setItemBoxCountKeys] = useState([])
	const [selectedItemId, setSelectedItemId] = useState('')
	const [platform, setPlatform] = useState('')
	const [updateState, setUpdateState] = useState(false)
	// const [platforms, setPlatforms] = useState([])
	const [showPlatformChoices, setShowPlatformChoices] = useState([])

	let navigate = useNavigate()

	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
			onGetPlatforms(nodeSlug)
			// onGetMenuMap(nodeSlug)
		}
	}, [nodeSlug])

	const handleMenuMapUpdate = () => {
		// console.log('selectedItemId boxes', selectedItemId, newBoxCountLookUp[selectedItemId])
		onUpdateMenuMap({
			nodeSlug: nodeSlug,
			itemBoxCountLookUp: newBoxCountLookUp,
			clientId: platform,
		})
	}
	const getMenuMap = () => {
		// console.log('selectedItemId boxes', selectedItemId, newBoxCountLookUp)
		onGetMenuMap({
			nodeSlug: nodeSlug,
			clientId: platform,
		})
	}

	useEffect(() => {
		if (platform != '') {
			getMenuMap()
		}
	}, [platform])

	useEffect(() => {
		if (updatedBoxCountLookUp && platform != '') {
			getMenuMap()
		}
	}, [updatedBoxCountLookUp])

	useEffect(() => {
		const closestMatches = itemBoxCountKeys.filter(
			(txt) => txt.indexOf(typedItemSearch) !== -1
		)
		setItemSearchResults(closestMatches.slice(0, 10))
	}, [typedItemSearch])

	// console.log(itemBoxCountLookUp)

	useEffect(() => {
		if (itemBoxCountLookUp) {
			let tempArray = []
			for (const itemId in itemBoxCountLookUp['itemBoxCountLookUp']) {
				let key =
					itemBoxCountLookUp['itemBoxCountLookUp'][itemId][
						'name'
					].toLowerCase() + ';'
				if (itemBoxCountLookUp['itemBoxCountLookUp'][itemId]['type']) {
					key =
						key +
						itemBoxCountLookUp['itemBoxCountLookUp'][itemId][
							'type'
						].toLowerCase() +
						';'
				} else {
					key = key + 'Unknown;'
				}
				if (
					itemBoxCountLookUp['itemBoxCountLookUp'][itemId]['category']
				) {
					key =
						key +
						itemBoxCountLookUp['itemBoxCountLookUp'][itemId][
							'category'
						].toLowerCase() +
						';'
				} else {
					key = key + 'Unknown;'
				}
				key = key + itemId
				tempArray.push(key)
			}
			setItemBoxCountKeys(tempArray)
			setNewBoxCountLookUp(itemBoxCountLookUp['itemBoxCountLookUp'])
		}
	}, [itemBoxCountLookUp])

	useEffect(() => {
		if (updateState) {
			setUpdateState(false)
		}
	}, [updateState])

	// useEffect(() => {
	//     if (node) {
	//         var plats = []
	//         if (node.thirdPartyClient) {
	//             plats = [...plats, ...Object.keys(node.thirdPartyClient)]
	//
	//         }
	//         if(node.directOrdering){
	//             plats=[...plats,node.directOrderingClient]
	//         }
	//         setPlatforms(plats)
	//     }
	// }, [node])

	function addBoxes(selectedItemId) {
		let tempObj = newBoxCountLookUp
		tempObj[selectedItemId]['boxCount'] =
			tempObj[selectedItemId]['boxCount'] + 1
		setNewBoxCountLookUp(tempObj)
		setUpdateState(true)
	}

	function subtractBoxes(selectedItemId) {
		let tempObj = newBoxCountLookUp
		if (tempObj[selectedItemId]['boxCount'] > 0) {
			tempObj[selectedItemId]['boxCount'] =
				tempObj[selectedItemId]['boxCount'] - 1
		}
		setNewBoxCountLookUp(tempObj)
		setUpdateState(true)
	}

	const getMenuMapFromClient = () => {
		onGetMenuMapFromClient({ nodeSlug: nodeSlug, platform: platform })
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		if (node) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='flex flex-col my-10 mx-2'>
						<div className='mx-auto max-w-[800px]'>
							<div className='font-header text-2xl text-green-600'>
								{node.name}
							</div>
							<div className=''>
								<span className='font-bold'>Address:</span>{' '}
								{node.address}, {node.city}, {node.state}{' '}
								{node.postal}
							</div>

							{platforms.length > 0 ? (
								<div className='flex flex-row justify-center items-center'>
									<div className='mt-6 mb-3 font-bold text-xl text-center'>
										Select the platform
									</div>
									{showPlatformChoices ? (
										<BsChevronUp
											onClick={() => {
												setShowPlatformChoices(false)
											}}
											className='cursor-pointer ml-2 text-green-600 text-lg sm:text-xl mt-2 font-bold'
										/>
									) : (
										<BsChevronDown
											onClick={() => {
												setShowPlatformChoices(true)
											}}
											className='cursor-pointer ml-2 text-green-600 text-lg sm:text-xl mt-2 font-bold'
										/>
									)}
								</div>
							) : (
								<BounceLoaderCentered container='div' />
							)}
							<div className='flex-col flex items-center'>
								{showPlatformChoices && (
									<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
										{platforms.map((client) => (
											<div
												className='text-sm cursor-pointer my-1 hover:bg-gray-100'
												key={client}
												onClick={() => {
													setSelectedItemId('')
													setItemSearchResults([])
													setPlatform(client)
													setShowPlatformChoices(
														false
													)
												}}
											>
												{client}
											</div>
										))}
									</div>
								)}
							</div>

							<h1 className='font-bold text-center'>
								Menu Map for {platform}
							</h1>

							{(loadingItemBoxCountLookUp ||
								updatingItemBoxCountLookUp) && (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							)}
							{updatedBoxCountLookUp && (
								<div className='font-bold text-green-600'>
									Successfully updated menu map for{' '}
									{node.name}!
								</div>
							)}
							{itemBoxCountLookUpError && (
								<div className='font-bold text-red-400'>
									An error occurred while getting or updating
									menu map for {node.name}:{' '}
									{itemBoxCountLookUpError.error}
								</div>
							)}
							{itemBoxCountLookUp &&
								itemBoxCountLookUp['itemBoxCountLookUp'] && (
									<div>
										<div className='mt-6 font-bold'>
											Search an item by it's exact name on
											the menu
										</div>
										<Input
											type='text'
											label=''
											name='item-search'
											placeholder='e.g. "Saag Paneer"'
											className='bg-white border-2 border-gray-300 mb-6'
											onChange={(e) => {
												setTypedItemSearch(
													e.target.value.toLowerCase()
												)
											}}
										/>

										<div>
											{itemSearchResults.map((item) => {
												const splitItem =
													item.split(';')
												return (
													<div className='flex flex-row my-1'>
														<div className='w-[300px] overflow-x-hidden'>
															<span className='font-bold'>
																TYPE:
															</span>
															{splitItem[1]},{` `}
															<span className='font-bold'>
																CATEGORY:
															</span>
															{splitItem[2]},{` `}
															<span className='font-bold'>
																NAME:
															</span>
															{splitItem[0]}
														</div>
														<Button
															className='items-center'
															size='xs'
															text='Select'
															onClick={() => {
																setSelectedItemId(
																	splitItem[3]
																)
															}}
														/>
													</div>
												)
											})}
											{selectedItemId &&
												selectedItemId !== '' && (
													<div className='mt-6'>
														<div className='text-lg'>
															<span className='font-bold'>
																{platform.toUpperCase()}{' '}
																Item ID:{' '}
															</span>
															{selectedItemId}
														</div>
														{itemBoxCountLookUp[
															'itemBoxCountLookUp'
														][selectedItemId][
															'type'
														] && (
															<div className='text-lg'>
																<span className='font-bold'>
																	Item Type:{' '}
																</span>
																{
																	itemBoxCountLookUp[
																		'itemBoxCountLookUp'
																	][
																		selectedItemId
																	]['type']
																}
															</div>
														)}
														{itemBoxCountLookUp[
															'itemBoxCountLookUp'
														][selectedItemId][
															'category'
														] && (
															<div className='text-lg'>
																<span className='font-bold'>
																	Category:{' '}
																</span>
																{
																	itemBoxCountLookUp[
																		'itemBoxCountLookUp'
																	][
																		selectedItemId
																	][
																		'category'
																	]
																}
															</div>
														)}
														<div className='text-lg'>
															<span className='font-bold'>
																Item Name:{' '}
															</span>
															{
																itemBoxCountLookUp[
																	'itemBoxCountLookUp'
																][
																	selectedItemId
																]['name']
															}
														</div>
														<div className='text-lg'>
															<span className='font-bold'>
																Box Count:{' '}
															</span>
														</div>
														<div className='flex flex-row'>
															<span
																color='default'
																className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																onClick={() => {
																	setUpdateState(
																		false
																	)
																	subtractBoxes(
																		selectedItemId
																	)
																}}
															>
																-
															</span>
															<Input
																type='text'
																className='text-xl sm:text-2xl mx-2 w-[100px]'
																value={
																	newBoxCountLookUp[
																		selectedItemId
																	].boxCount
																}
																onChange={(
																	e
																) => {
																	console.log(
																		e.target
																			.value
																	)
																	const tempObj =
																		newBoxCountLookUp
																	if (
																		e.target
																			.value ===
																		''
																	) {
																		tempObj[
																			selectedItemId
																		].boxCount = 0
																	} else {
																		if (
																			e.target.value.endsWith(
																				'.'
																			)
																		) {
																			tempObj[
																				selectedItemId
																			].boxCount =
																				e.target.value
																		} else {
																			tempObj[
																				selectedItemId
																			].boxCount =
																				parseFloat(
																					e
																						.target
																						.value
																				)
																		}
																	}
																	setNewBoxCountLookUp(
																		tempObj
																	)
																	setUpdateState(
																		true
																	)
																}}
															/>
															<span
																color='default'
																className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																onClick={() => {
																	setUpdateState(
																		false
																	)
																	addBoxes(
																		selectedItemId
																	)
																}}
															>
																+
															</span>
														</div>
														{!(
															updatingItemBoxCountLookUp ||
															loadingItemBoxCountLookUp
														) && (
															<Button
																text='Submit New Box Count'
																onClick={() => {
																	handleMenuMapUpdate()
																}}
															/>
														)}
													</div>
												)}
										</div>
									</div>
								)}
							{!itemBoxCountLookUp && (
								<div className='my-4'>
									{platform === '' ? (
										<div>No menu map applicable</div>
									) : (
										<Button
											text={`Get from ${platform.toUpperCase()}`}
											onClick={getMenuMapFromClient}
										/>
									)}
								</div>
							)}
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div>
					<Header />
					<div className='flex items-center h-screen'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
						<Footer />
					</div>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ BoxCountLookUp, User, Nodes, Admin }) => ({
	itemBoxCountLookUp: BoxCountLookUp.itemBoxCountLookUp,
	loadingItemBoxCountLookUp: BoxCountLookUp.loadingItemBoxCountLookUp,
	updatingItemBoxCountLookUp: BoxCountLookUp.updatingItemBoxCountLookUp,
	updatedBoxCountLookUp: BoxCountLookUp.updatedBoxCountLookUp,
	itemBoxCountLookUpError: BoxCountLookUp.itemBoxCountLookUpError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	node: Nodes.node,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	platforms: Admin.platforms,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onGetMenuMap: (slug) => dispatch(getMenuMap(slug)),
	onUpdateMenuMap: (payload) => dispatch(updateMenuMap(payload)),
	onGetMenuMapFromClient: (slug) => dispatch(getMenuMapFromClient(slug)),
	onGetPlatforms: (payload) => dispatch(getAdminPlatforms(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantMenuMap)
