import React, { useEffect, useState } from 'react'
import { Input, CheckboxGroup, Button, Alert } from '../Elements'
import { registerUser } from '../../store/auth/register/actions'
import {
	smsVerifySendPhone,
	smsVerifyPhoneUserResponse,
	captureUser,
} from '../../store/actions'
import {
	socialLoginGoogle,
	socialRegisterGoogle,
} from '../../store/auth/login/actions'
import { getUser } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import PhoneInput from 'react-phone-number-input/input'
import { BounceLoader } from 'react-spinners'
import { realm } from '../../helpers/realm'

const RegisterCard = (props) => {
	const {
		nodeSlug,
		officeSlug,
		adminContact,
		user,
		loggedIn,
		registrationError,
		registrationLoading,
		registrationAttemptStatus,
		onRegisterUser,
		onGetUser,
		userLoading,
		path,
		loginLoading,
		onGoogleLogin,
		onGoogleRegister,
		loginError,
		loginAttemptStatus,
		onSmsVerifySendPhone,
		onSmsVerifyPhoneUserResponse,
		smsVerifySuccessResponse,
		smsVerifyError,
		smsVerifyLoading,
		smsVerifySendPhoneResponse,
		onCaptureUser,
		visitor,
		userError,
	} = props

	const [form, setForm] = useState({
		email: '',
		phone: '',
		password: '',
		signUpNewsletter: true,
		// restaurantAdmin: nodeSlug, // check for adminContact first
		// officeSlug: officeSlug, // check for adminContact first
	})
	const [errorMessage, setErrorMessage] = useState(false)
	const [existingDzUser, setExistingDzUser] = useState(false)
	const [existingDzUserForGoogle, setExistingDzUserForGoogle] =
		useState(false)
	const [existingDzUserLoading, setExistingDzUserLoading] = useState(false)
	const [submitForm, setSubmitForm] = useState(false)
	const [showNameFields, setShowNameFields] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [phone, setPhone] = useState('')
	const [showOnlyPhoneField, setShowOnlyPhoneField] = useState(false)
	const [googleResponse, setGoogleResponse] = useState({})
	const [submitGoogleLogin, setSubmitGoogleLogin] = useState(false)
	const [submitGoogleRegister, setSubmitGoogleRegister] = useState(false)
	const [missingEmailError, setMissingEmailError] = useState(false)
	const [missingPasswordError, setMissingPasswordError] = useState(false)
	const [missingPhoneError, setMissingPhoneError] = useState(false)
	const [newsletterSignup, setNewsletterSignup] = useState(true)
	const [smsCheckModal, setSmsCheckModal] = useState(false)
	const [smsCheckResponse, setSmsCheckResponse] = useState('')
	const [smsCheckLoading, setSmsCheckLoading] = useState(false)
	const [smsCheckError, setSmsCheckError] = useState(false)
	const [googleRegistration, setGoogleRegistration] = useState(false)
	const [phoneVerified, setPhoneVerified] = useState(false)
	const bounceLoaderColor = '#507f74'
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const currentPath = location.pathname
		if (loggedIn && path) {
			if (nodeSlug) {
				navigate('/restaurant-admin/home')
			} else if (officeSlug) {
				navigate('/office-ordering/home')
			} else if (currentPath.includes('/membership')) {
				navigate('/membership#subscribe')
				setTimeout(() => {
					const element = document.getElementById('subscribe')
					if (element) {
						element.scrollIntoView({ behavior: 'smooth' })
					}
				}, 100)
			} else {
				navigate(path)
			}
		} else if (loggedIn) {
			navigate('/')
		}
	}, [loggedIn])

	useEffect(() => {
		onCaptureUser({
			source: 'A',
			fromMobile: false,
		})
	}, [])

	useEffect(() => {
		if (nodeSlug && adminContact) {
			setForm({ ...form, restaurantAdmin: nodeSlug })
		}
	}, [nodeSlug, adminContact])

	useEffect(() => {
		if (officeSlug && adminContact) {
			setForm({ ...form, officeSlug: officeSlug })
		}
	}, [officeSlug, adminContact])

	const handleChange = (e) => {
		if (e.target.name == 'password') {
			setMissingPasswordError(false)
		}
		if (e.target.name == 'email') {
			setMissingEmailError(false)
			setForm({ ...form, [e.target.name]: e.target.value.toLowerCase() })
		} else {
			setForm({ ...form, [e.target.name]: e.target.value })
		}
	}

	const handlePhone = (e) => {
		setMissingPhoneError(false)
		setForm({ ...form, phone: e })
	}

	const errorMessageLookUp = {
		'name already in use': 'This email is already in use.',
		'password must be between 6 and 128 characters':
			'Oops, looks like the password you used was less than 6 characters. Refresh the page to start sign up over.',
		'invalid username': 'That email is invalid. Try again or sign up below',
		'invalid username/password':
			'That email or password is invalid. Try again, reset your password, or sign up below',
		'invalid password':
			'That password is invalid. Try again or reset your password below',
	}

	useEffect(() => {
		if (registrationError) {
			if (registrationError === 'phone-number-already-exists') {
				setExistingDzUser(true)
			} else {
				setErrorMessage(errorMessageLookUp[registrationError.error])
			}
		}
	}, [registrationError])

	const checkExistingDzUser = async (form) => {
		const phone = form.phone
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ phone: phone })
		// console.log('in check existing user')
		// console.log('userDoc',userDoc)
		if (userDoc) {
			if (userDoc.userId) {
				// If Existing DZ User, send user email instructions to sign in with existing
				// email and show alert to give them option to replace account with new account
				const sendUserSignInInfoEmail =
					await realm.currentUser.functions.sendUserSignInInfoEmail({
						phone: phone,
					})
				setExistingDzUser(true)
			} else {
				// If Client User, submit form, we have all the info we need
				setSubmitForm(true)
			}
		} else {
			// If Unregistered User, we need their first and last name before submitting
			if (!form.firstName || !form.lastName) {
				// console.log('show name fields')
				setShowNameFields(true)
			} else {
				// console.log('submit form')
				setSubmitForm(true)
			}
		}
	}

	const validateFormFields = () => {
		if (form.email === '' || !form.email.includes('@')) {
			setMissingEmailError(true)
		} else {
			setMissingEmailError(false)
		}
		if (form.password === '') {
			setMissingPasswordError(true)
		} else {
			setMissingPasswordError(false)
		}
		if (form.phone === '' || form.phone.length !== 12) {
			setMissingPhoneError(true)
		} else {
			setMissingPhoneError(false)
		}
		if (
			form.email !== '' &&
			form.email.includes('@') &&
			form.phone.length === 12 &&
			form.password !== ''
		) {
			return true
		} else {
			return false
		}
	}
	// console.log(form.phone)

	const handleRegisterSubmit = (e) => {
		e.preventDefault()
		if (validateFormFields()) {
			setMissingPhoneError(false)
			setGoogleRegistration(false)
			// console.log("handleRegisterSubmit")
			// if (isValidPhoneNumber(form.phone)) {
			setPhoneError(false)
			setExistingDzUserLoading(true)
			setExistingDzUser(false)
			setSubmitForm(false)
			// console.log(form.phone)
			if (phoneVerified) {
				checkExistingDzUser(form).then(() => {
					setExistingDzUserLoading(false)
				})
			} else {
				onSmsVerifySendPhone(form.phone)
				setSmsCheckModal(true)
			}
			e.preventDefault()
			// } else {
			// 	setPhoneError(true)
			// 	e.preventDefault()
			// }
		}
	}

	useEffect(() => {
		if (smsVerifySuccessResponse) {
			setSmsCheckModal(false)
			setPhoneVerified(true)
			if (googleRegistration) {
				checkExistingUserPhoneGoogle().then(() => {
					setExistingDzUserLoading(false)
				})
			} else {
				checkExistingDzUser(form).then(() => {
					setExistingDzUserLoading(false)
				})
			}
		}
	}, [smsVerifySuccessResponse])

	useEffect(() => {
		if (submitForm) {
			// console.log('form', form)
			onRegisterUser(form)
			onCaptureUser({
				source: 'A',
				fromMobile: false,
				...form,
			})
		}
	}, [submitForm])

	//For Existing DZ User
	const handleReplaceSubmit = (e) => {
		//Clear the alert
		setExistingDzUser(false)
		e.preventDefault()
		//submit the form
		setSubmitForm(true)
	}

	useEffect(() => {
		if (registrationAttemptStatus == 'completed') {
			onGetUser()
		}
	}, [registrationAttemptStatus])

	const googleResponseSuccess = (res) => {
		setGoogleRegistration(true)
		setExistingDzUserLoading(true)
		setSubmitGoogleLogin(false)
		setSubmitGoogleRegister(false)
		setShowOnlyPhoneField(false)
		checkExistingUserGoogle(res).then(() => {
			setExistingDzUserLoading(false)
		})
	}

	const checkExistingUserGoogle = async (res) => {
		setGoogleResponse(res)
		const email = res.profileObj.email
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ email: email })
		if (userDoc) {
			if (userDoc.userId && userDoc.phone) {
				// If Existing DZ User, proceed with google login
				setSubmitGoogleLogin(true)
			} else {
				// If Client User, submit form, we have all the info we need
				setPhone(userDoc.phone)
				setSubmitGoogleRegister(true)
			}
		} else {
			// If Unregistered User, we need their first and last name before submitting
			setShowOnlyPhoneField(true)
		}
	}

	useEffect(() => {
		if (submitGoogleLogin) {
			onGoogleLogin(googleResponse)
		}
	}, [submitGoogleLogin])

	const checkExistingUserPhoneGoogle = async () => {
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ phone: form.phone })
		// console.log(userDoc)
		if (userDoc) {
			// console.log(userDoc.userId)
			// console.log(userDoc.phone)
			if (userDoc.userId && userDoc.phone) {
				// console.log("here")
				// If Existing DZ User, send user email instructions to sign in with existing
				// email and show alert to give them option to replace account with new account
				const sendUserSignInInfoEmail =
					await realm.currentUser.functions.sendUserSignInInfoEmail({
						phone: phone,
					})
				setExistingDzUserForGoogle(true)
			} else {
				// If Client User, submit form, we have all the info we need
				setSubmitGoogleRegister(true)
			}
		} else {
			// If Unregistered User, we need their first and last name before submitting
			setSubmitGoogleRegister(true)
		}
	}

	const handlePhoneSubmit = (e) => {
		if (form.phone === '') {
			setMissingPhoneError(true)
			e.preventDefault()
		} else {
			setMissingPhoneError(false)
			setPhone(form.phone)
			setExistingDzUserLoading(true)
			setSubmitGoogleRegister(false)
			e.preventDefault()
			if (phoneVerified) {
				checkExistingUserPhoneGoogle().then(() => {
					setExistingDzUserLoading(false)
				})
			} else {
				onSmsVerifySendPhone(form.phone)
				setSmsCheckModal(true)
			}
		}
		// if (isValidPhoneNumber(form.phone)) {

		// } else {
		// 	setPhoneError(true)
		// 	e.preventDefault()
		// }
	}

	useEffect(() => {
		if (submitGoogleRegister) {
			onGoogleRegister({
				googleRes: googleResponse,
				phone: phone,
				signUpNewsletter: newsletterSignup,
			})
		}
	}, [submitGoogleRegister])

	const googleResponseFailure = (res) => {
		// console.log(res)
	}

	const handleNewsletterSignup = () => {
		setNewsletterSignup(!newsletterSignup)
		// setForm({ ...form, signUpNewsletter: newsletterSignup })
	}

	useEffect(() => {
		setForm({ ...form, signUpNewsletter: newsletterSignup })
	}, [newsletterSignup])

	const handleSmsVerifyPhoneUserResponse = (e) => {
		e.preventDefault()
		// console.log(smsCheckResponse)
		onSmsVerifyPhoneUserResponse({
			smsUserPhone: form.phone,
			smsUserResponse: smsCheckResponse,
		})
	}

	const handleSmsResponseChange = (e) => {
		setSmsCheckResponse(e.target.value)
	}

	return (
		<div className='w-11/12 md:w-form-widget h-full bg-white overflow-hidden shadow-light-grey rounded-xl max-w-lg m-auto'>
			{smsCheckModal && (
				<div className='text-xl md:px-4 py-5 sm:p-6 m-6 mb-0 mt- text-center min-w-[350px] mx-auto'>
					<h1 className='font-vollkorn text-green-600 text-4xl mb-6 text-center'>
						Verify Phone
					</h1>
					{smsVerifySendPhoneResponse ? (
						<p className='mb-4 text-sm'>
							A confirmation code was sent to the number provided.{' '}
						</p>
					) : (
						<p className='mb-4 text-sm'>
							A confirmation code is being sent to the number
							provided.{' '}
						</p>
					)}
					{existingDzUser && (
						<Alert
							type='warning'
							className='text-sm mb-2 sm:mb-10'
							content={
								<div>
									This phone number is already associated with
									an account. We've sent an email to the
									address on the account as a refresher.
									Follow those instructions to login.
									<a
										href='/login'
										className='font-weight-medium text-primary'
									>
										{' '}
										Log in to your existing account.
									</a>
								</div>
							}
						></Alert>
					)}
					{existingDzUserForGoogle && (
						<Alert
							type='warning'
							className='text-sm mb-2 sm:mb-10'
							content={
								<div>
									This phone number is already associated with
									an account. We've sent an email to the
									address on the account as a refresher.
									Follow those instructions to login.
									<a
										href='/login'
										className='font-weight-medium text-primary'
									>
										{' '}
										Log in to your existing account.
									</a>
								</div>
							}
						></Alert>
					)}
					<form
						className='form-horizontal'
						onSubmit={(e) => handleSmsVerifyPhoneUserResponse(e)}
					>
						<Input
							type='text'
							label=''
							name='smsResponse'
							onChange={handleSmsResponseChange}
							error={smsCheckError}
							className='bg-white border-2 border-gray-300 mb-6 w-5/6 h-14'
						/>
						{smsVerifyLoading ? (
							<div className='mt-3 text-center'>
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							</div>
						) : (
							<Button
								color='green'
								full
								text='Submit'
								className='mb-3 w-5/6'
								submit={true}
							/>
						)}
						{smsVerifyError &&
							smsVerifyError.error === '"Invalid Code"' && (
								<p className='text-center text-red-400 font-bold text-sm'>
									That code was invalid! Try again.
								</p>
							)}
						{smsVerifyError &&
							smsVerifyError.error === '"Expired"' && (
								<div>
									<p className='text-center text-red-400 font-bold text-sm'>
										That code expired!
									</p>
									<Button
										color='green'
										full
										text='Send New Code'
										className='mb-3 w-5/6'
										onClick={() => {
											onSmsVerifySendPhone(form.phone)
										}}
									/>
								</div>
							)}
					</form>
				</div>
			)}
			{!smsCheckModal && (
				<div className='text-xl md:px-4 py-5 sm:p-6 m-6 mb-0 mt-'>
					<h1 className='font-vollkorn text-green-600 text-4xl mb-6 text-center'>
						Sign Up
					</h1>
					{showOnlyPhoneField && !smsCheckModal && (
						<form
							className='form-horizontal'
							onSubmit={handlePhoneSubmit}
						>
							{loginError && (
								<Alert
									type='warning'
									content={errorMessage}
									className='text-sm mb-5'
								></Alert>
							)}
							{phoneError && (
								<Alert
									type='warning'
									content='Please enter a valid US phone number.'
									className='text-sm mb-5'
								></Alert>
							)}
							{existingDzUser && (
								<Alert
									type='warning'
									className='text-sm mb-2 sm:mb-10'
									content={
										<div>
											This phone number is already
											associated with an account. We've
											sent an email to the address on the
											account as a refresher. Follow those
											instructions to login.
											<a
												href='/login'
												className='font-weight-medium text-primary'
											>
												{' '}
												Log in to your existing account.
											</a>
										</div>
									}
								></Alert>
							)}
							{existingDzUserForGoogle && (
								<Alert
									type='warning'
									className='text-sm mb-2 sm:mb-10'
									content={
										<div>
											This phone number is already
											associated with an account. We've
											sent an email to the address on the
											account as a refresher. Follow those
											instructions to login.
											<a
												href='/login'
												className='font-weight-medium text-primary'
											>
												{' '}
												Log in to your existing account.
											</a>
										</div>
									}
								></Alert>
							)}
							<div className='mb-3'>
								<div className='text-center text-sm mt-6 mb-8 font-bold'>
									We need a bit more info to create your
									profile!
								</div>
								<div className={'pb-1'}>
									<label
										className={`text-sm text-gray-600 font-poppins ${
											missingPhoneError && 'text-red-600'
										} absolute -translate-y-5`}
										htmlFor={'phone'}
									>
										PHONE
									</label>
								</div>
								<PhoneInput
									onChange={handlePhone}
									country='US'
									value={form.phone}
									id='phone'
									name='phone'
									label='PHONE'
									className={` form-control bg-white border-2 border-gray-300 w-full border border-2 border-gray-200 rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow ${
										missingPhoneError && `border-red-600`
									}`}
									type='phone'
								/>
								<p className='text-xs mb-8'>
									If you've ordered in DeliverZero boxes from
									a third-party, please use the phone number
									from those orders
								</p>
							</div>
							{existingDzUserLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{loginLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{userLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{!existingDzUserLoading &&
								!loginLoading &&
								!userLoading && (
									<Button
										className=''
										full
										submit={true}
										text='Continue with Google'
									></Button>
								)}
						</form>
					)}
					{!showOnlyPhoneField && !smsCheckError && (
						<form
							className='form-horizontal'
							onSubmit={handleRegisterSubmit}
						>
							{registrationError && !existingDzUser && (
								<Alert
									type='warning'
									content={errorMessage}
									className='text-sm mb-2 sm:mb-10'
								></Alert>
							)}
							{phoneError && (
								<Alert
									type='warning'
									content='Please enter a valid US phone number.'
									className='text-sm mb-2 sm:mb-10'
								></Alert>
							)}
							{existingDzUser && (
								<Alert
									type='warning'
									content={
										<div>
											<p>
												This phone number is already
												associated with a different
												email address. We've sent an
												email to that address as a
												refresher.
												<Link
													to='/login'
													className='font-weight-medium text-primary'
												>
													{' '}
													Log in to your existing
													account.
												</Link>
											</p>
											{/*<p>If you would like to replace the existing account with this*/}
											{/*  new login info,*/}
											{/*  <a onClick={handleReplaceSubmit}*/}
											{/*     className='font-weight-medium text-primary'>*/}
											{/*    {' '}*/}
											{/*    click here.*/}
											{/*  </a>*/}
											{/*</p>*/}
										</div>
									}
									className='text-sm mb-2 sm:mb-10'
								></Alert>
							)}
							{!showNameFields && !smsCheckError && (
								<div>
									<div className={'pb-1'}>
										<label
											className={`text-sm text-gray-600 font-poppins ${
												missingPhoneError &&
												'text-red-600'
											} absolute -translate-y-5`}
											htmlFor={'phone'}
										>
											PHONE
										</label>
									</div>
									<form>
										<PhoneInput
											onChange={handlePhone}
											country='US'
											value={form.phone}
											id='phone'
											name='phone'
											label='PHONE'
											className={` form-control bg-white border-2 border-gray-300 w-full  rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow ${
												missingPhoneError &&
												`border-red-600`
											}`}
											type='phone'
										/>
									</form>
									<p className='text-xs mb-6'>
										If you've ordered in DeliverZero boxes
										from a third-party, please use the phone
										number from those orders
									</p>
									<Input
										type='email'
										label='EMAIL'
										name='email'
										onChange={handleChange}
										value={form.email}
										error={missingEmailError}
										className='bg-white border-2 border-gray-300 mb-4'
									/>
									<Input
										type='password'
										label='PASSWORD'
										name='password'
										onChange={handleChange}
										error={missingPasswordError}
										className='bg-white border-2 border-gray-300'
									/>
									{/*<Input type='password' label='CONFIRM PASSWORD' name='confirmPassword' className='bg-white border-2 border-gray-300'/>*/}
									<div className='my-3'>
										<CheckboxGroup
											label='Sign up for our newsletter'
											name='terms'
											className='py-3'
											defaultChecked={true}
											onChange={handleNewsletterSignup}
										/>
									</div>
								</div>
							)}
							{showNameFields && !smsCheckError && (
								<div>
									<div className='text-center text-sm mt-6 mb-10 font-bold'>
										We need a tiny bit more info to create
										your profile!
									</div>
									<Input
										type='firstName'
										label='FIRST NAME'
										name='firstName'
										onChange={handleChange}
										className='bg-white border-2 border-gray-300 mb-6'
									/>
									<Input
										type='lastName'
										label='LAST NAME'
										name='lastName'
										onChange={handleChange}
										className='mb-6 bg-white border-2 border-gray-300'
									/>
								</div>
							)}

							{!registrationLoading &&
								!userLoading &&
								!existingDzUserLoading &&
								!smsCheckError && (
									<div>
										<Button
											color='green'
											full
											text='Create an Account'
											className='mb-3'
											submit={true}
										/>
										{/*                <GoogleLogin*/}
										{/*                    clientId={google.CLIENT_ID}*/}
										{/*                    render={(renderProps) => (*/}
										{/*                        <button*/}
										{/*                            onClick={renderProps.onClick}*/}
										{/*                            className='w-full text-base font-semibold py-3 px-8 items-center font-poppins tracking-wider cursor-pointer border border-2*/}
										{/*rounded-full whitespace-nowrap min-w-[100px] text-center justify-center*/}
										{/*focus:ring-2 focus:ring-offset-2 text-green-600 bg-transparent border-green-600 hover:bg-green-600 hover:border-green-600 hover:text-white focus:ring-green-600'*/}
										{/*                        >*/}
										{/*                            <FcGoogle className='mr-2 inline-block'/>*/}
										{/*                            Sign up with Google*/}
										{/*                        </button>*/}
										{/*                    )}*/}
										{/*                    // native_callback={nativeCallback}*/}
										{/*                    buttonText='Sign up with Google'*/}
										{/*                    onSuccess={googleResponseSuccess}*/}
										{/*                    onFailure={googleResponseFailure}*/}
										{/*                />*/}
									</div>
								)}
							{existingDzUserLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{registrationLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{userLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
						</form>
					)}
					<div className='text-center text-sm mt-5'>
						Already have an account?{' '}
						<Link
							to='/login'
							className='text-green-600 underline font-semibold'
						>
							Log in.
						</Link>
					</div>
					<div className='mt-0 text-center text-xxs'>
						<p className='mb-0'>
							By registering you agree to the DeliverZero{' '}
							<Link
								to='/terms'
								className='text-primary'
							>
								Terms of Use
							</Link>{' '}
							and{' '}
							<Link
								to='/privacy'
								className='text-primary'
							>
								Privacy Policy
							</Link>
							{'.'}
						</p>
					</div>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = ({ User, Account, Path, Login, SmsVerify }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	registrationLoading: Account.loading,
	registrationAttemptStatus: Account.registrationAttemptStatus,
	userLoading: User.loading,
	userError: User.error,
	visitor: User.visitor,
	registrationError: Account.registrationError,
	path: Path.path,
	loginLoading: Login.loading,
	loginError: Login.error,
	loginAttemptStatus: Login.loginAttemptStatus,
	smsVerifyLoading: SmsVerify.smsVerifyLoading,
	smsVerifyError: SmsVerify.smsVerifyError,
	smsVerifySuccessResponse: SmsVerify.smsVerifySuccessResponse,
	smsVerifySendPhoneResponse: SmsVerify.smsVerifySendPhoneResponse,
})

const mapDispatchToProps = (dispatch) => ({
	onRegisterUser: (user) => dispatch(registerUser(user)),
	onGoogleLogin: (res) => dispatch(socialLoginGoogle(res)),
	onGoogleRegister: (payload) => dispatch(socialRegisterGoogle(payload)),
	onGetUser: () => dispatch(getUser()),
	onSmsVerifySendPhone: (phone) => dispatch(smsVerifySendPhone(phone)),
	onSmsVerifyPhoneUserResponse: (phone, response) =>
		dispatch(smsVerifyPhoneUserResponse(phone, response)),
	onCaptureUser: (payload) => dispatch(captureUser(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCard)
