export const GET_RAAS_MERCHANT = 'GET_RAAS_MERCHANT'
export const GET_RAAS_MERCHANT_SUCCESS = 'GET_RAAS_MERCHANT_SUCCESS'
export const GET_RAAS_MERCHANT_ERROR = 'GET_RAAS_MERCHANT_ERROR'
export const GET_RAAS_CUSTOMER = 'GET_RAAS_CUSTOMER'
export const GET_RAAS_CUSTOMER_SUCCESS = 'GET_RAAS_CUSTOMER_SUCCESS'
export const GET_RAAS_CUSTOMER_ERROR = 'GET_RAAS_CUSTOMER_ERROR'
export const INSERT_RAAS_TRANSACTION = 'INSERT_RAAS_TRANSACTION'
export const INSERT_RAAS_TRANSACTION_SUCCESS = 'INSERT_RAAS_TRANSACTION_SUCCESS'
export const INSERT_RAAS_TRANSACTION_ERROR = 'INSERT_RAAS_TRANSACTION_ERROR'
export const GET_ALL_RAAS_MERCHANTS = 'GET_ALL_RAAS_MERCHANTS'
export const GET_ALL_RAAS_MERCHANTS_SUCCESS = 'GET_ALL_RAAS_MERCHANTS_SUCCESS'
export const GET_ALL_RAAS_MERCHANTS_ERROR = 'GET_ALL_RAAS_MERCHANTS_ERROR'
export const RUN_AGGREGATION_RAAS = 'RUN_AGGREGATION_RAAS'
export const RUN_AGGREGATION_RAAS_SUCCESS = 'RUN_AGGREGATION_RAAS_SUCCESS'
export const RUN_AGGREGATION_RAAS_ERROR = 'RUN_AGGREGATION_RAAS_ERROR'
