import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	getDropoffQuote,
	createDropoff,
	createGentlyDropoff,
	createGentlyDropoffNoNode,
	getContainers,
} from '../../../../store/actions'
import { Footer, Header } from '../../../../components'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	SelectGroup,
	TextArea,
} from '../../../../components/Elements'
import { FormGroup } from '../../../../components/Elements/index'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../../model/nodes'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'

const DropoffWidget = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		onGetDropoffQuote,
		creatingDropoffQuote,
		dropoffQuote,
		dropoffQuoteError,
		onCreateDropoff,
		creatingDropoff,
		dropoffRequested,
		dropoffError,
		onCreateGentlyDropoff,
		onCreateGentlyDropoffNoNode,
		containers,
		onGetContainers,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [pickupNode, setPickupNode] = useState({})
	const [pickupInstructions, setPickupInstructions] = useState('')
	const [quantity, setQuantity] = useState({})
	const [dropoffDate, setDropoffDate] = useState('')
	const [pickupStartTime, setPickupStartTime] = useState('')
	const [pickupEndTime, setPickupEndTime] = useState('')
	const [dropoffStartTime, setDropoffStartTime] = useState('')
	const [dropoffEndTime, setDropoffEndTime] = useState('')
	const [dropoffNodes, setDropoffNodes] = useState([])
	const [dropoffInstructions, setDropoffInstructions] = useState('')
	const [signoffRequired, setSignoffRequired] = useState(false)
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [showNodeChoicesDropOff, setShowNodeChoicesDropOff] = useState(false)
	const [formError, setFormError] = useState('')
	const [partner, setPartner] = useState('')
	const [gentlyType, setGentlyType] = useState('')
	const [enabledFilter, setEnabledFilter] = useState({
		type: {
			$nin: [NodeTypes.none],
		},
	})
	const [products, setProducts] = useState([])

	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	const handleAddressChange = async (newAddress) => {
		if (newAddress.target) {
			setSearchedAddress(newAddress.target.value)
			geocodeAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
			geocodeAddress(newAddress.formatted_address)
		}
	}

	const handleAddressSubmit = () => {
		// console.log("geoCodedAddress", geoCodedAddress)
		setAddressError(false)
		setDropoffNodes((prevNodes) => {
			let tempArray = [...prevNodes]
			if (geoCodedAddress) {
				tempArray.push(geoCodedAddress)
			}
			return tempArray
		})
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (partner === 'gently') {
			navigate('/admin/dropoff-create/gently')
		} else if (partner === 'uberdirect') {
			navigate('/admin/dropoff-create/uberdirect')
		} else if (partner === 'flot') {
			navigate('/admin/dropoff-create/flot')
		}
	}, [partner])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		onGetContainers([
			{
				enabled: true,
			},
		])
	}, [onGetContainers])

	useEffect(() => {
		// console.log(filter)
		onGetNodes([filter])
	}, [filter])

	const handleGetQuote = () => {
		if (Object.keys(pickupNode).length === 0) {
			setFormError('Assign a pickup location')
		} else if (dropoffNodes.length === 0) {
			setFormError('Assign at least one dropoff location')
		} else if (
			pickupStartTime === '' ||
			pickupEndTime === '' ||
			dropoffStartTime === '' ||
			dropoffEndTime === ''
		) {
			setFormError('Assign time windows for pickup and dropoff')
		} else if (Object.keys(quantity).length < dropoffNodes.length) {
			// console.log(Object.keys(quantity).length, dropoffNodes.length)
			setFormError('Specify a quantity of containers per stop')
		} else {
			setFormError('')
			const dropoffPayload = {
				pickupNode: pickupNode.slug,
				dropoffNodes: dropoffNodes,
				dropoffInstructions: dropoffInstructions,
				pickupInstructions: pickupInstructions,
				quantity: quantity,
				pickupStartTime: pickupStartTime,
				pickupEndTime: pickupEndTime,
				dropoffStartTime: dropoffStartTime,
				dropoffEndTime: dropoffEndTime,
				signoffRequired: signoffRequired,
				dropoffDate: dropoffDate,
			}
			// console.log(dropoffPayload)
			onGetDropoffQuote(dropoffPayload)
		}
	}

	const handleAcceptQuote = (quoteId) => {
		onCreateDropoff(quoteId)
	}

	const changeQuantity = (e, nodeSlug) => {
		setQuantity({
			...quantity,
			[nodeSlug]: e.target.value,
		})
	}

	const removeFromDropoff = (nodeSlug) => {
		const tempObj = quantity
		delete tempObj[nodeSlug]
		setQuantity(tempObj)

		var tempArray = dropoffNodes
		tempArray = tempArray.filter((obj) => obj.slug !== nodeSlug)
		setDropoffNodes(tempArray)
	}

	const removeAddressFromDropoff = (address) => {
		const tempObj = products
		const newObj = {}
		for (const index in tempObj) {
			if (
				tempObj.hasOwnProperty(index) &&
				tempObj[index].node !== address
			) {
				newObj[index] = tempObj[index]
			}
		}
		setProducts(newObj)

		let tempArray = dropoffNodes
		tempArray = tempArray.filter((obj) => obj !== address)
		setDropoffNodes(tempArray)
	}

	const updateProduct = (e, containerId, description, nodeSlug) => {
		const updatedProducts = [...products]
		const existingProductIndex = updatedProducts.findIndex(
			(product) => product.id === containerId && product.node === nodeSlug
		)
		let inputValue = parseInt(e.target.value, 10)
		if (inputValue < 0) {
			inputValue = '0'
		}
		if (existingProductIndex !== -1) {
			updatedProducts[existingProductIndex] = {
				...updatedProducts[existingProductIndex],
				name: description,
				quantity: inputValue,
				node: nodeSlug,
			}
		} else {
			updatedProducts.push({
				id: containerId,
				name: description,
				quantity: inputValue,
				node: nodeSlug,
			})
		}

		setProducts(updatedProducts)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='px-12'>
					<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
						<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
							Request a Dropoff
						</h1>
						<div>
							<SelectGroup
								label=''
								placeholder='Select...'
								className='h-12'
								onChange={(e) => {
									setPartner(e.target.value)
									setProducts([])
									setDropoffNodes([])
									setFormError('')
								}}
								options={[
									{
										label: 'Doordash Quote',
										value: 'doordash',
									},
									{ label: 'Gently', value: 'gently' },
									{
										label: 'UberDirect',
										value: 'uberdirect',
									},
									{ label: 'Flot', value: 'flot' },
								]}
							/>
						</div>
						{partner && (
							<div>
								<div>
									<div className='text-lg font-bold mt-4'>
										Pick Up
									</div>
									<div className='w-4/5 max-w-[600px] mb-4'>
										<Input
											placeholder='Search and select pickup location'
											className=''
											name='searchInput'
											id='searchInput'
											onChange={(e) => {
												setShowNodeChoices(true)
												setFilter({
													$or: [
														{
															name: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
														{
															slug: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
													],
												})
											}}
										/>
									</div>
									<div className='flex-col flex items-center'>
										{showNodeChoices && (
											<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
												{nodes.map((node) => (
													<div
														className='text-sm cursor-pointer my-1 hover:bg-gray-100'
														key={node.slug}
														onClick={() => {
															setPickupNode(node)
															setShowNodeChoices(
																false
															)
														}}
													>
														{node.name}
													</div>
												))}
											</div>
										)}
									</div>
									<div className='flex flex-row'>
										<div className='mb-3'>
											<span className='font-bold '>
												Selected Pickup Location:
											</span>{' '}
											{pickupNode.name}
										</div>
										{Object.keys(pickupNode).length > 0 && (
											<div
												className='ml-4 font-bold text-lg cursor-pointer text-green-600'
												onClick={() => {
													setPickupNode({})
												}}
											>
												X
											</div>
										)}
									</div>
									<div className='flex flex-row mb-2'>
										<Input
											type='date'
											label='Date of Dropoff:'
											name='returnDate'
											onChange={(e) => {
												setDropoffDate(
													new Date(
														e.target.value +
															'T00:00:00'
													)
												)
											}}
											error=''
											className='bg-white border-2 border-gray-300 text-base min-w-[200px] w-2/3 h-11 mb-3'
											// defaultValue={startDate}
										/>
										<div className='ml-4 flex flex-col'>
											<label className='font-bold'>
												Pickup Start Window:
											</label>
											<input
												type='text'
												value={pickupStartTime}
												onChange={(e) => {
													setPickupStartTime(
														e.target.value
													)
												}}
												placeholder='HH:MM AM'
											/>
										</div>
										<div className='ml-4 flex flex-col'>
											<label className='font-bold'>
												Pickup End Window:
											</label>
											<input
												type='text'
												value={pickupEndTime}
												onChange={(e) => {
													setPickupEndTime(
														e.target.value
													)
												}}
												placeholder='HH:MM AM'
											/>
										</div>
									</div>
									<div className='font-bold'>
										HEADS UP! Specify the time in Eastern
										Time.
									</div>
									<TextArea
										name='Pickup Instructions'
										label='Pickup Instructions'
										placeholder='Include any special pickup instructions here'
										className='!h-20 !mt-1'
										onChange={(e) => {
											setPickupInstructions(e)
										}}
									/>
									<div className='text-lg font-bold mt-4'>
										Drop Off
									</div>
									<div className='w-4/5 max-w-[600px] mb-4'>
										<Input
											placeholder='Search and select dropoff locations'
											className=''
											name='searchInput'
											id='searchInput'
											onChange={(e) => {
												setShowNodeChoicesDropOff(true)
												setFilter({
													$or: [
														{
															name: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
														{
															slug: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
													],
												})
											}}
										/>
									</div>
									<div className='flex-col flex items-center'>
										{showNodeChoicesDropOff && (
											<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
												<div
													className='cursor-pointer text-green-600'
													onClick={() => {
														setShowNodeChoicesDropOff(
															false
														)
													}}
												>
													{' '}
													Close X
												</div>
												{nodes.map((node) => (
													<div
														className='text-sm cursor-pointer my-1 hover:bg-gray-100'
														key={node.slug}
														onClick={() => {
															var tempArray =
																dropoffNodes
															const found =
																tempArray.some(
																	(obj) =>
																		obj.slug ===
																		node.slug
																)
															if (found) {
															} else {
																tempArray.push(
																	node
																)
															}
															setDropoffNodes(
																tempArray
															)
															setShowNodeChoicesDropOff(
																false
															)
														}}
													>
														{node.name}
													</div>
												))}
											</div>
										)}
									</div>
									<div className='mb-3'>
										<span className='font-bold '>
											Selected Dropoff Locations:
										</span>
									</div>
									{dropoffNodes.map((node) => (
										<div
											className='flex flex-row'
											key={node.slug}
										>
											<div className='mt-1'>
												{node.name}:
											</div>
											<Input
												className='w-[100px]'
												type='number'
												step={25}
												placeholder='Quantity in units of 25'
												// value={quantity[node.slug] ? quantity[node.slug] : 0}
												onChange={(e) => {
													changeQuantity(e, node.slug)
												}}
											/>
											<div className='mt-1 text-sm'>
												boxes
											</div>
											<div
												className='ml-4 font-bold text-lg mt-1 cursor-pointer text-green-600'
												onClick={() => {
													removeFromDropoff(node.slug)
												}}
											>
												X
											</div>
										</div>
									))}
								</div>
								<div className='flex flex-row mb-2'>
									<div className='flex flex-col'>
										<label className='font-bold'>
											Dropoff Start Window:
										</label>
										<input
											type='text'
											value={dropoffStartTime}
											onChange={(e) => {
												setDropoffStartTime(
													e.target.value
												)
											}}
											placeholder='HH:MM AM'
										/>
									</div>
									<div className='ml-4 flex flex-col'>
										<label className='font-bold'>
											Dropoff End Window:
										</label>
										<input
											type='text'
											value={dropoffEndTime}
											onChange={(e) => {
												setDropoffEndTime(
													e.target.value
												)
											}}
											placeholder='HH:MM AM'
										/>
									</div>
								</div>

								<TextArea
									name='Drop Off Instructions'
									label='Drop Off Instructions'
									placeholder='Include any special delivery instructions here'
									className='!h-20 !mt-1'
									onChange={(e) => {
										setPickupInstructions(e)
									}}
								/>
								<CheckboxGroup
									label='Is a signature required from the restaurant?'
									name='default-address'
									className='mt-2 mb-1'
									defaultChecked={false}
									onChange={() => {
										setSignoffRequired(!signoffRequired)
									}}
								/>
								{formError !== '' && (
									<div className='font-bold text-red-400 my-1'>
										{formError}
									</div>
								)}
								{dropoffQuoteError && (
									<div className='font-bold text-red-400 my-1'>
										{dropoffQuoteError.message}
									</div>
								)}
								{dropoffError && (
									<div className='font-bold text-red-400 my-1'>
										{dropoffError.message
											? dropoffError.message
											: dropoffError}
									</div>
								)}
								{creatingDropoffQuote || creatingDropoff ? (
									<BounceLoaderCentered container='div' />
								) : (
									<Button
										className='mt-6'
										onClick={handleGetQuote}
										text='Get Dropoff Quote'
									/>
								)}
								{dropoffQuote && (
									<div>
										<div className='mt-2 text-xl'>
											<span className='font-bold'>
												Dropoff Quote:
											</span>
											$
											{(dropoffQuote.quote / 100).toFixed(
												2
											)}
										</div>
										<div className='mt-2 text-xl'>
											<span className='font-bold'>
												Dropoff Quote Id:
											</span>
											{dropoffQuote.id}
										</div>
										{creatingDropoff ? (
											<BounceLoaderCentered container='div' />
										) : (
											<Button
												className='mt-6'
												onClick={() => {
													handleAcceptQuote(
														dropoffQuote.id
													)
												}}
												text='Request Dropoff'
											/>
										)}
									</div>
								)}
								{dropoffRequested && (
									<div>
										<div className='mt-2 text-xl mb-6'>
											<span className='font-bold'>
												Status:
											</span>
											{dropoffRequested.status}
										</div>
										{/*    {dropoffRequested.dasher && (<div className="mt-2 text-xl"><span className="font-bold">Dasher Info:</span>{dropoffRequested.dasher.name}, {dropoffRequested.dasher.phone}</div>)}*/}
										{/*<div className="mt-2 text-xl"><span className="font-bold">Tracking URL:</span>{dropoffQuote.trackingUrl}</div>*/}
										<Button
											className='mb-6'
											link='/admin/dropoff'
											text='View Dropoff Statuses'
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, Admin, Containers, Dropoffs }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	creatingDropoffQuote: Dropoffs.creatingDropoffQuote,
	dropoffQuote: Dropoffs.dropoffQuote,
	dropoffQuoteError: Dropoffs.dropoffQuoteError,
	creatingDropoff: Dropoffs.creatingDropoff,
	dropoffRequested: Dropoffs.dropoffRequested,
	dropoffError: Dropoffs.dropoffError,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetDropoffQuote: (dropoffPayload) =>
		dispatch(getDropoffQuote(dropoffPayload)),
	onCreateDropoff: (dropoffQuoteId) =>
		dispatch(createDropoff(dropoffQuoteId)),
	onCreateGentlyDropoff: (dropoffPayload) =>
		dispatch(createGentlyDropoff(dropoffPayload)),
	onCreateGentlyDropoffNoNode: (dropoffPayload) =>
		dispatch(createGentlyDropoffNoNode(dropoffPayload)),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropoffWidget)
