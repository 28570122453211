import { connect } from 'react-redux'
import React, { useEffect, useState, useRef } from 'react'
import {
	getUser,
	getLead,
	generateDocuSign,
	updateLead,
	finalizeNode,
	getServiceAreas,
} from '../../../store/actions'
import { isAddressInsideDeliveryPolygon } from '../../../helpers/googleMapsAPI'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import {
	Button,
	Input,
	Alert,
	BounceLoaderCentered,
} from '../../../components/Elements'
import { useNavigate } from 'react-router-dom'
import { realm } from '../../../helpers/realm'

const SignUpPage4 = (state) => {
	const {
		user,
		lead,
		loadingLead,
		updatedLead,
		updatingLead,
		updateLeadError,
		docuSignUrl,
		docuSignError,
		loadingDocuSign,
		finalizedNode,
		loadingFinalizeNode,
		finalizeNodeError,
		onGetUser,
		onGetLead,
		onGenerateDocuSign,
		onUpdateLead,
		onFinalizeNode,
		onGetServiceAreas,
		serviceAreaPolygons,
	} = state

	const isInitialRender = useRef(true)

	const [docuSignLoading, setDocuSignLoading] = useState(true) // set to false once UI has been rendered
	const [signingComplete, setSigningComplete] = useState(false)
	const [countdown, setCountdown] = useState(5)
	const [generateDocuSignCalled, setGenerateDocuSignCalled] = useState(false)

	const bounceLoaderColor = '#507f74'
	const currentPage = 4
	const totalPages = 4
	const progressPercentage = (currentPage / totalPages) * 100
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			onGetLead({ slug: user.restaurantAdmin })
		}
	}, [user])

	useEffect(() => {
		if (lead) {
			if (lead.signUpComplete) {
				navigate('/restaurant-admin/home')
			} else {
				onGetServiceAreas()
			}
		}
	}, [lead])

	useEffect(() => {
		if (
			lead &&
			lead.lastPage === 3 &&
			serviceAreaPolygons &&
			!generateDocuSignCalled
		) {
			setGenerateDocuSignCalled(true)
			var market = ''
			if (
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['CO']
				) ||
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['SF']
				) ||
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['LA']
				)
			) {
				market = 'CO/CA'
			} else if (
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['NYNJ']
				)
			) {
				market = 'NY/NJ'
			} else {
				market = 'outside'
			}

			const docuSignPayload = {
				firstName: lead.firstName,
				lastName: lead.lastName,
				title: lead.title,
				restaurantName: lead.restaurantName,
				address: lead.fullAddress,
				email: lead.email,
				adminPinCode: lead.adminPinCode,
				dd: lead.orderingPlatforms
					? lead.orderingPlatforms.includes('doorDashCaviar')
						? true
						: false
					: false,
				ue: lead.orderingPlatforms
					? lead.orderingPlatforms.includes('uberEatsPostmates')
						? true
						: false
					: false,
				market: market,
				ach: lead.payWithACH ? true : false,
				containerUsage: lead.hasCommercialDishwasher,
			}

			onGenerateDocuSign(docuSignPayload)
		}
	}, [lead, serviceAreaPolygons, generateDocuSignCalled])

	useEffect(() => {
		if (!isInitialRender.current && finalizedNode) {
			navigate('/restaurantpartners/sign-up/page-5')
		}
		// set isInitialRender to false after the first render
		isInitialRender.current = false
	}, [finalizedNode])

	useEffect(() => {
		// const loadDocuSign = async () => {
		//  try {
		//      // Load the DocuSign library
		//      const docusign = await window.DocuSign.loadDocuSign(
		//          '4ec3b742-b5db-4f62-b9d4-478c344e60a7'
		//      )

		//      // Use the docusign object to initialize signing
		//      const signing = docusign.signing({
		//          url: docuSignUrl.url,
		//          displayFormat: 'focused',
		//          style: {
		//              branding: {
		//                  primaryButton: {
		//                      backgroundColor: '#507f74',
		//                      color: '#fff',
		//                  },
		//              },
		//              signingNavigationButton: {
		//                  finishText: 'Finish',
		//                  position: 'bottom-left',
		//              },
		//          },
		//      })

		//      // Event handlers
		//      signing.on('ready', (event) => {
		//          setDocuSignLoading(false)
		//      })

		//      signing.on('sessionEnd', (event) => {
		//          setSigningComplete(true)
		//      })

		//      // Mount the signing UI to the container with id 'agreement'
		//      signing.mount('#agreement')
		//  } catch (error) {
		//      console.error('Error loading DocuSign:', error)
		//  }
		// }

		if (docuSignUrl && docuSignUrl.url) {
			setDocuSignLoading(false)
			const interval = setInterval(() => {
				setCountdown((currentCountdown) => {
					if (currentCountdown > 1) {
						return currentCountdown - 1
					} else {
						clearInterval(interval) // Clear the interval
						window.location.href = docuSignUrl.url // Redirect
						return 0
					}
				})
			}, 1000)

			// cleanup function to clear the interval on component unmount
			return () => clearInterval(interval)
		}
	}, [docuSignUrl])

	// const handleClickFinish = async () => {
	//  try {
	//      await onUpdateLead({
	//          slug: user.restaurantAdmin,
	//          lastPage: 4,
	//          signUpComplete: true,
	//          lastUpdated: new Date(),
	//      })
	//      await onFinalizeNode({ adminPinCode: lead.adminPinCode })
	//  } catch (error) {
	//      console.error('ERRR', error)
	//  }
	// }

	if (user) {
		if (user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<div
						id='signup'
						className='p-0 m-0 sm:p-10'
						style={{
							backgroundImage: `url("https://i.imgur.com/N8Tqgz8.jpg")`,
							height: 'full',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
						}}
					>
						<div className='flex flex-col m-auto sm:mb-10 mx-20'>
							<div
								className='underline font-bold text-green-600 cursor-pointer ml-3'
								onClick={() => {
									navigate(
										'/restaurantpartners/sign-up/page-3'
									)
								}}
							>
								&#8592; Go Back
							</div>
							<div className='bg-gray-200 h-4 rounded-full mt-6'>
								<div
									className='bg-green-600 h-full rounded-full'
									style={{
										width: `${progressPercentage}%`,
									}}
								></div>
								<div className='text-gray-600 text-sm pl-3'>
									PAGE {currentPage} OF {totalPages}
								</div>
							</div>
						</div>
						<div className='z-20 flex flex-col p-5 mx-auto max-w-[500px] bg-green-600 shadow-light-grey rounded-sm sm:max-w-[1200px] mt-5'>
							<div className='bg-white shadow-light-grey rounded-xl w-full min-h-[700px] overflow-y-auto scrollbar'>
								{lead && lead.lastPage === 3 ? (
									<div>
										<h1 className='font-header text-green-600 text-2xl sm:text-4xl mb-3 mt-6 text-center'>
											Almost there!
										</h1>
										{docuSignUrl && docuSignUrl.url && (
											<div className='items-center text-center justify-center'>
												<div className='text-xl mt-5 mb-3'>
													Please click the link below
													if you are not automatically
													redirected in {countdown}{' '}
													seconds...
												</div>
												<a
													href={docuSignUrl.url}
													className='font-semibold whitespace-wrap underline text-green-600'
												>
													Link to sign forms
												</a>
											</div>
										)}
										{(docuSignLoading ||
											loadingFinalizeNode) && (
											<div className='text-center'>
												<BounceLoaderCentered container='page' />
												Loading signature page..
											</div>
										)}
										{/* {signingComplete && (
                                            <div className='flex justify-center py-20'>
                                                <Button
                                                    className='w-[200px]'
                                                    size='sm'
                                                    onClick={handleClickFinish}
                                                    text='Complete sign up'
                                                ></Button>
                                            </div>
                                        )} */}
										{/* FOCUSED VIEW  */}
										{/* <div
                                            id='agreement'
                                            style={{ height: '650px' }}
                                        ></div> */}
									</div>
								) : (
									<BounceLoaderCentered />
								)}
							</div>
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			navigate('/')
		}
	} else {
		return (
			<>
				<Header />
				<BounceLoaderCentered container='page' />
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ User, Leads }) => ({
	user: User.user,
	loadingUser: User.loading,
	lead: Leads.lead,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	updatedLead: Leads.updatedLead,
	updatingLead: Leads.updatingLead,
	updateLeadError: Leads.updateLeadError,
	docuSignUrl: Leads.docuSignUrl,
	loadingDocuSign: Leads.loadingDocuSign,
	docuSignError: Leads.docuSignError,
	finalizedNode: Leads.finalizedNode,
	loadingFinalizeNode: Leads.loadingFinalizeNode,
	serviceAreaPolygons: Leads.serviceAreaPolygons,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetLead: (payload) => dispatch(getLead(payload)),
	onGenerateDocuSign: (payload) => dispatch(generateDocuSign(payload)),
	onUpdateLead: (payload) => dispatch(updateLead(payload)),
	onFinalizeNode: (payload) => dispatch(finalizeNode(payload)),
	onGetServiceAreas: () => dispatch(getServiceAreas()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage4)
