import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import { Footer } from '../../components'
import { getOneOrder, getOrders } from '../../store/orders/actions'
import { getUser } from '../../store/auth/user/actions'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import UserProfileCard from '../../components/UserProfile/UserProfileCard'

const ViewDirectOrders = (props) => {
	const {
		user,
		loadingUser,
		onGetOneOrder,
		loadingOrder,
		order,
		orderError,
	} = props
	const { orderId } = useParams()
	const bounceLoaderColor = '#507f74'

	const orderStatusDict = order
		? {
				NEW_ORDER: 'Order sent to restaurant',
				ORDER_ACCEPTED: 'Your order is being prepped',
				ORDER_PREPPED:
					order.fulfillmentInfo.fulfillmentMode === 'DELIVERY'
						? 'Your order has been made and will soon be dispatched'
						: 'Your order has been made and will soon be ready for pickup',
				ORDER_READY_TO_PICKUP:
					order.fulfillmentInfo.fulfillmentMode === 'DELIVERY'
						? 'Your order has been prepared and is getting dispatched'
						: 'Your order is ready for pickup',
				ORDER_HANDED_OFF:
					order.fulfillmentInfo.fulfillmentMode === 'DELIVERY'
						? 'Order is out for delivery'
						: 'Order has been picked up',
				ORDER_FULFILLED: 'Order has been completed',
				CANCELED:
					'Order has been canceled by restaurant. Contact restaurant or support.',
		  }
		: {}

	useEffect(() => {
		if (orderId) {
			onGetOneOrder(orderId)
		}
	}, [onGetOneOrder])

	// console.log(order)

	function amount(mod) {
		return mod.price
	}

	function sum(prev, next) {
		return prev + next
	}

	if (!order)
		return (
			<div>
				<Header />
				<div className='flex items-center h-screen-no-header'>
					<BounceLoader
						className='m-auto w-full'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</div>
		)

	return (
		<div>
			<Header />
			<h1 className='font-vollkorn text-green-600 text-4xl mt-14 text-center'>
				View Order Info
			</h1>
			<div className=' h-screen-no-header flex justify-center'>
				<UserProfileCard
					titleClassName='text-green-400 text-3.5xl '
					className='max-w-xs mt-5 m-10 overflow-y-auto min-w-[300px] md:min-w-[500px] lg:min-w-[720px] h-[500px] shadow-lighter-grey'
					title={`Order ${order.externalIdentifiers.id}`}
					content={
						<div className='flex flex-col'>
							<div className='my-1'>
								<span className='font-header'>Subtotal: </span>$
								{order.subtotal.toFixed(2)}
							</div>
							<div className='my-1'>
								<span className='font-header'>Tax: </span>$
								{order.taxes.toFixed(2)}
							</div>
							<div className='my-1'>
								<span className='font-header'>Fees: </span>$
								{(order.deliveryFee + order.serviceFee).toFixed(
									2
								)}
							</div>
							<div className='my-1'>
								<span className='font-header'>Tip: </span>$
								{order.tip.toFixed(2)}
							</div>
							<div className='my-1'>
								<span className='font-header'>Total: </span>$
								{order.dzTotal.toFixed(2)}
							</div>
							<div className='my-1'>
								<span className='font-header'>
									Fulfillment Method:{' '}
								</span>
								<span className='capitalize'>
									{order.fulfillmentInfo.fulfillmentMode.toLowerCase()}
								</span>
							</div>

							<div className='my-1'>
								<span className='font-header'>
									Order Status:{' '}
								</span>
								{orderStatusDict[order.status]}
							</div>
							<div className='mt-5 mb-1'>
								<span className='font-header'>Items: </span>
							</div>
							{order.items.map((item) => {
								let modifierTotal = 0
								if (item.modifiers.length > 0) {
									modifierTotal = item.modifiers
										.map(amount)
										.reduce(sum)
								}
								return (
									<div className='ml-2'>
										<div className='flex flex-row'>
											<div className='w-3/4'>
												<span className='font-header'>
													{item.quantity} X{' '}
													{item.name}
												</span>
											</div>
											<div>
												<span className='text-sm'>
													$
													{(
														item.quantity *
														(item.price +
															modifierTotal)
													).toFixed(2)}{' '}
												</span>
											</div>
										</div>
										<div>
											<span className='font-header'>
												Modifiers:
											</span>
											{item.modifiers.map((mod) => {
												return (
													<span>
														{item.modifiers.length >
															1 && (
															<span className='text-sm'>
																{' '}
																{mod.name}
																{','}
															</span>
														)}
														{item.modifiers
															.length == 1 && (
															<span className='text-sm'>
																{' '}
																{mod.name}
															</span>
														)}
													</span>
												)
											})}
										</div>
										_____________________
									</div>
								)
							})}
						</div>
					}
				/>
			</div>
			<Footer />
		</div>
	)
}
const mapStateToProps = ({ User, Orders }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingOrder: Orders.loading,
	order: Orders.oneOrder,
	orderError: Orders.error,
})
const mapDispatchToProps = (dispatch) => ({
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetOneOrder: (payload) => dispatch(getOneOrder(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewDirectOrders)
