import { call, put, takeEvery } from 'redux-saga/effects'

import {
	getRAASMerchantSuccess,
	getRAASMerchantError,
	getRAASCustomerSuccess,
	getRAASCustomerError,
	insertRAASTransactionSuccess,
	insertRAASTransactionError,
	getAllRAASMerchantsSuccess,
	getAllRAASMerchantsError,
	getRAASTransactionsSuccess,
	getRAASTransactionsFail,
	runAggregationRaasSuccess,
	runAggregationRaasError,
} from './actions'

import {
	GET_RAAS_MERCHANT,
	GET_RAAS_CUSTOMER,
	INSERT_RAAS_TRANSACTION,
	GET_ALL_RAAS_MERCHANTS,
	GET_RAAS_TRANSACTIONS,
	RUN_AGGREGATION_RAAS,
} from './actionTypes'

import {
	getRAASMerchant,
	getRAASCustomer,
	insertRAASTransaction,
	getRAASMerchants,
	getRAASTransactions,
	transactionAggregation,
} from '../../model/raas'

// Fetch RAAS Merchant Saga
function* fetchRaasMerchant(action) {
	try {
		const raasMerchant = yield call(getRAASMerchant, action.payload)
		yield put(getRAASMerchantSuccess(raasMerchant))
	} catch (error) {
		yield put(getRAASMerchantError(error))
	}
}

// Fetch RAAS Customer Saga
function* fetchRaasCustomer(action) {
	try {
		const raasCustomer = yield call(getRAASCustomer, action.payload)
		yield put(getRAASCustomerSuccess(raasCustomer))
	} catch (error) {
		yield put(getRAASCustomerError(error))
	}
}

// Insert RAAS Transaction Saga
function* insertRaasTransaction(action) {
	try {
		const insertedRaasTransaction = yield call(
			insertRAASTransaction,
			action.payload
		)
		yield put(insertRAASTransactionSuccess(insertedRaasTransaction))
	} catch (error) {
		yield put(insertRAASTransactionError(error))
	}
}

// Fetch All RAAS Merchants Saga
function* fetchAllRaasMerchants(action) {
	try {
		const raasMerchants = yield call(getRAASMerchants, action.payload)
		yield put(getAllRAASMerchantsSuccess(raasMerchants))
	} catch (error) {
		yield put(getAllRAASMerchantsError(error))
	}
}

function* aggregateThis(action) {
	try {
		const transactions = yield call(transactionAggregation, action.payload)
		yield put(runAggregationRaasSuccess(transactions))
		if (typeof action.callback === 'function') {
			action.callback(transactions)
		}
	} catch (error) {
		yield put(runAggregationRaasError(error))
		if (typeof action.callback === 'function') {
			action.callback(null, error)
		}
	}
}

// Main RAAS Saga
function* raasSaga() {
	yield takeEvery(GET_RAAS_MERCHANT, fetchRaasMerchant)
	yield takeEvery(GET_RAAS_CUSTOMER, fetchRaasCustomer)
	yield takeEvery(INSERT_RAAS_TRANSACTION, insertRaasTransaction)
	yield takeEvery(GET_ALL_RAAS_MERCHANTS, fetchAllRaasMerchants)
	yield takeEvery(RUN_AGGREGATION_RAAS, aggregateThis)
}

export default raasSaga
