import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	createTransaction,
	getNodeByAdminCodeOrSlug,
	getUser,
	resetTransaction,
	updateUser,
} from '../../store/actions'

import { Header, Footer } from '../../components'
import { Button, Input } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'
import ReactConfetti from 'react-confetti'
import { GiPartyPopper } from 'react-icons/gi'
import PhoneInput from 'react-phone-number-input/input'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const CheckBoxesOutModule = (props) => {
	const {
		onGetUser,
		onGetNodeBySlugAdmin,
		loadingNode,
		node,
		user,
		onCreateTransaction,
		transaction,
		createTransactionError,
		creatingTransaction,
		onResetTransaction,
		onUpdateUser,
		updateUserLoading,
	} = props

	const navigate = useNavigate()
	const [showSuccess, setShowSuccess] = useState(false)
	const [qty, setQty] = useState(0)
	const [returnImage, setReturnImage] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const [submitDisabled, setSubmitDisabled] = useState(true)
	const [customerPhone, setCustomerPhone] = useState('')
	const [missingPhoneError, setMissingPhoneError] = useState(false)
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [chargePerContainer, setChargePerContainer] = useState(false)
	const bounceLoaderColor = '#507f74'

	const openSupport = () => {
		window.Intercom('show')
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	const handlePhone = (e) => {
		setMissingPhoneError(false)
		setCustomerPhone(e)
	}

	const submitTransaction = (e) => {
		e.preventDefault()
		// console.log(customerPhone)
		if (
			customerPhone &&
			customerPhone !== '' &&
			customerPhone.replaceAll('+1', '').length === 10
		) {
			setMissingPhoneError(false)
			const transactionPayload = {
				timestamp: new Date(),
				type: 'boxesOut',
				boxCount: qty,
				items: [
					{
						product: {
							name: 'Overall Container Count',
						},
						boxCount: qty,
					},
				],
				client: 'deliverzero',
				node: node.slug,
				user: {
					phone: customerPhone,
					firstName: 'Friend',
					lastName: '',
				},
				checkoutModule: true,
			}
			if (chargePerContainer) {
				transactionPayload.chargePerContainer = chargePerContainer
			}
			// console.log(transactionPayload)
			onCreateTransaction(transactionPayload)
		} else {
			setMissingPhoneError(true)
		}
	}

	useEffect(() => {
		if (transaction) {
			setShowSuccess(true)
		}
		return function cleanup() {
			onResetTransaction()
		}
	}, [transaction])

	const changeTheMerchant = (selectedNodeSlug) => {
		onUpdateUser({
			restaurantAdmin: selectedNodeSlug,
		})
	}

	const handleWheel = (e) => {
		// Disable scrolling with arrow keys or mouse wheel
		e.preventDefault()
	}

	// console.log(node?.name)

	useEffect(() => {
		if (node) {
			if (node.slug === 'rincon-argentino---catering') {
				setCustomerPhone('+15555555555')
			}
			if (node.priceToCustomerPerContainer) {
				setChargePerContainer(true)
			}
		}
	}, [node])

	if (user?.restaurantAdmin && node && !updateUserLoading) {
		return (
			<div className=' h-full w-full flex flex-col justify-between '>
				<Header />
				<div
					className='underline font-bold mt-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/restaurant-admin/home')
					}}
				>
					&#8592; Go to Dashboard Home
				</div>
				{!showSuccess && (
					<div className='h-full flex flex-col justify-between items-center overflow-hidden'>
						{errorMessage && (
							<div
								className='text-red-400 font-bold'
								content={errorMessage}
								type='danger'
							>
								{errorMessage}
							</div>
						)}

						{loadingNode && <BounceLoader />}
						{!loadingNode && (
							<>
								<div className='flex flex-col w-2/3 items-center justify-center container mb-2 pt-6'>
									<div className='text-center'>
										<h1 className='text-3xl md:text-4xl font-bold font-header text-green-600 mb-4'>
											Check containers out to customer
										</h1>
										{user.restaurantAdminOtherLocations &&
										user.restaurantAdminOtherLocations
											.length > 0 ? (
											<div className='flex flex-row justify-center'>
												<div className='text-lg'>
													From:{' '}
													<span className='font-bold'>
														{node?.name}
													</span>
												</div>
												{showNodeChoices ? (
													<BsChevronUp
														onClick={() => {
															setShowNodeChoices(
																false
															)
														}}
														className='cursor-pointer ml-2 text-green-600 text-lg sm:text-xl mt-2 font-bold'
													/>
												) : (
													<BsChevronDown
														onClick={() => {
															setShowNodeChoices(
																true
															)
														}}
														className='cursor-pointer ml-2 text-green-600 text-lg sm:text-xl mt-2 font-bold'
													/>
												)}
											</div>
										) : (
											<div className='text-lg'>
												From:{' '}
												<span className='font-bold'>
													{node?.name}
												</span>
											</div>
										)}
										<div className='flex-col flex items-center'>
											{showNodeChoices && (
												<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
													{user.restaurantAdminOtherLocations.map(
														(location) => (
															<div
																className='text-sm cursor-pointer my-1 hover:bg-gray-100'
																key={
																	location.nodeSlug
																}
																onClick={() => {
																	changeTheMerchant(
																		location.nodeSlug
																	)
																	setShowNodeChoices(
																		false
																	)
																}}
															>
																{location.name}
															</div>
														)
													)}
												</div>
											)}
										</div>
									</div>
								</div>
								<div className='border-2 border-green-600 shadow-2xl flex flex-col lg:flex-row md:w-2/3 min-h-[20em] my-6 items-center container mb-2 rounded-xl overflow-hidden relative'>
									<div className='flex flex-col justify-between items-center h-full w-full p-6 sm:px-8 sm:py-12 z-10 bg-white text-center lg:text-left'>
										{node?.slug !==
											'rincon-argentino---catering' && (
											<div>
												<div className='my-4 lg:mr-4 text-xl md:text-2xl'>
													Enter customer's phone
													number (required)
												</div>
												<PhoneInput
													onChange={handlePhone}
													country='US'
													value={customerPhone}
													id='phone'
													name='phone'
													label='PHONE'
													className={` form-control bg-white border-2 border-gray-300 w-full max-w-[300px] border border-2 border-gray-200 rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow ${
														missingPhoneError &&
														`border-red-600`
													}`}
													type='phone'
												/>
											</div>
										)}
										<div className='my-4 lg:mr-4 text-xl md:text-2xl'>
											How many containers is the customer
											getting?
										</div>
										<div className='border-2 rounded-full mb-3 sm:mb-0 lg:w-1/3 w-full text-center flex justify-between px-3 items-center'>
											<span
												color='default'
												className='text-4xl text-gray-300 w-1/3 cursor-pointer'
												onClick={() => {
													if (qty >= 2) {
														setQty(qty - 1)
													}
												}}
											>
												-
											</span>
											{node?.slug ===
											'rincon-argentino---catering' ? (
												<Input
													type='number'
													className='text-xl sm:text-2xl mx-2 w-[100px]'
													value={qty}
													onDrag={handleWheel}
													onChange={(e) => {
														console.log(
															e.target.value
														)
														setQty(
															parseInt(
																e.target.value
															)
														)
													}}
												/>
											) : (
												<div className='text-2xl'>
													{qty}
												</div>
											)}
											<span
												color='default'
												className='text-2xl text-gray-300 w-1/3 cursor-pointer'
												onClick={() => {
													// if (qty < user.boxCount)
													setQty(qty + 1)
												}}
											>
												+
											</span>
										</div>
										{node.priceToCustomerPerContainer && (
											<div className='flex flex-row items-start mt-3'>
												<div
													style={{
														width: '1.5rem',
														height: '1.5rem',
													}}
												>
													<input
														type='checkbox'
														defaultChecked={
															chargePerContainer
														}
														onChange={(check) => {
															setChargePerContainer(
																check.target
																	.checked
															)
														}}
														className='appearance-none w-6 h-6 border border-gray-300 rounded-lg outline-none cursor-pointer checked:bg-green-400'
													/>
												</div>
												<p>
													In-person order (whether for
													dine in or takeout)
												</p>
											</div>
										)}
										<div className='mt-4 min-w-32'>
											{creatingTransaction && (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
											{!creatingTransaction && (
												<div>
													{qty > 0 ? (
														<Button
															text='Submit'
															size='base'
															color='red'
															onClick={(e) =>
																submitTransaction(
																	e
																)
															}
															className='px-8 py-3'
														/>
													) : (
														<Button
															text='Submit'
															size='base'
															color='grey'
															className='px-8 py-3'
														/>
													)}
												</div>
											)}
										</div>
									</div>
								</div>

								<div className='mx-2 text-center inline-block mt-8 mb-4 text-lg'>
									Need Help?{' '}
									<a
										className='text-green-600 font-bold hover:text-green-400'
										onClick={openSupport}
									>
										Chat with us
									</a>
								</div>
							</>
						)}
					</div>
				)}
				{showSuccess && (
					<div className='h-full lg:h-screen-3/4 my-5 flex flex-col justify-center items-center overflow-hidden'>
						<ReactConfetti colors={['#f38b74']} />
						<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
							You're all set!
						</h1>
						<GiPartyPopper className='text-orange-600 text-[250px]' />
						<Button
							onClick={() => window.location.reload()}
							className='px-10 w-[260px] mt-5 shadow-light-grey'
							text='Checkout More Containers'
						/>
					</div>
				)}

				<Footer />
			</div>
		)
	} else {
		return (
			<>
				<Header />
				<div className='h-full flex flex-col items-center justify-center my-10'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ Nodes, User, TransactionsCreate }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
	updateUserLoading: User.updateUserLoading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onGetUser: () => dispatch(getUser()),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onUpdateUser: (payload) => dispatch(updateUser(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckBoxesOutModule)
