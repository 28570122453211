import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getOffice, getOfficeCustomers } from '../../store/actions'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../../components'
import Header from '../../components/Header'

const OfficeOrders = (state) => {
	const {
		onGetUser,
		onGetOffice,
		onGetCustomers,
		customers,
		loadingCustomers,
		office,
		loadingOffice,
		user,
		loadingUser,
	} = state

	const paginationLimit = 25
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	const [activeRow, setActiveRow] = useState(-1)

	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.officeSlug) {
			onGetOffice(user.officeSlug)
		}
	}, [user])

	const getCustomers = (page) => {
		const slug = office.slug
		let filter = {
			email: { $regex: slug },
		}

		onGetCustomers({
			filter: filter,
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const customersPaginate = () => {
		getCustomers(page)
	}

	useEffect(() => {
		if (office && office.slug) {
			customersPaginate()
		}
	}, [office, page])

	const handleRowHover = (index) => {
		setActiveRow(index)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.officeSlug) {
		if (office) {
			const isAggregatedOrdering = office.aggregatedOrdering === true
			return (
				<>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/office-ordering/home')
						}}
					>
						&#8592; Back to Office Dashboard
					</div>
					<div className='flex flex-col items-center'>
						{isAggregatedOrdering ? (
							<h1 className='font-header text-green-600 text-xl sm:text-2xl md:text-4xl mb-3 sm:mb-9 mt-5 sm:mt-14 text-center'>
								{office.name}'s Office Locations Dashboard
							</h1>
						) : (
							<h1 className='font-header text-green-600 text-xl sm:text-2xl md:text-4xl mb-3 sm:mb-9 mt-5 sm:mt-14 text-center'>
								{office.name}'s Employee Dashboard
							</h1>
						)}
					</div>
					<div className='w-full'>
						<div className='flex flex-col max-w-[1200px] justify-center mb-10 mx-auto overflow-x-auto overflow-y-auto scrollbar'>
							<div className='w-full'>
								{customers.length > 0 ? (
									<div>
										<div className='min-w-[570px] w-[570px] sm:w-[940px] px-2 sm:px-5 py-5 my-6 shadow-light-grey mx-auto'>
											<div className='flex flex-row min-w-[550px] w-[550px] sm:w-[900px] mb-3 px-2 sm:px-5 text-green-600 text-left font-bold'>
												{isAggregatedOrdering ? (
													<>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Office Location
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Packaging Prevented
															Overall
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Packaging Currently
															Out
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Late Packaging
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Return Rate
														</div>
													</>
												) : (
													<>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Employee
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Packaging Prevented
															Overall
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Packaging Currently
															Out
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Late Packaging
														</div>
														<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[200px] mr-2 sm:mr-4'>
															Return Rate
														</div>
													</>
												)}
											</div>
											{(loadingCustomers ||
												loadingOffice) && (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
											{customers.map(
												(customer, index) => {
													return (
														<div
															className='min-w-[550px] w-[550px] sm:w-[900px] flex flex-row my-2 even:bg-gray-100 py-1 px-2 sm:px-5'
															key={customer._id}
														>
															<div
																className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 underline mr-2 sm:mr-4'
																onClick={() => {
																	const url = `/office-ordering/employees/${customer.phone}`
																	navigate(
																		url
																	)
																}}
															>
																{
																	customer.firstName
																}{' '}
																{
																	customer.lastName
																}
															</div>
															<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
																{customer.getTotalPackagingPrevented
																	? customer
																			.getTotalPackagingPrevented
																			.totalPackagingPrevented
																	: 0}
															</div>
															<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
																{customer.getTotalPackagingPrevented
																	? customer
																			.getTotalPackagingPrevented
																			.currentPackagingOut
																	: 0}
															</div>
															<div
																className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 relative'
																onMouseEnter={() =>
																	handleRowHover(
																		index
																	)
																}
																onMouseLeave={() =>
																	handleRowHover(
																		-1
																	)
																}
																onTouchStart={() =>
																	handleRowHover(
																		index
																	)
																}
																onTouchEnd={() =>
																	handleRowHover(
																		-1
																	)
																}
															>
																{customer
																	.getLatePackagingByCustomer
																	?.lateBoxCount !==
																	undefined &&
																customer
																	.getLatePackagingByCustomer
																	.lateBoxCount >=
																	1 ? (
																	<>
																		<span className='text-red-400 cursor-pointer'>
																			YES
																		</span>
																		{activeRow ===
																			index && (
																			<div className='absolute top-[-30px] left-0 p-2 bg-white border border-red-400 rounded shadow-lg z-10'>
																				Number
																				of
																				late
																				boxes:{' '}
																				{
																					customer
																						.getLatePackagingByCustomer
																						.lateBoxCount
																				}
																			</div>
																		)}
																	</>
																) : (
																	<span className='text-green-600 cursor-pointer'>
																		NO
																	</span>
																)}
															</div>
															<div className='min-w-[100px] w-[100px] sm:w-[200px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
																{Math.round(
																	customer
																		.getCustomerReturnRate
																		?.returnRate
																)
																	? Math.round(
																			customer
																				.getCustomerReturnRate
																				.returnRate
																	  ) + '%'
																	: '-'}
															</div>
														</div>
													)
												}
											)}
										</div>
										{customers.length > paginationLimit ? (
											<div className='flex flex-row'>
												<div
													className='mx-auto underline font-bold mr-6 cursor-pointer'
													onClick={() => {
														setPage(page - 1)
													}}
												>
													Previous {paginationLimit}{' '}
													employees
												</div>
												<div
													className='mx-auto underline font-bold cursor-pointer'
													onClick={() => {
														setPage(page + 1)
														setLoading(true)
													}}
												>
													Next {paginationLimit}{' '}
													employees
												</div>
											</div>
										) : (
											<div></div>
										)}
										{loadingCustomers && (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										)}
									</div>
								) : (
									<div className='text-center my-6'>
										No orders to display
									</div>
								)}
							</div>
						</div>
					</div>
					<Footer />
				</>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, OfficeOrdering, Aggregation }) => ({
	customers: OfficeOrdering.customers,
	loadingCustomers: OfficeOrdering.loadingCustomers,
	office: OfficeOrdering.office,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	customerError: OfficeOrdering.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOffice: (officeSlug) => dispatch(getOffice(officeSlug)),
	onGetCustomers: (payload) => dispatch(getOfficeCustomers(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeOrders)
