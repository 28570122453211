import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { FaShoppingCart } from 'react-icons/fa'
import { useEffect, useRef } from 'react'
import { getCart, getUser } from '../store/actions'
import { CartItem } from './'
import useOutsideClick from './Elements/DetectOutsideClick'

const MobileBottomNav = (props) => {
	const {
		cartNodeSlug,
		onGetCart,
		user,
		onGetUser,
		cartItems,
		path,
		cartTotal,
	} = props

	const navigate = useNavigate()

	const [cartDropUp, setCartDropUp] = React.useState(false)
	const [cartItemsNodeSlug, setCartItemsNodeSlug] = useState(null)

	const { nodeSlug } = useParams()

	useEffect(() => {
		onGetUser()
	}, [])

	useEffect(() => {
		if (user && user.activeCart) {
			// console.log('getting new cart')
			onGetCart()
		}
	}, [user, nodeSlug, JSON.stringify(user?.activeCart)])

	const impactRef = useRef(null)

	useOutsideClick(impactRef, () => {
		setCartDropUp(false)
	})

	useEffect(() => {
		if (cartItems) {
			if (cartItems[0]) {
				setCartItemsNodeSlug(cartItems[0].nodeSlug)
			}
		}
	}, [cartItems, JSON.stringify(cartItems)])

	return (
		<div className='w-full h-[60px] fixed bottom-0 bg-white z-50 shadow-lighter-grey sm:hidden'>
			<div className='flex justify-between items-center text-center h-full pl-4 pr-6 relative'>
				<div
					className='cursor-pointer flex flex-col justify-center items-center text-center -mr-4'
					onClick={() => navigate(-1)}
				>
					<MdOutlineArrowBackIosNew className='text-4xl text-dark-grey' />
				</div>
				<Link to='marketplace/locations'>
					<div className=' flex flex-col cursor-pointer justify-center items-center text-center'>
						<img
							src='/images/HowItWorks/Not apples.png'
							alt='an icon of deliverzero containers'
							className='h-[35px]'
						/>
						<p className='text-xs'>Order Now</p>
					</div>
				</Link>
				<Link to='returns'>
					<div className='cursor-pointer flex flex-col justify-center items-center text-center'>
						<img
							src='/images/HowItWorks/House.svg'
							alt='an icon of deliverzero containers'
							className='h-[35px]'
						/>
						<p className='text-xs'>Returns</p>
					</div>
				</Link>
				{path != '/marketplace/test-api-restaurant' ? (
					<Link
						to={
							cartItemsNodeSlug &&
							cartItems &&
							cartItems.length > 0
								? `/marketplace/${cartItemsNodeSlug}`
								: '/marketplace/locations'
						}
					>
						<div className=' flex flex-col justify-between items-center text-center relative'>
							<FaShoppingCart className='text-4xl text-green-600 hover:text-green-400' />{' '}
							<p className='text-xs'>Cart</p>
							{cartItems && (
								<div className='text-green-600 font-semibold ml-1 absolute transfrom translate-x-6'>
									{cartItems.length}
								</div>
							)}
						</div>
					</Link>
				) : (
					<div
						className=' flex flex-col justify-between items-center text-center cursor-pointer '
						onClick={() => setCartDropUp(true)}
					>
						{cartDropUp &&
							path === '/marketplace/test-api-restaurant' && (
								<div
									ref={impactRef}
									className='max-h-[400px] overflow-y-auto absolute w-[90%] bottom-16 right-0 shadow-xl rounded-xl bg-white '
									onMouseLeave={() => setCartDropUp(false)}
								>
									<CartItem
										cartItems={cartItems}
										setCartItemsNodeSlug={
											setCartItemsNodeSlug
										}
									/>
									<p className='text-sm pl-4 pb-2'>{`Subtotal: ${new Intl.NumberFormat(
										'en-US',
										{
											style: 'currency',
											currency: 'USD',
										}
									).format(cartTotal)}`}</p>
								</div>
							)}
						<FaShoppingCart className='text-4xl text-green-600 hover:text-green-400' />{' '}
						<p className='text-xs'>Cart</p>
						{cartItems && (
							<div className='text-green-600 font-semibold ml-1 absolute transfrom translate-x-6'>
								{cartItems.length}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}
const mapStateToProps = ({ User, Cart, Path }) => ({
	cartNodeSlug: Cart.nodeSlug,
	user: User.user,
	cartItems: Cart.cartItems,
	cartTotal: Cart.cartTotal,
	path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({
	onGetCart: (cart) => dispatch(getCart(cart)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileBottomNav)
