import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import { Footer } from '../../components'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import {connectToClient, getSquareAuthLink} from '../../store/auth/oauth/actions'
import {getUser} from "../../store/auth/user/actions";
import {getNodeByAdminCodeOrSlug} from "../../store/nodes/actions";
import {Button} from "../../components/Elements";
import {useNavigate} from "react-router";

const OAuthFlow = (props) => {
	const {
		connectionError,
		connecting,
		connected,
		errorMessage,
		onConnectToClient,
		loadingSquareAuthLink,
		squareAuthLink,
		squareAuthLinkError,
		onGetSquareLink,
		onGetUser,
		user,
		loadingUser,
		loggedIn,
		onGetNodeBySlugAdmin,
		loadingNode,
		node,
	} = props
	const bounceLoaderColor = '#507f74'
	const { client } = useParams()
	const urlParams = new URLSearchParams(location.search)
	const oAuthCode = urlParams.get('code')
	const error = urlParams.get('error')
	const errorDescription = urlParams.get('error_description')

	const navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [])

	useEffect(() => {
		if (oAuthCode !== '' && client) {
			if (client === 'square') {
				if(errorDescription) {
					onConnectToClient({
						client: client,
						error: error,
						errorDescription: errorDescription,
					})
				} else {
					const oAuthState = urlParams.get('state')
					onConnectToClient({
						client: client,
						oAuthCode: oAuthCode,
						oAuthState: oAuthState,
					})
				}
			} else {
				onConnectToClient({
					client: client,
					oAuthCode: oAuthCode,
				})
			}
		}
	}, [oAuthCode, client])

	useEffect(() => {
		if(errorMessage && user && user.restaurantAdmin){
			if(errorDescription ==="user_denied"){
				onGetSquareLink(user.restaurantAdmin)
				onGetNodeBySlugAdmin(user.restaurantAdmin)
			}
		}
	}, [errorMessage, user])

	console.log(errorMessage?.error)

	return (
		<div>
			<Header />
			{connecting && (
				<div className='h-screen-3/4'>
					<h1 className='font-vollkorn text-green-600 text-4xl mt-14 text-center'>
						Connecting to {client.toUpperCase()}
					</h1>
					<div className='flex items-center h-screen-no-header'>
						<BounceLoader
							className='m-auto w-full'
							color={bounceLoaderColor}
						></BounceLoader>
					</div>
				</div>
			)}
			{connected && (
				<div className='h-screen-3/4'>
					<h1 className='font-vollkorn text-green-600 text-4xl mt-14 text-center'>
						Successful connection!
					</h1>
					<div className='mt-6 text-center mx-auto px-4 max-w-[800px]'>
						You've successfully connected to {client.toUpperCase()}.
						You can now publish your menu to and receive orders from
						DeliverZero.
					</div>
					<div className='my-6 text-center mx-auto px-4 max-w-[800px]'>
						<span className='font-bold text-lg'>
							BUT WAIT!{` `}
						</span>
						A few steps are left before customers can find you on
						the DeliverZero site, such as we need to bring you
						reusable containers! We'll contact you by phone, or if
						you're extra eager, you can contact us at
						restaurants@deliverzero.com to get boxes as soon as
						possible.
					</div>
					<div className='my-6 text-center mx-auto px-4 max-w-[800px]'>
						Welcome to the DeliverZero family!
					</div>
				</div>
			)}
			{connectionError && (
				<div className='h-screen-3/4'>
					<h1 className='font-vollkorn text-green-600 text-4xl mt-14 text-center'>
						An Error Occurred!
					</h1>
					<div className='my-6 mx-auto text-center px-4 max-w-[800px]'>
						Something went wrong trying to connect to{' '}
						{client.toUpperCase()}. Contact DeliverZero for
						assistance.
					</div>
					<div className='my-6 mx-auto font-bold text-red-400 text-center px-4 max-w-[800px]'>
						Error Message: {errorMessage?.error}
					</div>
					{
						client === 'square' && errorDescription ==="user_denied" && (
							<div>
								{squareAuthLink ? (
									<div className='max-w-[800px] mx-auto px-4 flex justify-center'>
										<Button
											text={'Authorize DeliverZero to Square'}
											size='sm'
											className='lg:w-[350px] w-[250px]'
											href={squareAuthLink}
										></Button>
									</div>
								) : (
									<div className='max-w-[800px] mx-auto px-4 flex justify-center'>
										<Button
											text={'Go Back to Authorize DeliverZero to Square'}
											size='sm'
											className='lg:w-[350px] w-[250px]'
											onClick={()=>{
												navigate("/square-landing")
											}}
										></Button>
									</div>
								)}
							</div>
						)
					}
				</div>
			)}
			<Footer/>
		</div>
	)
}
const mapStateToProps = ({OAuth, User, Nodes}) => ({
	connectionError: OAuth.connectionError,
	connecting: OAuth.connecting,
	connected: OAuth.connected,
	errorMessage: OAuth.errorMessage,
	loadingSquareAuthLink: OAuth.loadingSquareAuthLink,
	squareAuthLink: OAuth.squareAuthLink,
	squareAuthLinkError: OAuth.squareAuthLinkError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
})

const mapDispatchToProps = (dispatch) => ({
	onConnectToClient: (payload) => dispatch(connectToClient(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetSquareLink: (payload) => dispatch(getSquareAuthLink(payload)),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OAuthFlow)
