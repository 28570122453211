import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getAppInfo, getOrders } from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { loadStripe } from '@stripe/stripe-js'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Elements'
// import { Header } from '../../components'

const AdminHome = (state) => {
	const {
		onGetOrders,
		loadingOrders,
		orders,
		ordersCount,
		user,
		loadingUser,
		onGetUser,
		onGetAppInfo,
		appInfo,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

	useEffect(() => {
		onGetAppInfo()
	}, [])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<h1 className='font-header text-green-600 text-4xl mb-11 mt-14 text-center'>
					Admin Panel
				</h1>
				{!user.franchiseeSlug && (
					<div className='flex flex-col justify-center md:columns-2'>
						<Button
							className='mt-4 mb-2 mx-auto'
							link='/admin/order-upload'
							text='Import Orders from Client'
						/>
						<Button
							className='my-2 mx-auto'
							link='/admin/menu-upload'
							text='Bulk Import Menus from Client'
						/>
					</div>
				)}
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-1 mb-8 flex justify-center mx-auto w-full lg:max-w-[1200px]'>
					<div
						onClick={() => {
							navigate('/admin/restaurants')
						}}
						className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							Restaurant Dashboard
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							View restaurant inventories, payments, info, and
							more
						</p>
					</div>
					{user.adminPermissions.includes('customer') && (
						<div
							onClick={() => {
								navigate('/admin/customers')
							}}
							className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								Customer Dashboard
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								View customer box counts, order history, return
								history and more
							</p>
						</div>
					)}
					{user.adminPermissions.includes('customer') && (
						<div
							onClick={() => {
								navigate('/admin/returns')
							}}
							className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								Returns Dashboard
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								View returns, create returns, info, and more
							</p>
						</div>
					)}
					{!user.franchiseeSlug && (
						<div
							onClick={() => {
								navigate('/admin/orders')
							}}
							className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								Marketplace Order Dashboard
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								View all orders, specific order statuses, and
								more
							</p>
						</div>
					)}
					<div
						onClick={() => {
							navigate('/admin/analytics')
						}}
						className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							Analytics Dashboard
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							View data about order growth, customer growth, and
							more
						</p>
					</div>
					{!user.franchiseeSlug && (
						<>
							{/* <div
								onClick={() => {
									navigate('/admin/logistics')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Logistics
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									Route creator, packing lists, inventory, and more
								</p>
							</div> */}
							<div
								onClick={() => {
									navigate('/admin/logistics')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Logistics
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									Route creator, packing lists, inventory, and
									more
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/revenue')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Revenue Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View data about monthly revenue streams
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/raas')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									RAAS Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View RAAS merchants, total packaging
									prevented, and more
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/leads')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Leads Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View new leads and sign up flow status
								</p>
							</div>
						</>
					)}
					{!user.franchiseeSlug && (
						<>
							<div
								onClick={() => {
									navigate('/admin/promos')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Promo Codes
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									Create promo codes
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/wholefoods')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Whole Foods Market Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View WFM inventory statuses and data
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/reusables-shop')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Reusables Shop Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View reusables shop orders
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/office')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Office Charges Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View office ordering charges
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/subscribers')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Subscribers Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View membership program subscribers
								</p>
							</div>
						</>
					)}
					{user.franchiseeSlug && (
						<>
							<div
								onClick={() => {
									navigate('/admin/billing')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Billing Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View charges and billing data
								</p>
							</div>
						</>
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, AppInfo, Orders }) => ({
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
})

const mapDispatchToProps = (dispatch) => ({
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome)
