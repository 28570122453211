import { call, put, takeEvery } from 'redux-saga/effects'
import { getHIW } from '../../model/howItWorks'
import { getHIWSuccess, getHIWFail } from './actions'
import { GET_HIW } from './actionTypes'

function* fetchHIW(action) {
	try {
		const howItWorks = yield call(getHIW, action.payload)
		yield put(getHIWSuccess(howItWorks))
	} catch (error) {
		yield put(getHIWFail(error))
	}
}

function* howItWorksSaga() {
	yield takeEvery(GET_HIW, fetchHIW)
}

export default howItWorksSaga
