import React, { useEffect, useState } from 'react'
import { getUser } from '../../store/actions'
import { connect } from 'react-redux'
import {
	Button,
	CheckboxGroup,
	SelectGroup,
	Input,
	RadioGroup,
	BounceLoaderCentered,
	FormGroup,
	CheckboxSelections,
} from '../Elements'

const OfficeAdminForm = (props) => {
	const { addOfficeAdmin, setShowInviteAdmin } = props

	const [name, setName] = useState(null)
	const [email, setEmail] = useState(null)
	const [phone, setPhone] = useState('')
	const [formError, setFormError] = useState(false)
	const [isValid, setIsValid] = useState(false)

	const handleSubmit = (e) => {
		e.preventDefault()
		setIsValid(false)
		if (isValid && name.length > 1) {
			addOfficeAdmin({ name, email, phone })
			setName('')
			setEmail('')
			setPhone('')
			setShowInviteAdmin(false)
		} else {
			setFormError('Please fill out all fields')
		}
	}

	const validateEmail = (email) => {
		const emailPattern = /\S+@\S+\.\S+/
		if (!emailPattern.test(email)) {
			setFormError('Please enter a valid email address')
			setIsValid(false)
		} else {
			setIsValid(true)
		}
	}

	const validatePhone = (phone) => {
		const cleanedPhone = phone.replace(/[()-]/g, '')
		if (cleanedPhone.length !== 10 || isNaN(cleanedPhone)) {
			setFormError('Please enter a valid phone number (10 digits)')
			setIsValid(false)
		} else {
			setIsValid(true)
		}
	}

	return (
		<FormGroup
			onSubmit={handleSubmit}
			className='w-fit flex flex-col justify-center items-center border-2 border-green-600 p-6 shadow-xl rounded-xl bg-white mb-3'
		>
			<Input
				type='text'
				className='my-1 w-[300px] mx-auto'
				placeholder='Name'
				value={name}
				onChange={(e) => {
					setName(e.target.value)
					setFormError('')
				}}
			/>
			<Input
				type='email'
				className='my-1 w-[300px] mx-auto'
				placeholder='Email'
				value={email}
				onChange={(e) => {
					setEmail(e.target.value)
					setFormError('')
					setIsValid(false)
					validateEmail(e.target.value)
				}}
			/>
			<Input
				type='phone'
				className='my-1 w-[300px] mx-auto'
				placeholder='Phone'
				value={phone}
				onChange={(e) => {
					setPhone(e.target.value)
					setFormError('')
					setIsValid(false)
					validatePhone(e.target.value)
				}}
			/>
			<div className='my-1 w-[300px] mx-auto italic my-1'>
				New users will be recieve an email invitation to create an
				account
			</div>
			{formError && <div className='text-red-400'>{formError}</div>}
			{isValid ? (
				<Button
					className='w-[300px] mx-auto my-1'
					size='sm'
					text='Save'
					onClick={handleSubmit}
				/>
			) : (
				<Button
					className='w-[300px] mx-auto my-1'
					size='sm'
					text='Save'
					color='disabled'
				/>
			)}
		</FormGroup>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeAdminForm)
