import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'
import { realm } from '../../helpers/realm'
import { updatePath } from '../../store/path/actions'
import { connect } from 'react-redux'

const PrivateRoute = (props) => {
	const { onUpdatePath } = props
	const location = useLocation()
	// console.log(location)
	useEffect(() => {
		// console.log(props.path)
		const nextPath = location.pathname
		if (
			nextPath !== '/login' &&
			nextPath !== '/sign-up' &&
			nextPath !== '/sign-up-b' &&
			nextPath !== '/office-ordering/login' &&
			nextPath !== '/office-ordering/sign-up'
		) {
			onUpdatePath(nextPath)
		}
	}, [onUpdatePath])

	const currentUser = realm.currentUser
	const auth = currentUser && currentUser.providerType !== 'anon-user'
	return auth ? (
		props.component
	) : (
		<Navigate
			to='/login'
			replace
			state={{ from: props.path }}
		/>
	)
}

const mapStateToProps = ({ Path }) => ({
	path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({
	onUpdatePath: (path) => dispatch(updatePath(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
