import React from 'react'
import { Link } from 'react-router-dom'

function UserProfileOrders({ orders = [] }) {
	if (!orders.length) return <div className='text-center'>No orders yet</div>
	return (
		<div className=''>
			{orders.map((order, i) => {
				const dateString2 = order.timestamp.toLocaleString('default', {
					month: 'short',
					day: 'numeric',
					year: 'numeric',
				})
				return (
					<div
						key={i}
						className='flex mb-5 h-fit px-2 py-2.5 w-full border-gray-100 border-2 rounded-[10px]'
					>
						<div className='flex flex-row w-full'>
							<div className='flex flex-col w-[250px] lg:w-3/4 '>
								<div className='h-2/3 items-center'>
									<span className='text-green-600 text-3xl font-header'>
										${order.dzTotal.toFixed(2)}
									</span>
								</div>
								<div className='flex flex-col'>
									<div className='font-semibold text-[13px] lg:text-md text-wrap'>
										{order.nodeInfo.name}{' '}
									</div>
									<div className='text-[13px] lg:text-md'>
										{dateString2}
									</div>
								</div>
							</div>
							<Link
								className='flex items-center my-3 ml-2 text-xs underline text-green-600 font-semibold'
								to={`/order/${order._id}`}
							>
								View Order
							</Link>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default UserProfileOrders
