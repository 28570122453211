export const CREATE_STOP = 'CREATE_STOP'
export const CREATE_STOP_SUCCESS = 'CREATE_STOP_SUCCESS'
export const CREATE_STOP_ERROR = 'CREATE_STOP_ERROR'

export const UPDATE_STOP = 'UPDATE_STOP'
export const UPDATE_STOP_SUCCESS = 'UPDATE_STOP_SUCCESS'
export const UPDATE_STOP_ERROR = 'UPDATE_STOP_ERROR'

export const DELETE_STOP = 'DELETE_STOP'
export const DELETE_STOP_SUCCESS = 'DELETE_STOP_SUCCESS'
export const DELETE_STOP_ERROR = 'DELETE_STOP_ERROR'

export const GET_ALL_LOGISTICS = 'GET_ALL_LOGISTICS'
export const GET_ALL_LOGISTICS_SUCCESS = 'GET_ALL_LOGISTICS_SUCCESS'
export const GET_ALL_LOGISTICS_ERROR = 'GET_ALL_LOGISTICS_ERROR'

export const RESET_SUCCESS = 'RESET_SUCCESS'

export const LOGISTICS_ALERT = 'LOGISTICS_ALERT'
export const LOGISTICS_ALERT_SUCCESS = 'LOGISTICS_ALERT_SUCCESS'
export const LOGISTICS_ALERT_ERROR = 'LOGISTICS_ALERT_SUCCESS'

export const GET_INCOMPLETE_STOPS = 'GET_INCOMPLETE_STOPS'
export const GET_INCOMPLETE_STOPS_SUCCESS = 'GET_INCOMPLETE_STOPS_SUCCESS'
export const GET_INCOMPLETE_STOPS_ERROR = 'GET_INCOMPLETE_STOPS_ERROR'

export const GET_NEW_RESTAURANT_STOPS = 'GET_NEW_RESTAURANT_STOPS'
export const GET_NEW_RESTAURANT_STOPS_SUCCESS =
	'GET_NEW_RESTAURANT_STOPS_SUCCESS'
export const GET_NEW_RESTAURANT_STOPS_ERROR = 'GET_NEW_RESTAURANT_STOPS_ERROR'

export const GET_INVENTORY = 'GET_INVENTORY'
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS'
export const GET_INVENTORY_ERROR = 'GET_INVENTORY_ERROR'

export const GET_LOGISTICS_DASHBOARD = 'GET_LOGISTICS_DASHBOARD'
export const GET_LOGISTICS_DASHBOARD_SUCCESS = 'GET_LOGISTICS_DASHBOARD_SUCCESS'
export const GET_LOGISTICS_DASHBOARD_ERROR = 'GET_LOGISTICS_DASHBOARD_ERROR'
export const GET_RETURN_BIN_BOX_COUNT = 'GET_RETURN_BIN_BOX_COUNT'
export const GET_RETURN_BIN_BOX_COUNT_SUCCESS =
	'GET_RETURN_BIN_BOX_COUNT_SUCCESS'
export const GET_RETURN_BIN_BOX_COUNT_ERROR = 'GET_RETURN_BIN_BOX_COUNT_ERROR'

export const GET_UBER_DIRECT_QUOTE = 'GET_UBER_DIRECT_QUOTE'
export const GET_UBER_DIRECT_QUOTE_SUCCESS = 'GET_UBER_DIRECT_QUOTE_SUCCESS'
export const GET_UBER_DIRECT_QUOTE_ERROR = 'GET_UBER_DIRECT_QUOTE_ERROR'

export const CREATE_UBER_DIRECT_REQUEST = 'CREATE_UBER_DIRECT_REQUEST'
export const CREATE_UBER_DIRECT_REQUEST_SUCCESS =
	'CREATE_UBER_DIRECT_REQUEST_SUCCESS'
export const CREATE_UBER_DIRECT_REQUEST_ERROR =
	'CREATE_UBER_DIRECT_REQUEST_ERROR'

export const UPDATE_UBER_DIRECT = 'UPDATE_UBER_DIRECT'
export const UPDATE_UBER_DIRECT_SUCCESS = 'UPDATE_UBER_DIRECT_SUCCESS'
export const UPDATE_UBER_DIRECT_ERROR = 'UPDATE_UBER_DIRECT_ERROR'

export const GET_ALL_UBER_DIRECT = 'GET_ALL_UBER_DIRECT'
export const GET_ALL_UBER_DIRECT_SUCCESS = 'GET_ALL_UBER_DIRECT_SUCCESS'
export const GET_ALL_UBER_DIRECT_ERROR = 'GET_ALL_UBER_DIRECT_ERROR'

export const CANCEL_UBER_DIRECT = 'CANCEL_UBER_DIRECT'
export const CANCEL_UBER_DIRECT_SUCCESS = 'CANCEL_UBER_DIRECT_SUCCESS'
export const CANCEL_UBER_DIRECT_ERROR = 'CANCEL_UBER_DIRECT_ERROR'

export const GET_UBER_DIRECT_REQUEST_BY_ID = 'GET_UBER_DIRECT_REQUEST_BY_ID'
export const GET_UBER_DIRECT_REQUEST_BY_ID_SUCCESS =
	'GET_UBER_DIRECT_REQUEST_BY_ID_SUCCESS'
export const GET_UBER_DIRECT_REQUEST_BY_ID_ERROR =
	'GET_UBER_DIRECT_REQUEST_BY_ID_ERROR'

export const GET_UBER_DIRECT_QUOTE_DROPOFF = 'GET_UBER_DIRECT_QUOTE_DROPOFF'
export const GET_UBER_DIRECT_QUOTE_DROPOFF_SUCCESS =
	'GET_UBER_DIRECT_QUOTE_DROPOFF_SUCCESS'
export const GET_UBER_DIRECT_QUOTE_DROPOFF_ERROR =
	'GET_UBER_DIRECT_QUOTE_DROPOFF_ERROR'

export const GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE =
	'GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE'
export const GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_SUCCESS =
	'GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_SUCCESS'
export const GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_ERROR =
	'GET_UBER_DIRECT_QUOTE__DROPOFF_NO_NODE_ERROR'

export const GET_UBER_DIRECT_REQUEST_DOC_BY_ID =
	'GET_UBER_DIRECT_REQUEST_DOC_BY_ID'
export const GET_UBER_DIRECT_REQUEST_DOC_BY_ID_SUCCESS =
	'GET_UBER_DIRECT_REQUEST_DOC_BY_ID_SUCCESS'
export const GET_UBER_DIRECT_REQUEST_DOC_BY_ID_ERROR =
	'GET_UBER_DIRECT_REQUEST_DOC_BY_ID_ERROR'

export const GET_MARKET = 'GET_MARKET'
export const GET_MARKET_ERROR = 'GET_MARKET_ERROR'
export const GET_MARKET_SUCCESS = 'GET_MARKET_SUCCESS'
export const GET_PROOF_OF_DELIVERY = 'GET_PROOF_OF_DELIVERY'
export const GET_PROOF_OF_DELIVERY_ERROR = 'GET_PROOF_OF_DELIVERY_ERROR'
export const GET_PROOF_OF_DELIVERY_SUCCESS = 'GET_PROOF_OF_DELIVERY_SUCCESS'

export const ADJUST_INVENTORY = 'ADJUST_INVENTORY'
export const ADJUST_INVENTORY_ERROR = 'ADJUST_INVENTORY_ERROR'
export const ADJUST_INVENTORY_SUCCESS = 'ADJUST_INVENTORY_SUCCESS'

export const GET_MARKET_OBJ = 'GET_MARKET_OBJ'
export const GET_MARKET_OBJ_ERROR = 'GET_MARKET_OBJ_ERROR'
export const GET_MARKET_OBJ_SUCCESS = 'GET_MARKET_OBJ_SUCCESS'

export const CREATE_PACKING_LIST_TRANSACTIONS =
	'CREATE_PACKING_LIST_TRANSACTIONS'
export const CREATE_PACKING_LIST_TRANSACTIONS_ERROR =
	'CREATE_PACKING_LIST_TRANSACTIONS_ERROR'
export const CREATE_PACKING_LIST_TRANSACTIONS_SUCCESS =
	'CREATE_PACKING_LIST_TRANSACTIONS_SUCCESS'
