import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAppInfo,
	getNodeByAdminCodeOrSlug,
	restaurantAdminSubmitRequest,
	getContainers,
	getMarket,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { BounceLoaderCentered, Button, Input } from '../../components/Elements'

const RestaurantAdminHome = (state) => {
	const {
		user,
		onGetUser,
		onGetNodeBySlugAdmin,
		node,
		onUpdateUser,
		updateUserLoading,
		userUpdateCompleted,
		userError,
		loadingNode,
		onSubmitRestaurantRequest,
		submittingRestaurantAdminRequest,
		submitRestaurantAdminRequestError,
		submittedRestaurantAdminRequest,
		onGetAppInfo,
		appInfo,
		containers,
		onGetContainers,
		containerMarket,
		onGetMarket,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [loadingAddNode, setLoadingAddNode] = useState(false)
	const [showAddLocation, setShowAddLocation] = useState(false)
	const [adminPinCode, setAdminPinCode] = useState('')
	const [containerRequest, setContainerRequest] = useState({})
	const [availableContainers, setAvailableContainers] = useState([])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	useEffect(() => {
		if (node) {
			onGetAppInfo()
			onGetMarket({ coords: [node.coordinates[1], node.coordinates[0]] })
		}
	}, [node])

	useEffect(() => {
		if (node) {
			if (node.pizza) {
				onGetContainers([
					{
						enabled: true,
					},
				])
			} else {
				onGetContainers([
					{
						enabled: true,
					},
					{
						hide: { $ne: true },
					},
				])
			}
		}
	}, [node, onGetContainers])

	useEffect(() => {
		if (containers && node && containerMarket) {
			const currentContainers = []
			for (const container of containers) {
				if (node.franchiseeSlug) {
					if (
						container.franchiseeSlug === node.franchiseeSlug &&
						container.markets.includes(containerMarket)
					) {
						currentContainers.push(container)
					}
				} else if (container.markets.includes(containerMarket)) {
					currentContainers.push(container)
				}
			}
			setAvailableContainers(currentContainers)
		} else {
			if (node) {
				if (node.pizza) {
					onGetContainers([
						{
							enabled: true,
						},
					])
				} else {
					onGetContainers([
						{
							enabled: true,
						},
						{
							hide: { $ne: true },
						},
					])
				}
			}
		}
	}, [containerMarket])

	const submitRequest = () => {
		onSubmitRestaurantRequest({
			type: 'more-containers',
			containers: containerRequest,
			nodeSlug: user.restaurantAdmin,
		})
	}

	useEffect(() => {
		if (containers && containers.length > 0) {
			let object = {}
			for (const container of containers) {
				object[container.containerId] = 0
			}
			setContainerRequest(object)
		}
	}, [containers])

	// useEffect(() => {
	//     console.log("availableContainers", availableContainers)
	// }, [availableContainers])

	const addContainers = (containerId) => {
		let object = containerRequest
		setContainerRequest((prevState) => ({
			...prevState,
			[containerId]: object[containerId] + 50,
		}))
	}

	const subtractContainers = (container) => {
		let object = containerRequest
		if (object[container] > 0) {
			setContainerRequest((prevState) => ({
				...prevState,
				[container]: object[container] - 50,
			}))
		}
	}

	const openSupport = () => {
		window.Intercom('show')
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		if (node && node.slug === user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/restaurant-admin/home')
						}}
					>
						&#8592; Go to Dashboard Home
					</div>
					{node ? (
						<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
							Request Containers for {node.name}
						</h1>
					) : (
						<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
							Request Containers
						</h1>
					)}
					<div className='max-w-[600px] mx-auto mb-4 text-center'>
						Enter in how many containers you need. Please only order
						containers that you currently use. If you would like a
						new container type, please contact
						restaurants@deliverzero.com or click on the Support
						button in the My Account icon.
					</div>
					<div className='flex-col flex items-center h-full mb-10'>
						{containers && containers.length > 0 ? (
							availableContainers &&
							availableContainers.length > 0 ? (
								<div>
									{availableContainers.map((container) => (
										<div className='flex flex-row mb-4 items-center'>
											<div className='mr-4'>
												<img
													src={`/images/containers/${container.containerId}.png`}
													alt={container.containerId}
													className='w-[100px] h-[100px] object-scale-down'
												/>
											</div>
											<div className='text-lg font-bold w-[250px] mr-2'>
												{/* {container.description} ({container.markets.join(", ")}): */}
												{container.description}:
											</div>
											<div className='flex flex-row bg-gray-100 rounded-[5px] border-2 items-center'>
												<span
													color='default'
													className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
													onClick={() => {
														subtractContainers(
															container.containerId
														)
													}}
												>
													-
												</span>
												<div className='bg-white text-2xl mx-1 border-2 text-center border-gray-300 w-[100px]'>
													{
														containerRequest[
															container
																.containerId
														]
													}
												</div>
												{/*<Input*/}
												{/*    type='text'*/}
												{/*    label=''*/}
												{/*    name='item-search'*/}
												{/*    value={containerRequest[container]}*/}
												{/*    className='bg-white text-2xl mx-1 border-2 text-center border-gray-300 w-[100px]'*/}
												{/*    onChange={(e) => {*/}
												{/*        if(isNaN(parseInt(e.target.value))){*/}
												{/*            setContainerRequest(prevState => ({*/}
												{/*                ...prevState,*/}
												{/*                [container]:0*/}
												{/*            }))*/}
												{/*        } else {*/}
												{/*            setContainerRequest(prevState => ({*/}
												{/*                ...prevState,*/}
												{/*                [container]: parseInt(e.target.value)*/}
												{/*            }))*/}
												{/*        }*/}
												{/*    }}*/}
												{/*/>*/}
												<span
													color='default'
													className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
													onClick={() => {
														addContainers(
															container.containerId
														)
													}}
												>
													+
												</span>
											</div>
										</div>
									))}
								</div>
							) : (
								<div className='flex flex-col text-2xl font-bold items-center justify-center m-10'>
									Oops, it looks like there are currently no
									available containers in your area:
									<Button
										className='p-2 w-min mb-[30%] mt-4'
										onClick={openSupport}
										text='Contact Support'
									></Button>
								</div>
							)
						) : (
							<BounceLoaderCentered container='div' />
						)}
						{submittingRestaurantAdminRequest ? (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							/>
						) : (
							<Button
								text='Submit'
								size='sm'
								className='px-8 py-2 mt-6'
								onClick={() => {
									submitRequest()
								}}
							/>
						)}
						{submittedRestaurantAdminRequest && (
							<div className='font-bold text-green-600 w-[300px]'>
								Success!{' '}
								{node.franchiseeSlug
									? 'We have'
									: `DeliverZero has`}{' '}
								received your request and will dispatch
								containers as soon as possible.
							</div>
						)}
						{submitRestaurantAdminRequestError && (
							<div className='font-bold text-red-400'>
								An error occurred, please contact DeliverZero.
							</div>
						)}
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, AppInfo, Nodes, Containers, Logistics }) => ({
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	userError: User.error,
	submittingRestaurantAdminRequest: Nodes.submittingRestaurantAdminRequest,
	submitRestaurantAdminRequestError: Nodes.submitRestaurantAdminRequestError,
	submittedRestaurantAdminRequest: Nodes.submittedRestaurantAdminRequest,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	containerMarket: Logistics.market,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onSubmitRestaurantRequest: (request) =>
		dispatch(restaurantAdminSubmitRequest(request)),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onGetMarket: (payload) => dispatch(getMarket(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantAdminHome)
