import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import { Button, Badge } from '../Elements'
import { cancelUberDirect, resetSuccess } from '../../store/actions'

function UserProfileTransactions({
	transactions = [],
	returnWindow = 0,
	disputeWindow = 0,
	onCancelFirstDelivery,
	cancelingFirstDelivery,
	onCancelUberDirect,
	cancelledRequest,
	onResetSuccess,
}) {
	const bounceLoaderColor = '#507f74'
	// const [showScheduledBadge, setShowScheduledBadge] = useState(false)

	const firstDeliveryStatuses = {
		created: 'pending',
		scheduled: 'scheduled',
		assigned_courier: 'scheduled',
		arrived_at_pickup: 'courier dispatched',
		picked_up: 'courier dispatched',
		in_route_to_dropoff: 'courier enroute',
		arrived_at_dropoff: 'courier arriving',
		delivered: 'completed',
		cancelled: 'cancelled',
		return_started: 'error',
		return_arrived_at_dropoff: 'error',
		returned: 'error',
		failed: 'error',
		attempted: 'error',
	}

	const [uberdirectCancellable, setUberdirectCancellable] = useState(false)
	const [uberdirectId, setUberdirectId] = useState('')

	useEffect(() => {
		const pendingUberDirect = transactions.find(
			(transaction) =>
				transaction.reverseLogisticsPartner &&
				transaction.reverseLogisticsPartner === 'uberdirect' &&
				transaction.status &&
				(transaction.status === 'pending' ||
					transaction.status === 'pickup')
		)

		if (pendingUberDirect) {
			setUberdirectCancellable(true)
			setUberdirectId(pendingUberDirect.uberdirectId)
		}
	}, [transactions, cancelledRequest])

	useEffect(() => {
		onResetSuccess()
	}, [])

	return (
		<div className='flex flex-col items-center'>
			{cancelledRequest && (
				<div className='text-xs mb-2'>
					Your pickup has been cancelled. A refund will be issued
					shortly.
				</div>
			)}
			{transactions.map((transaction, i) => {
				// console.log('transactions')
				let showScheduledBadge = false
				const currentDate = new Date()
				const disputeCutOffDate = new Date(transaction.timestamp)
				disputeCutOffDate.setDate(
					disputeCutOffDate.getDate() + disputeWindow / 24
				)
				// const returnDate = new Date(transaction.timestamp)
				// if (
				// 	transaction.type === 'boxesIn' &&
				// 	(currentDate.getTime() < returnDate.getTime() ||
				// 		(transaction.uberdirectId &&
				// 			transaction?.status !== 'canceled'))
				// ) {
				// 	// console.log(currentDate.getTime(), returnDate.getTime())
				// 	// console.log('scheduled')
				// 	showScheduledBadge = true
				// }
				// if (transaction.type === 'boxesOut') {
				// 	returnDate.setDate(returnDate.getDate() + returnWindow / 24)
				// }
				const dateString2 = disputeCutOffDate.toLocaleString(
					'default',
					{
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					}
				)
				if (
					transaction.type === 'adminBalanceUpdate' ||
					transaction.type === 'giftCard'
				) {
				} else {
					return (
						<div
							key={i}
							className='flex flex-col mb-5 h-fit px-2 py-2.5 w-full border-gray-100 border-2 rounded-[10px]'
						>
							<div className='flex flex-row w-full justify-start p-4 pt-2'>
								<div className='flex flex-col w-[300px] lg:w-5/6'>
									<div className='h-fit items-start text-sm flex flex-col-reverse justify-between w-full'>
										{transaction.type == 'boxesIn' && (
											<>
												<div className='flex flex-wrap text-[13px] lg:text-md'>
													{dateString2}
												</div>
												<div className='flex flex-wrap w-full justify-start items-center text-center text-[13px] lg:text-md'>
													<span className='text-green-600 text-3xl font-header mr-2 flex flex-wrap'>
														{transaction.boxCount}{' '}
													</span>
													{`${
														transaction.boxCount > 1
															? `containers`
															: `container`
													} ${
														showScheduledBadge ||
														transaction?.status ===
															'canceled'
															? `to return`
															: transaction.latePayment
															? `paid for`
															: `returned`
													}`}
													{'\n'}
												</div>
											</>
										)}
										{transaction.type == 'boxesOut' && (
											<div className='text-[13px] lg:text-md'>
												<span className='text-orange-600 text-3xl font-header'>
													{transaction.boxCount}{' '}
												</span>
												{`${
													transaction.boxCount > 1
														? `containers`
														: `container`
												}`}
											</div>
										)}
									</div>
									<div className='flex flex-row w-5/6 justify-start mb-1 text-[13px] lg:text-md'>
										{!transaction.returnAtDoor &&
											transaction.type !== 'boxesOut' &&
											transaction.nodeInfo && (
												<>
													<div className=''>
														<div className='font-semibold flex flex-wrap text-[13px] lg:text-md'>
															{`To ${transaction.nodeInfo.name}`}
														</div>
														<div className='flex flex-wrap text-[13px] lg:text-md'>
															{dateString2}
														</div>
													</div>
												</>
											)}
										{transaction.returnAtDoor &&
											showScheduledBadge &&
											transaction?.status !==
												'canceled' && (
												<>
													<div className='font-semibold flex flex-wrap'>
														Returning at door
													</div>
													<div className='flex flex-wrap text-[13px] lg:text-md'>
														{dateString2}
													</div>
												</>
											)}
										{transaction.type == 'boxesOut' && (
											<div className=''>
												<div className='font-semibold flex flex-wrap text-[13px] lg:text-md'>{`From ${transaction.nodeInfo.name}`}</div>
												<div className='flex flex-wrap text-[13px] lg:text-md'>
													{dateString2}
												</div>
											</div>
										)}
										{/* {transaction.type == 'boxesIn' &&
										!showScheduledBadge && (
											<div className='font-semibold text-[13px] lg:text-[16px] whitespace-nowrap'>
												{` `}on {dateString2}
											</div>
										)} */}
									</div>
									<div className='flex flex-col'>
										<div>
											{showScheduledBadge && (
												<>
													{transaction.status ===
														'pending' &&
														!cancelledRequest && (
															<Button
																className='px-3 py-2 my-2 w-[175px]'
																text='Cancel on demand pickup'
																size='xs'
																onClick={() => {
																	onCancelUberDirect(
																		transaction.uberdirectId
																	)
																}}
															/>
														)}
													<Badge
														text={`Scheduled for ${dateString2}`}
														color='orange-primary'
														className='cursor-pointer text-center flex flex-wrap w-fit px-3'
													/>
												</>
											)}
										</div>
										<div>
											{transaction.status &&
												transaction.status ===
													'canceled' && (
													<Badge
														text={`Cancelled`}
														color='red-primary'
														className='cursor-pointer text-center flex flex-wrap w-fit px-3'
													/>
												)}
										</div>
									</div>
									{transaction.cancelAvailable && (
										<div>
											{cancelingFirstDelivery ? (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											) : (
												<div
													className='underline cursor-pointer text-xs'
													onClick={() => {
														onCancelFirstDelivery(
															transaction.deliveryId
														)
													}}
												>
													Cancel Pickup
												</div>
											)}
										</div>
									)}
									{transaction.pickupStatus && (
										<div>
											{firstDeliveryStatuses[
												transaction.pickupStatus
											].toUpperCase()}
										</div>
									)}
									{!(
										currentDate.getTime() >
										disputeCutOffDate.getTime()
									) &&
										transaction.type == 'boxesOut' && (
											<Link
												className='inline-block xl:hidden items-center text-xs underline text-green-600 font-semibold'
												to={`/dispute/${transaction._id}`}
											>
												Dispute Amount
											</Link>
										)}
								</div>
								{!(
									currentDate.getTime() >
									disputeCutOffDate.getTime()
								) &&
									transaction.type == 'boxesOut' && (
										<Link
											className='hidden xl:inline-block items-center my-3 text-xs underline text-green-600 font-semibold'
											to={`/dispute/${transaction._id}`}
										>
											Dispute Amount
										</Link>
									)}
							</div>
						</div>
					)
				}
			})}
		</div>
	)
}

const mapStateToProps = ({ Logistics }) => ({
	cancelledRequest: Logistics.cancelled,
})

const mapDispatchToProps = (dispatch) => ({
	onCancelUberDirect: (payload) => dispatch(cancelUberDirect(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserProfileTransactions)
