import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BounceLoader } from 'react-spinners'
import React, { useEffect, useState } from 'react'
import { Footer, Header } from '../../components'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../store/actions'
import { connect } from 'react-redux'
import OfficeAccountCreditCardForm from './OfficeAccountCreditCardForm'

const OfficeCreditCardPage = (props) => {
	const { user, loadingUser, onGetUser } = props
	const bounceLoaderColor = '#507f74'
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user) {
		if (user.officeAdmin) {
			return (
				<>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/office-ordering/account')
						}}
					>
						&#8592; Back to Account Info
					</div>
					{/* <div
                        className='underline font-bold my-4 cursor-pointer ml-6'
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        &#8592; Go Back
                    </div> */}
					<div className='flex flex-col items-center text-center h-screen-no-header w-screen px-4 py-12'>
						<div className='w-full flex flex-col justify-center items-center mb-12'>
							<Elements stripe={stripePromise}>
								<OfficeAccountCreditCardForm />
							</Elements>
						</div>
					</div>
					<Footer />
				</>
			)
		} else {
			navigate('/')
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OfficeCreditCardPage)
