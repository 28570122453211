import {
	RUN_PENTEST,
	RUN_PENTEST_ERROR,
	RUN_PENTEST_SUCCESS,
} from './actionTypes'

export const runPentest = (payload) => ({
	type: RUN_PENTEST,
	payload: payload,
})

export const runPentestSuccess = (payload) => ({
	type: RUN_PENTEST_SUCCESS,
	payload: payload,
})

export const runPentestError = (payload) => ({
	type: RUN_PENTEST_ERROR,
	payload: payload,
})
