import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { Button } from './Elements'

const videoConstraints = {
	width: 400,
	height: 480,
	facingMode: 'environment',
}

const WebcamCapture = ({ handleCameraSubmit }) => {
	const webcamRef = useRef(null)
	const [imgSrc, setImgSrc] = useState(null)
	const [photoClass, setPhotoClass] = useState('mb-3')

	const capture = () => {
		const imageSrc = webcamRef.current.getScreenshot()
		setImgSrc(imageSrc)
		handleCameraSubmit(imageSrc)
		setPhotoClass('mb-3 border border-success')
	}

	return (
		<>
			{!imgSrc && (
				<div className='mb-3'>
					<Webcam
						audio={false}
						screenshotFormat='image/jpeg'
						videoConstraints={videoConstraints}
						ref={webcamRef}
					/>
					<Button onClick={capture}>Capture photo</Button>
				</div>
			)}
			{imgSrc && (
				<div className='mb-3'>
					<img
						className='mb-3'
						src={imgSrc}
					/>
					<Button
						className='m-2'
						onClick={() => {
							handleCameraSubmit(imgSrc)
						}}
					>
						Accept
					</Button>
					<Button
						className='m-2 '
						onClick={() => setImgSrc(null)}
					>
						Reset
					</Button>
				</div>
			)}
		</>
	)
}

export default WebcamCapture
