import React from 'react'

function Badge({
	className = '',
	text = 'New',
	color = 'red-secondary',
	onClick = () => {},
	...newProps
}) {
	let finalClass = `${className} rounded-full py-1 px-2 text-xs font-medium`
	if (color === 'red-primary') finalClass += ' text-white bg-red-400'
	else if (color === 'red-secondary')
		finalClass +=
			' text-red-400 bg-white-600 border-2 border-red-400 hover:bg-red-400 hover:text-white'
	else if (color === 'green-primary') finalClass += ' text-white bg-green-400'
	else if (color === 'orange-primary')
		finalClass += ' text-white bg-orange-600'
	else if (color === 'green-secondary')
		finalClass +=
			' text-green-400 bg-white-600 border-2 border-green-400 hover:bg-green-400 hover:text-white'

	return (
		<span
			className={finalClass}
			{...newProps}
			onClick={(e) => onClick(e)}
			value={`${text}`}
		>
			{text}
		</span>
	)
}

export default Badge
