import {
	CREATE_PENDING_PICKUP,
	CREATE_PENDING_PICKUP_SUCCESS,
	CREATE_PENDING_PICKUP_ERROR,
	GET_PENDING_PICKUPS,
	GET_PENDING_PICKUPS_SUCCESS,
	GET_PENDING_PICKUPS_ERROR,
	GET_ALL_PENDING_PICKUPS,
	GET_ALL_PENDING_PICKUPS_SUCCESS,
	GET_ALL_PENDING_PICKUPS_ERROR,
	GET_PICKUPS_BY_PHONE,
	GET_PICKUPS_BY_PHONE_SUCCESS,
	GET_PICKUPS_BY_PHONE_ERROR,
	RESET_PICKUPS,
	GET_PICKUP_BY_ID,
	GET_PICKUP_BY_ID_ERROR,
	GET_PICKUP_BY_ID_SUCCESS,
	CREATE_GENTLY_PICKUP,
	CREATE_GENTLY_PICKUP_SUCCESS,
	CREATE_GENTLY_PICKUP_ERROR,
	CREATE_GENTLY_PICKUP_NO_NODE,
	CREATE_GENTLY_PICKUP_NO_NODE_SUCCESS,
	CREATE_GENTLY_PICKUP_NO_NODE_ERROR,
} from './actionTypes'

const initialState = {
	pendingPickups: [],
	pickup: null,
	pickupError: null,
	pickupLoading: false,
	pickupSuccess: false,
	creatingPickup: false,
	pickupRequested: false,
}

const pickups = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_PENDING_PICKUP:
			return {
				...state,
				pickupLoading: true,
				pickupError: null,
				pickup: null,
				pendingPickups: [],
				pickupSuccess: false,
			}

		case GET_PENDING_PICKUPS:
			return {
				...state,
				pickupLoading: true,
				pickupError: null,
				pickup: null,
				pendingPickups: [],
				pickupSuccess: false,
			}

		case GET_ALL_PENDING_PICKUPS:
			return {
				...state,
				pickupLoading: true,
				pickupError: null,
				pickup: null,
				pendingPickups: [],
				pickupSuccess: false,
			}

		case GET_PICKUPS_BY_PHONE:
			return {
				...state,
				pickupLoading: true,
				pickupError: null,
				pickup: null,
				pendingPickups: [],
			}

		case GET_PICKUP_BY_ID:
			return {
				...state,
				pickupLoading: true,
				pickupError: null,
				pickup: null,
				pendingPickups: [],
				pickupSuccess: false,
			}

		case CREATE_PENDING_PICKUP_SUCCESS:
			// console.log("success", action.payload);
			return {
				...state,
				pickupLoading: false,
				pickupError: null,
				pickup: action.payload,
				pickupSuccess: true,
			}

		case GET_PICKUP_BY_ID_SUCCESS:
			// console.log("success", action.payload);
			return {
				...state,
				pickupLoading: false,
				pickupError: null,
				pickup: action.payload,
				pickupSuccess: true,
			}

		case GET_PENDING_PICKUPS_SUCCESS:
			// console.log("success", action.payload);
			return {
				...state,
				pickupLoading: false,
				pendingPickups: action.payload,
				pickupError: null,
				pickupSuccess: true,
			}

		case GET_ALL_PENDING_PICKUPS_SUCCESS:
			// console.log("success", action.payload);
			return {
				...state,
				pickupLoading: false,
				pendingPickups: action.payload,
				pickupError: null,
				pickupSuccess: true,
			}

		case GET_PICKUPS_BY_PHONE_SUCCESS:
			return {
				...state,
				pickupLoading: false,
				pendingPickups: action.payload,
				pickupError: null,
			}

		case CREATE_PENDING_PICKUP_ERROR:
			// console.log("error", action.payload);
			return {
				...state,
				pickupLoading: false,
				pickupError: action.payload,
				pickupSuccess: false,
				pickup: null,
				pendingPickups: [],
			}

		case GET_PENDING_PICKUPS_ERROR:
			// console.log("error", action.payload);
			return {
				...state,
				pickupLoading: false,
				pickupError: action.payload,
				pickupSuccess: false,
				pickup: null,
				pendingPickups: [],
			}

		case GET_ALL_PENDING_PICKUPS_ERROR:
			// console.log("error", action.payload);
			return {
				...state,
				pickupLoading: false,
				pickupError: action.payload,
				pickupSuccess: false,
				pickup: null,
				pendingPickups: [],
			}

		case GET_PICKUPS_BY_PHONE_ERROR:
			// console.log("error", action.payload);
			return {
				...state,
				pickupLoading: false,
				pickupError: action.payload,
				pickup: null,
				pendingPickups: [],
			}

		case GET_PICKUP_BY_ID_ERROR:
			// console.log("error", action.payload);
			return {
				...state,
				pickupLoading: false,
				pickupError: action.payload,
				pickupSuccess: false,
				pickup: null,
				pendingPickups: [],
			}

		case CREATE_GENTLY_PICKUP:
			return {
				...state,
				creatingPickup: true,
				pickupError: null,
			}

		case CREATE_GENTLY_PICKUP_SUCCESS:
			return {
				...state,
				creatingPickup: false,
				pickupRequested: action.payload,
				pickupError: action.payload.error ? action.payload.error : null,
			}

		case CREATE_GENTLY_PICKUP_ERROR:
			return {
				...state,
				creatingPickup: false,
				pickupError: action.payload,
				pickupRequested: false,
			}

		case CREATE_GENTLY_PICKUP_NO_NODE:
			return {
				...state,
				creatingPickup: true,
				pickupError: null,
			}

		case CREATE_GENTLY_PICKUP_NO_NODE_SUCCESS:
			return {
				...state,
				creatingPickup: false,
				pickupRequested: action.payload,
				pickupError: action.payload.error ? action.payload.error : null,
			}

		case CREATE_GENTLY_PICKUP_NO_NODE_ERROR:
			return {
				...state,
				creatingPickup: false,
				pickupError: action.payload,
				pickupRequested: false,
			}

		case RESET_PICKUPS:
			return {
				pickupError: false,
				pickupLoading: false,
				pickupSuccess: false,
				pickup: null,
				pendingPickups: [],
			}

		default:
			return state
	}
}

export default pickups
