import { SMS_VERIFY_SEND_PHONE, SMS_VERIFY_USER_RESPONSE } from './actionTypes'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
	smsVerifySendPhoneSuccess,
	smsVerifyFailed,
	smsVerifySuccessful,
} from './actions'
import { smsCheckSend, smsCheckVerify } from '../../../model/auth'

function* sendSms(action) {
	try {
		// console.log("in sendSms", action.payload)
		const smsResponse = yield call(smsCheckSend, action.payload)
		yield put(smsVerifySendPhoneSuccess(smsResponse))
	} catch (error) {
		yield put(smsVerifyFailed(error))
	}
}

function* verifySms(action) {
	try {
		// console.log("in verifySms", action.payload)
		const smsVerifyResponse = yield call(smsCheckVerify, action.payload)
		// console.log("smsVerifyResponse", smsVerifyResponse)
		yield put(smsVerifySuccessful(smsVerifyResponse))
	} catch (error) {
		yield put(smsVerifyFailed(error))
	}
}

function* smsVerifySaga() {
	yield takeEvery(SMS_VERIFY_SEND_PHONE, sendSms),
		yield takeEvery(SMS_VERIFY_USER_RESPONSE, verifySms)
}

export default smsVerifySaga
