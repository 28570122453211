import React, { useEffect, useState } from 'react'
import { CheckboxGroup, RadioGroup } from '../Elements'

import { connect } from 'react-redux'

const NestedModifiers = (props) => {
	const { nestedModifiers, handleSetModifiers, modErrorGroupId } = props
	return (
		<div className='pl-10 pt-2'>
			{nestedModifiers.map((nestedModifier, index) => {
				const minSelection =
					nestedModifier.nestedModifierGroup.minimumSelections
				const maxSelection =
					nestedModifier.nestedModifierGroup.maximumSelections > 1
						? nestedModifier.nestedModifierGroup.maximumSelections
						: 1
				const modItems = nestedModifier.nestedModItems
				const [selectedModifiers, setSelectedModifiers] = useState([])
				const [limitReached, setLimitReached] = useState(false)

				useEffect(() => {
					if (maxSelection > selectedModifiers.length) {
						setLimitReached(false)
					} else {
						setLimitReached(true)
					}
				}, [JSON.stringify(selectedModifiers)])

				if (maxSelection > 1 || minSelection === 0) {
					return (
						<div
							className='mb-4'
							key={index}
						>
							{' '}
							{minSelection === 0 ? (
								<label
									className={`font-semibold mb-3 text-sm text-gray-600 inline-block`}
								>
									{nestedModifier.nestedModifierGroup.name} -
									LIMIT {maxSelection}
								</label>
							) : (
								<label
									className={`font-semibold mb-3 text-sm text-gray-600 inline-block`}
								>
									{nestedModifier.nestedModifierGroup.name} -
									LIMIT {maxSelection} (REQUIRED{' '}
									{minSelection})
								</label>
							)}
							<form>
								{modItems.map((nestedModItem) => {
									const [checked, setChecked] =
										useState(false)
									useEffect(() => {
										if (checked) {
											const tempArray = selectedModifiers
											tempArray.push(nestedModItem)
											setSelectedModifiers(tempArray)
											handleSetModifiers(
												nestedModItem,
												'add'
											)
											if (
												maxSelection >
												selectedModifiers.length
											) {
												setLimitReached(false)
											} else {
												setLimitReached(true)
											}
										} else {
											const reducedModifiers =
												selectedModifiers.filter(
													(e) => e !== nestedModItem
												)
											setSelectedModifiers(
												reducedModifiers
											)
											handleSetModifiers(
												nestedModItem,
												'remove'
											)
										}
									}, [checked])
									return (
										<div>
											{limitReached ? (
												<div>
													{selectedModifiers.includes(
														nestedModItem
													) ? (
														<div className='my-2'>
															<CheckboxGroup
																className=''
																label={
																	nestedModItem
																		.price
																		.amount >
																	0
																		? `${
																				nestedModItem.name
																		  } (+${nestedModItem.price.amount.toFixed(
																				2
																		  )})`
																		: nestedModItem
																				.price
																				.amount <
																		  0
																		? `${
																				nestedModItem.name
																		  } (${nestedModItem.price.amount.toFixed(
																				2
																		  )})`
																		: nestedModItem.name
																}
																name={
																	nestedModItem.id
																}
																defaultChecked={
																	checked
																}
																onChange={() => {
																	setChecked(
																		!checked
																	)
																}}
															/>
														</div>
													) : (
														<div className='my-2'>
															<CheckboxGroup
																disabled='disabled'
																className=''
																label={
																	nestedModItem
																		.price
																		.amount >
																	0
																		? `${
																				nestedModItem.name
																		  } (+${nestedModItem.price.amount.toFixed(
																				2
																		  )})`
																		: nestedModItem
																				.price
																				.amount <
																		  0
																		? `${
																				nestedModItem.name
																		  } (${nestedModItem.price.amount.toFixed(
																				2
																		  )})`
																		: nestedModItem.name
																}
																name={
																	nestedModItem.id
																}
																defaultChecked={
																	checked
																}
																onChange={() => {}}
															/>
														</div>
													)}
												</div>
											) : (
												<div className='my-2'>
													<CheckboxGroup
														className=''
														label={
															nestedModItem.price
																.amount > 0
																? `${
																		nestedModItem.name
																  } (+${nestedModItem.price.amount.toFixed(
																		2
																  )})`
																: nestedModItem
																		.price
																		.amount <
																  0
																? `${
																		nestedModItem.name
																  } (${nestedModItem.price.amount.toFixed(
																		2
																  )})`
																: nestedModItem.name
														}
														name={nestedModItem.id}
														defaultChecked={checked}
														onChange={() => {
															setChecked(!checked)
														}}
													/>
												</div>
											)}
										</div>
									)
								})}
							</form>
							{limitReached && (
								<div className='text-red-400 text-sm'>
									Limit reached for this modifier
								</div>
							)}
							{modErrorGroupId ==
								nestedModifier.nestedModifierGroup.id && (
								<div className='text-red-400 text-sm'>
									Required {minSelection}
								</div>
							)}
						</div>
					)
				} else {
					const modItemRadios = []
					modItems.map((nestedModItem, nestedModIndex) => {
						modItemRadios.push({
							value: nestedModIndex,
							label:
								nestedModItem.price.amount > 0
									? `${
											nestedModItem.name
									  } (+${nestedModItem.price.amount.toFixed(
											2
									  )})`
									: nestedModItem.price.amount < 0
									? `${
											nestedModItem.name
									  } (${nestedModItem.price.amount.toFixed(
											2
									  )})`
									: nestedModItem.name,
						})
					})
					return (
						<div className='mb-4'>
							{minSelection > 0 && (
								<RadioGroup
									labelClassName='font-semibold'
									name={
										nestedModifier.nestedModifierGroup.name
									}
									value={
										nestedModifier.nestedModifierGroup.name
									}
									label={
										minSelection !== 0
											? nestedModifier.nestedModifierGroup
													.name + ' - (REQUIRED 1)'
											: nestedModifier.nestedModifierGroup
													.name + ' - LIMIT 1'
									}
									radios={modItemRadios}
									onChange={(nestedModIndex) => {
										for (const nestedMod of modItems) {
											handleSetModifiers(
												nestedMod,
												'remove'
											)
										}
										handleSetModifiers(
											modItems[nestedModIndex],
											'add',
											'radio',
											modItems
										)
									}}
								/>
							)}

							{modErrorGroupId ==
								nestedModifier.nestedModifierGroup.id && (
								<div className='text-red-400 text-sm'>
									Required {minSelection}
								</div>
							)}
						</div>
					)
				}
			})}
		</div>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NestedModifiers)
