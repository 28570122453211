import {
	GET_RESTAURANT_PAYMENTS,
	GET_RESTAURANT_PAYMENTS_FAIL,
	GET_RESTAURANT_PAYMENTS_SUCCESS,
	SEND_RESTAURANT_PAYMENTS,
	SEND_RESTAURANT_PAYMENTS_FAIL,
	SEND_RESTAURANT_PAYMENTS_SUCCESS,
	GET_RESTAURANT_CHARGES,
	GET_RESTAURANT_CHARGES_FAIL,
	GET_RESTAURANT_CHARGES_SUCCESS,
	CHARGE_RESTAURANTS,
	CHARGE_RESTAURANTS_FAIL,
	CHARGE_RESTAURANTS_SUCCESS,
} from './actionTypes'

export const getRestaurantPayments = (payload) => ({
	type: GET_RESTAURANT_PAYMENTS,
	payload: payload,
})

export const getRestaurantPaymentsSuccess = (payload) => ({
	type: GET_RESTAURANT_PAYMENTS_SUCCESS,
	payload: payload,
})

export const getRestaurantPaymentsFail = (error) => ({
	type: GET_RESTAURANT_PAYMENTS_FAIL,
	payload: error,
})

export const sendRestaurantPayments = (payload) => ({
	type: SEND_RESTAURANT_PAYMENTS,
	payload: payload,
})

export const sendRestaurantPaymentsSuccess = (payload) => ({
	type: SEND_RESTAURANT_PAYMENTS_SUCCESS,
	payload: payload,
})

export const sendRestaurantPaymentsFail = (error) => ({
	type: SEND_RESTAURANT_PAYMENTS_FAIL,
	payload: error,
})

export const getRestaurantCharges = (payload) => ({
	type: GET_RESTAURANT_CHARGES,
	payload: payload,
})

export const getRestaurantChargesSuccess = (payload) => ({
	type: GET_RESTAURANT_CHARGES_SUCCESS,
	payload: payload,
})

export const getRestaurantChargesFail = (error) => ({
	type: GET_RESTAURANT_CHARGES_FAIL,
	payload: error,
})

export const chargeRestaurants = (payload) => ({
	type: CHARGE_RESTAURANTS,
	payload: payload,
})

export const chargeRestaurantsSuccess = (payload) => ({
	type: CHARGE_RESTAURANTS_SUCCESS,
	payload: payload,
})

export const chargeRestaurantsFail = (error) => ({
	type: CHARGE_RESTAURANTS_FAIL,
	payload: error,
})
