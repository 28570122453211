import {
	ADMIN_GET_RETURNS,
	ADMIN_GET_RETURNS_SUCCESS,
	ADMIN_GET_RETURNS_ERROR,
	ADMIN_GET_RETURNS_COUNT,
	ADMIN_GET_RETURNS_COUNT_SUCCESS,
	ADMIN_GET_CUSTOMERS,
	ADMIN_GET_CUSTOMERS_SUCCESS,
	ADMIN_GET_CUSTOMERS_ERROR,
	ADMIN_GET_CUSTOMERS_BOX_COUNT,
	ADMIN_GET_CUSTOMERS_BOX_COUNT_SUCCESS,
	ADMIN_GET_CUSTOMERS_BOX_COUNT_ERROR,
	ADMIN_GET_CLIENTS_SUCCESS,
	ADMIN_GET_CLIENTS,
	ADMIN_GET_CLIENTS_ERROR,
	ADMIN_GET_AGGREGATORS,
	ADMIN_GET_AGGREGATORS_SUCCESS,
	ADMIN_GET_AGGREGATORS_ERROR,
	ADMIN_GET_ALL_CUSTOMERS_SUCCESS,
	ADMIN_GET_ALL_CUSTOMERS_ERROR,
	ADMIN_GET_ALL_CUSTOMERS,
	ADMIN_GET_ENV_IMPACT,
	ADMIN_GET_ENV_IMPACT_SUCCESS,
	ADMIN_UPLOAD_MENUS,
	ADMIN_UPLOAD_MENUS_ERROR,
	ADMIN_UPLOAD_MENUS_SUCCESS,
	GET_DZ_PICKUPS,
	GET_DZ_PICKUPS_SUCCESS,
	GET_DZ_PICKUPS_ERROR,
	CREATE_ROUTE,
	CREATE_ROUTE_SUCCESS,
	CREATE_ROUTE_ERROR,
	ADMIN_GET_PLATFORMS,
	ADMIN_GET_PLATFORMS_SUCCESS,
	ADMIN_GET_PLATFORMS_ERROR,
	ALERT_SLACK,
	ALERT_SLACK_ERROR,
	ALERT_SLACK_SUCCESS,
	GET_USAGE_DATA,
	GET_USAGE_DATA_ERROR,
	GET_USAGE_DATA_SUCCESS,
} from './actionTypes'

export const getAdminReturns = (payload) => ({
	type: ADMIN_GET_RETURNS,
	payload: payload,
})

export const getReturnsSuccess = (payload) => ({
	type: ADMIN_GET_RETURNS_SUCCESS,
	payload: payload,
})

export const getReturnsError = (payload) => ({
	type: ADMIN_GET_RETURNS_ERROR,
	payload: payload,
})

export const getReturnsCount = (payload) => ({
	type: ADMIN_GET_RETURNS_COUNT,
	payload: payload,
})

export const getReturnsCountSuccess = (payload) => ({
	type: ADMIN_GET_RETURNS_COUNT_SUCCESS,
	payload: payload,
})

export const getAdminCustomers = (payload) => ({
	type: ADMIN_GET_CUSTOMERS,
	payload: payload,
})

export const getAdminCustomersSuccess = (payload) => ({
	type: ADMIN_GET_CUSTOMERS_SUCCESS,
	payload: payload,
})

export const getAdminCustomersError = (payload) => ({
	type: ADMIN_GET_CUSTOMERS_ERROR,
	payload: payload,
})

export const getAdminCustomersBoxCount = (payload) => ({
	type: ADMIN_GET_CUSTOMERS_BOX_COUNT,
	payload: payload,
})

export const getAdminCustomersBoxCountSuccess = (payload) => ({
	type: ADMIN_GET_CUSTOMERS_BOX_COUNT_SUCCESS,
	payload: payload,
})

export const getAdminCustomersBoxCountError = (payload) => ({
	type: ADMIN_GET_CUSTOMERS_BOX_COUNT_ERROR,
	payload: payload,
})

export const getAdminClients = (payload) => ({
	type: ADMIN_GET_CLIENTS,
	payload: payload,
})

export const getAdminClientsSuccess = (payload) => ({
	type: ADMIN_GET_CLIENTS_SUCCESS,
	payload: payload,
})

export const getAdminClientsError = (payload) => ({
	type: ADMIN_GET_CLIENTS_ERROR,
	payload: payload,
})

export const getAdminAggregators = (payload) => ({
	type: ADMIN_GET_AGGREGATORS,
	payload: payload,
})

export const getAdminAggregatorsSuccess = (payload) => ({
	type: ADMIN_GET_AGGREGATORS_SUCCESS,
	payload: payload,
})

export const getAdminAggregatorsError = (payload) => ({
	type: ADMIN_GET_AGGREGATORS_ERROR,
	payload: payload,
})

export const getAllCustomers = (payload) => ({
	type: ADMIN_GET_ALL_CUSTOMERS,
	payload: payload,
})

export const getAllCustomersSuccess = (payload) => ({
	type: ADMIN_GET_ALL_CUSTOMERS_SUCCESS,
	payload: payload,
})

export const getAllCustomersError = (payload) => ({
	type: ADMIN_GET_ALL_CUSTOMERS_ERROR,
	payload: payload,
})

export const getEnvImpact = (payload) => ({
	type: ADMIN_GET_ENV_IMPACT,
	payload: payload,
})

export const getEnvImpactSuccess = (payload) => ({
	type: ADMIN_GET_ENV_IMPACT_SUCCESS,
	payload: payload,
})

export const adminUploadMenus = (payload) => ({
	type: ADMIN_UPLOAD_MENUS,
	payload: payload,
})

export const adminUploadMenusSuccess = (payload) => ({
	type: ADMIN_UPLOAD_MENUS_SUCCESS,
	payload: payload,
})

export const adminUploadMenusError = (payload) => ({
	type: ADMIN_UPLOAD_MENUS_ERROR,
	payload: payload,
})

export const createRoute = (payload) => ({
	type: CREATE_ROUTE,
	payload: payload,
})

export const createRouteSuccess = (payload) => ({
	type: CREATE_ROUTE_SUCCESS,
	payload: payload,
})

export const createRouteError = (payload) => ({
	type: CREATE_ROUTE_ERROR,
	payload: payload,
})

export const getDzPickups = (payload) => ({
	type: GET_DZ_PICKUPS,
	payload: payload,
})

export const getDzPickupsSuccess = (payload) => ({
	type: GET_DZ_PICKUPS_SUCCESS,
	payload: payload,
})

export const getDzPickupsError = (payload) => ({
	type: GET_DZ_PICKUPS_ERROR,
	payload: payload,
})

export const getAdminPlatforms = (payload) => ({
	type: ADMIN_GET_PLATFORMS,
	payload: payload,
})

export const getAdminPlatformsSuccess = (payload) => ({
	type: ADMIN_GET_PLATFORMS_SUCCESS,
	payload: payload,
})

export const getAdminPlatformsError = (payload) => ({
	type: ADMIN_GET_PLATFORMS_ERROR,
	payload: payload,
})

export const alertSlack = (payload) => ({
	type: ALERT_SLACK,
	payload: payload,
})

export const alertSlackSuccess = (payload) => ({
	type: ALERT_SLACK_SUCCESS,
	payload: payload,
})

export const alertSlackError = (payload) => ({
	type: ALERT_SLACK_ERROR,
	payload: payload,
})

export const getUsageData = (payload) => ({
	type: GET_USAGE_DATA,
	payload: payload,
})

export const getUsageDataSuccess = (payload) => ({
	type: GET_USAGE_DATA_SUCCESS,
	payload: payload,
})

export const getUsageDataError = (error) => ({
	type: GET_USAGE_DATA_ERROR,
	payload: error,
})
