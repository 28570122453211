import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	createTransaction,
	getAppInfo,
	adminUploadMenus,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import { BounceLoaderCentered, Button } from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import * as xlsx from 'xlsx'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const MenuUpload = (state) => {
	const {
		user,
		onGetUser,
		onCreateTransaction,
		transactionResult,
		appInfo,
		onGetAppInfo,
		menusUploading,
		menusUploaded,
		menusUploadError,
		onUploadMenus,
	} = state

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [selectedClient, setSelectedClient] = useState('')
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [menusArray, setMenusArray] = useState([])
	const [formError, setFormError] = useState('')

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	useEffect(() => {
		onGetAppInfo()
	}, [onGetAppInfo])

	const readUploadFile = (e) => {
		e.preventDefault()
		if (e.target.files) {
			const reader = new FileReader()
			reader.onload = (e) => {
				const data = e.target.result
				const workbook = xlsx.read(data, { type: 'array' })
				const sheetName = workbook.SheetNames[0]
				const worksheet = workbook.Sheets[sheetName]
				const json = xlsx.utils.sheet_to_json(worksheet)
				setMenusArray(json)
			}
			reader.readAsArrayBuffer(e.target.files[0])
		}
	}

	// //If menu array is too large, break it into chunks
	// function chunkMenu(menusArray, chunkSize) {
	//     let chunks = [];
	//     for (let i = 0; i < menusArray.length; i += chunkSize) {
	//         chunks.push(menusArray.slice(i, i + chunkSize));
	//     }
	//     return chunks;
	// }

	// chunk in 6 for UE
	function chunkMenu(menusArray) {
		const chunkSize = Math.ceil(menusArray.length / 6)
		let chunks = []
		for (let i = 0; i < menusArray.length; i += chunkSize) {
			chunks.push(menusArray.slice(i, i + chunkSize))
		}
		return chunks
	}

	// chunk in half for DD
	function chunkMenuDD(menusArray) {
		// console.log("menusArray", menusArray);
		const chunkSize = Math.ceil(menusArray.length / 2)
		let chunks = []
		for (let i = 0; i < menusArray.length; i += chunkSize) {
			chunks.push(menusArray.slice(i, i + chunkSize))
		}
		return chunks
	}

	const submitMenus = async () => {
		setFormError(false)
		if (
			menusArray.length > 0 &&
			selectedClient != '' &&
			selectedClient === 'ubereats'
		) {
			let menuChunks = chunkMenu(menusArray)
			const delayBetweenRequests = 100000
			for (const menuChunk of menuChunks) {
				try {
					const response = await onUploadMenus({
						client: selectedClient,
						menusArray: menuChunk,
					})
				} catch (error) {
					console.error('Error uploading menus:', error.message)
				} finally {
					await new Promise((resolve) =>
						setTimeout(resolve, delayBetweenRequests)
					)
				}
			}
		} else if (menusArray.length > 0 && selectedClient != '') {
			let menuChunks = chunkMenuDD(menusArray)
			// console.log(menuChunks)
			const delayBetweenRequests = 100000
			for (const menuChunk of menuChunks) {
				console.log(
					'selectedClient',
					selectedClient,
					'menuChunk',
					menuChunk
				)
				try {
					const response = await onUploadMenus({
						client: selectedClient,
						menusArray: menuChunk,
					})
				} catch (error) {
					console.error('Error uploading menus:', error.message)
				} finally {
					await new Promise((resolve) =>
						setTimeout(resolve, delayBetweenRequests)
					)
				}
			}
		} else {
			console.log(
				'menusArray',
				menusArray,
				'selectedClient',
				selectedClient
			)
			setFormError("You're missing one of the fields above.")
		}
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div className='px-12 h-screen-3/4'>
					<div>
						<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
							Manual Bulk Import Menus from Client
						</h1>
						<form className='flex flex-col items-center'>
							<label
								htmlFor='upload'
								className='font-bold text-xl mb-2'
							>
								Upload File
							</label>
							<input
								type='file'
								name='upload'
								id='upload'
								onChange={readUploadFile}
							/>
						</form>
						<div className='mt-6 mb-3 font-bold text-xl text-center'>
							Select the platform
						</div>

						{appInfo ? (
							<div className='flex flex-row justify-center'>
								<div className='text-lg'>
									For:{' '}
									<span className='font-bold'>
										{selectedClient !== ''
											? appInfo.clients[selectedClient]
													.name
											: selectedClient}
									</span>
								</div>
								{showNodeChoices ? (
									<BsChevronUp
										onClick={() => {
											setShowNodeChoices(false)
										}}
										className='cursor-pointer ml-2 text-green-600 text-lg sm:text-xl mt-2 font-bold'
									/>
								) : (
									<BsChevronDown
										onClick={() => {
											setShowNodeChoices(true)
										}}
										className='cursor-pointer ml-2 text-green-600 text-lg sm:text-xl mt-2 font-bold'
									/>
								)}
							</div>
						) : (
							<BounceLoaderCentered container='div' />
						)}
						<div className='flex-col flex items-center'>
							{showNodeChoices && appInfo?.clients && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white bmenu-2 rounded-[5px] bmenu-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									{Object.keys(appInfo.clients).map(
										(client) => (
											<div
												className='text-sm cursor-pointer my-1 hover:bg-gray-100'
												key={client}
												onClick={() => {
													setSelectedClient(client)
													setShowNodeChoices(false)
												}}
											>
												{appInfo.clients[client].name}
											</div>
										)
									)}
								</div>
							)}
						</div>
					</div>
					<div className='text-center'>
						{menusUploading ? (
							<BounceLoaderCentered container='div' />
						) : (
							<Button
								onClick={submitMenus}
								className='mx-auto mt-4 w-[150px]'
								text='Submit Menus'
							/>
						)}
					</div>
					{menusUploadError && (
						<div className='text-center font-bold text-red-400 mt-1'>
							{menusUploadError.message}
						</div>
					)}
					{formError && (
						<div className='text-center font-bold text-red-400 mt-1'>
							{formError}
						</div>
					)}
					{menusUploaded && (
						<div className='text-center font-bold text-green-600 mt-1'>
							Menus successfully uploaded!
						</div>
					)}
				</div>

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Transactions, AppInfo, Admin }) => ({
	user: User.user,
	loadingUser: User.loading,
	menusUploading: Admin.menusUploading,
	menusUploaded: Admin.menusUploaded,
	menusUploadError: Admin.menusUploadError,
	appInfo: AppInfo.appInfo,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onCreateTransaction: (transactionPayload) =>
		dispatch(createTransaction(transactionPayload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onUploadMenus: (payload) => dispatch(adminUploadMenus(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuUpload)
