import { call, put, takeEvery } from 'redux-saga/effects'
import { runPentestError, runPentestSuccess } from './actions'
import { RUN_PENTEST } from './actionTypes'
import { executePentestApi } from '../../model/pentest'

function* runPentest(action) {
	try {
		const response = yield call(executePentestApi, action.payload)
		yield put(runPentestSuccess(response))
	} catch (error) {
		yield put(runPentestError(error))
	}
}

function* pentestSaga() {
	yield takeEvery(RUN_PENTEST, runPentest)
}

export default pentestSaga
