import { realm } from '../helpers/realm'

export const getLead = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const leads = client.db('caas').collection('leads')
	const lead = await leads.findOne({ slug: payload.slug })
	return lead
}

export const updateLead = async (updateObject) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const leads = client.db('caas').collection('leads')
	const update = await leads.updateOne(
		{ slug: updateObject.slug },
		{ $set: updateObject }
	)
	const lead = await leads.findOne({ slug: updateObject.slug })
	return lead
}

export const getAllLeads = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const leads = client.db('caas').collection('leads')

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const stages = []

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

	return leads.aggregate(stages)
}

export const addLead = async (leadDoc) => {
	// console.log("leadDoc in model", leadDoc)
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const leads = client.db('caas').collection('leads')
	const existingLead = await leads.findOne({ slug: leadDoc.slug })
	if (existingLead) {
		throw 'lead already exists'
	} else {
		return leads.insertOne(leadDoc)
	}
}

export const generateDocuSign = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const res = await realm.currentUser.callFunction(
		'signUpFlow/generateDocuSign',
		payload
	)

	return res
}

export const finalizeNode = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const res = await realm.currentUser.callFunction(
		'signUpFlow/finalizeSignUp',
		payload
	)

	return res
}

export const getServiceAreaPolygons = async () => {
	const client = realm.currentUser.mongoClient('RealmService')
	const serviceAreas = client.db('caas').collection('service_areas')
	const polygons = await serviceAreas.find({})
	return polygons
}

export const checkPromoCodeValidity = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const nodePromos = client.db('caas').collection('node_promos')
	const promoCodeDoc = await nodePromos.find({
		promoCode: payload.promoCode,
	})
	return promoCodeDoc
}
