import {
	ADMIN_GET_RETURNS,
	ADMIN_GET_RETURNS_SUCCESS,
	ADMIN_GET_RETURNS_ERROR,
	ADMIN_GET_RETURNS_COUNT,
	ADMIN_GET_RETURNS_COUNT_SUCCESS,
	ADMIN_GET_CUSTOMERS,
	ADMIN_GET_CUSTOMERS_SUCCESS,
	ADMIN_GET_CUSTOMERS_ERROR,
	ADMIN_GET_CUSTOMERS_BOX_COUNT,
	ADMIN_GET_CUSTOMERS_BOX_COUNT_SUCCESS,
	ADMIN_GET_CUSTOMERS_BOX_COUNT_ERROR,
	ADMIN_GET_CLIENTS,
	ADMIN_GET_CLIENTS_SUCCESS,
	ADMIN_GET_CLIENTS_ERROR,
	ADMIN_GET_AGGREGATORS,
	ADMIN_GET_AGGREGATORS_SUCCESS,
	ADMIN_GET_AGGREGATORS_ERROR,
	ADMIN_GET_ALL_CUSTOMERS,
	ADMIN_GET_ALL_CUSTOMERS_SUCCESS,
	ADMIN_GET_ALL_CUSTOMERS_ERROR,
	ADMIN_GET_ENV_IMPACT,
	ADMIN_GET_ENV_IMPACT_SUCCESS,
	GET_DZ_PICKUPS,
	GET_DZ_PICKUPS_SUCCESS,
	GET_DZ_PICKUPS_ERROR,
	CREATE_ROUTE,
	CREATE_ROUTE_SUCCESS,
	CREATE_ROUTE_ERROR,
	ADMIN_GET_PLATFORMS,
	ADMIN_GET_PLATFORMS_SUCCESS,
	ADMIN_GET_PLATFORMS_ERROR,
	ALERT_SLACK,
	ALERT_SLACK_SUCCESS,
	ALERT_SLACK_ERROR,
	GET_USAGE_DATA,
	GET_USAGE_DATA_ERROR,
	GET_USAGE_DATA_SUCCESS,
} from './actionTypes'
import {
	ADMIN_UPLOAD_MENUS,
	ADMIN_UPLOAD_MENUS_ERROR,
	ADMIN_UPLOAD_MENUS_SUCCESS,
} from '../admin/actionTypes'

const initialState = {
	returns: {},
	loadingReturns: false,
	error: null,
	returnsFeedCount: 0,
	customers: [],
	loadingCustomers: false,
	customersCount: 0,
	clients: [],
	loadingClients: false,
	clientError: null,
	aggregators: [],
	loadingAggregators: false,
	aggregatorError: null,
	allCustomers: [],
	loadingAllCustomers: false,
	returnsCount: 100,
	loadingEnvImpact: false,
	totalBoxesOut: null,
	totalBoxesIn: null,
	effectiveReturnRate: null,
	menusUploading: false,
	menusUploaded: false,
	menusUploadError: null,
	creatingRoute: false,
	createdRoute: null,
	createRouteError: null,
	gettingDzPickups: false,
	dzPickups: null,
	dzPickupsError: null,
	loadingPlatforms: false,
	platforms: [],
	platformsError: null,
	alertingSlack: false,
	alertedSlack: false,
	alertSlackError: null,
	loadingData: false,
	usageData: null,
	usageMonths: null,
}

const admin = (state = initialState, action) => {
	switch (action.type) {
		case ADMIN_GET_RETURNS:
			return {
				...state,
				loadingReturns: true,
			}
		case ADMIN_GET_RETURNS_SUCCESS:
			const returns = action.payload[0]
			return {
				...state,
				returns: returns.data,
				loadingReturns: false,
				returnsFeedCount: returns.pagination[0]
					? returns.pagination[0].total
					: 0,
			}
		case ADMIN_GET_RETURNS_ERROR:
			return {
				...state,
				error: action.payload,
				loadingReturns: false,
			}
		case ADMIN_GET_RETURNS_COUNT:
			return {
				...state,
			}
		case ADMIN_GET_RETURNS_COUNT_SUCCESS:
			return {
				...state,
				returnsCount: action.payload,
			}
		case ADMIN_GET_CUSTOMERS:
			return {
				...state,
				loadingCustomers: true,
			}
		case ADMIN_GET_CUSTOMERS_SUCCESS:
			return {
				...state,
				customers: action.payload[0].data,
				customersCount: action.payload[0].pagination[0]?.total || 0,
				loadingCustomers: false,
			}
		case ADMIN_GET_CUSTOMERS_ERROR:
			return {
				...state,
				error: action.payload,
				loadingCustomers: false,
			}
		case ADMIN_GET_CUSTOMERS_BOX_COUNT:
			return {
				...state,
				loadingCustomers: true,
			}
		case ADMIN_GET_CUSTOMERS_BOX_COUNT_SUCCESS:
			return {
				...state,
				customers: action.payload.data,
				customersCount: action.payload.pagination[0].total,
				loadingCustomers: false,
			}
		case ADMIN_GET_CUSTOMERS_BOX_COUNT_ERROR:
			return {
				...state,
				error: action.payload,
				loadingCustomers: false,
			}
		case ADMIN_GET_CLIENTS:
			return {
				...state,
				loadingClients: true,
			}
		case ADMIN_GET_CLIENTS_SUCCESS:
			return {
				...state,
				clients: action.payload,
				loadingClients: false,
			}
		case ADMIN_GET_CLIENTS_ERROR:
			return {
				...state,
				clientError: action.payload,
				loadingClients: false,
			}
		case ADMIN_GET_AGGREGATORS:
			return {
				...state,
				loadingAggregators: true,
			}
		case ADMIN_GET_AGGREGATORS_SUCCESS:
			return {
				...state,
				aggregators: action.payload,
				loadingAggregators: false,
			}
		case ADMIN_GET_AGGREGATORS_ERROR:
			return {
				...state,
				aggregatorError: action.payload,
				loadingAggregators: false,
			}
		case ADMIN_GET_ALL_CUSTOMERS:
			return {
				...state,
				loadingAllCustomers: true,
			}
		case ADMIN_GET_ALL_CUSTOMERS_SUCCESS:
			return {
				...state,
				allCustomers: action.payload[0].data,
				loadingAllCustomers: false,
			}
		case ADMIN_GET_ALL_CUSTOMERS_ERROR:
			return {
				...state,
				error: action.payload,
				loadingAllCustomers: false,
			}
		case ADMIN_GET_ENV_IMPACT:
			return {
				...state,
				loadingEnvImpact: true,
				totalBoxesOut: null,
				totalBoxesIn: null,
				effectiveReturnRate: null,
			}
		case ADMIN_GET_ENV_IMPACT_SUCCESS:
			return {
				...state,
				loadingEnvImpact: false,
				totalBoxesOut: action.payload.totalBoxesOut,
				totalBoxesIn: action.payload.totalBoxesIn,
				effectiveReturnRate: action.payload.effectiveReturnRate,
			}
		case ADMIN_UPLOAD_MENUS:
			return {
				...state,
				menusUploaded: false,
				menusUploading: true,
				menusUploadError: null,
			}
		case ADMIN_UPLOAD_MENUS_SUCCESS:
			return {
				...state,
				menusUploaded: true,
				menusUploading: false,
				menusUploadError: null,
			}
		case ADMIN_UPLOAD_MENUS_ERROR:
			return {
				...state,
				menusUploaded: false,
				menusUploading: false,
				menusUploadError: action.payload,
			}
		case CREATE_ROUTE:
			return {
				...state,
				creatingRoute: true,
				createRouteError: null,
			}
		case CREATE_ROUTE_SUCCESS:
			return {
				...state,
				creatingRoute: false,
				createdRoute: action.payload,
			}
		case CREATE_ROUTE_ERROR:
			return {
				...state,
				creatingRoute: false,
				createRouteError: action.payload,
			}
		case GET_DZ_PICKUPS:
			return {
				...state,
				gettingDzPickups: true,
				dzPickupsError: null,
			}
		case GET_DZ_PICKUPS_SUCCESS:
			return {
				...state,
				gettingDzPickups: false,
				dzPickups: action.payload,
			}
		case GET_DZ_PICKUPS_ERROR:
			return {
				...state,
				gettingDzPickups: false,
				dzPickupsError: action.payload,
			}
		case ADMIN_GET_PLATFORMS:
			return {
				...state,
				loadingPlatforms: true,
				platformsError: null,
			}
		case ADMIN_GET_PLATFORMS_SUCCESS:
			return {
				...state,
				loadingPlatforms: false,
				platforms: action.payload,
			}
		case ADMIN_GET_PLATFORMS_ERROR:
			return {
				...state,
				loadingPlatforms: false,
				platformsError: action.payload,
			}
		case ALERT_SLACK:
			return {
				...state,
				alertingSlack: true,
				alertedSlack: false,
				alertSlackError: null,
			}
		case ALERT_SLACK_SUCCESS:
			return {
				...state,
				alertingSlack: false,
				alertedSlack: true,
				alertSlackError: null,
			}
		case ALERT_SLACK_ERROR:
			return {
				...state,
				alertingSlack: false,
				alertedSlack: false,
				alertSlackError: action.payload,
			}

		case GET_USAGE_DATA:
			return {
				...state,
				loadingData: true,
				error: false,
				usageData: null,
				usageMonths: null,
			}
		case GET_USAGE_DATA_SUCCESS:
			const data = action.payload
			return {
				...state,
				usageData: data.data,
				usageMonths: data.months,
				loadingData: false,
				error: false,
			}
		case GET_USAGE_DATA_ERROR:
			return {
				...state,
				loadingData: false,
				error: action.payload,
				usageData: null,
				usageMonths: null,
			}
		default:
			return state
	}
}

export default admin
