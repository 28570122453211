import {
	GET_WFM_DATA,
	GET_WFM_DATA_FAIL,
	GET_WFM_DATA_SUCCESS,
	GET_WFM_LOCATIONS,
	GET_WFM_LOCATIONS_FAIL,
	GET_WFM_LOCATIONS_SUCCESS,
	GET_WFM_BY_SLUG,
	GET_WFM_BY_SLUG_FAIL,
	GET_WFM_BY_SLUG_SUCCESS,
	UPLOAD_WFM_DATA,
	UPLOAD_WFM_DATA_FAIL,
	UPLOAD_WFM_DATA_SUCCESS,
	GET_WFM_ENV_IMPACT,
	GET_WFM_ENV_IMPACT_SUCCESS,
} from './actionTypes'

const initialState = {
	allData: null,
	error: null,
	singleLocationData: null,
	loading: false,
	locations: null,
	uploadResponse: null,
	loadingEnvImpact: false,
	totalBoxesOut: null,
	totalBoxesIn: null,
	effectiveReturnRate: null,
}

const wholefoods = (state = initialState, action) => {
	switch (action.type) {
		case GET_WFM_DATA:
			return {
				...state,
				loading: true,
			}
		case GET_WFM_DATA_SUCCESS:
			// console.log("success", action.payload)
			return {
				...state,
				loading: false,
				allData: action.payload,
			}

		case GET_WFM_DATA_FAIL:
			// console.log("fail", action.payload)
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case UPLOAD_WFM_DATA:
			return {
				...state,
				loading: true,
			}
		case UPLOAD_WFM_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				uploadResponse: action.payload,
			}

		case UPLOAD_WFM_DATA_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case GET_WFM_LOCATIONS:
			return {
				...state,
				loading: true,
			}
		case GET_WFM_LOCATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				locations: action.payload,
			}

		case GET_WFM_LOCATIONS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case GET_WFM_BY_SLUG:
			return {
				...state,
				loading: true,
			}
		case GET_WFM_BY_SLUG_SUCCESS:
			return {
				...state,
				loading: false,
				singleLocationData: action.payload,
			}

		case GET_WFM_BY_SLUG_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case GET_WFM_ENV_IMPACT:
			return {
				...state,
				loadingEnvImpact: true,
				totalBoxesOut: null,
				totalBoxesIn: null,
				effectiveReturnRate: null,
			}
		case GET_WFM_ENV_IMPACT_SUCCESS:
			return {
				...state,
				loadingEnvImpact: false,
				totalBoxesOut: action.payload.totalBoxesOut,
				totalBoxesIn: action.payload.totalBoxesIn,
				effectiveReturnRate: action.payload.effectiveReturnRate,
			}
		default:
			return state
	}
}

export default wholefoods
