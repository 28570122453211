import { takeEvery, put, call } from 'redux-saga/effects'

//Account Redux states
import {CONNECT_TO_CLIENT, AUTHORIZE_CHOWNOW, GET_SQUARE_AUTH_LINK} from './actionTypes'
import {
	connectToClientSuccessful,
	connectToClientFailed,
	authorizeChownowSuccessful,
	authorizeChownowFailed, getSquareAuthLinkSuccess, getSquareAuthLinkError,
} from './actions'
import {connectToClient, authorizeChownow, getSquareLink} from '../../../model/auth'

function* attachToClient(action) {
	try {
		const response = yield call(connectToClient, action.payload)
		yield put(connectToClientSuccessful(response))
	} catch (error) {
		yield put(connectToClientFailed(error))
	}
}

function* attachToChownow(action) {
	try {
		const response = yield call(authorizeChownow, action.payload)
		yield put(authorizeChownowSuccessful(response))
	} catch (error) {
		yield put(authorizeChownowFailed(error))
	}
}

function* fetchSquareAuthLink(action) {
	try {
		const response = yield call(getSquareLink, action.payload);
		yield put(getSquareAuthLinkSuccess(response));
	} catch (error) {
		yield put(getSquareAuthLinkError(error));
	}
}

function* oAuthSaga() {
	yield takeEvery(CONNECT_TO_CLIENT, attachToClient)
	yield takeEvery(AUTHORIZE_CHOWNOW, attachToChownow)
	yield takeEvery(GET_SQUARE_AUTH_LINK, fetchSquareAuthLink);
}

export default oAuthSaga
