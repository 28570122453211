import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getOrders,
	getNodes,
	getNodeBySlug,
	updateNode,
	createTransaction,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input } from '../../../components/Elements'

const RestaurantProfile = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetNodes,
		nodes,
		onGetNodeBySlug,
		node,
		loadingNode,
		nodeError,
		transactions,
		transactionsCount,
		loadingTransactions,
		onGetTransactions,
		onGetOrders,
		loadingOrders,
		orders,
		ordersCount,
		updatingNode,
		updateNodeCompleted,
		updateNodeError,
		onCreateTransaction,
		onUpdateNode,
		transactionResult,
	} = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const { nodeSlug } = useParams()
	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	const [orderPage, setOrderPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [showUpdateBalance, setShowUpdateBalance] = useState(false)
	const [newBalance, setNewBalance] = useState(0)
	const [noteForBalance, setNoteForBalance] = useState('')
	const [balanceError, setBalanceError] = useState(false)
	let navigate = useNavigate()

	const getTransactions = (page) => {
		onGetTransactions({
			filter: {
				$or: [{ node: nodeSlug }, { parentSlug: nodeSlug }],
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const transactionPaginate = () => {
		getTransactions(page)
	}

	const getOrders = (page) => {
		onGetOrders({
			filter: {
				nodeSlug: nodeSlug,
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const orderPaginate = () => {
		getOrders(orderPage)
	}

	const handleNodeUpdate = (nodeSlug, update) => {
		onUpdateNode({
			nodeSlug: nodeSlug,
			update: update,
		})
	}

	const handleUpdateBalance = (nodeSlug, update, note) => {
		onCreateTransaction({
			node: nodeSlug,
			type: 'adminBalanceUpdate',
			user: {
				phone: user.phone,
				id: user.id,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
			},
			boxCount: update.balance,
			client: 'deliverzero',
			timestamp: new Date(),
			note: note,
		})
		setShowUpdateBalance('')
	}

	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
			transactionPaginate()
			orderPaginate()
		}
	}, [nodeSlug])

	useEffect(() => {
		const validNegativeIntegerRegex = /^-?\d+$/
		if (newBalance === '' || !validNegativeIntegerRegex.test(newBalance)) {
			setBalanceError(true)
			setNewBalance(0)
		} else {
			const numericBalance = parseInt(newBalance, 10)
			if (numericBalance === 0) {
				setBalanceError(true)
				setNewBalance(0)
			} else {
				setBalanceError(false)
			}
		}
	}, [newBalance])

	useEffect(() => {
		if (updateNodeCompleted && nodeSlug) {
			onGetNodeBySlug(nodeSlug)
			transactionPaginate()
			orderPaginate()
		}
	}, [updateNodeCompleted])

	useEffect(() => {
		if (transactionResult && nodeSlug) {
			onGetNodeBySlug(nodeSlug)
			transactionPaginate()
			orderPaginate()
		}
	}, [transactionResult])

	useEffect(() => {
		if (nodeSlug) {
			transactionPaginate()
		}
	}, [page])

	useEffect(() => {
		if (nodeSlug) {
			orderPaginate()
		}
	}, [orderPage])

	useEffect(() => {
		setLoading(false)
	}, [orders, transactions])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		if (node) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='flex flex-col max-w-[800px] justify-center items-center my-10 ml-6'>
						<div className='mx-auto'>
							<div className='font-header text-2xl text-green-600'>
								{node.name}
							</div>
							{node.enabled ? (
								<div className='flex flex-row items-center'>
									<div className='font-bold text-green-600'>
										ENABLED
									</div>
									{updatingNode || loadingNode ? (
										<div className='ml-4'>Loading...</div>
									) : (
										<Button
											className='ml-4'
											color='red'
											size='xs'
											text='DISABLE RESTAURANT'
											onClick={() => {
												handleNodeUpdate(node.slug, {
													enabled: false,
												})
											}}
										/>
									)}
								</div>
							) : (
								<div className='flex flex-row items-center'>
									<div className='font-bold text-red-400'>
										DISABLED
									</div>
									{updatingNode || loadingNode ? (
										<div className='ml-4'>Loading...</div>
									) : (
										<Button
											className='ml-4'
											size='xs'
											text='ENABLE RESTAURANT'
											onClick={() => {
												handleNodeUpdate(node.slug, {
													enabled: true,
												})
											}}
										/>
									)}
								</div>
							)}
							{updateNodeError && (
								<div className='text-red-400'>
									{updateNodeError.error}
								</div>
							)}
							<div className=''>
								<span className='font-bold'>Address:</span>{' '}
								{node.address}, {node.city}, {node.state}{' '}
								{node.postal}
							</div>
							<div className=''>
								<span className='font-bold'>Phone:</span>{' '}
								{node.phone}
							</div>
							<div className='flex flex-row items-center'>
								<div className=''>
									<span className='font-bold'>Balance:</span>{' '}
									{node.balance} boxes
								</div>
								{showUpdateBalance ? (
									<Button
										className='ml-4'
										size='xs'
										color='red'
										text='CLOSE'
										onClick={() => {
											setShowUpdateBalance(false)
										}}
									/>
								) : (
									<Button
										className='ml-4'
										size='xs'
										text='UPDATE BALANCE'
										onClick={() => {
											setShowUpdateBalance(true)
										}}
									/>
								)}
							</div>
							{showUpdateBalance && (
								<div className='flex flex-row items-center justify-center'>
									<div className='m-6'>
										<Input
											type='number'
											label='Add/Subtract inventory'
											name='balance'
											className='bg-white border-2 border-gray-300 mb-2'
											onChange={(e) =>
												setNewBalance(e.target.value)
											}
										/>
										<div className='text-xs mb-6'>
											enter a negative to subtract
										</div>
										<Input
											type='text'
											label='note: (optional)'
											name='note'
											className='bg-white border-2 border-gray-300 w-full'
											onChange={(e) =>
												setNoteForBalance(
													e.target.value
												)
											}
										/>
										{updatingNode || loadingNode ? (
											<div className='ml-4'>
												Loading...
											</div>
										) : balanceError || newBalance === 0 ? (
											<>
												<Button
													className='mt-4'
													size='xs'
													color='disabled'
													disabled
													text='SUBMIT NEW BALANCE'
												/>
												{balanceError && (
													<div className='text-xs text-red-400 text-center'>
														Please enter a valid
														number
													</div>
												)}
											</>
										) : (
											<Button
												className='mt-4'
												size='xs'
												text='SUBMIT NEW BALANCE'
												onClick={() => {
													handleUpdateBalance(
														node.slug,
														{
															balance:
																parseInt(
																	newBalance
																),
														},
														noteForBalance
													)
												}}
											/>
										)}
									</div>
								</div>
							)}
							{transactionsCount > 0 && (
								<div className=''>
									<span className='font-bold'>
										Number of total transactions:
									</span>{' '}
									{transactionsCount}
								</div>
							)}
							{ordersCount > 0 && (
								<div className=''>
									<span className='font-bold'>
										Number of total orders:
									</span>{' '}
									{ordersCount}
								</div>
							)}
							{node.commissionFee && node.commissionFee > 0 ? (
								<div className=''>
									<span className='font-bold'>
										Commission Rate:
									</span>{' '}
									{node.commissionFee * 100}%
								</div>
							) : (
								<div></div>
							)}

							<div className='my-4'>
								<Button
									size='sm'
									text='Configure Menu Map'
									link={`/admin/restaurants/${nodeSlug}/menu-map`}
								/>
							</div>

							<div className='my-6'>
								<Button
									size='sm'
									text='View Usage by Month'
									link={`/admin/restaurants/${nodeSlug}/usage-data`}
								/>
							</div>
							{transactions.length > 0 && (
								<div className='px-10 my-6 shadow-light-grey rounded-xl py-2 w-full'>
									<div className='my-2 text-lg text-green-600'>
										All Box Transactions
									</div>
									<div className='flex flex-row mb-2'>
										<div className='font-bold w-1/4'>
											Date
										</div>
										<div className='font-bold w-1/4'>
											User
										</div>
										<div className='font-bold w-1/4'>
											Box Count
										</div>
										<div className='font-bold w-1/4 pl-2'>
											Notes
										</div>
										{node.parent && (
											<div className='font-bold w-1/4 pl-2'>
												Location
											</div>
										)}
									</div>
									{loading && (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									)}
									{transactions.map((transaction, i) => {
										const transactionDate = new Date(
											transaction.timestamp
										)
										const transactionDateString =
											transactionDate.toLocaleString(
												'default',
												{
													month: 'short',
													day: 'numeric',
													year: 'numeric',
													hour: '2-digit',
													minute: '2-digit',
												}
											)

										return (
											<div
												className='flex flex-row my-2 divide-y-2 divide-solid w-full'
												key={i}
											>
												<div></div>
												<div className='w-1/4'>
													{transactionDateString}
												</div>
												{transaction.type ===
												'adminBalanceUpdate' ? (
													<div className='w-1/4 overflow-hidden text-red-600'>
														{transaction.user
															? transaction.user
																	.firstName
															: 'no user provided'}{' '}
														{transaction.user
															? transaction.user
																	.lastName
															: ''}
													</div>
												) : (
													<div className='w-1/4 overflow-hidden '>
														{transaction.user
															? transaction.user
																	.firstName
															: 'no user provided'}{' '}
														{transaction.user
															? transaction.user
																	.lastName
															: ''}
													</div>
												)}
												{transaction.type ===
													'boxesIn' && (
													<div className='w-1/4 text-green-600 text-sm pr-2'>
														{transaction.boxCount}{' '}
														boxes returned by user
													</div>
												)}
												{transaction.type ===
													'boxesOut' && (
													<div className='w-1/4 text-orange-600 text-sm pr-2'>
														{transaction.boxCount}{' '}
														boxes given to user
													</div>
												)}
												{transaction.type ===
													'adminBalanceUpdate' && (
													<div className='w-1/4 text-red-600 text-sm pr-2 text-center'>
														{transaction.boxCount}{' '}
														boxes added by admin
													</div>
												)}
												{transaction.type ===
													'adminBalanceUpdate'}
												<div className='w-1/4 text-xs pl-2'>
													{transaction?.note}
												</div>
												{node.parent &&
													transaction.nodeInfo &&
													transaction.nodeInfo
														.name && (
														<div className='font-bold w-1/4 pl-2'>
															{
																transaction
																	.nodeInfo
																	.name
															}
														</div>
													)}
											</div>
										)
									})}
									<div className='flex flex-row'>
										<div
											className='mx-auto underline font-bold mr-6 cursor-pointer'
											onClick={() => {
												setPage(page - 1)
												setLoading(true)
											}}
										>
											Previous {paginationLimit}{' '}
											transactions
										</div>
										<div
											className='mx-auto underline font-bold cursor-pointer'
											onClick={() => {
												setPage(page + 1)
												setLoading(true)
											}}
										>
											Next {paginationLimit} transactions
										</div>
									</div>
								</div>
							)}

							{orders.length >= 0 && !user.franchiseeSlug && (
								<div className='px-10 my-6 shadow-light-grey rounded-xl py-2 w-full'>
									<div className='my-2 text-lg text-green-600'>
										Marketplace Orders
									</div>
									<div className='flex flex-row mb-2'>
										<div className='font-bold w-1/4'>
											Date
										</div>
										<div className='font-bold w-1/4'>
											User
										</div>
										<div className='font-bold w-1/4'>
											Subtotal
										</div>
										<div className='font-bold w-1/4'>
											Total
										</div>
									</div>
									{loading && (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									)}
									{orders.map((order, i) => {
										const orderDate = new Date(
											order.timestamp
										)
										const orderDateString =
											orderDate.toLocaleString(
												'default',
												{
													month: 'short',
													day: 'numeric',
													year: 'numeric',
													hour: '2-digit',
													minute: '2-digit',
												}
											)

										return (
											<div
												className='flex flex-row my-2 divide-y-2 divide-solid'
												key={i}
											>
												<div></div>
												<div className='w-1/4'>
													{orderDateString}
												</div>
												<div className='w-1/4 overflow-hidden'>
													{order.userInfo?.firstName}{' '}
													{order.userInfo?.lastName}
												</div>
												<div className='w-1/4 overflow-hidden'>
													${order.subtotal.toFixed(2)}
												</div>
												<div className='w-1/4 overflow-hidden'>
													${order.dzTotal.toFixed(2)}
												</div>
											</div>
										)
									})}
									<div className='flex flex-row'>
										<div
											className='mx-auto underline font-bold mr-6 cursor-pointer'
											onClick={() => {
												setOrderPage(orderPage - 1)
												setLoading(true)
											}}
										>
											Previous {paginationLimit} orders
										</div>
										<div
											className='mx-auto underline font-bold cursor-pointer'
											onClick={() => {
												setOrderPage(orderPage + 1)
												setLoading(true)
											}}
										>
											Next {paginationLimit} orders
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div>
					<Header />
					<div className='flex items-center h-screen'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
						<Footer />
					</div>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Transactions,
	Nodes,
	User,
	Orders,
	TransactionsCreate,
}) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	transactionResult: TransactionsCreate.transactionResult,
	nodes: Nodes.nodes,
	node: Nodes.node,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	updatingNode: Nodes.updatingNode,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	updateNodeError: Nodes.updateNodeError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateNode: (updatePayload) => dispatch(updateNode(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onCreateTransaction: (transactionPayload) =>
		dispatch(createTransaction(transactionPayload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantProfile)
