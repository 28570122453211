import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getOrders,
	getPaymentMethods,
	getNodeByAdminCodeOrSlug,
	updateNode,
	updateUser,
	getLead,
	alertSlack,
	resetNode,
	inviteMerchantUser,
	resetMerchant,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import ContactForm from '../../../components/RestaurantAdmin/ContactForm'
import ContactTable from '../../../components/RestaurantAdmin/ContactTable'
import { Button } from '../../../components/Elements'

const MerchantContacts = (state) => {
	const {
		user,
		onGetUser,
		onGetNodeBySlugAdmin,
		node,
		onUpdateUser,
		userError,
		onGetLead,
		lead,
		onAlertSlack,
		onUpdateNode,
		updatingNode,
		inviteSent,
		loadingMerchant,
		merchantError,
		onInviteMerchantUser,
		onResetMerchant,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [showSignUp, setShowSignUp] = useState(false)
	const [signUpUrl, setSignUpURL] = useState('')
	const [contacts, setContacts] = useState([])
	const [showAddContact, setShowAddContact] = useState(false)
	const [adminAccess, setAdminAccess] = useState(false)
	const [showSaveContacts, setShowSaveContacts] = useState(false)
	const { nodeSlug } = useParams()

	useEffect(() => {
		onGetUser()
		onResetMerchant()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	useEffect(() => {
		if (node && node.fromSignUpFlow) {
			onGetLead({ slug: nodeSlug })
		}
		if (node && node.contacts) {
			setContacts(node.contacts)
		}
	}, [node])

	useEffect(() => {
		if (lead && !lead.signUpComplete) {
			setShowSignUp(true)
			const pageNumber = lead.lastPage + 1
			setSignUpURL(`/restaurantpartners/sign-up/page-${pageNumber}`)
		}
	}, [lead])

	const changeTheMerchant = (selectedNodeSlug) => {
		onUpdateUser({
			restaurantAdmin: selectedNodeSlug,
		})
		navigate(`/restaurant-admin/account/${selectedNodeSlug}`)
	}

	const addContact = (newContact) => {
		onUpdateNode({
			nodeSlug: user.restaurantAdmin,
			update: {
				contacts: [
					...contacts,
					{
						...newContact,
						id: contacts.length + 1,
						adminAccess: adminAccess,
					},
				],
			},
		})
		setContacts([
			...contacts,
			{
				...newContact,
				id: contacts.length + 1,
				adminAccess: adminAccess,
			},
		])
		// check if exisitng user --> slack alert for add
		// else --> email invite with unique link to grant admin access
		if (adminAccess) {
			onInviteMerchantUser({
				newContact: newContact,
				nodeSlug: nodeSlug,
			})
		}
	}

	useEffect(() => {
		if (inviteSent) {
			onResetMerchant()
			onGetNodeBySlugAdmin(nodeSlug)
		}
	}, [inviteSent])

	const toggleAdminAccess = (newContact) => {
		setContacts(
			contacts.map((contact) =>
				contact.phone === newContact.phone
					? { ...contact, adminAccess: !contact.adminAccess }
					: contact
			)
		)
		setShowSaveContacts(true)
		// Add logic to add/remove admin access from user
		// Only user can update own user doc
		const updatedContact = contacts.find(
			(contact) => contact.phone === newContact.phone
		)
		if (updatedContact) {
			if (updatedContact.adminAccess) {
				// admin access needs to be revoked
				onAlertSlack({
					channel: '#eng-testing',
					text:
						'<@U05CHR1JENT>, Revoke admin access to ' +
						nodeSlug +
						' for ' +
						newContact.phone,
				})
			} else {
				// admin access needs to be granted
				// may need to invite user if when created admin access was not granted
				onInviteMerchantUser({
					newContact: newContact,
					nodeSlug: nodeSlug,
				})
			}
		}
	}

	const handleDeleteContact = (contactPhone) => {
		const confirmDelete = window.confirm(
			'Are you sure you want to delete this contact?'
		)

		if (confirmDelete) {
			onUpdateNode({
				nodeSlug: user.restaurantAdmin,
				update: {
					contacts: contacts.filter(
						(contact) => contact.phone !== contactPhone
					),
				},
			})
			setContacts((prevContacts) =>
				prevContacts.filter((contact) => contact.phone !== contactPhone)
			)
		} else {
			const checkbox = document.getElementById(`checkbox-${contactPhone}`)
			if (checkbox) {
				checkbox.checked = false
			}
		}
		// add logic to remove admin access from user
		// only user can update own user doc --> slack alert for remove
		onAlertSlack({
			channel: '#eng-testing',
			text:
				'<@U05CHR1JENT>, Revoke admin access to ' +
				nodeSlug +
				' for ' +
				contactPhone,
		})
	}

	const handleUpdateContacts = () => {
		onUpdateNode({
			nodeSlug: user.restaurantAdmin,
			update: {
				contacts: [...contacts],
			},
		})
		setShowSaveContacts(false)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		if (node && node.slug === user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/restaurant-admin/home')
						}}
					>
						&#8592; Go to Dashboard Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='min-h-screen w-full'>
						{node ? (
							<div>
								{user.restaurantAdminOtherLocations &&
								user.restaurantAdminOtherLocations.length >
									0 ? (
									<div className='flex flex-row justify-center'>
										<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
											{node.name}'s Account
										</h1>
										{showNodeChoices ? (
											<BsChevronUp
												onClick={() => {
													setShowNodeChoices(false)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										) : (
											<BsChevronDown
												onClick={() => {
													setShowNodeChoices(true)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										)}
									</div>
								) : (
									<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
										{node.name}'s Account
									</h1>
								)}
							</div>
						) : (
							<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
								DeliverZero Dashboard
							</h1>
						)}
						<div className='flex-col flex items-center'>
							{showNodeChoices && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									{user.restaurantAdminOtherLocations &&
									user.restaurantAdminOtherLocations.length >
										1 ? (
										user.restaurantAdminOtherLocations.map(
											(location) => (
												<div
													className='text-sm cursor-pointer my-1 hover:bg-gray-100'
													key={location.nodeSlug}
													onClick={() => {
														changeTheMerchant(
															location.nodeSlug
														)
														setShowNodeChoices(
															false
														)
													}}
												>
													{location.name}
												</div>
											)
										)
									) : user.restaurantAdminOtherLocations &&
									  user.restaurantAdminOtherLocations[0] ? (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											onClick={() => {
												changeTheMerchant(
													location.nodeSlug
												)
												setShowNodeChoices(false)
											}}
										>
											{
												user
													.restaurantAdminOtherLocations[0]
													.name
											}
										</div>
									) : (
										<div></div>
									)}
								</div>
							)}
						</div>

						{/* Contacts table */}
						<div className='w-[90%] sm:max-w-[1200px] overflow-auto scrollbar flex flex-col justify-center items-start mx-auto'>
							{contacts && contacts.length > 0 && (
								<>
									<h3 className='text-green-600 font-header mt-12 mb-3 text-xl w-full mx-auto'>
										Contacts
									</h3>
									<ContactTable
										contacts={contacts}
										toggleAdminAccess={toggleAdminAccess}
										handleDeleteContact={
											handleDeleteContact
										}
									/>
								</>
							)}
						</div>

						{/* Add contacts */}
						<div className='w-full flex flex-col justify-center items-center mb-10 mt-3'>
							{showAddContact && (
								<ContactForm
									addContact={addContact}
									setShowAddContact={setShowAddContact}
									allLocs={
										user.restaurantAdminOtherLocations
											? user.restaurantAdminOtherLocations.map(
													(location) => location.name
											  )
											: user.restaurantAdminOtherLocations ===
											  undefined
											? []
											: [
													user
														.restaurantAdminOtherLocations[0]
														.name,
											  ]
									}
									adminAccess={adminAccess}
									setAdminAccess={setAdminAccess}
									contacts={contacts}
								/>
							)}
							{(updatingNode || loadingMerchant) && (
								<div className='flex items-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{merchantError && (
								<div className='flex items-center text-red-400 text-center my-3'>
									An error occured, please refresh the page
									and try again.
								</div>
							)}
							{showSaveContacts && (
								<Button
									className='w-[300px] mx-auto my-1'
									size='sm'
									text='Save Updates'
									onClick={() => {
										handleUpdateContacts()
									}}
								/>
							)}
							<Button
								size='sm'
								className='my-3 w-[180px] px-10'
								onClick={() => {
									setShowAddContact(!showAddContact)
									onResetMerchant()
								}}
								text={
									!showAddContact
										? 'Add New Contact'
										: 'Hide Contact Form'
								}
							/>
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Transactions,
	User,
	AppInfo,
	Orders,
	Nodes,
	Leads,
	Merchant,
}) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	lead: Leads.lead,
	updatingNode: Nodes.updatingNode,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	updateNodeError: Nodes.updateNodeError,
	inviteSent: Merchant.invite,
	loadingMerchant: Merchant.loading,
	merchantError: Merchant.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetPaymentMethods: (payload) => dispatch(getPaymentMethods(payload)),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onUpdateUser: (payload) => dispatch(updateUser(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
	onAlertSlack: (payload) => dispatch(alertSlack(payload)),
	onUpdateNode: (payload) => dispatch(updateNode(payload)),
	onResetNode: () => dispatch(resetNode()),
	onInviteMerchantUser: (payload) => dispatch(inviteMerchantUser(payload)),
	onResetMerchant: () => dispatch(resetMerchant()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantContacts)
