import React, { useState } from 'react'
import { Header, Footer } from '../../../components'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {
	getAdminCustomers,
	getAllCustomers,
	getUser,
	generatePromo,
	getPromoIds,
	getNodes,
} from '../../../store/actions'
import { FormGroup } from '../../../components/Elements'
import {
	Input,
	Button,
	RadioGroup,
	CheckboxGroup,
} from '../../../components/Elements'
import { BsPercent, BsCurrencyDollar } from 'react-icons/bs'
import { BounceLoader } from 'react-spinners'
import ReactConfetti from 'react-confetti'
import { GiPartyPopper } from 'react-icons/gi'
import { NodeTypes } from '../../../model/nodes'

const PromoCreate = (props) => {
	const {
		onGetCustomers,
		onGeneratePromo,
		promo,
		loadingCustomers,
		customers,
		user,
		onGetUser,
		customersCount,
		onGetAllCustomers,
		loadingAllCustomers,
		allCustomers,
		promoCreated,
		promoError,
		loading,
		onGetPromoIds,
		promoIds,
		loadingIds,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
	} = props
	let navigate = useNavigate()
	const paginationLimit = 50

	const [error, setError] = useState(false)
	const [showUserId, setShowUserId] = useState(false)
	const [showLimit, setShowLimit] = useState(false)
	const [customersPage, setCustomersPage] = useState(1)
	// const [showExpiration, setShowExpiration] = useState(false)
	const [noExpirationChecked, setNoExpirationChecked] = useState(true)
	const [showCodeInput, setShowCodeInput] = useState(true)
	const [showSuccess, setShowSuccess] = useState(false)
	// const [showGenerateOptions, setShowGenerateOptions] = useState(true)
	const [specificNode, setSpecificNode] = useState(false)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [typedCustomerSearch, setTypedCustomerSearch] = useState('')
	const [customerSearchResults, setCustomerSearchResults] = useState([])
	const [showCustomerSearchResults, setShowCustomerSearchResults] =
		useState(false)
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [showNodeChoicesDropOff, setShowNodeChoicesDropOff] = useState(false)
	const [assignedNode, setAssignedNode] = useState(null)
	const [filter, setFilter] = useState({})
	const [form, setForm] = useState({
		promoId: '',
		codeType: 'one-time-overall',
		amount: 0,
		promoCodes: [],
		startDate: null,
		endDate: null,
		discountType: '',
		assignedUserPhone: '',
		maxUses: '1',
		codeCreationMethod: 'providedCode',
		assignedNode: null,
	})
	const bounceLoaderColor = '#507f74'

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		// console.log(filter)
		onGetNodes([filter])
	}, [filter])

	useEffect(() => {
		if (!showUserId) {
			setForm({ ...form, assignedUserPhone: '' })
		}
	}, [showUserId])

	// FORM CONTROL
	const usageTypeController = (e) => {
		if (e === 'one-time-one-user' || e === 'unlimited-for-specific-user') {
			setShowUserId(true)
			setShowLimit(false)
		} else if (
			e === 'one-time-per-user' ||
			e === 'one-time-overall' ||
			e === 'unlimited-overall'
		) {
			setShowUserId(false)
			setShowLimit(false)
		} else if (e === 'limited-overall') {
			setShowUserId(false)
			setShowLimit(true)
		}
		if (e === 'providedCode') {
			setShowCodeInput(true)
		} else if (e === 'randomCode' || e === 'unique-code-per-user') {
			setShowCodeInput(false)
		}
	}

	// CUSTOMER SEARCH AUTO COMPLETE
	const getCustomers = (page) => {
		// console.log('here')
		onGetCustomers({
			filter: {},
			options: {
				sort: {
					lastUse: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	useEffect(() => {
		if (typedCustomerSearch !== '') {
			const closestMatches = allCustomers.filter(
				(txt) =>
					txt.email.toLowerCase().indexOf(typedCustomerSearch) !==
						-1 ||
					txt.phone.toLowerCase().indexOf(typedCustomerSearch) !==
						-1 ||
					txt.firstName.toLowerCase().indexOf(typedCustomerSearch) !==
						-1 ||
					txt.lastName.toLowerCase().indexOf(typedCustomerSearch) !==
						-1
			)
			setCustomerSearchResults(closestMatches.slice(0, 5))
		} else {
			setCustomerSearchResults([])
		}
	}, [typedCustomerSearch])

	const customerPaginate = () => {
		getCustomers(customersPage)
	}

	useEffect(() => {
		customerPaginate()
	}, [customersPage])

	// DATE
	const formatDate = (date) => {
		const d = date ? new Date(date) : new Date(),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear()
		return [year, month, day].join('-')
	}

	useEffect(() => {
		setStartDate(formatDate())
		setForm({ ...form, startDate: formatDate(), dateCreated: formatDate() })
		onGetPromoIds()
	}, [])

	useEffect(() => {
		if (promoIds.length < 1) {
			onGetPromoIds()
		}
	}, [promoIds])

	useEffect(() => {
		setError(false)
	}, [form])

	useEffect(() => {
		if (endDate) {
			setNoExpirationChecked(false)
		} else {
			setNoExpirationChecked(true)
		}
	}, [endDate])

	// SUBMIT FORM
	const handleFormSubmit = (e) => {
		e.preventDefault()
		validateForm()
		// console.log(form)
		// if (!error) {
		// 	console.log("error", error)
		// 	onGeneratePromo(form)
		// }
	}

	const validateForm = async () => {
		setError(false)
		const yesterday = new Date()
		yesterday.setDate(yesterday.getDate() - 1)
		const startDateValue = new Date(startDate)
		if (form.promoId === '') {
			setError('Please input a promo name/description')
		} else if (promoIds.length < 1 || promoIds.includes(form.promoId)) {
			setError('Promo name must be unique, please use a different name')
		} else if (
			form.codeCreationMethod === 'providedCode' &&
			form.promoCodes.length < 1
		) {
			setError('Please enter a custom promo code')
		} else if (form.discountType === '') {
			setError('Please select discount type ($ or %)')
		} else if (
			form.codeType === 'one-time-one-user' ||
			form.codeType === 'unlimited-for-specific-user'
		) {
			const phoneNumberPattern = /^\+1\d{10}$/
			if (
				form.assignedUserPhone === '' ||
				!phoneNumberPattern.test(form.assignedUserPhone)
			) {
				setError(
					'Invalid user phone number - Please select a valid user from the search dropdown'
				)
			}
		} else if (form.amount <= 0) {
			setError('Amount must be > 0')
		} else if (
			form.codeCreationMethod === 'providedCode' &&
			form.promoCodes.length < 1
		) {
			setError('Please input a valid promo code')
		} else if (form.discountType === 'percent' && form.amount > 100) {
			setError('Discount percentage cannot be greater than 100%')
		} else if (specificNode && !form.assignedNode) {
			setError('Please select a valid node')
		} else if (startDateValue < yesterday) {
			setError('Start date must be in the future')
		} else if (endDate) {
			const endDateValue = new Date(endDate)
			if (startDateValue > endDateValue) {
				setError('End date must be after start date')
			}
		} else {
			onGeneratePromo(form)
		}
	}

	useEffect(() => {
		if (promoCreated) {
			setShowSuccess(true)
		}
	}, [promoCreated])

	if (loading) {
		return (
			<div className='flex justify-center items-center h-full'>
				<BounceLoader color={bounceLoaderColor} />
			</div>
		)
	} else if (showSuccess) {
		return (
			<div className='w-full h-screen-no-header flex flex-col justify-center items-center'>
				<ReactConfetti
					className='w-full'
					colors={['#f38b74']}
				/>
				<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
					Promo Created!
				</h1>
				<GiPartyPopper className='text-orange-600 text-[250px]' />
				<Button
					onClick={() => window.location.reload()}
					className='px-10 w-[260px] mt-5 shadow-light-grey'
					text='Create Another'
				/>
				<Button
					link='/admin/home'
					className='px-10 w-[260px] mt-5 shadow-light-grey'
					text='Admin Home'
				/>
			</div>
		)
	} else {
		return (
			<>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='flex flex-col justify-center items-center h-full p-8 '>
					<h1 className='font-header text-5xl text-green-600 mb-20'>
						Create a Promo
					</h1>
					<div className='flex justify-center items-center w-5/6 min-w-[375px] max-w-[900px] p-24 rounded-xl shadow-light-grey'>
						<FormGroup
							horizontal='true'
							className='w-full flex justify-center items-center'
						>
							<Input
								type='text'
								label='Promo Name (for internal use only)'
								name='promoId'
								onChange={(e) => {
									setForm({
										...form,
										promoId: e.target.value,
									})
								}}
								error=''
								className='bg-white border-2 border-gray-300 mb-6 w-full h-14'
							/>
							<RadioGroup
								label='Promo Code Type'
								labelClassName='font-bold'
								name='codeType'
								onChange={(e) => {
									setForm({ ...form, codeType: e })
									usageTypeController(e)
								}}
								radios={[
									{
										label: 'One time use (1 code that can be used by any user)',
										checked: true,
										value: 'one-time-overall',
									},
									{
										label: 'One time use (1 code that can be used by 1 specific user)',
										value: 'one-time-one-user',
									},
									{
										label: 'One time use (all users)',
										value: 'one-time-per-user',
									},
									{
										label: 'Limited use (1 code that can be used a specific # of times by any user)',
										value: 'limited-overall',
									},
									{
										label: 'Unlimited use (any user)',
										value: 'unlimited-overall',
									},
									{
										label: 'Unlimited use (1 specific user)',
										value: 'unlimited-for-specific-user',
									},
								]}
							/>
							{showUserId && (
								<Input
									type='text'
									label='User Phone'
									name='assignedUserPhone'
									value={selectedCustomer?.phone}
									onChange={(e) => {
										setTypedCustomerSearch(
											e.target.value.toLowerCase()
										)
										setShowCustomerSearchResults(true)
										setSelectedCustomer(null)
									}}
									error=''
									className='bg-white border-2 border-gray-300 mb-6 w-full h-14'
								/>
							)}
							{showCustomerSearchResults &&
								customerSearchResults.map((customer, i) => {
									return (
										<div
											className='flex flex-row my-1'
											key={i}
										>
											<div className='w-[350px] overflow-x-hidden'>
												{customer.firstName}
												{` `}
												{customer.lastName}
												{` `}
												{customer.email}
												{` `}
												{customer.phone}
											</div>
											<Button
												className='items-center h-6 text-center'
												size='xs'
												text='Select'
												onClick={() => {
													setForm({
														...form,
														assignedUserPhone:
															customer.phone,
													})
													setSelectedCustomer(
														customer
													)
													// console.log(customer)
													setShowCustomerSearchResults(
														false
													)
												}}
											/>
										</div>
									)
								})}
							{showLimit && (
								<div>
									<Input
										type='number'
										label='Limit'
										min='1'
										step='1'
										name='maxUses'
										onChange={(e) => {
											setForm({
												...form,
												maxUses: e.target.value,
											})
										}}
										className='bg-white border-2 border-gray-300 mb-6 w-full h-14'
									/>
								</div>
							)}
							<RadioGroup
								label='Promo Code'
								name='codeCreationMethod'
								onChange={(e) => {
									setForm({ ...form, codeCreationMethod: e })
									usageTypeController(e)
								}}
								radios={[
									{
										label: 'I have a code in mind',
										checked: true,
										value: 'providedCode',
									},
									{
										label: 'Generate a random code',
										checked: false,
										value: 'randomCode',
									},
								]}
							/>
							{showCodeInput && (
								<Input
									type='text'
									label='Promo Code'
									name='promoCode'
									onChange={(e) => {
										setForm({
											...form,
											promoCodes: [e.target.value],
										})
									}}
									error=''
									className='bg-white border-2 border-gray-300 mb-6 w-full h-14'
								/>
							)}
							<RadioGroup
								label='Promo Discount Type'
								labelClassName='font-bold'
								name='discountType'
								onChange={(e) => {
									setForm({ ...form, discountType: e })
								}}
								radios={[
									{ label: 'Percentage', value: 'percent' },
									{ label: 'Cash', value: 'cash' },
								]}
							/>
							<div className='relative'>
								<Input
									type='number'
									min='1'
									label='Amount'
									name='amount'
									onChange={(e) => {
										setForm({
											...form,
											amount: e.target.value,
										})
									}}
									error=''
									className='bg-white border-2 border-gray-300 mb-6 lg:w-full h-14'
								/>
								{form.discountType === 'percent' ? (
									<BsPercent className='text-2xl text-green-600 absolute right-8 top-11' />
								) : (
									<BsCurrencyDollar className='text-2xl text-green-600 absolute right-8 top-11' />
								)}
							</div>
							<CheckboxGroup
								name='Specific node'
								onChange={(e) => {
									const checked = e.target.checked
									setSpecificNode(checked)
									if (!checked) {
										setForm({
											...form,
											assignedNode: null,
										})
									}
								}}
								label='Valid for specific node only'
								className=''
								checked={specificNode}
							/>
							{specificNode && (
								<>
									<div className='w-4/5 max-w-[600px] mb-4'>
										<Input
											placeholder='Search and select dropoff locations'
											className=''
											value={assignedNode?.name}
											name='searchInput'
											id='searchInput'
											onChange={(e) => {
												setShowNodeChoicesDropOff(true)
												setFilter({
													$or: [
														{
															name: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
														{
															slug: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
													],
												})
											}}
										/>
									</div>
									<div className='flex-col flex items-center'>
										{showNodeChoicesDropOff && (
											<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
												<div
													className='cursor-pointer text-green-600'
													onClick={() => {
														setShowNodeChoicesDropOff(
															false
														)
													}}
												>
													{' '}
													Close X
												</div>
												{nodes.map((node) => (
													<div
														className='text-sm cursor-pointer my-1 hover:bg-gray-100'
														key={node.slug}
														onClick={() => {
															setAssignedNode(
																node
															)
															setShowNodeChoicesDropOff(
																false
															)
															setForm({
																...form,
																assignedNode:
																	node.slug,
															})
														}}
													>
														{node.name}
													</div>
												))}
											</div>
										)}
									</div>
								</>
							)}
							<div className='flex flex-col lg:flex-row justify-between items-between w-full mb-6 h-full'>
								<div className='w-full lg:mb-0 mb-6 mr-4'>
									<Input
										type='date'
										label='Start'
										name='startDate'
										onChange={(e) => {
											setStartDate(e.target.value)
											setForm({
												...form,
												startDate: e.target.value,
											})
										}}
										error=''
										className='bg-white border-2 border-gray-300 w-full h-14 -mb-6'
										defaultValue={startDate}
									/>
								</div>
								<div className='w-full lg:mb-0 mb-6'>
									<Input
										type='date'
										label='End'
										name='endDate'
										defaultValue='endDate'
										onChange={(e) => {
											setEndDate(e.target.value)
											setForm({
												...form,
												endDate: e.target.value,
											})
										}}
										error=''
										className='bg-white border-2 border-gray-300 w-full h-14 -mb-6'
									/>
								</div>
								<div className=''>
									<CheckboxGroup
										name='noExpiration'
										onChange={(e) => {
											if (e.target.checked) {
												setEndDate(null)
												setForm({
													...form,
													endDate: null,
												})
											}
											setNoExpirationChecked(
												e.target.checked
											)
										}}
										label='No expiration'
										className=''
										checked={noExpirationChecked}
									/>
								</div>
							</div>
							{error ? (
								<>
									<div className='text-red-400 text-center w-full'>
										{error}
									</div>
									<Button
										color='disabled'
										full
										disabled
										text='Submit'
										className='mb-3 w-full'
										onClick={(e) => handleFormSubmit(e)}
									/>
								</>
							) : (
								<Button
									color='green'
									full
									text='Submit'
									className='mb-3 w-full'
									onClick={(e) => handleFormSubmit(e)}
								/>
							)}
						</FormGroup>
					</div>
				</div>
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ Admin, User, Promos, Nodes }) => ({
	customers: Admin.customers,
	customersCount: Admin.customersCount,
	loadingCustomers: Admin.loadingCustomers,
	user: User.user,
	allCustomers: Admin.allCustomers,
	loadingAllCustomers: Admin.loadingAllCustomers,
	promoCreated: Promos.promoCreated,
	promoError: Promos.promoError,
	loading: Promos.loading,
	promoIds: Promos.promoIds,
	loadingIds: Promos.loadingIds,
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetCustomers: (payload) => dispatch(getAdminCustomers(payload)),
	onGetAllCustomers: (payload) => dispatch(getAllCustomers(payload)),
	onGetUser: () => dispatch(getUser()),
	onGeneratePromo: (payload) => dispatch(generatePromo(payload)),
	onGetPromoIds: () => dispatch(getPromoIds()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PromoCreate)
