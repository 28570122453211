// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import {
	getUser,
	registerUser,
	socialLoginGoogle,
	socialRegisterGoogle,
	getNodeByAdminCodeOrSlug,
	getOfficeByAdminCode,
} from '../../store/actions'
import Header from '../../components/Header'
import RegisterCard from '../../components/Authentication/RegisterCard'
import { Footer } from '../../components'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

const Register = (props) => {
	const {
		onGetNodeBySlugAdmin,
		node,
		loadingNode,
		onGetOfficeByAdminOrSlug,
		loadingOffice,
		office,
	} = props
	const navigate = useNavigate()
	const location = useLocation()
	const { nodeSlug, officeSlug, adminPhone } = useParams()
	const [adminContact, setAdminContact] = useState(false)

	const RandomizedRedirectRoute = () => {
		const randomValue = Math.random()

		// Redirect 50% of users to '/sign-up-b'
		if (randomValue < 0.25 && !(nodeSlug || adminPhone)) {
			navigate('/sign-up')
		}
	}

	useEffect(() => {
		const currentPath = location.pathname
		if (nodeSlug && adminPhone) {
			onGetNodeBySlugAdmin(nodeSlug)
		} else if (officeSlug && adminPhone) {
			onGetOfficeByAdminOrSlug(officeSlug)
		} else if (!currentPath.includes('/membership')) {
			RandomizedRedirectRoute()
		}
	}, [nodeSlug, officeSlug, adminPhone, location?.pathname])

	useEffect(() => {
		if (adminPhone) {
			if (node && node.contacts) {
				const existingContact = node.contacts.find(
					(contact) => contact.phone === adminPhone
				)
				if (existingContact && existingContact.adminAccess) {
					setAdminContact(true)
				} else {
					setAdminContact(false)
					if (!nodeSlug && !adminPhone) {
						navigate('/sign-up')
					}
				}
			} else if (office && office.invitedAdmins) {
				const existingContact = office.invitedAdmins.find(
					(admin) => admin === adminPhone
				)
				if (existingContact) {
					setAdminContact(true)
				} else {
					setAdminContact(false)
					if (!officeSlug && !adminPhone) {
						navigate('/sign-up')
					}
				}
			}
		}
	}, [node, office, node?.contacts, office?.invitedAdmins])

	return (
		<>
			<Header />
			<div className='h-full w-screen flex'>
				<div className='w-full min-h-[630px] flex flex-col items-center bg-gradient-to-tr to-yellow-400 from-yellow-600 lg:w-1/2 lg:flex'>
					<div className='h-fit my-6 md:mt-20'>
						<RegisterCard
							nodeSlug={nodeSlug}
							officeSlug={officeSlug}
							adminContact={adminContact}
						/>
					</div>
				</div>
				<div className='min-h-[630px] max-h-[800px] w-full hidden lg:overflow-hidden lg:w-1/2 lg:flex'>
					<img
						className='overflow-hidden object-cover h-full w-full'
						src='https://i.imgur.com/31JfaBe.jpg'
						alt='DeliverZero Reusable Containers'
					/>
				</div>
			</div>
			<Footer />
		</>
	)
}

const mapStateToProps = ({
	User,
	Account,
	Path,
	Login,
	Nodes,
	OfficeOrdering,
}) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	registrationLoading: Account.loading,
	registrationAttemptStatus: Account.registrationAttemptStatus,
	userLoading: User.loading,
	registrationError: Account.registrationError,
	path: Path.path,
	loginLoading: Login.loading,
	loginError: Login.error,
	loginAttemptStatus: Login.loginAttemptStatus,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	office: OfficeOrdering.office,
	loadingOffice: OfficeOrdering.office,
})

const mapDispatchToProps = (dispatch) => ({
	onRegisterUser: (user) => dispatch(registerUser(user)),
	onGoogleLogin: (res) => dispatch(socialLoginGoogle(res)),
	onGoogleRegister: (payload) => dispatch(socialRegisterGoogle(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onGetOfficeByAdminOrSlug: (payload) =>
		dispatch(getOfficeByAdminCode(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
