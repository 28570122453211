import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect } from 'react'

import { Footer, Header } from '../../components'
import RestaurantCreditCardFormPhone from './RestaurantCreditCardFormPhone'
import { getUserByUserIdPhone } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import { BounceLoaderCentered } from '../../components/Elements'
import { useNavigate, useParams } from 'react-router-dom'

// if merchant visits this page & is not logged in
const RestaurantCreditCardPagePhone = (state) => {
	const { user, loadingUser, onGetUserByUserIdPhone } = state
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const { userId, phone } = useParams()
	let navigate = useNavigate()

	useEffect(() => {
		if (phone && userId) {
			onGetUserByUserIdPhone({
				phone: phone,
				userId: userId,
			})
		}
	}, [phone, userId])

	useEffect(() => {
		if (!user || !user.phone) {
			onGetUserByUserIdPhone({
				phone: phone,
				userId: userId,
			})
		}
	}, [loadingUser])

	// console.log(user)
	// console.log(user?.restaurantAdmin)

	if (user && user.userId) {
		if (user.restaurantAdmin) {
			return (
				<>
					<Header />
					<div className='flex flex-col items-center text-center h-screen-no-header w-screen px-4 py-12'>
						<h1 className='font-header text-3xl md:text-5xl my-8 text-green-600'>
							Credit/Debit Card Authorization Form
						</h1>
						<p className='w-1/3 text-center min-w-[300px] mb-8 md:text-base'>
							Enter in your credit or debit card info below to set
							up and consent to automatic billing for monthly
							container usage.
						</p>
						<div className='w-full flex flex-col justify-center items-center mb-12'>
							<Elements stripe={stripePromise}>
								<RestaurantCreditCardFormPhone
									nodeSlug={user.restaurantAdmin}
								/>
							</Elements>
						</div>
					</div>
					<Footer />
				</>
			)
		} else {
			navigate('/')
		}
	} else {
		return (
			<>
				<Header />
				<BounceLoaderCentered container='page' />
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUserByUserIdPhone: (payload) =>
		dispatch(getUserByUserIdPhone(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantCreditCardPagePhone)
