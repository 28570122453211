import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser } from '../../../../store/actions'
import DropoffWidgetUberdirect from '../../../../components/Admin/DropoffWidgetUberdirect'
import { Footer, Header } from '../../../../components'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const DropoffUberdirect = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		onGetUser,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div className='h-full w-full'>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='px-12'>
						<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
							<DropoffWidgetUberdirect />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Logistics }) => ({
	user: User.user,
	loadingUser: User.loading,
	loadingStops: Logistics.loadingStops,
	stopsError: Logistics.error,
	stopsSuccess: Logistics.success,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropoffUberdirect)
