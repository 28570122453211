import {
	SMS_VERIFY_SEND_PHONE,
	SMS_VERIFY_USER_RESPONSE,
	SMS_VERIFY_SEND_PHONE_SUCCESS,
	SMS_VERIFY_SUCCESSFUL,
	SMS_VERIFY_FAILED,
} from './actionTypes'

export const smsVerifySendPhone = (userPhone) => {
	return {
		type: SMS_VERIFY_SEND_PHONE,
		payload: userPhone,
	}
}

export const smsVerifySendPhoneSuccess = (smsVerifyResponse) => {
	return {
		type: SMS_VERIFY_SEND_PHONE_SUCCESS,
		payload: smsVerifyResponse,
	}
}

export const smsVerifyPhoneUserResponse = (userResponse) => {
	return {
		type: SMS_VERIFY_USER_RESPONSE,
		payload: userResponse,
	}
}

export const smsVerifySuccessful = (successResponse) => {
	return {
		type: SMS_VERIFY_SUCCESSFUL,
		payload: successResponse,
	}
}

export const smsVerifyFailed = (smsVerifyError) => {
	return {
		type: SMS_VERIFY_FAILED,
		payload: smsVerifyError,
	}
}
