import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser } from '../../store/actions'
import { Footer, Header } from '../../components'
import { BounceLoaderCentered, Button } from '../../components/Elements'
import { realm } from '../../helpers/realm'
import { deleteUser } from '../../model/auth'
import { useNavigate } from 'react-router-dom'

const ManageAccount = (state) => {
	const { user, onGetUser } = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const [showDeleteWarning, setShowDeleteWarning] = useState(false)
	const [deletePermanently, setDeletePermanently] = useState(false)

	let navigate = useNavigate()

	const openSupport = () => {
		window.Intercom('show')
	}

	const handleDeleteAccount = () => {
		setShowDeleteWarning(true)
	}

	useEffect(() => {
		if (deletePermanently) {
			if (realm.currentUser) {
				deleteUser(user.userId).then(() => {
					realm.deleteUser(realm.currentUser).then(() => {
						navigate('/')
					})
				})
			}
		}
	}, [deletePermanently])

	if (!user) return <BounceLoaderCentered container='screen' />

	return (
		<div className='h-auto w-screen '>
			<Header />
			<div className='flex flex-col items-center justify-between h-auto min-h-screen w-auto bg-white px-6 mt-10'>
				<div className='cardLessMargin max-h-[500px] sm:w-[500px] md:m-4 overflow-y-auto scrollbar'>
					<h3 className='block font-header text-center text-2xl sm:text-3xl text-green-400'>
						Manage Account
					</h3>
					<div className='flex flex-col items-center justify-center mt-8'>
						<Button
							className='px-10 w-min'
							size='sm'
							onClick={openSupport}
							text='Contact Support'
						></Button>
						<div
							className='px-10 mt-10 text-red-400'
							onClick={handleDeleteAccount}
						>
							Delete Account
						</div>
						{showDeleteWarning && (
							<div className='px-8 mt-3 text-sm pb-20 text-center'>
								<div>
									Are you sure you want to delete your
									account? This action cannot be undone.
								</div>
								<div className='flex flex-row items-center justify-center mt-2'>
									<div
										className='cursor-pointer'
										onClick={() => {
											setDeletePermanently(true)
										}}
									>
										Yes
									</div>
									<div
										className='ml-8 cursor-pointer'
										onClick={() => {
											window.location.reload()
										}}
									>
										No
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount)
