import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getUserBoxCount,
	getTransactions,
	getAppInfo,
	getOrders,
	cancelFirstDeliveryPickup,
	getPickupsByPhone,
} from '../../store/actions'
import {
	Footer,
	Header,
	EnvironmentalImpactCard,
	ProfileInfoCard,
	ContainerCountCard,
	UsageHistoryCard,
	OrderHistoryCard,
	RewardPointsCard,
} from '../../components'
import MembershipCard from '../../components/UserProfile/MembershipCard'
import { BounceLoaderCentered, Button } from '../../components/Elements'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useNavigate } from 'react-router-dom'

const Account = (state) => {
	const {
		transactions,
		transactionsCount,
		loadingTransactions,
		onGetTransactions,
		onGetOrders,
		loadingOrders,
		orders,
		ordersCount,
		user,
		onGetUser,
		userBoxCount,
		userUpdateCompleted,
		loadingUserBoxCount,
		boxCountError,
		onGetUserBoxCount,
		onGetAppInfo,
		appInfo,
		onCancelFirstDelivery,
		cancelledFirstDelivery,
		cancelingFirstDelivery,
		pickupLoading,
		pickupSuccess,
		pickupError,
		pendingPickups,
		onGetPickups,
		cancelledRequest,
	} = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const paginationLimit = 5
	const [transactionsPage, setTransactionsPage] = useState(1)
	const [orderPage, setOrderPage] = useState(1)
	const [withPickups, setWithPickups] = useState([])
	let navigate = useNavigate()

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

	useEffect(() => {
		if (user && user.phone) {
			getTransactions(transactionsPage)
			getOrders(orderPage)
			onGetUserBoxCount(user.phone)
			onGetPickups({ phone: user.phone })
		}
	}, [user, user?.boxCount, userUpdateCompleted])

	useEffect(() => {
		if (cancelledRequest && user && user.phone) {
			getTransactions(transactionsPage)
			getOrders(orderPage)
			onGetUserBoxCount(user.phone)
			onGetPickups({ phone: user.phone })
		}
	}, [cancelledRequest])

	useEffect(() => {
		if (user && user.phone) {
			getTransactions(transactionsPage)
		}
	}, [transactionsPage])

	useEffect(() => {
		if (pendingPickups && transactions) {
			if (
				transactions &&
				transactions.length > 0 &&
				pendingPickups &&
				pendingPickups.length > 0
			) {
				setWithPickups([...pendingPickups, ...transactions])
			} else if (transactions && transactions.length > 0) {
				setWithPickups([...transactions])
			} else if (pendingPickups && pendingPickups.length > 0) {
				setWithPickups([...pendingPickups])
			}
		}
	}, [transactions, pendingPickups])

	useEffect(() => {
		if (user && user.phone) {
			getOrders(orderPage)
		}
	}, [orderPage])

	useEffect(() => {
		console.log('')
	}, [withPickups])

	useEffect(() => {
		if (cancelledFirstDelivery) {
			onGetUser()
		}
	}, [cancelledFirstDelivery])

	useEffect(() => {
		onGetAppInfo()
	}, [])

	const getTransactions = (page) => {
		onGetTransactions({
			filter: {
				'user.phone': user.phone,
				type: { $ne: 'adminBalanceUpdate' },
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: page * paginationLimit,
				skip: 0,
			},
		})
	}

	const getOrders = (page) => {
		onGetOrders({
			filter: {
				'customer.phone': user.phone,
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: page * paginationLimit,
				skip: 0,
			},
		})
	}

	// console.log(transactions)
	if (!user) return <BounceLoaderCentered container='screen' />

	return (
		<div className='h-auto w-screen '>
			<Header />
			<div className='flex flex-col items-center justify-between h-auto min-h-screen w-auto bg-white px-6 '>
				<h1 className='text-3xl md:text-5xl font-header text-green-600 text-center w-full mb-4 mt-12'>
					My Account
				</h1>
				{user.officeSlug && (
					<Button
						color='green'
						text='Go to Office Dashboard'
						className='mb-6 w-[200px] mx-auto px-10'
						size='sm'
						onClick={() => {
							navigate('/office-ordering/home')
						}}
					/>
				)}

				<div className='flex flex-col justify-center items-center container w-full h-auto'>
					<div className='w-full flex flex-col md:flex-row h-auto items-start justify-center'>
						<div className='w-[80%] sm:w-[350px] mx-auto h-auto mb-4 md:m-4'>
							{userBoxCount ? (
								userBoxCount.currentPackagingOut < 0 ? (
									<ContainerCountCard boxCount={0} />
								) : (
									<ContainerCountCard
										boxCount={
											userBoxCount.currentPackagingOut
										}
									/>
								)
							) : (
								<BounceLoaderCentered />
							)}
						</div>
						<div className='w-[80%] sm:w-[350px] mx-auto h-auto mb-4 md:m-4'>
							<RewardPointsCard
								rewardPoints={user.rewardPoints}
							/>
						</div>
					</div>

					<div className='flex flex-col justify-center items-center container sm:w-[730px] h-auto mx-auto'>
						<div className='w-full md:m-4'>
							{userBoxCount ? (
								<EnvironmentalImpactCard
									packagingPrevented={
										userBoxCount.totalPackagingPrevented
									}
									environmentalImpact={
										appInfo.environmentalImpact
									}
								/>
							) : (
								<BounceLoaderCentered />
							)}
						</div>
					</div>

					<div className='w-full flex flex-col h-auto items-start justify-center'>
						<div className='w-[90%] mx-auto my-4 sm:w-[730px]'>
							<Elements stripe={stripePromise}>
								<ProfileInfoCard user={user} />
							</Elements>
						</div>
						<div className='w-[90%] mx-auto my-4 sm:w-[730px]'>
							<MembershipCard
								user={user}
								appInfo={appInfo}
							/>
						</div>
						<div className='w-[90%] sm:w-[730px] mx-auto my-4 flex sm:flex-row flex-col'>
							<div className='mb-8 w-full sm:w-[350px] mr-7'>
								<UsageHistoryCard
									loadingTransactions={loadingTransactions}
									transactions={withPickups}
									appInfo={appInfo}
									onCancelFirstDelivery={
										onCancelFirstDelivery
									}
									cancelingFirstDelivery={
										cancelingFirstDelivery
									}
									transactionPage={transactionsPage}
									setTransactionPage={setTransactionsPage}
									paginationLimit={paginationLimit}
									transactionsCount={transactionsCount}
								/>
							</div>
							<div className='mb-8 w-full sm:w-[350px]'>
								<OrderHistoryCard
									loadingOrders={loadingOrders}
									orders={orders}
									orderPage={orderPage}
									setOrderPage={setOrderPage}
									paginationLimit={paginationLimit}
									ordersCount={ordersCount}
								/>
							</div>
						</div>
						<div className='w-[90%] sm:w-[730px] mx-auto my-4 flex sm:flex-row flex-col'>
							<Button
								color='green'
								text='Pay for Late Containers'
								className='mb-6 w-[200px] mx-auto px-10'
								size='sm'
								onClick={() => {
									navigate('/late-payments')
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

const mapStateToProps = ({
	Transactions,
	User,
	AppInfo,
	Orders,
	ReverseLogistics,
	Logistics,
	Pickups,
}) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	user: User.user,
	userBoxCount: User.userBoxCount,
	userUpdateCompleted: User.userUpdateCompleted,
	loadingUserBoxCount: User.loadingUserBoxCount,
	boxCountError: User.boxCountError,
	hasValidCard: User.hasValidCard,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	cancelledFirstDelivery: ReverseLogistics.cancelledFirstDelivery,
	cancelingFirstDeliveryError: ReverseLogistics.cancelingFirstDeliveryError,
	cancelingFirstDelivery: ReverseLogistics.cancelingFirstDelivery,
	pickupLoading: Pickups.pickupLoading,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pendingPickups: Pickups.pendingPickups,
	cancelledRequest: Logistics.cancelled,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetUserBoxCount: (payload) => dispatch(getUserBoxCount(payload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onCancelFirstDelivery: (payload) =>
		dispatch(cancelFirstDeliveryPickup(payload)),
	onGetPickups: (payload) => dispatch(getPickupsByPhone(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)
