import {
	REGISTER_USER,
	REGISTER_USER_SUCCESSFUL,
	REGISTER_USER_FAILED,
} from './actionTypes'

const initialState = {
	registrationError: null,
	message: null,
	loading: false,
	user: null,
	registrationAttemptStatus: null,
}

const account = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER_USER:
			state = {
				...state,
				loading: true,
				registrationError: null,
				registrationAttemptStatus: 'attempting',
			}
			break
		case REGISTER_USER_SUCCESSFUL:
			state = {
				...state,
				loading: false,
				user: action.payload,
				registrationError: null,
				registrationAttemptStatus: 'completed',
			}
			break
		case REGISTER_USER_FAILED:
			state = {
				...state,
				user: null,
				loading: false,
				registrationError: action.payload,
				registrationAttemptStatus: 'completed',
			}
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default account
