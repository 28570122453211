import React from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import RestaurantForm from './RestaurantForm'

function RestaurantPartnersGetInfo() {
	return (
		<>
			<Header />
			<div
				className='flex flex-col items-center justify-center w-full'
				id='join-us'
			>
				<h1 className='font-header text-center text-green-600 text-3.5xl sm:text-5xl -mb-10 mt-10 w-full'>
					Learn More:
				</h1>
				<div className='font-sans text-[14px] text-green-600 font-header font-semibold mb-5'>
					<RestaurantForm />
				</div>
			</div>
			<Footer />
		</>
	)
}

export default RestaurantPartnersGetInfo
