import React, { useEffect, useState } from 'react'
import { getUser } from '../../store/actions'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Input, BounceLoaderCentered, FormGroup } from '../Elements'
import { MdClear } from 'react-icons/md'

const MerchantAccountForm = (props) => {
	const {
		inputBackgroundColor = 'white',
		onUpdateNode,
		nodeSlug,
		name,
		email,
		phone,
		address,
		city,
		state,
		postal,
		updatingNode,
		updateNodeError,
		updateNodeCompleted,
		setShowEditMerchant,
		onResetNode,
		onGetNodeBySlugAdmin,
	} = props
	const [newName, setName] = useState(name)
	const [newEmail, setEmail] = useState(email)
	const [newPhone, setPhone] = useState(phone)
	const [formError, setFormError] = useState(false)
	const [fullAddress, setFullAddress] = useState(
		`${address}, ${city}, ${state}, ${postal}`
	)
	const [searchedAddress, setSearchedAddress] = useState(fullAddress)
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const navigate = useNavigate()

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	const handleAddressChange = async (newAddress) => {
		setFormError('')
		if (newAddress.target) {
			setSearchedAddress(newAddress.target.value)
			geocodeAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
			geocodeAddress(newAddress.formatted_address)
		}
	}

	const handleSubmit = (e) => {
		setFormError('')
		e.preventDefault()
		if (!name) {
			setFormError('Please enter location name')
		} else if (!validateEmail()) {
			setFormError('Please enter a valid email address')
		} else if (searchedAddress !== '' && !geoCodedAddress) {
			setFormError('Please enter address')
		} else if (!validatePhone()) {
			setFormError('Please enter a valid phone number (10 digits)')
		} else {
			let formattedPhone = newPhone
			if (newPhone.length === 10 && !newPhone.startsWith('+1')) {
				formattedPhone = `+1${newPhone}`
			}

			const updateObj = {
				name: newName,
				address: searchedAddress !== '' ? searchedAddress : fullAddress,
				email: newEmail,
				phone: formattedPhone,
			}
			handleNodeUpdate(nodeSlug, updateObj)
		}
	}

	const validateEmail = () => {
		const emailPattern = /\S+@\S+\.\S+/
		return emailPattern.test(newEmail)
	}

	const validatePhone = () => {
		let phoneNumber = newPhone
		if (phoneNumber.startsWith('+1')) {
			phoneNumber = phoneNumber.slice(2)
		}
		return phoneNumber && phoneNumber.length === 10 && !isNaN(phoneNumber)
	}

	const handleNodeUpdate = (nodeSlug, update) => {
		onUpdateNode({
			nodeSlug: nodeSlug,
			update: update,
		})
	}

	useEffect(() => {
		if (updateNodeCompleted) {
			setTimeout(() => {
				onResetNode()
				onGetNodeBySlugAdmin(nodeSlug)
				setShowEditMerchant(false)
			}, 2000)
		}
	}, [updateNodeCompleted])

	useEffect(() => {
		if (searchedAddress) {
			geocodeAddress(searchedAddress)
		}
	}, [searchedAddress])

	return (
		<FormGroup
			onSubmit={handleSubmit}
			className='w-fit flex flex-col justify-center items-center border-2 border-green-600 p-6 shadow-xl rounded-xl bg-white'
		>
			<div className='w-full text-left font-header ml-3'>Name: </div>
			<Input
				type='text'
				className='my-1 w-[300px] sm:w-[500px] mx-auto'
				placeholder={name}
				value={newName}
				onChange={(e) => {
					setName(e.target.value), setFormError('')
				}}
			/>
			<div className='w-full text-left font-header ml-3'>Address: </div>
			<Input
				placeholder={fullAddress}
				className={`bg-${inputBackgroundColor} my-1 w-[300px] sm:w-[500px] mx-auto`}
				error={addressError}
				autoCompleteAddress={true}
				value={searchedAddress}
				onChange={handleAddressChange}
			/>
			{searchedAddress !== '' && (
				<MdClear
					className={`font-semibold absolute left-2 ml-4 top-[83px] transform -translate-y-12 text-green-600 cursor-pointer`}
					onClick={() => {
						setSearchedAddress(''), setFormError('')
					}}
				/>
			)}
			<div className='w-full text-left font-header ml-3'>Email: </div>
			<Input
				type='email'
				className='my-1 w-[300px] sm:w-[500px] mx-auto'
				placeholder={email}
				value={newEmail}
				onChange={(e) => {
					setEmail(e.target.value), setFormError('')
				}}
			/>
			<div className='w-full text-left font-header ml-3'>Phone: </div>
			<Input
				type='phone'
				className='my-1 w-[300px] sm:w-[500px] mx-auto'
				placeholder={phone}
				value={newPhone}
				onChange={(e) => {
					setPhone(e.target.value), setFormError('')
				}}
			/>
			{formError && <div className='text-red-400'>{formError}</div>}

			{updatingNode && (
				<div className='flex items-center my-3'>
					<BounceLoaderCentered />
				</div>
			)}
			{updateNodeError && (
				<div className='flex items-center text-red-400 text-center my-3'>
					An error occured, please refresh the page and try again.
				</div>
			)}
			{updateNodeCompleted && (
				<div className='flex items-center text-green-600 text-center my-3'>
					Update successful.
				</div>
			)}
			<Button
				className='w-[300px] mx-auto mt-3'
				size='sm'
				text='Save'
				onClick={handleSubmit}
			>
				Save Changes
			</Button>
			<div
				className='underline text-green-600 text-center my-3'
				onClick={() =>
					navigate('/restaurant-admin/charges/credit-card-form')
				}
			>
				Click here to view/add payment methods
			</div>
		</FormGroup>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantAccountForm)
