import { realm } from '../helpers/realm'

export const membershipSignup = async (submitPayload) => {
	const submitted = await realm.currentUser.functions.dzMembershipHandler(
		submitPayload
	)
	return submitted
}

export const resetMembership = () => {
	return true
}

export const getMembershipSavings = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const reimbursements = client.db('caas').collection('reimbursements')

	const currentDate = new Date()
	const firstDayOfMonth = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		1
	)

	const allTimePipeline = [
		{
			$match: {
				userPhone: payload,
			},
		},
		{
			$group: {
				_id: null,
				totalSavings: { $sum: '$reimbursementAmount' },
			},
		},
	]

	const thisMonthPipeline = [
		{
			$match: {
				userPhone: payload,
				timestamp: { $gte: firstDayOfMonth },
			},
		},
		{
			$group: {
				_id: null,
				thisMonthSavings: { $sum: '$reimbursementAmount' },
			},
		},
	]

	const [allTimeResult, thisMonthResult] = await Promise.all([
		reimbursements.aggregate(allTimePipeline),
		reimbursements.aggregate(thisMonthPipeline),
	])

	const totalSavings = allTimeResult[0]?.totalSavings || 0
	const thisMonthSavings = thisMonthResult[0]?.thisMonthSavings || 0

	return {
		thisMonth: thisMonthSavings,
		allTime: totalSavings,
	}
}

export const getMembers = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const users = client.db('caas').collection('users')
	const uberdirect = client.db('caas').collection('uberdirect')
	const reimbursements = client.db('caas').collection('reimbursements')

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const stages = []

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [
				{ $skip: skip },
				{ $limit: limit },
				{
					$lookup: {
						from: 'uberdirect',
						let: {
							memberPhone: '$phone',
							memberSince: {
								$toDate: '$dzMemberSubscription.memberSince',
							},
						},
						pipeline: [
							{
								$match: {
									$expr: {
										$and: [
											{
												$eq: [
													'$pickup.phone_number',
													'$$memberPhone',
												],
											},
											{
												$gt: [
													{ $toDate: '$created' },
													'$$memberSince',
												],
											},
										],
									},
								},
							},
							{ $project: { fee: 1, tip: 1 } },
						],
						as: 'uberdirect_docs',
					},
				},
				{
					$lookup: {
						from: 'reimbursements',
						localField: 'phone',
						foreignField: 'userPhone',
						as: 'reimbursements_docs',
					},
				},
				{
					$addFields: {
						totalPickups: {
							$sum: {
								$map: {
									input: '$uberdirect_docs',
									as: 'doc',
									in: {
										$add: ['$$doc.fee', '$$doc.tip'],
									},
								},
							},
						},
						totalReimbursements: {
							$sum: '$reimbursements_docs.reimbursementAmount',
						},
					},
				},
				{
					$project: {
						uberdirect_docs: 0,
						reimbursements_docs: 0,
					},
				},
			],
		},
	})

	const members = await users.aggregate(stages)
	return members
}
