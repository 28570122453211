import React from 'react'

const ReturnSteps = (props) => {
	const { step } = props
	return (
		<div className='flex w-full justify-between items-center text-center mb-4'>
			<div className='h-12 w-12 flex items-center justify-center bg-yellow-300 rounded-full shrink-0 mx-2'>
				1
			</div>
			<div className='font-medium text-lg shrink-0 mx-2 text-gray-800 hidden lg:inline'>
				Select drop-off location
			</div>

			{step === 1 ? (
				<div className='flex w-full justify-between items-center text-center'>
					<div className='h-1 w-5/6 bg-slate-200 mx-2 '></div>
					<div className='h-12 w-12 flex items-center justify-center bg-gray-300 rounded-full shrink-0 mx-2'>
						2
					</div>
					<div className='font-medium text-lg shrink-0 mx-2 text-gray-800 hidden lg:inline'>
						Tally your return
					</div>
				</div>
			) : (
				<div className='flex w-full justify-between items-center text-center'>
					<div className='h-1 w-5/6 bg-slate-500 mx-2 '></div>
					<div className='h-12 w-12 flex items-center justify-center bg-yellow-300 rounded-full shrink-0 mx-2'>
						2
					</div>
					<div className='font-medium text-lg shrink-0 mx-2 text-gray-800 hidden lg:inline'>
						Tally your return
					</div>
				</div>
			)}
		</div>
	)
}

export default ReturnSteps
