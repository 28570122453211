import {
	INVITE_MERCHANT_USER,
	INVITE_MERCHANT_USER_FAIL,
	INVITE_MERCHANT_USER_SUCCESS,
	RESET_MERCHANT,
	GET_ALL_CHILDREN,
	GET_ALL_CHILDREN_FAIL,
	GET_ALL_CHILDREN_SUCCESS,
	ADD_CHILDREN_TO_USER,
	ADD_CHILDREN_TO_USER_FAIL,
	ADD_CHILDREN_TO_USER_SUCCESS,
	TRACK_USAGE,
	TRACK_USAGE_FAIL,
	TRACK_USAGE_SUCCESS,
} from './actionTypes'

export const inviteMerchantUser = (payload) => ({
	type: INVITE_MERCHANT_USER,
	payload: payload,
})

export const inviteMerchantUserSuccess = (payload) => ({
	type: INVITE_MERCHANT_USER_SUCCESS,
	payload: payload,
})

export const inviteMerchantUserError = (payload) => ({
	type: INVITE_MERCHANT_USER_FAIL,
	payload: payload,
})

export const getAllChildren = (payload) => ({
	type: GET_ALL_CHILDREN,
	payload: payload,
})

export const getAllChildrenSuccess = (payload) => ({
	type: GET_ALL_CHILDREN_SUCCESS,
	payload: payload,
})

export const getAllChildrenError = (payload) => ({
	type: GET_ALL_CHILDREN_FAIL,
	payload: payload,
})

export const addChildrenToUser = (payload) => ({
	type: ADD_CHILDREN_TO_USER,
	payload: payload,
})

export const addChildrenToUserSuccess = (payload) => ({
	type: ADD_CHILDREN_TO_USER_SUCCESS,
	payload: payload,
})

export const addChildrenToUserError = (payload) => ({
	type: ADD_CHILDREN_TO_USER_FAIL,
	payload: payload,
})

export const trackUsage = (payload) => ({
	type: TRACK_USAGE,
	payload: payload,
})

export const trackUsageSuccess = (payload) => ({
	type: TRACK_USAGE_SUCCESS,
	payload: payload,
})

export const trackUsageFail = (payload) => ({
	type: TRACK_USAGE_FAIL,
	payload: payload,
})

export const resetMerchant = () => ({
	type: RESET_MERCHANT,
})
