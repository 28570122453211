import React from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Button } from '../../components/Elements'

function AboutUs() {
	return (
		<>
			<Header />
			<div className='flex flex-col justify-center'>
				<div className='w-[90%] md:w-4/5 max-w-[950px] mx-auto mb-10'>
					<h1 className='text-3xl md:text-5xl font-header text-green-600 w-full mb-4 mt-12 md:mb-8'>
						About us
					</h1>
					<div className='font-header text-green-600 text-xl md:text-3xl mb-4'>
						Fight Climate Change & Waste.
					</div>
					<div className='font-header text-green-600 text-xl md:text-3xl mb-4 md:mb-8'>
						Order Takeout & Delivery, Sustainably.
					</div>
					<div className='text-sm md:text-[22px] sm:mb-3 mb-6 leading-7'>
						DeliverZero is a network of returnable, reusable food
						containers. We make it easy for restaurants, delivery
						platforms, and POS systems to offer customers the option
						to receive takeout and delivery without a side of trash.
					</div>
					<div className='text-sm md:text-[22px] sm:mb-6 mb-12 leading-7'>
						As a working single mom, our CEO & co-founder Lauren
						Sweeney often relies on the convenience of takeout and
						delivery. As she became increasingly frustrated by how
						much waste came with each delivery (and frequently asked
						herself whether she could really recycle or compost the
						single-use containers she received) Lauren set out on a
						mission to make{' '}
						<span className='font-semibold'>
							reuse easier and more transparent than recycling.
						</span>
					</div>
					<div className='text-sm md:text-[22px] sm:mb-3 mb-6 leading-7'>
						Lauren connected with likeminded people working to solve
						the takeout waste problem, including Ashwin Ramdas and
						Chris Todd, who co-founded Repeater in Colorado. After
						it became clear that our visions aligned, we joined
						forces in the summer of 2022.
					</div>
					<div className='text-sm md:text-[22px] sm:mb-9 mb-18 leading-7'>
						Our small but mighty team is ready to normalize reuse.
						We’re here to make the most climate-friendly option an
						accessible one.
					</div>
					<Button
						text='Why Reuse'
						link='/whyreuse'
						color='green'
						size='sm'
						className='min-w-[150px] px-12 py-3 mb-20'
					/>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default AboutUs
