import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getNodes,
	createTransaction,
	getContainers,
	resetTransaction,
	getMarket,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
	BounceLoaderCentered,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'

const RestaurantManualEntry = (state) => {
	const {
		user,
		onGetUser,
		onGetNodes,
		nodes,
		loadingNodes,
		onCreateTransaction,
		transactionResult,
		containers,
		onGetContainers,
		creatingTransaction,
		onResetTransaction,
		transactions,
		transactionsCount,
		loadingTransactions,
		onGetTransactions,
		containerMarket,
		onGetMarket,
	} = state

	const bounceLoaderColor = '#507f74'
	const paginationLimit = 50
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(true)
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [manualNode, setManualNode] = useState(null)
	const [entryDate, setEntryDate] = useState('')
	const [filteredContainers, setFilteredContainers] = useState(null)
	const [formError, setFormError] = useState(null)
	const [inventoryIn, setInventoryIn] = useState({})
	const [inventoryOut, setInventoryOut] = useState({})
	const [showSuccess, setShowSuccess] = useState(null)

	const getTransactions = (page, nodeSlug) => {
		console.log('node', nodeSlug)
		onGetTransactions({
			filter: {
				node: nodeSlug,
			},
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const transactionPaginate = (nodeSlug) => {
		getTransactions(page, nodeSlug)
	}

	useEffect(() => {
		if (manualNode) {
			transactionPaginate(manualNode.slug)
		}
	}, [page])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
				manualEntry: true,
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		onGetNodes([filter])
	}, [filter, transactionResult])

	useEffect(() => {
		if (manualNode) {
			onGetMarket({
				coords: [manualNode.coordinates[1], manualNode.coordinates[0]],
			})
		}
	}, [manualNode])

	useEffect(() => {
		onGetContainers([
			{
				enabled: true,
			},
		])
	}, [onGetContainers])

	useEffect(() => {
		if (containers && containerMarket) {
			const currentContainers = []
			for (const container of containers) {
				if (container.markets.includes(containerMarket)) {
					currentContainers.push(container)
				}
			}
			setFilteredContainers(currentContainers)
		} else {
			if (manualNode) {
				if (manualNode.pizza) {
					onGetContainers([
						{
							enabled: true,
						},
					])
				} else {
					onGetContainers([
						{
							enabled: true,
						},
						{
							hide: { $ne: true },
						},
					])
				}
			}
		}
	}, [containerMarket, containers, manualNode])

	const handleDateInputChange = (e) => {
		const selectedDate = e.target.value
		if (selectedDate) {
			setEntryDate(selectedDate)
			setFormError('')
		} else {
			setEntryDate(null)
		}
	}

	useEffect(() => {
		if (transactionResult) {
			setShowSuccess(true)
		}
		if (manualNode) {
			transactionPaginate(manualNode.slug)
		}
		return function cleanup() {
			onResetTransaction()
		}
	}, [transactionResult])

	useEffect(() => {
		setLoading(false)
	}, [transactions])

	const handleUpdateBalance = async () => {
		setFormError('')
		if (!entryDate || entryDate === '') {
			setFormError('Please enter a valid date')
		} else {
			const inventoryInValues = Object.values(inventoryIn)
			const totalIn = inventoryInValues.reduce(
				(acc, value) => acc + value,
				0
			)
			const inventoryOutValues = Object.values(inventoryOut)
			const totalOut = inventoryOutValues.reduce(
				(acc, value) => acc + value,
				0
			)
			console.log(entryDate)

			const timestamp = new Date(entryDate)
			timestamp.setHours(20, 0, 0, 0)
			timestamp.setDate(timestamp.getDate() + 1)
			console.log(timestamp)
			const transactionDataIn = {
				node: manualNode.slug,
				type: 'boxesIn',
				boxCount: Math.abs(totalIn),
				boxCountByContainer: inventoryIn,
				timestamp: timestamp,
				manualEntry: true,
			}
			const transactionDataOut = {
				node: manualNode.slug,
				type: 'boxesOut',
				boxCount: Math.abs(totalIn),
				boxCountByContainer: inventoryIn,
				timestamp: timestamp,
				manualEntry: true,
			}
			const transactionDataAdmin = {
				node: manualNode.slug,
				type: 'adminBalanceUpdate',
				boxCount: Math.abs(totalOut),
				boxCountByContainer: inventoryOut,
				timestamp: timestamp,
				manualEntry: true,
			}

			if (totalIn > 0) {
				await onCreateTransaction(transactionDataIn)
				await onCreateTransaction(transactionDataOut)
			}
			if (totalOut > 0) {
				await onCreateTransaction(transactionDataAdmin)
			}
		}
	}

	const updateContainerInventory = (qty, containerId, type) => {
		// console.log(qty, typeof qty)
		const updatedInventoryIn = { ...inventoryIn }
		const updatedInventoryOut = { ...inventoryOut }
		if (qty === '') {
			qty = 0
		}
		if (type === 'pickedUp') {
			updatedInventoryIn[containerId] = parseInt(qty)
		} else {
			updatedInventoryOut[containerId] = parseInt(qty)
		}
		setInventoryIn(updatedInventoryIn)
		setInventoryOut(updatedInventoryOut)
	}

	console.log(transactions)

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='px-2'>
					<div className='flex container flex-col justify-center items-center text-center w-full mb-4 mx-auto'>
						<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
							Manual Entry
						</h1>
						<div className='w-4/5 max-w-[600px] mb-4'>
							<Input
								placeholder='Search by restaurant...'
								className=''
								name='searchInput'
								id='searchInput'
								onChange={(e) => {
									setShowNodeChoices(true)
									setFilter({
										$or: [
											{
												name: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
											{
												slug: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
										],
										manualEntry: true,
									})
								}}
							/>
						</div>
						<div className='flex-col flex items-center'>
							{showNodeChoices && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									<div
										className='cursor-pointer text-green-600'
										onClick={() => {
											setShowNodeChoices(false)
										}}
									>
										{' '}
										Close X
									</div>
									{nodes.map((node) => (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											key={node.slug}
											onClick={() => {
												setManualNode(node)
												transactionPaginate(node.slug)
												setShowNodeChoices(false)
											}}
										>
											{node.name}
										</div>
									))}
								</div>
							)}
						</div>

						{manualNode && filteredContainers && (
							<>
								<div
									className='mx-auto'
									onClick={() => {
										const url = `/admin/restaurants/${manualNode.slug}`
										navigate(url)
									}}
								>
									<div className='font-header text-2xl text-green-600'>
										{manualNode.name}
									</div>
									{manualNode.enabled ? (
										<div className='flex flex-row items-center justify-center w-full'>
											<div className='font-bold text-green-600'>
												ENABLED
											</div>
										</div>
									) : (
										<div className='flex flex-row items-center justify-center w-full'>
											<div className='font-bold text-red-400'>
												DISABLED
											</div>
										</div>
									)}
									<div className=''>
										<span className='font-bold'>
											Address:
										</span>{' '}
										{manualNode.address}, {manualNode.city},{' '}
										{manualNode.state} {manualNode.postal}
									</div>
									<div className=''>
										<span className='font-bold'>
											Phone:
										</span>{' '}
										{manualNode.phone}
									</div>
									<div className=''>
										<div className=''>
											<span className='font-bold'>
												Balance:
											</span>{' '}
											{manualNode.balance} boxes
										</div>
									</div>
									{transactionsCount > 0 && (
										<div className=''>
											<span className='font-bold'>
												Number of total transactions:
											</span>{' '}
											{transactionsCount}
										</div>
									)}
									{manualNode.commissionFee &&
									manualNode.commissionFee > 0 ? (
										<div className=''>
											<span className='font-bold'>
												Commission Rate:
											</span>{' '}
											{manualNode.commissionFee * 100}%
										</div>
									) : (
										<div></div>
									)}
								</div>

								<div
									className='flex flex-col my-6'
									key={manualNode.slug}
								>
									<div className='flex flex-row justify-between'>
										<div className='mt-1 font-bold underline'>
											Update Inventory for{' '}
											{manualNode.name}:
										</div>
										<div className='flex flex-row'>
											<input
												label='Date: '
												type='date'
												value={entryDate || ''}
												onChange={handleDateInputChange}
											/>
										</div>
									</div>
									<div className='flex flex-col ml-[20px] mt-5'>
										<table>
											<thead>
												<tr>
													<th className='font-bold font-bold text-green-600 text-left'>
														Container Type
													</th>
													<th className='font-bold font-bold text-green-600'>
														Inventory Picked Up
													</th>
													<th className='font-bold font-bold text-green-600'>
														Inventory Dropped Off
													</th>
												</tr>
											</thead>
											<tbody>
												{filteredContainers.map(
													(container) => (
														<tr key={container._id}>
															<td className='flex flex-wrap justify-start align-start text-left'>
																{
																	container.description
																}
															</td>
															<td>
																<Input
																	type='number'
																	step={1}
																	min={0}
																	placeholder='# of containers'
																	className='mr-2 px-2'
																	onChange={(
																		e
																	) =>
																		updateContainerInventory(
																			e
																				.target
																				.value,
																			container.containerId,
																			'pickedUp'
																		)
																	}
																/>
															</td>
															<td>
																<Input
																	type='number'
																	step={1}
																	min={0}
																	className='mr-2 px-2'
																	placeholder='# of containers'
																	onChange={(
																		e
																	) =>
																		updateContainerInventory(
																			e
																				.target
																				.value,
																			container.containerId,
																			'droppedOff'
																		)
																	}
																/>
															</td>
														</tr>
													)
												)}
											</tbody>
										</table>
									</div>
									{formError !== '' && (
										<div className='font-bold text-red-400 my-1'>
											{formError}
										</div>
									)}
									{creatingTransaction ? (
										<BounceLoaderCentered container='div' />
									) : (
										<Button
											className='mt-6 w-[300px] flex justify-center items-center mx-auto'
											onClick={handleUpdateBalance}
											text='Submit'
										/>
									)}
									{showSuccess && (
										<div className='flex flex-col justify-center items-center'>
											<span className='font-bold text-green-600'>
												Success!
											</span>
										</div>
									)}
								</div>

								{transactions.length > 0 && (
									<div className='px-10 my-6 shadow-light-grey rounded-xl py-2 w-[900px] lg:w-[1300px] overflow-auto scrollbar'>
										<div className='my-2 text-lg text-green-600'>
											All Box Transactions
										</div>
										<div className='flex flex-row justify-center items-center mb-2 mx-auto w-[1300px]'>
											<div className='font-bold w-[200px]'>
												Date
											</div>
											<div className='font-bold w-[200px]'>
												Type
											</div>
											<div className='font-bold w-[100px]'>
												Box Count
											</div>
											{filteredContainers.map(
												(container) => (
													<div className='font-bold w-[100px]'>
														{container.containerId}
													</div>
												)
											)}
										</div>
										{loading && (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										)}
										{transactions.map((transaction, i) => {
											const transactionDate = new Date(
												transaction.timestamp
											)
											const transactionDateString =
												transactionDate.toLocaleString(
													'default',
													{
														month: 'short',
														day: 'numeric',
														year: 'numeric',
														hour: '2-digit',
														minute: '2-digit',
													}
												)

											return (
												<div
													className='my-2 divide-y-2 divide-solid flex flex-row justify-center items-center mb-2 mx-auto w-[1300px]'
													key={i}
												>
													<div></div>
													<div className='w-[200px]'>
														{transactionDateString}
													</div>
													<div
														className={`w-[200px] ${
															transaction.type ===
															'adminBalanceUpdate'
																? 'text-orange-400'
																: transaction.type ===
																  'boxesIn'
																? 'text-green-600'
																: transaction.type ===
																  'boxesOut'
																? 'text-red-400'
																: ''
														}`}
													>
														{transaction.type}
													</div>
													<div className='w-[100px]'>
														{transaction.boxCount}
													</div>
													{filteredContainers.map(
														(container) => {
															const containerSku =
																container.containerId
															return transaction?.boxCountByContainer?.hasOwnProperty(
																containerSku
															) ? (
																<div
																	className='font-bold w-[100px]'
																	key={
																		containerSku
																	}
																>
																	{
																		transaction
																			.boxCountByContainer[
																			containerSku
																		]
																	}
																</div>
															) : (
																<div className='font-bold w-[100px]'>
																	0
																</div>
															)
														}
													)}
												</div>
											)
										})}
										<div className='flex flex-row'>
											<div
												className='mx-auto underline font-bold mr-6 cursor-pointer'
												onClick={() => {
													setPage(page - 1)
													setLoading(true)
												}}
											>
												Previous {paginationLimit}{' '}
												transactions
											</div>
											<div
												className='mx-auto underline font-bold cursor-pointer'
												onClick={() => {
													setPage(page + 1)
													setLoading(true)
												}}
											>
												Next {paginationLimit}{' '}
												transactions
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	TransactionsCreate,
	Containers,
	Transactions,
	Logistics,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	transactionResult: TransactionsCreate.transactionResult,
	creatingTransaction: TransactionsCreate.creating,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	containerMarket: Logistics.market,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetUser: () => dispatch(getUser()),
	onCreateTransaction: (transactionPayload) =>
		dispatch(createTransaction(transactionPayload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetMarket: (payload) => dispatch(getMarket(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantManualEntry)
