import React, { useState } from 'react'
import { connect } from 'react-redux'
import { realm } from '../../helpers/realm'
import { Button } from '../Elements'
import { Carousel } from '../'

const HowItWorks = (props) => {
	const { user } = props
	const [email, setEmail] = useState('')
	const [submittingEmail, setSubmittingEmail] = useState(false)
	const [emailSuccess, setEmailSuccess] = useState(false)
	const [emailError, setEmailError] = useState('')
	const bounceLoaderColor = '#507f74'

	const handleEmailChange = (emailChange) => {
		setEmail(emailChange.target.value)
	}

	const handleSubscribe = async () => {
		setEmailError('')
		setEmailSuccess(false)
		setSubmittingEmail(true)
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (re.test(email)) {
			const subscriberStatus = await realm.currentUser.callFunction(
				'mailChimp/AddUserToEmailList',
				{
					email: email,
					phoneNumber: user ? user.phone : '',
					firstName: user ? user.firstName : '',
					lastName: user ? user.lastName : '',
				}
			)
			if (subscriberStatus == 'error') {
				setSubmittingEmail(false)
				setEmailError('An error occured. Try again.')
			} else {
				setEmailSuccess(true)
				setSubmittingEmail(false)
			}
		} else {
			// invalid email, maybe show an error to the user.
			setSubmittingEmail(false)
			setEmailError('Invalid email address. Try again.')
		}
	}
	return (
		<>
			<div className='h-auto flex flex-col justify-center items-center p-4 w-full z-10'>
				<h1 className='font-header text-green-600 text-3.5xl sm:text-5xl sm:mb-4 xl:mb-8'>
					How it Works
				</h1>
				<div className='z-10 block lg:hidden'>
					<Carousel />
				</div>
				<div className=' w-full lg:flex flex-col lg:flex-row items-center justify-center hidden'>
					{/* ORDER NOW */}
					<div className='w-[350px] lg:w-[275px] xl:w-[350px] xl:h-[400px] max-w-[350px] h-[445px] p-4 rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center mb-4 mx-4 flex-shrink-0 bg-white z-10'>
						<div className='w-full flex justify-center items-center mb-6'>
							<img
								src='images/HowItWorks/ForkKnife.svg'
								alt='an icon of a fork and knife'
								className='w-[100px] mt-3'
							/>
						</div>
						<div className=' h-full flex flex-col content-center'>
							<div className=' h-full flex flex-col content-center'>
								<div className='flex flex-col content-center h-full'>
									<h1 className='font-header text-green-600 text-xl mb-2'>
										Find a restaurant and order pickup or
										delivery
									</h1>
									<p className='mb-4 text-md'>
										Search your address to see which
										restaurants near you have joined our
										mission to reduce packaging waste.
									</p>
								</div>
								<Button
									color='green-outlined'
									size='sm'
									className='mx-12 mb-3'
									text='Order Now'
									link='/marketplace/locations'
								/>
							</div>
						</div>
					</div>
					{/* LEARN MORE  */}
					<div className='w-[350px] lg:w-[275px] xl:w-[350px] xl:h-[400px] max-w-[350px] h-[445px] p-4 rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center mb-4 mx-4 flex-shrink-0 bg-white z-10'>
						<div className='w-full flex justify-center mb-6'>
							<img
								src='images/HowItWorks/Not apples.png'
								alt='an icon of a person in front of a house'
								className='w-[100px] mt-3'
							/>
						</div>
						<div className=' h-full flex flex-col content-center'>
							<div className=' h-full flex flex-col content-center'>
								<div className='flex flex-col content-center h-full'>
									<h1 className='font-header text-green-600 text-xl mb-2'>
										Receive your order in the most
										climate-friendly packaging
									</h1>
									<p className='mb-4 text-md'>
										Reusable containers are more
										environmentally-friendly than single-use
										options. We're out to make reusable
										options more convenient, too.
									</p>
								</div>
								<Button
									color='green-outlined'
									size='sm'
									className='mx-12 mb-3'
									text='Learn More'
									link='/howitworks'
								/>
							</div>
						</div>
					</div>
					{/* RETURN BOXES  */}
					<div className='w-[350px] lg:w-[275px] xl:w-[350px] xl:h-[400px] max-w-[350px] h-[445px] p-4 rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center  mb-4 mx-4 flex-shrink-0 bg-white z-10'>
						<div className='w-full flex justify-center mb-6'>
							<img
								src='images/HowItWorks/House.svg'
								alt='an icon of a person in front of a house'
								className='w-[100px] mt-3'
							/>
						</div>
						<div className=' h-full flex flex-col content-center'>
							<div className=' h-full flex flex-col content-center'>
								<div className='flex flex-col content-center h-full'>
									<h1 className='font-header text-green-600 text-xl my-2'>
										Return your containers within 3 weeks
									</h1>
									<p className='mb-4 text-md'>
										Schedule a pickup, hand them back
										to the courier, or drop them off in
										person at any return point.
									</p>
								</div>
								<Button
									color='green-outlined'
									size='sm'
									className='mx-12 mb-3'
									text='Return Boxes'
									link='/returns'
								/>
							</div>
						</div>
					</div>
				</div>
				{/* JOIN  */}
				<div className='w-[350px] h-[500px] lg:w-[800px] lg:h-[250px] px-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center lg:justify-between items-center mx-8 flex-shrink-0 z-10 bg-white mt-8'>
					<div className='w-[180px] h-[180px] flex justify-center mb-6 mt-2 rounded-xl lg:flex-shrink-0'>
						<img
							src='images/HowItWorks/Burger.png'
							alt='an icon of a person in front of a house'
							className='w-[180px] object-cover lg:flex-shrink-0 rounded-xl'
						/>
					</div>
					<div className='h-fit flex flex-col justify-between lg:justify-center  px-2 lg:w-full lg:px-8'>
						<div className='h-full flex flex-col justify-between  lg:py-12'>
							<div className='flex flex-col h-full text-start'>
								<h1 className='text-green-600 font-header text-2xl lg:text-3.5xl my-3'>
									Join our ambassador program
								</h1>
								<p className=' text-start mb-3 text-md'>
									Be a leader for reuse in your own community,
									work directly with our team, and make a
									measurable, tangible difference.
								</p>
							</div>
							<div className='w-full lg:h-12 flex flex-col lg:flex-row lg:relative justify-center items-center'>
								<Button
									link='/ambassador-program'
									color='red'
									size='sm'
									className='mb-2 lg:pb-8 lg:absolute lg:left-0 lg:h-full lg:mb-0 w-full lg:w-auto lg:px-12'
									text='Sign Up'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='w-[350px] h-[500px] lg:w-[800px] lg:h-[250px] px-4 lg:px-0 lg:pl-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center lg:justify-between items-center mx-8 flex-shrink-0 z-10 bg-white my-5'>
					<div className='w-[180px] h-[180px] flex justify-center rounded-xl lg:flex-shrink-0'>
						<img
							src='https://i.imgur.com/tVvMoQx.png'
							alt='package free shop img'
							className='w-[180px] h-[180px] lg:flex-shrink-0 object-cover rounded-xl'
						/>
					</div>
					<div className='h-fit flex flex-col justify-between lg:justify-center px-2 lg:w-full lg:pl-8 content-center'>
						<div className='h-full flex flex-col content-center'>
							<div className='flex flex-col h-full text-start'>
								<h1 className='font-header text-green-600 text-2xl lg:text-[30px] my-3'>
									Track your packaging with DeliverZero
								</h1>
								<p className='text-start mb-3 text-md'>
									Whether you're an e-commerce brand shipping
									bath products nationwide, a local meal kit
									service, or a chain retailer, make your
									packaging reusable with DeliverZero.
								</p>
							</div>
							<div className='w-full lg:h-12 flex flex-col lg:flex-row lg:relative justify-center items-center'>
								<Button
									link='/raas-landing'
									color='red'
									size='sm'
									className='mb-2 lg:pb-8 lg:absolute lg:left-0 lg:h-full lg:mb-0 w-full lg:w-auto lg:px-12'
									text='DeliverZero for Business'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='w-[350px] h-[500px] lg:w-[800px] lg:h-[250px] px-4 rounded-xl shadow-light-grey text-center flex flex-col lg:flex-row justify-center lg:justify-between items-center mx-8 flex-shrink-0 z-10 bg-white mb-5'>
					<div className='w-[180px] h-[180px] flex justify-center mb-6 mt-2 rounded-xl lg:flex-shrink-0'>
						<img
							src='https://i.imgur.com/0UijxIb.png'
							alt='an icon of a person in front of a house'
							className='w-[180px] h-[180px] lg:flex-shrink-0 object-cover rounded-xl'
						/>
					</div>
					<div className='h-fit flex flex-col justify-between lg:justify-center  px-2 lg:w-full lg:px-8 content-center'>
						<div className='h-full flex flex-col content-center'>
							<div className='flex flex-col h-full text-start'>
								<h1 className='font-header text-green-600 text-2xl lg:text-3.5xl my-3'>
									DeliverZero at Work
								</h1>
								<p className=' text-start mb-3 text-md'>
									We make it easy for teams to order delivery
									in returnable reusable containers with
									serviced return bins and custom programs
									designed to meet your needs.
								</p>
							</div>
							<div className='w-full lg:h-12 flex flex-col lg:flex-row lg:relative justify-center items-center'>
								<Button
									link='/office-ordering/sign-up'
									color='red'
									size='sm'
									className='mb-2 lg:pb-8 lg:absolute lg:left-0 lg:h-full lg:mb-0 w-full lg:w-auto lg:px-12'
									text='Learn More'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks)
