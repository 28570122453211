import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Header, Footer } from '../../components'
import { Button } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { getUser } from '../../store/actions'
import {
	getAllReusableProducts,
	addProductReusablesShop,
	updateCartItemReusablesShop,
	updateCartFromUser,
	addCartToUserReusablesShop,
	clearCartReusablesShop,
	resetReusables,
} from '../../store/actions'
import MerchantReusableProductTile from '../../components/ReusablesShop/MerchantReusableProductTile'
import ReusablesCart from '../../components/ReusablesShop/ReusablesCart'
import { FaChevronDown } from 'react-icons/fa'

const MerchantReusablesShop = (props) => {
	const {
		user,
		loadingUser,
		onGetUser,
		allReusableProducts,
		loadingReusableProducts,
		onGetAllReusableProducts,
		onAddItem,
		onUpdateItem,
		onUpdateCartFromUser,
		cartItemsReusablesShop,
		loadingCartItems,
		onAddCartToUser,
		loadingClearCart,
		onClearCart,
		userUpdated,
		onResetReusables,
	} = props

	const bounceLoaderColor = '#507f74'
	const navigate = useNavigate()

	const [allProducts, setAllProducts] = useState([])
	const [isOpenProductSpecs, setIsOpenProductSpecs] = useState(false)
	const [isOpenPotentialUses, setIsOpenPotentialUses] = useState(false)

	const handleUpdateCartItem = (product) => {
		onUpdateItem({
			currCartItems: cartItemsReusablesShop,
			cartItemToUpdate: product,
			quantity: product.quantity,
		})
	}

	const handleAddProduct = (product) => {
		onAddItem({
			currCartItems: cartItemsReusablesShop,
			nextCartItem: product,
			quantity: product.quantity,
		})
	}

	const handleClearCart = () => {
		onClearCart()
	}

	useEffect(() => {
		onResetReusables()
		onGetUser()
		onGetAllReusableProducts({ bulk: true })
	}, [])

	useEffect(() => {
		if (allReusableProducts) {
			setAllProducts(allReusableProducts)
		}
	}, [allReusableProducts])

	useEffect(() => {
		if (!cartItemsReusablesShop && user && user.reusablesShopCart) {
			onUpdateCartFromUser(user.reusablesShopCart)
		} else if (localStorage.getItem('reusablesShopCart')) {
			const localStorageCart = JSON.parse(
				localStorage.getItem('reusablesShopCart')
			)
			onUpdateCartFromUser(localStorageCart)
		} else {
			onUpdateCartFromUser({})
		}
	}, [user, user?.reusablesShopCart, userUpdated])

	useEffect(() => {
		if (cartItemsReusablesShop) {
			// updating user if needed
			if (
				user &&
				(!user.reusablesShopCart ||
					!user.reusablesShopCart.cartItems ||
					(user.reusablesShopCart &&
						user.reusablesShopCart.cartItems &&
						cartItemsReusablesShop !=
							user.reusablesShopCart.cartItems))
			) {
				onAddCartToUser({ cartItems: cartItemsReusablesShop })
			}

			// updating local storage
			const reusablesShopCart = {
				cartItems: cartItemsReusablesShop,
				lastActive: new Date(),
			}
			localStorage.setItem(
				'reusablesShopCart',
				JSON.stringify(reusablesShopCart)
			)
		}
	}, [cartItemsReusablesShop, JSON.stringify(cartItemsReusablesShop)])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)
	else {
		return (
			<>
				<div>
					<Header />
					<div className='flex flex-col justify-center items-center mb-20'>
						{cartItemsReusablesShop &&
							Object.keys(cartItemsReusablesShop).length > 0 && (
								<div
									style={{
										position: 'relative',
										width: '100%',
									}}
								>
									<ReusablesCart
										cartItems={cartItemsReusablesShop}
										handleUpdateCartItem={
											handleUpdateCartItem
										}
										handleClearCart={handleClearCart}
									/>
								</div>
							)}
						<div className='flex flex-col justify-center items-center text-center mx-2 sm:mx-20'>
							<h1 className='text-3xl md:text-5xl font-header text-green-600 text-center w-full mb-4 mt-12'>
								Shop
							</h1>

							<h2 className='text-lg text-left max-w-[850px] mb-3'>
								Interested in DeliverZero's reusable containers,
								but located outside of our coverage areas? No
								problem! Get our durable, microwavable
								containers directly at volume-discounted
								pricing.
							</h2>

							<div className='flex flex-row text-center mx-4 sm:mx-20 my-4 max-w-[700px]'>
								<div
									className='flex flex-col text-xs sm:text-base max-w-[350px]'
									onClick={() => {
										setIsOpenProductSpecs(
											!isOpenProductSpecs
										)
									}}
								>
									<div className='flex flex-row'>
										Product Specifications{' '}
										<FaChevronDown
											className={`ml-1 inline text-sm transition-colors duration-150 ease-in-out${
												isOpenProductSpecs
													? 'origin-center rotate-180'
													: ''
											}`}
										/>
									</div>
									{isOpenProductSpecs && (
										<div className='pl-5 text-start'>
											<p>• BPA-free</p>
											<p>• NSF certified</p>
											<p>• Microwavable </p>
											<p>• Dishwashable </p>
											<p>• Rated for over 1,000 uses </p>
											<p>
												• Trusted by some of your
												favorite restaurants
											</p>
										</div>
									)}
								</div>
								<div
									className='flex flex-col text-xs sm:text-base max-w-[350px] ml-10'
									onClick={() => {
										setIsOpenPotentialUses(
											!isOpenPotentialUses
										)
									}}
								>
									<div className='flex flex-row'>
										Potential uses{' '}
										<FaChevronDown
											className={`ml-1 inline text-sm transition-colors duration-150 ease-in-out${
												isOpenPotentialUses
													? 'origin-center rotate-180'
													: ''
											}`}
										/>
									</div>
									{isOpenPotentialUses && (
										<div className='pl-5 text-start'>
											<p>• Meal prep</p>
											<p>
												• Packing lunch to take to the
												office
											</p>
											<p>• Storing leftovers</p>
											<p>• BYO to restaurants</p>
											<p>• and many more! </p>
											<p>
												• Trusted by some of your
												favorite restaurants
											</p>
										</div>
									)}
								</div>
							</div>

							<h2 className='text-lg text-left max-w-[850px]'>
								Need expertise and technology to help your
								organization operate its reuse system?
								<a
									target='_blank'
									href='https://www.deliverzero.com/raas-landing'
									className='underline text-green-600'
								>
									{` `}Use DeliverZero to build and manage
									your own closed-loop.
								</a>
							</h2>
						</div>

						<div
							className={
								'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center max-w-[1100px] my-10 gap-5'
							}
						>
							{(allProducts.length === 0 ||
								loadingUser ||
								loadingReusableProducts) && (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							)}
							{allProducts.length > 0 &&
								allProducts.map((product) => {
									return (
										<MerchantReusableProductTile
											name={product.name}
											description={product.description}
											imgSrc={product.image}
											price={product.price}
											sku={product.sku}
											bulkQty={product.bulkQty}
											handleAddProduct={handleAddProduct}
											weight25={product.weight25}
										/>
									)
								})}
						</div>
					</div>
					<Footer />
				</div>
			</>
		)
	}
}

const mapStateToProps = ({ User, ReusablesShop }) => ({
	user: User.user,
	loadingUser: User.loading,
	userUpdated: ReusablesShop.userUpdated,
	allReusableProducts: ReusablesShop.allProducts,
	loadingReusableProducts: ReusablesShop.loading,
	cartItemsReusablesShop: ReusablesShop.cartItemsReusablesShop,
	loadingCartItems: ReusablesShop.cartItemsLoading,
	loadingClearCart: ReusablesShop.clearingCartReusablesShop,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAllReusableProducts: (payload) =>
		dispatch(getAllReusableProducts(payload)),
	onAddItem: (payload) => dispatch(addProductReusablesShop(payload)),
	onUpdateItem: (payload) => dispatch(updateCartItemReusablesShop(payload)),
	onUpdateCartFromUser: (payload) => dispatch(updateCartFromUser(payload)),
	onAddCartToUser: (payload) => dispatch(addCartToUserReusablesShop(payload)),
	onClearCart: () => dispatch(clearCartReusablesShop()),
	onResetReusables: () => dispatch(resetReusables()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MerchantReusablesShop)
