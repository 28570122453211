import { getUser } from '../../../store/auth/user/actions'
import {
	chargeRestaurants,
	getRestaurantCharges,
} from '../../../store/restaurantPayments/actions'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import Header from '../../../components/Header'
import { Button, CheckboxGroup, Input } from '../../../components/Elements'
import { Footer } from '../../../components'

const RestaurantCharges = (state) => {
	const {
		user,
		onGetUser,
		restaurantCharges,
		restaurantChargesError,
		chargingRestaurants,
		restaurantChargesCompleted,
		onGetRestaurantCharges,
		onChargeRestaurants,
		totalCharges,
		loadingRestaurantCharges,
	} = state

	useEffect(() => {
		onGetUser()
		orderPaginate()
	}, [onGetUser])

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [restaurantChargesPage, setRestaurantChargesPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [restaurantsToPay, setRestaurantsToPay] = useState([])
	let navigate = useNavigate()
	const [filter, setFilter] = useState({
		chargeToOffice: { $exists: false },
	})
	const [paidFilter, setPaidFilter] = useState(false)
	const [pendingFilter, setPendingFilter] = useState(false)
	const [failedFilter, setFailedFilter] = useState(false)

	// useEffect(() => {
	// 	console.log("filter", filter.status )
	// }, [filter])

	const getRestaurantCharges = (page) => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetRestaurantCharges({
					filter: {
						...filter,
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							endDate: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			} else {
				onGetRestaurantCharges({
					filter: filter,
					options: {
						sort: {
							endDate: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			}
		} else {
			onGetUser()
		}
	}

	const orderPaginate = () => {
		getRestaurantCharges(restaurantChargesPage)
	}

	const submitCharges = () => {
		if (restaurantsToPay.length > 0) {
			onChargeRestaurants(restaurantsToPay)
		}
		setRestaurantsToPay([])
	}

	useEffect(() => {
		orderPaginate()
	}, [restaurantChargesCompleted])

	useEffect(() => {
		orderPaginate()
	}, [restaurantChargesPage])

	useEffect(() => {
		setLoading(false)
	}, [restaurantCharges])

	useEffect(() => {
		getRestaurantCharges(restaurantChargesPage)
	}, [filter, user])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Merchant Charges
				</h1>
				<div className='w-4/5 max-w-[600px] mb-4 mx-auto'>
					<Input
						placeholder='Search restaurants ...'
						className=''
						name='searchInput'
						id='searchInput'
						onChange={(e) => {
							setFilter({
								...filter,
								nodeSlug: {
									$regex: e.target.value,
									$options: 'i',
								},
							})
							setRestaurantChargesPage(1)
						}}
					/>
				</div>
				{!user.franchiseeSlug && (
					<div>
						<div className='flex min-w-min w-[350px] mx-auto items-center mb-4'>
							<CheckboxGroup
								label='Paid'
								name='status-paid'
								className=''
								defaultChecked={false}
								onChange={(check) => {
									if (check.target.checked) {
										setPaidFilter(true)
										let statuses = ['paid']
										if (pendingFilter) {
											statuses.push('pending')
										}
										if (failedFilter) {
											statuses.push('failed')
										}
										setFilter({
											...filter,
											status: {
												$in: statuses,
											},
										})
										setRestaurantChargesPage(1)
									} else {
										setPaidFilter(false)
										let statuses = []
										if (pendingFilter) {
											statuses.push('pending')
										}
										if (failedFilter) {
											statuses.push('failed')
										}
										if (statuses.length === 0) {
											setFilter({
												...filter,
												status: {
													$nin: [],
												},
											})
											setRestaurantChargesPage(1)
										} else {
											setFilter({
												...filter,
												status: {
													$in: statuses,
												},
											})
											setRestaurantChargesPage(1)
										}
									}
								}}
							/>
							<CheckboxGroup
								label='Pending'
								name='status-pending'
								className=''
								defaultChecked={false}
								onChange={(check) => {
									if (check.target.checked) {
										setPendingFilter(true)
										let statuses = ['pending']
										if (paidFilter) {
											statuses.push('paid')
										}
										if (failedFilter) {
											statuses.push('failed')
										}
										setFilter({
											...filter,
											status: {
												$in: statuses,
											},
										})
										setRestaurantChargesPage(1)
									} else {
										setPendingFilter(false)
										let statuses = []
										if (paidFilter) {
											statuses.push('paid')
										}
										if (failedFilter) {
											statuses.push('failed')
										}
										if (statuses.length === 0) {
											setFilter({
												...filter,
												status: {
													$nin: [],
												},
											})
											setRestaurantChargesPage(1)
										} else {
											setFilter({
												...filter,
												status: {
													$in: statuses,
												},
											})
											setRestaurantChargesPage(1)
										}
									}
								}}
							/>
							<CheckboxGroup
								label='Failed'
								name='status-failed'
								className=''
								defaultChecked={false}
								onChange={(check) => {
									if (check.target.checked) {
										setFailedFilter(true)
										let statuses = ['failed']
										if (paidFilter) {
											statuses.push('paid')
										}
										if (pendingFilter) {
											statuses.push('pending')
										}
										setFilter({
											...filter,
											status: {
												$in: statuses,
											},
										})
										setRestaurantChargesPage(1)
									} else {
										setFailedFilter(false)
										let statuses = []
										if (paidFilter) {
											statuses.push('paid')
										}
										if (pendingFilter) {
											statuses.push('pending')
										}
										if (statuses.length === 0) {
											setFilter({
												...filter,
												status: {
													$nin: [],
												},
											})
											setRestaurantChargesPage(1)
										} else {
											setFilter({
												...filter,
												status: {
													$in: statuses,
												},
											})
											setRestaurantChargesPage(1)
										}
									}
								}}
							/>
						</div>
						{chargingRestaurants ? (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						) : (
							<Button
								onClick={submitCharges}
								text='Charge Selected Restaurants'
								size='sm'
								className='my-6 cursor-pointer w-[180px] mx-auto'
							/>
						)}
						{restaurantChargesError && (
							<div className='font-bold text-red-400'>
								An error occurred:{' '}
								{restaurantChargesError.message}. Contact an
								administrator.
							</div>
						)}
					</div>
				)}

				<div className='flex flex-col max-w-[1200px] justify-center mt-10 overflow-x-auto scrollbar mx-auto'>
					<div className='mx-auto'>
						{loadingRestaurantCharges ||
							(loading && (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							))}
						{restaurantCharges.length === 0 && (
							<div className='w-full text-center mx-auto'>
								<div className='w-fit font-bold text-red-400 w-[70px] whitespace-nowrap'>
									No charges found
								</div>
							</div>
						)}
						{restaurantCharges.length > 0 && (
							<div className='px-10 my-6 shadow-light-grey'>
								<div className='w-full flex flex-row justify-between'>
									<div className='mb-6 flex justify-start'>
										{' '}
										Total restaurant charges: {
											totalCharges
										}{' '}
									</div>
									<div className='mb-6 flex justify-end'>
										{' '}
										Page: {restaurantChargesPage} of{' '}
										{Math.round(
											totalCharges / paginationLimit
										)}{' '}
									</div>
								</div>
								<div className='flex flex-row mb-2'>
									<div className='font-bold w-[70px]'>
										Select
									</div>
									<div className='font-bold w-[130px]'>
										Orders From
									</div>
									<div className='font-bold w-[130px]'>
										To
									</div>
									<div className='font-bold w-[180px]'>
										Merchant
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Number of Orders
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Number of Boxes
									</div>
									<div className='font-bold w-[130px] text-sm'>
										Total to Charge
									</div>
									<div className='font-bold w-[130px] text-sm'>
										Payment on File?
									</div>
									{!user.franchiseeSlug && (
										<div className='font-bold w-[100px] text-sm'>
											Charge Status
										</div>
									)}
									<div className='font-bold w-[130px] text-sm'>
										Fail Reason
									</div>
								</div>
								{restaurantCharges.map(
									(restaurantCharge, index) => {
										// console.log(restaurantCharge)
										const startDate = new Date(
											restaurantCharge.startDate
										)
										const endDate = new Date(
											restaurantCharge.endDate
										)
										const startDateString =
											startDate.toLocaleString(
												'default',
												{
													month: 'short',
													day: 'numeric',
													year: 'numeric',
												}
											)
										const endDateString =
											endDate.toLocaleString('default', {
												month: 'short',
												day: 'numeric',
												year: 'numeric',
											})

										return (
											<div
												className='flex flex-row my-2 divide-y-2 divide-solid'
												key={index}
											>
												<div className='w-[70px] !h-[35px] '>
													<CheckboxGroup
														label=''
														name='pay-this-restaurant'
														className='!min-w-min !w-[25px]my-4'
														defaultChecked={false}
														onChange={(val) => {
															const tempArray =
																restaurantsToPay
															if (
																val.target
																	.checked
															) {
																// console.log('here')
																tempArray.push(
																	restaurantCharge._id
																)
																setRestaurantsToPay(
																	tempArray
																)
															} else {
																setRestaurantsToPay(
																	tempArray.filter(
																		function (
																			e
																		) {
																			// console.log(
																			// 	e
																			// )
																			return (
																				e !==
																				restaurantCharge._id
																			)
																		}
																	)
																)
															}
														}}
													/>
												</div>
												<div className='w-[130px]'>
													{startDateString}
												</div>
												<div className='w-[130px]'>
													{endDateString}
												</div>
												{restaurantCharge.nodeInfo && (
													<div
														onClick={() => {
															const url = `/admin/restaurants/${restaurantCharge.nodeSlug}`
															navigate(url)
														}}
														className='w-[180px] text-green-600 overflow-hidden cursor-pointer underline font-bold'
													>
														{
															restaurantCharge
																.nodeInfo.name
														}
													</div>
												)}
												{restaurantCharge.mealDeliveryInfo && (
													<div className='w-[180px] text-green-600 overflow-hidden cursor-pointer underline font-bold'>
														{
															restaurantCharge
																.mealDeliveryInfo
																.name
														}
													</div>
												)}

												<div className='w-[100px] overflow-hidden'>
													{
														restaurantCharge.numberOfOrders
													}
												</div>
												<div className='w-[100px] overflow-hidden'>
													{restaurantCharge.boxCount}
												</div>
												<div className='w-[130px] overflow-hidden'>
													$
													{restaurantCharge.amountToCharge.toFixed(
														2
													)}
												</div>
												{restaurantCharge.nodeInfo && (
													<div>
														{restaurantCharge
															.nodeInfo
															.defaultPaymentMethod &&
														restaurantCharge
															.nodeInfo
															.defaultPaymentMethod !==
															'' ? (
															<div className='w-[130px] text-green-600 overflow-hidden'>
																Yes
															</div>
														) : (
															<div className='w-[130px] text-red-400 overflow-hidden underline font-bold'>
																NO
															</div>
														)}
													</div>
												)}
												{!user.franchiseeSlug && (
													<div>
														{restaurantCharge.status &&
														restaurantCharge.status ===
															'paid' ? (
															<div className='w-[100px] overflow-hidden text-green-600'>
																{restaurantCharge.status.toUpperCase()}
															</div>
														) : (
															<div className='w-[100px] overflow-hidden'>
																{restaurantCharge.status.toUpperCase()}
															</div>
														)}
													</div>
												)}
												{restaurantCharge.failReason && (
													<div className='w-[130px] text-red-600'>
														{
															restaurantCharge.failReason
														}
													</div>
												)}
											</div>
										)
									}
								)}
							</div>
						)}
					</div>
				</div>
				<div className='w-full flex flex-row justify-center items-center mb-8'>
					{restaurantChargesPage > 1 ? (
						<div
							className='mx-auto underline font-bold mr-6 cursor-pointer w-fit'
							onClick={() => {
								if (restaurantChargesPage >= 2) {
									setRestaurantChargesPage(
										restaurantChargesPage - 1
									)
								}
								setLoading(true)
							}}
						>
							Previous {paginationLimit} charges
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Previous {paginationLimit} charges
						</div>
					)}
					{restaurantCharges.length === paginationLimit ? (
						<div
							className='mx-auto w-fit underline font-bold cursor-pointer'
							onClick={() => {
								if (
									restaurantChargesPage <
									totalCharges / paginationLimit
								) {
									setRestaurantChargesPage(
										restaurantChargesPage + 1
									)
								}
								setLoading(true)
							}}
						>
							Next {paginationLimit} charges
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Next {paginationLimit} charges
						</div>
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, RestaurantPayments }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	restaurantCharges: RestaurantPayments.restaurantCharges,
	restaurantChargesError: RestaurantPayments.error,
	loadingRestaurantCharges: RestaurantPayments.loadingRestaurantCharges,
	chargingRestaurants: RestaurantPayments.chargingRestaurants,
	restaurantChargesCompleted: RestaurantPayments.restaurantChargesCompleted,
	totalCharges: RestaurantPayments.totalCharges,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetRestaurantCharges: (filter) => dispatch(getRestaurantCharges(filter)),
	onChargeRestaurants: (restaurantChargesIdList) =>
		dispatch(chargeRestaurants(restaurantChargesIdList)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantCharges)
