import {
	CANCEL_FIRST_DELIVERY,
	CANCEL_FIRST_DELIVERY_FAILURE,
	CANCEL_FIRST_DELIVERY_SUCCESS,
	GET_RL_PARTNER,
	GET_RL_PARTNER_FAILURE,
	GET_RL_PARTNER_SUCCESS,
	GET_RL_PARTNERS,
	GET_RL_PARTNERS_BY_POLYGON,
	GET_RL_PARTNERS_BY_POLYGON_FAILURE,
	GET_RL_PARTNERS_BY_POLYGON_SUCCESS,
	GET_RL_PARTNERS_BY_ZIP,
	GET_RL_PARTNERS_BY_ZIP_FAILURE,
	GET_RL_PARTNERS_BY_ZIP_SUCCESS,
	GET_RL_PARTNERS_FAILURE,
	GET_RL_PARTNERS_SUCCESS,
	GET_THIRD_PARTY_PARTNERS,
	GET_THIRD_PARTY_PARTNERS_FAILURE,
	GET_THIRD_PARTY_PARTNERS_SUCCESS,
} from './actionTypes'

const initialState = {
	partner: null,
	partnerError: null,
	partners: [],
	allPartners: [],
	partnersError: null,
	loadingPartners: false,
	loadingPartner: false,
	cancelingFirstDelivery: false,
	cancelledFirstDelivery: null,
	cancelingFirstDeliveryError: false,
}

const reverseLogistics = (state = initialState, action) => {
	switch (action.type) {
		case GET_RL_PARTNERS:
			return {
				...state,
				loadingPartners: true,
			}
		case GET_RL_PARTNERS_SUCCESS:
			return {
				...state,
				loadingPartners: false,
				partners: action.payload,
			}
		case GET_RL_PARTNERS_FAILURE:
			return {
				...state,
				loadingPartners: false,
				partnersError: action.payload,
			}
		case GET_RL_PARTNERS_BY_ZIP:
			return {
				...state,
				loadingPartners: true,
			}
		case GET_RL_PARTNERS_BY_ZIP_SUCCESS:
			return {
				...state,
				loadingPartners: false,
				partners: action.payload,
			}
		case GET_RL_PARTNERS_BY_ZIP_FAILURE:
			return {
				...state,
				loadingPartners: false,
				partnersError: action.payload,
			}
		case GET_RL_PARTNERS_BY_POLYGON:
			return {
				...state,
				loadingPartners: true,
			}
		case GET_RL_PARTNERS_BY_POLYGON_SUCCESS:
			return {
				...state,
				loadingPartners: false,
				partners: action.payload,
			}
		case GET_RL_PARTNERS_BY_POLYGON_FAILURE:
			return {
				...state,
				loadingPartners: false,
				partnersError: action.payload,
			}
		case GET_RL_PARTNER:
			return {
				...state,
				loadingPartner: true,
			}
		case GET_RL_PARTNER_SUCCESS:
			return {
				...state,
				loadingPartner: false,
				partner: action.payload,
			}
		case GET_RL_PARTNER_FAILURE:
			return {
				...state,
				loadingPartner: false,
				partnerError: action.payload,
			}
		case CANCEL_FIRST_DELIVERY:
			return {
				...state,
				cancelingFirstDelivery: true,
			}
		case CANCEL_FIRST_DELIVERY_SUCCESS:
			return {
				...state,
				cancelingFirstDelivery: false,
				cancelledFirstDelivery: action.payload,
			}
		case CANCEL_FIRST_DELIVERY_FAILURE:
			return {
				...state,
				cancelingFirstDelivery: false,
				cancelingFirstDeliveryError: true,
			}
		case GET_THIRD_PARTY_PARTNERS:
			return {
				...state,
				loadingPartners: true,
			}
		case GET_THIRD_PARTY_PARTNERS_SUCCESS:
			return {
				...state,
				loadingPartners: false,
				partners: action.payload,
			}
		case GET_THIRD_PARTY_PARTNERS_FAILURE:
			return {
				...state,
				loadingPartners: false,
				partnersError: action.payload,
			}
		default:
			return state
	}
}

export default reverseLogistics
