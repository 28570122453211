import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getOrders,
	getPaymentMethods,
	getNodeByAdminCodeOrSlug,
	updateNode,
	updateUser,
	getLead,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { Button, Input } from '../../components/Elements'
import { realm } from '../../helpers/realm'
// import { Header } from '../../components'

const RestaurantAdminHome = (state) => {
	const {
		user,
		onGetUser,
		onGetNodeBySlugAdmin,
		node,
		onUpdateUser,
		updateUserLoading,
		userUpdateCompleted,
		userError,
		loadingNode,
		onGetLead,
		loadingLead,
		leadError,
		lead,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [showSignUp, setShowSignUp] = useState(false)
	const [signUpUrl, setSignUpURL] = useState('')

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	useEffect(() => {
		if (node && node.fromSignUpFlow) {
			onGetLead({ slug: user.restaurantAdmin })
		}
	}, [node])

	useEffect(() => {
		if (lead && !lead.signUpComplete) {
			setShowSignUp(true)
			const pageNumber = lead.lastPage + 1
			setSignUpURL(`/restaurantpartners/sign-up/page-${pageNumber}`)
		}
	}, [lead])

	const changeTheMerchant = (selectedNodeSlug) => {
		onUpdateUser({
			restaurantAdmin: selectedNodeSlug,
		})
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		if (node && node.slug === user.restaurantAdmin) {
			return (
				<div>
					<Header />
					{node ? (
						<div>
							{user.restaurantAdminOtherLocations &&
							user.restaurantAdminOtherLocations.length > 0 ? (
								<div className='flex flex-row justify-center'>
									<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
										{node.name}'s DeliverZero Dashboard
									</h1>
									{showNodeChoices ? (
										<BsChevronUp
											onClick={() => {
												setShowNodeChoices(false)
											}}
											className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
										/>
									) : (
										<BsChevronDown
											onClick={() => {
												setShowNodeChoices(true)
											}}
											className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
										/>
									)}
								</div>
							) : (
								<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
									{node.name}'s DeliverZero Dashboard
								</h1>
							)}
						</div>
					) : (
						<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
							DeliverZero Dashboard
						</h1>
					)}
					<div className='flex-col flex items-center'>
						{showNodeChoices && (
							<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
								{user.restaurantAdminOtherLocations.map(
									(location) => (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											key={location.nodeSlug}
											onClick={() => {
												changeTheMerchant(
													location.nodeSlug
												)
												setShowNodeChoices(false)
											}}
										>
											{location.name}
										</div>
									)
								)}
							</div>
						)}
					</div>
					{showSignUp && (
						<div className='flex flex-col items-center'>
							<div>
								Almost there! Finish signing up for DeliverZero
								below
							</div>
							<Button
								size='sm'
								className='mb-4 w-[180px] px-10 mt-2'
								onClick={() => navigate(signUpUrl)}
								text='Finish Signing Up for DeliverZero'
							/>
						</div>
					)}
					<Button
						onClick={() => {
							navigate(`/restaurant-admin/account/${node.slug}`)
						}}
						className='mb-10 w-50% max-w-[200px] shadow-light-grey flex flex-col m-auto cursor-pointer'
						text='My Account'
					></Button>
					{userError && (
						<div className='font-bold text-red-400'>
							An error occurred, please contact DeliverZero.
						</div>
					)}
					<div
						onClick={() => {
							navigate('/restaurant-admin/platform-integration')
						}}
						className='my-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							Platform Integration Help
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							View instructions for platform integration
						</p>
					</div>
					{node.checkoutModule && (
						<div
							onClick={() => {
								navigate(
									'/restaurant-admin/checkout-containers'
								)
							}}
							className='mb-10 border-green-600 border-2 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								Checkout Containers to Customers
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								Use this page to check containers out to
								customers
							</p>
						</div>
					)}
					<div
						onClick={() => {
							navigate('/restaurant-admin/orders')
						}}
						className='w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							View My Orders
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							View any DeliverZero order and individual orders
						</p>
					</div>
					<div
						onClick={() => {
							navigate('/restaurant-admin/charges')
						}}
						className='mt-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							View My Charges
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							See a breakdown of your monthly usage charges
						</p>
					</div>
					{!showSignUp && (
						<div
							onClick={() => {
								navigate('/restaurant-admin/request-containers')
							}}
							className='mt-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								Request Containers
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								Request containers by type
							</p>
						</div>
					)}
					<div
						onClick={() => {
							navigate('/restaurant-admin/payments')
						}}
						className='mt-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							View My Payments
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							See any payments that DeliverZero owes you for
							orders placed directly through DeliverZero
						</p>
					</div>
					<div
						onClick={() => {
							navigate('/restaurant-admin/impact')
						}}
						className='mt-10 mb-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							View My Impact
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							See how much packaging you've prevented and the
							climate benefits
						</p>
					</div>

					<Footer />
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Transactions,
	User,
	AppInfo,
	Orders,
	Nodes,
	Leads,
}) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	lead: Leads.lead,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetPaymentMethods: (payload) => dispatch(getPaymentMethods(payload)),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onUpdateUser: (payload) => dispatch(updateUser(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantAdminHome)
