import { useDispatch, connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getAppInfo, runAggregation } from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const AdminRevenue = (state) => {
	const {
		user,
		onGetUser,
		onGetAppInfo,
		onGetAggregator,
		aggregationResult,
		aggregationError,
		runningAggregation,
	} = state

	const dispatch = useDispatch()
	let navigate = useNavigate()
	const bounceLoaderColor = '#507f74'

	const [containersOrdersRes, setContainersOrdersRes] = useState([])
	const [monthlyRevStreams, setMonthlyRevStreams] = useState({})
	const [totalRev, setTotalRev] = useState({})

	useEffect(() => {
		onGetAppInfo()
	}, [])

	useEffect(() => {
		onGetUser()
		onGetAggregator()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			dispatch(
				runAggregation({
					functionName: 'getContainersAndOrdersByMonth',
				})
			)
			dispatch(
				runAggregation({
					functionName: 'getMonthlyRevenueStreams',
				})
			)
		}
	}, [user])

	useEffect(() => {
		if (aggregationResult) {
			if (aggregationResult['getContainersAndOrdersByMonth']) {
				setContainersOrdersRes(
					aggregationResult['getContainersAndOrdersByMonth']
				)
			}
			if (aggregationResult['getMonthlyRevenueStreams']) {
				setMonthlyRevStreams(
					aggregationResult['getMonthlyRevenueStreams']
				)
			}
		}
	}, [aggregationResult])

	console.log(aggregationResult)

	function sumChargesByMonth(data) {
		const result = {}

		for (const key in data) {
			if (
				key === 'numberOfRestaurantsWithOrders' ||
				key === 'numberOfRestaurants'
			) {
			} else {
				const nestedObject = data[key]

				for (const monthYear in nestedObject) {
					if (result[monthYear]) {
						result[monthYear] += nestedObject[monthYear]
					} else {
						result[monthYear] = nestedObject[monthYear]
					}
				}
			}
		}

		return result
	}

	useEffect(() => {
		if (monthlyRevStreams && Object.keys(monthlyRevStreams).length > 0) {
			const tempObj = sumChargesByMonth(monthlyRevStreams)
			setTotalRev(tempObj)
		}
	}, [monthlyRevStreams])

	const lineStyle = {
		borderLeft: '1px solid #000', // Adjust color and thickness as needed
		height: '100px', // Adjust height as needed
		margin: '0 00px', // Adjust margin as needed
	}
	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		if (user.adminPermissions.includes('financial')) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<h1 className='font-header text-green-600 text-4xl mb-11 mt-14 text-center'>
						Revenue Dashboard
					</h1>
					<div className='max-w-[1500px] max-h-[600px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col mx-[2px] sm:mx-auto rounded-xl pb-2 mt-6 mb-8 scrollbar bg-white bg-opacity-100'>
						<div className='flex flex-row sticky border-b top-0 bg-white bg-opacity-100 min-w-[850px] pt-2'>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky left-0 top-0 z-20 bg-white text-xs sm:text-base'>
								Month
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								# of Restaurants as of 3rd of the month
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								# of Restaurants who got orders
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Total Revenue
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Revenue from Restaurants
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Number of Orders
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Number of Container Uses
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Revenue (Commissions only) from Marketplace
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Revenue from Office Ordering
							</div>
							<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Revenue from Customer Pickups
							</div>
							<div className='mb-1 pt-1 font-header text-center min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Revenue from Late Charges
							</div>
							<div className='mb-1 pt-1 font-header text-center min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Revenue from RAAS
							</div>
							<div className='mb-1 pt-1 font-header text-center min-w-[192px] max-w-[192px] sticky bg-white text-xs sm:text-base'>
								Revenue from On Premise
							</div>
						</div>
						{Object.keys(totalRev).length > 0 &&
						monthlyRevStreams &&
						Object.keys(monthlyRevStreams).length > 0 &&
						containersOrdersRes &&
						containersOrdersRes.length > 0 ? (
							<div className=''>
								{containersOrdersRes.map((element, index) => {
									return (
										<div
											className='min-w-[2850px] py-0 whitespace-nowrap bg-white bg-opacity-100 h-full even:bg-gray-100 my-0'
											key={index}
										>
											<div className='text-sm flex flex-row border-b-2 border-green-600 items-center my-0 justify-start min-h-[80px] my-0 py-0'>
												<div
													className='flex justify-center items-center font-bold underline text-center cursor-pointer min-h-[80px] sticky left-0 my-0 p-0 min-w-[192px] max-w-[192px] text-xs sm:text-base bg-green-100'
													onClick={() => {
														const url = `/admin/revenue/monthly/${element._id.monthName}/${element._id.year}`
														navigate(url)
													}}
												>
													<div className='h-full p-0 m-0 w-full'>
														{element._id.monthName}{' '}
														{element._id.year}{' '}
													</div>
													<div
														style={lineStyle}
													></div>
												</div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px] flex flex-col items-center'>
													{
														monthlyRevStreams[
															'numberOfRestaurants'
														][
															`${element._id.monthName} ${element._id.year}`
														]
													}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px] flex flex-col items-center'>
													{
														monthlyRevStreams[
															'numberOfRestaurantsWithOrders'
														][
															`${element._id.monthName} ${element._id.year}`
														]
													}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px] flex flex-col items-center ml-[180px] pl-[180px] sm:ml-4 sm:pl-0'>
													$
													{totalRev[
														`${element._id.monthName} ${element._id.year}`
													].toFixed(2)}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px] flex flex-col items-center'>
													$
													{monthlyRevStreams[
														'restaurantChargesByMonth'
													][
														`${element._id.monthName} ${element._id.year}`
													].toFixed(2)}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px]'>
													{element.totalOrders}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px]'>
													{element.totalContainers}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px]'>
													$
													{monthlyRevStreams[
														'markChargesByMonth'
													][
														`${element._id.monthName} ${element._id.year}`
													]
														? monthlyRevStreams[
																'markChargesByMonth'
														  ][
																`${element._id.monthName} ${element._id.year}`
														  ].toFixed(2)
														: 0.0}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px]'>
													$
													{monthlyRevStreams[
														'officeOrderingChargesByMonth'
													][
														`${element._id.monthName} ${element._id.year}`
													]
														? monthlyRevStreams[
																'officeOrderingChargesByMonth'
														  ][
																`${element._id.monthName} ${element._id.year}`
														  ].toFixed(2)
														: 0.0}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px]'>
													$
													{monthlyRevStreams[
														'pickupChargesByMonth'
													][
														`${element._id.monthName} ${element._id.year}`
													]
														? monthlyRevStreams[
																'pickupChargesByMonth'
														  ][
																`${element._id.monthName} ${element._id.year}`
														  ].toFixed(2)
														: 0.0}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center mr-4 min-w-[192px] max-w-[192px] '>
													$
													{monthlyRevStreams[
														'lateChargesByMonth'
													][
														`${element._id.monthName} ${element._id.year}`
													]
														? monthlyRevStreams[
																'lateChargesByMonth'
														  ][
																`${element._id.monthName} ${element._id.year}`
														  ].toFixed(2)
														: 0.0}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center min-w-[192px] max-w-[192px]'>
													$
													{monthlyRevStreams[
														'raasChargesByMonth'
													][
														`${element._id.monthName} ${element._id.year}`
													]
														? monthlyRevStreams[
																'raasChargesByMonth'
														  ][
																`${element._id.monthName} ${element._id.year}`
														  ].toFixed(2)
														: 0.0}
												</div>
												<div style={lineStyle}></div>
												<div className='my-1 text-center min-w-[192px] max-w-[192px]'>
													$
													{monthlyRevStreams[
														'onPremiseChargesByMonth'
													][
														`${element._id.monthName} ${element._id.year}`
													]
														? monthlyRevStreams[
																'onPremiseChargesByMonth'
														  ][
																`${element._id.monthName} ${element._id.year}`
														  ].toFixed(2)
														: 0.0}
												</div>
											</div>
										</div>
									)
								})}
							</div>
						) : (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
					</div>
					<Footer />
				</div>
			)
		} else {
			navigate('/admin/home')
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, AppInfo, Aggregation }) => ({
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	runningAggregation: Aggregation.runningAggregation,
	aggregationError: Aggregation.aggregationError,
	aggregationResult: Aggregation.aggregationResult,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetAggregator: () => dispatch(runAggregation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRevenue)
