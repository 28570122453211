import {
	CardElement,
	Elements,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

import { getUser } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import {
	getPaymentMethods,
	resetCardAdded,
	storePaymentMethod,
} from '../../store/payments/actions'
import {
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
} from '../../components/Elements'
import { BounceLoader } from 'react-spinners'

const OfficeCreditCardForm = (state) => {
	const {
		form,
		setForm,
		formErrors,
		setStripePaymentMethod,
		user,
		loadingUser,
		loggedIn,
		cardAdded,
		loadingAddition,
		cardAddedError,
		paymentMethods,
		loadingPaymentMethods,
		onGetUser,
		onStorePaymentMethod,
		onResetCardAdded,
	} = state

	const bounceLoaderColor = '#507f74'
	const [stripeLoading, setStripeLoading] = useState(false)
	const [addCardError, setAddCardError] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')

	const [usePreviousEmail, setUsePreviousEmail] = useState(false)
	const [addCardSuccess, setAddCardSuccess] = useState(false)
	const stripe = useStripe()
	const elements = useElements()

	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const handleAddCardSubmit = async () => {
		setStripeLoading(true)
		setAddCardError(false)
		setAddCardSuccess(false)
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})
		if (result.error) {
			setStripeLoading(false)
			setAddCardError(true)
		} else {
			setStripeLoading(false)
			setStripePaymentMethod(result.paymentMethod.id)
			setAddCardSuccess(true)
		}
	}

	useEffect(() => {
		setForm({
			...form,
			['paymentEmail']: emailAddress,
		})
	}, [emailAddress])

	useEffect(() => {
		if (usePreviousEmail) {
			setEmailAddress(form.email)
		} else {
			setEmailAddress('')
		}
	}, [usePreviousEmail])

	return (
		<div>
			<p
				className={`${
					formErrors['paymentEmail'] && 'text-red-600'
				} text-sm`}
			>
				EMAIL FOR PAYMENTS
			</p>
			{form.email && form.email != '' && (
				<div className='my-3'>
					<CheckboxGroup
						label='Use the same email as above'
						name='terms'
						className=''
						labelClassName='!text-xs'
						defaultChecked={usePreviousEmail}
						onChange={() => {
							setUsePreviousEmail(!usePreviousEmail)
						}}
					/>
				</div>
			)}
			{!usePreviousEmail && (
				<Input
					type='email'
					label=''
					name='email-address'
					error={formErrors['paymentEmail']}
					onChange={(e) => {
						setEmailAddress(e.target.value)
					}}
					className='bg-white border-2 border-gray-300 mb-3'
				/>
			)}
			<p>Card Info</p>
			<CardElement options={cardElementOptions} />
			{addCardError && (
				<div className='font-bold text-red-600 text-sm'>
					Error occurred while verifying card! Try again or contact
					DeliverZero for help
				</div>
			)}
			{addCardSuccess && (
				<div className='font-bold text-green-600'>
					Card successfully verified!
				</div>
			)}
			{formErrors.officePaymentMethod && (
				<div className='font-bold text-red-600'>
					Please add and verify a card!
				</div>
			)}
			{stripeLoading ? (
				<div className='mt-3 text-center'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			) : (
				<div className='text-center'>
					<Button
						color='green-light'
						size='sm'
						text='Verify Card'
						className='w-[100px] mt-2 mb-3 mx-auto'
						onClick={handleAddCardSubmit}
					/>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = ({ User, Payments }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onResetCardAdded: () => dispatch(resetCardAdded()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OfficeCreditCardForm)
