import { call, put, takeEvery } from 'redux-saga/effects'
import {
	ADMIN_GET_AGGREGATORS,
	ADMIN_GET_ALL_CUSTOMERS,
	ADMIN_GET_CLIENTS,
	ADMIN_GET_CUSTOMERS,
	ADMIN_GET_ENV_IMPACT,
	ALERT_SLACK,
	ADMIN_GET_RETURNS,
	ADMIN_GET_RETURNS_COUNT,
	ADMIN_UPLOAD_MENUS,
	CREATE_ROUTE,
	GET_DZ_PICKUPS,
	ADMIN_GET_PLATFORMS,
	ADMIN_GET_CUSTOMERS_BOX_COUNT,
	GET_USAGE_DATA,
} from './actionTypes'
import {
	getAdminAggregatorsError,
	getAdminAggregatorsSuccess,
	getAdminClientsError,
	getAdminClientsSuccess,
	getAdminCustomersError,
	getAdminCustomersSuccess,
	getAllCustomersError,
	getAllCustomersSuccess,
	getReturnsError,
	getReturnsSuccess,
	getReturnsCountSuccess,
	getEnvImpactSuccess,
	alertSlackError,
	alertSlackSuccess,
	createRouteSuccess,
	createRouteError,
	getDzPickupsSuccess,
	getDzPickupsError,
	getAdminPlatformsSuccess,
	getAdminPlatformsError,
	getAdminCustomersBoxCountSuccess,
	getAdminCustomersBoxCountError,
	getUsageDataError,
	getUsageDataSuccess,
} from './actions'
import {
	createOptimizedRoute,
	getAggregators,
	getClients,
	getCustomers,
	getDeliverZeroCustomerPickups,
	getEnvironmentalImpact,
	getReturns,
	getReturnsCount,
	uploadMenus,
	getAdminPlatforms,
	sendSlackAlertApi,
	getAdminCustomersBoxCount,
	getUsageData,
} from '../../model/admin'
import {
	adminUploadMenusError,
	adminUploadMenusSuccess,
} from '../admin/actions'

function* fetchReturns(action) {
	// console.log('fetchReturns', action)
	try {
		const returns = yield call(getReturns, action.payload)
		yield put(getReturnsSuccess(returns))
	} catch (error) {
		yield put(getReturnsError(error))
	}
}

function* fetchReturnsCount(action) {
	// console.log('fetchReturnsCount', action)
	try {
		const returnsCount = yield call(getReturnsCount, action.payload)
		yield put(getReturnsCountSuccess(returnsCount))
	} catch (error) {
		yield put(getReturnsError(error))
	}
}

function* fetchCustomers(action) {
	try {
		const customers = yield call(getCustomers, action.payload)
		yield put(getAdminCustomersSuccess(customers))
	} catch (error) {
		yield put(getAdminCustomersError(error))
	}
}

function* fetchClients(action) {
	try {
		const clients = yield call(getClients, action.payload)
		yield put(getAdminClientsSuccess(clients))
	} catch (error) {
		yield put(getAdminClientsError(error))
	}
}

function* fetchAggregators(action) {
	try {
		const aggs = yield call(getAggregators, action.payload)
		yield put(getAdminAggregatorsSuccess(aggs))
	} catch (error) {
		yield put(getAdminAggregatorsError(error))
	}
}

function* fetchAllCustomers(action) {
	try {
		const customers = yield call(getCustomers, action.payload)
		yield put(getAllCustomersSuccess(customers))
	} catch (error) {
		yield put(getAllCustomersError(error))
	}
}

function* fetchEnvImpact(action) {
	try {
		const impact = yield call(getEnvironmentalImpact, action.payload)
		yield put(getEnvImpactSuccess(impact))
	} catch (error) {
		console.log(error)
	}
}

function* manualUploadMenus(action) {
	try {
		const orderUploadResponse = yield call(uploadMenus, action.payload)
		yield put(adminUploadMenusSuccess(orderUploadResponse))
	} catch (error) {
		yield put(adminUploadMenusError(error))
	}
}

function* makeRoute(action) {
	try {
		const routeResponse = yield call(createOptimizedRoute, action.payload)
		yield put(createRouteSuccess(routeResponse))
	} catch (error) {
		yield put(createRouteError(error))
	}
}

function* retrieveDzPickups(action) {
	try {
		const dzPickups = yield call(
			getDeliverZeroCustomerPickups,
			action.payload
		)
		yield put(getDzPickupsSuccess(dzPickups))
	} catch (error) {
		yield put(getDzPickupsError(error))
	}
}

function* fetchPlatforms(action) {
	try {
		const customers = yield call(getAdminPlatforms, action.payload)
		yield put(getAdminPlatformsSuccess(customers))
	} catch (error) {
		yield put(getAdminPlatformsError(error))
	}
}

function* fetchACustomersBoxCount(action) {
	try {
		const customers = yield call(getAdminCustomersBoxCount, action.payload)
		yield put(getAdminCustomersBoxCountSuccess(customers))
	} catch (error) {
		yield put(getAdminCustomersBoxCountError(error))
	}
}

function* alertSlack(action) {
	try {
		const response = yield call(sendSlackAlertApi, action.payload)
		yield put(alertSlackSuccess(response))
	} catch (error) {
		yield put(alertSlackError(error))
	}
}

function* fetchUsageData(action) {
	try {
		const data = yield call(getUsageData, action.payload)
		yield put(getUsageDataSuccess(data))
	} catch (error) {
		yield put(getUsageDataError(error))
	}
}

function* adminSaga() {
	yield takeEvery(ADMIN_GET_RETURNS, fetchReturns)
	yield takeEvery(ADMIN_GET_CUSTOMERS, fetchCustomers)
	yield takeEvery(ADMIN_GET_CUSTOMERS_BOX_COUNT, fetchACustomersBoxCount)
	yield takeEvery(ADMIN_GET_CLIENTS, fetchClients)
	yield takeEvery(ADMIN_GET_ALL_CUSTOMERS, fetchAllCustomers)
	yield takeEvery(ADMIN_GET_AGGREGATORS, fetchAggregators)
	yield takeEvery(ADMIN_GET_RETURNS_COUNT, fetchReturnsCount)
	yield takeEvery(ADMIN_GET_ENV_IMPACT, fetchEnvImpact)
	yield takeEvery(ADMIN_UPLOAD_MENUS, manualUploadMenus)
	yield takeEvery(CREATE_ROUTE, makeRoute)
	yield takeEvery(GET_DZ_PICKUPS, retrieveDzPickups)
	yield takeEvery(ADMIN_GET_PLATFORMS, fetchPlatforms)
	yield takeEvery(ALERT_SLACK, alertSlack)
	yield takeEvery(GET_USAGE_DATA, fetchUsageData)
}

export default adminSaga
