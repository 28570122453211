import React from 'react'
import Header from '../../components/Header'
import { Footer } from '../../components'

function Terms() {
	return (
		<>
			<Header />
			<div className=''>
				<div className='ml-10 md:ml-28 text-3xl md:text-5xl font-header text-green-600 w-full mb-4 mt-12 md:mb-8'>
					Terms of Use
				</div>
			</div>

			<div className='flex flex-col justify-center'>
				<div className='w-[90%] max-w-[950px] px-5 mx-auto pt-10 pb-10 overflow-hidden'>
					<div>
						<p className='mb-3'>
							DeliverZero, Inc. and its affiliates (“DeliverZero,”
							“we,” “our,” or “us”) own and operate a website
							DeliverZero.com and associated webpages (“Sites”)
							These Terms of Use (the “Agreement”) constitute a
							contract between you and us that governs your access
							and use of the Sites. By accessing and/or using the
							Sites or our services through the Sites, you agree
							to all the terms and conditions of this Agreement.
							If you do not agree, do not use the Sites. As used
							in this Agreement, “you” means any visitor, user, or
							other person who accesses our Sites.
						</p>
						<p className='mb-3'>
							IMPORTANT: PLEASE REVIEW THE “DISPUTE RESOLUTION”
							SECTION SET FORTH BELOW CAREFULLY, AS IT WILL
							REQUIRE YOU TO RESOLVE ALL DISPUTES WITH DeliverZero
							THROUGH BINDING INDIVIDUAL ARBITRATION. YOU
							ACKNOWLEDGE AND AGREE THAT YOU AND DeliverZero ARE
							EACH WAIVING THE RIGHT TO A TRIAL BY JURY. YOU
							FURTHER ACKNOWLEDGE AND AGREE THAT YOU WAIVE YOUR
							RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER
							IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE
							PROCEEDING AGAINST DeliverZero. BY ENTERING THIS
							AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE
							READ AND UNDERSTOOD, AND AGREE TO BE BOUND BY, ALL
							OF THE TERMS AND CONDITIONS OF THIS AGREEMENT AND
							HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS
							IMPORTANT DECISION.
						</p>
						<h2 className='mb-3'>ABOUT DeliverZero</h2>
						<p className='mb-3'>
							DeliverZero connects diners with local takeout
							restaurants. You may order food through the Sites to
							be delivered or picked up from particular
							restaurants.
						</p>
						<p className='mb-3'>
							DeliverZero is not a restaurant or food preparation
							entity. The restaurants available on our Sites
							(collectively, the “restaurants”) operate
							independently of DeliverZero. The restaurants are
							required to comply with federal, state, and local
							laws, rules, regulations, and standards pertaining
							to the preparation, sale, and marketing of food,
							including, without limitation, food preparation and
							safety and menu disclosure. DeliverZero is not
							liable or responsible for the restaurants' food
							preparation or safety and does not verify their
							compliance with all applicable laws. DeliverZero
							provides restaurants with NSF-certified restaurant
							equipment in which they may serve food. It is up to
							the restaurants to use that equipment in a manner
							consistnet with applicable law. In addition,
							DeliverZero does not guarantee the quality of what
							the restaurants or other food purveyors sell, nor
							does it guarantee the services provided by them,
							including, without limitation, in those cases where
							they provide the delivery services or engage another
							third party delivery service. DeliverZero does not
							independently verify, and is not liable for,
							representations made by restaurants regarding their
							food, including, without limitation, any menu- or
							restaurant-level descriptors or disclosures.
						</p>
						<p className='mb-3'>USING DeliverZero</p>
						<p className='mb-3'>
							You may only create and hold one account on each
							Site for your personal use (an “Account”). You may
							have another account if you are using the Site as
							part of a corporate account created for business
							purposes pursuant to a separate agreement with
							DeliverZero. In consideration of the use of the
							Sites and the services contained therein, you agree
							that you are able to create a binding legal
							obligation with DeliverZero, and you also agree to:
							(a) provide true, accurate, current, and complete
							information about yourself, and (b) maintain and
							promptly update the personal information you provide
							to keep it true, accurate, current, and complete.
						</p>
						<p className='mb-3'>
							The Sites may permit you to make purchases without
							an Account or without logging in to your Account. If
							you make a purchase in this manner, we may create an
							Account for you based on the information provide to
							us in connection with the transaction (e.g., your
							name, phone number, email address, and other
							transaction information). If you are a minor in the
							jurisdiction in which you reside (generally under
							the age of 18), you must have the permission of, and
							be directly supervised by, your parent or legal
							guardian to use the Sites, and your parent or legal
							guardian must read and agree to this Agreement prior
							to your using the Sites. Notwithstanding the
							foregoing, you are not authorized to use the Sites
							if you are under the age of 13. If you are using the
							Sites on behalf of an entity, organization, or
							company, you represent and warrant that you have the
							authority to bind that organization to this
							Agreement and you agree to be bound by this
							Agreement on behalf of that organization.
						</p>
						<p className='mb-3'>
							If you provide any information that is untrue,
							inaccurate, not current or incomplete, including,
							without limitation, having an invalid or expired
							payment method on file, or if DeliverZero has
							reasonable grounds to suspect that any information
							you provide is untrue, inaccurate, not current or
							incomplete, DeliverZero has the right to block your
							current or future use of the Sites (or any portion
							thereof) and/or terminate this Agreement with you.
							If your Account is cancelled for any or no reason,
							you may forfeit any pending, current, or future
							account credits or promotional offers and any other
							forms of unredeemed value in or associated with your
							Account without prior notice to you.
						</p>
						<p className='mb-3'>
							You are responsible for maintaining the
							confidentiality and security of your Account
							including your password and, if applicable, any
							password for Facebook, Google, or other third party
							login. You are also responsible for all activities
							or any other actions that occur under or that are
							taken in connection with your Account. You agree to:
							(a) immediately notify DeliverZero of any known or
							suspected unauthorized use(s) of your password or
							Account, or any known or suspected breach of
							security, including, without limitation, loss,
							theft, or unauthorized disclosure of your password
							or credit card information; and (b) ensure that you
							exit from your Account at the end of each session.
							DeliverZero will not be liable for any injury, loss,
							or damage of any kind arising from or relating to
							your failure to comply with (a) and/or (b) or for
							any acts or omissions by you or someone else who is
							using your Account and/or password.
						</p>
						<h2 className='mb-3'>PAYMENT AND OUR CREDIT POLICY</h2>
						<p className='mb-3'>
							Certain features of the Sites, including, without
							limitation, the placing of orders, may require you
							to make certain payments. When paid by you, these
							payments are final and non-refundable, unless
							otherwise determined by DeliverZero, such as
							refundable deposits for the use of DeliverZero
							containers. DeliverZero, in its sole discretion, may
							offer credits or refunds on a case-by-case basis
							including, by way of example, in the event of an
							error with your order or in the amounts you were
							charged.
						</p>
						<p className='mb-3'>
							When you order through DeliverZero, you may receive
							a reusable container or containers. You must return
							such container(s) to DeliverZero, within 3 weeks of
							your receipt of the container(s). If you do not
							return them, you will be charged, and you grant
							DeliverZero the right to charge, your credit card or
							other authorized method of payment $5.00, plus
							applicable taxes, for each unreturned container.
						</p>
						<p className='mb-3'>
							DeliverZero will charge, and you authorize
							DeliverZero to charge, the payment method you
							specify at the time of purchase. If you pay any
							amounts with a credit card, DeliverZero may seek
							pre-authorization of your credit card account prior
							to your purchase to verify that the credit card is
							valid and has credit available for your intended
							purchase. In the event DeliverZero advances payment
							for any of your orders placed via the Sites (e.g.,
							if you sign up for a corporate account), DeliverZero
							may separately send you invoices for payment of
							those advanced amounts. In the event that you fail
							to pay such invoices within thirty (30) days of the
							date of such invoice (the “Payment Due Date”), you
							grant DeliverZero the right, but not the obligation,
							to charge the credit card you provide with your
							Account at any time after any Payment Due Date,
							unless prohibited by law.
						</p>
						<p className='mb-3'>
							DeliverZero reserves the right to establish, remove,
							and/or revise prices, fees, taxes, and/or surcharges
							for any or all services or goods obtained through
							the use of the services at any time. DeliverZero may
							also, in its sole discretion, make promotional
							offers with different features and different rates
							to any or all of our customers. Unless made to you,
							these promotional offers will have no bearing on
							your obligation to pay the amounts charged.
						</p>
						<h2 className='mb-3'>
							OUR MATERIALS AND LICENSE TO YOU
						</h2>
						<p className='mb-3'>
							With the exception of User Content (defined below),
							the Sites and everything on them, from text to
							photos to videos to graphics and software,
							(collectively, the "Materials") are owned by or
							licensed to DeliverZero. The Sites and the Materials
							are protected by copyright, trademark, trade dress,
							domain name, patent, trade secret, international
							treaties, and/or other proprietary rights and laws
							of the United States and other countries. Except as
							otherwise indicated on the Sites and Materials and
							except for the trademarks, service marks, logos and
							trade names of other companies that are displayed on
							the Sites and Materials, all trademarks, service
							marks, logos, trade dress, and trade names are
							proprietary to DeliverZero, including, without
							limitation, GH; DeliverZero; DeliverZero for Work;
							SEAMLESS; DeliverZero for Restaurants; Eat24;
							AllMenus; MenuPages; Yummy Rummy; and the Eat24.com,
							DeliverZero.com, and Seamless.com trade dress.
							Please be advised that DeliverZero enforces its
							intellectual property rights to the fullest extent
							of the law.
						</p>
						<p className='mb-3'>
							Subject to your compliance with this Agreement, we
							grant you a limited, non-exclusive,
							non-transferable, non-sublicensable, and revocable
							license to access and use the Sites and/or the
							Materials for your personal and noncommercial use,
							solely as expressly permitted by this Agreement and
							subject to all the terms and conditions of this
							Agreement, all applicable intellectual property
							laws, and any Additional Terms (as defined below)
							contained on the Sites and/or the Materials. Any
							other use of the Sites and/or the Materials is
							strictly prohibited. The Sites and the Materials may
							not be copied, republished, uploaded, posted,
							transmitted, distributed in any way, and/or modified
							without our express written permission. Nothing
							contained on the Sites and/or Materials should be
							interpreted as granting to you any license or right
							to use any of the Materials (other than as provided
							herein) and/or third party proprietary content on
							the Sites without the express written permission of
							DeliverZero or the appropriate third party owner, as
							applicable. Any rights not expressly granted herein
							are reserved by DeliverZero and DeliverZero’s
							licensors.
						</p>
						<p className='mb-3'>
							You may not rent, lease, redistribute, sell,
							sublicense, decompile, reverse engineer,
							disassemble, or otherwise reduce the Sites and/or
							the Materials, in whole or in part, to a
							human-perceivable form for any purpose, including,
							without limitation, to build a product and/or
							service competitive with the Sites and its related
							services. You are not authorized to download any
							content from the Sites, including, without
							limitation, the Materials, and if you do,
							DeliverZero will not be responsible in any way for
							any damage to your computer system or loss of data
							that results from such download.
						</p>
						<h2 className='mb-3'>YOUR CONTENT AND CONDUCT</h2>
						<ol className='mb-3'>
							<li> User Conduct</li>
						</ol>
						<p className='mb-3'>
							By accessing the Sites, you agree:
						</p>
						<ul className='mb-3'>
							<li>
								- to comply with all applicable laws in
								connection with your use of the Sites,
								including, without limitation, laws regarding
								online conduct and submission of acceptable User
								Content;
							</li>
							<li>
								- not to use the Sites or the services or submit
								content to the Sites if you are under the age of
								13;
							</li>
							<li>
								- not to use the Sites to purchase alcohol
								unless you and the alcohol recipient are 21 or
								older and present a valid photo
								identification(s) verifying your or the
								recipient’s age at the time of alcohol delivery;
							</li>
							<li>
								- not to access the Sites or services using a
								third party's account/registration without the
								express consent of the account - holder and not
								to attempt to impersonate another user or
								person;
							</li>
							<li>
								- not to attempt, through any means, to gain
								unauthorized access to any part of the Sites
								and/or any service, other account, computer
								system, and/or network connected to any
								DeliverZero server and not to otherwise use the
								Sites or services in any manner that could
								damage, disable, overburden, and/or impair any
								DeliverZero server, or the network(s) connected
								to any DeliverZero server, and/or interfere with
								any other party's use and enjoyment of the
								Sites;
							</li>
							<li>
								- not to advertise to, or solicit, any user,
								restaurant, or other business to buy or sell any
								products or services, or use any information
								obtained from the Sites or the DeliverZero
								services in order to contact, solicit, or
								advertise or sell to any user, restaurant, or
								other business, in each case, unless
								specifically authorized in writing by
								DeliverZero;
							</li>
							<li>
								- not to deep-link or frame to the Sites and/or
								access the Sites manually and/or with any robot,
								spider, web crawler, extraction software,
								automated process, and/or device or other means
								to scrape, copy, and/or monitor any portion of
								the Sites and/or any Materials and/or other
								content on the Sites, unless specifically
								authorized in writing by DeliverZero;
							</li>
							<li>
								- not to conduct any scraping, indexing,
								surveying, data mining, or any other kind of
								systematic retrieval of data or other content
								from the Sites;
							</li>
							<li>
								- not to create or compile, directly or
								indirectly, any collection, compilation,
								database, or directory from the Site or
								Materials;
							</li>
							<li>
								- not to create restaurant reviews or blog
								entries for or with any commercial or other
								purpose or intent that does not in good faith
								comport with the purpose or spirit of the Sites;
							</li>
							<li>
								- not to copy, publish, or redistribute any
								coupon or discount code or act in bad faith in
								an attempt to manipulate or gain an unintended
								commercial benefit from incentive offers;
							</li>
							<li>
								- not to harass, annoy, intimidate, or threaten
								any DeliverZero employees, contractors, or
								agents engaged in providing any portion of
								DeliverZero's services;
							</li>
							<li>
								- not to engage in any criminal or tortious
								activity, including, without limitation, fraud,
								spamming (including, without limitation, by
								email or instant message), sending of viruses or
								other harmful files, copyright infringement,
								patent infringement, or theft of trade secrets
								or otherwise deleting the copyright or other
								proprietary rights notice from any User Content
								(as defined below) or from any portion of the
								Sites or DeliverZero's services;
							</li>
							<li>
								- not to disrupt, interfere with, or otherwise
								harm or violate the security of the Sites, or
								any services, system resources, accounts,
								passwords, servers or networks connected to or
								accessible through the Sites or affiliated or
								linked sites (including, without limitation,
								those of our restaurant partners); and
							</li>
							<li>
								- not to use the Sites for any illegal purposes
								or in any way otherwise inconsistent with any
								and all applicable laws, rules, and regulations.
							</li>
						</ul>
						<p className='mb-3'>
							You agree to comply with the above user conduct, and
							agree not assist or permit any person in engaging in
							any conduct that does not comply with the above user
							conduct. Further, you agree that the consequences of
							commercial use or re-publication of User Content or
							Materials from the Sites or other violations of the
							foregoing proscriptions may be so serious and
							incalculable that monetary compensation may not be a
							sufficient or appropriate remedy, and that
							DeliverZero will be entitled to temporary and
							permanent injunctive relief to prohibit such use or
							activity without the need to prove damages.
						</p>
						<ol className='mb-3'>
							<li> Content You Provide</li>
						</ol>
						<p className='mb-3'>
							DeliverZero may provide you with interactive
							opportunities (i) on the Sites, including, without
							limitation, features such as user ratings and
							reviews, saved favorites, liked items and bookmarked
							restaurants, user profiles and pictures, as well as
							(ii) through other communications with you,
							including, without limitation, through text (“SMS”)
							or multimedia (“MMS”) messages (collectively,
							"Interactive Areas"). You represent and warrant that
							you are the owner of and/or otherwise have the right
							to provide all information, comments, reviews,
							ratings, photographs and/or other materials and/or
							content that you submit, upload, post, publish,
							and/or otherwise make available to DeliverZero
							through the Sites or otherwise in connection with
							your use of our services, including, without
							limitation, information and materials provided or
							made available in connection with any Facebook,
							Google, or other third party login (“User Content”).
							User Content includes, without limitation, textual,
							visual, or audio content and information, whether
							transmitted via the Sites, SMS or MMS message, or
							otherwise.
						</p>
						<p className='mb-3'>III. Use of Your Content</p>
						<p className='mb-3'>
							You grant DeliverZero an irrevocable, transferable,
							paid up, royalty-free, perpetual, non-exclusive
							worldwide sublicensable license to use, copy,
							display, publish, modify, remove, publicly perform,
							translate, create derivative works from, distribute,
							and/or otherwise use User Content in connection with
							DeliverZero's or its partners’ or affiliates’
							business and in all forms of media now known or
							hereafter invented (collectively, the “Uses”)
							without notification to and/or approval by you. You
							further grant DeliverZero a license to use your
							username and/or other user profile information,
							including, without limitation, your ratings history
							and how long you have been a DeliverZero diner, to
							attribute User Content to you in connection with the
							Uses, if we choose to do so, again without
							notification to and/or approval by you. Further, if
							you provide any suggestions, input, or other
							feedback relating to the Sites or the services we
							provide (collectively, the “Feedback”), DeliverZero
							shall have the right to freely and fully exercise
							and exploit the Feedback in connection with our
							business or the business of our partners or
							affiliates, without notice to, approval by, or
							compensation to you. Accordingly, you hereby grant
							DeliverZero a royalty-free, perpetual, irrevocable,
							transferable, sublicensable, worldwide, nonexclusive
							license to all rights necessary to incorporate and
							use the Feedback for any purpose.
						</p>
						<p className='mb-3'>
							User Content transmitted to certain parts of the
							Sites, including, without limitation, restaurant
							pages and certain Interactive Areas, may be posted
							in public areas on our Sites, including, without
							limitation, in a compilation format, and as such
							will be publicly visible and accessible. You further
							understand and agree that you may be exposed to
							third party User Content that is inaccurate,
							objectionable, inappropriate for children, or
							otherwise unsuited to your purpose. DeliverZero and
							its parents, subsidiaries, affiliates, and each of
							their officers, directors, employees, successors,
							assigns, licensors, licensees, designees, business
							partners, contractors, agents and representatives
							(collectively, the "Released Parties") will not be
							responsible for, and you hereby expressly release
							the Released Parties from, any and all liability for
							the action of any and all third parties with respect
							to your User Content, or for any damages you allege
							to incur as a result of or relating to any third
							party User Content.
						</p>
						<ol className='mb-3'>
							<li> Conduct within Interactive Areas</li>
						</ol>
						<p className='mb-3'>
							By transmitting User Content, you agree to follow
							the standards of conduct below, and any additional
							standards that may be stated on the Sites. We expect
							your cooperation in upholding our standards. You are
							responsible for all of your User Content. You agree
							not to provide any User Content that:
						</p>
						<ul className='mb-3'>
							<li>
								- is unlawful, harmful to adults or minors,
								threatening, abusive, harassing, tortious,
								defamatory, vulgar, obscene, profane, offensive,
								invasive of another's privacy, hateful, and/or
								racially, ethnically, and/or otherwise
								objectionable; has a commercial, political, or
								religious purpose;
							</li>
							<li>
								- is false, misleading, and/or not written in
								good faith;
							</li>
							<li>
								- infringes any patent, trademark, trade secret,
								copyright, right of privacy and/or publicity,
								and/or other proprietary rights of any person
								and/or entity;
							</li>
							<li>
								- is illegal and/or promotes illegal activity;
							</li>
							<li>
								- contains unauthorized advertising and/or
								solicits users to a business other than those on
								the Sites; and/or
							</li>
							<li>
								- is intended to interrupt, destroy, or limit
								the functionality or integrity of any computer
								software, hardware, or Materials on the Sites or
								other websites.
							</li>
						</ul>
						<p className='mb-3'>
							We do our best to encourage civility and discourage
							disruptive communication on the Sites. We also do
							our best to discourage communications that incite
							others to violate our standards. DeliverZero may
							monitor any and all use of the Sites, including,
							without limitation, interactions between our users;
							however, we are under no obligation to do so. We may
							manage the Sites in a manner intended to protect our
							property and rights and to facilitate the proper
							functioning of the Sites. If any User Content or
							conduct on our Sites violates our standards, or any
							other terms and conditions of this Agreement; or
							interferes with other peoples' enjoyment of the
							Materials or our Sites; or is inappropriate in our
							judgment; we reserve the right, in our sole
							discretion, to change, delete or remove, in part or
							in full, any such User Content or Materials, and we
							further reserve the right to terminate or suspend
							access to any Interactive Areas or any Sites.
							DeliverZero will cooperate with local, state, and/or
							federal authorities to the extent required by
							applicable law in connection with User Content.
						</p>
						<ol className='mb-3'>
							<li> Ratings and Reviews</li>
						</ol>
						<p className='mb-3'>
							The Sites and other Interactive Areas may allow you
							to rate (“Ratings”) and post reviews (“Reviews”) of
							restaurants and other businesses. Such Ratings and
							Reviews are considered User Content and are governed
							by the terms and conditions of this Agreement,
							including, without limitation, your agreement
							regarding your use of Interactive Areas and the
							Sites' standards of conduct. Ratings and Reviews are
							not endorsed by DeliverZero, and do not represent
							the views of DeliverZero or of any affiliate or
							partner of DeliverZero. DeliverZero does not assume
							liability for Ratings and Reviews or for any claims,
							liabilities, or losses resulting from any Ratings
							and Reviews. We strive to maintain a high level of
							integrity with our Ratings and Reviews and other
							User Content. Therefore, all Ratings and Reviews
							must comply with the following criteria: (1) before
							posting a Rating or Review, you must have had
							first-hand experience with the restaurant within the
							7-day period prior to your review; (2) you may not
							have a proprietary or other affiliation with either
							the restaurant or any of its competitors; (3) you
							may not draw any legal conclusions regarding the
							restaurants' products, services, or conduct; and (4)
							your review must otherwise comply with the terms of
							this Agreement. Any Rating and/or Review that we
							determine, in our sole discretion, could diminish
							the integrity of the Ratings and Reviews, the
							Materials and/or the Sites may be removed or
							excluded by us without notice.
						</p>
						<h2 className='mb-3'>
							COMMUNICATIONS &amp; TEXT MESSAGES
						</h2>
						<p className='mb-3'>
							By voluntarily providing your cell phone number to
							DeliverZero, you agree that DeliverZero may contact
							you by telephone, SMS, or MMS messages at that phone
							number, and you hereby consent to receiving such
							communications for transactional, operational, or
							informational purposes. You understand that such
							messages may be sent using an automatic telephone
							dialing system. You may unsubscribe from receiving
							text messages from DeliverZero at any time. To
							revoke your consent to receiving SMS or MMS messages
							from DeliverZero, please follow the unsubscribe
							procedures described below.
						</p>
						<p className='mb-3'>
							Diners placing orders through the Sites may receive
							two or more order status messages from DeliverZero
							about each order. To unsubscribe from order-related
							messages, just reply "STOP" to the number sending
							the message. To resubscribe, text “START” to the
							same number from the phone number associated with
							your account. If you need assistance, text “HELP”.
							Please note that unsubscribing from one Site will
							not automatically unsubscribe you from another Site.
							For example, if you unsubscribe from DeliverZero
							order-related messages, you may still receive
							order-related messages when you place an order
							through Seamless, unless you also unsubscribe from
							Seamless order-related messages.
						</p>
						<p className='mb-3'>
							Additionally, diners may receive messages from
							DeliverZero following receipt of a completed order
							soliciting feedback and/or other information
							relating to the order. You may unsubscribe from all
							such feedback messages by replying "STOP" to the
							number sending the feedback messages. To
							resubscribe, text "START" to the number sending the
							feedback messages using the phone number associated
							with your account. Please note that unsubscribing
							from such feedback texts will not prevent you from
							receiving texts from DeliverZero, the restaurant, or
							your delivery person regarding your order or its
							delivery unless you also text "STOP" to the number
							sending the order-related messages, and even in such
							event, you may still receive individual texts from
							the restaurant or your delivery person to enable
							successful delivery of your order.
						</p>
						<p className='mb-3'>
							You may also receive text messages in response to
							certain Customer Care requests. To unsubscribe from
							Customer Care messages, just reply to the message by
							texting “STOP” to the number sending the message.
						</p>
						<p className='mb-3'>
							If you unsubscribe from receiving text messages from
							DeliverZero through the process described above, you
							may continue to receive text messages for a short
							period while DeliverZero processes your request(s).
							If you change or deactivate the phone number you
							provided to DeliverZero, you have an affirmative
							obligation to update your account information and
							the phone number(s) attached to your account to
							prevent us from inadvertently communicating with
							anyone who acquires any phone number(s) previously
							attributed to you, and any new phone number(s) you
							attach to your account may receive DeliverZero’s
							standard SMS or MMS messages unless you also
							unsubscribe via the above procedures.
						</p>
						<p className='mb-3'>
							Standard data and message rates may apply for SMS
							and MMS alerts, whether you send or receive such
							messages. Please contact your mobile phone carrier
							for details. Your mobile phone carrier (e.g.
							T-Mobile, AT&amp;T, Verizon, etc.) is not liable for
							delayed or undelivered messages. If you require
							assistance, please call us at 516.459.4898.
						</p>
						<h2 className='mb-3'>DISCLAIMER</h2>
						<p className='mb-3'>
							THE SITES, THE MATERIALS, AND ALL OTHER CONTENT ON
							THE SITES ARE PROVIDED "AS IS" AND “AS AVAILABLE”
							AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS
							OR IMPLIED, UNLESS OTHERWISE SPECIFIED IN WRITING.
							TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW,
							THE RELEASED PARTIES DISCLAIM, WITH RESPECT TO THE
							MATERIALS AND ALL OTHER CONTENT ON THE SITES, ALL
							WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR
							OTHERWISE, INCLUDING, BUT NOT LIMITED TO IMPLIED
							WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
							PARTICULAR PURPOSE AND NON-INFRINGEMENT. DeliverZero
							DOES NOT REPRESENT OR WARRANT THAT THE SITES, THE
							MATERIALS AND/OR THE OTHER CONTENT ON THE SITES WILL
							BE SECURE, UNINTERRUPTED, AND/OR ERROR-FREE, THAT
							DEFECTS WILL BE CORRECTED, AND/OR THAT THE SITES,
							THE MATERIALS, AND/OR OTHER CONTENT ON THE SITES ARE
							FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS.
							DeliverZero DOES NOT WARRANT OR MAKE ANY
							REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF
							THE USE OF THE SITES, THE MATERIALS, AND/OR ANY
							OTHER CONTENT ON THE SITES IN TERMS OF THEIR
							CORRECTNESS, ACCURACY, RELIABILITY, TIMELINESS,
							COMPLETENESS, CURRENTNESS, OR OTHERWISE, INCLUDING,
							WITHOUT LIMITATION, THE SAFETY, QUALITY, AND/OR
							TIMING OF A DELIVERY ORDERED ON THE SITES, AND/OR
							THE FOOD OR OTHER PRODUCTS DELIVERED. YOU (AND NOT
							DeliverZero) ASSUME THE ENTIRE COST OF ALL NECESSARY
							SERVICING, REPAIR, OR CORRECTION RELATING TO YOUR
							USE OF THE SITES, THE MATERIALS, AND/OR OTHER
							CONTENT ON THE SITES. APPLICABLE LAW MAY NOT ALLOW
							THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
							EXCLUSION MAY NOT FULLY APPLY TO YOU.
						</p>
						<p className='mb-3'>
							DeliverZero SHALL NOT BE LIABLE FOR DELAY OR FAILURE
							IN PERFORMANCE RESULTING FROM CAUSES BEYOND
							DeliverZero'S REASONABLE CONTROL, INCLUDING, WITHOUT
							LIMITATION, DELAYS AND OTHER PROBLEMS INHERENT IN
							THE USE OF THE INTERNET AND ELECTRONIC
							COMMUNICATIONS. DeliverZero IS NOT RESPONSIBLE FOR
							ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE
							RESULTING FROM SUCH PROBLEMS.
						</p>
						<p className='mb-3'>
							DeliverZero RELIES UPON RESTAURANTS AND OTHER
							THIRD-PARTY FOOD AND BEVERAGE PROVIDERS TO PROVIDE
							ACCURATE ALLERGEN AND DIETARY INFORMATION AND
							GENERAL PRODUCT SAFETY. DeliverZero DOES NOT
							REPRESENT OR WARRANT THAT THE INFORMATION ACCESSIBLE
							THROUGH THE SERVICE IS ACCURATE, COMPLETE, RELIABLE,
							CURRENT, OR ERROR-FREE, INCLUDING, WITHOUT
							LIMITATION, MENUS, NUTRITIONAL AND ALLERGEN
							INFORMATION, PHOTOS, FOOD QUALITY OR DESCRIPTIONS,
							PRICING, HOURS OF OPERATION, OR REVIEWS. ALL CONTENT
							IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY. THE
							RELIANCE ON ANY INFORMATION PROVIDED THROUGH THE
							SERVICE IS SOLELY AT YOUR OWN RISK, INCLUDING,
							WITHOUT LIMITATION, NUTRITIONAL AND ALLERGEN
							INFORMATION.
						</p>
						<h2 className='mb-3'>LIMITATION OF LIABILITY</h2>
						<p className='mb-3'>
							TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW,
							IN NO EVENT SHALL DeliverZero BE LIABLE TO YOU FOR
							ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
							EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OR
							DAMAGES WHATSOEVER (INCLUDING PERSONAL INJURY, LOSS
							OF DATA, REVENUE, PROFITS, REPUTATION, USE, OR OTHER
							ECONOMIC ADVANTAGE), EVEN IF DeliverZero HAS BEEN
							PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
							DAMAGES, ARISING OUT OF A WARRANTY, CONTRACT, OR
							NEGLIGENCE ACTION THAT IN ANY MANNER ARISES OUT OF
							OR IN CONNECTION WITH THE USE, INABILITY TO USE,
							PERFORMANCE OF, OR SERVICES PROVIDED ON OR THROUGH
							THE SITES. DeliverZero ASSUMES NO RESPONSIBILITY AND
							SHALL NOT BE LIABLE FOR ANY DAMAGES TO, OR VIRUSES
							THAT MAY INFECT YOUR COMPUTER EQUIPMENT OR OTHER
							PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF,
							BROWSING OF, OR DOWNLOADING OF ANY MATERIAL FROM THE
							SITES. DeliverZero ASSUMES NO RESPONSIBILITY OR
							LIABILITY IN ANY MANNER ARISING OUT OF OR IN
							CONNECTION WITH ANY INFORMATION, CONTENT, PRODUCTS,
							SERVICES, OR MATERIAL AVAILABLE ON OR THROUGH THE
							SITES, AS WELL AS ANY THIRD PARTY WEBSITE PAGES OR
							ADDITIONAL WEBSITES LINKED TO THIS SITE, FOR ANY
							ERROR, DEFAMATION, LIBEL, SLANDER, OMISSION,
							FALSEHOOD, OBSCENITY, PORNOGRAPHY, PROFANITY,
							DANGER, INACCURACY CONTAINED THEREIN, OR HARM TO
							PERSON OR PROPERTY CAUSED THEREBY. THESE LIMITATIONS
							SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
							PURPOSE OF ANY LIMITED REMEDY. IN NO EVENT SHALL
							DeliverZero'S TOTAL LIABILITY TO YOU FOR ALL
							DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN
							WARRANTY, CONTRACT, OR TORT EXCEED (A) THE AMOUNT
							PAID BY YOU TO DeliverZero OR A DeliverZero
							RESTAURANT PARTNER, IF ANY, OR (B) $1,000 (WHICHEVER
							IS LESS). BECAUSE SOME JURISDICTIONS DO NOT ALLOW
							THE EXCLUSION OR LIMITATION OF LIABILITY FOR
							CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
							LIMITATIONS MAY NOT APPLY TO YOU.
						</p>
						<p className='mb-3'>
							YOU AND DeliverZero AGREE THAT THE WARRANTY
							DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THIS
							AGREEMENT ARE MATERIAL, BARGAINED-FOR BASES OF THIS
							AGREEMENT, AND THAT THEY HAVE BEEN TAKEN INTO
							ACCOUNT IN DETERMINING THE CONSIDERATION TO BE GIVEN
							BY EACH PARTY UNDER THIS AGREEMENT AND IN THE
							DECISION BY EACH PARTY TO ENTER INTO THIS AGREEMENT.
							YOU AND DeliverZero AGREE THAT THE WARRANTY
							DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THESE
							TERMS OF USE ARE FAIR AND REASONABLE. IF YOU ARE
							DISSATISFIED WITH THE SITE OR DO NOT AGREE TO ANY
							PROVISIONS OF THESE TERMS OF USE, YOUR SOLE AND
							EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE,
							EXCEPT AS MAY BE OTHERWISE PROVIDED FOR IN THIS
							SECTION.
						</p>
						<h2 className='mb-3'>THIRD PARTY LINKS</h2>
						<p className='mb-3'>
							The Sites may contain links to websites that are
							owned, controlled, developed, sponsored and/or
							maintained by third parties and which may be subject
							to additional terms and conditions ("Third Party
							Websites"). DeliverZero does not review, monitor,
							operate and/or control the Third Party Websites and
							DeliverZero makes no guarantees, representations,
							and/or warranties as to, and shall have no liability
							for, the content available on or through and/or the
							functioning of the Third Party Websites. By
							providing access to Third Party Websites,
							DeliverZero is not recommending and/or otherwise
							endorsing the products and/or services provided by
							the sponsors and/or owners of those websites. Your
							access to and/or use of the Third Party Websites,
							including, without limitation, providing
							information, materials and/or other content to the
							Third Party Websites, is entirely at your own risk.
							DeliverZero reserves the right to discontinue links
							to any Third Party Websites at any time and for any
							reason, without notice.
						</p>
						<h2 className='mb-3'>ADDITIONAL TERMS</h2>
						<p className='mb-3'>
							Your use of the Sites is subject to any and all
							additional terms, policies, rules, or guidelines
							applicable to DeliverZero's services or certain
							features of the Sites that we may post or link to on
							the Sites (collectively, the "Additional Terms"),
							such as end-user license agreements, or other
							agreements or rules applicable to particular
							features, promotions, or content on the Sites,
							including, without limitation, the Google
							Maps/Google Earth Additional Terms of Service
							located at
							<a href='https://maps.google.com/help/terms_maps.html'>
								https://maps.google.com/help/terms_maps.html
							</a>
							and the Google Privacy Policy located at
							<a href='https://www.google.com/intl/ALL/policies/privacy/index.html'>
								https://www.google.com/intl/ALL/policies/privacy/index.html
							</a>
							All such Additional Terms are hereby incorporated
							into this Agreement by reference.
						</p>
						<h2 className='mb-3'>COPYRIGHT POLICY</h2>
						<p className='mb-3'>
							DeliverZero respects the intellectual property of
							others, and we ask all of our users to do the same.
							If you believe that your copyrighted work has been
							copied and is accessible on the Sites or a website
							through which our services may be accessed in a way
							that constitutes copyright infringement, please
							provide DeliverZero's Copyright Agent (as set forth
							below) with notification containing the following
							information required by the Digital Millennium
							Copyright Act, 17 U.S.C. §512 (“DMCA”):
						</p>
						<ol className='mb-3'>
							<li>
								- A physical or electronic signature of a person
								authorized to act on behalf of the copyright
								owner of the work that allegedly has been
								infringed;
							</li>
							<li>
								- Identification of the copyrighted work claimed
								to have been infringed, or, if multiple
								copyrighted works allegedly have been infringed,
								then a representative list of such copyrighted
								works;
							</li>
							<li>
								- Identification of the material that is claimed
								to be infringing and that is to be removed or
								access to which is to be disabled, and
								information reasonably sufficient to permit us
								to locate the allegedly infringing material,
								e.g., the specific web page address on the
								Sites;
							</li>
							<li>
								- Information reasonably sufficient to permit us
								to contact the party alleging infringement,
								including an email address;
							</li>
							<li>
								- A statement that the party alleging
								infringement has a good-faith belief that use of
								the copyrighted work in the manner complained of
								is not authorized by the copyright owner or its
								agent, or is not otherwise permitted under the
								law; and
							</li>
							<li>
								- A statement that the information in the
								notification is accurate, and under penalty of
								perjury, that the party alleging infringement is
								authorized to act on behalf of the copyright
								owner of the work that allegedly has been
								infringed.
							</li>
						</ol>
						<p className='mb-3'>
							Please send this notification to our copyright agent
							at: DeliverZero Holdings Inc., Attention: Copyright
							Agent, 111 West Washington Street, Suite 2100,
							Chicago, Illinois 60602.
						</p>
						<p className='mb-3'>
							UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT
							THAT ONLINE MATERIAL IS INFRINGING, YOU MAY BE
							SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND
							CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT
							COSTS, AND ATTORNEYS’ FEES.
						</p>
						<p className='mb-3'>
							Please note that this procedure is exclusively for
							notifying DeliverZero and its affiliates that your
							copyrighted material has been infringed. The
							preceding requirements are intended to comply with
							DeliverZero’s rights and obligations under the DMCA,
							including 17 U.S.C. §512(c), but do not constitute
							legal advice. It may be advisable to contact an
							attorney regarding your rights and obligations under
							the DMCA and other applicable laws.
						</p>
						<p className='mb-3'>
							In accordance with the DMCA and other applicable
							law, DeliverZero has adopted a policy of
							terminating, in appropriate circumstances, users who
							are deemed to be repeat infringers. DeliverZero may
							also in its sole discretion limit access to the
							Sites and/or terminate the Accounts of any users who
							infringe any intellectual property rights of others,
							whether or not there is any repeat infringement.
						</p>
						<h2 className='mb-3'>
							TERMINATION AND VIOLATIONS OF THE AGREEMENT
						</h2>
						<p className='mb-3'>
							Your rights under this Agreement will terminate
							automatically without notice if you fail to comply
							with any provision of this Agreement. Further,
							DeliverZero reserves the right, in its sole and
							absolute discretion, to modify, suspend, or
							discontinue at any time, with or without notice, the
							Sites and/or services offered on or through the
							Sites (or any part thereof), including but not
							limited to the Sites' features, look and feel, and
							functional elements and related services. We will
							have no liability whatsoever on account of any
							change to the Sites or any suspension or termination
							of your access to or use of the Sites. You may
							terminate this Agreement at any time by closing your
							Account, uninstalling all Mobile Application(s) (if
							applicable) and ceasing use of the Sites and
							services provided herein.
						</p>
						<p className='mb-3'>
							Upon termination of this Agreement for any reason or
							no reason: (i) your access rights will terminate and
							you must immediately cease all use of the Sites; and
							(ii) any provision of this Agreement that
							contemplates or governs performance or observance
							subsequent to termination of this Agreement will
							survive the termination of this Agreement, including
							without limitation the following sections: (i) “Your
							Content and Conduct;” (ii) “Disclaimer;” (iii)
							“Limitation of Liability;” (iv) “Important Note to
							New Jersey Consumers;” (v) “Termination and
							Violations of this Agreement;” (vi) “Dispute
							Resolution;” and (vii) “Waiver and Severability.”
						</p>
						<p className='mb-3'>
							DeliverZero reserves the right to seek all remedies
							available at law and in equity for violations of the
							Agreement, including, without limitation, the right
							to block access to the Sites from a particular
							account, device and/or IP address.
						</p>
						<p className='mb-3'>
							You may not assign or transfer this Agreement or
							your rights under this Agreement, in whole or in
							part, by operation of law or otherwise, without our
							prior written consent. We may assign this Agreement
							in whole or in part at any time to any entity
							without your notice or consent. Any purported
							assignment by you in violation of this section shall
							be null and void.
						</p>
						<h2 className='mb-3'>CHANGES TO THE AGREEMENT</h2>
						<p className='mb-3'>
							We may change this Agreement from time to time and
							without prior notice. If we make a change to this
							Agreement, it will be effective as soon as we post
							it and the most current version of this Agreement
							will always be posted under the "Terms of Use" link
							available on our Sites ("Updated Terms"). If we make
							a material change to the Agreement, we may notify
							you. You agree that you will review this Agreement
							periodically. By continuing to access and/or use the
							Sites after we post Updated Terms, you agree to be
							bound by the Updated Terms, and if you do not agree
							to the Updated Terms, you will stop using the Sites.
							Any disputes under this Agreement will be governed
							by the version of Agreement in effect at the time of
							the first event which gave rise to the dispute.
						</p>
						<h2 className='mb-3'>GOVERNING LAW</h2>
						<p className='mb-3'>
							You acknowledge and agree that your access to and/or
							use of the Sites, the Materials, and other content
							on the Sites is subject to all applicable
							international, federal, state, and local laws and
							regulations. The terms, conditions, and policies
							contained in this Agreement shall be governed by and
							construed in accordance with the laws of the State
							of New York, without regard to its conflict of laws
							principles.
						</p>
						<h2>DISPUTE RESOLUTION</h2>
						<ol className='mb-3'>
							<li> Arbitration and Class Action Waiver.</li>
						</ol>
						<p className='mb-3'>
							You agree that all claims, disputes, or
							disagreements that may arise out of the
							interpretation or performance of this Agreement, or
							that in any way relate to your use of the Sites, the
							Materials, and/or other content on the Sites, shall
							be submitted exclusively to binding arbitration,
							except that each party retains the right to bring an
							individual action in small claims court and the
							right to seek injunctive or other equitable relief
							in a court of competent jurisdiction to prevent the
							actual or threatened infringement, misappropriation,
							or violation of a party's copyrights, trademarks,
							trade secrets, patents, or other intellectual
							property rights. Arbitration means that an
							arbitrator and not a judge or jury will decide the
							claim. Rights to prehearing exchange of information
							and appeals may also be limited in arbitration. You
							acknowledge and agree that you and DeliverZero are
							each waiving the right to a trial by jury. You
							further acknowledge and agree that you waive your
							right to participate as a plaintiff or class member
							in any purported class action or representative
							proceeding. Further, unless both you and DeliverZero
							otherwise agree in writing, the arbitrator may not
							consolidate more than one person's claims, and may
							not otherwise preside over any form of any class or
							representative proceeding. If this specific
							paragraph is held unenforceable, then the entirety
							of this "Dispute Resolution" section will be deemed
							void. Except as provided in the preceding sentence,
							this "Dispute Resolution" section will survive any
							termination of these Terms of Use. You further
							acknowledge that any claim arising under this
							Agreement must be brought within one year of its
							accrual or it will be waived.
						</p>
						<ol className='mb-3'>
							<li> Arbitration Rules</li>
						</ol>
						<p className='mb-3'>
							The arbitration will be administered by the American
							Arbitration Association ("AAA") in accordance with
							the Commercial Arbitration Rules and the
							Supplementary Procedures for Consumer Related
							Disputes (the "AAA Rules") then in effect, except as
							modified by this "Dispute Resolution" section. (The
							AAA Rules are available at
							<a href='https://www.adr.org/Rules'>
								https://www.adr.org/Rules
							</a>
							or by calling the AAA at 1-800-778-7879). The
							Federal Arbitration Act will govern the
							interpretation and enforcement of this section.
						</p>
						<p className='mb-3'>III. Arbitration Process</p>
						<p className='mb-3'>
							A party who desires to initiate arbitration must
							provide the other party with a written Demand for
							Arbitration as specified in the AAA Rules. (The AAA
							provides a form Demand for Arbitration at
							<a href='https://www.adr.org/sites/default/files/Demand%20for%20Arbitration%20Consumer%20Arbitration%20Rules.pdf'>
								https://www.adr.org/sites/default/files/Demand%20for%20Arbitration%20Consumer%20Arbitration%20Rules.pdf
							</a>
							and a separate affidavit for waiver of fees for
							California residents only at
							<a href='https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf.'>
								https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf.
							</a>
							The arbitrator will be either a retired judge or an
							attorney licensed to practice law in the county in
							which you reside and will be selected by the parties
							from the AAA's roster of consumer dispute
							arbitrators. If the parties are unable to agree upon
							an arbitrator within seven (7) days of delivery of
							the Demand for Arbitration, then the AAA will
							appoint the arbitrator in accordance with the AAA
							Rules.
						</p>
						<ol className='mb-3'>
							<li> Arbitration Location and Procedure</li>
						</ol>
						<p className='mb-3'>
							Unless you and DeliverZero otherwise agree, the
							arbitration will be conducted in the county where
							you reside. If your claim does not exceed $10,000,
							then the arbitration will be conducted solely on the
							basis of documents you and DeliverZero submit to the
							arbitrator, unless you request a hearing or the
							arbitrator determines that a hearing is necessary.
							If your claim exceeds $10,000, your right to a
							hearing will be determined by the AAA Rules. Subject
							to the AAA Rules, the arbitrator will have the
							discretion to direct a reasonable exchange of
							information by the parties, consistent with the
							expedited nature of the arbitration.
						</p>
						<ol className='mb-3'>
							<li> Arbitrator's Decision</li>
						</ol>
						<p className='mb-3'>
							The arbitrator will render an award within the time
							frame specified in the AAA Rules. The arbitrator's
							decision will include the essential findings and
							conclusions upon which the arbitrator based the
							award. Judgment on the arbitration award may be
							entered in any court having jurisdiction thereof.
							The arbitrator's award damages must be consistent
							with the terms of the "Limitation of Liability"
							section above as to the types and the amounts of
							damages for which a party may be held liable. The
							arbitrator may award declaratory or injunctive
							relief only in favor of the claimant and only to the
							extent necessary to provide the relief warranted by
							the claimant's individual claim. If you prevail in
							arbitration, you will be entitled to an award of
							attorneys' fees and expenses to the extent provided
							under applicable law. DeliverZero will not seek, and
							hereby waives, all rights DeliverZero may have under
							applicable law to recover attorneys' fees and
							expenses if DeliverZero prevails in arbitration.
						</p>
						<ol className='mb-3'>
							<li> Fees</li>
						</ol>
						<p className='mb-3'>
							Your responsibility to pay any AAA filing,
							administrative and arbitrator fees will be solely as
							set forth in the AAA Rules. However, if your claim
							for damages does not exceed $25,000, DeliverZero
							will pay all such fees unless the arbitrator finds
							that either the substance of your claim or the
							relief sought in your Demand for Arbitration was
							frivolous or was brought for an improper purpose (as
							measured by the standards set forth in Federal Rule
							of Civil Procedure 11(b)).
						</p>
						<p className='mb-3'>VII. Changes</p>
						<p className='mb-3'>
							DeliverZero reserves the right to change this
							"Dispute Resolution" section, but any such changes
							will not apply to disputes arising before the
							effective date of such amendment. Notwithstanding
							the provisions of the modification-related
							provisions above, if DeliverZero changes this
							"Dispute Resolution" section after the date you
							first accepted this Agreement (or accepted any
							subsequent changes to this Agreement), you agree
							that your continued use of the Sites after such
							change will be deemed acceptance of those changes.
							If you do not agree to such change, you may reject
							any such change by providing DeliverZero written
							notice of such rejection by the email address
							associated with your account to:
							hello@DeliverZero.com, within 30 days of the date
							such change became effective, as indicated in the
							"Effective" date above. In order to be effective,
							the notice must include your full name and clearly
							indicate your intent to reject changes to this
							"Dispute Resolution" section. By rejecting changes,
							you are agreeing that you will arbitrate any dispute
							between you and DeliverZero in accordance with the
							provisions of this "Dispute Resolution" section as
							of the date you first accepted this Agreement (or
							accepted any subsequent changes to this Agreement,
							as applicable).
						</p>
						<h2 className='mb-3'>WAIVER AND SEVERABILITY</h2>
						<p className='mb-3'>
							Any waiver by DeliverZero of any provision of this
							Agreement must be in writing. If any portion of this
							Agreement is found to be void, invalid, or otherwise
							unenforceable, then that portion shall be deemed to
							be superseded by a valid, enforceable provision that
							matches the intent of the original provision as
							closely as possible except that in the event of
							unenforceability of the class action waiver, the
							entire arbitration agreement shall be unenforceable.
							The remainder of this Agreement shall continue to be
							enforceable and valid according to terms contained
							herein.
						</p>
						<h2 className='mb-3'>ENTIRE AGREEMENT</h2>
						<p className='mb-3'>
							This Agreement, together with any amendments and any
							additional agreements you may enter into with
							DeliverZero in connection with the Sites and the
							services hereunder, shall constitute the entire
							agreement between you and DeliverZero concerning the
							Sites and the services hereunder.
						</p>
						<h2 className='mb-3'>CONTACT</h2>
						<p className='mb-3'>
							If you have any questions regarding this Agreement,
							please visit our “Contact Us” page for answers and
							our contact information.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Terms
