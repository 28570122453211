import { connect } from 'react-redux'
import React, { useEffect, useState, useRef } from 'react'
import {
	getUser,
	getNodes,
	updateLead,
	getLead,
	getServiceAreas,
	getContainers,
	getMarket,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { isAddressInsideDeliveryPolygon } from '../../../helpers/googleMapsAPI'
import {
	Button,
	Input,
	RadioGroup,
	TextArea,
	CheckboxSelections,
	SelectGroup,
	BounceLoaderCentered,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const SignUpPage2 = (state) => {
	const {
		user,
		onGetUser,
		onGetNodes,
		onGetLead,
		loadingNodes,
		loadingUser,
		loggedIn,
		loadingLead,
		leadError,
		updatedLead,
		updatingLead,
		updateLeadError,
		nodes,
		onUpdateLead,
		lead,
		onGetServiceAreas,
		serviceAreaPolygons,
		containers,
		onGetContainers,
		containerMarket,
		onGetMarket,
	} = state

	const isInitialRender = useRef(true)

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [form, setForm] = useState({})
	const [incomplete, setIncomplete] = useState(false)
	const [otherAggregator, setOtherAggregator] = useState('')
	const [dishwasherOptions, setDishwasherOptions] = useState([])
	const [availableContainers, setAvailableContainers] = useState([])
	const currentPage = 2
	const totalPages = 4
	const progressPercentage = (currentPage / totalPages) * 100

	const aggregatorOptions = [
		'no',
		'otter',
		'itsacheckmate',
		'deliverect',
		'chowly',
		'throughPos',
	]

	const handleChange = (name, value, isChecked) => {
		const selectedValues = form[name] || []
		const updatedValues = isChecked
			? [...selectedValues, value]
			: selectedValues.filter((val) => val !== value)

		setForm({ ...form, [name]: updatedValues })
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			onGetLead({ slug: user.restaurantAdmin })
			// getting service area polygons
			onGetServiceAreas()
		}
	}, [user])

	useEffect(() => {
		if (!isInitialRender.current && updatedLead) {
			navigate('/restaurantpartners/sign-up/page-3')
		}
		// set isInitialRender to false after the first render
		isInitialRender.current = false
	}, [updatedLead])

	useEffect(() => {
		if (lead) {
			onGetContainers([
				{
					enabled: true,
				},
			])
		}
	}, [lead])

	// so that if user navs back to this page and just clicks save & continue without changing, no 'incomplete' errors are thrown
	useEffect(() => {
		if (lead && serviceAreaPolygons && containers) {
			// to figure out container market
			var state = ''
			if (lead.fullAddress) {
				const addressParts = lead.fullAddress.split(',')
				state = addressParts[addressParts.length - 2]
					.trim()
					.split(' ')[0]
			}
			if (state) {
				// setting container options based on market
				const currentContainers = []
				var dzAsset = null // if we need to show this, listing at end for cleaner readability
				for (const container of containers) {
					if (container.markets.includes(state)) {
						if (container['containerId'] == 'DZ-Asset-Kit') {
							dzAsset = container
						} else {
							currentContainers.push({
								label: container.description,
								value: container.containerId,
								defaultChecked:
									lead.containers &&
									lead.containers.includes(
										container.containerId
									)
										? true
										: false,
								hoverInfo: container.container_measurements,
								hoverImg: `/images/containers/${container.containerId}.png`,
							})
						}
					}
				}
				if (dzAsset) {
					currentContainers.push({
						label: 'Asset Kit', // so that it is short and readable
						value: dzAsset.containerId,
						defaultChecked:
							lead.containers &&
							lead.containers.includes(dzAsset.containerId)
								? true
								: false,
						hoverInfo: dzAsset.container_measurements,
					})
				}
				setAvailableContainers(currentContainers)
			}
			// checking if in area market or not
			if (
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['CO']
				) ||
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['NYNJ']
				) ||
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['SF']
				) ||
				isAddressInsideDeliveryPolygon(
					[lead.coordinates[1], lead.coordinates[0]],
					serviceAreaPolygons[0]['LA']
				)
			) {
				setDishwasherOptions([
					{
						label: `In-House Sanitation (lowers your costs up to 78%): My restaurant will handle the sanitization of containers returned at our location.`,
						value: 'inHouse',
						selected:
							lead.hasCommercialDishwasher &&
							lead.hasCommercialDishwasher === 'inHouse'
								? true
								: false,
					},
					{
						label: 'Outsourced Dishwashing: We prefer that DeliverZero manage the dishwashing process for container returns.',
						value: 'outSourced',
						selected:
							lead.hasCommercialDishwasher &&
							lead.hasCommercialDishwasher === 'outSourced'
								? true
								: false,
					},
				])
			} else {
				setDishwasherOptions([
					{
						label: 'In-House Sanitation (lowers your costs by up to 78%): My restaurant will handle the sanitization of customer returns at our location.',
						value: 'inHouse',
						selected:
							lead.hasCommercialDishwasher &&
							lead.hasCommercialDishwasher === 'inHouse'
								? true
								: false,
					},
				])
			}
			if (lead.signUpComplete) {
				navigate('/restaurant-admin/home')
			} else if (lead.lastPage !== 1) {
				// only pre-fill if alr been on this page before
				if (
					lead.aggregator &&
					!aggregatorOptions.includes(lead.aggregator)
				) {
					setOtherAggregator(lead.aggregator)
				}
				setForm({
					['posSystem']: lead.posSystem ? lead.posSystem : '',
					['orderingPlatforms']: lead.orderingPlatforms
						? lead.orderingPlatforms
						: [],
					['cateringPlatforms']: lead.cateringPlatforms
						? lead.cateringPlatforms
						: [],
					['aggregator']:
						lead.aggregator &&
						!aggregatorOptions.includes(lead.aggregator)
							? 'other'
							: lead.aggregator
							? lead.aggregator
							: '',
					['containers']: lead.containers ? lead.containers : [],
					['hasCommercialDishwasher']: lead.hasCommercialDishwasher
						? lead.hasCommercialDishwasher
						: '',
					['returnPoint']: lead.returnPoint ? lead.returnPoint : '',
					['howDidYouHear']: lead.howDidYouHear
						? lead.howDidYouHear
						: [],
				})
			} else {
			}
		}
	}, [lead, serviceAreaPolygons, containers])

	const handleSubmit = async (e) => {
		e.preventDefault()
		setIncomplete(false)

		const requiredFields = [
			'posSystem',
			'orderingPlatforms',
			'aggregator',
			'hasCommercialDishwasher',
		]

		for (const key of requiredFields) {
			if (
				!(key in form) ||
				form[key] === null ||
				form[key] === '' ||
				!form[key] ||
				(key === 'posSystem' && form[key] === 'Select...') ||
				(key === 'aggregator' && form[key] === 'Select...') ||
				(key === 'hasCommercialDishwasher' &&
					form[key] === 'Select...') ||
				(key === 'aggregator' &&
					form[key] === 'other' &&
					!otherAggregator) ||
				(key === 'hasCommercialDishwasher' &&
					form[key] === 'outSourced' &&
					(form['returnPoint'] === 'Select...' ||
						!form['returnPoint']))
			) {
				// console.log(key, form[key])
				setIncomplete(true)
				return
			}
		}

		try {
			await onUpdateLead({
				slug: user.restaurantAdmin,
				lastPage: 2,
				// orderReceivingMethod: form.orderReceivingMethod,
				posSystem: form.posSystem,
				orderingPlatforms: form.orderingPlatforms,
				cateringPlatforms: form.cateringPlatforms
					? form.cateringPlatforms
					: [],
				// directOrderingPlatform: form.directOrderingPlatform,
				// courierService: form.courierService,
				aggregator:
					form.aggregator === 'other'
						? otherAggregator
						: form.aggregator,
				containers: form.containers,
				hasCommercialDishwasher: form.hasCommercialDishwasher,
				returnPoint:
					form.hasCommercialDishwasher === 'inHouse'
						? 'yes'
						: form.returnPoint,
				howDidYouHear: form.howDidYouHear ? form.howDidYouHear : [],
				lastUpdated: new Date(),
				dzAmbassador: form.dzAmbassador ? form.dzAmbassador : '',
			})
		} catch (error) {
			console.error('Lead update error:', error.message)
		}
	}

	// console.log(form)

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	// if (user.restaurantAdmin) {
	return (
		<div className='sm:bg-green-600'>
			<Header />
			<div className='flex flex-col sm:p-5 sm:my-10 lg:my-20 mx-auto sm:max-w-[1300px] sm:bg-white sm:rounded-2xl w-full'>
				<div className='flex flex-col justify-center align-center mx-auto overlow-auto w-full sm:max-w-[1200px]'>
					{lead ? (
						<div>
							<div className='w-full h-full sm:hidden'>
								<img
									src='/images/SignUpFlow/1of4.jpg'
									alt='Image Description'
									className='w-full h-full object-cover'
								/>
							</div>

							<div className='max-w-full mx-3'>
								<h1 className='font-header text-green-600 text-4xl my-3 text-center'>
									Tell us about {lead.restaurantName}
								</h1>

								<div className='flex mx-auto items-center justify-center mb-4 text-sm sm:text-lg text-center'>
									Fill out the info below to get started with
									DeliverZero.
								</div>

								<div className='flex flex-col m-auto sm:mb-10'>
									<div className='bg-gray-200 h-4 rounded-full'>
										<div
											className='bg-green-600 h-full rounded-full'
											style={{
												width: `${progressPercentage}%`,
											}}
										></div>
										<div className='text-gray-600 text-sm pl-3'>
											PAGE {currentPage} OF {totalPages}
										</div>
									</div>
								</div>

								<div className='flex flex-row mb-6 w-full'>
									<div className='w-full h-full hidden sm:block sm:h-[600px] max-w-[50%]'>
										<img
											src='/images/SignUpFlow/1of4.jpg'
											alt='Image Description'
											className='w-full h-full object-cover object-center mb-[300px]'
										/>
									</div>

									<div>
										<form
											className='form-horizontal sm:h-[600px] overflow-auto scrollbar'
											onSubmit={handleSubmit}
										>
											<div className='flex flex-col m-auto pt-10 px-6 sm:mb-0 sm:pt-2'>
												<div className='mb-3'>
													<SelectGroup
														label='What POS system do you use? *'
														error={
															incomplete &&
															(form[
																'posSystem'
															] === 'Select...' ||
																!form[
																	'posSystem'
																])
														}
														name='posSystem'
														placeholder='Select...'
														options={[
															{
																label: 'Toast',
																value: 'toast',
																selected:
																	lead.posSystem &&
																	lead.posSystem ===
																		'toast'
																		? true
																		: false,
															},
															{
																label: 'Square',
																value: 'square',
																selected:
																	lead.posSystem &&
																	lead.posSystem ===
																		'square'
																		? true
																		: false,
															},
															{
																label: 'Clover',
																value: 'clover',
																selected:
																	lead.posSystem &&
																	lead.posSystem ===
																		'clover'
																		? true
																		: false,
															},
															{
																label: 'Other',
																value: 'other',
																selected:
																	lead.posSystem &&
																	lead.posSystem ===
																		'other'
																		? true
																		: false,
															},
														]}
														onChange={(e) =>
															setForm({
																...form,
																['posSystem']:
																	e.target
																		.value,
															})
														}
													/>
												</div>
												<div className='mt-3 mb-3'>
													<CheckboxSelections
														label='Which ordering platforms do you use? Check all that apply. *'
														error={
															incomplete &&
															!form.orderingPlatforms
														}
														name='orderingPlatforms'
														options={[
															{
																label: 'DoorDash/Caviar',
																value: 'doorDashCaviar',
																defaultChecked:
																	lead.orderingPlatforms &&
																	lead.orderingPlatforms.includes(
																		'doorDashCaviar'
																	)
																		? true
																		: false,
															},
															{
																label: 'GrubHub/Seamless',
																value: 'grubHubSeamless',
																defaultChecked:
																	lead.orderingPlatforms &&
																	lead.orderingPlatforms.includes(
																		'grubHubSeamles'
																	)
																		? true
																		: false,
															},
															{
																label: 'Square',
																value: 'square',
																defaultChecked:
																	lead.orderingPlatforms &&
																	lead.orderingPlatforms.includes(
																		'square'
																	)
																		? true
																		: false,
															},
															{
																label: 'Uber Eats/Postmates',
																value: 'uberEatsPostmates',
																defaultChecked:
																	lead.orderingPlatforms &&
																	lead.orderingPlatforms.includes(
																		'uberEatsPostmates'
																	)
																		? true
																		: false,
															},
															{
																label: 'ChowNow',
																value: 'chowNow',
																defaultChecked:
																	lead.orderingPlatforms &&
																	lead.orderingPlatforms.includes(
																		'chowNow'
																	)
																		? true
																		: false,
															},
															{
																label: 'OLO',
																value: 'olo',
																defaultChecked:
																	lead.orderingPlatforms &&
																	lead.orderingPlatforms.includes(
																		'olo'
																	)
																		? true
																		: false,
															},
															{
																label: 'Toasttab',
																value: 'toasttab',
																defaultChecked:
																	lead.orderingPlatforms &&
																	lead.orderingPlatforms.includes(
																		'toasttab'
																	)
																		? true
																		: false,
															},
														]}
														onChange={(e) => {
															handleChange(
																'orderingPlatforms',
																e.target.value,
																e.target.checked
															)
														}}
													/>
												</div>
												<div className='mt-3 mb-3'>
													<CheckboxSelections
														label='Which online ordering platforms do you use for catering or office orders (if applicable)?'
														name='cateringPlatforms'
														options={[
															{
																label: 'Fooda',
																value: 'fooda',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'fooda'
																	)
																		? true
																		: false,
															},
															{
																label: 'DoorDash for Work',
																value: 'doorDashWork',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'doorDashWork'
																	)
																		? true
																		: false,
															},
															{
																label: 'Uber for Business',
																value: 'uberBusiness',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'uberBusiness'
																	)
																		? true
																		: false,
															},
															{
																label: 'ezCater',
																value: 'ezCater',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'ezCater'
																	)
																		? true
																		: false,
															},
															{
																label: 'Foodee',
																value: 'foodee',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'foodee'
																	)
																		? true
																		: false,
															},
															{
																label: 'Sharebite',
																value: 'sharebite',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'sharebite'
																	)
																		? true
																		: false,
															},
															{
																label: 'CaterCow',
																value: 'caterCow',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'caterCow'
																	)
																		? true
																		: false,
															},
															{
																label: 'Forkable',
																value: 'forkable',
																defaultChecked:
																	lead.cateringPlatforms &&
																	lead.cateringPlatforms.includes(
																		'forkable'
																	)
																		? true
																		: false,
															},
														]}
														onChange={(e) => {
															handleChange(
																'cateringPlatforms',
																e.target.value,
																e.target.checked
															)
														}}
													/>
												</div>
												<div className='mb-3 mt-3'>
													<SelectGroup
														label='Do you use an aggregator or menu management tool? *'
														error={
															(incomplete &&
																(form[
																	'aggregator'
																] ===
																	'Select...' ||
																	!form[
																		'aggregator'
																	])) ||
															(incomplete &&
																form[
																	'aggregator'
																] === 'other' &&
																!otherAggregator)
														}
														name='aggregator'
														placeholder='Select...'
														options={[
															{
																label: 'No',
																value: 'no',
																selected:
																	lead.aggregator &&
																	lead.aggregator ===
																		'no'
																		? true
																		: false,
															},
															{
																label: 'Otter',
																value: 'otter',
																selected:
																	lead.aggregator &&
																	lead.aggregator ===
																		'otter'
																		? true
																		: false,
															},
															{
																label: 'ItsaCheckmate',
																value: 'itsacheckmate',
																selected:
																	lead.aggregator &&
																	lead.aggregator ===
																		'itsacheckmate'
																		? true
																		: false,
															},
															{
																label: 'Deliverect',
																value: 'deliverect',
																selected:
																	lead.aggregator &&
																	lead.aggregator ===
																		'deliverect'
																		? true
																		: false,
															},
															{
																label: 'Chowly',
																value: 'chowly',
																selected:
																	lead.aggregator &&
																	lead.aggregator ===
																		'chowly'
																		? true
																		: false,
															},
															{
																label: 'Through my POS',
																value: 'throughPos',
																selected:
																	lead.aggregator &&
																	lead.aggregator ===
																		'throughPos'
																		? true
																		: false,
															},
															{
																label: 'Other',
																value: 'other',
																selected:
																	lead.aggregator &&
																	!aggregatorOptions.includes(
																		lead.aggregator
																	)
																		? true
																		: false,
															},
														]}
														onChange={(e) =>
															setForm({
																...form,
																['aggregator']:
																	e.target
																		.value,
															})
														}
													/>
													{form.aggregator ===
														'other' && (
														<TextArea
															type='text'
															name='aggregator'
															defaultValue={
																lead.aggregator &&
																!aggregatorOptions.includes(
																	lead.aggregator
																)
																	? lead.aggregator
																	: ''
															}
															placeholder='Please specify'
															onChange={(e) =>
																setOtherAggregator(
																	e
																)
															}
															className={`mt-2 ${
																incomplete &&
																form.aggregator ===
																	'other' &&
																!otherAggregator
																	? 'border-red-600'
																	: ''
															}`}
														/>
													)}
												</div>
												{availableContainers.length >
													0 && (
													<div className='mt-3 mb-3'>
														<CheckboxSelections
															label='What type of containers do you require?'
															name='containers'
															options={
																availableContainers
															}
															onChange={(e) => {
																handleChange(
																	'containers',
																	e.target
																		.value,
																	e.target
																		.checked
																)
															}}
														/>
													</div>
												)}
												<div className='mb-3 mt-3'>
													<SelectGroup
														label='How would you prefer to manage the sanitation of returned containers? *'
														error={
															incomplete &&
															(form[
																'hasCommercialDishwasher'
															] === 'Select...' ||
																!form[
																	'hasCommercialDishwasher'
																])
														}
														name='hasCommercialDishwasher'
														placeholder='Select...'
														options={
															dishwasherOptions
														}
														onChange={(e) =>
															setForm({
																...form,
																['hasCommercialDishwasher']:
																	e.target
																		.value,
															})
														}
													/>
												</div>
												{form.hasCommercialDishwasher ===
													'inHouse' && (
													<div className='text-xs font-bold text-green-600'>
														You'll be listed as a
														return point where
														customers can return
														containers.
													</div>
												)}
												{form.hasCommercialDishwasher ===
													'outSourced' && (
													<div className='mb-3 mt-3'>
														<SelectGroup
															label='Would you like to increase foot traffic and be a return point where customers can return containers? *'
															error={
																incomplete &&
																(form[
																	'returnPoint'
																] ===
																	'Select...' ||
																	!form[
																		'returnPoint'
																	])
															}
															name='returnPoint'
															placeholder='Select...'
															options={[
																{
																	label: 'Yes',
																	value: 'yes',
																	selected:
																		lead.returnPoint &&
																		lead.returnPoint ===
																			'yes'
																			? true
																			: false,
																},
																{
																	label: 'No',
																	value: 'no',
																	selected:
																		lead.returnPoint &&
																		lead.returnPoint ===
																			'no'
																			? true
																			: false,
																},
															]}
															onChange={(e) =>
																setForm({
																	...form,
																	['returnPoint']:
																		e.target
																			.value,
																})
															}
														/>
													</div>
												)}
												<div className='mt-3 mb-3'>
													<CheckboxSelections
														label='How did you hear about DeliverZero? Check all that apply.'
														name='howDidYouHear'
														options={[
															{
																label: 'Toast',
																value: 'toast',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'toast'
																	)
																		? true
																		: false,
															},
															{
																label: 'DoorDash',
																value: 'doordash',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'doordash'
																	)
																		? true
																		: false,
															},
															{
																label: 'Uber Eats',
																value: 'ubereats',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'ubereats'
																	)
																		? true
																		: false,
															},
															{
																label: 'Government agency',
																value: 'govAgency',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'govAgency'
																	)
																		? true
																		: false,
															},
															{
																label: 'Media outlet',
																value: 'news',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'news'
																	)
																		? true
																		: false,
															},
															{
																label: 'Social media',
																value: 'social',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'social'
																	)
																		? true
																		: false,
															},
															{
																label: 'Customer request',
																value: 'customer',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'customer'
																	)
																		? true
																		: false,
															},
															{
																label: 'Employee request',
																value: 'employee',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'employee'
																	)
																		? true
																		: false,
															},
															{
																label: 'DeliverZero Ambassador',
																value: 'dzAmbassador',
																defaultChecked:
																	lead.howDidYouHear &&
																	lead.howDidYouHear.includes(
																		'dzAmbassador'
																	)
																		? true
																		: false,
															},
														]}
														onChange={(e) => {
															handleChange(
																'howDidYouHear',
																e.target.value,
																e.target.checked
															)
														}}
													/>
													{form &&
														form.howDidYouHear &&
														form.howDidYouHear.includes(
															'dzAmbassador'
														) && (
															<Input
																label='NAME OF AMBASSADOR'
																name='dzAmbassador'
																className='bg-white border-2 mt-2 sm:w-[150px] md:w-[200px] lg:w-[225px] border-gray-300 mb-3 py-0'
																onChange={(
																	e
																) => {
																	setForm({
																		...form,
																		['dzAmbassador']:
																			e
																				.target
																				.value,
																	})
																}}
															/>
														)}
												</div>
												{incomplete && (
													<p className='text-red-400'>
														Error above. Missing or
														incorrect fields.
													</p>
												)}

												{updateLeadError && (
													<p className='text-red-400'>
														There was an error
														submitting this form.
														{updateLeadError}.
														Please try again.
													</p>
												)}

												{updatingLead || loadingLead ? (
													<BounceLoader
														className='m-auto'
														color={
															bounceLoaderColor
														}
													></BounceLoader>
												) : (
													<Button
														className='my-6 w-[200px]'
														size='sm'
														submit={handleSubmit}
														text='Save & Continue'
													/>
												)}
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					) : (
						<BounceLoaderCentered />
					)}
				</div>
			</div>
			<Footer />
		</div>
	)
	// } else {
	// 	navigate('/restaurantpartners')
	// }
}

const mapStateToProps = ({ Nodes, User, Leads, Containers, Logistics }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	updatedLead: Leads.updatedLead,
	updatingLead: Leads.updatingLead,
	updateLeadError: Leads.updateLeadError,
	lead: Leads.lead,
	serviceAreaPolygons: Leads.serviceAreaPolygons,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	containerMarket: Logistics.market,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetUser: () => dispatch(getUser()),
	onUpdateLead: (payload) => dispatch(updateLead(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
	onGetServiceAreas: () => dispatch(getServiceAreas()),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onGetMarket: (payload) => dispatch(getMarket(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage2)
