import React, { createRef, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
	getNodes,
	updateSearchNodes,
	getAddressLocation,
	getGPSLocation,
	captureGPS,
} from '../../store/actions'
import {
	calculateDistanceBetweenCoords,
	googleMapsLibraries,
} from '../../helpers/googleMapsAPI'
import {
	Header,
	Footer,
	ReturnSteps,
	LocationsSearchInputs,
} from '../../components'
import {
	Alert,
	RadioGroup,
	Button,
	BounceLoaderCentered,
} from '../../components/Elements'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { FaCaretDown, FaCaretUp, FaMapMarkerAlt, FaRoute } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { realm } from '../../helpers/realm'

const ReturnLocations = (props) => {
	const {
		onGetNodes,
		userLocation,
		onGetUserAddressLocation,
		nodes,
		user,
		onUpdateSearchAddress,
		searchedAddress,
		searchedAddressLatLng,
		onGetGPSLocation,
		gpsLocationFound,
		userLocationError,
		onCaptureGPS,
	} = props

	let navigate = useNavigate()

	const [deliveryAddress, setDeliveryAddress] = useState('')
	const [deliveryLatLng, setDeliveryLatLng] = useState(null)
	const [mapBool, setMapBool] = useState(false)
	const [postSearchNodes, setPostSearchNodes] = useState([])
	const [restaurantInput, setRestaurantInput] = useState('')
	const [selectedMapNode, setSelectedMapNode] = useState({ name: '' })
	const [selectedZoom, setSelectedZoom] = useState(12)
	const [selectedCenterCoords, setSelectedCenterCoords] = useState({
		lat: 40.7128,
		lng: -74.006,
	})
	const [locationWarning, setLocationWarning] = useState(false)
	const [mapFilter, setMapFilter] = useState({})
	const [searchFilter, setSearchFilter] = useState({})
	const [addressFilter, setAddressFilter] = useState({})
	const [cupsFilter, setCupsFilter] = useState(false)
	const [distanceBarValue, setDistanceBarValue] = useState(25)
	const defaultDistanceBarValue = distanceBarValue
	const [sortByType, setSortByType] = useState('distance')
	const [email, setEmail] = useState('')
	const [submittingEmail, setSubmittingEmail] = useState(false)
	const [emailSuccess, setEmailSuccess] = useState(false)
	const [emailError, setEmailError] = useState('')
	const [activeGPS, setActiveGPS] = useState(true)
	const [filter, setFilter] = useState({
		// type: {
		//   $in: [NodeTypes.bin],
		// },
		returnBinEnabled: {
			$in: [true],
		},
		enabled: {
			$nin: [false],
		},
		type: {
			$nin: ['whole-foods'],
		},
	})
	const [dayOfWeek, setDayOfWeek] = useState('')
	const [openHoursDropDown, setOpenHoursDropDown] = useState({})
	const [isHoliday, setIsHoliday] = useState(false)
	const [lastSearched, setLastSearched] = useState(null)
	const [geoCodedAddress, setGeoCodedAddress] = useState()
	const [geoCodedLat, setGeoCodedLat] = useState()
	const [geoCodedLng, setGeoCodedLng] = useState()
	const [distPopupVisible, setDistPopupVisible] = useState(false)
	const [distanceInfo, setDistanceInfo] = useState('')
	const usBankHolidays = [
		'Jan 1',
		'Jan 15',
		'Feb 19',
		'May 27',
		'Jun 19',
		'Jul 4',
		'Sep 2',
		'Oct 9',
		'Nov 11',
		'Nov 23',
		'Dec 25',
	] // oct 2023 - oct 2024 dates
	const daysOfWeekMap = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 } // map from Date().getDay() (0-6=Sun-Sat) to google's openHours (0-6=Mon-Sun)

	const google = window.google
	let resultRef = createRef()

	// LOAD GOOGLE MAPS API
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: googleMapsLibraries,
	})

	//* STEP ONE: GET USER LOCATION ON LOAD
	useEffect(() => {
		const currentDate = new Date()
		const dateOptions = { month: 'short', day: 'numeric' }
		const formattedDate = currentDate.toLocaleDateString(
			'en-US',
			dateOptions
		) // in format "Jan 1"
		setDayOfWeek(currentDate.getDay())
		if (usBankHolidays.includes(formattedDate)) {
			setIsHoliday(true)
		}
		if (!userLocation && userLocationError === 'User denied Geolocation') {
			onGetUserAddressLocation()
		}
		if (activeGPS) {
			setActiveGPS(false)
		}

		if (gpsLocationFound && userLocation) {
			setActiveGPS(false)
			{
				setSelectedCenterCoords({
					lat: userLocation.lat,
					lng: userLocation.lng,
				})
			}
		}

		const lastSearchedAddress = JSON.parse(
			localStorage.getItem('lastSearchedAddress')
		)

		if (lastSearchedAddress && lastSearchedAddress.address) {
			setLastSearched(lastSearchedAddress.address)
		}
	}, [gpsLocationFound, activeGPS, userLocationError])

	useEffect(() => {
		if (!userLocation) {
			makeGPSRequest()
		}
	}, [])

	useEffect(() => {
		if (lastSearched) {
			geocodeAddress()
		}
	}, [lastSearched])

	useEffect(() => {
		if (user && user.phone) {
			onCaptureGPS({ phone: user.phone })
		}
	}, [user])

	const makeGPSRequest = async () => {
		await onGetGPSLocation()
		setActiveGPS(true)
	}

	const handleReturnCups = () => {
		setCupsFilter(!cupsFilter)
	}

	// CENTER MAP ON USER LOCATION, IF NO USER LOCATION, CENTER ON DEFAULT LOCATION & ZOOM, SHOW ALERT
	useEffect(() => {
		if (userLocation) {
			setSelectedCenterCoords({
				lat: userLocation.lat,
				lng: userLocation.lng,
			})
			if (gpsLocationFound) {
				setLocationWarning(false)
			} else {
				setLocationWarning(true)
			}
			setSelectedZoom(13)
		} else {
			setSelectedCenterCoords({
				lat: 40.7128,
				lng: -74.006,
			})
			setSelectedZoom(6)
			setLocationWarning(true)
		}
		// createFilter()
	}, [userLocation])

	// GET NODES NEAR USER
	useEffect(() => {
		if (userLocation) {
			createFilter()
		} else if (deliveryAddress) {
			createFilter()
		}
	}, [
		filter,
		mapFilter,
		searchFilter,
		userLocation,
		sortByType,
		addressFilter,
		distanceBarValue,
		cupsFilter,
	])

	// useEffect(() => {
	//     if (selectedCenterCoords) {
	//         console.log('selected center coords', selectedCenterCoords)
	//     }
	// }, [selectedCenterCoords])

	// COMBINE FILTER ARRAY AND GET NODES
	const createFilter = () => {
		let combinedFilter = [filter]
		if (mapBool) {
			combinedFilter.push(searchFilter, mapFilter)
		} else if (searchedAddress && searchedAddress != '') {
			combinedFilter.push(searchFilter, addressFilter)
		} else {
			const geoFilter = getNearestNodes()
			if (geoFilter) {
				combinedFilter.push(geoFilter, searchFilter)
			} else {
				combinedFilter.push(searchFilter)
			}
		}

		if (user && user.franchiseeSlug) {
			combinedFilter.push({
				franchiseeSlug: user.franchiseeSlug,
			})
		}

		if (cupsFilter) {
			combinedFilter.push({
				cupReturns: true,
			})
		}
		// console.log(combinedFilter)
		onGetNodes(combinedFilter)
	}

	// MAP FILTER ON SELECT POINT
	const onMapSelect = (node) => {
		// setDeliveryAddress('')
		setSelectedMapNode(node)
		setMapBool(true)
		setMapFilter({
			coordinates: {
				$near: {
					$geometry: {
						type: 'Point',
						coordinates: [node.coordinates[0], node.coordinates[1]],
					},
				},
			},
		})
		document.getElementById('results-scroller').scroll(0, 0)
	}

	const locationValidation = (node) => {
		// console.log(node)
		// const restaurantCoords = node.coordinates
		// const restaurantLat = restaurantCoords[1]
		// const restaurantLng = restaurantCoords[0]
		// const distanceToRestaurant = calculateDistanceBetweenCoords([restaurantLat, restaurantLng], restaurantCoords)
		// if (distanceToRestaurant > 100) {
		//     setShowTooFarAlert(true)
		//     return false
		// } else {
		//     // history.push(`/returns/${node.slug}`)
		//     return true
		// }
		// console.log('navigate', navigate)
		// console.log(`/returns/${node.slug}`)
		navigate(`/returns/${node.slug}`)
	}

	const getNearestNodes = () => {
		let geoFilter = {
			coordinates: {
				$near: {
					$geometry: {
						type: 'Point',
						coordinates: [
							selectedCenterCoords.lng,
							selectedCenterCoords.lat,
						],
					},
					// $minDistance: 3000000,
					$maxDistance: distanceBarValue * 1609.34,
				},
			},
		}
		if (userLocation) {
			geoFilter.coordinates.$near.$geometry.coordinates = [
				userLocation.lng,
				userLocation.lat,
			]
			setDeliveryLatLng({ lat: userLocation.lat, lng: userLocation.lng })
		} else {
			geoFilter.coordinates.$near.$geometry.coordinates = [
				selectedCenterCoords.lng,
				selectedCenterCoords.lat,
			]
			setDeliveryLatLng({
				lat: selectedCenterCoords.lat,
				lng: selectedCenterCoords.lng,
			})
		}
		return geoFilter
	}

	const addressHandler = (address, latLng) => {
		onUpdateSearchAddress([address, latLng])
		if (address) {
			// if(latLng) {
			setSearchFilter({})
			setRestaurantInput('')
			setAddressFilter({
				coordinates: {
					$near: {
						$geometry: {
							type: 'Point',
							coordinates: [latLng.lng, latLng.lat],
						},
						// $minDistance: 3000000,
					},
				},
			})

			setSelectedCenterCoords({
				lat: latLng.lat,
				lng: latLng.lng,
			})
			setLastSearched(address)
			const lastSearchedAddress = {
				address: address,
				lat: latLng.lat,
				lng: latLng.lng,
				address2: '',
			}
			localStorage.setItem(
				'lastSearchedAddress',
				JSON.stringify(lastSearchedAddress)
			)
			// } else {
			//     latLng = window.google.maps.Geocoder(address)
			//     console.log(latLng)
			// }
		} else {
			setAddressFilter({})
			if (userLocation) {
				setDeliveryLatLng({
					lat: userLocation.lat,
					lng: userLocation.lng,
				})
			} else {
				setDeliveryAddress('')
				setDeliveryLatLng(null)
			}
		}
	}

	const geocodeAddress = async () => {
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: lastSearched ? lastSearched : searchedAddress,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					addressHandler(results[0].formatted_address, {
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng(),
					})
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const addressCheck = (address, latLng) => {
		setDeliveryAddress(address)
		setDeliveryLatLng(latLng)
		let tempArray = []
		// if(mapBool){
		//   setPostSearchNodes(nodes)
		// } else {
		if (latLng) {
			nodes.map((node) => {
				//For the returns map page
				const distanceToCoordMiles =
					calculateDistanceBetweenCoords(
						[latLng.lat, latLng.lng],
						node.coordinates
					) / 1609.34
				//Filter by distance again in case they've clicked by
				if (distanceToCoordMiles <= distanceBarValue) {
					node['distanceToCoord'] = distanceToCoordMiles
					tempArray.push(node)
				}
				// //For the get food map page
				// if (isAddressInsideDeliveryPolygon([latLng.lat, latLng.lng], node.deliveryPolygon)) {
				//     const distanceToCoordMiles = calculateDistanceBetweenCoords([latLng.lat, latLng.lng], node.coordinates) / 1609.34
				//     node['distanceToCoord'] = distanceToCoordMiles
				//     tempArray.push(node)
				// }
			})

			// const addressFilter = nodesAsArray.filter(([key, value]) =>
			//   isAddressInsideDeliveryPolygon(
			//     [latLng.lat, latLng.lng],
			//     value.deliveryPolygon
			//   )
			// )
			let sortedNodes = tempArray
			if (!mapBool) {
				if (sortByType == 'name') {
					sortedNodes = sortedNodes.sort((a, b) =>
						a.name > b.name ? 1 : b.name > a.name ? -1 : 0
					)
				} else if (sortByType == 'distance') {
					sortedNodes = sortedNodes.sort(
						(a, b) => a.distanceToCoord - b.distanceToCoord
					)
				}
			}

			setPostSearchNodes(sortedNodes)
			if (mapBool == false) {
				setSelectedCenterCoords({
					lat: latLng.lat,
					lng: latLng.lng,
				})
				setSelectedZoom(12)
			}
		} else {
			let sortedNodes = nodes
			if (!mapBool) {
				if (sortByType == 'name') {
					sortedNodes = sortedNodes.sort((a, b) =>
						a.name > b.name ? 1 : b.name > a.name ? -1 : 0
					)
				} else if (sortByType == 'distance') {
					sortedNodes = sortedNodes.sort(
						(a, b) => a.distanceToCoord - b.distanceToCoord
					)
				}
			}
			setPostSearchNodes(sortedNodes)
		}
		// }
	}

	useEffect(() => {
		addressCheck(deliveryAddress, deliveryLatLng)
	}, [nodes])

	useEffect(() => {
		if (searchedAddress && searchedAddress != '') {
			addressCheck(searchedAddress, searchedAddressLatLng)
		} else {
			return
		}
	}, [searchedAddress])

	const onSearchChange = (query) => {
		// console.log(selectedZoom)
		setMapBool(false)
		if (selectedZoom > 12) {
			setSelectedZoom(12)
		}
		setSelectedMapNode({ name: '' })
		// setSelectedCenterCoords({
		//     lat: 40.7128,
		//     lng: -74.006,
		// })

		setRestaurantInput(query.target.value)
		if (query.target.value !== '') {
			// console.log('run search')
			setSearchFilter({
				$or: [
					{
						name: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						slug: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						city: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						address: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						cuisines: {
							$in: [new RegExp(query.target.value, 'i')],
						},
					},
				],
			})
		} else {
			setSearchFilter({})
		}
	}

	const changeMapBoolToFalse = () => {
		setMapBool(false)
	}

	const handleSortBy = (sortBy) => {
		setMapBool(false)
		setSortByType(sortBy)
	}

	const onNameQuery = (name) => {
		onSearchChange(name)
	}

	// const autoCompleteQuery = (query) => {
	// 	onAddressSelect(query)
	// }

	const handleEmailChange = (emailChange) => {
		setEmail(emailChange.target.value)
	}

	const handleSubscribe = async () => {
		setEmailError('')
		setEmailSuccess(false)
		setSubmittingEmail(true)
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (re.test(email)) {
			const subscriberStatus = await realm.currentUser.callFunction(
				'mailChimp/AddUserToEmailList',
				{
					email: email,
					phoneNumber: user ? user.phone : '',
					firstName: user ? user.firstName : '',
					lastName: user ? user.lastName : '',
				}
			)
			if (subscriberStatus == 'error') {
				setSubmittingEmail(false)
				setEmailError('An error occured. Try again.')
			} else {
				setEmailSuccess(true)
				setSubmittingEmail(false)
			}
		} else {
			// invalid email, maybe show an error to the user.
			setSubmittingEmail(false)
			setEmailError('Invalid email address. Try again.')
		}
	}

	const formatTodayHours = (input) => {
		if (typeof input === 'string') {
			input = input.replace(/.*day/, "Today's Hours")
			return input
		}
		return toString(input)
	}

	const shortenDay = (day) => {
		if (typeof day === 'string') {
			const shortform = day.slice(0, 3)
			day = day.replace(/.*day/, shortform)
		}
		return day
	}

	const innerRef = useRef(null)
	const outerRef = useRef(null)
	const [scrollingInner, setScrollingInner] = useState(false)

	const handleTouchStart = () => {
		setScrollingInner(true)
	}

	const handleTouchEnd = () => {
		setScrollingInner(false)
	}

	const handleMarkerClick = async (node) => {
		const originLat = geoCodedLat || userLocation.lat
		const originLng = geoCodedLng || userLocation.lng

		if (!originLat || !originLng) {
			console.error('Origin location is not available')
			return
		}

		onMapSelect(node)
		setSelectedZoom(15)
		setSelectedCenterCoords({
			lat: node.coordinates[1],
			lng: node.coordinates[0],
		})
		setDistanceInfo({
			originLat: originLat,
			originLng: originLng,
			destLat: node.coordinates[1],
			destLng: node.coordinates[0],
			distance: node.distanceToCoord
				.toFixed(2)
				.toString()
				.substring(0, 4),
		})
		setDistPopupVisible(true)
	}

	if (!isLoaded && !userLocation) return 'loading...'
	return (
		<div
			ref={outerRef}
			onTouchMove={() => {
				if (scrollingInner) {
					const inner = innerRef.current
					if (inner.scrollTop === 0) {
						outerRef.current.scrollTop -= 1
					} else if (
						inner.scrollTop ===
						inner.scrollHeight - inner.clientHeight
					) {
						outerRef.current.scrollTop += 1
					}
				}
			}}
			className=' h-full w-full flex flex-col justify-between overflow-auto'
		>
			<Header />
			{/*{locationWarning && (*/}
			{/*	<Alert*/}
			{/*		// content='You have location services turned off, you may continue by searching an address, but you will be asked to verify your location by another method on the next page.'*/}
			{/*		content='You have location services turned off. You may continue by searching an address below, but certain map features may behave differently.'*/}
			{/*		type='warning'*/}
			{/*	/>*/}
			{/*)}*/}
			<div
				className={`min-h-[50em] md:h-screen md:min-h-[70em] overflow-hidden pt-6`}
			>
				<div className='pb-2'>
					<div className='flex flex-col items-center justify-center'>
						<div className=' flex w-2/3 items-center justify-center container mb-2'>
							<ReturnSteps step={1} />
						</div>
						<div className='text-center tracking-wider'>
							<h1 className='text-3xl md:text-5xl font-header text-center text-green-600 w-full mb-4 mt-2 sm:mt-6 md:mb-8'>
								Select a drop-off location
							</h1>
							{/* <p className='font-poppins mb-2'>When you're at a return location, select where you're returning to.</p> */}
							<p className='font-poppins px-4 text-xs'>
								NEW: To report via text, send the number of
								containers and your return location to{' '}
								<a
									className='underline text-green-600'
									href='sms:+17204879754'
								>
									720-487-9754
								</a>
							</p>
						</div>
						{isLoaded && (
							<LocationsSearchInputs
								onNameQuery={onNameQuery}
								// makeGPSRequest={makeGPSRequest}
								onAddressSelect={addressHandler}
								changeMapBoolToFalse={changeMapBoolToFalse}
								addressValue={
									lastSearched
										? lastSearched
										: searchedAddress
								}
								setLastSearched={setLastSearched}
								nameValue={restaurantInput}
								defaultDistanceBarValue={
									defaultDistanceBarValue
								}
								setDistanceBarValue={setDistanceBarValue}
								distanceBarValue={distanceBarValue}
								setMapBool={setMapBool}
							/>
						)}
					</div>
				</div>
				<div className='w-[80%] flex justify-center items-center mx-auto mb-2'>
					<a
						href='#'
						onClick={handleReturnCups}
						className='text-green-600 text-sm md:text-base text-center my-2 w-full px-2 underline cursor-pointer'
					>
						{cupsFilter
							? 'Click here to filter by locations that accept food containers'
							: 'Returning cups? Click here to filter by locations that accept cups'}
					</a>
				</div>
				<div className='w-full sm:h-full md:min-h-[36em] flex flex-col-reverse md:flex-row sm:justify-between border-t border'>
					<div className=' w-full md:w-1/2 md:h-full h-3/4 pb-20 px-2 pt-4 md:overflow-hidden'>
						<div className='flex flex-col'>
							<div className='flex justify-between items-center pb-2 border-b mr-4'>
								<div className='min-w-[100px]'>
									{postSearchNodes.length} Locations
								</div>
								<div className='flex items-center'>
									<div className='mt-1 sm:mt-0 mr-1 ml-4'>
										Sort By:
									</div>
									<RadioGroup
										className='mt-1 sm:mt-0'
										name='sortBy'
										horizontal
										radios={[
											{
												value: 'distance',
												label: 'Distance',
												checked: true,
											},
											{ value: 'name', label: 'Name' },
										]}
										onChange={(sortBy) => {
											handleSortBy(sortBy)
										}}
									/>
								</div>
							</div>
						</div>
						{postSearchNodes.length == 0 && (
							<div
								className='h-[450px] md:h-2/3 overflow-scroll scrollbar'
								id='results-scroller'
								ref={resultRef}
							>
								<div className='mt-3 text-center'>
									<BounceLoaderCentered container='div' />
								</div>
								<div className='mt-3'>
									No restaurants fit your search! Try changing
									your search, choosing a different address,
									or adjusting the distance bar.
								</div>
								<div className='mt-6'>
									Sign up for our newsletter to be informed
									when we are available in your area!
								</div>
								<div className='w-full flex flex-col justify-center items-center p-8'>
									{submittingEmail ? (
										<BounceLoaderCentered container='div' />
									) : (
										<>
											<input
												onChange={handleEmailChange}
												type='email'
												name='email'
												id='email'
												className='rounded-full w-full bg-[#E9EFFD] py-3 pl-4 mb-2'
												placeholder='Enter your email'
											/>
											<Button
												onClick={handleSubscribe}
												color='red'
												size='sm'
												className='mb-4 w-full'
												text='Sign Up'
											/>
										</>
									)}
									{emailError !== '' && (
										<div className='text-red-400 my-2 font-semibold text-sm'>
											{emailError}
										</div>
									)}
									{emailSuccess && (
										<div className='text-green-400 my-2 font-semibold text-sm'>
											You've successfully subscribed!
										</div>
									)}
								</div>
								<div className='mt-6'>
									Looking for a particular restaurant that
									isn't on our list? Let them and us know that
									they should use our reusables.{' '}
								</div>
								<div className='w-full text-center font-semibold text-green-600 underline decoration-2 underline-offset-4 decoration-yellow-400'>
									<br />{' '}
									<Link to='/restaurantpartners'>
										Send them here!
									</Link>
								</div>
							</div>
						)}
						{postSearchNodes.length > 0 && (
							<div
								className='h-[400px] md:h-2/3 overflow-y-scroll overflow-x-hidden scrollbar mb-50'
								id='results-scroller'
								ref={innerRef}
								onTouchStart={handleTouchStart}
								onTouchEnd={handleTouchEnd}
							>
								{postSearchNodes.map((node, index) => {
									return (
										<div key={index}>
											<div
												className='flex h-45px w-full-b items-center p-2 border-b mr-4 tracking-wider cursor-pointer relative'
												key={index}
											>
												<div
													className='text-center w-24 shrink-0'
													onClick={() => {
														handleMarkerClick(node)
													}}
												>
													<img
														className='h-24 w-24 shrink-0'
														src={node.thumbnail}
														alt='restaurant-thumbnail'
													/>
													{node.distanceToCoord ? (
														<div className='text-sm text-gray-400'>
															{node.distanceToCoord
																.toFixed(2)
																.toString()
																.substring(
																	0,
																	4
																)}{' '}
															miles
														</div>
													) : (
														<div className='text-sm text-gray-400'>
															0 miles
														</div>
													)}
												</div>
												<div className='pl-4 w-full flex flex-col'>
													<div className='w-full flex flex-row'>
														<div
															className='w-full sm:w-2/3'
															onClick={() => {
																handleMarkerClick(
																	node
																)
															}}
														>
															<div className='font-medium mb-1 max-w-[235px]'>
																{node.name}
															</div>
															{node.returnInstructions && (
																<div className='text-xs max-w-[235px] font-header mb-1'>
																	{
																		node?.returnInstructions
																	}
																</div>
															)}
															<div className='hidden lg:block'>
																<div className='text-sm text-gray-400 flex flex-row items-center my-auto mb-1'>
																	<div>
																		<FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
																	</div>
																	<div className='ml-1 text-sm text-gray-400 flex flex-col whitespace-nowrap'>
																		<div className=''>
																			{
																				node.address
																			}
																			,{' '}
																			{
																				node.city
																			}
																			,{' '}
																			{
																				node.state
																			}{' '}
																		</div>
																	</div>
																</div>
															</div>
															<div className='lg:hidden'>
																{node.address &&
																node.address
																	.length >
																	15 ? (
																	<div className='text-sm text-gray-400 flex flex-row items-center my-auto mb-1'>
																		<div>
																			<FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
																		</div>
																		<div className='ml-1 text-sm text-gray-400 flex flex-col'>
																			<div className='whitespace-nowrap'>
																				{
																					node.address
																				}
																			</div>
																			<div className='whitespace-nowrap'>
																				{
																					node.city
																				}

																				,{' '}
																				{
																					node.state
																				}
																			</div>
																		</div>
																	</div>
																) : (
																	<div className='text-sm text-gray-400 flex flex-row items-center my-auto mb-1'>
																		<div>
																			<FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
																		</div>
																		<div className='ml-1 text-sm text-gray-400 whitespace-nowrap'>
																			<div className=''>
																				{
																					node.address
																				}

																				,{' '}
																				{
																					node.city
																				}

																				,{' '}
																				{
																					node.state
																				}{' '}
																			</div>
																		</div>
																	</div>
																)}
															</div>
															{isHoliday && (
																<div className='text-red-300 text-xs'>
																	Hours may
																	differ on
																	holidays.
																</div>
															)}
															{node.openHoursGoogle &&
															Object.keys(
																node.openHoursGoogle
															).length > 0 ? (
																<div
																	onClick={(
																		e
																	) => {
																		e.stopPropagation()
																		const updatedOpenHoursDropDowns =
																			{
																				...openHoursDropDown,
																			}
																		updatedOpenHoursDropDowns[
																			node.name
																		] =
																			!updatedOpenHoursDropDowns[
																				node
																					.name
																			]
																		setOpenHoursDropDown(
																			updatedOpenHoursDropDowns
																		)
																	}}
																>
																	<div className='flex flex-row items-center text-xs w-[250px] mb-2'>
																		<div>
																			{node.openHoursGoogle
																				? formatTodayHours(
																						node
																							.openHoursGoogle[
																							daysOfWeekMap[
																								dayOfWeek
																							]
																						]
																				  )
																				: node.openHoursDescription}
																		</div>
																		{node.openHoursGoogle &&
																			!openHoursDropDown[
																				node
																					.name
																			] && (
																				<FaCaretDown className='text-green-400 text-[22px] text-center' />
																			)}
																		{node.openHoursGoogle &&
																			openHoursDropDown[
																				node
																					.name
																			] && (
																				<FaCaretUp className='text-green-400 text-[22px] text-center' />
																			)}
																	</div>
																	{node.openHoursGoogle &&
																		openHoursDropDown[
																			node
																				.name
																		] &&
																		node.openHoursGoogle.map(
																			(
																				day
																			) => {
																				return (
																					<div
																						className='mb-2 text-sm text-green-400 ml-2'
																						key={
																							day
																						}
																					>
																						{shortenDay(
																							day
																						)}
																					</div>
																				)
																			}
																		)}
																</div>
															) : (
																<div className='text-xs text-gray-400 mb-3 '>
																	{
																		node.openHoursDescription
																	}
																</div>
															)}
														</div>
														<div
															className='w-0 sm:w-1/3'
															onClick={() => {
																handleMarkerClick(
																	node
																)
															}}
														></div>
													</div>
													<div className='flex flex-row w-full'>
														<div className='w-1/2 xl:w-[215px]'>
															<Button
																size='sm'
																className='text-sm px-6 static z-10 relative'
																text="I'm Here"
																onClick={() => {
																	// console.log(node.slug)
																	locationValidation(
																		node
																	)
																}}
															/>
														</div>
														<div
															className='grow'
															onClick={() => {
																handleMarkerClick(
																	node
																)
															}}
														></div>
													</div>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						)}
					</div>
					{isLoaded && (
						<div className='w-full md:w-1/2 h-[200px] sm:h-[268px] md:h-[63%] mx-auto flex flex-col md:flex-row relative border-red-700'>
							<div className=''>
								<GoogleMap
									mapContainerStyle={{
										overflow: 'hidden',
										position: 'absolute',
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
										// width: '100%',
									}}
									// onLoad={onLoad}
									// onUnmount={onUnmount}
									zoom={selectedZoom}
									center={selectedCenterCoords}
								>
									{postSearchNodes.map((node) => {
										if (!node.coordinates) return
										const [lng, lat] = node.coordinates
										// bounds.extend({
										//   lat: Number(lat.toString()),
										//   lng: Number(lng.toString()),
										// })
										if (
											node.name === selectedMapNode.name
										) {
											return (
												<Marker
													onClick={() => {
														handleMarkerClick(node)
													}}
													key={node._id.toString()}
													title={node.name}
													icon={{
														url: 'https://i.imgur.com/YjB79uU.png',
														scaledSize:
															new google.maps.Size(
																50,
																50
															),
													}}
													name={node.name}
													position={{
														lat: Number(
															lat.toString()
														),
														lng: Number(
															lng.toString()
														),
													}}
												></Marker>
											)
										} else {
											return (
												<Marker
													onClick={() => {
														handleMarkerClick(node)
													}}
													key={node._id.toString()}
													title={node.name}
													icon={{
														url: 'https://i.imgur.com/ci46o90.png',
														scaledSize:
															new google.maps.Size(
																50,
																50
															),
													}}
													name={node.name}
													position={{
														lat: Number(
															lat.toString()
														),
														lng: Number(
															lng.toString()
														),
													}}
												></Marker>
											)
										}
									})}
								</GoogleMap>
							</div>
							{distPopupVisible && distanceInfo && (
								<div
									className={`absolute top-[60px] left-[10px] bg-white border-4 border-solid border-green-600 rounded-lg p-4 z-20 flex flex-col`}
								>
									<div className='flex flex-row justify-start items-center my-auto'>
										<FaRoute />
										<div className='ml-2'>
											{distanceInfo.distance} miles
										</div>
									</div>
									<a
										href={`https://www.google.com/maps/dir/?api=1&origin=${distanceInfo.originLat},${distanceInfo.originLng}&destination=${distanceInfo.destLat},${distanceInfo.destLng}`}
										target='_blank'
										rel='noopener noreferrer'
										className='text-blue-500 underline'
									>
										Open in Google Maps
									</a>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	)
}

const mapStateToProps = ({ Nodes, User, Location }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	searchedAddress: Nodes.searchedAddress,
	searchedAddressLatLng: Nodes.searchedAddressLatLng,
	userLocation: Location.userLocation,
	gpsLocationFound: Location.gpsLocationFound,
	userLocationError: Location.userLocationError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateSearchAddress: (address) => dispatch(updateSearchNodes(address)),
	onGetUserAddressLocation: () => dispatch(getAddressLocation()),
	onGetGPSLocation: () => dispatch(getGPSLocation()),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnLocations)
