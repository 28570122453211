import { realm } from '../helpers/realm'

export const getRlPartners = async () => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const partners = client.db('caas').collection('reverse_logistics_partners')
	const allPartners = await partners.find({})
	return allPartners
}

export const getRlPartner = async (query) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const partners = client.db('caas').collection('reverse_logistics_partners')
	const partner = await partners.findOne(query)
	return partner
}

export const getRlPartnersByZip = async (zip) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const partners = client.db('caas').collection('reverse_logistics_partners')
	// console.log('model zip',zip)
	return partners.find({ zipCodes: zip })
}

export const getRlPartnersByPolygon = async (coords) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const partner = await realm.currentUser.callFunction(
		'getRlPartnerByPolygon',
		coords
	)

	return partner
}

export const cancelFirstDeliveryPickup = async (deliveryId) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const response = await realm.currentUser.callFunction(
		'rlp/firstDelivery/cancelDelivery',
		deliveryId
	)

	return response
}

export const getThirdPartyPickupOptions = async () => {
	const client = realm.currentUser.mongoClient('RealmService')
	const partners = client.db('caas').collection('reverse_logistics_partners')
	const thirdPartyPickupOptions = await partners.find({
		type: 'pickup_with_order',
	})
	return thirdPartyPickupOptions
}
