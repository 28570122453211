import {
	GET_ALL_REUSABLE_PRODUCTS,
	GET_ALL_REUSABLE_PRODUCTS_SUCCESS,
	GET_ALL_REUSABLE_PRODUCTS_FAIL,
	ADD_TO_CART_REUSABLES_SHOP,
	ADD_TO_CART_REUSABLES_SHOP_SUCCESS,
	ADD_TO_CART_REUSABLES_SHOP_FAIL,
	UPDATE_CART_ITEM_REUSABLES_SHOP,
	UPDATE_CART_ITEM_REUSABLES_SHOP_SUCCESS,
	UPDATE_CART_ITEM_REUSABLES_SHOP_FAIL,
	UPDATE_CART_FROM_USER,
	UPDATE_CART_FROM_USER_SUCCESS,
	UPDATE_CART_FROM_USER_FAIL,
	ADD_CART_TO_USER_REUSABLES_SHOP,
	ADD_CART_TO_USER_REUSABLES_SHOP_SUCCESS,
	ADD_CART_TO_USER_REUSABLES_SHOP_FAIL,
	CLEAR_CART_REUSABLES_SHOP,
	CLEAR_CART_REUSABLES_SHOP_SUCCESS,
	CLEAR_CART_REUSABLES_SHOP_FAIL,
	SUBMIT_REUSABLES_ORDER,
	SUBMIT_REUSABLES_ORDER_FAIL,
	SUBMIT_REUSABLES_ORDER_SUCCESS,
	TOTAL_REUSABLES_CART,
	CALCULATE_SHIPPING,
	CALCULATE_SHIPPING_FAIL,
	CALCULATE_SHIPPING_SUCCESS,
	GET_REUSABLES_ORDERS,
	GET_REUSABLES_ORDERS_FAIL,
	GET_REUSABLES_ORDERS_SUCCESS,
	RESET_REUSABLES,
} from './actionTypes'
import { totalReusablesCart } from '../../model/reusablesShop'

const initialState = {
	error: null,
	loading: false,
	allProducts: null,
	productsCount: null,
	cartItemsReusablesShop: {},
	cartItemsLoading: false,
	cartItemsError: null,
	addingToUser: false,
	addingToUserError: null,
	clearingCartReusablesShop: false,
	clearCartErrorReusablesShop: null,
	cartTotal: 0,
	reusablesOrders: null,
	ordersCount: 0,
	shippingCost: null,
	loadingCost: false,
	costError: null,
	userUpdated: false,
}

const reusableProducts = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_REUSABLE_PRODUCTS:
			return {
				...state,
				loading: true,
			}
		case GET_ALL_REUSABLE_PRODUCTS_SUCCESS:
			const allProducts = action.payload
			return {
				...state,
				allProducts: allProducts,
				loading: false,
				error: null,
			}
		case GET_ALL_REUSABLE_PRODUCTS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case ADD_TO_CART_REUSABLES_SHOP:
			return {
				...state,
				cartItemsLoading: true,
			}
		case ADD_TO_CART_REUSABLES_SHOP_SUCCESS:
			return {
				...state,
				cartItemsLoading: false,
				cartItemsReusablesShop: action.payload,
			}
		case ADD_TO_CART_REUSABLES_SHOP_FAIL:
			return {
				...state,
				cartItemsLoading: false,
				cartItemsError: action.payload,
			}
		case UPDATE_CART_ITEM_REUSABLES_SHOP:
			return {
				...state,
				cartItemsLoading: true,
			}
		case UPDATE_CART_ITEM_REUSABLES_SHOP_SUCCESS:
			return {
				...state,
				cartItemsLoading: false,
				cartItemsReusablesShop: action.payload,
			}
		case UPDATE_CART_ITEM_REUSABLES_SHOP_FAIL:
			return {
				...state,
				cartItemsLoading: false,
				cartItemsError: action.payload,
			}
		case UPDATE_CART_FROM_USER:
			return {
				...state,
				cartItemsLoading: true,
			}
		case UPDATE_CART_FROM_USER_SUCCESS:
			return {
				...state,
				cartItemsLoading: false,
				cartItemsReusablesShop: action.payload,
			}
		case UPDATE_CART_FROM_USER_FAIL:
			return {
				...state,
				cartItemsLoading: false,
				cartItemsError: action.payload,
			}

		case ADD_CART_TO_USER_REUSABLES_SHOP:
			return {
				...state,
				addingToUser: true,
			}
		case ADD_CART_TO_USER_REUSABLES_SHOP_SUCCESS:
			return {
				...state,
				addingToUser: false,
				userUpdated: true,
				addingToUserError: null,
			}
		case ADD_CART_TO_USER_REUSABLES_SHOP_FAIL:
			return {
				...state,
				addingToUser: false,
				addingToUserError: action.payload,
			}
		case CLEAR_CART_REUSABLES_SHOP:
			return {
				...state,
				clearingCartReusablesShop: true,
			}
		case CLEAR_CART_REUSABLES_SHOP_SUCCESS:
			return {
				...state,
				clearingCartReusablesShop: false,
				cartItemsReusablesShop: action.payload,
			}
		case CLEAR_CART_REUSABLES_SHOP_FAIL:
			return {
				...state,
				clearingCartReusablesShop: false,
				clearCartErrorReusablesShop: action.payload,
			}
		case SUBMIT_REUSABLES_ORDER:
			return {
				...state,
				submittingOrder: true,
				submitOrderError: null,
			}
		case SUBMIT_REUSABLES_ORDER_SUCCESS:
			return {
				...state,
				submittingOrder: false,
				submittedOrder: action.payload,
			}
		case SUBMIT_REUSABLES_ORDER_FAIL:
			return {
				...state,
				submittingOrder: false,
				submitOrderError: action.payload,
			}
		case TOTAL_REUSABLES_CART:
			return {
				...state,
				cartTotal: totalReusablesCart(action.payload),
			}
		case CALCULATE_SHIPPING:
			return {
				...state,
				loadingCost: true,
				costError: null,
				shippingCost: null,
			}
		case CALCULATE_SHIPPING_SUCCESS:
			// console.log("SUCCESS", action.payload)
			return {
				...state,
				loadingCost: false,
				shippingCost: action.payload,
			}
		case CALCULATE_SHIPPING_FAIL:
			// console.log("FAIL", action.payload)
			return {
				...state,
				loadingCost: false,
				costError: action.payload,
				shippingCost: null,
			}
		case GET_REUSABLES_ORDERS:
			return {
				...state,
				loading: true,
				reusablesOrders: null,
			}
		case GET_REUSABLES_ORDERS_SUCCESS:
			return {
				...state,
				loading: false,
				reusablesOrders:
					action.payload[0].data.length === 0
						? []
						: action.payload[0].data,
				ordersCount: action.payload[0].pagination[0]
					? action.payload[0].pagination[0].total
					: 0,
			}
		case GET_REUSABLES_ORDERS_FAIL:
			return {
				...state,
				loading: false,
				reusablesOrders: null,
				error: action.payload,
			}
		case RESET_REUSABLES:
			return {
				...state,
				submittingOrder: false,
				submittedOrder: null,
				submitOrderError: null,
				reusablesOrders: null,
				error: null,
				shippingCost: null,
				costError: null,
				userUpdated: false,
			}
		default:
			return state
	}
}

export default reusableProducts
