import {
	RUN_PENTEST,
	RUN_PENTEST_SUCCESS,
	RUN_PENTEST_ERROR,
} from './actionTypes'

const initialState = {
	runningPentest: false,
	pentestResult: null,
	runPentestError: null,
}

const pentest = (state = initialState, action) => {
	switch (action.type) {
		case RUN_PENTEST:
			return {
				...state,
				runningPentest: true,
				pentestResult: null,
				runPentestError: null,
			}
		case RUN_PENTEST_SUCCESS:
			return {
				...state,
				runningPentest: false,
				pentestResult: action.payload,
				runPentestError: null,
			}
		case RUN_PENTEST_ERROR:
			return {
				...state,
				runningPentest: false,
				pentestResult: null,
				runPentestError: action.payload,
			}
		default:
			return state
	}
}

export default pentest
