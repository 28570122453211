import {
	GET_PAYMENT_METHODS_FAIL,
	GET_PAYMENT_METHODS_SUCCESS,
	CREATE_PAYMENT_METHOD_FAIL,
	CREATE_PAYMENT_METHOD_SUCCESS,
	CREATE_PAYMENT_METHOD_CONFIRM_SUCCESS,
	CREATE_PAYMENT_METHOD_CONFIRM_FAIL,
	DELETE_PAYMENT_METHOD_FAIL,
	DELETE_PAYMENT_METHOD_SUCCESS,
	GET_PAYMENT_METHODS,
	CREATE_PAYMENT_METHOD_CONFIRM,
	DELETE_PAYMENT_METHOD,
	STORE_PAYMENT_METHOD,
	STORE_PAYMENT_METHOD_SUCCESS,
	STORE_PAYMENT_METHOD_FAIL,
	GET_PAYMENTS,
	GET_PAYMENTS_SUCCESS,
	GET_PAYMENTS_FAIL,
	RESET_CARD_ADDED,
	CHARGE_PAYMENT_METHOD,
	CHARGE_PAYMENT_METHOD_SUCCESS,
	CHARGE_PAYMENT_METHOD_FAIL,
	RESET_CARD_RESPONSE,
	CHARGE_GUEST_PAYMENT,
	CHARGE_GUEST_PAYMENT_SUCCESS,
	CHARGE_GUEST_PAYMENT_FAIL,
	STORE_GUEST_PAYMENT,
	STORE_GUEST_PAYMENT_SUCCESS,
	STORE_GUEST_PAYMENT_FAIL,
} from './actionTypes'

const initialState = {
	payments: [],
	paymentsCount: 0,
	loadingPayments: false,
	clientSecret: null,
	paymentMethods: [],
	error: null,
	deletionError: null,
	loadingPaymentMethods: false,
	deleted: false,
	created: null,
	loadingConfirmation: false,
	loadingAddition: false,
	loadingDeletion: false,
	cardAdded: false,
	chargingCard: false,
	chargingCardResponse: null,
	chargingCardError: null,
}

const payments = (state = initialState, action) => {
	switch (action.type) {
		case GET_PAYMENTS:
			return {
				...state,
				loadingPayments: true,
			}

		case GET_PAYMENTS_SUCCESS:
			const [result] = action.payload
			const { data, pagination } = result
			return {
				...state,
				loadingPayments: false,
				payments: data,
				paymentsCount: pagination[0].total,
			}

		case GET_PAYMENTS_FAIL:
			return {
				...state,
				loadingPayments: false,
				error: action.payload,
			}

		case GET_PAYMENT_METHODS:
			return {
				...state,
				loadingPaymentMethods: true,
			}

		case GET_PAYMENT_METHODS_SUCCESS:
			// console.log(action.payload)
			return {
				...state,
				loadingPaymentMethods: false,
				paymentMethods: action.payload,
			}

		case GET_PAYMENT_METHODS_FAIL:
			return {
				...state,
				loadingPaymentMethods: false,
				error: action.payload,
			}

		case CREATE_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				loading: false,
				clientSecret: action.payload,
			}

		case CREATE_PAYMENT_METHOD_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case CREATE_PAYMENT_METHOD_CONFIRM:
			return {
				...state,
				loadingConfirmation: true,
			}

		case CREATE_PAYMENT_METHOD_CONFIRM_SUCCESS:
			return {
				...state,
				loading: false,
				created: action.payload,
				loadingConfirmation: false,
			}

		case CREATE_PAYMENT_METHOD_CONFIRM_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
				loadingConfirmation: false,
			}

		case STORE_PAYMENT_METHOD:
			return {
				...state,
				loadingAddition: true,
				cardAdded: false,
			}

		case STORE_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				paymentMethods: action.payload,
				cardAdded: true,
				loadingAddition: false,
				error: null,
			}

		case STORE_PAYMENT_METHOD_FAIL:
			return {
				...state,
				error: action.payload,
				loadingAddition: false,
				cardAdded: false,
			}

		case DELETE_PAYMENT_METHOD:
			return {
				...state,
				loadingDeletion: true,
				deleted: false,
			}
		case DELETE_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				loadingDeletion: false,
				paymentMethods: action.payload,
				deleted: true,
			}

		case DELETE_PAYMENT_METHOD_FAIL:
			return {
				...state,
				loadingDeletion: false,
				deletionError: action.payload,
				deleted: false,
			}

		case RESET_CARD_ADDED:
			return {
				...state,
				cardAdded: false,
			}

		case CHARGE_PAYMENT_METHOD:
			return {
				...state,
				chargingCard: true,
				chargingCardResponse: null,
			}

		case CHARGE_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				chargingCard: false,
				chargingCardResponse: action.payload,
			}

		case CHARGE_PAYMENT_METHOD_FAIL:
			return {
				...state,
				chargingCard: false,
				chargingCardError: action.payload,
			}
		case RESET_CARD_RESPONSE:
			return {
				...state,
				chargingCard: false,
				chargingCardResponse: null,
				chargingCardError: null,
				cardAdded: false,
			}

		case CHARGE_GUEST_PAYMENT:
			return {
				...state,
				chargingCard: true,
				chargingCardResponse: null,
				loadingAddition: true,
				cardAdded: false,
			}

		case CHARGE_GUEST_PAYMENT_SUCCESS:
			// console.log("SUCCESS:", action.payload)
			return {
				...state,
				chargingCard: false,
				chargingCardResponse: action.payload,
				cardAdded: true,
				loadingAddition: false,
			}

		case CHARGE_GUEST_PAYMENT_FAIL:
			// console.log("FAILED:", action.payload)
			return {
				...state,
				chargingCard: false,
				chargingCardError: action.payload,
				loadingAddition: false,
				cardAdded: false,
			}

		case STORE_GUEST_PAYMENT:
			return {
				...state,
				loadingAddition: true,
				cardAdded: false,
			}

		case STORE_GUEST_PAYMENT_SUCCESS:
			// console.log("SUCCESS:", action.payload)
			return {
				...state,
				paymentMethods: action.payload,
				cardAdded: true,
				loadingAddition: false,
				error: null,
			}

		case STORE_GUEST_PAYMENT_FAIL:
			// console.log("FAILED:", action.payload)
			return {
				...state,
				error: action.payload,
				loadingAddition: false,
				cardAdded: false,
			}
		default:
			return state
	}
}

export default payments
