import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getOffice, getOfficeTransactions } from '../../store/actions'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Button } from '../../components/Elements'

const OfficeOrders = (state) => {
	const {
		onGetUser,
		onGetOffice,
		onGetTransactions,
		transactions,
		loadingTransactions,
		office,
		loadingOffice,
		user,
		loadingUser,
	} = state

	const paginationLimit = 25
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)

	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.officeSlug) {
			onGetOffice(user.officeSlug)
		}
	}, [user])

	const getTransactions = (page) => {
		const slug = office.slug
		let filter = {
			type: 'boxesOut',
			'user.email': { $regex: slug },
		}

		onGetTransactions({
			filter: filter,
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const transactionPaginate = () => {
		getTransactions(page)
	}

	useEffect(() => {
		if (office && office.slug) {
			transactionPaginate()
		}
	}, [office, page])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.officeSlug) {
		if (office) {
			return (
				<>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/office-ordering/home')
						}}
					>
						&#8592; Back to Office Dashboard
					</div>
					<div className='flex flex-col items-center'>
						<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-3 sm:mb-9 mt-5 sm:mt-14 text-center'>
							{office.name}'s Orders
						</h1>
					</div>
					<div className='w-full'>
						<div className='flex flex-col max-w-[1200px] justify-center mb-10 mx-auto overflow-x-auto overflow-y-auto scrollbar'>
							<div className='w-full'>
								{transactions.length > 0 ? (
									<div>
										<div className='min-w-[570px] w-[570px] sm:w-[1040px] px-2 sm:px-5 py-5 my-6 shadow-light-grey mx-auto'>
											<div className='flex flex-row min-w-[550px] w-[550px] sm:w-[1000px] mb-3 px-2 sm:px-5 text-green-600 text-left font-bold'>
												<div className='font-bold text-sm sm:text-base min-w-[80px] w-[80px] sm:w-[160px] mr-2 sm:mr-4'>
													{office.aggregatedOrdering
														? 'Office'
														: 'Employee'}
												</div>
												<div className='font-bold text-sm sm:text-base min-w-[80px] w-[80px] sm:w-[160px] mr-2 sm:mr-4'>
													Admin
												</div>
												<div className='font-bold text-sm sm:text-base min-w-[50px] w-[50px] sm:w-[160px] mr-2 sm:mr-4'>
													Date
												</div>
												<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[160px] mr-2 sm:mr-4'>
													Restaurant
												</div>
												<div className='font-bold text-sm sm:text-base min-w-[50px] w-[50px] sm:w-[160px] mr-2 sm:mr-4 text-center'>
													Boxes
												</div>
												<div className='font-bold text-sm sm:text-base min-w-[100px] w-[100px] sm:w-[160px]'></div>
											</div>
											{(loadingTransactions ||
												loadingOffice) && (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
											{transactions.map((transaction) => {
												const transactionDate =
													new Date(
														transaction.timestamp
													)
												const transactionDateString =
													transactionDate.toLocaleString(
														'default',
														{
															month: 'short',
															day: 'numeric',
															year: 'numeric',
														}
													)
												return (
													<div
														className='min-w-[550px] w-[550px] sm:w-[1000px] flex flex-row my-2 even:bg-gray-100 py-1 px-2 sm:px-5'
														key={transaction._id}
													>
														<div className='min-w-[80px] w-[80px] sm:w-[160px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
															{
																transaction.user
																	.firstName
															}{' '}
															{
																transaction.user
																	.lastName
															}
														</div>
														<div className='min-w-[80px] w-[80px] sm:w-[160px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
															{transaction.user
																.officeAdmin
																? transaction
																		.user
																		.officeAdmin
																: 'No Admin Assigned'}
														</div>
														<div className='min-w-[50px] w-[50px] sm:w-[160px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
															{
																transactionDateString
															}
														</div>
														<div className='min-w-[100px] w-[100px] sm:w-[160px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
															{transaction.nodeObject ? (
																<div className=''>
																	{
																		transaction
																			.nodeObject[0]
																			.name
																	}
																</div>
															) : (
																<div className=''>
																	{
																		transaction.node
																	}
																</div>
															)}
														</div>
														<div className='min-w-[50px] w-[50px] sm:w-[160px] text-xs sm:text-base pt-2 mr-2 sm:mr-4 text-center'>
															{
																transaction.boxCount
															}
														</div>
														<div className='min-w-[100px] w-[100px] sm:w-[160px] text-xs sm:text-base pt-2 mr-2 sm:mr-4'>
															<Button
																className='text-xs sm:text-base'
																size='xs'
																text='View Order'
																onClick={() => {
																	navigate(
																		`/office-ordering/orders/${transaction._id}`
																	)
																}}
															/>
														</div>
													</div>
												)
											})}
										</div>
										{transactions.length >
										paginationLimit ? (
											<div className='flex flex-row'>
												<div
													className='mx-auto underline font-bold mr-6 cursor-pointer'
													onClick={() => {
														setPage(page - 1)
													}}
												>
													Previous {paginationLimit}{' '}
													transactions
												</div>
												<div
													className='mx-auto underline font-bold cursor-pointer'
													onClick={() => {
														setPage(page + 1)
														setLoading(true)
													}}
												>
													Next {paginationLimit}{' '}
													transactions
												</div>
											</div>
										) : (
											<div></div>
										)}
										{loadingTransactions && (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										)}
									</div>
								) : (
									<div className='text-center my-6'>
										No orders to display
									</div>
								)}
							</div>
						</div>
					</div>
					<Footer />
				</>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, OfficeOrdering }) => ({
	transactions: OfficeOrdering.transactions,
	transactionsCount: OfficeOrdering.transactionsCount,
	loadingTransactions: OfficeOrdering.loadingTransactions,
	office: OfficeOrdering.office,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetOffice: (officeSlug) => dispatch(getOffice(officeSlug)),
	onGetTransactions: (payload) => dispatch(getOfficeTransactions(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeOrders)
