import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAppInfo,
	getNodes,
	getContainers,
	getAllLogistics,
	getTransactions,
	getPendingPickups,
	getIncompleteStops,
	getLogisticsDashboard,
	resetSuccess,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	SelectGroup,
	TextArea,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const LogisticsMonthly = (props) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		containers,
		onGetContainers,
		stopsError,
		logisticsStops,
		loadingStops,
		stopsSuccess,
		stops,
		loadingLogistics,
		logisticsError,
		onGetAllStops,
		onGetTransactions,
		transactions,
		loadingTransactions,
		transactionsError,
		transactionsCount,
		pendingPickup,
		pickupSuccess,
		pickupLoading,
		pickupError,
		pendingPickups,
		onGetPendingPickups,
		onGetIncompleteStops,
		incompleteStops,
		onGetLogisticsDashboard,
		logisticsDashboard,
		logisticsMonthly,
		logisticsVariables,
		onResetSuccess,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	const [selectedCategory, setSelectedCategory] = useState(null)
	const [selectedVariable, setSelectedVariable] = useState(null)
	const [variableOptions, setVariableOptions] = useState([])
	const [numberOfStops, setNumberOfStops] = useState(null)
	const [boxCount, setBoxCount] = useState(null)

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetLogisticsDashboard({
			filter: {},
			page: 1,
			paginationLimit: 50,
		})
	}, [onGetLogisticsDashboard])

	useEffect(() => {
		if (
			logisticsVariables &&
			selectedCategory &&
			logisticsVariables[selectedCategory]
		) {
			const variablesArray = []
			for (const variable of logisticsVariables[selectedCategory]) {
				if (variable) {
					variablesArray.push({
						label: variable,
						value: variable,
					})
				}
			}
			setVariableOptions(variablesArray)
		}
	}, [selectedCategory, logisticsVariables])

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	useEffect(() => {
		if (selectedCategory && selectedVariable) {
			if (selectedCategory === 'completedBy') {
				setNumberOfStops('numberOfStopsByDriver')
				setBoxCount('boxCountByDriver')
			} else {
				setNumberOfStops(
					`numberOfStopsBy${capitalizeFirstLetter(selectedCategory)}`
				)
				setBoxCount(
					`boxCountBy${capitalizeFirstLetter(selectedCategory)}`
				)
			}
		}
	}, [selectedCategory, selectedVariable])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-6 mt-14 text-center'>
					Monthly Mapping
				</h1>

				<div className='w-full flex justify-center items-center mb-3'>
					<SelectGroup
						label=''
						value={selectedCategory}
						placeholder='Select Category...'
						className='h-12 mt-3 min-w-[300px]'
						onChange={(e) => {
							setSelectedCategory(e.target.value)
						}}
						options={[
							{ label: 'Stop Type', value: 'stopType' },
							{ label: 'Provider', value: 'provider' },
							{ label: 'Completed By', value: 'completedBy' },
						]}
					/>
				</div>
				{selectedCategory && (
					<div className='w-full flex justify-center items-center mb-3'>
						<SelectGroup
							label=''
							value={selectedVariable}
							placeholder='Select Variable...'
							className='h-12 min-w-[300px]'
							onChange={(e) => {
								setSelectedVariable(e.target.value)
								onResetSuccess()
							}}
							options={variableOptions}
						/>
					</div>
				)}

				{numberOfStops && boxCount && selectedVariable && (
					<div className='flex flex-col overflow-auto max-w-[800px] mx-auto justify-center items-center my-6'>
						<table className='w-full border-collapse border border-gray-300'>
							<thead>
								<tr className='bg-gray-100'>
									<th className='border border-gray-300 py-2 px-4'>
										Month-Year
									</th>
									<th className='border border-gray-300 py-2 px-4'>
										Number of Stops
									</th>
									<th className='border border-gray-300 py-2 px-4'>
										Box Count
									</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(logisticsMonthly).map(
									(monthYear) => (
										<tr
											key={`row-${monthYear}-${selectedVariable}`}
										>
											<td className='border border-gray-300 py-2 px-4'>
												{monthYear}
											</td>
											<td className='border border-gray-300 py-2 px-4'>
												{
													logisticsMonthly[monthYear][
														numberOfStops
													][selectedVariable]
												}
											</td>
											<td className='border border-gray-300 py-2 px-4'>
												{
													logisticsMonthly[monthYear][
														boxCount
													][selectedVariable]
												}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
				)}

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	Containers,
	Logistics,
	Transactions,
	Pickups,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	stops: Logistics.stops,
	loadingStops: Logistics.loadingStops,
	stopsSuccess: Logistics.success,
	transactions: Transactions.transactions,
	loadingTransactions: Transactions.loadingTransactions,
	transactionsCount: Transactions.transactionsCount,
	transactionsError: Transactions.error,
	pendingPickup: Pickups.pickup,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pickupLoading: Pickups.pickupLoading,
	pendingPickups: Pickups.pendingPickups,
	incompleteStops: Logistics.incompleteStops,
	logisticsDashboard: Logistics.logisticsDashboard,
	logisticsError: Logistics.error,
	loadingLogistics: Logistics.loading,
	logisticsMonthly: Logistics.logisticsMonthly,
	logisticsVariables: Logistics.logisticsVariables,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetUser: () => dispatch(getUser()),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onGetAllStops: (payload) => dispatch(getAllLogistics(payload)),
	onGetTransactions: (filter) => dispatch(getTransactions(filter)),
	onCreatePendingPickup: (payload) => dispatch(createPendingPickup(payload)),
	onGetPendingPickups: (payload) => dispatch(getPendingPickups(payload)),
	onGetIncompleteStops: (payload) => dispatch(getIncompleteStops(payload)),
	onGetLogisticsDashboard: (payload) =>
		dispatch(getLogisticsDashboard(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsMonthly)
