import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAppInfo,
	getNodes,
	getContainers,
	getAllLogistics,
	resetSuccess,
	getTransactions,
	getPendingPickups,
	getIncompleteStops,
	getLogisticsDashboard,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const LogisticsDashboard = (props) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		containers,
		onGetContainers,
		stopsError,
		logisticsStops,
		loadingStops,
		stopsSuccess,
		stops,
		loadingLogistics,
		logisticsError,
		onGetAllStops,
		onGetTransactions,
		transactions,
		loadingTransactions,
		transactionsError,
		transactionsCount,
		pendingPickup,
		pickupSuccess,
		pickupLoading,
		pickupError,
		pendingPickups,
		onGetPendingPickups,
		onGetIncompleteStops,
		incompleteStops,
		onGetLogisticsDashboard,
		logisticsDashboard,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [date, setDate] = useState('')
	const [market, setMarket] = useState(null)
	const [stopType, setStopType] = useState(null)
	const [locationType, setLocationType] = useState(null)
	const [showIncomplete, setShowIncomplete] = useState(true)
	const [page, setPage] = useState(1)

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const getDashboard = () => {
		onGetLogisticsDashboard({
			filter: filter,
			page: page,
			paginationLimit: paginationLimit,
		})
	}

	const dashboardPaginate = () => {
		getDashboard()
	}

	useEffect(() => {
		dashboardPaginate()
	}, [page])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	// console.log(logisticsDashboard)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-11 mt-14 text-center'>
					Logistics Dashboard
				</h1>
				<div className='px-2'>
					<div className='my-10 max-w-[800px] lg:max-w-[2400px] max-h-[900px] lg:max-h-[1200px] overflow-auto scrollbar flex flex-col justify-center align-center mx-auto'>
						<div className='overflow-x-auto'>
							<table className='w-full border-collapse border border-gray-300'>
								<thead>
									<tr className='bg-gray-100'>
										<th className='border border-gray-300 py-2 px-4'>
											Date
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											Market
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											Provider
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											STOP TYPE
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											Notes
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											Total Box Count
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#OG-12oz
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#OG-16oz
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#OG Small
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#OG Medium
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#OG Large
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#DZ-12oz
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#DZ-17F
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#DZ-17V
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#DZ-35V
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#12oz CUPS
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											#16oz CUPS
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											INTERNAL or THIRD PARTY?
										</th>
										<th className='border border-gray-300 py-2 px-4'>
											COMPLETED BY
										</th>
									</tr>
								</thead>
								<tbody>
									{logisticsDashboard &&
									logisticsDashboard.length > 0 ? (
										logisticsDashboard.map(
											(rowValues, rowIndex) => (
												<tr
													key={rowIndex}
													className=''
												>
													{Object.values(
														rowValues
													).map((item, cellIndex) => (
														<td
															key={cellIndex}
															className='border border-gray-300 py-2 px-4'
														>
															{item}
														</td>
													))}
												</tr>
											)
										)
									) : loadingLogistics ? (
										<div className='flex flex-col justify-center items-center m-auto'>
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										</div>
									) : (
										<div className='text-center text-red-400 my-[50px]'>
											No results found
										</div>
									)}
								</tbody>
							</table>
						</div>
						<div className='flex flex-row w-full'>
							<div
								className='mx-auto underline font-bold mr-6 cursor-pointer'
								onClick={() => {
									setPage(page - 1)
								}}
							>
								Previous {paginationLimit}
							</div>
							<div
								className='mx-auto underline font-bold cursor-pointer'
								onClick={() => {
									setPage(page + 1)
								}}
							>
								Next {paginationLimit}
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	Containers,
	Logistics,
	Transactions,
	Pickups,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	stops: Logistics.stops,
	loadingStops: Logistics.loadingStops,
	stopsSuccess: Logistics.success,
	transactions: Transactions.transactions,
	loadingTransactions: Transactions.loadingTransactions,
	transactionsCount: Transactions.transactionsCount,
	transactionsError: Transactions.error,
	pendingPickup: Pickups.pickup,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pickupLoading: Pickups.pickupLoading,
	pendingPickups: Pickups.pendingPickups,
	incompleteStops: Logistics.incompleteStops,
	logisticsDashboard: Logistics.logisticsDashboard,
	logisticsError: Logistics.error,
	loadingLogistics: Logistics.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetUser: () => dispatch(getUser()),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onGetAllStops: (payload) => dispatch(getAllLogistics(payload)),
	onGetTransactions: (filter) => dispatch(getTransactions(filter)),
	onCreatePendingPickup: (payload) => dispatch(createPendingPickup(payload)),
	onGetPendingPickups: (payload) => dispatch(getPendingPickups(payload)),
	onGetIncompleteStops: (payload) => dispatch(getIncompleteStops(payload)),
	onGetLogisticsDashboard: (payload) =>
		dispatch(getLogisticsDashboard(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsDashboard)
