export const GET_LEAD = 'GET_LEAD'
export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS'
export const GET_LEAD_FAIL = 'GET_LEAD_FAIL'
export const ADD_LEAD = 'ADD_LEAD'
export const ADD_LEAD_SUCCESS = 'ADD_LEAD_SUCCESS'
export const ADD_LEAD_FAIL = 'ADD_LEAD_FAIL'
export const UPDATE_LEAD = 'UPDATE_LEAD'
export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS'
export const UPDATE_LEAD_FAIL = 'UPDATE_LEAD_FAIL'
export const GET_ALL_LEADS = 'GET_ALL_LEADS'
export const GET_ALL_LEADS_SUCCESS = 'GET_ALL_LEADS_SUCCESS'
export const GET_ALL_LEADS_FAIL = 'GET_ALL_LEADS_FAIL'
export const GENERATE_DOCUSIGN = 'GENERATE_DOCUSIGN'
export const GENERATE_DOCUSIGN_SUCCESS = 'GENERATE_DOCUSIGN_SUCCESS'
export const GENERATE_DOCUSIGN_FAIL = 'GENERATE_DOCUSIGN_FAIL'
export const FINALIZE_NODE = 'FINALIZE_NODE'
export const FINALIZE_NODE_SUCCESS = 'FINALIZE_NODE_SUCCESS'
export const FINALIZE_NODE_FAIL = 'FINALIZE_NODE_FAIL'
export const GET_SERVICE_AREAS = 'GET_SERVICE_AREAS'
export const GET_SERVICE_AREAS_SUCCESS = 'GET_SERVICE_AREAS_SUCCESS'
export const GET_SERVICE_AREAS_FAIL = 'GET_SERVICE_AREAS_FAIL'
export const CHECK_PROMO_CODE_VALIDITY = 'CHECK_PROMO_CODE_VALIDITY'
export const CHECK_PROMO_CODE_VALIDITY_SUCCESS =
	'CHECK_PROMO_CODE_VALIDITY_SUCCESS'
export const CHECK_PROMO_CODE_VALIDITY_FAIL = 'CHECK_PROMO_CODE_VALIDITY_FAIL'
