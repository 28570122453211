import React from 'react'
import { connect } from 'react-redux'
import { Footer } from '../../components'
import { Button } from '../../components/Elements'
import { Link } from 'react-router-dom'
import {
	FaHamburger,
	FaUtensilSpoon,
	FaWineGlass,
	FaBreadSlice,
	FaUtensils,
	FaTruck,
	FaCarrot,
	FaConciergeBell,
} from 'react-icons/fa'
import { trackUsage } from '../../store/actions'

const MerchantBenefitsPage = (props) => {
	const { analytics, error, onTrackUsage } = props

	const handleButtonClick = () => {
		onTrackUsage({
			url: '/merchant-benefits',
			feature: 'DD_button',
		})
	}

	return (
		<>
			{/* <Header /> */}
			<div className='flex flex-row py-5'>
				{/* DZ Logo */}
				<div className='hidden sm:block'>
					<Link
						to='/'
						className='flex h-20 flex-shrink-0 mr-[min(12px,12rem)] '
					>
						<img
							alt=''
							className='ml-4 w-[300px] my-auto h-2/3'
							src='/images/PrimaryCropped.svg'
						/>
					</Link>
				</div>
				<div className='sm:hidden'>
					<Link
						to='/'
						className='flex h-20 flex-shrink-0 mr-[min(12px,12rem)] mt-2 mr-4'
					>
						<img
							alt=''
							className='ml-6 w-[50px] my-auto h-2/3'
							src='/images/deliver zero _ mark only.svg'
						/>
					</Link>
				</div>

				{/* Vertical Line */}
				<div className='border-l border-gray-500 h-12 sm:h-20 my-auto mx-1 sm:mx-5' />

				{/* DoorDash Logo */}
				<img
					src='images/PlatformPartners/doordash.png'
					alt='doordash logo'
					className='w-[320px] h-auto my-auto hidden sm:block'
				/>
				<img
					src='images/PlatformPartners/dd_logo_only.png'
					alt='doordash logo'
					className='w-auto ml-3 h-[40px] flex mt-7 sm:hidden'
				/>
			</div>
			<div
				id='merchantBenefits'
				className='p-0 m-0 sm:p-10 lg:p-20 h-[550px] hidden sm:block flex justify-center items-center'
				style={{
					backgroundImage: `url("https://i.imgur.com/N8Tqgz8.jpg")`,
					height: 'full',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
			>
				<div className='z-20 flex flex-col p-5 mx-auto w-[95%] bg-green-600 shadow-light-grey rounded-sm justify-center items-center'>
					<div className='bg-white shadow-light-grey rounded-xl w-full'>
						{/* Welcome Message */}
						<div className='my-auto flex flex-col justify-center text-left p-[50px]'>
							<h1 className='font-header text-green-600 text-3xl sm:text-4xl lg:text-5xl my-8'>
								Welcome DoorDash Restaurant Partners!
							</h1>
							<p className='text-2xl mb-8'>
								Get your first month of DeliverZero containers
								FREE
							</p>
							<Button
								text='Claim offer'
								color='green'
								className='py-3 px-8 rounded-full text-2xl font-semibold w-[200px]'
								link='/restaurantpartners/sign-up/page-1?promo=DD2024'
								onClick={() => {
									handleButtonClick()
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				id='merchantBenefits'
				className='p-0 m-0 sm:hidden'
			>
				<div className='z-20 flex flex-col p-5 mx-auto w-full bg-green-600 shadow-light-grey rounded-sm py-[50px]'>
					<div className='bg-white shadow-light-grey rounded-xl w-full'>
						{/* Welcome Message */}
						<div className='my-auto flex flex-col justify-center text-left ml-3 p-[10px]'>
							<h1 className='font-header text-green-600 text-3xl sm:text-4xl lg:text-5xl my-8'>
								Welcome DoorDash Restaurant Partners!
							</h1>
							<p className='text-xl mb-8'>
								Get your first month of DeliverZero containers
								FREE
							</p>
							<Button
								text='Claim offer'
								color='green'
								size='lg'
								className='py-3 px-8 rounded-full text-lg font-semibold w-[200px] mb-3'
								link='/restaurantpartners/sign-up/page-1?promo=DD2024'
							/>
						</div>
					</div>
				</div>
			</div>
			{/* Merchant Benefits */}
			<div className='w-full lg:flex flex-col items-center justify-center'>
				{/* Benefits Overview */}
				<div className='w-full lg:w-[90%] py-[50px] sm:py-150px] flex flex-col justify-center items-center'>
					<h2 className='font-header text-green-600 text-2xl sm:text-3xl mb-1 mx-[30px] mb-8 lg:mb-12'>
						Built for restaurants of all shapes and sizes.
					</h2>
					<div className='grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 w-[95%] flex items-center justify-center ml-16 sm:ml-0 '>
						<div className='flex flex-col justify-center sm:items-center'>
							<ul className='list-inside text-lg sm:text-xl lg:text-2xl mb-6 sm:mb-8'>
								<li className='mb-6'>
									{/* <FaHamburger className='h-6 w-6 lg:h-10 lg:w-10 text-green-600 mr-3'/> */}
									QSR
								</li>
								<li>
									{/* <FaUtensilSpoon className='h-6 w-6 lg:h-10 lg:w-10 text-green-600 mr-3'/> */}
									Casual Dining
								</li>
							</ul>
						</div>
						<div className='flex flex-col justify-center sm:items-center'>
							<ul className='list-inside text-lg sm:text-xl lg:text-2xl mb-6 sm:mb-8'>
								<li className='mb-6'>
									{/* <FaWineGlass className='h-6 w-6 lg:h-10 lg:w-10 text-green-600 mr-3'/> */}
									Fine Dining
								</li>
								<li>
									{/* <FaCarrot className='h-6 w-6 lg:h-10 lg:w-10 text-green-600 mr-3'/> */}
									Caterer
								</li>
							</ul>
						</div>
						<div className='flex flex-col justify-center sm:items-center'>
							<ul className='list-inside text-lg sm:text-xl lg:text-2xl mb-6 sm:mb-8'>
								<li className='mb-6'>
									{/* <FaUtensils className='h-6 w-6 lg:h-10 lg:w-10 text-green-600 mr-3'/> */}
									Fast-casual
								</li>
								<li>
									{/* <FaBreadSlice className='h-6 w-6 lg:h-10 lg:w-10 text-green-600 mr-3'/> */}
									Cafe/Bakery
								</li>
							</ul>
						</div>
						<div className='flex flex-col justify-center sm:items-center'>
							<ul className='list-inside text-lg sm:text-xl lg:text-2xl mb-6 sm:mb-8'>
								<li className='mb-6'>
									{/* <FaConciergeBell className='h-6 w-6 lg:h-12 lg:w-12 text-green-600 mr-3'/> */}
									Full Service
								</li>
								<li>
									{/* <FaTruck className='h-6 w-6 lg:h-12 lg:w-12 text-green-600 mr-3'/> */}
									Food truck
								</li>
							</ul>
						</div>
					</div>
				</div>
				{/* Stats Tiles */}
				<div className='w-full py-[50px] sm:py-[50px] bg-green-600 flex flex-col justify-center items-center'>
					<div className='w-[90%]'>
						<div className='grid grid-cols-1 md:grid-cols-2 gap-4 sm:mb-8'>
							<div className='px-6 rounded-xl shadow-light-grey text-center bg-white h-[200px] flex flex-col items-center justify-center'>
								<h3 className='font-header text-green-600 text-2xl sm:text-3xl mb-4'>
									No start up or monthly fees
								</h3>
								<p className='hidden text-xl lg:block mb-4'>
									We stock you up with reusable <br />{' '}
									packaging at no cost. You only <br /> pay
									for what you use
								</p>
								<p className='text-lg sm:text-xl mb-4 lg:hidden'>
									We stock you up with reusable packaging at
									no cost. You only pay for what you use
								</p>
							</div>
							<div className='p-6 rounded-xl shadow-light-grey text-center bg-white h-[200px] flex flex-col items-center justify-center'>
								<h3 className='font-header text-green-600 text-2xl sm:text-3xl mb-4'>
									Attract new customers
								</h3>
								<p className='hidden text-xl lg:block mb-4'>
									86% of our customers have ordered <br />{' '}
									from a restaurant they never tried <br />{' '}
									before because of DeliverZero
								</p>
								<p className='text-lg sm:text-xl mb-4 lg:hidden'>
									86% of our customers have ordered from a
									restaurant they never tried before because
									of DeliverZero
								</p>
							</div>
							<div className='p-6 rounded-xl shadow-light-grey text-center bg-white h-[200px] flex flex-col items-center justify-center'>
								<h3 className='font-header text-green-600 text-2xl sm:text-3xl mb-4'>
									40% savings
								</h3>
								<p className='hidden text-xl lg:block mb-4'>
									Using our packaging costs less than <br />{' '}
									biodegradable or compostable packaging{' '}
									<br /> - and it’s actually sustainable
								</p>
								<p className='text-lg sm:text-xl mb-4 lg:hidden'>
									Using our packaging costs less than
									biodegradable or compostable packaging–and
									it’s actually sustainable
								</p>
							</div>
							<div className='p-6 rounded-xl shadow-light-grey text-center bg-white h-[200px] flex flex-col items-center justify-center'>
								<h3 className='font-header text-green-600 text-2xl sm:text-3xl mb-4'>
									Increase revenue
								</h3>
								<p className='hidden text-xl lg:block mb-4'>
									Our customers spend an average of <br /> $10
									more than customers ordering <br /> from the
									same restaurants
								</p>
								<p className='text-lg sm:text-xl mb-4 lg:hidden'>
									Our customers spend an average of $10 more
									than customers ordering from the same
									restaurants
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* Testimonial */}
				<div className='my-12 flex flex-col justify-center items-center'>
					<h2 className='font-header text-2xl sm:text-3xl text-green-600 mb-3 text-left mx-[30px]'>
						We make reuse easy for everyone.
					</h2>
					<div className='w-[95%] lg:w-3/4 p-4 rounded-xl shadow-light-grey text-center bg-white my-8 flex flex-col justify-center items-center'>
						<p className='text-lg sm:text-xl mb-4 p-[10px] sm:p-[50px]'>
							"As a business owner I’m always looking for ways to
							be more efficient and save money. DeliverZero
							packaging costs half of what our single-use
							packaging costs, and unlike the single-use
							packaging, DeliverZero tracks our inventory and
							knows when we need to be replenished. It’s less
							money and less work for us, plus we’re doing
							something tangible to reduce waste and make a
							positive impact in the community. It’s hard to
							believe there was a time when we didn’t offer
							reusable packaging. It’s a no brainer for any
							business."
						</p>
						<p className='font-semibold italic text-green-600 text-lg mb-8'>
							— Kal Pant, Owner | Spice Room, Denver, CO
						</p>
					</div>
					<div className='my-[50px]'>
						<Button
							text='Get started'
							color='green'
							className='py-3 px-8 rounded-full text-3xl font-semibold w-[300px]'
							link='/restaurantpartners/sign-up/page-1?promo=DD2024'
						/>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

const mapStateToProps = ({ Merchant }) => ({
	analytics: Merchant.analytics,
	error: Merchant.error,
})

const mapDispatchToProps = (dispatch) => ({
	onTrackUsage: (payload) => dispatch(trackUsage(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MerchantBenefitsPage)
