export const GET_ALL_REUSABLE_PRODUCTS = 'GET_ALL_REUSABLE_PRODUCTS'
export const GET_ALL_REUSABLE_PRODUCTS_SUCCESS =
	'GET_ALL_REUSABLE_PRODUCTS_SUCCESS'
export const GET_ALL_REUSABLE_PRODUCTS_FAIL = 'GET_ALL_REUSABLE_PRODUCTS_FAIL'

export const ADD_TO_CART_REUSABLES_SHOP = 'ADD_TO_CART_REUSABLES_SHOP'
export const ADD_TO_CART_REUSABLES_SHOP_SUCCESS =
	'ADD_TO_CART_REUSABLES_SHOP_SUCCESS'
export const ADD_TO_CART_REUSABLES_SHOP_FAIL = 'ADD_TO_CART_REUSABLES_SHOP_FAIL'

export const UPDATE_CART_ITEM_REUSABLES_SHOP = 'UPDATE_CART_ITEM_REUSABLES_SHOP'
export const UPDATE_CART_ITEM_REUSABLES_SHOP_SUCCESS =
	'UPDATE_CART_ITEM_REUSABLES_SHOP_SUCCESS'
export const UPDATE_CART_ITEM_REUSABLES_SHOP_FAIL =
	'UPDATE_CART_ITEM_REUSABLES_SHOP_FAIL'

export const UPDATE_CART_FROM_USER = 'UPDATE_CART_FROM_USER'
export const UPDATE_CART_FROM_USER_SUCCESS = 'UPDATE_CART_FROM_USER_SUCCESS'
export const UPDATE_CART_FROM_USER_FAIL = 'UPDATE_CART_FROM_USER_FAIL'

export const ADD_CART_TO_USER_REUSABLES_SHOP = 'ADD_CART_TO_USER_REUSABLES_SHOP'
export const ADD_CART_TO_USER_REUSABLES_SHOP_SUCCESS =
	'ADD_CART_TO_USER_REUSABLES_SHOP_SUCCESS'
export const ADD_CART_TO_USER_REUSABLES_SHOP_FAIL =
	'ADD_CART_TO_USER_REUSABLES_SHOP_FAIL'

export const CLEAR_CART_REUSABLES_SHOP = 'CLEAR_CART_REUSABLES_SHOP'
export const CLEAR_CART_REUSABLES_SHOP_SUCCESS =
	'CLEAR_CART_SUCCESS_REUSABLES_SHOP_SUCCESS'
export const CLEAR_CART_REUSABLES_SHOP_FAIL =
	'CLEAR_CART_FAIL_REUSABLES_SHOP_FAIL'

export const SUBMIT_REUSABLES_ORDER = 'SUBMIT_REUSABLES_ORDER'
export const SUBMIT_REUSABLES_ORDER_SUCCESS = 'SUBMIT_REUSABLES_ORDER_SUCCESS'
export const SUBMIT_REUSABLES_ORDER_FAIL = 'SUBMIT_REUSABLES_ORDER_FAIL'

export const TOTAL_REUSABLES_CART = 'TOTAL_REUSABLES_CART'

export const CALCULATE_SHIPPING = 'CALCULATE_SHIPPING'
export const CALCULATE_SHIPPING_SUCCESS = 'CALCULATE_SHIPPING_SUCCESS'
export const CALCULATE_SHIPPING_FAIL = 'CALCULATE_SHIPPING_FAIL'

export const GET_REUSABLES_ORDERS = 'GET_REUSABLES_ORDERS'
export const GET_REUSABLES_ORDERS_SUCCESS = 'GET_REUSABLES_ORDERS_SUCCESS'
export const GET_REUSABLES_ORDERS_FAIL = 'GET_REUSABLES_ORDERS_FAIL'

export const RESET_REUSABLES = 'RESET_REUSABLES'
