import {
	RUN_AGGREGATION,
	RUN_AGGREGATION_SUCCESS,
	RUN_AGGREGATION_ERROR,
} from './actionTypes'

export const runAggregation = (payload) => ({
	type: RUN_AGGREGATION,
	payload: payload,
})

export const runAggregationSuccess = (payload) => ({
	type: RUN_AGGREGATION_SUCCESS,
	payload: payload,
})

export const runAggregationError = (payload) => ({
	type: RUN_AGGREGATION_ERROR,
	payload: payload,
})
