export const GET_DROPOFF_QUOTE = 'GET_DROPOFF_QUOTE'
export const GET_DROPOFF_QUOTE_SUCCESS = 'GET_DROPOFF_QUOTE_SUCCESS'
export const GET_DROPOFF_QUOTE_ERROR = 'GET_DROPOFF_QUOTE_ERROR'
export const CREATE_DROPOFF = 'CREATE_DROPOFF'
export const CREATE_DROPOFF_SUCCESS = 'CREATE_DROPOFF_SUCCESS'
export const CREATE_DROPOFF_ERROR = 'CREATE_DROPOFF_ERROR'
export const CREATE_GENTLY_DROPOFF = 'CREATE_GENTLY_DROPOFF'
export const CREATE_GENTLY_DROPOFF_SUCCESS = 'CREATE_GENTLY_DROPOFF_SUCCESS'
export const CREATE_GENTLY_DROPOFF_ERROR = 'CREATE_GENTLY_DROPOFF_ERROR'
export const CREATE_GENTLY_DROPOFF_NO_NODE = 'CREATE_GENTLY_DROPOFF_NO_NODE'
export const CREATE_GENTLY_DROPOFF_NO_NODE_SUCCESS =
	'CREATE_GENTLY_DROPOFF_NO_NODE_SUCCESS'
export const CREATE_GENTLY_DROPOFF_NO_NODE_ERROR =
	'CREATE_GENTLY_DROPOFF_NO_NODE_ERROR'
export const GET_DROPOFF_STATUS = 'GET_DROPOFF_STATUS'
export const GET_DROPOFF_STATUS_SUCCESS = 'GET_DROPOFF_STATUS_SUCCESS'
export const GET_DROPOFF_STATUS_ERROR = 'GET_DROPOFF_STATUS_ERROR'
export const GET_DROPOFFS = 'GET_DROPOFFS'
export const GET_DROPOFFS_SUCCESS = 'GET_DROPOFFS_SUCCESS'
export const GET_DROPOFFS_ERROR = 'GET_DROPOFFS_ERROR'
export const CANCEL_DROPOFF = 'CANCEL_DROPOFF'
export const CANCEL_DROPOFF_ERROR = 'CANCEL_DROPOFF_ERROR'
export const GET_DROPOFF_BY_ID = 'GET_DROPOFF_BY_ID'
export const GET_DROPOFF_BY_ID_SUCCESS = 'GET_DROPOFF_BY_ID_SUCCESS'
export const GET_DROPOFF_BY_ID_ERROR = 'GET_DROPOFF_BY_ID_ERROR'

export const CREATE_FLOT_DROPOFF = 'CREATE_FLOT_DROPOFF'
export const CREATE_FLOT_DROPOFF_SUCCESS = 'CREATE_FLOT_DROPOFF_SUCCESS'
export const CREATE_FLOT_DROPOFF_ERROR = 'CREATE_FLOT_DROPOFF_ERROR'

export const CREATE_FLOT_DROPOFF_NO_NODE = 'CREATE_FLOT_DROPOFF_NO_NODE'
export const CREATE_FLOT_DROPOFF_NO_NODE_SUCCESS =
	'CREATE_FLOT_DROPOFF_NO_NODE_SUCCESS'
export const CREATE_FLOT_DROPOFF_NO_NODE_ERROR =
	'CREATE_FLOT_DROPOFF_NO_NODE_ERROR'

export const CANCEL_FLOT_DROPOFF = 'CANCEL_FLOT_DROPOFF'
export const CANCEL_FLOT_DROPOFF_SUCCESS = 'CANCEL_FLOT_DROPOFF_SUCCESS'
export const CANCEL_FLOT_DROPOFF_ERROR = 'CANCEL_FLOT_DROPOFF_ERROR'

export const GET_ALL_FLOT_DROPOFFS = 'GET_ALL_FLOT_DROPOFFS'
export const GET_ALL_FLOT_DROPOFFS_SUCCESS = 'GET_ALL_FLOT_DROPOFFS_SUCCESS'
export const GET_ALL_FLOT_DROPOFFS_ERROR = 'GET_ALL_FLOT_DROPOFFS_ERROR'

export const RESET_DROPOFF_SUCCESS = 'RESET_DROPOFF_SUCCESS'
