import { call, put, takeEvery } from 'redux-saga/effects'
import {
	addCartToUser,
	clearCart,
	getCartFromUser,
	getCartNodeBySlug,
} from '../../model/cart'
import {
	ADD_CART_TO_USER,
	CLEAR_CART,
	GET_CART,
	GET_CART_NODE_BY_SLUG,
} from './actionTypes'
import {
	clearCartFail,
	clearCartSuccess,
	getCartError,
	getCartNodeBySlugFail,
	getCartNodeBySlugSuccess,
	getCartSuccess,
} from '../cart/actions'

function* addCart(action) {
	try {
		// console.log("ACTION IN SAGA!!!!!!!!!!!", action.payload);
		const cart = yield call(addCartToUser, action.payload)
		// console.log("POST ADD CART!!!!!!!!!!", cart);
	} catch (error) {
		console.log(error)
	}
}

function* getCartSaga(action) {
	try {
		const cart = yield call(getCartFromUser, action.payload)
		yield put(getCartSuccess(cart))
	} catch (error) {
		console.log(error)
		yield put(getCartError(error))
	}
}

function* clearCartSaga(action) {
	try {
		const cart = yield call(clearCart, action.payload)
		yield put(clearCartSuccess())
	} catch (error) {
		console.log(error)
		yield put(clearCartFail(error))
	}
}

function* fetchNodeBySlug(action) {
	try {
		const node = yield call(getCartNodeBySlug, action.payload)
		yield put(getCartNodeBySlugSuccess(node))
	} catch (error) {
		yield put(getCartNodeBySlugFail(error))
	}
}

function* cartSaga() {
	yield takeEvery(ADD_CART_TO_USER, addCart)
	yield takeEvery(GET_CART_NODE_BY_SLUG, fetchNodeBySlug)
	yield takeEvery(CLEAR_CART, clearCartSaga)
	yield takeEvery(GET_CART, getCartSaga)
}

export default cartSaga
