import { call, put, takeEvery } from 'redux-saga/effects'
import {
	CREATE_PENDING_PICKUP,
	GET_PENDING_PICKUPS,
	GET_ALL_PENDING_PICKUPS,
	GET_PICKUPS_BY_PHONE,
	GET_PICKUP_BY_ID,
	CREATE_GENTLY_PICKUP,
	CREATE_GENTLY_PICKUP_NO_NODE,
} from './actionTypes'
import {
	createPendingPickupSuccess,
	createPendingPickupError,
	getPendingPickupsSuccess,
	getPendingPickupsError,
	getAllPendingPickupsSuccess,
	getAllPendingPickupsError,
	getPickupsByPhoneSuccess,
	getPickupsByPhoneError,
	getPickupByIdError,
	getPickupByIdSuccess,
	createGentlyPickupSuccess,
	createGentlyPickupError,
	createGentlyPickupNoNodeSuccess,
	createGentlyPickupNoNodeError,
} from './actions'
import {
	createPendingPickup,
	getPendingPickups,
	getPickupsByPhone,
	getAllPendingPickups,
	getPickupById,
	createGentlyPickup,
	createGentlyPickupNoNode,
} from '../../model/pickups'

function* createPendingPickupSaga(action) {
	try {
		const result = yield call(createPendingPickup, action.payload)
		yield put(createPendingPickupSuccess(result))
	} catch (error) {
		yield put(createPendingPickupError(error))
	}
}

function* fetchPendingPickups(action) {
	try {
		const result = yield call(getPendingPickups, action.payload)
		yield put(getPendingPickupsSuccess(result))
	} catch (error) {
		yield put(getPendingPickupsError(error))
	}
}

function* fetchAllPendingPickups(action) {
	try {
		const result = yield call(getAllPendingPickups, action.payload)
		yield put(getAllPendingPickupsSuccess(result))
	} catch (error) {
		yield put(getAllPendingPickupsError(error))
	}
}

function* fetchPendingPickupsByPhone(action) {
	try {
		const result = yield call(getPickupsByPhone, action.payload)
		yield put(getPickupsByPhoneSuccess(result))
	} catch (error) {
		yield put(getPickupsByPhoneError(error))
	}
}

function* fetchPendingPickupById(action) {
	try {
		const result = yield call(getPickupById, action.payload)
		yield put(getPickupByIdSuccess(result))
	} catch (error) {
		yield put(getPickupByIdError(error))
	}
}

function* dispatchGentlyPickup(action) {
	try {
		const quoteResponse = yield call(createGentlyPickup, action.payload)
		yield put(createGentlyPickupSuccess(quoteResponse))
	} catch (error) {
		yield put(createGentlyPickupError(error))
	}
}

function* dispatchGentlyPickupNoNode(action) {
	try {
		const quoteResponse = yield call(
			createGentlyPickupNoNode,
			action.payload
		)
		yield put(createGentlyPickupNoNodeSuccess(quoteResponse))
	} catch (error) {
		yield put(createGentlyPickupNoNodeError(error))
	}
}
function* pickupsSaga() {
	yield takeEvery(CREATE_PENDING_PICKUP, createPendingPickupSaga)
	yield takeEvery(GET_PENDING_PICKUPS, fetchPendingPickups)
	yield takeEvery(GET_ALL_PENDING_PICKUPS, fetchAllPendingPickups)
	yield takeEvery(GET_PICKUPS_BY_PHONE, fetchPendingPickupsByPhone)
	yield takeEvery(GET_PICKUP_BY_ID, fetchPendingPickupById)
	yield takeEvery(CREATE_GENTLY_PICKUP, dispatchGentlyPickup)
	yield takeEvery(CREATE_GENTLY_PICKUP_NO_NODE, dispatchGentlyPickupNoNode)
}

export default pickupsSaga
