import {
	GET_FRANCHISEE_CHARGES,
	GET_FRANCHISEE_CHARGES_FAIL,
	GET_FRANCHISEE_CHARGES_SUCCESS,
	GET_FRANCHISEE,
	GET_FRANCHISEE_ERROR,
	GET_FRANCHISEE_SUCCESS,
} from './actionTypes'

export const getFranchiseeCharges = (payload) => ({
	type: GET_FRANCHISEE_CHARGES,
	payload: payload,
})

export const getFranchiseeChargesSuccess = (payload) => ({
	type: GET_FRANCHISEE_CHARGES_SUCCESS,
	payload: payload,
})

export const getFranchiseeChargesFail = (error) => ({
	type: GET_FRANCHISEE_CHARGES_FAIL,
	payload: error,
})

export const getFranchisee = (payload) => ({
	type: GET_FRANCHISEE,
	payload: payload,
})

export const getFranchiseeSuccess = (payload) => ({
	type: GET_FRANCHISEE_SUCCESS,
	payload: payload,
})

export const getFranchiseeError = (payload) => ({
	type: GET_FRANCHISEE_ERROR,
	payload: payload,
})
