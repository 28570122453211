import { call, put, takeEvery } from 'redux-saga/effects'
import {
	updateOfficeSuccess,
	updateOfficeError,
	getOfficeSuccess,
	getOfficeError,
	getOfficeTransactionsSuccess,
	getOfficeTransactionsFail,
	getOneTransactionOfficeSuccess,
	getOneTransactionOfficeFail,
	getOfficeAdminsSuccess,
	getOfficeAdminsError,
	getOfficeCustomersSuccess,
	getOfficeCustomersFail,
	getOneCustomerOfficeSuccess,
	getOneCustomerOfficeFail,
	storePaymentMethodOfficeFail,
	storePaymentMethodOfficeSuccess,
	updateOfficeEmailSuccess,
	updateOfficeEmailError,
	getOfficeByAdminCodeSuccess,
	getOfficeByAdminCodeFail,
	inviteOfficeAdminError,
	inviteOfficeAdminSuccess,
} from './actions'
import {
	GET_OFFICE,
	GET_OFFICE_ADMINS,
	GET_OFFICE_CUSTOMERS,
	GET_OFFICE_TRANSACTIONS,
	GET_ONE_CUSTOMER_OFFICE,
	GET_ONE_TRANSACTION_OFFICE,
	UPDATE_OFFICE,
	STORE_PAYMENT_METHOD_OFFICE,
	UPDATE_OFFICE_EMAIL,
	GET_OFFICE_BY_ADMIN_CODE,
	INVITE_OFFICE_ADMIN,
} from './actionTypes'
import { assignOfficeAdminSuccess, assignOfficeAdminError } from './actions'
import { ASSIGN_OFFICE_ADMIN } from './actionTypes'
import {
	scheduleOfficePickupSuccess,
	scheduleOfficePickupError,
} from './actions'
import { SCHEDULE_OFFICE_PICKUP } from './actionTypes'
import {
	assignOfficeAdminApi,
	getOfficeAdminsApi,
	getOfficeApi,
	getOfficeCustomers,
	getOfficeTransactions,
	getOneCustomerOffice,
	getOneTransactionOffice,
	scheduleOfficePickupApi,
	updateOfficeApi,
	addPaymentMethodOffice,
	updateStripeOfficeEmail,
	getOfficeByAdminCode,
	inviteOfficeAdmin,
} from '../../model/officeOrdering'

function* getOffice(action) {
	try {
		const response = yield call(getOfficeApi, action.payload)
		yield put(getOfficeSuccess(response))
	} catch (error) {
		yield put(getOfficeError(error))
	}
}

function* fetchOfficeTransactions(action) {
	try {
		const transactions = yield call(getOfficeTransactions, action.payload)
		yield put(getOfficeTransactionsSuccess(transactions))
	} catch (error) {
		yield put(getOfficeTransactionsFail(error))
	}
}

function* fetchOneTransactionOffice(action) {
	try {
		const response = yield call(getOneTransactionOffice, action.payload)
		yield put(getOneTransactionOfficeSuccess(response))
	} catch (error) {
		yield put(getOneTransactionOfficeFail(error))
	}
}

function* updateOffice(action) {
	try {
		const response = yield call(updateOfficeApi, action.payload)
		yield put(updateOfficeSuccess(response))
	} catch (error) {
		yield put(updateOfficeError(error))
	}
}

function* getOfficeAdmins(action) {
	try {
		const admins = yield call(getOfficeAdminsApi, action.payload)
		yield put(getOfficeAdminsSuccess(admins))
	} catch (error) {
		yield put(getOfficeAdminsError(error))
	}
}

function* assignOfficeAdmin(action) {
	try {
		const response = yield call(assignOfficeAdminApi, action.payload)
		yield put(assignOfficeAdminSuccess(response))
	} catch (error) {
		yield put(assignOfficeAdminError(error))
	}
}

function* scheduleOfficePickup(action) {
	try {
		const response = yield call(scheduleOfficePickupApi, action.payload)
		yield put(scheduleOfficePickupSuccess(response))
	} catch (error) {
		yield put(scheduleOfficePickupError(error))
	}
}

function* fetchOfficeCustomers(action) {
	try {
		const response = yield call(getOfficeCustomers, action.payload)
		yield put(getOfficeCustomersSuccess(response))
	} catch (error) {
		yield put(getOfficeCustomersFail(error))
	}
}

function* fetchOneCustomerOffice(action) {
	try {
		const response = yield call(getOneCustomerOffice, action.payload)
		yield put(getOneCustomerOfficeSuccess(response))
	} catch (error) {
		yield put(getOneCustomerOfficeFail(error))
	}
}

function* storePaymentMethodOffice(action) {
	try {
		const paymentMethod = yield call(addPaymentMethodOffice, action.payload)
		yield put(storePaymentMethodOfficeSuccess(paymentMethod))
	} catch (error) {
		yield put(storePaymentMethodOfficeFail(error))
	}
}
function* updateOfficeEmail(action) {
	try {
		const response = yield call(updateStripeOfficeEmail, action.payload)
		yield put(updateOfficeEmailSuccess(response))
	} catch (error) {
		yield put(updateOfficeEmailError(error))
	}
}

function* fetchOfficeByAdminCode(action) {
	try {
		const office = yield call(getOfficeByAdminCode, action.payload)
		yield put(getOfficeByAdminCodeSuccess(office))
	} catch (error) {
		yield put(getOfficeByAdminCodeFail(error))
	}
}

function* dispatchInviteOfficeAdmin(action) {
	try {
		const invite = yield call(inviteOfficeAdmin, action.payload)
		yield put(inviteOfficeAdminSuccess(invite))
	} catch (error) {
		yield put(inviteOfficeAdminError(error))
	}
}

function* officeSaga() {
	yield takeEvery(GET_OFFICE, getOffice)
	yield takeEvery(GET_OFFICE_TRANSACTIONS, fetchOfficeTransactions)
	yield takeEvery(GET_ONE_TRANSACTION_OFFICE, fetchOneTransactionOffice)
	yield takeEvery(UPDATE_OFFICE, updateOffice)
	yield takeEvery(UPDATE_OFFICE_EMAIL, updateOfficeEmail)
	yield takeEvery(GET_OFFICE_ADMINS, getOfficeAdmins)
	yield takeEvery(ASSIGN_OFFICE_ADMIN, assignOfficeAdmin)
	yield takeEvery(SCHEDULE_OFFICE_PICKUP, scheduleOfficePickup)
	yield takeEvery(GET_OFFICE_CUSTOMERS, fetchOfficeCustomers)
	yield takeEvery(GET_ONE_CUSTOMER_OFFICE, fetchOneCustomerOffice)
	yield takeEvery(STORE_PAYMENT_METHOD_OFFICE, storePaymentMethodOffice)
	yield takeEvery(GET_OFFICE_BY_ADMIN_CODE, fetchOfficeByAdminCode)
	yield takeEvery(INVITE_OFFICE_ADMIN, dispatchInviteOfficeAdmin)
}

export default officeSaga
