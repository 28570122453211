import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAppInfo,
	updateUser,
	getAdminReturns,
	getWFMEnvImpact,
	getWFMLocations,
} from '../../../store/actions'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { SelectGroup } from '../../../components/Elements'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import EnvironmentalImpactCard from '../../../components/UserProfile/EnvironmentalImpactCard'

const WFMEnvironmental = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetReturns,
		returns,
		onGetAppInfo,
		appInfo,
		onGetWFMEnvImpact,
		loadingEnvImpact,
		totalBoxesOut,
		totalBoxesIn,
		effectiveReturnRate,
		error,
		loading,
		locations,
		onGetWFMLocations,
	} = state

	useEffect(() => {
		onGetUser()
		onGetReturns()
		onGetAppInfo()
	}, [onGetUser])

	const bounceLoaderColor = '#507f74'

	const [location, setLocation] = useState()
	const [locationSelections, setLocationSelections] = useState([])
	const [startDate, setStartDate] = useState(new Date(2022, 9, 1))
	const [endDate, setEndDate] = useState(new Date())
	const [filter, setFilter] = useState({})

	let navigate = useNavigate()

	useEffect(() => {
		const localFilter = filter
		if (localFilter.node === '') {
			delete localFilter.node
		}
		onGetWFMEnvImpact({
			filter: {
				...localFilter,
			},
			startDate: startDate,
			endDate: endDate,
		})
	}, [onGetWFMEnvImpact, filter, startDate, endDate])

	useEffect(() => {
		if (location && location !== '') {
			setFilter({ node: location })
		} else {
			setFilter({})
		}
	}, [location])

	useEffect(() => {
		if (locations && locations.length > 0) {
			const locationsArray = [
				{
					label: 'All',
					value: '',
				},
			]
			for (const location of locations) {
				locationsArray.push({
					label: location.name,
					value: location.slug,
				})
			}
			setLocationSelections(locationsArray)
		}
	}, [locations])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div className='h-screen'>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='flex flex-col max-w-[1200px] justify-center my-10 overflow-x-auto mx-auto'>
					<div className='mx-auto'>
						<div className='font-header text-2xl text-green-600'>
							Whole Foods Environmental Analytics
						</div>
						{loadingEnvImpact && (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
						<SelectGroup
							label='Filter by location'
							placeholder='Select a location...'
							name='location'
							options={locationSelections}
							onChange={(e) => {
								const selectedValue = e.target.value
								setLocation(
									selectedValue === 'Select a location...'
										? ''
										: selectedValue
								)
							}}
						/>
						<div className='mt-4'>Select Start Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
							/>
						</div>
						<div className='mt-4'>Select End Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={endDate}
								onChange={(date) => setEndDate(date)}
							/>
						</div>

						{totalBoxesOut && (
							<div className='text-2xl mb-10'>
								<div className='text-green-600 font-bold font-header'>
									Total boxes given out:
								</div>
								<div>{totalBoxesOut} boxes</div>
							</div>
						)}

						{!loadingEnvImpact && (
							<EnvironmentalImpactCard
								packagingPrevented={totalBoxesOut}
								environmentalImpact={
									appInfo.environmentalImpact
								}
							/>
						)}
						{effectiveReturnRate && (
							<div className='text-2xl my-10'>
								<div className='text-green-600 font-bold font-header'>
									Effective return rate:
								</div>
								<div>
									{effectiveReturnRate.toFixed(3) * 100}%
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Admin, AppInfo, WholeFoods }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	returns: Admin.returns,
	appInfo: AppInfo.appInfo,
	loadingEnvImpact: WholeFoods.loadingEnvImpact,
	totalBoxesOut: WholeFoods.totalBoxesOut,
	totalBoxesIn: WholeFoods.totalBoxesIn,
	effectiveReturnRate: WholeFoods.effectiveReturnRate,
	error: WholeFoods.error,
	loading: WholeFoods.loading,
	locations: WholeFoods.locations,
	uploadResponse: WholeFoods.uploadResponse,
})

const mapDispatchToProps = (dispatch) => ({
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onGetReturns: (payload) => dispatch(getAdminReturns(payload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetWFMEnvImpact: (payload) => dispatch(getWFMEnvImpact(payload)),
	onGetWFMLocations: () => dispatch(getWFMLocations()),
})

export default connect(mapStateToProps, mapDispatchToProps)(WFMEnvironmental)
