import { realm } from '../helpers/realm'
import { BSON } from 'realm-web'

export const insertTransaction = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')
	// console.log(payload)
	return transactions.insertOne(payload)
}

export const getTransactions = (payload) => {
	// console.log("payload", payload)
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')

	const stages = [
		{
			$addFields: {
				orderObjectId: {
					$toObjectId: '$orderCollectionId',
				},
			},
		},
		{
			$lookup: {
				from: 'nodes',
				localField: 'node',
				foreignField: 'slug',
				as: 'nodeInfo',
			},
		},
		{
			$lookup: {
				from: 'orders',
				localField: 'orderObjectId',
				foreignField: '_id',
				as: 'orderInfo',
			},
		},
		{
			$unwind: {
				path: '$nodeInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
		{
			$unwind: {
				path: '$orderInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [
				{ $skip: skip },
				{ $limit: limit },
				{
					$match: {
						$and: [filter, { adjustedBoxCount: { $ne: true } }],
					},
				},
				{
					$project: {
						returnImageSource: 0,
					},
				},
			],
		},
	})

	return transactions.aggregate(stages)
}

export const getOneTransaction = async (transactionPayload) => {
	const transactionId = transactionPayload.transactionId
	const phone = transactionPayload.phone
	const admin = !!transactionPayload.admin
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')
	if (admin) {
		const transaction = await transactions.findOne({
			_id: BSON.ObjectId(transactionId),
		})
		return transaction
	} else {
		const transaction = await transactions.findOne({
			_id: BSON.ObjectId(transactionId),
			'user.phone': phone,
		})
		return transaction
	}
}

export const disputeTransaction = async (transactionPayload) => {
	const transactionId = transactionPayload._id.toString()
	const newItems = transactionPayload.items
	// console.log(transactionId)
	// console.log(newItems)
	const transaction = await realm.currentUser.functions.disputeBoxesHandler({
		transactionId: transactionId,
		newItems: newItems,
		userId: realm.currentUser.id,
	})
	return transaction
}

export const adminDisputeTransaction = async (payload) => {
	const transactionPayload = payload.transaction
	const adjustedBoxCount = payload.adjustedBoxCount
	const transactionId = transactionPayload._id.toString()
	const newItems = transactionPayload.items
	const updatePayload = {
		transactionId: transactionId,
		adjustedBoxCount: adjustedBoxCount,
	}
	const transactionUpdateResponse = await realm.currentUser.callFunction(
		'adminDisputeBoxesHandler',
		updatePayload
	)
	return transactionUpdateResponse
}

export const uploadOrders = async (orderUploadPayload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const orderUploadResponse = await realm.currentUser.callFunction(
		'api/manualOrderUpload',
		orderUploadPayload
	)

	return orderUploadResponse
}

export const updateTransaction = async (updatePayload) => {
	const transactionId = updatePayload.transactionId.toString()
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')
	const update = await transactions.updateOne(
		{ _id: BSON.ObjectId(transactionId) },
		{ $set: updatePayload.update }
	)
	// console.log(transactionId)
	const transaction = await transactions.findOne({
		_id: BSON.ObjectId(transactionId),
	})
	// console.log(transaction)
	return transaction
}

export const getAnalyticsTransactions = async (payload) => {
	console.log('payload', payload)
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const { filter, options } = payload
	const { sort, skip, limit } = options
	const page = skip / limit
	let modifiedFilter = { ...filter }

	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')

	const stages = [
		{
			$lookup: {
				from: 'nodes',
				localField: 'node',
				foreignField: 'slug',
				as: 'nodeInfo',
			},
		},
		{
			$unwind: {
				path: '$nodeInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
	]

	if (filter) {
		if (filter.market) {
			delete modifiedFilter.market
		}
		stages.push({ $match: modifiedFilter })
	}

	const uniqueStatesPipeline = [
		{
			$match: {
				'nodeInfo.enabled': true,
			},
		},
		{
			$group: {
				_id: null,
				uniqueStates: { $addToSet: '$nodeInfo.state' },
			},
		},
	]

	const uniqueStatesResult = await transactions.aggregate(
		[...stages, ...uniqueStatesPipeline],
		{ allowDiskUse: true }
	)

	const uniqueStates = uniqueStatesResult[0]?.uniqueStates || []

	const countPipeline = [
		{
			$match: {
				$and: [
					{ adjustedBoxCount: { $ne: true } },
					filter.market !== undefined
						? { 'nodeInfo.state': filter.market }
						: {},
				],
			},
		},
		{
			$count: 'total',
		},
	]

	const totalDocuments = await transactions.aggregate(
		[...stages, ...countPipeline],
		{ allowDiskUse: true }
	)

	const totalBoxCountPipline = [
		{
			$match: {
				$and: [
					{ adjustedBoxCount: { $ne: true } },
					filter.market ? { 'nodeInfo.state': filter.market } : {},
				],
			},
		},
		{
			$group: {
				_id: null,
				totalBoxCount: { $sum: '$boxCount' },
			},
		},
	]

	const totalBoxCount = await transactions.aggregate(
		[...stages, ...totalBoxCountPipline],
		{ allowDiskUse: true }
	)

	stages.push(
		{
			$match: {
				$and: [
					{ adjustedBoxCount: { $ne: true } },
					filter.market ? { 'nodeInfo.state': filter.market } : {},
				],
			},
		},
		{
			$project: {
				timestamp: 1,
				'nodeInfo.name': 1,
				'nodeInfo.state': 1,
				node: 1,
				boxCount: 1,
				'user.firstName': 1,
				'user.lastName': 1,
				'user.phone': 1,
				client: 1,
				aggregator: 1,
				_id: 0,
			},
		},
		{ $skip: skip },
		{ $limit: limit }
	)

	if (sort) {
		stages.push({ $sort: sort })
	}

	const dataResults = await transactions.aggregate(stages, {
		allowDiskUse: true,
	})

	return {
		data: dataResults,
		total: totalDocuments ? totalDocuments[0].total : 0,
		totalBoxCount: totalBoxCount[0] ? totalBoxCount[0].totalBoxCount : 0,
		uniqueStates,
	}
}

// for packing list - logistics
export const createOrUpdateTransaction = async (transactionPayload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')
	const exisitingTransaction = await transactions.findOne({
		$and: [
			{ node: transactionPayload.node },
			{ stopId: transactionPayload.stopId },
		],
	})
	if (exisitingTransaction) {
		const update = await transactions.updateOne(
			{ _id: BSON.ObjectId(exisitingTransaction._id) },
			{ $set: transactionPayload }
		)
		const transaction = await transactions.findOne({
			_id: BSON.ObjectId(exisitingTransaction._id),
		})
		return transaction
	} else {
		return transactions.insertOne(transactionPayload)
	}
}

export const resetUpdateTransaction = () => {
	return true
}
