import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getOrders,
	getNodes,
	getNodeBySlug,
	updateNode,
	createTransaction,
	getUsageData,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input } from '../../../components/Elements'

const RestaurantUsageData = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetNodeBySlug,
		node,
		loadingNode,
		nodeError,
		usageData,
		usageMonths,
		onGetUsageData,
		loading,
		error,
	} = state

	const [dataOut, setDataOut] = useState([])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const { nodeSlug } = useParams()
	const bounceLoaderColor = '#507f74'

	let navigate = useNavigate()

	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
			onGetUsageData(nodeSlug)
		}
	}, [nodeSlug])

	useEffect(() => {
		if (usageData && usageData[0] && usageData[1]) {
			if (usageData[0].type && usageData[0].type === 'boxesOut') {
				setDataOut(usageData[0])
			} else {
				setDataOut(usageData[1])
			}
		}
	}, [usageData])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		if (node) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='flex flex-col justify-center items-center my-10 ml-6'>
						<div className='mx-auto'>
							<div className='font-header text-2xl text-green-600'>
								{node.name}
							</div>
							<div className=''>
								<span className='font-bold'>Address:</span>{' '}
								{node.address}, {node.city}, {node.state}{' '}
								{node.postal}
							</div>
							<div className=''>
								<span className='font-bold'>Phone:</span>{' '}
								{node.phone}
							</div>
							<div className=''>
								<span className='font-bold'>Balance:</span>{' '}
								{node.balance} boxes
							</div>
							<div className='flex flex-row items-center w-full min-w-[800px]'>
								{dataOut && (
									<div className='px-10 my-6 shadow-light-grey rounded-xl py-2 w-full'>
										<div className='my-2 text-lg text-center'>
											Total Transactions:{' '}
											{dataOut.overallCount}
										</div>
										<div className='my-2 text-lg text-center'>
											Total Boxes Out:{' '}
											{dataOut.overallBoxCount}
										</div>
										<div className='my-2 text-lg text-green-600'>
											Transaction by Month
										</div>
										<div className='flex flex-row mb-2'>
											<div className='font-bold w-1/4'>
												Date
											</div>
											<div className='font-bold w-1/4'>
												Sales
											</div>
											<div className='font-bold w-1/4'>
												Boxes Out
											</div>
											<div className='font-bold w-1/4'>
												Returns
											</div>
											<div className='font-bold w-1/4'>
												Boxes In
											</div>
										</div>
										{loading ? (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										) : (
											<>
												{usageMonths &&
													usageMonths
														.filter(
															(monthData) =>
																monthData.month !=
																null
														)
														.sort((a, b) =>
															b.year === a.year
																? b.month -
																  a.month
																: b.year -
																  a.year
														)
														.map(
															(
																monthData,
																monthIndex
															) => (
																<div
																	key={
																		monthIndex
																	}
																	className='flex flex-row mb-2'
																>
																	<div className='w-1/4'>
																		{`${monthData.month}/${monthData.year}`}
																	</div>
																	<div className='w-1/4'>
																		{usageData
																			.find(
																				(
																					entry
																				) =>
																					entry.type ===
																					'boxesOut'
																			)
																			?.months.find(
																				(
																					m
																				) =>
																					m.month ===
																						monthData.month &&
																					m.year ===
																						monthData.year
																			)
																			?.count ||
																			0}
																	</div>
																	<div className='w-1/4'>
																		{usageData
																			.find(
																				(
																					entry
																				) =>
																					entry.type ===
																					'boxesOut'
																			)
																			?.months.find(
																				(
																					m
																				) =>
																					m.month ===
																						monthData.month &&
																					m.year ===
																						monthData.year
																			)
																			?.boxCount ||
																			0}
																	</div>
																	<div className='w-1/4'>
																		{usageData
																			.find(
																				(
																					entry
																				) =>
																					entry.type ===
																					'boxesIn'
																			)
																			?.months.find(
																				(
																					m
																				) =>
																					m.month ===
																						monthData.month &&
																					m.year ===
																						monthData.year
																			)
																			?.count ||
																			0}
																	</div>
																	<div className='w-1/4'>
																		{usageData
																			.find(
																				(
																					entry
																				) =>
																					entry.type ===
																					'boxesIn'
																			)
																			?.months.find(
																				(
																					m
																				) =>
																					m.month ===
																						monthData.month &&
																					m.year ===
																						monthData.year
																			)
																			?.boxCount ||
																			0}
																	</div>
																</div>
															)
														)}
											</>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div>
					<Header />
					<div className='flex items-center h-screen'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
						<Footer />
					</div>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, Admin }) => ({
	node: Nodes.node,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loading: Admin.loadingData,
	usageData: Admin.usageData,
	usageMonths: Admin.usageMonths,
	error: Admin.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onGetUsageData: (payload) => dispatch(getUsageData(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantUsageData)
