import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser } from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import {
	getRestaurantPayments,
	sendRestaurantPayments,
} from '../../../store/restaurantPayments/actions'
import { Button, CheckboxGroup } from '../../../components/Elements'

const RestaurantPayments = (state) => {
	const {
		user,
		onGetUser,
		restaurantPayments,
		restaurantPaymentsError,
		onGetRestaurantPayments,
		sendingRestaurantPayments,
		onSendRestaurantPayments,
		restaurantPaymentsCompleted,
		totalPayments,
		loadingRestaurantPayments,
	} = state

	useEffect(() => {
		onGetUser()
		orderPaginate()
	}, [onGetUser])

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [restaurantPaymentsPage, setRestaurantPaymentsPage] = useState(1)
	const [loading, setLoading] = useState(true)
	const [restaurantsToPay, setRestaurantsToPay] = useState([])
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [paidFilter, setPaidFilter] = useState(false)
	const [pendingFilter, setPendingFilter] = useState(false)
	const [failedFilter, setFailedFilter] = useState(false)

	const getRestaurantPayments = (page) => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetRestaurantPayments({
					filter: {
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							endDate: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			} else {
				onGetRestaurantPayments({
					filter: filter,
					options: {
						sort: {
							endDate: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			}
		} else {
			onGetUser()
		}
	}

	const orderPaginate = () => {
		getRestaurantPayments(restaurantPaymentsPage)
	}

	const submitPayments = () => {
		if (restaurantsToPay.length > 0) {
			onSendRestaurantPayments(restaurantsToPay)
		}
		setRestaurantsToPay([])
	}

	useEffect(() => {
		orderPaginate()
	}, [restaurantPaymentsCompleted, restaurantPaymentsPage, user])

	// useEffect(() => {
	// 	orderPaginate()
	// }, [restaurantPaymentsPage])

	useEffect(() => {
		setLoading(false)
	}, [restaurantPayments])

	useEffect(() => {
		getRestaurantPayments(restaurantPaymentsPage)
	}, [filter])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Restaurant Payments
				</h1>
				<div className='flex min-w-min w-[350px] mx-auto items-center mb-4'>
					<CheckboxGroup
						label='Paid'
						name='status-paid'
						className=''
						defaultChecked={false}
						onChange={(check) => {
							if (check.target.checked) {
								setPaidFilter(true)
								let statuses = ['paid']
								if (pendingFilter) {
									statuses.push('pending')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								setFilter({
									...filter,
									status: {
										$in: statuses,
									},
								})
								setRestaurantPaymentsPage(1)
							} else {
								setPaidFilter(false)
								let statuses = []
								if (pendingFilter) {
									statuses.push('pending')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								if (statuses.length === 0) {
									setFilter({
										...filter,
										status: {
											$nin: [],
										},
									})
									setRestaurantPaymentsPage(1)
								} else {
									setFilter({
										...filter,
										status: {
											$in: statuses,
										},
									})
									setRestaurantPaymentsPage(1)
								}
							}
						}}
					/>
					<CheckboxGroup
						label='Pending'
						name='status-pending'
						className=''
						defaultChecked={false}
						onChange={(check) => {
							if (check.target.checked) {
								setPendingFilter(true)
								let statuses = ['pending']
								if (paidFilter) {
									statuses.push('paid')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								setFilter({
									...filter,
									status: {
										$in: statuses,
									},
								})
								setRestaurantPaymentsPage(1)
							} else {
								setPendingFilter(false)
								let statuses = []
								if (paidFilter) {
									statuses.push('paid')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								if (statuses.length === 0) {
									setFilter({
										...filter,
										status: {
											$nin: [],
										},
									})
									setRestaurantPaymentsPage(1)
								} else {
									setFilter({
										...filter,
										status: {
											$in: statuses,
										},
									})
									setRestaurantPaymentsPage(1)
								}
							}
						}}
					/>
					<CheckboxGroup
						label='Failed'
						name='status-failed'
						className=''
						defaultChecked={false}
						onChange={(check) => {
							if (check.target.checked) {
								setFailedFilter(true)
								let statuses = ['failed']
								if (paidFilter) {
									statuses.push('paid')
								}
								if (pendingFilter) {
									statuses.push('pending')
								}
								setFilter({
									...filter,
									status: {
										$in: statuses,
									},
								})
								setRestaurantPaymentsPage(1)
							} else {
								setFailedFilter(false)
								let statuses = []
								if (paidFilter) {
									statuses.push('paid')
								}
								if (pendingFilter) {
									statuses.push('pending')
								}
								if (statuses.length === 0) {
									setFilter({
										...filter,
										status: {
											$nin: [],
										},
									})
									setRestaurantPaymentsPage(1)
								} else {
									setFilter({
										...filter,
										status: {
											$in: statuses,
										},
									})
									setRestaurantPaymentsPage(1)
								}
							}
						}}
					/>
				</div>
				{sendingRestaurantPayments ? (
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				) : (
					<Button
						onClick={submitPayments}
						text='Pay Selected Restaurant'
						size='sm'
						className='my-6 cursor-pointer w-[180px] mx-auto'
					/>
				)}
				{restaurantPaymentsError && (
					<div className='font-bold text-red-400'>
						An error occurred: {restaurantPaymentsError.message}.
						Contact an administrator.
					</div>
				)}
				<div className='w-full flex flex-row justify-center items-center mb-8'>
					{restaurantPaymentsPage > 1 ? (
						<div
							className='mx-auto underline font-bold mr-6 cursor-pointer w-fit'
							onClick={() => {
								if (restaurantPaymentsPage >= 2) {
									setRestaurantPaymentsPage(
										restaurantPaymentsPage - 1
									)
								}
								setLoading(true)
							}}
						>
							Previous {paginationLimit} payments
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Previous {paginationLimit} payments
						</div>
					)}
					{restaurantPayments.length === paginationLimit ? (
						<div
							className='mx-auto w-fit underline font-bold cursor-pointer'
							onClick={() => {
								if (
									restaurantPaymentsPage <
									totalPayments / paginationLimit
								) {
									setRestaurantPaymentsPage(
										restaurantPaymentsPage + 1
									)
								}
								setLoading(true)
							}}
						>
							Next {paginationLimit} payments
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Next {paginationLimit} payments
						</div>
					)}
				</div>
				<div className='flex flex-col max-w-[90%] justify-center mt-10 overflow-x-auto overflow-y-auto scrollbar mx-auto'>
					<div className='mx-auto'>
						{loadingRestaurantPayments ||
							(loading && (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							))}
						{restaurantPayments.length === 0 && (
							<div className='w-full text-center mx-auto'>
								<div className='w-fit font-bold text-red-400 w-[70px] whitespace-nowrap'>
									No payments found
								</div>
							</div>
						)}
						{restaurantPayments.length > 0 && (
							<div className='w-full h-full px-2 shadow-light-grey'>
								<div className='w-full flex flex-row justify-between'>
									<div className='mb-6 flex justify-start'>
										{' '}
										Total restaurant charges:{' '}
										{totalPayments}{' '}
									</div>
									<div className='mb-6 flex justify-end'>
										{' '}
										Page: {restaurantPaymentsPage} of{' '}
										{Math.round(
											totalPayments / paginationLimit
										)}{' '}
									</div>
								</div>
								<div className='flex flex-row mb-2'>
									<div className='font-bold w-[70px]'>
										Select
									</div>
									<div className='font-bold w-[180px]'>
										Orders From
									</div>
									<div className='font-bold w-[180px]'>
										To
									</div>
									<div className='font-bold w-[150px]'>
										Restaurant
									</div>
									<div className='font-bold w-[100px] text-sm'>
										DZ Commission Rate
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Subtotal
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Subtotal to Remit
									</div>
									<div className='font-bold w-[100px]'>
										Delivery Fee
									</div>
									<div className='font-bold w-[100px]'>
										Taxes
									</div>
									<div className='font-bold w-[100px]'>
										Tip
									</div>
									<div className='font-bold w-[110px] text-sm'>
										Total to Remit
									</div>
									<div className='font-bold w-[100px] text-sm'>
										Payment Status
									</div>
								</div>
								{restaurantPayments.map(
									(restaurantPayment, index) => {
										const startDate = new Date(
											restaurantPayment.startDate
										)
										const endDate = new Date(
											restaurantPayment.endDate
										)
										const startDateString =
											startDate.toLocaleString(
												'default',
												{
													month: 'short',
													day: 'numeric',
													year: 'numeric',
													hour: '2-digit',
													minute: '2-digit',
												}
											)
										const endDateString =
											endDate.toLocaleString('default', {
												month: 'short',
												day: 'numeric',
												year: 'numeric',
												hour: '2-digit',
												minute: '2-digit',
											})

										return (
											<div
												className='flex flex-row my-2 divide-y-2 divide-solid'
												key={index}
											>
												<div className='w-[70px] !h-[35px] '>
													<CheckboxGroup
														label=''
														name='pay-this-restaurant'
														className='!min-w-min !w-[25px]my-4'
														defaultChecked={false}
														onChange={(val) => {
															const tempArray =
																restaurantsToPay
															if (
																val.target
																	.checked
															) {
																// console.log('here')
																tempArray.push(
																	restaurantPayment._id
																)
																setRestaurantsToPay(
																	tempArray
																)
															} else {
																setRestaurantsToPay(
																	tempArray.filter(
																		function (
																			e
																		) {
																			return (
																				e !==
																				restaurantPayment._id
																			)
																		}
																	)
																)
															}
														}}
													/>
												</div>
												<div className='w-[180px]'>
													{startDateString}
												</div>
												<div className='w-[180px]'>
													{endDateString}
												</div>
												<div
													onClick={() => {
														const url = `/admin/restaurants/${restaurantPayment.nodeSlug}`
														navigate(url)
													}}
													className='w-[150px] overflow-hidden cursor-pointer underline font-bold'
												>
													{
														restaurantPayment
															.nodeInfo.name
													}
												</div>
												<div className='w-[100px] overflow-hidden'>
													{restaurantPayment.nodeInfo
														.commissionFee * 100}
													%
												</div>
												<div className='w-[100px] overflow-hidden'>
													$
													{restaurantPayment.subtotal.toFixed(
														2
													)}
												</div>
												<div className='w-[100px] overflow-hidden'>
													$
													{(
														(1 -
															restaurantPayment
																.nodeInfo
																.commissionFee) *
														restaurantPayment.subtotal
													).toFixed(2)}
												</div>
												<div className='w-[100px] overflow-hidden'>
													$
													{restaurantPayment.deliveryFee.toFixed(
														2
													)}
												</div>
												<div className='w-[100px] overflow-hidden'>
													$
													{restaurantPayment.taxes.toFixed(
														2
													)}
												</div>
												<div className='w-[100px] overflow-hidden'>
													$
													{restaurantPayment.tip.toFixed(
														2
													)}
												</div>
												<div className='w-[110px] font-bold overflow-hidden'>
													$
													{restaurantPayment.remitToRestaurant.toFixed(
														2
													)}
												</div>
												{restaurantPayment.status &&
												restaurantPayment.status ===
													'paid' ? (
													<div className='w-[100px] overflow-hidden text-green-600'>
														{restaurantPayment.status.toUpperCase()}
													</div>
												) : (
													<div className='w-[100px] overflow-hidden'>
														{restaurantPayment.status.toUpperCase()}
													</div>
												)}
											</div>
										)
									}
								)}
							</div>
						)}
					</div>
				</div>
				<div className='w-full flex flex-row justify-center items-center mb-8'>
					{restaurantPaymentsPage > 1 ? (
						<div
							className='mx-auto underline font-bold mr-6 cursor-pointer w-fit'
							onClick={() => {
								if (restaurantPaymentsPage >= 2) {
									setRestaurantPaymentsPage(
										restaurantPaymentsPage - 1
									)
								}
								setLoading(true)
							}}
						>
							Previous {paginationLimit} payments
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Previous {paginationLimit} payments
						</div>
					)}
					{restaurantPayments.length === paginationLimit ? (
						<div
							className='mx-auto w-fit underline font-bold cursor-pointer'
							onClick={() => {
								if (
									restaurantPaymentsPage <
									totalPayments / paginationLimit
								) {
									setRestaurantPaymentsPage(
										restaurantPaymentsPage + 1
									)
								}
								setLoading(true)
							}}
						>
							Next {paginationLimit} payments
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Next {paginationLimit} payments
						</div>
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, RestaurantPayments }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	restaurantPayments: RestaurantPayments.restaurantPayments,
	restaurantPaymentsError: RestaurantPayments.error,
	loadingRestaurantPayments: RestaurantPayments.loadingRestaurantPayments,
	sendingRestaurantPayments: RestaurantPayments.sendingRestaurantPayments,
	restaurantPaymentsCompleted: RestaurantPayments.restaurantPaymentsCompleted,
	totalPayments: RestaurantPayments.totalPayments,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetRestaurantPayments: (payload) =>
		dispatch(getRestaurantPayments(payload)),
	onSendRestaurantPayments: (restaurantPaymentsIdList) =>
		dispatch(sendRestaurantPayments(restaurantPaymentsIdList)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantPayments)
