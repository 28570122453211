import React from 'react'
import { WhyReuseCarousel } from '../'
import { BsTrash } from 'react-icons/bs'
import { MdOutlineWaterDrop } from 'react-icons/md'
import { FiWind } from 'react-icons/fi'
const WhyReuse = () => {
	return (
		<div className='flex flex-col items-center justify-center mt-44 sm:mt-36 md:mt-20 lg:mt-5 mb-8'>
			<div className='flex flex-col justify-center items-center text-center'>
				<h1 className='text-3.5xl sm:text-5xl font-header text-green-600 text-center w-full mb-4 xs:mt-24'>
					Why Reuse?
				</h1>
				<h2 className='text-2xl font-header text-green-600 w-full text-center'>
					For every 1 million DeliverZero containers used:
				</h2>
			</div>
			<div className='z-10 block lg:hidden'>
				<WhyReuseCarousel />
			</div>
			<div className='w-full lg:flex flex-col items-center justify-center hidden'>
				{/* Waste  */}
				<div className='w-[725px] h-[200px] p-4 rounded-xl shadow-light-grey text-center flex flex-row justify-center items-center mt-12 mb-4 mx-8 flex-shrink-0 z-10 bg-white'>
					<div className='h-full w-full flex flex-row justify-start p-4'>
						<BsTrash className='w-[100px] h-[100px] object-cover rounded-xl fill-green-400 flex justify-center items-center my-auto' />
						<div className='h-full flex flex-col justify-center items-center w-full px-8 text-md'>
							<p className=' text-center mb-2 text-start leading-7'>
								Over{' '}
								<span className='font-semibold'>
									25 tons of packaging waste
								</span>{' '}
								would be diverted from{' '}
								<span className='font-semibold'>landfills</span>{' '}
								when compared to single-use plastics and
								compostables.
							</p>
						</div>
					</div>
				</div>
				{/* Water  */}
				<div className='w-[725px] h-[200px] p-4 rounded-xl shadow-light-grey text-center flex flex-row justify-center items-center mt-4 mb-4 mx-8 flex-shrink-0 z-10 bg-white'>
					<div className='h-full w-full flex flex-row justify-start p-4'>
						<MdOutlineWaterDrop className='w-[100px] h-[100px] object-cover rounded-xl fill-green-400 flex justify-center items-center my-auto' />
						<div className='h-full flex flex-col justify-center items-center w-full px-8 text-md'>
							<p className='text-center mb-2 lg:text-start  leading-7'>
								Over{' '}
								<span className='font-semibold'>
									440,000 gallons
								</span>{' '}
								of <span className='font-semibold'>water</span>{' '}
								would be saved when compared to compostables.
							</p>
						</div>
					</div>
				</div>
				{/* Emissions */}
				<div className='w-[725px] h-[200px] p-4 rounded-xl shadow-light-grey text-center flex flex-row justify-center items-center mt-4 mb-4 mx-8 flex-shrink-0 z-10 bg-white'>
					<div className='h-full w-full flex flex-row justify-start p-4'>
						<FiWind className='w-[100px] h-[100px] lg:object-cover lg:rounded-xl stroke-green-400 my-auto' />
						<div className='h-full flex flex-col justify-center items-center w-full px-8 text-md'>
							<p className=' text-center mb-2 lg:text-start  leading-7'>
								Over{' '}
								<span className='font-semibold'>110 tons</span>{' '}
								of{' '}
								<span className='font-semibold'>
									Greenhouse Gasses (GHG)
								</span>{' '}
								would be avoided when compared to single-use
								plastic and compostables.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WhyReuse
