import {
	GET_WFM_DATA,
	GET_WFM_DATA_FAIL,
	GET_WFM_DATA_SUCCESS,
	UPLOAD_WFM_DATA,
	UPLOAD_WFM_DATA_FAIL,
	UPLOAD_WFM_DATA_SUCCESS,
	GET_WFM_LOCATIONS,
	GET_WFM_LOCATIONS_FAIL,
	GET_WFM_LOCATIONS_SUCCESS,
	GET_WFM_BY_SLUG,
	GET_WFM_BY_SLUG_FAIL,
	GET_WFM_BY_SLUG_SUCCESS,
	GET_WFM_ENV_IMPACT,
	GET_WFM_ENV_IMPACT_SUCCESS,
} from './actionTypes'

export const getWFMData = (payload) => ({
	type: GET_WFM_DATA,
	payload: payload,
})

export const getWFMDataSuccess = (containers) => ({
	type: GET_WFM_DATA_SUCCESS,
	payload: containers,
})

export const getWFMDataFail = (error) => ({
	type: GET_WFM_DATA_FAIL,
	payload: error,
})

export const uploadWFMData = (payload) => ({
	type: UPLOAD_WFM_DATA,
	payload: payload,
})

export const uploadWFMDataSuccess = (containers) => ({
	type: UPLOAD_WFM_DATA_SUCCESS,
	payload: containers,
})

export const uploadWFMDataFail = (error) => ({
	type: UPLOAD_WFM_DATA_FAIL,
	payload: error,
})

export const getWFMLocations = (payload) => ({
	type: GET_WFM_LOCATIONS,
	payload: payload,
})

export const getWFMLocationsSuccess = (containers) => ({
	type: GET_WFM_LOCATIONS_SUCCESS,
	payload: containers,
})

export const getWFMLocationsFail = (error) => ({
	type: GET_WFM_LOCATIONS_FAIL,
	payload: error,
})

export const getWFMDataBySlug = (payload) => ({
	type: GET_WFM_BY_SLUG,
	payload: payload,
})

export const getWFMDataBySlugSuccess = (product) => ({
	type: GET_WFM_BY_SLUG_SUCCESS,
	payload: product,
})

export const getWFMDataBySlugFail = (error) => ({
	type: GET_WFM_BY_SLUG_FAIL,
	payload: error,
})

export const getWFMEnvImpact = (payload) => ({
	type: GET_WFM_ENV_IMPACT,
	payload: payload,
})

export const getWFMEnvImpactSuccess = (payload) => ({
	type: GET_WFM_ENV_IMPACT_SUCCESS,
	payload: payload,
})
