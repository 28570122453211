export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS'
export const GET_PAYMENTS_FAIL = 'GET_PAYMENTS_FAIL'

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL'

export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD'
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS'
export const CREATE_PAYMENT_METHOD_FAIL = 'CREATE_PAYMENT_METHOD_FAIL'

export const CREATE_PAYMENT_METHOD_CONFIRM = 'CREATE_PAYMENT_METHOD_CONFIRM'
export const CREATE_PAYMENT_METHOD_CONFIRM_SUCCESS =
	'CREATE_PAYMENT_METHOD_CONFIRM_SUCCESS'
export const CREATE_PAYMENT_METHOD_CONFIRM_FAIL =
	'CREATE_PAYMENT_METHOD_CONFIRM_FAIL'

export const STORE_PAYMENT_METHOD = 'STORE_PAYMENT_METHOD'
export const STORE_PAYMENT_METHOD_SUCCESS = 'STORE_PAYMENT_METHOD_SUCCESS'
export const STORE_PAYMENT_METHOD_FAIL = 'STORE_PAYMENT_METHOD_FAIL'

export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_FAIL = 'DELETE_PAYMENT_METHOD_FAIL'

export const RESET_CARD_ADDED = 'RESET_CARD_ADDED'

export const CHARGE_PAYMENT_METHOD = 'CHARGE_PAYMENT_METHOD'
export const CHARGE_PAYMENT_METHOD_SUCCESS = 'CHARGE_PAYMENT_METHOD_SUCCESS'
export const CHARGE_PAYMENT_METHOD_FAIL = 'CHARGE_PAYMENT_METHOD_FAIL'

export const RESET_CARD_RESPONSE = 'RESET_CARD_RESPONSE'
export const RESET_CARD_RESPONSE_SUCCESS = 'RESET_CARD_RESPONSE_SUCCESS'
export const RESET_CARD_RESPONSE_ERROR = 'RESET_CARD_RESPONSE_ERROR'

export const CHARGE_GUEST_PAYMENT = 'CHARGE_GUEST_PAYMENT'
export const CHARGE_GUEST_PAYMENT_SUCCESS = 'CHARGE_GUEST_PAYMENT_SUCCESS'
export const CHARGE_GUEST_PAYMENT_FAIL = 'CHARGE_GUEST_PAYMENT_FAIL'

export const STORE_GUEST_PAYMENT = 'STORE_GUEST_PAYMENT'
export const STORE_GUEST_PAYMENT_SUCCESS = 'STORE_GUEST_PAYMENT_SUCCESS'
export const STORE_GUEST_PAYMENT_FAIL = 'STORE_GUEST_PAYMENT_FAIL'
