import React, { useState } from 'react'

const Popup = ({ children, isOpen }) => {
	if (!isOpen) {
		return null
	}

	return (
		<div className='fixed inset-0 flex flex-col bg-gray-800 bg-opacity-25 backdrop-filter backdrop-blur-sm'>
			<div className='fixed inset-0 flex flex-col items-center justify-center z-50'>
				<div className='bg-white p-2 sm:p-6 flex flex-col items-center justify-center rounded shadow-lg w-5/6 sm:w-3/4 md:w-1/2 md:min-w-[480px] border-2 border-green-600'>
					{children}
				</div>
			</div>
		</div>
	)
}

export default Popup
