import { getUser } from '../../../store/auth/user/actions'
import { getFranchiseeCharges } from '../../../store/actions'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import Header from '../../../components/Header'
import { Button, CheckboxGroup, Input } from '../../../components/Elements'
import { Footer } from '../../../components'

const FranchiseeCharges = (state) => {
	const {
		user,
		onGetUser,
		franchiseeCharges,
		totalCharges,
		franchiseeChargesError,
		loadingFranchiseeCharges,
		onGetFranchiseeCharges,
	} = state

	useEffect(() => {
		onGetUser()
		orderPaginate()
	}, [onGetUser])

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	const [franchiseeChargesPage, setFranchiseeChargesPage] = useState(1)
	const [loading, setLoading] = useState(true)
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [paidFilter, setPaidFilter] = useState(false)
	const [pendingFilter, setPendingFilter] = useState(false)
	const [failedFilter, setFailedFilter] = useState(false)

	const getFranchiseeCharges = (page) => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetFranchiseeCharges({
					filter: {
						...filter,
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							endDate: -1,
						},
						limit: page * paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			}
		} else {
			onGetUser()
		}
	}

	const orderPaginate = () => {
		getFranchiseeCharges(franchiseeChargesPage)
	}

	useEffect(() => {
		orderPaginate()
	}, [franchiseeChargesPage])

	useEffect(() => {
		setLoading(false)
	}, [franchiseeCharges])

	useEffect(() => {
		getFranchiseeCharges(franchiseeChargesPage)
	}, [filter, user])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
					Billing Dashboard
				</h1>
				{/* <div className='w-4/5 max-w-[600px] mb-4 mx-auto'>
					<Input
						placeholder='Search restaurants ...'
						className=''
						name='searchInput'
						id='searchInput'
                        onChange={(e) => {
                            setFilter({
                                ...filter,
                                franchiseeSlug: user.franchiseeSlug,
                                nodeSlug: {
                                    $regex: e.target.value,
                                    $options: 'i',
                                }
                            })
                            setFranchiseeChargesPage(1)
                        }}                        
					/>
				</div>
				<div className='flex min-w-min w-[350px] mx-auto items-center mb-4'>
					<CheckboxGroup
						label='Paid'
						name='status-paid'
						className=''
						defaultChecked={false}
						onChange={(check) => {
							if (check.target.checked) {
								setPaidFilter(true)
								let statuses = ['paid']
								if (pendingFilter) {
									statuses.push('pending')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								setFilter({
									...filter,
                                    franchiseeSlug: user.franchiseeSlug,
									status: {
										$in: statuses,
									},
								})
								setFranchiseeChargesPage(1)
							} else {
								setPaidFilter(false)
								let statuses = []
								if (pendingFilter) {
									statuses.push('pending')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								if (statuses.length === 0) {
									setFilter({
										...filter,
                                        franchiseeSlug: user.franchiseeSlug,
										status: {
											$nin: [],
										},
									})
									setFranchiseeChargesPage(1)
								} else {
									setFilter({
										...filter,
                                        franchiseeSlug: user.franchiseeSlug,
										status: {
											$in: statuses,
										},
									})
									setFranchiseeChargesPage(1)
								}
							}
						}}
					/>
					<CheckboxGroup
						label='Pending'
						name='status-pending'
						className=''
						defaultChecked={false}
						onChange={(check) => {
							if (check.target.checked) {
								setPendingFilter(true)
								let statuses = ['pending']
								if (paidFilter) {
									statuses.push('paid')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								setFilter({
									...filter,
                                    franchiseeSlug: user.franchiseeSlug,
									status: {
										$in: statuses,
									},
								})
								setFranchiseeChargesPage(1)
							} else {
								setPendingFilter(false)
								let statuses = []
								if (paidFilter) {
									statuses.push('paid')
								}
								if (failedFilter) {
									statuses.push('failed')
								}
								if (statuses.length === 0) {
									setFilter({
										...filter,
                                        franchiseeSlug: user.franchiseeSlug,
										status: {
											$nin: [],
										},
									})
									setFranchiseeChargesPage(1)
								} else {
									setFilter({
										...filter,
                                        franchiseeSlug: user.franchiseeSlug,
										status: {
											$in: statuses,
										},
									})
									setFranchiseeChargesPage(1)
								}
							}
						}}
					/>
					<CheckboxGroup
						label='Failed'
						name='status-failed'
						className=''
						defaultChecked={false}
						onChange={(check) => {
							if (check.target.checked) {
								setFailedFilter(true)
								let statuses = ['failed']
								if (paidFilter) {
									statuses.push('paid')
								}
								if (pendingFilter) {
									statuses.push('pending')
								}
								setFilter({
									...filter,
									status: {
										$in: statuses,
									},
								})
								setFranchiseeChargesPage(1)
							} else {
								setFailedFilter(false)
								let statuses = []
								if (paidFilter) {
									statuses.push('paid')
								}
								if (pendingFilter) {
									statuses.push('pending')
								}
								if (statuses.length === 0) {
									setFilter({
										...filter,
										status: {
											$nin: [],
										},
									})
									setFranchiseeChargesPage(1)
								} else {
									setFilter({
										...filter,
										status: {
											$in: statuses,
										},
									})
									setFranchiseeChargesPage(1)
								}
							}
						}}
					/>
				</div> */}
				{franchiseeChargesError && (
					<div className='font-bold text-red-400'>
						An error occurred: {franchiseeChargesError.message}.
						Contact an administrator.
					</div>
				)}
				<div className='flex flex-col max-w-[1200px] justify-center mt-10 overflow-x-auto scrollbar mx-auto'>
					<div className='mx-auto'>
						{loadingFranchiseeCharges ||
							(loading && (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							))}
						{franchiseeCharges.length === 0 && (
							<div className='w-full text-center mx-auto'>
								<div className='w-fit font-bold text-red-400 w-[70px] whitespace-nowrap'>
									No charges found
								</div>
							</div>
						)}
						{franchiseeCharges.length > 0 && (
							<div className='mx-3 md:mx-6'>
								<div className='w-full flex flex-row justify-between'>
									<div className='mb-6 flex justify-start'>
										{' '}
										Total charges: {totalCharges}{' '}
									</div>
									<div className='mb-6 flex justify-end'>
										{' '}
										Page: {franchiseeChargesPage} of{' '}
										{Math.round(
											totalCharges / paginationLimit
										)}{' '}
									</div>
								</div>
								<table className='w-full border-collapse border border-gray-300'>
									<thead>
										<tr className='bg-gray-100'>
											<th className='border border-gray-300 py-2 px-4'>
												Orders From
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Base Monthly Fee
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Total Containers Tracked
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Additional Container Tracked
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Additional Usage Fee
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Total Fee
											</th>
										</tr>
									</thead>
									<tbody>
										{franchiseeCharges.map(
											(franchiseeCharge, index) => {
												return (
													<tr key={index}>
														<td className='border border-gray-300 py-2 px-4'>
															{
																franchiseeCharge.monthYear
															}
														</td>
														<td className='border border-gray-300 py-2 px-4'>
															$
															{
																franchiseeCharge.monthlyBaseFee
															}
														</td>
														<td className='border border-gray-300 py-2 px-4'>
															{
																franchiseeCharge.totalContainerUsage
															}
														</td>
														<td className='border border-gray-300 py-2 px-4'>
															{
																franchiseeCharge.additionalContainerUsage
															}
														</td>
														<td className='border border-gray-300 py-2 px-4'>
															$
															{
																franchiseeCharge.additionalContainerFee
															}
														</td>
														<td className='border border-gray-300 py-2 px-4'>
															$
															{
																franchiseeCharge.totalMonthlyFee
															}
														</td>
													</tr>
												)
											}
										)}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div>
				<div className='w-full flex flex-row justify-center items-center mb-8'>
					{franchiseeChargesPage > 1 ? (
						<div
							className='mx-auto underline font-bold mr-6 cursor-pointer w-fit'
							onClick={() => {
								if (franchiseeChargesPage >= 2) {
									setFranchiseeChargesPage(
										franchiseeChargesPage - 1
									)
								}
								setLoading(true)
							}}
						>
							Previous {paginationLimit} charges
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Previous {paginationLimit} charges
						</div>
					)}
					{franchiseeCharges.length === paginationLimit ? (
						<div
							className='mx-auto w-fit underline font-bold cursor-pointer'
							onClick={() => {
								if (
									franchiseeChargesPage <
									totalCharges / paginationLimit
								) {
									setFranchiseeChargesPage(
										franchiseeChargesPage + 1
									)
								}
								setLoading(true)
							}}
						>
							Next {paginationLimit} charges
						</div>
					) : (
						<div
							className='mx-auto w-fit'
							style={{ visibility: 'hidden' }}
						>
							Next {paginationLimit} charges
						</div>
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Franchisee }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	franchiseeCharges: Franchisee.franchiseeCharges,
	franchiseeChargesError: Franchisee.error,
	loadingFranchiseeCharges: Franchisee.loadingCharges,
	totalCharges: Franchisee.totalCharges,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetFranchiseeCharges: (filter) => dispatch(getFranchiseeCharges(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FranchiseeCharges)
