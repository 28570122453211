import { connect } from 'react-redux'
import React, { useRef, useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	createStop,
	updateStop,
	deleteStop,
	getContainers,
	getAllLogistics,
	resetSuccess,
	getTransactions,
	createTransaction,
	getInventory,
	resetTransaction,
	adjustInventory,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	Input,
	Button,
	SelectGroup,
	TextArea,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'
import useOutsideClick from '../../../components/Elements/DetectOutsideClick'

const LogisticsInventory = (props) => {
	const {
		user,
		loadingUser,
		onGetUser,
		updatingNode,
		loadingNodes,
		containers,
		onGetContainers,
		onCreateStop,
		onUpdateStop,
		onDeleteStop,
		logisticsStops,
		loadingStops,
		stopsSuccess,
		stops,
		onGetAllStops,
		stopsCount,
		onGetNodes,
		nodes,
		transactions,
		transactionsCount,
		loadingTransactions,
		totalBoxCount,
		onGetTransactions,
		inventory,
		loadingLogistics,
		logisticsError,
		onGetInventory,
		onResetSuccess,
		onCreateTransaction,
		createTransactionError,
		onResetTransaction,
		transaction,
		adjustedInventory,
		onAdjustInventory,
		...newProps
	} = props

	const paginationLimit = 10
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const impactRef = useRef(null)
	const [selectedNode, setSelectedNode] = useState({})
	const [selectedNodeName, setSelectedNodeName] = useState(null)
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [nodeSlugs, setNodeSlugs] = useState([])
	const [newNote, setNewNote] = useState('')
	const [sumQty, setSumQty] = useState(0)
	const [page, setPage] = useState(1)
	const [inputValues, setInputValues] = useState({})
	const [editInventory, setEditInventory] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		const filter = {
			inventory: true,
		}
		onGetContainers([filter])
	}, [onGetContainers])

	useEffect(() => {
		if (containers) {
			const initialInputValues = {}
			containers.forEach((container) => {
				initialInputValues[container.containerId] = 0
			})
			setInputValues({
				...initialInputValues,
			})
		}
	}, [containers])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$in: ['warehouse', 'washhub'],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		if (nodes) {
			const slugs = nodes.map((node) => node.slug)
			setNodeSlugs(slugs)
			onGetInventory({
				node: {
					$in: slugs,
				},
			})
		}
	}, [nodes, transaction])

	useOutsideClick(impactRef, () => {
		setShowNodeChoices(false)
	})

	const getTransactions = (page) => {
		let filter = {
			node: {
				$in: [nodeSlugs],
			},
		}
		if (selectedNode !== '') {
			filter.node = selectedNode
		}

		if (user) {
			onGetTransactions({
				filter: filter,
				options: {
					sort: {
						timestamp: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const transactionPaginate = () => {
		getTransactions(page)
	}

	useEffect(() => {
		transactionPaginate()
	}, [page, selectedNode, user])

	useEffect(() => {
		setSumQty(
			Object.values(inputValues).reduce(
				(acc, currentValue) =>
					parseInt(acc) +
					(parseInt(currentValue) < 0 ? 0 : parseInt(currentValue)),
				0
			)
		)
	}, [inputValues])

	const handleInputChange = (column, value) => {
		setInputValues((prevInputValues) => ({
			...prevInputValues,
			[column]: value,
		}))
	}

	const handleUpdate = () => {
		onAdjustInventory({
			nodeSlug: selectedNode,
			desiredQuantities: inputValues,
		})
	}

	useEffect(() => {
		if (selectedNode && Object.keys(adjustedInventory).length > 0) {
			const zeroOutAdjustments = adjustedInventory.zeroOutAdjustments
			const sumOfAdjustments = Object.values(zeroOutAdjustments)
				.map((val) => {
					const num = parseFloat(val)
					return isNaN(num) ? 0 : num
				})
				.reduce((acc, val) => acc + val, 0)

			const newAdjustments = adjustedInventory.newAdjustments
			const sumOfNewAdjustments = Object.values(newAdjustments)
				.map((val) => {
					const num = parseFloat(val)
					return isNaN(num) ? 0 : num
				})
				.reduce((acc, val) => acc + val, 0)

			onCreateTransaction({
				timestamp: new Date(),
				type: 'adminBalanceUpdate',
				node: selectedNode,
				user: {
					phone: user.phone,
					email: user.email,
					firstName: user.firstName,
					lastName: user.lastName,
				},
				client: 'deliverzero',
				boxCountByContainer: zeroOutAdjustments,
				boxCount: sumOfAdjustments,
				note: 'Zero out inventory',
			})

			onCreateTransaction({
				timestamp: new Date(),
				type: 'adminBalanceUpdate',
				node: selectedNode,
				user: {
					phone: user.phone,
					email: user.email,
					firstName: user.firstName,
					lastName: user.lastName,
				},
				client: 'deliverzero',
				boxCountByContainer: newAdjustments,
				boxCount: sumOfNewAdjustments,
				note: newNote,
			})

			setShowSuccess(true)
			setEditInventory(false)
		}
	}, [adjustedInventory])

	useEffect(() => {
		if (transaction) {
			setShowSuccess(true)
			setEditInventory(false)
			onGetInventory({
				node: {
					$in: nodeSlugs,
				},
			})
			return function cleanup() {
				onResetTransaction()
				onResetSuccess()
			}
		}
	}, [transaction])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div className='h-full w-full'>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>

					<div className='px-12'>
						<div className='flex container flex-col justify-center max-w-[1600px] mb-4 mx-auto'>
							<h1 className='font-header text-green-600 text-4xl mb-7 mt-5 text-center'>
								Inventory Dashboard
							</h1>

							{/* inventory table */}
							<div className='px-2'>
								<div className='my-10 max-w-[1600px] overflow-auto scrollbar flex flex-col justify-center align-center mx-auto'>
									<div className='overflow-x-auto'>
										{inventory && containers ? (
											<table className='w-full border-collapse border border-gray-300'>
												<thead>
													<tr className='bg-gray-100'>
														<th className='border border-gray-300 py-2 px-4'>
															Node
														</th>
														<th className='border border-gray-300 py-2 px-4 font-header bg-green-50'>
															Current Total Box
															Count
														</th>
														<th className='border border-gray-300 py-2 px-4'>
															Last Inventory
															Update
														</th>
														{containers.map(
															(container) => (
																<th
																	key={
																		container.containerId
																	}
																	className='border border-gray-300 py-2 px-4'
																>
																	{
																		container.containerId
																	}
																</th>
															)
														)}
													</tr>
												</thead>
												<tbody>
													{inventory &&
														Array.isArray(
															inventory
														) &&
														inventory.map(
															(
																nodeInventory,
																index
															) => (
																<tr
																	key={index}
																	className=''
																	onClick={() => {
																		setSelectedNode(
																			nodeInventory._id
																		)
																		setSelectedNodeName(
																			nodeInventory.name
																		)
																		setPage(
																			1
																		)
																	}}
																>
																	<td className='border border-gray-300 py-2 px-4'>
																		{
																			nodeInventory.name
																		}
																	</td>
																	<td className='border border-gray-300 py-2 px-4 font-header bg-green-50'>
																		{nodeInventory.totalBoxCount <
																		0
																			? 0
																			: nodeInventory.totalBoxCount}
																	</td>
																	<td className='border border-gray-300 py-2 px-4'>
																		{new Date(
																			nodeInventory.lastInventoryUpdate
																		).toLocaleDateString(
																			'en-US',
																			{
																				year: '2-digit',
																				month: '2-digit',
																				day: 'numeric',
																			}
																		)}
																	</td>
																	{containers &&
																		containers.map(
																			(
																				container
																			) => (
																				<td
																					key={
																						container.containerId
																					}
																					className='border border-gray-300 py-2 px-4'
																				>
																					{nodeInventory[
																						container
																							.containerId
																					] !==
																					undefined
																						? nodeInventory[
																								container
																									.containerId
																						  ] <
																						  0
																							? 0
																							: nodeInventory[
																									container
																										.containerId
																							  ]
																						: 0}
																				</td>
																			)
																		)}
																</tr>
															)
														)}
												</tbody>
											</table>
										) : (
											<div className='text-center text-red-400 my-[50px]'>
												loading inventory...
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* transactions table */}
					<div className='px-10'>
						<div className='my-10 max-w-[800px] lg:max-w-[1600px] overflow-auto scrollbar flex flex-col justify-center align-center mx-auto'>
							{selectedNodeName && (
								<div className='font-header text-xl text-green-600 flex flex-row'>
									{selectedNodeName} transactions:
									<Button
										text='Edit Inventory'
										color='green'
										className='px-2 w-[100px] ml-6 mb-6'
										size='xs'
										onClick={() => {
											setEditInventory(!editInventory)
										}}
									/>
								</div>
							)}
							<div className='overflow-x-auto'>
								{showSuccess && (
									<div className='text-green-600 mb-6'>
										Inventory updated!
									</div>
								)}
								{editInventory && (
									<div className='flex flex-col ml-6'>
										{createTransactionError && (
											<div className='text-red-400'>
												{createTransactionError}
											</div>
										)}
										<table className='w-[300px] border-collapse border border-gray-300'>
											<tbody>
												<tr className='border border-gray-300 py-2 px-4'>
													<th className='bg-gray-100 border-gray-300'>
														Box Count
													</th>
													<td className=''>
														<Input
															type='number'
															placeholder={`Enter total qty`}
															value={sumQty || 0}
															onChange={(e) =>
																setSumQty(
																	e.target
																		.value
																)
															}
															className='text-green-600 max-w-[180px]'
														/>
													</td>
												</tr>
												<tr className='border border-gray-300 py-2 px-4'>
													<th className='bg-gray-100 border-gray-300'>
														Note
													</th>
													<td className=''>
														<Input
															type='text'
															placeholder={``}
															value={newNote}
															onChange={(e) =>
																setNewNote(
																	e.target
																		.value
																)
															}
															className='max-w-[180px]'
														/>
													</td>
												</tr>
												{containers &&
													containers.map(
														(container) => (
															<tr
																key={
																	container.containerId
																}
																className='border border-gray-300 py-2 px-4'
															>
																<th className='bg-gray-100 border-gray-300'>
																	{
																		container.containerId
																	}
																</th>
																<td
																	key={
																		container.containerId
																	}
																	className=''
																>
																	<Input
																		type='number'
																		placeholder={`Enter qty`}
																		value={
																			inputValues[
																				container
																					.containerId
																			] ||
																			''
																		}
																		onChange={(
																			e
																		) =>
																			handleInputChange(
																				container.containerId,
																				parseInt(
																					e
																						.target
																						.value
																				)
																			)
																		}
																		className='max-w-[180px]'
																	/>
																</td>
															</tr>
														)
													)}
											</tbody>
										</table>
										<Button
											text='Save'
											size='xs'
											color='green'
											className='px-2 w-[100px] ml-[200px] mt-2 mb-6'
											onClick={handleUpdate}
										/>
									</div>
								)}
								{transactions && transactions.length > 0 ? (
									<>
										<table className='w-full border-collapse border border-gray-300'>
											<thead>
												<tr className='bg-gray-100'>
													<th className='border border-gray-300 py-2 px-4'>
														Date
													</th>
													{/* <th className="border border-gray-300 py-2 px-4">Type</th> */}
													<th className='border border-gray-300 py-2 px-4'>
														Box Count
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Note
													</th>
													{containers &&
														containers.map(
															(container) => (
																<th
																	key={
																		container.containerId
																	}
																	className='border border-gray-300 py-2 px-4 transform rotate-180'
																	style={{
																		writingMode:
																			'vertical-rl',
																	}}
																>
																	{
																		container.containerId
																	}
																</th>
															)
														)}
												</tr>
											</thead>
											<tbody>
												{transactions &&
													transactions.map(
														(
															transaction,
															index
														) => (
															<tr
																key={index}
																className=''
															>
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		new Date(
																			transaction.timestamp
																		)
																			.toISOString()
																			.split(
																				'T'
																			)[0]
																	}
																</td>
																{/* <td className="border border-gray-300 py-2 px-4">{transaction.type}</td> */}
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		transaction.boxCount
																	}
																</td>
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		transaction.note
																	}
																</td>
																{containers &&
																	containers.map(
																		(
																			container
																		) => (
																			<td
																				key={
																					container.containerId
																				}
																				className='border border-gray-300 py-2 px-4'
																			>
																				{transaction.boxCountByContainer &&
																				transaction
																					.boxCountByContainer[
																					container
																						.containerId
																				] !==
																					undefined
																					? transaction
																							.boxCountByContainer[
																							container
																								.containerId
																					  ]
																					: 0}
																			</td>
																		)
																	)}
															</tr>
														)
													)}
											</tbody>
										</table>
										<div className='w-full flex flex-row justify-center items-center mb-8'>
											{page > 1 ? (
												<div
													className='mx-auto underline font-bold mr-6 cursor-pointer w-fit'
													onClick={() => {
														if (page >= 2) {
															setPage(page - 1)
														}
													}}
												>
													Previous {paginationLimit}{' '}
													transactions
												</div>
											) : (
												<div
													className='mx-auto w-fit'
													style={{
														visibility: 'hidden',
													}}
												>
													Previous {paginationLimit}{' '}
													transactions
												</div>
											)}
											{transactions &&
											transactions.length ===
												paginationLimit ? (
												<div
													className='mx-auto w-fit underline font-bold cursor-pointer'
													onClick={() => {
														if (
															page <
															transactionsCount /
																paginationLimit
														) {
															setPage(page + 1)
														}
													}}
												>
													Next {paginationLimit}{' '}
													transactions
												</div>
											) : (
												<div
													className='mx-auto w-fit'
													style={{
														visibility: 'hidden',
													}}
												>
													Next {paginationLimit}{' '}
													transactions
												</div>
											)}
										</div>
									</>
								) : selectedNodeName ? (
									<div className='text-center text-red-400 my-[50px]'>
										No transactions found
									</div>
								) : (
									<div className='text-center text-green-600 my-[50px]'>
										Select a facility above to view
										transaction history
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	Admin,
	Containers,
	Dropoffs,
	Logistics,
	Transactions,
	TransactionsCreate,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	creatingRoute: Admin.creatingRoute,
	createdRoute: Admin.createdRoute,
	createRouteError: Admin.createRouteError,
	creatingDropoff: Dropoffs.creatingDropoff,
	dropoffRequested: Dropoffs.dropoffRequested,
	dropoffError: Dropoffs.dropoffError,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	stops: Logistics.stops,
	loadingStops: Logistics.loadingStops,
	logisticsError: Logistics.error,
	loadingLogistics: Logistics.loading,
	stopsSuccess: Logistics.success,
	stopsCount: Logistics.stopsCount,
	inventory: Logistics.inventory,
	totalBoxCount: Transactions.totalBoxCount,
	markets: Transactions.markets,
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
	adjustedInventory: Logistics.adjustedInventory,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onCreateGentlyDropoff: (dropoffPayload) =>
		dispatch(createGentlyDropoff(dropoffPayload)),
	onCreateGentlyDropoffNoNode: (dropoffPayload) =>
		dispatch(createGentlyDropoffNoNode(dropoffPayload)),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onCreateStop: (payload) => dispatch(createStop(payload)),
	onUpdateStop: (payload) => dispatch(updateStop(payload)),
	onDeleteStop: (payload) => dispatch(deleteStop(payload)),
	onGetAllStops: (payload) => dispatch(getAllLogistics(payload)),
	onCreateRoute: (routePayload) => dispatch(createRoute(routePayload)),
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetInventory: (payload) => dispatch(getInventory(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onAdjustInventory: (paylaod) => dispatch(adjustInventory(paylaod)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsInventory)
