import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { totalCart, clearCart, getCartNodeBySlug } from '../../store/actions'
import { CartItem } from '../'
import { Button, BounceLoaderCentered } from '../Elements'
import { MdClear } from 'react-icons/md'
import useOutsideClick from '../Elements/DetectOutsideClick'

const Cart = (props) => {
	const {
		cartDropUp,
		cartItems,
		onTotalCart,
		cartTotal,
		onClearCart,
		onGetCartNodeBySlug,
		cartNode,
		handleCloseViewCart,
		clearingCart,
		deliveryOption,
	} = props

	const [cartItemsNodeSlug, setCartItemsNodeSlug] = useState(null)

	const total =
		cartTotal +
		cartTotal * (cartNode?.serviceFeeToUser + cartNode?.taxToUser)
	const totalWithDelivery =
		cartTotal +
		cartTotal * (cartNode?.serviceFeeToUser + cartNode?.taxToUser) +
		cartNode?.deliveryFeeToUser

	const handleClosePopUp = () => {
		handleCloseViewCart(false)
	}

	const handleClearCart = () => {
		onClearCart()
	}

	useEffect(() => {
		if (cartItems) {
			onTotalCart(cartItems)
			if (cartItems[0]) {
				setCartItemsNodeSlug(cartItems[0].nodeSlug)
			}
		}
	}, [cartItems, JSON.stringify(cartItems)])

	useEffect(() => {
		if (cartItemsNodeSlug) {
			onGetCartNodeBySlug(cartItemsNodeSlug)
		}
	}, [cartItemsNodeSlug])

	const impactRef = useRef(null)

	useOutsideClick(impactRef, () => {
		handleCloseViewCart(false)
	})

	return (
		<>
			{/* lg */}
			<div className='scrollbar shadow-xl hidden lg:inline lg:w-[350px] lg:sticky lg:top-0 lg:right-0 lg:h-screen lg:overflow-y-auto lg:bg-white'>
				<div className='pt-8 pb-4 pr-10 pl-4 items-center justify-between border-solid border-b'>
					{cartNode ? (
						<h3 className='text-sm'>
							Your Order for{' '}
							<p className='font-header pt-2 text-xl'>
								{cartNode.name}
							</p>
						</h3>
					) : (
						<h3 className='text-sm'>Your Order</h3>
					)}
				</div>
				{cartItems && cartItems.length > 0 ? (
					<>
						{' '}
						<div className='pt-5 border-solid border-b pb-5'>
							<div className='flex justify-between items-center px-5'>
								<p className='text-xl text-black font-header'>
									Items
								</p>
								{clearingCart ? (
									<BounceLoaderCentered container='div' />
								) : (
									<p
										className='text-sm cursor-pointer'
										onClick={handleClearCart}
									>
										Clear Cart
									</p>
								)}
							</div>
							<CartItem
								cartItems={cartItems}
								setCartItemsNodeSlug={setCartItemsNodeSlug}
							/>
						</div>
						<div className='pt-5 pb-5'>
							<p className='text-xl text-black font-header px-5'>
								Summary
							</p>
							<div className='flex justify-between px-8 pt-5'>
								<p className='text-md'>Subtotal</p>
								<p className='text-md'>
									{`${new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'USD',
									}).format(cartTotal)}`}
								</p>
							</div>
							{deliveryOption === 'delivery' && (
								<div className='flex justify-between px-8 pt-2'>
									<p className='text-md'>Delivery Fee</p>
									<p className='text-md'>
										{`${new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency: 'USD',
										}).format(
											cartNode?.deliveryFeeToUser
										)}`}
									</p>
								</div>
							)}
							<div className='flex justify-between px-8 pt-2'>
								<p className='text-md'>Taxes & Fees</p>
								<p className='text-md'>
									{`${new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'USD',
									}).format(
										cartTotal *
											(cartNode?.serviceFeeToUser +
												cartNode?.taxToUser)
									)}`}
								</p>
							</div>
							<div className='flex justify-between mx-8 mt-2 pt-2 border-t border-solid'>
								<p className='text-lg font-header'>Total</p>
								<p className='text-lg font-header'>
									{`${new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'USD',
									}).format(
										deliveryOption === 'delivery'
											? totalWithDelivery
											: total
									)}`}
								</p>
							</div>
						</div>
						<div className='py-5 border-solid border-t flex justify-center'>
							<Button
								link='/checkout'
								className='px-8'
								text={`Checkout ${new Intl.NumberFormat(
									'en-US',
									{
										style: 'currency',
										currency: 'USD',
									}
								).format(
									deliveryOption === 'delivery'
										? totalWithDelivery
										: total
								)}`}
								size='sm'
							/>
						</div>
					</>
				) : (
					<p className='text-center text-lg text-gray-500 pt-10'>
						Your Cart is Empty!
					</p>
				)}
			</div>
			{cartDropUp && (
				<>
					{/* smaller than lg */}
					<div className='justify-end fixed flex w-full inset-0 z-50 shadow-xl overflow-x-hidden sm:bg-gray-800 sm:bg-opacity-25 sm:backdrop-filter sm:backdrop-blur-sm lg:hidden'>
						<div
							ref={impactRef}
							className='relative flex flex-col w-full bg-white outline-none focus:outline-none h-fit min-h-full pb-20 sm:w-1/2'
						>
							<div className='fixed top-5 right-4'>
								<MdClear
									className='text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl'
									onClick={handleClosePopUp}
								/>
							</div>
							<div className='pt-8 pb-4 pr-10 pl-4 items-center justify-between border-solid border-b'>
								{cartNode ? (
									<h3 className='text-sm'>
										Your Order for{' '}
										<p className='font-header pt-2 text-xl'>
											{cartNode.name}
										</p>
									</h3>
								) : (
									<h3 className='text-sm'>Your Order</h3>
								)}
							</div>
							{cartItems && cartItems.length > 0 ? (
								<>
									{' '}
									<div className='pt-5 border-solid border-b pb-5'>
										<div className='flex justify-between items-center px-5'>
											<p className='text-xl text-black font-header'>
												Items
											</p>
											{clearingCart ? (
												<BounceLoaderCentered container='div' />
											) : (
												<p
													className='text-sm cursor-pointer'
													onClick={handleClearCart}
												>
													Clear Cart
												</p>
											)}
										</div>
										<CartItem
											cartItems={cartItems}
											setCartItemsNodeSlug={
												setCartItemsNodeSlug
											}
										/>
									</div>
									<div className='pt-5 pb-5'>
										<p className='text-xl text-black font-header px-5'>
											Summary
										</p>
										<div className='flex justify-between px-8 pt-5'>
											<p className='text-md'>Subtotal</p>
											<p className='text-md'>
												{`${new Intl.NumberFormat(
													'en-US',
													{
														style: 'currency',
														currency: 'USD',
													}
												).format(cartTotal)}`}
											</p>
										</div>
										{deliveryOption === 'delivery' && (
											<div className='flex justify-between px-8 pt-2'>
												<p className='text-md'>
													Delivery Fee
												</p>
												<p className='text-md'>
													{`${new Intl.NumberFormat(
														'en-US',
														{
															style: 'currency',
															currency: 'USD',
														}
													).format(
														cartNode?.deliveryFeeToUser
													)}`}
												</p>
											</div>
										)}
										<div className='flex justify-between px-8 pt-2'>
											<p className='text-md'>
												Taxes & Fees
											</p>
											<p className='text-md'>
												{`${new Intl.NumberFormat(
													'en-US',
													{
														style: 'currency',
														currency: 'USD',
													}
												).format(
													cartTotal *
														(cartNode?.serviceFeeToUser +
															cartNode?.taxToUser)
												)}`}
											</p>
										</div>
										<div className='flex justify-between mx-8 mt-2 pt-2 border-t border-solid'>
											<p className='text-lg font-header'>
												Total
											</p>
											<p className='text-lg font-header'>
												{`${new Intl.NumberFormat(
													'en-US',
													{
														style: 'currency',
														currency: 'USD',
													}
												).format(
													deliveryOption ===
														'delivery'
														? totalWithDelivery
														: total
												)}`}
											</p>
										</div>
									</div>
									<div className='py-5 border-solid border-t flex justify-center'>
										<Button
											link='/checkout'
											className='px-8'
											text={`Checkout ${new Intl.NumberFormat(
												'en-US',
												{
													style: 'currency',
													currency: 'USD',
												}
											).format(
												deliveryOption === 'delivery'
													? totalWithDelivery
													: total
											)}`}
											size='sm'
										/>
									</div>
								</>
							) : (
								<p className='text-center text-lg text-gray-500 pt-10'>
									Your Cart is Empty!
								</p>
							)}
						</div>
					</div>
				</>
			)}
		</>
	)
}

const mapStateToProps = ({ Cart }) => ({
	cartTotal: Cart.cartTotal,
	cartItems: Cart.cartItems,
	cartNodeSlug: Cart.nodeSlug,
	cartNode: Cart.cartNode,
	clearingCart: Cart.clearingCart,
})

const mapDispatchToProps = (dispatch) => ({
	onTotalCart: (cart) => dispatch(totalCart(cart)),
	onClearCart: () => dispatch(clearCart()),
	onGetCartNodeBySlug: (slug) => dispatch(getCartNodeBySlug(slug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
