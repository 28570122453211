import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useState } from 'react'

import { Footer, GiftCardForm, Header } from '../../components'

const GiftCardPurchase = () => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const [showModal, setShowModal] = useState(false)

	return (
		<>
			<Header />

			<div className='flex flex-col justify-center items-center text-center h-full w-screen px-4 py-12'>
				<h1 className='font-header text-3xl md:text-5xl mb-12 text-green-600'>
					Purchase a Gift Card
				</h1>
				<p className='w-1/3 text-center min-w-[300px] mb-12 text-sm md:text-base'>
					Give the gift of sustainable delivery with a DeliverZero
					gift card.
				</p>
				<div className='w-full flex flex-col justify-center items-center mb-12'>
					<Elements stripe={stripePromise}>
						<GiftCardForm />
					</Elements>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default GiftCardPurchase
