export const GET_PLATFORMS = 'GET_PLATFORMS'
export const GET_PLATFORMS_SUCCESS = 'GET_PLATFORMS_SUCCESS'
export const GET_PLATFORMS_FAIL = 'GET_PLATFORMS_FAIL'

export const GET_PLATFORM_BY_KEY = 'GET_PLATFORM_BY_KEY'
export const GET_PLATFORM_BY_KEY_SUCCESS = 'GET_PLATFORM_BY_KEY_SUCCESS'
export const GET_PLATFORM_BY_KEY_FAIL = 'GET_PLATFORM_BY_KEY_FAIL'

export const RESET_PLATFORM = 'RESET_PLATFORM'

export const TRIGGER_OTTER = 'TRIGGER_OTTER'
export const TRIGGER_OTTER_SUCCESS = 'TRIGGER_OTTER_SUCCESS'
export const TRIGGER_OTTER_FAIL = 'TRIGGER_OTTER_FAIL'
