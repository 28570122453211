import { call, put, takeEvery } from 'redux-saga/effects'
import { GET_APP_INFO, GET_BANNER, HIDE_BANNER, RUN_MONGO_FUNCTION } from './actionTypes'
import {
	getAppInfoSuccess,
	getAppInfoFailure,
	getBannerSuccess,
	getBannerFailure,
	runMongoFunctionError,
	runMongoFunctionSuccess,
} from './actions'
import {executeMongoFunctionApi, findAppInfo, findBannerInfo, hideBanner} from '../../model/appInfo'

function* fetchAppInfo() {
	try {
		// console.log('IN SAGA')
		const appInfo = yield call(findAppInfo)
		yield put(getAppInfoSuccess(appInfo))
	} catch (error) {
		yield put(getAppInfoFailure(error))
	}
}

function* fetchBannerInfo() {
	try {
		const bannerInfo = yield call(findBannerInfo)
		yield put(getBannerSuccess(bannerInfo))
	} catch (error) {
		yield put(getBannerFailure(error))
	}
}

function* executeMongoFunction(action) {
	try {
		const response = yield call(executeMongoFunctionApi, action.payload);
		yield put(runMongoFunctionSuccess(response));
	} catch (error) {
		yield put(runMongoFunctionError(error));
	}
}

function* hideBannerInfo() {
	const hide = yield call(hideBanner)
}

function* appInfoSaga() {
	yield takeEvery(GET_APP_INFO, fetchAppInfo)
	yield takeEvery(GET_BANNER, fetchBannerInfo)
	yield takeEvery(HIDE_BANNER, hideBannerInfo)
	yield takeEvery(RUN_MONGO_FUNCTION, executeMongoFunction);
}

export default appInfoSaga
