import {
	GET_APP_INFO,
	GET_APP_INFO_SUCCESS,
	GET_APP_INFO_FAILURE,
	GET_BANNER,
	GET_BANNER_SUCCESS,
	GET_BANNER_FAILURE,
	HIDE_BANNER,
	RUN_MONGO_FUNCTION,
	RUN_MONGO_FUNCTION_SUCCESS,
	RUN_MONGO_FUNCTION_ERROR,
} from './actionTypes'
export const getAppInfo = () => ({
	type: GET_APP_INFO,
})

export const getAppInfoSuccess = (payload) => ({
	type: GET_APP_INFO_SUCCESS,
	payload: payload,
})
export const getAppInfoFailure = (payload) => ({
	type: GET_APP_INFO_FAILURE,
	payload: payload,
})

export const getBanner = () => ({
	type: GET_BANNER,
})

export const runMongoFunction = (payload) => ({
	type: RUN_MONGO_FUNCTION,
	payload: payload,
});

export const runMongoFunctionSuccess = (payload) => ({
	type: RUN_MONGO_FUNCTION_SUCCESS,
	payload: payload,
});

export const runMongoFunctionError = (payload) => ({
	type: RUN_MONGO_FUNCTION_ERROR,
	payload: payload,
});

export const getBannerSuccess = (payload) => ({
	type: GET_BANNER_SUCCESS,
	payload: payload,
})

export const getBannerFailure = (payload) => ({
	type: GET_BANNER_FAILURE,
	payload: payload,
})

export const hideBanner = () => ({
	type: HIDE_BANNER,
})
