import React from 'react'

function FormGroup({
	className = '',
	horizontal = false,
	header = false,
	footer = false,
	children = null,
	...newProps
}) {
	let finalClass = `${className} `
	if (header)
		finalClass += `grid grid-cols-1 gap-6 sm:mb-0 sm:gap-6 items-center`
	if (horizontal)
		finalClass += `grid grid-cols-1 gap-6 sm:mb-0 sm:gap-6 sm:grid-cols-${children.length} items-center`
	if (footer) finalClass += `items-center`
	return (
		<div
			className={finalClass}
			{...newProps}
		>
			{children}
		</div>
	)
}

export default FormGroup
