import React, { useEffect } from 'react'
import Header from '../../components/Header'
import { Footer } from '../../components'

function AmbassadorLanding() {
	useEffect(() => {
		// Create script element and set attributes
		const script = document.createElement('script')
		script.src = '//embed.typeform.com/next/embed.js'
		script.async = true
		script.onload = () => {}

		// Append the script to the document body
		document.body.appendChild(script)

		// Cleanup function to remove the script when component unmounts
		return () => {
			document.body.removeChild(script)
		}
	}, [])
	return (
		<>
			<Header />
			<div
				className='h-auto min-h-[500px]'
				data-tf-live='01HNBP0FHNPPRVNNVX02BTAG9E'
			></div>
			<script src='//embed.typeform.com/next/embed.js'></script>
			<Footer />
		</>
	)
}

export default AmbassadorLanding
