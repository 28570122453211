import React, { useRef, useState, useEffect } from 'react'
import useOutsideClick from '../Elements/DetectOutsideClick'
import { MdClear } from 'react-icons/md'
import { connect } from 'react-redux'
import {
	getUser,
	getNodes,
	updateUser,
	createStop,
	updateStop,
	deleteStop,
	getContainers,
	getAllLogistics,
	resetSuccess,
	resetTransaction,
	createOrUpdateTransaction,
	logisticsAlert,
	getMarketObj,
	createPackingListTransactions,
} from '../../store/actions'
import { Button, TextArea } from '../Elements'
import { BounceLoader } from 'react-spinners'
import LZString from 'lz-string'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const PackingListPopUp = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		containers,
		onGetContainers,
		onCreateStop,
		onUpdateStop,
		onDeleteStop,
		stopsError,
		logisticsStops,
		loadingStops,
		stopsSuccess,
		stops,
		onGetAllStops,
		selectedStop,
		openPopUp,
		handleOpenPopUp,
		onCreateOrUpdateTransaction,
		transactionResult,
		creatingTransaction,
		onResetTransaction,
		onCreateAlert,
		stopsCount,
		binBoxCount,
		setBinBoxCount,
		onResetSuccess,
		onGetMarketObj,
		marketObj,
		packingListTransactions,
		onCreatePackingListTransactions,
		...newProps
	} = props

	const [formError, setFormError] = useState('')
	const [containerActuals, setContainerActuals] = useState({
		...selectedStop.products,
	})
	const [totalQty, setTotalQty] = useState({})
	const [notes, setNotes] = useState('')
	const [viewAll, setViewAll] = useState(false)
	const [showEdit, setShowEdit] = useState(false)
	const bounceLoaderColor = '#507f74'

	const fileInputRef = useRef(null)
	const [stopImage, setStopImage] = useState(null)
	const [imageURL, setImageURL] = useState(null)

	const storage = getStorage()
	const impactRef = useRef(null)

	useEffect(() => {
		return function cleanup() {
			onResetTransaction()
			onResetSuccess()
		}
	}, [transactionResult])

	useEffect(() => {
		onGetMarketObj(selectedStop.market)
	}, [selectedStop])

	const handleClosePopUp = () => {
		setBinBoxCount(null)
		// onResetSuccess()
		handleOpenPopUp(false)
	}

	useOutsideClick(impactRef, () => {
		setBinBoxCount(null)
		// onResetSuccess()
		handleOpenPopUp(false)
	})

	const handleViewAll = () => {
		setViewAll(!viewAll)
	}

	const handleShowEdit = () => {
		setShowEdit(!showEdit)
	}

	const handleQuantityChange = (containerId, quantity) => {
		setContainerActuals((prevState) => ({
			...prevState,
			[containerId]: parseInt(quantity, 10) || 0,
		}))
	}

	const handleComplete = () => {
		setFormError('')
		let actualBoxCount = selectedStop.boxCount
		const actualTotal = Object.values(containerActuals).reduce(
			(acc, value) => acc + value,
			0
		)
		if (actualTotal > 0) {
			actualBoxCount = actualTotal
		} else if (totalQty && Object.keys(totalQty).length > 0) {
			actualBoxCount = parseInt(totalQty)
		}
		;('')

		if (
			selectedStop.stopType === 'pick' &&
			!['warehouse', 'washhub', 'customer'].includes(
				selectedStop.locationType
			)
		) {
			// check that containerActuals has at least one non-zero value
			const hasNonZeroValues = Object.values(containerActuals).some(
				(value) => value > 0
			)
			if (!hasNonZeroValues && !totalQty) {
				setFormError('Actuals required for this pickup')
				return
			}
		}

		// if (selectedStop.stopType === 'pick' && !stopImage) {
		// 	setFormError('Photo required for collection')
		// } else {
		const updatePayload = {
			date: selectedStop.date,
			market: selectedStop.market,
			stopId: selectedStop.stopId,
			status: 'complete',
			driverNote: notes,
			actuals: {
				boxCount: actualBoxCount,
				...containerActuals,
			},
			image: imageURL,
		}
		onGetMarketObj(selectedStop.market)
		onUpdateStop(updatePayload)
		submitTransaction(actualBoxCount)
		// handleOpenPopUp(false)
		// }
	}
	const submitTransaction = async (actualBoxCount) => {
		if (!marketObj || Object.keys(marketObj).length === 0) {
			await onGetMarketObj(selectedStop.market)
		}

		onCreatePackingListTransactions({
			actualBoxCount: actualBoxCount,
			marketObj: marketObj,
			selectedStop: selectedStop,
			containerActuals: containerActuals,
			notes: notes,
			imageURL: imageURL,
		})

		handleOpenPopUp(false)
	}

	const handleIncomplete = () => {
		setFormError('')
		if (notes && notes !== '') {
			const updatePayload = {
				date: selectedStop.date,
				market: selectedStop.market,
				stopId: selectedStop.stopId,
				status: 'incomplete',
				driverNote: notes,
				image: imageURL,
			}
			onUpdateStop(updatePayload)
			onCreateAlert({
				channel: '#eng-testing',
				text:
					'Packing List stop marked incomplete: ' +
					JSON.stringify(updatePayload),
			})
			handleOpenPopUp(false)
		} else {
			setFormError('Note is required for incompleted stop')
		}
	}

	const decompressImage = () => {
		const compressedData = stopImage.split('data:image/jpeg;base64,')[1]
		const umcompressedData = LZString.decompressFromBase64(compressedData)
		return `data:image/jpeg;base64,${umcompressedData}`
	}

	const handleFileUpload = async (files) => {
		if (files.length > 0) {
			const file = files[0]
			const storageRef = ref(storage, 'packing_list/' + file.name)

			try {
				// console.log('Uploading file:', file.name)
				const uploadTask = await uploadBytes(storageRef, file)
				// console.log('File uploaded successfully:', uploadTask)

				const downloadURL = await getDownloadURL(storageRef)
				// console.log('File uploaded to:', downloadURL)
				setImageURL(downloadURL)

				const reader = new FileReader()
				reader.onloadend = () => {
					const base64String = reader.result.split(',')[1]
					const compressedData =
						LZString.compressToBase64(base64String)
					setStopImage(`data:image/jpeg;base64,${compressedData}`)
				}

				reader.readAsDataURL(file)
			} catch (error) {
				console.error(
					'Error uploading file to Firebase Storage:',
					error
				)
				console.error('Error details:', error.details)
			}
		}
	}

	return (
		<>
			{openPopUp && (
				<>
					<div className='justify-center flex w-full bg-white fixed inset-0 z-50 shadow-xl overflow-x-hidden sm:bg-gray-800 sm:bg-opacity-25 sm:backdrop-filter sm:backdrop-blur-sm sm:py-10'>
						<div
							ref={impactRef}
							className='sm:h-fit h-full min-h-[600px] my-auto border-0 shadow-xl relative w-full overflow-y-auto sm:bg-white sm:w-[500px] sm:rounded-xl z-50'
						>
							<div className='relative flex flex-col w-full bg-white outline-none focus:outline-none'>
								<div className='relative flex flex-row w-full'>
									<div className='flex flex-col items-start justify-between px-4 py-4 border-b border-solid border-slate-200 rounded-t'>
										<h3 className='text-xl font-semibold mb-1'>
											{selectedStop.name}
										</h3>
										<p className='text-slate-500 text-sm leading-relaxed'>
											{selectedStop.address}{' '}
										</p>
										<p className='text-sm leading-relaxed font-bold text-red-400 mb-1'>
											{selectedStop.stopType === 'pick'
												? 'Collection'
												: 'Drop Off'}{' '}
										</p>
										<p className='text-slate-500 text-sm leading-relaxed'>
											{selectedStop.note
												? `Note: ${selectedStop.note}`
												: ''}
										</p>
									</div>

									<div className='absolute right-3 top-3'>
										<MdClear
											className='text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl'
											onClick={handleClosePopUp}
										/>
									</div>
								</div>
								<>
									<div className='bg-gray-100 w-full pb-6 mb-3 px-3'>
										<div className='flex flex-col mb-3'>
											<div className='flex flex-col'>
												<div className='flex flex-row my-4 p-3 items-center border-2 border-gray-300'>
													<div className='mr-4 hidden sm:block'>
														<img
															src={`/images/HomepageHeroContainers.png`}
															alt='Total Qty'
															className='w-[100px] h-[100px] object-scale-down'
														/>
													</div>
													<div className='text-lg font-bold w-[250px] mr-2'>
														Total # of Containers:
													</div>
													<div className='flex flex-row bg-gray-100 rounded-[5px] border-2 items-center'>
														<div className='bg-gray-100 text-2xl mx-1 border-2 text-center border-gray-300 w-[100px]'>
															{binBoxCount
																? binBoxCount
																: selectedStop.boxCount ||
																  0}
														</div>
														{showEdit && (
															<input
																type='number'
																className='bg-white text-2xl mx-1 border-2 text-center border-gray-300 w-[100px]'
																value={
																	totalQty ||
																	0
																}
																onChange={(
																	e
																) => {
																	setTotalQty(
																		e.target
																			.value
																	)
																	setFormError(
																		''
																	)
																	// onResetSuccess()
																}}
															/>
														)}
													</div>
												</div>
												{containers &&
												containers.length > 0 ? (
													<div>
														{containers
															.filter(
																(container) =>
																	(!viewAll &&
																		Object.keys(
																			selectedStop.products
																		).includes(
																			container.containerId
																		) &&
																		selectedStop
																			.products[
																			container
																				.containerId
																		] >
																			0) ||
																	viewAll
															)
															.map(
																(container) => (
																	<div
																		className='flex flex-row mb-4 items-center sm:ml-[20px]'
																		key={
																			container.containerId
																		}
																	>
																		<div className='mr-4 hidden sm:block'>
																			<img
																				src={`/images/containers/${container.containerId}.png`}
																				alt={
																					container.containerId
																				}
																				className='w-[100px] h-[100px] object-scale-down'
																			/>
																		</div>
																		<div className='text-lg font-bold mr-2 w-[250px]'>
																			{
																				container.description
																			}
																			:
																		</div>
																		<div className='flex flex-row bg-gray-100 rounded-[5px] border-2 items-center'>
																			<div className='bg-gray-100 text-2xl mx-1 border-2 text-center border-gray-300 w-[100px]'>
																				{selectedStop
																					.products[
																					container
																						.containerId
																				] ||
																					0}
																			</div>
																			{showEdit && (
																				<input
																					type='number'
																					className='bg-white text-2xl mx-1 border-2 text-center border-gray-300 w-[100px]'
																					value={
																						containerActuals[
																							container
																								.containerId
																						] ||
																						0
																					}
																					onChange={(
																						e
																					) => {
																						handleQuantityChange(
																							container.containerId,
																							e
																								.target
																								.value
																						)
																						setFormError(
																							''
																						)
																						// onResetSuccess()
																					}}
																				/>
																			)}
																		</div>
																	</div>
																)
															)}
														<div>
															<Button
																className='mt-6 w-[300px] flex justify-center items-center mx-auto'
																text={
																	viewAll
																		? 'View Less'
																		: 'View All'
																}
																size='xs'
																color='green-outlined'
																onClick={
																	handleViewAll
																}
															/>
														</div>
													</div>
												) : (
													<>
														<div>
															No container qtys
															assigned to this
															stop
														</div>
														<div>
															<Button
																className='mt-6 w-[300px] flex justify-center items-center mx-auto'
																text={
																	viewAll
																		? 'View Less'
																		: 'View All'
																}
																color='green-outlined'
																size='xs'
																onClick={
																	handleViewAll
																}
															/>
														</div>
													</>
												)}
											</div>
										</div>
									</div>

									<div className='mx-4 mb-4'>
										<TextArea
											name='Notes'
											label={
												<span className=''>
													Driver Notes:
												</span>
											}
											placeholder='Include any notes here'
											className='!h-20 !mt-1'
											onChange={(e) => {
												setFormError('')
												setNotes(e)
											}}
										/>
									</div>

									{selectedStop.stopType === 'pick' && (
										<div className='flex flex-col w-full items-center container rounded-xl justify-center mx-auto'>
											<div className='flex flex-col justify-between h-full w-full p-2 z-10 bg-white text-center'>
												<div className='flex flex-col w-full items-center my-2 mx-auto justify-between'>
													<div className='w-full mt-3 center-self'>
														<Button
															text='Add Photo'
															size='sm lg:base'
															// icon={<FaCloudUploadAlt/>}
															className='px-3 py-2'
															onClick={() => {
																fileInputRef.current.click()
															}}
														/>
														<input
															type='file'
															accept='image/*'
															onChange={(e) =>
																handleFileUpload(
																	e.target
																		.files
																)
															}
															style={{
																display: 'none',
															}}
															ref={fileInputRef}
														/>
														<div className='mx-3'>
															Make sure all of the
															containers are
															visible
														</div>
													</div>
												</div>
											</div>

											<div className='w-[90%] h-full flex flex-col items-center justify-center'>
												{stopImage && (
													<img
														className='w-full h-full object-cover mb-3'
														src={decompressImage(
															stopImage
														)}
													/>
												)}
											</div>
										</div>
									)}

									<div className='mx-4 mb-4'>
										<Button
											className='mx-2 w-[200px] flex justify-center items-center mx-auto'
											text={
												showEdit
													? 'Hide Edit'
													: 'Enter Actuals'
											}
											color='green-outlined'
											size='xs'
											onClick={handleShowEdit}
										/>
									</div>

									{formError !== '' && (
										<div className='font-bold text-red-400 my-1 mx-4 text-center'>
											{formError}
										</div>
									)}

									<div className='mx-4 flex flex-row justify-around sm:mb-4'>
										<Button
											className='mt-6 mx-2 px-2 max-w-[200px] flex justify-center items-center mx-auto'
											color='red'
											text='Not Completed'
											size='sm'
											onClick={handleIncomplete}
										/>
										<Button
											className='mt-6 mx-2 px-2 min-w-[160px] max-w-[200px] flex justify-center items-center mx-auto'
											text='Completed'
											size='sm'
											onClick={handleComplete}
										/>
									</div>

									{loadingStops && (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									)}

									<div
										className='text-xs underline text-red-400 mb-[100px] sm:mb-3 mt-5 text-center'
										onClick={() => {
											onDeleteStop(selectedStop)
											onCreateAlert({
												channel: '#eng-testing',
												text:
													'Packing List stop deleted: ' +
													JSON.stringify(
														selectedStop
													),
											})
											handleOpenPopUp(false)
										}}
									>
										Delete Stop
									</div>
								</>
							</div>
						</div>
					</div>
					<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
				</>
			)}
		</>
	)
}

const mapStateToProps = ({
	Nodes,
	User,
	Admin,
	Containers,
	Logistics,
	Dropoffs,
	TransactionsCreate,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	creatingDropoff: Dropoffs.creatingDropoff,
	dropoffRequested: Dropoffs.dropoffRequested,
	dropoffError: Dropoffs.dropoffError,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	stops: Logistics.stops,
	loadingStops: Logistics.loadingStops,
	logisticsStops: Logistics.stops,
	stopsError: Logistics.error,
	stopsSuccess: Logistics.success,
	transactionResult: TransactionsCreate.transactionResult,
	creatingTransaction: TransactionsCreate.creating,
	marketObj: Logistics.marketObj,
	packingListTransactions: Logistics.transactions,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onCreateGentlyDropoff: (dropoffPayload) =>
		dispatch(createGentlyDropoff(dropoffPayload)),
	onCreateGentlyDropoffNoNode: (dropoffPayload) =>
		dispatch(createGentlyDropoffNoNode(dropoffPayload)),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onCreateStop: (payload) => dispatch(createStop(payload)),
	onUpdateStop: (payload) => dispatch(updateStop(payload)),
	onDeleteStop: (payload) => dispatch(deleteStop(payload)),
	onGetAllStops: (payload) => dispatch(getAllLogistics(payload)),
	onCreateOrUpdateTransaction: (transactionPayload) =>
		dispatch(createOrUpdateTransaction(transactionPayload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onCreateAlert: (payload) => dispatch(logisticsAlert(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
	onGetMarketObj: (payload) => dispatch(getMarketObj(payload)),
	onCreatePackingListTransactions: (payload) =>
		dispatch(createPackingListTransactions(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PackingListPopUp)
