import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { getUser, getAppInfo } from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const AdminAnalytics = (state) => {
	const { user, onGetUser, onGetAppInfo } = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	useEffect(() => {
		if (user && user.phone) {
			// console.log('stripeId', user.stripeId)
			// onGetPaymentMethods(user.stripeId)
		}
	}, [user])

	useEffect(() => {
		onGetAppInfo()
	}, [])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-11 mt-14 text-center'>
					Analytics Panel
				</h1>
				<div
					onClick={() => {
						navigate('/admin/analytics/orders')
					}}
					className='mb-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
				>
					<h1 className='font-header text-2xl mt-6 ml-10'>
						Order Analytics
					</h1>
					<p className='font-header text-base mt-3 mx-10 mb-10'>
						See order counts by different metrics
					</p>
				</div>
				<div
					onClick={() => {
						navigate('/admin/analytics/environment')
					}}
					className='mb-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
				>
					<h1 className='font-header text-2xl mt-6 ml-10'>
						Environmental Analytics
					</h1>
					<p className='font-header text-base mt-3 mx-10 mb-10'>
						See environmental impact
					</p>
				</div>
				{/*<div*/}
				{/*    onClick={()=>{*/}
				{/*        navigate('/admin/analytics/customers')*/}
				{/*    }}*/}
				{/*    className="mb-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer">*/}
				{/*    <h1 className="font-header text-2xl mt-6 ml-10">Customer Analytics</h1>*/}
				{/*    <p className="font-header text-base mt-3 mx-10 mb-10">View customer box counts, order history, return history and more</p>*/}
				{/*</div>*/}
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, AppInfo }) => ({
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminAnalytics)
