import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodeBySlug,
	getAppInfo,
	getNodeByAdminCodeOrSlug,
	getTransactions,
	getAnalyticsTransactions,
	getNodeTransactions,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import EnvironmentalImpactCard from '../../components/UserProfile/EnvironmentalImpactCard'

const RestaurantImpact = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		transactions,
		transactionsCount,
		loadingTransactions,
		onGetTransactions,
		onGetAppInfo,
		appInfo,
		onGetNodeBySlug,
		node,
	} = state

	useEffect(() => {
		onGetUser()
		onGetAppInfo()
	}, [onGetUser])

	const paginationLimit = 1000000
	const bounceLoaderColor = '#507f74'
	const [selectedNode, setSelectedNode] = useState('')
	const [startDate, setStartDate] = useState(new Date(2022, 9, 1))
	const [endDate, setEndDate] = useState(new Date())
	const [totalBoxCount, setTotalBoxCount] = useState(0)
	let navigate = useNavigate()

	const getTransactions = (page) => {
		if (selectedNode !== '') {
			let filter = {
				type: 'boxesOut',
				timestamp: { $gte: startDate, $lte: endDate },
				$or: [{ node: selectedNode }, { parentSlug: selectedNode }],
			}

			onGetTransactions({
				filter: filter,
				options: {
					sort: {
						timestamp: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const transactionPaginate = () => {
		getTransactions(1)
	}

	useEffect(() => {
		transactionPaginate()
	}, [selectedNode, startDate, endDate])

	useEffect(() => {
		const sumBoxCount = transactions.reduce(
			(a, b) => a + (b['boxCount'] || 0),
			0
		)
		setTotalBoxCount(sumBoxCount)
	}, [transactions])

	useEffect(() => {
		if (user) {
			setSelectedNode(user.restaurantAdmin)
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [user])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/restaurant-admin/home')
					}}
				>
					&#8592; Go to Dashboard Home
				</div>
				<div className='flex flex-col max-w-[1200px] justify-center my-10 overflow-x-auto mx-auto'>
					<div className='mx-auto'>
						<div className='font-header text-2xl text-green-600'>
							Your Environmental Impact
						</div>
						{loadingTransactions && (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}

						<div className='mt-4'>Select Start Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
							/>
						</div>
						<div className='mt-4'>Select End Date</div>
						<div className='mb-2 border border-gray-400'>
							<DatePicker
								selected={endDate}
								onChange={(date) => setEndDate(date)}
							/>
						</div>

						{transactionsCount > 0 && node && (
							<div className='text-lg md:text-2xl mb-10 mx-auto text-center flex-col'>
								<div className=''>
									So far, {node.name} has prevented the use
									of:
								</div>
								<div className='text-2xl md:text-4xl font-header font-bold'>
									{transactionsCount}{' '}
								</div>
								<div> single-use takeout containers!</div>
							</div>
						)}

						<EnvironmentalImpactCard
							packagingPrevented={transactionsCount}
							environmentalImpact={appInfo.environmentalImpact}
						/>
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Transactions, Nodes, User, AppInfo }) => ({
	transactions: Transactions.nodeTransactions,
	transactionsCount: Transactions.totalBoxCount,
	loadingTransactions: Transactions.loadingTransactions,
	node: Nodes.restaurantAdminNode,
	nodeError: Nodes.nodeError,
	loadingNode: Nodes.loadingNode,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	appInfo: AppInfo.appInfo,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetTransactions: (payload) => dispatch(getAnalyticsTransactions(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantImpact)
