import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAllLeads,
	finalizeNode,
	updateLead,
	updateLeadSuccess,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const LeadsDashboard = (state) => {
	const {
		user,
		onGetUser,
		leads,
		loadingLeads,
		leadsError,
		leadsCount,
		onGetLeads,
		onFinalizeNode,
		onUpdateLead,
		finalizedNode,
		loadingFinalizeNode,
		finalizeNodeError,
		updatingLead,
		updatedLead,
	} = state

	const paginationLimit = 20
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [sortedLeads, setSortedLeads] = useState({})

	const pageDescriptions = {
		1: 'Account Creation',
		2: 'Location Info',
		3: 'CC Form & Promos',
		4: 'Docusign',
	}

	const getLeads = (page) => {
		if (user) {
			onGetLeads({
				filter: filter,
				options: {
					sort: {
						lastUpdated: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const leadsPaginate = () => {
		getLeads(page)
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		leadsPaginate()
	}, [filter, page, user, finalizedNode, updatedLead])

	useEffect(() => {
		if (leads) {
			const filteredLeads = leads.filter((lead) => !lead.test)
			const sortedLeadsCopy = [...filteredLeads].sort(
				(a, b) => b.lastUpdated - a.lastUpdated
			)
			setSortedLeads(sortedLeadsCopy)
		}
	}, [leads])

	const handleMarkCompleted = async (lead) => {
		onUpdateLead({
			slug: lead.slug,
			signUpComplete: true,
			lastUpdated: new Date(),
		})
		onFinalizeNode({ adminPinCode: lead.adminPinCode })
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		if (leads) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div className='px-2'>
						<div className='flex container flex-col justify-center items-center text-center w-full mb-4 mx-auto'>
							<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
								Leads Dashboard
							</h1>

							<div className='w-4/5 max-w-[600px] mb-4'>
								<Input
									placeholder='Search leads...'
									className=''
									name='searchInput'
									id='searchInput'
									value={
										filter?.['$or']?.[0]?.restaurantName?.[
											'$regex'
										]
									}
									onChange={(e) => {
										setFilter({
											$or: [
												{
													restaurantName: {
														$regex: e.target.value,
														$options: 'i',
													},
												},
												{
													slug: {
														$regex: e.target.value,
														$options: 'i',
													},
												},
											],
										})
									}}
								/>
							</div>
						</div>

						{updatingLead ||
							(loadingFinalizeNode && (
								<div className='flex items-center w-full my-10'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							))}

						<div className='my-10 max-w-[1600px] overflow-auto scrollbar flex flex-col justify-center align-center mx-auto'>
							<div className='overflow-x-auto'>
								<table className='w-full border-collapse border border-gray-300'>
									<thead>
										<tr className='bg-gray-100'>
											<th className='border border-gray-300 py-2 px-4'>
												Restaurant Name
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Address
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Contact Name
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Phone
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Email
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Status
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Last Page Completed
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Ambsdr
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Last Updated
											</th>
										</tr>
									</thead>
									<tbody>
										{sortedLeads &&
										sortedLeads.length > 0 ? (
											sortedLeads.map((lead) => (
												<tr
													key={lead._id}
													className={`${
														lead.signUpComplete ===
														true
															? 'bg-green-100'
															: 'bg-white'
													} h-12 overflow-hidden`}
													onClick={() => {
														navigate(
															`/admin/leads/${lead.slug}`
														)
													}}
												>
													<td className='border border-gray-300 py-2 px-4'>
														{lead.restaurantName}
													</td>
													<td className='border border-gray-300 py-2 px-4'>
														{lead.fullAddress}
													</td>
													<td className='border border-gray-300 py-2 px-4'>
														{lead.firstName}{' '}
														{lead.lastName}
													</td>
													<td className='border border-gray-300 py-2 px-4'>
														{lead.contactPhone}
													</td>
													<td className='border border-gray-300 py-2 px-4'>
														{lead.email}
													</td>
													<td className='border border-gray-300 py-2 px-4 text-center'>
														{lead.signUpComplete ? (
															'Completed'
														) : (
															<div className='flex flex-col'>
																<div>
																	Incomplete
																</div>
																{lead.lastPage >
																2 ? (
																	<Button
																		className='w-fit'
																		size='xs'
																		onClick={() => {
																			handleMarkCompleted(
																				lead
																			)
																		}}
																		text='Mark completed'
																	/>
																) : (
																	<div></div>
																)}
															</div>
														)}
													</td>
													<td className='border border-gray-300 py-2 px-4'>
														{lead.lastPage}
														{': '}
														{
															pageDescriptions[
																lead.lastPage
															]
														}
													</td>
													<td className='border border-gray-300 py-2 px-4'>
														{lead.dzAmbassador
															? leads.dzAmbassador
															: ''}
													</td>
													<td className='border border-gray-300 py-2 px-4'>
														{new Date(
															lead.lastUpdated
														)
															.toString()
															.slice(0, 15)}
													</td>
												</tr>
											))
										) : (
											<div className='text-center'>
												No results found
											</div>
										)}
									</tbody>
								</table>
								<div className='flex flex-row'>
									{page > 1 ? (
										<div
											className='mx-auto my-auto underline font-bold mr-6 cursor-pointer w-full flex justify-start items-center'
											onClick={() => {
												if (page >= 2) {
													setPage(page - 1)
												}
											}}
										>
											Previous {paginationLimit} leads
										</div>
									) : (
										<div
											className='mx-auto my-auto w-full flex justify-start items-center'
											style={{ visibility: 'hidden' }}
										>
											Previous {paginationLimit} leads
										</div>
									)}
									{leadsCount ? (
										<div
											className='mx-auto my-auto w-full flex justify-end underline font-bold cursor-pointer items-center'
											onClick={() => {
												if (
													page <
													leadsCount / paginationLimit
												) {
													setPage(page + 1)
												}
											}}
										>
											Next {paginationLimit} leads
										</div>
									) : (
										<div
											className='mx-auto my-auto w-full flex justify-end items-center'
											style={{ visibility: 'hidden' }}
										>
											Next {paginationLimit} leads
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Leads }) => ({
	leads: Leads.allLeads,
	loadingLeads: Leads.loading,
	leadsError: Leads.error,
	user: User.user,
	loadingUser: User.loading,
	leadsCount: Leads.leadsCount,
	lead: Leads.lead,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	updatedLead: Leads.updatedLead,
	updatingLead: Leads.updatingLead,
	updateLeadError: Leads.updateLeadError,
	finalizedNode: Leads.finalizedNode,
	loadingFinalizeNode: Leads.loadingFinalizeNode,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetLeads: (payload) => dispatch(getAllLeads(payload)),
	onUpdateLead: (payload) => dispatch(updateLead(payload)),
	onFinalizeNode: (payload) => dispatch(finalizeNode(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeadsDashboard)
