import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

//Account Redux states
import { REGISTER_USER } from './actionTypes'
import { registerUserSuccessful, registerUserFailed } from './actions'
import { register } from '../../../model/auth'

function* registerUser({ payload: form }) {
	try {
		const response = yield call(register, form)
		yield put(registerUserSuccessful(response))
	} catch (error) {
		yield put(registerUserFailed(error))
	}
}

export function* watchUserRegister() {
	yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
	yield all([fork(watchUserRegister)])
}

export default accountSaga
