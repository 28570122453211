import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	createGentlyDropoff,
	createGentlyDropoffNoNode,
	getContainers,
	getMarket,
	createGentlyPickup,
	createGentlyPickupNoNode,
	resetPickups,
} from '../../store/actions'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	SelectGroup,
	TextArea,
} from '../Elements'
import { FormGroup } from '../Elements/index'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../model/nodes'
import { MdClear } from 'react-icons/md'

const DropoffWidgetGently = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		creatingDropoff,
		dropoffRequested,
		dropoffError,
		onCreateGentlyDropoff,
		onCreateGentlyDropoffNoNode,
		containers,
		onGetContainers,
		containerMarket,
		onGetMarket,
		creatingPickup,
		pickupRequested,
		pickupError,
		onCreateGentlyPickup,
		onCreateGentlyPickupNoNode,
		onResetPickups,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [quantity, setQuantity] = useState({})
	const [dropoffDate, setDropoffDate] = useState('')
	const [dropoffNodes, setDropoffNodes] = useState([])
	const [dropoffInstructions, setDropoffInstructions] = useState('')
	const [showNodeChoicesDropOff, setShowNodeChoicesDropOff] = useState(false)
	const [formError, setFormError] = useState('')
	const [partner, setPartner] = useState('gently')
	const [gentlyType, setGentlyType] = useState('')
	const [qtyType, setQtyType] = useState('')
	const [products, setProducts] = useState([])
	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [asapChecked, setAsapChecked] = useState(false)
	const [pickupChecked, setPickupChecked] = useState(false)
	const [locationName, setLocationName] = useState('')
	const [locationNamesObj, setLocationNamesObj] = useState({})
	const [filteredContainers, setFilteredContainers] = useState([])

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	const handleAddressChange = async (newAddress) => {
		if (newAddress.target) {
			setSearchedAddress(newAddress.target.value)
			geocodeAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
			geocodeAddress(newAddress.formatted_address)
		}
	}

	const handleAddressSubmit = () => {
		setFormError('')
		setAddressError(false)
		if (locationName === '') {
			setFormError('Specify the dropoff location name')
		} else {
			setDropoffNodes((prevNodes) => {
				let tempArray = [...prevNodes]
				if (geoCodedAddress) {
					tempArray.push(geoCodedAddress)
				}
				return tempArray
			})
			setLocationNamesObj((prevNames) => {
				let tempObj = { ...prevNames }
				if (geoCodedAddress) {
					tempObj[geoCodedAddress] = locationName
				}
				return tempObj
			})
		}
	}

	useEffect(() => {
		onGetUser()
		onResetPickups()
	}, [onGetUser])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		if (geoCodedLat && geoCodedLng) {
			onGetMarket({ coords: [geoCodedLat, geoCodedLng] })
		} else if (dropoffNodes && dropoffNodes[0]) {
			const coordinates = dropoffNodes[0].coordinates
			if (
				coordinates &&
				coordinates.length === 2 &&
				coordinates[0] !== undefined &&
				coordinates[1] !== undefined
			) {
				onGetMarket({ coords: [coordinates[1], coordinates[0]] })
			} else {
				geocodeAddress(
					`${dropoffNodes[0].address}, ${dropoffNodes[0].city}, ${dropoffNodes[0].state}, ${dropoffNodes[0].zip}`
				)
			}
		}
	}, [dropoffNodes, dropoffNodes[0], geoCodedLat, geoCodedLng])

	useEffect(() => {
		onGetContainers([
			{
				enabled: true,
			},
		])
	}, [onGetContainers])

	useEffect(() => {
		if (containers && containerMarket) {
			const currentContainers = []
			for (const container of containers) {
				if (container.markets.includes(containerMarket)) {
					currentContainers.push(container)
				}
			}
			setFilteredContainers(currentContainers)
		} else if (!containers) {
			if (
				(dropoffNodes && dropoffNodes[0]) ||
				(geoCodedLat && geoCodedLng)
			) {
				if (dropoffNodes[0].pizza || (geoCodedLat && geoCodedLng)) {
					onGetContainers([
						{
							enabled: true,
						},
					])
				} else {
					onGetContainers([
						{
							enabled: true,
						},
						{
							hide: { $ne: true },
						},
					])
				}
			}
		}
	}, [containerMarket, containers])

	useEffect(() => {
		// console.log(filter)
		onGetNodes([filter])
	}, [filter])

	const handleSubmitNodeDropoff = () => {
		const currentDate = new Date()
		currentDate.setDate(currentDate.getDate())
		const year = currentDate.getFullYear()
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const day = String(currentDate.getDate()).padStart(2, '0')
		const formattedDate = `${year}-${month}-${day}`

		const userObj = {
			phone: user.phone,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
		}

		if (dropoffNodes.length === 0) {
			setFormError('Assign at least one dropoff location')
		} else if (dropoffDate && dropoffDate <= formattedDate) {
			setFormError('Specify a valid future dropoff date or select ASAP')
		} else if (Object.keys(products).length < dropoffNodes.length) {
			setFormError('Specify a quantity of containers per stop')
		} else {
			setFormError('')
			const dropoffPayload = {
				dropoffNodes: dropoffNodes,
				note: dropoffInstructions,
				products: products,
				date: dropoffDate,
				user: userObj,
			}

			console.log(dropoffPayload)
			if (pickupChecked) {
				onCreateGentlyPickup(dropoffPayload)
			} else {
				onCreateGentlyDropoff(dropoffPayload)
			}
		}
	}

	const handleSubmitAddressDropoff = () => {
		const currentDate = new Date()
		currentDate.setDate(currentDate.getDate())
		const year = currentDate.getFullYear()
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const day = String(currentDate.getDate()).padStart(2, '0')
		const formattedDate = `${year}-${month}-${day}`

		const userObj = {
			phone: user.phone,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
		}

		if (dropoffNodes.length === 0) {
			setFormError('Assign at least one dropoff location')
		} else if (dropoffDate && dropoffDate <= formattedDate) {
			setFormError('Specify a valid future dropoff date or select ASAP')
		} else if (Object.keys(products).length < dropoffNodes.length) {
			setFormError('Specify a quantity of containers per stop')
		} else {
			setFormError('')
			const dropoffPayload = {
				dropoffNodes: dropoffNodes,
				note: dropoffInstructions,
				products: products,
				date: dropoffDate,
				user: userObj,
			}

			// console.log(dropoffPayload)
			if (pickupChecked) {
				onCreateGentlyPickupNoNode(dropoffPayload)
			} else {
				onCreateGentlyDropoffNoNode(dropoffPayload)
			}
		}
	}

	const removeFromDropoff = (nodeSlug) => {
		const tempObj = quantity
		delete tempObj[nodeSlug]
		setQuantity(tempObj)

		var tempArray = dropoffNodes
		tempArray = tempArray.filter((obj) => obj.slug !== nodeSlug)
		setDropoffNodes(tempArray)
	}

	const removeAddressFromDropoff = (address) => {
		const tempObj = products
		const newObj = {}
		for (const index in tempObj) {
			if (
				tempObj.hasOwnProperty(index) &&
				tempObj[index].node !== address
			) {
				newObj[index] = tempObj[index]
			}
		}
		setProducts(newObj)

		let tempArray = dropoffNodes
		tempArray = tempArray.filter((obj) => obj !== address)
		setDropoffNodes(tempArray)

		const tempNamesObj = locationNamesObj
		const newNamesObj = {}
		for (const index in tempNamesObj) {
			if (tempNamesObj.hasOwnProperty(index) && index !== address) {
				newNamesObj[index] = tempNamesObj[index]
			}
		}
		setLocationNamesObj(newNamesObj)
	}

	const updateProductNode = (e, containerId, description, nodeSlug) => {
		const updatedProducts = [...products]
		const existingProductIndex = updatedProducts.findIndex(
			(product) => product.id === containerId && product.node === nodeSlug
		)
		let inputValue = parseInt(e.target.value, 10)
		if (inputValue < 0) {
			inputValue = '0'
		}
		if (existingProductIndex !== -1) {
			if (qtyType === 'cases') {
				updatedProducts[existingProductIndex] = {
					...updatedProducts[existingProductIndex],
					name: 'CASES of ' + description,
					quantity: inputValue,
					node: nodeSlug,
				}
			} else if (qtyType === 'containers') {
				updatedProducts[existingProductIndex] = {
					...updatedProducts[existingProductIndex],
					name: 'INDIVIDUAL CONTAINERS of ' + description,
					quantity: inputValue,
					node: nodeSlug,
				}
			}
		} else {
			if (qtyType === 'cases') {
				updatedProducts.push({
					id: containerId,
					name: 'CASES of ' + description,
					quantity: inputValue,
					node: nodeSlug,
				})
			} else if (qtyType === 'containers') {
				updatedProducts.push({
					id: containerId,
					name: 'INDIVIDUAL CONTAINERS of ' + description,
					quantity: inputValue,
					node: nodeSlug,
				})
			}
		}

		setProducts(updatedProducts)
	}

	const updateProductAddress = (e, containerId, description, address) => {
		let updatedProducts = []
		if (products && products.length) {
			updatedProducts = [...products]
		}
		const existingProductIndex = updatedProducts.findIndex(
			(product) => product.id === containerId && product.node === address
		)
		let inputValue = parseInt(e.target.value, 10)
		if (inputValue < 0) {
			inputValue = '0'
		}
		if (existingProductIndex !== -1) {
			if (qtyType === 'cases') {
				updatedProducts[existingProductIndex] = {
					...updatedProducts[existingProductIndex],
					// name: 'CASES of ' + description,
					quantity: inputValue,
					node: address,
					name: locationNamesObj[address],
				}
			} else if (qtyType === 'containers') {
				updatedProducts[existingProductIndex] = {
					...updatedProducts[existingProductIndex],
					// name: 'INDIVIDUAL CONTAINERS of ' + description,
					quantity: inputValue,
					node: address,
					name: locationNamesObj[address],
				}
			}
		} else {
			if (qtyType === 'cases') {
				updatedProducts.push({
					id: containerId,
					// name: 'CASES of ' + description,
					quantity: inputValue,
					node: address,
					name: locationNamesObj[address],
				})
			} else if (qtyType === 'containers') {
				updatedProducts.push({
					id: containerId,
					// name: 'INDIVIDUAL CONTAINERS of ' + description,
					quantity: inputValue,
					node: address,
					name: locationNamesObj[address],
				})
			}
		}

		setProducts(updatedProducts)
	}

	const handleAsapCheckboxChange = (e) => {
		const checked = e.target.checked
		setAsapChecked(checked)

		if (checked) {
			setDropoffDate(null)
		}
	}

	const handleDateInputChange = (e) => {
		const selectedDate = e.target.value

		if (selectedDate) {
			setDropoffDate(selectedDate)
			setAsapChecked(false)
		} else {
			setDropoffDate(null)
		}
	}

	const handlePickupCheckboxChange = (e) => {
		const checked = e.target.checked
		setPickupChecked(checked)
	}

	// useEffect(() => {
	//     console.log("dropoffDate", dropoffDate)
	// }, [dropoffDate])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div className='px-12'>
				<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
					<h1 className='font-header text-green-600 text-4xl mb-7 mt-5 text-center'>
						Request a Dropoff
					</h1>
					{partner && (
						<div>
							<div className='w-full flex justify-center items-center'>
								<SelectGroup
									label=''
									placeholder='Select Dropoff Type...'
									className='h-12 mt-3 min-w-[300px]'
									onChange={(e) => {
										setGentlyType(e.target.value)
										setProducts([])
										setDropoffNodes([])
										setFormError('')
									}}
									options={[
										{ label: 'Node', value: 'node' },
										{ label: 'Address', value: 'address' },
									]}
								/>
							</div>
							<div className='w-full flex justify-center items-center mb-3'>
								<SelectGroup
									label=''
									placeholder='Select Quantity Type...'
									className='h-12 mt-3 min-w-[300px]'
									onChange={(e) => {
										setQtyType(e.target.value)
										setDropoffNodes([])
										setProducts([])
										setFormError('')
									}}
									options={[
										{ label: 'Cases', value: 'cases' },
										{
											label: 'Containers',
											value: 'containers',
										},
									]}
								/>
							</div>
							{gentlyType &&
							qtyType &&
							gentlyType === 'address' ? (
								<>
									<div
										className={`relative flex w-full items-center md:min-w-[500px] max-w-[720px] ${className} px-4`}
									>
										<FormGroup
											className='h-auto w-full items-center justify-around my-5'
											header
										>
											{loadingUser ? (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											) : (
												<>
													<Input
														placeholder='Enter dropoff restaurant/company name'
														className={`relative bg-${inputBackgroundColor} pl-8 !-mb-4`}
														value={locationName}
														onChange={(e) => {
															setLocationName(
																e.target.value
															)
														}}
													/>
													<Input
														placeholder='Enter dropoff address'
														className={`relative bg-${inputBackgroundColor} pl-8 !-mb-4`}
														error={addressError}
														autoCompleteAddress={
															true
														}
														value={searchedAddress}
														onChange={
															handleAddressChange
														}
													/>
												</>
											)}
											{searchedAddress !== '' && (
												<MdClear
													className={`font-semibold absolute left-2 ml-4 top-[83px] transform -translate-y-12 text-green-600 cursor-pointer`}
													onClick={() =>
														setSearchedAddress('')
													}
												/>
											)}
											<Button
												className='mb-6'
												text='Select Address'
												size='sm'
												onClick={handleAddressSubmit}
											/>
										</FormGroup>
									</div>
									<div className='bg-gray-100 w-full pb-6 mb-3 px-3'>
										<div className='mb-3'>
											<span className='font-bold text-sm '>
												Selected Dropoff Locations:
											</span>
										</div>
										{dropoffNodes.map((address, index) => {
											return (
												<div
													className='flex flex-col mb-3'
													key={index}
												>
													<div className='flex flex-row'>
														<div className='mt-1 font-bold text-green-600'>
															{address}:
														</div>
														<div
															className='ml-4 font-bold text-sm mt-1 cursor-pointer underline text-red-600'
															onClick={() => {
																removeAddressFromDropoff(
																	address
																)
															}}
														>
															Remove location
														</div>
													</div>
													<div className='flex flex-col ml-[20px]'>
														{filteredContainers.map(
															(container) => (
																<div
																	className='flex flex-row'
																	key={
																		container._id
																	}
																>
																	{/* <div className="mt-1">{container.containerId}:</div> */}
																	<div className='mt-1 mr-2'>
																		{
																			container.description
																		}
																		:
																	</div>
																	{qtyType &&
																	qtyType ===
																		'cases' ? (
																		<Input
																			className='w-full'
																			type='number'
																			step={
																				1
																			}
																			min={
																				0
																			}
																			placeholder='# of cases'
																			onChange={(
																				e
																			) => {
																				updateProductAddress(
																					e,
																					container.containerId,
																					container.description,
																					address
																				)
																			}}
																		/>
																	) : (
																		<Input
																			className='w-full'
																			type='number'
																			step={
																				1
																			}
																			min={
																				0
																			}
																			placeholder='# of containers'
																			onChange={(
																				e
																			) => {
																				updateProductAddress(
																					e,
																					container.containerId,
																					container.description,
																					address
																				)
																			}}
																		/>
																	)}
																</div>
															)
														)}
													</div>
												</div>
											)
										})}
									</div>
								</>
							) : gentlyType &&
							  qtyType &&
							  gentlyType === 'node' ? (
								<>
									<div className='text-sm font-bold mt-4 ml-3'>
										Search by Node:
									</div>
									<div className='w-4/5 max-w-[600px] mb-4'>
										<Input
											placeholder='Search and select dropoff locations'
											className=''
											name='searchInput'
											id='searchInput'
											onChange={(e) => {
												setShowNodeChoicesDropOff(true)
												setFilter({
													$or: [
														{
															name: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
														{
															slug: {
																$regex: e.target
																	.value,
																$options: 'i',
															},
														},
													],
												})
											}}
										/>
										{/* <MdClear
                                                className={`font-semibold absolute left-10 ml-4 top-[535px] transform -translate-y-12 text-green-600 cursor-pointer z-50`}
                                                onClick={() => setSearchedNode('')}
                                            /> */}
									</div>
									<div className='flex-col flex items-center'>
										{showNodeChoicesDropOff && (
											<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
												<div
													className='cursor-pointer text-green-600'
													onClick={() => {
														setShowNodeChoicesDropOff(
															false
														)
													}}
												>
													{' '}
													Close X
												</div>
												{nodes.map((node) => (
													<div
														className='text-sm cursor-pointer my-1 hover:bg-gray-100'
														key={node.slug}
														onClick={() => {
															var tempArray =
																dropoffNodes
															const found =
																tempArray.some(
																	(obj) =>
																		obj.slug ===
																		node.slug
																)
															if (found) {
															} else {
																tempArray.push(
																	node
																)
															}
															setDropoffNodes(
																tempArray
															)
															setShowNodeChoicesDropOff(
																false
															)
														}}
													>
														{node.name}
													</div>
												))}
											</div>
										)}
									</div>
									<div className='bg-gray-100 w-full pb-6 mb-3 px-3'>
										<div className='mb-3'>
											<span className='font-bold text-sm'>
												Selected Dropoff Locations:
											</span>
										</div>
										{dropoffNodes.map((node) => {
											return (
												<div
													className='flex flex-col'
													key={node.slug}
												>
													<div className='flex flex-row'>
														<div className='mt-1 font-bold text-green-600'>
															{node.name}:
														</div>
														<div
															className='ml-4 font-bold text-sm mt-1 cursor-pointer underline text-red-600'
															onClick={() => {
																removeFromDropoff(
																	node.slug
																)
															}}
														>
															Remove location
														</div>
													</div>
													<div className='flex flex-col ml-[20px]'>
														{filteredContainers.map(
															(container) => (
																<div
																	className='flex flex-row'
																	key={
																		container._id
																	}
																>
																	{/* <div className="mt-1">{container.containerId}:</div> */}
																	<div className='mt-1 mr-2'>
																		{
																			container.description
																		}
																		:
																	</div>
																	{qtyType &&
																	qtyType ===
																		'cases' ? (
																		<Input
																			className='w-full'
																			type='number'
																			step={
																				1
																			}
																			min={
																				0
																			}
																			placeholder='# of cases'
																			onChange={(
																				e
																			) => {
																				updateProductNode(
																					e,
																					container.containerId,
																					container.description,
																					node.slug
																				)
																			}}
																		/>
																	) : (
																		<Input
																			className='w-full'
																			type='number'
																			step={
																				1
																			}
																			min={
																				0
																			}
																			placeholder='# of containers'
																			onChange={(
																				e
																			) => {
																				updateProductNode(
																					e,
																					container.containerId,
																					container.description,
																					node.slug
																				)
																			}}
																		/>
																	)}
																</div>
															)
														)}
													</div>
												</div>
											)
										})}
									</div>
								</>
							) : (
								<div></div>
							)}
							{gentlyType !== '' && qtyType !== '' && (
								<>
									<div className='flex flex-row mb-3 ml-3'>
										<div className='flex flex-col'>
											<label className='font-bold text-sm'>
												Dropoff Date:
											</label>
											<input
												type='date'
												value={dropoffDate || ''}
												onChange={handleDateInputChange}
											/>
										</div>
										<div className='ml-6'>
											<CheckboxGroup
												label='ASAP'
												name='asap'
												className=''
												checked={asapChecked}
												onChange={
													handleAsapCheckboxChange
												}
											/>
										</div>
									</div>

									<TextArea
										name='Dropoff Instructions'
										label={
											<span className='ml-3'>
												Dropoff Instructions
											</span>
										}
										placeholder='Include any special delivery instructions here'
										className='!h-20 !mt-1'
										onChange={(e) => {
											setDropoffInstructions(e)
										}}
									/>

									<div className='my-3 ml-6 text-red-400 font-header border-2 border-red-400 p-3 rounded-lg'>
										<CheckboxGroup
											label='THIS REQUEST IS A PICKUP'
											name='pickup'
											className='text-red-400'
											checked={pickupChecked}
											onChange={
												handlePickupCheckboxChange
											}
										/>
									</div>
								</>
							)}
							{formError !== '' && (
								<div className='font-bold text-red-400 my-1'>
									{formError}
								</div>
							)}
							{dropoffError && (
								<div className='font-bold text-red-400 my-1'>
									{dropoffError.message
										? dropoffError.message
										: dropoffError}
								</div>
							)}
							{creatingDropoff || creatingPickup ? (
								<BounceLoaderCentered container='div' />
							) : gentlyType === 'address' ? (
								<Button
									className='mt-6 w-[300px] flex justify-center items-center mx-auto'
									onClick={handleSubmitAddressDropoff}
									text='Submit Address Dropoff'
								/>
							) : gentlyType === 'node' ? (
								<Button
									className='mt-6 w-[300px] flex justify-center items-center mx-auto'
									onClick={handleSubmitNodeDropoff}
									text='Submit Node Dropoff'
								/>
							) : gentlyType !== '' ? (
								<Button
									className='mt-6 w-[300px] flex justify-center items-center mx-auto'
									disabled
									color='disabled'
									text='Submit Dropoff'
								/>
							) : (
								<></>
							)}
							{dropoffRequested && dropoffError === null && (
								<div className='flex flex-col justify-center items-center'>
									<div className='mt-2 text-xl mb-6 text-green-600'>
										<span className='font-bold'>
											Successful!
										</span>
									</div>
									<Button
										className='mb-6'
										link='/admin/dropoff'
										text='View Dashboard'
									/>
								</div>
							)}
							{pickupRequested && pickupError === null && (
								<div className='flex flex-col justify-center items-center'>
									<div className='mt-2 text-xl mb-6 text-green-600'>
										<span className='font-bold'>
											Successful!
										</span>
									</div>
									<Button
										className='mb-6'
										link='/admin/dropoff'
										text='View Dashboard'
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	Dropoffs,
	Containers,
	Logistics,
	Pickups,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	creatingDropoff: Dropoffs.creatingDropoff,
	dropoffRequested: Dropoffs.dropoffRequested,
	dropoffError: Dropoffs.dropoffError,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	containerMarket: Logistics.market,
	creatingPickup: Pickups.creatingPickup,
	pickupRequested: Pickups.pickupRequested,
	pickupError: Pickups.pickupError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onCreateGentlyDropoff: (dropoffPayload) =>
		dispatch(createGentlyDropoff(dropoffPayload)),
	onCreateGentlyDropoffNoNode: (dropoffPayload) =>
		dispatch(createGentlyDropoffNoNode(dropoffPayload)),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onGetMarket: (payload) => dispatch(getMarket(payload)),
	onCreateGentlyPickup: (payload) => dispatch(createGentlyPickup(payload)),
	onCreateGentlyPickupNoNode: (payload) =>
		dispatch(createGentlyPickupNoNode(payload)),
	onResetPickups: () => dispatch(resetPickups()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropoffWidgetGently)
