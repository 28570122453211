import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState, useRef } from 'react'
import {
	Button,
	Input,
	RadioGroup,
	CheckboxGroup,
} from '../../../components/Elements'
import {
	getUser,
	getNodes,
	updateLead,
	getLead,
	checkPromoCodeValidity,
} from '../../../store/actions'
import { getNodeByAdminCodeOrSlug } from '../../../store/nodes/actions'
import { BounceLoader } from 'react-spinners'
import { Footer, Header } from '../../../components'
import SignUpCreditCardForm from './SignUpCreditCardForm'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { BounceLoaderCentered } from '../../../components/Elements'
import { FaClosedCaptioning } from 'react-icons/fa'

const SignUpPage3 = (state) => {
	const {
		user,
		onGetUser,
		onGetLead,
		onGetNodeBySlug,
		loadingNodes,
		loadingUser,
		loggedIn,
		loadingLead,
		leadError,
		updatedLead,
		updatingLead,
		updateLeadError,
		nodes,
		onUpdateLead,
		lead,
		node,
		paymentMethods,
		onCheckPromoCodeValidity,
		promoCodeDoc,
		loadingValidatePromoCode,
	} = state
	const isInitialRender = useRef(true)
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	const [incomplete, setIncomplete] = useState(false)
	const [payWithACH, setPayWithACH] = useState(false)
	const [payWithACHClicked, setPayWithACHClicked] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [billingEmail, setBillingEmail] = useState('')
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('')
	const [promoCode, setPromoCode] = useState('')
	const [promoCodeValidated, setPromoCodeValidated] = useState(false)
	const [promoCodeValidatedMsg, setPromoCodeValidatedMsg] = useState('')
	const [promoCodeError, setPromoCodeError] = useState('')
	const currentPage = 3
	const totalPages = 4
	const progressPercentage = (currentPage / totalPages) * 100

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			onGetLead({ slug: user.restaurantAdmin })
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [user])

	useEffect(() => {
		if (lead) {
			if (lead.signUpComplete) {
				navigate('/restaurant-admin/home')
			} else {
				if (lead.promoCodeSlug) {
					setPromoCodeValidated(true)
					setPromoCodeError('')
				}
				if (lead.promoCodeMsg) {
					setPromoCodeValidatedMsg(lead.promoCodeMsg)
				}
				if (node) {
					setBillingEmail(node.email)
				}
				if (node && node.defaultPaymentMethod) {
					setDefaultPaymentMethod(node.defaultPaymentMethod)
					setBillingEmail(node.email)
				} else if (lead.payWithACH) {
					setPayWithACH(true)
				}
			}
		}
	}, [lead, node])

	useEffect(() => {
		if (payWithACH) {
			setEmailError(false)
		}
	}, [payWithACH])

	useEffect(() => {
		if (
			paymentMethods &&
			paymentMethods.length > 0 &&
			user &&
			user.restaurantAdmin
		) {
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [paymentMethods])

	useEffect(() => {
		if (!isInitialRender.current && updatedLead && payWithACHClicked) {
			navigate('/restaurantpartners/sign-up/page-4')
		}
		// set isInitialRender to false after the first render
		isInitialRender.current = false
	}, [updatedLead])

	console.log('promo code', promoCodeDoc)

	useEffect(() => {
		if (promoCodeDoc) {
			if (promoCodeDoc.length > 0) {
				const msg =
					'Code validated! Enjoy ' + promoCodeDoc[0].description
				setPromoCodeValidatedMsg(msg)
				setPromoCodeValidated(true)
				setPromoCodeError('')
				const pg = node && node.defaultPaymentMethod ? 3 : 2
				onUpdateLead({
					slug: user.restaurantAdmin,
					lastPage: pg,
					lastUpdated: new Date(),
					promoCodeSlug: promoCodeDoc[0].codeTypeSlug,
					promoCodeMsg: msg,
				})
			} else {
				setPromoCodeError('Please enter a valid code.')
			}
		}
	}, [promoCodeDoc])

	const checkEmail = (e) => {
		setEmailError(false)
		setIncomplete(false)
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		const isValidEmail = emailRegex.test(e.target.value)
		setEmailError(!isValidEmail)
		if (isValidEmail) {
			setBillingEmail(e.target.value)
		}
	}

	const handleValidatePromoCode = async (e) => {
		if (!promoCode) {
			setPromoCodeError('Please enter a valid code.')
		} else {
			try {
				await onCheckPromoCodeValidity({
					promoCode: promoCode,
				})
			} catch (error) {
				console.error('Error validating promo code', error.message)
			}
		}
	}

	const handleSubmitCC = async (e) => {
		e.preventDefault()
		// validation checks for CC form
		if ((node && !node.defaultPaymentMethod) || (node && !node.email)) {
			console.log('126')
			setIncomplete(true)
			return
		}
		if (emailError) {
			console.log('131')
			return
		} else {
			navigate('/restaurantpartners/sign-up/page-4')
		}
	}

	const handleSubmitACH = async (e) => {
		e.preventDefault()

		setPayWithACHClicked(true)

		try {
			await onUpdateLead({
				slug: user.restaurantAdmin,
				lastPage: 3,
				defaultPaymentMethod: '',
				billingEmail: '',
				payWithACH: true,
				lastUpdated: new Date(),
			})
		} catch (error) {
			console.error('Lead update error:', error.message)
		}
	}

	if (user) {
		if (user.restaurantAdmin) {
			return (
				<div className='sm:bg-green-600'>
					<Header />
					<div
						className='underline font-bold text-white cursor-pointer mx-20 mt-4'
						onClick={() => {
							navigate('/restaurantpartners/sign-up/page-2')
						}}
					>
						&#8592; Go Back
					</div>
					<div className='flex flex-col sm:p-2 sm:my-4 lg:my-8 mx-auto sm:max-w-[1300px] sm:bg-white sm:shadow-light-grey sm:rounded-2xl w-full'>
						<div className='flex flex-col justify-center align-center mx-auto overlow-auto w-full sm:max-w-[1200px]'>
							{lead && lead.lastPage !== 1 ? (
								<div>
									<div className='w-full h-full sm:hidden'>
										<img
											src='/images/SignUpFlow/3of4.jpg'
											alt='Image Description'
											className='w-full h-full object-cover'
										/>
									</div>

									<div className='max-w-full mx-3'>
										<h1 className='font-header text-green-600 text-4xl mb-7 my-3 text-center'>
											Credit/Debit Card Authorization Form
										</h1>

										<div className='flex flex-col m-auto sm:mb-10'>
											<div className='bg-gray-200 h-4 rounded-full'>
												<div
													className='bg-green-600 h-full rounded-full'
													style={{
														width: `${progressPercentage}%`,
													}}
												></div>
												<div className='text-gray-600 text-sm pl-3'>
													PAGE {currentPage} OF{' '}
													{totalPages}
												</div>
											</div>
										</div>

										<div className='flex flex-row mb-6 w-full'>
											<div className='w-full h-full hidden sm:block sm:h-[600px] max-w-[50%]'>
												<img
													src='/images/SignUpFlow/3of4.jpg'
													alt='Image Description'
													className='w-full h-full object-cover object-center mb-[300px]'
												/>
											</div>

											<div className='sm:h-[700px] overflow-auto scrollbar flex m-auto'>
												<div className='flex flex-col m-auto px-8 pb-3 sm:h-full sm:my-auto sm:w-full'>
													<p className='font-bold mt-10 sm:mt-0 mb-4'>
														(You will not incur any
														credit card payment
														processing fees)
													</p>
													<p className='mx-auto mb-4 text-sm font-bold w-full'>
														Enter in your credit or
														debit card info below to
														set up and consent to
														automatic billing for
														monthly container usage.
														<br />
														There are no upfront
														costs. You will only be
														charged for what you
														actually use.
													</p>
													<Input
														type='email'
														label='Billing Email'
														name='email'
														defaultValue={
															lead.email
														}
														error={
															emailError ||
															(incomplete &&
																!billingEmail)
														}
														className='bg-white border-2 border-gray-300 mb-4'
														onChange={checkEmail}
													/>
													<div className='w-full flex flex-col mb-2'>
														<Elements
															stripe={
																stripePromise
															}
														>
															<SignUpCreditCardForm
																defaultPaymentMethod={
																	defaultPaymentMethod
																}
																setDefaultPaymentMethod={
																	setDefaultPaymentMethod
																}
																billingEmail={
																	billingEmail
																}
															/>
														</Elements>
													</div>
													{lead.promoCodeSlug &&
													lead.promoCodeMsg ? (
														<></>
													) : (
														<Input
															label='Enter promo code (if applicable)'
															name='promoCode'
															className='bg-white border-2 border-gray-300 mb-3'
															onChange={(e) => {
																setPromoCode(
																	e.target
																		.value
																)
															}}
														/>
													)}
													{promoCodeError && (
														<div className='text-sm font-bold text-red-400 mb-2'>
															{promoCodeError}
														</div>
													)}
													{promoCodeValidatedMsg && (
														<div className='text-sm font-bold text-green-600 mb-5'>
															{
																promoCodeValidatedMsg
															}
														</div>
													)}
													{!promoCodeValidated && (
														<Button
															className='mb-10 w-[100px]'
															size='sm'
															onClick={
																handleValidatePromoCode
															}
															text='Validate'
														/>
													)}
													{updatingLead ||
													loadingLead ? (
														<div>
															<BounceLoader
																className='m-auto'
																color={
																	bounceLoaderColor
																}
															></BounceLoader>
														</div>
													) : (
														<Button
															className='mb-10 w-[200px]'
															size='sm'
															onClick={
																handleSubmitCC
															}
															text='Save & Continue'
														/>
													)}
													{node &&
														!node.defaultPaymentMethod && (
															<CheckboxGroup
																label='Pay with ACH'
																name='ach'
																defaultChecked={
																	lead.payWithACH
																		? true
																		: false
																}
																onChange={() => {
																	var ach =
																		!payWithACH
																	setPayWithACH(
																		ach
																	)
																}}
															/>
														)}

													{node &&
														!node.defaultPaymentMethod &&
														payWithACH && (
															<div className='max-w-[500px] text-xs my-2 font-semibold'>
																You will be
																presented with
																an ACH form on
																the following
																page. ACH
																requires a
																manual
																verification
																with your bank.
																Once you fill
																out the ACH
																form, a small
																deposit will be
																sent to your
																bank with a
																code, please
																provide us with
																that code.
															</div>
														)}

													{node &&
														!node.defaultPaymentMethod &&
														payWithACH &&
														(updatingLead ||
															loadingLead) && (
															<div>
																<BounceLoader
																	className='m-auto'
																	color={
																		bounceLoaderColor
																	}
																></BounceLoader>
															</div>
														)}
													{node &&
														!node.defaultPaymentMethod &&
														payWithACH &&
														!(
															updatingLead ||
															loadingLead
														) && (
															<Button
																className='my-5 w-[200px]'
																size='sm'
																onClick={
																	handleSubmitACH
																}
																text='Next'
															/>
														)}

													{(updateLeadError ||
														incomplete) && (
														<p className='text-red-400'>
															There was an error
															saving your payment
															method. Please try
															again.
														</p>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<BounceLoaderCentered />
							)}
						</div>
					</div>

					<Footer />
				</div>
			)
		} else {
			navigate('/')
		}
	} else {
		return (
			<>
				<Header />
				<BounceLoaderCentered container='page' />
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ Nodes, User, Leads, Payments }) => ({
	nodes: Nodes.nodes,
	node: Nodes.restaurantAdminNode,
	loadingNodes: Nodes.loadingNodes,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	updatedLead: Leads.updatedLead,
	updatingLead: Leads.updatingLead,
	updateLeadError: Leads.updateLeadError,
	lead: Leads.lead,
	paymentMethods: Payments.paymentMethods,
	loadingValidatePromoCode: Leads.loadingValidatePromoCode,
	promoCodeDoc: Leads.promoCodeDoc,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetUser: () => dispatch(getUser()),
	onUpdateLead: (payload) => dispatch(updateLead(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
	onGetNodeBySlug: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onCheckPromoCodeValidity: (payload) =>
		dispatch(checkPromoCodeValidity(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage3)
