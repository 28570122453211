import React, { useState, useEffect } from 'react'
import { Input, Button, BounceLoaderCentered } from '../Elements'
import { updateUser } from '../../store/actions'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'

const DeliveryAddressEditForm = (props) => {
	const google = window.google
	const {
		user,
		updateUserLoading,
		onUpdateUser,
		onGetUser,
		setShowAddressForm,
		setChangedDeliveryAddress,
		setChangedDeliveryAddress2,
		invalidAddress,
		setInvalidAddress,
		validateAddress,
		shipping,
	} = props

	const [addressChange, setAddressChange] = useState('')
	const [defaultAddressChange, setDefaultAddressChange] = useState(false)
	const [address2, setAddress2] = useState('')
	const [addressError, setAddressError] = useState(false)
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [reverseSavedAddresses, setReverseSavedAddresses] = useState([])
	const [showSearchBar, setShowSearchBar] = useState(false)
	const bounceLoaderColor = '#507f74'

	useEffect(() => {
		if (user && user.savedAddresses) {
			setReverseSavedAddresses(user.savedAddresses.reverse())
		}
	}, [user, updateUserLoading])

	useEffect(() => {
		if (window.google && window.google.maps) {
			setShowSearchBar(true)
		}
	}, [window.google?.maps])

	useEffect(() => {}, [addressError])

	const handleAddressChange = (newAddress) => {
		if (newAddress.target) {
			setAddressChange(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setAddressChange(newAddress.formatted_address)
		}
	}

	const handleAddress2Change = (apt) => {
		if (apt.target) {
			setAddress2(apt.target.value)
			setChangedDeliveryAddress2(apt.target.value)
		}
	}

	const handleAddressSubmit = async (e) => {
		if (e) e.preventDefault()
		if (addressChange != '') {
			// Geocode the address
			// console.log('handling submit')
			await geocodeAddress()
			// handleHideAddressForm()
			onGetUser()
		}
	}
	//
	// useEffect(() => {
	// 	if(addressChange!=""){
	// 		geocodeAddress(addressChange)
	// 	}
	// }, [addressChange])

	const geocodeAddress = async () => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addressChange,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)
					setAddressChange(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
					// show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	useEffect(() => {
		if (geoCodedAddress) {
			setInvalidAddress(false)
			if (
				user &&
				user.savedAddresses &&
				!user.savedAddresses.find(
					(address) => address.savedAddress === geoCodedAddress
				)
			) {
				// console.log('updating user')
				onUpdateUser({
					savedAddresses: [
						...user.savedAddresses,
						{
							savedAddress: geoCodedAddress,
							savedAddress2: address2,
						},
					],
				})
			}
			onGetUser()
			setChangedDeliveryAddress(geoCodedAddress)
			handleHideAddressForm()
			validateAddress()
			// setShowAddressList(false)
		}
	}, [geoCodedAddress, user])

	// END OF ASYNC FUNCTION
	const handleHideAddressForm = () => {
		setAddressChange('')
		setAddress2('')
		setAddressError(false)
		setShowAddressForm(false)
	}

	return (
		<div className='w-full'>
			{user && !user.loading && (
				<div>
					<div className='flex flex-col justify-between items-center mt-2'>
						{user.savedAddresses &&
							!updateUserLoading &&
							reverseSavedAddresses.map((address, index) => {
								if (index > 5) {
									return null
								} else {
									return (
										<div
											key={index}
											className='flex justify-between items-center w-full m-1 border-b mb-2'
										>
											<div
												className='mr-2 text-sm'
												onClick={() => {}}
											>
												{address.savedAddress}{' '}
												{address.savedAddress2
													? ` Unit: ${address?.savedAddress2}`
													: ''}
											</div>

											<div
												className='mr-4 link-sm text-xs'
												onClick={() => {
													setChangedDeliveryAddress(
														address.savedAddress
													)
													if (address.savedAddress2) {
														setChangedDeliveryAddress2(
															address.savedAddress2
														)
													} else {
														setChangedDeliveryAddress2(
															''
														)
													}
													// console.log(
													// 	address.savedAddress
													// )
												}}
											>
												Set{' '}
												{shipping
													? 'shipping'
													: 'delivery'}{' '}
												address
											</div>
										</div>
									)
								}
							})}
					</div>
				</div>
			)}
			<div className='w-full h-auto my-5 flex flex-col justify-between items-start'>
				<form onSubmit={(e) => handleAddressSubmit(e)}>
					{showSearchBar ? (
						<>
							<Input
								placeholder='Enter your address'
								className={`relative bg-white pl-5 h-11 mb-2 w-[350px]`}
								name={`address`}
								error={false}
								autoCompleteAddress={true}
								onChange={handleAddressChange}
								onKeyDown={(e) => {
									handleAddressChange(e)
									e.key === 'Enter' && e.preventDefault()
								}}
							/>
							<Input
								type=''
								name='unit'
								placeholder='APT, SUITE, ETC (OPTIONAL)'
								onChange={handleAddress2Change}
								className='relative bg-white pl-5 h-11 w-[180px] sm:w-[350px] mb-2'
								onKeyDown={(e) => {
									e.key === 'Enter' && e.preventDefault()
								}}
							/>
						</>
					) : (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					)}
					{invalidAddress && (
						<div className='text-red-400 mt-3 mb-1'>
							Please select or enter a valid delivery address
						</div>
					)}
					{!updateUserLoading && (
						<Button
							className=''
							text='Save Address'
							full
							size='sm'
							submit={true}
						/>
					)}
					{updateUserLoading && (
						<BounceLoaderCentered container='div' />
					)}
				</form>
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Payments }) => ({
	loadingDeletion: Payments.loadingDeletion,
	user: User.user,
	loadingUser: User.loading,
	cardDeletionError: Payments.deletionError,
	cardDeleted: Payments.deleted,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
})

const mapDispatchToProps = (dispatch) => ({
	onUpdateUser: (data) => dispatch(updateUser(data)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeliveryAddressEditForm)
