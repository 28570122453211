/* PAGE DESCRIPTION: 
        Rewards page that allows users to exchange rewards for discounts at participating merchants 
*/

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Header, Footer } from '../../components'
import { Button } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import RewardsTile from '../../components/Marketplace/RewardsTile'
import { getUser } from '../../store/actions'
import { getAllRewards } from '../../store/actions'

const RewardsMarketplace = (props) => {
	const { user, onGetUser, allRewards, onGetAllRewards, updatedUser } = props

	const bounceLoaderColor = '#507f74'

	const navigate = useNavigate()

	const [loading, setLoading] = useState(true)
	const [totalRewardPts, setTotalRewardPts] = useState(0)
	const [allRewardsInfo, setAllRewardsInfo] = useState([])

	const getAllRewards = () => {
		onGetAllRewards({
			filter: {},
			options: {},
		})
	}

	useEffect(() => {
		onGetUser()
	}, [onGetUser, updatedUser])

	useEffect(() => {
		getAllRewards()
	}, [onGetAllRewards, updatedUser])

	useEffect(() => {
		if (user) {
			setTotalRewardPts(user?.rewardPoints)
		}
	}, [user, user?.rewardPoints])

	useEffect(() => {
		setLoading(false)
		setAllRewardsInfo(allRewards)
	}, [allRewards])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)
	else {
		return (
			<>
				<div>
					<Header />
					<div className='flex flex-col justify-center items-center text-center mx-2 sm:mx-10'>
						<h1 className='text-3xl md:text-5xl font-header text-green-600 text-center w-full mb-4 mt-12'>
							DeliverZero Rewards Marketplace
						</h1>
						<h2 className='text-lg sm:text-l md:text-xl font-body w-full text-center'>
							Customers should be rewarded for making the
							climate-friendly choice. Every time you return
							DeliverZero containers, you earn points you can
							exchange for rewards from values-aligned brands.
						</h2>
						{user.userId ? (
							<h1 className='text-xl md:text-3xl font-body text-black text-center w-full mb-8 mt-8'>
								You currently have{' '}
								<span className='text-green-600 font-header'>
									{' '}
									{totalRewardPts}
								</span>{' '}
								reward points
							</h1>
						) : (
							<a
								className='whitespace-wrap text-3xl font-body text-green-600 underline cursor-pointer mb-8 mt-8'
								rel='noreferrer'
								onClick={() => navigate('/login')}
							>
								Login to Redeem
							</a>
						)}
					</div>
					<div
						className={
							'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 place-items-center mr-20 ml-20 mb-20'
						}
					>
						{allRewardsInfo.length === 0 || loading ? (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						) : (
							allRewardsInfo.map((reward) => {
								return (
									<RewardsTile
										brandName={reward.brand}
										brandDescription={reward.description}
										rewardCaption={reward.caption}
										rewardPoints={reward.points}
										imgSrc={reward.imageSrc}
										discountCodes={reward.availableCodes}
										rewardURL={reward.rewardURL}
										user={user}
										expirationDate={reward.expirationDate.toLocaleDateString()}
										nonUnique={
											reward.nonUnique ? true : false
										}
										redeemedUsers={
											reward.redeemedUsers
												? reward.redeemedUsers
												: []
										}
									/>
								)
							})
						)}
					</div>
					<Footer />
				</div>
			</>
		)
	}
}

const mapStateToProps = ({ User, AllRewards }) => ({
	user: User.user,
	allRewards: AllRewards.allRewards,
	updatedUser: AllRewards.updatedUser,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAllRewards: (payload) => dispatch(getAllRewards(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RewardsMarketplace)
