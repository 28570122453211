import { realm } from '../helpers/realm'

export const getMenu = async (slug) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const menus = client.db('caas').collection('menus')
	return await menus.findOne({ nodeBySlug: slug })
}

export const getProduct = async (id) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const menus = client.db('caas').collection('menus')
	const product = await menus.findOne({ items: id })
	// console.log(product)
	return product
}

//deliverect
export const getDispatchAvailability = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const res = await realm.currentUser.callFunction(
		'deliverect/validateDispatchAvailability',
		payload
	)

	return res
}
