import React, { useEffect, useState } from 'react'
import { Header, Footer } from '../../../components'
import { getMembers, getUser } from '../../../store/actions'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '../../../components/Elements'

const SubscribersDashboard = (props) => {
	const {
		onGetMembers,
		loadingMembers,
		members,
		user,
		onGetUser,
		membersCount,
	} = props
	let navigate = useNavigate()
	const bounceLoaderColor = '#507f74'
	const paginationLimit = 30
	const [membersPage, setMembersPage] = useState(1)
	const [typedSearch, setTypedSearch] = useState('')
	const [memberSearchResults, setMemberSearchResults] = useState([])
	const [memberFilter, setMemberFilter] = useState({})

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const getMembers = (page) => {
		if (user) {
			onGetMembers({
				filter: {
					...memberFilter,
					'dzMemberSubscription.enabled': true,
				},
				options: {
					sort: {
						lastUse: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		} else {
			onGetUser()
		}
	}

	useEffect(() => {
		if (typedSearch.length < 3) {
			setMemberFilter({})
			setMembersPage(1)
		} else {
			setMemberFilter({
				$or: [
					{
						firstName: {
							$regex: typedSearch,
							$options: 'i',
						},
					},
					{ email: { $regex: typedSearch, $options: 'i' } },
					{
						lastName: {
							$regex: typedSearch,
							$options: 'i',
						},
					},
					{ phone: { $regex: typedSearch, $options: 'i' } },
				],
			})
			setMembersPage(1)
		}
	}, [typedSearch, user])

	const memberPaginate = () => {
		getMembers(membersPage)
	}

	useEffect(() => {
		memberPaginate()
	}, [membersPage, memberFilter])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div className='flex flex-col items-center justify-center mb-16'>
					<h1 className='font-header text-green-600 text-4xl md:text-[66px] mb-6 md:mb-11 mt-8'>
						Subscribers
					</h1>
					{membersCount > 0 && (
						<div className='text-center'>
							<span className='font-bold'>
								Number of total subscribed members:
							</span>{' '}
							{membersCount}
						</div>
					)}
					<div className='mx-auto'>
						{members && members.length > 0 && (
							<Input
								type='text'
								label=''
								name='item-search'
								placeholder='e.g. "bob@gmail.com"'
								value={typedSearch}
								className='bg-white border-2 border-gray-300'
								onChange={(e) => {
									setTypedSearch(e.target.value.toLowerCase())
								}}
							/>
						)}
						<div>
							{memberSearchResults.map((member) => {
								return (
									<div
										key={member.phone}
										className='flex flex-row my-1'
									>
										<div className='w-[350px] overflow-x-hidden'>
											{member.firstName}
											{` `}
											{member.lastName}
											{` `}
											{member.email} {member.phone}
										</div>
									</div>
								)
							})}
						</div>

						{members.length > 0 ? (
							<div className='px-10 my-6 pt-3 shadow-light-grey'>
								<div className='flex flex-row mb-2 mt-6'>
									<div className='font-bold w-[150px]'>
										Member
									</div>
									<div className='font-bold w-[150px] ml-3 text-sm'>
										Tier
									</div>
									<div className='font-bold w-[180px] ml-3 text-sm'>
										Member Since
									</div>
									<div className='font-bold w-[150px] ml-3 text-sm'>
										Renewal Fee
									</div>
									<div className='font-bold w-[150px] ml-3 text-sm'>
										Cadence
									</div>
									<div className='font-bold w-[150px] ml-3 text-sm'>
										Total Reimbursements
									</div>
									<div className='font-bold w-[150px] ml-3 text-sm'>
										Total Free Pickups
									</div>
								</div>
								{loadingMembers && (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								{members.map((member, index) => {
									const signUpDate = new Date(
										member.dzMemberSubscription.memberSince
									)
									const signUpDateString =
										signUpDate.toLocaleString('default', {
											month: 'short',
											day: 'numeric',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
										})
									return (
										<div
											className='flex flex-row my-2 divide-y-2 divide-solid'
											key={index}
										>
											<div></div>
											<div className='w-[150px] overflow-hidden font-bold'>
												{member.firstName}{' '}
												{member.lastName}
											</div>
											<div className='w-[150px] ml-3'>
												{
													member.dzMemberSubscription
														.tierSlug
												}
											</div>
											<div className='w-[180px] ml-3'>
												{signUpDateString}
											</div>
											<div className='w-[150px] overflow-hidden ml-3'>
												$
												{parseFloat(
													member.dzMemberSubscription
														.renewalFee
												).toFixed(2)}
											</div>
											<div className='w-[150px] overflow-hidden ml-3'>
												{
													member.dzMemberSubscription
														.renewalCadence
												}
											</div>
											<div className='w-[150px] overflow-hidden ml-3'>
												$
												{parseFloat(
													member.totalReimbursements
												).toFixed(2)}
											</div>
											<div className='w-[150px] overflow-hidden ml-3'>
												$
												{parseFloat(
													member.totalPickups / 100
												).toFixed(2)}
											</div>
										</div>
									)
								})}
							</div>
						) : (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
						<div className='flex flex-row justify-start'>
							{membersPage > 1 && (
								<div
									className='underline font-bold mr-6 cursor-pointer'
									onClick={() => {
										setMembersPage(membersPage - 1)
									}}
								>
									Previous {paginationLimit} members
								</div>
							)}
							{members.length > paginationLimit && (
								<div
									className='underline font-bold cursor-pointer'
									onClick={() => {
										setMembersPage(membersPage + 1)
									}}
								>
									Next {paginationLimit} members
								</div>
							)}
						</div>
					</div>
				</div>
				<Footer />
			</>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Membership, User }) => ({
	members: Membership.members,
	membersCount: Membership.membersCount,
	loadingMembers: Membership.loading,
	user: User.user,
})

const mapDispatchToProps = (dispatch) => ({
	onGetMembers: (payload) => dispatch(getMembers(payload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubscribersDashboard)
