import React, { useEffect, useState } from 'react'
import { getUser } from '../../store/actions'
import { connect } from 'react-redux'
import {
	Button,
	CheckboxGroup,
	SelectGroup,
	Input,
	RadioGroup,
	BounceLoaderCentered,
} from '../Elements'

const ContactTable = (props) => {
	const { contacts, toggleAdminAccess, handleDeleteContact } = props

	return (
		<table className='w-full border-collapse border border-gray-300 mb-10 mx-auto'>
			<thead className='bg-gray-100'>
				<tr>
					<th className='border border-gray-300 py-2 px-4'>Name</th>
					<th className='border border-gray-300 py-2 px-4'>Email</th>
					<th className='border border-gray-300 py-2 px-4'>Phone</th>
					<th className='border border-gray-300 py-2 px-4'>Title</th>
					{/* <th className="border border-gray-300 py-2 px-4">
                        Locations
                    </th> */}
					<th className='border border-gray-300 py-2 px-4'>
						Admin Access
					</th>
					<th className='border border-gray-300 py-2 px-4'></th>
				</tr>
			</thead>
			<tbody>
				{contacts.map((contact) => (
					<tr
						key={contact.id}
						className='bg-white'
					>
						<td className='border border-gray-300 py-2 px-4'>
							{contact.name}
						</td>
						<td className='border border-gray-300 py-2 px-4'>
							{contact.email}
						</td>
						<td className='border border-gray-300 py-2 px-4'>
							{contact.phone}
						</td>
						<td className='border border-gray-300 py-2 px-4'>
							{contact.title}
						</td>
						{/* <td className="border border-gray-300 py-2 px-4">
                            {contact.locations.join(", ")}
                        </td> */}
						<td className='border border-gray-300 py-2 px-4'>
							<CheckboxGroup
								type='checkbox'
								checked={contact.adminAccess}
								onChange={() => toggleAdminAccess(contact)}
							/>
						</td>
						<td className='border border-gray-300 py-2 px-4 text-red-400'>
							<CheckboxGroup
								id={`checkbox-${contact.phone}`}
								type='checkbox'
								className='text-red-400'
								label='DELETE'
								defaultChecked={false}
								onChange={() =>
									handleDeleteContact(contact.phone)
								}
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactTable)
