import { call, put, takeEvery } from 'redux-saga/effects'
import {
	disputeTransaction,
	getOneTransaction,
	getTransactions,
	updateTransaction,
	uploadOrders,
	getAnalyticsTransactions,
	adminDisputeTransaction,
} from '../../model/transactions'
import {
	getTransactionsSuccess,
	getTransactionsFail,
	getAnalyticsTransactionsSuccess,
	getAnalyticsTransactionsFail,
	getOneTransactionSuccess,
	disputeTransactionSuccess,
	getNodeTransactionsSuccess,
	getNodeTransactionsFail,
	adminUploadOrdersSuccess,
	adminUploadOrdersError,
	adminDisputeTransactionFail,
	adminDisputeTransactionSuccess,
} from './actions'
import {
	ADMIN_UPLOAD_ORDERS,
	DISPUTE_TRANSACTION,
	GET_NODE_TRANSACTIONS,
	GET_ONE_TRANSACTION,
	GET_TRANSACTIONS,
	GET_ANALYTICS_TRANSACTIONS,
	ADMIN_DISPUTE_TRANSACTION,
} from './actionTypes'
import {
	updateOneTransactionError,
	updateOneTransactionSuccess,
} from './actions'
import { UPDATE_ONE_TRANSACTION } from './actionTypes'

function* fetchTransactions(action) {
	try {
		const transactions = yield call(getTransactions, action.payload)
		yield put(getTransactionsSuccess(transactions))
	} catch (error) {
		yield put(getTransactionsFail(error))
	}
}

function* fetchOneTransaction(action) {
	try {
		const oneOrder = yield call(getOneTransaction, action.payload)
		yield put(getOneTransactionSuccess(oneOrder))
	} catch (error) {
		yield put(getTransactionsFail(error))
	}
}

function* changeTransaction(action) {
	try {
		const updatedTransaction = yield call(
			disputeTransaction,
			action.payload
		)
		yield put(disputeTransactionSuccess(updatedTransaction))
	} catch (error) {
		yield put(getTransactionsFail(error))
	}
}

function* adminChangeTransaction(action) {
	try {
		const updatedTransaction = yield call(
			adminDisputeTransaction,
			action.payload
		)
		yield put(adminDisputeTransactionSuccess(updatedTransaction))
	} catch (error) {
		yield put(adminDisputeTransactionFail(error))
	}
}

function* fetchNodeTransactions(action) {
	try {
		const transactions = yield call(getTransactions, action.payload)
		yield put(getNodeTransactionsSuccess(transactions))
	} catch (error) {
		yield put(getNodeTransactionsFail(error))
	}
}

function* manualUploadOrders(action) {
	try {
		const orderUploadResponse = yield call(uploadOrders, action.payload)
		yield put(adminUploadOrdersSuccess(orderUploadResponse))
	} catch (error) {
		yield put(adminUploadOrdersError(error))
	}
}

function* updateTransactionSaga(action) {
	try {
		const transaction = yield call(updateTransaction, action.payload)
		yield put(getOneTransactionSuccess(transaction))
	} catch (error) {
		yield put(updateOneTransactionError(error))
	}
}

function* fetchAnalyticsTransactions(action) {
	try {
		const transactions = yield call(
			getAnalyticsTransactions,
			action.payload
		)
		yield put(getAnalyticsTransactionsSuccess(transactions))
	} catch (error) {
		yield put(getAnalyticsTransactionsFail(error))
	}
}

function* transactionsSaga() {
	yield takeEvery(GET_TRANSACTIONS, fetchTransactions)
	yield takeEvery(GET_ANALYTICS_TRANSACTIONS, fetchAnalyticsTransactions)
	yield takeEvery(GET_ONE_TRANSACTION, fetchOneTransaction)
	yield takeEvery(DISPUTE_TRANSACTION, changeTransaction)
	yield takeEvery(ADMIN_DISPUTE_TRANSACTION, adminChangeTransaction)
	yield takeEvery(GET_NODE_TRANSACTIONS, fetchNodeTransactions)
	yield takeEvery(ADMIN_UPLOAD_ORDERS, manualUploadOrders)
	yield takeEvery(UPDATE_ONE_TRANSACTION, updateTransactionSaga)
}

export default transactionsSaga
