import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNewRestaurantStops,
	resetDropoffSuccess,
} from '../../../../store/actions'
import DropOffWidgetFlot from '../../../../components/Admin/DropoffWidgetFlot'
import { Footer, Header } from '../../../../components'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const DropoffFlot = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		onGetUser,
		onGetNewRestaurantStops,
		newRestaurantStops,
		onResetDropoffSuccess,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	useEffect(() => {
		onGetNewRestaurantStops('NY')
		onResetDropoffSuccess()
	}, [])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div className='h-full w-full'>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='px-12'>
						{/* new restaurant stops table */}
						{newRestaurantStops && newRestaurantStops.length > 0 ? (
							<div className='w-[90%] mx-auto mt-6 overflow-auto'>
								<div className='w-full my-2'>
									<h3 className='font-header text-red-400 text-xl mb-4 text-center'>
										NYC New Restaurant Stops
									</h3>
									<div className='overflow-x-auto'>
										<table className='w-full border-collapse border border-gray-300'>
											<thead>
												<tr className='bg-gray-100'>
													<th className='border border-gray-300 py-2 px-4'>
														Date
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Stop Name
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Address
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Contact
													</th>
													<th className='border border-gray-300 py-2 px-4'>
														Phone
													</th>
												</tr>
											</thead>
											<tbody>
												{newRestaurantStops &&
													newRestaurantStops.length >
														0 &&
													newRestaurantStops.map(
														(stop, index) => (
															<tr key={index}>
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		new Date(
																			stop.fullDocument.lastUpdated
																		)
																			.toISOString()
																			.split(
																				'T'
																			)[0]
																	}
																</td>
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		stop
																			.fullDocument
																			.restaurantName
																	}
																</td>
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		stop
																			.fullDocument
																			.fullAddress
																	}
																</td>
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		stop
																			.fullDocument
																			.firstName
																	}{' '}
																	{
																		stop
																			.fullDocument
																			.lastName
																	}
																</td>
																<td className='border border-gray-300 py-2 px-4'>
																	{
																		stop
																			.fullDocument
																			.contactPhone
																	}
																</td>
															</tr>
														)
													)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						) : (
							<div className='text-center'>
								No new restaurant stops found
							</div>
						)}

						<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
							<DropOffWidgetFlot />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Logistics }) => ({
	user: User.user,
	loadingUser: User.loading,
	loadingStops: Logistics.loadingStops,
	stopsError: Logistics.error,
	stopsSuccess: Logistics.success,
	newRestaurantStops: Logistics.newRestaurantStops,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetNewRestaurantStops: (payload) =>
		dispatch(getNewRestaurantStops(payload)),
	onResetDropoffSuccess: () => dispatch(resetDropoffSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropoffFlot)
