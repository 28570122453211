import { realm } from '../helpers/realm'

export const getFranchiseeCharges = (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const charges = client.db('caas').collection('franchisee_charges')

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const stages = [
		{
			$lookup: {
				from: 'nodes',
				localField: 'nodeSlug',
				foreignField: 'slug',
				as: 'nodeInfo',
			},
		},
		{
			$lookup: {
				from: 'meal_delivery_services',
				localField: 'nodeSlug',
				foreignField: 'slug',
				as: 'mealDeliveryInfo',
			},
		},
		{
			$unwind: {
				path: '$nodeInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
		{
			$unwind: {
				path: '$mealDeliveryInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

	return charges.aggregate(stages)
}

export const fetchFranchiseeApi = async (franchiseeSlug) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const franchisee = client.db('caas').collection('franchisee')
	return await franchisee.findOne({ slug: franchiseeSlug })
}
