import { realm } from '../helpers/realm'

export const inviteMerchantUser = async (payload) => {
	const response = await realm.currentUser.callFunction(
		'merchantContacts/inviteNew',
		payload
	)
	return response
}

export const getAllChildren = async (payload) => {
	try {
		const client = realm.currentUser.mongoClient('RealmService')
		const nodes = client.db('caas').collection('nodes')

		const childNodes = await nodes.find({ parentSlug: payload })
		return childNodes
	} catch (error) {
		console.error('Error fetching child nodes:', error)
		throw error
	}
}

export const addChildrenToUser = async (payload) => {
	if (!realm.currentUser) {
		return await realm.logIn(Realm.Credentials.anonymous())
	}

	try {
		if (realm.currentUser.providerType === 'anon-user') {
			return realm.currentUser
		}
		const children = await getAllChildren(payload)
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const nodes = client.db('caas').collection('nodes')
		const parentNode = await nodes.findOne({ slug: payload })

		const user = await users.findOne({ userId: realm.currentUser.id })
		if (!user) {
			throw new Error(`User with ID ${realm.currentUser.id} not found`)
		}

		const updateRestaurantAdminOtherLocations =
			user.restaurantAdminOtherLocations &&
			user.restaurantAdminOtherLocations.length > 0
				? [...user.restaurantAdminOtherLocations]
				: []

		const uniqueSlugs = new Set(
			updateRestaurantAdminOtherLocations.map((loc) => loc.nodeSlug)
		)

		for (const child of children) {
			if (!uniqueSlugs.has(child.slug)) {
				const locObj = {
					name: child.name,
					nodeSlug: child.slug,
				}
				updateRestaurantAdminOtherLocations.push(locObj)
				uniqueSlugs.add(child.slug)
			}
		}

		if (parentNode && !uniqueSlugs.has(parentNode.slug)) {
			updateRestaurantAdminOtherLocations.push({
				name: parentNode.name,
				nodeSlug: parentNode.slug,
			})
			uniqueSlugs.add(parentNode.slug)
		}

		const updateObject = {
			restaurantAdminOtherLocations: updateRestaurantAdminOtherLocations,
		}

		const update = await users.updateOne(
			{ userId: realm.currentUser.id },
			{ $set: updateObject }
		)

		const updatedUser = await users.findOne({
			userId: realm.currentUser.id,
		})
		return updatedUser
	} catch (error) {
		console.error('Error updating user:', error)
		throw error
	}
}

export const trackUsage = async (payload) => {
	try {
		const client = realm.currentUser.mongoClient('RealmService')
		const websiteAnalytics = client
			.db('caas')
			.collection('website_analytics')
		const { url, feature } = payload
		const timestamp = new Date()

		const newDoc = {
			url: url,
			feature: feature,
			timestamp: timestamp,
		}

		const insert = await websiteAnalytics.insertOne(newDoc)
		return insert
	} catch (error) {
		console.error('Error tracking:', error)
		throw error
	}
}
