import {
	ADD_TO_CART,
	REMOVE_CART_ITEM,
	REDUCE_CART_ITEM,
	CLEAR_CART,
	GET_CART,
	TOTAL_CART,
	ADD_CART_TO_USER,
	UPDATE_DELIVERY_OPTION,
	GET_CART_NODE_BY_SLUG,
	GET_CART_NODE_BY_SLUG_SUCCESS,
	GET_CART_NODE_BY_SLUG_FAIL,
	CLEAR_CART_SUCCESS,
	CLEAR_CART_FAIL,
	GET_CART_SUCCESS,
	GET_CART_ERROR,
} from './actionTypes'
import {
	GET_NODE_BY_SLUG,
	GET_NODE_BY_SLUG_FAIL,
	GET_NODE_BY_SLUG_SUCCESS,
} from '../nodes/actionTypes'

export const addProduct = (nextCartItem) => ({
	type: ADD_TO_CART,
	payload: nextCartItem,
})

export const removeCartItem = (cartItem) => ({
	type: REMOVE_CART_ITEM,
	payload: cartItem,
})

export const reduceCartItem = (cartItem) => ({
	type: REDUCE_CART_ITEM,
	payload: cartItem,
})

export const clearCart = () => ({
	type: CLEAR_CART,
})

export const clearCartSuccess = () => ({
	type: CLEAR_CART_SUCCESS,
})

export const clearCartFail = (error) => ({
	type: CLEAR_CART_FAIL,
	payload: error,
})

export const getCart = (cart) => ({
	type: GET_CART,
	payload: cart,
})

export const getCartSuccess = (cart) => ({
	type: GET_CART_SUCCESS,
	payload: cart,
})

export const getCartError = (cart) => ({
	type: GET_CART_ERROR,
	payload: cart,
})

export const totalCart = (cart) => ({
	type: TOTAL_CART,
	payload: cart,
})

export const addCartToUser = (payload) => ({
	type: ADD_CART_TO_USER,
	payload: payload,
})

export const updateDeliveryOption = (deliveryOption) => ({
	type: UPDATE_DELIVERY_OPTION,
	payload: deliveryOption,
})

export const getCartNodeBySlug = (payload) => ({
	type: GET_CART_NODE_BY_SLUG,
	payload: payload,
})

export const getCartNodeBySlugSuccess = (node) => ({
	type: GET_CART_NODE_BY_SLUG_SUCCESS,
	payload: node,
})

export const getCartNodeBySlugFail = (error) => ({
	type: GET_CART_NODE_BY_SLUG_FAIL,
	payload: error,
})
