import {
	LOGIN_USER,
	LOGIN_USER_FAIL,
	LOGIN_USER_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_FAIL,
	LOGOUT_USER_SUCCESS,
	SOCIAL_LOGIN_GOOGLE,
	SOCIAL_LOGIN_GOOGLE_FAIL,
	SOCIAL_LOGIN_GOOGLE_SUCCESS,
	SOCIAL_REGISTER_GOOGLE,
} from './actionTypes'

export const socialLoginGoogle = (payload) => {
	return {
		type: SOCIAL_LOGIN_GOOGLE,
		payload: payload,
	}
}

export const socialRegisterGoogle = (payload) => {
	return {
		type: SOCIAL_REGISTER_GOOGLE,
		payload: payload,
	}
}

export const socialLoginGoogleSuccess = (payload) => {
	return {
		type: SOCIAL_LOGIN_GOOGLE_SUCCESS,
		payload: payload,
	}
}

export const socialLoginGoogleFail = (payload) => {
	return {
		type: SOCIAL_LOGIN_GOOGLE_FAIL,
		payload: payload,
	}
}

export const loginUser = (payload) => {
	return {
		type: LOGIN_USER,
		payload: payload,
	}
}

export const loginUserSuccess = (user) => {
	return {
		type: LOGIN_USER_SUCCESS,
		payload: user,
	}
}

export const loginUserFail = (payload) => {
	return {
		type: LOGIN_USER_FAIL,
		payload: payload,
	}
}

export const logoutUser = (history) => {
	return {
		type: LOGOUT_USER,
		payload: history,
	}
}

export const logoutUserSuccess = (payload) => {
	return {
		type: LOGOUT_USER_SUCCESS,
		payload: payload,
	}
}

export const logoutUserFail = (payload) => {
	return {
		type: LOGOUT_USER_FAIL,
		payload: payload,
	}
}
