import {
	GET_USER,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	GET_USER_BOX_COUNT,
	GET_USER_BOX_COUNT_SUCCESS,
	GET_USER_BOX_COUNT_FAIL,
	UPDATE_USER_FAIL,
	UPDATE_USER_SUCCESS,
	UPDATE_USER,
	VALIDATE_PHONE_SUCCESS,
	VALIDATE_PHONE,
	VALIDATE_PHONE_FAIL,
	UPDATE_MAILCHIMP_MERGE_FIELDS,
	UPDATE_MAILCHIMP_MERGE_FIELDS_SUCCESS,
	UPDATE_MAILCHIMP_MERGE_FIELDS_FAIL,
	GET_USER_BY_PHONE,
	GET_USER_BY_PHONE_ONLY,
	GET_USER_BY_USERID_PHONE,
	UPDATE_USER_BY_PHONE,
	CAPTURE_USER,
	CAPTURE_USER_SUCCESS,
	CAPTURE_USER_FAIL,
	CAPTURE_IP,
	CAPTURE_IP_FAIL,
	CAPTURE_IP_SUCCESS,
	GET_OVERDUE_BOX_COUNT,
	GET_OVERDUE_BOX_COUNT_FAIL,
	GET_OVERDUE_BOX_COUNT_SUCCESS,
	UPDATE_OPT_OUT_PREFERENCES,
	UPDATE_OPT_OUT_PREFERENCES_SUCCESS,
	UPDATE_OPT_OUT_PREFERENCES_FAIL,
	RESET_PREFERENCES_UPDATED,
} from './actionTypes'

const initialState = {
	error: null,
	loading: false,
	user: null,
	boxCountError: null,
	loadingBoxCount: false,
	userBoxCount: null,
	loggedIn: false,
	isAnon: true,
	hasValidCard: false,
	updateUserLoading: false,
	validatePhoneLoading: false,
	validatedPhone: null,
	userUpdateCompleted: false,
	visitor: null,
	ip: null,
	overdueBoxCount: null,
	preferencesUpdated: null,
	preferencesUpdating: false,
	preferencesUpdateError: null,
}

const user = (state = initialState, action) => {
	switch (action.type) {
		case GET_USER:
			return {
				...state,
				loading: true,
			}
		case GET_USER_SUCCESS:
			const user = action.payload
			return {
				...state,
				loading: false,
				user: user,
				loggedIn: user.id && user.providerType !== 'anon-user',
				isAnon: user.providerType === 'anon-user',
				validatedPhone: user && user.phone,
			}
		case GET_USER_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
				loggedIn: false,
			}
		case GET_USER_BOX_COUNT:
			return {
				...state,
				loadingBoxCount: true,
			}
		case GET_USER_BOX_COUNT_SUCCESS:
			const userBoxCount = action.payload
			return {
				...state,
				loadingBoxCount: false,
				userBoxCount: userBoxCount,
			}
		case GET_USER_BOX_COUNT_FAIL:
			return {
				...state,
				loadingBoxCount: false,
				boxCountError: action.payload,
			}
		case UPDATE_USER:
			return {
				...state,
				userUpdateCompleted: false,
				updateUserLoading: true,
			}
		case UPDATE_USER_SUCCESS:
			const updatedUser = action.payload
			// console.log('user reducer',updatedUser)
			return {
				...state,
				error: null,
				updateUserLoading: false,
				userUpdateCompleted: true,
				user: updatedUser,
				loggedIn:
					updatedUser.id && updatedUser.providerType !== 'anon-user',
				isAnon: updatedUser.providerType === 'anon-user',
				hasValidCard:
					updatedUser.userId &&
					updatedUser.cards &&
					updatedUser.cards.length > 0,
			}
		case UPDATE_USER_FAIL:
			return {
				...state,
				userUpdateCompleted: false,
				updateUserLoading: false,
				error: action.payload,
			}
		case VALIDATE_PHONE:
			return {
				...state,
				validatePhoneLoading: true,
			}
		case VALIDATE_PHONE_SUCCESS:
			return {
				...state,
				validatePhoneLoading: false,
				validatedPhone: action.payload,
			}
		case VALIDATE_PHONE_FAIL:
			return {
				...state,
				validatePhoneLoading: false,
				validatedPhone: false,
				error: action.payload,
			}
		case UPDATE_USER:
			return {
				...state,
				userUpdateCompleted: false,
				updateUserLoading: true,
			}
		case UPDATE_MAILCHIMP_MERGE_FIELDS:
			return {
				...state,
			}
		case UPDATE_MAILCHIMP_MERGE_FIELDS_SUCCESS:
			return {
				...state,
			}
		case UPDATE_MAILCHIMP_MERGE_FIELDS_FAIL:
			return {
				...state,
			}
		case GET_USER_BY_PHONE:
			return {
				...state,
				loading: true,
			}
		case GET_USER_BY_PHONE_ONLY:
			return {
				...state,
				loading: true,
			}
		case GET_USER_BY_USERID_PHONE:
			return {
				...state,
				loading: true,
			}
		case UPDATE_USER_BY_PHONE:
			return {
				...state,
				userUpdateCompleted: false,
				updateUserLoading: true,
			}
		case CAPTURE_USER:
			return {
				...state,
				loading: true,
			}
		case CAPTURE_USER_SUCCESS:
			const visitor = action.payload
			// console.log("visitor", visitor)
			return {
				...state,
				loading: false,
				visitor: visitor,
			}
		case CAPTURE_USER_FAIL:
			// console.log("error in reducer", action.payload)
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case CAPTURE_IP:
			return {
				...state,
				loading: true,
			}
		case CAPTURE_IP_SUCCESS:
			const ip = action.payload
			// console.log("ip", ip)
			return {
				...state,
				loading: false,
				ip: ip,
			}
		case CAPTURE_IP_FAIL:
			// console.log("error in reducer", action.payload)
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case GET_OVERDUE_BOX_COUNT:
			return {
				...state,
				loadingBoxCount: true,
			}
		case GET_OVERDUE_BOX_COUNT_SUCCESS:
			const overdueBoxCount = action.payload
			// console.log("success", overdueBoxCount, "overdueBoxes", overdueBoxCount.overdueBoxes)
			return {
				...state,
				loadingBoxCount: false,
				overdueBoxCount: overdueBoxCount.overdueBoxes,
			}
		case GET_OVERDUE_BOX_COUNT_FAIL:
			// console.log("fail", action.payload)
			return {
				...state,
				loadingBoxCount: false,
				boxCountError: action.payload,
			}
		case UPDATE_OPT_OUT_PREFERENCES:
			return {
				...state,
				preferencesUpdating: true,
			}
		case UPDATE_OPT_OUT_PREFERENCES_SUCCESS:
			const updated = action.payload
			return {
				...state,
				preferencesUpdating: false,
				preferencesUpdated: updated,
			}
		case UPDATE_OPT_OUT_PREFERENCES_FAIL:
			return {
				...state,
				preferencesUpdating: false,
				preferencesUpdateError: action.payload,
			}
		case RESET_PREFERENCES_UPDATED:
			return {
				...state,
				preferencesUpdating: false,
				preferencesUpdated: null,
				preferencesUpdateError: null,
			}
		default:
			return state
	}
}

export default user
