import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import { Footer } from '../../components'
import {
	adminGetOneOrder,
	getOneOrder,
	getOrders,
} from '../../store/orders/actions'
import { getUser } from '../../store/auth/user/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import UserProfileCard from '../../components/UserProfile/UserProfileCard'
import {
	disputeTransaction,
	getOneTransaction,
} from '../../store/transactions/actions'
import {
	getNodeByAdminCodeOrSlug,
	getNodeBySlug,
} from '../../store/nodes/actions'

const RestaurantViewTransaction = (props) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetOneTransaction,
		loadingTransaction,
		transaction,
		transactionError,
		onGetOneOrder,
		loadingOrder,
		order,
		orderError,
		onGetNode,
		node,
		loadingNode,
		nodeError,
	} = props
	const { transactionId } = useParams()
	const bounceLoaderColor = '#507f74'
	useState(false)

	let navigate = useNavigate()

	const orderStatusDict = order
		? {
				NEW_ORDER: 'Order sent to restaurant',
				ORDER_ACCEPTED: 'Your order is being prepped',
				ORDER_PREPPED:
					order.fulfillmentInfo.fulfillmentMode === 'DELIVERY'
						? 'Your order has been made and will soon be dispatched'
						: 'Your order has been made and will soon be ready for pickup',
				ORDER_READY_TO_PICKUP:
					order.fulfillmentInfo.fulfillmentMode === 'DELIVERY'
						? 'Your order has been prepared and is getting dispatched'
						: 'Your order is ready for pickup',
				ORDER_HANDED_OFF:
					order.fulfillmentInfo.fulfillmentMode === 'DELIVERY'
						? 'Order is out for delivery'
						: 'Order has been picked up',
				ORDER_FULFILLED: 'Order has been completed',
				CANCELED:
					'Order has been canceled by restaurant. Contact restaurant or support.',
		  }
		: {}

	useEffect(() => {
		if (user) {
			if (user.restaurantAdmin) {
				onGetNode(user.restaurantAdmin)
			}
			if (transactionId) {
				onGetOneTransaction({
					transactionId: transactionId,
					admin: user.restaurantAdmin,
				})
			}
		} else {
			onGetUser()
		}
	}, [user])

	useEffect(() => {
		if (transaction) {
			onGetOneOrder(transaction.orderId)
		}
	}, [transaction])
	// console.log(disputedTransaction.items)

	if (!user)
		return (
			<div>
				<Header />
				<div className='flex items-center h-screen-no-header'>
					<BounceLoader
						className='m-auto w-full'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</div>
		)
	// console.log(order)
	if (user.restaurantAdmin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/restaurant-admin/orders')
					}}
				>
					&#8592; Go Back to My Orders
				</div>
				<h1 className='font-vollkorn text-green-600 text-4xl mt-14 text-center'>
					Order Breakdown
				</h1>
				{order && (
					<div className='flex justify-center'>
						<UserProfileCard
							titleClassName='text-green-400 text-3.5xl '
							className='max-w-xs mt-5 m-10 overflow-y-auto min-w-[300px] md:min-w-[500px] lg:min-w-[720px] h-[500px] shadow-lighter-grey'
							title={`Order ${order.externalIdentifiers.id}`}
							content={
								<div className='flex flex-col'>
									<div className='my-1'>
										<span className='font-header'>
											Subtotal:{' '}
										</span>
										${order.subtotal.toFixed(2)}
									</div>
									<div className='my-1'>
										<span className='font-header'>
											Tax:{' '}
										</span>
										${order.taxes.toFixed(2)}
									</div>
									<div className='my-1'>
										<span className='font-header'>
											Fees:{' '}
										</span>
										${order.deliveryFee.toFixed(2)}
									</div>
									<div className='my-1'>
										<span className='font-header'>
											Tip:{' '}
										</span>
										${order.tip.toFixed(2)}
									</div>
									<div className='my-1'>
										<span className='font-header'>
											Total to You:{' '}
										</span>
										$
										{(
											(1 - node.commissionFee) *
												order.subtotal +
											order.taxes +
											order.tip +
											order.deliveryFee
										).toFixed(2)}
									</div>

									<div className='my-1'>
										<span className='font-header'>
											Order Status:{' '}
										</span>
										{orderStatusDict[order.status]}
									</div>
									<div className='mt-5 mb-1'>
										<span className='font-header'>
											Items:{' '}
										</span>
									</div>
									{order.items.map((item) => {
										let modifierTotal = 0
										if (item.modifiers.length > 0) {
											modifierTotal = item.modifiers
												.map(amount)
												.reduce(sum)
										}
										return (
											<div className='ml-2'>
												<div className='flex flex-row'>
													<div className='w-3/4'>
														<span className='font-header'>
															{item.quantity} X{' '}
															{item.name}
														</span>
													</div>
													<div>
														<span className='text-sm'>
															$
															{(
																item.quantity *
																(item.price +
																	modifierTotal)
															).toFixed(2)}{' '}
														</span>
													</div>
												</div>
												<div>
													<span className='font-header'>
														Modifiers:
													</span>
													{item.modifiers.map(
														(mod) => {
															return (
																<span>
																	{item
																		.modifiers
																		.length >
																		1 && (
																		<span className='text-sm'>
																			{' '}
																			{
																				mod.name
																			}
																			{
																				','
																			}
																		</span>
																	)}
																	{item
																		.modifiers
																		.length ==
																		1 && (
																		<span className='text-sm'>
																			{' '}
																			{
																				mod.name
																			}
																		</span>
																	)}
																</span>
															)
														}
													)}
												</div>
												_____________________
											</div>
										)
									})}
								</div>
							}
						/>
					</div>
				)}

				<div className=' h-screen-no-header flex justify-center'>
					<UserProfileCard
						titleClassName='text-green-400 text-2xl md:text-3xl '
						className='mt-5 m-10 overflow-y-auto w-5/6 md:w-3/5 lg:max-w-[720px] h-[500px] shadow-lighter-grey'
						title={`Container Breakdown`}
						content={
							<div className='flex flex-col mb-2'>
								<div className='flex flex-row'>
									<div className='font-header w-3/5'>
										Item
									</div>
									<div className='font-header w-2/5'>
										Number of Containers
									</div>
								</div>
								{transaction && transaction.items ? (
									<div>
										{transaction.items?.map(
											(item, index) => {
												if (
													!item.product.name
														.toLowerCase()
														.replaceAll(' ', '')
														.includes('deliverzero')
												) {
													return (
														<div>
															<div className='flex flex-row my-2'>
																<div className='items-center my-1.5 w-3/5 overflow-hidden'>
																	{
																		item
																			.product
																			.name
																	}
																</div>
																<div className='ml-1 sm:ml-0 w-2/5'>
																	<div
																		className={`text-xl sm:text-2xl`}
																	>
																		{
																			item.boxCount
																		}
																	</div>
																</div>
															</div>

															{item.modifiers?.map(
																(
																	mod,
																	modIndex
																) => {
																	if (
																		mod.boxCount >
																		0
																	) {
																		return (
																			<div>
																				<div className='flex flex-row my-2 mx-2'>
																					<div className='my-1.5 w-3/5 overflow-hidden'>
																						<span className='font-header w-3/5'>
																							Mod:{' '}
																						</span>
																						{
																							mod.name
																						}
																					</div>
																					<div className='w-2/5'>
																						<div
																							className={`border-2 w-full sm:w-5/6 rounded-full text-center flex justify-between px-3 items-center`}
																						>
																							<div
																								className={`text-xl sm:text-2xl`}
																							>
																								{
																									mod.boxCount
																								}
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		)
																	}
																}
															)}
														</div>
													)
												}
											}
										)}
									</div>
								) : (
									<BounceLoader
										className='m-auto w-full'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
							</div>
						}
					/>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}
const mapStateToProps = ({ User, Transactions, Orders, Nodes }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingTransaction: Transactions.loadingTransactions,
	transaction: Transactions.oneTransaction,
	transactionError: Transactions.error,
	loadingOrder: Orders.loading,
	order: Orders.oneOrder,
	orderError: Orders.oneOrderError,
	successfulDispute: Transactions.successfulDispute,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	nodeError: Nodes.nodeError,
})
const mapDispatchToProps = (dispatch) => ({
	onGetOneOrder: (payload) => dispatch(adminGetOneOrder(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetOneTransaction: (payload) => dispatch(getOneTransaction(payload)),
	onSubmitDisputeTransaction: (payload) =>
		dispatch(disputeTransaction(payload)),
	onGetNode: (payload) => dispatch(getNodeByAdminCodeOrSlug(payload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantViewTransaction)
