import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	addNode,
	registerUser,
	addLead,
} from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { Button, Input, Alert } from '../../../components/Elements'
import { useJsApiLoader } from '@react-google-maps/api'
import { googleMapsLibraries } from '../../../helpers/googleMapsAPI'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { realm } from '../../../helpers/realm'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const SignUpPage1 = (state) => {
	const {
		user,
		onGetUser,
		loadingAddNode,
		addNodeSuccess,
		addNodeError,
		onAddNode,
		registerUser,
		onRegisterUser,
		registrationAttemptStatus,
		onUpdateUser,
		userUpdateCompleted,
		registrationError,
		onAddLead,
		newLead,
		leadError,
		loadingUser,
		loadingLead,
		loggedIn,
	} = state

	const google = window.google
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [form, setForm] = useState({})
	const [formLoggedInVersion, setFormLoggedInVersion] = useState({})
	const [incomplete, setIncomplete] = useState(false)
	const [incompleteLoggedInVersion, setIncompleteLoggedInVersion] =
		useState(false)
	const [phoneInvalid, setPhoneInvalid] = useState(false)
	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [address, setAddress] = useState('')
	const [city, setCity] = useState('')
	const [stateAddress, setStateAddress] = useState('')
	const [postal, setPostal] = useState('')
	const [passwordStrengthError, setPasswordStrengthError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [existingDzUser, setExistingDzUser] = useState(false)
	const [pinCode, setPinCode] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const [promoCode, setPromoCode] = useState(null)
	const [loggedInVersion, setLoggedInVersion] = useState(false)
	const [showSearchBar, setShowSearchBar] = useState(false)

	useEffect(() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const promoParam = urlParams.get('promo')

		if (promoParam) {
			setPromoCode(promoParam)
		}
	}, [])

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: googleMapsLibraries,
	})

	useEffect(() => {
		if (window.google && window.google.maps) {
			setShowSearchBar(true)
		}
	}, [window.google?.maps, Object.keys(window.google).length])

	const checkPasswordStrength = (e) => {
		setPasswordStrengthError(false)
		setIncomplete(false)
		const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/
		const isStrongPassword = passwordRegex.test(e.target.value)
		setPasswordStrengthError(!isStrongPassword)
		if (isStrongPassword) {
			setForm({ ...form, [e.target.name]: e.target.value })
		}
	}

	const checkEmail = (e) => {
		setEmailError(false)
		setIncomplete(false)
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		const isValidEmail = emailRegex.test(e.target.value)
		setEmailError(!isValidEmail)
		if (isValidEmail) {
			setForm({ ...form, [e.target.name]: e.target.value })
		}
	}

	const handleChange = (e) => {
		if (!loggedInVersion) {
			setIncomplete(false)
			setExistingDzUser(false)
			setForm({ ...form, [e.target.name]: e.target.value })
		} else {
			setIncompleteLoggedInVersion(false)
			setFormLoggedInVersion({
				...formLoggedInVersion,
				[e.target.name]: e.target.value,
			})
		}
	}

	useEffect(() => {
		if (loggedIn) {
			if (user && user.restaurantAdmin) {
				navigate('/restaurant-admin/home')
			} else {
				setLoggedInVersion(true)
			}
		}
	}, [loggedIn])

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			// we don't want the user to try signing up again if they alr have restaurantAdmin associated with them
			navigate('/restaurant-admin/home')
		}
	}, [user])

	const generateRandomNumberString = () => {
		let randomNumberString = ''
		for (let i = 0; i < 4; i++) {
			randomNumberString += Math.floor(Math.random() * 10)
		}
		return randomNumberString
	}

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	useEffect(() => {
		if (geoCodedAddress) {
			const [address, city, stateZip, country] =
				geoCodedAddress.split(', ')
			const [state, postal] = stateZip.split(' ')
			setAddress(address)
			setCity(city)
			setStateAddress(state)
			setPostal(postal)
		}
	}, [geoCodedAddress])

	const handleAddressChange = (newAddress) => {
		setAddressError(false)
		setGeoCodedAddress(null)
		if (newAddress.target) {
			setSearchedAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
		}
	}

	const handleAddressSelect = (address) => {
		setSearchedAddress(address)
		geocodeAddress(address)
	}

	const checkExistingDzUser = async (form) => {
		setExistingDzUser(false)
		const phone = '+1' + form.phone.replace(/[^\d]/g, '')
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ phone: phone })
		if (userDoc && userDoc.userId) {
			setExistingDzUser(true)
			return true
		} else {
			setExistingDzUser(false)
			return false
		}
	}

	const openSupport = () => {
		window.Intercom('show')
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setIncomplete(false)
		const requiredFields = [
			'firstName',
			'lastName',
			'title',
			'restaurantName',
			'phone',
			'email',
			'password',
		]

		// checking address separately bc just need to check geoCodedAddr
		if (!geoCodedAddress || !geoCodedLat || !geoCodedLng) {
			setIncomplete(true)
			return
		}

		// Validation check 1: Check if required fields filled out
		for (const key of requiredFields) {
			if (!(key in form) || form[key] === null || form[key] === '') {
				setIncomplete(true)
				return
			}
		}

		setPhoneInvalid(false)

		if (form['phone'].replace(/[^\d]/g, '').length != 10) {
			setPhoneInvalid(true)
			return
		}

		// Validation check 2: Check if user alr exists
		const existingUser = await checkExistingDzUser(form)
		if (existingUser) {
			return
		} else {
			setIncomplete(false)
			setPinCode(
				form.restaurantName.toLowerCase().slice(0, 3) +
					generateRandomNumberString()
			)
		}

		if (
			passwordStrengthError ||
			emailError ||
			existingDzUser ||
			addressError ||
			incomplete
		) {
			// console.log("Validation failed")
			return
		} else {
			// console.log("Validation succeeded")
			await onRegisterUser({
				email: form.email,
				firstName: form.restaurantName,
				lastName: '',
				password: form.password,
				phone: '+1' + form.phone.replace(/[^\d]/g, ''),
				signUpNewsletter: false,
			})
		}
	}

	const handleSubmitLoggedInVersion = async (e) => {
		e.preventDefault()
		setIncompleteLoggedInVersion(false)
		const requiredFields = ['restaurantName', 'title']

		// checking address separately bc just need to check geoCodedAddr
		if (!geoCodedAddress || !geoCodedLat || !geoCodedLng) {
			setIncompleteLoggedInVersion(true)
			return
		}

		// Validation check 1: Check if required fields filled out
		for (const key of requiredFields) {
			if (
				!(key in formLoggedInVersion) ||
				formLoggedInVersion[key] === null ||
				formLoggedInVersion[key] === ''
			) {
				setIncompleteLoggedInVersion(true)
				return
			}
		}

		const adminPinCode =
			formLoggedInVersion.restaurantName.toLowerCase().slice(0, 3) +
			generateRandomNumberString()

		if (addressError || incompleteLoggedInVersion) {
			return
		} else {
			await onUpdateUser({
				restaurantAdmin: formLoggedInVersion.restaurantName
					.toLowerCase()
					.replaceAll(' ', '-')
					.replace(/[^\w-]/g, ''),
			})
			// user should be guaranteed to be here since form won't show if there is no user
			const nodeDoc = {
				name: formLoggedInVersion.restaurantName,
				address: address,
				city: city,
				state: stateAddress,
				postal: postal,
				unit: '',
				country: 'USA',
				thumbnail: '',
				slug: formLoggedInVersion.restaurantName
					.toLowerCase()
					.replaceAll(' ', '-')
					.replace(/[^\w-]/g, ''),
				contactPhone: user.phone,
				email: user.email,
				type: 'restaurant',
				enabled: false,
				mute: true,
				balance: 0,
				balanceByContainerType: {},
				pricePerUseByContainerType: {},
				pricePerUse: 0,
				adminPinCode: adminPinCode,
				coordinates: [parseFloat(geoCodedLng), parseFloat(geoCodedLat)],
				maxBalance: 200,
				returnBinEnabled: false,
				inHouseCouriers: false,
				description: '',
				openHoursDescription: '',
				openHours: [],
				courierAcceptsReturns: false,
				qr: ['XXXXXX'],
				thirdPartyClient: {},
				cuisine: '',
				instoreOnly: false,
				pickupOnly: false,
				pricePerUseToUser: 0,
				commissionFee: 0.15,
				serviceFeeToUser: 0.1,
				deliveryFeeToUser: 3.99,
				taxToUser: 0.08875,
				deliveryPolygon: [],
				directOrdering: false,
				checkoutModule: false,
				fromSignUpFlow: true,
			}
			const leadDoc = {
				restaurantName: formLoggedInVersion.restaurantName,
				firstName: user.firstName,
				lastName: user.lastName,
				title: formLoggedInVersion.title,
				fullAddress: searchedAddress,
				slug: formLoggedInVersion.restaurantName
					.toLowerCase()
					.replaceAll(' ', '-')
					.replace(/[^\w-]/g, ''),
				contactPhone: user.phone,
				email: user.email,
				type: 'restaurant',
				enabled: false,
				mute: true,
				adminPinCode: adminPinCode,
				coordinates: [parseFloat(geoCodedLng), parseFloat(geoCodedLat)],
				signUpComplete: false,
				lastPage: 1,
				lastUpdated: new Date(),
			}
			if (promoCode) {
				leadDoc.promoCodeMsg =
					'Code validated! Enjoy One month free of container usage'
				leadDoc.promoCodeSlug = 'months-free-1'
			}
			await onAddNode(nodeDoc)
			await onAddLead(leadDoc)
		}
	}

	useEffect(() => {
		if (registrationAttemptStatus === 'completed' && !registrationError) {
			onUpdateUser({
				restaurantAdmin: form.restaurantName
					.toLowerCase()
					.replaceAll(' ', '-')
					.replace(/[^\w-]/g, ''),
			})
			const nodeDoc = {
				name: form.restaurantName,
				address: address,
				city: city,
				state: stateAddress,
				postal: postal,
				unit: '',
				country: 'USA',
				thumbnail: '',
				slug: form.restaurantName
					.toLowerCase()
					.replaceAll(' ', '-')
					.replace(/[^\w-]/g, ''),
				contactPhone: '+1' + form.phone.replace(/[^\d]/g, ''),
				email: form.email,
				type: 'restaurant',
				enabled: false,
				mute: true,
				balance: 0,
				balanceByContainerType: {},
				pricePerUseByContainerType: {},
				pricePerUse: 0,
				adminPinCode: pinCode,
				coordinates: [parseFloat(geoCodedLng), parseFloat(geoCodedLat)],
				maxBalance: 200,
				returnBinEnabled: false,
				inHouseCouriers: false,
				description: '',
				openHoursDescription: '',
				openHours: [],
				courierAcceptsReturns: false,
				qr: ['XXXXXX'],
				thirdPartyClient: {},
				cuisine: '',
				instoreOnly: false,
				pickupOnly: false,
				pricePerUseToUser: 0,
				commissionFee: 0.15,
				serviceFeeToUser: 0.1,
				deliveryFeeToUser: 3.99,
				taxToUser: 0.08875,
				deliveryPolygon: [],
				directOrdering: false,
				checkoutModule: false,
				fromSignUpFlow: true,
			}
			const leadDoc = {
				restaurantName: form.restaurantName,
				firstName: form.firstName,
				lastName: form.lastName,
				title: form.title,
				fullAddress: searchedAddress,
				slug: form.restaurantName
					.toLowerCase()
					.replaceAll(' ', '-')
					.replace(/[^\w-]/g, ''),
				contactPhone: '+1' + form.phone.replace(/[^\d]/g, ''),
				email: form.email,
				type: 'restaurant',
				enabled: false,
				mute: true,
				adminPinCode: pinCode,
				coordinates: [parseFloat(geoCodedLng), parseFloat(geoCodedLat)],
				signUpComplete: false,
				lastPage: 1,
				lastUpdated: new Date(),
			}
			if (promoCode) {
				leadDoc.promoCodeMsg =
					'Code validated! Enjoy One month free of container usage'
				leadDoc.promoCodeSlug = 'months-free-1'
			}
			onAddNode(nodeDoc)
			onAddLead(leadDoc)
		}
	}, [registrationAttemptStatus])

	useEffect(() => {
		if (
			userUpdateCompleted &&
			!registrationError &&
			addNodeSuccess &&
			newLead
		) {
			navigate('/restaurantpartners/sign-up/page-2')
		}
	}, [userUpdateCompleted, addNodeSuccess])

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (!user || !isLoaded) {
		return (
			<BounceLoader
				className='m-auto'
				color={bounceLoaderColor}
			></BounceLoader>
		)
	} else {
		if (user) {
			return (
				<div>
					<Header />
					<div
						id='signup'
						className='p-0 m-0 sm:p-10 lg:p-20'
						style={{
							backgroundImage: `url("https://i.imgur.com/N8Tqgz8.jpg")`,
							height: 'full',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
						}}
					>
						<div className='z-20 flex flex-col p-5 mx-auto max-w-[500px] bg-green-600 shadow-light-grey rounded-sm sm:max-w-[800px]'>
							<div
								className={`bg-white shadow-light-grey rounded-xl w-full ${
									loggedInVersion
										? 'min-h-[550px]'
										: 'min-h-[700px]'
								} overflow-y-auto scrollbar`}
							>
								<h1 className='font-header text-green-600 text-4xl mb-3 mt-6 text-center'>
									Sign Me Up
								</h1>

								<div
									className='flex justify-center mx-auto px-3 sm:mx-6 items-center text-center sm:text-left'>
									Save money, attract customers, and fight
									waste. Let your customers get your food in
									reusables. Fill out the form below to get
									started.
								</div>
								<div
									className='flex justify-center text-sm px-3 my-2 sm:mx-6 sm:text-left'>
									Already a DeliverZero merchant? {` `}<a className="cursor-pointer underline text-green-600">{` `}Log in here.</a>
								</div>
								<div className='text-gray-300 mt-2 text-[10px] flex px-3 sm:mx-6 text-left'>
									Time to complete: ~3min
								</div>
								{!loggedInVersion && (
									<form
										className='form-horizontal'
										onSubmit={handleSubmit}
									>
										<div className='w-full flex flex-col m-auto pt-3 px-6 sm:pt-6'>
											<div
												className='flex flex-col sm:flex sm:flex-row sm:justify-between sm:w-full'>
												<Input
													label='FIRST NAME *'
													error={
														incomplete &&
														!form['firstName']
													}
													name='firstName'
													className='bg-white border-2 sm:w-[150px] md:w-[200px] lg:w-[225px] border-gray-300 mb-3'
													onChange={handleChange}
												/>
												<Input
													label='LAST NAME *'
													error={
														incomplete &&
														!form['lastName']
													}
													name='lastName'
													className='bg-white border-2 sm:w-[150px] md:w-[200px] lg:w-[225px] border-gray-300 mb-3'
													onChange={handleChange}
												/>
												<Input
													label='TITLE/POSITION *'
													error={
														incomplete &&
														!form['title']
													}
													name='title'
													className='bg-white border-2 sm:w-[150px] md:w-[200px] lg:w-[225px] border-gray-300 mb-3'
													onChange={handleChange}
												/>
											</div>
											<Input
												type='name'
												label='RESTAURANT NAME *'
												error={
													incomplete &&
													!form['restaurantName']
												}
												name='restaurantName'
												className='bg-white border-2 border-gray-300 mb-3'
												onChange={handleChange}
											/>
											{showSearchBar ? (
												<Input
													label='RESTAURANT ADDRESS *'
													className='bg-white border-2 border-gray-300 mb-3'
													error={
														addressError ||
														(incomplete &&
															!geoCodedAddress)
													}
													autoCompleteAddress={true}
													value={searchedAddress}
													onChange={
														handleAddressChange
													}
													onFocus={() => {
														setIsFocused(true)
													}}
													onAddressSelect={
														handleAddressSelect
													}
													type='address'
													name='address'
												/>
											) : (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
											<Input
												type='phone'
												label='YOUR CONTACT PHONE NUMBER*'
												name='phone'
												error={
													(incomplete &&
														!form['phone']) ||
													phoneInvalid
												}
												className='bg-white border-2 border-gray-300 mb-3'
												onChange={handleChange}
												maxLength={10}
											/>
											<Input
												type='email'
												label='EMAIL *'
												name='email'
												error={
													emailError ||
													(incomplete &&
														!form['email'])
												}
												className='bg-white border-2 border-gray-300 mb-3'
												onChange={checkEmail}
											/>
											<div className='relative'>
												<Input
													type={
														showPassword
															? 'text'
															: 'password'
													}
													label='PASSWORD *'
													name='password'
													error={
														passwordStrengthError ||
														(incomplete &&
															!form['password'])
													}
													className='bg-white border-2 border-gray-300 mb-6 sm:mb-10'
													onChange={
														checkPasswordStrength
													}
												/>
												<div
													className='absolute right-4 top-[38px] sm:top-1/3 transform cursor-pointer'
													onClick={
														togglePasswordVisibility
													}
												>
													{showPassword ? (
														<FaEye className='h-6 w-6 text-gray-500'/>
													) : (
														<FaEyeSlash className='h-6 w-6 text-gray-500'/>
													)}
												</div>
											</div>
											{loadingAddNode ||
											loadingLead ||
											loadingUser ||
											registrationAttemptStatus ===
											'attempting' ? (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											) : existingDzUser ? (
												<Button
													className='mb-6 w-[200px]'
													size='sm'
													color='disabled'
													text='Save & Continue'
												/>
											) : (
												<Button
													className='mb-6 w-[200px]'
													size='sm'
													submit={handleSubmit}
													text='Save & Continue'
												/>
											)}
											{incomplete && (
												<p className='text-red-400 mb-6'>
													Error above. Missing or
													incorrect fields.
												</p>
											)}
											{passwordStrengthError && (
												<p className='text-red-400 mb-6'>
													Password must be at least 8
													characters long and contain
													at least one capital letter,
													one special character, and
													one number.
												</p>
											)}
											{emailError && (
												<p className='text-red-400 mb-6'>
													Please enter a valid email.
												</p>
											)}
											{phoneInvalid && (
												<p className='text-red-400 mb-6'>
													Please enter a valid phone
													number.
												</p>
											)}
											{addressError && (
												<p className='text-red-400 mb-6'>
													Please enter a valid
													address.
												</p>
											)}
											{addNodeError && (
												<p className='text-red-400 mb-6'>
													There was an error
													submitting this form. Please
													try again.
												</p>
											)}
											{((registrationError &&
													registrationError ===
													'phone-number-already-exists') ||
												existingDzUser) && (
												<Alert
													type='warning'
													content={
														<div>
															<p>
																This phone
																number is
																already
																associated with
																a Deliverzero
																account. Please
																use a different
																phone number or
																<span
																	onClick={
																		openSupport
																	}
																>
																	{' '}
																	contact our
																	support
																	team.
																</span>
															</p>
														</div>
													}
													className='text-sm mb-2 sm:mb-10'
												></Alert>
											)}
											{registrationError &&
												registrationError.error ===
												'name already in use' && (
													<Alert
														type='warning'
														content={
															<div>
																<p>
																	This email
																	address is
																	already
																	associated
																	with a
																	Deliverzero
																	account.
																	Please use a
																	different
																	email or
																	<span
																		onClick={
																			openSupport
																		}
																	>
																		{' '}
																		contact
																		our
																		support
																		team.
																	</span>
																</p>
															</div>
														}
														className='text-sm mb-2 sm:mb-10'
													></Alert>
												)}
										</div>
									</form>
								)}
								{loggedInVersion && (
									<form
										className='form-horizontal'
										onSubmit={handleSubmitLoggedInVersion}
									>
										<div className='w-full flex flex-col m-auto pt-3 px-6 sm:pt-6'>
											<Input
												type='name'
												label='RESTAURANT NAME *'
												error={
													incompleteLoggedInVersion &&
													!formLoggedInVersion[
														'restaurantName'
														]
												}
												name='restaurantName'
												className='bg-white border-2 border-gray-300 mb-3'
												onChange={handleChange}
											/>
											<Input
												label='TITLE/POSITION *'
												error={
													incompleteLoggedInVersion &&
													!formLoggedInVersion[
														'title'
														]
												}
												name='title'
												className='bg-white border-2 border-gray-300 mb-3'
												onChange={handleChange}
											/>
											{showSearchBar ? (
												<Input
													label='RESTAURANT ADDRESS *'
													className='bg-white border-2 border-gray-300 mb-6 sm:mb-10'
													error={
														addressError ||
														(incompleteLoggedInVersion &&
															!geoCodedAddress)
													}
													autoCompleteAddress={true}
													value={searchedAddress}
													onChange={
														handleAddressChange
													}
													onFocus={() => {
														setIsFocused(true)
													}}
													onAddressSelect={
														handleAddressSelect
													}
													type='address'
													name='address'
												/>
											) : (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
											{loadingAddNode ||
											loadingLead ||
											loadingUser ? (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											) : (
												<Button
													className='mb-6 w-[200px]'
													size='sm'
													submit={handleSubmit}
													text='Save & Continue'
												/>
											)}
											{incompleteLoggedInVersion && (
												<p className='text-red-400 mb-6'>
													Error above. Missing or
													incorrect fields.
												</p>
											)}
											{addressError && (
												<p className='text-red-400 mb-6'>
													Please enter a valid
													address.
												</p>
											)}
											{addNodeError ||
												(leadError && (
													<p className='text-red-400 mb-6'>
														There was an error
														submitting this form.
														Please try again.
													</p>
												))}
										</div>
									</form>
								)}
							</div>
						</div>
					</div>
					<Footer/>
				</div>
			)
		} else {
			navigate('/')
		}
	}
}

const mapStateToProps = ({Nodes, User, Account, Leads}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	user: User.user,
	userUpdateCompleted: User.userUpdateCompleted,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingAddNode: Nodes.loadingAddNode,
	addNodeError: Nodes.addNodeError,
	addNodeSuccess: Nodes.addNodeSuccess,
	registrationAttemptStatus: Account.registrationAttemptStatus,
	registrationError: Account.registrationError,
	registrationLoading: Account.loading,
	newLead: Leads.newLead,
	loadingLead: Leads.loading,
	leadError: Leads.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onAddNode: (nodeDoc) => dispatch(addNode(nodeDoc)),
	onRegisterUser: (user) => dispatch(registerUser(user)),
	onAddLead: (leadDoc) => dispatch(addLead(leadDoc)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage1)
