import { connect } from 'react-redux'
import { Footer, Header } from '../../components'
import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ReusablesCheckout from '../../components/ReusablesShop/ReusablesCheckout'

const ReusablesCheckoutPage = () => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

	return (
		<div>
			<Header />
			<Elements stripe={stripePromise}>
				<ReusablesCheckout />
			</Elements>
			<Footer />
		</div>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = () => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReusablesCheckoutPage)
