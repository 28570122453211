import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import {
	getAppInfo,
	getCart,
	getUser,
	updatePath,
	captureIP,
	captureGPS,
} from '../store/actions'
import { FaShoppingCart, FaChevronDown, FaBars } from 'react-icons/fa'
import { Button, Dropdown, Alert } from './Elements'
import { realm } from '../helpers/realm'
import LocationSearchBar from './Elements/LocationSearchBar'
import { getTransactions } from '../model/transactions'
import useOutsideClick from './Elements/DetectOutsideClick'
import { BsPersonCircle } from 'react-icons/bs'
import { useJsApiLoader } from '@react-google-maps/api'
import { googleMapsLibraries } from '../helpers/googleMapsAPI'
import { BounceLoader } from 'react-spinners'
import Banner from './Banner'

const Header = (props) => {
	const [openDropdown, setOpenDropdown] = useState(false)
	const [openAvatarDropdown, setOpenAvatarDropdown] = useState(false)
	const [openAvatarDropdown2, setOpenAvatarDropdown2] = useState(false)
	const [openHamburgerDropdown, setOpenHamburgerDropdown] = useState(false)
	const [openHamburgerDropdown2, setOpenHamburgerDropdown2] = useState(false)
	const [openHamburgerDropdown3, setOpenHamburgerDropdown3] = useState(false)
	const [openCartDropDown, setOpenCartDropDown] = useState(false)
	const [showSearchBar, setShowSearchBar] = useState(false)
	const [orderNowTag, setOrderNowTag] = useState('Link')
	const [locationWarning, setLocationWarning] = useState(false)
	const [dismissLocationWarning, setDismissLocationWarning] = useState(false)

	const {
		onGetUser,
		user,
		onUpdatePath,
		loggedIn,
		path,
		onGetCart,
		cartItems,
		cartTotal,
		lastActive,
		cartNodeSlug,
		onCaptureIP,
		onCaptureGPS,
	} = props
	const location = useLocation()
	const navigate = useNavigate()

	const bounceLoaderColor = '#507f74'

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: googleMapsLibraries,
	})

	useEffect(() => {
		onGetUser()
		const localStorageCart = JSON.parse(localStorage.getItem('activeCart'))
		const dismissLocationWarningLS = localStorage.getItem(
			'dismissLocationWarning'
		)
		// console.log('LS', dismissLocationWarningLS)

		setDismissLocationWarning(dismissLocationWarningLS === 'true')

		// console.log(localStorageCart)
		if (localStorageCart && localStorageCart.cartItems) {
			onGetCart(localStorageCart)
		} else if (user && user.activeCart) {
			onGetCart(user.activeCart)
		}

		if (user && user.phone) {
			onCaptureIP({ phone: user.phone })
			onCaptureGPS({ phone: user.phone })
		}
	}, [user])

	useEffect(() => {
		if (user && user.activeCart) {
			onGetCart(user.activeCart)
		}
	}, [user?.activeCart])

	useEffect(() => {
		const currentPath = location.pathname
		if (
			currentPath !== '/login' &&
			currentPath !== '/sign-up' &&
			currentPath !== '/sign-up-b' &&
			currentPath !== '/office-ordering/login' &&
			currentPath !== '/office-ordering/sign-up'
		) {
			onUpdatePath(currentPath)
		}
	}, [onUpdatePath])

	useEffect(() => {
		if (window.google && window.google.maps) {
			setShowSearchBar(true)
		}
	}, [window.google?.maps, isLoaded])

	const handleLogout = () => {
		if (realm.currentUser) {
			realm.currentUser.logOut().then(() => {
				if (path == '/') {
					window.location.reload()
				} else {
					navigate('/')
					window.location.reload()
				}
			})
		}
	}

	const openSupport = () => {
		window.Intercom('show')
	}
	// console.log('in header')

	const headerRef = useRef()

	useOutsideClick(headerRef, () => {
		setOpenDropdown(false)
		setOpenAvatarDropdown(false)
		setOpenAvatarDropdown2(false)
		setOpenHamburgerDropdown(false)
		setOpenHamburgerDropdown2(false)
		setOpenHamburgerDropdown3(false)
	})

	const handleLocationWarningChange = (newWarning) => {
		setLocationWarning(newWarning)
	}

	// const lastSearchedAddress = JSON.parse(
	// 	localStorage.getItem('lastSearchedAddress')
	// )
	// console.log(dismissLocationWarning)

	useEffect(() => {
		const dismissLocationWarningLS = localStorage.getItem(
			'dismissLocationWarning'
		)
		if (dismissLocationWarning.toString() !== dismissLocationWarningLS) {
			// console.log('here')
			localStorage.setItem(
				'dismissLocationWarning',
				dismissLocationWarning.toString()
			)
		}
	}, [dismissLocationWarning])

	return (
		<div className='sticky top-0 sm:static z-40'>
			<Banner />
			{locationWarning && !dismissLocationWarning && (
				<Alert
					content='You have location services turned off. You may continue by searching an address, but certain map features may behave differently.'
					type='warning'
					dismissAlert={true}
					setDismissAlert={setDismissLocationWarning}
				/>
			)}
			<header className='h-header w-full hidden header-break:flex px-2 py-2 items-center justify-between font-poppins bg-white border-2 border-gray-200'>
				<div>
					<Link
						to='/'
						className='flex h-20 flex-shrink-0 mr-[min(12px,12rem)] '
					>
						<img
							alt=''
							className='ml-4 mr-3 min-w-[100px] my-auto h-2/3'
							src='/images/Small primary.svg'
						/>
					</Link>
				</div>
				<div className='flex items-center justify-end'>
					{showSearchBar ? (
						<LocationSearchBar
							className='mr-4 h-full'
							inputBackgroundColor='gray-200'
							size='sm'
							onLocationWarningChange={
								handleLocationWarningChange
							}
						/>
					) : (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						/>
					)}
					<div className=' h-full lg:w-1/4 max-w-sm items-center justify-around px-4 tracking-wider flex text-green-600 font-bold'>
						<Link
							to='/Returns'
							className='whitespace-nowrap'
						>
							Return Containers
						</Link>
					</div>
					<div>
						<div className='relative px-4'>
							<div
								className='whitespace-nowrap hover:text-green-600 hidden xl:block'
								onClick={() => setOpenDropdown(true)}
								onMouseEnter={() => setOpenDropdown(true)}
							>
								Learn More{' '}
								<FaChevronDown
									className={`inline text-sm transition-colors duration-150 ease-in-out${
										openDropdown
											? 'origin-center rotate-180'
											: ''
									}`}
								/>
							</div>
							{openDropdown && (
								<div ref={headerRef}>
									<Dropdown
										onMouseLeave={() =>
											setOpenDropdown(false)
										}
										className='absolute z-20'
										options={[
											{
												id: '0',
												link: '/membership',
												text: 'DeliverZero+',
											},
											// {
											// 	id: '1',
											// 	link: '/rewards-marketplace',
											// 	text: 'Rewards Marketplace',
											// },
											{
												id: '2',
												link: '/howitworks',
												text: 'How It Works',
											},
											{
												id: '3',
												subMenu: [
													{
														id: '3.1',
														link: '/restaurantpartners',
														text: 'For Restaurant Partners',
														textClassName:
															'text-sm',
													},
													{
														id: '3.2',
														link: '/office-ordering/sign-up',
														text: 'DeliverZero at Work',
														textClassName:
															'text-sm',
													},
													{
														id: '3.3',
														link: '/raas-landing',
														text: 'Reuse for your Business',
														textClassName:
															'text-sm',
													},
													{
														id: '3.4',
														link: '/ambassador-program',
														text: 'Become an Ambassador',
														textClassName:
															'text-sm',
													},
												],
												text: 'Work With Us',
											},
											{
												id: '4',
												onClick: openSupport,
												text: 'Contact Support',
											},
											{
												id: '5',
												link: '/reusables-shop',
												text: 'Shop',
											},
										]}
									/>
								</div>
							)}
						</div>
					</div>
					<div className='relative ml-4 mr-8'>
						<div className='flex flex-col items-center'>
							{cartNodeSlug != '' && cartItems ? (
								<>
									<Link
										to={`/marketplace/${cartNodeSlug}`}
										onMouseEnter={() =>
											setOpenCartDropDown(true)
										}
									>
										<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
									</Link>

									{openCartDropDown && (
										<div ref={headerRef}>
											<Dropdown
												onMouseLeave={() =>
													setOpenCartDropDown(false)
												}
												className='absolute z-20'
												options={[
													{
														id: '1',
														link: `/marketplace/${cartNodeSlug}`,
														text: 'Restaurant Cart',
													},
													{
														id: '2',
														link: '/reusables-shop',
														text: 'Shop Cart',
													},
												]}
											/>
										</div>
									)}
								</>
							) : (
								<>
									<Link
										to='/marketplace/locations'
										onMouseEnter={() =>
											setOpenCartDropDown(true)
										}
									>
										<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
									</Link>
									{openCartDropDown && (
										<div ref={headerRef}>
											<Dropdown
												onMouseLeave={() =>
													setOpenCartDropDown(false)
												}
												className='absolute z-20'
												options={[
													{
														id: '1',
														link: '/marketplace/locations',
														text: 'Shop Restaurants',
													},
													{
														id: '2',
														link: '/reusables-shop',
														text: 'Shop Reusables',
													},
												]}
											/>
										</div>
									)}
								</>
							)}
						</div>
					</div>

					{loggedIn && (
						<div className='relative ml-4 mr-8'>
							<div className='flex flex-col items-center'>
								<Link
									to='/user/account'
									onMouseEnter={() =>
										setOpenAvatarDropdown(true)
									}
								>
									<BsPersonCircle className='flex text-red-400 text-[35px]' />
								</Link>
							</div>
							{openAvatarDropdown && (
								<div ref={headerRef}>
									<Dropdown
										onMouseLeave={() =>
											setOpenAvatarDropdown(false)
										}
										className='absolute z-20'
										options={[
											{
												id: '1',
												link: '/user/account',
												text: 'My Account',
											},
											{
												id: '2',
												onClick: openSupport,
												text: 'Contact Support',
											},
											{
												id: '3',
												onClick: handleLogout,
												text: 'Log Out',
											},
										]}
									/>
								</div>
							)}
						</div>
					)}

					{!loggedIn && (
						<>
							<Button
								link='/login'
								text='Login'
								size='sm'
								color='red-outlined'
								className='mr-4 block font-semibold relative'
							/>
							<Button
								// link={new Date().getMinutes() % 2 === 0 ? '/sign-up' : '/sign-up-b'}
								link='/sign-up'
								text='Sign Up'
								size='sm'
								color='red'
								className='block font-semibold'
							/>
						</>
					)}
				</div>
				{/*</div>*/}
				{/*</div>*/}
			</header>

			{/*/////////////
         Header between header-break-hide-return and header-break
         ////////////////*/}
			<header className='h-header w-full hidden header-break-hide-return:flex header-break:hidden px-2 py-2 items-center justify-between font-poppins bg-white border-2 border-gray-200'>
				<div>
					<Link
						to='/'
						className='flex h-20 flex-shrink-0 '
					>
						<img
							alt=''
							className='ml-4 mr-4 max-w-[100px] my-auto h-2/3'
							src='/images/Small primary.svg'
						/>
					</Link>
				</div>
				{showSearchBar ? (
					<LocationSearchBar
						className='h-full'
						inputBackgroundColor='gray-200'
						size='sm'
						onLocationWarningChange={handleLocationWarningChange}
					/>
				) : (
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					/>
				)}
				<div className=' h-full w-1/4 max-w-sm items-center justify-around tracking-wider md:flex'>
					<Link
						to='/Returns'
						className='whitespace-nowrap'
					>
						Return Containers
					</Link>
				</div>
				<div>
					<FaBars
						onClick={() => {
							setOpenHamburgerDropdown(!openHamburgerDropdown)
						}}
						className='mr-2 text-4xl text-green-600 cursor-pointer hover:text-green-300'
					/>
					{openHamburgerDropdown && (
						<div>
							{!loggedIn && (
								<div ref={headerRef}>
									<Dropdown
										className='absolute z-20'
										options={[
											{
												id: '1',
												link: '/login',
												text: 'Login',
											},
											{
												id: '2',
												// link={new Date().getMinutes() % 2 === 0 ? '/sign-up' : '/sign-up-b'}
												link: '/sign-up',
												text: 'Sign Up',
											},
											{
												id: '3',
												link:
													cartNodeSlug != '' &&
													cartItems
														? `/marketplace/${cartNodeSlug}`
														: '/marketplace/locations',
												text: 'My Cart',
												icon:
													cartNodeSlug != '' &&
													cartItems ? (
														<div className='flex flex-row items-center'>
															<Link
																to={`/marketplace/${cartNodeSlug}`}
															>
																<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
															</Link>
															<div className='text-green-600 font-semibold ml-1'>
																{
																	cartItems.length
																}
															</div>
														</div>
													) : (
														<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
													),
											},
											{
												id: '4',
												link: '/membership',
												text: 'DeliverZero+',
											},
											// {
											// 	id: '5',
											// 	link: '/rewards-marketplace',
											// 	text: 'Rewards Marketplace',
											// },
											{
												id: '6',
												link: '/howitworks',
												text: 'How It Works',
											},
											{
												id: '7',
												subMenu: [
													{
														id: '7.1',
														link: '/restaurantpartners',
														text: 'For Restaurant Partners',
														textClassName:
															'text-sm',
													},
													{
														id: '7.2',
														link: '/office-ordering/sign-up',
														text: 'DeliverZero at Work',
														textClassName:
															'text-sm',
													},
													{
														id: '7.3',
														link: '/raas-landing',
														text: 'Reuse for your Business',
														textClassName:
															'text-sm',
													},
													{
														id: '7.4',
														link: '/ambassador-program',
														text: 'Become an Ambassador',
														textClassName:
															'text-sm',
													},
												],
												text: 'Work With Us',
											},
											{
												id: '8',
												onClick: openSupport,
												text: 'Contact Support',
											},
											{
												id: '9',
												link: '/reusables-shop',
												text: 'Shop',
											},
										]}
									/>
								</div>
							)}
							{loggedIn && (
								<div ref={headerRef}>
									<Dropdown
										className='absolute z-20'
										options={[
											{
												id: '1',
												link:
													cartNodeSlug != '' &&
													cartItems
														? `/marketplace/${cartNodeSlug}`
														: '/marketplace/locations',
												text: 'My Cart',
												icon:
													cartNodeSlug != '' &&
													cartItems ? (
														<div className='flex flex-row items-center'>
															<Link
																to={`/marketplace/${cartNodeSlug}`}
															>
																<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
															</Link>
															<div className='text-green-600 font-semibold ml-1'>
																{
																	cartItems.length
																}
															</div>
														</div>
													) : (
														<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
													),
											},
											{
												id: '2',
												link: '/user/account',
												text: 'My Account',
											},
											{
												id: '3',
												link: '/membership',
												text: 'DeliverZero+',
											},
											// {
											// 	id: '4',
											// 	link: '/rewards-marketplace',
											// 	text: 'Rewards Marketplace',
											// },
											{
												id: '5',
												link: '/howitworks',
												text: 'How It Works',
											},
											{
												id: '6',
												subMenu: [
													{
														id: '6.1',
														link: '/restaurantpartners',
														text: 'For Restaurant Partners',
														textClassName:
															'text-sm',
													},
													{
														id: '6.2',
														link: '/office-ordering/sign-up',
														text: 'DeliverZero at Work',
														textClassName:
															'text-sm',
													},
													{
														id: '6.3',
														link: '/raas-landing',
														text: 'Reuse for your Business',
														textClassName:
															'text-sm',
													},
													{
														id: '6.4',
														link: '/ambassador-program',
														text: 'Become an Ambassador',
														textClassName:
															'text-sm',
													},
												],
												text: 'Work With Us',
											},
											{
												id: '7',
												link: '/reusables-shop',
												text: 'Shop',
											},
											{
												id: '8',
												onClick: openSupport,
												text: 'Contact Support',
											},
											{
												id: '9',
												onClick: handleLogout,
												text: 'Log Out',
											},
										]}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</header>

			{/*/////////////
         Header between sm and header-break-hide-return
         ////////////////*/}
			<header className='h-header w-full hidden sm:flex header-break-hide-return:hidden px-2 py-2 items-center justify-between font-poppins bg-white border-2 border-gray-200'>
				<div>
					<Link
						to='/'
						className='flex h-20 flex-shrink-0 '
					>
						<img
							alt=''
							className='ml-4 mr-2 max-w-[100px] my-auto h-2/3'
							src='/images/Small primary.svg'
						/>
					</Link>
				</div>
				{showSearchBar ? (
					<LocationSearchBar
						className='h-full'
						inputBackgroundColor='gray-200'
						size='sm'
						onLocationWarningChange={handleLocationWarningChange}
					/>
				) : (
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					/>
				)}
				<div>
					<FaBars
						onClick={() => {
							setOpenHamburgerDropdown2(!openHamburgerDropdown2)
						}}
						className='mr-2 text-4xl text-green-600 cursor-pointer hover:text-green-300'
					/>
					{openHamburgerDropdown2 && (
						<div>
							{!loggedIn && (
								<div ref={headerRef}>
									<Dropdown
										className='absolute z-20'
										options={[
											{
												id: '1',
												link: '/returns/',
												text: 'Return Containers',
											},
											{
												id: '2',
												link:
													cartNodeSlug != '' &&
													cartItems
														? `/marketplace/${cartNodeSlug}`
														: '/marketplace/locations',
												text: 'My Cart',
												icon:
													cartNodeSlug != '' &&
													cartItems ? (
														<div className='flex flex-row items-center'>
															<Link
																to={`/marketplace/${cartNodeSlug}`}
															>
																<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
															</Link>
															<div className='text-green-600 font-semibold ml-1'>
																{
																	cartItems.length
																}
															</div>
														</div>
													) : (
														<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
													),
											},
											{
												id: '3',
												link: '/login',
												text: 'Login',
											},
											{
												id: '4',
												// link={new Date().getMinutes() % 2 === 0 ? '/sign-up' : '/sign-up-b'}
												link: '/sign-up',
												text: 'Sign Up',
											},
											{
												id: '5',
												link: '/membership',
												text: 'DeliverZero+',
											},
											// {
											// 	id: '6',
											// 	link: '/rewards-marketplace',
											// 	text: 'Rewards Marketplace',
											// },
											{
												id: '7',
												link: '/howitworks',
												text: 'How It Works',
											},
											{
												id: '8',
												subMenu: [
													{
														id: '8.1',
														link: '/restaurantpartners',
														text: 'For Restaurant Partners',
														textClassName:
															'text-sm',
													},
													{
														id: '8.2',
														link: '/office-ordering/sign-up',
														text: 'DeliverZero at Work',
														textClassName:
															'text-sm',
													},
													{
														id: '8.3',
														link: '/raas-landing',
														text: 'Reuse for your Business',
														textClassName:
															'text-sm',
													},
													{
														id: '8.4',
														link: '/ambassador-program',
														text: 'Become an Ambassador',
														textClassName:
															'text-sm',
													},
												],
												text: 'Work With Us',
											},
											{
												id: '9',
												link: '/reusables-shop',
												text: 'Shop',
											},
											{
												id: '10',
												onClick: openSupport,
												text: 'Contact Support',
											},
										]}
									/>
								</div>
							)}
							{loggedIn && (
								<div ref={headerRef}>
									<Dropdown
										className='absolute z-20'
										options={[
											{
												id: '1',
												link: '/returns/',
												text: 'Return Containers',
											},
											{
												id: '2',
												link:
													cartNodeSlug != '' &&
													cartItems
														? `/marketplace/${cartNodeSlug}`
														: '/marketplace/locations',
												text: 'My Cart',
												icon:
													cartNodeSlug != '' &&
													cartItems ? (
														<div className='flex flex-row items-center'>
															<Link
																to={`/marketplace/${cartNodeSlug}`}
															>
																<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
															</Link>
															<div className='text-green-600 font-semibold ml-1'>
																{
																	cartItems.length
																}
															</div>
														</div>
													) : (
														<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
													),
											},
											{
												id: '3',
												link: '/user/account',
												text: 'My Account',
											},
											{
												id: '4',
												link: '/membership',
												text: 'DeliverZero+',
											},
											// {
											// 	id: '5',
											// 	link: '/rewards-marketplace',
											// 	text: 'Rewards Marketplace',
											// },
											{
												id: '6',
												link: '/howitworks',
												text: 'How It Works',
											},
											{
												id: '7',
												subMenu: [
													{
														id: '7.1',
														link: '/restaurantpartners',
														text: 'For Restaurant Partners',
														textClassName:
															'text-sm',
													},
													{
														id: '7.2',
														link: '/office-ordering/sign-up',
														text: 'DeliverZero at Work',
														textClassName:
															'text-sm',
													},
													{
														id: '7.3',
														link: '/raas-landing',
														text: 'Reuse for your Business',
														textClassName:
															'text-sm',
													},
													{
														id: '7.4',
														link: '/ambassador-program',
														text: 'Become an Ambassador',
														textClassName:
															'text-sm',
													},
												],
												text: 'Work With Us',
											},
											{
												id: '8',
												link: '/reusables-shop',
												text: 'Shop',
											},
											{
												id: '9',
												onClick: openSupport,
												text: 'Contact Support',
											},
											{
												id: '10',
												onClick: handleLogout,
												text: 'Log Out',
											},
										]}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</header>

			{/*/////////////
         Header all below sm
         ////////////////*/}
			<div className=''>
				<header className='h-[3.25rem] w-full flex sm:hidden px-2 py-2 items-center justify-between font-poppins bg-white border-2 border-gray-200'>
					<div>
						<FaBars
							onClick={() => {
								setOpenHamburgerDropdown3(
									!openHamburgerDropdown3
								)
							}}
							className='ml-2 mr-2 text-3xl text-green-600 cursor-pointer hover:text-green-300'
						/>
						{openHamburgerDropdown3 && (
							<div>
								{!loggedIn && (
									<div ref={headerRef}>
										<Dropdown
											align='left'
											className='absolute z-20'
											options={[
												{
													id: '1',
													link: '/marketplace/locations/',
													text: 'Order Food',
												},
												{
													id: '2',
													link: '/returns/',
													text: 'Return Containers',
												},
												{
													id: '3',
													link:
														cartNodeSlug != '' &&
														cartItems
															? `/marketplace/${cartNodeSlug}`
															: '/marketplace/locations',
													text: 'My Cart',
													icon:
														cartNodeSlug != '' &&
														cartItems ? (
															<div className='flex flex-row items-center'>
																<Link
																	to={`/marketplace/${cartNodeSlug}`}
																>
																	<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
																</Link>
																<div className='text-green-600 font-semibold ml-1'>
																	{
																		cartItems.length
																	}
																</div>
															</div>
														) : (
															<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
														),
												},
												{
													id: '4',
													link: '/membership',
													text: 'DeliverZero+',
												},
												// {
												// 	id: '5',
												// 	link: '/rewards-marketplace',
												// 	text: 'Rewards Marketplace',
												// },
												{
													id: '6',
													link: '/howitworks',
													text: 'How It Works',
													textClassName: 'text-md',
												},
												{
													id: '7',
													subMenu: [
														{
															id: '7.1',
															link: '/restaurantpartners',
															text: 'For Restaurant Partners',
															textClassName:
																'text-sm',
														},
														{
															id: '7.2',
															link: '/office-ordering/sign-up',
															text: 'DeliverZero at Work',
															textClassName:
																'text-sm',
														},
														{
															id: '7.3',
															link: '/raas-landing',
															text: 'Reuse for your Business',
															textClassName:
																'text-sm',
														},
														{
															id: '7.4',
															link: '/ambassador-program',
															text: 'Become an Ambassador',
															textClassName:
																'text-sm',
														},
													],
													text: 'Work With Us',
												},
												{
													id: '8',
													link: '/reusables-shop',
													text: 'Shop',
												},
												{
													id: '9',
													onClick: openSupport,
													text: 'Contact Support',
												},
											]}
										/>
									</div>
								)}
								{loggedIn && (
									<div ref={headerRef}>
										<Dropdown
											align='left'
											className='absolute z-20'
											options={[
												{
													id: '1',
													link: '/marketplace/locations/',
													text: 'Order Food',
												},
												{
													id: '2',
													link: '/returns/',
													text: 'Return Containers',
												},
												{
													id: '3',
													link:
														cartNodeSlug != '' &&
														cartItems
															? `/marketplace/${cartNodeSlug}`
															: '/marketplace/locations',
													text: 'My Cart',
													icon:
														cartNodeSlug != '' &&
														cartItems ? (
															<div className='flex flex-row items-center'>
																<Link
																	to={`/marketplace/${cartNodeSlug}`}
																>
																	<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
																</Link>
																<div className='text-green-600 font-semibold ml-1'>
																	{
																		cartItems.length
																	}
																</div>
															</div>
														) : (
															<FaShoppingCart className='text-3xl text-green-600 hover:text-green-400' />
														),
												},
												{
													id: '4',
													link: '/membership',
													text: 'DeliverZero+',
												},
												// {
												// 	id: '5',
												// 	link: '/rewards-marketplace',
												// 	text: 'Rewards Marketplace',
												// },
												{
													id: '6',
													link: '/howitworks',
													text: 'How It Works',
													textClassName: 'text-md',
												},
												{
													id: '7',
													subMenu: [
														{
															id: '7.1',
															link: '/restaurantpartners',
															text: 'For Restaurant Partners',
															textClassName:
																'text-sm',
														},
														{
															id: '7.2',
															link: '/office-ordering/sign-up',
															text: 'DeliverZero at Work',
															textClassName:
																'text-sm',
														},
														{
															id: '7.3',
															link: '/raas-landing',
															text: 'Reuse for your Business',
															textClassName:
																'text-sm',
														},
														{
															id: '7.4',
															link: '/ambassador-program',
															text: 'Become an Ambassador',
															textClassName:
																'text-sm',
														},
													],
													text: 'Work With Us',
												},
												{
													id: '8',
													link: '/reusables-shop',
													text: 'Shop',
												},
												{
													id: '9',
													onClick: openSupport,
													text: 'Contact Support',
												},
											]}
										/>
									</div>
								)}
							</div>
						)}
					</div>
					<div>
						<Link
							to='/'
							className='flex h-16 flex-shrink-0'
						>
							<img
								alt=''
								className='ml-4 mr-2 max-w-[100px] my-auto h-2/3'
								src='/images/Small primary.svg'
							/>
						</Link>
					</div>

					{loggedIn && (
						<div className='relative ml-4 mr-2'>
							<div className='flex flex-col items-center'>
								<button
									onClick={() =>
										setOpenAvatarDropdown2(
											!openAvatarDropdown2
										)
									}
									onMouseEnter={() =>
										setOpenAvatarDropdown2(true)
									}
								>
									<BsPersonCircle className='flex text-red-400 text-[35px]' />
								</button>
							</div>
							{openAvatarDropdown2 && (
								<div ref={headerRef}>
									<Dropdown
										onMouseLeave={() =>
											setOpenAvatarDropdown2(false)
										}
										className='absolute z-20'
										options={[
											{
												id: '1',
												link: '/user/account',
												text: 'My Account',
											},
											{
												id: '2',
												onClick: openSupport,
												text: 'Contact Support',
											},
											{
												id: '3',
												onClick: handleLogout,
												text: 'Log Out',
											},
										]}
									/>
								</div>
							)}
						</div>
					)}

					{!loggedIn && (
						<div className='relative ml-4 mr-2'>
							<div className='flex flex-col items-center'>
								<button
									onClick={() =>
										setOpenAvatarDropdown2(
											!openAvatarDropdown2
										)
									}
									onMouseEnter={() =>
										setOpenAvatarDropdown2(true)
									}
								>
									<BsPersonCircle className='flex text-red-400 text-[35px]' />
								</button>
							</div>
							{openAvatarDropdown2 && (
								<div ref={headerRef}>
									<Dropdown
										onMouseLeave={() =>
											setOpenAvatarDropdown2(false)
										}
										className='absolute z-20'
										options={[
											{
												id: '1',
												link: '/login',
												text: 'Login',
											},
											{
												id: '2',
												// link={new Date().getMinutes() % 2 === 0 ? '/sign-up' : '/sign-up-b'}
												link: '/sign-up',
												text: 'Sign Up',
											},
											{
												id: '3',
												onClick: openSupport,
												text: 'Contact Support',
											},
										]}
									/>
								</div>
							)}
						</div>
					)}
				</header>
				<div className='flex justify-center sm:hidden py-1 bg-white'>
					{showSearchBar ? (
						<LocationSearchBar
							className='m-auto w-5/6 min-w-[350px]'
							inputBackgroundColor='gray-200'
							size='xs'
							onLocationWarningChange={
								handleLocationWarningChange
							}
						/>
					) : (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, AppInfo, Path, Cart }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	appInfo: AppInfo.appInfo,
	path: Path.path,
	cartItems: Cart.cartItems,
	cartTotal: Cart.cartTotal,
	lastActive: Cart.lastActive,
	cartNodeSlug: Cart.nodeSlug,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onUpdatePath: (path) => dispatch(updatePath(path)),
	onGetCart: (cart) => dispatch(getCart(cart)),
	onCaptureIP: (payload) => dispatch(captureIP(payload)),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
