import {
	CardElement,
	Elements,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

import { Footer, Header } from '../../components'
import { getUser } from '../../store/auth/user/actions'
import { getRestaurantCharges } from '../../store/restaurantPayments/actions'
import { getNodeByAdminCodeOrSlug, updateNode } from '../../store/nodes/actions'
import { connect } from 'react-redux'
import {
	getPaymentMethods,
	resetCardAdded,
	storePaymentMethod,
} from '../../store/payments/actions'
import { Button, Input, RadioGroup } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'

const RestaurantCreditCardForm = (state) => {
	const {
		user,
		loadingUser,
		onGetUser,
		loadingAddition,
		onStorePaymentMethod,
		updatingNode,
		updateNodeCompleted,
		updateNodeError,
		onUpdateNode,
		cardAdded,
		cardAddedError,
		onGetPaymentMethods,
		paymentMethods,
		loadingPaymentMethods,
		onGetNodeBySlug,
		node,
	} = state

	const bounceLoaderColor = '#507f74'
	const [stripeLoading, setStripeLoading] = useState(false)
	const [addCardError, setAddCardError] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [cardRadios, setCardRadios] = useState([])
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('')
	const stripe = useStripe()
	const elements = useElements()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const handleAddCardSubmit = async (event) => {
		event.preventDefault()
		if (emailAddress === '') {
			setEmailError('Please enter in an email address')
		} else {
			setStripeLoading(true)
			setAddCardError(false)
			if (elements == null) {
				return
			}

			const result = await stripe.createPaymentMethod({
				type: 'card',
				card: elements.getElement(CardElement),
			})
			if (result.error) {
				setStripeLoading(false)
				setAddCardError(true)
			} else {
				setStripeLoading(false)
				onStorePaymentMethod({
					restaurantAdmin: true,
					nodeSlug: user.restaurantAdmin,
					stripeCustomerId: node.stripeId,
					paymentMethodId: result.paymentMethod.id,
					desiredEmail: emailAddress,
				})
			}
		}
	}

	// useEffect(() => {
	//     if(cardAdded){
	//         onGetPaymentMethods(node.stripeId)
	//     }
	// }, [cardAdded])

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [user])

	useEffect(() => {
		// console.log('here')
		if (node && node.stripeId) {
			// console.log('here 2')
			if (node.paymentMethods && node.paymentMethods.length > 0) {
				// console.log('here 3')
				if (node.paymentMethods.length === 1) {
					setDefaultPaymentMethod(node.paymentMethods[0].id)
				}
				makeCardRadios(node.paymentMethods)
			}
		}
		if (node && node.email) {
			setEmailAddress(node.email)
		}
	}, [JSON.stringify(node), JSON.stringify(node?.paymentMethods)])

	const makeCardRadios = (paymentMethods) => {
		let radios = []
		paymentMethods.forEach(function (paymentMethod, index) {
			// console.log(paymentMethod.id === node.defaultPaymentMethod)
			radios.push({
				value: paymentMethod.id,
				label:
					paymentMethod.id === node.defaultPaymentMethod
						? `${paymentMethod.card.brand.toUpperCase()} ....${
								paymentMethod.card.last4
						  } - Exp. ${paymentMethod.card.exp_month}/${
								paymentMethod.card.exp_year
						  } - DEFAULT`
						: `${paymentMethod.card.brand.toUpperCase()} ....${
								paymentMethod.card.last4
						  } - Exp. ${paymentMethod.card.exp_month}/${
								paymentMethod.card.exp_year
						  }`,
				// checked: paymentMethod.id === node.defaultPaymentMethod,
			})
		})
		setCardRadios(radios)
	}

	useEffect(() => {
		if (defaultPaymentMethod !== '') {
			onUpdateNode({
				nodeSlug: user.restaurantAdmin,
				update: {
					defaultPaymentMethod: defaultPaymentMethod,
				},
			})
		}
	}, [defaultPaymentMethod])

	useEffect(() => {
		if (updateNodeCompleted) {
			onGetNodeBySlug(user.restaurantAdmin)
		}
	}, [updateNodeCompleted])

	useEffect(() => {
		onGetNodeBySlug(user.restaurantAdmin)
	}, [JSON.stringify(paymentMethods)])

	// console.log(node.paymentMethods)

	if (node) {
		return (
			<div>
				{cardAddedError && (
					<div className='text-red-400 font-bold'>
						An error occurred while adding your card! Contact
						DeliverZero for help.
					</div>
				)}
				<form
					className='w-[330px]'
					onSubmit={handleAddCardSubmit}
				>
					{(!node.email || node.email === '') && (
						<Input
							type=''
							label='EMAIL'
							name='email-address'
							onChange={(e) => {
								setEmailAddress(e.target.value)
							}}
							className='bg-white border-2 border-gray-300 mb-3 md:w-full lg:w-5/6'
						/>
					)}
					<CardElement options={cardElementOptions} />
					{!loadingAddition &&
						!stripeLoading &&
						!updatingNode &&
						!loadingUser && (
							<div>
								<Button
									className='mt-2'
									text='Add Card'
									size='sm'
									submit={true}
								/>
							</div>
						)}
				</form>
				{(loadingPaymentMethods ||
					loadingAddition ||
					stripeLoading ||
					loadingUser ||
					loadingPaymentMethods) && (
					<div className='mt-3 text-center'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						/>
					</div>
				)}

				{!loadingAddition &&
					!stripeLoading &&
					!loadingUser &&
					!loadingPaymentMethods && (
						<div className='mt-6'>
							<div className='mt-8 font-bold text-base'>
								Select your default payment
							</div>
							{cardRadios.length > 0 ? (
								<div>
									<RadioGroup
										className='mt-8 sm:mt-0'
										name='sortBy'
										radios={cardRadios}
										onChange={(paymentMethodId) => {
											setDefaultPaymentMethod(
												paymentMethodId
											)
										}}
									/>
								</div>
							) : (
								<div>
									<div className='font-semibold'>
										No cards on file
									</div>
								</div>
							)}
						</div>
					)}
			</div>
		)
	} else {
		return (
			<div className='flex items-center h-screen-no-header'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				/>
			</div>
		)
	}
}

const mapStateToProps = ({ User, Payments, Nodes }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
	node: Nodes.restaurantAdminNode,
	updatingNode: Nodes.updatingNode,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	updateNodeError: Nodes.updateNodeError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onGetNodeBySlug: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onResetCardAdded: () => dispatch(resetCardAdded()),
	onGetPaymentMethods: (userStripeId) =>
		dispatch(getPaymentMethods(userStripeId)),
	onUpdateNode: (updatePayload) => dispatch(updateNode(updatePayload)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantCreditCardForm)
