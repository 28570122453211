import { call, put, takeEvery } from 'redux-saga/effects'
import {
	CANCEL_FIRST_DELIVERY,
	GET_RL_PARTNER,
	GET_RL_PARTNERS,
	GET_RL_PARTNERS_BY_POLYGON,
	GET_RL_PARTNERS_BY_ZIP,
	GET_THIRD_PARTY_PARTNERS,
} from './actionTypes'
import {
	cancelFirstDeliveryPickup,
	getRlPartner,
	getRlPartners,
	getRlPartnersByPolygon,
	getRlPartnersByZip,
	getThirdPartyPickupOptions,
} from '../../model/reverseLogistics'
import {
	cancelFirstDeliveryPickupFailure,
	cancelFirstDeliveryPickupSuccess,
	getRlPartnerFailure,
	getRlPartnersByPolygonFailure,
	getRlPartnersByPolygonSuccess,
	getRlPartnersByZipFailure,
	getRlPartnersByZipSuccess,
	getRlPartnersFailure,
	getRlPartnersSuccess,
	getRlPartnerSuccess,
	getThirdPartyPartnersFailure,
	getThirdPartyPartnersSuccess,
} from './actions'

function* fetchPartnersByZip(action) {
	try {
		const partners = yield call(getRlPartnersByZip, action.payload)
		yield put(getRlPartnersByZipSuccess(partners))
	} catch (error) {
		yield put(getRlPartnersByZipFailure(error))
	}
}

function* fetchPartnersByPolygon(action) {
	try {
		const partners = yield call(getRlPartnersByPolygon, action.payload)
		yield put(getRlPartnersByPolygonSuccess(partners))
	} catch (error) {
		yield put(getRlPartnersByPolygonFailure(error))
	}
}

function* fetchPartners(action) {
	try {
		const partners = yield call(getRlPartners, action.payload)
		yield put(getRlPartnersSuccess(partners))
	} catch (error) {
		yield put(getRlPartnersFailure(error))
	}
}

function* fetchPartner(action) {
	try {
		const partner = yield call(getRlPartner, action.payload)
		yield put(getRlPartnerSuccess(partner))
	} catch (error) {
		yield put(getRlPartnerFailure(error))
	}
}

function* cancelFirstDelivery(action) {
	try {
		const partner = yield call(cancelFirstDeliveryPickup, action.payload)
		yield put(cancelFirstDeliveryPickupSuccess(partner))
	} catch (error) {
		yield put(cancelFirstDeliveryPickupFailure(error))
	}
}

function* getThirdPartyPartners(action) {
	try {
		const partners = yield call(getThirdPartyPickupOptions, action.payload)
		yield put(getThirdPartyPartnersSuccess(partners))
	} catch (error) {
		yield put(getThirdPartyPartnersFailure(error))
	}
}

function* reverseLogisticsSaga() {
	yield takeEvery(GET_RL_PARTNERS_BY_ZIP, fetchPartnersByZip)
	yield takeEvery(GET_RL_PARTNERS_BY_POLYGON, fetchPartnersByPolygon)
	yield takeEvery(GET_RL_PARTNERS, fetchPartners)
	yield takeEvery(GET_RL_PARTNER, fetchPartner)
	yield takeEvery(CANCEL_FIRST_DELIVERY, cancelFirstDelivery)
	yield takeEvery(GET_THIRD_PARTY_PARTNERS, getThirdPartyPartners)
}

export default reverseLogisticsSaga
