import {
	GET_RAAS_MERCHANT,
	GET_RAAS_MERCHANT_SUCCESS,
	GET_RAAS_MERCHANT_ERROR,
	GET_RAAS_CUSTOMER,
	GET_RAAS_CUSTOMER_SUCCESS,
	GET_RAAS_CUSTOMER_ERROR,
	INSERT_RAAS_TRANSACTION,
	INSERT_RAAS_TRANSACTION_SUCCESS,
	INSERT_RAAS_TRANSACTION_ERROR,
	GET_ALL_RAAS_MERCHANTS,
	GET_ALL_RAAS_MERCHANTS_SUCCESS,
	GET_ALL_RAAS_MERCHANTS_ERROR,
	RUN_AGGREGATION_RAAS,
	RUN_AGGREGATION_RAAS_SUCCESS,
	RUN_AGGREGATION_RAAS_ERROR,
} from './actionTypes'

const initialState = {
	gettingRaasMerchant: false,
	raasMerchant: null,
	raasMerchantError: null,
	gettingRaasCustomer: false,
	raasCustomer: null,
	raasCustomerError: null,
	insertingRaasTransaction: false,
	insertedRaasTransaction: null,
	insertRaasTransactionError: null,
	gettingAllRaasMerchants: false,
	raasMerchants: null,
	runningAggregator: false,
	aggregationResult: null,
}

const raas = (state = initialState, action) => {
	switch (action.type) {
		case GET_RAAS_MERCHANT:
			return {
				...state,
				gettingRaasMerchant: true,
				raasMerchantError: null,
			}
		case GET_RAAS_MERCHANT_SUCCESS:
			return {
				...state,
				gettingRaasMerchant: false,
				raasMerchant: action.payload,
			}
		case GET_RAAS_MERCHANT_ERROR:
			return {
				...state,
				gettingRaasMerchant: false,
				raasMerchant: null,
				raasMerchantError: action.payload,
			}
		case GET_RAAS_CUSTOMER:
			return {
				...state,
				gettingRaasCustomer: true,
				raasCustomerError: null,
			}
		case GET_RAAS_CUSTOMER_SUCCESS:
			return {
				...state,
				gettingRaasCustomer: false,
				raasCustomer: action.payload,
			}
		case GET_RAAS_CUSTOMER_ERROR:
			return {
				...state,
				gettingRaasCustomer: false,
				raasCustomer: null,
				raasCustomerError: action.error,
			}
		case INSERT_RAAS_TRANSACTION:
			return {
				...state,
				insertingRaasTransaction: true,
				insertRaasTransactionError: null,
			}
		case INSERT_RAAS_TRANSACTION_SUCCESS:
			return {
				...state,
				insertingRaasTransaction: false,
				insertedRaasTransaction: action.payload,
			}
		case INSERT_RAAS_TRANSACTION_ERROR:
			return {
				...state,
				insertingRaasTransaction: false,
				insertedRaasTransaction: null,
				insertRaasTransactionError: action.error,
			}
		case GET_ALL_RAAS_MERCHANTS:
			return {
				...state,
				gettingAllRaasMerchants: true,
				raasMerchantsError: null,
			}
		case GET_ALL_RAAS_MERCHANTS_SUCCESS:
			return {
				...state,
				gettingAllRaasMerchants: false,
				raasMerchants: action.payload,
			}
		case GET_ALL_RAAS_MERCHANTS_ERROR:
			return {
				...state,
				gettingAllRaasMerchants: false,
				raasMerchants: null,
				raasMerchantsError: action.payload,
			}
		case RUN_AGGREGATION_RAAS:
			return {
				...state,
				runningAggregator: true,
			}
		case RUN_AGGREGATION_RAAS_SUCCESS:
			return {
				...state,
				runningAggregator: false,
				aggregationResult: action.payload,
			}
		case RUN_AGGREGATION_RAAS_ERROR:
			return {
				...state,
				runningAggregator: false,
				aggregationResult: null,
			}
		default:
			return state
	}
}

export default raas
