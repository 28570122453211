import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getOrders,
	getPaymentMethods,
	getNodeByAdminCodeOrSlug,
	updateNode,
	updateUser,
	getLead,
	alertSlack,
	resetNode,
	inviteMerchantUser,
	resetMerchant,
} from '../../store/actions'
import { Footer, Header } from '../../components'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { realm } from '../../helpers/realm'
import ContactForm from '../../components/RestaurantAdmin/ContactForm'
import ContactTable from '../../components/RestaurantAdmin/ContactTable'
import { Button, SelectGroup, Input } from '../../components/Elements'
import { MdClear } from 'react-icons/md'
import MerchantAccountForm from '../../components/RestaurantAdmin/MerchantAccountForm'
import MerchantAccount from '../../components/RestaurantAdmin/MerchantAccount'

const RestaurantAdminAccount = (state) => {
	const {
		user,
		onGetUser,
		onGetNodeBySlugAdmin,
		node,
		onUpdateUser,
		updateUserLoading,
		userUpdateCompleted,
		userError,
		loadingNode,
		onGetLead,
		loadingLead,
		leadError,
		lead,
		onAlertSlack,
		onUpdateNode,
		updatingNode,
		updateNodeCompleted,
		updateNodeError,
		onResetNode,
		inviteSent,
		loadingMerchant,
		merchantError,
		onInviteMerchantUser,
		onResetMerchant,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [loadingAddNode, setLoadingAddNode] = useState(false)
	const [showAddLocation, setShowAddLocation] = useState(false)
	const [showAdminPin, setShowAdminPin] = useState(false)
	const [adminPinCode, setAdminPinCode] = useState('')
	const [showNewLoc, setShowNewLoc] = useState(false)
	const [showSignUp, setShowSignUp] = useState(false)
	const [signUpUrl, setSignUpURL] = useState('')
	const [contacts, setContacts] = useState([])
	const [showAddContact, setShowAddContact] = useState(false)
	const [newLocName, setNewLocName] = useState(null)
	const [newLocAddress, setNewLocAddress] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [adminAccess, setAdminAccess] = useState(false)
	const [showSaveContacts, setShowSaveContacts] = useState(false)
	const [showEditMerchant, setShowEditMerchant] = useState(false)
	const [showNewLocMessage, setShowNewLocMessage] = useState(false)
	const { nodeSlug } = useParams()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	useEffect(() => {
		if (node && node.fromSignUpFlow) {
			onGetLead({ slug: nodeSlug })
		}
		if (node && node.contacts) {
			setContacts(node.contacts)
		}
	}, [node])

	useEffect(() => {
		if (lead && !lead.signUpComplete) {
			setShowSignUp(true)
			const pageNumber = lead.lastPage + 1
			setSignUpURL(`/restaurantpartners/sign-up/page-${pageNumber}`)
		}
	}, [lead])

	const changeTheMerchant = (selectedNodeSlug) => {
		onUpdateUser({
			restaurantAdmin: selectedNodeSlug,
		})
		navigate(`/restaurant-admin/account/${selectedNodeSlug}`)
	}

	const addTheMerchant = async () => {
		setLoadingAddNode(true)
		const client = realm.currentUser.mongoClient('RealmService')
		const nodes = client.db('caas').collection('nodes')
		const newNode = await nodes.findOne({
			adminPinCode: adminPinCode.toLowerCase(),
		})

		let currentOtherLocations = []
		if (user.restaurantAdminOtherLocations) {
			currentOtherLocations = user.restaurantAdminOtherLocations
		} else {
			currentOtherLocations.push({
				name: node.name,
				nodeSlug: node.slug,
			})
		}

		if (
			currentOtherLocations.filter((e) => e.nodeSlug === newNode.slug)
				.length > 0
		) {
			/* empty */
		} else {
			currentOtherLocations.push({
				name: newNode.name,
				nodeSlug: newNode.slug,
			})
		}

		onUpdateUser({
			restaurantAdminOtherLocations: currentOtherLocations,
		})
		setLoadingAddNode(false)
		setShowAddLocation(false)
	}

	const addContact = (newContact) => {
		onUpdateNode({
			nodeSlug: user.restaurantAdmin,
			update: {
				contacts: [
					...contacts,
					{
						...newContact,
						id: contacts.length + 1,
						adminAccess: adminAccess,
					},
				],
			},
		})
		setContacts([
			...contacts,
			{
				...newContact,
				id: contacts.length + 1,
				adminAccess: adminAccess,
			},
		])
		// check if exisitng user --> slack alert for add
		// else --> email invite with unique link to grant admin access
		if (adminAccess) {
			onInviteMerchantUser({
				newContact: newContact,
				nodeSlug: nodeSlug,
			})
		}
	}

	useEffect(() => {
		if (inviteSent) {
			onResetMerchant()
			onGetNodeBySlugAdmin(nodeSlug)
		}
	}, [inviteSent])

	const toggleAdminAccess = (newContact) => {
		setContacts(
			contacts.map((contact) =>
				contact.phone === newContact.phone
					? { ...contact, adminAccess: !contact.adminAccess }
					: contact
			)
		)
		setShowSaveContacts(true)
		// Add logic to add/remove admin access from user
		// Only user can update own user doc
		const updatedContact = contacts.find(
			(contact) => contact.phone === newContact.phone
		)
		if (updatedContact) {
			if (updatedContact.adminAccess) {
				// admin access needs to be revoked
				onAlertSlack({
					channel: '#eng-testing',
					text:
						'<@U05CHR1JENT>, Revoke admin access to ' +
						nodeSlug +
						' for ' +
						newContact.phone,
				})
			} else {
				// admin access needs to be granted
				// may need to invite user if when created admin access was not granted
				onInviteMerchantUser({
					newContact: newContact,
					nodeSlug: nodeSlug,
				})
			}
		}
	}

	const handleDeleteContact = (contactPhone) => {
		const confirmDelete = window.confirm(
			'Are you sure you want to delete this contact?'
		)

		if (confirmDelete) {
			onUpdateNode({
				nodeSlug: user.restaurantAdmin,
				update: {
					contacts: contacts.filter(
						(contact) => contact.phone !== contactPhone
					),
				},
			})
			setContacts((prevContacts) =>
				prevContacts.filter((contact) => contact.phone !== contactPhone)
			)
		} else {
			const checkbox = document.getElementById(`checkbox-${contactPhone}`)
			if (checkbox) {
				checkbox.checked = false
			}
		}
		// add logic to remove admin access from user
		// only user can update own user doc --> slack alert for remove
		onAlertSlack({
			channel: '#eng-testing',
			text:
				'<@U05CHR1JENT>, Revoke admin access to ' +
				nodeSlug +
				' for ' +
				contactPhone,
		})
	}

	const handleUpdateContacts = () => {
		onUpdateNode({
			nodeSlug: user.restaurantAdmin,
			update: {
				contacts: [...contacts],
			},
		})
		setShowSaveContacts(false)
	}

	const handleAddressChange = async (newAddress) => {
		if (newAddress.target) {
			setNewLocAddress(newAddress.target.value)
			geocodeAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setNewLocAddress(newAddress.formatted_address)
			geocodeAddress(newAddress.formatted_address)
		}
	}

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	const handleSubmitNewLoc = () => {
		onAlertSlack({
			// channel: "#eng-testing",
			// text:
			//     "<@U05CHR1JENT>, New Location Inquiry: " + "\n" +
			//     newLocName + "\n" + geoCodedAddress + "\n" + ' from ' + (user.firstName || "") + ' ' + (user.lastName || "") + ' (' + (user.phone || "") + ', ' + (user.email || "") + ")"
			channel: '#sales',
			text:
				'<@U02PUDJCWPM> New Location Inquiry: ' +
				'\n' +
				newLocName +
				'\n' +
				geoCodedAddress +
				'\n' +
				' from ' +
				(user.firstName || '') +
				' ' +
				(user.lastName || '') +
				' (' +
				(user.phone || '') +
				', ' +
				(user.email || '') +
				')',
		})
		setShowNewLocMessage(true)
		setTimeout(() => {
			setShowAddLocation(false)
			setShowNewLocMessage(false)
		}, 5000)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		if (node && node.slug === user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/restaurant-admin/home')
						}}
					>
						&#8592; Go to Dashboard Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>
					<div className='min-h-screen w-full'>
						{node ? (
							<div>
								{user.restaurantAdminOtherLocations &&
								user.restaurantAdminOtherLocations.length >
									0 ? (
									<div className='flex flex-row justify-center'>
										<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
											{node.name}'s Account
										</h1>
										{showNodeChoices ? (
											<BsChevronUp
												onClick={() => {
													setShowNodeChoices(false)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										) : (
											<BsChevronDown
												onClick={() => {
													setShowNodeChoices(true)
												}}
												className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl mt-16 font-bold'
											/>
										)}
									</div>
								) : (
									<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
										{node.name}'s Account
									</h1>
								)}
							</div>
						) : (
							<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
								DeliverZero Dashboard
							</h1>
						)}
						<div className='flex-col flex items-center'>
							{showNodeChoices && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									{user.restaurantAdminOtherLocations &&
									user.restaurantAdminOtherLocations.length >
										1 ? (
										user.restaurantAdminOtherLocations.map(
											(location) => (
												<div
													className='text-sm cursor-pointer my-1 hover:bg-gray-100'
													key={location.nodeSlug}
													onClick={() => {
														changeTheMerchant(
															location.nodeSlug
														)
														setShowNodeChoices(
															false
														)
													}}
												>
													{location.name}
												</div>
											)
										)
									) : user.restaurantAdminOtherLocations &&
									  user.restaurantAdminOtherLocations[0] ? (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											onClick={() => {
												changeTheMerchant(
													location.nodeSlug
												)
												setShowNodeChoices(false)
											}}
										>
											{
												user
													.restaurantAdminOtherLocations[0]
													.name
											}
										</div>
									) : (
										<div></div>
									)}
								</div>
							)}
						</div>

						{showSignUp && (
							<div className='flex flex-col items-center'>
								<div>
									Almost there! Finish signing up for
									DeliverZero below
								</div>
								<Button
									size='sm'
									className='mb-4 w-[180px] px-10 mt-2'
									onClick={() => navigate(signUpUrl)}
									text='Finish Signing Up for DeliverZero'
								/>
							</div>
						)}
						<div
							onClick={() => {
								navigate(
									`/restaurant-admin/account/${nodeSlug}/restaurant-info`
								)
							}}
							className='my-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								Restaurant Info
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								View and update restaurant information
							</p>
						</div>
						<div
							onClick={() => {
								navigate(
									`/restaurant-admin/account/${nodeSlug}/new-locations`
								)
							}}
							className='my-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								New Locations
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								Add new restaurant locations
							</p>
						</div>
						<div
							onClick={() => {
								navigate(
									`/restaurant-admin/account/${nodeSlug}/contacts`
								)
							}}
							className='my-10 w-80% max-w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
						>
							<h1 className='font-header text-2xl mt-6 ml-10'>
								Contacts
							</h1>
							<p className='font-header text-base mt-3 mx-10 mb-10'>
								View and update restaurant contacts and admins
							</p>
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Transactions,
	User,
	AppInfo,
	Orders,
	Nodes,
	Leads,
	Merchant,
}) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	loadingOrders: Orders.loading,
	orders: Orders.orders,
	ordersCount: Orders.ordersCount,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	lead: Leads.lead,
	updatingNode: Nodes.updatingNode,
	updateNodeCompleted: Nodes.updateNodeCompleted,
	updateNodeError: Nodes.updateNodeError,
	inviteSent: Merchant.invite,
	loadingMerchant: Merchant.loading,
	merchantError: Merchant.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetOrders: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetPaymentMethods: (payload) => dispatch(getPaymentMethods(payload)),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
	onUpdateUser: (payload) => dispatch(updateUser(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
	onAlertSlack: (payload) => dispatch(alertSlack(payload)),
	onUpdateNode: (payload) => dispatch(updateNode(payload)),
	onResetNode: () => dispatch(resetNode()),
	onInviteMerchantUser: (payload) => dispatch(inviteMerchantUser(payload)),
	onResetMerchant: () => dispatch(resetMerchant()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RestaurantAdminAccount)
