import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser } from '../../../store/actions'
import { getRestaurantCharges } from '../../../store/restaurantPayments/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'

const RevenueMonthly = (state) => {
	const {
		user,
		restaurantCharges,
		restaurantChargesError,
		onGetUser,
		onGetRestaurantCharges,
	} = state

	const { month, year } = useParams()

	let navigate = useNavigate()
	const bounceLoaderColor = '#507f74'
	const paginationLimit = 50
	const [restaurantChargesPage, setRestaurantChargesPage] = useState(1)
	const [totalRev, setTotalRev] = useState(0)

	const getRestaurantCharges = (page) => {
		const mo = new Date(Date.parse(month + ' 1, ' + year)).getMonth() + 1 // to get the numeric value of month; js 0 indexes so need to + 1
		const lastDay = new Date(year, mo, 0).getDate() // 0 gets last day of prev month; works since js 0 indexes
		const endDateStr = `${year}-${String(mo).padStart(
			2,
			'0'
		)}-${lastDay}T23:59:59.999+00:00`

		onGetRestaurantCharges({
			filter: {
				$and: [
					{ endDate: new Date(endDateStr) },
					{ month: month },
					{ status: 'paid' },
				],
			},
			options: {
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
	}

	const orderPaginate = () => {
		getRestaurantCharges(restaurantChargesPage)
	}

	useEffect(() => {
		onGetUser()
		orderPaginate()
	}, [onGetUser])

	useEffect(() => {
		if (restaurantCharges && restaurantCharges.length > 0) {
			let sum = 0
			for (const charge of restaurantCharges) {
				sum = charge.amountToCharge + sum
			}
			setTotalRev(sum)
		}
	}, [restaurantCharges])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-11 mt-5 text-center'>
					{month} {year}
				</h1>
				<div className='max-w-[1384px] max-h-[600px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col mx-[2px] items-center sm:mx-auto rounded-xl pb-2 mt-6 mb-8 scrollbar bg-white bg-opacity-100'>
					<div className='flex flex-row sticky top-0 bg-white bg-opacity-100 min-w-[750px] z-10 px-20 pt-2'>
						<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[84px] w-[84px] sm:w-[128px] lg:w-[192px] z-50 sticky bg-white left-0 text-xs sm:text-base'>
							Restaurant
						</div>
						<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[128px] w-[280px] sm:w-[128px] md:w-[128px] lg:w-[192px] sticky bg-white text-xs sm:text-base ml-[180px] pl-[180px] sm:ml-4 sm:pl-0'>
							Number of Orders
						</div>
						<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[128px] w-[128px] lg:w-[192px] sticky bg-white text-xs sm:text-base'>
							Price per container use
						</div>
						<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[128px] w-[128px] lg:w-[192px] sticky bg-white text-xs sm:text-base'>
							Number of Container Uses
						</div>
						<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px] sticky bg-white text-xs sm:text-base'>
							Revenue
						</div>
						<div className='mb-1 pt-1 font-header text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px] sticky bg-white text-xs sm:text-base'>
							Percent of Revenue
						</div>
					</div>
					{totalRev > 0 &&
					restaurantCharges &&
					restaurantCharges.length > 0 ? (
						<div className=''>
							{restaurantCharges.map((restaurant, index) => {
								return (
									<div
										className='min-w-[750px] py-0 whitespace-nowrap bg-white bg-opacity-100 h-full even:bg-gray-100 my-0'
										key={index}
									>
										<div className='text-sm flex flex-row items-center my-0 justify-start min-h-[50px] my-0 py-0'>
											<div className='flex items-center justify-center font-bold text-center sticky left-0 overflow-hidden whitespace-normal min-h-[80px] my-0 p-0 bg-green-100 mr-4 min-w-[84px] w-[84px] sm:w-[128px] lg:w-[192px] text-xs sm:text-sm'>
												{restaurant.nodeInfo.name}
											</div>
											<div className='my-1 text-center mr-4 min-w-[128px] w-[280px] sm:w-[128px] md:w-[128px] lg:w-[192px] flex flex-col items-center ml-[180px] pl-[180px] sm:ml-4 sm:pl-0'>
												{restaurant.numberOfOrders}
											</div>
											<div className='my-1 text-center mr-4 min-w-[128px] w-[128px] lg:w-[192px] flex flex-col items-center'>
												{
													restaurant.nodeInfo
														.pricePerUse
												}
											</div>
											<div className='my-1 text-center mr-4 min-w-[128px] w-[128px] lg:w-[192px] flex flex-col items-center'>
												{restaurant.boxCount}
											</div>
											<div className='my-1 text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px]'>
												$
												{restaurant.amountToCharge.toFixed(
													2
												)}
											</div>
											<div className='my-1 text-center mr-4 min-w-[96px] w-[96px] lg:min-w-[128px] w-[128px]'>
												{(
													(restaurant.amountToCharge *
														100) /
													totalRev
												).toFixed(1)}
												%
											</div>
										</div>
									</div>
								)
							})}
						</div>
					) : (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, RestaurantPayments }) => ({
	user: User.user,
	loadingUser: User.loading,
	restaurantCharges: RestaurantPayments.restaurantCharges,
	restaurantChargesError: RestaurantPayments.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetRestaurantCharges: (filter) => dispatch(getRestaurantCharges(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RevenueMonthly)
