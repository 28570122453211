import React from 'react'
import { Button, BounceLoaderCentered } from '../Elements'
import UserProfileTransactions from './UserProfileTransactions'
const UsageHistoryCard = ({
	loadingTransactions,
	transactions,
	appInfo,
	onCancelFirstDelivery,
	cancelingFirstDelivery,
	transactionPage,
	setTransactionPage,
	paginationLimit,
	transactionsCount,
}) => {
	return (
		// <div className='max-w-xs mb-10 overflow-y-auto scrollbar mx-auto md:ml-10 min-w-[300px] md:min-w-[360px] lg:min-w-[720px] h-[350px] shadow-lighter-grey w-full flex sm:flex-row justify-between rounded-xl bg-white p-6'>
		<div className='cardLessMargin max-h-[500px] overflow-y-auto scrollbar'>
			<h3
				className={`mt-2 block font-header text-2xl sm:text-3xl text-green-400`}
			>
				My Usage History
			</h3>
			<div>
				{loadingTransactions && (
					<BounceLoaderCentered container='div' />
				)}
				{(!loadingTransactions && !transactions) ||
					(transactions.length == 0 && (
						<div className='text-md lg:text-[18px] mt-3'>
							<div>
								Looks like you haven't used any boxes yet!
							</div>
							<div className='text-md lg:text-[18px] mt-2 mb-6'>
								Let's change that.
							</div>
							<Button
								className='px-3 m-auto mt-3'
								text='Order in Reusables'
								size='sm'
								link='/marketplace/locations'
							></Button>
						</div>
					))}
				{!loadingTransactions &&
					transactions &&
					transactions.length > 0 && (
						<div className='mt-3'>
							<UserProfileTransactions
								transactions={transactions}
								returnWindow={appInfo.returnWindow}
								disputeWindow={appInfo.disputeWindow}
								onCancelFirstDelivery={onCancelFirstDelivery}
								cancelingFirstDelivery={cancelingFirstDelivery}
							/>
						</div>
					)}
				{transactionsCount > transactionPage * paginationLimit && (
					<div className='flex justify-center'>
						<button
							onClick={() => {
								setTransactionPage(transactionPage + 1)
							}}
							className='mt-2 my-3 link-sm underline text-green-600 font-semibold'
						>
							Load more
						</button>
					</div>
				)}
			</div>
		</div>
	)
}

export default UsageHistoryCard
