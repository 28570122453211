import React, { useEffect, useState } from 'react'
import {
	getUser,
	getWFMData,
	getWFMLocations,
	uploadWFMData,
} from '../../store/actions'
import { connect } from 'react-redux'
import {
	Button,
	CheckboxGroup,
	SelectGroup,
	Input,
	RadioGroup,
	BounceLoaderCentered,
} from '../Elements'

const AdminWFM = (state) => {
	const {
		onGetWFMData,
		user,
		loadingUser,
		WFMData,
		error,
		loading,
		locations,
		onGetWFMLocations,
		onUploadWFMData,
		uploadResponse,
	} = state

	const [filter, setFilter] = useState({})
	const [location, setLocation] = useState('')
	const [locationName, setLocationName] = useState('All Locations')
	const [locationSelections, setLocationSelections] = useState([])
	const [showAddData, setShowAddData] = useState(false)
	const [inputValues, setInputValues] = useState({
		date: null,
		boxesOutLg: 0,
		boxesInLg: 0,
		boxesInMd: 0,
		barTrans: 0,
	})
	const [formError, setFormError] = useState(null)

	useEffect(() => {
		onGetWFMData(filter)
		onGetWFMLocations()
		const locationsArray = [
			{
				label: 'All Locations',
				value: '',
			},
		]
		setLocationSelections(locationsArray)
	}, [])

	useEffect(() => {
		onGetWFMData(filter)
	}, [filter])

	useEffect(() => {
		if (location && location !== '') {
			setFilter({ node: location })
		} else {
			setFilter({})
		}
	}, [location])

	useEffect(() => {
		if (uploadResponse) {
			setShowAddData(false)
			setFormError('New data saved')
		}
	}, [uploadResponse])

	useEffect(() => {
		if (locations && locations.length > 0) {
			const locationsArray = [
				{
					label: 'All Locations',
					value: '',
				},
			]
			for (const location of locations) {
				locationsArray.push({
					label: location.name,
					value: location.slug,
				})
			}
			setLocationSelections(locationsArray)
		}
	}, [locations])

	const handleAddData = () => {
		setFormError(null)
		if (!location || location === '') {
			setFormError('Please select a specific location to input new data')
		} else {
			setShowAddData(true)
		}
	}

	const handleInputChange = (column, value) => {
		setInputValues((prevInputValues) => ({
			...prevInputValues,
			[column]: value,
		}))
	}

	const formattedDate = (date) => {
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			timeZone: 'UTC',
		}
		return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
	}

	const validate = () => {
		setFormError(null)
		const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/

		if (!dateRegex.test(inputValues.date)) {
			setFormError('Please enter a valid date MM/DD/YYYY')
			return false
			// } else if (inputValues.barTrans) {
			//   setFormError("Please fill out all fields")
			//   return false
		} else {
			return true
		}
	}

	const handleSubmitData = async () => {
		const isValid = validate()

		if (isValid) {
			// console.log("inputValues", inputValues)
			onUploadWFMData({ [location]: [inputValues] })
			setInputValues({
				date: null,
				boxesOutLg: 0,
				boxesInLg: 0,
				boxesInMd: 0,
				barTrans: 0,
			})
		}
	}

	return (
		<div className='w-full h-full'>
			<div className='w-1/3 flex justify-center items-center my-6 mx-auto'>
				<SelectGroup
					label='Filter by location'
					placeholder='Select a location...'
					name='location'
					options={locationSelections}
					onChange={(e) => {
						const selectedValue = e.target.value
						const selectedIndex = e.target.selectedIndex
						const selectedLabel =
							e.target.options[selectedIndex].label

						// console.log("Selected Value:", selectedValue)
						// console.log("Selected Label:", selectedLabel)
						setLocation(
							selectedValue === 'Select a location...'
								? ''
								: selectedValue
						)
						setLocationName(
							selectedLabel === 'All Locations'
								? ''
								: selectedLabel
						)
						setFormError(null)
						setShowAddData(false)
					}}
				/>
			</div>
			{!showAddData && !loading && (
				<div className='flex justify-center items-center my-6 mx-auto'>
					<Button
						text='Add Data'
						color='green'
						className='px-2 flex items-center justify-center'
						onClick={handleAddData}
					/>
				</div>
			)}
			{formError &&
				(formError === 'New data saved' ? (
					<div className='text-green-600 w-1/2 mx-auto my-3 flex justify-center items-center'>
						{formError}
					</div>
				) : (
					<div className='text-red-400 w-1/2 mx-auto my-3 flex justify-center items-center'>
						{formError}
					</div>
				))}
			<div className='max-w-[80%] mx-auto flex flex-col justify-center items-center'>
				<div className='shadow-light-grey rounded-xl mb-6 w-full mx-auto px-5'>
					<h3 className='mt-2 block font-header text-green-400 text-3.5xl flex items-center w-full justify-center'>
						{!locationName || locationName === ''
							? 'All Locations'
							: locationName}
					</h3>
					{WFMData && (
						<div className='max-w-[1200px] overflow-auto scrollbar mx-auto mb-6'>
							<thead className='w-full'>
								<tr className='w-full'>
									<th className='w-[200px] text-left font-header mr-5'>
										Date
									</th>
									<th className='w-[200px] text-left font-header mr-5'>
										Boxes Out <br /> (OG-large)
									</th>
									<th className='w-[200px] text-left font-header mr-5'>
										Boxes Out <br /> (OG-medium)
									</th>
									<th className='w-[200px] text-left font-header mr-5'>
										Boxes In <br /> (OG-large)
									</th>
									<th className='w-[200px] text-left font-header mr-5'>
										Boxes In <br /> (OG-medium)
									</th>
									<th className='w-[200px] text-left font-header mr-5'>
										BarTrans
									</th>
									<th className='w-[200px] text-left font-header mr-5'>
										Wk%
									</th>
									<th className='w-[200px] text-left font-header'>
										Return Rate
									</th>
								</tr>
							</thead>
							<tbody className='w-full'>
								{loading ? (
									<div className='flex justify-center items-center my-6 mx-auto'>
										<BounceLoaderCentered />
									</div>
								) : (
									WFMData.sort(
										(a, b) =>
											new Date(a._id) - new Date(b._id)
									).map((data, index) => (
										<tr
											key={index}
											className='w-full'
										>
											<td className='w-[200px] text-left mr-5'>
												{formattedDate(data._id)}
											</td>
											<td className='w-[200px] text-left mr-5'>
												{data.boxesOutLg}
											</td>
											<td className='w-[200px] text-left mr-5'>
												{data.boxesOutMd}
											</td>
											<td className='w-[200px] text-left mr-5'>
												{data.boxesInLg}
											</td>
											<td className='w-[200px] text-left mr-5'>
												{data.boxesInMd}
											</td>
											<td className='w-[200px] text-left mr-5'>
												{data.barTrans}
											</td>
											<td className='w-[200px] text-left mr-5'>
												{(data.wkPercent * 100).toFixed(
													2
												)}
												%
											</td>
											<td className='w-[200px] text-left'>
												{data.returnRate !== '-'
													? (
															data.returnRate *
															100
													  ).toFixed(2)
													: data.returnRate}
												%
											</td>
										</tr>
									))
								)}
								{showAddData && (
									<tr className='w-full'>
										<td className='w-[200px] text-left mr-5'>
											<Input
												type='text'
												placeholder={`Enter Date`}
												value={
													inputValues['date'] || ''
												}
												onChange={(e) =>
													handleInputChange(
														'date',
														e.target.value
													)
												}
												className='text-red-400 max-w-[180px]'
											/>
										</td>
										<td className='w-[200px] text-left mr-5'>
											<Input
												type='number'
												placeholder={`Enter lgDep`}
												value={
													inputValues['boxesOutLg'] ||
													''
												}
												onChange={(e) => {
													handleInputChange(
														'boxesOutLg',
														e.target.value
													)
												}}
												className='text-red-400 max-w-[180px]'
											/>
										</td>
										<td className='w-[200px] text-left mr-5'>
											<Input
												type='number'
												placeholder={`Enter medDep`}
												value={
													inputValues['boxesOutMd'] ||
													''
												}
												onChange={(e) =>
													handleInputChange(
														'boxesOutMd',
														e.target.value
													)
												}
												className='text-red-400 max-w-[180px]'
											/>
										</td>
										<td className='w-[200px] text-left mr-5'>
											<Input
												type='number'
												placeholder={`Enter lgRef`}
												value={
													inputValues['boxesInLg'] ||
													''
												}
												onChange={(e) =>
													handleInputChange(
														'boxesInLg',
														e.target.value
													)
												}
												className='text-red-400 max-w-[180px]'
											/>
										</td>
										<td className='w-[200px] text-left mr-5'>
											<Input
												type='number'
												placeholder={`Enter mfRef`}
												value={
													inputValues['boxesInMd'] ||
													''
												}
												onChange={(e) =>
													handleInputChange(
														'boxesInMd',
														e.target.value
													)
												}
												className='text-red-400 max-w-[180px]'
											/>
										</td>
										<td className='w-[200px] text-left mr-5'>
											<Input
												type='number'
												placeholder={`Enter barTrans`}
												value={
													inputValues['barTrans'] ||
													''
												}
												onChange={(e) =>
													handleInputChange(
														'barTrans',
														e.target.value
													)
												}
												className='text-red-400 max-w-[180px]'
											/>
										</td>
										<td className='ml-10'>
											{loading ? (
												<BounceLoaderCentered />
											) : (
												<Button
													text='Add'
													size='sm'
													className='py-1 px-2 w-[50px]'
													onClick={handleSubmitData}
												/>
											)}
										</td>
									</tr>
								)}
							</tbody>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, WholeFoods }) => ({
	user: User.user,
	loadingUser: User.loading,
	WFMData: WholeFoods.allData,
	error: WholeFoods.error,
	loading: WholeFoods.loading,
	locations: WholeFoods.locations,
	uploadResponse: WholeFoods.uploadResponse,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetWFMData: (payload) => dispatch(getWFMData(payload)),
	onUploadWFMData: (payload) => dispatch(uploadWFMData(payload)),
	onGetWFMLocations: () => dispatch(getWFMLocations()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminWFM)
