import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, Button, Alert, Modal } from '../Elements'
import { loginUserSuccess } from '../../store/auth/login/actions'
import { getUser } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { BounceLoader } from 'react-spinners'
import { sendResetPassword } from '../../store/auth/forgetpwd/actions'

const PasswordResetCard = (props) => {
	const {
		user,
		loggedIn,
		userLoading,
		path,
		sendingResetPassword,
		forgetSuccessMsg,
		forgetError,
		onSendResetPassword,
	} = props

	const [missingEmailError, setMissingEmailError] = useState(false)
	const [email, setEmail] = useState('')
	const [submitted, setSubmitted] = useState(false)
	const bounceLoaderColor = '#507f74'

	const handleChange = (e) => {
		if (e.target.name == 'email') {
			setMissingEmailError(false)
			setEmail(e.target.value)
		}
	}
	const navigate = useNavigate()
	const location = useLocation()

	const [errorMessage, setErrorMessage] = useState(false)
	const errorMessageLookUp = {
		'user not found':
			'There is no account associated with this email address',
		'password must be between 6 and 128 characters':
			'Passwords must be at least 6 characters.',
		'invalid username': 'Email is invalid. Try again or sign up below',
		'invalid username/password':
			"Email or password is invalid. Try again, reset your password, or sign up below. If you've ordered in DeliverZero boxes " +
			'from a third-party, sign up above using your phone number!',
		'invalid password':
			'Password is invalid. Try again or reset your password below',
	}

	useEffect(() => {
		if (loggedIn && path) {
			navigate(path)
		} else if (loggedIn) {
			navigate('/')
		}
	}, [loggedIn])

	useEffect(() => {
		if (forgetError) {
			setErrorMessage(errorMessageLookUp[forgetError.error])
		}
	}, [forgetError])

	const handleSubmit = () => {
		validateFormFields()
	}

	const validateFormFields = () => {
		if (email === '') {
			setMissingEmailError(true)
		} else {
			setMissingEmailError(false)
			onSendResetPassword(email)
		}
	}

	return (
		<div className='w-11/12 md:w-form-widget h-full bg-white overflow-hidden shadow-light-grey rounded-xl max-w-lg m-auto'>
			{submitted && (
				<Modal
					text={`If associated with an account, an email has been sent to ${email} with a pasword reset link`}
				/>
			)}
			<div className='text-xl md:px-4 py-5 sm:p-6 m-6 justify-center items-center'>
				<h1 className='font-vollkorn text-green-600 text-4xl mb-12 text-center'>
					Forgot Password
				</h1>
				{forgetError && (
					<Alert
						type='warning'
						content={errorMessage}
						className='text-sm mb-10'
					></Alert>
				)}
				{forgetSuccessMsg && (
					<div className='text-green-600 font-bold text-sm mb-6 '>
						Success! Instructions to reset your password were sent
						to this email from "no-reply+stitch@mongodb.com"
					</div>
				)}
				<Input
					type='email'
					label='EMAIL'
					name='email'
					error={missingEmailError}
					className='bg-white border-2 border-gray-300 mb-6'
					onChange={handleChange}
				/>
				<div className='flex'>
					{/*<CheckboxGroup label='Remember me' name='terms' className='m-4'/>*/}
					<Link
						to='/login'
						className='text-sm mb-6 text-green-600 font-poppins underline'
					>
						Back to Login
					</Link>
				</div>

				{sendingResetPassword ? (
					<div className='mt-3 text-center'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					</div>
				) : (
					<div>
						<Button
							color='green'
							full
							text='Submit'
							className='mb-3'
							onClick={handleSubmit}
						/>
					</div>
				)}

				{userLoading && (
					<div className='mt-3 text-center'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					</div>
				)}
				<div className='text-center text-sm mt-5'>
					New to DeliverZero?{' '}
					<Link
						to='/sign-up'
						className='text-green-600 underline font-semibold'
					>
						Create an account.
					</Link>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Path, ForgetPassword }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	userLoading: User.loading,
	path: Path.path,
	sendingResetPassword: ForgetPassword.sendingResetPassword,
	forgetSuccessMsg: ForgetPassword.forgetSuccessMsg,
	forgetError: ForgetPassword.forgetError,
})

const mapDispatchToProps = (dispatch) => ({
	onSendResetPassword: (email) => dispatch(sendResetPassword(email)),
	onLoginSuccess: (user) => dispatch(loginUserSuccess(user)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetCard)
