import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getUserAddressLocation,
	getUserGPSLocation,
	captureGPS,
} from '../../model/location'
import {
	getAddressLocationFail,
	getAddressLocationSuccess,
	getGPSLocationSuccess,
	getGPSLocationFail,
	captureGPSFail,
	captureGPSSuccess,
} from './actions'

import {
	GET_USER_ADDRESS_LOCATION,
	GET_GPS_LOCATION,
	CAPTURE_GPS,
} from './actionTypes'

function* fetchAddressLocation(action) {
	try {
		const location = yield call(getUserAddressLocation, action.payload)
		// console.log('location', location)
		yield put(getAddressLocationSuccess(location))
	} catch (error) {
		yield put(getAddressLocationFail(error))
	}
}

function* fetchGPSLocation(action) {
	try {
		const location = yield call(getUserGPSLocation, action.payload)
		// console.log('location', location)
		yield put(getGPSLocationSuccess(location))
	} catch (error) {
		yield put(getGPSLocationFail(error))
	}
}

function* captureGPSSaga(action) {
	try {
		const location = yield call(captureGPS, action.payload)
		yield put(captureGPSSuccess(location))
	} catch (error) {
		yield put(captureGPSFail(error))
	}
}

function* locationsSaga() {
	yield takeEvery(GET_USER_ADDRESS_LOCATION, fetchAddressLocation)
	yield takeEvery(GET_GPS_LOCATION, fetchGPSLocation)
	yield takeEvery(CAPTURE_GPS, captureGPSSaga)
}

export default locationsSaga
