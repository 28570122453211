import React, { useEffect, useState } from 'react'
import { getUser } from '../../store/actions'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Input, BounceLoaderCentered, FormGroup } from '../Elements'

const MerchantAccount = (props) => {
	const {
		inputBackgroundColor = 'white',
		nodeSlug,
		name,
		email,
		phone,
		address,
		city,
		state,
		postal,
		showEditMerchant,
		setShowEditMerchant,
	} = props
	const [fullAddress, setFullAddress] = useState(
		`${address}, ${city}, ${state}, ${postal}`
	)
	const navigate = useNavigate()

	return (
		<FormGroup className='w-fit flex flex-col justify-center items-center'>
			<div className='w-full text-left font-header ml-3'>Name: </div>
			<Input
				type='text'
				className='my-1 w-[300px] sm:w-[500px] mx-auto'
				placeholder={name}
				value={name}
				disabled
			/>
			<div className='w-full text-left font-header ml-3'>Address: </div>
			<Input
				placeholder={fullAddress}
				className={`bg-${inputBackgroundColor} my-1 w-[300px] sm:w-[500px] mx-auto`}
				autoCompleteAddress={true}
				value={fullAddress}
				disabled
			/>
			<div className='w-full text-left font-header ml-3'>Email: </div>
			<Input
				type='email'
				className='my-1 w-[300px] sm:w-[500px] mx-auto'
				placeholder={email}
				value={email}
				disabled
			/>
			<div className='w-full text-left font-header ml-3'>Phone: </div>
			<Input
				type='phone'
				className='my-1 w-[300px] sm:w-[500px] mx-auto'
				placeholder={phone}
				value={phone}
				disabled
			/>

			{!showEditMerchant && (
				<Button
					size='sm'
					className='w-[300px] mx-auto mt-3'
					onClick={() => {
						setShowEditMerchant(!showEditMerchant)
					}}
					text={'Edit Location Details'}
				/>
			)}
		</FormGroup>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantAccount)
