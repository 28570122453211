import React, { useState } from 'react'
import { Input, Button } from './Elements'
import { FaMapMarkerAlt, FaSearch } from 'react-icons/fa'
import { MdClear } from 'react-icons/md'

const LocationsSearchInputs = (props) => {
	const {
		onAddressSelect,
		changeMapBoolToFalse,
		addressValue,
		nameValue,
		onNameQuery,
		defaultDistanceBarValue,
		distanceBarValue,
		setDistanceBarValue,
		setMapBool,
		setLastSearched,
	} = props

	// const [selectedDistanceInMiles, setSelectedDistanceInMiles] = useState(5)
	const [address, setAddress] = useState('')
	const [currentlyTypedAddressValue, setCurrentlyTypedAddressValue] =
		useState('')
	const [currentlyTypedNameValue, setCurrentlyTypedNameValue] = useState('')

	const handleSubmitAddress = () => {
		// console.log('ADDRESS', address)
		changeMapBoolToFalse()
		let latLng = new window.google.maps.Geocoder(address)
		onAddressSelect(address, latLng)
	}

	const handleSelectDistance = (distanceInMiles) => {
		setMapBool(false)
		setDistanceBarValue(distanceInMiles)
	}

	const handleSubmitName = (e) => {
		onNameQuery(e)
	}

	const reverseGeocodeAddress = async (coords) => {
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{ location: coords },
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					if (results && results[0] && results[0].formatted_address) {
						setLastSearched(results[0].formatted_address)
					}
					return results[0].formatted_address
				} else {
					// show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const handleGPSRequest = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const crd = position.coords
				reverseGeocodeAddress({
					lat: parseFloat(crd.latitude),
					lng: parseFloat(crd.longitude),
				})
			},
			(e) => {
				console.log('error', e)
			},
			{ enableHighAccuracy: true, timeout: 5000 }
		)
		onAddressSelect()
	}

	const autoCompleteQuery = (address, coords) => {
		// console.log('ADDRESS', address)
		// console.log('COORDS', coords)
		changeMapBoolToFalse()
		onAddressSelect(address, coords)
	}

	return (
		<div className='flex flex-col md:flex-row w-full mt-4 items-center justify-center px-4'>
			<div className='flex flex-row w-5/6 mb-2 md:mb-0 md:w-1/3 mx-4 relative items-center'>
				<div className='w-[90%]'>
					<Input
						placeholder='Search by name'
						className='pl-12'
						name='nodeName'
						error={false}
						onChange={(e) => {
							// setNameQuery(e.target.value)
							setCurrentlyTypedNameValue(e.target.value)
							if (e.target.value.length > 3) {
								handleSubmitName(e)
							} else {
								handleSubmitName({ target: { value: '' } })
							}
						}}
						onKeyDown={(e) => {
							e.code === 'Enter' ? handleSubmitName(e) : null
						}}
						value={nameValue ? nameValue : currentlyTypedNameValue}
					/>
					<FaSearch className='absolute left-0 ml-4 top-1/2 transform -translate-y-3.5 text-green-400 text-2xl' />
				</div>
				<MdClear
					className='text-green-600 text-[20px] ml-1 cursor-pointer'
					onClick={() => {
						setCurrentlyTypedNameValue('')
						handleSubmitName({ target: { value: '' } })
					}}
				/>
			</div>
			<div className='flex flex-row w-5/6 mb-2 md:mb-0 md:w-1/3 mx-4 relative items-center'>
				<div className='w-[90%]'>
					<Input
						placeholder={
							addressValue ? addressValue : 'Search by address'
						}
						className='pl-12'
						name='nodeAddress'
						error={false}
						autoCompleteAddress={true}
						autoComplete='new-password'
						onKeyDown={(e) => {
							e.code === 'Enter' ? handleSubmitAddress(e) : null
						}}
						onChange={(e) => {
							{
								e.target
									? setAddress(e.target.value)
									: setAddress(e.formatted_address)
							}
							{
								e.target
									? setCurrentlyTypedAddressValue(
											e.target.value
									  )
									: setCurrentlyTypedAddressValue(
											e.formatted_address
									  )
							}
						}}
						autoCompleteQuery={autoCompleteQuery}
						onAddressSelect={onAddressSelect}
						// defaultValue={addressValue ? addressValue : ''}
						value={
							addressValue
								? addressValue
								: currentlyTypedAddressValue
						}
					/>
					<FaMapMarkerAlt className='absolute left-0 ml-4 top-1/2 transform -translate-y-3.5 text-green-400 text-2xl' />
				</div>
				<div className='ml-1 w-[20px]'>
					<button>
						<MdClear
							className='text-green-600 text-[20px]'
							onClick={() => {
								setAddress('')
								setCurrentlyTypedAddressValue('')
								autoCompleteQuery(null, null)
								setLastSearched('')
							}}
						/>
					</button>
				</div>
			</div>
			<div className='hidden sm:inline-block text-sm lg:text-base w-5/6 md:w-1/3 mx-4'>
				<form action='#'>
					<label
						htmlFor='miles'
						className=' text-gray-700 w-full'
					>
						Distance
					</label>
					<input
						type='range'
						min='1'
						name='miles'
						id='miles'
						max='25'
						defaultValue={defaultDistanceBarValue}
						className='w-full h-2 bg-green-400 rounded-full appearance-none'
						onChange={(event) =>
							handleSelectDistance(event.target.value)
						}
					/>
				</form>
				<div>{distanceBarValue} miles</div>
			</div>
			<div className='w-4/5 max-w-[250px] mt-2 sm:mt-2'>
				<Button
					className='py-2 px-10'
					size='sm'
					text='Use my location'
					full='true'
					onClick={handleGPSRequest}
				/>
			</div>
		</div>
	)
}

export default LocationsSearchInputs
