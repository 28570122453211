import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getAppInfo,
	getOffice,
	runAggregation,
	getOfficeTransactions,
	updateOffice,
	getOfficeAdmins,
	updateUser,
	assignOfficeAdmin,
	scheduleOfficePickup,
	inviteOfficeAdmin,
	resetOfficeInvite,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useMatch, useNavigate } from 'react-router-dom'
import { Button, Input, RadioGroup, TextArea } from '../../components/Elements'
import LocationSearchBar from '../../components/Elements/LocationSearchBar'
import PhoneInput from 'react-phone-number-input/input'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import DatePicker from 'react-datepicker'
import ContactForm from '../../components/RestaurantAdmin/ContactForm'
import OfficeAdminForm from '../../components/OfficeOrdering/OfficeAdminForm'

const OfficeAccount = (state) => {
	const {
		onGetUser,
		onGetOffice,
		office,
		user,
		onUpdateUser,
		updateUserLoading,
		userUpdateCompleted,
		loadingUser,
		onRunAggregation,
		aggregationResult,
		loadingAggregation,
		aggregationError,
		appInfo,
		onGetAppInfo,
		onGetOfficeTransactions,
		transactions,
		loadingTransactions,
		updatingOffice,
		onUpdateOffice,
		updateOfficeError,
		onGetOfficeAdmins,
		loadingOfficeAdmins,
		officeAdmins,
		onAssignAdminToOffice,
		schedulingOfficePickup,
		scheduledOfficePickup,
		onScheduleOfficePickup,
		scheduleOfficePickupError,
		officeAdminsError,
		onInviteOfficeAdmin,
		inviteSent,
		onResetOfficeInvite,
	} = state

	const google = window.google
	const paginationLimit = 5
	const bounceLoaderColor = '#507f74'
	const [page, setPage] = useState(1)
	const [showLocationForm, setShowLocationForm] = useState(false)
	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [newPhone, setNewPhone] = useState('')
	const [newPhoneError, setNewPhoneError] = useState(false)
	const [newOfficeName, setNewOfficeName] = useState('')
	const [newOfficeNameError, setNewOfficeNameError] = useState(false)
	const [officeLocations, setOfficeLocations] = useState({})
	const [doneGeoCoding, setDoneGeoCoding] = useState(false)
	const [savingAddress, setSavingAddress] = useState(false)
	const [updateOfficeLocations, setUpdateOfficeLocations] = useState(false)
	const [showAssignAdminDropdown, setShowAssignAdminDropdown] =
		useState(false)

	const [showPickupForm, setShowPickupForm] = useState(false)

	const [showOndemandPickup, setShowOndemandPickup] = useState(false)
	const [pickupLocation, setPickupLocation] = useState(null)
	const [pickupNote, setPickupNote] = useState('')
	const [pickupDate, setPickupDate] = useState(
		new Date(new Date().setDate(new Date().getDate() + 1))
	)
	const [showInviteAdmin, setShowInviteAdmin] = useState(false)
	const [showInviteSent, setShowInviteSent] = useState(false)

	const pickups = {
		daily: 'daily pickups between 2-5pm',
		weekly: 'weekly pickups',
		ondemand: 'on demand pickups',
		'by-order': 'pickups the same day as when your orders are delivered.',
	}

	let navigate = useNavigate()

	useEffect(() => {
		onGetAppInfo()
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.officeSlug) {
			onGetOffice(user.officeSlug)
			onGetOfficeAdmins(user.officeSlug)
		}
	}, [user])

	useEffect(() => {
		if (inviteSent) {
			setShowInviteSent(true)
		}
	}, [inviteSent])

	useEffect(() => {
		if (office) {
			setOfficeLocations(office.locations)
			setShowLocationForm(false)
			setSearchedAddress('')
			setGeoCodedAddress(null)
			setGeoCodedLat(null)
			setGeoCodedLng(null)
		}
	}, [office])

	const handleAddressChange = (newAddress) => {
		// if (newAddress.target.value) {
		// 	setInputHasValue(true)
		// }
		setAddressError(false)
		setGeoCodedAddress(null)
		if (newAddress.target) {
			// console.log(newAddress.target.value)
			setSearchedAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
		}
	}

	const geocodeAddress = async () => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: searchedAddress,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// console.log(results[0])
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
					setDoneGeoCoding(true)
				} else {
					setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const validateFields = () => {
		if (
			newPhone === '' ||
			newPhone.length !== 12 ||
			(office.locations && newPhone in office.locations)
		) {
			setNewPhoneError(true)
			return false
		} else {
			setNewPhoneError(false)
			if (geoCodedAddress) {
				setAddressError(false)
				return true
			} else {
				setAddressError(true)
				return false
			}
		}
	}

	const handleSaveNewAddress = async () => {
		setDoneGeoCoding(false)
		setSavingAddress(true)
		// console.log('1')
		await geocodeAddress()
	}

	useEffect(() => {
		// console.log('eradas')
		if (doneGeoCoding) {
			if (validateFields()) {
				setOfficeLocations((prevState) => ({
					...prevState,
					[newPhone]: {
						name: newOfficeName,
						address: geoCodedAddress,
						lat: geoCodedLat,
						lng: geoCodedLng,
					},
				}))
				setUpdateOfficeLocations(true)
			} else {
				setSavingAddress(false)
			}
		}
	}, [doneGeoCoding])

	useEffect(() => {
		if (updateOfficeLocations) {
			onUpdateOffice({
				slug: office.slug,
				update: {
					locations: officeLocations,
				},
			})
			setSavingAddress(false)
			setUpdateOfficeLocations(false)
		}
	}, [updateOfficeLocations])

	useEffect(() => {
		if (updateOfficeLocations) {
			onUpdateUser({
				userId: user.userId,
				update: {
					locations: officeLocations,
				},
			})
			setSavingAddress(false)
			setUpdateOfficeLocations(false)
		}
	}, [updateOfficeLocations])

	// console.log(officeLocations)

	const addOfficeAdmin = ({ name, email, phone }) => {
		const cleanedPhone = phone.replace(/[()-]/g, '')
		onInviteOfficeAdmin({
			newAdmin: {
				name: name,
				email: email,
				phone: `+1${cleanedPhone}`,
			},
			officeSlug: office.slug,
		})
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.officeSlug) {
		if (office) {
			return (
				<>
					<Header />
					<div>
						<div
							className='underline font-bold my-4 cursor-pointer ml-6'
							onClick={() => {
								navigate('/office-ordering/home')
							}}
						>
							&#8592; Back to Home
						</div>
						<h1 className='font-header text-green-600 sm:text-2xl md:text-4xl mb-9 mt-14 text-center'>
							{office.name}'s Account Info
						</h1>
						<div className='flex flex-col min-mx-6 mx-auto max-w-[1200px] justify-center'>
							<div className='card mb-10'>
								<h3
									className={`mt-2 block font-header text-green-400 text-3.5xl`}
								>
									Locations
								</h3>
								<div className='flex flex-row items-center mb-5'>
									<Button
										text='Add Office Location'
										className='text-[16px] w-[300px] mt-4'
										size='sm'
										onClick={() => {
											setShowLocationForm(
												!showLocationForm
											)
										}}
									/>
									{showLocationForm && (
										<div
											className='ml-4 underline cursor-pointer text-green-600'
											onClick={() => {
												setShowLocationForm(false)
												setSearchedAddress('')
												setGeoCodedAddress(null)
												setGeoCodedLat(null)
												setGeoCodedLng(null)
											}}
										>
											Cancel
										</div>
									)}
								</div>
								{showLocationForm && (
									<div className='mb-10'>
										<div className='mb-6'>
											{office.locations &&
											Object.keys(office.locations)
												.length > 0 ? (
												<div>
													Fill out the form below to
													add additional office
													locations
												</div>
											) : (
												<div>
													Add your first office
													location
												</div>
											)}
										</div>
										<div className='flex flex-col mb-6'>
											<Input
												label="ENTER THE OFFICE'S ADDRESS"
												className={`relative pl-4 w-[300px] ${
													addressError &&
													'text-red-600'
												} mb-6`}
												error={addressError}
												autoCompleteAddress={true}
												value={searchedAddress}
												onChange={handleAddressChange}
												// onBlur={() => { setIsFocused(false); console.log('onBlur') }}
												// onKeyDown={(e) => {
												// 	// console.log('in key down', e.target.value)
												// 	handleAddressChange(e)
												// 	e.key === 'Enter' && handleAddressSubmit(e)
												// }}
											/>
											<div className={'pb-1'}>
												<label
													className={`text-sm text-gray-600 font-poppins ${
														newPhoneError &&
														'text-red-600'
													} absolute -translate-y-5`}
													htmlFor={'phone'}
												>
													ENTER A PHONE NUMBER FOR
													THIS LOCATION THAT YOU WILL
													USE WHEN ORDERING FOR THIS
													LOCATION
												</label>
											</div>
											<PhoneInput
												onChange={(e) => {
													setNewPhone(e)
												}}
												country='US'
												id='phone'
												name='phone'
												label='PHONE'
												className={` form-control bg-white border-2 border-gray-200 focus:border-gray-300 w-[300px] border border-2 border-gray-200 rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow ${
													newPhoneError &&
													`border-red-600`
												}`}
												type='phone'
											/>
											<Input
												label='ENTER THE NAME FOR THIS OFFICE'
												className={`relative pl-4 w-[300px] ${
													newOfficeNameError &&
													'text-red-600'
												} mb-6`}
												error={newOfficeNameError}
												onChange={(e) => {
													setNewOfficeName(
														e.target.value
													)
												}}
												// onBlur={() => { setIsFocused(false); console.log('onBlur') }}
												// onKeyDown={(e) => {
												// 	// console.log('in key down', e.target.value)
												// 	handleAddressChange(e)
												// 	e.key === 'Enter' && handleAddressSubmit(e)
												// }}
											/>
										</div>
										{updateOfficeError && (
											<div className='font-bold text-red-600'>
												{updateOfficeError.message}
											</div>
										)}
										{savingAddress || updatingOffice ? (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										) : (
											<Button
												onClick={handleSaveNewAddress}
												className='w-[300px]'
												size='sm'
												text='Save New Location'
											/>
										)}
									</div>
								)}
								{office.locations &&
								Object.keys(office.locations).length > 0 ? (
									<div>
										<div className='w-[900px] flex flex-row mb-3 text-green-600 text-left font-bold px-10'>
											<div className='font-bold text-lg min-w-[150px] max-w-[150px] mr-4'>
												Office
											</div>
											<div className='font-bold text-lg min-w-[300px] max-w-[300px]  mr-4'>
												Address
											</div>
											<div className='font-bold text-lg min-w-[200px] max-w-[200px]  mr-4'>
												Phone Number or ID
											</div>
											<div className='font-bold text-lg min-w-[200px] max-w-[200px]  mr-4'>
												Office Admin
											</div>
										</div>
										{Object.keys(office.locations).map(
											(location) => {
												return (
													<div
														key={location}
														className='w-[1000px] flex flex-row mb-3 text-left px-10'
													>
														<div className='min-w-[150px] max-w-[150px] mr-4'>
															{
																office
																	.locations[
																	location
																]['name']
															}
														</div>
														<div className='min-w-[300px] max-w-[300px]  mr-4'>
															{
																office
																	.locations[
																	location
																]['address']
															}
														</div>
														<div className=' min-w-[200px] max-w-[200px]  mr-4'>
															{location}
														</div>
														<div className=' min-w-[200px] max-w-[200px] '>
															{showAssignAdminDropdown ? (
																<div>
																	<div
																		className='underline cursor-pointer text-red-600'
																		onClick={() => {
																			setShowAssignAdminDropdown(
																				false
																			)
																			setOfficeLocations(
																				office.locations
																			)
																		}}
																	>
																		Cancel
																	</div>
																	{showAssignAdminDropdown && (
																		<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
																			{officeAdmins.map(
																				(
																					admin
																				) => (
																					<div
																						className='text-sm cursor-pointer my-1 hover:bg-gray-100'
																						key={
																							admin.userId
																						}
																						onClick={() => {
																							setOfficeLocations(
																								(
																									prevState
																								) => ({
																									...prevState,
																									[location]:
																										{
																											...prevState[
																												location
																											], // Spread the existing object of the specific location
																											admin: {
																												userId: admin.userId,
																												name:
																													admin.firstName +
																													' ' +
																													admin.lastName,
																											}, // Add or update the "admin" parameter
																										},
																								})
																							)
																							setShowAssignAdminDropdown(
																								false
																							)
																							setUpdateOfficeLocations(
																								true
																							)
																							onAssignAdminToOffice(
																								{
																									userId: admin.userId,
																									officePhone:
																										location,
																								}
																							)
																						}}
																					>
																						{
																							admin.firstName
																						}{' '}
																						{
																							admin.lastName
																						}
																					</div>
																				)
																			)}
																		</div>
																	)}
																</div>
															) : (
																<div>
																	{office
																		.locations[
																		location
																	][
																		'admin'
																	] ? (
																		<div>
																			<div>
																				{
																					office
																						.locations[
																						location
																					][
																						'admin'
																					][
																						'name'
																					]
																				}
																			</div>
																			<div
																				onClick={() => {
																					setShowAssignAdminDropdown(
																						true
																					)
																				}}
																				className='underline cursor-pointer text-green-600'
																			>
																				Change
																				Admin
																			</div>
																		</div>
																	) : (
																		<div
																			onClick={() => {
																				setShowAssignAdminDropdown(
																					true
																				)
																			}}
																			className='underline cursor-pointer text-green-600'
																		>
																			Assign
																			Admin
																		</div>
																	)}
																</div>
															)}
														</div>
														<div
															onClick={() => {
																setOfficeLocations(
																	(
																		prevState
																	) => {
																		let newState =
																			{
																				...prevState,
																			}
																		delete newState[
																			location
																		]
																		return newState
																	}
																)
																setUpdateOfficeLocations(
																	true
																)
															}}
															className='underline cursor-pointer text-red-600'
														>
															Delete
														</div>
													</div>
												)
											}
										)}
									</div>
								) : (
									<div>
										Add office locations to your company's
										account.
									</div>
								)}
							</div>
							<div className='card mb-10'>
								<h3
									className={`mt-2 block font-header text-green-400 text-3.5xl`}
								>
									Pickup Information
								</h3>
								<div>
									{office.name} is scheduled for{' '}
									<span className='font-bold text-green-600 mb-4'>
										{pickups[office.pickupCadence]}
									</span>
									{office.pickupCadence === 'weekly'
										? ` on ${office.pickupDayOfWeek}`
										: ''}
								</div>
								<div className='flex flex-row items-center'>
									{updatingOffice ? (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									) : (
										<Button
											text='Change Pickup Schedule'
											className='text-[16px] w-[300px] mt-4'
											size='sm'
											onClick={() => {
												setShowPickupForm(
													!showPickupForm
												)
											}}
										/>
									)}
									{showPickupForm && (
										<div
											className='ml-4 underline cursor-pointer text-green-600'
											onClick={() => {
												setShowPickupForm(false)
											}}
										>
											Close
										</div>
									)}
								</div>

								{showPickupForm && (
									<div className='my-4'>
										<p>
											How often will you want DeliverZero
											to pick up your used containers?
										</p>
										<RadioGroup
											className='mt-1 sm:mt-0 mb-6'
											name='pickupCadence'
											horizontal
											radios={[
												{
													value: 'weekly',
													label: 'Weekly',
													labelClassName: 'mt-2',
												},
												{
													value: 'daily',
													label: 'Daily',
													labelClassName: 'mt-2',
												},
												{
													value: 'ondemand',
													labelClassName:
														'w-[95%] mt-2',
													label: 'By Request (Must schedule a day in advance through widget below)',
												},
												{
													value: 'by-order',
													label: "Same day as order (orders must be placed a day ahead, we'll automatically schedule a pickup for you)",
													labelClassName: 'mt-2',
												},
											]}
											onChange={(pickupCadence) => {
												onUpdateOffice({
													slug: office.slug,
													update: {
														pickupCadence:
															pickupCadence,
													},
												})
											}}
										/>
										{office.pickupCadence === 'weekly' && (
											<div>
												<p>
													What day of the week? We'll
													come by between 2-5PM.
												</p>
												<RadioGroup
													className='mt-1 sm:mt-0 mb-6'
													name='pickupDayOfWeek'
													horizontal
													radios={[
														{
															value: 'Monday',
															label: 'Monday',
															labelClassName:
																'mt-2',
														},
														{
															value: 'Tuesday',
															label: 'Tuesday',
															labelClassName:
																'mt-2',
														},
														{
															value: 'Wednesday',
															label: 'Wednesday',
															labelClassName:
																'mt-2',
														},
														{
															value: 'Thursday',
															label: 'Thursday',
															labelClassName:
																'mt-2',
														},
														{
															value: 'Friday',
															label: 'Friday',
															labelClassName:
																'mt-2',
														},
													]}
													onChange={(
														pickupDayOfWeek
													) => {
														onUpdateOffice({
															slug: office.slug,
															update: {
																pickupDayOfWeek:
																	pickupDayOfWeek,
															},
														})
													}}
												/>
											</div>
										)}
									</div>
								)}
								{office.pickupCadence === 'ondemand' && (
									<div>
										{schedulingOfficePickup ||
										updatingOffice ? (
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										) : (
											<Button
												text='Request Ondemand Pickup'
												className='text-[16px] w-[300px] mt-4 mx-2'
												size='sm'
												onClick={() => {
													setShowOndemandPickup(
														!showOndemandPickup
													)
												}}
											/>
										)}
										{showOndemandPickup && (
											<div>
												<div
													className='ml-4 my-3  underline cursor-pointer text-green-600'
													onClick={() => {
														setShowOndemandPickup(
															false
														)
														setPickupLocation(null)
													}}
												>
													Close
												</div>
												<div className='my-3'>
													Select which address for the
													pickup
												</div>
												<RadioGroup
													className='mt-1 sm:mt-0 mb-6'
													name='pickupLocation'
													horizontal
													radios={Object.keys(
														office.locations
													).map((location) => {
														return {
															value: location,
															label: office
																.locations[
																location
															]['name'],
															labelClassName:
																'mt-2',
														}
													})}
													onChange={(
														pickupLocation
													) => {
														setPickupLocation(
															pickupLocation
														)
													}}
												/>
												{pickupLocation && (
													<div>
														<p>
															Pick up Note for{' '}
															<span className='italic text-sm'>
																{
																	office
																		.locations[
																		pickupLocation
																	]['name']
																}
															</span>
														</p>
														<TextArea
															name='Delivery Note'
															label=''
															defaultValue={
																office
																	.locations[
																	pickupLocation
																]['pickupNote']
																	? office
																			.locations[
																			pickupLocation
																	  ][
																			'pickupNote'
																	  ]
																	: 'Include any special instructions for how to enter your building here'
															}
															placeholder='Include any special instructions for how to enter your building here'
															description='Maximum of 250 characters'
															className='!h-24 !mt-1 w-[300px]'
															onChange={(e) => {
																setPickupNote(e)
															}}
														/>
														<div className='mt-4'>
															Select Pickup Date
														</div>
														<div className='mb-2 border border-gray-400 w-[300px]'>
															<DatePicker
																minDate={
																	new Date(
																		new Date().setDate(
																			new Date().getDate() +
																				1
																		)
																	)
																}
																selected={
																	pickupDate
																}
																onChange={(
																	date
																) =>
																	setPickupDate(
																		date
																	)
																}
															/>
														</div>
														{schedulingOfficePickup ||
														updatingOffice ? (
															<BounceLoader
																className='m-auto'
																color={
																	bounceLoaderColor
																}
															></BounceLoader>
														) : (
															<Button
																text='Submit Pickup Request'
																className='text-[16px] w-[300px] mt-4'
																size='sm'
																onClick={() => {
																	onScheduleOfficePickup(
																		{
																			company:
																				office.name,
																			officeName:
																				office
																					.locations[
																					pickupLocation
																				][
																					'name'
																				],
																			officeAddress:
																				office
																					.locations[
																					pickupLocation
																				][
																					'address'
																				],
																			officePhone:
																				pickupLocation,
																			pickupDate:
																				pickupDate,
																			pickupNote:
																				pickupNote,
																		}
																	)
																	setOfficeLocations(
																		(
																			prevState
																		) => ({
																			...prevState,
																			[pickupLocation]:
																				{
																					...prevState[
																						pickupLocation
																					], // Spread the existing object of the specific location
																					pickupNote:
																						pickupNote, // Add or update the "admin" parameter
																				},
																		})
																	)
																	setUpdateOfficeLocations(
																		true
																	)
																}}
															/>
														)}
														{scheduledOfficePickup && (
															<div className='font-bold text-green-600 my-3'>
																Pickup request
																successfully
																sent! Our team
																will reach out
																to you if
																necessary.
															</div>
														)}
														{scheduleOfficePickupError && (
															<div className='font-bold text-red-600 my-3'>
																Error while
																requesting
																pickup. Contact
																support.
															</div>
														)}
													</div>
												)}
											</div>
										)}
									</div>
								)}
							</div>
							<div className='card mb-10'>
								<h3
									className={`mt-2 block font-header text-green-400 text-3.5xl`}
								>
									Payment & Billing
								</h3>
								<Button
									text='Update Payment Method'
									className='text-[16px] w-[300px] mt-4'
									size='sm'
									onClick={() => {
										navigate(
											'/office-ordering/account/payment-method'
										)
									}}
								/>
							</div>
							<div className='card mb-10'>
								<h3
									className={`mt-2 block font-header text-green-400 text-3.5xl`}
								>
									Office Account Access
								</h3>
								<div className='flex flex-row items-center'>
									<Button
										text='Add Office Admins'
										className='text-[16px] w-[300px] mt-4'
										size='sm'
										onClick={() => {
											setShowInviteAdmin(!showInviteAdmin)
											setShowInviteSent(false)
										}}
									/>
									{showInviteAdmin && (
										<div
											className='ml-4 underline cursor-pointer text-green-600'
											onClick={() => {
												setShowInviteAdmin(false)
											}}
										>
											Close
										</div>
									)}
								</div>

								{showInviteAdmin && (
									<div className='mt-3'>
										<OfficeAdminForm
											addOfficeAdmin={addOfficeAdmin}
											setShowInviteAdmin={
												setShowInviteAdmin
											}
										/>
									</div>
								)}
								{showInviteSent && (
									<div className='mt-3 text-green-600 mt-3'>
										Invite sent!
									</div>
								)}
							</div>
						</div>
					</div>
					<Footer />
				</>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, OfficeOrdering, AppInfo, Aggregation }) => ({
	office: OfficeOrdering.office,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	userError: User.error,
	appInfo: AppInfo.appInfo,
	aggregationResult: Aggregation.aggregationResult,
	loadingAggregation: Aggregation.runningAggregation,
	aggregationError: Aggregation.aggregationError,
	transactions: OfficeOrdering.transactions,
	loadingTransactions: OfficeOrdering.loadingTransactions,
	updatingOffice: OfficeOrdering.updatingOffice,
	updateOfficeError: OfficeOrdering.updateOfficeError,
	loadingOfficeAdmins: OfficeOrdering.loadingOfficeAdmins,
	officeAdmins: OfficeOrdering.officeAdmins,
	officeAdminsError: OfficeOrdering.officeAdminsError,
	schedulingOfficePickup: OfficeOrdering.schedulingOfficePickup,
	scheduledOfficePickup: OfficeOrdering.scheduledOfficePickup,
	scheduleOfficePickupError: OfficeOrdering.scheduleOfficePickupError,
	inviteSent: OfficeOrdering.invite,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onUpdateUser: (payload) => dispatch(updateUser(payload)),
	onGetOffice: (officeSlug) => dispatch(getOffice(officeSlug)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onRunAggregation: (payload) => dispatch(runAggregation(payload)),
	onGetOfficeTransactions: (payload) =>
		dispatch(getOfficeTransactions(payload)),
	onUpdateOffice: (payload) => dispatch(updateOffice(payload)),
	onGetOfficeAdmins: (payload) => dispatch(getOfficeAdmins(payload)),
	onAssignAdminToOffice: (payload) => dispatch(assignOfficeAdmin(payload)),
	onScheduleOfficePickup: (payload) =>
		dispatch(scheduleOfficePickup(payload)),
	onInviteOfficeAdmin: (payload) => dispatch(inviteOfficeAdmin(payload)),
	onResetOfficeInvite: () => dispatch(resetOfficeInvite()),
})
export default connect(mapStateToProps, mapDispatchToProps)(OfficeAccount)
