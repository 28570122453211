import { call, put, takeEvery } from 'redux-saga/effects'
import {
	inviteMerchantUser,
	getAllChildren,
	addChildrenToUser,
	trackUsage,
} from '../../model/merchant'
import {
	inviteMerchantUserSuccess,
	inviteMerchantUserError,
	getAllChildrenSuccess,
	getAllChildrenError,
	addChildrenToUserError,
	addChildrenToUserSuccess,
	trackUsageFail,
	trackUsageSuccess,
} from './actions'
import {
	INVITE_MERCHANT_USER,
	GET_ALL_CHILDREN,
	ADD_CHILDREN_TO_USER,
	TRACK_USAGE,
} from './actionTypes'

function* dispatchInviteMerchantUser(action) {
	try {
		const invite = yield call(inviteMerchantUser, action.payload)
		yield put(inviteMerchantUserSuccess(invite))
	} catch (error) {
		yield put(inviteMerchantUserError(error))
	}
}

function* fetchAllChildre(action) {
	try {
		const invite = yield call(getAllChildren, action.payload)
		yield put(getAllChildrenSuccess(invite))
	} catch (error) {
		yield put(getAllChildrenError(error))
	}
}

function* dispatchAddChildrenToUser(action) {
	try {
		const invite = yield call(addChildrenToUser, action.payload)
		yield put(addChildrenToUserSuccess(invite))
	} catch (error) {
		yield put(addChildrenToUserError(error))
	}
}

function* dispatchTrackUsage(action) {
	try {
		const analytics = yield call(trackUsage, action.payload)
		yield put(trackUsageSuccess(analytics))
	} catch (error) {
		yield put(trackUsageFail(error))
	}
}

function* merchantSaga() {
	yield takeEvery(INVITE_MERCHANT_USER, dispatchInviteMerchantUser)
	yield takeEvery(GET_ALL_CHILDREN, fetchAllChildre)
	yield takeEvery(ADD_CHILDREN_TO_USER, dispatchAddChildrenToUser)
	yield takeEvery(TRACK_USAGE, dispatchTrackUsage)
}

export default merchantSaga
