import {
	getUser,
	updateUser,
	getAppInfo,
	submitReusablesOrder,
	getPaymentMethods,
	resetCardAdded,
	storePaymentMethod,
	validateGiftCard,
	validatePromo,
	updateGiftCard,
	updatePromo,
	getAllReusableProducts,
	addProductReusablesShop,
	updateCartItemReusablesShop,
	updateCartFromUser,
	addCartToUserReusablesShop,
	clearCartReusablesShop,
	resetCardResponse,
	storeGuestPayment,
	resetReusables,
	totalReusablesCart,
	resetPromo,
	calculateShipping,
} from '../../store/actions'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import ReactConfetti from 'react-confetti'
import { BounceLoader } from 'react-spinners'
import { GiPartyPopper } from 'react-icons/gi'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
	TextArea,
} from '../../components/Elements'
import { DeliveryAddressEditForm, ReusablesCartItem } from '../../components'

const ReusablesCheckout = (props) => {
	const {
		loadingAddition,
		onStorePaymentMethod,
		paymentMethods,
		loadingPaymentMethods,
		onUpdateUser,
		updateUserLoading,
		userUpdateCompleted,
		submittingOrder,
		submittedOrder,
		submitOrderError,
		promoError,
		onValidatePromo,
		onValidateGiftCard,
		giftCardIsActive,
		promoMessageType,
		giftCard,
		onUpdateGiftCard,
		promoIsActive,
		promo,
		onUpdatePromo,
		onSubmitOrder,
		onResetSubmitOrder,
		onResetPromo,

		user,
		loadingUser,
		loggedIn,
		onGetUser,
		allReusableProducts,
		loadingReusableProducts,
		onGetAllReusableProducts,
		onAddItem,
		onUpdateItem,
		onUpdateCartFromUser,
		cartItemsReusablesShop,
		loadingCartItems,
		onAddCartToUser,
		loadingClearCart,
		onClearCart,
		loadingUserCart,
		onTotalCart,
		cartTotal,
		onCalculateShipping,
		loadingCost,
		costError,
		shippingCost,

		onStorePaymentGuest,
		chargingCard,
		chargingCardResponse,
		chargingCardError,
		cardAdded,
		paymentError,
		onResetCardAdded,
	} = props

	const bounceLoaderColor = '#507f74'
	const [address, setAddress] = useState('')
	const [address2, setAddress2] = useState('')
	const [zipCode, setZipCode] = useState('')
	const [city, setCity] = useState('')
	const [stateName, setStateName] = useState('')
	const [showAddressForm, setShowAddressForm] = useState(false)
	const [saveAsDefaultAddress, setSaveAsDefaultAddress] = useState(false)
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [fullGeoCodedAddress, setFullGeoCodedAddress] = useState(null)
	const [addressLat, setAddressLat] = useState(null)
	const [addressLng, setAddressLng] = useState(null)
	const [streetNumber, setStreetNumber] = useState('')
	const [streetName, setStreetName] = useState('')
	const [addressError, setAddressError] = useState(false)
	const [cardRadios, setCardRadios] = useState([])
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
	// const [deliveryNote, setDeliveryNote] = useState("");
	const [promoMessage, setPromoMessage] = useState('')

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const stripe = useStripe()
	const elements = useElements()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const [addCardError, setAddCardError] = useState(false)
	const [stripeLoading, setStripeLoading] = useState(false)
	const [showCardForm, setShowCardForm] = useState(false)
	const [zeroFoodPrint, setZeroFoodPrint] = useState(0.01)
	const [taxes, setTaxes] = useState(0.08875)
	const [serviceFee, setServiceFee] = useState(0.1)
	const [shippingFee, setShippingFee] = useState(null) ///////////////////// UPDATE TO SHIPPING CALCULATOR
	const [zeroFoodPrintBox, setZeroFoodPrintBox] = useState(true)
	const [settingGeocodedAddress, setSettingGeocodedAddress] = useState(false)
	const [showSuccessfulOrder, setShowSuccessfulOrder] = useState(false)
	const [address2Final, setAddress2Final] = useState('')
	const [providedCode, setProvidedCode] = useState('')
	const [giftCardDiscount, setGiftCardDiscount] = useState(0.0)
	const [promoDollarAmount, setPromoDollarAmount] = useState(0.0)
	const google = window.google
	const navigate = useNavigate()
	const [taxRate, setTaxRate] = useState(0.08875)
	const serviceFeeRate = 0.0

	const [changedShippingAddress, setChangedShippingAddress] = useState(null)
	const [changedShippingAddress2, setChangedShippingAddress2] = useState(null)
	const [invalidAddress, setInvalidAddress] = useState(false)
	const [allProducts, setAllProducts] = useState([])
	const [contactSaved, setContactSaved] = useState(false)
	const [stripeCustomer, setStripeCustomer] = useState(null)
	const [formError, setFormError] = useState('')
	const [form, setForm] = useState({
		phone: user?.phone || '',
		email: user?.email || '',
		firstName: user?.firstName || '',
		lastName: user?.lastName || '',
		companyName: '',
		paymentMethodId: '',
		stripeCustomerId: '',
	})

	useEffect(() => {
		const lastSearchedAddress = JSON.parse(
			localStorage.getItem('lastSearchedAddress')
		)
		if (
			user &&
			user.reusablesShopCart &&
			user.reusablesShopCart.lastSearchedAddress
		) {
			setAddress(user.reusablesShopCart.lastSearchedAddress)
			geocodeAddress(user.reusablesShopCart.lastSearchedAddress)
		} else if (lastSearchedAddress && lastSearchedAddress.address) {
			setAddress(lastSearchedAddress.address)
			geocodeAddress(lastSearchedAddress.address)
		} else if (user && user.lastSearchedAddress) {
			setAddress(user.lastSearchedAddress)
			geocodeAddress(user.lastSearchedAddress)
		} else if (user && user.address) {
			setAddress(user.address)
			geocodeAddress(user.address)
		}
	}, [user])

	useEffect(() => {
		// console.log('user payment useeffect triggered')
		if (user && user.paymentMethods) {
			makeCardRadios(user.paymentMethods)
		}
	}, [user?.paymentMethods])

	useEffect(() => {
		if (geoCodedAddress) {
			validateAddress()
			if (user || contactSaved) {
				const shippingPayload = {
					cartItems: cartItemsReusablesShop,
					addressLat: addressLat,
					addressLng: addressLng,
					address: geoCodedAddress,
					address2: address2,
					streetNumber: streetNumber,
					streetName: streetName,
					zipCode: zipCode,
					city: city,
					stateName: stateName,
					promo:promo
				}
				if (loggedIn) {
					shippingPayload.userPhone = user.phone
				} else {
					const formattedPhone = `+1${form.phone.replace(/\D/g, '')}`
					shippingPayload.contactForm = {
						...form,
						phone: formattedPhone,
					}
				}
				onCalculateShipping(shippingPayload)
			}
		}
	}, [geoCodedAddress,promo])

	useEffect(() => {
		if (shippingCost && shippingCost.shippingCost != null ) {
			setShippingFee(shippingCost.shippingCost)
		}
	}, [shippingCost])

	const validateAddress = () => {
		if (
			!streetName ||
			streetName === '' ||
			!streetNumber ||
			streetNumber === ''
		) {
			setInvalidAddress(true)
			setShowAddressForm(true)
		} else {
			setInvalidAddress(false)
			if (address) {
				geocodeAddress()
			}
		}
	}

	useEffect(() => {
		if (changedShippingAddress) {
			// console.log("changedShippingAddress", changedShippingAddress);
			geocodeAddress(changedShippingAddress)
			setAddress2(changedShippingAddress2)
			validateAddress(changedShippingAddress)
		}
	}, [changedShippingAddress])

	const geocodeAddress = async (addy) => {
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setFullGeoCodedAddress(results[0])
					setGeoCodedAddress(results[0].formatted_address)
					results[0].address_components.forEach((element) => {
						// console.log(element);
						if (element.types.includes('postal_code')) {
							setZipCode(element.short_name)
						} else if (element.types.includes('route')) {
							setStreetName(element.short_name)
						} else if (element.types.includes('street_number')) {
							setStreetNumber(element.short_name)
						} else if (element.types.includes('sublocality')) {
							setCity(element.short_name)
						} else if (
							element.types.includes(
								'administrative_area_level_1'
							)
						) {
							setStateName(element.short_name)
						}
					})
					setAddressLat(results[0].geometry.location.lat())
					setAddressLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
					setSettingGeocodedAddress(false)
				}
			}
		)
	}

	useEffect(() => {
		if (saveAsDefaultAddress) {
			// console.log('saving address', geoCodedAddress)
			onUpdateUser({
				address: geoCodedAddress,
				address2: address2,
			})
			const lastSearchedAddress = {
				address: geoCodedAddress,
				lat: addressLat,
				lng: addressLng,
			}
			localStorage.setItem(
				'lastSearchedAddress',
				JSON.stringify(lastSearchedAddress)
			)
		}
		setSettingGeocodedAddress(false)
		setShowAddressForm(false)
		setSaveAsDefaultAddress(false)
	}, [addressLng, address2Final])

	useEffect(() => {
		if (userUpdateCompleted) {
			onGetUser()
		}
	}, [userUpdateCompleted])

	const makeCardRadios = (paymentMethods) => {
		let radios = []
		paymentMethods.forEach(function (paymentMethod, index) {
			radios.push({
				value: paymentMethod.id,
				label: `${paymentMethod.card.brand.toUpperCase()} ....${
					paymentMethod.card.last4
				} - Exp. ${paymentMethod.card.exp_month}/${
					paymentMethod.card.exp_year
				}`,
				checked: index === 0,
			})
			if (index === 0) {
				setSelectedPaymentMethod(paymentMethod.id)
			}
		})
		setCardRadios(radios)
	}

	const handleAddCardSubmit = async (event) => {
		setStripeLoading(true)
		event.preventDefault()
		// setAddCardError(false)
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})
		if (result.error) {
			setStripeLoading(false)
			// setAddCardError(true)
		} else {
			setStripeLoading(false)
			onStorePaymentMethod({
				stripeCustomerId: user.stripeId,
				paymentMethodId: result.paymentMethod.id,
			})

			setShowCardForm(false)
		}
	}

	useEffect(() => {
		if (paymentMethods.length > 0) {
			onGetUser()
			makeCardRadios(paymentMethods)
			setStripeCustomer(paymentMethods[0].customer)
		}
	}, [paymentMethods])

	const handleZeroFoodPrint = () => {
		setZeroFoodPrintBox(!zeroFoodPrintBox)
	}

	useEffect(() => {
		if (zeroFoodPrintBox) {
			setZeroFoodPrint(0.01)
		} else {
			setZeroFoodPrint(0.0)
		}
	}, [zeroFoodPrintBox])

	useEffect(() => {
		if (cartItemsReusablesShop) {
			const reusablesShopCart = {
				cartItems: cartItemsReusablesShop,
				lastActive: new Date(),
				lastSearchedAddress: geoCodedAddress,
			}
			// console.log('setting reusablesShopCart in checkout', reusablesShopCart)
			localStorage.setItem(
				'reusablesShopCart',
				JSON.stringify(reusablesShopCart)
			)
			if (user) {
				onAddCartToUser({
					reusablesShopCart: reusablesShopCart,
					user: user,
				})
			}
		}
	}, [cartItemsReusablesShop, geoCodedAddress])

	const handleSubmitOrder = async (e) => {
		e.preventDefault()
		// Calculate total order amount
		const zeroFoodPrintTotal = cartTotal * zeroFoodPrint
		const totalOrderAmount =
			zeroFoodPrintTotal + cartTotal + taxes + serviceFee + shippingFee

		let orderPayload = {
			cartItems: cartItemsReusablesShop,
			subtotal: cartTotal,
			taxes: parseFloat(taxes.toFixed(2)),
			serviceFee: parseFloat(serviceFee.toFixed(2)),
			shippingFee: parseFloat(shippingFee.toFixed(2)),
			zeroFoodPrint: parseFloat(zeroFoodPrintTotal.toFixed(2)),
			orderTotal: parseFloat(totalOrderAmount.toFixed(2)),
			giftCard: giftCard ? giftCard : null,
			giftCardCode: giftCard ? providedCode : 0,
			giftCardAmount: giftCard ? giftCardDiscount : 0,
			promo: promo ? promo : null,
			promoCode: promo ? providedCode : null,
			promoAmount: promo ? parseFloat(promoDollarAmount.toFixed(2)) : 0,
			address: geoCodedAddress,
			address2: address2,
			streetNumber: streetNumber,
			streetName: streetName,
			zipCode: zipCode,
			city: city,
			addressLat: addressLat,
			addressLng: addressLng,
			stateName: stateName,
			fullGeoCodedAddress: fullGeoCodedAddress,
			paymentMethodId: selectedPaymentMethod,
			reusablesShop: true,
			stripeCustomer: stripeCustomer,
			rateId: shippingCost.rateId,
		}

		if (loggedIn) {
			orderPayload.userPhone = user.phone
			// orderPayload.loggedIn = true
		} else {
			const formattedPhone = `+1${form.phone.replace(/\D/g, '')}`
			orderPayload.contactForm = { ...form, phone: formattedPhone }
			// orderPayload.loggedIn = false
		}

		// console.log('orderPayload', orderPayload)

		onSubmitOrder(orderPayload)
	}

	useEffect(() => {
		if (submittedOrder) {
			if (promoIsActive) {
				onUpdatePromo({
					promo: promo,
					promoCode: providedCode,
					userPhone:
						user?.phone || `+1${form.phone.replace(/\D/g, '')}`,
				})
				// console.log("updating promo")
			}

			if (giftCardIsActive) {
				onUpdateGiftCard({
					giftCardCode: providedCode,
					amountUsed: giftCardDiscount,
				})
				// console.log("updating giftcard")
			}

			onClearCart()
			localStorage.setItem('reusablesShopCart', JSON.stringify({}))
			setShowSuccessfulOrder(true)
			if (user) {
				onAddCartToUser({
					cartItems: {},
					lastActive: new Date(),
				})
			}
			onResetSubmitOrder()
		}
	}, [submittedOrder])

	// ! VALIDATE PROMO/GC CODE
	const handleCodeValidation = (e) => {
		e.preventDefault()
		// console.log('CODE SLICE', providedCode.slice(0, 4))
		if (providedCode.slice(0, 4) === 'DZGC') {
			// console.log('GIFTCARD VALIDATE')
			onValidateGiftCard({ code: providedCode })
		} else {
			// console.log('PROMO CODE VALIDATE', user?.phone || `+1${form.phone.replace(/\D/g, '')}`)
			onValidatePromo({
				promoCode: providedCode,
				phone: user?.phone || `+1${form.phone.replace(/\D/g, '')}`,
				node: null,
			})
		}
	}

	const promoMessages = {
		promoValid: 'Promo code applied!',
		promoInvalid: 'Promo code is invalid',
		promoExpired: 'Promo code has expired',
		promoAlreadyUsed: 'Promo code is no longer valid',
		promoNotYetActive: 'Promo code is not yet active',
		promoNotValidForUser: 'Promo code is not valid',
		promoNotValidForNode: 'Promo code is not valid for this restaurant',
		giftCardValid: 'Gift card applied!',
		giftCardInvalid: 'Gift card is invalid',
		giftCardBalanceZero: 'Gift card balance is $0.00',
		promoNotValidForUberDirect:
			'Promo code not valid for on demand pickups',
		promoOnlyValidForUberDirect:
			'Promo code is only valid for on demand pickups',
	}

	useEffect(() => {
		if (promoMessageType) {
			setPromoMessage(promoMessages[promoMessageType])
		}
	}, [promoMessageType])

	// ! CALCULATE TOTAL ORDER AMOUNT
	useEffect(() => {
		const zeroFoodPrintTotal = cartTotal * zeroFoodPrint
		let totalOrderAmount =
			zeroFoodPrintTotal + cartTotal + taxes + serviceFee + shippingFee
		// console.log('TOTAL ORDER AMOUNT', totalOrderAmount)
		if (giftCardIsActive && giftCard.balance >= totalOrderAmount) {
			setGiftCardDiscount(totalOrderAmount)
			// console.log('GIFTCARD DISCOUNT BALANCE >', totalOrderAmount)
		} else if (giftCardIsActive && giftCard.balance < totalOrderAmount) {
			setGiftCardDiscount(giftCard.balance)
			// console.log('GIFTCARD DISCOUNT BALANCE <', totalOrderAmount)
		}
		if (promo && promoIsActive && promo.discountType === 'percent') {
			setPromoDollarAmount(cartTotal * (parseInt(promo.amount) / 100))
		}
		if (promo && promoIsActive && promo.discountType === 'cash') {
			setPromoDollarAmount(parseInt(promo.amount))
		}
	}, [
		giftCardIsActive,
		promoIsActive,
		promo,
		giftCard,
		cartTotal,
		zeroFoodPrint,
		taxes,
		serviceFee,
		shippingFee,
	])

	const handleUpdateCartItem = (product) => {
		onUpdateItem({
			currCartItems: cartItemsReusablesShop,
			cartItemToUpdate: product,
			quantity: product.quantity,
		})
	}

	useEffect(() => {
		onGetUser()
		onGetAllReusableProducts()
		onResetPromo()
		onResetSubmitOrder()
		onResetCardAdded()
	}, [])

	useEffect(() => {
		if (allReusableProducts) {
			setAllProducts(allReusableProducts)
		}
	}, [allReusableProducts])

	useEffect(() => {
		if (user && user.reusablesShopCart) {
			onUpdateCartFromUser(user.reusablesShopCart)
		} else if (localStorage.getItem('reusablesShopCart')) {
			const localStorageCart = JSON.parse(
				localStorage.getItem('reusablesShopCart')
			)
			onUpdateCartFromUser(localStorageCart)
		} else {
			onUpdateCartFromUser({})
		}
		if(user && user.phone){
			setContactSaved(true)
		}
	}, [user, user?.reusablesShopCart])

	useEffect(() => {
		if (cartItemsReusablesShop) {
			onTotalCart(cartItemsReusablesShop)
			if (geoCodedAddress) {
				if (user || contactSaved) {
					const shippingPayload = {
						cartItems: cartItemsReusablesShop,
						addressLat: addressLat,
						addressLng: addressLng,
						address: geoCodedAddress,
						address2: address2,
						streetNumber: streetNumber,
						streetName: streetName,
						zipCode: zipCode,
						city: city,
						stateName: stateName,
						promo:promo
					}
					if (loggedIn) {
						shippingPayload.userPhone = user.phone
					} else {
						const formattedPhone = `+1${form.phone.replace(
							/\D/g,
							''
						)}`
						shippingPayload.contactForm = {
							...form,
							phone: formattedPhone,
						}
					}
					onCalculateShipping(shippingPayload)
				}
			}
			// updating user if needed
			if (
				user &&
				(!user.reusablesShopCart ||
					!user.reusablesShopCart.cartItems ||
					(user.reusablesShopCart &&
						user.reusablesShopCart.cartItems &&
						cartItemsReusablesShop !=
							user.reusablesShopCart.cartItems))
			) {
				onAddCartToUser({ cartItems: cartItemsReusablesShop })
			}

			// updating local storage
			const reusablesShopCart = { cartItems: cartItemsReusablesShop }
			localStorage.setItem(
				'reusablesShopCart',
				JSON.stringify(reusablesShopCart)
			)
		}
	}, [cartItemsReusablesShop, JSON.stringify(cartItemsReusablesShop)])

	useEffect(() => {
		if (cartTotal) {
			setTaxes(taxRate * (cartTotal-promoDollarAmount))
			setServiceFee(serviceFeeRate * cartTotal)
		}
	}, [cartTotal])

	useEffect(() => {
		if (promoDollarAmount) {
			setTaxes(taxRate * (cartTotal-promoDollarAmount))
		}
	}, [promoDollarAmount])

	const validateFormFields = () => {
		const formattedPhone = formatPhoneNumber(form.phone)

		if (form.firstName === '' || form.lastName === '') {
			setFormError('Please enter a first and last name')
			return false
		} else if (
			formattedPhone === '' ||
			!/^\d{3}-\d{3}-\d{4}$/.test(formattedPhone)
		) {
			setFormError('Please enter a valid 10 digit phone number')
			return false
		} else if (form.email === '' || !form.email.includes('@')) {
			setFormError('Please enter a valid email address')
			return false
		} else {
			setFormError('')
			return true
		}
	}

	const handleContactForm = async () => {
		const validContactInfo = validateFormFields()
		if (validContactInfo) {
			setContactSaved(true)
		}
	}

	const handleAddCardGuest = async (e) => {
		e.preventDefault()
		if (validateFormFields()) {
			setStripeLoading(true)
			e.preventDefault()
			setAddCardError(false)
			if (elements == null) {
				return
			}

			const result = await stripe.createPaymentMethod({
				type: 'card',
				card: elements.getElement(CardElement),
			})

			if (result.error || !result.paymentMethod) {
				setStripeLoading(false)
				setAddCardError(true)
			} else {
				setStripeLoading(false)
				// console.log("result.paymentMethod.id", result.paymentMethod.id);
				onStorePaymentGuest({
					email: form.email,
					phone: `+1${form.phone.replace(/\D/g, '')}`,
					paymentMethodId: result.paymentMethod.id,
				})
				setSelectedPaymentMethod(result.paymentMethod.id)
			}
		}
	}

	const formatPhoneNumber = (input) => {
		const formatted = input.replace(/\D/g, '')
		const match = formatted.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			return `${match[1]}-${match[2]}-${match[3]}`
		} else {
			return formatted
		}
	}

	const handlePhoneChange = (e) => {
		const input = e.target.value
		const formatted = formatPhoneNumber(input)
		setFormError('')
		setContactSaved(false)
		setForm({
			...form,
			phone: formatted,
		})
	}

	if (showSuccessfulOrder) {
		return (
			<div className='w-full h-full lg:h-screen-3/4 flex flex-col justify-center items-center overflow-hidden'>
				<ReactConfetti
					className='w-full'
					colors={['#f38b74']}
				/>
				<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
					Order Submitted!
				</h1>
				<GiPartyPopper className='text-orange-600 text-[250px]' />
				<div>Visit your account to track the status of your order.</div>
				<Button
					link='/user/account'
					className='px-10 w-[260px] mt-5 shadow-light-grey'
					text='View My Account'
				/>
			</div>
		)
	} else if (
		cartItemsReusablesShop &&
		Object.keys(cartItemsReusablesShop).length > 0
	) {
		return (
			<div className='flex flex-col md:flex-row md:min-h-screen-no-header-mobile sm:min-h-screen-no-header'>
				<div className='md:w-3/5 lg:w-2/3 ml-5 lg:ml-10 overflow md:overflow-y-auto md:scrollbar'>
					<div className='divide-y-2 divide-solid mb-2'>
						<div className='mt-5 md:mt-12 font-header font-bold text-green-600 text-4xl mb-3'>
							Let's review your order!
						</div>
						<div className='my-5 pt-3 md:pt-7 pb-1 md:pb-5'>
							<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
								Contact Information
							</div>
							{loggedIn ? (
								<div className='flex flex-col sm:flex-row'>
									<div className='flex flex-col mr-4 max-w-300'>
										<div className='font-semibold'>
											{user.firstName} {user.lastName}
										</div>
										<div className=''>{user.email}</div>
										<div className=''>{user.phone}</div>
									</div>
								</div>
							) : (
								<div className='w-full px-2 mb-2'>
									<div className='flex flex-col sm:flex-row'>
										<div className='w-[350px] px-2 mb-2 mr-2'>
											<Input
												label='First Name'
												type='string'
												name='firstName'
												value={form.firstName}
												defaultValue={form.firstName}
												className='h-12 text-md mr-2'
												onChange={(e) => {
													setFormError('')
													setContactSaved(false)
													setForm({
														...form,
														firstName:
															e.target.value,
													})
												}}
											/>
										</div>
										<div className='w-[350px] px-2 mb-2'>
											<Input
												label='Last name'
												type='string'
												name='lastName'
												value={form.lastName}
												defaultValue={form.lastName}
												className='h-12 text-md'
												onChange={(e) => {
													setFormError('')
													setContactSaved(false)
													setForm({
														...form,
														lastName:
															e.target.value,
													})
												}}
											/>
										</div>
									</div>
									<div className='w-[350px] px-2 mb-2'>
										<Input
											label='Company name (optional)'
											type='string'
											name='company'
											value={form.companyName}
											defaultValue={form.companyName}
											className='h-12 text-md'
											onChange={(e) => {
												setFormError('')
												setContactSaved(false)
												setForm({
													...form,
													companyName: e.target.value,
												})
											}}
										/>
									</div>
									<div className='w-[350px] px-2 mb-2'>
										<Input
											label='Phone'
											onChange={handlePhoneChange}
											type='tel'
											name='phone'
											value={form.phone}
											className='h-12 text-md'
											pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
										/>
									</div>
									<div className='w-[350px] px-2 mb-2'>
										<Input
											label='Email'
											type='string'
											name='email'
											value={form.email}
											defaultValue={form.email}
											className='h-12 text-md'
											onChange={(e) => {
												setFormError('')
												setContactSaved(false)
												setForm({
													...form,
													email: e.target.value,
												})
											}}
										/>
									</div>
									{formError && (
										<div className='ml-3 mt-3 mb-2 text-red-400 w-[350px]'>
											{formError}
										</div>
									)}
									{contactSaved ? (
										<div
											className='ml-3 mt-3 sm:mt-6 text-green-600 w-[350px] underline'
											onClick={() => {
												setContactSaved(false)
											}}
										>
											Edit Contact Information
										</div>
									) : (
										<Button
											text='Save'
											size='sm'
											className='w-[350px]'
											onClick={(e) =>
												handleContactForm(e)
											}
										/>
									)}
								</div>
							)}
						</div>
						<div className='mt-2 sm:mt-5 pt-3 md:pt-7 mb-2 pb-1'>
							<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
								Shipping Address
							</div>
							{loggedIn ? (
								<>
									<div className='mb-2'>
										{(user.address != '' ||
											geoCodedAddress) &&
											!showAddressForm && (
												<div
													className='underline text-green-600 text-sm cursor-pointer'
													onClick={() =>
														setShowAddressForm(true)
													}
												>
													Change Shipping Address
												</div>
											)}
									</div>
									{showAddressForm ||
									invalidAddress ||
									!geoCodedAddress ? (
										<div className='w-full px-2 mb-2'>
											{!invalidAddress &&
												geoCodedAddress && (
													<div
														className='underline text-green-600 text-sm cursor-pointer'
														onClick={() =>
															setShowAddressForm(
																false
															)
														}
													>
														Cancel Change Shipping
														Address
													</div>
												)}
											<DeliveryAddressEditForm
												user={user}
												onGetUser={onGetUser}
												setShowAddressForm={
													setShowAddressForm
												}
												setChangedDeliveryAddress={
													setChangedShippingAddress
												}
												setChangedDeliveryAddress2={
													setChangedShippingAddress2
												}
												invalidAddress={invalidAddress}
												setInvalidAddress={
													setInvalidAddress
												}
												validateAddress={
													validateAddress
												}
												shipping={true}
											/>
										</div>
									) : (
										<div className='w-full px-2 mb-2'>
											<div>{geoCodedAddress}</div>
											{address2 && address2 != '' && (
												<div>Unit: {address2}</div>
											)}
										</div>
									)}
								</>
							) : (
								<>
									{showAddressForm ||
									!geoCodedAddress ||
									invalidAddress ? (
										<div className='w-full px-2 mb-2'>
											<DeliveryAddressEditForm
												user={user}
												onGetUser={onGetUser}
												setShowAddressForm={
													setShowAddressForm
												}
												setChangedDeliveryAddress={
													setChangedShippingAddress
												}
												setChangedDeliveryAddress2={
													setChangedShippingAddress2
												}
												invalidAddress={invalidAddress}
												setInvalidAddress={
													setInvalidAddress
												}
												validateAddress={
													validateAddress
												}
											/>
										</div>
									) : (
										<>
											<div className='w-full px-2 mb-2'>
												<div>{geoCodedAddress}</div>
												{address2 && address2 != '' && (
													<div>Unit: {address2}</div>
												)}
											</div>
											<div className='mb-2'>
												<div
													className='ml-3 text-green-600 w-[350px] underline'
													onClick={() =>
														setShowAddressForm(true)
													}
												>
													Change Shipping Address
												</div>
											</div>
										</>
									)}
								</>
							)}
						</div>
						<div className='mt-2 sm:mt-5 pt-3 md:pt-7 mb-2 pb-1'>
							<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
								Payment
							</div>
							{loggedIn ? (
								<>
									<div className='mt-1 mb-2 text-lg font-semibold'>
										Select a card for this order
									</div>
									{!loadingAddition &&
										!stripeLoading &&
										!loadingUser &&
										!loadingPaymentMethods && (
											<div>
												{user.paymentMethods &&
												cardRadios.length > 0 ? (
													<div>
														<RadioGroup
															className='mt-1 sm:mt-0'
															name='sortBy'
															radios={cardRadios}
															onChange={(
																paymentMethodId
															) => {
																setSelectedPaymentMethod(
																	paymentMethodId
																)
															}}
														/>
													</div>
												) : (
													<div>
														<div className='font-semibold'>
															No cards on file
														</div>
													</div>
												)}
											</div>
										)}
									{loadingPaymentMethods && (
										<div className='mt-3 text-center'>
											<BounceLoaderCentered container='div' />
										</div>
									)}
									{loadingAddition && (
										<div className='mt-3 text-center'>
											<BounceLoaderCentered container='div' />
										</div>
									)}
									{loadingUser && (
										<div className='mt-3 text-center'>
											<BounceLoaderCentered container='div' />
										</div>
									)}
									{stripeLoading && (
										<div className='mt-3 text-center'>
											<BounceLoaderCentered container='div' />
										</div>
									)}
									<div className='w-[90%] max-w-[440px] mb-3'>
										<div>
											{!showCardForm && (
												<div>
													<button
														className='my-3 text-lg underline text-green-600 font-semibold'
														onClick={() => {
															setShowCardForm(
																true
															)
														}}
													>
														Add a card
													</button>
												</div>
											)}
											{showCardForm && (
												<div>
													<div className='mt-4'>
														<div className='text-sm'>
															Enter card info
															below
														</div>

														<form
															onSubmit={
																handleAddCardSubmit
															}
														>
															<CardElement
																options={
																	cardElementOptions
																}
															/>
															{!loadingAddition &&
																!stripeLoading &&
																!loadingUser && (
																	<div>
																		<Button
																			className='mt-2'
																			text='Add Card'
																			size='sm'
																			submit={
																				true
																			}
																		/>
																	</div>
																)}
														</form>
													</div>
													<div>
														{!loadingAddition &&
															!stripeLoading &&
															!loadingUser && (
																<button
																	className='mt-6 text-[16px] underline text-green-600 font-semibold'
																	onClick={() => {
																		setShowCardForm(
																			false
																		)
																	}}
																>
																	Cancel
																</button>
															)}
													</div>
												</div>
											)}
										</div>
									</div>
								</>
							) : (
								<form
									onSubmit={handleAddCardGuest}
									className='w-full sm:w-[500px]'
								>
									<CardElement options={cardElementOptions} />
									{cardAdded ? (
										<div className='text-green-600 font-bold'>
											Card successfully added!
										</div>
									) : (
										<div></div>
									)}
									{paymentError ? (
										<div className='text-red-600 font-bold'>
											There was an error adding your card.
											Please try again.
										</div>
									) : (
										<div></div>
									)}
									{!loadingAddition &&
										!stripeLoading &&
										!loadingUser &&
										contactSaved && (
											<div>
												<Button
													className={`mt-2 px-2`}
													text='Add Card'
													size='sm'
													submit={true}
												/>
											</div>
										)}
									{loadingAddition && (
										<div>
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										</div>
									)}
									{stripeLoading && (
										<div>
											<BounceLoader
												className='m-auto'
												color={bounceLoaderColor}
											></BounceLoader>
										</div>
									)}
								</form>
							)}
						</div>
						{/* PROMO */}
						<div className='mt-2 sm:mt-5 pt-3 md:pt-7 mb-4 md:mb-10 pb-3'>
							<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
								Giftcard / Promo
							</div>
							<div className='w-full h-full'>
								<Input
									label='Code'
									className='w-[350px] mb-4'
									onChange={(e) =>
										setProvidedCode(e.target.value)
									}
								/>
								{contactSaved ? (
									<Button
										text='Apply'
										size='sm'
										className='w-[350px]'
										onClick={(e) => handleCodeValidation(e)}
									/>
								) : (
									<div>
										<Button
											text='Apply'
											size='sm'
											color="grey"
											className='w-[350px]'
											onClick={()=>{}}
										/>
										<div className="mt-2 text-red-400">Add contact info before applying promo</div>
									</div>
								)}
							</div>
							{promoMessage && (
								<div className='mt-4'>{promoMessage}</div>
							)}
						</div>
					</div>
				</div>
				<div className='shadow-lighter-grey md:w-2/5 lg:w-1/3 md:overflow-y-auto md:scrollbar'>
					{updateUserLoading && (
						<div className='flex items-center h-screen-no-header-mobile sm:h-screen-no-header'>
							<BounceLoaderCentered container='div' />
						</div>
					)}

					<div className='w-[90%] m-auto max-w-[500px] divide-y-2 divide-solid mb-2'>
						<div>
							<div className='font-header my-2 text-black-default text-2xl md:text-3xl'>
								Reusables order summary
							</div>
						</div>
						<ReusablesCartItem
							cartItems={cartItemsReusablesShop}
							handleUpdateCartItem={handleUpdateCartItem}
						/>
						<div>
							<div className='flex flex-col mt-5'>
								<div className='flex flex-row justify-between my-2'>
									<div className=''>Subtotal</div>
									<div>${cartTotal.toFixed(2)}</div>
								</div>
								{promoIsActive && (
									<div className='flex flex-row justify-between my-2 text-green-600'>
										<div className=''>Promo</div>
										<div>
											- ${promoDollarAmount.toFixed(2)}
										</div>
									</div>
								)}
								{giftCardIsActive && (
									<div className='flex flex-row justify-between my-2 text-green-600'>
										<div className=''>GiftCard</div>
										<div>
											- ${giftCardDiscount.toFixed(2)}
										</div>
									</div>
								)}
								<div className='flex flex-row justify-between my-2'>
									<div className=''>Taxes & Fees</div>
									<div>
										${(taxes + serviceFee).toFixed(2)}
									</div>
								</div>
								{/* <div className='flex flex-row justify-between my-2'>
									<div className=''>Taxes</div>
									<div>${taxes.toFixed(2)}</div>
								</div>
								<div className='flex flex-row justify-between my-2'>
									<div className=''>Service Fee</div>
									<div>${serviceFee.toFixed(2)}</div>
								</div> */}
								<div className='flex flex-row justify-between my-2'>
									<div className=''>Shipping Fee</div>
									{shippingFee != null ? (
										<div>${shippingFee.toFixed(2)}</div>
									) : (
										<div className='text-gray-300'>TBD</div>
									)}
								</div>
								<div className='flex flex-row justify-between mt-2 mr-3'>
									<CheckboxGroup
										label='Zero Foodprint Donation (1% of Subtotal)'
										name='default-address'
										className=''
										defaultChecked={true}
										onChange={handleZeroFoodPrint}
									/>
									<div className=''></div>
									<div>
										$
										{(cartTotal * zeroFoodPrint).toFixed(2)}
									</div>
								</div>
								<a
									href='https://www.zerofoodprint.org/'
									target='_blank'
									className='mb-2 text-xs underline text-green-600'
									rel='noreferrer'
								>
									Learn more about how ZFP tackles climate
									change with regenerative farming
								</a>
								<div className='flex flex-row justify-between my-2'>
									<div className='font-semibold text-[28px]'>
										Total
									</div>
									<div className='font-semibold text-[28px]'>
										$
										{(
											cartTotal * zeroFoodPrint +
											cartTotal +
											taxes +
											serviceFee +
											shippingFee +
											giftCardDiscount -
											promoDollarAmount
										).toFixed(2)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='justify-center mx-4 flex flex-col my-2 sticky bottom-20 sm:bottom-6 text-4xl'>
						{submitOrderError && (
							<div className='rounded-[5px] mb-1 px-2 bg-red-400 w-full justify-self-end text-white text-base shadow-light-grey'>
								An error occured while submitting your order.
								Try again or contact us.
							</div>
						)}
						{submittingOrder ? (
							<BounceLoaderCentered container='div' />
						) : invalidAddress ||
						  !selectedPaymentMethod ||
						  selectedPaymentMethod === '' ||
						  !geoCodedAddress ||
						  loadingAddition ||
						  !shippingFee ? (
							<Button
								text='Place Order'
								className='shadow-light-grey w-full'
								color='disabled'
							/>
						) : (
							<Button
								text='Place Order'
								className='shadow-light-grey w-full'
								onClick={handleSubmitOrder}
							/>
						)}
					</div>
				</div>
			</div>
		)
	} else if (
		!cartItemsReusablesShop ||
		(cartItemsReusablesShop &&
			Object.keys(cartItemsReusablesShop).length < 1)
	) {
		return (
			<div className='flex flex-col items-center h-screen-no-header-mobile sm:h-screen-no-header'>
				<div className='mt-10 text-[24px]'>Your cart is empty!</div>
				<div className='mt-2 mb-3 text-[24px]'>Let's change that.</div>
				<Button
					className='m-auto mt-3 px-4'
					text='Order Reusables'
					size='sm'
					link='/reusables-shop'
				></Button>
			</div>
		)
	} else {
		return (
			<div className='flex items-center h-screen-no-header-mobile sm:h-screen-no-header'>
				<BounceLoaderCentered container='div' />
			</div>
		)
	}
}

const mapStateToProps = ({
	User,
	Payments,
	AppInfo,
	Promos,
	ReusablesShop,
}) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	allReusableProducts: ReusablesShop.allProducts,
	loadingReusableProducts: ReusablesShop.loading,
	cartItemsReusablesShop: ReusablesShop.cartItemsReusablesShop,
	loadingCartItems: ReusablesShop.cartItemsLoading,
	loadingClearCart: ReusablesShop.clearingCartReusablesShop,
	submittingOrder: ReusablesShop.submittingOrder,
	submittedOrder: ReusablesShop.submittedOrder,
	submitOrderError: ReusablesShop.submitOrderError,
	loadingUserCart: ReusablesShop.addingToUser,
	cartTotal: ReusablesShop.cartTotal,
	shippingCost: ReusablesShop.shippingCost,
	loadingCost: ReusablesShop.loadingCost,
	costError: ReusablesShop.costError,

	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
	paymentError: Payments.error,
	appInfo: AppInfo.appInfo,
	promoError: Promos.error,
	giftCardIsActive: Promos.giftCardIsActive,
	promoIsActive: Promos.promoIsActive,
	promoLoading: Promos.loading,
	promoMessageType: Promos.promoMessageType,
	giftCard: Promos.giftCard,
	promo: Promos.promo,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAllReusableProducts: (payload) =>
		dispatch(getAllReusableProducts(payload)),
	onAddItem: (payload) => dispatch(addProductReusablesShop(payload)),
	onUpdateItem: (payload) => dispatch(updateCartItemReusablesShop(payload)),
	onUpdateCartFromUser: (payload) => dispatch(updateCartFromUser(payload)),
	onAddCartToUser: (payload) => dispatch(addCartToUserReusablesShop(payload)),
	onClearCart: () => dispatch(clearCartReusablesShop()),
	onTotalCart: (payload) => dispatch(totalReusablesCart(payload)),

	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onResetCardAdded: () => dispatch(resetCardAdded()),
	onGetPaymentMethods: (userStripeId) =>
		dispatch(getPaymentMethods(userStripeId)),
	onSubmitOrder: (submitOrderPayload) =>
		dispatch(submitReusablesOrder(submitOrderPayload)),
	onValidatePromo: (promoCode) => dispatch(validatePromo(promoCode)),
	onValidateGiftCard: (giftCardCode) =>
		dispatch(validateGiftCard(giftCardCode)),
	onUpdateGiftCard: (payload) => dispatch(updateGiftCard(payload)),
	onUpdatePromo: (payload) => dispatch(updatePromo(payload)),
	onResetPromo: () => dispatch(resetPromo()),
	onResetSubmitOrder: () => dispatch(resetReusables()),
	onStorePaymentGuest: (payload) => dispatch(storeGuestPayment(payload)),
	onResetCardAdded: () => dispatch(resetCardResponse()),
	onCalculateShipping: (payload) => dispatch(calculateShipping(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReusablesCheckout)
