import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Input } from './index'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { updateUser } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'

const LocationSearchBar = (props) => {
	const {
		className = '',
		buttonText = 'Order Now',
		size = 'base',
		buttonColor = 'green',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		path,
		...newProps
	} = props
	const bounceLoaderColor = '#507f74'
	let inputSize = `h-`
	let iconSize = `h-`

	if (size === 'sm') {
		inputSize += '11'
		iconSize += '11'
	} else if (size === 'xs') {
		inputSize += '10 py-1'
		iconSize += '11'
	} else if (size === 'base') {
		inputSize += '14'
		iconSize += '14'
	}

	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [userLoc, setUserLoc] = useState(null)
	const [locationWarning, setLocationWarning] = useState(false)

	let navigate = useNavigate()

	useEffect(() => {
		const lastSearchedAddress = JSON.parse(
			localStorage.getItem('lastSearchedAddress')
		)
		if (lastSearchedAddress && lastSearchedAddress.address) {
			setSearchedAddress(lastSearchedAddress.address)
		} else if (user && user.lastSearchedAddress) {
			setSearchedAddress(user.lastSearchedAddress)
		} else {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const crd = position.coords
					reverseGeocodeAddress({
						lat: parseFloat(crd.latitude),
						lng: parseFloat(crd.longitude),
					})
					setUserLoc(searchedAddress)
					setLocationWarning(false)
				},
				(e) => {
					if (user && user.address) {
						setSearchedAddress(user.address)
					} else {
						setSearchedAddress('43 Park Row, New York, NY 10038')
					}
					setLocationWarning(true)
				},
				{ enableHighAccuracy: true, timeout: 5000 }
			)
		}
	}, [user, user?.lastSearchedAddress, navigator, JSON.stringify(navigator)])

	useEffect(() => {
		if (userLoc) {
			setSearchedAddress(userLoc)
			setLocationWarning(false)
		}
	}, [userLoc])

	const reverseGeocodeAddress = async (coords) => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{ location: coords },
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setSearchedAddress(results[0].formatted_address)
				} else {
					setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const geocodeAddress = async () => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: searchedAddress,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// console.log(results[0])
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const handleAddressChange = (newAddress) => {
		setAddressError(false)
		setGeoCodedAddress(null)
		if (newAddress.target) {
			// console.log(newAddress.target.value)
			setSearchedAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
		}
	}

	useEffect(() => {
		// geocodeAddress()
	}, [searchedAddress])

	const handleAddressEnter = (e) => {
		e.preventDefault()
		// Geocode the address
	}

	const handleAddressSubmit = async (e) => {
		// console.log('hit submit')
		e.preventDefault()
		// Geocode the address
		await geocodeAddress()
	}

	useEffect(() => {
		if (geoCodedAddress) {
			const lastSearchedAddress = {
				address: geoCodedAddress,
				lat: geoCodedLat,
				lng: geoCodedLng,
				address2: '',
			}
			localStorage.setItem(
				'lastSearchedAddress',
				JSON.stringify(lastSearchedAddress)
			)
			if (user && user.userId) {
				if (
					user &&
					!user?.savedAddresses.find(
						(address) => address.savedAddress === geoCodedAddress
					)
				) {
					// new address so add to front of array
					onUpdateUser({
						savedAddresses: [
							{
								savedAddress: geoCodedAddress,
								savedAddress2: '',
							},
							...user.savedAddresses,
						],
						lastSearchedAddress: geoCodedAddress,
					})
				} else {
					// exisiting address so need to shift it to front to update ordering
					const existingAddressIdx = user.savedAddresses.findIndex(
						(address) => address.savedAddress === geoCodedAddress
					)
					const savedAddress2 =
						user.savedAddresses[existingAddressIdx].savedAddress2
					const updatedSavedAddresses = [...user.savedAddresses]
					updatedSavedAddresses.splice(existingAddressIdx, 1)
					updatedSavedAddresses.unshift({
						savedAddress: geoCodedAddress,
						savedAddress2: savedAddress2,
					})
					onUpdateUser({
						savedAddresses: updatedSavedAddresses,
						lastSearchedAddress: geoCodedAddress,
						lastSearchedAddress2: savedAddress2,
					})
				}
			}
			// if (path == '/marketplace/locations') {
			// 	window.location.reload()
			// } else if (
			// 	path.includes('marketplace') &&
			// 	path.includes('locations')
			// ) {
			// 	navigate('/marketplace/locations')
			// 	window.location.reload()
			// } else {
			// 	navigate('/marketplace/locations')
			// }

			if (path != '/marketplace/locations') {
				navigate('/marketplace/locations')
			}
		}
	}, [geoCodedAddress])

	return (
		<div
			className={`relative flex w-full items-center md:min-w-[500px] max-w-[720px] ${className} px-4`}
		>
			<FormGroup
				className='h-auto w-full items-center justify-around'
				header
			>
				{loadingUser ? (
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				) : (
					<Input
						placeholder='Enter your address'
						className={`relative bg-${inputBackgroundColor} pl-8 ${inputSize} !-mb-4`}
						error={addressError}
						autoCompleteAddress={true}
						value={searchedAddress}
						onChange={handleAddressChange}
						onKeyDown={(e) => {
							// console.log('in key down', e.target.value)
							handleAddressChange(e)
							e.key === 'Enter' && handleAddressEnter(e)
						}}
					/>
				)}
				{searchedAddress == '' ? (
					<FaMapMarkerAlt
						className={`absolute left-2 ml-4 top-12 transform -translate-y-12 text-green-400 ${iconSize}`}
					/>
				) : (
					<AiOutlineClose
						className={`font-semibold absolute left-2 ml-4 top-12 transform -translate-y-12 text-green-600 cursor-pointer ${iconSize}`}
						onClick={() => setSearchedAddress('')}
					/>
				)}{' '}
				<Button
					text={buttonText}
					size={size}
					color={buttonColor}
					className={
						size === 'xs'
							? 'right-0 px-4 !py-2 '
							: 'right-0 px-4 w-full'
					}
					onClick={handleAddressSubmit}
				/>
			</FormGroup>
		</div>
	)
}

const mapStateToProps = ({ User, Path }) => ({
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchBar)
