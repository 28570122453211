import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getWFMData,
	getWFMDataBySlug,
	getWFMLocations,
	uploadWFMData,
	getWFMEnvImpact,
} from '../../model/wholefoods'
import {
	getWFMDataFail,
	getWFMDataSuccess,
	getWFMDataBySlugFail,
	getWFMDataBySlugSuccess,
	getWFMLocationsFail,
	getWFMLocationsSuccess,
	uploadWFMDataFail,
	uploadWFMDataSuccess,
	getWFMEnvImpactSuccess,
} from './actions'
import {
	GET_WFM_DATA,
	GET_WFM_BY_SLUG,
	GET_WFM_LOCATIONS,
	UPLOAD_WFM_DATA,
	GET_WFM_ENV_IMPACT,
} from './actionTypes'

function* fetchWFMData(action) {
	try {
		const allData = yield call(getWFMData, action.payload)
		yield put(getWFMDataSuccess(allData))
	} catch (error) {
		yield put(getWFMDataFail(error))
	}
}

function* storeWFMData(action) {
	try {
		const data = yield call(uploadWFMData, action.payload)
		yield put(uploadWFMDataSuccess(data))
	} catch (error) {
		yield put(uploadWFMDataFail(error))
	}
}

function* fetchWFMLocations(action) {
	try {
		const locations = yield call(getWFMLocations, action.payload)
		yield put(getWFMLocationsSuccess(locations))
	} catch (error) {
		yield put(getWFMLocationsFail(error))
	}
}

function* fetchWFMDataBySlug(action) {
	try {
		const data = yield call(getWFMDataBySlug, action.payload)
		yield put(getWFMDataBySlugSuccess(data))
	} catch (error) {
		yield put(getWFMDataBySlugFail(error))
	}
}

function* fetchWFMEnvImpact(action) {
	try {
		const impact = yield call(getWFMEnvImpact, action.payload)
		yield put(getWFMEnvImpactSuccess(impact))
	} catch (error) {
		console.log(error)
	}
}

function* wholefoodsSaga() {
	yield takeEvery(GET_WFM_DATA, fetchWFMData)
	yield takeEvery(UPLOAD_WFM_DATA, storeWFMData)
	yield takeEvery(GET_WFM_LOCATIONS, fetchWFMLocations)
	yield takeEvery(GET_WFM_BY_SLUG, fetchWFMDataBySlug)
	yield takeEvery(GET_WFM_ENV_IMPACT, fetchWFMEnvImpact)
}

export default wholefoodsSaga
