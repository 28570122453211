import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../Elements'

const Partners = () => {
	return (
		<div className='h-auto bg-yellow-400 py-8 flex justify-center items-center '>
			<div className='lg:flex-row-reverse lg:w-[800px] lg:h-[350px] w-[350px] h-auto pb-8 lg:pb-0 rounded-xl shadow-light-grey text-center flex flex-col justify-center items-center bg-white z-10 '>
				<div className='w-[350px] h-[350px] lg:w-full lg:h-full flex justify-center items-center lg:justify-end lg:items-end -mb-[10px] lg:m-0 lg:p-0'>
					<img
						src='images/Chef.png'
						alt='a picture of a chef filling a deliver zero reusable container'
						className='w-[250px] h-[270px] lg:h-full object-cover lg:rounded-r-xl'
					/>
				</div>
				<div className='lg:w-1/2 w-[350px] h-full flex flex-col justify-start lg:justify-center lg:text-start lg:ml-[100px] px-4'>
					<h1 className='font-header text-green-600 my-6 text-2xl lg:text-3.5xl'>
						For Restaurant Partners
					</h1>
					<p className='mb-4 leading-7 text-md'>
						Doing the right thing for the environment just feels
						good… especially when it makes your customers happy and
						saves you money.
					</p>

					<Button
						color='green'
						size='sm'
						className='mt-4 mb-6 lg:w-[330px]'
						text='Partner With Us'
						link='/restaurantpartners'
					/>
				</div>
			</div>
		</div>
	)
}

export default Partners
