import React, { useEffect, useState } from 'react'
import { getUser } from '../../store/actions'
import { connect } from 'react-redux'
import {
	Button,
	CheckboxGroup,
	SelectGroup,
	Input,
	RadioGroup,
	BounceLoaderCentered,
	FormGroup,
	CheckboxSelections,
} from '../Elements'

const ContactForm = (props) => {
	const {
		addContact,
		setShowAddContact,
		allLocs,
		adminAccess,
		setAdminAccess,
		contacts,
	} = props

	const [name, setName] = useState(null)
	const [email, setEmail] = useState(null)
	const [phone, setPhone] = useState('')
	const [title, setTitle] = useState(null)
	const [locations, setLocations] = useState(null)
	// const [showLocs, setShowLocs] = useState(false);
	const [formError, setFormError] = useState(false)

	const handleSubmit = (e) => {
		e.preventDefault()
		if (checkExistingPhone(phone)) {
			setFormError('A contact already exists with this phone number')
		} else if (checkExistingEmail(email)) {
			setFormError('A contact already exists with this email address')
		} else if (!name) {
			setFormError('Please enter contact name')
		} else if (!email || !validateEmail(email)) {
			setFormError('Please enter a valid email address')
		} else if (!phone || !validatePhone(phone)) {
			setFormError('Please enter a valid phone number (10 digits)')
		} else if (!title) {
			setFormError('Please provide contact title/position')
			// } else if (showLocs && !locations) {
			//     setFormError(
			//         "Please select locations this contact should have access to"
			//     );
		} else {
			addContact({ name, email, phone, title, adminAccess })
			setName('')
			setEmail('')
			setPhone('')
			setTitle('')
			setShowAddContact(false)
		}
	}

	const checkExistingPhone = (phone) => {
		const existingContact = contacts.find(
			(contact) => contact.phone === phone
		)
		if (existingContact) {
			return true
		} else {
			return false
		}
	}

	const checkExistingEmail = (email) => {
		const existingContact = contacts.find(
			(contact) => contact.email === email
		)
		if (existingContact) {
			return true
		} else {
			return false
		}
	}

	const validateEmail = (email) => {
		const emailPattern = /\S+@\S+\.\S+/
		return emailPattern.test(email)
	}

	const validatePhone = (phone) => {
		return phone.length === 10 && !isNaN(phone)
	}

	const handleChange = (value, checked) => {
		if (Array.isArray(locations)) {
			if (checked) {
				setLocations((prevLocations) => [...prevLocations, value])
			} else {
				setLocations((prevLocations) =>
					prevLocations.filter((loc) => loc !== value)
				)
			}
		} else if (locations === undefined || locations === null) {
			setLocations([value])
		} else {
			setLocations([locations, value])
		}
	}

	return (
		<FormGroup
			onSubmit={handleSubmit}
			className='w-fit flex flex-col justify-center items-center border-2 border-green-600 p-6 shadow-xl rounded-xl bg-white mb-3'
		>
			<Input
				type='text'
				className='my-1 w-[300px] mx-auto'
				placeholder='Name'
				value={name}
				onChange={(e) => {
					setName(e.target.value)
					setFormError('')
				}}
			/>
			<Input
				type='email'
				className='my-1 w-[300px] mx-auto'
				placeholder='Email'
				value={email}
				onChange={(e) => {
					setEmail(e.target.value)
					setFormError('')
				}}
			/>
			<Input
				type='phone'
				className='my-1 w-[300px] mx-auto'
				placeholder='Phone'
				value={phone}
				onChange={(e) => {
					setPhone(e.target.value)
					setFormError('')
				}}
			/>
			<Input
				type='text'
				className='my-1 w-[300px] mx-auto'
				placeholder='Title (i.e., GM, Owner)'
				value={title}
				onChange={(e) => {
					setTitle(e.target.value)
					setFormError('')
				}}
			/>
			<div className='my-1 w-[300px] mx-auto'>
				<h3>Grant new contact Admin access?</h3>
				<SelectGroup
					placeholder='Select...'
					options={[
						{ value: 'yes', label: 'Yes' },
						{ value: 'no', label: 'No' },
					]}
					onChange={(e) => {
						if (e.target.value === 'yes') {
							setAdminAccess(true)
						} else {
							setAdminAccess(false)
							setLocations([])
						}
					}}
				/>
				{adminAccess && (
					<div className='italic my-1'>
						New users will be recieve an email invitation to create
						an account
					</div>
				)}
			</div>
			{/* {showLocs && (
                <div className="mb-3 w-[500px] mx-auto">
                    <CheckboxSelections
                        name="locations"
                        options={allLocs.map((loc) => ({
                            value: loc,
                            label: loc,
                        }))}
                        onChange={(e) => {
                            handleChange(e.target.value, e.target.checked);
                        }}
                    />
                </div>
            )} */}
			{formError && <div className='text-red-400'>{formError}</div>}
			<Button
				className='w-[300px] mx-auto my-1'
				size='sm'
				text='Save'
				onClick={handleSubmit}
			>
				Add Contact
			</Button>
		</FormGroup>
	)
}

const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
