import { realm } from '../helpers/realm'
import { runAggregationApi } from './aggregation'

export const getOfficeApi = async (officeSlug) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const offices = client.db('caas').collection('offices')
	const office = await offices.findOne({
		slug: officeSlug,
	})
	return office
}

export const getOneTransactionOffice = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')

	const stages = [
		{
			$lookup: {
				from: 'nodes',
				localField: 'node',
				foreignField: 'slug',
				as: 'nodeObject',
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	if (limit) {
		stages.push({
			$facet: {
				pagination: [
					{
						$count: 'total',
					},
				],
				data: [{ $skip: skip }, { $limit: limit }],
			},
		})
	}

	const transactionsAggregated = await transactions.aggregate(stages)

	return transactionsAggregated
}

export const getOfficeTransactions = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')

	const stages = [
		{
			$lookup: {
				from: 'nodes',
				localField: 'node',
				foreignField: 'slug',
				as: 'nodeObject',
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	if (limit) {
		stages.push({
			$facet: {
				pagination: [
					{
						$count: 'total',
					},
				],
				data: [{ $skip: skip }, { $limit: limit }],
			},
		})
	}

	const transactionsAggregated = await transactions.aggregate(stages)

	return transactionsAggregated
}

export const updateOfficeApi = async (updateObject) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const offices = client.db('caas').collection('offices')
	const update = await offices.updateOne(
		{
			slug: updateObject.slug,
		},
		{ $set: updateObject.update }
	)

	const office = await offices.findOne({
		slug: updateObject.slug,
	})

	return office
}

export const getOfficeAdminsApi = async (officeSlug) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const users = client.db('caas').collection('users')
	const officeAdmins = await users.find({
		$and: [{ officeAdmin: true, officeSlug: officeSlug }],
	})

	return officeAdmins
}

export const assignOfficeAdminApi = async (payload) => {
	const subscriberStatus = await realm.currentUser.callFunction(
		'office/assignAdminToOffice',
		payload
	)

	return
}

export const scheduleOfficePickupApi = async (payload) => {
	const subscriberStatus = await realm.currentUser.callFunction(
		'office/scheduledPickupsAlert',
		payload
	)

	return
}

export const getOfficeCustomers = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const client = realm.currentUser.mongoClient('RealmService')
	const customers = client.db('caas').collection('users')

	const pipeline = [
		{ $match: filter },
		{ $sort: sort },
		{
			$lookup: {
				from: 'transactions',
				localField: 'email',
				foreignField: 'user.email',
				as: 'transactionObject',
			},
		},
	]

	const customersAggregated = await customers.aggregate(pipeline)

	if (!customersAggregated || customersAggregated.length === 0) {
		return []
	}

	const customersWithPackaging = await Promise.all(
		customersAggregated.map(async (customer) => {
			if (
				!customer.transactionObject ||
				customer.transactionObject.length === 0
			) {
				return customer
			}

			const getTotalPackagingPreventedAggr = await runAggregationApi({
				phone: customer.phone,
				functionName: 'getTotalPackagingPrevented',
			})

			const getCustomerReturnRateAggr = await runAggregationApi({
				phone: customer.phone,
				functionName: 'getCustomerReturnRate',
			})

			const getLatePackagingByCustomerAggr = await runAggregationApi({
				phone: customer.phone,
				functionName: 'getLatePackagingByCustomer',
			})

			customer.getTotalPackagingPrevented =
				getTotalPackagingPreventedAggr.getTotalPackagingPrevented
			customer.getCustomerReturnRate =
				getCustomerReturnRateAggr.getCustomerReturnRate
			customer.getLatePackagingByCustomer =
				getLatePackagingByCustomerAggr.getLatePackagingByCustomer

			return customer
		})
	)

	return customersWithPackaging
}

export const getOneCustomerOffice = async (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const { filter, options } = payload
	const { sort, skip, limit } = options

	const client = realm.currentUser.mongoClient('RealmService')
	const customers = client.db('caas').collection('users')

	const stages = []

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	if (limit) {
		stages.push({
			$facet: {
				pagination: [
					{
						$count: 'total',
					},
				],
				data: [{ $skip: skip }, { $limit: limit }],
			},
		})
	}

	const customersAggregated = await customers.aggregate(stages)

	return customersAggregated
}

export const addPaymentMethodOffice = async (addPayload) => {
	if (addPayload.restaurantAdmin) {
		return
	}
	const paymentMethods = await realm.currentUser.callFunction(
		'stripe/addPaymentMethodOffice',
		addPayload
	)

	return paymentMethods
}

export const updateStripeOfficeEmail = async (addPayload) => {
	const paymentMethods = await realm.currentUser.callFunction(
		'stripe/updateOfficeCustomerEmail',
		addPayload
	)

	return paymentMethods
}

export const getOfficeByAdminCode = async (adminCodeOrSlug) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const offices = client.db('caas').collection('offices')
	return offices.findOne({
		$or: [
			{ adminPinCode: adminCodeOrSlug.toLowerCase() },
			{ slug: adminCodeOrSlug.toLowerCase() },
		],
	})
}

export const inviteOfficeAdmin = async (payload) => {
	const response = await realm.currentUser.callFunction(
		'office/inviteNew',
		payload
	)
	return response
}
