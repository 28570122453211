export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const CREATE_TRANSACTION_EMAIL = 'CREATE_TRANSACTION_EMAIL'
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS'
export const CREATE_TRANSACTION_FAIL = 'CREATE_TRANSACTION_FAIL'
export const RESET_TRANSACTION = 'RESET_TRANSACTION'
export const CREATE_OR_UPDATE_TRANSACTION = 'CREATE_OR_UPDATE_TRANSACTION'
export const CREATE_OR_UPDATE_TRANSACTION_SUCCESS =
	'CREATE_OR_UPDATE_TRANSACTION_SUCCESS'
export const CREATE_OR_UPDATE_TRANSACTION_ERROR =
	'CREATE_OR_UPDATE_TRANSACTION_ERROR'
