import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { getUser, getAppInfo } from '../../../store/actions'
import { Footer } from '../../../components'
import Header from '../../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const LogisticsHome = (state) => {
	const { user, onGetUser, onGetAppInfo } = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<h1 className='font-header text-green-600 text-4xl mb-11 mt-14 text-center'>
					Logistics
				</h1>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-1 mb-8 flex justify-center mx-auto w-full lg:max-w-[1200px]'>
					{!user.franchiseeSlug && (
						<>
							<div
								onClick={() => {
									navigate('/admin/logistics/packinglist')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Packing List Creator
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									Schedule stops for dropoffs/pickups
								</p>
							</div>
							<div
								onClick={() => {
									navigate(
										'/admin/logistics/packinglist/dashboard'
									)
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									DZ Internal Fulfillment
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View and update scheduled stops
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/dropoff')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Third Party Logistics Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									Create and view third party dropoffs
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/logistics/pickups')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Customer Pickups
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View internal and third party customer
									pickups
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/logistics/dashboard')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Logistics Stops Dashboard
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									See data on previous stops
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/logistics/inventory')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Inventory
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									See current warehouse and washhub inventory
									data
								</p>
							</div>
							<div
								onClick={() => {
									navigate('/admin/logistics/monthly')
								}}
								className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
							>
								<h1 className='font-header text-2xl mt-6 ml-10'>
									Monthly Mapping
								</h1>
								<p className='font-header text-base mt-3 mx-10 mb-10'>
									View logistics data by month
								</p>
							</div>
						</>
					)}
					<div
						onClick={() => {
							navigate('/admin/route-creator')
						}}
						className='w-80% mt-8 w-[500px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
					>
						<h1 className='font-header text-2xl mt-6 ml-10'>
							Route Creator
						</h1>
						<p className='font-header text-base mt-3 mx-10 mb-10'>
							Create your optimized route for the day
						</p>
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, AppInfo }) => ({
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsHome)
