import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	getDropoffQuote,
	createDropoff,
	createRoute,
	getDzPickups,
	sendSMS,
	getAllLogistics,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	TextArea,
} from '../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'
import { useJsApiLoader } from '@react-google-maps/api'
import { googleMapsLibraries } from '../../../helpers/googleMapsAPI'
// import { Header } from '../../components'

const RouteCreator = (state) => {
	const {
		user,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		creatingRoute,
		createdRoute,
		createRouteError,
		onCreateRoute,
		gettingDzPickups,
		dzPickups,
		dzPickupsError,
		onGetDzPickups,
		onSendSMS,
		sendingSMS,
		sentSMS,
		sendSMSError,
		stops,
		loadingStops,
		stopsError,
		onGetAllStops,
	} = state

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [pickupNode, setPickupNode] = useState({})
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [showNodeChoicesDropOff, setShowNodeChoicesDropOff] = useState(false)
	const [formError, setFormError] = useState('')
	const [startingLocation, setStartingLocation] = useState({})
	const [destinations, setDestinations] = useState([])
	const [phoneNumber, setPhoneNumber] = useState('')
	const [userEnteredStart, setUserEnteredStart] = useState('')
	const [currentlyTypedAddressValue, setCurrentlyTypedAddressValue] =
		useState('')
	const [currentlyTypedAddressValueDest, setCurrentlyTypedAddressValueDest] =
		useState('')
	const [date, setDate] = useState('')
	const [showStops, setShowStops] = useState(false)

	const google = window.google
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: googleMapsLibraries,
	})

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		const today = new Date()
		today.setDate(today.getDate() + 1)

		const yyyy = today.getFullYear()
		const mm = String(today.getMonth() + 1).padStart(2, '0')
		const dd = String(today.getDate()).padStart(2, '0')

		const formattedDate = `${yyyy}-${mm}-${dd}`

		setDate(formattedDate)
	}, [])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		// console.log(filter)
		onGetNodes([filter])
	}, [filter])

	const removeFromDropoff = (nodeName) => {
		var tempArray = destinations
		tempArray = tempArray.filter((obj) => obj.name !== nodeName)
		setDestinations(tempArray)
	}

	const handleCreateRoute = () => {
		onCreateRoute({
			startingLocation: startingLocation,
			destinations: destinations,
		})
	}

	const importDzPickups = () => {
		// console.log('jere')
		onGetDzPickups()
	}

	useEffect(() => {
		// console.log(dzPickups)
	}, [dzPickups])

	const processTransactions = (transactions) => {
		return transactions.map((transaction) => {
			const name =
				transaction.user.firstName + ' ' + transaction.user.lastName
			const address =
				transaction.user.street +
				', ' +
				transaction.user.city +
				', ' +
				transaction.user.state +
				', ' +
				transaction.user.postal
			const note =
				transaction.pickupNote +
				' Unit: ' +
				transaction.user.unit +
				' BoxCount: ' +
				transaction.boxCount
			const phone = transaction.user.phone
			return {
				name: name,
				address: address,
				note: note,
				phone: phone,
			}
		})
	}

	const processStops = (stops) => {
		return stops.map((packingList) =>
			packingList?.stops?.map((stop) => {
				const name = stop.name
				const address = stop.address
				const note =
					stop.note +
					', BoxCount: ' +
					stop.boxCount +
					', ' +
					(stop.stopType === 'pick' ? 'Collection' : 'Drop Off') +
					', Container Details: ' +
					JSON.stringify(stop.products)
				return {
					name: name,
					address: address,
					note: note,
				}
			})
		)
	}

	const addDzPickupsToDestinations = () => {
		const pickupsArray = processTransactions(dzPickups)
		const combinedArray = destinations.concat(pickupsArray)
		setDestinations(combinedArray)
	}

	const addPackingListStopsToDestinations = () => {
		const pickupsArray = processStops(stops)
		const combinedArray = destinations.concat(pickupsArray[0])
		setDestinations(combinedArray)
	}

	function generateDrivingInstructions(route) {
		const restaurants = route.routeOrder
		let instructions = 'Driving Instructions:\n\n'
		instructions += 'Starting Location: ' + restaurants[0].name + '\n'
		instructions += restaurants[0].address + '\n\n'

		for (let i = 1; i < restaurants.length; i++) {
			instructions += 'Stop ' + i + ': ' + restaurants[i].name + '\n'
			instructions += 'Address: ' + restaurants[i].address + '\n'
			if (restaurants[i].note) {
				instructions += 'NOTE: ' + restaurants[i].note + '\n'
			}
			if (restaurants[i].phone) {
				instructions += 'PHONE: ' + restaurants[i].phone + '\n'
			}
			instructions += restaurants[i].mapsUrl + '\n\n'
		}

		return instructions
	}

	useEffect(() => {
		if (user) {
			setPhoneNumber(user.phone.substring(2))
		}
	}, [user])

	function handleSendSMS() {
		const text = generateDrivingInstructions(createdRoute)
		const splitSize = 1600
		const chunks = []

		let currentIndex = 0
		while (currentIndex < text.length) {
			let end = currentIndex + splitSize

			// Make sure not to split a word
			while (end < text.length && !/\s/.test(text[end])) {
				end--
			}

			// Make sure not to split a link
			const lastLinkIndex = text.lastIndexOf('https://', end)
			if (lastLinkIndex > currentIndex) {
				const nextSpaceIndex = text.indexOf(' ', lastLinkIndex)
				if (nextSpaceIndex > 0 && nextSpaceIndex < end) {
					end = nextSpaceIndex
				}
			}

			const chunk = text.slice(currentIndex, end)
			chunks.push(chunk)

			currentIndex = end
		}

		for (const chunk of chunks) {
			onSendSMS({
				to: '+1' + phoneNumber,
				message: chunk,
			})
		}
	}

	const onAddressSelectStart = (address) => {
		setStartingLocation({
			name: 'User Entered Start',
			address: address,
		})
	}

	const autoCompleteQueryStart = (address, coords) => {
		// console.log('ADDRESS', address)
		// console.log('COORDS', coords)
		onAddressSelectStart(address)
	}

	const handleSubmitAddressStart = (address) => {
		// console.log('ADDRESS', address)
		let latLng = window.google.maps.Geocoder(address)
		onAddressSelectStart(address.target.value)
	}
	// console.log(destinations)

	const onAddressSelectDest = (address) => {
		var tempArray = destinations
		tempArray.push({
			name: 'User Entered Destination',
			address: address,
		})
		setDestinations(tempArray)
	}

	const autoCompleteQueryDest = (address) => {
		// console.log('ADDRESS', address)
		// console.log('COORDS', coords)
		onAddressSelectDest(address)
	}

	const handleSubmitAddressDest = (address) => {
		// console.log('ADDRESS', address)
		let latLng = window.google.maps.Geocoder(address)
		onAddressSelectDest(address.target.value)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>

				<div className='px-12'>
					<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
						<h1 className='font-header text-green-600 text-4xl mb-7 mt-4 text-center'>
							Create Route
						</h1>

						<div className='text-lg font-bold mt-4'>
							Starting Location
						</div>
						<div className='w-4/5 max-w-[600px] mb-4'>
							<Input
								placeholder='Search and select pickup location'
								className=''
								name='searchInput'
								id='searchInput'
								onChange={(e) => {
									setShowNodeChoices(true)
									setFilter({
										$or: [
											{
												name: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
											{
												slug: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
										],
									})
								}}
							/>
							<div>Or search an address</div>
							<Input
								placeholder={
									userEnteredStart
										? userEnteredStart
										: 'Search by address'
								}
								className='pl-12'
								name='nodeAddress'
								error={false}
								autoCompleteAddress={true}
								onKeyDown={(e) => {
									e.code === 'Enter'
										? handleSubmitAddressStart(e)
										: null
								}}
								onChange={(e) => {
									{
										e.target
											? setUserEnteredStart(
													e.target.value
											  )
											: setUserEnteredStart(
													e.formatted_address
											  )
									}
									{
										e.target
											? setCurrentlyTypedAddressValue(
													e.target.value
											  )
											: setCurrentlyTypedAddressValue(
													e.formatted_address
											  )
									}
								}}
								autoCompleteQuery={autoCompleteQueryStart}
								onAddressSelect={onAddressSelectStart}
								value={
									userEnteredStart
										? userEnteredStart
										: currentlyTypedAddressValue
								}
							/>
						</div>
						<div className='flex-col flex items-center'>
							{showNodeChoices && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									{nodes.map((node) => (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											key={node.name}
											onClick={() => {
												setStartingLocation({
													name: node.name,
													address: [
														node.address,
														node.city,
														node.state,
														node.postal,
													].join(', '),
												})
												setShowNodeChoices(false)
											}}
										>
											{node.name}
										</div>
									))}
								</div>
							)}
						</div>
						<div className='flex flex-row'>
							<div className='mb-3'>
								<span className='font-bold '>
									Selected Start Location:
								</span>{' '}
								{startingLocation.name}
							</div>

							{Object.keys(pickupNode).length > 0 && (
								<div
									className='ml-4 font-bold text-lg cursor-pointer text-green-600'
									onClick={() => {
										setPickupNode({})
									}}
								>
									X
								</div>
							)}
						</div>
						<div className='mb-3'>
							<span className='font-bold '>Address:</span>{' '}
							{startingLocation.address}
						</div>
						<div className='text-lg font-bold mt-4'>
							Destinations
						</div>
						<div className='w-4/5 max-w-[600px] mb-4'>
							<Input
								placeholder='Search and select destinations'
								className=''
								name='searchInput'
								id='searchInput'
								onChange={(e) => {
									setShowNodeChoicesDropOff(true)
									setFilter({
										$or: [
											{
												name: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
											{
												slug: {
													$regex: e.target.value,
													$options: 'i',
												},
											},
										],
									})
								}}
							/>
							<div>Or search addresses</div>
							<Input
								placeholder='Search by address'
								className='pl-12'
								name='nodeAddress'
								error={false}
								autoCompleteAddress={true}
								onKeyDown={(e) => {
									e.code === 'Enter'
										? handleSubmitAddressDest(e)
										: null
								}}
								onChange={(e) => {
									{
										e.target
											? setCurrentlyTypedAddressValueDest(
													e.target.value
											  )
											: setCurrentlyTypedAddressValueDest(
													e.formatted_address
											  )
									}
								}}
								autoCompleteQuery={autoCompleteQueryDest}
								onAddressSelect={onAddressSelectDest}
								// defaultValue={addressValue ? addressValue : ''}
								value={currentlyTypedAddressValueDest}
							/>
						</div>
						<div className='flex-col flex items-center'>
							{showNodeChoicesDropOff && (
								<div className='absolute z-20 h-1/5 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[280px] whitespace-nowrap'>
									<div
										className='cursor-pointer text-green-600'
										onClick={() => {
											setShowNodeChoicesDropOff(false)
										}}
									>
										{' '}
										Close X
									</div>
									{nodes.map((node) => (
										<div
											className='text-sm cursor-pointer my-1 hover:bg-gray-100'
											key={node.name}
											onClick={() => {
												var tempArray = destinations
												const found = tempArray.some(
													(obj) =>
														obj.name === node.name
												)
												if (found) {
												} else {
													tempArray.push({
														name: node.name,
														address: [
															node.address,
															node.city,
															node.state,
															node.postal,
														].join(', '),
													})
												}
												setDestinations(tempArray)
												setShowNodeChoicesDropOff(false)
											}}
										>
											{node.name}
										</div>
									))}
								</div>
							)}
						</div>
						{!user.franchiseeSlug &&
							(gettingDzPickups ? (
								<BounceLoaderCentered
									className='mt-6'
									container='div'
								/>
							) : (
								<Button
									className='px-2 my-3 w-[300px]'
									size='sm'
									onClick={importDzPickups}
									text="Import this coming Monday's Customer Returns"
								/>
							))}
						{/* {!user.franchiseeSlug && (
                            loadingStops ? (
                                <BounceLoaderCentered className="mt-6" container="div"/>
                            ) : (
                                <Button className="px-2 my-3 w-[300px]" size="sm" onClick={importStopsCO} text="Import Today's Packing List Denver/Boulder"/>
                            )
                        )}                                
                        {!user.franchiseeSlug && (
                            gettingDzPickups ? (
                                <BounceLoaderCentered className="mt-6" container="div"/>
                            ) : (
                                <Button className="px-2 my-3 w-[300px]" size="sm" onClick={importStopsNY} text="Import Today's Packing List NY"/>
                            )
                        )}                                 */}

						{dzPickups && dzPickups.length > 0 && (
							<div>
								{dzPickups.map((dzPickup) => (
									<div className='my-2'>
										{dzPickup.user.firstName}{' '}
										{dzPickup.user.lastName},{' '}
										<b>{dzPickup.user.pickupAddress}</b>,{' '}
										{dzPickup.pickupNote}, Box Count:{' '}
										{dzPickup.boxCount}
										<hr />
									</div>
								))}
								<Button
									className='my-6 w-[300px]'
									size='sm'
									onClick={addDzPickupsToDestinations}
									text='Add Customer Pickups to Destinations'
								/>
							</div>
						)}
						{/* {showStops && stops && stops.length > 0 && (
                            <div> 
                                {stops.map((packingList) => (
										packingList?.stops?.map((stop) => (
                                    <div className="my-2" key={stop.stopId}>
                                        {stop.name}, <b>{stop.address}</b>, {stop.note}, {stop.stopType === "pick" ? 'Collection' : 'Drop Off'}, Box Count: {stop.boxCount}
                                        <hr/>
                                    </div>
                                ))))}
                                <Button className="my-6 w-[300px]" size="sm" onClick={addPackingListStopsToDestinations} text="Add All Stops to Destinations"/>
                            </div>
                        )} */}

						<div className='mb-3'>
							<span className='font-bold '>
								Selected Destinations:
							</span>
						</div>
						{destinations.map((node) => (
							<div
								className='flex flex-row'
								key={node.name}
							>
								<div className='mt-1'>
									{node.name}: {node.address}
								</div>
								<div
									className='ml-4 font-bold text-lg mt-1 cursor-pointer text-green-600'
									onClick={() => {
										removeFromDropoff(node.name)
									}}
								>
									X
								</div>
							</div>
						))}
						{formError !== '' && (
							<div className='font-bold text-red-400 my-1'>
								{formError}
							</div>
						)}
						{createRouteError && (
							<div className='font-bold text-red-400 my-1'>
								{createRouteError.message}
							</div>
						)}
						{creatingRoute ? (
							<BounceLoaderCentered container='div' />
						) : (
							<Button
								className='mt-6 w-[300px]'
								size='sm'
								onClick={handleCreateRoute}
								text='Create Optimized Route'
							/>
						)}

						{createdRoute && (
							<div className='max-w-[500px] mt-10'>
								<Button
									href={createdRoute.fullRoute}
									target='_blank'
									className=''
									size='sm'
									color='green-outlined'
									text='Full Optimized Route'
								/>
								<div className='mt-4 font-bold'>Steps:</div>
								{createdRoute.routeOrder.map((stop, index) => (
									<div>
										{index + 1}: {stop.name}, {stop.address}
										, {stop.note}
									</div>
								))}
								<div className='font-bold mt-8'>
									Send route to driver:
								</div>
								<Input
									type='phone'
									label='PHONE (ONLY DIGITS NO SPACES)'
									name='phone'
									defaultValue={phoneNumber}
									className='bg-white border-2 border-gray-300 mb-2'
									onChange={(e) => {
										setPhoneNumber(e.target.value)
									}}
								/>
								{sendingSMS ? (
									<BounceLoaderCentered container='div' />
								) : (
									<div>
										{phoneNumber.length < 10 ? (
											<Button
												onClick={() => {}}
												className='mt-2'
												size='sm'
												color='gray'
												text='Send SMS With Instructions'
											/>
										) : (
											<Button
												onClick={handleSendSMS}
												className='mt-8'
												size='sm'
												color='green-outlined'
												text='Send SMS With Instructions'
											/>
										)}
									</div>
								)}
								{sendSMSError && (
									<div className='font-bold text-red-400 my-1'>
										{sendSMSError.message}
									</div>
								)}
								{sentSMS && (
									<div className='font-bold text-red-400 my-1 text-green-600'>
										SMS Sent!
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Nodes, User, Admin, Notifications, Logistics }) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	creatingRoute: Admin.creatingRoute,
	createdRoute: Admin.createdRoute,
	createRouteError: Admin.createRouteError,
	gettingDzPickups: Admin.gettingDzPickups,
	dzPickups: Admin.dzPickups,
	dzPickupsError: Admin.dzPickupsError,
	sendingSMS: Notifications.sendingSMS,
	sentSMS: Notifications.sentSMS,
	sendSMSError: Notifications.sendSMSError,
	stops: Logistics.stops,
	loadingStops: Logistics.loadingStops,
	stopsError: Logistics.error,
	stopsSuccess: Logistics.success,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onCreateRoute: (routePayload) => dispatch(createRoute(routePayload)),
	onGetDzPickups: () => dispatch(getDzPickups()),
	onSendSMS: (smsPayload) => dispatch(sendSMS(smsPayload)),
	onGetAllStops: (payload) => dispatch(getAllLogistics(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RouteCreator)
