import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
	loginUser,
	loginUserSuccess,
	socialLoginGoogle,
	socialRegisterGoogle,
} from '../../store/auth/login/actions'
import { getUser } from '../../store/auth/user/actions'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { realm } from '../../helpers/realm'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input'
import { BounceLoader } from 'react-spinners'
import { Button, Input, Alert } from '../../components/Elements'

const OfficeLogin = (props) => {
	const {
		user,
		loggedIn,
		onLoginUser,
		loginLoading,
		loginError,
		loginAttemptStatus,
		onGetUser,
		onGoogleLogin,
		onGoogleRegister,
		userLoading,
		path,
	} = props

	const [form, setForm] = useState({ email: '', password: '' })
	const [existingDzUserLoading, setExistingDzUserLoading] = useState(false)

	const [googleResponse, setGoogleResponse] = useState({})
	const [submitGoogleLogin, setSubmitGoogleLogin] = useState(false)
	const [submitGoogleRegister, setSubmitGoogleRegister] = useState(false)
	const [phone, setPhone] = useState('')
	const [showPhoneField, setShowPhoneField] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [existingDzUser, setExistingDzUser] = useState(false)
	const [missingEmailError, setMissingEmailError] = useState(false)
	const [missingPasswordError, setMissingPasswordError] = useState(false)
	// const

	const bounceLoaderColor = '#507f74'

	const handleChange = (e) => {
		if (e.target.name == 'email') {
			setMissingEmailError(false)
		}
		if (e.target.name == 'password') {
			setMissingPasswordError(false)
		}
		setForm({ ...form, [e.target.name]: e.target.value })
	}
	const handlePhone = (phoneNum) => {
		setPhone(phoneNum)
	}

	const navigate = useNavigate()
	const location = useLocation()

	const [errorMessage, setErrorMessage] = useState(false)
	const errorMessageLookUp = {
		'name already in use': 'This email is already in use.',
		'password must be between 6 and 128 characters':
			'Passwords must be at least 6 characters.',
		'invalid username': 'Email is invalid. Try again or sign up below',
		'invalid username/password':
			"Email or password is invalid. Try again, reset your password, or sign up below. If you've ordered in DeliverZero boxes " +
			'from a third-party, sign up above using your phone number!',
		'invalid password':
			'Password is invalid. Try again or reset your password below',
	}

	useEffect(() => {
		if (loginError) {
			setErrorMessage(errorMessageLookUp[loginError.error])
		}
	}, [loginError])

	useEffect(() => {
		if (loggedIn && path) {
			navigate(path)
		} else if (loggedIn) {
			navigate('/')
		}
	}, [loggedIn])

	const handleSubmit = (event) => {
		validateFormFields()
		event.preventDefault()
		onLoginUser({ form: form })
	}

	const validateFormFields = () => {
		if (form.email === '') {
			setMissingEmailError(true)
		} else {
			setMissingEmailError(false)
		}
		if (form.password === '') {
			setMissingPasswordError(true)
		} else {
			setMissingPasswordError(false)
		}
	}

	const googleResponseSuccess = (res) => {
		setExistingDzUserLoading(true)
		setSubmitGoogleLogin(false)
		setSubmitGoogleRegister(false)
		setShowPhoneField(false)
		checkExistingUserGoogle(res).then(() => {
			setExistingDzUserLoading(false)
		})
	}

	const checkExistingUserGoogle = async (res) => {
		setGoogleResponse(res)
		const email = res.profileObj.email
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ email: email })
		if (userDoc) {
			if (userDoc.userId && userDoc.phone) {
				// If Existing DZ User, proceed with google login

				setSubmitGoogleLogin(true)
			} else {
				// If Client User, submit form, we have all the info we need
				setPhone(userDoc.phone)
				setSubmitGoogleRegister(true)
			}
		} else {
			// console.log('unregistered user google')
			// If Unregistered User, we need their first and last name before submitting
			setShowPhoneField(true)
		}
	}

	useEffect(() => {
		if (submitGoogleLogin) {
			onGoogleLogin(googleResponse)
		}
	}, [submitGoogleLogin])

	const checkExistingUserByPhone = async (form) => {
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ phone: phone })

		if (userDoc) {
			if (userDoc.userId && userDoc.phone) {
				// If Existing DZ User, send user email instructions to sign in with existing
				// email and show alert to give them option to replace account with new account
				const sendUserSignInInfoEmail =
					await realm.currentUser.functions.sendUserSignInInfoEmail({
						phone: phone,
					})
				setExistingDzUser(true)
			} else {
				// If Client User, submit form, we have all the info we need
				setSubmitGoogleRegister(true)
			}
		} else {
			// If Unregistered User, we need their first and last name before submitting
			setSubmitGoogleRegister(true)
		}
	}

	const handlePhoneSubmit = (e) => {
		if (isValidPhoneNumber(phone)) {
			setExistingDzUserLoading(true)
			setSubmitGoogleRegister(false)
			e.preventDefault()
			checkExistingUserByPhone().then(() => {
				setExistingDzUserLoading(false)
			})
		} else {
			setPhoneError(true)
			e.preventDefault()
		}
	}

	useEffect(() => {
		if (submitGoogleRegister) {
			onGoogleRegister({
				googleRes: googleResponse,
				phone: phone,
			})
		}
	}, [submitGoogleRegister])

	const googleResponseFailure = (res) => {
		// console.log(res)
	}

	return (
		<div className='w-11/12 md:w-form-widget h-full bg-white overflow-hidden shadow-light-grey rounded-xl max-w-lg m-auto'>
			<div className='text-xl md:px-4 py-5 sm:p-6 m-6 justify-center items-center'>
				<h1 className='font-vollkorn text-green-600 text-4xl mb-6 text-center'>
					Log in to your office dashboard
				</h1>
				{showPhoneField && (
					<form
						className='form-horizontal'
						onSubmit={handlePhoneSubmit}
					>
						{loginError && (
							<Alert
								type='warning'
								content={errorMessage}
								className='text-sm mb-10'
							></Alert>
						)}
						{phoneError && (
							<Alert
								type='warning'
								content='Please enter a valid US phone number'
								className='text-sm mb-10'
							></Alert>
						)}
						{existingDzUser && (
							<Alert
								type='warning'
								className='text-sm mb-10'
								content={
									<div>
										This phone number is already associated
										with an account. We've sent an email to
										the address on the account as a
										refresher. Follow those instructions to
										login.
										<a
											href='/login'
											className='font-weight-medium text-primary'
										>
											{' '}
											Log in to your existing account.
										</a>
									</div>
								}
							></Alert>
						)}
						<div className='text-center text-sm mt-6 mb-10 font-bold'>
							We need a bit more info to create your profile!
						</div>
						<div className={'pb-1'}>
							<label
								className={`text-sm text-gray-600 font-poppins absolute -translate-y-5`}
								htmlFor={'phone'}
							>
								PHONE
							</label>
						</div>
						<PhoneInput
							onChange={handlePhone}
							country='US'
							value={form.phone}
							id='phone'
							name='phone'
							label='Phone'
							className='form-control bg-white border-2 border-gray-300  w-full border border-2 border-gray-200 rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow '
							type='phone'
							required
						/>
						<div className='text-center text-xs mt-3 mb-6'>
							Psst! If you've ordered in DeliverZero boxes from a
							third-party, please use the phone number you used on
							those orders.
						</div>

						<div>
							{existingDzUserLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{loginLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{userLoading && (
								<div className='mt-3 text-center'>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</div>
							)}
							{!existingDzUserLoading &&
								!loginLoading &&
								!userLoading && (
									<Button
										className=''
										full
										submit={true}
										text='Continue with Google'
									></Button>
								)}
						</div>
					</form>
				)}

				{!showPhoneField && (
					<form
						className='form-horizontal'
						onSubmit={handleSubmit}
					>
						{loginError && (
							<Alert
								type='warning'
								content={errorMessage}
								className='text-sm mb-10'
							></Alert>
						)}
						<Input
							type='email'
							label='EMAIL'
							name='email'
							error={missingEmailError}
							className='bg-white border-2 border-gray-300 mb-6'
							onChange={handleChange}
						/>
						<Input
							type='password'
							label='PASSWORD'
							name='password'
							error={missingPasswordError}
							className='bg-white border-2 border-gray-300'
							onChange={handleChange}
						/>
						<div className='flex'>
							{/*<CheckboxGroup label='Remember me' name='terms' className='m-4'/>*/}
							<Link
								to='/ForgotPassword'
								className='text-sm my-5 text-green-600 font-poppins underline'
							>
								Forgot Password
							</Link>
						</div>
						{!loginLoading && !userLoading && (
							<div>
								<Button
									color='green'
									full
									text='Login'
									className='mb-3'
									submit={true}
									// onClick = {buttonSubmit}
								/>
								{/*<Button color='green-outlined' full text='Login with Google' icon=<FcGoogle/> iconPosition="left" />*/}
								{/*                      <GoogleLogin*/}
								{/*                          clientId={google.CLIENT_ID}*/}
								{/*                          render={renderProps => (*/}
								{/*                              <button onClick={renderProps.onClick} className="w-full text-base font-semibold py-3 px-8 items-center font-poppins tracking-wider cursor-pointer border border-2*/}
								{/*rounded-full whitespace-nowrap min-w-[100px] text-center justify-center*/}
								{/*focus:ring-2 focus:ring-offset-2 text-green-600 bg-transparent border-green-600 hover:bg-green-600 hover:border-green-600 hover:text-white focus:ring-green-600"*/}
								{/*                              >*/}
								{/*                                  <FcGoogle className="mr-2 inline-block"/>*/}
								{/*                                  Login with Google*/}
								{/*                              </button>*/}
								{/*                          )}*/}
								{/*                          // native_callback={nativeCallback}*/}
								{/*                          buttonText="Log in with Google"*/}
								{/*                          onSuccess={googleResponseSuccess}*/}
								{/*                          onFailure={googleResponseFailure}*/}
								{/*                      />*/}
							</div>
						)}
						{loginLoading && (
							<div className='mt-3 text-center'>
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							</div>
						)}

						{userLoading && (
							<div className='mt-3 text-center'>
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							</div>
						)}
						<div className='text-center text-sm mt-5'>
							New to DeliverZero?{' '}
							<Link
								to='/sign-up'
								className='text-green-600 underline font-semibold'
							>
								Create an account.
							</Link>
						</div>
					</form>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Login, Path }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	loginLoading: Login.loading,
	loginError: Login.error,
	loginAttemptStatus: Login.loginAttemptStatus,
	userLoading: User.loading,
	path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({
	onLoginSuccess: (user) => dispatch(loginUserSuccess(user)),
	onLoginUser: (credentials) => dispatch(loginUser(credentials)),
	onGoogleLogin: (res) => dispatch(socialLoginGoogle(res)),
	onGoogleRegister: (payload) => dispatch(socialRegisterGoogle(payload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OfficeLogin)
