// import PropTypes from "prop-types"
import React, { createRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
	getNodes,
	getNodeBySlug,
	updateSearchNodes,
	getAddressLocation,
	getGPSLocation,
	getUser,
} from '../../store/actions'
import {
	calculateDistanceBetweenCoords,
	googleMapsLibraries,
	isAddressInsideDeliveryPolygon,
} from '../../helpers/googleMapsAPI'
import { Header, Footer, NodeLocationsSearchInputs } from '../../components'
import {
	Alert,
	RadioGroup,
	Button,
	Badge,
	BounceLoaderCentered,
} from '../../components/Elements'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { NodeTypes } from '../../model/nodes'
import {
	FaCaretDown,
	FaCaretUp,
	FaRoute,
	FaMarker,
	FaMapMarker,
	FaMapMarkerAlt,
	FaMapMarked,
} from 'react-icons/fa'
import RedirectPopUp from '../../components/Marketplace/RedirectPopUp'
import {BiLinkExternal} from "react-icons/bi";

const MarketplaceLocations = (props) => {
	const {
		onGetNodes,
		onGetNodeBySlug,
		node,
		nodes,
		searchedAddressLatLng,
		user,
		onGetUser,
		finishedNodesPull,
		updateUserLoading,
		userUpdateCompleted,
		loadingNode,
	} = props
	const navigate = useNavigate()
	const [deliveryAddress, setDeliveryAddress] = useState('')
	const [deliveryLatLng, setDeliveryLatLng] = useState(null)
	const [loadingSearchNodes, setLoadingSearchNodes] = useState(true)
	const [mapBool, setMapBool] = useState(false)
	const [postSearchNodes, setPostSearchNodes] = useState([])
	const [restaurantInput, setRestaurantInput] = useState('')
	const [selectedMapNode, setSelectedMapNode] = useState({ name: '' })
	const [selectedNode, setSelectedNode] = useState()
	const [selectedZoom, setSelectedZoom] = useState(12)
	const [selectedCenterCoords, setSelectedCenterCoords] = useState({
		lat: 40.7128,
		lng: -74.006,
	})
	const [locationWarning, setLocationWarning] = useState(false)
	const [mapFilter, setMapFilter] = useState({})
	const [searchFilter, setSearchFilter] = useState({})
	const radiusAroundPoint = 25
	const [sortByType, setSortByType] = useState('distance')
	const [filter, setFilter] = useState({
		type: {
			$nin: [NodeTypes.bin],
		},
		enabled: {
			$nin: [false],
		},
		$or: [
			{ instoreOnly: true },
			{
				$and: [
					{ thirdPartyClient: { $exists: true } },
					{ thirdPartyClient: { $ne: {} } },
				],
			},
		],
	})
	const [sortedActiveNodeCuisines, setSortedActiveNodeCuisines] = useState([])
	const [alphaActiveNodeCuisines, setAlphaActiveNodeCuisines] = useState([])
	const [activeClients, setActiveClients] = useState([])
	const [clientFilter, setClientFilter] = useState({})
	const [selectedClient, setSelectedClient] = useState('none')
	const [courierFilter, setCourierFilter] = useState({})
	const [courierFilterChecked, setCourierFilterChecked] = useState(false)
	const [openNowFilter, setOpenNowFilter] = useState({})
	const [openNowFilterChecked, setOpenNowFilterChecked] = useState(false)
	const [deliveryOnlyFilterChecked, setDeliveryOnlyFilterChecked] =
		useState(false)
	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState()
	const [geoCodedLat, setGeoCodedLat] = useState()
	const [geoCodedLng, setGeoCodedLng] = useState()
	const [userLocation, setUserLocation] = useState()
	const [addressError, setAddressError] = useState()
	const [searchByGPS, setSearchByGPS] = useState(false)
	const [email, setEmail] = useState('')
	const [submittingEmail, setSubmittingEmail] = useState(false)
	const [emailSuccess, setEmailSuccess] = useState(false)
	const [emailError, setEmailError] = useState('')
	const [dayOfWeek, setDayOfWeek] = useState('')
	const [openHoursDropDown, setOpenHoursDropDown] = useState({})
	const [isHoliday, setIsHoliday] = useState(false)
	const [openPopUp, setOpenPopUp] = useState(false)
	const [popUpNode, setPopUpNode] = useState()
	const [distPopupVisible, setDistPopupVisible] = useState(false)
	const [distanceInfo, setDistanceInfo] = useState('')
	const [showGoogleMapsLink, setShowGoogleMapsLink] = useState("")
	const google = window.google
	const resultRef = createRef()
	const bounceLoaderColor = '#507f74'
	const { thirdPartyFilter,nodeSlug } = useParams()



	const usBankHolidays = [
		'Jan 1',
		'Jan 15',
		'Feb 19',
		'May 27',
		'Jun 19',
		'Jul 4',
		'Sep 2',
		'Oct 9',
		'Nov 11',
		'Nov 23',
		'Dec 25',
	] // oct 2023 - oct 2024 dates

	const daysOfWeekMap = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 } // map from Date().getDay() (0-6=Sun-Sat) to google's openHours (0-6=Mon-Sun)

	// LOAD GOOGLE MAPS API
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: googleMapsLibraries,
	})

	useEffect(() => {
		onGetUser()
	}, [userUpdateCompleted])

	useEffect(() => {
		if(nodeSlug){
			onGetNodeBySlug(nodeSlug)
		}
	}, [nodeSlug])

	useEffect(() => {
		if(node){
			setPopUpNode(node)
			setOpenPopUp(true)
		}
	}, [node])

	const geocodeAddress = async (searchedAddress) => {
		setAddressError(false)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: searchedAddress,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
					setSelectedCenterCoords({
						lat: geoCodedLat,
						lng: geoCodedLng,
					})
					setUserLocation({
						address: geoCodedAddress,
						lat: geoCodedLat,
						lng: geoCodedLng,
					})
					setSearchedAddress(geoCodedAddress)
					setDeliveryAddress(geoCodedAddress)
					setDeliveryLatLng({
						lat: geoCodedLat,
						lng: geoCodedLng,
					})
				} else {
					setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	const reverseGeocodeAddress = async (coords) => {
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{ location: coords },
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// set it to the correct, formatted address if it's valid
					setSearchedAddress(results[0].formatted_address)
				} else {
					setAddressError(true)
					// // show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	useEffect(() => {
		const lastSearchedAddress = JSON.parse(
			localStorage.getItem('lastSearchedAddress')
		)
		if (lastSearchedAddress && lastSearchedAddress.address) {
			setSearchedAddress(lastSearchedAddress.address)
			setSelectedCenterCoords({
				lat: lastSearchedAddress.lat,
				lng: lastSearchedAddress.lng,
			})
			setDeliveryAddress(lastSearchedAddress.address)
			setDeliveryLatLng({
				lat: lastSearchedAddress.lat,
				lng: lastSearchedAddress.lng,
			})
			setLocationWarning(false)
			setSelectedZoom(13)
			setUserLocation(lastSearchedAddress)
		} else if (user && user.lastSearchedAddress) {
			setSearchedAddress(user.lastSearchedAddress)
			geocodeAddress(user.lastSearchedAddress)
			setLocationWarning(false)
			setSelectedZoom(13)
		} else {
			// return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const crd = position.coords
					setSelectedCenterCoords({
						lat: crd.latitude,
						lng: crd.longitude,
					})
					reverseGeocodeAddress({
						lat: parseFloat(crd.latitude),
						lng: parseFloat(crd.longitude),
					})
					// return resolve({ lat: crd.latitude, lng: crd.longitude })
				},
				(e) => {
					// reject(e.message)
					if (user && user.address) {
						setSearchedAddress(user.address)
						geocodeAddress(user.address)
						setLocationWarning(false)
						setSelectedZoom(13)
					} else {
						setSelectedCenterCoords({
							lat: 34.70653852456014,
							lng: -90.131785,
						})
						setSelectedZoom(4)
						setLocationWarning(true)
					}
				},
				{ enableHighAccuracy: true, timeout: 3000 }
			)
			createFilter()
			getNearestNodes()
		}
	}, [
		user,
		searchedAddress,
		userUpdateCompleted,
		user?.lastSearchedAddress,
		navigator,
		JSON.stringify(navigator),
	])

	useEffect(() => {
		if (geoCodedAddress) {
			if (thirdPartyFilter) {
				navigate(`/marketplace/${thirdPartyFilter}/locations`)
			} else {
				navigate('/marketplace/locations')
			}
		}
	}, [geoCodedAddress, user])

	useEffect(() => {
		if (thirdPartyFilter) {
			const cFilt = {}
			setSelectedClient(thirdPartyFilter)
			if (thirdPartyFilter !== 'deliverzero') {
				cFilt[`thirdPartyClient.${thirdPartyFilter}`] = {
					$exists: true,
				}
			} else if (thirdPartyFilter === 'deliverzero') {
				cFilt['directOrdering'] = { $eq: true }
			}
			setClientFilter(cFilt)
		}
	}, [thirdPartyFilter])

	// CENTER MAP ON USER LOCATION, IF NO USER LOCATION, CENTER ON DEFAULT LOCATION & ZOOM, SHOW ALERT
	// useEffect(() => {
	//   if (userLocation) {
	//     setSelectedCenterCoords({
	//       lat: userLocation.lat,
	//       lng: userLocation.lng
	//     })

	//   } else {
	//     setSelectedCenterCoords({
	//       lat: 34.70653852456014,
	//       lng: -90.131785
	//     })
	//     setSelectedZoom(4)
	//     setLocationWarning(true)
	//   }
	//   createFilter()
	// }, [userLocation])

	// // GET NODES NEAR USER
	useEffect(() => {
		if (userLocation) {
			createFilter()
		} else if (deliveryAddress) {
			createFilter()
		}
	}, [
		filter,
		mapFilter,
		searchFilter,
		userLocation,
		sortByType,
		clientFilter,
		courierFilter,
		openNowFilter,
		deliveryOnlyFilterChecked,
	])

	// COMBINE FILTER ARRAY AND GET NODES
	const createFilter = async () => {
		setLoadingSearchNodes(true)
		const combinedFilter = [filter]
		const geoFilter = getNearestNodes()
		if (mapBool) {
			combinedFilter.push(
				searchFilter,
				mapFilter,
				clientFilter,
				courierFilter,
				openNowFilter
			)
		} else {
			if (geoFilter) {
				combinedFilter.push(
					geoFilter,
					searchFilter,
					clientFilter,
					courierFilter,
					openNowFilter
				)
			} else {
				combinedFilter.push(
					searchFilter,
					clientFilter,
					courierFilter,
					openNowFilter
				)
			}
		}
		onGetNodes(combinedFilter)
	}

	// MAP FILTER ON SELECT POINT
	const onMapSelect = (node) => {
		// setDeliveryAddress('')
		setSelectedMapNode(node)
		setMapBool(true)
		setMapFilter({
			coordinates: {
				$near: {
					$geometry: {
						type: 'Point',
						coordinates: [node.coordinates[0], node.coordinates[1]],
					},
				},
			},
		})
		document.getElementById('results-scroller').scroll(0, 0)
	}

	const getNearestNodes = () => {
		const geoFilter = {
			coordinates: {
				$near: {
					$geometry: {
						type: 'Point',
						coordinates: [
							selectedCenterCoords.lng,
							selectedCenterCoords.lat,
						],
					},
					// $minDistance: 3000000,
					$maxDistance: radiusAroundPoint * 1609.34,
				},
			},
		}
		if (userLocation) {
			geoFilter.coordinates.$near.$geometry.coordinates = [
				userLocation.lng,
				userLocation.lat,
			]
			setDeliveryLatLng({ lat: userLocation.lat, lng: userLocation.lng })
		} else {
			geoFilter.coordinates.$near.$geometry.coordinates = [
				selectedCenterCoords.lng,
				selectedCenterCoords.lat,
			]
			setDeliveryLatLng({
				lat: selectedCenterCoords.lat,
				lng: selectedCenterCoords.lng,
			})
		}
		return geoFilter
	}

	const addressCheck = (address, latLng) => {
		setLoadingSearchNodes(true)
		setDeliveryAddress(address)
		setDeliveryLatLng(latLng)
		const tempArray = []
		// if(mapBool){
		//   setPostSearchNodes(nodes)
		// } else {
		if (latLng) {
			nodes.map((node) => {
				// For the returns map page
				const distanceToCoordMiles =
					calculateDistanceBetweenCoords(
						[latLng.lat, latLng.lng],
						node.coordinates
					) / 1609.34
				node.distanceToCoord = distanceToCoordMiles
				// Filter by distance again in case they've clicked by
				if (distanceToCoordMiles <= radiusAroundPoint) {
					tempArray.push(node)
				}
				// For the get food map page
				// console.log(node)
				if (
					isAddressInsideDeliveryPolygon(
						[latLng.lat, latLng.lng],
						node.deliveryPolygon
					)
				) {
				} else {
					node.outsideDeliveryArea = true
					if (deliveryOnlyFilterChecked) {
						const index = tempArray.indexOf(node)
						if (index > -1) {
							// only splice array when item is found
							tempArray.splice(index, 1) // 2nd parameter means remove one item only
						}
					}
				}
			})

			let sortedNodes = tempArray
			if (!mapBool) {
				if (sortByType == 'name') {
					sortedNodes = sortedNodes.sort((a, b) =>
						a.name > b.name ? 1 : b.name > a.name ? -1 : 0
					)
				} else if (sortByType == 'distance') {
					sortedNodes = sortedNodes.sort(
						(a, b) => a.distanceToCoord - b.distanceToCoord
					)
				}
			}

			setPostSearchNodes(sortedNodes)
			const initialOpenHoursDropDowns = {}
			sortedNodes.forEach((node) => {
				initialOpenHoursDropDowns[node.name] = false
			})
			setOpenHoursDropDown(initialOpenHoursDropDowns)
			if (mapBool == false) {
				setSelectedCenterCoords({
					lat: latLng.lat,
					lng: latLng.lng,
				})
				setSelectedZoom(12)
			}
		} else {
			let sortedNodes = nodes
			if (!mapBool) {
				if (sortByType == 'name') {
					sortedNodes = sortedNodes.sort((a, b) =>
						a.name > b.name ? 1 : b.name > a.name ? -1 : 0
					)
				} else if (sortByType == 'distance') {
					sortedNodes = sortedNodes.sort(
						(a, b) => a.distanceToCoord - b.distanceToCoord
					)
				}
			}
			setPostSearchNodes(sortedNodes)
			const initialOpenHoursDropDowns = {}
			sortedNodes.forEach((node) => {
				initialOpenHoursDropDowns[node.name] = false
			})
			setOpenHoursDropDown(initialOpenHoursDropDowns)
		}
		// }
	}

	const addressHandler = () => {
		if (userLocation) {
			setDeliveryLatLng({
				lat: userLocation.lat,
				lng: userLocation.lng,
			})
			setSelectedCenterCoords({
				lat: userLocation.lat,
				lng: userLocation.lng,
			})
			setSelectedZoom(12)
		} else {
			setDeliveryAddress('')
			setDeliveryLatLng(null)
		}
	}

	useEffect(() => {
		addressCheck(deliveryAddress, deliveryLatLng)
	}, [nodes])

	useEffect(() => {
		if (searchedAddress && searchedAddress !== '') {
			addressCheck(searchedAddress, searchedAddressLatLng)
		}
	}, [searchedAddress])

	useEffect(() => {
		const currentDate = new Date()
		const dateOptions = { month: 'short', day: 'numeric' }
		const formattedDate = currentDate.toLocaleDateString(
			'en-US',
			dateOptions
		) // in format "Jan 1"
		setDayOfWeek(currentDate.getDay())
		if (usBankHolidays.includes(formattedDate)) {
			setIsHoliday(true)
		}
		if (postSearchNodes.length >= 0) {
			setLoadingSearchNodes(false)
		} else {
			if (thirdPartyFilter && finishedNodesPull) {
				setClientFilter({})
			}
			if (openNowFilterChecked) {
				onOpenNowFilter(true)
			} else {
				setOpenNowFilter({})
				setOpenNowFilterChecked(false)
			}
		}
	}, [postSearchNodes])

	const onSearchChange = (query) => {
		// console.log(query.target.value)
		setMapBool(false)
		if (selectedZoom > 12) {
			setSelectedZoom(12)
		}
		setSelectedMapNode({ name: '' })
		// setSelectedCenterCoords({
		//     lat: 40.7128,
		//     lng: -74.006,
		// })

		setRestaurantInput(query.target.value)
		if (query.target.value !== '') {
			setSearchFilter({
				$or: [
					{
						name: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						slug: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						city: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						address: {
							$regex: query.target.value,
							$options: 'i',
						},
					},
					{
						cuisine: { $in: [new RegExp(query.target.value, 'i')] },
					},
				],
			})
		} else {
			setSearchFilter({})
		}
	}

	const onClientFilter = (clientQuery) => {
		const cFilt = {}
		//	console.log(clientQuery)
		setSelectedClient(clientQuery)
		if (clientQuery !== 'none' && clientQuery !== 'DeliverZero') {
			cFilt[`thirdPartyClient.${clientQuery}`] = { $exists: true }
			cFilt[`$or`] = [
				{
					[`enabledClients.${clientQuery}`]: true,
				},
				{
					enabledClients: { $exists: false },
				},
			]
		} else if (clientQuery === 'DeliverZero') {
			cFilt['directOrdering'] = { $eq: true }
		}

		//	console.log(cFilt)
		setClientFilter(cFilt)
	}

	const onCourierFilter = (courierQuery) => {
		const cFilt = {}
		setCourierFilterChecked(courierQuery)
		if (courierQuery) {
			cFilt['courierAcceptsReturns'] = { $eq: true }
		}
		setCourierFilter(cFilt)
	}

	const getCurrentTimeAndDay = () => {
		const now = new Date()
		const daysOfWeek = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday',
		]
		const dayOfWeekIndex = now.getDay()
		const dayOfWeek = daysOfWeek[dayOfWeekIndex]
		const currentTime = now.getHours() * 60 + now.getMinutes()
		return { dayOfWeekIndex, dayOfWeek, currentTime }
	}

	const isNodeOpenNow = (node, dayOfWeekIndex, dayOfWeek, currentTime) => {
		if (!node.openHoursGoogle) {
			return true // show if we don't have google hours
		}

		const hoursMap = {}
		const hoursString = node.openHoursGoogle[dayOfWeekIndex - 1]
		if (hoursString) {
			if (hoursString.includes('24')) {
				// if open 24 hours
				return true
			} else if (hoursString.toLowerCase().includes('closed')) {
				// if closed
				return false
			} else {
				const [day, hours] = hoursString.split(': ')
				if (hours.includes(',')) {
					const [firstTimeChunk, secondTimeChunk] = hours.split(', ')
					const [firstOpening, firstClosing] =
						firstTimeChunk.split('–')
					const [secondOpening, secondClosing] =
						secondTimeChunk.split('–')
					if (
						parseTimeToMinutes(secondClosing) <
						parseTimeToMinutes(secondOpening)
					) {
						return true // cases where node closes at 1 AM next day
					} else {
						hoursMap[day] = {
							opening: parseTimeToMinutes(firstOpening),
							closing: parseTimeToMinutes(firstClosing),
							secondOpening: parseTimeToMinutes(secondOpening),
							secondClosing: parseTimeToMinutes(secondClosing),
						}
					}
				} else {
					const [opening, closing] = hours.split('–')
					if (
						parseTimeToMinutes(closing) <
						parseTimeToMinutes(opening)
					) {
						return true // cases where node closes at 1 AM next day
					}
					hoursMap[day] = {
						opening: parseTimeToMinutes(opening),
						closing: parseTimeToMinutes(closing),
					}
				}
			}
		} else {
			return false
		}

		const todayHours = hoursMap[dayOfWeek]
		if (!todayHours) {
			return false
		} else {
			const isOpen =
				currentTime >= todayHours.opening &&
				currentTime <= todayHours.closing
			if (isOpen) {
				return true
			} else if (todayHours.secondOpening && todayHours.secondClosing) {
				return (
					currentTime >= todayHours.secondOpening &&
					currentTime <= todayHours.secondClosing
				)
			} else {
				// console.log("not open", node.name, "currentTime", currentTime, "todayHours.opening", todayHours.opening, "todayHours.closing", todayHours.closing)
			}
		}
	}

	const parseTimeToMinutes = (timeString) => {
		const [hours, minutes] = timeString
			.split(':')
			.map((part) => parseInt(part, 10))
		let totalMinutes = hours * 60
		if (!timeString.includes('AM') && !timeString.includes('PM')) {
			if (hours === 12 || hours < 8) {
				// console.log("PM", timeString);
				totalMinutes += 12 * 60 // assume PM
			} else {
				// console.log("AM", timeString);
			}
		} else if (timeString.includes('AM') && hours === 12) {
			totalMinutes = 0 // if midnight the next day
		} else if (timeString.includes('PM') && hours !== 12) {
			totalMinutes += 12 * 60 // add 12 hours for PM time (except for 12:00 PM)
		}
		totalMinutes += minutes
		return totalMinutes
	}

	const onOpenNowFilter = (openNowQuery) => {
		const cFilt = {}
		setOpenNowFilterChecked(openNowQuery)
		const openArray = []

		if (openNowQuery) {
			const { dayOfWeekIndex, dayOfWeek, currentTime } =
				getCurrentTimeAndDay()
			// console.log("currentTime", currentTime)

			nodes.forEach((node) => {
				if (
					isNodeOpenNow(node, dayOfWeekIndex, dayOfWeek, currentTime)
				) {
					openArray.push(node.slug)
				}
			})
			cFilt['slug'] = { $in: openArray }
		}

		setOpenNowFilter(cFilt)
	}

	const changeMapBoolToFalse = () => {
		setMapBool(false)
	}

	const handleSortBy = (sortBy) => {
		setMapBool(false)
		setSortByType(sortBy)
	}

	const compare = (a, b) => {
		if (a[0] < b[0]) {
			return 1
		} else if (a[0] > b[0]) {
			return -1
		} else if (a[0] === b[0]) {
			if (a[1] < b[1]) {
				return -1
			} else {
				return 1
			}
		}
	}

	const sortArrFreq = (arr, length) => {
		let indexCount = {}
		for (let i = 0; i < length; i++) {
			indexCount[arr[i]] = 0
		}

		for (let i = 0; i < length; i++) {
			indexCount[arr[i]]++
		}

		const vec = new Array(length)
		for (let i = 0; i < length; i++) {
			vec[i] = []
		}

		let k = 0
		for (var it in indexCount) {
			vec[k] = [indexCount[it], it]
			k += 1
		}

		vec.sort(compare)
		let sortedArr = []
		for (let i = 0; i < k; i++) {
			sortedArr.push(vec[i][1])
		}
		return sortedArr
	}

	const alphabetize = (arr) => {
		const alphaArr = [...arr].sort((a, b) => a.localeCompare(b))
		return alphaArr
	}

	const reorderNodes = (nodes, selectedNode) => {
		const selectedNodeIndex = nodes.findIndex(
			(node) => node === selectedNode
		)
		if (selectedNodeIndex !== -1) {
			const selectedNode = nodes[selectedNodeIndex]
			const restOfTheNodes = [
				...nodes.slice(0, selectedNodeIndex),
				...nodes.slice(selectedNodeIndex + 1),
			]
			return [selectedNode, ...restOfTheNodes]
		}
		return nodes
	}

	useEffect(() => {
		if (postSearchNodes) {
			let cuisinesArr = []
			postSearchNodes.map((node) => {
				if (typeof node.cuisine === 'string') {
					let splitArray = node.cuisine.split('/')
					splitArray.forEach((arr) => cuisinesArr.push(arr))
				} else {
					return
				}
			})
			cuisinesArr = sortArrFreq(cuisinesArr, cuisinesArr.length)
			setSortedActiveNodeCuisines(
				cuisinesArr.length < 5
					? cuisinesArr
					: [
							cuisinesArr[0],
							cuisinesArr[1],
							cuisinesArr[2],
							cuisinesArr[3],
							cuisinesArr[4],
							cuisinesArr[5],
							cuisinesArr[6],
					  ]
			)
			setAlphaActiveNodeCuisines(alphabetize(cuisinesArr))
			if (activeClients.length <= 2) {
				let clientsArr = ['DeliverZero']
				postSearchNodes.map((node) => {
					if (node.thirdPartyClient) {
						Object.keys(node.thirdPartyClient).forEach((client) =>
							clientsArr.push(client)
						)
					}
				})

				clientsArr = sortArrFreq(clientsArr, clientsArr.length)
				setActiveClients(clientsArr)
			}
			const reorderedNodes = reorderNodes(postSearchNodes, selectedNode)
			setPostSearchNodes(reorderedNodes)
		}
	}, [postSearchNodes, selectedNode])

	const handleEmailChange = (emailChange) => {
		setEmail(emailChange.target.value)
	}

	const handleSubscribe = async () => {
		setEmailError('')
		setEmailSuccess(false)
		setSubmittingEmail(true)
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (re.test(email)) {
			const subscriberStatus = await realm.currentUser.callFunction(
				'mailChimp/AddUserToEmailList',
				{
					email: email,
					phoneNumber: user ? user.phone : '',
					firstName: user ? user.firstName : '',
					lastName: user ? user.lastName : '',
				}
			)
			if (subscriberStatus == 'error') {
				setSubmittingEmail(false)
				setEmailError('An error occured. Try again.')
			} else {
				setEmailSuccess(true)
				setSubmittingEmail(false)
			}
		} else {
			// invalid email, maybe show an error to the user.
			setSubmittingEmail(false)
			setEmailError('Invalid email address. Try again.')
		}
	}

	const formatTodayHours = (input) => {
		if (typeof input === 'string') {
			input = input.replace(/.*day/, "Today's Hours")
			return input
		}
		return toString(input)
	}

	const shortenDay = (day) => {
		if (typeof day === 'string') {
			const shortform = day.slice(0, 3)
			day = day.replace(/.*day/, shortform)
		}
		return day
	}

	const handleOpenPopUp = (node) => {
		setOpenPopUp(!openPopUp)
		setPopUpNode(node)
	}

	const handleMarkerClick = async (node) => {
		const originLat = geoCodedLat || userLocation.lat
		const originLng = geoCodedLng || userLocation.lng

		if (!originLat || !originLng) {
			console.error('Origin location is not available')
			return
		}

		onMapSelect(node)
		setSelectedNode(node)
		setSelectedZoom(15)
		setSelectedCenterCoords({
			lat: node.coordinates[1],
			lng: node.coordinates[0],
		})
		setDistanceInfo({
			originLat: originLat,
			originLng: originLng,
			destLat: node.coordinates[1],
			destLng: node.coordinates[0],
			distance: node.distanceToCoord
				.toFixed(2)
				.toString()
				.substring(0, 4),
		})
		setDistPopupVisible(false)
	}

	if ((!isLoaded && !userLocation) || updateUserLoading)
		return (
			<div className='flex justify-center items-center my-6 mx-auto'>
				<BounceLoaderCentered />
			</div>
		)
	return (
		<div className=' h-full w-full flex flex-col justify-between '>
			<Header user={user} />
			{locationWarning && (
				<Alert
					// content='You have location services turned off, you may continue by searching an address, but you will be asked to verify your location by another method on the next page.'
					content='You have location services turned off. You may continue by searching an address, but certain map features may behave differently.'
					type='warning'
				/>
			)}
			<div className='h-[150vh] sm:h-[120vh] md:h-screen-marketplace min-h-[50em] overflow-hidden pt-2 md:pt-6'>
				<div className='pb-2'>
					<div className='flex flex-col items-center justify-center'>
						{isLoaded && (
							<NodeLocationsSearchInputs
								activeNodeCuisines={sortedActiveNodeCuisines}
								onNameQuery={onSearchChange}
								onSelectQuery={onSearchChange}
								handleSortBy={handleSortBy}
								alphaCuisines={alphaActiveNodeCuisines}
								nameValue={restaurantInput}
								locationEnabled={userLocation}
								activeClients={activeClients}
								onClientFilter={onClientFilter}
								onCourierFilter={onCourierFilter}
								courierFilterChecked={courierFilterChecked}
								onOpenNowFilter={onOpenNowFilter}
								openNowFilterChecked={openNowFilterChecked}
								selectedClient={selectedClient}
								deliveryOnlyFilterChecked={
									deliveryOnlyFilterChecked
								}
								setDeliveryOnlyFilterChecked={
									setDeliveryOnlyFilterChecked
								}
							/>
						)}
					</div>
				</div>
				<div className='w-full md:min-h-[32em] md:h-screen-no-header-mobile sm:h-screen-no-header flex flex-col-reverse md:flex-row sm:justify-between border-t border'>
					<div className=' w-full md:w-1/2 md:h-full h-3/4 pt-2 p-4 md:pt-4 md:overflow-hidden '>
						<div className='flex justify-between items-center pb-2 border-b mr-4 '>
							<div className='min-w-[100px] '>
								{postSearchNodes.length} Locations
							</div>
							<div className='flex items-center'>
								<div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
									Sort By:
								</div>
								<RadioGroup
									className='mt-1 sm:mt-0'
									name='sortBy'
									horizontal
									radios={[
										{
											value: 'distance',
											label: 'Distance',
											checked: true,
										},
										{ value: 'name', label: 'Name' },
									]}
									onChange={(sortBy) => {
										// console.log(sortBy)
										handleSortBy(sortBy)
									}}
								/>
							</div>
						</div>
						{/* {loadingSearchNodes && (
							<div className='mt-3 text-center'>
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							</div>
						)} */}
						{postSearchNodes.length == 0 && (
							<div
								className='h-[650px] md:h-full overflow-scroll scrollbar '
								id='results-scroller'
								ref={resultRef}
							>
								<div className='mt-6 text-center'>
									<span className='font-bold mb-6'>
										No restaurants fit your search!
									</span>{' '}
									Try changing your search, choosing a
									different address, or adjusting the distance
									bar.
								</div>
								<div className='mt-6 text-center'>
									<span className='font-bold'>
										Sign up for our newsletter
									</span>{' '}
									to be informed when we are available in your
									area!
								</div>
								<div className='w-full flex flex-col justify-center items-center p-8'>
									{submittingEmail ? (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									) : (
										<>
											<input
												onChange={handleEmailChange}
												type='email'
												name='email'
												id='email'
												className='rounded-full w-full mini:w-3/4 lg:w-1/2 bg-[#E9EFFD] py-3 pl-4 mb-2'
												placeholder='Enter your email'
											/>
											<Button
												onClick={handleSubscribe}
												color='red'
												size='sm'
												className='mb-4 w-full mini:w-3/4 lg:w-1/2'
												text='Sign Up'
											/>
										</>
									)}
									{emailError !== '' && (
										<div className='text-red-400 my-2 font-semibold text-sm'>
											{emailError}
										</div>
									)}
									{emailSuccess && (
										<div className='text-green-400 my-2 font-semibold text-sm'>
											You've successfully subscribed!
										</div>
									)}
								</div>
								{/*<div className='mt-6'>*/}
								{/*	Looking for a particular restaurant that*/}
								{/*	isn't on our list? Let them and us know that*/}
								{/*	they should use our reusables.{' '}*/}
								{/*</div>*/}
								{/*<div className='w-full text-center font-semibold text-green-600 underline decoration-2 underline-offset-4 decoration-yellow-400'>*/}
								{/*	<br />{' '}*/}
								{/*	<Link to='/restaurantpartners'>*/}
								{/*		Send them here!*/}
								{/*	</Link>*/}
								{/*</div>*/}
							</div>
						)}
						{postSearchNodes.length > 0 && (
							<div
								className='h-[600px] md:h-full overflow-scroll scrollbar '
								id='results-scroller'
								ref={resultRef}
							>
								{postSearchNodes.map((node, index) => {
									// const [preferredThirdPartyClient,setPreferredThirdPartyClient]=useState('')
									const getPreferredClient = () => {
										const preferredClients = [
											'toast',
											'ubereats',
											'caviar',
											'doordash',
											'chownow',
											'whole_foods',
										]
										//Get preferred client by comparing to array return first thing found
										let foundClient = ''
										if (node.thirdPartyClient) {
											if (
												Object.keys(
													node.thirdPartyClient
												).length > 1
											) {
												foundClient = 'multipleClients'
												// console.log(node.name)
											} else if (
												Object.keys(
													node.thirdPartyClient
												).length === 1
											) {
												foundClient = Object.keys(
													node.thirdPartyClient
												)[0]
											}
										}

										//lookup client info from dict
										return foundClient
									}

									const preferredThirdPartyClient =
										getPreferredClient(node)
									// console.log(preferredThirdPartyClient)
									return (
										<div
											key={index}
											className='w-full overflow-hidden'
										>
											<div
												className='flex h-45px w-full-b items-center p-2 border-b mr-4 tracking-wider cursor-pointer relative '
												key={index}
											>
												<div
													className='text-center w-24 shrink-0'
													onClick={() => {
														handleMarkerClick(node)
													}}
												>
													<img
														className='h-18 w-18 md:h-24 md:w-24 shrink-0 border rounded-lg'
														src={node.thumbnail}
														alt='restaurant-thumbnail'
													/>
													{node.distanceToCoord ? (
														<div className='text-sm text-gray-400'>
															{node.distanceToCoord
																.toFixed(2)
																.toString()
																.substring(
																	0,
																	4
																)}{' '}
															miles
														</div>
													) : (
														<div className='text-sm text-gray-400'>
															0 miles
														</div>
													)}
												</div>
												<div className='pl-4 w-full flex flex-col '>
													<div className='w-full flex'>
														<div
															className='w-full'
															onClick={() => {
																handleMarkerClick(
																	node
																)
															}}
														>
															<div className='flex flex-col sm:flex-row justify-between w-full'>
																<div className='font-medium mb-1 w-full'>
																	{node.name}
																</div>

																{node.courierAcceptsReturns &&
																	(!node.thirdPartyClient ||
																		(node.thirdPartyClient &&
																			Object.keys(
																				node.thirdPartyClient
																			)
																				.length ===
																				0)) && (
																		<div className='whitespace-nowrap w-full mb-2'>
																			<Badge
																				text='Courier Accepts Returns'
																				color='orange-primary'
																				className='cursor-pointer '
																			/>
																		</div>
																	)}
															</div>
															{node.instoreOnly && (
																<div className='text-xs text-red-400 mb-1 w-full'>
																	Available in
																	store only
																</div>
															)}
															<div className='mb-2 text-xs text-gray-400 whitespace-nowrap'>
																{node.cuisine}
															</div>
															<div className='hidden lg:block'>
																<div className='text-xs text-gray-400 flex flex-row items-center my-auto mb-1'>
																	<div>
																		<FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
																	</div>
																	<div className='ml-1 text-xs text-gray-400 flex flex-col whitespace-nowrap'>
																		<div className={`z-100  ${showGoogleMapsLink===node.slug ? "" : "cursor-pointer underline"}`} onClick={()=>{setShowGoogleMapsLink(node.slug)}} >
																			{
																				node.address
																			}
																			,{' '}
																			{
																				node.city
																			}
																			,{' '}
																			{
																				node.state
																			}{' '}
																		</div>
																	</div>

																</div>
																{showGoogleMapsLink===node.slug && (
																	<div
																		className='text-xs text-gray-400 flex flex-row items-center my-auto mb-1'>
																		<div>
																			<BiLinkExternal
																				className='text-green-400 text-[14px] text-center mr-1'/>
																		</div>
																		<a
																			href={`https://www.google.com/maps/search/?api=1&query=${node.name.replaceAll(" ","+")}+${node.address}+${node.city}+${node.state}`}
																			// href={`https://www.google.com/maps/search/?api=1&query=${node.address}+${node.city}+${node.state}`}
																			target='_blank'
																			rel='noopener noreferrer'
																			className='text-green-400 font-bold underline'
																		>
																			Open in Google Maps
																		</a>
																	</div>
																)}
															</div>
															<div className='lg:hidden'>
																{node.address &&
																node.address
																	.length >
																15 ? (
																	<div
																		className='text-xs text-gray-400 flex flex-row items-center my-auto mb-1'>
																		<div>
																			<FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
																		</div>
																		<div className={`z-100 ml-1 text-xs flex flex-col ${showGoogleMapsLink===node.slug ? "" : "cursor-pointer underline"}`} onClick={()=>{setShowGoogleMapsLink(node.slug)}} >

																			<div className='whitespace-nowrap'>
																				{
																					node.address
																				}
																			</div>
																			<div className='whitespace-nowrap'>
																				{
																					node.city
																				}

																				,{' '}
																				{
																					node.state
																				}
																			</div>
																		</div>
																	</div>
																) : (
																	<div className='text-xs text-gray-400 flex flex-row items-center my-auto mb-1'>
																		<div>
																			<FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
																		</div>
																		<div className={`z-100 ml-1 text-xs whitespace-nowrap ${showGoogleMapsLink===node.slug ? "" : "cursor-pointer underline"}`} onClick={()=>{setShowGoogleMapsLink(node.slug)}} >

																			<div className=''>
																				{
																					node.address
																				}

																				,{' '}
																				{
																					node.city
																				}

																				,{' '}
																				{
																					node.state
																				}{' '}
																			</div>
																		</div>
																	</div>
																)}
																{showGoogleMapsLink===node.slug && (
																	<div
																		className='text-xs text-gray-400 flex flex-row items-center my-auto mb-1'>
																		<div>
																			<BiLinkExternal
																				className='text-green-400 text-[14px] text-center mr-1'/>
																		</div>
																		<a
																			href={`https://www.google.com/maps/search/?api=1&query=${node.name.replaceAll(" ","+")}+${node.address}+${node.city}+${node.state}`}
																			// href={`https://www.google.com/maps/search/?api=1&query=${node.address}+${node.city}+${node.state}`}
																			target='_blank'
																			rel='noopener noreferrer'
																			className='text-green-400 font-bold underline'
																		>
																			Open in Google Maps
																		</a>
																	</div>
																)}
															</div>

															{isHoliday && (
																<div className='text-red-300 text-xs mt-2'>
																	Hours may
																	differ on
																	holidays.
																</div>
															)}
															{node.openHoursGoogle &&
															Object.keys(
																node.openHoursGoogle
															).length > 0 ? (
																<div
																	onClick={(
																		e
																	) => {
																		e.stopPropagation()
																		const updatedOpenHoursDropDowns =
																			{
																				...openHoursDropDown,
																			}
																		updatedOpenHoursDropDowns[
																			node.name
																		] =
																			!updatedOpenHoursDropDowns[
																				node
																					.name
																			]
																		setOpenHoursDropDown(
																			updatedOpenHoursDropDowns
																		)
																	}}
																>
																	<div className='flex flex-row items-center text-xs w-[250px] text-gray-400'>
																		<div>
																			{node.openHoursGoogle
																				? formatTodayHours(
																						node
																							.openHoursGoogle[
																							daysOfWeekMap[
																								dayOfWeek
																							]
																						]
																				  )
																				: node.openHoursDescription}
																		</div>
																		{node.openHoursGoogle &&
																			!openHoursDropDown[
																				node
																					.name
																			] && (
																				<FaCaretDown className='text-green-400 text-[22px] text-center' />
																			)}
																		{node.openHoursGoogle &&
																			openHoursDropDown[
																				node
																					.name
																			] && (
																				<FaCaretUp className='text-green-400 text-[22px] text-center' />
																			)}
																	</div>
																	{node.openHoursGoogle &&
																		openHoursDropDown[
																			node
																				.name
																		] &&
																		node.openHoursGoogle.map(
																			(
																				day
																			) => {
																				return (
																					<div
																						className='mb-2 text-sm text-green-400 ml-2'
																						key={
																							day
																						}
																					>
																						{shortenDay(
																							day
																						)}
																					</div>
																				)
																			}
																		)}
																</div>
															) : (
																<div className='text-xs text-gray-400 mb-3 '>
																	{
																		node.openHoursDescription
																	}
																</div>
															)}
														</div>
														{/* <div
                                                            className='w-1/3'
                                                            onClick={() => {
                                                                onMapSelect(node)
                                                                setSelectedZoom(15)
                                                                setSelectedCenterCoords({
                                                                    lat: node.coordinates[1],
                                                                    lng: node.coordinates[0],
                                                                })
                                                            }}
                                                        ></div> */}
													</div>
													{/*{node.outsideDeliveryArea && (*/}
													{/*	<div>*/}
													{/*		<div className='text-green-400 text-xs'>*/}
													{/*			Address may be outside of*/}
													{/*			delivery area.*/}
													{/*		</div>*/}
													{/*		<div className='text-green-400 text-xs mb-4'>*/}
													{/*			Pickup still*/}
													{/*			available*/}
													{/*		</div>*/}
													{/*	</div>*/}
													{/*)}*/}
													<div className='flex w-full'>
														<div className='flex flex-col w-full xl:w-[215px] mb-2'>
															{(preferredThirdPartyClient !==
																'' ||
																node.instoreOnly) && (
																<div>
																	{node.type ===
																	'whole-foods' ? (
																		<Button
																			size='xs'
																			color='whole-foods'
																			className='mt-2 text-sm px-4'
																			text='Visit Whole Foods'
																			href={`https://www.deliverzero.com/wholefoods`}
																		/>
																	) : (
																		<Button
																			size='xs'
																			className='mt-2 text-sm px-4 !font-medium  sm:font-bold w-min'
																			text={`Order Now`}
																			onClick={() =>
																				handleOpenPopUp(
																					node
																				)
																			}
																		/>
																		// <Button
																		// 	size='xs'
																		// 	className='mt-2 text-sm px-4 !font-medium  sm:font-bold'
																		// 	// color={
																		// 	// preferredThirdPartyClient ===
																		// 	// 'multipleClients'
																		// 	// 	? `green-light`
																		// 	// 	: preferredThirdPartyClient
																		// 	// }
																		// 	text={
																		// 		`Order Now`
																		// 		// preferredThirdPartyClient ===
																		// 		// 'multipleClients'
																		// 		// 	? `Order Now`
																		// 		// 	: `Order with ${thirdPartyDict[preferredThirdPartyClient].name}`
																		// 	}
																		// 	link={`/redirect/${node.slug}`}
																		// />
																	)}
																</div>
															)}

															{preferredThirdPartyClient ===
																'' &&
																node.directOrdering && (
																	<Button
																		size='xs'
																		className='mt-2 text-sm px-4 !font-medium  sm:font-bold w-min'
																		text='Order Now'
																		link={`/marketplace/${node.slug}`}
																	/>
																)}
														</div>
														<div
															className='grow'
															onClick={() => {
																handleMarkerClick(
																	node
																)
															}}
														></div>
													</div>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						)}
					</div>
					{isLoaded && (
						<div className='w-full md:w-1/2 h-[200px] sm:h-[268px] md:h-screen-no-header mx-auto flex flex-col md:flex-row relative'>
							<div className='map-container'>
								<GoogleMap
									mapContainerStyle={{
										overflow: 'hidden',
										position: 'absolute',
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
										// width: '100%',
									}}
									// onLoad={onLoad}
									// onUnmount={onUnmount}
									zoom={selectedZoom}
									center={selectedCenterCoords}
								>
									{postSearchNodes.map((node) => {
										if (!node.coordinates) return
										const [lng, lat] = node.coordinates
										// bounds.extend({
										//   lat: Number(lat.toString()),
										//   lng: Number(lng.toString()),
										// })
										if (
											node.name === selectedMapNode.name
										) {
											return (
												<Marker
													onClick={() => {
														handleMarkerClick(node)
													}}
													key={node._id.toString()}
													title={node.name}
													icon={{
														url:
															node.type ===
															'whole-foods'
																? 'https://i.imgur.com/ppyvkNZ.png'
																: 'https://i.imgur.com/b33G8C4.png',
														scaledSize:
															node.type ===
															'whole-foods'
																? new google.maps.Size(
																		35,
																		45
																  )
																: new google.maps.Size(
																		50,
																		50
																  ),
													}}
													name={node.name}
													position={{
														lat: Number(
															lat.toString()
														),
														lng: Number(
															lng.toString()
														),
													}}
												></Marker>
											)
										} else {
											return (
												<Marker
													onClick={() => {
														handleMarkerClick(node)
													}}
													key={node._id.toString()}
													title={node.name}
													icon={{
														url:
															node.type ===
															'whole-foods'
																? 'https://i.imgur.com/ppyvkNZ.png'
																: 'https://i.imgur.com/F4ogwrP.png',
														scaledSize:
															node.type ===
															'whole-foods'
																? new google.maps.Size(
																		35,
																		45
																  )
																: new google.maps.Size(
																		50,
																		50
																  ),
													}}
													name={node.name}
													position={{
														lat: Number(
															lat?.toString()
														),
														lng: Number(
															lng?.toString()
														),
													}}
												></Marker>
											)
										}
									})}
									{userLocation && userLocation.lng && (
										<Marker
											key='user-location'
											title='Your address'
											icon={{
												url: 'https://i.imgur.com/GOE5xAq.png',
												scaledSize:
													new google.maps.Size(
														50,
														50
													),
											}}
											name='Your address'
											position={{
												lat: userLocation.lat,
												lng: userLocation.lng,
											}}
										/>
									)}
								</GoogleMap>
							</div>
							{distPopupVisible && distanceInfo && (
								<div
									className={`absolute top-[60px] left-[10px] bg-white border-4 border-solid border-green-600 rounded-lg p-4 z-20 flex flex-col`}
								>
									<div className='flex flex-row justify-start items-center my-auto'>
										<FaRoute />
										<div className='ml-2'>
											{distanceInfo.distance} miles
										</div>
									</div>
									<a
										href={`https://www.google.com/maps/dir/?api=1&origin=${distanceInfo.originLat},${distanceInfo.originLng}&destination=${distanceInfo.destLat},${distanceInfo.destLng}`}
										target='_blank'
										rel='noopener noreferrer'
										className='text-blue-500 underline'
									>
										Open in Google Maps
									</a>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<Footer />
			{openPopUp && (
				<RedirectPopUp
					handleOpenPopUp={handleOpenPopUp}
					popUpNode={popUpNode}
					openPopUp={openPopUp}
				/>
			)}{' '}
		</div>
	)
}

// MarketplaceLocations.propTypes = {
//   loadingNodes: PropTypes.any,
//   loggedIn: PropTypes.any,
//   nodes: PropTypes.shape({
//     map: PropTypes.func
//   }),
//   nodesError: PropTypes.any,
//   onGetNodeCuisines: PropTypes.func,
//   onGetNodes: PropTypes.func,
//   onGetUserAddressLocation: PropTypes.func,
//   onUpdateSearchAddress: PropTypes.func,
//   searchedAddress: PropTypes.string,
//   searchedAddressLatLng: PropTypes.any,
//   user: PropTypes.any,
//   userLocation: PropTypes.shape({
//     lat: PropTypes.any,
//     lng: PropTypes.any
//   })
// }

const mapStateToProps = ({ Nodes, User, Location }) => ({
	nodes: Nodes.nodes,
	node: Nodes.node,
	loadingNodes: Nodes.loadingNodes,
	loadingNode: Nodes.loadingNode,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	searchedAddress: Nodes.searchedAddress,
	searchedAddressLatLng: Nodes.searchedAddressLatLng,
	allUniqueCuisines: Nodes.allUniqueCuisines,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	finishedNodesPull: Nodes.finishedNodesPull,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetNodeBySlug: (filter) => dispatch(getNodeBySlug(filter)),
	onUpdateSearchAddress: (address) => dispatch(updateSearchNodes(address)),
	onGetUserAddressLocation: () => dispatch(getAddressLocation()),
	onGetUser: () => dispatch(getUser()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MarketplaceLocations)
