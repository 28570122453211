import React, { useEffect, useState } from 'react'
import { Header, Footer } from '../../components'
import { Link, useParams } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'
import { connect } from 'react-redux'
import { getNodeBySlug } from '../../store/nodes/actions'
import { BounceLoader } from 'react-spinners'
import { Button } from '../../components/Elements'
import { thirdPartyDict } from '../../helpers/clientInfo'

const RedirectPage = (props) => {
	const { node, loadingNode, onGetNodeBySlug } = props
	const bounceLoaderColor = '#507f74'
	const { nodeSlug } = useParams()

	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
		}
	}, [nodeSlug])

	useEffect(() => {}, [node])

	return (
		<>
			{node ? (
				<>
					<Header />
					<div className='flex flex-col items-center min-h-min w-full text-center  bg-yellow-400 pb-7'>
						<h1 className='mt-7 md:mt-14 mb-4 md:mb-8 px-6 text-3xl text-green-600 font-header md:text-6xl '>
							Before we send you to {node.name}
						</h1>
						<div className='w-[90%] max-w-[950px] min-h-min py-5 px-10 mb-5 shadow-lighter-grey bg-white rounded-[10px] mx-auto'>
							<div className='flex flex-col md:flex-row mb-6 md:mb-12'>
								<div className='text-left mt-3 md:mt-6 w-full md:w-1/2 pr-0 md:pr-6 text-base md:text-[22px] leading-5 md:leading-9'>
									Remember you must add "Use DeliverZero
									Reusable Containers" to your cart!
								</div>
								<div className='text-left mt-3 md:mt-0 w-full md:w-1/2 text-base md:text-[22px] border-2'>
									<img src='https://i.imgur.com/2GrmDIr.png' />
								</div>
							</div>
							<div className='flex flex-col sm:flex-row justify-center sm:items-center'>
								{node.thirdPartyClient && (
									<div>
										<div className='mt-4 mb-8 text-lg'>
											Order Through
										</div>
										{Object.keys(node.thirdPartyClient).map(
											(client) => {
												const enabledClients =
													node.enabledClients
												if (
													enabledClients &&
													!enabledClients[client]
												) {
												} else {
													return (
														<div className='mb-7 md:mb-8'>
															<Button
																text={`${thirdPartyDict[client].name}`}
																href={
																	node
																		.thirdPartyClient[
																		client
																	]
																}
																color={client}
																size='sm'
																className='text-[16px] sm:text-[20px] shadow-light-grey font-semibold mx-auto py-2 px-6 mb-12'
																icon={
																	<BiLinkExternal />
																}
																iconPosition='right'
															/>
														</div>
													)
												}
											}
										)}
									</div>
								)}
								<hr className='inline-block sm:hidden my-4' />
								<div className='sm:inline-block hidden border-l border-[1px] border-gray-100 mx-6 h-[100px]' />
								{node.directOrdering &&
									node.directOrderingClient && (
										<div className='mb-6'>
											<div className='sm:mt-4 mb-8 text-lg'>
												Order Directly
											</div>
											<Button
												size='xs'
												className='text-[14px] sm:text-[20px] shadow-light-grey font-semibold mx-auto py-3 px-6 mb-12'
												text='Order with DeliverZero'
												link={`/marketplace/${node.slug}`}
											/>
										</div>
									)}
							</div>
						</div>
						<Link
							to='/marketplace/locations'
							className='text-green-600 underline font-semibold leading-5 mt-2 mb-2'
						>
							Go Back
						</Link>
					</div>
					<Footer />
				</>
			) : (
				<>
					<Header />
					<div className='flex flex-col items-center min-h-min w-full text-center  bg-yellow-400 lg:h-screen-no-header'>
						<div className='mt-3 text-center'>
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						</div>
					</div>
					<Footer />
				</>
			)}
		</>
	)
}
const mapStateToProps = ({ Nodes }) => ({
	loadingNode: Nodes.loadingNode,
	node: Nodes.node,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage)
