import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from '../Elements'
import ReusablesModal from './ResuablesModal'

const ReusableProductTile = (props) => {
	const { name, description, imgSrc, price, sku, handleAddProduct, details } =
		props
	const [itemCount, setItemCount] = useState(1)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const descrMaxLen = 260

	const handleAddToCart = () => {
		if (itemCount < 1) {
			return
		}

		handleAddProduct({
			id: sku,
			name: name,
			description: description,
			price: price,
			imgSrc: imgSrc,
			quantity: itemCount,
		})
	}

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
			<div
				className='w-[275px] sm:w-[315px] h-[400px] py-7 px-7 flex flex-col flex-shrink-0 border border-gray-200 rounded-xl cursor-pointer'
				onClick={toggleModal}
			>
				<div className='w-full mb-6'>
					<img
						src={imgSrc}
						className='w-[190px] h-[125px]'
					/>
				</div>
				<div className='h-full flex flex-col'>
					<div className='flex flex-col h-full'>
						<h1 className='font-header text-black text-lg'>
							{name}
						</h1>
						<p className='whitespace-wrap overflow-hidden text-sm mt-2'>
							{description.length > descrMaxLen
								? description.slice(0, descrMaxLen - 3) + '...'
								: description}
						</p>
					</div>
					<div className='flex flex-col'>
						<p className='text-sm font-bold pb-2'>
							{'Price: $' + price.toString()}
						</p>
						<div className='flex flex-row justify-between'>
							<div className='border-2 w-full rounded-full text-center flex justify-between px-3 items-center'>
								<span
									className='text-xl text-gray-300 cursor-pointer'
									onClick={(e) => {
										e.stopPropagation()
										const updatedCount =
											itemCount > 1
												? itemCount - 1
												: itemCount
										setItemCount(updatedCount)
									}}
								>
									-
								</span>
								<input
									value={itemCount}
									onChange={(e) => {
										if (isNaN(parseInt(e.target.value))) {
											setItemCount(0)
										} else {
											const updatedCount = parseInt(
												e.target.value
											)
											if (updatedCount < 101) {
												setItemCount(updatedCount)
											}
										}
									}}
									className='max-w-[40px] text-center'
									onClick={(e) => e.stopPropagation()}
								/>
								<span
									className='text-xl text-gray-300 cursor-pointer'
									onClick={(e) => {
										e.stopPropagation()
										const updatedCount = itemCount + 1
										if (updatedCount < 101) {
											setItemCount(updatedCount)
										}
									}}
								>
									+
								</span>
							</div>
							<Button
								text={'Add to Cart'}
								size='sm'
								color={itemCount > 0 ? 'green' : 'gray'}
								className='min-w-[120px] md:min-w-[140px] ml-2 text-xs md:text-sm'
								onClick={(e) => {
									e.stopPropagation()
									handleAddToCart()
								}}
							></Button>
						</div>
					</div>
				</div>
			</div>

			<ReusablesModal
				isOpen={isModalOpen}
				onClose={toggleModal}
			>
				<div className='flex flex-col items-center'>
					<img
						src={imgSrc}
						className='w-[300px] h-[200px]'
					/>
					<h1 className='font-header text-black text-lg mt-4'>
						{name}
					</h1>
					<p className='whitespace-wrap text-sm mt-2 mx-10 w-[80%]'>
						{description}
					</p>
					<p className='whitespace-wrap text-sm mt-2 mx-10 w-[80%] mb-3'>
						{details?.general && details.general.length > 0 && (
							<ul className='list-disc ml-6'>
								{details.general.map((bullet, index) => {
									return <li key={index}>{bullet}</li>
								})}
							</ul>
						)}
					</p>
					<p className='whitespace-wrap text-sm mt-2 mx-10 w-[80%] mb-3'>
						{details?.features && details.features.length > 0 && (
							<>
								<h2 className='text-green-600 font-header'>
									Materials & Features
								</h2>
								<ul className='list-disc ml-6'>
									{details.features.map((bullet, index) => {
										return <li key={index}>{bullet}</li>
									})}
								</ul>
							</>
						)}
					</p>
					<p className='whitespace-wrap text-sm mt-2 mx-10 w-[80%] mb-3'>
						{details?.dimensions &&
							details.dimensions.length > 0 && (
								<>
									<h2 className='text-green-600 font-header'>
										Product Dimensions
									</h2>
									<ul className='list-disc ml-6'>
										{details.dimensions.map(
											(bullet, index) => {
												return (
													<li key={index}>
														{bullet}
													</li>
												)
											}
										)}
									</ul>
								</>
							)}
					</p>
					<p className='text-lg font-bold pb-2 mt-4'>
						{'Price: $' + price.toString()}
					</p>
					<div className='border-2 w-[30%] rounded-full text-center flex justify-between px-3 items-center'>
						<span
							className='text-xl text-gray-300 cursor-pointer'
							onClick={() => {
								const updatedCount =
									itemCount > 1 ? itemCount - 1 : itemCount
								setItemCount(updatedCount)
							}}
						>
							-
						</span>
						<input
							value={itemCount}
							onChange={(e) => {
								if (isNaN(parseInt(e.target.value))) {
									setItemCount(0)
								} else {
									const updatedCount = parseInt(
										e.target.value
									)
									if (updatedCount < 101) {
										setItemCount(updatedCount)
									}
								}
							}}
							className='max-w-[40px] text-center'
						/>
						<span
							className='text-xl text-gray-300 cursor-pointer'
							onClick={() => {
								const updatedCount = itemCount + 1
								if (updatedCount < 101) {
									setItemCount(updatedCount)
								}
							}}
						>
							+
						</span>
					</div>
					<Button
						text={'Add to Cart'}
						size='sm'
						color={itemCount > 0 ? 'green' : 'gray'}
						className='min-w-[120px] md:min-w-[140px] ml-2 text-xs md:text-sm mt-4'
						onClick={() => {
							handleAddToCart()
							toggleModal()
						}}
					></Button>
				</div>
			</ReusablesModal>
		</>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ReusableProductTile)
