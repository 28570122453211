import React, { useState } from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { Button } from '../../components/Elements'

import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

function HowItWorks() {
	const FAQs = {
		'How do I order food?':
			'Search your address on our to see which restaurants deliver to you. ' +
			'You can order from select restaurants directly through our site, or you will be redirected to another platform. ' +
			'If you order through another platform, you will see an option to opt-in to receive your order in our containers on ' +
			'the restaurant’s menu or on the checkout page.',
		'How do I order in DeliverZero containers on other platforms?':
			'When you use our order page to see which restaurants deliver to you, you can filter by your preferred platform. When you select a restaurant, you’ll be redirected to the restaurant’s menu on another platform. On the menu, you’ll see an option to opt-in to receive your order in reusable containers on the restaurant’s menu, as an item-level modifier (similar to choosing a dressing or adding avocado), or on the checkout page.',
		'Does it cost extra to order in DeliverZero containers?':
			'When you order pickup or delivery through our site or app, there is a small service fee that allows us to manage everything in the background to retrieve, sanitize, and reuse our containers, so that you can continue dining without the waste. If you order through another platform, there may be a small fee associated with ordering in our containers.',
		'How do I return my containers?':
			'You can return your boxes to any return point on the DeliverZero network, schedule a pickup, or order from a restaurant whose courier accepts returns. Find a return point near you or schedule a return by clicking "Return Containers" in our menu',
		'How do I return containers at my door?':
			'You can schedule a container pickup by clicking "Return Containers" in our menu and the "Return at your door". Then enter the address where you are returning from, select an available pickup date, and tell us how many container you are returning. We take advantage of existing empty courier legs and use route optimization so that pick ups are as efficient and minimally impactful as possible.',
		'Do I have to clean my containers before returning them?':
			'Please make sure your containers are rinsed clean and free of food debris before returning them. They will be sanitized in a commercial dishwasher before they are reused.',
		'What happens after I return the containers? How often can they be reused?':
			'The containers you return will be sanitized and reused. They are designed to be used up to 1,000 times and withstand the high heat of commercial dishwashers. They are BPA free and NSF certified.',
		'What are the environmental benefits of ordering in DeliverZero containers?':
			'Each time one of our containers is used, it saves a single-use container from ending up in the trash. According to Upstream’s Reuse Wins report, reusable food service containers beat single-use alternatives through every environmental measure, including climate pollution, energy use, water use, resource extraction, waste, and plastic pollution.',
		'How do I get in touch?':
			'For media or partnership inquiries, please email us at hello@deliverzero.com. For customer support inquiries, please contact support@deliverzero.com.',
	}

	// return (
	// 	<>
	// 		<Header />
	// 		<div className='flex flex-col justify-center'>
	// 			<div className='w-[90%] md:w-4/5 max-w-[950px] mx-auto'>
	// 				<h1 className='font-header text-green-600 text-4xl sm:text-[66px] mb-6 sm:mb-11 mt-14 '>
	// 					How it Works
	// 				</h1>
	// 				<div className='flex flex-row min-h-min py-5 px-10 mb-12 shadow-lighter-grey bg-white rounded-xl'>
	// 					<div className='hidden sm:flex flex-col mx-auto mr-10 mb-6 w-full max-w-[130px] items-center justify-center flex-shrink-0'>
	// 						<img
	// 							src='images/HowItWorks/ForkKnife.svg'
	// 							alt='an icon of a fork and knife'
	// 							className='w-[130px] h-[130px]'
	// 						/>
	// 					</div>
	// 					<div className='flex flex-col'>
	// 						<div className='font-header text-green-600 text-xl sm:text-[38px] mb-4 sm:mb-5'>
	// 							Search your address to find a restaurant near
	// 							you
	// 						</div>

	// 						<div className='mb-4 text-[14px] sm:text-[18px]'>
	// 							When you search your address, you can sort
	// 							restaurants based on preferences such as
	// 							distance or cuisine type. Order from restaurants
	// 							directly through DeliverZero or through one of
	// 							our third-party ordering platforms. Check out
	// 							our growing list of restaurants on our{' '}
	// 							<Link
	// 								className='text-yellow-600 underline font-semibold'
	// 								to='/marketplace/locations'
	// 							>
	// 								<span className='text-green-600'>
	// 									order page
	// 								</span>
	// 							</Link>
	// 							.
	// 						</div>
	// 						<Button
	// 							text='Order Now'
	// 							link='/marketplace/locations'
	// 							color='green'
	// 							size='sm'
	// 							className='w-[210px]'
	// 						/>
	// 					</div>
	// 				</div>
	// 				<div className='flex flex-row min-h-min py-5 px-10 mb-12 shadow-lighter-grey bg-white rounded-xl mx-auto'>
	// 					<div className='hidden sm:flex flex-col mx-auto mr-10 mb-6 w-full max-w-[130px] items-center justify-center flex-shrink-0'>
	// 						<img
	// 							src='images/HowItWorks/Not apples.png'
	// 							alt='an icon of a fork and knife'
	// 							className='w-[130px] h-[130px]'
	// 						/>
	// 					</div>
	// 					<div className='flex flex-col'>
	// 						<div className='font-header text-green-600 text-xl sm:text-[38px] mb-4 sm:mb-5'>
	// 							Receive your order in returnable reusable
	// 							containers
	// 						</div>

	// 						<div className='mb-4 text-[14px] sm:text-[18px]'>
	// 							Your order will arrive in BPA free,
	// 							NSF-certified reusable containers designed to be
	// 							reused up to 1,000 times. Our containers
	// 							eliminate waste and the emissions required to
	// 							manufacture, ship, and dispose of containers
	// 							designed to be used just once.
	// 						</div>
	// 						<Button
	// 							text='Why Reuse'
	// 							link='/whyreuse'
	// 							color='green'
	// 							size='sm'
	// 							className='w-[210px]'
	// 						/>
	// 					</div>
	// 				</div>
	// 				<div className='flex flex-row min-h-min py-5 px-10 mb-5 shadow-lighter-grey bg-white rounded-xl mx-auto'>
	// 					<div className='hidden sm:flex flex-col mx-auto mr-10 mb-6 w-full max-w-[130px] items-center justify-center flex-shrink-0'>
	// 						<img
	// 							src='images/HowItWorks/House.svg'
	// 							alt='an icon of a fork and knife'
	// 							className='w-[130px] h-[130px]'
	// 						/>
	// 					</div>
	// 					<div className='flex flex-col'>
	// 						<div className='font-header text-green-600 text-xl sm:text-[38px] mb-4 sm:mb-5'>
	// 							Return your containers within 3 weeks
	// 						</div>

	// 						<div className='mb-4 text-[14px] sm:text-[18px]'>
	// 							You can return your containers to any point on
	// 							our network, not just to restaurants you’ve
	// 							ordered from. Search the returns page to find a
	// 							return point where you can drop off your
	// 							containers, or schedule a pickup to return them
	// 							at your door. You can also return your
	// 							containers to the courier who delivers your
	// 							order from{' '}
	// 							<Link
	// 								className='text-yellow-600 underline font-semibold'
	// 								to='/marketplace/locations'
	// 							>
	// 								<span className='text-green-600'>
	// 									select restaurants
	// 								</span>
	// 							</Link>
	// 							.
	// 						</div>
	// 						<Button
	// 							text='Make a Return'
	// 							link='/returns'
	// 							color='green'
	// 							size='sm'
	// 							className='w-[210px]'
	// 						/>
	// 					</div>
	// 				</div>
	// 			</div>
	// 			<div className='w-[90%] md:w-4/5 max-w-[950px] mx-auto mb-10'>
	// 				<h1 className='font-header text-green-600 text-4xl sm:text-[66px] mb-6 sm:mb-11 mt-14 '>
	// 					FAQ
	// 				</h1>

	// 				{Object.keys(FAQs).map((i, FAQ) => {
	// 					const [open, setOpen] = useState(false)
	// 					return (
	// 						<div key={i}>
	// 							<div className='flex flex-row items-center mb-2 sm:mb-5'>
	// 								<div
	// 									onClick={() => {
	// 										setOpen(!open)
	// 									}}
	// 									className='cursor-pointer font-header text-green-600 text-base sm:text-xl'
	// 								>
	// 									{i}
	// 								</div>
	// 								{open ? (
	// 									<BsChevronUp
	// 										onClick={() => {
	// 											setOpen(false)
	// 										}}
	// 										className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl font-semibold'
	// 									/>
	// 								) : (
	// 									<BsChevronDown
	// 										onClick={() => {
	// 											setOpen(true)
	// 										}}
	// 										className='cursor-pointer ml-2 text-green-600 text-base sm:text-xl font-semibold'
	// 									/>
	// 								)}
	// 							</div>
	// 							{open && (
	// 								<div className='text-sm sm:text-base mb-2 sm:mb-5'>
	// 									{FAQs[i]}
	// 								</div>
	// 							)}
	// 						</div>
	// 					)
	// 				})}
	// 			</div>
	// 		</div>
	// 		<Footer />
	// 	</>
	// )

	return (
		<>
			<Header />
			<div className='flex flex-col justify-center'>
				<div className='w-[90%] md:w-4/5 max-w-[950px] mx-auto'>
					<h1 className='text-2xl md:text-5xl font-header text-green-600 w-full mb-4 mt-12'>
						How it Works
					</h1>
					<div className='flex flex-row min-h-min py-5 px-10 shadow-lighter-grey bg-white rounded-xl my-5'>
						<div className='hidden sm:flex flex-col mx-auto mr-10 mb-6 w-full max-w-[100px] items-center justify-center flex-shrink-0'>
							<img
								src='images/HowItWorks/ForkKnife.svg'
								alt='an icon of a fork and knife'
								className='w-[100px] h-[100px]'
							/>
						</div>
						<div className='flex flex-col'>
							<div
								className='font-header text-green-600 text-2xl mb-4 sm:mb-5'
								style={{ lineHeight: '0.9' }}
							>
								Search your address to find a restaurant near
								you
							</div>

							<div className='mb-4'>
								When you search your address, you can sort
								restaurants based on preferences such as
								distance or cuisine type. Order from restaurants
								directly through DeliverZero or through one of
								our third-party ordering platforms. Check out
								our growing list of restaurants on our{' '}
								<Link
									className='text-yellow-600 underline font-semibold'
									to='/marketplace/locations'
								>
									<span className='text-green-600'>
										order page
									</span>
								</Link>
								.
							</div>
							<Button
								text='Order Now'
								link='/marketplace/locations'
								color='green'
								size='sm'
								className='w-[210px]'
							/>
						</div>
					</div>
					<div className='flex flex-row min-h-min py-5 px-10 shadow-lighter-grey bg-white rounded-xl mx-auto my-5'>
						<div className='hidden sm:flex flex-col mx-auto mr-10 mb-6 w-full max-w-[100px] items-center justify-center flex-shrink-0'>
							<img
								src='images/HowItWorks/Not apples.png'
								alt='an icon of a fork and knife'
								className='w-[100px] h-[100px]'
							/>
						</div>
						<div className='flex flex-col'>
							<div
								className='font-header text-green-600 text-2xl mb-4 sm:mb-5'
								style={{ lineHeight: '0.9' }}
							>
								Receive your order in returnable reusable
								containers
							</div>

							<div className='mb-4'>
								Your order will arrive in BPA free,
								NSF-certified reusable containers designed to be
								reused up to 1,000 times. Our containers
								eliminate waste and the emissions required to
								manufacture, ship, and dispose of containers
								designed to be used just once.
							</div>
							<Button
								text='Why Reuse'
								link='/whyreuse'
								color='green'
								size='sm'
								className='w-[210px]'
							/>
						</div>
					</div>
					<div className='flex flex-row min-h-min py-5 px-10 shadow-lighter-grey bg-white rounded-xl mx-auto my-5'>
						<div className='hidden sm:flex flex-col mx-auto mr-10 mb-6 w-full max-w-[100px] items-center justify-center flex-shrink-0'>
							<img
								src='images/HowItWorks/House.svg'
								alt='an icon of a fork and knife'
								className='w-[100px] h-[100px]'
							/>
						</div>
						<div className='flex flex-col'>
							<div
								className='font-header text-green-600 text-2xl mb-4 sm:mb-5'
								style={{ lineHeight: '0.9' }}
							>
								Return your containers within 3 weeks
							</div>

							<div className='mb-4'>
								You can return your containers to any point on
								our network, not just to restaurants you’ve
								ordered from. Search the returns page to find a
								return point where you can drop off your
								containers, or schedule a pickup to return them
								at your door. You can also return your
								containers to the courier who delivers your
								order from{' '}
								<Link
									className='text-yellow-600 underline font-semibold'
									to='/marketplace/locations'
								>
									<span className='text-green-600'>
										select restaurants
									</span>
								</Link>
								.
							</div>
							<Button
								text='Make a Return'
								link='/returns'
								color='green'
								size='sm'
								className='w-[210px]'
							/>
						</div>
					</div>
				</div>
				<div className='w-[90%] md:w-4/5 max-w-[950px] mx-auto mb-10'>
					<h1 className='font-header text-green-600 text-2xl sm:text-[60px] mb-6 sm:mb-11 mt-14'>
						FAQ
					</h1>

					{Object.keys(FAQs).map((i, index) => {
						const [open, setOpen] = useState(false)
						return (
							<div key={index}>
								<div className='flex flex-row items-center mb-2 sm:mb-5'>
									<div
										onClick={() => {
											setOpen(!open)
										}}
										className='cursor-pointer font-header text-green-600 text-xl'
									>
										{i}
									</div>
									{open ? (
										<BsChevronUp
											onClick={() => {
												setOpen(false)
											}}
											className='cursor-pointer ml-2 text-green-600 text-sm sm:text-3xl font-semibold'
										/>
									) : (
										<BsChevronDown
											onClick={() => {
												setOpen(true)
											}}
											className='cursor-pointer ml-2 text-green-600 text-sm sm:text-3xl font-semibold'
										/>
									)}
								</div>
								{open && (
									<div className='text-[14px] sm:text-base mb-2 sm:mb-5'>
										{FAQs[i]}
									</div>
								)}
							</div>
						)
					})}
				</div>
			</div>
			<Footer />
		</>
	)
}

export default HowItWorks
