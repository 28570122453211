import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, getNodeBySlug, getLead } from '../../../store/actions'
import { Footer, Header } from '../../../components'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'

const ViewOneLead = (state) => {
	const {
		user,
		onGetUser,
		onGetLead,
		onGetNodeBySlug,
		node,
		loadingNode,
		lead,
		loadingLead,
		leadError,
	} = state

	const { nodeSlug } = useParams()
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		onGetLead({ slug: nodeSlug })
		onGetNodeBySlug(nodeSlug)
	}, [nodeSlug])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				{lead ? (
					<div className='w-full h-full'>
						<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
							Lead Details: {lead.restaurantName}
						</h1>
						{leadError && (
							<div className='font-bold text-red-400 my-1'>
								{JSON.stringify(leadError)}
							</div>
						)}
						<div className='max-w-[90%] shadow-light-grey flex flex-col justify-center items-center m-auto rounded-xl px-8 py-2 mt-6 mb-8 overflow-y-auto overflow-x-auto scrollbar'>
							<div className='flex flex-col justify-start items-start p-10'>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Restaurant Name:
									</span>
									<span className='font-normal'>
										{lead.restaurantName}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Contact Name:
									</span>
									<span className='font-normal'>
										{lead.firstName} {lead.lastName}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Title:
									</span>
									<span className='font-normal'>
										{lead.title}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Address:
									</span>
									<span className='font-normal'>
										{lead.fullAddress}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Phone:
									</span>
									<span className='font-normal'>
										{lead.contactPhone}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Email:
									</span>
									<span className='font-normal'>
										{lead.email}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mr-3 mb-3'>
									<span className='font-bold font-header'>
										Last Updated:{' '}
									</span>
									<span className='font-normal'>
										{new Date(lead.lastUpdated)
											.toString()
											.slice(0, 15)}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										How Did You Hear:
									</span>
									<ul className='font-normal'>
										{lead.howDidYouHear &&
										lead.howDidYouHear.length > 0 ? (
											lead.howDidYouHear.map(
												(source, index) => (
													<li key={index}>
														{source}
													</li>
												)
											)
										) : (
											<li></li>
										)}
									</ul>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										DZ Ambassador:
									</span>
									<span className='font-normal'>
										{lead.dzAmbassador}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Has Commercial Dishwasher:
									</span>
									<span className='font-normal'>
										{lead.hasCommercialDishwasher}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Last Page:
									</span>
									<span className='font-normal'>
										{lead.lastPage}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Sign-Up Complete:
									</span>
									<span className='font-normal'>
										{lead.signUpComplete ? 'Yes' : 'No'}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Aggregator:
									</span>
									<span className='font-normal'>
										{lead.aggregator}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Ordering Platforms:
									</span>
									<ul className='font-normal'>
										{lead.orderingPlatforms &&
										lead.orderingPlatforms.length > 1 ? (
											lead.orderingPlatforms.map(
												(platform, index) => (
													<li key={index}>
														{platform}
													</li>
												)
											)
										) : (
											<li>{lead.orderingPlatforms}</li>
										)}
									</ul>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										POS System:
									</span>
									<span className='font-normal'>
										{lead.posSystem}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Return Point:
									</span>
									<span className='font-normal'>
										{lead.returnPoint}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Catering Platforms:
									</span>
									<ul className='font-normal'>
										{lead.cateringPlatforms &&
										lead.cateringPlatforms.length > 0 ? (
											lead.cateringPlatforms.map(
												(platform, index) => (
													<li key={index}>
														{platform}
													</li>
												)
											)
										) : (
											<li></li>
										)}
									</ul>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Billing Email:
									</span>
									<span className='font-normal'>
										{lead.billingEmail}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Default Payment Method:
									</span>
									<span className='font-normal'>
										{lead.defaultPaymentMethod}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Pay with ACH:
									</span>
									<span className='font-normal'>
										{lead.payWithACH ? 'Yes' : 'No'}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Promo Code Slug:
									</span>
									<span className='font-normal'>
										{lead.promoCodeSlug}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Promo Code Message:
									</span>
									<span className='font-normal'>
										{lead.promoCodeMsg}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Containers:
									</span>
									<ul className='font-normal'>
										{lead.containers &&
										lead.containers.length > 1 ? (
											lead.containers.map(
												(container, index) => (
													<li key={index}>
														{container}
													</li>
												)
											)
										) : lead.containers ? (
											<li>{lead.containers[0]}</li>
										) : (
											''
										)}
									</ul>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Type:
									</span>
									<span className='font-normal'>
										{lead.type}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Enabled:
									</span>
									<span className='font-normal'>
										{lead.enabled ? 'true' : 'false'}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Mute:
									</span>
									<span className='font-normal'>
										{lead.mute ? 'true' : 'false'}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Admin Pin Code:
									</span>
									<span className='font-normal'>
										{lead.adminPinCode}
									</span>
								</div>
								<div className='flex flex-col sm:flex-row mb-3'>
									<span className='font-bold font-header mr-3'>
										Slug:
									</span>
									<span className='font-normal'>
										{lead.slug}
									</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className='flex items-center h-screen'>
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					</div>
				)}
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Leads, Nodes }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	lead: Leads.lead,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	node: Nodes.node,
	loadingNode: Nodes.loadingNode,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodeBySlug: (payload) => dispatch(getNodeBySlug(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetLead: (payload) => dispatch(getLead(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewOneLead)
