import {
	MEMBERSHIP_SIGNUP,
	GET_MEMBERSHIP_SAVINGS,
	GET_MEMBERS,
} from './actionTypes'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
	membershipSignupFail,
	membershipSignupSuccess,
	getMembershipSavingsFail,
	getMembershipSavingsSuccess,
	getMembersFail,
	getMembersSuccess,
} from './actions'
import {
	membershipSignup,
	getMembershipSavings,
	getMembers,
} from '../../model/membership'

function* dispatchMembershipSignup(action) {
	try {
		const member = yield call(membershipSignup, action.payload)
		yield put(membershipSignupSuccess(member))
	} catch (error) {
		yield put(membershipSignupFail(error))
	}
}

function* calculateMembershipSavings(action) {
	try {
		const savings = yield call(getMembershipSavings, action.payload)
		yield put(getMembershipSavingsSuccess(savings))
	} catch (error) {
		yield put(getMembershipSavingsFail(error))
	}
}

function* fetchMembers(action) {
	try {
		const members = yield call(getMembers, action.payload)
		yield put(getMembersSuccess(members))
	} catch (error) {
		yield put(getMembersFail(error))
	}
}

function* membershipSaga() {
	yield takeEvery(MEMBERSHIP_SIGNUP, dispatchMembershipSignup)
	yield takeEvery(GET_MEMBERSHIP_SAVINGS, calculateMembershipSavings)
	yield takeEvery(GET_MEMBERS, fetchMembers)
}

export default membershipSaga
