import React, { useState } from 'react'
import { useEffect } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const Pagination = (props) => {
	const {
		totalCount,
		paginationLimit,
		page,
		handleChangePage,
		numDisplayPages,
	} = props
	const [totalPages, setTotalPages] = useState(0)

	useEffect(() => {
		setTotalPages(Math.ceil(totalCount / paginationLimit))
	}, [totalCount, paginationLimit, page])

	const nextPage = () => {
		const nextPageNumber = page + 1
		if (nextPageNumber <= totalPages) {
			handleChangePage(nextPageNumber)
			window.scrollTo(0, 0)
		}
	}

	const prevPage = () => {
		const prevPageNumber = page - 1
		if (prevPageNumber >= 1) {
			handleChangePage(prevPageNumber)
			window.scrollTo(0, 0)
		}
	}

	const specificPage = (page) => {
		handleChangePage(page)
		window.scrollTo(0, 0)
	}

	const renderPageNumbers = () => {
		const pages = []
		for (let i = 1; i <= totalPages; i++) {
			if (
				i === 1 ||
				i === totalPages ||
				(i >= page - numDisplayPages && i <= page + numDisplayPages)
			) {
				pages.push(
					<div
						key={i}
						onClick={() => specificPage(i)}
						className={`relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-20 ${
							i === page ? 'bg-green-400 text-white' : ''
						}`}
					>
						{i}
					</div>
				)
			} else if (
				i === page - numDisplayPages - 1 ||
				i === page + numDisplayPages + 1
			) {
				pages.push(
					<div
						key={i}
						onClick={() => specificPage(i)}
						className='relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-20'
					>
						...
					</div>
				)
			}
		}
		return pages
	}

	if (totalPages)
		return (
			<div className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'>
				<div className='flex flex-1 justify-between sm:hidden'>
					<div
						onClick={() => {
							prevPage()
						}}
						className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
					>
						Previous
					</div>
					<div
						onClick={() => {
							nextPage()
						}}
						className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
					>
						Next
					</div>
				</div>
				<div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
					<div>
						<p className='text-sm text-gray-700'>
							Showing{' '}
							<span className='font-medium'>{`${
								(page - 1) * paginationLimit + 1
							}`}</span>{' '}
							to{' '}
							<span className='font-medium'>
								{paginationLimit * page}
							</span>{' '}
							of <span className='font-medium'>{totalCount}</span>{' '}
							results
						</p>
					</div>
					<div>
						<nav
							className='isolate inline-flex -space-x-px rounded-md shadow-sm cursor-pointer'
							aria-label='Pagination'
						>
							<div
								onClick={prevPage}
								className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
							>
								<span className='sr-only'>Previous</span>
								<FaChevronLeft
									className='h-5 w-5'
									aria-hidden='true'
								/>
							</div>
							{renderPageNumbers()}
							<div
								onClick={nextPage}
								className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
							>
								<span className='sr-only'>Next</span>
								<FaChevronRight
									className='h-5 w-5'
									aria-hidden='true'
								/>
							</div>
							{/* <div
								onClick={prevPage}
								className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
							>
								<span className='sr-only'>Previous</span>
								<FaChevronLeft
									className='h-5 w-5'
									aria-hidden='true'
								/>
							</div>
							{[...Array(totalPages)].map((_, num) => {
								if (
									num >= 2 &&
									num < totalPages - 2 &&
									num === page - 1 &&
									page != 3
								)
									return (
										<div className='flex'>
											<div
												key={num}
												onClick={() => {}}
												className={`relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-black focus:z-20`}
											>
												. . .{' '}
											</div>
											<div
												key={num}
												onClick={() => {
													specificPage(num + 1)
												}}
												className={`relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-20 ${
													num === page - 1
														? 'bg-green-400 text-white'
														: ''
												}`}
											>
												{num + 1}
											</div>

											<div
												key={num}
												onClick={() => {}}
												className={`relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-black focus:z-20`}
											>
												. . .{' '}
											</div>
										</div>
									)
								if (page < 2 || page > totalPages - 2)
									<div
										key={num}
										onClick={() => {}}
										className={`relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-black focus:z-20`}
									>
										. . .{' '}
									</div>
								if (
									num > 2 &&
									num < totalPages - 2 &&
									num != page - 1
								)
									return null

								return (
									<div
										key={num}
										onClick={() => {
											specificPage(num + 1)
										}}
										className={`relative z-10 inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-20 ${
											num === page - 1
												? 'bg-green-400 text-white'
												: ''
										}`}
									>
										{num + 1}
									</div>
								)
							})}
							<div
								onClick={nextPage}
								className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
							>
								<span className='sr-only'>Next</span>
								<FaChevronRight
									className='h-5 w-5'
									aria-hidden='true'
								/>
							</div> */}
						</nav>
					</div>
				</div>
			</div>
		)
}

export default Pagination
