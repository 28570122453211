import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Header, Footer } from '../../components'
import { Button } from '../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { getUser } from '../../store/actions'
import {
	getAllReusableProducts,
	addProductReusablesShop,
	updateCartItemReusablesShop,
	updateCartFromUser,
	addCartToUserReusablesShop,
	clearCartReusablesShop,
	resetReusables,
} from '../../store/actions'
import ReusableProductTile from '../../components/ReusablesShop/ReusableProductTile'
import ReusablesCart from '../../components/ReusablesShop/ReusablesCart'
import { FaChevronDown } from 'react-icons/fa'

const CustomerReusablesShop = (props) => {
	const {
		user,
		loadingUser,
		onGetUser,
		allReusableProducts,
		loadingReusableProducts,
		onGetAllReusableProducts,
		onAddItem,
		onUpdateItem,
		onUpdateCartFromUser,
		cartItemsReusablesShop,
		loadingCartItems,
		onAddCartToUser,
		loadingClearCart,
		onClearCart,
		userUpdated,
		onResetReusables,
	} = props

	const bounceLoaderColor = '#507f74'
	const navigate = useNavigate()

	const [allProducts, setAllProducts] = useState([])
	const [isOpenProductSpecs, setIsOpenProductSpecs] = useState(false)
	const [isOpenPotentialUses, setIsOpenPotentialUses] = useState(false)
	const [shopAccess, setShopAccess] = useState(false)

	const handleUpdateCartItem = (product) => {
		onUpdateItem({
			currCartItems: cartItemsReusablesShop,
			cartItemToUpdate: product,
			quantity: product.quantity,
		})
	}

	const handleAddProduct = (product) => {
		onAddItem({
			currCartItems: cartItemsReusablesShop,
			nextCartItem: product,
			quantity: product.quantity,
		})
	}

	const handleClearCart = () => {
		onClearCart()
	}

	useEffect(() => {
		if (userUpdated) {
			onGetUser()
		}
	}, [userUpdated])

	useEffect(() => {
		onResetReusables()
		onGetUser()
		onGetAllReusableProducts({
			$or: [{ bulk: { $exists: false } }, { bulk: false }],
			memberShop: true,
		})
	}, [])

	useEffect(() => {
		if (allReusableProducts) {
			setAllProducts(allReusableProducts)
		}
	}, [allReusableProducts])

	useEffect(() => {
		if (
			user
			// &&
			// user.dzMemberSubscription?.tierSlug === 'tierTwo' &&
			// user.dzMemberSubscription?.enabled
		) {
			setShopAccess(true)
		}
		if (!cartItemsReusablesShop && user && user.reusablesShopCart) {
			onUpdateCartFromUser(user.reusablesShopCart)
		} else if (localStorage.getItem('reusablesShopCart')) {
			const localStorageCart = JSON.parse(
				localStorage.getItem('reusablesShopCart')
			)
			onUpdateCartFromUser(localStorageCart)
		} else {
			onUpdateCartFromUser({})
		}
	}, [user, user?.reusablesShopCart, userUpdated])

	useEffect(() => {
		if (cartItemsReusablesShop) {
			// updating user if needed
			if (
				user &&
				(!user.reusablesShopCart ||
					!user.reusablesShopCart.cartItems ||
					(user.reusablesShopCart &&
						user.reusablesShopCart.cartItems &&
						cartItemsReusablesShop !=
							user.reusablesShopCart.cartItems))
			) {
				onAddCartToUser({ cartItems: cartItemsReusablesShop })
			}

			// updating local storage
			const reusablesShopCart = {
				cartItems: cartItemsReusablesShop,
				lastActive: new Date(),
			}
			localStorage.setItem(
				'reusablesShopCart',
				JSON.stringify(reusablesShopCart)
			)
		}
	}, [cartItemsReusablesShop, JSON.stringify(cartItemsReusablesShop)])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)
	else {
		return (
			<>
				<div>
					<Header />
					{!shopAccess && (
						<div className='absolute top-1/3 left-1/3 z-10 w-1/3'>
							<div className='bg-white p-6 rounded-xl shadow-lg'>
								<h2 className='text-2xl font-bold mb-4'>
									Available to DeliverZero+ members
								</h2>
								<p className='mb-4'>
									Join now to access exclusive products and
									benefits.
								</p>
								<Button
									text='Join DeliverZero+'
									color='green'
									size='sm'
									className='flex w-[210px] shadow-light-grey'
									onClick={() => {
										navigate('/membership')
									}}
								/>
							</div>
						</div>
					)}
					<div
						className={`flex flex-col items-center ${
							shopAccess ? '' : 'blur-sm'
						}`}
					>
						{!shopAccess && (
							<div className='absolute inset-0 bg-gray-500 bg-opacity-50'></div>
						)}
						{cartItemsReusablesShop &&
							Object.keys(cartItemsReusablesShop).length > 0 && (
								<div
									style={{
										position: 'relative',
										width: '100%',
									}}
								>
									<ReusablesCart
										cartItems={cartItemsReusablesShop}
										handleUpdateCartItem={
											handleUpdateCartItem
										}
										handleClearCart={handleClearCart}
									/>
								</div>
							)}
						<div className='flex flex-col justify-center items-center text-center mx-4 sm:mx-20'>
							<h1 className='text-3xl font-header text-green-600 text-center w-full mt-5'>
								DeliverZero+ Shop
							</h1>
							{/* <h2 className='font-header text-xl w-full text-center'>
								You asked. We listened.
							</h2>
							<h2 className='text-sm text-center max-w-[850px]'>
								Throughout our journey of making reuse simple
								and ubiquitous within the food delivery space,
								we've often been asked, "Can I take DeliverZero
								containers home with me?" Now you can: we've
								just released a limited quantity for purchase.
							</h2> */}
						</div>

						{/* <div className='flex flex-row text-center mx-4 sm:mx-20 my-4 max-w-[700px]'>
							<div
								className='flex flex-col text-xs max-w-[350px]'
								onClick={() => {
									setIsOpenProductSpecs(!isOpenProductSpecs)
								}}
							>
								<div className='flex flex-row'>
									Product Specifications{' '}
									<FaChevronDown
										className={`ml-1 inline text-sm transition-colors duration-150 ease-in-out${
											isOpenProductSpecs
												? 'origin-center rotate-180'
												: ''
										}`}
									/>
								</div>
								{isOpenProductSpecs && (
									<div className='pl-5 text-start'>
										<p>• BPA-free</p>
										<p>• NSF certified</p>
										<p>• Microwavable </p>
										<p>• Dishwashable </p>
										<p>• Rated for over 1,000 uses </p>
										<p>
											• Trusted by some of your favorite
											restaurants
										</p>
									</div>
								)}
							</div>
							<div
								className='flex flex-col text-xs max-w-[350px] ml-10'
								onClick={() => {
									setIsOpenPotentialUses(!isOpenPotentialUses)
								}}
							>
								<div className='flex flex-row'>
									Potential uses{' '}
									<FaChevronDown
										className={`ml-1 inline text-sm transition-colors duration-150 ease-in-out${
											isOpenPotentialUses
												? 'origin-center rotate-180'
												: ''
										}`}
									/>
								</div>
								{isOpenPotentialUses && (
									<div className='pl-5 text-start'>
										<p>• Meal prep</p>
										<p>
											• Packing lunch to take to the
											office
										</p>
										<p>• Storing leftovers</p>
										<p>• BYO to restaurants</p>
										<p>• and many more! </p>
										<p>
											• Trusted by some of your favorite
											restaurants
										</p>
									</div>
								)}
							</div>
						</div> */}
						<div className='flex flex-col text-center mt-2 text-sm max-w-[700px] mx-10 sm:mx-20'>
							<div>
								For{' '}
								<span className='font-header'>bulk order</span>{' '}
								purchases,{' '}
								<span
									className='underline cursor-pointer'
									onClick={() => navigate('/reusables-shop/bulk')}
								>
									click here
								</span>
							</div>
							<div className='text-xs'>
								We offer special volume pricing for businesses
								or organizations looking to use our containers
								to operate their own reuse systems
							</div>
						</div>
						<div
							className={
								'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center md:w-[800px] lg:w-[1000px] my-10 gap-5'
							}
						>
							{(allProducts.length === 0 ||
								loadingUser ||
								loadingReusableProducts) && (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							)}
							{allProducts.length > 0 &&
								allProducts.map((product, index) => {
									return (
										<ReusableProductTile
											name={product.name}
											description={product.description}
											imgSrc={product.image}
											price={product.price}
											details={product.details}
											sku={product.sku}
											handleAddProduct={handleAddProduct}
											key={index}
										/>
									)
								})}
						</div>
					</div>
					<Footer />
				</div>
			</>
		)
	}
}

const mapStateToProps = ({ User, ReusablesShop }) => ({
	user: User.user,
	loadingUser: User.loading,
	userUpdated: ReusablesShop.userUpdated,
	allReusableProducts: ReusablesShop.allProducts,
	loadingReusableProducts: ReusablesShop.loading,
	cartItemsReusablesShop: ReusablesShop.cartItemsReusablesShop,
	loadingCartItems: ReusablesShop.cartItemsLoading,
	loadingClearCart: ReusablesShop.clearingCartReusablesShop,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAllReusableProducts: (payload) =>
		dispatch(getAllReusableProducts(payload)),
	onAddItem: (payload) => dispatch(addProductReusablesShop(payload)),
	onUpdateItem: (payload) => dispatch(updateCartItemReusablesShop(payload)),
	onUpdateCartFromUser: (payload) => dispatch(updateCartFromUser(payload)),
	onAddCartToUser: (payload) => dispatch(addCartToUserReusablesShop(payload)),
	onClearCart: () => dispatch(clearCartReusablesShop()),
	onResetReusables: () => dispatch(resetReusables()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerReusablesShop)
