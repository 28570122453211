import React, { useState, useEffect } from 'react'
import { Input, Button, BounceLoaderCentered } from '../../components/Elements'
import { updateUser } from '../../store/actions'
import { connect } from 'react-redux'

const AddressEditForm = (props) => {
	const google = window.google
	const {
		user,
		updateUserLoading,
		onUpdateUser,
		onGetUser,
		showAddressForm,
	} = props
	const [showAddressList, setShowAddressList] = useState(true)
	const [addressChange, setAddressChange] = useState('')
	const [defaultAddressChange, setDefaultAddressChange] = useState(false)
	const [address2, setAddress2] = useState('')
	const [addressError, setAddressError] = useState(false)
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [reverseSavedAddresses, setReverseSavedAddresses] = useState([])
	const [toggleShowAddressForm, setToggleShowAddressForm] = useState(false)

	useEffect(() => {
		if (user && user.savedAddresses) {
			setReverseSavedAddresses(user.savedAddresses.reverse())
		}
	}, [user, updateUserLoading])

	useEffect(() => {}, [addressError])

	const handleAddressChange = (newAddress) => {
		if (newAddress.target) {
			setAddressChange(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setAddressChange(newAddress.formatted_address)
		}
	}

	const handleAddress2Change = (apt) => {
		if (apt.target) {
			setAddress2(apt.target.value)
		}
	}

	const handleAddressSubmit = async (e) => {
		if (e) e.preventDefault()
		if (addressChange != '') {
			// Geocode the address
			// console.log('handling submit')
			await geocodeAddress()
			handleHideAddressForm()
			onGetUser()
		}
	}

	const geocodeAddress = async () => {
		setAddressError(false)
		// console.log('geocoding address', addressChange)
		// Geocode the address
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addressChange,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					// console.log(results[0].formatted_address)
					// set it to the correct, formatted address if it's valid
					setGeoCodedAddress(results[0].formatted_address)
					setAddressChange(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
					// show an error if it's not
					alert('Invalid address')
				}
			}
		)
	}

	useEffect(() => {
		// console.log('GEOCODED ADDRESS', geoCodedAddress)
	}, [geoCodedAddress, geoCodedLat, geoCodedLng, addressChange])

	useEffect(() => {
		// console.log('geoCodedAddress in useEffect OUTSIDE IF', geoCodedAddress)
		if (geoCodedAddress) {
			// console.log('geoCodedAddress in useEffect INSIDE IF', geoCodedAddress)
			const lastSearchedAddress = {
				address: geoCodedAddress,
				lat: geoCodedLat,
				lng: geoCodedLng,
			}
			localStorage.setItem(
				'lastSearchedAddress',
				JSON.stringify(lastSearchedAddress)
			)

			if (
				user &&
				!user?.savedAddresses.find(
					(address) => address.savedAddress === geoCodedAddress
				)
			) {
				// console.log('updating user')
				onUpdateUser({
					savedAddresses: [
						...user.savedAddresses,
						{
							savedAddress: geoCodedAddress,
							savedAddress2: address2,
						},
					],
				})
			}
			if (user && defaultAddressChange) {
				// console.log('updating user')
				onUpdateUser({
					address: geoCodedAddress,
					address2: address2,
				})
				setDefaultAddressChange(false)
			}
			handleHideAddressForm()
			onGetUser()
			setShowAddressList(true)
		}
		// handleHideAddressForm()
	}, [geoCodedAddress, defaultAddressChange, addressChange])

	const handleChangeDefaultAddress = async (address) => {
		// console.log('address', address)
		setAddressChange(address)
		setDefaultAddressChange(true)
	}

	const awaitGeocode = async () => {
		await geocodeAddress()
	}

	useEffect(() => {
		if (addressChange != '' && defaultAddressChange) {
			awaitGeocode()
		}
		onGetUser()
	}, [defaultAddressChange, addressChange])

	const handleHideAddressForm = () => {
		setToggleShowAddressForm(!toggleShowAddressForm)
		setAddressChange('')
		setAddress2('')
		setAddressError(false)
	}

	useEffect(() => {
		if (!toggleShowAddressForm && !showAddressForm) {
			handleHideAddressForm()
		}
		if (showAddressForm && !toggleShowAddressForm) {
			setToggleShowAddressForm(true)
		}
	}, [toggleShowAddressForm])

	return (
		<div className='w-full'>
			{!user.loading && showAddressList && (
				<div>
					<div className='flex flex-col justify-between items-center mt-2'>
						{user.savedAddresses &&
							!updateUserLoading &&
							reverseSavedAddresses.map((address, index) => {
								if (index > 5) {
									return null
								} else {
									return (
										<div
											key={index}
											className='flex justify-between items-center w-full m-1 border-b mb-2'
										>
											<div
												className='mr-4 text-sm'
												onClick={() => {}}
											>
												{address.savedAddress}
											</div>
											<div
												className='mr-4 text-sm'
												onClick={() => {}}
											>
												{address?.savedAddress2}
											</div>
											<div
												className='mr-4 link-sm text-xs'
												onClick={() => {
													handleChangeDefaultAddress(
														address.savedAddress
													)
													// console.log(
													// 	address.savedAddress
													// )
												}}
											>
												set default
											</div>
										</div>
									)
								}
							})}
					</div>
				</div>
			)}
			{showAddressForm && toggleShowAddressForm && (
				<div className='w-full h-auto my-5 flex flex-col justify-between items-start'>
					<form onSubmit={(e) => handleAddressSubmit(e)}>
						<Input
							placeholder='Enter your address'
							className={`relative bg-white pl-5 h-11 mb-2`}
							name={`address`}
							error={false}
							autoCompleteAddress={true}
							onChange={handleAddressChange}
							onKeyDown={(e) => {
								handleAddressChange(e)
								e.key === 'Enter' && e.preventDefault()
							}}
						/>
						<Input
							type=''
							name='unit'
							placeholder='APT, SUITE, ETC (OPTIONAL)'
							onChange={handleAddress2Change}
							className='relative bg-white pl-5 h-11 w-[180px]'
							onKeyDown={(e) => {
								e.key === 'Enter' && e.preventDefault()
							}}
						/>
						{!updateUserLoading && (
							<Button
								className='mt-2'
								text='Save Address'
								full
								size='sm'
								submit={true}
							/>
						)}
						{updateUserLoading && (
							<BounceLoaderCentered container='div' />
						)}
					</form>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = ({ User, Payments }) => ({
	loadingDeletion: Payments.loadingDeletion,
	user: User.user,
	loadingUser: User.loading,
	cardDeletionError: Payments.deletionError,
	cardDeleted: Payments.deleted,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
})

const mapDispatchToProps = (dispatch) => ({
	onUpdateUser: (data) => dispatch(updateUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddressEditForm)
