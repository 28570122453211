import {
	GET_OFFICE,
	GET_OFFICE_SUCCESS,
	GET_OFFICE_ERROR,
	GET_OFFICE_TRANSACTIONS,
	GET_OFFICE_TRANSACTIONS_SUCCESS,
	GET_OFFICE_TRANSACTIONS_FAIL,
	GET_ONE_TRANSACTION_OFFICE,
	GET_ONE_TRANSACTION_OFFICE_SUCCESS,
	GET_ONE_TRANSACTION_OFFICE_FAIL,
	GET_OFFICE_CUSTOMERS,
	GET_OFFICE_CUSTOMERS_SUCCESS,
	GET_OFFICE_CUSTOMERS_FAIL,
	GET_ONE_CUSTOMER_OFFICE,
	GET_ONE_CUSTOMER_OFFICE_SUCCESS,
	GET_ONE_CUSTOMER_OFFICE_FAIL,
	UPDATE_OFFICE_EMAIL,
	UPDATE_OFFICE_EMAIL_SUCCESS,
	UPDATE_OFFICE_EMAIL_ERROR,
	GET_OFFICE_BY_ADMIN_CODE,
	GET_OFFICE_BY_ADMIN_CODE_SUCCESS,
	GET_OFFICE_BY_ADMIN_CODE_FAIL,
	RESET_ADMIN_OFFICE,
	INVITE_OFFICE_ADMIN,
	INVITE_OFFICE_ADMIN_FAIL,
	INVITE_OFFICE_ADMIN_SUCCESS,
	RESET_OFFICE_INVITE,
} from './actionTypes'
import {
	UPDATE_OFFICE,
	UPDATE_OFFICE_SUCCESS,
	UPDATE_OFFICE_ERROR,
} from './actionTypes'
import {
	STORE_PAYMENT_METHOD_OFFICE,
	STORE_PAYMENT_METHOD_OFFICE_SUCCESS,
	STORE_PAYMENT_METHOD_OFFICE_FAIL,
} from './actionTypes'
import {
	GET_OFFICE_ADMINS,
	GET_OFFICE_ADMINS_SUCCESS,
	GET_OFFICE_ADMINS_ERROR,
} from './actionTypes'
import {
	ASSIGN_OFFICE_ADMIN,
	ASSIGN_OFFICE_ADMIN_SUCCESS,
	ASSIGN_OFFICE_ADMIN_ERROR,
} from './actionTypes'
import {
	SCHEDULE_OFFICE_PICKUP,
	SCHEDULE_OFFICE_PICKUP_SUCCESS,
	SCHEDULE_OFFICE_PICKUP_ERROR,
} from './actionTypes'

const initialState = {
	loadingOffice: false,
	office: null,
	officeError: null,
	error: {},
	loadingInvite: false,
	invite: null,
	transactions: [],
	loadingTransactions: true,
	oneTransactionOffice: null,
	loadingTransaction: true,
	updatingOffice: false,
	updateOfficeCompleted: null,
	updateOfficeError: null,
	updatingOfficeEmail: false,
	updateOfficeEmailCompleted: null,
	updateOfficeEmailError: null,
	loadingOfficeAdmins: false,
	officeAdmins: [],
	officeAdminsError: null,
	assigningOfficeAdmin: false,
	assignedOfficeAdmin: false,
	assignOfficeAdminError: null,
	schedulingOfficePickup: false,
	scheduledOfficePickup: false,
	scheduleOfficePickupError: null,
	customers: [],
	loadingCustomers: true,
	oneCustomerOffice: null,
	loadingCustomer: true,
	cardAdded: false,
	loadingAddition: false,
	paymentError: null,
	paymentMethods: [],
	loadingPaymentMethods: false,
}

const officeReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_OFFICE:
			return {
				...state,
				loadingOffice: true,
				office: null,
				officeError: null,
			}
		case GET_OFFICE_SUCCESS:
			return {
				...state,
				loadingOffice: false,
				office: action.payload,
				officeError: null,
			}
		case GET_OFFICE_ERROR:
			return {
				...state,
				loadingOffice: false,
				office: null,
				officeError: action.payload,
			}
		case GET_OFFICE_TRANSACTIONS:
			return {
				...state,
				loadingTransactions: true,
			}
		case GET_OFFICE_TRANSACTIONS_SUCCESS:
			const transactions = action.payload[0]
			return {
				...state,
				loadingTransactions: false,
				transactions: transactions.data,
				error: false,
			}
		case GET_OFFICE_TRANSACTIONS_FAIL:
			return {
				...state,
				loadingTransactions: false,
				error: action.payload,
			}
		case GET_ONE_TRANSACTION_OFFICE:
			return {
				...state,
				loadingTransaction: true,
			}
		case GET_ONE_TRANSACTION_OFFICE_SUCCESS:
			const singleTransaction = action.payload[0]
			const singleTransactionData = singleTransaction.data
			return {
				...state,
				loadingTransaction: false,
				error: false,
				oneTransactionOffice: singleTransactionData[0],
			}
		case GET_ONE_TRANSACTION_OFFICE_FAIL:
			return {
				...state,
				loadingTransaction: false,
				error: action.payload,
			}

		case UPDATE_OFFICE:
			return {
				...state,
				updatingOffice: true,
				updateOfficeError: null,
			}
		case UPDATE_OFFICE_SUCCESS:
			return {
				...state,
				updatingOffice: false,
				office: action.payload,
				updateOfficeError: null,
			}
		case UPDATE_OFFICE_ERROR:
			return {
				...state,
				updatingOffice: false,
				updateOfficeError: action.payload,
			}
		case GET_OFFICE_ADMINS:
			return {
				...state,
				loadingOfficeAdmins: true,
				officeAdmins: [],
				officeAdminsError: null,
			}
		case GET_OFFICE_ADMINS_SUCCESS:
			return {
				...state,
				loadingOfficeAdmins: false,
				officeAdmins: action.payload,
				officeAdminsError: null,
			}
		case GET_OFFICE_ADMINS_ERROR:
			return {
				...state,
				loadingOfficeAdmins: false,
				officeAdmins: [],
				officeAdminsError: action.payload,
			}
		case ASSIGN_OFFICE_ADMIN:
			return {
				...state,
				assigningOfficeAdmin: true,
				assignedOfficeAdmin: false,
				assignOfficeAdminError: null,
			}
		case ASSIGN_OFFICE_ADMIN_SUCCESS:
			return {
				...state,
				assigningOfficeAdmin: false,
				assignedOfficeAdmin: true,
				assignOfficeAdminError: null,
			}
		case ASSIGN_OFFICE_ADMIN_ERROR:
			return {
				...state,
				assigningOfficeAdmin: false,
				assignedOfficeAdmin: false,
				assignOfficeAdminError: action.payload,
			}
		case SCHEDULE_OFFICE_PICKUP:
			return {
				...state,
				schedulingOfficePickup: true,
				scheduledOfficePickup: false,
				scheduleOfficePickupError: null,
			}
		case SCHEDULE_OFFICE_PICKUP_SUCCESS:
			return {
				...state,
				schedulingOfficePickup: false,
				scheduledOfficePickup: true,
				scheduleOfficePickupError: null,
			}
		case SCHEDULE_OFFICE_PICKUP_ERROR:
			return {
				...state,
				schedulingOfficePickup: false,
				scheduledOfficePickup: false,
				scheduleOfficePickupError: action.payload,
			}
		case GET_OFFICE_CUSTOMERS:
			return {
				...state,
				loadingCustomers: true,
			}
		case GET_OFFICE_CUSTOMERS_SUCCESS:
			const customers = action.payload
			return {
				...state,
				loadingCustomers: false,
				customers: customers,
				error: false,
			}
		case GET_OFFICE_CUSTOMERS_FAIL:
			return {
				...state,
				loadingCustomers: false,
				error: action.payload,
			}
		case GET_ONE_CUSTOMER_OFFICE:
			return {
				...state,
				loadingCustomers: true,
			}
		case GET_ONE_CUSTOMER_OFFICE_SUCCESS:
			const singleCustomer = action.payload[0]
			const singleCustomerData = singleCustomer.data
			return {
				...state,
				loadingCustomer: false,
				error: false,
				oneCustomerOffice: singleCustomerData[0],
			}
		case GET_ONE_CUSTOMER_OFFICE_FAIL:
			return {
				...state,
				loadingCustomer: false,
				error: action.payload,
			}
		case STORE_PAYMENT_METHOD_OFFICE:
			return {
				...state,
				loadingAddition: true,
				cardAdded: false,
			}

		case STORE_PAYMENT_METHOD_OFFICE_SUCCESS:
			return {
				...state,
				paymentMethods: action.payload,
				cardAdded: true,
				loadingAddition: false,
				paymentError: null,
			}

		case STORE_PAYMENT_METHOD_OFFICE_FAIL:
			return {
				...state,
				paymentError: action.payload,
				loadingAddition: false,
				cardAdded: false,
			}
		case UPDATE_OFFICE_EMAIL:
			return {
				...state,
				updatingOfficeEmail: true,
				updateOfficeEmailError: null,
				updateOfficeEmailCompleted: false,
			}
		case UPDATE_OFFICE_EMAIL_SUCCESS:
			return {
				...state,
				updatingOfficeEmail: false,
				updateOfficeEmailCompleted: true,
				updateOfficeEmailError: null,
			}
		case UPDATE_OFFICE_EMAIL_ERROR:
			return {
				...state,
				updatingOfficeEmail: false,
				updateOfficeEmailCompleted: false,
				updateOfficeEmailError: action.payload,
			}
		case GET_OFFICE_BY_ADMIN_CODE:
			return {
				...state,
				loadingOffice: true,
			}
		case GET_OFFICE_BY_ADMIN_CODE_SUCCESS:
			return {
				...state,
				loadingOffice: false,
				office: action.payload,
			}
		case GET_OFFICE_BY_ADMIN_CODE_FAIL:
			return {
				...state,
				loadingOffice: false,
				office: action.payload,
			}
		case RESET_ADMIN_OFFICE:
			return {
				...state,
				office: null,
			}
		case INVITE_OFFICE_ADMIN:
			return {
				...state,
				loadingInvite: true,
			}
		case INVITE_OFFICE_ADMIN_SUCCESS:
			// console.log("SUCCESS", action.payload)
			return {
				...state,
				loadingInvite: false,
				invite: action.payload,
			}

		case INVITE_OFFICE_ADMIN_FAIL:
			// console.log("FAIL", action.payload)
			return {
				...state,
				loadingInvite: false,
				error: action.payload,
			}
		case RESET_OFFICE_INVITE:
			return {
				invite: null,
				loadingInvite: false,
			}
		default:
			return state
	}
}

export default officeReducer
