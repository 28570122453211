import React, { useEffect, useState } from 'react'
import { Header, Footer } from '../../../components'
import {
	getAdminCustomersBoxCount,
	getAllCustomers,
	getUser,
} from '../../../store/actions'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '../../../components/Elements'

const AdminCustomers = (props) => {
	const {
		onGetCustomers,
		loadingCustomers,
		customers,
		user,
		onGetUser,
		customersCount,
		onGetAllCustomers,
		loadingAllCustomers,
		allCustomers,
	} = props
	let navigate = useNavigate()
	const bounceLoaderColor = '#507f74'
	const paginationLimit = 30
	const [customersPage, setCustomersPage] = useState(1)
	const [selectedCustomer, setSelectedCustomer] = useState({})
	const [typedCustomerSearch, setTypedCustomerSearch] = useState('')
	const [customerSearchResults, setCustomerSearchResults] = useState([])
	const [customerFilter, setCustomerFilter] = useState({})

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const getCustomers = (page) => {
		if (user) {
			if (user.franchiseeSlug) {
				onGetCustomers({
					filter: {
						...customerFilter,
						franchiseeSlug: user.franchiseeSlug,
					},
					options: {
						sort: {
							lastUse: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			} else {
				onGetCustomers({
					filter: customerFilter,
					options: {
						sort: {
							lastUse: -1,
						},
						limit: paginationLimit,
						skip: (page - 1) * paginationLimit,
					},
				})
			}
		} else {
			onGetUser()
		}
	}

	useEffect(() => {
		if (typedCustomerSearch.length < 3) {
			setCustomerFilter({})
			setCustomersPage(1)
		} else {
			setCustomerFilter({
				$or: [
					{
						firstName: {
							$regex: typedCustomerSearch,
							$options: 'i',
						},
					},
					{ email: { $regex: typedCustomerSearch, $options: 'i' } },
					{
						lastName: {
							$regex: typedCustomerSearch,
							$options: 'i',
						},
					},
					{ phone: { $regex: typedCustomerSearch, $options: 'i' } },
				],
			})
			setCustomersPage(1)
		}
	}, [typedCustomerSearch, user])

	const customerPaginate = () => {
		getCustomers(customersPage)
	}

	useEffect(() => {
		customerPaginate()
	}, [customersPage, customerFilter])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div className='flex flex-col items-center justify-center mb-16'>
					<h1 className='font-header text-green-600 text-4xl md:text-[66px] mb-6 md:mb-11 mt-8'>
						Customers
					</h1>
					{customersCount > 0 && (
						<div className='text-center'>
							<span className='font-bold'>
								Number of total sign ups:
							</span>{' '}
							{customersCount}
						</div>
					)}
					<div className='mx-auto'>
						{!user.franchiseeSlug ? (
							<div className='mt-6 font-bold'>
								Search a customer email, phone, or name
							</div>
						) : (
							<div className='mt-6 font-bold'>
								Search a customer email or name
							</div>
						)}
						{allCustomers && customers.length > 0 && (
							<Input
								type='text'
								label=''
								name='item-search'
								placeholder='e.g. "bob@gmail.com"'
								value={typedCustomerSearch}
								className='bg-white border-2 border-gray-300'
								onChange={(e) => {
									setTypedCustomerSearch(
										e.target.value.toLowerCase()
									)
								}}
							/>
						)}
						<div>
							{customerSearchResults.map((customer) => {
								return (
									<div
										key={customer.phone}
										className='flex flex-row my-1'
									>
										<div className='w-[350px] overflow-x-hidden'>
											{customer.firstName}
											{` `}
											{customer.lastName}
											{` `}
											{user.franchiseeSlug ? null : (
												<>
													{customer.email}{' '}
													{customer.phone}
												</>
											)}
										</div>
										<Button
											className='items-center'
											size='xs'
											text='Select'
											onClick={() => {
												const urlCustomer =
													customer.userId
														? `/admin/customers/${customer.userId}`
														: `/admin/customers/${customer.phone}`
												navigate(urlCustomer)
											}}
										/>
									</div>
								)
							})}
						</div>

						{customers.length > 0 ? (
							<div className='px-10 my-6 pt-3 shadow-light-grey'>
								<div className='flex flex-row mb-2 mt-6'>
									<div className='font-bold w-[150px]'>
										Customer
									</div>
									<div className='font-bold w-[180px] ml-3 text-sm'>
										Last Use
									</div>
									<div className='font-bold w-[100px] ml-3 text-sm'>
										Current Box Count
									</div>
									<div className='font-bold w-[100px] ml-3 text-sm'>
										Packaging Prevented
									</div>
									<div className='font-bold w-[150px] ml-3 text-sm'>
										Email
									</div>
									{!user.franchiseeSlug && (
										<>
											<div className='font-bold w-[150px] ml-3 text-sm'>
												Phone
											</div>
										</>
									)}
									<div className='font-bold w-[100px] ml-3 text-sm'>
										Traffic Source
									</div>
								</div>
								{loadingCustomers && (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								{customers.map((customer) => {
									const lastUseDate = new Date(
										customer.lastUse
									)
									const lastUseDateString =
										lastUseDate.toLocaleString('default', {
											month: 'short',
											day: 'numeric',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
										})
									return (
										<div className='flex flex-row my-2 divide-y-2 divide-solid'>
											<div></div>
											{customer.userId ? (
												<div
													onClick={() => {
														const url = `/admin/customers/${customer.userId}`
														navigate(url)
													}}
													className='w-[150px] overflow-hidden cursor-pointer underline font-bold'
												>
													{customer.firstName}{' '}
													{customer.lastName}
												</div>
											) : (
												<div
													onClick={() => {
														const url = `/admin/customers/${customer.phone}`
														navigate(url)
													}}
													className='w-[150px] overflow-hidden cursor-pointer underline font-bold'
												>
													{customer.firstName}{' '}
													{customer.lastName}
												</div>
											)}

											<div className='w-[180px] ml-3'>
												{lastUseDateString}
											</div>
											{customer.getTotalPackagingPrevented ? (
												<>
													<div className='w-[100px] ml-3'>
														{
															customer
																.getTotalPackagingPrevented
																.currentPackagingOut
														}
													</div>
													<div className='w-[100px] ml-3'>
														{
															customer
																.getTotalPackagingPrevented
																.totalPackagingPrevented
														}
													</div>
												</>
											) : (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
											<div className='w-[150px] overflow-hidden ml-3'>
												{customer.email}
											</div>
											{!user.franchiseeSlug && (
												<>
													<div className='w-[150px] ml-3'>
														{customer.phone}
													</div>
												</>
											)}
											<div className='w-[100px] ml-3'>
												{customer.trafficSource}
											</div>
										</div>
									)
								})}
							</div>
						) : (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						)}
						<div className='flex flex-row justify-start'>
							{customersPage > 1 && (
								<div
									className='underline font-bold mr-6 cursor-pointer'
									onClick={() => {
										setCustomersPage(customersPage - 1)
									}}
								>
									Previous {paginationLimit} customers
								</div>
							)}
							{customers.length > paginationLimit && (
								<div
									className='underline font-bold cursor-pointer'
									onClick={() => {
										setCustomersPage(customersPage + 1)
									}}
								>
									Next {paginationLimit} customers
								</div>
							)}
						</div>
					</div>
				</div>
				<Footer />
			</>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ Admin, User }) => ({
	customers: Admin.customers,
	customersCount: Admin.customersCount,
	loadingCustomers: Admin.loadingCustomers,
	user: User.user,
	allCustomers: Admin.allCustomers,
	loadingAllCustomers: Admin.loadingAllCustomers,
})

const mapDispatchToProps = (dispatch) => ({
	onGetCustomers: (payload) => dispatch(getAdminCustomersBoxCount(payload)),
	onGetAllCustomers: (payload) => dispatch(getAllCustomers(payload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminCustomers)
