import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { HomepageSearchBar, LocationSearchBar } from '../Elements'
import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { useJsApiLoader } from '@react-google-maps/api'
import { googleMapsLibraries } from '../../helpers/googleMapsAPI'

const Hero = (props) => {
	const { user } = props
	const [showSearchBar, setShowSearchBar] = useState(false)
	const [locationWarning, setLocationWarning] = useState(false)
	const bounceLoaderColor = '#507f74'
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: googleMapsLibraries,
	})

	useEffect(() => {
		if (window.google && window.google.maps) {
			setShowSearchBar(true)
		}
	}, [window.google?.maps, isLoaded])

	const handleLocationWarningChange = (newWarning) => {
		setLocationWarning(newWarning)
	}

	return (
		<>
			<div className='h-auto w-full bg-yellow-400 pt-4 flex flex-col lg:flex-row justify-center items-center relative z-10'>
				<div className='lg:w-1/2'>
					<h1 className='font-header text-green-600 text-2xl sm:text-[2.625rem] xl:text-5xl mx-12 text-left lg:ml-16 lg:mt-2 mt-4 sm:mt-6 mb-3 lg:mb-0 sm:leading-[2.5rem] lg:leading-[2.625rem]'>
						Takeout and delivery in reusable containers you can
						return at your door.
					</h1>
					<div className='mx-12 mt-12 lg:mt-10 -mb-12 hidden lg:inline-block'>
						{showSearchBar ? (
							<LocationSearchBar
								size='sm'
								className=''
								onLocationWarningChange={
									handleLocationWarningChange
								}
							/>
						) : (
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							/>
						)}
						<p className='text-sm transform translate-x-8 mt-2'>
							<Link
								to='/login'
								className='font-semibold underline text-green-600'
							>
								Login
							</Link>{' '}
							for your saved address
						</p>
					</div>
				</div>
				<div className='my-4 mx-16 md:mx-36 lg:mx-8 lg:w-1/2 max-w-[800px] lg:max-w-none'>
					<img
						className='bg-cover'
						src='/images/HomepageHeroContainers.png'
						alt='a picture of deliver zero containers'
					/>
				</div>
				{showSearchBar ? (
					<HomepageSearchBar
						size='sm'
						className='lg:hidden'
					/>
				) : (
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					/>
				)}
			</div>
		</>
	)
}
const mapStateToProps = ({ User }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Hero)
