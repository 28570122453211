import React from 'react'
import { Button } from '../Elements'

const RewardPointsCard = ({ rewardPoints = null }) => {
	return (
		<div className='card '>
			<div className='text-center justify-center items-center'>
				<h3 className='mt-2 mb-3 block font-header text-center text-2xl sm:text-3xl text-green-400'>
					Total Reward Points
				</h3>
				<div className='mt-5 flex flex-col text-center items-center justify-center'>
					<div className='mb-3 text-5xl rounded-[64px] font-header px-9 w-min bg-gray-100 text-center m-auto py-3 text-orange-600'>
						{rewardPoints}
					</div>
					<Button
						link='/rewards-marketplace'
						className='px-10 mt-5 shadow-light-grey'
						text='Redeem Rewards'
						size='sm'
					/>
				</div>
			</div>
		</div>
	)
}

export default RewardPointsCard
