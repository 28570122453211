import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/Elements'
import { getUser, getNodes, updateLead, getLead } from '../../../store/actions'
import { Footer, Header } from '../../../components'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { BounceLoaderCentered } from '../../../components/Elements'

const SignUpPage5 = (state) => {
	const { user, onGetUser, onGetLead, lead } = state

	let navigate = useNavigate()

	const [loadingTimeout, setLoadingTimeout] = useState(null)

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.restaurantAdmin) {
			onGetLead({ slug: user.restaurantAdmin })

			// start a timer to reload lead after 3 seconds
			setLoadingTimeout(
				setTimeout(
					() => onGetLead({ slug: user.restaurantAdmin }),
					2000
				)
			)
		}
	}, [user])

	useEffect(() => {
		// clear the loading timeout when component unmounts
		return () => clearTimeout(loadingTimeout)
	}, [loadingTimeout])

	useEffect(() => {
		if (lead && !lead.signUpComplete && user && user.restaurantAdmin) {
			// check if the lead lands on the success page but signUpComplete is false
			setLoadingTimeout(
				setTimeout(
					() => onGetLead({ slug: user.restaurantAdmin }),
					2000
				)
			)
		}
	}, [lead])

	if (user) {
		if (user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<div
						id='signup'
						className='p-0 m-0 sm:p-10 lg:p-20'
						style={{
							backgroundImage: `url("https://i.imgur.com/N8Tqgz8.jpg")`,
							height: 'full',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
						}}
					>
						<div className='z-20 flex flex-col p-5 mx-auto max-w-[500px] bg-green-600 shadow-light-grey rounded-sm sm:max-w-[1200px]'>
							<div className='bg-white shadow-light-grey rounded-xl w-full min-h-[700px] overflow-y-auto scrollbar'>
								{lead && lead.signUpComplete ? (
									<div>
										<h1 className='font-header text-green-600 text-4xl mb-3 mt-6 text-center'>
											Thank you for joining DeliverZero!
											🎉
										</h1>
										<h1 className='mb-3 mt-6 text-center'>
											You are going to receive a welcome
											email with instructions on how to
											add DeliverZero to your online
											ordering platforms.
										</h1>
										<h1 className='mb-3 mt-6 text-center'>
											We will drop containers off within
											10 business days. DeliverZero will
											contact you to coordinate.
										</h1>
										<h1 className='mb-3 mt-6 text-center'>
											If you need help with onboarding,
											you can schedule an onboarding
											support call{' '}
											<a
												href={
													'https://calendly.com/damion-deliverzero/deliverzero-ob-boulder'
												}
												target='_blank'
												className='underline font-semibold text-green-600'
											>
												here
											</a>
										</h1>
										<div className='flex w-full justify-center'>
											<Button
												className='mt-10 w-[200px]'
												size='sm'
												text='Merchant Dashboard'
												link='/restaurant-admin/home'
											/>
										</div>
									</div>
								) : (
									<div>
										<h1 className='mb-3 mt-6 text-center'>
											Please allow a few seconds as we
											finalize your registration!
										</h1>
										<BounceLoaderCentered />
									</div>
								)}
							</div>
						</div>
					</div>
					<Footer />
				</div>
			)
		} else {
			navigate('/')
		}
	} else {
		return (
			<>
				<Header />
				<BounceLoaderCentered container='page' />
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ User, Leads }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	loadingLead: Leads.loading,
	leadError: Leads.error,
	updatedLead: Leads.updatedLead,
	updatingLead: Leads.updatingLead,
	updateLeadError: Leads.updateLeadError,
	lead: Leads.lead,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onUpdateLead: (payload) => dispatch(updateLead(payload)),
	onGetLead: (payload) => dispatch(getLead(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage5)
