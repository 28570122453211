import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Header, Footer } from '../../../components'
import { SelectGroup } from '../../../components/Elements'
import {
	getUser,
	getAllRAASMerchants,
	runAggregation,
} from '../../../store/actions'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const AdminRaas = (state) => {
	const {
		user,
		loadingUser,
		raasMerchants,
		onGetUser,
		onGetRAASMerchants,
		onGetAggregator,
		aggregationResult,
		aggregationError,
		runningAggregation,
	} = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const dispatch = useDispatch()
	const [merchantsList, setMerchantsList] = useState([])
	const [filteredMonths, setFilteredMonths] = useState([])

	useEffect(() => {
		onGetUser()
		onGetRAASMerchants()
		onGetAggregator()
	}, [onGetUser])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	useEffect(() => {
		if (raasMerchants) {
			dispatch(
				runAggregation({
					raasMerchants,
					functionName: 'getMerchantTotalPackagingOut',
				})
			)
		}
		// if (aggregationError) {
		//     console.log("aggregationError", aggregationError)
		// }
	}, [raasMerchants])

	useEffect(() => {
		if (
			aggregationResult &&
			aggregationResult.getMerchantTotalPackagingOut
		) {
			setMerchantsList(aggregationResult.getMerchantTotalPackagingOut)
		}
	}, [aggregationResult])

	function sortDates(dates) {
		return dates.sort((a, b) => {
			const dateA = new Date(a.replace('-', ' 1, '))
			const dateB = new Date(b.replace('-', ' 1, '))
			return dateB - dateA
		})
	}

	useEffect(() => {
		if (merchantsList) {
			const allMonths =
				merchantsList[0]?.chargesData?.map(
					(charge) => charge.monthString
				) || []
			const sortedMonths = sortDates(allMonths)
			setFilteredMonths(sortedMonths)
		}
	}, [merchantsList])

	const uniqueMonthsSet = new Set()
	merchantsList[0]?.chargesData?.forEach((charge) => {
		uniqueMonthsSet.add(charge.monthString)
	})

	const monthOptions = Array.from(uniqueMonthsSet).map((monthString) => ({
		label: monthString.replace('-', ' '),
		value: monthString,
	}))

	const allMonths =
		merchantsList[0]?.chargesData?.map((charge) => charge.monthString) || []
	const allMonthsOption = { label: 'All', value: allMonths }
	const allOptions = [allMonthsOption, ...monthOptions]

	const defaultColumn = {
		monthString: '',
		totalCharge: 0,
		totalUsage: 0,
	}

	console.log(aggregationResult)
	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div className='flex flex-col justify-between h-screen-no-header w-screen'>
					<div className='flex flex-col items-center justify-center mb-16 w-screen'>
						<div
							className='underline font-bold my-4 cursor-pointer'
							onClick={() => {
								navigate('/admin/home')
							}}
						>
							Go to Admin Home
						</div>
						<h1 className='font-header text-green-600 text-4xl md:text-[66px] mb-6 md:mb-11 mt-8'>
							RAAS Dashboard
						</h1>
						<div>
							<SelectGroup
								label='Filter by Month'
								placeholder='Select...'
								name='month'
								options={allOptions}
								onChange={(e) => {
									const selectedMonth = e.target.value
									setFilteredMonths(selectedMonth)
								}}
							/>
						</div>
						<div className='mx-auto'>
							{merchantsList.length > 0 ? (
								<div className='max-w-[500px] sm:max-w-[1200px] max-h-[900px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col m-auto rounded-xl px-8 py-2 mt-6 mb-8 scrollbar'>
									<div className='flex flex-row min-h-[60px]'>
										<div className='font-bold min-w-[200px] font-header sticky left-0 bg-white z-10'>
											Merchant
										</div>
										<div className='font-bold min-w-[150px] font-header bg-gray-200 ml-2'>
											Total Packaging Prevented
										</div>
										{filteredMonths.map((monthString) => {
											if (
												filteredMonths.includes(
													monthString
												)
											) {
												return (
													<React.Fragment
														key={monthString}
													>
														<div className='flex flex-col'>
															<div className='h-[50%] text-center'>
																{monthString.replace(
																	'-',
																	' '
																)}
															</div>
															<div className='flex flex-row'>
																<div className='font-bold min-w-[150px] font-header text-center'>
																	Charges
																</div>
																<div className='font-bold min-w-[150px] font-header text-center bg-gray-200 min-h-[30px]'>
																	Usage
																</div>
															</div>
														</div>
													</React.Fragment>
												)
											} else {
												return null
											}
										})}
									</div>
									{loadingUser && (
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									)}
									{merchantsList.map((merchant) => {
										return (
											<div
												className='flex flex-row divide-solid min-h-[30px]'
												key={merchant.slug}
											>
												<div className='min-w-[200px] sticky left-0 bg-white z-10'>
													{merchant.name}
												</div>
												<div className='min-w-[150px] bg-gray-200 ml-2'>
													{merchant.transactionsData
														? merchant
																.transactionsData
																.totalPackagingPrevented
														: '0'}
												</div>
												{/* {merchant.chargesData?.map((charge) => {
                                        if (filteredMonths.includes(charge.monthString)) {
                                            return (
                                                <React.Fragment key={charge.monthString}>
                                                <div className='min-w-[150px] text-center min-h-[30px]'>
                                                    ${charge.totalCharge ? charge.totalCharge.toFixed(2) : '0'}
                                                </div>
                                                <div className='min-w-[150px] text-center bg-gray-200 h-full min-h-[30px]'>
                                                    {charge.totalUsage ? charge.totalUsage : '0'}
                                                </div>
                                                </React.Fragment>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })} */}
												{filteredMonths.map(
													(monthString) => {
														const charge =
															merchant.chargesData?.find(
																(charge) =>
																	charge.monthString ===
																	monthString
															)
														return (
															<React.Fragment
																key={
																	monthString
																}
															>
																<div className='min-w-[150px] text-center min-h-[30px]'>
																	$
																	{charge
																		? charge.totalCharge.toFixed(
																				2
																		  )
																		: '0.00'}
																</div>
																<div className='min-w-[150px] text-center bg-gray-200 h-full min-h-[30px]'>
																	{charge
																		? charge.totalUsage
																		: '0'}
																</div>
															</React.Fragment>
														)
													}
												)}
											</div>
										)
									})}
								</div>
							) : (
								<>
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								</>
							)}
						</div>
					</div>
					<Footer />
				</div>
			</>
		)
	}
}

const mapStateToProps = ({ User, RAAS, Aggregation }) => ({
	user: User.user,
	loadingUser: User.loading,
	raasMerchants: RAAS.raasMerchants,
	runningAggregation: Aggregation.runningAggregation,
	aggregationError: Aggregation.aggregationError,
	aggregationResult: Aggregation.aggregationResult,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetRAASMerchants: () => dispatch(getAllRAASMerchants()),
	onGetAggregator: () => dispatch(runAggregation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRaas)
