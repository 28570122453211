import {
	GET_PLATFORMS_FAIL,
	GET_PLATFORMS_SUCCESS,
	GET_PLATFORM_BY_KEY_FAIL,
	GET_PLATFORM_BY_KEY_SUCCESS,
	RESET_PLATFORM,
	TRIGGER_OTTER,
	TRIGGER_OTTER_FAIL,
	TRIGGER_OTTER_SUCCESS,
} from './actionTypes'

const initialState = {
	platform: null,
	platforms: {},
	error: null,
}

const platforms = (state = initialState, action) => {
	switch (action.type) {
		case GET_PLATFORMS_SUCCESS:
			return {
				...state,
				loadingPlatforms: false,
				platforms: action.payload,
			}

		case GET_PLATFORMS_FAIL:
			return {
				...state,
				loadingPlatforms: false,
				error: action.payload,
			}

		case GET_PLATFORM_BY_KEY_SUCCESS:
			return {
				...state,
				platform: action.payload,
			}

		case GET_PLATFORM_BY_KEY_FAIL:
			return {
				...state,
				error: action.payload,
			}

		case RESET_PLATFORM:
			return {
				...state,
				platform: null,
			}

		case TRIGGER_OTTER:
			return {
				...state,
				loadingPlatforms: true,
				platform: null,
				error: action.payload,
			}

		case TRIGGER_OTTER_SUCCESS:
			return {
				...state,
				platform: action.payload,
				loadingPlatforms: false,
				error: null,
			}

		case TRIGGER_OTTER_FAIL:
			return {
				...state,
				error: action.payload,
				loadingPlatforms: false,
				platform: null,
			}

		default:
			return state
	}
}

export default platforms
