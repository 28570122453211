import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
	getUser,
	getNodes,
	updateUser,
	createStop,
	updateStop,
	deleteStop,
	getContainers,
	getAllLogistics,
	resetSuccess,
	getTransactions,
	getPendingPickups,
	getIncompleteStops,
	getNewRestaurantStops,
	getMarket,
} from '../../../store/actions'
import { Footer, Header } from '../../../components'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	SelectGroup,
	TextArea,
} from '../../../components/Elements'
import { FormGroup } from '../../../components/Elements/index'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../model/nodes'
import { MdClear } from 'react-icons/md'

const LogisticsPackingList = (props) => {
	const {
		className = '',
		inputBackgroundColor = 'white',
		nameSuffix = '',
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onUpdateUser,
		onGetUser,
		onGetNodes,
		nodes,
		updatingNode,
		loadingNodes,
		containers,
		onGetContainers,
		onCreateStop,
		onUpdateStop,
		onDeleteStop,
		stopsError,
		logisticsStops,
		loadingStops,
		stopsSuccess,
		stops,
		onGetAllStops,
		onGetTransactions,
		transactions,
		loadingTransactions,
		transactionsError,
		transactionsCount,
		pendingPickup,
		pickupSuccess,
		pickupLoading,
		pickupError,
		pendingPickups,
		onGetPendingPickups,
		onGetIncompleteStops,
		incompleteStops,
		newRestaurantStops,
		onGetNewRestaurantStops,
		nodeMarket,
		onGetMarket,
		availPartners,
		onResetSuccess,
		...newProps
	} = props

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [filter, setFilter] = useState({})
	const [date, setDate] = useState('')
	const [page, setPage] = useState(1)
	const [stop, setStop] = useState(null)
	const [selectedStop, setSelectedStop] = useState(null)
	const [instructions, setInstructions] = useState('')
	const [showNodeChoices, setShowNodeChoices] = useState(false)
	const [formError, setFormError] = useState('')
	const [searchedAddress, setSearchedAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState(null)
	const [geoCodedLat, setGeoCodedLat] = useState(null)
	const [geoCodedLng, setGeoCodedLng] = useState(null)
	const [addressError, setAddressError] = useState(false)
	const [locationName, setLocationName] = useState('')
	const [market, setMarket] = useState(null)
	const [stopType, setStopType] = useState(null)
	const [locationType, setLocationType] = useState(null)
	const [containerRequest, setContainerRequest] = useState({})
	const [initialLoad, setInitialLoad] = useState(true)
	const [customerPickups, setCustomerPickups] = useState([])
	const [availRLP, setAvailRLP] = useState([])
	const [warehouseNewShipment, setWarehouseNewShipment] = useState(false)
	const [endpoint, setEndpoint] = useState('washhub')

	const geocodeAddress = async (addy) => {
		setAddressError(false)
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode(
			{
				address: addy,
			},
			function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results.length > 0
				) {
					setGeoCodedAddress(results[0].formatted_address)
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng())
				} else {
					setAddressError(true)
				}
			}
		)
	}

	const handleAddressChange = async (newAddress) => {
		setFormError('')
		if (newAddress.target) {
			setSearchedAddress(newAddress.target.value)
			geocodeAddress(newAddress.target.value)
		} else if (newAddress.formatted_address) {
			setSearchedAddress(newAddress.formatted_address)
			geocodeAddress(newAddress.formatted_address)
		}
	}

	const handleAddressSubmit = () => {
		setFormError('')
		setAddressError(false)
		if (locationName === '') {
			setFormError('Specify the stop location name')
		} else {
			setStop(() => {
				let tempObj = {}
				if (geoCodedAddress) {
					;(tempObj['name'] = locationName),
						(tempObj['address'] = geoCodedAddress),
						(tempObj['type'] = locationType),
						(tempObj['stopType'] = stopType)
				}
				return tempObj
			})
		}
	}

	useEffect(() => {
		onGetUser()
		onResetSuccess()
	}, [onGetUser])

	useEffect(() => {
		onGetNodes([
			{
				type: {
					$nin: [NodeTypes.none],
				},
			},
		])
	}, [onGetNodes])

	useEffect(() => {
		const filter = {}

		if (market) {
			filter.market = { $in: [market] }
		}
		if (date) {
			filter.date = { $in: [date] }
		}

		onGetIncompleteStops(market)
		onGetNewRestaurantStops(market)
		onGetAllStops({
			filter: filter,
			options: {
				sort: {
					timestamp: -1,
				},
				limit: paginationLimit,
				skip: (page - 1) * paginationLimit,
			},
		})
		setInitialLoad(false)
	}, [date, market, stopsSuccess])

	useEffect(() => {
		const filter = {
			enabled: true,
		}
		if (market) {
			filter.markets = { $in: [market] }
		}
		onGetContainers([filter])
	}, [onGetContainers, market])

	useEffect(() => {
		// console.log("locationType", locationType)
		if (
			locationType &&
			!(locationType === 'address' || locationType === 'customer')
		) {
			if (locationType === 'other') {
				onGetNodes([
					filter,
					{
						type: {
							$in: ['whole-foods', 'museum'],
						},
					},
				])
			} else if (locationType === 'rlp') {
				onGetNodes([
					filter,
					{
						$or: [
							{
								type: {
									$in: ['rlp'],
								},
							},
							{
								slug: 'nudefoods-return-bin',
							},
						],
					},
				])
			} else {
				onGetNodes([
					filter,
					{
						type: {
							$in: [locationType],
						},
					},
				])
			}
		} else if (locationType === 'customer') {
			// console.log('date', date)
			const localDate = new Date(date + 'T00:00:00')
			const start = new Date(localDate)
			const end = new Date(localDate)
			start.setDate(start.getDate() - 1)
			end.setDate(end.getDate() + 1)
			end.setHours(0, 0, 0, 0)
			// console.log(start, end)
			setStopType('pick')

			const filter = [
				{
					type: 'boxesIn',
				},
				{
					reverseLogisticsPartner: { $in: ['flot', 'deliverzero'] },
				},
				{
					returnAtDoor: true,
				},
				{
					timestamp: {
						$gte: start,
						$lt: end,
					},
				},
			]
			// eventually remove - still uses state, should all go to pending_pickups
			if (market) {
				filter.push({
					'user.state': { $in: [market] },
				})
			}
			onGetTransactions({
				filter: { $and: filter },
				options: {
					sort: {
						timestamp: -1,
					},
					limit: 100,
					skip: 0,
				},
			})
			////////////////////////////////////////
			onGetPendingPickups([
				{
					timestamp: {
						$gte: start,
						$lt: end,
					},
					market: market,
				},
			])
		} else {
			onGetNodes([filter])
		}
	}, [filter, locationType, market, date])

	useEffect(() => {
		const tomorrow = new Date()
		tomorrow.setDate(tomorrow.getDate() + 1)

		const yyyy = tomorrow.getFullYear()
		const mm = String(tomorrow.getMonth() + 1).padStart(2, '0')
		const dd = String(tomorrow.getDate()).padStart(2, '0')

		const formattedDate = `${yyyy}-${mm}-${dd}`

		setDate(formattedDate)
	}, [])

	const handleSubmitStop = () => {
		const currentDate = new Date()
		currentDate.setDate(currentDate.getDate())
		const year = currentDate.getFullYear()
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const day = String(currentDate.getDate()).padStart(2, '0')
		const formattedDate = `${year}-${month}-${day}`

		const stopId = uuidv4()
		const totalQty = Object.values(containerRequest).reduce(
			(acc, value) => acc + value,
			0
		)

		if (!stop) {
			setFormError('Assign a location')
			// } else if (date && date <= formattedDate) {
			//     setFormError('Specify a valid future date')
		} else if (
			totalQty < 1 &&
			stopType === 'drop' &&
			!['warehouse', 'washhub', 'customer'].includes(locationType)
		) {
			setFormError('Specify a quantity of containers for drop off')
		} else if (!stop.name) {
			setFormError('Specify stop name')
		} else if (!stop.address) {
			setFormError('Specify stop address')
		} else if (!locationType) {
			setFormError('Specify stop location type')
		} else {
			setFormError('')
			const stopPayload = {
				name: stop.name,
				slug: stop.slug,
				address: stop.address,
				note: instructions,
				products: containerRequest,
				boxCount: totalQty,
				date: date,
				market: market,
				stopType: stopType,
				locationType: locationType,
				user: user.firstName,
				stopId: stopId,
				warehouseNewShipment: warehouseNewShipment,
				endpoint: endpoint,
			}
			// console.log(stopPayload)
			onCreateStop(stopPayload)
		}
	}

	const handleCustomerSubmit = async () => {
		const currentDate = new Date()
		currentDate.setDate(currentDate.getDate())
		const year = currentDate.getFullYear()
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const day = String(currentDate.getDate()).padStart(2, '0')
		const formattedDate = `${year}-${month}-${day}`

		if (customerPickups.length < 1) {
			setFormError('Select at least one customer pick up')
			// } else if (date && date <= formattedDate) {
			//     setFormError('Specify a valid future date')
		} else if (stopType === 'drop') {
			setFormError('Customer stop type must be pick up')
		} else {
			setFormError('')
			for (const pickup of customerPickups) {
				const stopsToAdd = {
					...pickup,
					date: date,
					market: market,
				}
				// console.log("stopsToAdd", stopsToAdd)
				await onCreateStop(stopsToAdd)
			}
		}
	}

	useEffect(() => {
		if (stopsSuccess) {
			setStop(null)
			setContainerRequest({})
			setInstructions('')
		}
	}, [stopsSuccess])

	const handleDateInputChange = (e) => {
		setCustomerPickups([])
		setFormError('')
		const selectedDate = e.target.value

		if (selectedDate) {
			setDate(selectedDate)
		} else {
			setDate(null)
		}
	}

	useEffect(() => {
		if (containers && containers.length > 0) {
			let object = {}
			for (const container of containers) {
				object[container.containerId] = 0
			}
			setContainerRequest(object)
		}
	}, [containers])

	useEffect(() => {
		setMarket(nodeMarket)
		setAvailRLP(availPartners)
	}, [nodeMarket, availPartners])

	const addContainers = (containerId) => {
		let object = containerRequest
		setContainerRequest((prevState) => ({
			...prevState,
			[containerId]: object[containerId] + 1,
		}))
	}

	const subtractContainers = (container) => {
		let object = containerRequest
		if (object[container] > 0) {
			setContainerRequest((prevState) => ({
				...prevState,
				[container]: object[container] - 1,
			}))
		}
	}

	const handleQuantityChange = (containerId, quantity) => {
		setContainerRequest((prevState) => ({
			...prevState,
			[containerId]: parseInt(quantity, 10) || 0,
		}))
	}

	const handleCustomerCheckbox = (event, transaction) => {
		const isChecked = event.target.checked
		const stopId = transaction._id.toString()

		const stopPayload = {
			name: `${transaction.user.firstName} ${transaction.user.lastName} (${transaction.user.phone})`,
			address: transaction.user.pickupAddress,
			note: transaction.pickupNote,
			products: {},
			boxCount: transaction.boxCount,
			date: date,
			market: market,
			stopType: stopType,
			user: transaction.user,
			stopId: stopId,
			locationType: locationType,
		}

		setCustomerPickups((prevCustomerPickups) =>
			isChecked
				? [...prevCustomerPickups, stopPayload]
				: prevCustomerPickups.filter(
						(item) => item.stopId !== stopPayload.stopId
				  )
		)
	}

	const handleIncompleteCheckbox = async (event, stop) => {
		const isChecked = event.target.checked
		delete stop.status

		const stopPayload = {
			...stop,
			date: date,
		}
		if (isChecked) {
			await onCreateStop(stopPayload)
		} else {
			return
		}
	}

	const handleNewRestaurantCheckbox = async (event, stop) => {
		const isChecked = event.target.checked

		if (isChecked) {
			setLocationType('restaurant')
			setStopType('drop')
			// console.log("stop", stop)
			// onGetMarket(stop.fullAddress)
			setStop({
				name: stop.fullDocument.restaurantName,
				slug: stop.fullDocument.slug,
				address: stop.fullAddress,
				type: 'restaurant',
				stopType: 'drop',
			})
			setSelectedStop(stop)
		} else {
			return
		}
	}

	const handleGentlyRequest = async () => {
		navigate('/admin/dropoff-create/gently')
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div className='h-full w-full'>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate('/admin/home')
						}}
					>
						&#8592; Go to Admin Home
					</div>
					<div
						className='underline font-bold my-4 cursor-pointer ml-6'
						onClick={() => {
							navigate(-1)
						}}
					>
						&#8592; Go Back
					</div>

					{/* request a stop form */}
					<div className='px-12'>
						<div className='flex container flex-col justify-center max-w-[800px] mb-4 mx-auto'>
							<h1 className='font-header text-green-600 text-4xl mb-7 mt-5 text-center'>
								Request a Stop
							</h1>
							<div>
								<div className='w-full flex justify-center items-center'>
									<div className='flex flex-row'>
										<label className='font-bold text-md mr-6'>
											Date:
										</label>
										<input
											type='date'
											value={date || ''}
											onChange={handleDateInputChange}
										/>
									</div>
								</div>
								<div className='w-full flex justify-center items-center'>
									<SelectGroup
										label=''
										value={market}
										placeholder='Select Market...'
										className='h-12 mt-3 min-w-[300px]'
										onChange={(e) => {
											const selectedValue = e.target.value
											if (
												selectedValue ===
												'Select Market...'
											) {
												setMarket(null)
											} else {
												setMarket(selectedValue)
												setFormError('')
												setCustomerPickups([])
											}
										}}
										options={[
											{
												label: 'Denver/Boulder',
												value: 'CO',
											},
											{ label: 'NYC', value: 'NY' },
											{ label: 'LA', value: 'LA' },
											{ label: 'Bay Area', value: 'SF' },
										]}
									/>
								</div>
								<div className='w-full flex justify-center items-center mb-3'>
									<SelectGroup
										label=''
										placeholder='Select Stop Type...'
										className='h-12 mt-3 min-w-[300px]'
										value={stopType}
										onChange={(e) => {
											setStopType(e.target.value)
											setFormError('')
										}}
										options={[
											{
												label: 'Drop Off',
												value: 'drop',
											},
											{ label: 'Pick Up', value: 'pick' },
										]}
									/>
								</div>
								{stopType === 'pick' && (
									<>
										{market === 'LA' ? (
											<>
												<div className='w-full flex justify-center items-center mb-3'>
													<SelectGroup
														label=''
														placeholder='Select Location Type...'
														className='h-12 min-w-[300px]'
														onChange={(e) => {
															setLocationType(
																e.target.value
															)
															setFormError('')
															setStop(null)
														}}
														options={[
															{
																label: 'Restaurant',
																value: 'restaurant',
															},
															{
																label: 'Address',
																value: 'address',
															},
															// { label: 'Wash Hub', value: 'washhub' },
															{
																label: 'Customer',
																value: 'customer',
															},
															// { label: 'Office', value: 'office' },
															{
																label: 'Bin',
																value: 'bin',
															},
															// { label: 'Logistics Partner', value: 'rlp' },
															// { label: 'Other (WFM, DMNS)', value: 'other' },
														]}
													/>
												</div>
												{locationType &&
													locationType !==
														'customer' &&
													locationType !== 'bin' && (
														<div className='w-full flex justify-center items-center'>
															<SelectGroup
																label=''
																value={endpoint}
																className='h-12 mt-3 min-w-[300px]'
																onChange={(
																	e
																) => {
																	const selectedValue =
																		e.target
																			.value
																	if (
																		selectedValue !==
																		'Select Endpoint...'
																	) {
																		setEndpoint(
																			e
																				.target
																				.value
																		)
																		setFormError(
																			''
																		)
																	}
																}}
																options={[
																	{
																		label: 'Endpoint: Washhub',
																		value: 'washhub',
																	},
																	{
																		label: 'Endpoint: Warehouse/Storage',
																		value: 'warehouse',
																	},
																]}
															/>
														</div>
													)}
											</>
										) : (
											<div className='w-full flex justify-center items-center mb-3'>
												<SelectGroup
													label=''
													placeholder='Select Location Type...'
													className='h-12 min-w-[300px]'
													onChange={(e) => {
														setLocationType(
															e.target.value
														)
														setFormError('')
														setStop(null)
													}}
													options={[
														{
															label: 'Restaurant',
															value: 'restaurant',
														},
														{
															label: 'Address',
															value: 'address',
														},
														{
															label: 'Wash Hub',
															value: 'washhub',
														},
														{
															label: 'Customer',
															value: 'customer',
														},
														{
															label: 'Office',
															value: 'office',
														},
														{
															label: 'Bin',
															value: 'bin',
														},
														{
															label: 'Logistics Partner',
															value: 'rlp',
														},
														{
															label: 'Other (WFM, DMNS)',
															value: 'other',
														},
													]}
												/>
											</div>
										)}
									</>
								)}
								{stopType === 'drop' && (
									<>
										{market === 'LA' ? (
											<div className='w-full flex justify-center items-center mb-3'>
												<SelectGroup
													label=''
													placeholder='Select Location Type...'
													className='h-12 min-w-[300px]'
													onChange={(e) => {
														setLocationType(
															e.target.value
														)
														setFormError('')
														setStop(null)
													}}
													options={[
														{
															label: 'Restaurant',
															value: 'restaurant',
														},
														{
															label: 'Address',
															value: 'address',
														},
														// { label: 'Wash Hub', value: 'washhub' },
														// { label: 'Warehouse', value: 'warehouse' },
														// { label: 'Office', value: 'office' },
														// { label: 'Logistics Partner', value: 'rlp' },
														// { label: 'Other (WFM, DMNS)', value: 'other' },
													]}
												/>
											</div>
										) : (
											<div className='w-full flex justify-center items-center mb-3'>
												<SelectGroup
													label=''
													placeholder='Select Location Type...'
													className='h-12 min-w-[300px]'
													onChange={(e) => {
														setLocationType(
															e.target.value
														)
														setFormError('')
														setStop(null)
													}}
													options={[
														{
															label: 'Restaurant',
															value: 'restaurant',
														},
														{
															label: 'Address',
															value: 'address',
														},
														{
															label: 'Wash Hub',
															value: 'washhub',
														},
														{
															label: 'Warehouse',
															value: 'warehouse',
														},
														{
															label: 'Office',
															value: 'office',
														},
														{
															label: 'Logistics Partner',
															value: 'rlp',
														},
														{
															label: 'Other (WFM, DMNS)',
															value: 'other',
														},
													]}
												/>
											</div>
										)}
									</>
								)}

								{stopType && market && locationType && (
									<>
										<div className='w-full mx-auto'>
											{locationType === 'address' ? (
												<>
													<div
														className={`relative flex w-full items-center md:min-w-[500px] max-w-[720px] ${className} px-4`}
													>
														<FormGroup
															className='h-auto w-full items-center justify-around my-5'
															header
														>
															{loadingUser ? (
																<BounceLoader
																	className='m-auto'
																	color={
																		bounceLoaderColor
																	}
																></BounceLoader>
															) : (
																<>
																	<Input
																		placeholder='Enter restaurant/company name'
																		className={`relative bg-${inputBackgroundColor} pl-8 !-mb-4`}
																		value={
																			locationName ||
																			null
																		}
																		onChange={(
																			e
																		) => {
																			setLocationName(
																				e
																					.target
																					.value
																			)
																			setFormError(
																				''
																			)
																			onResetSuccess()
																		}}
																	/>
																	<Input
																		placeholder='Enter address'
																		className={`relative bg-${inputBackgroundColor} pl-8 !-mb-4`}
																		error={
																			addressError
																		}
																		autoCompleteAddress={
																			true
																		}
																		value={
																			searchedAddress ||
																			null
																		}
																		onChange={
																			handleAddressChange
																		}
																	/>
																</>
															)}
															{searchedAddress !==
																'' && (
																<MdClear
																	className={`font-semibold absolute left-2 ml-4 top-[83px] transform -translate-y-12 text-green-600 cursor-pointer`}
																	onClick={() =>
																		setSearchedAddress(
																			''
																		)
																	}
																/>
															)}
															<Button
																className='mb-6'
																text='Select Address'
																size='sm'
																onClick={
																	handleAddressSubmit
																}
															/>
														</FormGroup>
													</div>
												</>
											) : locationType === 'customer' ? (
												<>
													<div className='w-full mb-4 flex flex-col justify-center align-center mx-auto'>
														<div className='text-sm font-bold mt-4 ml-3'>
															Select:
														</div>
														<div className='w-full'>
															{pendingPickups &&
															pendingPickups.length >
																0 ? (
																pendingPickups.map(
																	(
																		pickup,
																		index
																	) => (
																		<div
																			className='w-full my-2'
																			key={
																				index
																			}
																		>
																			<CheckboxGroup
																				key={
																					index
																				}
																				label={`${pickup.user.firstName} ${pickup.user.lastName} (${pickup.user.phone}), ${pickup.user.pickupAddress}, ${pickup.pickupNote}, Box Count: ${pickup.boxCount}`}
																				name={`pickup_${pickup._id}`}
																				className=''
																				defaultChecked={
																					false
																				}
																				onChange={(
																					event
																				) =>
																					handleCustomerCheckbox(
																						event,
																						pickup
																					)
																				}
																			/>
																		</div>
																	)
																)
															) : loadingTransactions ? (
																<BounceLoaderCentered />
															) : (
																<div className='text-center'>
																	No customer
																	pick ups
																	scheduled
																	for {date}
																</div>
															)}
														</div>
													</div>
												</>
											) : (
												<div className='w-full max-w-[600px] mb-4 flex flex-col justify-center align-center mx-auto'>
													<div className='text-sm font-bold mt-4 ml-3'>
														Search:
													</div>
													<div className='w-full mb-4'>
														<Input
															placeholder='Search and select locations'
															className=''
															name='searchInput'
															id='searchInput'
															onChange={(e) => {
																setShowNodeChoices(
																	true
																)
																setFilter({
																	$or: [
																		{
																			name: {
																				$regex: e
																					.target
																					.value,
																				$options:
																					'i',
																			},
																		},
																		{
																			slug: {
																				$regex: e
																					.target
																					.value,
																				$options:
																					'i',
																			},
																		},
																	],
																})
															}}
														/>
													</div>
													<div className='flex-col flex items-center'>
														{showNodeChoices && (
															<div className='absolute z-20 h-1/3 opacity-100 bg-white border-2 rounded-[5px] border-gray-300 overflow-y-auto !mt-18 w-[50%] max-w-[600px] whitespace-nowrap'>
																<div
																	className='cursor-pointer text-green-600'
																	onClick={() => {
																		setShowNodeChoices(
																			false
																		)
																	}}
																>
																	{' '}
																	Close X
																</div>
																{nodes.map(
																	(node) => (
																		<div
																			className='text-sm cursor-pointer my-1 hover:bg-gray-100'
																			key={
																				node.slug
																			}
																			onClick={() => {
																				if (
																					node &&
																					node.name
																				) {
																					const fullAddress =
																						node.unit
																							? `${
																									node.address ||
																									''
																							  }, ${
																									node.city ||
																									''
																							  }, ${
																									node.state ||
																									''
																							  }, ${
																									node.postal ||
																									''
																							  }`
																							: `${
																									node.address ||
																									''
																							  }, ${
																									node.unit ||
																									''
																							  }, ${
																									node.city ||
																									''
																							  }, ${
																									node.state ||
																									''
																							  }, ${
																									node.postal ||
																									''
																							  }`
																					setStop(
																						{
																							name: node.name,
																							slug: node.slug,
																							address:
																								fullAddress,
																							type: locationType,
																							stopType:
																								stopType,
																						}
																					)
																					setShowNodeChoices(
																						false
																					)
																					setFormError(
																						''
																					)
																					onResetSuccess()
																				}
																			}}
																		>
																			{
																				node.name
																			}
																		</div>
																	)
																)}
															</div>
														)}
													</div>
												</div>
											)}
											{stop &&
												stop.address &&
												stop.name && (
													<>
														<div className='bg-gray-100 w-full pb-6 mb-3 px-3'>
															<div className='mb-3'>
																<span className='font-bold text-sm'>
																	Selected
																	Location:
																</span>
															</div>
															<div className='flex flex-col mb-3'>
																<div className='flex flex-row'>
																	<div className='mt-1 font-bold text-green-600'>
																		{
																			stop.name
																		}
																		:
																	</div>
																	<div
																		className='ml-4 font-bold text-sm mt-1 cursor-pointer underline text-red-600'
																		onClick={() => {
																			setStop(
																				null
																			)
																		}}
																	>
																		Remove
																		location
																	</div>
																	{locationType ===
																		'warehouse' && (
																		<>
																			<CheckboxGroup
																				key={
																					index
																				}
																				label='New Shipment (include QTYS to add to warehouse inventory)'
																				name={`warehouseNewShipment`}
																				className=''
																				defaultChecked={
																					false
																				}
																				onChange={(
																					event
																				) =>
																					setWarehouseNewShipment(
																						event
																							.target
																							.checked
																					)
																				}
																			/>
																		</>
																	)}
																</div>
																<div className='flex flex-col ml-[20px]'>
																	{containers &&
																	containers.length >
																		0 ? (
																		<div>
																			{containers
																				// .filter((container) => container.markets.includes(getStateFromAddress(stop.address)))
																				.map(
																					(
																						container
																					) => (
																						<div
																							className='flex flex-row mb-4 items-center'
																							key={
																								container.containerId
																							}
																						>
																							<div className='mr-4'>
																								<img
																									src={`/images/containers/${container.containerId}.png`}
																									alt={
																										container.containerId
																									}
																									className='w-[100px] h-[100px] object-scale-down'
																								/>
																							</div>
																							<div className='text-lg font-bold w-[250px] mr-2'>
																								{
																									container.description
																								}

																								:
																							</div>
																							<div className='flex flex-row bg-gray-100 rounded-[5px] border-2 items-center'>
																								<span
																									color='default'
																									className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																									onClick={() => {
																										subtractContainers(
																											container.containerId
																										)
																										setFormError(
																											''
																										)
																										onResetSuccess()
																									}}
																								>
																									-
																								</span>
																								<input
																									type='number'
																									className='bg-white text-2xl mx-1 border-2 text-center border-gray-300 w-[100px]'
																									value={
																										containerRequest[
																											container
																												.containerId
																										] ||
																										0
																									}
																									onChange={(
																										e
																									) => {
																										handleQuantityChange(
																											container.containerId,
																											e
																												.target
																												.value
																										)
																										setFormError(
																											''
																										)
																										onResetSuccess()
																									}}
																								/>
																								<span
																									color='default'
																									className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																									onClick={() => {
																										addContainers(
																											container.containerId
																										)
																										setFormError(
																											''
																										)
																										onResetSuccess()
																									}}
																								>
																									+
																								</span>
																							</div>
																						</div>
																					)
																				)}
																		</div>
																	) : (
																		<div>
																			No
																			containers
																			available
																		</div>
																	)}
																</div>
															</div>
														</div>
														<TextArea
															name='Instructions'
															label={
																<span className='ml-3'>
																	Notes/Instructions
																</span>
															}
															placeholder='Include any notes/instructions here'
															className='!h-20 !mt-1'
															onChange={(e) => {
																setInstructions(
																	e
																)
															}}
														/>
													</>
												)}
										</div>
									</>
								)}

								{formError !== '' && (
									<div className='font-bold text-red-400 my-1 text-center'>
										{formError}
									</div>
								)}
								{stopsError &&
									stopsError.message &&
									stopsError.message !== 'ok' && (
										<div className='font-bold text-red-400 my-1 text-center'>
											{stopsError.message
												? stopsError.message
												: stopsError}
										</div>
									)}
								{loadingStops ? (
									<BounceLoaderCentered container='div' />
								) : !locationType ? (
									<Button
										className='mt-6 w-[300px] flex justify-center items-center mx-auto'
										disabled
										color='disabled'
										text='Add Stop'
									/>
								) : locationType === 'customer' ? (
									<Button
										className='mt-6 w-[300px] flex justify-center items-center mx-auto'
										onClick={handleCustomerSubmit}
										text='Add Stop (Customer)'
									/>
								) : (
									<Button
										className='mt-6 w-[300px] flex justify-center items-center mx-auto'
										onClick={handleSubmitStop}
										text='Add Stop'
									/>
								)}
								{!loadingStops &&
									!stopsError &&
									stopsSuccess && (
										<div className='flex flex-col justify-center items-center'>
											<div className='mt-2 text-xl mb-6 text-green-600 text-center'>
												<span className='font-bold'>
													Successful!
												</span>
											</div>
										</div>
									)}
							</div>
						</div>
					</div>

					{/* incomplete stops table */}
					{incompleteStops &&
					containers &&
					incompleteStops.length > 0 ? (
						<div className='w-[90%] mx-auto mt-6 overflow-auto'>
							<div className='w-full my-2'>
								<h3 className='font-header text-red-400 text-xl mb-4 text-center'>
									{market ? market : ''} Incomplete Stops
								</h3>
								<div className='overflow-x-auto'>
									<table className='w-full border-collapse border border-gray-300'>
										<thead>
											<tr className='bg-gray-100'>
												<th className='border border-gray-300 py-2 px-4'></th>
												<th className='border border-gray-300 py-2 px-4'>
													Date
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Type
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Stop Name
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Address
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Driver Note
												</th>
											</tr>
										</thead>
										<tbody>
											{incompleteStops &&
												incompleteStops.length > 0 &&
												incompleteStops.map(
													(stop, index) => (
														<tr key={stop.stopId}>
															<td className='border border-gray-300 py-2 px-4'>
																<CheckboxGroup
																	key={index}
																	label=''
																	name={`incompleteStop_${stop.stopId}`}
																	className=''
																	defaultChecked={
																		false
																	}
																	onChange={(
																		event
																	) =>
																		handleIncompleteCheckbox(
																			event,
																			stop
																		)
																	}
																/>
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.date}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.stopType ===
																'pick'
																	? 'Collection'
																	: 'Drop Off'}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.name}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.address}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{
																	stop.driverNote
																}
															</td>
														</tr>
													)
												)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					) : (
						<div className='text-center'>
							No incomplete stops found
						</div>
					)}

					{/* new restaurant stops table */}
					{newRestaurantStops && newRestaurantStops.length > 0 ? (
						<div className='w-[90%] mx-auto mt-6 overflow-auto'>
							<div className='w-full my-2'>
								<h3 className='font-header text-red-400 text-xl mb-4 text-center'>
									{market ? market : ''} New Restaurant Stops
								</h3>
								<div className='overflow-x-auto'>
									<table className='w-full border-collapse border border-gray-300'>
										<thead>
											<tr className='bg-gray-100'>
												<th className='border border-gray-300 py-2 px-4'></th>
												<th className='border border-gray-300 py-2 px-4'>
													Date
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Stop Name
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Address
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Contact
												</th>
												<th className='border border-gray-300 py-2 px-4'>
													Phone
												</th>
											</tr>
										</thead>
										<tbody>
											{newRestaurantStops &&
												newRestaurantStops.length > 0 &&
												newRestaurantStops.map(
													(stop, index) => (
														<tr key={index}>
															{stop.state ===
															'CA' ? (
																<td
																	className='border border-gray-300 py-2 px-4 underline text-green-600'
																	onClick={(
																		stop
																	) =>
																		handleGentlyRequest(
																			stop
																		)
																	}
																>
																	Submit drop
																	off request
																	through
																	Gently
																</td>
															) : (
																<td className='border border-gray-300 py-2 px-4'>
																	<CheckboxGroup
																		key={
																			index
																		}
																		label=''
																		name={`incompleteStop_${stop.fullDocument.slug}`}
																		className=''
																		checked={
																			selectedStop ===
																			stop
																		}
																		onChange={(
																			event
																		) =>
																			handleNewRestaurantCheckbox(
																				event,
																				stop
																			)
																		}
																	/>
																</td>
															)}
															<td className='border border-gray-300 py-2 px-4'>
																{
																	new Date(
																		stop.fullDocument.lastUpdated
																	)
																		.toISOString()
																		.split(
																			'T'
																		)[0]
																}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{
																	stop
																		.fullDocument
																		.restaurantName
																}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{
																	stop
																		.fullDocument
																		.fullAddress
																}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{
																	stop
																		.fullDocument
																		.firstName
																}{' '}
																{
																	stop
																		.fullDocument
																		.lastName
																}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{
																	stop
																		.fullDocument
																		.contactPhone
																}
															</td>
														</tr>
													)
												)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					) : (
						<div className='text-center'>
							No new restaurant stops found
						</div>
					)}

					{/* packing list table for day selected */}
					{containers && stops && !loadingStops && !initialLoad && (
						<div className='mt-10 w-[90%] overflow-auto scrollbar flex flex-col justify-center align-center mx-auto'>
							<h2 className='font-header text-green-600 text-3xl mb-4 text-center'>
								{market ? market : ''} Packing List for {date}
							</h2>
							<div className='overflow-x-auto'>
								<table className='w-full border-collapse border border-gray-300'>
									<thead>
										<tr className='bg-gray-100'>
											<th className='border border-gray-300 py-2 px-4'>
												Delete
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Date
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Market
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Type
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Stop Name
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Address
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Note
											</th>
											<th className='border border-gray-300 py-2 px-4'>
												Total Qty
											</th>
											{containers.map((container) => (
												<th
													key={container.containerId}
													className='border border-gray-300 py-2 px-4'
												>
													{container.containerId}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{stops &&
											stops.length > 0 &&
											stops.map((packingList) =>
												packingList?.stops?.map(
													(stop, index) => (
														<tr key={index}>
															<td className='border border-gray-300 py-2 px-4'>
																<div
																	className='text-red-400 underline'
																	onClick={() =>
																		onDeleteStop(
																			stop
																		)
																	}
																>
																	Delete
																</div>
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.date}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.market}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.stopType ===
																'pick'
																	? 'Collection'
																	: 'Drop Off'}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.name}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.address}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.note}
															</td>
															<td className='border border-gray-300 py-2 px-4'>
																{stop.boxCount}
															</td>
															{containers.map(
																(container) => (
																	<td
																		key={
																			container.containerId
																		}
																		className='border border-gray-300 py-2 px-4'
																	>
																		{stop?.products &&
																		stop
																			.products[
																			container
																				.containerId
																		] !==
																			undefined
																			? stop
																					.products[
																					container
																						.containerId
																			  ]
																			: 0}
																	</td>
																)
															)}
														</tr>
													)
												)
											)}
									</tbody>
								</table>
							</div>

							<Button
								className='my-6 w-[200px] mx-auto px-2'
								link='/admin/logistics/packinglist/dashboard'
								text='View DZ Internal Fulfillment'
							/>
						</div>
					)}
				</div>
				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({
	Nodes,
	User,
	Dropoffs,
	Containers,
	Logistics,
	Transactions,
	Pickups,
}) => ({
	nodes: Nodes.nodes,
	loadingNodes: Nodes.loadingNodes,
	nodesError: Nodes.nodesError,
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	creatingDropoff: Dropoffs.creatingDropoff,
	dropoffRequested: Dropoffs.dropoffRequested,
	dropoffError: Dropoffs.dropoffError,
	containers: Containers.containers,
	loadingContainers: Containers.loadingContainers,
	containersError: Containers.containersError,
	stops: Logistics.stops,
	loadingStops: Logistics.loadingStops,
	logisticsStops: Logistics.stops,
	stopsError: Logistics.error,
	stopsSuccess: Logistics.success,
	transactions: Transactions.transactions,
	loadingTransactions: Transactions.loadingTransactions,
	transactionsCount: Transactions.transactionsCount,
	transactionsError: Transactions.error,
	pendingPickup: Pickups.pickup,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pickupLoading: Pickups.pickupLoading,
	pendingPickups: Pickups.pendingPickups,
	incompleteStops: Logistics.incompleteStops,
	newRestaurantStops: Logistics.newRestaurantStops,
	nodeMarket: Logistics.market,
	availPartners: Logistics.availPartners,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetContainers: (filter) => dispatch(getContainers(filter)),
	onCreateStop: (payload) => dispatch(createStop(payload)),
	onUpdateStop: (payload) => dispatch(updateStop(payload)),
	onDeleteStop: (payload) => dispatch(deleteStop(payload)),
	onGetAllStops: (payload) => dispatch(getAllLogistics(payload)),
	onGetTransactions: (filter) => dispatch(getTransactions(filter)),
	onCreatePendingPickup: (payload) => dispatch(createPendingPickup(payload)),
	onGetPendingPickups: (payload) => dispatch(getPendingPickups(payload)),
	onGetIncompleteStops: (payload) => dispatch(getIncompleteStops(payload)),
	onGetNewRestaurantStops: (payload) =>
		dispatch(getNewRestaurantStops(payload)),
	onGetMarket: (payload) => dispatch(getMarket(payload)),
	onResetSuccess: () => dispatch(resetSuccess()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LogisticsPackingList)
