import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getNodes,
	updateUser,
	getDropoffs,
	cancelDropoff,
	getAllFlotDropoffs,
	getAllPendingPickups,
} from '../../../../store/actions'
import { Footer, Header } from '../../../../components'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	TextArea,
} from '../../../../components/Elements'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { NodeTypes } from '../../../../model/nodes'

const DropOffHome = (state) => {
	const {
		user,
		onGetUser,
		onGetDropoffs,
		gettingDropoff,
		dropoff,
		getDropoffError,
		loadingDropoffs,
		dropoffs,
		dropoffsError,
		cancellingDropoff,
		cancelDropoffError,
		onCancelDropoff,
		onGetAllFlotDropoffs,
		flotDropoffs,
		pickupLoading,
		pickupError,
		pendingPickups,
		onGetAllPendingPickups,
	} = state

	const paginationLimit = 50
	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [page, setPage] = useState(1)
	const [activeTab, setActiveTab] = useState('dropoffs')
	const [filter, setFilter] = useState({
		type: 'adminBalanceUpdate',
	})

	useEffect(() => {
		onGetUser()
		onGetAllFlotDropoffs()
	}, [onGetUser])

	// useEffect(() => {
	//     console.log(dropoffs)
	// }, [dropoffs])

	useEffect(() => {
		if (user && user.franchiseeSlug) {
			navigate('/admin/home')
		}
	}, [user])

	const getDropoffs = (page) => {
		if (user) {
			onGetDropoffs({
				filter: {},
				options: {
					sort: {
						updateTimestamp: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const dropoffsPaginate = () => {
		getDropoffs(page)
	}

	const getPickups = (page) => {
		if (user) {
			onGetAllPendingPickups({
				filter: filter,
				options: {
					sort: {
						timestamp: -1,
					},
					limit: paginationLimit,
					skip: (page - 1) * paginationLimit,
				},
			})
		}
	}

	const pickupsPaginate = () => {
		getPickups(page)
	}

	useEffect(() => {
		if (
			user &&
			user.admin &&
			!gettingDropoff &&
			!cancellingDropoff &&
			activeTab === 'dropoffs'
		) {
			dropoffsPaginate()
		} else if (
			user &&
			user.admin &&
			!pickupLoading &&
			!pickupError &&
			activeTab === 'pickups'
		) {
			pickupsPaginate()
		}
	}, [user, gettingDropoff, cancellingDropoff, flotDropoffs, page, activeTab])

	const handleRefresh = () => {
		dropoffsPaginate()
		onGetAllFlotDropoffs()
	}

	const handleTabChange = (tab) => {
		setActiveTab(tab)
	}

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.admin) {
		return (
			<div>
				<Header />
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate('/admin/home')
					}}
				>
					&#8592; Go to Admin Home
				</div>
				<div
					className='underline font-bold my-4 cursor-pointer ml-6'
					onClick={() => {
						navigate(-1)
					}}
				>
					&#8592; Go Back
				</div>
				<div className='px-12'>
					<h1 className='font-header text-green-600 text-4xl mb-7 mt-14 text-center'>
						Third Party Logistics Dashboard
					</h1>
					<div className='flex flex-col items-center w-full'>
						<Button
							link='/admin/dropoff-create'
							text='Create New Dropoff'
							size='sm'
							className='my-2 sm:my-0 sm:ml-2'
						/>
						{gettingDropoff || loadingDropoffs ? (
							<BounceLoaderCentered container='div' />
						) : (
							<Button
								onClick={handleRefresh}
								text='Refresh Dropoff Statuses'
								size='sm'
								color='green-outlined'
								className='w-[200px] mt-4 my-2 sm:ml-2'
							/>
						)}
					</div>
					<div className='flex justify-center my-4'>
						<button
							className={`tab-btn ${
								activeTab === 'dropoffs'
									? 'bg-green-600 text-white'
									: 'bg-gray-100 text-gray-800'
							} rounded-tl-md rounded-bl-md px-4 py-2`}
							onClick={() => handleTabChange('dropoffs')}
						>
							View Dropoffs
						</button>
						<button
							className={`tab-btn ${
								activeTab === 'pickups'
									? 'bg-green-600 text-white'
									: 'bg-gray-100 text-gray-800'
							} rounded-tr-md rounded-br-md px-4 py-2`}
							onClick={() => handleTabChange('pickups')}
						>
							View Pickups
						</button>
					</div>
					{cancelDropoffError && (
						<div className='font-bold text-red-400 my-1'>
							{cancelDropoffError.message}
						</div>
					)}
					<div className='tab-content'>
						{activeTab === 'dropoffs' && (
							<div className='max-w-[1000px] max-h-[900px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col m-auto rounded-xl px-8 py-2 mt-6 mb-8 scrollbar'>
								<div className='flex flex-row justify-start items-start'>
									<div className='font-bold min-w-[130px] font-header px-1'>
										<div>Order ID</div>
									</div>
									<div className='font-bold min-w-[180px] font-header px-1'>
										<div>Est. Pickup Time</div>
									</div>
									<div className='font-bold min-w-[180px] font-header px-1'>
										<div>Dropoff Date</div>
									</div>
									<div className='font-bold min-w-[200px] lg:min-w-[300px] font-header overflow-ellipsis px-1'>
										<div>Dropoff Location</div>
									</div>
									<div className='font-bold min-w-[130px] font-header px-1'>
										<div>Status</div>
									</div>
									<div className='font-bold min-w-[130px] font-header px-1'>
										<div>Last Updated</div>
									</div>
									<div className='font-bold min-w-[120px] font-header px-1'>
										<div>RLP</div>
									</div>
								</div>
								{dropoffs && dropoffs.length > 0 ? (
									<div className=''>
										{dropoffs.map((dropoff, index) => {
											return (
												<div
													className='w-full h-auto py-2 mb-2 whitespace-nowrap border-b border-gray-300'
													key={index}
												>
													<div className='text-sm flex flex-row items-center my-1 justify-start'>
														<div
															className='font-bold underline min-w-[120px]  whitespace-normal overflow-hidden cursor-pointer'
															onClick={() => {
																const url = `/admin/dropoff/${dropoff.orderId}`
																navigate(url)
															}}
														>
															{dropoff.orderId && (
																<div className='px-1'>
																	{
																		dropoff.orderId
																	}
																</div>
															)}
														</div>
														{/* <div className='w-[25px]'></div> */}
														<div className='min-w-[180px] px-1'>
															{
																dropoff.pickupTimeEstimated
															}
														</div>
														<div className='min-w-[180px] px-1'>
															{
																dropoff.dropoffTimeEstimated
															}
														</div>
														<div className='min-w-[200px] lg:min-w-[300px] overflow-hidden px-1'>
															{
																dropoff.dropoffNodeName
															}
														</div>
														<div className='min-w-[130px] font-bold px-1'>
															{dropoff.flotResponse ? (
																<div className='flex flex-col sm:flex-row'>
																	{dropoff.status ===
																	'cancelled' ? (
																		<span className='text-red-400 font-normal mb-3'>
																			{(
																				dropoff.status ||
																				''
																			).toUpperCase()}
																		</span>
																	) : (
																		<span className='text-green-600 font-normal mb-3'>
																			{(
																				dropoff.status ||
																				''
																			).toUpperCase()}
																		</span>
																	)}
																</div>
															) : (
																<div className='flex flex-col sm:flex-row'>
																	{dropoff.completed ? (
																		<div className='text-green-600'>
																			COMPLETED
																		</div>
																	) : dropoff.status &&
																	  dropoff.status ===
																			'canceled' ? (
																		<span className='text-red-400 font-normal mb-3'>
																			CANCELED
																		</span>
																	) : (
																		<div className='text-red-400'>
																			PENDING
																		</div>
																	)}
																</div>
															)}
														</div>
														{typeof dropoff.updateTimestamp ===
														'string' ? (
															<div className='min-w-[130px] px-1'>
																{
																	dropoff.updateTimestamp
																}
															</div>
														) : dropoff.updateTimestamp ? (
															<div className='min-w-[130px] px-1'>
																{JSON.stringify(
																	dropoff.updateTimestamp
																).slice(1, 11)}
															</div>
														) : (
															<div className='min-w-[130px] px-1'></div>
														)}
														<div className='min-w-[130px] px-1'>
															{dropoff.uberdirectId ? (
																<span>
																	Uber Direct
																</span>
															) : (
																<span>
																	Gently
																</span>
															)}
														</div>
														<div className=' min-w-[130px]'>
															{loadingDropoffs && (
																<BounceLoaderCentered container='div' />
															)}
														</div>
													</div>
												</div>
											)
										})}
									</div>
								) : (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								<div className='flex flex-row'>
									<div
										className='mx-auto underline font-bold mr-6 cursor-pointer'
										onClick={() => {
											setPage(page - 1)
										}}
									>
										Previous {paginationLimit} dropoffs
									</div>
									<div
										className='mx-auto underline font-bold cursor-pointer'
										onClick={() => {
											setPage(page + 1)
										}}
									>
										Next {paginationLimit} dropoffs
									</div>
								</div>
							</div>
						)}
						{activeTab === 'pickups' && (
							<div className='max-w-[1000px] max-h-[900px] overflow-x-auto overflow-y-auto shadow-light-grey flex flex-col m-auto rounded-xl px-8 py-2 mt-6 mb-8 scrollbar'>
								<div className='flex flex-row justify-start items-start'>
									<div className='font-bold w-[160px] font-header px-1'>
										<div>Order ID</div>
									</div>
									<div className='font-bold w-[130px] font-header px-1'>
										<div>Pickup Date</div>
									</div>
									<div className='font-bold w-[360px] font-header overflow-ellipsis px-1'>
										<div>Pickup Location</div>
									</div>
									<div className='font-bold w-[130px] font-header px-1'>
										<div>Status</div>
									</div>
									<div className='font-bold w-[130px] font-header px-1'>
										<div>RLP</div>
									</div>
								</div>
								{pendingPickups &&
								pendingPickups[0] &&
								pendingPickups[0].data &&
								pendingPickups[0].data.length > 0 ? (
									<div className=''>
										{pendingPickups[0].data.map(
											(request, index) => {
												return (
													<div
														className='w-full h-auto py-2 mb-2 whitespace-nowrap border-b border-gray-300'
														key={index}
													>
														<div className='text-sm flex flex-row items-center my-1 justify-start'>
															<div
																className='font-bold underline w-[160px]  whitespace-normal overflow-hidden cursor-pointer'
																onClick={() => {
																	const url = `/admin/logistics/pickups/${
																		request.orderId ||
																		request
																			.user
																			.phone
																	}`
																	navigate(
																		url
																	)
																}}
															>
																<div className='px-1 overflow-hidden'>
																	{request.orderId ||
																		request
																			.user
																			.firstName +
																			' ' +
																			request
																				.user
																				.lastName}
																</div>
															</div>
															{/* <div className='w-[25px]'></div> */}
															<div className='w-[130px] overflow-hidden whitespace-nowrap ml-2 px-1'>
																{request.requestTimeEstimated
																	? request.requestTimeEstimated
																	: JSON.stringify(
																			request.timestamp
																	  ).slice(
																			1,
																			11
																	  )}
															</div>
															<div className='w-[360px] overflow-hidden px-1'>
																{request.node ||
																	request.user
																		.pickupAddress}
															</div>
															<div className='w-[130px] font-bold px-1'>
																{request.completed ? (
																	<div className='text-green-600'>
																		COMPLETED
																	</div>
																) : [
																		'deliverzero',
																		'flot',
																		'returnmates',
																		'gently',
																  ].includes(
																		request.reverseLogisticsPartner
																  ) ? (
																	request.transactionAdded ? (
																		<div className='text-green-600'>
																			COMPLETED
																		</div>
																	) : (
																		<div className='text-red-400'>
																			PENDING
																		</div>
																	)
																) : request.status ? (
																	request.status ===
																	'delivered' ? (
																		<div className='text-green-600'>
																			{request.status.toUpperCase()}
																		</div>
																	) : (
																		<div className='text-red-400'>
																			{request.status.toUpperCase()}
																		</div>
																	)
																) : (
																	<div className='text-red-400'>
																		PENDING
																	</div>
																)}
															</div>
															<div className='w-[130px] px-1'>
																<span>
																	{
																		request.reverseLogisticsPartner
																	}
																</span>
															</div>
														</div>
													</div>
												)
											}
										)}
									</div>
								) : (
									<BounceLoader
										className='m-auto'
										color={bounceLoaderColor}
									></BounceLoader>
								)}
								<div className='flex flex-row mb-3'>
									<div
										className='mx-auto underline font-bold mr-6 cursor-pointer'
										onClick={() => {
											if (page > 1) {
												setPage(page - 1)
											}
										}}
									>
										Previous {paginationLimit} pickups
									</div>
									<div
										className='mx-auto underline font-bold cursor-pointer'
										onClick={() => {
											setPage(page + 1)
										}}
									>
										Next {paginationLimit} pickups
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				<Footer />
			</div>
		)
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({ User, Dropoffs, Pickups }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingDropoffs: Dropoffs.loadingDropoffs,
	dropoffs: Dropoffs.dropoffs,
	dropoffsError: Dropoffs.dropoffsError,
	gettingDropoff: Dropoffs.gettingDropoff,
	dropoff: Dropoffs.dropoff,
	getDropoffError: Dropoffs.getDropoffError,
	cancellingDropoff: Dropoffs.cancellingDropoff,
	cancelDropoffError: Dropoffs.cancelDropoffError,
	flotDropoffs: Dropoffs.flotDropoffs,
	pendingPickup: Pickups.pickup,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pickupLoading: Pickups.pickupLoading,
	pendingPickups: Pickups.pendingPickups,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodes: (filter) => dispatch(getNodes(filter)),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetUser: () => dispatch(getUser()),
	onGetDropoffs: (payload) => dispatch(getDropoffs(payload)),
	onCancelDropoff: (dropoffId) => dispatch(cancelDropoff(dropoffId)),
	onGetAllFlotDropoffs: (payload) => dispatch(getAllFlotDropoffs(payload)),
	onGetAllPendingPickups: (payload) =>
		dispatch(getAllPendingPickups(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropOffHome)
