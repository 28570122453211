export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL'

export const SOCIAL_LOGIN_GOOGLE = 'SOCIAL_LOGIN_GOOGLE'
export const SOCIAL_REGISTER_GOOGLE = 'SOCIAL_REGISTER_GOOGLE'
export const SOCIAL_LOGIN_GOOGLE_SUCCESS = 'SOCIAL_LOGIN_GOOGLE_SUCCESS'
export const SOCIAL_LOGIN_GOOGLE_FAIL = 'SOCIAL_LOGIN_GOOGLE_FAIL'

export const SOCIAL_LOGIN_FACEBOOK = 'SOCIAL_LOGIN_FACEBOOK'
export const SOCIAL_LOGIN_FACEBOOK_SUCCESS = 'SOCIAL_LOGIN_FACEBOOK_SUCCESS'
export const SOCIAL_LOGIN_FACEBOOK_FAIL = 'SOCIAL_LOGIN_FACEBOOK_FAIL'
