export const GET_NODES = 'GET_NODES'
export const GET_NODES_SUCCESS = 'GET_NODES_SUCCESS'
export const GET_NODES_FAIL = 'GET_NODES_FAIL'

export const GET_ADMIN_NODES = 'GET_ADMIN_NODES'
export const GET_ADMIN_NODES_SUCCESS = 'GET_ADMIN_NODES_SUCCESS'
export const GET_ADMIN_NODES_FAIL = 'GET_ADMIN_NODES_FAIL'

export const GET_NODE = 'GET_NODE'
export const GET_NODE_FAIL = 'GET_NODE_FAIL'
export const GET_NODE_SUCCESS = 'GET_NODE_SUCCESS'

export const GET_NODE_BY_SLUG = 'GET_NODE_BY_SLUG'
export const GET_NODE_BY_SLUG_FAIL = 'GET_NODE_BY_SLUG_FAIL'
export const GET_NODE_BY_SLUG_SUCCESS = 'GET_NODE_BY_SLUG_SUCCESS'

export const GET_NODE_BY_QR = 'GET_NODE_BY_QR'
export const GET_NODE_BY_QR_FAIL = 'GET_NODE_BY_QR_FAIL'
export const GET_NODE_BY_QR_SUCCESS = 'GET_NODE_BY_QR_SUCCESS'
export const GET_NODE_BY_QR_NOT_FOUND = 'GET_NODE_BY_QR_NOT_FOUND'

export const RESET_NODE = 'RESET_NODE'

export const UPDATE_SEARCH_ADDRESS = 'UPDATE_SEARCH_ADDRESS'

export const ADD_NODE = 'ADD_NODE'
export const ADD_NODE_FAIL = 'ADD_NODE_FAIL'
export const ADD_NODE_SUCCESS = 'ADD_NODE_SUCCESS'

export const UPDATE_NODE = 'UPDATE_NODE'
export const UPDATE_NODE_FAIL = 'UPDATE_NODE_FAIL'
export const UPDATE_NODE_SUCCESS = 'UPDATE_NODE_SUCCESS'

export const GET_NODE_BY_ADMIN_CODE = 'GET_NODE_BY_ADMIN_CODE'
export const GET_NODE_BY_ADMIN_CODE_SUCCESS = 'GET_NODE_BY_ADMIN_CODE_SUCCESS'
export const GET_NODE_BY_ADMIN_CODE_FAIL = 'GET_NODE_BY_ADMIN_CODE_FAIL'

export const RESET_ADMIN_NODE = 'RESET_ADMIN_NODE'

export const RESTAURANT_ADMIN_SUBMIT_REQUEST = 'RESTAURANT_ADMIN_SUBMIT_REQUEST'
export const RESTAURANT_ADMIN_SUBMIT_REQUEST_SUCCESS =
	'RESTAURANT_ADMIN_SUBMIT_REQUEST_SUCCESS'
export const RESTAURANT_ADMIN_SUBMIT_REQUEST_FAIL =
	'RESTAURANT_ADMIN_SUBMIT_REQUEST_FAIL'
