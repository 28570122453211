import {
	GET_ALL_REUSABLE_PRODUCTS,
	ADD_TO_CART_REUSABLES_SHOP,
	UPDATE_CART_ITEM_REUSABLES_SHOP,
	UPDATE_CART_FROM_USER,
	ADD_CART_TO_USER_REUSABLES_SHOP,
	CLEAR_CART_REUSABLES_SHOP,
	SUBMIT_REUSABLES_ORDER,
	GET_REUSABLES_ORDERS,
	CALCULATE_SHIPPING,
} from './actionTypes'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getAllReusableProductsSuccess,
	getAllReusableProductsFail,
	addProductReusablesShopSuccess,
	addProductReusablesShopFail,
	updateCartItemReusablesShopSuccess,
	updateCartItemReusablesShopFail,
	updateCartFromUserSuccess,
	updateCartFromUserFail,
	addCartToUserReusablesShopSuccess,
	addCartToUserReusablesShopFail,
	clearCartReusablesShopSuccess,
	clearCartReusablesShopFail,
	submitReusablesOrderSuccess,
	submitReusablesOrderFail,
	getResuablesOrdersFail,
	getResuablesOrdersSuccess,
	calculateShippingFail,
	calculateShippingSuccess,
} from './actions'
import {
	getAllReusableProducts,
	handleAddToCartReusablesShop,
	handleUpdateCartItemReusablesShop,
	updateCartFromUser,
	addCartToUserReusablesShop,
	handleClearCartReusablesShop,
	submitReusablesOrder,
	getResuablesOrders,
	calculateShipping,
} from '../../model/reusablesShop'

function* fetchAllReusableProducts(action) {
	try {
		const allProducts = yield call(getAllReusableProducts, action.payload)
		yield put(getAllReusableProductsSuccess(allProducts))
	} catch (error) {
		yield put(getAllReusableProductsFail(error))
	}
}

function* addProductToCart(action) {
	try {
		const updatedCart = yield call(
			handleAddToCartReusablesShop,
			action.payload
		)
		yield put(addProductReusablesShopSuccess(updatedCart))
	} catch (error) {
		yield put(addProductReusablesShopFail(error))
	}
}

function* updateProductInCart(action) {
	try {
		const updatedCart = yield call(
			handleUpdateCartItemReusablesShop,
			action.payload
		)
		yield put(updateCartItemReusablesShopSuccess(updatedCart))
	} catch (error) {
		yield put(updateCartItemReusablesShopFail(error))
	}
}

function* updateCartFromUserSaga(action) {
	try {
		const updatedCart = yield call(updateCartFromUser, action.payload)
		yield put(updateCartFromUserSuccess(updatedCart))
	} catch (error) {
		yield put(updateCartFromUserFail(error))
	}
}

function* addCartToUserSaga(action) {
	try {
		yield call(addCartToUserReusablesShop, action.payload)
		yield put(addCartToUserReusablesShopSuccess())
	} catch (error) {
		yield put(addCartToUserReusablesShopFail(error))
	}
}

function* clearCartReusablesShopSaga(action) {
	try {
		const clearedCart = yield call(
			handleClearCartReusablesShop,
			action.payload
		)
		yield put(clearCartReusablesShopSuccess(clearedCart))
	} catch (error) {
		yield put(clearCartReusablesShopFail(error))
	}
}

function* completeReusablesOrder(action) {
	try {
		const oneOrder = yield call(submitReusablesOrder, action.payload)
		yield put(submitReusablesOrderSuccess(oneOrder))
	} catch (error) {
		yield put(submitReusablesOrderFail(error))
	}
}

function* fetchReusablesOrders(action) {
	try {
		const orders = yield call(getResuablesOrders, action.payload)
		yield put(getResuablesOrdersSuccess(orders))
	} catch (error) {
		yield put(getResuablesOrdersFail(error))
	}
}

function* fetchShippingCost(action) {
	try {
		const cost = yield call(calculateShipping, action.payload)
		yield put(calculateShippingSuccess(cost))
	} catch (error) {
		yield put(calculateShippingFail(error))
	}
}

function* reusablesShopSaga() {
	yield takeEvery(GET_ALL_REUSABLE_PRODUCTS, fetchAllReusableProducts)
	yield takeEvery(ADD_TO_CART_REUSABLES_SHOP, addProductToCart)
	yield takeEvery(UPDATE_CART_ITEM_REUSABLES_SHOP, updateProductInCart)
	yield takeEvery(UPDATE_CART_FROM_USER, updateCartFromUserSaga)
	yield takeEvery(ADD_CART_TO_USER_REUSABLES_SHOP, addCartToUserSaga)
	yield takeEvery(CLEAR_CART_REUSABLES_SHOP, clearCartReusablesShopSaga)
	yield takeEvery(SUBMIT_REUSABLES_ORDER, completeReusablesOrder)
	yield takeEvery(GET_REUSABLES_ORDERS, fetchReusablesOrders)
	yield takeEvery(CALCULATE_SHIPPING, fetchShippingCost)
}

export default reusablesShopSaga
