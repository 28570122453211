import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import { Footer } from '../../components'
import { getOrders } from '../../store/orders/actions'
import { getUser } from '../../store/auth/user/actions'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import UserProfileCard from '../../components/UserProfile/UserProfileCard'
import {
	disputeTransaction,
	getOneTransaction,
	updateOneTransaction,
	resetUpdateTransaction,
} from '../../store/transactions/actions'

const DisputeBoxes = (props) => {
	const {
		user,
		loadingUser,
		onGetUser,
		onGetOneTransaction,
		loadingTransaction,
		transaction,
		transactionError,
		onSubmitDisputeTransaction,
		successfulDispute,
		updatingTransaction,
		updatedTransaction,
		updateTransactionError,
		onUpdateOneTransaction,
		onResetUpdateTransaction,
	} = props
	const { transactionId } = useParams()
	const bounceLoaderColor = '#507f74'
	const [disputedTransaction, setDisputedTransaction] = useState({
		items: [],
	})
	const [updateDisputedTransaction, setUpdateDisputedTransaction] =
		useState(false)
	const [hasFractionalBoxes, setHasFractionalBoxes] = useState(false)

	useEffect(() => {
		onResetUpdateTransaction()
		// console.log("updatedTransaction", updatedTransaction, "successfulDispute", successfulDispute)
		if (user) {
			if (transactionId) {
				onGetOneTransaction({
					transactionId: transactionId,
					phone: user.phone,
				})
			}
		} else {
			onGetUser()
		}
	}, [user])

	// useEffect(() => {
	//     if (user && updatedTransaction && transactionId) {
	//         onGetOneTransaction({
	//             transactionId: transactionId,
	//             phone: user.phone,
	//         })
	//     }
	// }, [updatedTransaction])

	function hasFractionalBoxCount(object) {
		for (let item of object.items) {
			if (!Number.isInteger(item.boxCount)) {
				setHasFractionalBoxes(true)
			}
		}
	}

	useEffect(() => {
		if (transaction) {
			setDisputedTransaction(transaction)
			hasFractionalBoxCount(transaction)
		}
	}, [transaction])
	// console.log(disputedTransaction.items)

	useEffect(() => {
		if (updateDisputedTransaction) {
			setDisputedTransaction(transaction)
			setUpdateDisputedTransaction(false)
		}
	}, [updateDisputedTransaction])

	function addBoxes(itemIndex) {
		transaction.items[itemIndex].boxCount =
			transaction.items[itemIndex].boxCount + 1
		setUpdateDisputedTransaction(true)
	}

	function subtractBoxes(itemIndex) {
		if (transaction.items[itemIndex].boxCount > 0) {
			transaction.items[itemIndex].boxCount =
				transaction.items[itemIndex].boxCount - 1
			setUpdateDisputedTransaction(true)
		}
	}

	function addModBoxes(modIndex, itemIndex) {
		transaction.items[itemIndex].modifiers[modIndex].boxCount =
			transaction.items[itemIndex].modifiers[modIndex].boxCount + 1
		setUpdateDisputedTransaction(true)
	}

	function subtractModBoxes(modIndex, itemIndex) {
		if (transaction.items[itemIndex].modifiers[modIndex].boxCount > 0) {
			transaction.items[itemIndex].modifiers[modIndex].boxCount =
				transaction.items[itemIndex].modifiers[modIndex].boxCount - 1
			setUpdateDisputedTransaction(true)
		}
	}

	function addTotalBoxes() {
		transaction.boxCount = transaction.boxCount + 1
		setUpdateDisputedTransaction(true)
	}

	function subtractTotalBoxes() {
		if (transaction.boxCount > 0) {
			transaction.boxCount = transaction.boxCount - 1
		}
		setUpdateDisputedTransaction(true)
	}

	function handleOnlick() {
		// console.log(transaction)
		if (hasFractionalBoxes) {
			// console.log("HERE")
			onUpdateOneTransaction({
				transactionId: transactionId,
				update: {
					boxCount: transaction.boxCount,
					disputedTransaction: true,
				},
			})
		} else {
			onSubmitDisputeTransaction(disputedTransaction)
		}
	}

	if (!transaction)
		return (
			<div>
				<Header />
				<div className='flex items-center h-screen-no-header'>
					<BounceLoader
						className='m-auto w-full'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
				<Footer />
			</div>
		)

	return (
		<div>
			<Header />
			<h1 className='font-vollkorn text-green-600 text-4xl mt-14 text-center'>
				Dispute Container Count
			</h1>
			<div className='m-auto w-5/6 md:w-1/2 text-center'>
				Were our calculations off? Adjust the number of containers you
				received per item below and we'll adjust your account. Your
				adjustments help our system get smarter
			</div>

			<div className=' h-screen-no-header flex justify-center'>
				<UserProfileCard
					titleClassName='text-green-400 text-2xl md:text-3xl '
					className='mt-5 m-10 overflow-y-auto w-5/6 md:w-3/5 lg:max-w-[720px] h-[500px] shadow-lighter-grey'
					title={`How many containers did you receive per item?`}
					content={
						<div className='flex flex-col'>
							{hasFractionalBoxes && (
								<div className='my-4'>
									<div className='flex flex-row'>
										<div className='font-header w-3/5'>
											Total Number of Containers
										</div>
										<div className='font-header w-2/5'>
											<div
												className={`border-2 w-full sm:w-5/6 rounded-full text-center flex justify-between px-3 items-center`}
											>
												<span
													color='default'
													className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
													onClick={subtractTotalBoxes}
												>
													-
												</span>
												<div
													className={`text-xl sm:text-2xl`}
												>
													{transaction.boxCount}
												</div>
												<span
													color='default'
													className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
													onClick={addTotalBoxes}
												>
													+
												</span>
											</div>
										</div>
									</div>
									<div>
										Items were combined in boxes. Please
										update total number of containers.
									</div>
								</div>
							)}
							<div className='flex flex-row'>
								<div className='font-header w-3/5'>Item</div>
								<div className='font-header w-2/5'>
									Number of Containers
								</div>
							</div>
							{disputedTransaction &&
							disputedTransaction.items ? (
								<div>
									{disputedTransaction.items?.map(
										(item, index) => {
											if (
												!item.product.name
													.toLowerCase()
													.replaceAll(' ', '')
													.includes('deliverzero')
											) {
												return (
													<div>
														<div className='flex flex-row my-2'>
															<div className='items-center my-1.5 w-3/5 overflow-hidden'>
																{
																	item.product
																		.name
																}
															</div>
															{!hasFractionalBoxes && (
																<div className='ml-1 sm:ml-0 w-2/5'>
																	<div
																		className={`border-2 w-full sm:w-5/6 rounded-full text-center flex justify-between px-3 items-center`}
																	>
																		<span
																			color='default'
																			className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																			onClick={() => {
																				subtractBoxes(
																					index
																				)
																			}}
																		>
																			-
																		</span>
																		<div
																			className={`text-xl sm:text-2xl`}
																		>
																			{
																				item.boxCount
																			}
																		</div>
																		<span
																			color='default'
																			className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																			onClick={() => {
																				addBoxes(
																					index
																				)
																			}}
																		>
																			+
																		</span>
																	</div>
																</div>
															)}
														</div>
														{item.modifiers?.map(
															(mod, modIndex) => {
																if (
																	mod.boxCount >
																	0
																) {
																	return (
																		<div>
																			<div className='flex flex-row my-2 mx-2'>
																				<div className='my-1.5 w-3/5 overflow-hidden'>
																					<span className='font-header w-3/5'>
																						Mod:{' '}
																					</span>
																					{
																						mod.name
																					}
																				</div>
																				<div className='w-2/5'>
																					<div
																						className={`border-2 w-full sm:w-5/6 rounded-full text-center flex justify-between px-3 items-center`}
																					>
																						<span
																							color='default'
																							className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																							onClick={() => {
																								subtractModBoxes(
																									modIndex,
																									index
																								)
																							}}
																						>
																							-
																						</span>
																						<div
																							className={`text-xl sm:text-2xl`}
																						>
																							{
																								mod.boxCount
																							}
																						</div>
																						<span
																							color='default'
																							className={`text-3xl sm:text-4xl text-gray-300 cursor-pointer`}
																							onClick={() => {
																								addModBoxes(
																									modIndex,
																									index
																								)
																							}}
																						>
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																}
															}
														)}
													</div>
												)
											}
										}
									)}
								</div>
							) : (
								<BounceLoader
									className='m-auto w-full'
									color={bounceLoaderColor}
								></BounceLoader>
							)}

							<div className='flex flex-col justify-center my-5'>
								{(transactionError ||
									updateTransactionError) && (
									<div className='mt-3 text-center text-sm text-red-400'>
										Error submitting dispute. Contact
										customer service.
									</div>
								)}
								{(successfulDispute || updatedTransaction) && (
									<div className='mt-3 mb-6 text-center text-base text-green-600 font-semibold'>
										Success! Your account has been updated.
									</div>
								)}
								{loadingTransaction || updatingTransaction ? (
									<BounceLoader
										className='m-auto w-full'
										color={bounceLoaderColor}
									></BounceLoader>
								) : (
									<div
										className='m-auto w-1/2 text-xl font-semibold py-3 px-8 items-center font-poppins tracking-wider
                                                 cursor-pointer border border-2 rounded-full whitespace-nowrap  min-w-fit text-center
                                                 justify-center focus:ring-2 focus:ring-offset-2 text-white bg-green-600 border-green-600
                                                 hover:bg-green-400 hover:border-green-400 focus:ring-green-600'
										onClick={handleOnlick}
									>
										Submit Dispute
									</div>
								)}
							</div>
						</div>
					}
				/>
			</div>
			<Footer />
		</div>
	)
}
const mapStateToProps = ({ User, Transactions }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	loadingTransaction: Transactions.loadingTransactions,
	transaction: Transactions.oneTransaction,
	transactionError: Transactions.error,
	successfulDispute: Transactions.successfulDispute,
	updatingTransaction: Transactions.updatingTransaction,
	updatedTransaction: Transactions.updatedTransaction,
	updateTransactionError: Transactions.updateTransactionError,
})
const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getOrders(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetOneTransaction: (payload) => dispatch(getOneTransaction(payload)),
	onUpdateOneTransaction: (payload) =>
		dispatch(updateOneTransaction(payload)),
	onSubmitDisputeTransaction: (payload) =>
		dispatch(disputeTransaction(payload)),
	onResetUpdateTransaction: () => dispatch(resetUpdateTransaction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DisputeBoxes)
