import React, { useState, useEffect } from 'react'
import { Badge } from '../Elements'

const NodeMenuHeader = ({ node }) => {
	const daysOfWeekMap = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 } // map from Date().getDay() (0-6=Sun-Sat) to google's openHours (0-6=Mon-Sun)
	const usBankHolidays = [
		'Jan 1',
		'Jan 15',
		'Feb 19',
		'May 27',
		'Jun 19',
		'Jul 4',
		'Sep 2',
		'Oct 9',
		'Nov 11',
		'Nov 23',
		'Dec 25',
		'Oct 26',
	] // oct 2023 - oct 2024 dates

	const [dayOfWeek, setDayOfWeek] = useState('')
	const [isHoliday, setIsHoliday] = useState(false)

	const formatTodayHours = (input) => {
		if (typeof input === 'string') {
			input = input.replace(/.*day/, 'Today')
			return input
		}
		return toString(input)
	}

	useEffect(() => {
		const currentDate = new Date()
		const dateOptions = { month: 'short', day: 'numeric' }
		const formattedDate = currentDate.toLocaleDateString(
			'en-US',
			dateOptions
		) // in format "Jan 1"
		setDayOfWeek(currentDate.getDay())
		if (usBankHolidays.includes(formattedDate)) {
			setIsHoliday(true)
		}
	}, [node])

	return (
		<div className='md:flex md:flex-col md:pt-4'>
			<div className='h-[150px] md:h-[230px] rounded-xl overflow-hidden'>
				<img
					className='w-full object-cover'
					src={node.thumbnail}
					alt=''
				/>
			</div>
			<div className='mx-3 mt-4 md:mb-0 tracking-wider flex flex-row justify-between'>
				<div>
					<h1 className='font-header text-xl text-black md:text-3xl'>
						{node.name}
					</h1>
					<p className='font-semibold text-sm'>{node.cuisine}</p>
					{node.courierAcceptsReturns && (
						<div className='whitespace-nowrap w-full my-1'>
							<Badge
								text='Courier Accepts Returns'
								color='orange-primary'
								className='cursor-pointer'
							/>
						</div>
					)}
				</div>
				<div className=''>
					<div className='font-semibold text-xs ml-3 sm:text-sm sm:ml-0'>
						<p>
							{node.address}, {node.city}
						</p>
						<p className='mt-1'>
							{' '}
							{node.openHoursGoogle &&
							node.openHoursGoogle.length > 0
								? formatTodayHours(
										node.openHoursGoogle[
											daysOfWeekMap[dayOfWeek]
										]
								  )
								: node.openHoursDescription}
						</p>
						{isHoliday && (
							<div className='text-red-400 text-xs'>
								Hours may differ on holidays.
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default NodeMenuHeader
