import { realm } from '../helpers/realm'

export const sendSMSNotification = async (notificationPayload) => {
	const notificationsResponse = await realm.currentUser.callFunction(
		'sendUserSmsNotification',
		notificationPayload
	)

	return notificationsResponse
}
